import React from 'react';
import { presentStandardToast } from '../HelperIonic';
import { toastController } from '@ionic/core';

interface CFUProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
  onFilesSelect: (files: FileList) => Promise<void>;
  onError?: () => void;
  acceptedFileTypes?: string;
}

export const CFileUpload = ({
  fileInputRef,
  onFilesSelect,
  onError,
  acceptedFileTypes = '.jpg, .jpeg, .png',
}: CFUProps) => {
  return (
    <input
      ref={fileInputRef}
      hidden
      multiple
      type="file"
      accept={acceptedFileTypes}
      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if ((files ?? []).length === 0) return;
        try {
          await onFilesSelect(files);
        } catch (error) {
          const message = `Error uploading pictures:\n${error}`;
          await presentStandardToast(toastController, message, 10000);
          onError?.();
        }
      }}
    />
  );
};
