import firebase from 'firebase/compat/app';
import { inspectionColRef } from '../../DataStorage';
import { DeletionInfo, UserProfile } from '../../Model';

export const deleteFieldInspectionsById = async (
  firestore: firebase.firestore.Firestore,
  orgId: string,
  profile: UserProfile,
  inspectionIds: string[]
) => {
  const promises = inspectionIds.map(async (id) => {
    const doc = await inspectionColRef(firestore, orgId).doc(id).get();

    const deletionInfo: DeletionInfo = {
      deletedByUserId: profile.id,
      deletionDate: new Date(),
      deletedFrom: 'PageLocationDetails',
      originalPath: doc.ref.path,
    };

    // archive it
    await inspectionColRef(firestore, orgId, true)
      .doc(id)
      .set({ ...doc.data(), deletionInfo });

    // delete it
    await doc.ref.delete();
  });

  await Promise.all(promises);
};
