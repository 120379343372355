import { get, set, del, createStore, values } from 'idb-keyval';
import { storage, auth } from './ConfigFirebase';
import { Picture, RawPicture, UserProfile } from './Model';
import { BlobToBase64, uuid4 } from './HelperUtils';
import eventLogger from './events/common';
import firebase from 'firebase/compat/app';
import { Auth } from 'firebase/auth';
import { toastController } from '@ionic/core';
import { uniq } from 'lodash';
import {
  Configuration,
  AgrinormPlatformApi,
  LabelRequest,
  ValidationRequest,
} from './generated/openapi/vision';
import {
  createPhotoDeletedLocallyEvent,
  createPhotoSavedToCloudEvent,
} from './events/photo';
import { INSPECTION_IMAGES_STORAGE_BASE_PATH } from './GlobalConstants';
import { InspectionSpecSection } from './generated/openapi/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { imagesStateObservable } from './simpleObservable/observables';

// size of the batch upload of pictures
const BATCH_SIZE = 10;
// urlMap for caching already fetched images urls
let urlMap = {};
// urlMap for caching already fetched images urls
let uploadingMap: string[] = [];
// local IndexedDB for images
const imageStore = createStore('ag-qc-tool-db', 'image');

const toastError = async (message) => {
  const toast = await toastController.create({
    // @ts-ignore
    message: message,
    position: 'top',
    color: 'danger',
    duration: 0,
    cssClass: 'toast-message',
    buttons: [
      {
        text: 'Got it',
        role: 'cancel',
      },
      {
        text: 'Retry',
        cssClass: 'toast-retry-button',
        handler: () => {
          doImageSync(true);
          toast.dismiss();
        },
      },
    ],
  });
  toast.present().then();
};

export const getVisionApiUrl = () => {
  const defaultUrl = 'https://vision-api-integration-cua7thhd7a-ew.a.run.app';
  const urlMap = {
    'agrinorm-connect': 'https://vision-api-cua7thhd7a-ew.a.run.app',
    'agrinorm-connect-integration': defaultUrl,
    'agrinorm-fernando': defaultUrl,
  };
  return urlMap[process.env.REACT_APP_PROJECT_ID] ?? defaultUrl;
};

export function getPictureURI(pictureId: string) {
  return `gs://${process.env.REACT_APP_STORAGE_BUCKET}/${INSPECTION_IMAGES_STORAGE_BASE_PATH}/${pictureId}`;
}

export async function fetchOCRData(auth: Auth, profile: UserProfile, picture: Picture) {
  const token = await auth.currentUser.getIdToken();
  const axiosConfig = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const payload: LabelRequest = {
    org_id: profile.organisationId,
    picture_uri: getPictureURI(picture.id),
  };

  const apiConfig: Configuration = new Configuration({ basePath: getVisionApiUrl() });
  const visionApi = new AgrinormPlatformApi(apiConfig);

  console.log('OCR PAYLOAD', payload, axiosConfig);
  const res = await visionApi.labelEndpoint(payload, axiosConfig);
  console.log('OCR RESPONSE', res);
  return res;
}

// TODO: too much repeated code with function above, unify
export async function validateOCRProp(
  auth: Auth,
  profile: UserProfile,
  type: 'GGN' | 'COC' | 'GLN',
  value: string
): Promise<boolean> {
  const token = await auth.currentUser.getIdToken();
  const axiosConfig = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const payload: ValidationRequest = {
    org_id: profile.organisationId,
    targets: {
      [type]: value,
    },
  };

  const apiConfig: Configuration = new Configuration({ basePath: getVisionApiUrl() });
  const visionApi = new AgrinormPlatformApi(apiConfig);

  console.log('OCR PAYLOAD', payload, axiosConfig);
  const res = await visionApi.validationEndpoint(payload, axiosConfig);
  console.log('OCR RESPONSE', res);
  return res!! ? (res.data.targets[type].extras as any).valid : false;
}

//--------------------------------------------------------------------------------
export async function uploadSingleImage(image: RawPicture) {
  if (!image) {
    return;
  }

  uploadingMap = uniq(uploadingMap.concat([image.id]));

  // Upload to storage
  const uploadTask = storage
    .ref()
    .child(INSPECTION_IMAGES_STORAGE_BASE_PATH + '/' + image.id)
    .put(image.content);

  return new Promise((resolve, reject) => {
    const rejectPromise = (error) => {
      console.error(error);
      set(image.id, { ...image, isUploading: false }, imageStore);
      uploadingMap = uploadingMap.filter((id) => id !== image.id);
      reject(error);
    };

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {},
      (error) => rejectPromise(error.code),
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async (downloadURL) => {
            console.log('File available at', downloadURL);

            const profile: UserProfile = { id: auth.currentUser.uid } as UserProfile;
            eventLogger.log(
              createPhotoSavedToCloudEvent(downloadURL, image.id),
              profile
            );

            // ALL WENT GOOD: now proceed to update the local cache
            // removing the image.id from indexedDB
            // ----------------------------------------------------
            const images = imagesStateObservable
              .getValue()
              .images.filter((imageId) => imageId !== image.id);

            imagesStateObservable.next({ images, syncing: images.length !== 0 });
            await del(image.id, imageStore);
            uploadingMap = uploadingMap.filter((id) => id !== image.id);
            eventLogger.log(createPhotoDeletedLocallyEvent(image.id), profile);
            resolve('upload completed for ' + image.id);
          })
          .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            rejectPromise(error.code);
          });
      }
    );
  });
}

//--------------------------------------------------------------------------------
export async function doImageSync(showToastOnFinish = false) {
  let rawPictures = await dirtyImages();

  if (rawPictures.length === 0) {
    return;
  }

  if (!navigator.onLine) {
    // don't sync if we are not online
    return;
  }
  // filter images that are already uploading
  const imagesArrayIds = rawPictures
    .map((image) => image.id)
    .filter((id) => !uploadingMap.includes(id));
  const imagesArray = rawPictures.filter((image) => !uploadingMap.includes(image.id));
  console.log('start data syncing: doImageSync', imagesArray);

  if (imagesArray.length === 0) {
    return;
  }

  imagesStateObservable.next({
    images: imagesArrayIds,
    syncing: true,
  });

  let promises = imagesArray.map(uploadSingleImage);
  let rejectedPromises = [];

  while (promises.length) {
    // batching every 10 promises
    const result = await Promise.allSettled(
      promises
        .splice(0, BATCH_SIZE)
        .map((f) => f.then(() => console.log('promise finished in batch')))
    );
    // console.log('batch completed')
    rejectedPromises = rejectedPromises.concat(
      result.filter((p) => p.status === 'rejected')
    );
  }

  console.log('Finishing data syncing');

  if (rejectedPromises.length > 0) {
    const errorArray = Array.from(
      new Set(rejectedPromises.map((p) => mapUploadErrorReason(p.reason)))
    );

    toastError(
      `<b>ERROR UPLOADING IMAGE${
        rejectedPromises.length > 0 ? 'S' : ''
      }</b><br/><br/>` + errorArray.join('<br/>')
    );
  } else if (showToastOnFinish) {
    const toast = await toastController.create({
      message: '<b>IMAGE SYNC HAS FINISHED SUCCESSFULLY</b>',
      position: 'top',
      color: 'primary',
      duration: 0,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });
    toast.present().then();
  }

  let images = await dirtyImages();

  imagesStateObservable.next({
    images: images.map((i) => i.id),
    syncing: false,
  });

  return;
}

const mapUploadErrorReason = (reason: string): string => {
  switch (reason) {
    case 'storage/retry-limit-exceeded':
      return 'Image upload took too long. Please make sure that you have a healthy internet connection';
  }
  return reason;
};

export async function takeNativePicture(
  organisationId: string,
  inspectionId: string,
  section?: InspectionSpecSection
): Promise<Picture> {
  // MVP for taking a picture w/ the capacitor Camera plugin
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source: CameraSource.Camera,
    quality: 25,
    saveToGallery: true,
  });
  const path = Capacitor.convertFileSrc(photo.path);

  let picture = await saveRawPictureToIndexedDB(path, organisationId, inspectionId);
  if (section) {
    picture.sectionId = section.name;
    picture.sectionName = section.name;
    picture.imageTag = section.imageTag;
  }
  return picture;
}

async function fetchImageBlob(imagePath: string): Promise<Blob> {
  try {
    const base64Response = await fetch(imagePath);
    const blob = await base64Response.blob();
    return blob;
  } catch (e) {
    console.error('Unable to fetch data', e);
    throw e;
  }
}

export async function saveRawPictureToIndexedDB(
  imagePath: string,
  organisationId: string,
  assessmentId: string
): Promise<Picture> {
  const blob = await fetchImageBlob(imagePath);

  if (blob.size === 0 || blob.type === 'text/plain') {
    throw new Error(
      'The camera was unable to take the picture, please try again.\n[ERROR CODE: 0 byte image]'
    );
  }

  const imageId = `${organisationId}/${assessmentId}/${uuid4()}`;

  let rawPicture: RawPicture = { content: blob, id: imageId, isUploading: true };

  try {
    await set(rawPicture.id, rawPicture, imageStore);

    if (navigator.onLine) {
      imagesStateObservable.next({
        images: imagesStateObservable.getValue().images.concat([rawPicture.id]),
        syncing: true,
      });
      uploadSingleImage(rawPicture).catch(async (e) => {
        toastError(`<b>ERROR UPLOADING IMAGE</b><br/><br/>` + mapUploadErrorReason(e));
        let images = await dirtyImages();
        imagesStateObservable.next({
          images: images.map((i) => i.id),
          syncing: false,
        });
      });
    } else {
      imagesStateObservable.next({
        images: imagesStateObservable.getValue().images.concat([rawPicture.id]),
        syncing: false,
      });
    }

    return {
      id: imageId,
      inputIds: [],
      sectionId: undefined,
      sectionName: undefined,
    };
  } catch (e) {
    console.error('failed to save image id to indexedDB', e);
    await set(rawPicture.id, { ...rawPicture, isUploading: false }, imageStore);
    throw e;
  }
}

export async function getPictureRaw(
  id: string,
  location?: string
): Promise<RawPicture> {
  if (!id) {
    return;
  }

  try {
    let url;

    // try to get from memory map urlMap
    if (urlMap[location + id]) {
      url = urlMap[location + id];
    } else {
      // look at the indexDB Store
      let rawPicture: RawPicture = await get(id, imageStore);
      if (rawPicture && rawPicture.content) {
        rawPicture.content = await BlobToBase64(rawPicture.content);
        rawPicture.__dirty = true;
        return rawPicture;
      }

      // fetch from firestore
      if (id.includes(INSPECTION_IMAGES_STORAGE_BASE_PATH)) {
        url = await storage.ref().child(id).getDownloadURL();
      } else {
        if (location != null) {
          url = await storage
            .ref()
            .child(location + id)
            .getDownloadURL();
        } else {
          let lastIndex = id.lastIndexOf('/');
          if (lastIndex >= 0) {
            const replacement = '/thumbnails/';
            const replaced =
              id.substring(0, lastIndex) +
              replacement +
              id.substring(lastIndex + 1) +
              '_400x400';
            url = await storage
              .ref()
              .child(INSPECTION_IMAGES_STORAGE_BASE_PATH + '/' + replaced)
              .getDownloadURL();
          } else {
            url = await storage
              .ref()
              .child(INSPECTION_IMAGES_STORAGE_BASE_PATH + '/' + id)
              .getDownloadURL();
          }
        }
      }
      // update memory map
      urlMap[location + id] = url;
    }

    return { content: url, id: id, __dirty: false, isUploading: false };
  } catch (err) {
    // console.log("Error while loading: ", err);
    return { id: id, __error: true, __dirty: false, isUploading: false } as RawPicture;
  }
}

async function dirtyImages(): Promise<RawPicture[]> {
  let keyPairs: RawPicture[] = [];
  let imagesToUpload: RawPicture[] = [];
  try {
    // get all values
    keyPairs = await values(imageStore);
    imagesToUpload = keyPairs.filter((o) => o.content?.size > 0);
  } catch (e) {
    console.error('error on dirtyImages', e);
  } finally {
    return imagesToUpload;
  }
}

export async function resetIsUploadingIndexedDB() {
  uploadingMap = [];
  return;
}
