import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from '@ionic/react';
import { sadOutline, closeCircleOutline } from 'ionicons/icons';
import React from 'react';
import './CardOrder.scss';
import { formatDate } from './HelperUtils';
import { Claim, LotPopoverModel, LotPosition, Order, OrderType } from './Model';
import { userHasPermission } from './PermissionsService';
import { claimColRef } from './DataStorage';
import { firestore } from './ConfigFirebase';
import { toastController } from '@ionic/core';
import { presentStandardToast } from './HelperIonic';
import withContext, { ContextProps } from './HOC/withContext';

interface Props {
  order?: Order;
  loading?: boolean;
  showLink?: boolean;
  highlightLotId?: string;
  searchString?: string;
  routerLink?: string;
  onClick?: any;
  hideDone?: boolean;
  showDate?: boolean;
  showLotId?: boolean;
  claim: Claim;
}

interface State {
  showOrders: boolean;
  currPosition: LotPosition;
  lotOptionsPopover?: LotPopoverModel;
}

class CardClaim extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showOrders: true,
      currPosition: undefined,
      lotOptionsPopover: { visible: false },
    };
  }

  orderIcon() {
    return <IonIcon style={{ pointerEvents: 'none' }} icon={sadOutline} />;
  }

  renderedTitle = (): string => {
    const { order, claim } = this.props;
    const suffixMap: { [key in OrderType]?: string } = {
      BUY: 'SUPPLIER ',
      SELL: 'CUSTOMER ',
    };
    const suffix = suffixMap[claim.orderType ?? order?.type ?? ''] ?? '';
    return `${suffix}CLAIM`;
  };

  async deleteClaim() {
    if (window.confirm('Are you sure you want to delete this claim?')) {
      try {
        await claimColRef(firestore, this.props.profile.organisationId)
          .doc(this.props.claim.claimId)
          .delete();
        presentStandardToast(
          toastController,
          `Claim deleted successfully`,
          undefined,
          'success'
        );
      } catch (error) {
        presentStandardToast(
          toastController,
          `Error deleting claim. Please contact support`
        );
        console.log(error);
      }
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <IonCard className="card-order skeleton">
          <IonCardHeader>
            <IonCardTitle>
              <IonSkeletonText animated />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {[0].map((idx) => (
              <IonItem key={idx} style={{ border: 0 }}>
                <IonLabel>
                  <IonSkeletonText animated />
                </IonLabel>
              </IonItem>
            ))}
          </IonCardContent>
        </IonCard>
      );
    }

    let { order, claim } = this.props;

    let onClick = this.props.onClick
      ? { onClick: () => this.props.onClick(order) }
      : null;

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    // today.setDate(today.getDate()+1)

    console.log('CLAIM -->', claim);

    const allowDeleteClaim =
      this.props.organisation?.settings.allowManagingLots &&
      claim.isAGMaster &&
      userHasPermission(this.props.profile, 'WRITE', 'LOT');

    return (
      <IonCard
        key={claim.orderId}
        className={'card-order claim score-2 completed'}
        // {...this.getLink()}
        {...onClick}
      >
        <IonCardHeader>
          <IonCardTitle>
            {allowDeleteClaim && (
              <IonIcon
                icon={closeCircleOutline}
                color="medium"
                className="caret"
                onClick={() => this.deleteClaim()}
              />
            )}

            <div className="order-id">
              {this.orderIcon()}
              <div>{this.renderedTitle()}</div>
            </div>
            <div className="order-id">{claim.orderId}</div>

            {/* <div className="order-info">
            <IonBadge color="medium">
              {formatDate(order.fulfilmentDate)}
            </IonBadge>
          </div> */}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {this.props.showDate && (
            <div>
              <IonBadge color="danger">{formatDate(claim.receivedDate)}</IonBadge>
            </div>
          )}
          {this.props.showLotId === false ? null : (
            <div>
              <b>{claim.position.lotId}</b>
            </div>
          )}
          <div>
            <b>Claimed:</b> {claim.amount.currency}{' '}
            {(claim.amount.quantity ?? 0).toFixed(2)}
          </div>
          {/* <div><b>Total price:</b> {claim.position.price?.currency} {claim.position.price?.quantity}</div> */}
        </IonCardContent>
      </IonCard>
    );
  }
}

export default withContext(CardClaim, ['organisation', 'profile']);
