import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  // IonCardHeader,
  // IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRange,
  IonRow,
  // IonSegment,
  // IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { chevronBack, chevronForward, happyOutline } from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router';
import { auth, firestore } from './ConfigFirebase';
import {
  checkInitContextPermDict,
  combinationFromExample,
  correctLabel,
  exampleFromCombination,
  fetchContextPermDict,
  fetchNewQuery,
  fetchNewSample,
  getCategorySliderRange,
  getClosestExample,
  getDefectsInfo,
  getGroupsFromSection,
  getInitialExample,
  getSeverityDict,
  renderGroupName,
  scoreMaps,
  setContextPermDict,
} from './DataScoring';
import { FirebaseSpecificationRepository } from './DataSpecification';
import withContext, { ContextProps } from './HOC/withContext';
import {
  ADLSScoringParams,
  AGScore,
  CombinationSample,
  Convergence,
  Example,
  MergedSchema,
  NextQuery,
  ScoringContext,
  SeverityDict,
} from './Model';
import { LotScoringSection, ScoringToSection, Section } from './ModelSpecification';
import './PageScoring.scss';
import i18n from './ServiceI18n';

export interface Props extends RouteComponentProps {}

export interface State {
  inited: boolean;
  processing: { status: boolean; message: string };

  specRepo: FirebaseSpecificationRepository;

  menuOption: string;

  sections: Section[];
  scoringToSections: ScoringToSection[];
  scorings: LotScoringSection[];

  currentSection: Section;
  currentGroupId: string;
  currentScoring: LotScoringSection;

  currentExample: Example;
  currentPermDict: ScoringContext;
  currentCatSliderRange: { min: AGScore; max: AGScore };
  currentCatSliderValue: AGScore;
  currentCorrectionValue: AGScore;
  currentConvergence: Convergence;
  currentConvergenceRatios: Convergence;
  currentGroupDistribution: Convergence;

  correctedPermDict: ScoringContext;
  impliedDeletion: string[];
  correctedConvergence: Convergence;
  correctedConvergenceRatios: Convergence;
  correctedGroupDistribution: Convergence;
  correctedShowPrediction: boolean;

  nextQuery: NextQuery;

  isCorrecting: boolean;
  showPrediction: boolean;
  showAdvancedConvergence: boolean;

  showCombinations: boolean | number;
  showExamples: boolean;
  showDeletionExamples: boolean;

  scoreMode: string;

  combinationPagination: number;
  examplesPagination: number;

  toast: { message: string; show: boolean; color: string };
  alert: { message: string; show: boolean; onAccept: any };
}

class PageScoring extends React.Component<Props & ContextProps, State> {
  // private menuOptions: string[] = ["View/edit schemas", "Group scoring"];
  private menuOptions: string[] = ['Group scoring'];

  private defaultToast = { message: '', show: false, color: 'primary' };
  private defaultAlert = { message: '', show: false, onAccept: (_) => {} };

  private contentRef: RefObject<HTMLIonContentElement> = React.createRef();
  private pageRef: RefObject<HTMLElement> = React.createRef();

  private orgId = this.props.profile.organisationId;

  private SCORING_CONTEXT_STORAGE_KEY = 'scoringContext';

  constructor(props) {
    super(props);

    this.state = {
      inited: false,
      processing: { status: false, message: undefined },

      sections: undefined,
      scoringToSections: undefined,
      scorings: undefined,

      specRepo: undefined,

      // menuOption: "View/edit schemas",
      menuOption: 'Group scoring',

      currentSection: undefined,
      currentGroupId: undefined,
      currentScoring: undefined,

      currentExample: undefined,
      currentPermDict: undefined,
      currentCatSliderRange: undefined,
      currentCatSliderValue: undefined,
      currentCorrectionValue: undefined,
      currentConvergence: undefined,
      currentConvergenceRatios: undefined,
      currentGroupDistribution: undefined,

      correctedPermDict: undefined,
      impliedDeletion: [],
      correctedConvergence: undefined,
      correctedGroupDistribution: undefined,
      correctedConvergenceRatios: undefined,
      showPrediction: false,
      showExamples: false,
      showDeletionExamples: false,
      showAdvancedConvergence: false,
      correctedShowPrediction: false,

      showCombinations: false,

      combinationPagination: 0,
      examplesPagination: 0,

      nextQuery: undefined,

      isCorrecting: false,

      scoreMode: 'numbers',

      toast: this.defaultToast,
      alert: this.defaultAlert,
    };
  }

  /********************/
  // React life cycle
  /********************/

  async componentDidMount() {
    let scoringContext = localStorage.getItem(this.SCORING_CONTEXT_STORAGE_KEY);
    const specRepo = new FirebaseSpecificationRepository(
      firestore,
      this.props.profile.organisationId
    );

    const [scoringToSections, scorings, sections] =
      await specRepo.fetchScoringInterfaceData();

    if (sections.length === 0) return;

    // if (schemas.length > 0) {
    let currentSection: Section;
    let currentGroupId: string;

    if (scoringContext !== null) {
      const { sectionId, groupId } = JSON.parse(scoringContext);
      currentSection = sections.find((s) => s.id === sectionId);
      currentGroupId = groupId;
    } else {
      currentSection = sections[0];
      currentGroupId = getGroupsFromSection(currentSection)?.[0] ?? 'group_condition';
    }

    const currentScoringId = scoringToSections.find(
      (s) => s.sectionId === currentSection.id
    )?.scoringId;
    const currentScoring = scorings.find((sc) => sc.id === currentScoringId);

    console.log(
      'Current scoring:',
      currentScoringId,
      'Current section:',
      currentSection.id
    );

    localStorage.removeItem(this.SCORING_CONTEXT_STORAGE_KEY);

    // extract group from currentSchema and set an initial assignment with all values set to 4
    const currentExample: Example = getInitialExample(currentSection, currentGroupId);

    // get perm dict from db and get convergence
    const currentPermDict = await fetchContextPermDict(
      firestore,
      auth,
      currentSection,
      currentGroupId,
      this.orgId,
      this.props.organisation?.settings,
      currentScoring
    );
    const {
      convergence: currentConvergence,
      convergence_ratios: currentConvergenceRatios,
      next_query: nextQuery,
      show_prediction: showPrediction,
      group_distribution: currentGroupDistribution,
    } = await fetchNewQuery(auth, currentPermDict);

    const combination = combinationFromExample(
      currentExample,
      getSeverityDict(currentSection, currentGroupId),
      this.props.organisation?.settings,
      currentGroupId
    );
    const currentCatSliderRange = getCategorySliderRange(currentPermDict, combination);

    this.setState({
      sections,
      scorings,
      scoringToSections,

      currentSection,
      currentScoring,
      currentGroupId,

      currentExample,
      currentPermDict,
      currentCatSliderRange,
      currentGroupDistribution,
      showPrediction,
      currentCatSliderValue: currentCatSliderRange.max,
      currentConvergence,
      currentConvergenceRatios,
      nextQuery: nextQuery as NextQuery,
      inited: true,
    });
  }

  /********************/
  // UI interaction
  /********************/

  async onSaveSample(score: AGScore) {
    const {
      currentExample,
      currentPermDict,
      currentSection,
      currentGroupId,
      currentScoring,
    } = this.state;
    this.setState({ processing: { status: true, message: undefined } });

    const combination = combinationFromExample(
      currentExample,
      getSeverityDict(currentSection, currentGroupId),
      this.props.organisation?.settings,
      currentGroupId
    );
    const combinationSample: CombinationSample = { combination, score };

    const {
      context_permdict,
      convergence,
      convergence_ratios,
      next_query,
      show_prediction,
      group_distribution,
    } = await fetchNewSample(auth, currentPermDict, combinationSample);

    // add the example to the permdict
    const newPermDict = {
      ...context_permdict,
      [combination]: { ...context_permdict[combination], example: currentExample },
    };

    const currentCatSliderRange = getCategorySliderRange(newPermDict, combination);

    await setContextPermDict(
      firestore,
      newPermDict,
      currentScoring,
      currentGroupId,
      this.orgId
    );

    this.setState({
      processing: { status: false, message: undefined },
      currentPermDict: newPermDict,
      currentConvergence: convergence,
      currentConvergenceRatios: convergence_ratios,
      currentGroupDistribution: group_distribution,
      currentCatSliderRange,
      currentCatSliderValue: currentCatSliderRange.max,
      nextQuery: next_query as NextQuery,
      showPrediction: show_prediction,
    });
  }

  async onNextQuery() {
    const {
      currentPermDict,
      currentSection,
      currentGroupId,
      nextQuery,
      showPrediction,
      currentScoring: currentScoringId,
    } = this.state;
    this.setState({ processing: { status: true, message: undefined } });
    //const { convergence: currentConvergence, next_query: nextQuery, group_distribution: currentGroupDistribution, show_prediction: showPrediction } = await fetchNewQuery(currentPermDict);

    const { example, isValidExample } = exampleFromCombination(
      nextQuery.combination,
      getSeverityDict(currentSection, currentGroupId),
      currentSection,
      this.props.organisation?.settings,
      currentGroupId
    );

    const currentCatSliderRange = getCategorySliderRange(
      currentPermDict,
      nextQuery.combination
    );
    const currentPerm = currentPermDict[nextQuery.combination];
    let currentScore: any = showPrediction
      ? currentPerm?.prediction
      : currentCatSliderRange.max;

    this.setState(
      {
        processing: { status: false, message: undefined },
        currentExample: example,
        //currentConvergence,
        //currentGroupDistribution,
        nextQuery,
        currentCatSliderRange,
        currentCatSliderValue: currentScore,
      },
      async () => {
        if (!isValidExample) {
          const closestExample = getClosestExample(
            example,
            currentSection,
            currentGroupId,
            this.props.organisation?.settings
          );

          const closestCombination = combinationFromExample(
            closestExample,
            getSeverityDict(currentSection, currentGroupId),
            this.props.organisation?.settings,
            currentGroupId
          );
          const invalidCombination = combinationFromExample(
            example,
            getSeverityDict(currentSection, currentGroupId),
            this.props.organisation?.settings,
            currentGroupId
          );
          const deduction = currentPermDict[closestCombination].deduction;

          if (typeof deduction === 'number') {
            this.setState({ processing: { status: true, message: undefined } });
            const combinationSample: CombinationSample = {
              combination: invalidCombination,
              score: deduction as AGScore,
            };
            const {
              context_permdict,
              convergence,
              convergence_ratios,
              next_query,
              show_prediction,
              group_distribution,
            } = await fetchNewSample(auth, currentPermDict, combinationSample);

            // save updated permdict into db
            await setContextPermDict(
              firestore,
              context_permdict as ScoringContext,
              currentScoringId,
              currentGroupId,
              this.orgId
            );

            this.setState(
              {
                processing: { status: false, message: undefined },
                currentPermDict: context_permdict as ScoringContext,
                currentConvergenceRatios: convergence_ratios,
                currentConvergence: convergence,
                nextQuery: next_query as NextQuery,
                showPrediction: show_prediction,
                currentGroupDistribution: group_distribution,
              },
              () => {
                if ((next_query as NextQuery).combination !== null) this.onNextQuery();
              }
            );
          }
        }
      }
    );
  }

  async onSetExample(example: Example) {
    const { currentPermDict, currentSection, currentGroupId, showPrediction } =
      this.state;
    this.setState({ processing: { status: true, message: undefined } });

    const combination = combinationFromExample(
      example,
      getSeverityDict(currentSection, currentGroupId),
      this.props.organisation?.settings,
      currentGroupId
    );
    const currentCatSliderRange = getCategorySliderRange(currentPermDict, combination);

    const currentPerm = currentPermDict[combination];

    let currentScore: any = showPrediction
      ? currentPerm?.prediction
      : currentCatSliderRange.max;

    this.setState({
      processing: { status: false, message: undefined },
      currentExample: example,
      currentCatSliderRange,
      currentCatSliderValue: currentScore,
      showExamples: false,
    });
  }

  onAssignmentChange(
    newDefectScore: AGScore,
    defectId: string,
    currentSection: Section,
    currentGroupId: string
  ) {
    const { currentExample, currentPermDict, showPrediction } = this.state;
    const newExample: Example = cloneDeep(currentExample);
    newExample[defectId] = newDefectScore;

    const QuestionSeverity: SeverityDict = getSeverityDict(
      currentSection,
      currentGroupId
    );
    const combination = combinationFromExample(
      newExample,
      QuestionSeverity,
      this.props.organisation?.settings,
      currentGroupId
    );

    const newCatSlider = getCategorySliderRange(currentPermDict, combination);

    const currentPerm = currentPermDict[combination];
    let currentScore: any = showPrediction ? currentPerm?.prediction : newCatSlider.max;

    this.setState({
      currentExample: newExample,
      currentCatSliderRange: newCatSlider,
      currentCatSliderValue: currentScore,
    });
  }

  async onCheckConflicts(
    permDict: ScoringContext,
    example: Example,
    score: AGScore,
    section: Section,
    groupId: string
  ) {
    this.setState({ processing: { status: true, message: undefined } });
    const combinationSample: CombinationSample = {
      combination: combinationFromExample(
        example,
        getSeverityDict(section, groupId),
        this.props.organisation?.settings,
        groupId
      ),
      score,
    };
    const {
      context_permdict: correctedPermDict,
      implied_deletion: impliedDeletion,
      convergence: correctedConvergence,
      show_prediction: correctedShowPrediction,
      group_distribution: correctedGroupDistribution,
      convergence_ratios: correctedConvergenceRatios,
    } = await correctLabel(auth, permDict, combinationSample);

    this.setState({
      correctedPermDict: correctedPermDict as ScoringContext,
      impliedDeletion,
      correctedConvergence,
      correctedShowPrediction,
      correctedGroupDistribution,
      correctedConvergenceRatios,
      processing: { status: false, message: undefined },
    });
  }

  async onCorrectionConfirm() {
    const {
      correctedPermDict,
      currentExample,
      currentScoring: currentScoringId,
      currentSection,
      currentGroupId,
      correctedConvergence,
      correctedConvergenceRatios,
      correctedShowPrediction,
      correctedGroupDistribution,
    } = this.state;

    this.setState({
      processing: { status: true, message: undefined },
      showDeletionExamples: false,
    });

    const currentCatSliderRange = getCategorySliderRange(
      correctedPermDict,
      combinationFromExample(
        currentExample,
        getSeverityDict(currentSection, currentGroupId),
        this.props.organisation?.settings,
        currentGroupId
      )
    );

    // save updated permdict into db
    await setContextPermDict(
      firestore,
      correctedPermDict,
      currentScoringId,
      currentGroupId,
      this.orgId
    );

    this.setState({
      processing: { status: false, message: undefined },
      isCorrecting: false,
      currentPermDict: correctedPermDict,
      currentConvergence: correctedConvergence,
      currentConvergenceRatios: correctedConvergenceRatios,
      currentGroupDistribution: correctedGroupDistribution,
      correctedPermDict: undefined,
      correctedConvergence: undefined,
      correctedGroupDistribution: undefined,
      showPrediction: correctedShowPrediction,
      correctedShowPrediction: false,
      currentCatSliderRange,
      currentCatSliderValue: currentCatSliderRange.max,
    });
  }

  onCorrectionCancel() {
    this.setState({
      correctedPermDict: undefined,
      correctedConvergence: undefined,
      correctedShowPrediction: false,
      correctedGroupDistribution: undefined,
      showDeletionExamples: false,
    });
  }

  onToggleScoreMode() {
    const { scoreMode } = this.state;
    this.setState({ scoreMode: scoreMode === 'letters' ? 'numbers' : 'letters' });
  }

  async onSectionChange(sectionId: string) {
    // const schemas: MergedSchema[] = filterSchemasByScoringType(this.props.applicationContext.schemas, 'ADLS');

    const currentSection = this.state.sections.find((s) => s.id === sectionId);
    const currentGroupId: string = getGroupsFromSection(currentSection)[0];
    const currentExample: Example = getInitialExample(currentSection, currentGroupId);
    const currentScoringId = this.state.scoringToSections.find(
      (s) => s.sectionId === currentSection.id
    )?.scoringId;
    const currentScoring = this.state.scorings.find((s) => s.id === currentScoringId);

    console.log(
      'Current scoring:',
      currentScoringId,
      'Current section:',
      currentSection.id
    );

    this.setState({ processing: { status: true, message: undefined }, currentSection });

    // get perm dict from db and get convergence
    const currentPermDict = await fetchContextPermDict(
      firestore,
      auth,
      currentSection,
      currentGroupId,
      this.orgId,
      this.props.organisation?.settings,
      currentScoring
    );
    const {
      convergence: currentConvergence,
      next_query: nextQuery,
      show_prediction: showPrediction,
      group_distribution: currentGroupDistribution,
      convergence_ratios: currentConvergenceRatios,
    } = await fetchNewQuery(auth, currentPermDict);

    const combination = combinationFromExample(
      currentExample,
      getSeverityDict(currentSection, currentGroupId),
      this.props.organisation?.settings,
      currentGroupId
    );
    const currentCatSliderRange = getCategorySliderRange(currentPermDict, combination);

    this.setState({
      currentGroupId,
      currentExample,
      currentScoring,
      currentPermDict,
      currentCatSliderRange,
      currentGroupDistribution,
      showPrediction,
      showAdvancedConvergence: false,
      showExamples: false,
      currentCatSliderValue: currentCatSliderRange.max,
      currentConvergence,
      currentConvergenceRatios,
      nextQuery: nextQuery as NextQuery,
      processing: { status: false, message: undefined },
    });
  }

  async onGroupChange(groupId: string, section: Section) {
    this.setState({
      processing: { status: true, message: undefined },
      currentGroupId: groupId,
    });

    const currentPermDict = await fetchContextPermDict(
      firestore,
      auth,
      section,
      groupId,
      this.orgId,
      this.props.organisation?.settings,
      this.state.currentScoring
    );
    const currentExample = getInitialExample(section, groupId);

    const combination = combinationFromExample(
      currentExample,
      getSeverityDict(section, groupId),
      this.props.organisation?.settings,
      groupId
    );
    const currentCatSliderRange = getCategorySliderRange(currentPermDict, combination);

    const currentScoringId = this.state.scoringToSections.find(
      (s) => s.sectionId === this.state.currentSection.id
    )?.scoringId;
    const currentScoring = this.state.scorings.find((s) => s.id === currentScoringId);

    console.log(
      'Current scoring:',
      currentScoringId,
      'Current section:',
      this.state.currentSection.id
    );

    const {
      convergence: currentConvergence,
      next_query: nextQuery,
      show_prediction: showPrediction,
      group_distribution: currentGroupDistribution,
      convergence_ratios: currentConvergenceRatios,
    } = await fetchNewQuery(auth, currentPermDict);

    this.setState({
      currentScoring,
      currentExample,
      currentPermDict,
      showAdvancedConvergence: false,
      showExamples: false,
      currentCatSliderRange,
      currentCatSliderValue: currentCatSliderRange.max,
      processing: { status: false, message: undefined },
      currentConvergence,
      currentConvergenceRatios,
      nextQuery: nextQuery as NextQuery,
      showPrediction,
      currentGroupDistribution,
    });
  }

  async onScoringSchemaChange(schemaId: string) {
    // // this function runs from ViewSpecBuilderEdit. It changes the schema in PageScoring when the schema is changed there
    // await this.onSchemaChange(schemaId);
    // const { currentGroupId } = this.state;
    // const schemas: MergedSchema[] = filterSchemasByScoringType(this.props.applicationContext.schemas, 'ADLS');;
    // const currentSchema = schemas.find(schema => schema.id === schemaId);
    // const otherGroupId = getGroupsFromSection(currentSchema).find(g => g !== currentGroupId);
    // await this.onGroupChange(otherGroupId, currentSchema);
  }

  onCatSliderChange(currentCatSliderValue: AGScore) {
    this.setState({ currentCatSliderValue });
  }

  onMenuOptionChange(menuOption) {
    this.setState({ menuOption });
    // Hack to reload everything
    // this.componentDidMount()
  }

  getRangeColor(score: AGScore): string {
    const colorMap = {
      1: 'danger',
      2: 'danger',
      3: 'warning',
      4: 'primary',
    };
    return colorMap[score];
  }

  showToast(message: string, color?: string) {
    this.setState({ toast: { show: true, message, color: color || 'primary' } });
  }

  onShowCombinations(score: AGScore) {
    this.setState({ showCombinations: score });
  }

  onResetScores() {
    const { currentSection, currentGroupId } = this.state;
    const alert = {
      show: true,
      message: `All examples given for section <strong>${currentSection.name}</strong> and group <strong>${currentGroupId}</strong> will be deleted, this action cannot be undone. Are you sure you want to proceed?`,
      onAccept: async (_) => await this.onAcceptResetScores(),
    };
    this.setState({ alert });
  }

  // async onCopySchemas() {
  //   const docs = await firestore.collection('organisation').doc(this.orgId).collection('specifications').doc('schemas').collection('newSchemas').get()

  //   for (const doc of docs.docs) {
  //     await setSchema(doc.data() as MergedSchema, this.orgId, true);
  //   }
  // }

  onSetSchemaScoring() {
    const { currentSection } = this.state;
    const message = true
      ? `This will update the scoring for the current active schema <strong>${currentSection.name}</strong> (product <strong>${currentSection.criteria.productIds}</strong>) in the QC tool. Please note that if the scoring of Condition or Appearance is not set or partially set, the scores for that group will be missing or inaccurate. Are you sure you want to proceed?`
      : `This will set <strong>${currentSection.name}</strong> as the active schema for product <strong>${currentSection.criteria.productIds}</strong> in the QC tool. Please note that if the scoring of Condition or Appearance is not set or partially set, the scores for that group will be missing or inaccurate. Are you sure you want to proceed?`;

    const alert = {
      show: true,
      message,
      onAccept: async (_) => await this.onAcceptSetSchemaScoring(),
    };
    this.setState({ alert });
  }

  async onAcceptSetSchemaScoring() {
    // const { currentSchema } = this.state;
    this.setState({
      processing: {
        status: true,
        message: `Updating ${this.state.currentSection.name} scoring...`,
      },
    });

    // We don't set it as active from here anymore
    await this.setScoring();

    // Update schemas in the app's state
    const repo = new FirebaseSpecificationRepository(
      firestore,
      this.props.profile.organisationId
    );
    const scorings = await repo.getScorings();

    this.props.setScorings(scorings);

    this.setState({ processing: { status: false, message: undefined } });

    // we reload so the app loads the updated schema from the db on refresh
    this.saveContextLocallyAndRefresh();
  }

  async saveContextLocallyAndRefresh() {
    const { currentSection, currentGroupId } = this.state;
    localStorage.setItem(
      this.SCORING_CONTEXT_STORAGE_KEY,
      JSON.stringify({ sectionId: currentSection.id, groupId: currentGroupId })
    );
    this.setState({ inited: false });
    await this.componentDidMount();
    // window.location.reload();
  }

  async setScoring() {
    const specRepo = new FirebaseSpecificationRepository(firestore, this.orgId);
    await specRepo.updateADLSScoring(this.state.currentScoring.id);
  }

  async recoverScoringContextFromScoring(schema: MergedSchema) {
    // RF_TODO: this only applies to ADSL! Make generic?
    // for (const groupId of getGroupsFromSchema(schema)) {
    //   const groupScoring = (schema.lotScoring[groupId].params as ADLSScoringParams).groupScoreMap;
    //   const permDict = scoringToScoringContext(groupScoring, schema, groupId, this.props.applicationContext.organisation?.settings);
    //   // console.log(schema.id, groupId)
    //   await setContextPermDict(permDict, schema, groupId, this.orgId);
    // }
  }

  async onAcceptResetScores() {
    const { currentSection, currentGroupId, currentScoring } = this.state;
    this.setState({ processing: { status: true, message: undefined } });
    await checkInitContextPermDict(
      firestore,
      auth,
      currentSection,
      currentGroupId,
      currentScoring,
      this.orgId,
      this.props.organisation?.settings,
      true,
      this.props.profile.id
    );
    this.setState({ processing: { status: false, message: undefined } });
    // we reload so the app loads the updated schema from the db on refresh
    this.saveContextLocallyAndRefresh();
  }

  /********************/
  // Functions for state management in child components
  /********************/

  setProcessing(processing: { status: boolean; message: string }) {
    this.setState({ processing });
  }

  setAlert(alert: { message: string; show: boolean; onAccept: any }) {
    this.setState({ alert });
  }

  setToast(toast: { message: string; show: boolean; color: string }) {
    this.setState({ toast });
  }

  /********************/
  // Render methods
  /********************/

  render() {
    const {
      inited,
      toast,
      processing,
      showCombinations,
      currentSection,
      showExamples,
      currentPermDict,
      showDeletionExamples,
      impliedDeletion,
      alert,
      menuOption,
    } = this.state;

    // RF_TODO: this only applies to ADSL! Make generic?
    // const deployedGroupScores: string[] = Object.keys(currentSchema?.lotScoring ?? {});

    return (
      <IonPage ref={this.pageRef} className="ion-page page-scoring">
        <IonLoading
          isOpen={processing.status}
          message={
            processing.message
              ? processing.message
              : `Loading <strong>${
                  currentSection?.name || ''
                }</strong> schema/scoring...`
          }
        />
        <IonAlert
          isOpen={alert.show}
          onDidDismiss={() => this.setState({ alert: this.defaultAlert })}
          header={'Warning'}
          cssClass="alert-reset-scores"
          message={alert.message}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: (_) => this.setState({ alert: this.defaultAlert }),
            },
            {
              text: 'Proceed',
              cssClass: 'accept-button-alert',
              handler: alert.onAccept,
            },
          ]}
        />
        <IonToast
          isOpen={toast.show}
          message={toast.message}
          duration={4000}
          onDidDismiss={() => this.setState({ toast: this.defaultToast })}
          color={toast.color}
        />
        {this.renderHeader()}
        {inited ? (
          <IonContent ref={this.contentRef}>
            <IonCard className="">
              <IonCardContent>
                {menuOption === 'Group scoring' && (
                  <>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="8">
                          {this.renderSchemaSelector()}
                          {this.renderGroupSelector()}
                        </IonCol>
                        <IonCol size="4">
                          {/* <IonRow><IonCol><IonItem><small>This schema is currently <strong><IonText color={currentSchema.active ? 'primary' : 'danger'}>{currentSchema.active ? 'ACTIVE' : 'NOT ACTIVE'}</IonText></strong> in the QC tool</small></IonItem></IonCol></IonRow> */}
                          {/* {deployedGroupScores.length > 0 ?
                      <IonItem>
                        <small>
                          The following group scorings are currently deployed in the QC tool for this schema: <br />
                          <IonText color="dark">
                            <strong>{deployedGroupScores.map(s => renderGroupName(s)).join(' and ')}</strong>
                          </IonText>
                        </small>
                      </IonItem>
                      :
                      <IonItem><small><strong><IonText color="danger">WARNING: </IonText></strong>No group scorings are currently deployed for this schema in the QC tool</small></IonItem>
                    } */}

                          <IonButton
                            disabled={false}
                            color="secondary"
                            expand="block"
                            onClick={(_) => this.onSetSchemaScoring()}
                          >
                            {'Update section scoring'}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                    {this.renderDefectsPanel()}
                    <IonGrid>
                      {showCombinations
                        ? this.renderCombinations()
                        : showExamples
                        ? this.renderExamples(
                            showExamples,
                            Object.keys(currentPermDict)
                          )
                        : this.renderHelpMeLearn()}
                      {showDeletionExamples &&
                        this.renderExamples(
                          showDeletionExamples,
                          impliedDeletion,
                          false
                        )}
                    </IonGrid>
                  </>
                )}

                {/* {menuOption === "View/edit schemas" && <ViewSpecBuilderEdit
          {...this.props}
          inputList={this.props.applicationContext.inputList}
          processing={processing}
          organisationId={this.orgId}
          setProcessing={this.setProcessing.bind(this)}
          setToast={this.setToast.bind(this)}
          profile={this.props.profile}
          setApplicationContext={this.props.setApplicationContext}
          applicationContext={this.props.applicationContext}
          onScoringSchemaChange={this.onScoringSchemaChange.bind(this)}
          scoringCurrentSchemaId={currentSchema.id}
        />} */}

                {menuOption === 'Create new schema' && <></>}
              </IonCardContent>
            </IonCard>
          </IonContent>
        ) : (
          <IonLoading isOpen />
        )}
      </IonPage>
    );
  }

  renderCombinations() {
    const {
      showCombinations,
      currentPermDict,
      currentSection,
      currentGroupId,
      combinationPagination,
    } = this.state;

    if (!showCombinations) {
      return '';
    }

    const colSizes = ['4', '8'];
    const paginationSize = 10;
    const comboTitleArray = [
      'C1',
      'C2',
      'C3',
      'C4',
      'M1',
      'M2',
      'M3',
      'M4',
      'm1',
      'm2',
      'm3',
      'm4',
    ];

    let combinationsWithScore: string[] = Object.keys(currentPermDict).filter(
      (comb) =>
        currentPermDict[comb]?.deduction &&
        currentPermDict[comb].deduction === showCombinations
    );
    const totalPages = Math.ceil(combinationsWithScore.length / paginationSize);

    // pagination
    combinationsWithScore = combinationsWithScore.slice(
      paginationSize * combinationPagination,
      paginationSize * (1 + combinationPagination)
    );

    return (
      <>
        <IonRow>
          <IonToolbar>
            <IonLabel>
              Combinations with score{' '}
              <strong>
                <IonText color={this.getRangeColor(showCombinations as AGScore)}>
                  {this.renderScore(showCombinations)}
                </IonText>
              </strong>
            </IonLabel>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                onClick={(_) =>
                  this.setState({ showCombinations: false, combinationPagination: 0 })
                }
                size="small"
                color="primary"
              >
                Back
              </IonButton>
              {combinationsWithScore.length > 0 && (
                <>
                  <IonButton
                    disabled={combinationPagination === 0}
                    onClick={(_) =>
                      this.setState({
                        combinationPagination: combinationPagination - 1,
                      })
                    }
                    size="small"
                  >
                    <IonIcon icon={chevronBack} />
                  </IonButton>
                  <IonButton disabled size="small">
                    Page {combinationPagination + 1} of {totalPages}
                  </IonButton>
                  <IonButton
                    disabled={combinationPagination === totalPages - 1}
                    onClick={(_) =>
                      this.setState({
                        combinationPagination: combinationPagination + 1,
                      })
                    }
                    size="small"
                  >
                    <IonIcon icon={chevronForward} />
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonToolbar>

          <IonCol></IonCol>
        </IonRow>

        {combinationsWithScore.length > 0 ? (
          <>
            <IonRow>
              <IonCol size={colSizes[0]}>
                <IonRow>
                  {comboTitleArray.map((c) => (
                    <IonCol key={c} size="1">
                      <IonText color="dark">{c}</IonText>
                    </IonCol>
                  ))}
                </IonRow>
              </IonCol>
              <IonCol size={colSizes[1]}>
                <IonRow>
                  <IonCol>
                    <IonText color="dark">Example</IonText>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            {combinationsWithScore.map((c) => {
              const { example } = exampleFromCombination(
                c,
                getSeverityDict(currentSection, currentGroupId),
                currentSection,
                this.props.organisation?.settings,
                currentGroupId
              );

              return (
                <IonRow key={c}>
                  <IonCol size={colSizes[0]}>
                    <IonRow>
                      {c.split('').map((v, idx) => (
                        <IonCol key={idx} size="1">
                          {v}
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonCol>
                  <IonCol size={colSizes[1]}>{this.renderExample(example)}</IonCol>
                </IonRow>
              );
            })}
          </>
        ) : (
          'No combinations so far!'
        )}
      </>
    );
  }

  renderThresholds(thresholds: string[]) {
    return thresholds ? thresholds.join(' / ') : '';
  }

  renderDefectsPanel() {
    const {
      currentSection,
      currentGroupId,
      currentExample,
      processing,
      isCorrecting,
      showCombinations,
      showExamples,
    } = this.state;
    const defectsInfo = getDefectsInfo(
      currentSection,
      currentGroupId,
      this.props.organisation?.settings
    );

    const colSizes = ['7.5', '', '3', '1.5'];

    return (
      <>
        <h2>Example assignment</h2>
        <IonGrid>
          <IonRow>
            <IonCol size={colSizes[0]} className="col-header">
              Defects
            </IonCol>
            <IonCol size={colSizes[2]} className="col-header">
              Thresholds
            </IonCol>
            <IonCol size={colSizes[3]} className="col-header">
              Severity
            </IonCol>
          </IonRow>
          {defectsInfo.map((defectInfo, idx) => {
            const { inputSpace, id, name, thresholds, severity } = defectInfo;
            const min = Math.min(...inputSpace);
            const max = Math.max(...inputSpace);
            const defectScore = currentExample[id];

            return (
              <IonRow key={id}>
                <IonCol size={colSizes[0]}>
                  <IonItem>
                    <IonLabel className="label-defect">{name}</IonLabel>
                    <IonRange
                      className="slider-with-pin"
                      value={defectScore}
                      disabled={
                        processing.status ||
                        isCorrecting ||
                        !!showCombinations ||
                        showExamples
                      }
                      pin
                      min={min}
                      max={max}
                      step={1}
                      snaps={true}
                      ticks={true}
                      color={this.getRangeColor(defectScore)}
                      onIonChange={(evt) =>
                        this.onAssignmentChange(
                          evt.detail.value as AGScore,
                          id,
                          currentSection,
                          currentGroupId
                        )
                      }
                      debounce={100}
                    />
                  </IonItem>
                </IonCol>

                <IonCol size={colSizes[2]} className={`small-font`}>
                  <IonItem className={`range-${defectScore}`}>
                    <strong>{this.renderThresholds(thresholds[defectScore])}</strong>
                  </IonItem>
                </IonCol>

                <IonCol size={colSizes[3]} className={`small-font`}>
                  <IonItem>{severity}</IonItem>
                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid>
      </>
    );
  }

  renderConvergence(
    convergence: Convergence,
    showAdvanced: boolean,
    groupDistribution: Convergence,
    convergenceRatios: Convergence
  ) {
    const { currentPermDict, currentScoring, currentGroupId } = this.state;

    if (!convergence) {
      return '';
    }

    // calculations for convergence
    const convergenceKeys: (AGScore | 0)[] = Object.keys(convergence).map(
      (x) => +x as AGScore | 0
    );
    const maxKey = Math.max(...convergenceKeys);

    const toPercentage = (value: number) =>
      ((value * 100) / totalCombinations).toFixed(2);

    let totalCombinations = 0;
    convergenceKeys.forEach((key) => (totalCombinations += convergence[key]));

    const hasConflicts = convergence[0] > 0;
    const convergenceText0 = hasConflicts ? 'Conflicts found' : 'No conflicts found';

    const convergenceText1 = `${toPercentage(convergence[1])}% of scores are set`;

    let notSet = 0;
    convergenceKeys
      .filter((k) => k < maxKey && k > 1)
      .forEach((k) => (notSet += convergence[k]));
    const convergenceTextMiddle = `${toPercentage(notSet)}% of scores are ambiguous`;

    const convergenceTextEnd = `${toPercentage(
      convergence[maxKey]
    )}% of scores are unexplored`;

    // calculations for progress bar
    const combinations: string[] = Object.keys(currentPermDict);

    const combinationCount: number = combinations.length;
    const scoreSpaceCount: number = (
      currentScoring.lotScoring[currentGroupId].params as ADLSScoringParams
    ).rankedScoreSpace.length;

    const totalDeductions: number = combinationCount * scoreSpaceCount;
    const setDeductions: number = combinations
      .map((c) => {
        const deduction = currentPermDict[c].deduction;
        if (typeof deduction === 'number') {
          return 1;
        }
        return deduction.length;
      })
      .reduce((a, b) => a + b);

    const donePercentage = (
      ((totalDeductions - setDeductions) / (combinationCount * (scoreSpaceCount - 1))) *
      100
    ).toFixed(2);

    return showAdvanced ? (
      <IonList>
        <IonRow>
          <IonCol size="6">
            <IonItem>
              <IonText color={hasConflicts ? 'danger' : 'primary'}>
                <small>{convergenceText0}</small>
              </IonText>
            </IonItem>
            <IonItem>
              <IonText>
                <small>{convergenceText1}</small>
              </IonText>
            </IonItem>
            <IonItem>
              <IonText>
                <small>{convergenceTextMiddle}</small>
              </IonText>
            </IonItem>
            <IonItem>
              <IonText>
                <small>{convergenceTextEnd}</small>
              </IonText>
            </IonItem>
          </IonCol>
          <IonCol size="6">
            {groupDistribution &&
              Object.keys(groupDistribution)
                .sort((a, b) => +b - +a)
                .map((score) => {
                  return (
                    <IonItem
                      button
                      detail
                      key={score}
                      onClick={(_) => this.onShowCombinations(+score as AGScore)}
                    >
                      <IonText>
                        <small>
                          <strong>{groupDistribution[score]}</strong> combinations have
                          a score of{' '}
                          <strong>
                            <IonText color={this.getRangeColor(+score as AGScore)}>
                              {this.renderScore(score)}
                            </IonText>
                          </strong>
                          {convergenceRatios[score] !== undefined && (
                            <>&nbsp;({(+convergenceRatios[score] * 100).toFixed(2)}%)</>
                          )}
                        </small>
                      </IonText>
                    </IonItem>
                  );
                })}
          </IonCol>
        </IonRow>
      </IonList>
    ) : (
      <div className="progress-bar">
        <div
          style={{
            position: 'absolute',
            width: `${donePercentage}%`,
            height: '100%',
            backgroundColor: '#97D176',
            textAlign: 'center',
            overflowWrap: 'normal',
          }}
        >
          <span>{donePercentage}%</span>
        </div>
      </div>
    );
  }

  renderExamples(show: boolean, combos: string[], showButtons: boolean = true) {
    const { currentPermDict, examplesPagination } = this.state;

    if (!show) {
      return '';
    }

    const colSizes = ['12'];
    const paginationSize = 10;

    let examples: any[] = combos
      .filter((comb) => currentPermDict[comb]?.example !== null)
      .map((comb) => currentPermDict[comb]);

    const totalPages = Math.ceil(examples.length / paginationSize);

    // pagination
    examples = examples.slice(
      paginationSize * examplesPagination,
      paginationSize * (1 + examplesPagination)
    );

    return (
      <>
        <IonRow>
          <IonToolbar>
            <IonLabel>Examples given</IonLabel>
            <IonButtons slot="end">
              {showButtons && (
                <IonButton
                  fill="solid"
                  onClick={(_) =>
                    this.setState({
                      showExamples: false,
                      showDeletionExamples: false,
                      examplesPagination: 0,
                    })
                  }
                  size="small"
                  color="primary"
                >
                  Back
                </IonButton>
              )}
              {examples.length > 0 && (
                <>
                  <IonButton
                    disabled={examplesPagination === 0}
                    onClick={(_) =>
                      this.setState({ examplesPagination: examplesPagination - 1 })
                    }
                    size="small"
                  >
                    <IonIcon icon={chevronBack} />
                  </IonButton>
                  <IonButton disabled size="small">
                    Page {examplesPagination + 1} of {totalPages}
                  </IonButton>
                  <IonButton
                    disabled={examplesPagination === totalPages - 1}
                    onClick={(_) =>
                      this.setState({ examplesPagination: examplesPagination + 1 })
                    }
                    size="small"
                  >
                    <IonIcon icon={chevronForward} />
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonToolbar>
        </IonRow>

        {examples.length > 0
          ? examples.map((perm, idx) => {
              return (
                <IonRow key={idx}>
                  <IonCol size={colSizes[0]}>
                    <IonItem
                      detail={showButtons}
                      button={showButtons}
                      onClick={(_) => showButtons && this.onSetExample(perm.example)}
                    >
                      <small>
                        {this.renderExample(perm.example)}
                        <strong>
                          &nbsp; / Score:{' '}
                          <IonText color={this.getRangeColor(perm.deduction)}>
                            {this.renderScore(perm.deduction)}
                          </IonText>
                        </strong>
                      </small>
                    </IonItem>
                  </IonCol>
                </IonRow>
              );
            })
          : 'No examples so far!'}
      </>
    );
  }

  renderExample(ex: Example) {
    const { currentSection, currentGroupId } = this.state;
    const defectInfo = getDefectsInfo(
      currentSection,
      currentGroupId,
      this.props.organisation?.settings
    );

    return (
      <IonText>
        {defectInfo.map((defect, i) => (
          <React.Fragment key={defect.id}>
            <IonText color="dark">{defect.name}: </IonText>
            <IonText color={this.getRangeColor(ex[defect.id])}>{ex[defect.id]}</IonText>
            <IonText>{i < Object.keys(ex).length - 1 ? ', ' : ''}</IonText>
          </React.Fragment>
        ))}
      </IonText>
    );
  }

  renderScore(score) {
    const { scoreMode } = this.state;
    return scoreMaps[scoreMode][score] || score;
  }

  renderHelpMeLearn() {
    const {
      processing,
      currentCatSliderRange,
      currentCatSliderValue,
      currentPermDict,
      currentExample,
      currentSection,
      currentScoring,
      currentGroupId,
      currentConvergence,
      currentConvergenceRatios,
      currentGroupDistribution,
      nextQuery,
      isCorrecting,
      showPrediction,
      showAdvancedConvergence,
      showExamples,
    } = this.state;
    const { min, max } = currentCatSliderRange;
    const scoreSpace = (
      currentScoring.lotScoring[currentGroupId].params as ADLSScoringParams
    ).rankedScoreSpace;

    const colSizesCategory = ['3', '2', '7', '3'];

    const currentCombination = combinationFromExample(
      currentExample,
      getSeverityDict(currentSection, currentGroupId),
      this.props.organisation?.settings,
      currentGroupId
    );
    const currentPerm = currentPermDict[currentCombination];
    // console.log("renderHelpMeLearn - currentPerm", currentPerm)

    if (!currentPerm) {
      return '';
    }

    let currentScore: any = currentPerm?.deduction;
    currentScore =
      currentScore && typeof currentScore === 'number' ? currentScore : 'n/a';

    const isNoInfo =
      !showPrediction &&
      currentPerm.label === null &&
      typeof currentPerm.deduction === 'object' &&
      currentPerm.deduction.length === scoreSpace.length;
    const isPredicted =
      showPrediction &&
      currentPerm.label === null &&
      typeof currentPerm.deduction !== 'number';
    const isFullyDeducted = typeof currentPerm.deduction === 'number';
    const isPartiallyDeducted =
      !showPrediction &&
      typeof currentPerm.deduction === 'object' &&
      currentPerm.deduction.length < scoreSpace.length;

    const predictedScore = currentPerm?.prediction;

    const deductionMin: AGScore =
      typeof currentPerm.deduction === 'object'
        ? (Math.min(...(currentPerm.deduction as AGScore[])) as AGScore)
        : currentPerm.deduction;
    const deductionMax: AGScore =
      typeof currentPerm.deduction === 'object'
        ? (Math.max(...(currentPerm.deduction as AGScore[])) as AGScore)
        : currentPerm.deduction;

    // components
    const nextExampleButton = (
      <IonButton
        disabled={processing.status || ['', null].includes(nextQuery.combination)}
        onClick={(_) => this.onNextQuery()}
      >
        Next example
      </IonButton>
    );
    const saveSampleButton = (
      <IonButton
        disabled={processing.status || max === min}
        onClick={(_) => this.onSaveSample(currentCatSliderValue)}
      >
        Save example
      </IonButton>
    );
    const correctButton = (
      <IonButton
        disabled={processing.status || min !== max}
        color="warning"
        onClick={(_) =>
          this.setState({
            isCorrecting: true,
            currentCorrectionValue: currentCatSliderValue,
          })
        }
      >
        Correct example
      </IonButton>
    );
    // old range class: className={`slider-${min !== max ? 'with' : 'no'}-pin`}
    const scoreRange = (
      <IonRange
        disabled={min === max || processing.status}
        value={currentCatSliderValue}
        min={min}
        max={max}
        step={1}
        snaps={true}
        ticks={true}
        color={this.getRangeColor(currentCatSliderValue)}
        onIonChange={(evt) => this.onCatSliderChange(evt.detail.value as AGScore)}
        debounce={100}
      />
    );
    const scoreRangeWithScore = (
      <IonRow>
        <IonCol size="9">{scoreRange}</IonCol>
        <IonCol size="3">
          <IonItem>{this.renderScore(currentCatSliderValue)}</IonItem>
        </IonCol>
      </IonRow>
    );

    return (
      <>
        {!isCorrecting ? (
          <IonRow>
            {isNoInfo && (
              <>
                <IonCol size={(+colSizesCategory[0] + +colSizesCategory[1]).toFixed(0)}>
                  <IonLabel>
                    No information for this example. Please set a score
                  </IonLabel>
                  {scoreRangeWithScore}
                  {saveSampleButton}
                  {nextExampleButton}
                </IonCol>
              </>
            )}

            {isFullyDeducted && (
              <>
                <IonCol size={(+colSizesCategory[0] + +colSizesCategory[1]).toFixed(0)}>
                  <IonLabel>
                    For this example you have set a score of
                    <strong>
                      <IonText
                        className="big-text"
                        color={this.getRangeColor(currentScore)}
                      >
                        {' '}
                        {this.renderScore(currentScore)}
                      </IonText>
                    </strong>
                  </IonLabel>
                  <br />
                  <br />
                  {correctButton}
                  {nextExampleButton}
                </IonCol>
              </>
            )}

            {isPredicted && (
              <>
                <IonCol size={(+colSizesCategory[0] + +colSizesCategory[1]).toFixed(0)}>
                  <IonLabel>
                    For this example you have not yet set a score.
                    {(currentPerm.deduction as AGScore[]).includes(predictedScore) && (
                      <strong>
                        {' '}
                        We suggest:{' '}
                        <IonText
                          className="big-text"
                          color={this.getRangeColor(predictedScore)}
                        >
                          {' '}
                          {this.renderScore(predictedScore)}
                        </IonText>
                      </strong>
                    )}
                  </IonLabel>
                  <br />
                  <br />
                  {scoreRangeWithScore}
                  {saveSampleButton}
                  {nextExampleButton}
                </IonCol>
              </>
            )}

            {isPartiallyDeducted && (
              <>
                <IonCol size={(+colSizesCategory[0] + +colSizesCategory[1]).toFixed(0)}>
                  <IonLabel>
                    <b>For this example you have not yet set a score.</b>
                  </IonLabel>
                  <br />
                  <IonText>
                    Given your previous inputs, the score can be between{' '}
                    <strong>
                      <IonText
                        className="big-text"
                        color={this.getRangeColor(deductionMin)}
                      >
                        {' '}
                        {this.renderScore(deductionMin)}{' '}
                      </IonText>
                    </strong>
                    and
                    <strong>
                      <IonText
                        className="big-text"
                        color={this.getRangeColor(deductionMax)}
                      >
                        {' '}
                        {this.renderScore(deductionMax)}{' '}
                      </IonText>
                    </strong>
                  </IonText>
                  <br />
                  <br />
                  {scoreRangeWithScore}
                  {saveSampleButton}
                  {nextExampleButton}
                </IonCol>
              </>
            )}

            {/*ORIGINAL CODE*/}
            {/* <IonCol size={colSizesCategory[0]}>
          <IonLabel>Select correct category</IonLabel>
          <IonRange className={`slider-${min !== max ? 'with' : 'no'}-pin`} disabled={min === max || processing} value={currentCatSliderValue} min={min} max={max} step={1} snaps={true} pin ticks={true} color={this.getRangeColor(currentCatSliderValue)}
            onIonChange={evt => this.onCatSliderChange(evt.detail.value as Score)} debounce={100} />
          <IonButton disabled={processing || max === min} onClick={_ => this.onSaveSample(currentCatSliderValue)}>Save example</IonButton>
          <IonButton disabled={processing || ['', null].includes(nextQuery.combination)} onClick={_ => this.onNextQuery()}>Next example</IonButton>
          <IonButton disabled={processing || min !== max} color="warning" onClick={_ => this.setState({ isCorrecting: true, currentCorrectionValue: currentCatSliderValue })}>Correct label</IonButton>
        </IonCol>

        <IonCol size={colSizesCategory[1]}>
          <IonItem>
            <IonLabel>Score</IonLabel>            
            <IonText>{currentScore}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel><small>Our suggestion</small></IonLabel>
            {this.renderPrediction(currentPermDict, currentExample, currentSchema, currentGroupId, showPrediction)}
          </IonItem>
        </IonCol> */}

            <IonCol size={colSizesCategory[2]}>
              {!showExamples && (
                <>
                  <IonRow>
                    <IonLabel>
                      <b>Progress</b>
                    </IonLabel>
                  </IonRow>
                  <IonRow>
                    {showPrediction ? (
                      <IonText color="dark" className="thanks">
                        Thanks, we have enough data! (you're to keep providing more){' '}
                        <IonIcon icon={happyOutline} />
                      </IonText>
                    ) : (
                      <IonText color="warning">
                        Please keep providing more examples
                      </IonText>
                    )}
                  </IonRow>
                </>
              )}

              {this.renderConvergence(
                currentConvergence,
                showAdvancedConvergence,
                currentGroupDistribution,
                currentConvergenceRatios
              )}
              <IonRow className="buttons-row">
                {!showAdvancedConvergence && !showExamples && (
                  <IonButton
                    size="small"
                    onClick={(_) => this.setState({ showExamples: !showExamples })}
                  >
                    {showExamples ? 'Hide examples' : 'Show previous examples'}
                  </IonButton>
                )}
                {!showExamples && (
                  <IonButton
                    color="medium"
                    fill={showAdvancedConvergence ? 'solid' : 'clear'}
                    size="small"
                    onClick={(_) =>
                      this.setState({
                        showAdvancedConvergence: !showAdvancedConvergence,
                      })
                    }
                  >
                    {showAdvancedConvergence ? 'Back' : 'Advanced'}
                  </IonButton>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        ) : (
          this.renderCorrection(currentScore)
        )}
      </>
    );
  }

  renderCorrection(currentScore: any) {
    const {
      correctedPermDict,
      currentCorrectionValue,
      currentPermDict,
      currentExample,
      currentSection,
      currentGroupId,
      impliedDeletion,
      showDeletionExamples,
    } = this.state;
    const colSizesCorrection = ['6', '6'];

    return (
      <IonRow>
        <IonCol size={colSizesCorrection[0]}>
          <IonLabel>Correct example</IonLabel>
          <IonRow>
            <IonCol size="10">
              <IonItem>
                <IonRange
                  disabled={!!correctedPermDict}
                  value={currentCorrectionValue}
                  min={1}
                  max={4}
                  step={1}
                  snaps={true}
                  pin
                  ticks={true}
                  color={this.getRangeColor(currentCorrectionValue)}
                  onIonChange={(evt) =>
                    this.setState({
                      currentCorrectionValue: evt.detail.value as AGScore,
                    })
                  }
                  debounce={100}
                />
              </IonItem>
            </IonCol>
            <IonCol size="2">
              <IonItem>{this.renderScore(currentCorrectionValue)}</IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                disabled={
                  !!correctedPermDict || currentScore === currentCorrectionValue
                }
                onClick={(_) =>
                  this.onCheckConflicts(
                    currentPermDict,
                    currentExample,
                    currentCorrectionValue,
                    currentSection,
                    currentGroupId
                  )
                }
              >
                Check conflicts
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                disabled={!!correctedPermDict}
                onClick={(_) => this.setState({ isCorrecting: false })}
              >
                Cancel
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>

        {correctedPermDict && (
          <IonCol>
            <IonLabel>Info</IonLabel>
            <IonList>
              <IonItem>{this.renderConfirmationDialog()}</IonItem>
            </IonList>
            <IonRow>
              <IonCol>
                <IonButton color="danger" onClick={(_) => this.onCorrectionConfirm()}>
                  Confirm
                </IonButton>
              </IonCol>
              {impliedDeletion.length > 0 && (
                <IonCol>
                  <IonButton
                    color="warning"
                    onClick={(_) =>
                      this.setState({ showDeletionExamples: !showDeletionExamples })
                    }
                  >
                    {showDeletionExamples ? 'Hide examples' : 'See examples'}
                  </IonButton>
                </IonCol>
              )}
              <IonCol>
                <IonButton color="primary" onClick={(_) => this.onCorrectionCancel()}>
                  Cancel
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        )}
      </IonRow>
    );
  }

  renderConfirmationDialog() {
    const { currentPermDict, impliedDeletion } = this.state;
    const countLabelsToDelete = impliedDeletion.length;
    if (countLabelsToDelete === 0) {
      return (
        <IonText color="primary">
          No conflicts found! Click on confirm to save corrected label
        </IonText>
      );
    }
    const combinations = Object.keys(currentPermDict);
    const countSetLabels = combinations.filter(
      (comb) => currentPermDict[comb].label
    ).length;
    const percentage = ((countLabelsToDelete / countSetLabels) * 100).toFixed(1);
    const text = (
      <IonText color="danger">{`WARNING: ${percentage}% of set examples (${countLabelsToDelete} examples) would be deleted. Are you sure you want to proceed?`}</IonText>
    );
    return text;
  }

  renderPrediction(
    currentPermDict: ScoringContext,
    currentExample: Example,
    currentSection: Section,
    currentGroupId: string,
    showModel: boolean
  ) {
    try {
      if (!showModel) {
        return (
          <IonText color="medium">
            <small>Not enough data</small>
          </IonText>
        );
      }
      return (
        <IonText>
          {currentPermDict[
            combinationFromExample(
              currentExample,
              getSeverityDict(currentSection, currentGroupId),
              this.props.organisation?.settings,
              currentGroupId
            )
          ].prediction || 'n/a'}
        </IonText>
      );
    } catch {
      return <IonText>n/a</IonText>;
    }
  }

  renderGroupSelector() {
    const {
      currentSection,
      currentGroupId,
      isCorrecting,
      showCombinations,
      showExamples,
    } = this.state;
    const currentGroupIds = getGroupsFromSection(currentSection);

    return (
      <IonItem>
        <IonLabel>Select score</IonLabel>
        <IonSelect
          disabled={isCorrecting || !!showCombinations || showExamples}
          value={currentGroupId}
          onIonChange={(evt) => this.onGroupChange(evt.detail.value, currentSection)}
        >
          {currentGroupIds.map((groupId) => (
            <IonSelectOption key={groupId} value={groupId}>
              {renderGroupName(groupId)}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  }

  renderSchemaSelector() {
    const { currentSection, isCorrecting, showCombinations, showExamples, sections } =
      this.state;
    const sortedSections: Section[] = sections.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    return (
      <IonItem>
        <IonLabel>Select quality section</IonLabel>
        <IonSelect
          disabled={isCorrecting || !!showCombinations || showExamples}
          value={currentSection.id}
          onIonChange={(evt) => this.onSectionChange(evt.detail.value)}
        >
          {sortedSections.map((section) => {
            return (
              <IonSelectOption key={section.id} value={section.id}>
                {section.name}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    );
  }

  renderHeader() {
    const { menuOption, processing, scoreMode } = this.state;
    return (
      <IonHeader>
        <IonToolbar>
          <IonTitle>Group Scoring</IonTitle>
          <IonButtons slot="start" color="light">
            <IonMenuButton />
            <IonBackButton
              text={i18n.t('General.back')}
              defaultHref="/tabs/admin"
              color="dark"
            ></IonBackButton>
          </IonButtons>
          {/* <IonTitle onClick={_ => this.contentRef.current.scrollToTop(500)}>Scoring game</IonTitle> */}
          <IonButtons slot="end">
            {menuOption === 'Group scoring' && (
              <>
                <IonButton
                  disabled={processing.status}
                  color="danger"
                  onClick={(_) => this.onResetScores()}
                >
                  RESET SCORES
                </IonButton>
                <IonButton
                  disabled={processing.status}
                  size="small"
                  fill="outline"
                  color="primary"
                  onClick={(_) => this.onToggleScoreMode()}
                >{`Score mode: ${scoreMode}`}</IonButton>
                {/* <IonButton disabled={processing.status} color="danger" onClick={async (_) => await this.onCopySchemas()}>COPY SCEHASM</IonButton> */}
              </>
            )}
            {/* <IonButton disabled={processing} size="small" fill="outline" color="primary" onClick={_ => setContextPermDict(this.state.currentPermDict, this.state.currentSchema, this.state.currentGroupId)}>POPULATE</IonButton> */}
            {/* <IonButton disabled={processing} size="small" fill="outline" color="primary" onClick={_ => this.onSetScoring()}>Set scoring</IonButton> */}
            {/* <IonButton disabled={processing} size="small" fill="outline" color="primary" onClick={_ => this.recoverScoringContextFromScoring(currentSchema)}>Set schemas as active</IonButton> */}
            {/* await setContextPermDict(newPermDict, currentSchema.id, currentGroupId, this.orgId); */}
            {/* await setSchemaAsActive(this.schemas.find(s => s.name === "Raspberries packed 2021-10-12")); */}
          </IonButtons>
        </IonToolbar>
        {/* <IonToolbar>
        <IonSegment value={menuOption}
          onIonChange={(evt => this.onMenuOptionChange(evt.detail.value))}>
          {this.menuOptions.map(option => <IonSegmentButton value={option} key={option}>
            <IonLabel>{option}</IonLabel>
          </IonSegmentButton>)}
        </IonSegment>
      </IonToolbar> */}
      </IonHeader>
    );
  }

  stringifyTest() {
    const time = Date.now();
    const a = JSON.stringify(this.state.currentPermDict);
    console.log(a.slice(0, 2), Date.now() - time);
  }
}

export default withContext(PageScoring, [
  'profile',
  'organisation',
  'setScorings',
  'scorings',
]);
