import {
  IonBadge,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { cubeOutline, linkOutline, warningOutline } from 'ionicons/icons';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { ctxUsers } from './App';
import { CardImages } from './CardImages';
import './CardInspection.scss';
import withContext, { ContextProps } from './HOC/withContext';
import {
  formatDate,
  getLotOrPosQuantity,
  resolveLocationName,
  standardizeDate,
} from './HelperUtils';
import { LegacyInspection, LotProperties, QuestionInput } from './InspectionModel';
import {
  AG_BOXES_AT_INSPECTION_QUESTION_ID,
  AG_BOXES_SHIPPED_MISMATCH,
  AG_BOXES_SHIPPED_QUESTION_ID,
  AG_VOLUME_AT_INSPECTION,
  IArticle,
  Lot,
  LotTransfer,
  Order,
  OrganisationSettings,
  Report,
  UserProfile,
} from './Model';
import PageGallery from './PageGallery';
import { Article } from './ServiceArticle';
import {
  InspectionClass,
  getInspectionBoxes,
  getUserInputValuesToRender,
  stringifyInspectionReference,
} from './ServiceInspection';
import ViewArticleDescription from './ViewArticleDescription';
import ViewContactName, { ViewContactNameById } from './ViewContactName';
import { ViewInspectionScore } from './ViewInspectionScore';
import ViewReportTypeBadge from './ViewReportTypeBadge';
import { LotSchemaObjectTypeEnum } from './generated/openapi/core';
import { InspectionSpec } from './ModelSpecification';
import { MAX_BADGE_TEXT_SIZE } from './GlobalConstants';
import { PARTIAL_SUMMARY_PREFIX } from './InspectionLogic';
import { ModalEditVariety } from './modals/ModalEditVariety';

interface Props {
  inspection?: LegacyInspection;
  showLink?: boolean;
  showGalleryModal?: boolean;
  routerLink?: string;
  loading: boolean;
  showButtons?: boolean;
  allowStatusEdit?: boolean;
  lot?: Lot;
  // lotIdOverride?: string;
  report?: Report;
  maxImgs?: number;
  shouldUpdate?: Function;
  organisationId: string;
  noIcon?: boolean;
  reload?: boolean;

  allowGalleryEdit?: boolean;
  onVarietyEdit?: (v: string) => void;
  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;

  imageMode?: string;
  searchString?: string;
  doubleCard?: Order;
  orgSettings?: OrganisationSettings;
  history?: any;
  testingSuffix?: string;
  onPicturesUpdated?: Function;
  schema?: InspectionSpec;

  onLotUnlinkFromPageOrder?: (lotId: string, order: Order) => void;

  onMismatchShow?: () => void;

  splitLotTransferedQuantity?: number;
  motherLotIds?: string[];
  sharedReport?: boolean;
}

interface State {
  showPageGallery: boolean;
  showVarietyModal: boolean;
  culo?: boolean;
  showDefectId: string;
}

class CardInspection extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showPageGallery: false,
      showDefectId: undefined,
      culo: false,
      showVarietyModal: false,
    };
  }

  // TODO: implement properly
  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    // always update on state change
    if (this.state !== nextState) {
      return true;
    }

    if (!isEqual(this.props.locations, nextProps?.locations)) {
      return true;
    }

    if (this.props.reload === true) {
      return true;
    }
    const { showDefectId, showPageGallery } = this.state;
    if (
      showPageGallery !== nextState.showPageGallery ||
      showDefectId !== nextState.showDefectId
    ) {
      return true;
    }

    if (nextProps.organisationId !== this.props.organisationId) {
      // console.error('nextProps.organisationId !== this.props.organisationId')
      return true;
    }

    return true;
  }

  showGallery(e, defectId?) {
    if (this.props.showGalleryModal === false) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if (defectId) {
      if (
        this.props.inspection.pictures.filter((p) => p.inputIds.includes(defectId))
          .length <= 0
      ) {
        return;
      }
      this.setState({
        showPageGallery: true,
        showDefectId: defectId,
      });
    } else {
      this.setState({ showPageGallery: true, showDefectId: undefined });
    }
  }

  hideGallery() {
    this.setState({ showPageGallery: false });
  }

  boxesCount() {
    const { inspection } = this.props;
    if (inspection.objectType !== LotSchemaObjectTypeEnum.Lot) return null;

    const { boxesExpected, boxesShipped, volumeAtInspection, boxesAtInspection } =
      getInspectionBoxes(inspection);

    let boxesBadge: JSX.Element = null;
    let boxesShippedBadge: JSX.Element = null;
    let volumeBadge: JSX.Element = null;

    const badge = (className: string, title: string, value: string) => (
      <IonBadge className={className}>
        <span>{title}</span>
        <span>{value}</span>
      </IonBadge>
    );

    if (
      boxesExpected != null &&
      boxesShipped != null &&
      boxesExpected !== boxesShipped
    ) {
      boxesShippedBadge = badge(
        `score-1 boxes-count`,
        'Shipped',
        `${boxesShipped}/${boxesExpected}`
      );
    }

    if (boxesAtInspection != null) {
      boxesBadge = badge(`score-4 boxes-count`, 'Boxes', `${boxesAtInspection}`);
    }

    if (volumeAtInspection != null) {
      volumeBadge = badge(`score-4 boxes-count`, 'Volume', `${volumeAtInspection}Kg`);
    }

    return (
      <>
        {boxesBadge}
        {volumeBadge}
        {boxesShippedBadge}
      </>
    );
  }

  renderDefects() {
    const { inspection } = this.props;
    const questionSpecs = inspection?.renderingInfo?.questionSpecs;

    // these questions are treated separatedly in boxesCount()
    const ommitedDefects: string[] = [
      AG_BOXES_AT_INSPECTION_QUESTION_ID,
      AG_BOXES_SHIPPED_QUESTION_ID,
      AG_BOXES_SHIPPED_MISMATCH,
      AG_VOLUME_AT_INSPECTION,
    ];

    if (!questionSpecs) return;

    let defects = Object.entries(inspection.userInputs).filter(
      ([id, o]) =>
        (o.agScore < 4 && !ommitedDefects.includes(id)) ||
        id.includes(PARTIAL_SUMMARY_PREFIX)
    );
    let boxesCount = this.boxesCount();

    if (!!defects.length || !!boxesCount) {
      return (
        <div className="all-defects">
          {boxesCount}
          {defects
            .filter(
              ([id, o], i) =>
                questionSpecs[id]?.cardProperties?.hideInCard !== true &&
                !!(
                  questionSpecs[id]?.cardProperties?.cardName ||
                  questionSpecs[id]?.displayedName
                )
            )
            .map(([id, o], i) => {
              const fullName = !!questionSpecs[id].cardProperties?.cardName
                ? questionSpecs[id].cardProperties?.cardName
                : questionSpecs[id].displayedName;

              return <QuestionBadge key={i} fullName={fullName} questionInput={o} />;
            })}
        </div>
      );
    }
  }

  getLink(doubleCard = false) {
    let { showLink, inspection, profile, routerLink } = this.props;
    if (!inspection?.reference) return { routerLink: null };
    // TODO: reimplement double card logic

    // if (doubleCard) {
    //   assessment = this.props.doubleCard.lotAssessmentMap[linkedLotId]
    //   if (!assessment)
    //     assessment = this.props.doubleCard.transportAssessmentMap[linkedLotId]

    //   return { routerLink: '/secure/' + this.props.doubleCard.orgId + '/report/' + assessmentId(assessment.reference) }
    // } else {
    if (showLink === false) {
      return { routerLink: undefined };
    } else if (routerLink !== undefined) {
      return { routerLink };
    }
    // }

    // TODO: fix this later and also add support for upcoming lots
    // if(assessment.reference.type === "STOCK"){
    //   return { routerLink: '/secure/' + profile.organisationId + '/report/' + assessmentId(assessment.reference) + "/" + dateToString(assessment.lastModifiedDate) }
    // } else {
    //   return { routerLink: '/secure/' + profile.organisationId + '/report/' + assessmentId(assessment.reference) }
    // }
    return {
      routerLink:
        '/secure/' +
        profile.organisationId +
        '/inspection-view/' +
        stringifyInspectionReference(inspection?.reference),
    };
  }

  renderWaste() {
    const { lot } = this.props;

    if (!lot?.transfers.find((t) => t.wasteVolumeInKg)) {
      return null;
    }

    const isMotherLot: boolean = !!lot.transfers.find(
      (t) => t.fromLots?.map((l) => l.lotId).includes(lot.id) && !!t.wasteVolumeInKg
    );

    const isChildLot: boolean = !!lot.transfers.find(
      (t) => t.toLots?.map((l) => l.lotId).includes(lot.id) && !!t.wasteVolumeInKg
    );

    let motherPercentage: string;
    let childPercentage: string;

    if (isMotherLot) {
      const initialKg: number = lot.transfers.sort(
        (a, b) =>
          standardizeDate(a.transferDate).getTime() -
          standardizeDate(b.transferDate).getTime()
      )[0].volumeInKg;

      const sortedOutKg: number = lot.transfers.reduce(
        (acc, transfer) => acc + (transfer.wasteVolumeInKg ?? 0),
        0
      );

      motherPercentage = ((sortedOutKg / initialKg) * 100).toFixed(1);
    }
    if (isChildLot) {
      const wasteTransfer: LotTransfer = lot.transfers.find((t) =>
        (t.toLots ?? []).map((t) => t.lotId).includes(lot.id)
      );

      if (!wasteTransfer) return null;

      const motherVolume: number = (
        lot.transfers.find((t) => (t.toLots ?? []).map((t) => t.lotId).includes(lot.id))
          .fromLots ?? []
      ).reduce((acc, l) => acc + l.volumeInKg, 0);

      const childVolume: number = lot.transfers.find((t) =>
        (t.toLots ?? []).map((t) => t.lotId).includes(lot.id)
      )?.volumeInKg;

      childPercentage = (((motherVolume - childVolume) / motherVolume) * 100).toFixed(
        1
      );
    }

    if (!motherPercentage && !childPercentage) {
      return null;
    }

    return (
      <div className="waste">
        {!!motherPercentage && (
          <IonBadge color="warning">Sorted out: {motherPercentage}%</IonBadge>
        )}
        {!!childPercentage && (
          <IonBadge color="medium">Sorted out: {childPercentage}%</IonBadge>
        )}
      </div>
    );
  }

  renderStock() {
    const { lot, splitLotTransferedQuantity } = this.props;
    if (!lot || !lot.transfers) return null;

    // TODO: do better
    let quantity: any = getLotOrPosQuantity(lot);
    if (isNaN(quantity)) quantity = lot.transfers[0]?.numBoxes;
    quantity = Math.abs(quantity);
    quantity = isNaN(quantity) ? '??' : parseInt(quantity);

    return (
      <div className="quantity">
        <div>
          {quantity}
          <small>
            {splitLotTransferedQuantity && splitLotTransferedQuantity > 0
              ? ` (-${splitLotTransferedQuantity})`
              : ''}
          </small>
        </div>
        <div>{new Article(lot.article).getUnit(quantity)}</div>
      </div>
    );
  }

  culo(e) {
    console.log(this.state.culo);

    e.stopPropagation();
    this.setState({ culo: !this.state.culo });
  }

  render() {
    const { doubleCard } = this.props;
    // TODO: reimplement double card logic
    let linkedLotId = undefined;

    if (doubleCard && linkedLotId) {
      return (
        <div
          className={'double-card ' + (this.state.culo ? 'swap' : '')}
          onClick={(e) => this.culo(e)}
        >
          <div className="background-card">{this.renderCard()}</div>
          <div className="first-card">
            {this.renderCard(this.props.doubleCard.orgId, this.props.doubleCard)}
          </div>
          <div className="second-card">{this.renderCard()}</div>
        </div>
      );
    } else {
      return this.renderCard();
    }
  }

  getIcon() {
    return null;
    // const icon = () => {
    //   if (this.props.inspection.reference.transportId) {
    //     switch (this.props.inspection.userInputs.transport_type?.rawValues?.[0]) {
    //       case 1: return <IonIcon icon={airplaneOutline}/>
    //       case 2: return <IonIcon icon={boatOutline}/>
    //       case 3: return <IonIcon icon={carOutline}/>
    //     }
    //     return <IonIcon icon={documentAttachOutline}/>
    //   }
    //   return <IonIcon icon={documentAttachOutline}/>
    // }

    // return <div className="report-icon">{icon()}</div>
  }

  getTitle(title) {
    let { searchString, inspection } = this.props;
    if (!inspection.reference) return title;
    const { routerLink } = this.getLink();
    title = title || inspection.reference.lotId || inspection.reference.transportId;
    // const printLink = <a href={'https://app.agrinorm.ai' + routerLink} target="_blank" title={title} className="print-only inspection-print-link">open <IonIcon icon={openOutline}/></a>
    const printLink = (
      <a
        href={routerLink}
        target="_blank"
        rel="noreferrer"
        title={title}
        onClick={(e) => e.preventDefault()}
      >
        {title}
      </a>
    );

    if (searchString && title.endsWith(searchString)) {
      return (
        <>
          {title.slice(0, title.length - searchString.length)}
          <span className="highlight">{searchString}</span>
        </>
      );
    }

    return <>{printLink}</>;
  }

  getSubTitle() {
    const { suppliedByContactId } = this.props.lot ?? {};

    if (suppliedByContactId == null) {
      return null;
    }

    if (
      suppliedByContactId === this.props.profile?.organisationId &&
      !!this.props.organisation?.name
    ) {
      return <div className="supplier">{this.props.organisation.name}</div>;
    }

    return (
      <>
        {suppliedByContactId != null && (
          <div className="supplier">
            <ViewContactName lot={this.props.lot} />
          </div>
        )}
      </>
    );
  }

  renderCard(title = '', doubleCard: Order = undefined) {
    if (this.props.loading) {
      return '';
    }

    let {
      schema,
      inspection,
      allowGalleryEdit,
      sharedReport,
      onPicturesUpdated,
      maxImgs,
      lot,
      allowStatusEdit,
      report,
      profile,
      onMismatchShow,
      onVarietyEdit,
    } = this.props;

    // TODO: reimplement linked lot id
    let linkedLotId = undefined;

    if (doubleCard && linkedLotId) {
      inspection = doubleCard.lotInspectionMap[linkedLotId];
    }

    let totalScore = 0;
    // console.log('configuration', configuration)
    // debugger
    totalScore = Object.keys(inspection?.scores ?? {}).reduce((accumulator, key) => {
      return (accumulator += parseInt(inspection?.scores[key].score));
    }, 0);

    let minScore = Object.keys(inspection?.userInputs ?? {}).reduce(
      (accumulator, key) => {
        let score = inspection.userInputs[key]?.agScore ?? 4;
        return accumulator < score ? accumulator : score;
      },
      4
    );

    // console.log('minScore', minScore);

    let pictures = inspection?.pictures || [];
    const isTransportReport = !!inspection?.reference?.transportId;

    // take the last ones aka the most recent ones
    if (maxImgs) {
      pictures = pictures.slice(-maxImgs);
    }

    // TODO: reimplement this logic
    const displayLotLink = false;

    let className = 'card-inspection ' + (totalScore > 0 ? 'go-to-top' : '');

    const inspClass = new InspectionClass(inspection);
    const isCompleted = inspClass.isCompleted();

    if (isCompleted) {
      className += ' completed score-' + minScore;
    }

    const growerId = lot?.origin?.growerContactId ?? inspection?.reference?.growerId;

    let article: IArticle = lot?.article;
    if (!article && inspection?.objectType === LotSchemaObjectTypeEnum.Lot) {
      article = inspection?.lotProperties?.article;
    }

    return (
      <>
        <div
          className={`ion-card ${className}`}
          data-tip={`card-report-${this.props.testingSuffix}`}
          onClick={(e) => {
            e.stopPropagation();
            const { routerLink } = this.getLink(!!doubleCard && !!linkedLotId);
            if (!routerLink) return;
            this.props.history?.push(routerLink);
          }}
        >
          <div
            className="cover"
            onClick={(e) => (pictures.length ? this.showGallery(e) : null)}
          >
            <CardImages
              pictures={pictures}
              max={maxImgs || pictures.length}
              imageMode={this.props.imageMode}
            />
          </div>
          <div className="card-inspection-wrapper">
            <IonCardHeader>
              <div>
                <IonCardTitle className="card-title">
                  {this.props.noIcon ? null : this.getIcon()}
                  {this.getTitle(title)}
                  {lot?.transient?.hasLink ? <IonIcon icon={linkOutline} /> : null}
                </IonCardTitle>

                <div className="date-report-type">
                  {lot?.transient?.freshnessDate ? (
                    <IonBadge color="medium" className="arrival-date">
                      {formatDate(lot.transient.freshnessDate)}
                    </IonBadge>
                  ) : (
                    <IonBadge color="medium" className="arrival-date">
                      {formatDate(inspection.lastModifiedDate)}
                    </IonBadge>
                  )}
                  {lot?.latestInspection && (
                    <div className="arrival-date">
                      <ViewReportTypeBadge inspection={inspection} />
                    </div>
                  )}
                  {!isCompleted && !!inspection.lastModifiedUserId && (
                    <IonBadge color="secondary">IN PROGRESS</IonBadge>
                  )}
                </div>

                {this.getSubTitle()}

                {displayLotLink && (
                  <div className="linked-lot">
                    <IonIcon icon={linkOutline} /> {linkedLotId}
                    {!!onMismatchShow && (
                      <IonButton
                        title="Display allocation mismatches"
                        fill="clear"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onMismatchShow();
                        }}
                      >
                        <IonIcon icon={warningOutline} />
                      </IonButton>
                    )}
                  </div>
                )}

                {!!growerId && lot?.suppliedByContactId !== growerId && (
                  <div className="grower-name">
                    <ViewContactNameById contactId={growerId} />
                  </div>
                )}

                <ctxUsers.Consumer>
                  {(users) => {
                    if (
                      this.props.sharedReport &&
                      this.props.orgSettings?.sharedReportDisplaySettings
                        ?.inspectedBy === false
                    ) {
                      return null;
                    }
                    let inspectionUser: UserProfile = users.find(
                      (o) => o.id === inspection?.lastModifiedUserId
                    );
                    return (
                      inspectionUser && (
                        <div className="user">
                          {inspectionUser.name ?? inspectionUser.email}
                        </div>
                      )
                    );
                  }}
                </ctxUsers.Consumer>
                {!!article && (
                  <div className="lot-article-description">
                    <ViewArticleDescription
                      article={article}
                      pills={true}
                      onVarietyClick={
                        !!onVarietyEdit
                          ? () => this.setState({ showVarietyModal: true })
                          : undefined
                      }
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="score-type-section">
                  <ViewInspectionScore
                    inspection={inspection}
                    order={report}
                    organisationSettings={
                      this.props.orgSettings ?? this.props.organisation?.settings
                    }
                    profile={this.props.profile}
                    displayType="INLINE"
                  />
                </div>
                {this.renderStock()}
                {this.renderWaste()}
              </div>
            </IonCardHeader>

            {/* <IonCardContent className="lot-article-description">
        
        </IonCardContent> */}

            <IonCardContent className="defects">
              {/* Only display defects if inspection is finished */}
              {this.renderDefects()}
            </IonCardContent>
            {this.props.showButtons && (
              <div className="bottom-buttons">
                <IonButton
                  color="tertiary"
                  fill="outline"
                  routerLink={
                    '/secure/' + this.props.organisationId + '/lot/' + lot?.id
                  }
                >
                  view history
                </IonButton>
                <IonButton
                  color="tertiary"
                  fill="outline"
                  routerLink={
                    '/secure/' +
                    this.props.organisationId +
                    '/inspection-view/' +
                    stringifyInspectionReference(inspection.reference)
                  }
                >
                  view report
                </IonButton>
              </div>
            )}

            {this.props.lot?.transfers?.length !== 0 ? (
              <IonIcon icon={cubeOutline} className="has-transfers" />
            ) : null}

            {lot?.transient.locationId ? (
              <div className="location">
                Location:{' '}
                {resolveLocationName(lot.transient.locationId, this.props.locations)}
              </div>
            ) : (
              inspection?.locationId &&
              !this.props.sharedReport && (
                <div className="location">
                  Location:{' '}
                  {resolveLocationName(inspection?.locationId, this.props.locations)}
                </div>
              )
            )}
          </div>
        </div>

        {this.state.showVarietyModal && !!article && !!onVarietyEdit && (
          <IonModal
            isOpen={this.state.showVarietyModal}
            onDidDismiss={() => this.setState({ showVarietyModal: false })}
          >
            <ModalEditVariety
              onClose={() => this.setState({ showVarietyModal: false })}
              article={article}
              onVarietyChange={onVarietyEdit}
            />
          </IonModal>
        )}

        <IonModal
          isOpen={this.state.showPageGallery}
          onDidDismiss={(_) => this.hideGallery()}
        >
          <PageGallery
            editable={!sharedReport && !!allowGalleryEdit}
            editMode={schema ? 'assessment' : 'report'}
            pictures={inspection?.pictures}
            showDefectId={this.state.showDefectId}
            inspection={inspection}
            schema={schema}
            profile={profile}
            onPicturesUpdated={onPicturesUpdated}
            onDismiss={(_) => this.hideGallery()}
            inspectionReference={inspection.reference}
            updateOCRData={this.props.updateOCRData}
            orgSettings={this.props.orgSettings ?? this.props.organisation?.settings}
          />
        </IonModal>
      </>
    );
  }
}

export default withContext(CardInspection, ['profile', 'locations', 'organisation']);

interface QuestionBadgeProps {
  fullName: string;
  questionInput: QuestionInput;
}
const QuestionBadge = ({ fullName, questionInput }: QuestionBadgeProps) => {
  const shortName =
    fullName.length > MAX_BADGE_TEXT_SIZE
      ? `${fullName.slice(0, MAX_BADGE_TEXT_SIZE)}...`
      : fullName;

  const [showFullName, setShowFullName] = useState<boolean>(false);

  const isExpandable = shortName !== fullName;

  return (
    <IonBadge
      className={`score-${questionInput.agScore} ${questionInput.groupId} ${
        isExpandable ? 'expandable' : ''
      }`}
      onClick={
        isExpandable
          ? (e) => {
              e.stopPropagation();
              setShowFullName(!showFullName);
            }
          : undefined
      }
    >
      <span>{showFullName ? fullName : shortName}</span>
      <span>{getUserInputValuesToRender(questionInput)}</span>
    </IonBadge>
  );
};
