import { cloneDeep, omit, pick, isEqual } from 'lodash';
import { LOT_INSPECTION_MAP, TRANSPORT_INSPECTION_MAP } from './GlobalConstants';
import { LotInspection } from './InspectionModel';
import {
  Lot,
  LotUpdateObject,
  Order,
  OrderAGStatus,
  OrderType,
  OrganisationSettings,
  ProductVariety,
  ProductionSiteLocation,
  ProductionType,
  Report,
  ReportStatus,
  ReportType,
  UserProfile,
} from './Model';
import {
  AGTab,
  AGTabs,
  FilterType,
  QCFilterType,
  ReportFilterType,
} from './ModelAGTabs';
import { InspectionType, PackagingType } from './generated/openapi/core';
import { hasLocationRestriction } from './PermissionsService';
import { standardizeDate } from './HelperUtils';
import { TimeLabel } from './ModelInsight';

/* *************** */
// Model
/* *************** */
export interface Search {
  // general
  locationId?: string;
  contactId?: string;
  supplierId?: string;
  growerId?: string;

  scores?: AGScoreSearch;

  // reportReference?: ReportReference;

  // Arrays
  searchbox?: string[];
  searchProducts?: string[];
  searchSuppliers?: string[];
  searchVarieties?: string[];
  searchBrands?: string[];

  inspectionTypes?: InspectionType[]; // array with all present assessment types
  hasAssessment?: boolean; // flag that indicates presence of assessments (necessary for the filtering)
  hasVolume?: boolean;
}

export interface AGScoreSearch {
  has1orLess: boolean;
  has2orLess: boolean;
  has3orLess: boolean;
}

export interface AGFilters {
  article?: {
    productId?: { value: string; label: string }[];
    supplier?: string;
    variety?: string;
    packagingType?: PackagingType;
  };

  supplierId?: string;
  contactId?: string;
  growerId?: string;
  userId?: {
    value: string;
    label: string;
    name: string;
  };
  locationId?: string;
  locationReference1?: string;
  onlyLinked?: boolean;

  skipZeroVolume?: boolean;

  // TODO: improve this
  qcStatus?: { value: OrderAGStatus; label: string };
  reportStatus?: { value: ReportStatus; label: string };
  qcScore?: { value: string; label: string };
  orderType?: { value: OrderType; label: string };
  inspectionType?: { value: InspectionType; label: string };
  reportType?: { value: ReportType; label: string };

  // for supply chain batches
  tentativeOrderId?: string;

  hasAssessment?: boolean;
  isInStock?: boolean;
  showCompleted?: boolean;

  sort?: 'desc' | 'asc';
  maxDays?: 2;
  dateProperty?: string;
  arrivalDate?: Date;
  arrivalDateMax?: Date;
  search?: string;

  // Fields
  productionType?: ProductionType;
  producedProducts?: string[];
  producedVarieties?: ProductVariety[];
  partnerId?: string;
}

interface DateTabMap {
  [tab: string]: {
    range: TimeLabel | undefined;
    sorting: 'desc' | 'asc';
  };
}
export interface AGSavedFilters extends AGFilters {
  dateTabMapping: DateTabMap;
}

export type SearchField =
  | 'product'
  | 'location'
  | 'locationReference1'
  | 'supplier'
  | 'grower'
  | 'user'
  | 'qcStatus'
  | 'score'
  | 'reportStatus'
  | 'orderType'
  | 'inspectionType'
  | 'hasAssessment'
  | 'reportType'
  | 'isInStock'
  | 'sort_by'
  | 'showCompleted'
  | 'packagingType'
  | 'productionType'
  | 'producedVarieties'
  | 'producedProducts'
  | 'skipZeroVolume'
  | 'partner';

export const qcRelevantOrderTypeMap: { [key in OrderType]?: string } = {
  BUY: 'Incoming',
  SELL: 'Outgoing',
  INTERNAL_TRANSFER: 'Internal transfer',
  SELL_RETURN: 'Sell retour',
};

export type SearchFieldSection<FT extends FilterType> = {
  [key in FT]?: SearchField[];
};

export type SearchFieldMap = {
  PageQualityControl: SearchFieldSection<QCFilterType>;
  PageReportDashboard: SearchFieldSection<ReportFilterType>;
  PageDashboard: SearchFieldSection<QCFilterType>;
};

// All allowed search fields separated by app section and "stage"
export const searchFields: SearchFieldMap = {
  PageQualityControl: {
    'SUPPLY-CHAIN': [
      'product',
      'packagingType',
      'hasAssessment',
      'location',
      'supplier',
      'user',
      'score',
      'inspectionType',
    ],
    'BATCHES': [
      'product',
      'packagingType',
      'location',
      'supplier',
      'grower',
      'user',
      'score',
      'inspectionType',
      'skipZeroVolume',
      'hasAssessment',
      'isInStock',
    ],
    'STOCK': ['location', 'locationReference1', 'product', 'score', 'showCompleted'],
    'ORDERS': [
      'sort_by',
      'product',
      'location',
      'supplier',
      'user',
      'qcStatus',
      'score',
      'orderType',
    ],
    'FIELD_INSPECTIONS': [
      'producedProducts',
      'producedVarieties',
      'productionType',
      'partner',
    ],
  },
  PageReportDashboard: {
    'UPCOMING': ['product', 'location', 'supplier', 'user', 'score', 'reportStatus'],
    'MY-REPORTS': [
      'product',
      'location',
      'supplier',
      'user',
      'score',
      'reportStatus',
      'reportType',
    ],
    'FEEDBACK': ['product', 'supplier', 'score'],
  },
  PageDashboard: {
    ORDERS: ['product', 'location', 'supplier', 'score', 'orderType'],
  },
};

/* *************** */
// GENERATE SEARCH
/* *************** */

export function generateOrderSearch(
  order: Order,
  orgSettings: OrganisationSettings
): Search {
  const hasAssessment = getHasAssessment(order);
  const inspectionTypes = getInspectionTypes(order);

  let search: Search = {
    locationId: order.locationId,
    growerId: order.growerContactId,
    hasAssessment,
    inspectionTypes,

    // TODO: check this. Why is there a contact and a supplier in the order model?
    contactId: order.contactId,
    supplierId: order.supplierId,
  };

  search = { ...search, ...buildOrderArraySearch(order) };
  search.scores = generateOrderOrReportScores(order, orgSettings);

  return search;
}

export function generateReportSearch(
  report: Report,
  order: Order,
  orgSettings: OrganisationSettings
): Search {
  const hasAssessment = getHasAssessment(report);
  const inspectionTypes = getInspectionTypes(report);

  let search: Search = {
    locationId: order?.locationId,

    // TODO: check this. Why is there a contact and a supplier in the order model?
    contactId: report.contactId,
    supplierId: report.supplierId,
    growerId: report.growerContactId,
    hasAssessment,
    inspectionTypes,

    // validLink: report.contactOrder?.validLink ?? false,
  };

  search = { ...search, ...buildReportArraySearch(report) };
  search.scores = generateOrderOrReportScores(report, orgSettings);

  return search;
}

export function generateLotSearch(
  lot: Lot,
  orgSettings?: OrganisationSettings,
  lotUpdateObject?: LotUpdateObject
): Search {
  const inspectionTypes: InspectionType[] = [
    ...new Set(
      (lot.inspections ?? []).map((i) => i.reference?.type).filter((t) => !!t)
    ),
  ];

  const hasVolume = !!lotUpdateObject
    ? typeof lotUpdateObject['transient.volumeInKg'] === 'number' &&
      lotUpdateObject['transient.volumeInKg'] > 0
    : typeof lot.transient?.volumeInKg === 'number' && lot.transient?.volumeInKg > 0;

  let search: Search = {
    locationId: lot.transient?.locationId,
    supplierId: lot.suppliedByContactId,
    hasAssessment: !!lot.latestInspection,
    growerId: lot.origin?.growerContactId,
    inspectionTypes,
    hasVolume,
  };

  search = { ...search, ...buildLotArraySearch(lot) };
  if (orgSettings) {
    search.scores = generateLotScores(lot, orgSettings);
  }

  return search;
}

/******* */
// HELPERS
/******* */

export const sectionSpecificFilterFields = [
  'sort',
  'maxDays',
  'dateProperty',
  'arrivalDate',
  'arrivalDateMax',
  'search',
  'lot',
];

function getHasAssessment(entity: Order | Report): boolean {
  return (
    Object.keys(entity?.transportInspectionMap ?? {}).length > 0 ||
    Object.keys(entity?.lotInspectionMap ?? {}).length > 0
  );
}

export function getInspectionTypes(entity: Order | Report): InspectionType[] {
  const inspectionTypes: InspectionType[] = [];

  for (const mapType of [LOT_INSPECTION_MAP, TRANSPORT_INSPECTION_MAP]) {
    Object.values(entity?.[mapType] ?? {}).forEach((i: LotInspection) => {
      inspectionTypes.push(i.reference?.type);
    });
  }

  return [...new Set(inspectionTypes.filter((t) => !!t))];
}

export function areFiltersEqual(f_1: AGFilters, f_2: AGFilters): boolean {
  let f1 = removeSectionSpecificFilterFields(f_1);
  let f2 = removeSectionSpecificFilterFields(f_2);

  // remove empty fields for the comparison
  function normalizeFields(f: AGFilters) {
    if (f.hasAssessment === undefined) {
      delete f.hasAssessment;
    }
    Object.keys(f).forEach((key) => {
      if (f[key] == null) {
        delete f[key];
      }
    });
    delete (f as any).dateTabMapping;
  }

  normalizeFields(f1);
  normalizeFields(f2);

  // console.log("ARE EQUAL FILTERS?", f1, f2)

  return isEqual(f1, f2);
}

export function removeSectionSpecificFilterFields(filters: AGFilters): AGFilters {
  return omit(filters, sectionSpecificFilterFields);
}

export function computeNewFilters(
  sectionFilters: AGFilters,
  appFilters: AGFilters
): AGFilters {
  return {
    ...pick(sectionFilters, sectionSpecificFilterFields),
    ...omit(appFilters, sectionSpecificFilterFields),
  };
}

export function adaptSectionFilters(
  filters: AGFilters,
  fields: SearchField[]
): AGFilters {
  let newFilters = cloneDeep(filters);

  const keyMapping: { [key in SearchField]?: string } = {
    user: 'userId',
    // onlyLinked: 'onlyLinked',
    reportStatus: 'reportStatus',
    qcStatus: 'qcStatus',
  };

  for (const [key, mappedKey] of Object.entries(keyMapping)) {
    // @ts-ignore
    if (!fields?.includes(key)) {
      // @ts-ignore
      newFilters = omit(newFilters, mappedKey);
    }
  }

  // console.log("adaptSectionFilters", newFilters)
  return newFilters;
}

export function getLocationFilter(
  profile: UserProfile,
  lastUsedFilter?: AGFilters
): AGFilters {
  let locationFilterInit = undefined;
  const locationRestriction = hasLocationRestriction(profile);
  if (!locationRestriction) {
    locationFilterInit = lastUsedFilter?.locationId ?? undefined;
  } else {
    locationFilterInit = locationRestriction;
  }
  return { ...lastUsedFilter, locationId: locationFilterInit };
}

export function generateLotScores(lot: Lot, orgSettings: OrganisationSettings) {
  let scores: AGScoreSearch = {
    has1orLess: false,
    has2orLess: false,
    has3orLess: false,
  };

  const groupScoreSearch = orgSettings?.groupScoreSearch ?? 'group_condition';

  let inspection = lot.latestInspection;

  if (inspection?.scores?.[groupScoreSearch]?.agScore === 3) {
    scores.has3orLess = true;
  }
  if (inspection?.scores?.[groupScoreSearch]?.agScore === 2) {
    scores.has3orLess = true;
    scores.has2orLess = true;
  }
  if (inspection?.scores?.[groupScoreSearch]?.agScore === 1) {
    scores.has3orLess = true;
    scores.has2orLess = true;
    scores.has1orLess = true;
  }

  return scores;
}

export function generateOrderOrReportScores(
  newOrder: Order | Report,
  orgSettings: OrganisationSettings
) {
  let scores: AGScoreSearch = {
    has1orLess: false,
    has2orLess: false,
    has3orLess: false,
  };

  const groupScoreSearch = orgSettings?.groupScoreSearch ?? 'group_condition';

  Object.values(newOrder.lotInspectionMap ?? {}).forEach((item: LotInspection) => {
    // @ts-ignore
    if (parseInt(item?.scores?.[groupScoreSearch]?.agScore) === 3) {
      scores.has3orLess = true;
    }
    // @ts-ignore
    if (parseInt(item?.scores?.[groupScoreSearch]?.agScore) === 2) {
      scores.has3orLess = true;
      scores.has2orLess = true;
    }
    // @ts-ignore
    if (parseInt(item?.scores?.[groupScoreSearch]?.agScore) === 1) {
      scores.has3orLess = true;
      scores.has2orLess = true;
      scores.has1orLess = true;
    }
  });

  return scores;
}

export function buildSearchBoxFromOrderReport(report: Report) {
  const tokens: string[] = report.positions.map((p) => p.lotId);

  if (report.reportReference?.orderId != null) {
    tokens.push(report.reportReference.orderId);
  }

  if (report.reportReference?.externalOrderId != null) {
    tokens.push(report.reportReference.externalOrderId);
  }

  return buildSearchMap(tokens);
}

export function buildOrderArraySearch(order: Order): Search {
  const search: Search = {};

  if (!order.id) {
    return search;
  }

  search.searchbox = [
    ...new Set(
      buildSearchMap([
        ...(order.positions ?? []).map((o) => o.lotId),
        order.id,
        ...(order.reportReferences ?? []).map((r) => r.externalOrderId),
      ])
    ),
  ].filter((o) => !!o);
  // we add to the search arrays both ag's and the company's products and varieties
  // TODO: improve this
  search.searchProducts = [
    ...new Set(
      [
        ...order.positions?.map((o) => o.article?.productId),
        ...order.positions?.map((o) => o.article?.agProductId),
      ].filter((o) => !!o)
    ),
  ];
  search.searchVarieties = [
    ...new Set(
      [
        ...order.positions?.map((o) => o.article?.variety),
        ...order.positions?.map((o) => o.article?.agVariety),
      ].filter((o) => !!o)
    ),
  ];
  search.searchSuppliers = [order.contactId].filter((o) => !!o);
  search.searchBrands = [
    ...new Set(order.positions?.map((o) => o.article?.brand)),
  ].filter((o) => !!o);

  return search;
}

export function buildReportArraySearch(report: Report): Search {
  const search: Search = {};

  if (!report.id) {
    return search;
  }

  search.searchbox = buildSearchBoxFromOrderReport(report);
  // we add to the search arrays both ag's and the company's products and varieties
  search.searchProducts = [
    ...new Set(
      [
        ...report.positions?.map((o) => o.article?.productId),
        ...report.positions?.map((o) => o.article?.agProductId),
      ].filter((o) => !!o)
    ),
  ];
  search.searchVarieties = [
    ...new Set(
      [
        ...report.positions?.map((o) => o.article?.variety),
        ...report.positions?.map((o) => o.article?.agVariety),
      ].filter((o) => !!o)
    ),
  ];
  search.searchSuppliers = [report.contactId].filter((o) => !!o);
  search.searchBrands = [
    ...new Set(report.positions?.map((o) => o.article?.brand)),
  ].filter((o) => !!o);

  return search;
}

export function buildLotArraySearch(lot: Lot): Search {
  const search: Search = {};

  if (!lot.id) {
    return search;
  }

  const orderIds = lot.transfers
    .filter((t) => ['BUY', 'INTERNAL_TRANSFER', 'SELL'].includes(t.transferType))
    .map((t) => t.orderId);

  const splitLotOrderIdLinks: string[] = lot.transfers
    .filter((t) => !!t.splitLotOrderIdLink)
    .map((t) => t.splitLotOrderIdLink);

  search.searchbox = buildSearchMap([...orderIds, ...splitLotOrderIdLinks, lot.id]);

  search.searchProducts = [
    ...new Set([lot.article?.productId, lot.article?.agProductId]),
  ].filter((s) => !!s);
  search.searchVarieties = [
    ...new Set([lot.article?.agVariety, lot.article?.variety]),
  ].filter((s) => !!s);
  search.searchSuppliers = [lot.suppliedByContactId].filter((s) => !!s);
  search.searchBrands = [lot.article?.brand].filter((s) => !!s);

  return search;
}

export function buildSearchMap(tokens: string[]): string[] {
  let res =
    tokens?.map((token) => {
      if (!token) {
        return [];
      }
      if (token.indexOf('-') >= 0) {
        // Some more tokens, in case of "IO-21-1221743" we want everything after the last - eg. "1221743" to be searchable
        return [
          token,
          token.substr(token.lastIndexOf('-') + 1, token.length),
          token.substr(token.indexOf('-') + 1, token.length).replace('-', ''),
          token.substr(token.length - 6, token.length),
          token.substr(token.length - 5, token.length),
          token.substr(token.length - 4, token.length),
        ];
      } else {
        return [
          token,
          token.substr(token.length - 6, token.length),
          token.substr(token.length - 5, token.length),
          token.substr(token.length - 4, token.length),
        ];
      }
    }) ?? [];
  return Array.from(new Set(flatten(res)));
}

function flatten(arr) {
  const result = [];

  arr.forEach((i) => {
    if (Array.isArray(i)) {
      result.push(...flatten(i));
    } else {
      result.push(i);
    }
  });

  return result;
}

// FIelds
export const applyFieldFilters = (
  fields?: ProductionSiteLocation[],
  filters?: AGFilters,
  search?: string,
  dateFilter?: AGTab<QCFilterType>
): ProductionSiteLocation[] => {
  let filteredFields = cloneDeep(fields ?? []);

  const { sort, producedVarieties, productionType, partnerId, producedProducts } =
    filters ?? {};

  if ((search ?? '').length > 0) {
    filteredFields = filteredFields.filter(
      (f) =>
        f.locationId.toLowerCase().includes(search.toLowerCase()) ||
        (f.name ?? '').toLowerCase().includes(search.toLowerCase())
    );
  }

  const getLastInspectionDate = (f: ProductionSiteLocation): Date =>
    standardizeDate(
      f.lastQCDate ??
        f.latestInspectionPreview?.lastModifiedDate ??
        f.lastModifiedDate ??
        new Date(0)
    );

  if ((producedProducts ?? []).length > 0) {
    if ((producedVarieties ?? []).length > 0) {
      filteredFields = filteredFields.filter((f) =>
        producedVarieties.some((pv) =>
          f.producedVarieties?.some(
            (fpv) => fpv.agProductId === pv.agProductId && fpv.variety === pv.variety
          )
        )
      );
    } else {
      filteredFields = filteredFields.filter((f) =>
        producedProducts.some((pp) =>
          f.producedVarieties?.some((fpv) => fpv.agProductId === pp)
        )
      );
    }
  }

  if (partnerId != null) {
    filteredFields = filteredFields.filter((f) => f.associatedPartnerId == partnerId);
  }

  if (productionType != null) {
    filteredFields = filteredFields.filter((f) => f.productionType === productionType);
  }

  if (dateFilter?.maxDate != null) {
    filteredFields = filteredFields.filter(
      (f) => getLastInspectionDate(f).getTime() <= dateFilter.maxDate.getTime()
    );
  }

  if (dateFilter?.minDate != null) {
    filteredFields = filteredFields.filter(
      (f) => getLastInspectionDate(f).getTime() >= dateFilter.minDate.getTime()
    );
  }

  return filteredFields.sort(
    (a, b) =>
      (sort === 'desc' ? -1 : 1) *
      (getLastInspectionDate(a).getTime() - getLastInspectionDate(b).getTime())
  );
};

export function compileDateTabMapping(
  agTabs: AGTabs<FilterType> | undefined
): DateTabMap {
  if (!agTabs) {
    return {};
  }

  return Object.entries(agTabs).reduce(
    (acc, [tab, { range, sorting }]) => ({ ...acc, [tab]: { range, sorting } }),
    {}
  );
}
