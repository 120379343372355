import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import { chevronBackOutline, earthOutline } from 'ionicons/icons';
import React from 'react';
import i18n from '../../../ServiceI18n';
import { useLocationUtils } from '../../../hooks/useLocationUtils';

interface Props {
  name: string;
  geopoint?: firebase.firestore.GeoPoint;
}
export const LocationDetailsHeader = ({ name, geopoint }: Props) => {
  const { buildGoogleMapsUrl } = useLocationUtils();
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            text={i18n.t('General.back')}
            defaultHref="/tabs/quality-control"
            color="dark"
          >
            <IonIcon icon={chevronBackOutline} slot="icon-only" />
          </IonBackButton>
        </IonButtons>
        {geopoint && (
          <IonButtons slot="end">
            <IonRouterLink
              href={buildGoogleMapsUrl(geopoint)}
              target="_blank"
              rel="noreferrer"
            >
              <IonButton
                size="small"
                fill="clear"
                color="blue"
                onClick={(e) => e.stopPropagation()}
              >
                <IonIcon icon={earthOutline} />
              </IonButton>
            </IonRouterLink>
          </IonButtons>
        )}
        <IonTitle>
          <div>{name}</div>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
