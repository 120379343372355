import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline, send } from 'ionicons/icons';
import React from 'react';
import { Contact, Conversation, User } from './Model';
import './PageNewConversation.scss';
import i18n from './ServiceI18n';
// import ViewUser from "./ViewUser";
import { newConversation } from './DataStorage';
import withContext, { ContextProps } from './HOC/withContext';

interface Props {
  onCancel: Function;
  onSave: (conversation: Conversation) => any;

  contact?: Contact; // Optional Contact
}

interface State {
  search: string;
  selectedUsers: User[];
}

class PageNewConversation extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      selectedUsers: [],
    };
  }

  newConversation() {
    if (this.state.selectedUsers.length > 0)
      this.props.onSave(newConversation(this.props.profile, this.state.selectedUsers));
  }

  selectUser(ev, user) {
    if (ev.detail.checked) {
      this.state.selectedUsers.push(user);
      this.setState({ selectedUsers: this.state.selectedUsers });
    } else {
      this.setState({
        selectedUsers: this.state.selectedUsers.filter((u) => u.id !== user.id),
      });
    }
  }

  renderUser(user: User, i: number) {
    // console.log(user)
    return (
      <IonItem key={user.id + '-' + i}>
        {/* <IonAvatar slot="start">
        <ViewUser userId={user.id} applicationContext={this.props.applicationContext}></ViewUser>
      </IonAvatar> */}
        <IonCheckbox slot="start" onIonChange={(ev) => this.selectUser(ev, user)} />
        <IonLabel className="ion-text-capitalize">
          {user.name} <br />
          <span>{user.email}</span>
        </IonLabel>
      </IonItem>
    );
  }

  render() {
    let filterCompare = (user: User) =>
      this.state.search.length === 0 ||
      user.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0 ||
      user.email.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0;

    return (
      <div className="page-new-conversation ion-page">
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={(_) => this.props.onCancel()}>
                <IonIcon icon={closeOutline} color="dark"></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>{i18n.t('PageNewConversation.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{i18n.t('PageNewConversation.title')}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonList>
            <IonSearchbar
              mode="ios"
              onIonChange={(evt) =>
                this.setState({ ...this.state, search: evt.detail.value + '' })
              }
              placeholder={i18n.t('PageNewConversation.searchPlaceholder')}
            />
            <IonListHeader>My Organization</IonListHeader>
            {this.props.users
              ?.filter((user) => user.id !== this.props.profile.id)
              .sort((a, b) =>
                a?.name.toLocaleLowerCase() > b?.name.toLocaleLowerCase() ? 1 : -1
              )
              .filter((user) => user.isAgrinormTestUser !== true)
              .filter(filterCompare)
              .map((user, i) => this.renderUser(user, i))}

            {!!(this.props.contact && this.props.contact.users) && (
              <>
                <IonListHeader>{this.props.contact.name.toLowerCase()}</IonListHeader>
                {this.props.contact.users
                  .filter(filterCompare)
                  .sort((a, b) =>
                    a?.name.toLocaleLowerCase() > b?.name.toLocaleLowerCase() ? 1 : -1
                  )
                  .map((user, i) => this.renderUser(user, i))}
                {this.props.contact.users.length === 0 && (
                  <IonItem>No users yet</IonItem>
                )}
              </>
            )}
            {this.props.contacts?.map(
              (c) =>
                c.users?.length > 0 && (
                  <React.Fragment key={c.id}>
                    {c.users.filter(filterCompare).length > 0 && (
                      <div className="contact-divider">{c.name.toLowerCase()}</div>
                    )}
                    {c.users
                      .filter(filterCompare)
                      .sort((a, b) =>
                        a?.name.toLocaleLowerCase() > b?.name.toLocaleLowerCase()
                          ? 1
                          : -1
                      )
                      .map((user, i) => this.renderUser(user, i))}
                  </React.Fragment>
                )
            )}
          </IonList>
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton
              onClick={this.newConversation.bind(this)}
              color="primary"
              disabled={this.state.selectedUsers.length === 0}
            >
              <IonIcon icon={send} />
            </IonFabButton>
          </IonFab>
        </IonContent>
      </div>
    );
  }
}

export default withContext(PageNewConversation, ['contacts', 'users', 'profile']);
