import { firestore, functions } from './ConfigFirebase';
import {
  DateRange,
  EntityType,
  InsightReportType,
  InsightWidget,
  ProfilesFilter,
  ProfilesInsightsData,
} from './ModelInsight';
import { getUnixStartAndEndTime } from './HelperInsight';
import firebase from 'firebase/compat/app';
import { cloneDeep } from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';

const MAX_CACHE_ENTRIES = 100;
const CACHE_TTL_IN_MINUTES = 60;

export const profilesDateRange: DateRange = {
  startDate: new Date('2010-01-01'),
  endDate: new Date(Date.now()),
};

interface CachedResponse {
  response: any;
  cachedAt: number;
}

interface InsightCache {
  cacheQueue: string[];
  entries: { [key in string]: CachedResponse };
}

const isStale = (timestamp: number) => {
  const currentTimestamp = Date.now();
  return currentTimestamp - timestamp > CACHE_TTL_IN_MINUTES * 60 * 1000;
};

const normalizeRequestParams = (data) => {
  /**
   * Start/end time with second granularity leads to high cache misses.
   * We are normalizing these time ranges to have day granularity so as to maximize the number of cache hits
   */
  const normalizedParams = cloneDeep(data);
  if (data.payload?.start_time) {
    const startTime = new Date(data.payload.start_time * 1000);
    normalizedParams.payload.start_time = startTime.setHours(0, 0, 0, 0) / 1000;
  }
  if (data.payload?.end_time) {
    const endTime = new Date(data.payload.end_time * 1000);
    normalizedParams.payload.end_time = endTime.setHours(0, 0, 0, 0) / 1000;
  }
  return normalizedParams;
};

const getInsightCacheKey = (orgId: string): string => `insights-${orgId}`;

const getInsightCache = (orgId: string): InsightCache => {
  let cache = {
    cacheQueue: [],
    entries: {},
  };

  try {
    cache = JSON.parse(localStorage.getItem(getInsightCacheKey(orgId))) ?? cache;
  } catch (error) {
    console.warn(`Could not get insights cache`, error);
  }

  return cache;
};

export const getInsightsTest = async (
  functions: firebase.functions.Functions,
  data,
  orgId: string
) => {
  /**
   * Responses from the insight API are cached locally with a new cache entry stored for each set of input data.
   * Cache size,
   */
  try {
    let insightCache: InsightCache = getInsightCache(orgId);
    const normalizedRequestParams = normalizeRequestParams(data);
    const entryKey = JSON.stringify(normalizedRequestParams);

    // First check the cache and, if missing or stale, make API request
    const cachedData: CachedResponse = insightCache.entries[entryKey];
    if (cachedData && !isStale(cachedData.cachedAt)) {
      return cachedData.response;
    }
    let response;
    if (process.env.REACT_APP_INSIGHTS_API === 'local') {
      console.log('Fetching Insight from local API');
      const apiURL = 'http://127.0.0.1:8000';

      data.payload.org_id = 'specialfruit.be';

      const headers = {
        'Content-Type': 'application/json',
      };

      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${apiURL}${data.endpoint}`,
        data: data.payload,
        headers,
      };
      //console.log("Fetching insights: ", config, `took ${Date.now() - start} milliseconds`)
      const raw_res = await axios(config);
      response = {
        data: { response: raw_res.data },
      };
    } else {
      response = await functions.httpsCallable('getInsightsTest')(data);
    }

    data.payload.metric === 'incoming_bad_perc' &&
      console.log('Incoming bad perc:', response, data.payload);

    insightCache = getInsightCache(orgId); // Reloading here to avoid race conditions
    // Check if there is space in the cache. If not, evict the oldest entry
    if (insightCache.cacheQueue.length >= MAX_CACHE_ENTRIES) {
      delete insightCache.entries[insightCache.cacheQueue.shift()]; // Remove the old one
      insightCache.cacheQueue.push();
    }

    // Append new entry to the cache
    insightCache.cacheQueue.push(entryKey);
    insightCache.entries[entryKey] = { cachedAt: Date.now(), response };
    try {
      localStorage.setItem(
        getInsightCacheKey(orgId),
        JSON.stringify(insightCache as InsightCache)
      );
    } catch (error) {
      console.warn(`Could not set insights cache`, error);
    }

    return response;
  } catch (e) {
    console.error('ERROR FETCHING INSIGHTS:', data);
    throw e;
  }
};

export async function insightsProfilesApi(
  filters: ProfilesFilter,
  metric: string,
  orgId: string,
  aux_parameters?: string,
  sharedParams?: any
): Promise<ProfilesInsightsData> {
  const { start_time, end_time } = getUnixStartAndEndTime(profilesDateRange);

  let payload = {
    type: 'profile',
    metric,
    start_time,
    end_time,
    filters,
  };
  if (aux_parameters) {
    payload['aux_parameters'] = { property: aux_parameters };
  }
  if (!!sharedParams) {
    payload = {
      ...payload,
      ...sharedParams,
    };
  }
  console.log('insights profiles payload', payload);

  const result = await getInsightsTest(
    functions,
    { payload, endpoint: '/analytics/profiles' },
    orgId
  );
  return { series: result.data.response.series };
}

export async function getContactIdsWithInsights(organisationId: string): Promise<{
  buyerIds: string[];
  supplierIds: string[];
  contactIds: string[];
}> {
  const promises: Promise<{
    [key in EntityType]?: any[];
  }>[] = [
    fetchEntities({}, organisationId, 'outgoing', profilesDateRange, undefined, true),
    fetchEntities({}, organisationId, 'incoming', profilesDateRange, undefined, true),
  ];

  const responses: {
    [key in EntityType]?: any[];
  }[] = await Promise.all(promises);

  const buyerIds: string[] = responses?.[0]?.buyer ?? [];
  const supplierIds: string[] = responses?.[1]?.supplier ?? [];

  return {
    buyerIds,
    supplierIds,
    contactIds: [...buyerIds, ...supplierIds],
  };
}

export async function verifyCustomerProfilePassword(
  sharedId: string,
  password: string
) {
  return await functions.httpsCallable('verifyCustomerProfilePassword')({
    payload: {
      sharedId: sharedId,
      password: password,
    },
  });
}

export interface sharedCustomerProfile {
  orgId: string;
  customerType: string;
  customerId: string;
  customerName: string;
  password: string;
}

export async function shareCustomerProfile(shareObj: sharedCustomerProfile) {
  return await firestore.collection('sharedCustomerProfiles').add(shareObj);
}

interface SharedParams {
  isShared: boolean;
  sharedId: string;
}

export async function fetchEntities(
  entities: { [key: string]: any },
  orgId: string,
  insightGroup: InsightReportType,
  dateRange: DateRange,
  sharedParams?: SharedParams,
  isProfileEntities: boolean = false
): Promise<{ [key in EntityType]?: any[] }> {
  const { start_time, end_time } = getUnixStartAndEndTime(dateRange);

  const payload: any = {
    source: insightGroup,
    start_time,
    end_time,
    selected_entities: entities,
    ...(sharedParams ?? []),
  };
  const result = await getInsightsTest(
    functions,
    { payload, endpoint: `/analytics/entities${isProfileEntities ? '/profiles' : ''}` },
    orgId
  );
  const allEntities = result.data?.response?.entities;
  // console.log('fetchEntities ASSSSSSSSSSSSSSSSSSSSSSSSSSS', payload, allEntities);
  return allEntities;
}

export async function insightsRankApi(
  entities: { [key: string]: any },
  report: InsightWidget,
  organisationId: string,
  sharedParams?: SharedParams
) {
  const { dateRange, aggregation_interval, measured_by } = report;

  const { start_time, end_time } = getUnixStartAndEndTime(dateRange);

  let payload: any = {
    type: 'ranking',
    metric: report.metric,
    entity: report.entity,
    start_time,
    end_time,
    aggregation_interval: aggregation_interval ?? 'day',
    aux_parameters: { measured_by },
    ...(sharedParams ?? []),
  };

  let filters: any = {};

  // console.log('PAYLOAD RANKING', payload);
  Object.keys(entities).forEach((type) => (filters[type] = entities[type]));

  if (Object.keys(filters).length > 0) {
    payload.filters = filters;
  }

  const result = await getInsightsTest(
    functions,
    { payload, endpoint: '/analytics/ranking' },
    organisationId
  );

  return {
    entities: entities,
    entities_keys: Object.keys(entities),
    insight_type: report.chartType,
    metric: report.metric,
    stage: report.stage,
    data: result.data.response,
  };
}
