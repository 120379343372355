import * as Bowser from 'bowser';
import firebase, { firestore as defaultFirestore } from '../ConfigFirebase';
import { eventLogColRef } from '../DataStorage';
import { UserProfile } from '../Model';
import { isInstalledPwaSession } from '../helpers/HelperPwa';
import { Capacitor } from '@capacitor/core';

export interface BaseEvent {
  eventType: string;
  timestamp?: number;
}

interface LocalStorageStats {
  usedSpaceInBytes?: number;
  keys?: string[];
  canWrite?: boolean;
  error?: string;
}

export interface EnrichedEvent extends BaseEvent {
  userId: string;
  userEmail: string;
  orgId: string;
  browser: Bowser.Parser.BrowserDetails;
  engine: Bowser.Parser.EngineDetails;
  platform: Bowser.Parser.PlatformDetails;
  os: Bowser.Parser.OSDetails;
  displayMode: DisplayModeType;
  localStorageStats?: LocalStorageStats;
  isNativePlatform?: boolean;
}

interface EventLogger {
  log<T extends BaseEvent>(event: T, profile: UserProfile): void;
}

class FirestoreEventLogger implements EventLogger {
  private eventLogColRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private logLocally: boolean = false;

  constructor(firestore: firebase.firestore.Firestore = defaultFirestore) {
    this.eventLogColRef = eventLogColRef(firestore);
  }

  // this method writes the event log to firestore, and a trigger takes care of the actual logging afterwards
  // this way we don't have to take care of whether the user is online or offline, as this is handled by firestore
  public log<T extends BaseEvent>(event: T, profile: UserProfile): void {
    return
    
    try {
      const displayMode: DisplayModeType = getDisplayMode();
      const browserResults = Bowser.parse(navigator.userAgent);
      const eventDoc: EnrichedEvent = {
        ...event,
        timestamp: Date.now(),
        userId: profile.id,
        userEmail: profile.email,
        orgId: profile.organisationId,
        displayMode,
        ...browserResults,
      };

      // Add localStorage stats on login event
      if (event.eventType === 'LoginEvent') {
        eventDoc.localStorageStats = this.getLocalStorageStats();
        eventDoc.isNativePlatform = Capacitor.isNativePlatform()
      }

      this.logLocally && console.log('EVENT', eventDoc);
      this.eventLogColRef.add(eventDoc);
    } catch (err) {
      console.error('Error logging event', err);
    }
  }

  private getLocalStorageStats(writeTestChunkSize: number = 5000): LocalStorageStats {
    try {
      // const keys = [];

      // for (let i = 0; i < localStorage.length; i++) {
      //   keys.push(localStorage.key(i));
      // }

      // Test whether it's possible to write into the localStorage
      // const testString = '1'.repeat(writeTestChunkSize);
      // const testKey = 'spaceTest';
      // let canWrite: boolean = false;
      // let error: string;
      // try {
      //   localStorage.setItem(testKey, testString);
      //   localStorage.removeItem(testKey);
      //   canWrite = true;
      // } catch (err) {
      //   error = err;
      // }

      return {
        usedSpaceInBytes: JSON.stringify(localStorage).length,
        // keys,
        // canWrite,
        // error,
      };
    } catch (error) {
      console.log(`Could not extract localStorage stats`, error);
      return {
        error,
      };
    }
  }
}

const eventLogger = new FirestoreEventLogger();
export default eventLogger;

/******* */
// Helpers
/******* */
export type DisplayModeType = 'BROWSER_TAB' | 'STANDALONE';

export function getDisplayMode(): DisplayModeType {
  return isInstalledPwaSession() ? 'STANDALONE' : 'BROWSER_TAB';
}
