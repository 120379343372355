import {
  IonBadge,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonListHeader,
  useIonPopover,
} from '@ionic/react';
import {
  alertOutline,
  checkmarkDoneOutline,
  checkmarkOutline,
  ellipsisHorizontal,
  skullOutline,
  thumbsDownOutline,
} from 'ionicons/icons';
import React, { useState, useEffect, useContext } from 'react';
import { ctxOrg } from './App';
import { firestore } from './ConfigFirebase';
import { updateLotStatus } from './DataReport';
import { usePrevious } from './HelperHooks';
import { LotInspection } from './InspectionModel';
import { Order, Report } from './Model';
import { userHasPermission } from './PermissionsService';

import './ViewInspectionStatus.scss';

export const Status = ({
  title,
  inspection,
  profile,
  allowStatusEdit = false,
  order = undefined,
  report = undefined,
  supplyChainLot = false,
}: {
  title;
  inspection: LotInspection;
  profile;
  allowStatusEdit;
  order?: Order;
  report?: Report;
  supplyChainLot?: boolean;
}) => {
  const update = async (status) => {
    // updateAssessmentStatus(firestore, profile, {...assessment, status: status})
    if (
      !(order ?? report) ||
      !(order ?? report).lotInspectionMap[inspection.reference.lotId]
    ) {
      return;
    }

    updateLotStatus(firestore, profile, order, inspection, status, report);

    setTitle(status);
    title = status;
    dismiss();
  };

  const [innerTitle, setTitle] = useState(title);
  const prevTitle = usePrevious(title);
  const org = useContext(ctxOrg);

  useEffect(() => {
    if (prevTitle !== title) {
      setTitle(title);
    }
  }, [title, prevTitle]);

  const getColor = () => {
    switch (innerTitle) {
      case 'APPROVED':
      case 'COMPLETED':
      case 'SETTLED':
        return 'primary';
      case 'REJECTED':
        return 'danger';
      default:
        return 'warning';
    }
  };

  const hasCommercialStatusPermissions =
    userHasPermission(profile, 'WRITE', 'COMMERCIAL_LOT_STATUS') &&
    userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS');
  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    onClick: (status) => update(status),
    org,
    hasCommercialStatusPermissions,
  });

  return (
    <IonBadge
      className={getColor() + ' view-assessment-status'}
      color="white"
      onClick={(e) => {
        // if (allowStatusEdit && profile.userRoles.indexOf("COMMERCIAL") >= 0) {
        if (allowStatusEdit && hasCommercialStatusPermissions) {
          present({ event: e.nativeEvent, mode: 'md' });
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {innerTitle}{' '}
      {allowStatusEdit && hasCommercialStatusPermissions && (
        <IonIcon icon={ellipsisHorizontal} />
      )}
    </IonBadge>
  );
};

// const Status = React.memo(STA, (prev, next) => {
//   return (
//     prev.title === next.title
//   )
// })

const PopoverList = ({ onHide, onClick, org, hasCommercialStatusPermissions }) => {
  let inspectionStatusList = org?.settings?.inspectionStatusList ?? [];

  const getIcon = (color) => {
    switch (color) {
      case 'warning':
        return alertOutline;
      case 'red':
        return skullOutline;
      default:
        return alertOutline;
    }
  };

  return (
    <IonList>
      {hasCommercialStatusPermissions && (
        <>
          <IonListHeader>Approval Workflow</IonListHeader>
          <IonItem button onClick={(_) => onClick('SETTLED')}>
            <IonIcon icon={checkmarkDoneOutline} className="popup-icon" slot="start" />
            SETTLED
          </IonItem>
          <IonItem button onClick={(_) => onClick('APPROVED')}>
            <IonIcon icon={checkmarkOutline} className="popup-icon" slot="start" />
            Approve
          </IonItem>
          <IonItem button onClick={(_) => onClick('REJECTED')}>
            <IonIcon icon={thumbsDownOutline} className="popup-icon" slot="start" />
            Reject
          </IonItem>
          {inspectionStatusList.map((status) => (
            <IonItem button onClick={(_) => onClick(status.label.toUpperCase())}>
              <IonIcon
                icon={getIcon(status.color)}
                className="popup-icon"
                slot="start"
              />
              {status.label}
            </IonItem>
          ))}
        </>
      )}

      <IonItemDivider />

      {/* {hasPermission(profile, 'WRITE', 'ASSESSMENT') && <IonItem button onClick={_=>onClick("OPEN")}>
        <IonIcon icon={lockOpenOutline} className="popup-icon" slot="start" />
        Reopen report
      </IonItem>} */}

      {/* <IonItem button onClick={_=>onClick("COMPLETED")}>
        <IonIcon icon={checkmarkDoneOutline} className="popup-icon" slot="start" />
        Mark as completed
      </IonItem> */}
      <IonItem lines="none" detail={false} button onClick={onHide}>
        Close Menu
      </IonItem>
    </IonList>
  );
};
