import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import { SharedFieldReport } from '../../InspectionModel';
import {
  getFieldReportSharesSnapshot,
  getSharedFieldReportSnapshot,
} from './sharedFieldReport.service';
import { SharedTo } from '../../Model';

export function useGetSharedFieldReport(
  firestore: firebase.firestore.Firestore,
  sharedReportId: string
) {
  const [sharedFieldReport, setSharedFieldReport] = useState<
    SharedFieldReport | undefined
  >();
  const [error, setError] = useState<firebase.firestore.FirestoreError>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = getSharedFieldReportSnapshot(
      firestore,
      sharedReportId,
      (res) => {
        setSharedFieldReport(res);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);

  return { isLoading, sharedFieldReport, error };
}

export function useGetFieldReportShares(
  firestore: firebase.firestore.Firestore,
  locationId: string
) {
  const [sharedTo, setSharedTo] = useState<SharedTo[]>([]);
  const [error, setError] = useState<firebase.firestore.FirestoreError>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = getFieldReportSharesSnapshot(
      firestore,
      locationId,
      (res) => {
        setSharedTo(res);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);

  return { isLoading, sharedTo, error };
}
