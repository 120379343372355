import { ToastOptions } from '@ionic/react';

export const standardToastOptions = (message: string): ToastOptions => ({
  message,
  position: 'top',
  color: 'dark',
  duration: 3000,
  buttons: [{ text: 'Got it', role: 'cancel' }],
});

export const fetchEntityErrorMessage = (entity: string): string => {
  return `Problem fetching ${entity}, please refresh the page and try again`;
};

export async function presentStandardToast(
  toastController: {
    create(options: ToastOptions): Promise<HTMLIonToastElement>;
  },
  message: string,
  duration: number = 3000,
  color: string = 'danger',
  position: 'top' | 'bottom' | 'middle' = 'top',
  cssClass?: string
) {
  const toast = await toastController.create({
    message,
    cssClass,
    position,
    color,
    duration,
    buttons: [
      {
        text: 'Got it',
        role: 'cancel',
      },
    ],
  });

  toast.present().then();
}
