import { cloneDeep, isEqual } from 'lodash';
import {
  LotInspection,
  InspectionReference,
  InspectionScore,
  InspectionStatus,
  QuestionInput,
  LotProperties,
  Inspection,
  ProductionSiteInspectionReference,
} from '../InspectionModel';
import { IArticle, Picture, UserProfile } from '../Model';
import eventLogger, { BaseEvent } from './common';
import { InspectableObjectType } from '../generated/openapi/core';

interface GenericInspectionEvent extends BaseEvent {
  reference?: InspectionReference | ProductionSiteInspectionReference;
  article?: IArticle;
  schemaId?: string;
  schemaVersion?: string;
  locationId?: string;
  pictures?: Picture[];
  deletedPictures?: Picture[];
  status?: InspectionStatus;
  lotProperties?: LotProperties;
  userInputs?: { [key in string]: QuestionInput };
  scores?: { [key in string]: InspectionScore };
  objectType: InspectableObjectType;
}

export interface InspectionChanges {
  locationId?: string;
  pictures?: Picture[];
  deletedPictures?: Picture[];
  status?: InspectionStatus;
  lotProperties?: LotProperties;
  userInputs?: { [key in string]: QuestionInput };
  scores?: { [key in string]: InspectionScore };
}

interface OpenInspectionEvent extends GenericInspectionEvent {
  eventType: 'OpenInspectionEvent';
}

interface CloseInspectionEvent extends GenericInspectionEvent {
  eventType: 'CloseInspectionEvent';
}

interface ModifyLocalInspectionEvent extends GenericInspectionEvent {
  eventType: 'ModifyLocalInspectionEvent';
}

interface ModifyCloudInspectionEvent extends GenericInspectionEvent {
  eventType: 'ModifyCloudInspectionEvent';
}

function getInspectionInfo(inspection: Inspection): Partial<GenericInspectionEvent> {
  const {
    reference,
    schemaId,
    schemaVersion,
    scores,
    userInputs,
    status,
    pictures,
    locationId,
    objectType,
  } = inspection;

  const { lotProperties } = inspection as LotInspection;

  const event: Partial<GenericInspectionEvent> = {
    reference,
    schemaId,
    schemaVersion,
    lotProperties,
    userInputs,
    status,
    pictures,
    locationId,
    scores,
    objectType,
    article: lotProperties?.article,
  };
  return cloneDeep(event);
}

function getInspectionChanges(
  inspection: Inspection,
  inspectionChanges: InspectionChanges
): Partial<GenericInspectionEvent> {
  const { reference, schemaId, schemaVersion } = inspection ?? {};
  const {
    locationId,
    pictures,
    deletedPictures,
    status,
    lotProperties,
    userInputs,
    scores,
  } = inspectionChanges ?? {};
  return cloneDeep({
    reference,
    schemaId,
    schemaVersion,
    locationId,
    pictures,
    deletedPictures,
    status,
    lotProperties,
    userInputs,
    scores,
    article: (inspection as LotInspection)?.lotProperties?.article,
  });
}

export function createOpenInspectionEvent(inspection: Inspection): OpenInspectionEvent {
  return {
    ...getInspectionInfo(inspection),
    eventType: 'OpenInspectionEvent',
  } as OpenInspectionEvent;
}

export function createCloseInspectionEvent(
  inspection: Inspection
): CloseInspectionEvent {
  return {
    ...getInspectionInfo(inspection),
    eventType: 'CloseInspectionEvent',
  } as CloseInspectionEvent;
}

export function createModifyLocalInspectionEvent(
  inspection: Inspection,
  inspectionChanges: InspectionChanges
): ModifyLocalInspectionEvent {
  return {
    ...getInspectionChanges(inspection, inspectionChanges),
    eventType: 'ModifyLocalInspectionEvent',
  } as ModifyLocalInspectionEvent;
}

export function createModifyCloudInspectionEvent(
  inspection: Inspection
): ModifyCloudInspectionEvent {
  return {
    ...getInspectionInfo(inspection),
    eventType: 'ModifyCloudInspectionEvent',
  } as ModifyCloudInspectionEvent;
}

export function getInspectionDelta(
  oldInspection: Inspection,
  newInspection: Inspection
): InspectionChanges {
  const changes: InspectionChanges = {};

  ['status', 'locationId'].forEach((param) => {
    if (oldInspection?.[param] !== newInspection?.[param]) {
      changes[param] = newInspection[param];
    }
  });

  const newPictures: Picture[] = (newInspection?.pictures ?? []).filter((pic) => {
    const samePic = (oldInspection?.pictures ?? []).find((p) => p.id === pic.id);
    return !samePic || !isEqual(samePic, pic);
  });
  if (newPictures.length) {
    changes.pictures = newPictures;
  }

  const deletedPictures: Picture[] = (oldInspection?.pictures ?? []).filter(
    (pic) => !(newInspection?.pictures ?? []).find((p) => p.id === pic.id)
  );
  if (deletedPictures.length) {
    changes.deletedPictures = deletedPictures;
  }

  ['lotProperties', 'userInputs', 'scores'].forEach((prop) => {
    const newProps: any = {};
    Object.entries(newInspection?.[prop] ?? {}).forEach(([param, value]) => {
      if (!isEqual(value, (oldInspection?.[prop] ?? {})[param])) {
        newProps[param] = value;
      }
    });
    if (Object.keys(newProps).length) {
      changes[prop] = newProps;
    }
  });

  return cloneDeep(changes);
}

export function logLocalInspectionModificationEvent(
  prevInspection: Inspection,
  newInspection: Inspection,
  profile: UserProfile
) {
  const inspectionChanges: InspectionChanges = getInspectionDelta(
    prevInspection,
    newInspection
  );
  if (Object.keys(inspectionChanges).length) {
    eventLogger.log(
      createModifyLocalInspectionEvent(prevInspection, inspectionChanges),
      profile
    );
  }
}
