import {
  IonBadge,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import withContext, { ContextProps } from './HOC/withContext';
import PageFilters from './PageFilters';
import './PageTimeline.scss';
import { searchFields } from './SearchService';

interface Props {
  onCancel?: Function;
}

interface State {
  inited: true;
}

class PageTimeline extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      inited: true,
    };
  }

  render() {
    const title = 'Timeline';

    return (
      <IonPage className="page-timeline ion-page">
        <IonHeader translucent={true}>
          <IonToolbar>
            {/* <IonButtons slot="end">
            <IonButton onClick={_ => this.props.onCancel?.()}>
              <IonIcon icon={closeOutline} color="dark"></IonIcon>
            </IonButton>
          </IonButtons> */}
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="wrapper">
            <ul className="sessions">
              <li>
                <div className="icon"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:55</IonBadge>
                  <IonBadge>INSPECTION DONE</IonBadge>
                </div>
                <p>
                  <div>
                    <b>LOT234566</b> for{' '}
                    <IonBadge color="medium">INCOMING ORDER</IonBadge>{' '}
                    <b>IO-22-322124</b> has been inspected by <b>Katrien Govarts</b>{' '}
                    with score
                  </div>
                  <div className="view-assessment-score INLINE">
                    <div
                      data-tip="score-group_appearance"
                      className="score group_appearance score-4 score-custom-4"
                    >
                      <span className="label">Appearance</span>
                      <span className="numerical">4</span>
                    </div>
                    <div
                      data-tip="score-group_condition"
                      className="score group_condition score-2 score-custom-2"
                    >
                      <span className="label">Condition</span>
                      <span className="numerical">2</span>
                    </div>
                  </div>
                </p>
                <p>
                  <div className="cover">
                    <div className="card-images  one-row">
                      <img
                        alt=""
                        data-tip="picture-0"
                        src="https://firebasestorage.googleapis.com/v0/b/agrinorm-connect.appspot.com/o/assessment-images%2Fspecialfruit.be%2FUPCOMING%3B%3BL22-122749383%3B%3B2022.12.27%2Fthumbnails%2F24055398-e91a-4016-a085-697e1855033b_400x400?alt=media&amp;token=c0a5d73d-927d-4d63-bd83-7db52d1ed9ca"
                        className="render-picture culo loaded md hydrated"
                      ></img>
                      <img
                        alt=""
                        data-tip="picture-1"
                        src="https://firebasestorage.googleapis.com/v0/b/agrinorm-connect.appspot.com/o/assessment-images%2Fspecialfruit.be%2FUPCOMING%3B%3BL22-122749383%3B%3B2022.12.27%2Fthumbnails%2Fa2caa28d-0dfb-472c-9347-37bb2c5a4e7c_400x400?alt=media&amp;token=84d2e94b-09f9-4ce4-b5ee-729b01386e2d"
                        className="render-picture culo loaded md hydrated"
                      ></img>
                      <img
                        alt=""
                        data-tip="picture-2"
                        src="https://firebasestorage.googleapis.com/v0/b/agrinorm-connect.appspot.com/o/assessment-images%2Fspecialfruit.be%2FUPCOMING%3B%3BL22-122749383%3B%3B2022.12.27%2Fthumbnails%2F443788b1-f1ec-47c8-9988-8ab434ad75ec_400x400?alt=media&amp;token=59924837-9a7d-4fe5-b564-f3581862b131"
                        className="render-picture culo loaded md hydrated"
                      ></img>
                      <img
                        alt=""
                        data-tip="picture-3"
                        src="https://firebasestorage.googleapis.com/v0/b/agrinorm-connect.appspot.com/o/assessment-images%2Fspecialfruit.be%2FUPCOMING%3B%3BL22-122749383%3B%3B2022.12.27%2Fthumbnails%2F01ae2536-bf87-4448-9f77-efa1ab6bdcc9_400x400?alt=media&amp;token=dff41438-dc3d-4b26-a6a0-8db86f214229"
                        className="render-picture culo loaded md hydrated"
                      ></img>
                      <img
                        alt=""
                        data-tip="picture-4"
                        src="https://firebasestorage.googleapis.com/v0/b/agrinorm-connect.appspot.com/o/assessment-images%2Fspecialfruit.be%2FUPCOMING%3B%3BL22-122749383%3B%3B2022.12.27%2Fthumbnails%2F9d8176e7-ee32-4fcb-bd12-d1a0eb9a0f93_400x400?alt=media&amp;token=11687f50-35bc-418d-8520-d29f7f8f49d0"
                        className="render-picture culo loaded md hydrated"
                      ></img>
                      <img
                        alt=""
                        data-tip="picture-5"
                        src="https://firebasestorage.googleapis.com/v0/b/agrinorm-connect.appspot.com/o/assessment-images%2Fspecialfruit.be%2FUPCOMING%3B%3BL22-122749383%3B%3B2022.12.27%2Fthumbnails%2F7baa7448-9a82-4499-bd8a-6230fc652067_400x400?alt=media&amp;token=e93d05da-768a-4226-9c62-92d410e0c0d9"
                        className="render-picture culo loaded md hydrated"
                      ></img>
                    </div>
                  </div>
                </p>
              </li>
              <li>
                <div className="icon success"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:35</IonBadge>
                  <IonBadge color="tertiary">REPORT SHARED</IonBadge>
                </div>
                <p>
                  <div>
                    Report for order <b>IO-22-235543</b> has been shared to{' '}
                    <b>3 emails</b> ✉️
                  </div>
                </p>
              </li>
              <li>
                <div className="icon"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:22</IonBadge>
                  <IonBadge>INSPECTION DONE</IonBadge>
                </div>
                <p>
                  <div>
                    <b>LOT234577</b> in <IonBadge color="medium">STOCK</IonBadge> has
                    been inspected by <b>Katrien Govarts</b> with score
                  </div>
                  <div className="view-assessment-score INLINE">
                    <div
                      data-tip="score-group_appearance"
                      className="score group_appearance score-4 score-custom-4"
                    >
                      <span className="label">Appearance</span>
                      <span className="numerical">4</span>
                    </div>
                    <div
                      data-tip="score-group_condition"
                      className="score group_condition score-3 score-custom-3"
                    >
                      <span className="label">Condition</span>
                      <span className="numerical">3</span>
                    </div>
                  </div>
                </p>
              </li>
              <li>
                <div className="icon danger"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:15</IonBadge>
                  <IonBadge color="danger">REDO ORDER</IonBadge>
                </div>
                <p>
                  <div>
                    Order <b>IO-22-235543</b> has been marked for re-doing by{' '}
                    <b>Emiliano Onorati</b>
                  </div>
                </p>
              </li>
              <li>
                <div className="icon"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:05</IonBadge>
                  <IonBadge>ORDER DONE</IonBadge>
                </div>
                <p>
                  <div>
                    Inspections for order <b>IO-22-235543</b> have been completed by{' '}
                    <b>Emiliano Onorati</b>
                  </div>
                </p>
              </li>

              <li>
                <div className="icon"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:55</IonBadge>
                  <IonBadge>INSPECTION DONE</IonBadge>
                </div>
                <p>
                  <div>
                    <b>LOT234566</b> for{' '}
                    <IonBadge color="medium">INCOMING ORDER</IonBadge>{' '}
                    <b>IO-22-322124</b> has been inspected by <b>Katrien Govarts</b>{' '}
                    with score
                  </div>
                  <div className="view-assessment-score INLINE">
                    <div
                      data-tip="score-group_appearance"
                      className="score group_appearance score-4 score-custom-4"
                    >
                      <span className="label">Appearance</span>
                      <span className="numerical">4</span>
                    </div>
                    <div
                      data-tip="score-group_condition"
                      className="score group_condition score-2 score-custom-2"
                    >
                      <span className="label">Condition</span>
                      <span className="numerical">2</span>
                    </div>
                  </div>
                </p>
              </li>
              <li>
                <div className="icon success"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:35</IonBadge>
                  <IonBadge color="tertiary">REPORT SHARED</IonBadge>
                </div>
                <p>
                  <div>
                    Report for order <b>IO-22-235543</b> has been shared to{' '}
                    <b>3 emails</b> ✉️
                  </div>
                </p>
              </li>
              <li>
                <div className="icon"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:22</IonBadge>
                  <IonBadge>INSPECTION DONE</IonBadge>
                </div>
                <p>
                  <div>
                    <b>LOT234577</b> in <IonBadge color="medium">STOCK</IonBadge> has
                    been inspected by <b>Katrien Govarts</b> with score
                  </div>
                  <div className="view-assessment-score INLINE">
                    <div
                      data-tip="score-group_appearance"
                      className="score group_appearance score-4 score-custom-4"
                    >
                      <span className="label">Appearance</span>
                      <span className="numerical">4</span>
                    </div>
                    <div
                      data-tip="score-group_condition"
                      className="score group_condition score-3 score-custom-3"
                    >
                      <span className="label">Condition</span>
                      <span className="numerical">3</span>
                    </div>
                  </div>
                </p>
              </li>
              <li>
                <div className="icon danger"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:15</IonBadge>
                  <IonBadge color="danger">REDO ORDER</IonBadge>
                </div>
                <p>
                  <div>
                    Order <b>IO-22-235543</b> has been marked for re-doing by{' '}
                    <b>Emiliano Onorati</b>
                  </div>
                </p>
              </li>
              <li>
                <div className="icon"></div>
                <div className="time">
                  <IonBadge color="light-medium">13:05</IonBadge>
                  <IonBadge>ORDER DONE</IonBadge>
                </div>
                <p>
                  <div>
                    Inspections for order <b>IO-22-235543</b> have been completed by{' '}
                    <b>Emiliano Onorati</b>
                  </div>
                </p>
              </li>
            </ul>
            <PageFilters
              organisation={this.props.organisation}
              searchFields={searchFields.PageQualityControl['ORDERS']}
              profile={this.props.profile}
              filters={undefined}
              onCancel={() => {
                return;
              }}
              isComponent={true}
              onFilter={(filters) => {
                console.log('filters', filters);
                return filters;
              }}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withContext(PageTimeline, ['profile', 'organisation']);
