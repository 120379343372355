import React, { KeyboardEventHandler, useEffect } from 'react';
import { getValueAndUnitFromLocator } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import CreatableSelect from 'react-select/creatable';
import './CFloatList.scss';
import { isPlatform } from '@ionic/react';
import { useInspectionContext } from '../context/InspectionContextProvider';

const regExpNumber = /^[+-]?((\d*(\.\d*)?)|(\.\d+))$/;
// const regExpNumberInt = /-?\d*/;

// --------------------------------------------------------------------------------------------------------
// CFloatList
// --------------------------------------------------------------------------------------------------------
interface ICFloatList {
  inputLocator: UserInputLocator;
  isInt?: boolean;
}

interface Option {
  label: string;
  value: string;
}

export const CFloatList = (props: ICFloatList) => {
  const { inputLocator } = props;

  const createOption = (label: string, i: number) => ({
    label,
    value: label + i,
  });

  const components = {
    DropdownIndicator: null,
  };

  const { inspection, updateInspection } = useInspectionContext();
  let values: number[] = getValueAndUnitFromLocator(inputLocator, inspection).value;

  // HACK: there have been cases where "values" is not an array, but a number, causing the app to crash.
  // We are still investigating the root cause, but this hack should at least prevent the crash
  if (!Array.isArray(values)) {
    values = [];
  }

  const [value, setValue] = React.useState<Option[]>(
    values?.map((v, i) => createOption(`${v}`, i)) ?? []
  );
  const [inputValue, setInputValue] = React.useState('');

  // Cleans up the values in the select if values don't exist in the inspection anymore
  useEffect(() => {
    if (values?.length === 0 && value?.length > 0) {
      setValue([]);
    }
  }, [values]);

  const updateValue = (value) => {
    updateInspection(
      value.map((v) => +v.label),
      inputLocator
    );
    // console.log(updatedInspection)
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        setValue((prev) => {
          if (isNaN(parseFloat(inputValue))) {
            return prev;
          }
          const newVal = [
            ...prev,
            createOption(parseFloat(inputValue) + '', prev?.length),
          ];
          updateValue(newVal);
          return newVal;
        });
        setInputValue('');
        event.preventDefault();
        break;

      case 'Backspace':
        break;

      default:
        // console.log('event.key', event.key, inputValue)
        // if (props.isInt) {
        //   if (!regExpNumberInt.test(inputValue + event.key)) {
        //     event.preventDefault();
        //   }
        // } else if (!regExpNumber.test(inputValue + event.key)) {
        //   event.preventDefault();
        // }
        if (!regExpNumber.test(inputValue + event.key)) {
          event.preventDefault();
        }
    }
  };

  const handleAndroidChange = (e) => {
    const v = e.currentTarget.value.replace(/[^0-9$.,-]/g, '') as string;
    const map = v
      .split(',')
      .map((val) => parseFloat(val))
      .filter((o) => !!o);
    console.log(map);
    updateInspection(map, inputLocator);
    e.currentTarget.value = v;
  };

  const onBlurAndroid = (e) => {
    const v = e.currentTarget.value.replace(/[^0-9$.,-]/g, '') as string;
    const map = v
      .split(',')
      .map((val) => parseFloat(val))
      .filter((o) => !!o);
    console.log(map);
    e.currentTarget.value = map.join(',');
  };

  // console.log('values ', values)

  return (
    <div className="c-float-list">
      {isPlatform('android') ? (
        !values?.length ? (
          <input
            className="input"
            inputMode="text"
            type="text"
            onChange={handleAndroidChange}
            onBlur={onBlurAndroid}
            placeholder="Example: 18, 34.5, -12, 44.99"
          />
        ) : (
          <input
            className="input"
            inputMode="text"
            type="text"
            defaultValue={values?.join(',') ?? ''}
            onChange={handleAndroidChange}
            onBlur={onBlurAndroid}
            placeholder="Example: 18, 34.5, -12, 44.99"
          />
        )
      ) : (
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuPortalTarget={document.body}
          noOptionsMessage={() => null}
          formatCreateLabel={(label) => `Add ${label}`}
          // menuIsOpen={false}
          classNamePrefix={'react-select'}
          className={'react-select'}
          onChange={(newValue) => {
            //@ts-ignore
            setValue(newValue);
            updateValue(newValue);
          }}
          value={value}
          onInputChange={(newValue) => {
            setInputValue(newValue);
          }}
          onKeyDown={handleKeyDown}
          placeholder="Example: 18, 34.5, -12, 44.99"
        />
      )}
    </div>
  );
};
