import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import en from './languages/en.json';

import gbFlag from './assets/flags/gb.png';
import usFlag from './assets/flags/us.png';
import esFlag from './assets/flags/es.png';

export const supportedLanguages = [
  { name: 'English', code: 'en', flags: [gbFlag, usFlag] },
  { name: 'Español (próximamente)', code: 'es', flags: [esFlag] },
];

export let resources = {
  en: { common: en },
};

i18n.use(reactI18nextModule as any).init({
  interpolation: {
    escapeValue: false,
  },

  debug: true,

  resources,

  fallbackLng: 'en', //Fallback Language: English

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
} as any);

// check for missing keys
export function warnInconsistentKeys() {
  let knownPaths = {};

  let langKnownPaths = {};

  let scanPaths = (
    lang: string,
    prefix: string | undefined,
    obj: { [name: string]: any }
  ) => {
    for (let key of Object.keys(obj)) {
      let path = prefix ? prefix + ' / ' + key : key;
      let value = obj[key];

      if (typeof value === 'object') {
        scanPaths(lang, path, value);
      } else {
        langKnownPaths[lang][path] = true;
        knownPaths[path] = true;
      }
    }
  };

  // get paths of all keys that exist
  for (let resKey in resources) {
    let data = resources[resKey].common;
    langKnownPaths[resKey] = {};
    scanPaths(resKey, undefined, data);
  }

  let incompleteLangs: { name: string; missingKeys: string[] }[] = [];

  // get missing paths for each language
  for (let resKey in resources) {
    let pathsCopy = { ...knownPaths };

    for (let path in langKnownPaths[resKey]) {
      delete pathsCopy[path];
    }

    let missingKeys = Object.keys(pathsCopy);
    if (missingKeys.length > 0) {
      incompleteLangs.push({ name: resKey.toUpperCase(), missingKeys });
    }
  }

  return incompleteLangs;
}

// for (let incompleteLang of warnInconsistentKeys()) {
//   console.warn('lang', incompleteLang.name, 'missing keys', incompleteLang.missingKeys);
// }

export default i18n;
