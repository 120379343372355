import {
  FIELD_DATE_SORTING_FIELD,
  LOT_DATE_SORTING_FIELD,
  ORDER_DATE_SORTING_FIELD,
  REPORT_DATE_SORTING_FIELD,
} from './GlobalConstants';
import { getOffsetDate } from './HelperUtils';
import { TimeLabel } from './ModelInsight';

export type QCFilterType =
  | 'SUPPLY-CHAIN'
  | 'ORDERS'
  | 'BATCHES'
  | 'STOCK'
  | 'FIELD_INSPECTIONS';
export type ReportFilterType = 'FEEDBACK' | 'UPCOMING' | 'MY-REPORTS';

export type FilterType = ReportFilterType | QCFilterType;

export type AGTabs<T extends FilterType> = {
  [key in T]?: AGTab<T>;
};

export interface AGTab<T extends FilterType> {
  id: T;
  label: string;
  labelMobile: string;
  minDate: Date;
  maxDate: Date;
  range?: TimeLabel;
  sorting: 'desc' | 'asc';
  dateLabel: string;
  dateProperty: string;
}

// Initial state of the tabs
export const defaultAgTabs: AGTabs<QCFilterType> = {
  'ORDERS': {
    id: 'ORDERS',
    label: 'Orders',
    labelMobile: 'ORDER',
    minDate: getOffsetDate(-100),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'ARRIVED',
    dateProperty: ORDER_DATE_SORTING_FIELD,
  },
  'BATCHES': {
    id: 'BATCHES',
    label: 'Batches',
    labelMobile: 'BATCH',
    minDate: getOffsetDate(-10),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'ARRIVED/INSPECTED',
    dateProperty: LOT_DATE_SORTING_FIELD,
  },
  'SUPPLY-CHAIN': {
    id: 'SUPPLY-CHAIN',
    label: 'Supply chain',
    labelMobile: 'SC BATCH',
    minDate: getOffsetDate(-10),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'CREATED',
    dateProperty: LOT_DATE_SORTING_FIELD,
  },

  // 'MY_TODOS': {
  //   id: 'MY_TODOS',
  //   label: 'My Todos',
  //   labelMobile: 'Todos',
  //   minDate: getOffsetDate(-30),
  //   maxDate: new Date(),
  //   sorting: 'desc',
  //   dateLabel: 'ARRIVED/INSPECTED',
  //   dateProperty: 'relevancyDate'
  // },

  // 'INCOMING': {
  //   id: 'INCOMING',
  //   label: 'Incoming',
  //   labelMobile: 'Incoming',
  //   minDate: getOffsetDate(-100),
  //   maxDate: new Date(),
  //   sorting: 'desc',
  //   dateLabel: 'ARRIVED',
  //   dateProperty: 'fulfilmentDate'
  // },
  'STOCK': {
    id: 'STOCK',
    label: 'Stock TODO',
    labelMobile: 'TODO',
    minDate: getOffsetDate(-10),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'TODO CREATION',
    dateProperty: LOT_DATE_SORTING_FIELD,
  },
  'FIELD_INSPECTIONS': {
    id: 'FIELD_INSPECTIONS',
    label: 'Field',
    labelMobile: 'FIELD',
    minDate: getOffsetDate(-15),
    maxDate: getOffsetDate(+1),
    sorting: 'desc',
    dateLabel: 'LAST INSPECTED',
    dateProperty: FIELD_DATE_SORTING_FIELD,
  },
  // 'OUTGOING': {
  //   id: 'OUTGOING',
  //   label: 'Outgoing',
  //   labelMobile: 'Outgoing',
  //   minDate: new Date(),
  //   maxDate: getOffsetDate(7),
  //   sorting: 'asc',
  //   dateLabel: 'DISPATCH',
  //   dateProperty: 'fulfilmentDate'
  // },
  // 'UPCOMING': {
  //   id: 'UPCOMING',
  //   label: 'Upcoming',
  //   labelMobile: 'Upcoming',
  //   minDate: getOffsetDate(-30),
  //   maxDate: new Date(),
  //   sorting: 'desc',
  //   dateLabel: 'CREATED',
  //   dateProperty: 'lastModifiedDate'
  // }
};

export const qcTabsOrderMap: { [key in QCFilterType]: number } = {
  'SUPPLY-CHAIN': 1,
  'ORDERS': 2,
  'BATCHES': 3,
  'STOCK': 4,
  'FIELD_INSPECTIONS': 5,
};

export const agTabsReports: AGTabs<ReportFilterType> = {
  'UPCOMING': {
    id: 'UPCOMING',
    label: 'Upcoming',
    labelMobile: 'Upcoming',
    minDate: getOffsetDate(-700),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'SHARED',
    dateProperty: REPORT_DATE_SORTING_FIELD,
  },
  'MY-REPORTS': {
    id: 'MY-REPORTS',
    label: 'My Reports',
    labelMobile: 'Reports',
    minDate: getOffsetDate(-30),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'CREATED',
    dateProperty: REPORT_DATE_SORTING_FIELD,
  },
  'FEEDBACK': {
    id: 'FEEDBACK',
    label: 'Feedback',
    labelMobile: 'Feedback',
    minDate: getOffsetDate(-7),
    maxDate: new Date(),
    sorting: 'desc',
    dateLabel: 'SHARED',
    dateProperty: REPORT_DATE_SORTING_FIELD,
  },
};

export const QCFilterTypeArray: QCFilterType[] = ['ORDERS', 'BATCHES'];
export const ReportFilterTypeArray: ReportFilterType[] = ['MY-REPORTS', 'UPCOMING'];
