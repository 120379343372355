import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from '@ionic/react';
import {
  archiveOutline,
  arrowBackOutline,
  ellipse,
  ellipsisVertical,
  peopleCircle,
  person,
  personCircle,
  searchOutline,
} from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { firestore } from './ConfigFirebase';
import {
  archiveConversation,
  conversationTitle,
  createConversation,
  getAllConversationsSnapshot,
  messagesUnread,
  unarchiveConversation,
} from './DataStorage';
import { Conversation } from './Model';
import './PageInbox.scss';
import PageNewConversation from './PageNewConversation';
import i18n from './ServiceI18n';

import { ctxProfile, ctxUsers } from './App';
import iconNewMessage from './assets/icons/icons-pro/Communication/10. Message.svg';
// import { shallowEqual } from './HelperUtils';

interface Props {}

interface State {
  newConversation: boolean;
  conversation?: Conversation;
  showSearchbar?: boolean;
  search: string;
  isLoading?: boolean;
  showArchive?: boolean;
}

const PageInbox = () => {
  const unsubscribe = useRef<() => void>(() => {});

  // Context
  const profile = useContext(ctxProfile);
  const users = useContext(ctxUsers);

  const [search, setSearch] = useState('');
  const [newConversation, setNewConversation] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [showArchive, setShowArchive] = useState(false);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const history = useHistory();

  // ---------------------------------------------------------------------------------------------
  useIonViewDidEnter(() => {
    unsubscribe.current = getAllConversationsSnapshot(
      firestore,
      profile,
      (conversations: Conversation[]) => {
        setConversations(conversations ?? []);
        setLoading(false);
      }
    );
  });

  useIonViewDidLeave(() => {
    unsubscribe.current();
  });

  // ---------------------------------------------------------------------------------------------
  const archive = async (conversation: Conversation) => {
    console.log('archive clicked');
    if (showArchive) {
      unarchiveConversation(firestore, conversation, profile.id);
    } else {
      archiveConversation(firestore, conversation, profile.id);
    }
  };

  const saveNewConversation = async (conversation: Conversation) => {
    setTimeout(() => setNewConversation(false), 100);
    setLoading(true);
    try {
      await createConversation(firestore, conversation);
      setLoading(false);
      history.push('/secure/inbox/' + conversation.id);
      // this.setState({newConversation: false})
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert('error: ' + e);
    }
  };

  // ---------------------------------------------------------------------------------------------
  const renderDummyConversations = () => {
    // const renderDummy = false
    // if (!renderDummy) return null
    return [0, 1, 2, 3, 4, 5].map((item) => (
      <IonItem key={item}>
        <div
          style={{
            backgroundColor: 'var(--ion-color-gray)',
            width: '48px',
            height: '48px',
            borderRadius: '50%',
          }}
        ></div>
        <IonLabel className="ion-text-wrap">
          <IonGrid>
            <IonRow>
              <IonCol size="9">
                <IonSkeletonText animated className="conditions-animation" />
              </IonCol>
              <IonCol size="3">
                <IonSkeletonText animated className="conditions-animation" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonLabel>
      </IonItem>
    ));
  };

  // ---------------------------------------------------------------------------------------------
  const filterConversation = (conversation: Conversation, search: string) => {
    if (!search || search.length === 0) {
      return true;
    }
    let lastMessage =
      conversation.lastMessages.length > 0
        ? conversation.lastMessages[conversation.lastMessages.length - 1]
        : null;
    let conversationUsers = users.filter((u) => conversation.members.includes(u.id));

    return (
      (lastMessage &&
        lastMessage.message?.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
      conversationUsers?.filter((u) => u.email.indexOf(search) >= 0).length > 0 ||
      conversation.title?.indexOf(search) >= 0
    );
  };

  // ---------------------------------------------------------------------------------------------
  const getUnreadCount = (conversation: Conversation) => {
    return messagesUnread(conversation, profile.id);
  };

  // ---------------------------------------------------------------------------------------------
  const getConversationTitle = (conversation: Conversation) => {
    return conversationTitle(profile, [conversation], users, conversation.id);
  };

  // ---------------------------------------------------------------------------------------------
  // render
  // ---------------------------------------------------------------------------------------------
  let displayedConversations = conversations.filter(
    (c) => !c.archived?.includes(profile.id)
  );
  let archivedConversations = conversations.filter((c) =>
    c.archived?.includes(profile.id)
  );

  if (showArchive) {
    displayedConversations = archivedConversations;
  }

  console.log('conversations', conversations);

  return (
    <IonPage className="page-inbox ion-page">
      <IonHeader>
        {!showSearchbar ? (
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton color="dark" />
            </IonButtons>
            {!showArchive && (
              <IonTitle>
                {i18n.t('PageInbox.inbox')}{' '}
                {(profile.unreadConversationsCount ?? 0) > 0 &&
                  `(${profile.unreadConversationsCount})`}
              </IonTitle>
            )}
            {showArchive && <IonTitle>Archive</IonTitle>}
            <IonButtons slot="end">
              <IonButton
                onClick={() => setShowSearchbar(true)}
                className="search-button"
              >
                <IonIcon slot="icon-only" icon={searchOutline} color="dark"></IonIcon>
              </IonButton>
              {/* <IonButton color="dark" onClick={_ => this.startNewConversation()}>
            <IonIcon icon={createOutline}></IonIcon>
          </IonButton> */}
              {/* <IonButton>
            <IonIcon icon={ellipsisVertical} color="dark"></IonIcon>
          </IonButton> */}
            </IonButtons>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSearchbar
              placeholder={i18n.t('PageInbox.searchPlaceholder')}
              onIonChange={(evt) => setSearch(evt.detail.value + '')}
              showCancelButton="always"
              debounce={500}
              onIonCancel={(_) => setShowSearchbar(false)}
            />
          </IonToolbar>
        )}
      </IonHeader>

      <IonContent>
        <IonLoading isOpen={isLoading} />
        <IonHeader collapse="condense">
          {/* {!showArchive && <IonToolbar>
          <IonTitle size="large">
            {i18n.t('PageInbox.inbox')} 
          </IonTitle>
          {(applicationContext.inboxUnreadCount > 0) &&
            <div className="count">
              {applicationContext.inboxUnreadCount}
            </div>}
        </IonToolbar>}
        {showArchive && <IonToolbar>
          <IonTitle size="large">
            Archive
          </IonTitle>
        </IonToolbar>} */}
          <IonToolbar>
            <IonSearchbar
              placeholder={i18n.t('PageInbox.searchPlaceholder')}
              debounce={500}
              onIonChange={(evt) => setSearch(evt.detail.value + '')}
            />
          </IonToolbar>
        </IonHeader>
        <IonList>
          {!showArchive && archivedConversations?.length > 0 && (
            <div className="archived-top-bar" onClick={() => setShowArchive(true)}>
              <IonIcon icon={archiveOutline} /> {archivedConversations.length} Archived
            </div>
          )}
          {showArchive && (
            <div className="archived-top-bar" onClick={() => setShowArchive(false)}>
              <IonIcon icon={arrowBackOutline} /> Back to inbox
            </div>
          )}
          {conversations
            ? // this.filterConversation(conversations, search)
              displayedConversations
                .filter((c) => filterConversation(c, search))
                .map((conversation) => (
                  <IonItemSliding key={conversation.id}>
                    {/* <IonItemOptions side="start">
                  <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
                  <IonItemOption color="danger" onClick={() => console.log('share clicked')}>Share</IonItemOption>
                </IonItemOptions> */}
                    <IonItem
                      routerLink={'/secure/inbox/' + conversation.id}
                      // routerLink={'/secure/specialfruit.be/order/IO-19-32984'}
                      key={conversation.id}
                    >
                      <IonAvatar slot="start">
                        {conversation.members.length > 2 ? (
                          <IonIcon icon={peopleCircle} />
                        ) : (
                          <IonIcon icon={personCircle} />
                        )}
                      </IonAvatar>
                      {getUnreadCount(conversation) > 0 ? (
                        <IonIcon
                          slot="end"
                          icon={ellipse}
                          color="tertiary"
                          className="unread-icon"
                        />
                      ) : null}
                      <IonLabel className="ion-text-wrap">
                        <h3>{getConversationTitle(conversation)}</h3>
                        {conversation.subtitle && <h4>{conversation.subtitle}</h4>}
                        <div className="conversation-info">
                          {conversation.members.length > 2 ? (
                            <div>
                              <IonIcon icon={person} />
                              <div>{conversation.members.length}</div>
                            </div>
                          ) : null}
                          {conversation.lastMessages.length > 0 ? (
                            <p
                              className={
                                'last-message ' +
                                (getUnreadCount(conversation) > 0 ? 'unread' : '')
                              }
                            >
                              {
                                conversation.lastMessages[
                                  conversation.lastMessages.length - 1
                                ].message
                              }
                            </p>
                          ) : (
                            <p className="last-message unread">
                              <span role="img" aria-label="chat">
                                💬
                              </span>{' '}
                              new conversation
                            </p>
                          )}
                        </div>
                      </IonLabel>
                    </IonItem>
                    <IonItemOptions side="end">
                      <IonItemOption
                        color="tertiary"
                        onClick={() => archive(conversation)}
                      >
                        <IonIcon slot="icon-only" icon={archiveOutline} />
                        {/* Archive */}
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ))
            : renderDummyConversations()}
          {conversations?.length === 0 && <h1 className="h1-not-found">no messages</h1>}
        </IonList>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton onClick={(_) => setNewConversation(true)} color="primary">
            <IonIcon icon={iconNewMessage} />
          </IonFabButton>
        </IonFab>

        {newConversation && (
          <IonModal isOpen={true} onDidDismiss={(_) => setNewConversation(false)}>
            <PageNewConversation
              onCancel={() => setNewConversation(false)}
              onSave={(conversation) => saveNewConversation(conversation)}
            />
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export const PopoverMenu: React.FC = () => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  return (
    <>
      <IonPopover
        cssClass="my-custom-class"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonList>
          {/* <IonListHeader>Menu</IonListHeader> */}
          <IonItem button>Show Archived</IonItem>
          <IonItem button>Show All</IonItem>
          {/* <IonItem button>Option 3</IonItem> */}
          {/* <IonItem button>Option 4</IonItem> */}
        </IonList>
      </IonPopover>
      <IonButton
        onClick={(e: any) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
      >
        <IonIcon icon={ellipsisVertical}></IonIcon>
      </IonButton>
    </>
  );
};

export default React.memo(PageInbox);
