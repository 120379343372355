import React, { useState } from 'react';
import { IonButton, IonItem, IonLabel, IonInput } from '@ionic/react';

// Styling
import './SetPasswordForm.scss';

// Firebase
import { auth } from '../../../ConfigFirebase';
import { updatePassword } from 'firebase/auth';

interface Props {
  action: () => void;
}

export function SetPasswordForm({ action }: Props) {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = () => {
    setError(null);

    if (confirmPassword.length < 6 || newPassword.length < 6) {
      setError('Please enter password greater than 6 characters');

      return;
    }

    if (confirmPassword !== newPassword) {
      setError('Passwords do not match');

      return;
    }

    updatePassword(auth.currentUser, confirmPassword)
      .then(() => action())
      .catch(() => setError('Error occured setting password'));
  };

  return (
    <div className="change-password-form--wrapper">
      <div className="change-password-form--content">
        {error && (
          <div className="change-password-form--error">
            <p>{error}</p>
          </div>
        )}
        <IonItem className="change-password-form--input-container">
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            type="password"
            autocomplete="new-password"
            className="change-password-form--input"
            value={newPassword}
            onIonChange={(event) => setNewPassword(event.detail.value)}
          />
        </IonItem>
        <IonItem className="change-password-form--input-container">
          <IonLabel position="floating">Confirm password</IonLabel>
          <IonInput
            type="password"
            autocomplete="new-password"
            className="change-password-form--input"
            value={confirmPassword}
            onIonChange={(event) => setConfirmPassword(event.detail.value)}
          />
        </IonItem>
      </div>

      <IonButton
        color={'primary'}
        className="change-password-form--button"
        onClick={handleSubmit}
      >
        SET PASSWORD
      </IonButton>
    </div>
  );
}
