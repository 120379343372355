import { convertCamelToSentenceCase } from './HelperUtils';

export interface Option<T = string> {
  label: string;
  value: T;
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    color: '#1c1c1c',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const defaultSelectProps = {
  classNamePrefix: 'react-select',
  menuPortalTarget: window.document.body,  
  styles: customStyles,
  isClearable: false,
  menuPlacement: 'auto' as any
};

export const dictToReactSelectOptions = <T>(dict: { [key in string]: T }): {
  label: string;
  value: T;
}[] => {
  return Object.entries(dict).map(([label, value]) => ({
    label: convertCamelToSentenceCase(label),
    value,
  }));
};

export const arrayToReactSelectOptions = <T>(
  arr: T[]
): {
  label: string;
  value: T;
}[] => {
  return arr.map((value) => ({
    label: value as string,
    value,
  }));
};

export const booleanOptions: Option<boolean | null>[] = [null, true, false].map(
  (value) => ({
    value,
    label: value === null ? 'Unknown' : !!value ? 'Yes' : 'No',
  })
);

export const selectedBooleanOption = (boolVal: boolean): Option<boolean | null> =>
  booleanOptions.find((o) => o.value === boolVal);