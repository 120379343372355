import { IonCheckbox, IonItemDivider, IonList, IonSpinner } from '@ionic/react';
import { isEqual } from 'lodash';
import React from 'react';
import { formatDate } from '../../../HelperUtils';
import {
  ProductionSiteInspection,
  ProductionSiteInspectionReference,
  SharedFieldReport,
} from '../../../InspectionModel';
import { ProductionSiteLocation } from '../../../Model';
import { CardFieldInspection } from '../CardFieldInspection/CardFieldInspection';
import { datifyFieldInspectionReferenceDate } from '../../../ServiceInspection';
import './LocationInspections.scss'

interface Props {
  isLoadingInspections: boolean;
  inspections: ProductionSiteInspection[];
  field: ProductionSiteLocation | SharedFieldReport;
  isSelecting?: boolean;
  selectedInspections?: ProductionSiteInspection[];
  toggleInspection?: (r: ProductionSiteInspectionReference) => void;
}
export const LocationInspections = ({
  inspections,
  isLoadingInspections,
  field,
  isSelecting = false,
  selectedInspections = [],
  toggleInspection,
}: Props) => {
  const sortedInspections = inspections.sort(
    (a, b) =>
      datifyFieldInspectionReferenceDate(b.reference).getTime() -
      datifyFieldInspectionReferenceDate(a.reference).getTime()
  );
  const inspectionDates: string[] = Array.from(
    new Set(
      sortedInspections.map(
        (i) =>
          formatDate(datifyFieldInspectionReferenceDate(i.reference)) as string
      )
    )
  );  

  return (
    <div className="location-inspections">
      {isLoadingInspections ? (
        <IonSpinner />
      ) : sortedInspections.length > 0 ? (
        <IonList className="field-inspections-list">
          {inspectionDates.map((date, idx) => {
            const inspectionsFromDate = sortedInspections.filter(
              (i) =>
                formatDate(datifyFieldInspectionReferenceDate(i.reference)) ===
                date
            );
            return (
              <div key={idx}>
                <IonItemDivider>
                  <span>{date}</span>
                </IonItemDivider>
                {inspectionsFromDate.map((inspection, idx2) => (
                  <div className="field-inspection-container" key={idx2}>
                    <CardFieldInspection
                      field={field}
                      inspection={inspection}
                      sharedReportId={(field as SharedFieldReport).id}
                    />
                    {isSelecting && (
                      <IonCheckbox
                        checked={selectedInspections.some((i) =>
                          isEqual(inspection.reference, i.reference)
                        )}
                        onClick={() => toggleInspection(inspection.reference)}
                      />
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </IonList>
      ) : (
        <div style={{ padding: 30, margin: 'auto' }}>no inspections</div>
      )}
    </div>
  );
};
