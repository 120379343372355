import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  closeCircleOutline,
  closeOutline,
  informationCircleOutline,
  trashOutline,
} from 'ionicons/icons';
// import {closeOutline} from "ionicons/icons";
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// import {ApplicationContext, UserProfile} from "./Model";
import './ComponentProductsAdmin.scss';
// import i18n from "./ServiceI18n";
import { firestore } from './ConfigFirebase';
import { IArticle, Product, ProductView, VarietyMapping } from './Model';
import {
  agProductsToSelectOptions,
  deleteProduct,
  getAGProducts,
  saveNewProduct,
  updateProduct,
} from './DataAdmin';
import { find, get, pullAllBy } from 'lodash';
import { ISO3166 } from './HelperInsight';
import ViewAddProductPackagingModal from './ViewAddProductPackagingModal';
import { varietyMappingColRef } from './DataStorage';
interface Props {
  organisationId: string;
  products?: ProductView[];
}

interface State {
  page: number;
  inited: true;
  search: string;
  modal: boolean;
  modalPackaging: boolean;
  agProducts: any[];
  agProductOptions: any[];
  newProductSelectError: boolean;
  newProductInputError: boolean;
  originOptions: { value: string; label: string }[];
  packagingModalProduct: string;
  packagingArray: string[];
  varietyMapping?: VarietyMapping[];

  article: IArticle;
}

class ComponentProductsAdmin extends React.Component<Props, State> {
  newProductAgIdRef: React.RefObject<any>;
  newProductIdRef: React.RefObject<any>;
  refObject: any[];
  constructor(props) {
    super(props);
    this.refObject = [];
    this.state = {
      page: 0,
      inited: true,
      search: '',
      modal: false,
      modalPackaging: false,
      agProducts: [],
      agProductOptions: [],
      newProductSelectError: false,
      newProductInputError: false,
      originOptions: [],
      packagingModalProduct: '',
      packagingArray: [],
      article: {} as IArticle,
    };
    this.newProductAgIdRef = React.createRef();
    this.newProductIdRef = React.createRef();
  }

  async componentDidMount() {
    const agProducts = await getAGProducts(firestore);

    // fetch variety mapping, if exists
    let varietyMapping: VarietyMapping[];
    const vmRef = await varietyMappingColRef(
      firestore,
      this.props.organisationId
    ).get();
    if (!vmRef.empty) {
      varietyMapping = vmRef.docs.map((d) => d.data() as VarietyMapping);
    }

    const productOptions = agProductsToSelectOptions(agProducts);
    pullAllBy(
      productOptions,
      this.props.products.map((p) => {
        return { label: p.agProductId, value: p.agProductId };
      }),
      'value'
    );

    this.setState({
      agProducts: agProducts,
      agProductOptions: this.sortOptions(productOptions),
      varietyMapping,
    });
    this.setState({
      originOptions: this.sortOptions(
        Object.entries(ISO3166).map((o) => {
          return { label: o[1], value: o[0] };
        })
      ),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.products &&
      prevProps.products.length !== this.props.products.length
    ) {
      const agProducts = [...this.state.agProducts];
      const productOptions = agProductsToSelectOptions(agProducts);
      pullAllBy(
        productOptions,
        this.props.products.map((p) => {
          return { label: p.agProductId, value: p.agProductId };
        }),
        'value'
      );

      this.setState({
        agProductOptions: this.sortOptions(productOptions),
      });
    }
  }

  componentWillUnmount() {
    console.log('ComponentProductsAdmin will unmount');
  }

  async addNewProductHandle() {
    //TODO: fix warning messages,

    const newProductId = get(this.newProductIdRef, 'current.value');
    const newProductAgId = get(this.newProductAgIdRef, 'current.state.value.value');
    const isNewProductIdInvalid = newProductId === '' || !newProductId;
    const isNewProductAgIdInvalid = newProductAgId === '' || !newProductAgId;

    if (!isNewProductIdInvalid && !isNewProductAgIdInvalid) {
      this.setState({ modal: false });
      this.setState({
        newProductInputError: false,
        newProductSelectError: false,
      });
      await saveNewProduct(
        firestore,
        this.props.organisationId,
        newProductAgId,
        newProductId
      );
    } else {
      isNewProductIdInvalid
        ? this.setState({ newProductInputError: true })
        : this.setState({ newProductInputError: false });
      isNewProductAgIdInvalid
        ? this.setState({ newProductSelectError: true })
        : this.setState({ newProductSelectError: false });
    }
  }

  getAgProductVarieties = (productId) => {
    const product = find(
      this.state.agProducts,
      (product) => product['id'] === productId
    );
    const varieties: [] = get(product, 'varieties') || [];

    const findVariety = (vm: VarietyMapping, v: string) =>
      vm.id === v && vm.productId === productId;

    // we omit a variety from the list if the org has a variety mapping and the variety is not there
    return varieties
      .filter(
        (v) =>
          !this.state.varietyMapping ||
          !!(this.state.varietyMapping ?? []).find((vm) => findVariety(vm, v))
      )
      .map((variety) => {
        const value =
          (this.state.varietyMapping ?? []).find((vm) => findVariety(vm, variety))
            ?.companyId ?? variety;
        return { label: variety, value };
      });
  };

  handleSelectOnChange = async (agProductId, attribute, selectData) => {
    if (!selectData) selectData = [];
    let data = selectData.map((data) => data.value);

    const payload: Partial<ProductView> = {
      [attribute]: data,
    };

    if (attribute === 'varieties') {
      payload.agVarieties = selectData.map((data) => data.label);
    }

    await updateProduct(firestore, this.props.organisationId, agProductId, payload);
  };

  // TODO: DISPLAY WARNING ON DELETE IMPORTANT
  handleDeleteProduct = async (agProductId) => {
    await deleteProduct(firestore, this.props.organisationId, agProductId);
  };

  handleSearchBar = (text) => {
    this.setState({
      search: text.toLocaleLowerCase(),
      page: 0,
    });
  };

  handleInputOnChange = async (agProductId, attribute, data) => {
    const payload: Partial<Product> = {
      [attribute]: data.detail.value,
    };

    await updateProduct(firestore, this.props.organisationId, agProductId, payload);
  };

  debounce = (func, timeout = 200) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(args);
      }, timeout);
    };
  };

  renderPaging(list) {
    if (!list || list.length <= 10) {
      return;
    }
    return (
      <div className="paging">
        {[...Array(Math.ceil(list.length / 10))].map((v, i) => (
          <div
            key={i}
            onClick={(_) => this.setState({ page: i })}
            className={i === this.state.page ? 'current' : ''}
          >
            {i + 1}
          </div>
        ))}
      </div>
    );
  }

  sortOptions(options: { label: string; value: string }[]) {
    return options
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
      .filter(
        (o, idx, a) =>
          !a
            .slice(0, idx)
            .map((o) => o.value)
            .includes(o.value)
      ); // dedup
  }

  render() {
    const filteredProducts = this.props.products?.filter((c: ProductView) =>
      this.state.search.length > 0
        ? c.agProductId.toLocaleLowerCase().startsWith(this.state.search) ||
          c.productId?.toLocaleLowerCase().startsWith(this.state.search)
        : true
    );
    filteredProducts.forEach(() => this.refObject.push([]));
    return (
      <div className="component-products-admin">
        <div className="top-toolbar">
          <div>
            <IonSearchbar
              mode="ios"
              onIonChange={(ev) => this.handleSearchBar(ev.detail?.value)}
            />
          </div>
          <strong>Total ({this.props.products?.length ?? 0})</strong>
        </div>

        {this.renderPaging(filteredProducts)}

        <div className="table-list align-top">
          <div className="list-header">
            <div className="name short">Name</div>
          </div>
          {filteredProducts
            ?.slice(this.state.page * 10, this.state.page * 10 + 10)
            .map((product: ProductView, index) => {
              const optsOrigin = this.sortOptions(
                product.origins?.map((o) => {
                  return { label: ISO3166[o], value: o };
                }) ?? []
              );
              const optsVarieties = product.varieties?.map((o, idx) => {
                return { label: product.agVarieties?.[idx] ?? o, value: o };
              });

              const allOptsVarieties = this.sortOptions(
                this.getAgProductVarieties(product.agProductId)
              );

              const optsPackaging = this.sortOptions(
                product.packaging?.map((o) => {
                  return { label: o, value: o };
                }) ?? []
              );
              const optsBrands = this.sortOptions(
                product.brands?.map((o) => {
                  return { label: o, value: o };
                }) ?? []
              );

              return (
                <div key={product.agProductId} className="">
                  <div className="name short">
                    <b>{product.agProductId}</b> <br />
                    <IonInput
                      value={product.productId}
                      onIonChange={(e) =>
                        this.debounce(
                          this.handleInputOnChange(product.agProductId, 'productId', e)
                        )
                      }
                    />
                  </div>
                  <div className="selectables">
                    <div className="item-selectable">
                      <b>Origins</b>
                      <Select
                        classNamePrefix={'react-select'}
                        isClearable={false}
                        options={this.state.originOptions}
                        defaultValue={optsOrigin}
                        onChange={(e) =>
                          this.handleSelectOnChange(product.agProductId, 'origins', e)
                        }
                        isMulti
                      />
                    </div>
                    <div className="item-selectable">
                      <div className="item-selectable_variety">
                        <b>Varieties</b>
                        <div
                          className="info-button"
                          onClick={(e) => {
                            e.currentTarget?.parentElement.classList?.toggle('less');
                            // @ts-ignore
                            e.currentTarget?.parentElement.nextSibling?.classList?.toggle(
                              'visible'
                            );
                            const isOpen =
                              // @ts-ignore
                              e.currentTarget?.parentElement.nextSibling?.classList?.contains(
                                'visible'
                              );
                            document.getElementById(
                              `${product.agProductId}-closed`
                            ).hidden = isOpen;
                            document.getElementById(
                              `${product.agProductId}-open`
                            ).hidden = !isOpen;
                          }}
                        >
                          <IonIcon
                            ref={(refObject) =>
                              (this.refObject[index][`${product.agProductId}-closed`] =
                                refObject)
                            }
                            id={`${product.agProductId}-closed`}
                            icon={informationCircleOutline}
                          />
                          <IonIcon
                            ref={(refObject) =>
                              (this.refObject[index][`${product.agProductId}-open`] =
                                refObject)
                            }
                            id={`${product.agProductId}-open`}
                            icon={closeCircleOutline}
                            hidden={true}
                          />
                        </div>
                      </div>
                      <div className="more desc">
                        {
                          "Did not find your variety? Please send a request to Agrinorm's support with the details of the product and variety."
                        }
                      </div>
                      <Select
                        isClearable={false}
                        classNamePrefix={'react-select'}
                        options={allOptsVarieties}
                        defaultValue={optsVarieties}
                        onChange={(e) =>
                          this.handleSelectOnChange(product.agProductId, 'varieties', e)
                        }
                        noOptionsMessage={() => 'No options'}
                        isMulti
                      />
                    </div>
                    <div className="item-selectable">
                      <b id={'item-selectable-packaging'}>Packaging</b>
                      <Select
                        classNamePrefix={'react-select'}
                        isClearable={false}
                        options={[
                          { label: 'Add Packaging', value: 'Add Packaging' },
                          ...optsPackaging,
                        ]}
                        value={optsPackaging}
                        defaultValue={optsPackaging}
                        onChange={(e, action) => {
                          const selectedOption = action.option?.value;
                          if (selectedOption === 'Add Packaging') {
                            this.setState({
                              modalPackaging: true,
                              packagingModalProduct: product.agProductId,
                              packagingArray: product.packaging,
                            });
                          } else {
                            return this.handleSelectOnChange(
                              product.agProductId,
                              'packaging',
                              e
                            );
                          }
                        }}
                        isMulti
                      />
                    </div>
                    <div className="item-selectable">
                      <b>Brands</b>
                      <CreatableSelect
                        isClearable={false}
                        options={optsBrands}
                        defaultValue={optsBrands}
                        noOptionsMessage={() => 'Type to add a brand'}
                        onChange={(e) =>
                          this.handleSelectOnChange(product.agProductId, 'brands', e)
                        }
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="actions short">
                    <span
                      title="remove product"
                      className="action-button delete"
                      onClick={() => this.handleDeleteProduct(product.agProductId)}
                    >
                      <IonIcon icon={trashOutline} />
                    </span>
                  </div>
                </div>
              );
            })}
        </div>

        <IonButton
          slot="fixed"
          color="tertiary"
          className="main-button"
          onClick={(_) => this.setState({ modal: true })}
        >
          Add Product
        </IonButton>

        <IonModal isOpen={this.state.modal || false}>
          <IonHeader className={''}>
            <IonToolbar>
              <IonTitle>Add Product</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={(_) => this.setState({ modal: false })}>
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="add-modal">
            {/* <form className="ion-padding"> */}
            <IonList>
              <IonItem className="select-input">
                <IonLabel>Name</IonLabel>
                <Select
                  options={this.state.agProductOptions}
                  isClearable={false}
                  classNamePrefix={'react-select'}
                  ref={this.newProductAgIdRef}
                />
                {this.state.newProductSelectError && (
                  <div className={'warningMessageNewProduct'}>
                    Please select a product.
                  </div>
                )}
              </IonItem>
              <IonItem>
                <IonLabel>Product Id</IonLabel>
                <IonInput id="newProductId" ref={this.newProductIdRef} />
                {this.state.newProductInputError && (
                  <div className={'warningMessageNewProduct'}>
                    This field can not be empty.
                  </div>
                )}
              </IonItem>
            </IonList>
            <div className="main-btn">
              <IonButton onClick={() => this.addNewProductHandle()}>Add</IonButton>
            </div>
            {/* </form> */}
          </IonContent>
        </IonModal>
        {this.state.modalPackaging && (
          <ViewAddProductPackagingModal
            packagingArray={this.state.packagingArray}
            agProductId={this.state.packagingModalProduct}
            orgId={this.props.organisationId}
            openModal={this.state.modalPackaging}
            setOpenModal={(value) => {
              this.setState({ modalPackaging: value, packagingModalProduct: '' });
            }}
          />
        )}
      </div>
    );
  }
}

export default ComponentProductsAdmin;
// onIonChange
