import { toastController } from '@ionic/core';
import {
  IonAlert,
  IonBackButton,
  // IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSpinner,
  IonText,
  // IonSpinner,
  IonTitle,
  IonToolbar,
  useIonPopover,
  withIonLifeCycle,
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import {
  arrowRedoCircleOutline,
  arrowUndoOutline,
  attachOutline,
  chatbubbleEllipsesOutline,
  checkmarkCircleOutline,
  chevronBackOutline,
  documentAttachOutline,
  documentOutline,
  documentsOutline,
  ellipseOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  imageOutline,
  layersOutline,
  linkOutline,
  lockClosedOutline,
  lockOpenOutline,
  printOutline,
  trashOutline,
  warningOutline,
} from 'ionicons/icons';
import { isEqual } from 'lodash';
import React, { RefObject, useRef, useState } from 'react';
import { ctxContacts, ctxLocations } from './App';
import { firestore, storage } from './ConfigFirebase';
import { copyLotInspectionsWithinOrder } from './DataInspection';
import {
  createInternalReportFromOrder,
  reopenOrder,
  updateOrderStatus,
} from './DataReport';
import {
  addOrderMessage,
  createLotFromContactPosition,
  deleteOrder,
  deleteOrderAttachment,
  getLot,
  getOrderSnapshot,
  lotColRef,
  orderColRef,
  setSplitLotTransactional,
  undoSplitLotOrder,
  updateOrderAttachments,
  uploadOrderOrReportAttachments,
} from './DataStorage';
import { LOT_INSPECTION_MAP } from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import { fetchEntityErrorMessage, presentStandardToast } from './HelperIonic';
import {
  computeAllocationMismatches,
  formatDate,
  getOrderReportTypeTitle,
  getSplitLotTransferedQuantity,
  isSplitLotPosition,
  qcStatusToColor,
  qcStatusToUIName,
  sortByLastModifiedDateAsc,
  sortByLastModifiedDateDesc,
  standardizeDate,
} from './HelperUtils';
import {
  LegacyInspection,
  LotInspection,
  LegacyInspectionReference,
} from './InspectionModel';
import {
  AG_BOXES_SHIPPED_QUESTION_ID,
  AllocationMismatch,
  Contact,
  Conversation,
  Lot,
  LotCreatePayload,
  LotPopoverModel,
  // Lot,
  LotPosition,
  LotTransfer,
  Order,
  OrderCreatePayload,
  Organisation,
  OrganisationSettings,
  ReportReference,
  ReportType,
  User,
  UserProfile,
  dateToString,
} from './Model';
import './PageLot.scss';
import PageNewConversation from './PageNewConversation';
import PageNewLot from './PageNewLot';
import './PageOrder.scss';
import PageSplitLot from './PageSplitLot';
import {
  AppProduct,
  QCTierAction,
  hasRole,
  orgHasPermission,
  userHasPermission,
} from './PermissionsService';
import { Article } from './ServiceArticle';
import i18n from './ServiceI18n';
import {
  InspectionClass,
  buildLotOrTransitInspectionPath,
  getInspectionBoxes,
  orderInspectionsList,
  shouldVerifyNumBoxes,
  stringifyInspectionReference,
} from './ServiceInspection';
import ViewAllocationMismatchWarning from './ViewAllocationMismatchWarning';
import ViewContactName from './ViewContactName';
import { ViewInspectionScore } from './ViewInspectionScore';
import ViewProductName from './ViewProductName';
import { LotSchemaObjectTypeEnum } from './generated/openapi/core';
import { applicationStateObservable } from './simpleObservable/observables';
interface Props {
  organisationId: string;
  orderId: string;
  hideGeneral?: boolean;
  upfront?: boolean;
  shared?: boolean;
  sharedReport?: boolean;
  id?: string;

  reportType?: ReportType;

  history: any;
}

interface State {
  order?: Order | null;
  // history?: Order[];
  linkedOrder?: Order;
  filters?: any;

  showSaveChanges?: boolean;
  copyInspections?: boolean;
  copyInspectionsSource?: LotInspection;
  copyInspectionsTargets?: LotInspection[];
  showLoadingSpinner?: boolean;
  showDeleteAlert?: string;

  showNewLotModal: boolean;
  showLinkLotModal: boolean;
  currContactPosition?: LotPosition;

  alert: { message: string; header: string; show: boolean; onAccept: any };
  showSplitLotModal: boolean;
  currPosition?: LotPosition;

  lotOptionsPopover?: LotPopoverModel;

  showMismatches: {
    show: boolean;
    mismatches: AllocationMismatch[];
    expectedId: string;
    foundId: string;
  };
}

class PageOrder extends React.Component<Props & ContextProps, State> {
  private pageRef: RefObject<HTMLElement> = React.createRef();
  private unsubscribers: any[] = [];
  private mounted;
  private defaultAlert = { message: '', header: '', show: false, onAccept: (_) => {} };
  private defaultMismatch = {
    show: false,
    mismatches: [],
    expectedId: undefined,
    foundId: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      order: undefined,
      showLinkLotModal: false,
      showNewLotModal: false,
      alert: this.defaultAlert,
      showSplitLotModal: false,
      lotOptionsPopover: { visible: false },
      showMismatches: this.defaultMismatch,
      copyInspectionsTargets: [],
    };
  }

  // shouldComponentUpdate(nextProps: Props, nextState: State) {
  //   // const { showOrders } = this.state
  //   if (this.state !== nextState) {
  //     return true;
  //   }

  //   if (this.props.orderId !== nextProps.orderId) {
  //     return true;
  //   }
  //   if (this.props.organisationId !== nextProps.organisationId) {
  //     return true;
  //   }

  //   return false;
  // }

  async componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribers.map((unsubscribe) => unsubscribe());
  }

  async ionViewWillEnter() {
    const { profile } = this.props;

    if (this.props.orderId !== this.state.order?.id && this.mounted) {
      if (this.mounted) this.setState({ order: undefined });
    }

    this.unsubscribers.push(
      getOrderSnapshot(
        firestore,
        profile.organisationId,
        this.props.orderId,
        (order) => {
          if (this.mounted) {
            this.setState({ order });
          }
        },
        this.props.organisationId
      )
    );
  }

  async componentDidUpdate(_, prevState: Readonly<State>) {
    // if the order is set to null, it means there was an error fetching the snapshot
    if (this.state.order === null && !isEqual(prevState.order, this.state.order)) {
      await presentStandardToast(toastController, fetchEntityErrorMessage('order'));
    }
  }

  ionViewWillLeave() {
    this.unsubscribers.map((unsubscribe) => unsubscribe());
  }

  renderLoading() {
    // TODO: render loading
    return (
      <div className="loading" data-tip={`page-order-spinner`}>
        <IonSpinner name="dots" />
      </div>
    );
  }

  async onDownloadAttachment(path: string) {
    try {
      const downloadURL = await storage.ref().child(path).getDownloadURL();
      // Emi: is this too hacky?
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('type', 'hidden');
      downloadLink.href = downloadURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      alert('Document could not be downloaded');
      console.log(e);
    }
  }

  onDeleteAttachment(
    path: string,
    event?: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ showDeleteAlert: path });
  }

  async onDeleteConfirm(path: string) {
    const { profile } = this.props;
    const { order } = this.state;

    this.setState({ showLoadingSpinner: true, showDeleteAlert: undefined });

    try {
      // delete attachment in storage and remove reference in order
      await deleteOrderAttachment(firestore, storage, profile, order, path);
    } catch (error) {
      alert(`Something went wrong when deleting the attachment`);
      console.log(`Error updating order`, error);
    }
    this.setState({ showLoadingSpinner: false });
  }

  renderTransportOverview(order: Order, inspections: LegacyInspection[]) {
    const inspection: LegacyInspection = inspections.find(
      (ass) => ass.reference.transportId === 'TRANSPORT'
    );

    const inspClass = new InspectionClass(inspection);
    const isOpen = !inspClass.isCompleted();

    let routerLink;
    if (['SHARED', 'CHECKED'].includes(order.qcStatus)) {
      routerLink =
        '/secure/' +
        order.orgId +
        '/inspection-view/' +
        stringifyInspectionReference(inspection.reference);
    } else if (isOpen) {
      routerLink = buildLotOrTransitInspectionPath(order.orgId, inspection.reference);
    } else {
      routerLink = buildLotOrTransitInspectionPath(order.orgId, inspection.reference);
    }

    return (
      <>
        <IonListHeader>
          <h1>{i18n.t('PageOrder.transportOverview')}</h1>
        </IonListHeader>
        <IonItem
          className={'transport-item ' + (isOpen ? 'go-to-top' : '')}
          routerLink={routerLink}
          data-tip={'transport-inspection'}
        >
          {['SHARED', 'CHECKED'].includes(order.qcStatus) && (
            <IonIcon icon={lockClosedOutline} className="lock-icon" />
          )}
          <IonGrid className="ion-no-padding">
            <IonRow className="ion-align-items-center ion-padding-end">
              <IonCol className="ion-padding-end assesment-info">
                {order.shippingReference || 'Transport'}&nbsp;&nbsp;
                {isOpen ? (
                  <IonBadge color="medium" data-tip={'transport-inspection-open'}>
                    {inspection.status}
                  </IonBadge>
                ) : (
                  <IonBadge color="dark" data-tip={'transport-inspection-done'}>
                    DONE
                  </IonBadge>
                )}
                {inspection.pictures?.length > 0 && (
                  <IonBadge color="dark">
                    {inspection.pictures.length} <IonIcon icon={imageOutline} />
                  </IonBadge>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </>
    );
  }

  renderAttachmentItems(order: Order) {
    if (!order.attachments || order.attachments.length === 0) {
      return '';
    }

    return (
      <>
        <IonItemDivider />
        <IonListHeader className="separator">
          <h2>File Attachments</h2>
        </IonListHeader>
        {order.attachments.map((attachment) => {
          const [filename] = attachment.split('/').slice(-1);
          return (
            <IonItem
              key={attachment}
              button
              onClick={async () => await this.onDownloadAttachment(attachment)}
            >
              <IonIcon icon={attachOutline} style={{ marginRight: '10px' }} />
              {filename}
              {!this.props.sharedReport && (
                <IonButton
                  disabled={!navigator.onLine}
                  fill="clear"
                  color="danger"
                  slot="end"
                  onClick={(e) => this.onDeleteAttachment(attachment, e)}
                >
                  <IonIcon icon={trashOutline} />
                </IonButton>
              )}
            </IonItem>
          );
        })}
      </>
    );
  }

  renderAttachmentCards(order: Order) {
    if (!order.attachments || order.attachments.length === 0) {
      return '';
    }

    return (
      <>
        <IonListHeader>
          <h1>Attachments</h1>
        </IonListHeader>
        <div className="container-reports container-attachments">
          {order.attachments.map((a) => {
            const [filename] = a.split('/').slice(-1);
            return (
              <IonCard
                button
                disabled={!navigator.onLine}
                onClick={async () => await this.onDownloadAttachment(a)}
                key={a}
              >
                <IonItem>
                  <IonIcon icon={attachOutline} slot="start" />
                  {filename}
                  {!this.props.sharedReport && (
                    <IonButton
                      fill="clear"
                      disabled={!navigator.onLine}
                      color="danger"
                      slot="end"
                      onClick={(e) => this.onDeleteAttachment(a, e)}
                    >
                      <IonIcon icon={trashOutline} />
                    </IonButton>
                  )}
                </IonItem>
              </IonCard>
            );
          })}
        </div>
      </>
    );
  }

  async copyInspections() {
    const { order, copyInspectionsTargets } = this.state;
    if (
      window.confirm(
        `Are you sure you want to copy ${
          this.state.copyInspectionsSource.reference.lotId
        } to the following open Inspections?\nThis action cannot be undone.\n\n${copyInspectionsTargets
          .map((i) => '- ' + i.reference.lotId)
          .join('\n')}`
      )
    ) {
      await this.setState({ showLoadingSpinner: true });
      await copyLotInspectionsWithinOrder(
        firestore,
        this.props.profile,
        this.state.copyInspectionsSource as LotInspection,
        order,
        copyInspectionsTargets as LotInspection[],
        this.props.organisation
      );
      this.setState({
        copyInspections: false,
        showLoadingSpinner: false,
        copyInspectionsSource: undefined,
        copyInspectionsTargets: [],
      });
    } else {
      this.setState({
        copyInspections: false,
        showLoadingSpinner: false,
        copyInspectionsSource: undefined,
        copyInspectionsTargets: [],
      });
    }
  }

  setShowAllocationMismatches(
    mismatches: AllocationMismatch[],
    expectedId: string,
    foundId: string,
    e?: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ showMismatches: { show: true, mismatches, expectedId, foundId } });
  }

  renderLotOverviewList(
    order: Order,
    positions: LotPosition[],
    inspections: LegacyInspection[],
    myOrgOrder: boolean,
    isBuyerPosition: boolean = false,
    dataTip: string = 'lot'
  ) {
    const productPositionMap: { [key in string]: LotPosition[] } = {};

    positions.forEach((pos) => {
      if (!productPositionMap[pos.article?.agProductId]) {
        productPositionMap[pos.article?.agProductId] = [];
      }
      productPositionMap[pos.article?.agProductId].push(pos);
    });

    const splitLotPositions = order.positions.filter((p) => p.motherLotIds?.length > 0);

    const displayQCInfo = !isBuyerPosition;
    const displayLock = isBuyerPosition
      ? ['SHARED', 'CHECKED'].includes(this.state.order.qcStatus)
      : ['SHARED', 'CHECKED'].includes(order.qcStatus); // this.state.order is the company's order, while 'order' is the contact order

    return (
      <div className="lot-list" data-tip={`${dataTip}-list`}>
        {Object.keys(productPositionMap).map((productId: string) => (
          <div key={productId}>
            <IonListHeader>
              <h1>
                <ViewProductName
                  article={
                    positions.find(
                      (p) =>
                        p.article?.productId === productId ||
                        (p.article?.agProductId != null &&
                          p.article?.agProductId === productId)
                    )?.article
                  }
                />
              </h1>
            </IonListHeader>
            {productPositionMap[productId].map((position: LotPosition, index) => {
              const inspection = inspections.find(
                (i) => i.reference.lotId === position.lotId
              ) as LotInspection;
              // flag to decide whether to display or not the lot options button
              const displayLotOptions =
                this.state.order.qcStatus !== 'SHARED' &&
                (order.type === 'SELL' || order.type === 'BUY') &&
                !this.hasNoLotOptionsPermission(
                  isBuyerPosition,
                  order,
                  position,
                  myOrgOrder
                ) &&
                !this.state.copyInspections;

              const articleClass = new Article(position.article);

              const quantity = articleClass.isRaw()
                ? position.volumeInKg
                : position.numBoxes;

              const splitLotTransferedNumBoxes = getSplitLotTransferedQuantity(
                { positions: splitLotPositions ?? [] } as Order,
                position,
                'numBoxes'
              );
              const splitLotTransferedVolume = getSplitLotTransferedQuantity(
                { positions: splitLotPositions ?? [] } as Order,
                position,
                'volumeInKg'
              );

              const splitLotTransferedQuantity = articleClass.isRaw()
                ? splitLotTransferedVolume
                : splitLotTransferedNumBoxes;

              // TODO: reimplement when needed
              const displayLink = false;
              const linkedLotId = undefined;
              const linkedLot = undefined;

              const mismatches = computeAllocationMismatches(position, linkedLot);

              let routerLink;
              if (!isBuyerPosition) {
                // routerLink = '/secure/' + order.orgId + '/inspection/' + getInspectionId(inspection.reference) + `?${ORDER_BOXES_EXPECTED}=` + position.numBoxes + `&${ORDER_QC_STATUS}=` + order.qcStatus
                const numBoxes = shouldVerifyNumBoxes(order, position)
                  ? position.numBoxes
                  : undefined;
                routerLink = buildLotOrTransitInspectionPath(
                  order.orgId,
                  inspection.reference,
                  undefined,
                  numBoxes,
                  order.qcStatus
                );
              }

              const { boxesShipped } = getInspectionBoxes(inspection);

              const agScoreBoxes =
                inspection.userInputs?.[AG_BOXES_SHIPPED_QUESTION_ID]?.agScore;
              // console.log(AG_BOXES_SHIPPED_QUESTION_ID, assessment.userInputMap?.[AG_BOXES_SHIPPED_QUESTION_ID])

              // const assClass = new Assessment(assessment);
              // const isCompleted = assClass.isCompleted();

              const inspClass = new InspectionClass(inspection);
              const isCompleted = inspClass.isCompleted();

              return (
                <IonItem
                  className={
                    'no-ripple ' +
                    (!isCompleted ? 'go-to-top ' : '') +
                    (!isCompleted &&
                    this.state.copyInspections &&
                    !this.state.copyInspectionsSource
                      ? ' disabled '
                      : '') +
                    (isCompleted &&
                    this.state.copyInspections &&
                    !!this.state.copyInspectionsSource
                      ? ' disabled '
                      : '') +
                    (!!this.state.copyInspectionsSource &&
                    inspection.lotProperties?.article.productId !==
                      this.state.copyInspectionsSource.lotProperties?.article.productId
                      ? ' disabled '
                      : '') +
                    (displayLotOptions ? 'display-lot-options ' : '') +
                    (isBuyerPosition ? 'non-clickable ' : '') +
                    (this.state.copyInspectionsSource?.reference.lotId ===
                    inspection.reference.lotId
                      ? ' target '
                      : '')
                  }
                  button={false}
                  data-tip={`${dataTip}-${productId}-${index}`}
                  key={position.lotId}
                  onClick={async (e) => {
                    if (
                      this.state.copyInspections &&
                      !this.state.copyInspectionsSource
                    ) {
                      e.preventDefault();
                      this.setState({ copyInspectionsSource: inspection });
                    } else if (!!this.state.copyInspectionsSource) {
                      e.preventDefault();
                      let { copyInspectionsTargets } = this.state;
                      copyInspectionsTargets = copyInspectionsTargets ?? [];
                      const index = copyInspectionsTargets.findIndex(
                        (a) => a.reference.lotId === inspection.reference.lotId
                      );
                      if (index >= 0) {
                        copyInspectionsTargets.splice(index, 1);
                      } else {
                        copyInspectionsTargets.push(inspection);
                      }
                      this.setState({ copyInspectionsTargets });
                    }
                  }}
                  routerLink={routerLink}
                  detail={false}
                >
                  {displayLock && (
                    <IonIcon icon={lockClosedOutline} className="lock-icon" />
                  )}

                  {!!this.state.copyInspections && (
                    <IonCheckbox color="tertiary" className="lock-icon checkbox" />
                  )}

                  {/* LOT ACTION BUTTON */}
                  {displayLotOptions && (
                    <IonButtons
                      className={
                        'lot-options-button ' + (displayLock ? 'lock' : 'no-lock')
                      }
                    >
                      <IonButton
                        color="dark"
                        fill="clear"
                        className={`lot-options-button-${
                          articleClass.getArticle().packagingType
                        }`}
                        data-tip={`${dataTip}-${productId}-${index}-options`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          e.persist();
                          this.setState({
                            currPosition: position,
                            lotOptionsPopover: {
                              visible: true,
                              event: e,
                              isBuyerPosition,
                              order,
                              myOrgOrder,
                            },
                          });
                        }}
                      >
                        <IonIcon icon={ellipsisVertical} slot="icon-only" />
                      </IonButton>
                    </IonButtons>
                  )}

                  <div className={'lot-item ' + (!isCompleted && 'open')}>
                    <div className="first-row">
                      <div className="lot-info-score">
                        <div className="lot-info">{position.lotId}</div>

                        {position.motherLotIds && position.motherLotIds.length > 0 ? (
                          <small>{`FROM ${position.motherLotIds.join(', ')}`}</small>
                        ) : (
                          ''
                        )}

                        {displayLink && (
                          <div className="linked-lot">
                            <IonIcon icon={linkOutline} />
                            {linkedLotId}

                            {/* Allocation mismatches BUTTON */}
                            {mismatches.length > 0 && (
                              <IonButton
                                title="Display allocation mismatches"
                                fill="clear"
                                color="danger"
                                className="warning-button"
                                onClick={(e) =>
                                  this.setShowAllocationMismatches(
                                    mismatches,
                                    linkedLotId,
                                    position.lotId,
                                    e
                                  )
                                }
                              >
                                <IonIcon icon={warningOutline} />
                              </IonButton>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-scoring">
                        {displayQCInfo && isCompleted && (
                          <ViewInspectionScore
                            inspection={inspection}
                            order={order}
                            displayType={'CARD-ORDER'}
                            organisationSettings={this.props.organisation?.settings}
                            profile={this.props.profile}
                          />
                        )}
                        {!isCompleted && (
                          <IonBadge
                            data-tip={`${dataTip}-inspection-open`}
                            color={
                              inspection.status === 'IN PROGRESS'
                                ? 'secondary'
                                : 'medium'
                            }
                          >
                            {inspection.status}
                          </IonBadge>
                        )}
                      </div>
                    </div>
                    <div>
                      <div>
                        <div
                          className="assesment-badges"
                          style={{ marginBottom: '5px' }}
                        >
                          {articleClass
                            .getArticleInfoArray()
                            .filter((a) => !!a)
                            .filter((a) => a !== '-')
                            .map((o, i) => (
                              <IonBadge color="tertiary" key={i}>
                                {o}
                              </IonBadge>
                            ))}
                          {/* Render grower name */}
                          {!!position.growerId && (
                            <ctxContacts.Consumer>
                              {(contacts) => {
                                const growerName =
                                  (contacts ?? []).find(
                                    (c) => c.id === position.growerId
                                  )?.name ?? position.growerId;
                                return (
                                  <IonBadge className="article-badge" color="tertiary">
                                    {growerName}
                                  </IonBadge>
                                );
                              }}
                            </ctxContacts.Consumer>
                          )}
                        </div>
                        <div className="assesment-badges">
                          <IonBadge className={'quantity score-' + agScoreBoxes}>
                            {boxesShipped && agScoreBoxes === 2
                              ? boxesShipped + '/'
                              : ''}
                            {Math.round(quantity ?? 0)}
                            {splitLotTransferedQuantity > 0
                              ? ` (-${splitLotTransferedQuantity})`
                              : ''}
                            &nbsp;{articleClass.getUnit(quantity)}
                          </IonBadge>
                          {displayQCInfo && isCompleted && (
                            <IonBadge
                              data-tip={`${dataTip}-inspection-done`}
                              color="dark"
                            >
                              DONE
                            </IonBadge>
                          )}

                          <IonBadge
                            color={inspection.pictures?.length ? 'dark' : 'medium'}
                          >
                            {inspection.pictures?.length ?? 0}
                            <IonIcon icon={imageOutline} />
                          </IonBadge>
                        </div>
                      </div>
                    </div>
                  </div>
                </IonItem>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  openNewLotModal(
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    currentPosition: LotPosition
  ) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showNewLotModal: true, currContactPosition: currentPosition });
  }

  openLinkLotModal(
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    currentPosition: LotPosition
  ) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showLinkLotModal: true, currContactPosition: currentPosition });
  }

  getReportLink(assessment) {
    if (assessment.reference.orderId === '') {
      assessment.reference.orderId = this.props.orderId;
    }
    if (this.props.sharedReport) {
      return `/shared-report/${this.props.id}/${stringifyInspectionReference(
        assessment.reference
      )}`;
    } else {
      return (
        '/secure/' +
        this.props.organisationId +
        '/report/' +
        stringifyInspectionReference(assessment.reference)
      );
    }
  }

  renderLoadingLink() {
    // if (this.state.order.contactOrder.validLink === false)
    return (
      <>
        {/* <IonSpinner name="dots" /> */}
        ok
      </>
    );
  }

  renderGeneral() {
    let { order } = this.state;

    return (
      <div
        className={
          'general-info-wrapper ' + (this.props.sharedReport ? 'no-events' : '')
        }
      >
        <IonListHeader>
          <h1>General Information</h1>
        </IonListHeader>

        <IonItem className="print-only">
          <IonLabel>Order ID</IonLabel>
          <IonLabel className="organization-name align-left text-wrap">
            {order.id}
          </IonLabel>
        </IonItem>

        {!!order.qcStatus && (
          <IonItem>
            <IonLabel>QC Status</IonLabel>
            <IonLabel className="organization-name align-left text-wrap">
              <IonBadge
                data-tip={'order-status-badge'}
                style={{ fontSize: 14 }}
                color={qcStatusToColor(order.qcStatus)}
              >
                {qcStatusToUIName(order.qcStatus)}
              </IonBadge>
            </IonLabel>
          </IonItem>
        )}

        {/* TODO: reimplement if needed */}
        {/* Link display connection */}
        {/* {displayContactOrderLink &&
          <IonItem className="external-id">
            <IonLabel>{`${myOrgOrder ? 'External' : 'Internal'} ${externalReportShared ? ' report' : ' order'}`}</IonLabel>
            <IonLabel className="align-left text-wrap">
              <div onClick={_=> (myOrgOrder) 
                ? externalReportShared
                  ? this.props.history.push("/secure/report/" + order.contactOrder.order.latestReportReference.reportId)
                  : this.props.history.push("/secure/" + this.props.profile.organisationId + "/order/" + order.contactOrder?.id)
                : externalReportShared
                  ? this.props.history.push("/secure/report/" + order.contactOrder.order.latestReportReference.reportId + '/external')
                  : this.props.history.push("/secure/" + this.props.profile.organisationId + "/order/" + order.contactOrder?.id)
              }
              >
                {!!order.contactOrder?.id && <><IonIcon icon={linkOutline} /> {order.contactOrder?.id}</>}
              </div>
            </IonLabel>
          </IonItem>} */}

        {/* TODO: reimplement if needed */}
        {/* {displayContactOrderId && <IonItem className="external-id">
          <IonLabel>{`${myOrgOrder ? 'External' : 'Internal'} ID`}</IonLabel>
          {!!order.contactOrder?.id && <>{order.contactOrder?.id}</>}
        </IonItem>
        } */}

        {!!order.bookingDate ? (
          <IonItem>
            <IonLabel>Order Date</IonLabel>
            <IonLabel className="align-left text-wrap">
              {new Intl.DateTimeFormat('en-GB', {}).format(
                standardizeDate(order.bookingDate)
              )}
            </IonLabel>
          </IonItem>
        ) : (
          ''
        )}

        {!!order.fulfilmentDate && (
          <IonItem>
            <IonLabel>
              {order.type === 'BUY'
                ? i18n.t('PageOrder.arrivalDate')
                : i18n.t('PageOrder.dispatchDate')}
            </IonLabel>
            <IonLabel className="align-left text-wrap">
              {new Intl.DateTimeFormat('en-GB', {}).format(
                standardizeDate(order.fulfilmentDate)
              )}
            </IonLabel>
          </IonItem>
        )}

        {order.estimatedArrivalDate && (
          <IonItem>
            <IonLabel>Estimated arrival date</IonLabel>
            <IonLabel className="align-left text-wrap">
              {new Intl.DateTimeFormat('en-GB', {}).format(
                standardizeDate(order.estimatedArrivalDate)
              )}
            </IonLabel>
          </IonItem>
        )}

        {order.dispatchLocationId != null && (
          <IonItem>
            <IonLabel>Dispatch location</IonLabel>
            <IonLabel className="align-left text-wrap">
              <ctxLocations.Consumer>
                {(locations) => {
                  let location =
                    (locations ?? []).find(
                      (l) => l.locationId === order.dispatchLocationId
                    )?.name ?? order.dispatchLocationId;
                  return location;
                }}
              </ctxLocations.Consumer>
            </IonLabel>
          </IonItem>
        )}
        {order.locationId != null && (
          <IonItem>
            <IonLabel>Destination location</IonLabel>
            <IonLabel className="align-left text-wrap">
              <ctxLocations.Consumer>
                {(locations) => {
                  let location =
                    (locations ?? []).find((l) => l.locationId === order.locationId)
                      ?.name ?? order.locationId;
                  return location;
                }}
              </ctxLocations.Consumer>
            </IonLabel>
          </IonItem>
        )}

        {order.transport?.vessel && (
          <IonItem>
            <IonLabel>Vessel Name</IonLabel>
            <IonLabel className="align-left text-wrap">
              {order.transport.vessel}
            </IonLabel>
          </IonItem>
        )}

        {order.transport?.container && (
          <IonItem>
            <IonLabel>Container</IonLabel>
            <IonLabel className="align-left text-wrap">
              {order.transport.container}
            </IonLabel>
          </IonItem>
        )}

        {order.transport?.transportReference && (
          <IonItem>
            <IonLabel>Shipping ref.</IonLabel>
            <IonLabel className="align-left text-wrap">
              {order.transport.transportReference}
            </IonLabel>
          </IonItem>
        )}

        {order.externalReference && (
          <IonItem>
            <IonLabel>{`${
              order.type === 'SELL' ? 'Buyer' : 'Supplier'
            } ref.`}</IonLabel>
            <IonLabel className="align-left text-wrap">
              {order.externalReference}
            </IonLabel>
          </IonItem>
        )}

        {order.type === 'SELL' ? (
          order.contactId ? (
            <IonItem routerLink={'/secure/contact/' + order.contactId} detail={false}>
              <IonLabel>{i18n.t('PageOrder.buyer')}</IonLabel>
              <IonLabel className="organization-name align-left text-wrap">
                <ViewContactName report={order} />
              </IonLabel>
            </IonItem>
          ) : (
            <IonItem routerLink={'/secure/contact/' + order.orgId} detail={false}>
              <IonLabel>{i18n.t('PageOrder.seller')}</IonLabel>
              <IonLabel className="organization-name align-left text-wrap">
                {order.orgId}
              </IonLabel>
            </IonItem>
          )
        ) : null}

        {order.type === 'SELL' && order.supplierName ? (
          <IonItem routerLink={'/secure/contact/' + order.contactId} detail={false}>
            <IonLabel>Supplier</IonLabel>
            <IonLabel className="organization-name align-left text-wrap capitalize">
              {order.supplierName.toLocaleLowerCase()}
            </IonLabel>
          </IonItem>
        ) : null}

        {order.type === 'BUY' ? (
          order.contactId ? (
            <IonItem routerLink={'/secure/contact/' + order.contactId} detail={false}>
              <IonLabel>{i18n.t('PageOrder.seller')}</IonLabel>
              <IonLabel className="organization-name align-left text-wrap">
                <ViewContactName report={order} />
              </IonLabel>
            </IonItem>
          ) : (
            <IonItem routerLink={'/secure/contact/' + order.orgId} detail={false}>
              <IonLabel>{i18n.t('PageOrder.seller')}</IonLabel>
              <IonLabel className="organization-name align-left text-wrap">
                {order.orgId}
              </IonLabel>
            </IonItem>
          )
        ) : null}

        {/* {order.growerContactId ?
          <IonItem detail routerLink={"/secure/contact/" + order.growerContactId}>
            <IonLabel>{i18n.t('PageOrder.growerContactId')}</IonLabel>
            <IonLabel className="align-left text-wrap">
              <ViewContactName order={order} contactId={order.growerContactId} />
            </IonLabel>
          </IonItem> : ''} */}
      </div>
    );
  }

  renderReportGeneral() {
    return (
      <>
        <div className="report-general-overview">
          <div className="general">{this.renderGeneral()}</div>
          {this.renderReportColumn(
            'report-list',
            ['SHARED', 'CHECKED'].includes(this.state.order?.qcStatus)
          )}
        </div>
      </>
    );
  }

  renderReportColumn(className, locked = false) {
    let { order } = this.state;

    if (!order) {
      return;
    }

    // console.log('iddd', order.lastModifiedUserId)

    const reportReferencesGenFromOrder: ReportReference[] = (
      order?.reportReferences ?? []
    )
      // .filter(r => r.autogenFromOrder)
      .sort(sortByLastModifiedDateDesc);

    const userEmail = (userId: string) =>
      this.props.users?.find((u) => u.id === userId)?.email ?? '';
    const date =
      order.lastModifiedUserId === 'system'
        ? 'Not yet started'
        : formatDate(order.lastModifiedDate, {
            dateStyle: 'short',
            timeStyle: 'short',
          });

    return (
      <div className={className}>
        {/* TODO: only display when order is open? */}
        {/* {order.qcStatus !== 'CHECKED' && <div> */}
        {order.hasReportDraft && (
          <div>
            <IonListHeader>
              <h1>Report draft</h1>
            </IonListHeader>
            <IonItem
              data-tip={'report-draft-button'}
              onClick={() =>
                this.props.history.push(`/secure/report/draft/${order.id}`)
              }
              button
              className="report-button"
            >
              {locked ? (
                <IonIcon icon={lockClosedOutline} slot="start" />
              ) : (
                <IonIcon icon={documentOutline} slot="start" />
              )}
              <div>
                <div className="id">{order.id}</div>
                <div className="when">{date}</div>
                <div className="by">{userEmail(order.lastModifiedUserId)}</div>
              </div>
            </IonItem>
          </div>
        )}

        {reportReferencesGenFromOrder?.length > 0 && (
          <div>
            <IonListHeader>
              <h1>Reports</h1>
            </IonListHeader>
            {reportReferencesGenFromOrder.map((r, i) => (
              <IonItem
                key={i}
                onClick={() => {
                  this.props.history.push('/secure/report/' + r.reportId);
                }}
                button
              >
                {/* <IonIcon icon={documentAttachOutline} slot="start" /> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    alignItems: 'flex-start',
                  }}
                >
                  <div className="when">
                    <IonBadge color="light-medium">
                      {formatDate(r.lastModifiedDate, {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                    </IonBadge>
                    <IonBadge>{r.type}</IonBadge>
                  </div>
                  <div className="by">{r.lastModifiedUserEmail}</div>
                  {
                    <div className="shared">
                      {r.sharingDate != null
                        ? `Last shared: ${formatDate(r.sharingDate, {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}`
                        : 'Not shared yet'}
                    </div>
                  }
                </div>
              </IonItem>
            ))}
          </div>
        )}
      </div>
    );
  }

  applyFilter(key, lotsArray) {
    if (this.state.filters?.key === key) {
      this.setState({ filters: undefined });
    } else {
      this.setState({
        filters: {
          lots: lotsArray,
          key: key,
        },
      });
    }
  }

  async onBlur(orderId) {
    // console.log('-- blur -- ', orderId);
    // const order: Order = await getCrossOrder(firestore, orderId);
    // console.log('order', order);
  }

  renderTopInformation(orderDone) {
    // console.log('renderTopInformation', orderDone)
    return this.renderReportGeneral();
  }

  renderListInformation(
    orderDone,
    order: Order,
    inspections: LegacyInspection[],
    myOrgOrder: boolean
  ) {
    // separate normal lots from split lots
    const normalPositions = order.positions.filter((p) => !isSplitLotPosition(p));
    const splitLotPositions = order.positions.filter(isSplitLotPosition);

    // const normalAssessments = assessments.filter(a => normalPositions.map(p => p.lotId).includes(a.reference.lotId) || !!a.reference.transportId);
    // const splitLotAssessments = assessments.filter(a => splitLotPositions.map(p => p.lotId).includes(a.reference.lotId));

    const normalInspections = inspections.filter(
      (a) =>
        normalPositions.map((p) => p.lotId).includes(a.reference.lotId) ||
        !!a.reference.transportId
    );
    const splitLotInspections = inspections.filter((a) =>
      splitLotPositions.map((p) => p.lotId).includes(a.reference.lotId)
    );

    return (
      <>
        {this.renderTransportOverview(order, inspections)}
        {this.renderLotOverviewList(
          order,
          normalPositions,
          normalInspections,
          myOrgOrder
        )}
        {splitLotPositions.length > 0 && (
          <>
            <IonItemDivider />
            <IonListHeader mode="md" className="separator child-batches">
              <IonIcon icon={layersOutline} /> <h2>Child batches</h2>
            </IonListHeader>
            {this.renderLotOverviewList(
              order,
              splitLotPositions,
              splitLotInspections,
              myOrgOrder,
              undefined,
              'split-lot'
            )}
          </>
        )}
        {this.renderAttachmentItems(order)}
      </>
    );
  }

  closeLotModal() {
    this.setState({
      showNewLotModal: false,
      showLinkLotModal: false,
      currContactPosition: undefined,
    });
  }

  closeMismatchesModal() {
    this.setState({ showMismatches: this.defaultMismatch });
  }

  async saveLot(lot: LotCreatePayload, inspect: boolean = false) {
    const { order, currContactPosition } = this.state;
    const { profile, history, products, organisation } = this.props;

    // Check if lot with current id exists
    const oldLot = await getLot(firestore, profile.organisationId, lot.id);
    if (!!oldLot) {
      alert(`Error: lot ${lot.id} already exists, please enter a different id`);
      return;
    }

    await createLotFromContactPosition(
      firestore,
      lot,
      order,
      currContactPosition,
      profile,
      products,
      organisation?.settings
    );

    this.setState(
      {
        showNewLotModal: false,
        currContactPosition: undefined,
        lotOptionsPopover: { visible: false },
      },
      () => {
        if (inspect) {
          const inspectionReference: LegacyInspectionReference = {
            type: 'outgoing',
            lotId: lot.id,
            orderId: order.id,
            date: dateToString(new Date()),
          };
          history.push(
            '/secure/' +
              profile.organisationId +
              '/inspection/' +
              stringifyInspectionReference(inspectionReference)
          );
        }
      }
    );
  }

  myOrgOrder() {
    let myOrgOrder = false;
    if (!this.props.sharedReport) {
      myOrgOrder = this.props.profile.organisationId === this.props.organisationId;
    }
    return myOrgOrder;
  }

  async createReport() {
    const { profile, history, organisation } = this.props;
    if (window.confirm(`Are you sure you want to create the report?`))
      createInternalReportFromOrder(
        firestore,
        profile,
        this.state.order,
        organisation?.settings
      )
        .then((report) => history.push('/secure/report/' + report.id))
        .catch((err) => {
          window.alert('Error creating report: ' + JSON.stringify(err));
        });
  }

  // no more banner for now
  // ----------------------------------------------------------------
  // renderSupplyChainReportsBanner() {
  //   const { order } = this.state;

  //   const SCReportReferences: ReportReference[] = getSupplyChainReportReferences(order);

  //   if (SCReportReferences.length === 0) {
  //     return <></>
  //   }

  //   return

  //   return <IonList className='ion-no-padding'>
  //     {SCReportReferences.map((r, idx) => {
  //       const link = `/secure/report/${r.reportId}`;

  //       // if there's more than one supply chain report associated to the order, add date to the title to avoid confusion
  //       let title = `${getReportTypeTitle(r.type)} available`;
  //       if (SCReportReferences.length > 1) {
  //         title += ` - ${formatDate(r.sharingDate, { dateStyle: 'short', timeStyle: 'short' })}`;
  //       }

  //       return <IonItem key={idx} className="external-id animated" detail color="primary" style={{ cursor: "pointer" }} onClick={_ => this.props.history.push(link)}>
  //         <IonLabel>
  //           <IonIcon icon={alertCircleOutline} slot="start" />
  //           {title}
  //         </IonLabel>
  //       </IonItem>
  //     })}
  //   </IonList>
  // }

  render() {
    let { orderId, profile } = this.props;
    let {
      order,
      showDeleteAlert,
      showNewLotModal,
      alert,
      showSplitLotModal,
      currPosition,
      lotOptionsPopover,
      showMismatches,
    } = this.state;

    console.log('order', order);

    if (this.props.sharedReport) {
      //@ts-ignore
      profile = { userRoles: [] };
    }

    let inspections: LegacyInspection[] = orderInspectionsList(order);

    const myOrgOrder = this.myOrgOrder();

    let sum = inspections?.reduce((accumulator, element) => {
      if (element.reference?.transportId) return accumulator;
      else return accumulator + (new InspectionClass(element).isCompleted() ? 1 : 0);
    }, 0);

    // TODO: This assumes that there is always a transport inspection, revise this to: is there any
    // non transport inspection that is OPEN?
    // let orderDone = sum === assessments.length - 1;
    let orderDone = sum === inspections.length - 1;

    let className = 'page-order' + (!myOrgOrder ? ' upfront-page' : '');

    let enableCreateReport =
      order?.qcStatus === 'DONE' &&
      userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS');

    const displayCreateReport =
      userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') &&
      order?.qcStatus !== 'CHECKED';

    return (
      <IonPage className={className} ref={this.pageRef}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" className="no-print">
              <IonBackButton
                text={i18n.t('General.back')}
                defaultHref="/tabs/quality-control"
                color="dark"
              >
                <IonIcon icon={chevronBackOutline} slot="icon-only" />
              </IonBackButton>
              {/* <IonButton 
              color='dark'
              onClick={_=>{
                // debugger
                this.props.history.push('/tabs/quality-control')}
              }
            >
              <IonIcon icon={chevronBackOutline}/> Back
            </IonButton> */}
            </IonButtons>
            <IonTitle>{orderId}</IonTitle>
            {myOrgOrder && order && (
              <IonButtons slot="end" className="no-print">
                <SideMenu
                  profile={this.props.profile}
                  order={order}
                  history={this.props.history}
                  setState={this.setState.bind(this)}
                  organisation={this.props.organisation}
                  contacts={this.props.contacts}
                  users={this.props.users}
                />
                {/* <IonButton color="dark"  onClick={_ => this.setState({showNewMetadata: !this.state.showNewMetadata})}>
                {(!this.state.showNewMetadata)
                  ? <IonIcon icon={informationCircleOutline}></IonIcon>
                  : <IonIcon icon={closeCircleOutline}></IonIcon>}
              </IonButton> */}
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent
          id="page-order-content"
          className={!!this.state.copyInspections ? 'copy-assessments' : ''}
        >
          <div className="main-wrapper">
            {/* {this.renderSupplyChainReportsBanner()} */}

            {showNewLotModal && (
              <IonModal isOpen={true} onDidDismiss={() => this.closeLotModal()}>
                <PageNewLot
                  onCancel={() => this.closeLotModal()}
                  onSave={(lot: LotCreatePayload, inspect: boolean) =>
                    this.saveLot(lot, inspect)
                  }
                  // position={this.state.currContactPosition}
                  // order={order}
                ></PageNewLot>
              </IonModal>
            )}

            {showMismatches.show && (
              <IonModal
                isOpen={showMismatches.show}
                onDidDismiss={() => this.closeMismatchesModal()}
              >
                <ViewAllocationMismatchWarning
                  allocationMismatchList={showMismatches.mismatches}
                  onClose={() => this.closeMismatchesModal()}
                  expectedId={showMismatches.expectedId}
                  foundId={showMismatches.foundId}
                />
              </IonModal>
            )}

            {/* TODO: reimplement if needed */}
            {/* {showLinkLotModal &&
            <IonModal
              isOpen={true}
              onDidDismiss={_ => this.closeLotModal()}>
              <ViewLotLink
                {...this.props}
                profile={profile}
                applicationContext={applicationContext}
                position={currContactPosition}
                order={order}
                onCancel={() => this.closeLotModal()}
                onLink={(lot: Lot, isOwnPosition: boolean = false) => this.linkLot(lot, isOwnPosition)}
              />
            </IonModal>} */}

            {/* Lot options popover */}
            <IonPopover
              mode="md"
              isOpen={lotOptionsPopover.visible}
              event={lotOptionsPopover.event}
              onDidDismiss={() =>
                this.setState({
                  lotOptionsPopover: { visible: false },
                  currPosition: undefined,
                })
              }
            >
              {this.renderLotOptions(this.state.currPosition)}
            </IonPopover>

            {/* TODO: refactor this with the new generic alert below */}
            <IonAlert
              isOpen={!!showDeleteAlert}
              cssClass={'alert-delete'}
              onDidDismiss={() => this.setState({ showDeleteAlert: undefined })}
              header={'Warning'}
              message={`Are you sure you want to delete the file <strong>${
                showDeleteAlert ? showDeleteAlert.split('/').slice(-1)[0] : ''
              }</strong>?`}
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: (_) => this.setState({ showDeleteAlert: undefined }),
                },
                {
                  text: 'Proceed',
                  cssClass: 'accept-button-alert',
                  handler: async (_) => await this.onDeleteConfirm(showDeleteAlert),
                },
              ]}
            />

            {alert.show && (
              <IonAlert
                isOpen={alert.show}
                onDidDismiss={() => this.setState({ alert: this.defaultAlert })}
                header={alert.header}
                cssClass="alert-qc"
                message={alert.message}
                buttons={[
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => this.setState({ alert: this.defaultAlert }),
                  },
                  {
                    text: 'Proceed',
                    cssClass: 'accept-button-alert',
                    handler: alert.onAccept,
                  },
                ]}
              />
            )}

            {showSplitLotModal && (
              <IonModal
                isOpen={showSplitLotModal}
                onDidDismiss={(_) =>
                  this.setState({
                    showSplitLotModal: false,
                    lotOptionsPopover: { visible: false },
                  })
                }
              >
                <PageSplitLot
                  profile={this.props.profile}
                  order={order}
                  motherPosition={currPosition}
                  onCancel={(_) =>
                    this.setState({
                      showSplitLotModal: false,
                      lotOptionsPopover: { visible: false },
                    })
                  }
                  onSave={(splitLotOrder: Order) => this.saveSplitLot(splitLotOrder)}
                ></PageSplitLot>
              </IonModal>
            )}
            <IonLoading isOpen={this.state.showLoadingSpinner} />
            {displayCreateReport && enableCreateReport && (
              <div slot="fixed" className="button-container">
                <IonButton
                  data-tip={'page-order-create-report'}
                  disabled={!enableCreateReport}
                  className={enableCreateReport ? '' : 'translucent'}
                  expand="block"
                  color="tertiary"
                  onClick={() => this.createReport()}
                >
                  <IonIcon icon={documentAttachOutline} />
                  &nbsp;Create report
                </IonButton>
              </div>
            )}

            {/* top banner showing this order is external */}
            {!myOrgOrder && (
              <div className="shared-order-banner">
                {`${getOrderReportTypeTitle(order)} from `}
                {!this.props.sharedReport
                  ? this.props.organisationId
                  : //@ts-ignore
                    order?.sharedBy?.email}
              </div>
            )}

            {this.state.copyInspections && !this.state.copyInspectionsSource && (
              <div className="copy-assessment-banner first">
                <div>
                  <b>COPY INSPECTIONS:</b> Select <b>SOURCE</b> inspection
                </div>
                <div className="buttons">
                  <IonButton
                    color="dark"
                    fill="clear"
                    onClick={(_) =>
                      this.setState({
                        copyInspections: false,
                        copyInspectionsTargets: [],
                        copyInspectionsSource: undefined,
                      })
                    }
                  >
                    cancel
                  </IonButton>
                </div>
              </div>
            )}

            {this.state.copyInspections && !!this.state.copyInspectionsSource && (
              <div className="copy-assessment-banner">
                <div>
                  <b>
                    SOURCE INSPECTION:{' '}
                    {this.state.copyInspectionsSource.reference.lotId}
                  </b>
                  <br />
                  Selected {this.state.copyInspectionsTargets.length} <b>TARGET OPEN</b>{' '}
                  inspection(s)
                </div>
                <div className="buttons">
                  <IonButton
                    color="dark"
                    fill="clear"
                    onClick={(_) =>
                      this.setState({
                        copyInspections: false,
                        copyInspectionsSource: undefined,
                        copyInspectionsTargets: [],
                      })
                    }
                  >
                    Cancel
                  </IonButton>
                  <IonButton
                    color="dark"
                    disabled={this.state.copyInspectionsTargets.length === 0}
                    onClick={(_) => this.copyInspections()}
                  >
                    Copy
                  </IonButton>
                </div>
              </div>
            )}

            {/* TODO: Emi, is this still in use? */}
            {/* {(this.state.showNewMetadata) &&
            <IonList className="metadata" slot="fixed">
              <IonItem>
                <IonLabel>IO Number</IonLabel>
                <IonInput inputmode="numeric" type="text" value={""} onBlur={() => this.onBlur("IO-20-07987")} />
              </IonItem>
              <IonItem>
                <IonLabel>Supplier</IonLabel>
                <IonSelect
                  className="select-contact"
                  interface="action-sheet">
                  {this.props.applicationContext.contacts
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .map(contact =>
                      <IonSelectOption key={contact.id} value={contact.id}>{contact.name}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Extra field 1</IonLabel>
                <IonInput type="text" value={""}/>
              </IonItem>
              <IonItem>
                <IonLabel>Extra field 2</IonLabel>
                <IonInput inputmode="numeric" type="text" value={""}/>
              </IonItem>
              <IonItem>
                <IonLabel>Some date</IonLabel>
                <IonDatetime />
              </IonItem>
            </IonList>} */}

            {order === undefined ? (
              this.renderLoading()
            ) : order === null ? (
              <h1 className="h1-not-found">not found</h1>
            ) : (
              <IonList className="list-wrapper">
                {this.renderTopInformation(orderDone)}
                {this.renderListInformation(orderDone, order, inspections, myOrgOrder)}
              </IonList>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }

  /* LOT OPTIONS */

  lotOptionsPermissions(
    isBuyerPosition: boolean,
    order: Order,
    position: LotPosition,
    myOrgOrder: boolean
  ) {
    const displaySplitLots =
      !isBuyerPosition &&
      order?.type === 'BUY' &&
      this.props.organisation?.settings?.allowSplitLots &&
      userHasPermission(this.props.profile, 'SPLIT', 'LOT');

    return { displaySplitLots };
  }

  hasNoLotOptionsPermission(
    isBuyerPosition: boolean,
    order: Order,
    position: LotPosition,
    myOrgOrder: boolean
  ) {
    const { displaySplitLots } = this.lotOptionsPermissions(
      isBuyerPosition,
      order,
      position,
      myOrgOrder
    );
    return !displaySplitLots;
  }

  renderLotOptions(position: LotPosition) {
    const wrapper = (
      icon: string,
      func: (e: any) => any,
      text: string,
      disabled: boolean,
      dataTipSuffix: string,
      color: string = 'primary'
    ) => (
      <IonItem
        data-tip={`lot-options-${dataTipSuffix}`}
        button
        disabled={disabled}
        onClick={func}
      >
        <IonIcon color={color} icon={icon} />
        &nbsp;
        <IonLabel>{text}</IonLabel>
      </IonItem>
    );

    const { order, isBuyerPosition, myOrgOrder } = this.state.lotOptionsPopover;

    const { displaySplitLots /*, displayRemoveAssessments*/ } =
      this.lotOptionsPermissions(isBuyerPosition, order as Order, position, myOrgOrder);

    // console.log("isBuyerPosition", isBuyerPosition, "myOrgOrder", myOrgOrder, "sharedReport", this.props.sharedReport, "hasRoles(this.props.profile, ['CREATE_LOT'])", hasRoles(this.props.profile, ['CREATE_LOT']), )

    if (
      this.hasNoLotOptionsPermission(
        isBuyerPosition,
        order as Order,
        position,
        myOrgOrder
      )
    ) {
      return (
        <IonItem>
          <IonText color="medium">No actions available</IonText>
        </IonItem>
      );
    }

    return (
      <IonList>
        {displaySplitLots && (
          <>
            {wrapper(
              layersOutline,
              (e) => this.onLotSplit(e, position),
              'Split batch',
              false,
              'split-lot',
              'dark'
            )}
            {position?.motherLotIds?.length > 0 &&
              wrapper(
                arrowUndoOutline,
                (e) => this.onLotSplitUndo(e, position),
                'Undo split batch',
                false,
                'dark'
              )}
          </>
        )}

        {/* {(displayRemoveAssessments && false) && <>
        {wrapper(trashOutline, e => this.onDeleteAssessment(e, position), 'Clear assessment', false, 'dark')}
      </>} */}

        {/* TODO: reimplement if needed */}
        {/* {displayLinkAndCreate && <>
        {wrapper(addOutline, e => this.openNewLotModal(e, position), 'Create batch and link', false, 'primary')}
        {wrapper(linkOutline, e => this.openLinkLotModal(e, position), 'Link to existing batch', false, 'secondary')}
      </>}

      {displayUnlink && <>
        {wrapper(unlinkOutline, e => this.onLotUnlink(e, position, order), 'Unlink batches', false, 'danger')}
      </> */}
      </IonList>
    );
  }

  async saveSplitLot(splitLotOrder: Order) {
    try {
      // console.log("SPLIT LOT ORDER", splitLotOrder);
      this.setState({ showLoadingSpinner: true });
      // Note: this function requires the user to be in online mode
      await firestore.runTransaction(async (transaction) => {
        await setSplitLotTransactional(
          firestore,
          transaction,
          this.props.profile,
          splitLotOrder
        );
      });
      this.setState({
        showLoadingSpinner: false,
        lotOptionsPopover: { visible: false },
      });
    } catch (error) {
      alert(`Error while creating sub batch: ${error}`);
      this.setState({
        showLoadingSpinner: false,
        lotOptionsPopover: { visible: false },
      });
    }
  }

  // async onDeleteAssessment(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, position: LotPosition) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (window.confirm(`WARNING: Are you sure you want to remove the inspection of batch ${position.lotId}? This action cannot be undone`)) {
  //     await this.deletePositionAssessment(position);
  //   }
  //   this.setState({ lotOptionsPopover: { visible: false }, currPosition: undefined })
  // }

  async deletePositionInspection(position: LotPosition) {
    this.setState({ showLoadingSpinner: true });

    const { profile } = this.props;
    const { order } = this.state;
    const inspectionKey = `${LOT_INSPECTION_MAP}.${position.lotId}`;
    const inspection: LotInspection = order?.lotInspectionMap?.[position.lotId];
    const deleteField = firebase.firestore.FieldValue.delete() as any;

    const hasInspections: boolean =
      Object.keys(order.lotInspectionMap ?? {}).length > 0;
    const orderUpdateObj: Order = {
      [inspectionKey]: deleteField,
      lastModifiedUserId: profile.id,
      lastModifiedDate: new Date(),
      hasReportDraft: hasInspections,
    } as Order;

    if (!hasInspections) {
      orderUpdateObj.lastQCDate = deleteField;
      orderUpdateObj.firstQCDate = deleteField;
      orderUpdateObj.lastQCStatusDate = deleteField;
      orderUpdateObj.lastQCStatusUserId = deleteField;
    }

    orderColRef(firestore, profile.organisationId)
      .doc(order.id)
      .update(orderUpdateObj)
      .then(async (_) => {
        // delete assessment in lot as well
        const lotRef = lotColRef(firestore, profile.organisationId).doc(position.lotId);
        const lotDoc = await lotRef.get();
        if (lotDoc.exists) {
          const lot: Lot = lotDoc.data() as Lot;

          const inspections: LotInspection[] = (lot.inspections ?? [])
            .filter((a) => !isEqual(a.reference, inspection.reference))
            .sort(sortByLastModifiedDateAsc);

          let latestInspection: LotInspection = isEqual(
            lot.latestInspection?.reference,
            inspection.reference
          )
            ? inspections[inspections.length - 1]
            : lot.latestInspection;

          latestInspection = latestInspection ?? deleteField;

          const lotUpdateObj: Lot = {
            inspections,
            latestInspection,
            lastModifiedUserId: profile.id,
            lastModifiedDate: new Date(),
            search: {
              ...lot.search,
              hasAssessment: inspections.length > 0,
            },
          } as Lot;

          if ((inspections ?? []).length === 0) {
            lotUpdateObj.lastQCDate = deleteField;
            lotUpdateObj.firstQCDate = deleteField;
          } else {
            // find the last assessment lastQCDate and update it
            lotUpdateObj.lastQCDate = inspections.reduce(
              (acc, a) =>
                a.lastModifiedDate.valueOf() > acc.valueOf() ? a.lastModifiedDate : acc,
              inspections[0].lastModifiedDate
            );
          }

          return lotRef.update(lotUpdateObj);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: {
            message: error,
            header: 'Error deleting inspection',
            show: true,
            onAccept: () => {},
          },
        });
      })
      .finally(() => this.setState({ showLoadingSpinner: false }));
  }

  onLotSplit(
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    position: LotPosition
  ) {
    e.preventDefault();
    e.stopPropagation();
    // this.setState({ currPosition: position, showSplitLotModal: true });
    this.setState({ showSplitLotModal: true, currPosition: position });
  }

  async onLotSplitUndo(
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    position: LotPosition
  ) {
    e.preventDefault();
    e.stopPropagation();

    const { order } = this.state;
    this.setState({ showLoadingSpinner: true, lotOptionsPopover: { visible: false } });

    // don't allow deletion if the sublot being undone is the mother lot of yet another sublot
    const sublot: Lot = await getLot(
      firestore,
      this.props.profile.organisationId,
      position.lotId
    );
    const splitLotTransfers: LotTransfer[] = sublot.transfers.filter(
      (t) => t.fromLots?.[0].lotId === position.lotId && t.transferType === 'SPLIT_LOT'
    );
    if (splitLotTransfers.length > 0) {
      alert(
        `Child batch cannot be undone, as it is the mother batch of batch(es) ${splitLotTransfers
          .map((t) => t.toLots?.[0]?.lotId)
          .join(', ')}`
      );
      this.setState({ showLoadingSpinner: false });
      return;
    }

    // don't allow the undo if position has an associated inspection
    if (!!order.lotInspectionMap[position.lotId]) {
      if (
        window.confirm(
          `Are you sure you want to undo split batch ${
            position.lotId
          } from ${position.motherLotIds.join(
            ','
          )}?\nThis action will also remove the inspection from ${position.lotId}`
        )
      ) {
        await this.deletePositionInspection(position);
        await this.undoSplitLot(position, sublot);
      }
      this.setState({ showLoadingSpinner: false });
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to undo split batch ${
          position.lotId
        } from ${position.motherLotIds.join(',')}?`
      )
    ) {
      await this.undoSplitLot(position, sublot);
    }
    this.setState({ showLoadingSpinner: false });
  }

  async undoSplitLot(position: LotPosition, sublot: Lot) {
    const { order } = this.state;
    await undoSplitLotOrder(
      firestore,
      this.props.profile,
      position,
      order,
      this.props.organisation?.settings,
      sublot
    );
  }
}

export default React.memo(
  withContext(withIonLifeCycle(PageOrder), [
    'profile',
    'organisation',
    'users',
    'products',
    'contacts',
  ])
);

const PopoverList = ({
  onHide,
  onClick,
  profile,
  order,
  orgSettings,
}: {
  onHide: any;
  onClick: any;
  profile: UserProfile;
  order: Order;
  orgSettings: OrganisationSettings;
}) => {
  const allowShareInternal =
    userHasPermission(profile, 'SHARE_INT', 'ORDER') &&
    orgHasPermission(orgSettings, AppProduct.QualityControl, QCTierAction.Chat);
  const allowCopyInspections =
    !['SHARED', 'CHECKED'].includes(order.qcStatus) &&
    userHasPermission(profile, 'WRITE', 'ASSESSMENT');
  const allowMarkAsOpen =
    !['SHARED', 'CHECKED'].includes(order.qcStatus) &&
    userHasPermission(profile, 'WRITE', 'QC_ORDER_STATUS') &&
    order.qcStatus !== 'OPEN';
  const allowMarkAsDone =
    !['SHARED', 'CHECKED'].includes(order.qcStatus) &&
    userHasPermission(profile, 'WRITE', 'QC_ORDER_STATUS') &&
    order.qcStatus !== 'DONE';
  // const allowCreateReport = (hasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS')) && order.qcStatus === 'DONE';
  const allowMarkAsRedo =
    !['SHARED', 'CHECKED'].includes(order.qcStatus) &&
    userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') &&
    order.qcStatus !== 'REDO';
  // const allowMarkAsShared = (hasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') || hasRole(profile, 'QC_SURVEYER')) && order.qcStatus === 'CHECKED';
  const allowReopen =
    ['SHARED', 'CHECKED'].includes(order.qcStatus) &&
    userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS');

  const allowUpload =
    !['SHARED', 'CHECKED'].includes(order.qcStatus) &&
    userHasPermission(profile, 'UPLOAD', 'DOCUMENT');
  const allowPrint = true;

  const allowDelete =
    applicationStateObservable.getValue().networkEnabled &&
    userHasPermission(profile, 'DELETE', 'ORDER') &&
    (order.isAGMaster || orgSettings.allowDeletingApiImportedEntities);

  // TODO: reimplement if needed
  const allowLink = false;
  const allowUndoOrder = false;

  if (
    !allowLink &&
    !allowShareInternal &&
    !allowCopyInspections &&
    !allowMarkAsRedo &&
    !allowMarkAsDone &&
    !allowUndoOrder &&
    !allowUpload &&
    !allowPrint
  ) {
    return (
      <IonList>
        <IonItem>
          <IonText color="medium">No actions available</IonText>
        </IonItem>
      </IonList>
    );
  }

  return (
    <IonList>
      {/* TODO: reimplement if needed */}
      {/* {allowLink && <IonItemDivider>Order linking</IonItemDivider>} */}
      {/* { allowLink
          ? ((!order.contactOrder || !order.contactOrder?.validLink))
            ? <IonItem button onClick={_=>onClick("link")}>
                <IonIcon icon={linkOutline} className="popup-icon" slot="start" />
                Link to External ID
              </IonItem>
            : <IonItem button onClick={_=>onClick("unlink")}>
                <IonIcon icon={unlinkOutline}  className="popup-icon" slot="start" />
                Remove External ID
              </IonItem>
          : null
      } */}

      {(allowMarkAsOpen || allowReopen || allowMarkAsDone || allowMarkAsRedo) && (
        <IonItemDivider>Order status</IonItemDivider>
      )}

      {allowReopen && (
        <IonItem button onClick={(_) => onClick('reopen')}>
          <IonIcon icon={lockOpenOutline} className="popup-icon" slot="start" />
          Reopen inspections
        </IonItem>
      )}

      {allowMarkAsOpen && (
        <IonItem button onClick={(_) => onClick('open')}>
          <IonIcon icon={ellipseOutline} className="popup-icon" slot="start" />
          Mark as &nbsp; {<IonBadge color="medium">OPEN</IonBadge>}
        </IonItem>
      )}

      {allowMarkAsDone && (
        <IonItem
          data-tip={'page-order-set-to-done'}
          button
          onClick={(_) => onClick('skip')}
        >
          <IonIcon icon={checkmarkCircleOutline} className="popup-icon" slot="start" />
          Mark as &nbsp; {<IonBadge color="dark">DONE</IonBadge>}
        </IonItem>
      )}

      {allowMarkAsRedo && (
        <IonItem button onClick={(_) => onClick('redo')}>
          <IonIcon icon={arrowRedoCircleOutline} className="popup-icon" slot="start" />
          Mark as &nbsp; {<IonBadge color="danger">REDO</IonBadge>}
        </IonItem>
      )}

      <IonItemDivider>Other actions</IonItemDivider>

      {allowShareInternal && (
        <IonItem button onClick={(_) => onClick('chat')}>
          <IonIcon
            icon={chatbubbleEllipsesOutline}
            className="popup-icon"
            slot="start"
          />
          Start new conversation
        </IonItem>
      )}

      {allowCopyInspections && (
        <IonItem button onClick={(_) => onClick('copyInspections')}>
          <IonIcon icon={documentsOutline} className="popup-icon" slot="start" />
          Copy Inspections
        </IonItem>
      )}

      {allowUpload && (
        <IonItem disabled={!navigator.onLine} button onClick={(_) => onClick('upload')}>
          <IonIcon icon={documentAttachOutline} className="popup-icon" slot="start" />
          Upload attachment
        </IonItem>
      )}

      {allowPrint && (
        <IonItem disabled={!navigator.onLine} button onClick={(_) => onClick('print')}>
          <IonIcon icon={printOutline} className="popup-icon" slot="start" />
          Print
        </IonItem>
      )}

      {allowDelete && (
        <>
          <IonItem color="danger" button onClick={(_) => onClick('delete')}>
            <IonIcon icon={trashOutline} className="popup-icon" slot="start" />
            Delete Order
          </IonItem>
        </>
      )}
    </IonList>
  );
};

// ----------------------------------------------------------------

const SideMenu = ({
  profile,
  order,
  history,
  setState,
  contacts,
  users,
  organisation,
}: {
  profile: UserProfile;
  order: Order;
  history;
  setState;
  contacts: Contact[];
  users: User[];
  organisation: Organisation;
}) => {
  const [showChat, setShowChat] = useState<boolean>(false);

  // App text for translations
  const appText_orderReopenAlert =
    'Are you sure you want to modify the inspections? Note that a report has already been created';

  const contact = hasRole(profile, 'COMMERCIAL')
    ? contacts?.find((c) => c.id === order?.contactId)
    : undefined;

  const fileInput = useRef<HTMLInputElement>(null);

  // const [showLinkModal, setShowLinkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>(undefined);

  const clickItem = (item) => {
    dismiss();
    switch (item) {
      case 'copyInspections':
        setState({ copyInspections: true });
        return;
      case 'link':
        // TODO: reimplement if needed
        // return setShowLinkModal(true);
        return;
      case 'unlink':
        // TODO: reimplement if needed
        // if (window.confirm('DELETE External ID\nAre you sure?'))
        //   removeOrderLink(firestore, profile, order, order.contactOrder.order)
        return;
      case 'chat':
        return setShowChat(true);
      case 'skip':
        return updateOrderStatus(
          firestore,
          profile,
          order,
          'DONE',
          organisation?.settings
        );
      case 'reopen':
        if (window.confirm(appText_orderReopenAlert))
          return reopenOrder(firestore, profile, order, organisation?.settings);
        break;
      case 'open':
        return updateOrderStatus(
          firestore,
          profile,
          order,
          'OPEN',
          organisation?.settings
        );
      case 'create_report':
        if (window.confirm(`Are you sure you want to create the report?`))
          createInternalReportFromOrder(
            firestore,
            profile,
            order,
            organisation?.settings
          )
            .then((report) => history.push('/secure/report/' + report.id))
            .catch((err) => {
              window.alert('Error creating report' + JSON.stringify(err));
            });
        break;
      case 'redo':
        return updateOrderStatus(
          firestore,
          profile,
          order,
          'REDO',
          organisation?.settings
        );
      case 'delete':
        if (
          window.confirm(
            'Are you sure you want to delete the order? This action cannot be undone'
          )
        ) {
          setLoading(true);
          return deleteOrder(firestore, profile, order, 'PageOrder')
            .then((_) => history.push('/tabs/quality-control'))
            .catch((error) =>
              presentStandardToast(
                toastController,
                `Order could not be deleted\n${error}`
              )
            )
            .finally(() => setLoading(false));
        } else {
          return;
        }
      case 'upload':
        fileInput?.current?.click();
        return;
      case 'print':
        const node = document.getElementById('page-order-content').lastChild;
        const clone = node.cloneNode(true);
        //@ts-ignore
        clone.classList.add('page-order');
        //@ts-ignore
        clone.classList.add('print-mode');
        //@ts-ignore
        clone.id = 'clone';

        setLoading(true);
        document.body.appendChild(clone);

        setTimeout(() => {
          window.print();
          setLoading(false);
          document.getElementById('clone').remove();
        }, 500);
        return;
    }
  };

  async function uploadAttachments(
    event: React.ChangeEvent<HTMLInputElement>,
    profile: UserProfile,
    order: Order
  ) {
    const files = event.target.files;
    const currentAttachments: string[] = order.attachments ?? [];

    setLoading(true);
    setLoadingMessage(`Uploading files...`);

    // upload files
    let { errors, newAttachments } = await uploadOrderOrReportAttachments(
      storage,
      files,
      profile,
      order
    );

    // Update order
    const mergedAttachments: string[] = [
      ...new Set([...currentAttachments, ...newAttachments]),
    ];

    try {
      await updateOrderAttachments(firestore, profile, order, mergedAttachments);
    } catch (error) {
      console.log(`Error updating order`, error);
      errors = [];
      for (const file of files) {
        errors.push({
          filename: file.name,
          error: 'Could not update order in database',
        });
      }
    }

    if (errors.length > 0) {
      alert(
        `Following files could not be uploaded:\r\n${errors
          .map((e) => `File: ${e.filename}, ${e.error}`)
          .join('\r\n')}`
      );
    }

    setLoadingMessage(undefined);
    setLoading(false);
  }

  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    onClick: clickItem,
    profile: profile,
    order: order,
    orgSettings: organisation?.settings,
  });

  // TODO: reimplement if needed
  // const updateOrderLinkMessage = async (supplierOrder:Order) => {
  //   // if (!window.confirm('Are you sure? ' + supplierOrderId)) {
  //   //   return
  //   // }
  //   setShowLinkModal(false)
  //   if(supplierOrder.id !== order.contactOrder?.id) {
  //     const toast = await toastController.create({
  //       message: 'orders have been linked',
  //       position: 'top',
  //       color: 'dark',
  //       duration: 3000,
  //       buttons: [{
  //         text: 'Ok',
  //         role: 'cancel'
  //       }]
  //     });

  //     toast.present().then();
  //     return updateOrderLink2(firestore, profile, order, supplierOrder);
  //   }
  // }

  const saveNewConversation = async (conversation: Conversation, order: Order) => {
    let orderCopy = { ...order };
    // copy the name as a contactId
    orderCopy.contactId =
      contacts.find((c) => c.id === order.contactId)?.name ?? organisation.name;
    conversation.title = order.id;
    setShowChat(false);
    setLoading(true);
    await addOrderMessage(firestore, order, profile.id, conversation);
    await setLoading(false);

    history.push('/secure/inbox/' + conversation.id);
  };

  return (
    <>
      <IonLoading isOpen={loading} message={loadingMessage} />
      <input
        ref={fileInput}
        hidden
        multiple
        type="file"
        accept=".pdf, .doc*, .xls*, .ppt*"
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) =>
          await uploadAttachments(e, profile, order)
        }
      />
      {
        <IonButton
          data-tip={'page-order-side-menu'}
          color="dark"
          onClick={(e) => {
            present({ event: e.nativeEvent, mode: 'md' });
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <IonIcon icon={ellipsisHorizontal}></IonIcon>
        </IonButton>
      }
      {showChat && (
        <IonModal isOpen={showChat} onDidDismiss={() => setShowChat(false)}>
          <PageNewConversation
            onSave={(conversation) => saveNewConversation(conversation, order)}
            onCancel={() => setShowChat(false)}
            contact={contact}
          />
        </IonModal>
      )}
    </>
  );
};
