import { sharedFieldReportColRef, sharedFieldReportDocRef } from '../../DataStorage';
import firebase from 'firebase/compat/app';
import { ProductionSiteInspection, SharedFieldReport } from '../../InspectionModel';
import { ProductionSiteLocation, SharedTo, UserProfile } from '../../Model';
import { uuid4 } from '../../HelperUtils';
import { userProfileToUser } from '../../utils/Mappers';

export function getSharedFieldReportSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  sharedReportId: string,
  onResult: (report: SharedFieldReport) => void,
  onError?: (err: firebase.firestore.FirestoreError) => void
): () => void {
  return sharedFieldReportDocRef(firestoreRef, sharedReportId).onSnapshot(
    (doc) => {
      onResult(doc.data() as SharedFieldReport);
    },
    (error) => {
      if (!!onError) {
        onError(error);
      } else {
        throw error;
      }
    }
  );
}

export function getFieldReportSharesSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  locationId: string,
  onResult: (shares: SharedTo[]) => void,
  onError?: (err: firebase.firestore.FirestoreError) => void
): () => void {
  return sharedFieldReportColRef(firestoreRef)
    .where('locationId', '==', locationId)
    .onSnapshot(
      (snapshot) => {
        const shares: SharedTo[] = snapshot.empty
          ? []
          : snapshot.docs.map((d) => (d.data() as SharedFieldReport).sharedTo);
        onResult(shares);
      },
      (error) => {
        if (!!onError) {
          onError(error);
        } else {
          throw error;
        }
      }
    );
}

export async function addSharedFieldReport(
  firestoreRef: firebase.firestore.Firestore,
  field: ProductionSiteLocation,
  inspections: ProductionSiteInspection[],
  orgId: string,
  profile: UserProfile,
  emails: string[],
  message: string,
  associatedPartnerName?: string
) {
  const id = uuid4();

  const sharedTo: SharedTo = {
    sharedReportId: id,
    emails,
    sharedBy: userProfileToUser(profile),
    message,
    date: new Date() as any,
  };

  const report: SharedFieldReport = {
    ...field,
    id,
    inspections,
    associatedPartnerName,
    sharedTo,
    message,
    orgId,
    lastWriteSource: 'UI'
  };

  await sharedFieldReportDocRef(firestoreRef, id).set(report);
}
