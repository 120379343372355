import { get, map, flatten, uniqBy, omit } from 'lodash';
import React from 'react';
import { LotPosition, OrganisationSettings } from './Model';
import ViewAGTable from './ViewAGTable';
import { IonItem, IonListHeader } from '@ionic/react';
import { Article } from './ServiceArticle';
import { LotInspection } from './InspectionModel';
import { getInspectionBoxes, InspectionClass } from './ServiceInspection';
import { getScoreIndex } from './InspectionLogic';

interface Props {
  lotInspectionsMap: { [key: string]: LotInspection };
  searchVarieties: any;
  orgSettings: any;
  positions: LotPosition[];
}

const ViewOrderLevelAggregation = (props: Props) => {
  if (Object.keys(props.lotInspectionsMap ?? {}).length === 0) return <></>;

  let tables = uniqBy(
    flatten(
      map(props.lotInspectionsMap ?? {}, (inspection) => get(inspection, 'scores')).map(
        (attribute) =>
          Object.values(attribute ?? {}).map((groupScore: any) => {
            return {
              tableName: groupScore.name,
              aggregateId: groupScore.id,
              data: [],
              headers: [],
            };
          })
      )
    ),
    'aggregateId'
  );

  const tablesData = computeOrderSummaryData(
    props.lotInspectionsMap,
    props.orgSettings,
    props.positions
  );

  if (!tablesData) {
    return <></>;
  }

  tables.forEach((table) => {
    table.data = convertArrayOfTableDataFromMap(tablesData, props.orgSettings).map(
      (item) => item[table.aggregateId]
    );
    addTotalKeyToRow(table.data);
    addRowsOfTotalsFromKeys(table.data, props.orgSettings);
    table.headers = getHeaders(props.orgSettings, table.aggregateId);
  });

  let tablesHTML = tables
    .sort(
      (a, b) =>
        getScoreIndex(a.aggregateId, props.orgSettings) -
        getScoreIndex(b.aggregateId, props.orgSettings)
    )
    .map((table) => {
      return (
        <IonItem key={table.aggregateId}>
          <ViewAGTable
            tableName={table.tableName}
            headers={table.headers}
            data={table.data}
          />
        </IonItem>
      );
    });

  return (
    <div className="order-summary">
      <IonListHeader>
        <h1>Order Summary</h1>
      </IonListHeader>

      {tablesHTML}
    </div>
  );
};

const computeOrderSummaryData = (
  lotInspectionMap: any,
  organisationSettings: OrganisationSettings,
  positions: LotPosition[]
) => {
  let inspections: LotInspection[] = [];

  Object.keys(lotInspectionMap).forEach((key) => {
    inspections.push(lotInspectionMap[key]);
  });

  const relevantInspections = inspections.filter(
    (inspection) =>
      new InspectionClass(inspection).isCompleted() &&
      (getInspectionBoxes(inspection)?.boxesAtInspection ??
        getInspectionBoxes(inspection)?.boxesShipped) &&
      inspection.scores &&
      !!positions.find((p) => p.lotId === inspection?.reference?.lotId)
  );

  if (
    relevantInspections.length === 0 ||
    !organisationSettings ||
    !organisationSettings.orderSummary ||
    !organisationSettings.scoreSpace
  )
    return;

  const articleKeysToGroupBy = organisationSettings.orderSummary.groupBy;

  let rowsObject = {};
  let rowsIds = [];

  relevantInspections.forEach((inspection) => {
    let rowId = '';

    articleKeysToGroupBy.forEach((key) => {
      // rowId = rowId + `${assessment.article[key]}`;
      let value = extractArticleParamValue(inspection, key);
      rowId = rowId + `${value}`;
    });

    rowsObject[rowId] = {};

    articleKeysToGroupBy.forEach((key) => {
      let value = extractArticleParamValue(inspection, key);
      rowsObject[rowId][key] = value;
    });

    organisationSettings.orderSummary.aggregate.forEach((key) => {
      rowsObject[rowId][key] = {};

      let tableHeaders = organisationSettings.scoreSpace[key];
      tableHeaders.forEach((tableHeader) => (rowsObject[rowId][key][tableHeader] = 0));
    });

    rowsIds.push(rowId);
  });

  relevantInspections.forEach((inspection) => {
    let rowId = '';

    articleKeysToGroupBy.forEach((key) => {
      let value = extractArticleParamValue(inspection, key);
      rowId = rowId + `${value}`;
    });

    organisationSettings.orderSummary.aggregate.forEach((key) => {
      let rowKeyToAddValueTo = get(inspection, `scores[${key}].score`);

      if (rowId !== '' && rowId !== 'undefinedundefined') {
        const originalQuantity =
          getInspectionBoxes(inspection)?.boxesAtInspection ??
          getInspectionBoxes(inspection)?.boxesShipped ??
          0;

        // we check if the lot is the mother of other lots in the order and substract the quantities
        // const childPositions: LotPosition[] = positions.filter(p => !!p.motherLotIds && p.motherLotIds.includes(assessment.reference.lotId));
        // const substractedQuantity: number = childPositions.map(p => p.numBoxes ?? p.quantity).reduce((a, b) => a + b, 0);
        // const quantity = originalQuantity - substractedQuantity;
        const quantity = originalQuantity;

        rowsObject[rowId][key][rowKeyToAddValueTo] =
          +rowsObject[rowId][key][rowKeyToAddValueTo] + +quantity;
      }
    });
  });

  const cleanRowsObject = omit(rowsObject, ['']);

  return cleanRowsObject;
};

const addTotalKeyToRow = (tableData) => {
  // console.log("addTotalKeyToRow", tableData)
  tableData.forEach((row: any) => {
    if (!!row) {
      row.total = Object.values(row ?? {}).reduce(
        (previousValue: any, currentValue: any) => {
          if (!isNaN(currentValue)) {
            return +previousValue + +currentValue;
          } else {
            return +previousValue;
          }
        },
        0
      );
    }
  });
};

const addRowsOfTotalsFromKeys = (tableData, organisationSettings) => {
  let totalRow1 = {};
  const articleKeysToGroupBy = organisationSettings.orderSummary.groupBy;
  tableData.forEach((row: any) => {
    if (!!row) {
      Object.entries(row).forEach(([key, val]) => {
        let value: any = val;
        if (!isNaN(value)) {
          if (totalRow1[key]) {
            totalRow1[key] = +totalRow1[key] + +value;
          } else {
            totalRow1[key] = +value;
          }
        } else {
          if (key === articleKeysToGroupBy[0]) {
            totalRow1[key] = 'Total';
          } else {
            totalRow1[key] = '';
          }
        }
      });
    }
  });

  // tableData.unshift(totalRow1)
  tableData.push(totalRow1);
};

const convertArrayOfTableDataFromMap = (tablesData, orgSettings) => {
  let arrayOfTableDataFromMap = [];

  if (!tablesData || !orgSettings.orderSummary.groupBy) {
    return arrayOfTableDataFromMap;
  }

  Object.values(tablesData).forEach((value) => {
    orgSettings.orderSummary.groupBy.forEach((summary) => {
      // console.log("convertArrayOfTableDataFromMap", summary, value)
      value[orgSettings.orderSummary.aggregate[0]][summary] = value[summary];
      value[orgSettings.orderSummary.aggregate[1]][summary] = value[summary];
    });

    arrayOfTableDataFromMap.push({
      [`${orgSettings.orderSummary.aggregate[0]}`]:
        value[orgSettings.orderSummary.aggregate[0]],
      [`${orgSettings.orderSummary.aggregate[1]}`]:
        value[orgSettings.orderSummary.aggregate[1]],
    });
  });
  return arrayOfTableDataFromMap;
};

const getHeaders = (orgSettings, aggregate) => {
  let headers = [];

  if (!orgSettings.scoreSpace[aggregate]) return headers;

  let combinedPropertiesToHeaders = [
    ...orgSettings.orderSummary.groupBy,
    ...orgSettings.scoreSpace[aggregate],
  ];
  // console.log("combinedPropertiesToHeaders", combinedPropertiesToHeaders)
  headers = combinedPropertiesToHeaders.map((header, index) => ({
    name: header,
    index,
  }));
  headers.push({ name: 'total', index: headers.length });

  return headers;
};

// const mapArticleParam = (param: string) => {
//   return articleParamMap[param] ?? param;
// }

// const mapGroupByFields = (arr: string[]) => {
//   return arr.map(mapArticleParam);
// }

function extractArticleParamValue(inspection: LotInspection, key: string) {
  const { article } = inspection.lotProperties;
  let value = article[key];
  value =
    value === null && key === 'packaging'
      ? article.extPackagingRepr ?? new Article(article).getPackagingRepr()
      : value;
  return value;
}

export default ViewOrderLevelAggregation;
