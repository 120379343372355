/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MeasurementUnit = {
  WeightPercentage: 'weight_percentage',
  ProductPercentage: 'product_percentage',
  BoxesPercentage: 'boxes_percentage',
  PunnetsPercentage: 'punnets_percentage',
  ConcentrationPercentage: 'concentration_percentage',
  Punnets: 'punnets',
  Boxes: 'boxes',
  Pieces: 'pieces',
  Kilos: 'kilos',
  Pounds: 'pounds',
  Grams: 'grams',
  Celsius: 'celsius',
  Fahrenheit: 'fahrenheit',
  DegreeBrix: 'degree_brix',
  DurofelUnit: 'durofel_unit',
  Shore: 'shore',
  Millimeter: 'millimeter',
  KilosPerBox: 'kilos_per_box',
  Color: 'color',
  Question: 'question',
  Score: 'score',
  Days: 'days',
  Liters: 'liters',
  PlantsPercentage: 'plants_percentage',
} as const;

export type MeasurementUnit = (typeof MeasurementUnit)[keyof typeof MeasurementUnit];
