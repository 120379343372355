import { IonCheckbox } from '@ionic/react';
import React, { useState, useImperativeHandle, useRef } from 'react';
import { getValueAndUnitFromLocator } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import { AuxmeasurementsInner, BooleanMeasurement } from '../generated/openapi/core';
import { useInspectionContext } from '../context/InspectionContextProvider';
// import './CBoolean.scss';

// --------------------------------------------------------------------------------------------------------
// CBoolean
// --------------------------------------------------------------------------------------------------------
interface ICBoolean {
  locator: UserInputLocator;
  measurement: AuxmeasurementsInner;
  color?: string;
}
export const CBoolean = React.forwardRef((props: ICBoolean, ref) => {
  const { measurement, locator, color } = props;

  const { inspection, isPreview, updateInspection } = useInspectionContext();
  const checked = getValueAndUnitFromLocator(locator, inspection).value;
  const [lastValue, setLastValue] = useState(checked);

  const refCheckbox = useRef(undefined);

  const { displayedName } = measurement as BooleanMeasurement;

  const onIonChange = (e) => {
    // console.log(e.detail.checked)
    const value = e.detail.checked;
    e.stopPropagation?.();

    if (isPreview) {
      return (refCheckbox.current.checked = !refCheckbox.current.checked);
    }

    if (value === lastValue) {
      return;
    }
    setLastValue(value);
    updateInspection(value === false ? undefined : value, locator);
  };

  useImperativeHandle(ref, () => ({
    toggle() {
      onIonChange({ detail: { checked: !lastValue } });
    },
  }));

  // if (!inspection) return null

  // console.log(locator, checked)

  return (
    <div className={`c-input boolean ${displayedName ? 'has-label' : ''}`}>
      {/* {displayedName && <div className="label">{displayedName.trim()}</div>} */}
      <div className="actual-input">
        {isPreview ? (
          <IonCheckbox color={color ?? 'danger'} ref={refCheckbox} />
        ) : (
          <IonCheckbox
            checked={checked}
            color={color ?? 'danger'}
            // onClick={onIonChange}
          />
        )}
      </div>
    </div>
  );
});
