import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonCol,
  IonTextarea,
  IonRow,
  IonDatetime,
} from '@ionic/react';
import { closeOutline, readerOutline } from 'ionicons/icons';
import React, { useState, useContext } from 'react';
import { Claim, ClaimableOrderType, ClaimableOrderTypes, Lot, Order } from './Model';
import Select from 'react-select';
import { CurrencyCodeNames } from './HelperUtils';
import './PageCreateClaim.scss';
import { claimColRef, orderColRef } from './DataStorage';
import { firestore } from './ConfigFirebase';
import { presentStandardToast } from './HelperIonic';
import { toastController } from '@ionic/core';
import { ctxProfile } from './App';

interface Props {
  lot: Lot;
  onClose: () => void;
}

export function PageCreateClaim({ lot, onClose }: Props) {
  // Context
  const profile = useContext(ctxProfile);
  //-----------------------------------------------------
  // Options
  const currencyOptions = Object.entries(CurrencyCodeNames)
    .map(([code, name]) => ({ value: code, label: `${code} (${name})` }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const orderOptions = lot.transfers
    .filter(
      (t) =>
        ClaimableOrderTypes.includes(t.transferType as ClaimableOrderType) &&
        !!t.orderId
    )
    .map((t) => ({
      value: t.orderId,
      label: `${t.orderId} (${t.transferType})`,
    }));

  //-----------------------------------------------------
  // State
  const [loading, setLoading] = useState<boolean>(false);

  const [amount, setAmount] = useState<number | undefined>();
  const [currency, setCurrency] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [reason, setReason] = useState<string | undefined>();
  const [receivedDate, setReceivedDate] = useState<Date>(new Date());
  const [orderId, setOrderId] = useState<string | undefined>(
    orderOptions.length === 1 ? orderOptions[0].value : undefined
  );

  //-----------------------------------------------------
  // Functions
  const onSave = async () => {
    setLoading(true);

    try {
      // fetch associated order
      const order: Order = (
        await orderColRef(firestore, lot.orgId).doc(orderId).get()
      ).data() as Order;
      if (!order) throw Error(`Could not fetch order ${orderId} from database`);

      const position = order.positions.find((p) => p.lotId === lot.id);
      if (!position) throw Error(`Position ${lot.id} not found in order ${orderId}`);

      const claimId = `${orderId};${lot.id}`;

      const claim: Claim = {
        orderId,
        orderType: lot.transfers.find((t) => t.transferId === orderId)
          .transferType as ClaimableOrderType,
        claimId,
        position,
        reason,
        description,
        amount: {
          quantity: amount,
          currency,
        },
        receivedDate,
        isAGMaster: true,
        lastWriteSource: 'UI',
        lastModifiedUserId: profile.id,
        lastModifiedDate: new Date(),
        lastSystemDate: new Date(),
      };

      await claimColRef(firestore, lot.orgId).doc(claimId).set(claim);
      presentStandardToast(
        toastController,
        `Claim created successfully`,
        undefined,
        'success'
      );
    } catch (error) {
      presentStandardToast(
        toastController,
        `Error creating claim. Please contact support`
      );
      console.error(error);
    }

    setLoading(false);
    onClose();
  };

  //-----------------------------------------------------
  // Render
  const saveDisabled = !amount || !currency || !orderId;

  return (
    <div className="ion-page page-create-claim">
      <IonLoading isOpen={loading} />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create claim</IonTitle>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={() => onClose()}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="tertiary"
              disabled={saveDisabled}
              fill="solid"
              onClick={(_) => onSave()}
            >
              <IonIcon icon={readerOutline} slot="start" />
              {'Save'}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList className="form-list">
          <IonItem>
            <IonLabel position="fixed">Amount*</IonLabel>
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <IonInput
                    inputmode="decimal"
                    type="number"
                    placeholder="Enter amount"
                    value={amount ?? ''}
                    onIonChange={(e) =>
                      setAmount(e.detail.value.length > 0 ? +e.detail.value : undefined)
                    }
                  />
                </IonCol>
                <IonCol size="6">
                  <Select
                    options={currencyOptions}
                    classNamePrefix="react-select"
                    menuPortalTarget={document.body}
                    placeholder={'Select currency'}
                    value={currencyOptions.find((o) => o.value === currency) ?? null}
                    onChange={(evt) => setCurrency(evt?.value ?? undefined)}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItem>
            <IonLabel position="fixed">Order*</IonLabel>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <Select
                    options={orderOptions}
                    classNamePrefix="react-select"
                    menuPortalTarget={document.body}
                    placeholder={'Select an order from the list'}
                    value={orderOptions.find((o) => o.value === orderId) ?? null}
                    onChange={(evt) => setOrderId(evt?.value ?? undefined)}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItem>
            <IonLabel position="fixed">Description</IonLabel>
            <IonTextarea
              inputmode="text"
              placeholder="Provide a description (optional)"
              value={description ?? ''}
              onIonChange={(e) =>
                setDescription(e.detail.value.length > 0 ? e.detail.value : undefined)
              }
            />
          </IonItem>

          <IonItem>
            <IonLabel position="fixed">Reason</IonLabel>
            <IonTextarea
              inputmode="text"
              placeholder="Provide a reason (optional)"
              value={reason ?? ''}
              onIonChange={(e) =>
                setReason(e.detail.value.length > 0 ? e.detail.value : undefined)
              }
            />
          </IonItem>

          <IonItem>
            <IonLabel>Claim date</IonLabel>
            <IonDatetime
              displayFormat="DD/MM/YYYY"
              placeholder="(optional)"
              value={receivedDate ? receivedDate.toDateString() : ''}
              onIonChange={(evt) => {
                setReceivedDate(new Date(evt.detail.value));
              }}
            />
          </IonItem>
        </IonList>
      </IonContent>
    </div>
  );
}
