import React, { useEffect, useState } from 'react';
import './ViewResetPasswordModal.scss';
import PageResetPassword from './PageResetPassword';
import { auth } from './ConfigFirebase';
import { UserProfile } from './Model';
import _ from 'lodash';

interface Props {
  profile: UserProfile;
  signInMethods: string[];
}

const ViewResetPasswordModal = (props: Props) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const checkIfUserHasSetPassword = () => {
      const userSignInMethods = props.signInMethods;
      const userHasPassword = _.find(
        userSignInMethods,
        (method) => method === 'password'
      );
      if (userHasPassword) {
        setShowPasswordReset(false);
      } else {
        setShowPasswordReset(true);
        setLoading(false);
      }
    };
    checkIfUserHasSetPassword();
  }, [props]);

  const closePasswordResetModal = () => {
    setShowPasswordReset(false);
  };

  if (!showPasswordReset) {
    return null;
  }

  return (
    <>
      {loading ? (
        <div className="loading-container"></div>
      ) : (
        showPasswordReset &&
        !!auth.currentUser &&
        props.signInMethods.length > 0 && (
          <div id="reset-password-modal">
            <PageResetPassword closeModal={closePasswordResetModal} />
          </div>
        )
      )}
    </>
  );
};

export default ViewResetPasswordModal;
