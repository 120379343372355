import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

export function PagePrivacyPolicy() {
  return (
    <>
      <IonPage style={{ padding: '20px' }}>
        <IonContent>
          <h1 id="privacy-policy">Privacy Policy</h1>
          <p>
            <strong>Last updated: February 15, 2021</strong>
          </p>
          <p>
            At Agrinorm Ltd., a Swiss corporation with its principal place of business
            at Hafnerstrasse 31 in 8005 Zurich, Switzerland (the “Company”, “Agrinorm”,
            “We”, “Us” or “Our”) We highly value Your privacy (the “User”, “You” or
            “Your”) and thus put the necessary measures in place to protect Your
            personal information. We comply with the Swiss Federal Data Protection Act
            (DPA), the Swiss Federal Data Protection Ordinance (DPO), and the General
            Data Protection Regulation (GDPR). In this Privacy Policy We describe Our
            policies and procedures on the collection, use and disclosure of Your
            information when You visit Agrinorm’s website or use Our Application. We
            also inform You about Your privacy rights and how the applicable law
            protects You. You agree to this Privacy Policy and particularly to the
            collection and use of information in accordance with this Privacy Policy. We
            reserve the right to modify this Privacy Policy at any time, in our free
            discretion, without giving reasons. We will give due notice of any
            modifications of the Privacy Policy.
          </p>
          <h2 id="definitions">Definitions</h2>
          <p>
            The following definitions apply to this Privacy Policy: means a unique
            account created for You to access our Service or parts of our Service.
          </p>
          <ul>
            <li>
              <strong>Account</strong> means a unique account created for You to access
              our Service or parts of our Service.
            </li>
            <li>
              <strong>Affiliate</strong> means an entity that controls, is controlled by
              or is under common control with a party, where &quot;control&quot; means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other managing
              authority.
            </li>
            <li>
              <strong>Application</strong> means the software program(s) provided by the
              Company and downloaded or accessed by You on any electronic device.
            </li>
            <li>
              <strong>Company</strong> (referred to as either the “Company&quot;,
              “Agrinorm”, “We”, “Us” or “Our”) refers to Agrinorm Ltd., a Swiss
              corporation with its principal place of business at Hafnerstrasse 31, 8005
              Zurich, Switzerland. For the purpose of the applicable laws, the Company
              is the Data Controller.
            </li>
            <li>
              <strong>Cookies</strong> are small files that are placed on Your computer,
              mobile device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.
            </li>
            <li>
              <strong>Data Controller</strong> refers to the Company as the legal person
              which alone or jointly with others determines the purposes and means of
              the processing of Personal Data.
            </li>
            <li>
              <strong>Device</strong> means any device that can access the Service such
              as a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <strong>Personal Data</strong> means any information relating to You in
              accordance with the applicable data protection laws. such as a name, an
              identification number, location data, online identifier or to one or more
              factors specific to the physical, physiological, genetic, mental,
              economic, cultural or social identity.
            </li>
            <li>
              <strong>Service</strong> refers to the Application or the Website or both.
            </li>
            <li>
              <strong>Service Provider</strong> means any natural or legal person who
              processes the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to perform
              services related to the Service or to assist the Company in analyzing how
              the Service is used. For the purpose of the applicable data protection
              laws, Service Providers are considered Data Processors.
            </li>
            <li>
              <strong>Third-party Social Media Service</strong> refers to any website or
              any social network website through which a User can log in or create an
              account to use the Service.
            </li>
            <li>
              <strong>Usage Data</strong> refers to data collected automatically, either
              generated by the use of the Service or from the Service infrastructure
              itself (for example, the duration of a page visit).
            </li>
            <li>
              <strong>Website</strong> refers to
              <a href="https://www.agrinorm.ai">https://www.agrinorm.ai</a>.
            </li>
            <li>
              <strong>You</strong> (referred to as either “You”, the “User”, “You” or
              “Your”) means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual is
              accessing or using the Service, as applicable. Under the applicable data
              protection laws, You can be referred to as the Data Subject or as the User
              as You are the individual using the Service.
            </li>
          </ul>
          <p>
            <h2 id="types-of-data-collected">Types of Data Collected</h2>
            <strong>Personal Data</strong>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or identify
            You. Personally identifiable information may include, but is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>
              Bank account information in order to pay for products and/or services
              within the
            </li>
            <li>Service</li>
            <li>Usage Data</li>
          </ul>
          <p>
            When You pay for a product and/or a service via bank transfer, We may ask
            You to provide information to facilitate this transaction and to verify Your
            identity. Such information may include, without limitation:
          </p>
          <ul>
            <li>Date of birth</li>
            <li>Passport or National ID card</li>
            <li>Bank card statement</li>
            <li>Other information linking You to an address</li>
          </ul>
          <p>
            <strong>Usage Data</strong> Usage Data is collected automatically when using
            the Service. Usage Data may include information such as Your Device&#39;s
            Internet Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your visit,
            the time spent on those pages, unique device identifiers and other
            diagnostic data. When You access the Service by or through a mobile device,
            We may collect certain information automatically, including, but not limited
            to, the type of mobile device You use, Your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the type of
            mobile Internet browser You use, unique device identifiers and other
            diagnostic data. We may also collect information that Your browser sends
            whenever You visit our Service or when You access the Service by or through
            a mobile device. Information from Third-Party Social Media Services The
            Company may allow You to create an account and log in to use the Service
            through Third-party Social Media Services such as:
          </p>
          <ul>
            <li>Google</li>
            <li>Facebook</li>
            <li>LinkedIn</li>
          </ul>
          <p>
            If You decide to register through or otherwise grant Us access to a
            Third-Party Social Media Service, We may collect Personal data that is
            already associated with Your Third-Party Social Media Service&#39;s account,
            such as Your name, Your email address, Your activities or Your contact list
            associated with that account. You may also have the option of sharing
            additional information with the Company through Your Third-Party Social
            Media Service&#39;s account. If You choose to provide such information and
            Personal Data, during registration or otherwise, You are giving the Company
            permission to use, share, and store it in a manner consistent with this
            Privacy Policy. Information Collected while Using the Application While
            using Our Application, in order to provide features of Our Application, We
            may collect, with Your prior permission:
          </p>
          <ul>
            <li>Information regarding Your location</li>
            <li>Information from Your Device&#39;s phone book (contacts list)</li>
            <li>
              Pictures and other information from Your Device&#39;s camera and photo
              library
            </li>
          </ul>
          <p>
            We use this information to provide features of Our Service, to improve and
            customize Our Service. The information may be uploaded to the Company&#39;s
            servers and/or a Service Provider&#39;s server or it may be simply stored on
            Your device. You can enable or disable access to this information at any
            time, through Your Device settings.
          </p>
          <p>
            <strong>Tracking Technologies and Cookies</strong> We use Cookies and
            similar tracking technologies to track the activity on Our Service and store
            certain information. Tracking technologies used are beacons, tags, and
            scripts to collect and track information and to improve and analyze Our
            Service. The technologies We use may include:
          </p>
          <ul>
            <li>
              <strong>Cookies or Browser Cookies</strong>. A cookie is a small file
              placed on Your Device. You can instruct Your browser to refuse all Cookies
              or to indicate when a Cookie is being sent. However, if You do not accept
              Cookies, You may not be able to use some parts of our Service. Unless You
              have adjusted Your browser setting so that it will refuse Cookies, our
              Service may use Cookies.
            </li>
            <li>
              <strong>Flash Cookies</strong>. Certain features of our Service may use
              local stored objects (or Flash Cookies) to collect and store information
              about Your preferences or Your activity on our Service. Flash Cookies are
              not managed by the same browser settings as those used for Browser
              Cookies. For more information on how You can delete Flash Cookies, please
              read &quot;Where can I change the settings for disabling, or deleting
              local shared objects?&quot; available at
              <a href="https://helpx.adobe.com/flash-player/kb/disable-localshared-objectsflash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local">
                https://helpx.adobe.com/flash-player/kb/disable-localshared-objectsflash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local
              </a>
              _shared<em>objects</em>
            </li>
            <li>
              <strong>Web Beacons</strong>. Certain sections of our Service and our
              emails may contain small electronic files known as web beacons (also
              referred to as clear gifs, pixel tags, and single-pixel gifs) that permit
              the Company, for example, to count users who have visited those pages or
              opened an email and for other related website statistics (for example,
              recording the popularity of a certain section and verifying system and
              server integrity). Cookies can be &quot;Persistent&quot; or
              &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal
              computer or mobile device when You go offline, while Session Cookies are
              deleted as soon as You close Your web browser. We use both Session and
              Persistent Cookies. For more information about the cookies We use and Your
              choices regarding cookies, please visit our Cookies Policy.
            </li>
          </ul>
          <h2 id="using-your-personal-data">Using Your Personal Data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <strong>To provide and maintain our Service</strong>, including to monitor
              the usage of our Service.
            </li>
            <li>
              <strong>To manage Your Account</strong>: to manage Your registration as a
              user of the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You as a
              registered user.
            </li>
            <li>
              <strong>For the performance of one or several contracts</strong>: the
              development, compliance and undertaking of the contract(s) for the
              products, items or services You have purchased or licensed or of any other
              contract with Us through the Service.
            </li>
            <li>
              <strong>To contact You</strong>: To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such as a
              mobile application&#39;s push notifications regarding updates or
              informative communications related to the functionalities, products or
              contracted services, including the security updates, when necessary or
              reasonable for their implementation.
            </li>
            <li>
              <strong>
                To provide You with news, special offers and general information
              </strong>
              about other goods, services and events which We offer that are similar to
              those that You have already purchased or enquired about unless You have
              opted not to receive such information.
            </li>
            <li>
              <strong>To manage Your requests</strong>: To attend and manage Your
              requests to Us.
            </li>
            <li>
              <strong>For business transfers</strong>: We may use Your information to
              evaluate or conduct a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of Our assets,
              whether as a going concern or as part of bankruptcy, liquidation, or
              similar proceeding, in which Personal Data held by Us about our Service
              users is among the assets transferred.
            </li>
            <li>
              <strong>
                For other purposes in the context of the performance of Our contract(s)
                with You and improvement of Our Services
              </strong>
              : We may use Your information for other purposes, such as data analysis,
              identifying usage trends, determining the effectiveness of our promotional
              campaigns and to evaluate and improve our Service, products, services,
              marketing and Your experience.
            </li>
          </ul>
          <h2 id="sharing-your-personal-data">Sharing Your Personal Data</h2>
          <p>We may share Your personal information in the following situations:</p>
          <ul>
            <li>
              <strong>With Service Providers</strong>: We may share Your personal
              information with Service Providers to monitor and analyze the use of our
              Service, for payment processing, to contact You.
            </li>
            <li>
              <strong>For business transfers</strong>: We may share or transfer Your
              personal information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all or a
              portion of Our business to another company.
            </li>
            <li>
              <strong>With Affiliates</strong>: We may share Your information with Our
              affiliates, if any, in which case We will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company and any
              other subsidiaries, joint venture partners or other companies that We
              control or that are under common control with Us.
            </li>
            <li>
              <strong>With business partners</strong>: We may share Your information
              with Our business partners to offer You certain products, services or
              promotions or improve Our Services.
            </li>
            <li>
              <strong>With other users</strong>: when You share personal information or
              otherwise interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed outside. If You
              interact with other users or register through a Third-Party Social Media
              Service, Your contacts on the Third-Party Social Media Service may see
              Your name, profile, pictures and description of Your activity. Similarly,
              other users will be able to view descriptions of Your activity,
              communicate with You and view Your profile.
            </li>
            <li>
              <strong>With Your consent</strong>: We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <p>Retention of Your Personal Data</p>
          <p>
            The Company will retain Your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and use Your
            Personal Data to the extent necessary to comply with our legal obligations
            (for example, if We are required to retain Your data to comply with
            applicable laws), resolve disputes, and enforce our legal agreements and
            policies. The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of time,
            except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this
            data for longer time periods.
          </p>
          <p>
            Transfer of Your Personal Data Your information, including Personal Data, is
            processed at the Company&#39;s operating offices and in any other places
            where the parties involved in the processing, such as Our Service Providers,
            are located. It means that this information may be transferred to — and
            maintained on — computers located outside of Your state, province, country
            or other governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction. Your consent to this Privacy Policy
            represents Your agreement to that transfer. The Company will take all steps
            reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data
            will take place to an organization or a country unless there are adequate
            controls in place including the security of Your data and other personal
            information.
          </p>
          <p>
            Disclosure of Your Personal Data Business Transactions If the Company is
            involved in a merger, acquisition or asset sale, Your Personal Data may be
            transferred. Law enforcement Under certain circumstances, the Company may be
            required to disclose Your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency). Other legal requirements The Company may disclose Your
            Personal Data in the good faith belief that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the Service
            </li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
          <p>
            Otherwise set out in this Privacy Policy We disclose Your Personal
            Information as set out otherwise in this Privacy Policy.
          </p>
          <p>
            Security of Your Personal Data The security of Your Personal Data is
            important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to
            use commercially acceptable means to protect Your Personal Data, We cannot
            guarantee its absolute security.
          </p>
          <p>
            Processing of Your Personal Data To provide and improve the Service We may
            collaborate with Service Providers. Service Providers have access to Your
            Personal Data only to perform their tasks on Our behalf and are obligated
            not to disclose or use it for any other purpose. Moreover, We process Your
            Personal Data for the following purposes:
            <strong>Analytics</strong>
            We may use Our Service Providers to monitor and analyze the use of our
            Service. Our Service Providers include, but are not limited to the
            following:
          </p>
          <p>
            <strong>Google Analytics</strong> Google Analytics is a web analytics
            service offered by Google that tracks and reports website traffic. Google
            uses the data collected to track and monitor the use of Our Service. This
            data is shared with other Google services. Google may use the collected data
            to contextualize and personalize the ads of its own advertising network. You
            can opt-out of having made Your activity on the Service available to Google
            Analytics by installing the Google Analytics opt-out browser add-on. The
            add-on prevents the Google Analytics JavaScript from sharing information
            with Google Analytics about visits activity. You may opt-out of certain
            Google Analytics features through Your mobile device settings, such as Your
            device advertising settings or by following the instructions provided by
            Google in their Privacy Policy:
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
            For more information on the privacy practices of Google, please visit the
            Google Privacy &amp; Terms web page:
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
          </p>
          <p>
            <strong>Firebase</strong> Firebase is an analytics service provided by
            Google Inc. You may opt-out of certain Firebase features through Your mobile
            device settings, such as Your device advertising settings or by following
            the instructions provided by Google in their Privacy Policy:
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
            We also encourage You to review the Google&#39;s policy for safeguarding
            Your data:
            <a href="https://support.google.com/analytics/answer/6004245">
              https://support.google.com/analytics/answer/6004245
            </a>
            . For more information on what type of information Firebase collects, please
            visit the How Google uses data when You use our partners&#39; sites or apps
            webpage:
            <a href="https://policies.google.com/technologies/partner-sites">
              https://policies.google.com/technologies/partner-sites
            </a>
            .
          </p>
          <p>
            <strong>Mixpanel</strong> Mixpanel is provided by Mixpanel Inc. You can
            prevent Mixpanel from using Your information for analytics purposes by
            opting-out. To opt-out of Mixpanel service, please visit this page:
            <a href="https://mixpanel.com/optout/">https://mixpanel.com/optout/</a>. For
            more information on what type of information Mixpanel collects, please visit
            the Terms of Use page of Mixpanel:
            <a href="https://mixpanel.com/terms/">https://mixpanel.com/terms/</a>.
          </p>
          <p>
            <strong>Email Marketing</strong> We may use Your Personal Data to contact
            You with newsletters, marketing or promotional materials and other
            information that may be of interest to You. You may opt-out of receiving
            any, or all, of these communications from Us by following the unsubscribe
            link or instructions provided in any email We send or by contacting Us. We
            may use Email Marketing Service Providers to manage and send emails to You.
          </p>
          <p>
            <strong>Payments</strong> We may provide paid products and/or services
            within the Service. In that case, We may use third-party services for
            payment processing (e.g. payment processors). We will not store or collect
            Your payment card details. That information is provided directly to Our
            third-party payment processors whose use of Your personal information is
            governed by their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards Council,
            which is a joint effort of brands like Visa, Mastercard, American Express
            and Discover. PCIDSS requirements help ensure the secure handling of payment
            information. When You use Our Service to pay a product and/or service via
            bank transfer, We may ask You to provide information to facilitate this
            transaction and to verify Your identity.
          </p>
          <p>
            <strong>Other Purposes set out in this Privacy Policy</strong> We will
            process Your Personal Data for other purposes as set out in this Privacy
            Policy. If You have given Us Your consent to process Your Personal Data for
            certain purposes (for example when registering to receive newsletters), We
            will process Your Personal Data within the scope of and based on this
            consent, unless We have another legal basis, provided that we require one.
            Consent given can be withdrawn at any time, but this does not affect data
            processed prior to withdrawal.
          </p>
          <p>
            Legal Basis for Processing Personal Data under GDPR We may process Personal
            Data under the following conditions:
          </p>
          <ul>
            <li>
              Consent: You have given Your consent for processing Personal Data for one
              or more specific purposes.
            </li>
            <li>
              Performance of a contract: Provision of Personal Data is necessary for the
              performance of a contract with You and/or for any pre-contractual
              obligations thereof.
            </li>
            <li>
              Legal obligations: Processing Personal Data is necessary for compliance
              with a legal obligation to which the Company is subject.
            </li>
            <li>
              Vital interests: Processing Personal Data is necessary in order to protect
              Your vital interests or of another natural person.
            </li>
            <li>
              Legitimate interests: Processing Personal Data is necessary for the
              purposes of the legitimate interests pursued by the Company.
            </li>
          </ul>
          <p>
            In any case, the Company will gladly help to clarify the specific legal
            basis that applies to the processing, and in particular whether the
            provision of Personal Data is a statutory or contractual requirement, or a
            requirement necessary to enter into a contract.
          </p>
          <p>
            Your Rights under the applicable Data Protection Laws The Company undertakes
            to respect the confidentiality of Your Personal Data and to guarantee You
            can exercise Your rights under the applicable data protection laws. You have
            the right under this Privacy Policy, and by law if You are within the EU,
            to:
          </p>
          <ul>
            <li>
              Request access to Your Personal Data. The right to access, update or
              delete the information We have on You. Whenever made possible, You can
              access, update or request deletion of Your Personal Data directly within
              Your account settings section. If You are unable to perform these actions
              Yourself, please contact Us to assist You. This also enables You to
              receive a copy of the Personal Data We hold about You.
            </li>
            <li>
              Request correction of the Personal Data that We hold about You. You have
              the right to have any incomplete or inaccurate information We hold about
              You corrected.
            </li>
            <li>
              Object to processing of Your Personal Data. This right exists in cases
              where there is something about Your particular situation, which makes You
              want to object to Our processing of Your Personal Data. You also have the
              right to object where We are processing Your Personal Data for direct
              marketing purposes.
            </li>
            <li>
              Request erasure of Your Personal Data. You have the right to ask Us to
              delete or remove Personal Data when there is no good reason for Us to
              continue processing it.
            </li>
            <li>
              Request the transfer of Your Personal Data. We will provide to You, or to
              a third-party You have chosen, Your Personal Data in a structured,
              commonly used, machinereadable format. Please note that this right only
              applies to automated information which You initially provided consent for
              Us to use or where We used the information to perform a contract with You.
            </li>
            <li>
              Withdraw Your consent. You have the right to withdraw Your consent on
              using Your Personal Data. If You withdraw Your consent, We may not be able
              to provide You with access to certain specific functionalities of the
              Service.
            </li>
          </ul>
          <p>
            Exercising of Your Data Protection Rights You may exercise Your rights of
            access, rectification, cancellation and opposition by contacting Us. Please
            note that We may ask You to verify Your identity before responding to such
            requests. If You make a request, We will try Our best to respond to You as
            soon as possible.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about Our
            collection and use of Your Personal Data. For more information, if You are
            in the European Economic Area (EEA), please contact Your local data
            protection authority in the EEA.
          </p>
          <p>
            <strong>Minimum Age</strong> Our Service does not address anyone under the
            age of 18. We do not knowingly collect personally identifiable information
            from anyone under the age of 18. If You are a parent or guardian and You are
            aware that Your Children has provided Us with Personal Data, please contact
            Us. If We become aware that We have collected Personal Data from anyone
            under the age of 18 without verification of parental consent, We take steps
            to remove that information from Our and Our Service Provider’s servers.
          </p>
          <p>
            <strong>Contact Information</strong> If You have any questions about this
            Privacy Policy, You can contact Us by email: admin@agrinorm.ai
          </p>
        </IonContent>
      </IonPage>
    </>
  );
}
