import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import {
  checkboxOutline,
  closeCircleOutline,
  closeOutline,
  informationCircleOutline,
  keyOutline,
  squareOutline,
  trashOutline,
} from 'ionicons/icons';
// import {closeOutline} from "ionicons/icons";
import React, { RefObject } from 'react';
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';
// import {ApplicationContext, UserProfile} from "./Model";
import './ComponentUsersAdmin.scss';
// import i18n from "./ServiceI18n";
import { firestore } from './ConfigFirebase';
import {
  profileDocRef,
  removePendingInvite,
  sendInviteLink,
  userColRef,
  userInviteColRef,
} from './DataStorage';
import { OrganisationSettings, UserPermission, UserProfile } from './Model';
import { toastController } from '@ionic/core';
import { deleteUser, updateUser } from './DataAdmin';
import _, { cloneDeep, findIndex } from 'lodash';
import {
  isAGAdmin,
  userPermissionsMapping,
  roleOptions,
  userHasPermissionSet,
  IPermission,
  isTierHigher,
  orgPermissionMap,
  defaultPermissions,
} from './PermissionsService';
import { FIREBASE_FUNCTIONS_REGION } from './GlobalConstants';

interface Props {
  organisationId: string;
  profile: UserProfile;
  organisationSettings: OrganisationSettings;
}

interface State {
  inited: boolean;
  users?: UserProfile[];
  pendingInvites?: [];
  search: string;
  modal: boolean;
  page: number;
  isRolesLoading: boolean;
}
//TODO: add Loading spinner when making the call to get the users

class ComponentUsersAdmin extends React.Component<Props, State> {
  private userListener: () => void;
  private userInviteListener: () => void;

  private emailInput: RefObject<HTMLIonInputElement> = React.createRef();
  private nameInput: RefObject<HTMLIonInputElement> = React.createRef();
  private adminCheckbox: RefObject<HTMLIonCheckboxElement> = React.createRef();
  private select: RefObject<any> = React.createRef();
  refObject: any[];
  constructor(props) {
    super(props);
    this.refObject = [];
    this.state = {
      inited: true,
      search: '',
      modal: false,
      page: 0,
      isRolesLoading: true,
    };
  }

  componentDidMount() {
    const { organisationId } = this.props;

    this.fetchUserProfiles(organisationId);

    // This listener is only used to retrigger the loading of the profiles
    this.userListener = userColRef(firestore)
      .where('organisationId', '==', organisationId)
      .onSnapshot(() => {
        this.setState({ isRolesLoading: true });
        this.fetchUserProfiles(organisationId);
      });

    this.userInviteListener = userInviteColRef(firestore)
      .where('created', '==', false)
      .where('orgId', '==', organisationId)
      .onSnapshot((dbRows: any) => {
        this.setState({
          pendingInvites: dbRows.docs.map((d) => {
            return { ...d.data(), id: d.id };
          }),
        });
      });
  }

  fetchUserProfiles(organisationId: string) {
    const usersQuery = firestore
      .collectionGroup('profile')
      .where('organisationId', '==', organisationId)
      .get();

    usersQuery.then((userResponse) => {
      let users: UserProfile[] = userResponse.docs.map(
        (doc) => doc.data() as UserProfile
      );

      users = users
        .filter((u) => !!u.userRole)
        // we filter out agrinorm users only for non-agrinorm users!
        .filter((u) =>
          this.props.profile?.email?.toLowerCase().includes('agrinorm')
            ? true
            : !u.email?.toLowerCase().includes('agrinorm')
        );

      this.setState({ users, isRolesLoading: false });
    });
  }

  componentWillUnmount(): void {
    this.userListener?.();
    this.userInviteListener?.();
  }

  renderPaging(list) {
    if (!list || list.length <= 10) {
      return;
    }
    return (
      <div className="paging">
        {[...Array(Math.ceil(list.length / 10))].map((v, i) => (
          <div
            key={i}
            onClick={(_) => this.setState({ page: i })}
            className={i === this.state.page ? 'current' : ''}
          >
            {i + 1}
          </div>
        ))}
      </div>
    );
  }

  async sendPasswordReset(email) {
    console.log('reseting', email);

    const sendResetPasswordEmail = firebase
      .app()
      .functions(FIREBASE_FUNCTIONS_REGION)
      .httpsCallable('sendResetPassword');
    sendResetPasswordEmail({
      email,
      isLocalhost: window.location.hostname === 'localhost',
    })
      .then((result) => {
        console.log('result', result);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {});

    const toast = await toastController.create({
      message: 'Reset Password email sent to ' + email,
      position: 'top',
      color: 'dark',
      duration: 3000,
      buttons: [
        {
          text: 'Got it',
          role: 'cancel',
        },
      ],
    });

    toast.present().then();
  }

  async removeInvite(inviteId) {
    console.log('removing ', inviteId);

    await userInviteColRef(firestore).doc(inviteId).delete();
  }

  async sendInvite() {
    const email = this.emailInput.current.value as string;
    const name = this.nameInput.current.value as string;
    const select = this.select.current;
    const role = select.state?.value?.value ?? '';
    const isAdmin = this.adminCheckbox?.current?.checked ?? (false as boolean);

    if (!name) return alert('Name cannot be empty');
    if (!email) return alert('Email cannot be empty');
    if (!(role !== '')) return alert('Select one role');

    this.setState({ modal: false });

    console.log('role', role);
    console.log('is admin', isAdmin);

    try {
      // Before sending the invite, remove all others sent to the same email
      await removePendingInvite(email);

      sendInviteLink(firestore, email, name, this.props.organisationId, role, isAdmin);
      const toast = await toastController.create({
        message: 'Email invitation sent to ' + email,
        position: 'top',
        color: 'dark',
        duration: 3000,
        buttons: [
          {
            text: 'Got it',
            role: 'cancel',
          },
        ],
      });

      toast.present().then();
    } catch (error) {
      alert(error);
    }
  }
  // isRemovingAdminRole(user: UserProfile, isAdmin) {
  //   const isAdmin = user.isAdmin;
  //   if(isAdmin) {
  //     const willDeleteAdminRole = (findIndex(data, (data) => data === 'ORGANISATION_ADMIN') === -1);
  //     return willDeleteAdminRole;
  //   } else {
  //     return false
  //   }

  // }
  // isOnlyAdminInOrganisation() {
  //   let numberOfAdminsInOrganisation = 0;
  //   this.state.users.forEach((user: any) => {
  //     const isAdmin = (findIndex(user.userRoles, (role) => role === 'ORGANISATION_ADMIN') !== -1);
  //     if (isAdmin) numberOfAdminsInOrganisation += 1
  //   })

  //   return numberOfAdminsInOrganisation === 1;
  // }

  handleRoleSelectOnChange = async (uid, selectData) => {
    if (!selectData) selectData = [];
    let userRole = selectData.value;
    console.log(this.props.organisationId, uid, userRole);

    const userIndex = findIndex(
      this.state.users,
      (user: UserProfile) => user.id === uid
    );
    let users: UserProfile[] = cloneDeep(this.state.users);

    // const isRemovingAdminRole = this.isRemovingAdminRole(users[userIndex], userRole)
    // const isOnlyAdminInOrganisation = this.isOnlyAdminInOrganisation();

    // if (isRemovingAdminRole && isOnlyAdminInOrganisation) {
    //   alert('Can not delete Admin role, organisation must have at least 1 user with Admin role ');
    // }

    // recompute permissions
    const userPermissions = defaultPermissions[userRole];

    users[userIndex] = {
      ...users[userIndex],
      userRole,
      userPermissions,
    };
    this.setState({ users });

    await profileDocRef(firestore, uid).update({
      userRole,
      userPermissions,
    } as Partial<UserProfile>);
  };

  async handleIsAdminChange(user: UserProfile) {
    // ask for confirmation
    const message = user.isAdmin
      ? `Are you sure you want to remove administrator rights for the user ${user.name} (${user.email})?`
      : `Are you sure you want to give the user ${user.name} (${user.email}) administrator rights?`;

    if (!window.confirm(message)) {
      return;
    }

    const userIndex = findIndex(this.state.users, (u) => user.id === u.id);
    let users: UserProfile[] = _.cloneDeep(this.state.users);
    users[userIndex] = {
      ...users[userIndex],
      isAdmin: !user.isAdmin,
    };
    this.setState({ users });
    await updateUser(firestore, user.id, 'isAdmin', !user.isAdmin);
  }

  async updateUserPermissions(user: UserProfile, pId: string, checked: boolean) {
    let newPermissions: UserPermission[] = _.cloneDeep(user.userPermissions);

    if (checked) {
      // remove permissions
      userPermissionsMapping[pId].permissions.forEach(
        (perm) => (newPermissions = newPermissions.filter((p) => !_.isEqual(p, perm)))
      );
    } else {
      // add permissions
      newPermissions = [
        ...(user.userPermissions ?? []),
        ...userPermissionsMapping[pId].permissions,
      ];
    }

    let users: UserProfile[] = _.cloneDeep(this.state.users);
    const userIndex = findIndex(this.state.users, (u: UserProfile) => u.id === user.id);
    users[userIndex] = {
      ...users[userIndex],
      userPermissions: newPermissions,
    };
    this.setState({ users });

    console.log('newPermissions', newPermissions);
    await updateUser(firestore, user.id, 'userPermissions', newPermissions);
  }
  async handleDeleteUser(user: UserProfile) {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${user.name}`
    );

    const currentUserId = this.props.profile.id;

    const isCurrentUserSameAsUserToDelete = currentUserId === user.id;

    const adminUsers = _.filter(this.state.users, (user) => {
      if (user.isAdmin) {
        return user;
      }
    });
    const isTryingToDeleteOnlyAdmin = adminUsers.length === 1;

    if (isCurrentUserSameAsUserToDelete) {
      window.alert('You can not delete yourself');
      return;
    }

    if (isTryingToDeleteOnlyAdmin && user.isAdmin) {
      window.alert('You can not delete the only Admin user in your organization.');
      return;
    }

    if (confirmDelete) {
      console.log(confirmDelete);
      await deleteUser(firestore, user.id);
    }
  }

  render() {
    // if (this.state.isRolesLoading)
    //   return <></>

    const filteredUsers = this.state.users?.filter((c: UserProfile) =>
      this.state.search.length > 0
        ? c.email.toLocaleLowerCase().startsWith(this.state.search) ||
          c.name.toLocaleLowerCase().startsWith(this.state.search)
        : true
    );
    filteredUsers?.forEach(() => this.refObject.push([]));
    return (
      <div className="component-users-admin">
        <div className="top-toolbar">
          <div>
            <IonSearchbar
              mode="ios"
              onIonChange={(ev) =>
                this.setState({ page: 0, search: ev.detail.value?.toLocaleLowerCase() })
              }
            />
          </div>
          <strong>Total Users ({this.state.users?.length ?? 0})</strong>
        </div>

        {this.state.pendingInvites?.length > 0 && (
          <div className="pending-invites">
            <strong>Pending Invites ({this.state.pendingInvites.length})</strong>
            {this.state.pendingInvites.map((p: any) => (
              <div key={p.id}>
                {p.email}
                <span
                  className="action-button delete"
                  title="remove invite"
                  onClick={(_) => this.removeInvite(p.id)}
                >
                  <IonIcon icon={trashOutline} />
                </span>
              </div>
            ))}
          </div>
        )}

        {this.renderPaging(filteredUsers)}

        <div className="table-list align-top-not double-row">
          <div className="list-header">
            <div>
              <div className="name">Name/Email</div>
              {/* <div className="email">Email</div> */}
              <div className="item-selectable">Role</div>
              <div className="admin-checkbox">Admin</div>
              {/* <div>Permissions</div> */}
              <div className="actions">&nbsp;</div>
            </div>
          </div>
          {filteredUsers === undefined && <IonSpinner name="dots" />}
          {filteredUsers?.length === 0 && <div className="no-results">no results</div>}
          {filteredUsers
            ?.slice(this.state.page * 10, this.state.page * 10 + 10)
            .map((user: UserProfile, index) => {
              const permissionsArray: IPermission[] =
                Object.values(userPermissionsMapping);

              // we filter out those sections for which no permissions are present based on the org's product tiers
              const permissionSections = [
                ...new Set(permissionsArray.map((p) => p.section)),
              ].filter(
                (section) =>
                  permissionsArray
                    .filter((p) => p.section === section)
                    .filter((p) => {
                      const { appProduct, minTier: tier } = p.displayRules;
                      const orgTier =
                        this.props.organisationSettings?.productTiers?.[appProduct];

                      return !!orgTier
                        ? isTierHigher(
                            orgTier,
                            tier,
                            orgPermissionMap[appProduct].hierarchy
                          )
                        : false;
                    }).length > 0
              );

              const isAdmin = !!user?.isAdmin;

              return (
                <div key={user.id + index} className="">
                  <div>
                    <div className="name">
                      <div>{user.name}</div>
                      <div>{user.email}</div>
                    </div>
                    <div className="item-selectable">
                      {/* save on change, dont allow 0 roles */}
                      <Select
                        options={roleOptions}
                        classNamePrefix={'react-select'}
                        defaultValue={roleOptions.find(
                          (r) => r.value === user.userRole
                        )}
                        onChange={(e) => this.handleRoleSelectOnChange(user.id, e)}
                        isClearable={false}
                        isDisabled={this.state.isRolesLoading}
                      />
                    </div>
                    <div
                      className="admin-checkbox"
                      title={
                        this.props.profile.id === user.id
                          ? 'You cannot remove your own admin permissions'
                          : ''
                      }
                    >
                      <IonButton
                        fill={'clear'}
                        color={isAdmin ? 'success' : 'medium'}
                        disabled={this.props.profile.id === user.id}
                        onClick={() => this.handleIsAdminChange(user)}
                      >
                        <IonIcon icon={isAdmin ? checkboxOutline : squareOutline} />
                      </IonButton>

                      {/* <IonCheckbox checked={isAdmin} disabled={this.props.profile.id === user.id} 
                  onIonChange={() => this.handleIsAdminChange(user)} /> */}
                    </div>
                    {/* <div className="item-selectable">
                  <Select options={optionRoles}
                    classNamePrefix={'react-select'}
                    value={rolesToSelectValue(user.userRoles)}
                    // onChange={(e) => this.handleRoleSelectOnChange(user.id, 'userRoles', e)}
                    isMulti
                    isClearable={false}
                    isDisabled={this.state.isRolesLoading}
                  />
                </div> */}
                    <div className="actions">
                      {!(this.props.profile.id === user.id) && (
                        <span
                          title="disable account"
                          className="action-button delete"
                          onClick={(e) => this.handleDeleteUser(user)}
                        >
                          <IonIcon icon={trashOutline} />
                        </span>
                      )}

                      <span
                        title="reset password"
                        className="action-button"
                        onClick={() => this.sendPasswordReset(user.email)}
                      >
                        <IonIcon icon={keyOutline} />
                      </span>
                    </div>
                  </div>
                  <div
                    className="view-more"
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('less');
                      // @ts-ignore
                      e.currentTarget.nextSibling.classList?.toggle('visible');
                      console.log('user', user);
                    }}
                  >
                    User Permissions
                  </div>
                  <div className="more grid-list">
                    {permissionSections.map((section) => {
                      return (
                        <div className={`section-${section}`} key={section}>
                          <div className="section">{section}</div>
                          <div className="permission-list">
                            {Object.keys(userPermissionsMapping)
                              .filter((pId) => {
                                const {
                                  section: s,
                                  displayRules: { appProduct, minTier: tier },
                                } = userPermissionsMapping[pId];
                                const orgTier =
                                  this.props.organisationSettings?.productTiers?.[
                                    appProduct
                                  ];
                                return s === section && !!orgTier
                                  ? isTierHigher(
                                      orgTier,
                                      tier,
                                      orgPermissionMap[appProduct].hierarchy
                                    )
                                  : false;
                              })
                              .sort(
                                (a, b) =>
                                  userPermissionsMapping[a].index -
                                  userPermissionsMapping[b].index
                              )
                              .map((pId) => {
                                const { uiTitle, uiDescription } =
                                  userPermissionsMapping[pId];

                                let checked: boolean = userHasPermissionSet(
                                  userPermissionsMapping[pId],
                                  user
                                );

                                let onPermissionChange = () =>
                                  this.updateUserPermissions(user, pId, checked);
                                let title = null;

                                if (isAdmin) {
                                  onPermissionChange = () => null;
                                  checked = true;
                                  title =
                                    'You cannot remove permissions from an admin user';
                                }

                                return (
                                  <React.Fragment key={pId}>
                                    <div className="user-admin-checkbox-container">
                                      <div title={title}>
                                        <IonButton
                                          className="checkbox-button"
                                          fill={'clear'}
                                          size="small"
                                          color={checked ? 'success' : 'medium'}
                                          disabled={isAdmin}
                                          onClick={onPermissionChange}
                                        >
                                          <IonIcon
                                            icon={
                                              checked ? checkboxOutline : squareOutline
                                            }
                                          />
                                        </IonButton>

                                        {/* <IonCheckbox
                                checked={checked}
                                disabled={isAdmin}
                                onIonChange={onPermissionChange} /> */}

                                        <b>{uiTitle}</b>
                                      </div>
                                      <div
                                        className="info-button"
                                        onClick={(e) => {
                                          e.currentTarget?.parentElement.classList?.toggle(
                                            'less'
                                          );
                                          // @ts-ignore
                                          e.currentTarget?.parentElement.nextSibling?.classList?.toggle(
                                            'visible'
                                          );
                                          const isOpen =
                                            // @ts-ignore
                                            e.currentTarget?.parentElement.nextSibling?.classList?.contains(
                                              'visible'
                                            );
                                          // document.getElementById(`${pId}-closed`).hidden = isOpen;
                                          // document.getElementById(`${pId}-open`).hidden = !isOpen;
                                          this.refObject[index][
                                            `${pId}-closed`
                                          ].hidden = isOpen;
                                          this.refObject[index][`${pId}-open`].hidden =
                                            !isOpen;
                                        }}
                                      >
                                        <IonIcon
                                          ref={(refObject) =>
                                            (this.refObject[index][`${pId}-closed`] =
                                              refObject)
                                          }
                                          id={`${pId}-closed`}
                                          icon={informationCircleOutline}
                                        />
                                        <IonIcon
                                          ref={(refObject) =>
                                            (this.refObject[index][`${pId}-open`] =
                                              refObject)
                                          }
                                          id={`${pId}-open`}
                                          icon={closeCircleOutline}
                                          hidden={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="more desc">{uiDescription}</div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>

        <IonButton
          slot="fixed"
          color="tertiary"
          className="main-button"
          onClick={(_) => this.setState({ modal: true })}
        >
          Add User
        </IonButton>

        <IonModal
          isOpen={this.state.modal || false}
          onDidDismiss={(_) => this.setState({ modal: false })}
        >
          <IonHeader className={''}>
            <IonToolbar>
              <IonTitle>Add User</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={(_) => this.setState({ modal: false })}>
                  <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="add-modal">
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                this.sendInvite();
              }}
            >
              <IonList>
                <IonItem>
                  <IonLabel>Name</IonLabel>
                  <IonInput ref={this.nameInput} />
                </IonItem>
                <IonItem>
                  <IonLabel>Email</IonLabel>
                  <IonInput ref={this.emailInput} />
                </IonItem>
                <IonItem className="select-input">
                  <IonLabel>Role</IonLabel>
                  <Select
                    options={roleOptions}
                    ref={this.select}
                    classNamePrefix={'react-select'}
                  ></Select>
                </IonItem>
                {/* <IonItem lines="none">
                <IonLabel>Remember me</IonLabel>
                <IonCheckbox defaultChecked={true} slot="start" />
              </IonItem> */}
                {isAGAdmin(this.props.profile) && (
                  <IonItem>
                    <IonLabel>Is admin?</IonLabel>
                    <IonCheckbox ref={this.adminCheckbox} />
                  </IonItem>
                )}
              </IonList>
              <div className="main-btn">
                <IonButton onClick={(_) => this.sendInvite()}>Add</IonButton>
              </div>
            </form>
          </IonContent>
        </IonModal>
      </div>
    );
  }
}

export default ComponentUsersAdmin;
