import { IonSelect, IonSelectOption } from '@ionic/react';
import React, { useContext, useRef } from 'react';
import { ctxOrg } from '../App';
import { Inspection } from '../InspectionModel';
import { InspectionSpec } from '../ModelSpecification';
import { useInspectionContext } from '../context/InspectionContextProvider';
import './CLotInspectionHeader.scss';

interface SchemaSelectorProps {
  setShowAllSchemas: React.Dispatch<React.SetStateAction<boolean>>;
  onSchemaChange: (schemaId: string, oldInspection: Inspection) => void;
  disableSchemaSelect?: boolean;
  selectableSchemas: InspectionSpec[];
  showAllSchemas: boolean;
}

export function CSchemaSelector({
  setShowAllSchemas,
  onSchemaChange,
  disableSchemaSelect,
  selectableSchemas,
  showAllSchemas,
}: SchemaSelectorProps) {
  const schemaSelectRef = useRef<HTMLIonSelectElement>();
  const allSchemasStr = '*--all schemas--*';
  const onlyApplicableStr = '*--only applicable--*';

  const myOrg = useContext(ctxOrg);
  const { inspection, spec: schema, inited } = useInspectionContext();

  const onSchemaSelect = (evt: CustomEvent<any>) => {
    // @ts-ignore
    if (!evt.currentTarget?.classList.contains('select-expanded')) {
      // we only trigger the onChange when the select is expanded
      return;
    }
    const { value } = evt.detail;
    evt.preventDefault();
    evt.stopImmediatePropagation();
    evt.stopPropagation();
    if (value === allSchemasStr) {
      setShowAllSchemas(true);
      setTimeout(() => {
        schemaSelectRef.current.click();
      }, 700);
    } else if (value === onlyApplicableStr) {
      setShowAllSchemas(false);
      setTimeout(() => {
        schemaSelectRef.current.click();
      }, 700);
    } else {
      onSchemaChange(value, inspection);
    }
  };

  const disableSelect = disableSchemaSelect != null && disableSchemaSelect;

  return (
    <div className="ion-item">
      <div className="ion-label" color="dark">
        Schema
      </div>
      <IonSelect
        interfaceOptions={{ cssClass: 'schema-selector' }}
        ref={schemaSelectRef}
        placeholder={!inited ? 'Loading...' : 'Select'}
        className="inspection-header-selector"
        interface="action-sheet"
        value={schema?.id}
        onIonChange={onSchemaSelect}
        disabled={disableSelect}
      >
        {selectableSchemas?.map((s, i) => {
          return (
            <IonSelectOption
              key={s.id + i}
              value={s.id}
              data-tip={`schema-option-${s.id}`}
            >
              {s.name}
              {s.version != null ? ` (v${s.version})` : ''}
              {s.orgId != null && s.orgId !== myOrg.id ? ` - ${s.orgId}` : ''}              
            </IonSelectOption>
          );
        })}
        {!showAllSchemas && (
          <IonSelectOption className="show-all-schemas" value={allSchemasStr}>
            - Show all specs -
          </IonSelectOption>
        )}
        {showAllSchemas && (
          <IonSelectOption className="show-all-schemas" value={onlyApplicableStr}>
            - Show only applicable specs -
          </IonSelectOption>
        )}
      </IonSelect>
    </div>
  );
}
