import {
  IonBadge,
  IonButton,
  IonLoading,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React from 'react';
import { auth } from './ConfigFirebase';
import { validateOCRProp } from './DataImage';
import { UserProfile } from './Model';
import { OcrData, VisionAPIResponse } from './ModelVision';
import './ViewOCRExtraction.scss';

interface Props {
  profile: UserProfile;
  ocrResponse: VisionAPIResponse;
  onCancel: Function;
  addOCRDataToInspection: (data: OcrData) => void;
}

interface State {
  addGGNChecked: boolean;
  addGLNChecked: boolean;
  addCOCChecked: boolean;
  ggnProblem?: string;
  glnProblem?: string;
  cocProblem?: string;
  ggn?: any;
  gln?: any;
  coc?: any;

  requesting: boolean;
}

class ViewOCRExtraction extends React.Component<Props, State> {
  selectRef: { [key: string]: React.RefObject<HTMLIonSelectElement> } = {
    ggn: React.createRef<HTMLIonSelectElement>(),
    gln: React.createRef<HTMLIonSelectElement>(),
    coc: React.createRef<HTMLIonSelectElement>(),
  };

  constructor(props) {
    super(props);
    this.state = {
      addGGNChecked: false,
      addGLNChecked: false,
      addCOCChecked: false,
      ggn: props.ocrResponse?.targets.GGN,
      gln: props.ocrResponse?.targets.GLN,
      coc: props.ocrResponse?.targets.COC,
      requesting: false,
    };
  }

  performActions() {
    const { addOCRDataToInspection } = this.props;
    const { ggn, gln, coc } = this.state;
    const {
      ggnProblem,
      addGGNChecked,
      addGLNChecked,
      glnProblem,
      addCOCChecked,
      cocProblem,
    } = this.state;

    const data: OcrData = {};

    if (addGGNChecked) {
      data.ggn = ggn.value;
    }
    if (addGLNChecked) {
      data.gln = gln.value;
    }
    if (addCOCChecked) {
      data.coc = coc.value;
    }

    if (ggnProblem === 'invalid') {
      data.ggn_invalid = true;
    }
    if (ggnProblem === 'missing') {
      data.ggn_missing = true;
    }
    if (glnProblem === 'invalid') {
      data.gln_invalid = true;
    }
    if (glnProblem === 'missing') {
      data.gln_missing = true;
    }
    if (cocProblem === 'invalid') {
      data.coc_invalid = true;
    }
    if (cocProblem === 'missing') {
      data.coc_missing = true;
    }

    addOCRDataToInspection(data);
  }

  onCancel() {
    this.props.onCancel();
  }

  checkValue = async (
    value: string,
    type: 'GGN' | 'GLN' | 'CoC'
  ): Promise<string[]> => {
    let errors: string[] = [];
    if (!value?.length) {
      return [`${type} must not be empty`];
    }
    if (value.length !== 13) {
      errors.push(`${type} must be 13 digits long`);
    }
    if (!/^\d+$/.test(value)) {
      errors.push(`${type} must only contain numbers`);
    }
    if (errors.length === 0) {
      if (
        !(await validateOCRProp(
          auth,
          this.props.profile,
          type.toUpperCase() as any,
          value
        ))
      ) {
        errors.push(`${value} is not a valid ${type}`);
      }
    }
    return errors;
  };

  onAskNewValue = async (value: string, type: 'GGN' | 'GLN' | 'CoC') => {
    const val = window.prompt(`Enter new ${type} value:`, value ?? '');
    this.setState({ requesting: true });
    try {
      const errors = await this.checkValue(val, type);

      if (errors.length === 0) {
        // @ts-ignore
        this.setState({
          [`add${type.toUpperCase()}Checked`]: true,
          [type.toLowerCase()]: { value: val, extras: { valid: true } },
        });
      } else {
        let error = 'Errors found:\n';
        error += errors.map((e) => `- ${e}`).join('\n');
        alert(error);
        // reset the select
        this.selectRef[type.toLowerCase()].current.value = null;
      }
    } catch (error) {
      // DO SOMETHING
    } finally {
      this.setState({ requesting: false });
    }
  };

  render() {
    const {
      cocProblem,
      ggnProblem,
      glnProblem,
      addCOCChecked,
      addGGNChecked,
      addGLNChecked,
    } = this.state;

    const { ggn, gln, coc } = this.state;

    const ggnValid =
      !!ggn?.value &&
      (ggn?.extras === null || ggn?.extras?.valid === true) &&
      ggn?.extras?.unclear !== true;
    const ggnNotValid = !!ggn?.value && ggn?.extras?.valid === false;
    const ggnNotFound = !ggn?.value;
    const ggnUnclear = ggn?.extras?.unclear === true;

    const glnValid =
      !!gln?.value && gln?.extras?.valid === true && gln?.extras?.unclear !== true;
    const glnNotValid = !!gln?.value && gln?.extras?.valid === false;
    const glnUnclear = gln?.extras?.unclear === true;
    const glnNotFound = !gln?.value;

    const cocValid =
      !!coc?.value && coc?.extras?.valid === true && coc?.extras?.unclear !== true;
    const cocNotValid = !!coc?.value && coc?.extras?.valid === false;
    const cocUnclear = coc?.extras?.unclear === true;
    const cocNotFound = !coc?.value;

    const noOptionSelected =
      !cocProblem &&
      !ggnProblem &&
      !addGGNChecked &&
      !addCOCChecked &&
      !addGLNChecked &&
      !glnProblem;
    const applyButtonText = noOptionSelected ? 'Continue' : 'Apply';

    return (
      <div className="ocr-view">
        {this.state.requesting && <IonLoading isOpen={this.state.requesting} />}
        <IonList className="ion-no-padding">
          {/* ------------------------------------------------------------------------------------- */}
          {/* ggn */}
          {/* ------------------------------------------------------------------------------------- */}

          {ggnValid && (
            <>
              <IonItem color="primary">
                <b style={{ width: '50px', color: '#222' }}>GGN</b>
                <IonBadge
                  slot="end"
                  color="dark"
                  style={{ fontFamily: 'monospace', fontSize: '16px' }}
                >
                  {ggn.value}
                </IonBadge>
                {/* <IonIcon slot="end" icon={checkmarkCircleOutline} color="white"/> */}
              </IonItem>
              <IonItem>
                <IonLabel>Add this GGN</IonLabel>
                <IonCheckbox
                  checked={addGGNChecked}
                  onClick={() => this.setState({ addGGNChecked: !addGGNChecked })}
                />
              </IonItem>
              <IonItem button onClick={() => this.onAskNewValue(ggn?.value, 'GGN')}>
                <IonLabel>Edit GGN value</IonLabel>
              </IonItem>
            </>
          )}

          {(ggnNotValid || ggnNotFound || ggnUnclear) && (
            <>
              <IonItem color={ggnNotValid ? 'secondary' : 'danger'}>
                <b style={{ width: '170px' }}>
                  GGN
                  {ggnNotValid && <IonBadge>invalid</IonBadge>}
                  {ggnNotFound && <IonBadge>not found</IonBadge>}
                  {ggnUnclear && <IonBadge>unclear</IonBadge>}
                </b>
                <IonBadge slot="end" color="dark" className="ocr-code">
                  {ggn?.value}
                </IonBadge>
              </IonItem>
              <IonSelect
                ref={this.selectRef.ggn}
                interface="action-sheet"
                placeholder="Select One"
                onIonChange={(e) => {
                  // console.log(e.detail.value)
                  const { value } = e.detail;
                  if (value === 'edit') {
                    this.onAskNewValue(ggn?.value, 'GGN');
                  } else if (value === 'use') {
                    this.setState({
                      addGGNChecked: true,
                      ggnProblem: undefined,
                      ggn: { value: ggn.value, extras: { valid: true } },
                    });
                  } else {
                    this.setState({ addGGNChecked: false, ggnProblem: value });
                  }
                }}
              >
                <IonSelectOption value="invalid">Indicate GGN problem</IonSelectOption>
                <IonSelectOption value="missing">Indicate GGN missing</IonSelectOption>
                {!!ggn?.value && (
                  <IonSelectOption value="use">Use {ggn.value}</IonSelectOption>
                )}
                <IonSelectOption value="edit">Edit GGN value</IonSelectOption>
              </IonSelect>
            </>
          )}

          {/* ------------------------------------------------------------------------------------- */}
          {/* gln */}
          {/* ------------------------------------------------------------------------------------- */}

          {glnValid && (
            <>
              <IonItem color="primary">
                {/* <IonIcon slot="start" icon={checkmarkCircleOutline} color="dark"/> */}
                <b style={{ width: '50px', color: '#222' }}>GLN</b>
                <IonBadge slot="end" color="dark" className="ocr-code">
                  {gln.value}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Add GLN to inspection</IonLabel>
                <IonCheckbox
                  checked={addGLNChecked}
                  onClick={() => this.setState({ addGLNChecked: !addGLNChecked })}
                />
              </IonItem>
              <IonItem button onClick={() => this.onAskNewValue(gln?.value, 'GLN')}>
                <IonLabel>Edit GLN value</IonLabel>
              </IonItem>
            </>
          )}

          {(glnNotValid || glnNotFound || glnUnclear) && (
            <>
              <IonItem color={glnNotValid ? 'secondary' : 'danger'}>
                <b style={{ width: '170px' }}>
                  GLN
                  {glnNotValid && <IonBadge>invalid</IonBadge>}
                  {glnNotFound && <IonBadge>not found</IonBadge>}
                  {glnUnclear && <IonBadge>unclear</IonBadge>}
                </b>
                <IonBadge slot="end" color="dark" className="ocr-code">
                  {gln?.value}
                </IonBadge>
              </IonItem>
              <IonSelect
                interface="action-sheet"
                placeholder="Select One"
                ref={this.selectRef.gln}
                onIonChange={(e) => {
                  console.log(e.detail.value);
                  const { value } = e.detail;
                  if (value === 'edit') {
                    // const val = window.prompt('Enter new GLN value:', gln?.value)
                    // if (val) this.setState({addGLNChecked: true, glnProblem: undefined, gln: { value:val, extras: { valid: true} }})
                    this.onAskNewValue(gln?.value, 'GLN');
                  } else if (value === 'use') {
                    this.setState({
                      addGLNChecked: true,
                      glnProblem: undefined,
                      gln: { value: gln.value, extras: { valid: true } },
                    });
                  } else {
                    this.setState({ addGLNChecked: false, glnProblem: value });
                  }
                }}
              >
                <IonSelectOption value="invalid">Indicate GLN problem</IonSelectOption>
                <IonSelectOption value="missing">Indicate GLN missing</IonSelectOption>
                {!!gln?.value && (
                  <IonSelectOption value="use">Use {gln.value}</IonSelectOption>
                )}
                <IonSelectOption value="edit">Edit GLN value</IonSelectOption>
              </IonSelect>
            </>
          )}

          {/* ------------------------------------------------------------------------------------- */}
          {/* COC */}
          {/* ------------------------------------------------------------------------------------- */}

          {cocValid && (
            <>
              <IonItem color="primary">
                {/* <IonIcon slot="start" icon={checkmarkCircleOutline} color="dark"/> */}
                <b style={{ width: '50px', color: '#222' }}>CoC</b>
                <IonBadge slot="end" color="dark" className="ocr-code">
                  {coc.value}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Add CoC to inspection</IonLabel>
                <IonCheckbox
                  checked={addCOCChecked}
                  onClick={() => this.setState({ addCOCChecked: !addCOCChecked })}
                />
              </IonItem>
              <IonItem button onClick={() => this.onAskNewValue(coc?.value, 'CoC')}>
                <IonLabel>Edit CoC value</IonLabel>
              </IonItem>
            </>
          )}

          {(cocNotValid || cocNotFound || cocUnclear) && (
            <>
              <IonItem color={cocNotValid ? 'secondary' : 'danger'}>
                <b style={{ width: '170px' }}>
                  CoC
                  {cocNotValid && <IonBadge>invalid</IonBadge>}
                  {cocNotFound && <IonBadge>not found</IonBadge>}
                  {cocUnclear && <IonBadge>unclear</IonBadge>}
                </b>
                <IonBadge slot="end" color="dark" className="ocr-code">
                  {coc?.value}
                </IonBadge>
              </IonItem>
              <IonSelect
                interface="action-sheet"
                placeholder="Select One"
                ref={this.selectRef.coc}
                onIonChange={(e) => {
                  console.log(e.detail.value);
                  const { value } = e.detail;
                  if (value === 'edit') {
                    this.onAskNewValue(coc?.value, 'CoC');
                  } else if (value === 'use') {
                    this.setState({
                      addCOCChecked: true,
                      cocProblem: undefined,
                      coc: { value: coc.value, extras: { valid: true } },
                    });
                  } else {
                    this.setState({ addCOCChecked: false, cocProblem: value });
                  }
                }}
              >
                <IonSelectOption value="invalid">Indicate CoC problem</IonSelectOption>
                <IonSelectOption value="missing">Indicate CoC missing</IonSelectOption>
                {!!coc?.value && (
                  <IonSelectOption value="use">Use {coc.value}</IonSelectOption>
                )}
                <IonSelectOption value="edit">Edit CoC value</IonSelectOption>
              </IonSelect>
            </>
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: '10px',
            }}
          >
            <IonButton
              size="default"
              fill="clear"
              color="dark"
              onClick={() => this.onCancel()}
            >
              Cancel
            </IonButton>
            <IonButton
              size="default"
              color="primary"
              onClick={() => this.performActions()}
            >
              {applyButtonText}
            </IonButton>
          </div>
        </IonList>
      </div>
    );
  }
}

export default ViewOCRExtraction;
