import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  // IonCard,
  // IonCardContent,
  // IonCardHeader,
  // IonCardSubtitle,
  // IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  // IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import { toastController } from '@ionic/core';
import firebase from 'firebase/compat/app';
import {
  addCircleOutline,
  chatbubbleEllipsesOutline,
  chevronBackOutline,
  createOutline,
  trashOutline,
} from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import React, { RefObject } from 'react';
import { firestore } from './ConfigFirebase';
import {
  addLotMessage,
  deleteLot,
  getLotSnapshot,
  saveLotUnderNewId,
  updateLotAtomic,
} from './DataStorage';
import {
  COMMON_COL_NAME,
  SUPPLY_CHAIN_COL_NAME,
  SUPPLY_CHAIN_URL_SUFFIX,
} from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import {
  getLotOrPosQuantity,
  getProductionTransferOfLotCreatedFromBatchTransformation,
  isMobile,
  lotIsUserCreated,
} from './HelperUtils';
import { LegacyInspectionReference } from './InspectionModel';
import {
  Conversation,
  Lot,
  LotCreatePayload,
  LotUpdateObject,
  Picture,
  PopoverModel,
  ClaimableOrderTypes,
  ClaimableOrderType,
  dateToString,
  MergedSchema,
} from './Model';
import './PageLot.scss';
import PageLotHistory from './PageLotHistory';
import PageNewConversation from './PageNewConversation';
import PageNewLot from './PageNewLot';
import {
  AppProduct,
  QCTierAction,
  orgHasPermission,
  userHasPermission,
} from './PermissionsService';
import { Article } from './ServiceArticle';
import i18n from './ServiceI18n';
import { buildLotOrTransitInspectionPath } from './ServiceInspection';
import { PageCreateClaim } from './PageCreateClaim';
import ViewArticleDescription from './ViewArticleDescription';
import { presentStandardToast } from './HelperIonic';

interface Props {
  history: any;
  organisationId: string;
  lotId: string;
  supplyChainLot?: boolean;
  orderId?: string;
}

interface State {
  lot?: Lot;
  inited: boolean;
  loading: boolean;
  ionLoading?: boolean;

  menuPopover: PopoverModel;
  showPageGallery?: boolean;
  showHistory?: boolean;
  showNewConversation?: boolean;
  showMoreInsight?: boolean;
  showNewMetadata?: boolean;
  showRemoveAlert?: boolean;

  showEditLot: boolean;
  showCreateClaim: boolean;

  showInspectionOptions: boolean;

  defectConfiguration: MergedSchema;
}

class PageLot extends React.Component<Props & ContextProps, State> {
  private pageRef: RefObject<HTMLElement> = React.createRef();
  private unsubscribers: any[] = [];
  private mounted;
  private selectBrandRef;

  constructor(props) {
    super(props);

    this.state = {
      inited: false,
      loading: true,
      menuPopover: { visible: false },
      defectConfiguration: undefined,
      showEditLot: false,
      showInspectionOptions: false,
      showCreateClaim: false,
    };
  }

  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    // const { showOrders } = this.state
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.lotId !== nextProps.lotId) {
      return true;
    }

    if (this.props.organisationId !== nextProps.organisationId) {
      return true;
    }
    // if (this.props.upfront !== nextProps.upfront) {
    //   return true;
    // }

    return false;
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  async ionViewWillEnter() {
    console.log(this.props);
    if (this.props.lotId !== this.state.lot?.id) {
      if (this.mounted) {
        this.setState({ inited: true, lot: undefined, loading: true });
      }
    }

    this.unsubscribers.map((unsubscribe) => unsubscribe());

    this.unsubscribers.push(
      getLotSnapshot(
        firestore,
        this.props.profile.organisationId,
        this.props.lotId,
        (lot) => {
          if (this.mounted) {
            this.setState({ inited: true, lot: lot ?? null, loading: false });
          }
        },
        this.props.organisationId,
        !!this.props.supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined
      )
    );
  }

  ionViewWillLeave() {
    this.unsubscribers.map((unsubscribe) => unsubscribe());
    // this.setState({inited: false, lot: undefined, loading: false});
  }

  async pictureSelected(picture: Picture) {
    if (this.mounted) {
      this.setState({ showPageGallery: true });
    }
  }

  getProductName(): string {
    // TODO: return either agProductId or productId depending on Organisation Settings
    return this.state.lot?.article?.agProductId ?? this.state.lot?.article?.productId;
  }

  renderLoading() {
    // TODO: render loading
    return '';
  }

  async saveNewConversation(conversation: Conversation) {
    conversation.title = this.props.lotId;
    this.setState({ showNewConversation: false, ionLoading: true });
    await addLotMessage(firestore, this.state.lot, this.props.profile.id, conversation);
    await this.setState({ ionLoading: false });
    this.props.history.push('/secure/inbox/' + conversation.id);
  }

  onClickEditLot() {
    if (this.state.lot?.transient?.hasLink) {
      alert(
        'Lot is already linked to an order. Please remove the link before editing it'
      );
      return;
    }
    this.setState({ showEditLot: true });
  }

  showRemoveAlert(checkLink: boolean = false) {
    if (checkLink && this.state.lot?.transient?.hasLink) {
      alert(
        'Lot is already linked to an order. Please remove the link before proceeding with the deletion'
      );
      return;
    }
    this.setState({ showRemoveAlert: true });
  }

  hideRemoveAlert() {
    this.setState({ showRemoveAlert: false });
  }

  render() {
    const { lotId, supplyChainLot, orderId, profile, organisation } = this.props;
    const { lot } = this.state;

    const canDeleteSupplyChainLot =
      userHasPermission(this.props.profile, 'DO', 'SURVEYING') && supplyChainLot;

    const canDeleteERPLiteLot =
      lotIsUserCreated(lot) &&
      userHasPermission(this.props.profile, 'DELETE', 'LOT') &&
      this.props.organisation?.settings?.allowManagingLots;

    const canDeleteLotImportedViaApi =
      userHasPermission(this.props.profile, 'DELETE', 'LOT') &&
      organisation?.settings?.allowDeletingApiImportedEntities;

    const canRemoveLot =
      !!lot &&
      (canDeleteSupplyChainLot || canDeleteERPLiteLot || canDeleteLotImportedViaApi);

    // don't allow edition of lot if it was created via transformation
    const canEditLot =
      !!lot &&
      (userHasPermission(this.props.profile, 'WRITE', 'LOT') ||
        canDeleteSupplyChainLot) &&
      !getProductionTransferOfLotCreatedFromBatchTransformation(lot);

    const inspectionText = orderId != null ? 'Incoming inspection' : 'New inspection';

    // only show the create claim button if the lot has associated incoming/outgoing orders
    const displayCreateClaimButton: boolean =
      !!lot &&
      !supplyChainLot &&
      this.props.organisation?.settings?.allowManagingLots &&
      userHasPermission(profile, 'WRITE', 'LOT') &&
      lot.transfers.filter(
        (t) =>
          ClaimableOrderTypes.includes(t.transferType as ClaimableOrderType) &&
          !!t.orderId
      ).length > 0;

    const inspectionReference: LegacyInspectionReference = {
      lotId,
      type: orderId != null ? 'incoming' : undefined,
      orderId,
      date: dateToString(new Date()),
    };

    let title = `${supplyChainLot ? 'Supply chain batch - ' : ''}${lotId}`;
    if (isMobile()) {
      title = lotId;
    }

    return (
      <IonPage className="page-lot" ref={this.pageRef}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text={i18n.t('General.back')}
                defaultHref="/tabs/quality-control"
                color="dark"
              >
                <IonIcon icon={chevronBackOutline} slot="icon-only" />
              </IonBackButton>
            </IonButtons>

            <IonTitle>
              <div>{title}</div>
            </IonTitle>

            <IonButtons slot="end">
              {canRemoveLot && (
                <IonButton color="danger" onClick={(_) => this.showRemoveAlert(true)}>
                  <IonIcon icon={trashOutline} slot="icon-only" />
                </IonButton>
              )}
              {canEditLot && (
                <IonButton color="dark" onClick={(_) => this.onClickEditLot()}>
                  <IonIcon icon={createOutline} slot="icon-only" />
                </IonButton>
              )}
              {orgHasPermission(
                this.props.organisation?.settings,
                AppProduct.QualityControl,
                QCTierAction.Chat
              ) && (
                <IonButton
                  color="dark"
                  onClick={(_) => this.setState({ showNewConversation: true })}
                >
                  <IonIcon icon={chatbubbleEllipsesOutline} slot="icon-only" />
                </IonButton>
              )}
              {/* {canAccessInfo && <IonButton color="dark" onClick={_ => this.setState({ showNewMetadata: !this.state.showNewMetadata })}>
                {(!this.state.showNewMetadata)
                  ? <IonIcon icon={informationCircleOutline}></IonIcon>
                  : <IonIcon icon={closeCircleOutline}></IonIcon>}
              </IonButton>} */}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">
                <div>{lotId}</div>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonAlert
            isOpen={this.state.showRemoveAlert}
            onDidDismiss={() => this.hideRemoveAlert()}
            header="Confirm"
            message="Are you sure? This cannot be UNDONE"
            buttons={[
              {
                text: i18n.t('General.no'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {},
              },
              {
                text: i18n.t('General.yes'),
                cssClass: 'danger-button primary',
                handler: () => {
                  this.removeLot().catch((error) =>
                    presentStandardToast(
                      toastController,
                      `Lot could not be deleted\n${error}`
                    )
                  );
                },
              },
            ]}
          />
          {/* {
        (this.state.showNewMetadata) &&
        <IonList className="metadata" slot="fixed">
          <form onSubmit={(e)=>this.updateLot(e)}>
          <ctxContacts.Consumer>
            {contacList => {
              if (!this.state.lot) {
                return null
              }
              let optionsSupplier = contacList[0].contacts.sort((a, b) => a?.name?.localeCompare(b.name)).map(c => {
                return { 
                  value: c.name, 
                  label: c.name
                }
              })
              return <IonItem className="item-selectable z-12">
                <IonLabel>Supplier</IonLabel>
                <Select options={optionsSupplier} name="supplier" defaultInputValue={cache?.article?.supplier} classNamePrefix={'react-select'}/>
              </IonItem>
            }}
          </ctxContacts.Consumer>
          <ctxProducts.Consumer>
            {productList => {
              if (!this.state.lot) {
                return null
              }

              let product:ProductView = productList.find(p => p.productId === this.state.lot.article.productId || p.agProductId === this.state.lot.article.agProductId)
              console.log('p', product);
              let optionsBrand = (product.brands ?? []).sort().map(b => {
                return { 
                  value: b, 
                  label: b
                }
              })
              let optionsVariety = ['STANDARD', 'PREMIUM'].concat((product.varieties ?? []).sort()).map(b => {
                return { 
                  value: b, 
                  label: b
                }
              })
              let optionsPackaging = (product.packaging ?? []).sort().map(b => {
                return { 
                  value: b, 
                  label: b
                }
              })
              return <>
                <IonItem className="item-selectable z-11">
                  <IonLabel>Variety</IonLabel>
                  <Select options={optionsVariety} name="variety" defaultInputValue={cache?.article?.variety} />
                </IonItem>
                <IonItem className="item-selectable z-10">
                  <IonLabel>Packaging</IonLabel>
                  <Select options={optionsPackaging} name="packaging" defaultInputValue={cache?.article?.packaging} classNamePrefix={'react-select'}/>
                </IonItem>
                <IonItem className="item-selectable z-09">
                  <IonLabel>Brand</IonLabel>
                  <IonInput name="brand" type="text" value={cache?.article?.brand}/>
                  <Select options={optionsBrand} name="brand" ref={ref => this.selectBrandRef = ref} defaultInputValue={cache?.article?.brand} classNamePrefix={'react-select'}/>
                </IonItem>
                </>
            }}
            </ctxProducts.Consumer>
            <IonItem>
              <IonLabel>Quantity</IonLabel>
              <IonInput name="quantity" type="number" inputmode="numeric" value={cache?.transient?.numBoxes}/>
            </IonItem>
            <IonItem>
              <IonLabel>Origin ID</IonLabel>
              <IonInput placeholder="empty" name="origin" type="text" value={cache?.article?.origin}/>
            </IonItem>
            <IonItem>
              <IonLabel>Traceability code</IonLabel>
              <IonInput placeholder="empty" name="traceabilityCode" inputmode="numeric" type="text" value={cache?.traceabilityCode}/>
            </IonItem>           
            <IonItem>
              <IonLabel>Picking date</IonLabel>
              <IonDatetime placeholder="empty" name="pickingDate" value={cache?.article?.pickingDate}/>
            </IonItem>
            <div className="metadata-buttons">
              <IonButton type="submit" color="primary" fill="outline">Update</IonButton>
              { lotIsUserCreated(this.state.lot) &&
                <IonButton color="danger2" onClick={_=>this.showRemoveAlert()}>Delete LOT</IonButton>
              }
            </div>
            </form>
          </IonList>
        } */}
          {
            <>
              {lot && (
                <>
                  <div className="article-description">
                    <div>
                      {/* {lot.transfers?.filter(t => t.transferType === "BUY").map(t => t.orderId).join(", ")} */}
                      <ViewArticleDescription
                        growerId={lot.origin?.growerContactId}
                        article={lot.article}
                        pills={true}
                      />
                    </div>
                    <div className="quantity">
                      <div>{getLotOrPosQuantity(lot)}</div>
                      <div>
                        {new Article(lot.article).getUnit(lot.transient?.numBoxes || 0)}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {
                <div slot="fixed" className="button-container">
                  {userHasPermission(this.props.profile, 'WRITE', 'ASSESSMENT') && (
                    <IonButton
                      expand="block"
                      color="tertiary"
                      data-tip={'page-lot-new-inspection'}
                      routerLink={buildLotOrTransitInspectionPath(
                        this.props.profile.organisationId,
                        inspectionReference,
                        supplyChainLot
                      )}
                    >
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      &nbsp;{inspectionText}
                    </IonButton>
                  )}

                  {displayCreateClaimButton && (
                    <IonButton
                      expand="block"
                      color="tertiary"
                      data-tip={'page-lot-create-claim'}
                      onClick={() => this.setState({ showCreateClaim: true })}
                    >
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      &nbsp;New Claim
                    </IonButton>
                  )}
                </div>
              }

              {lot && this.mounted ? (
                <PageLotHistory
                  {...this.props}
                  lot={lot}
                  supplyChainLot={supplyChainLot}
                />
              ) : null}
            </>
          }

          {this.state.showEditLot && (
            <IonModal
              isOpen={true}
              onDidDismiss={() => this.setState({ showEditLot: false })}
              cssClass="modal-page-new-lot"
            >
              <PageNewLot
                onCancel={() => this.setState({ showEditLot: false })}
                onSave={(lot: LotCreatePayload, _, newLotId?: string) =>
                  this.saveLot(lot, newLotId)
                }
                lotToEdit={lot}
                supplyChainLot={supplyChainLot}
              />
            </IonModal>
          )}

          {this.state.showCreateClaim && (
            <IonModal
              isOpen={this.state.showCreateClaim}
              onDidDismiss={() => this.setState({ showCreateClaim: false })}
              cssClass="modal-page-create-claim"
            >
              <PageCreateClaim
                lot={lot}
                onClose={() => this.setState({ showCreateClaim: false })}
              />
            </IonModal>
          )}

          {this.state.showNewConversation && (
            <IonModal
              isOpen={true}
              onDidDismiss={(_) => this.setState({ showNewConversation: false })}
            >
              <PageNewConversation
                profile={this.props.profile}
                onCancel={(_) => this.setState({ showNewConversation: false })}
                onSave={(conversation) => this.saveNewConversation(conversation)}
              ></PageNewConversation>
            </IonModal>
          )}

          <IonLoading isOpen={this.state.ionLoading} />
        </IonContent>
      </IonPage>
    );
  }

  async saveLot(payload: LotCreatePayload, newLotId?: string) {
    const { profile, supplyChainLot, organisation } = this.props;
    const updateObj: LotUpdateObject = {
      article: payload.article,
      suppliedByContactId: payload.suppliedByContactId,
    } as LotUpdateObject;

    const deleteField = firebase.firestore.FieldValue.delete() as any;

    // transient props
    updateObj['transient.palletIds'] = payload.palletIds;
    updateObj['transient.numBoxes'] = payload.numBoxes;
    updateObj['transient.volumeInKg'] = payload.volumeInKg;
    updateObj['transient.locationId'] = payload.locationId;

    // origin props
    updateObj['origin.growerContactId'] = payload.growerContactId;
    updateObj['origin.growerGGN'] = payload.growerGGN;
    updateObj['origin.plotId'] = payload.originPlotId;
    updateObj['origin.locationId'] = payload.originLocationId;
    updateObj['origin.harvestDate'] = payload.originHarvestDate ?? deleteField;
    updateObj['origin.previousHarvestDate'] =
      payload.originPreviousHarvestDate ?? deleteField;
    updateObj['origin.harvestNumber'] = !isNaN(payload.originHarvestNumber)
      ? payload.originHarvestNumber
      : deleteField;
    updateObj['origin.daysFromPrevHarvest'] = !isNaN(payload.originDaysFromPrevHarvest)
      ? payload.originDaysFromPrevHarvest
      : deleteField;

    // prev org props
    updateObj['previousOrg.lotReferenceId'] = payload.prevOrgLotReferenceId;

    const collection = supplyChainLot ? SUPPLY_CHAIN_COL_NAME : COMMON_COL_NAME;

    if (!updateObj.transfers) {
      updateObj.transfers = cloneDeep(this.state.lot.transfers);
    }

    if (payload.numBoxes != null) {
      updateObj.transfers = updateObj.transfers.map((t, i) =>
        i === 0 ? { ...t, numBoxes: payload.numBoxes } : t
      );
    }

    if (payload.volumeInKg != null) {
      updateObj.transfers = updateObj.transfers.map((t, i) =>
        i === 0 ? { ...t, volumeInKg: payload.volumeInKg } : t
      );
    }

    if (payload.firstTransferType != null) {
      updateObj.transfers = updateObj.transfers.map((t, i) =>
        i === 0 ? { ...t, transferType: payload.firstTransferType } : t
      );
    }

    if (newLotId == null) {
      await updateLotAtomic(
        firestore,
        profile,
        this.state.lot,
        updateObj,
        organisation?.settings,
        collection,
        collection === COMMON_COL_NAME
      );
    } else {
      await saveLotUnderNewId(
        firestore,
        profile,
        this.state.lot,
        newLotId,
        organisation?.settings,
        collection,
        updateObj
      );
      this.props.history.replace(
        `/secure/${profile.organisationId}/lot/${newLotId}${
          supplyChainLot ? `/${SUPPLY_CHAIN_URL_SUFFIX}` : ''
        }`
      );
    }

    this.setState({ showEditLot: false });
  }

  async removeLot() {
    const lot = cloneDeep(this.state.lot);
    await this.setState({
      showRemoveAlert: false,
      lot: undefined,
      showNewMetadata: false,
    });
    this.props.history.push('/tabs/quality-control');

    return deleteLot(
      firestore,
      this.props.profile,
      this.props.lotId,
      'PageLot',
      this.props.supplyChainLot ? SUPPLY_CHAIN_COL_NAME : COMMON_COL_NAME
    );
  }
}

export default React.memo(
  withContext(withIonLifeCycle(PageLot), ['profile', 'organisation'])
);
