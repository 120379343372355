import firebase from 'firebase/compat/app';
import { cloneDeep } from 'lodash';
import { getReportSnapshot } from './DataReport';
import {
  addMessage,
  getLotSnapshot,
  getLotsTransactional,
  getLotTransactional,
  getOrderSnapshot,
  getOrderTransactional,
  inspectionColRef,
  inspectionDocRef,
  inspectionHistoryColRef,
  locationColRef,
  lotColRef,
  offlineSet,
  offlineUpdate,
  orderColRef,
  setLotFromUI,
} from './DataStorage';
import {
  INSPECTION_IMAGES_STORAGE_BASE_PATH,
  LOT_INSPECTION_MAP,
  SUPPLY_CHAIN_COL_NAME,
  TRANSPORT_INSPECTION_MAP,
} from './GlobalConstants';
import { isSplitLotPosition, sortByLastModifiedDateAsc, uuid4 } from './HelperUtils';
import { newInspectionForLot, newInspectionForOrder } from './InspectionLogic';
import {
  LotInspection,
  LegacyInspectionReference,
  LotProperties,
  PropertyToBeSet,
  LegacyInspection,
  TransitInspection,
  Inspection,
  ProductionSiteInspectionReference,
  ProductionSiteInspection,
  InspectionReference,
} from './InspectionModel';
import {
  AG_BOXES_AT_INSPECTION_QUESTION_ID,
  AG_BOXES_SHIPPED_QUESTION_ID,
  IArticle,
  Location,
  Lot,
  LotPosition,
  LotTransientProps,
  Order,
  OrderCreatePayload,
  Organisation,
  updateAggregateModificationData,
  UserProfile,
  Conversation,
  ProductionSiteLocation,
} from './Model';
import { generateOrderSearch } from './SearchService';
import {
  buildInspectionId,
  compileProductionSiteInspectionPreview,
  hasInspectionReference,
  InspectionClass,
  isNewerInspection,
  removeEmptyInspectionReferenceFields,
  stringifyInspectionReference,
  updateLotPropertiesFromInspection,
} from './ServiceInspection';
import {
  LotSchemaObjectTypeEnum,
  TransitSchemaObjectTypeEnum,
} from './generated/openapi/core';
import { useState, useEffect } from 'react';

export async function updateLotOrTransitInspectionInRelatedEntities(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  oldInspection: LegacyInspection,
  propertiesToSet: PropertyToBeSet[],
  organisation: Organisation,
  updateFromHistory?: boolean,
  supplyChainLot: boolean = false
) {
  let inspection: LegacyInspection = cloneDeep(oldInspection);

  inspection.reference = removeEmptyInspectionReferenceFields(inspection.reference);
  inspection.id = buildInspectionId(profile.organisationId, inspection);

  let article: IArticle | undefined;
  if (inspection.objectType === LotSchemaObjectTypeEnum.Lot) {
    article = inspection.lotProperties.article;
    inspection.isSupplyChain = supplyChainLot;
  }

  if (!updateFromHistory) {
    updateAggregateModificationData(profile.id, inspection);
  }

  // 1. Save inspection in its dedicated collection
  await offlineSet(
    inspectionDocRef(store, profile.organisationId, inspection.id),
    inspection
  );

  // 2. Update inspection in related entities
  const { orderId, lotId } = inspection.reference;

  const order: Order = orderId
    ? await getOrderTransactional(store, undefined, profile, orderId)
    : undefined;

  const oldLot: Lot = lotId
    ? await getLotTransactional(
        store,
        undefined,
        profile,
        { lotId, numBoxes: undefined, article },
        false,
        supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined,
        order
      )
    : undefined;

  if (!updateFromHistory) {
    // add copy of the inspection to local history of changes
    inspectionHistoryColRef(store, profile.organisationId)
      .add(inspection)
      .catch((err) => {
        console.error('error on adding to inspectionHistory', err);
        throw err;
      });
  }

  // Update location with the gps coordinates, if applies (only when updating assessment from the UI (!transaction))
  if (!!inspection.locationId && !!inspection.recordedGeoPoint) {
    const locationDocRef = locationColRef(store, profile.organisationId).doc(
      inspection.locationId
    );
    const locationDoc = await locationDocRef.get();
    if (locationDoc.exists) {
      const location: Location = locationDoc.data() as Location;
      // we only update the GeoPoint if it doesn't exist, revise this in the future
      if (!location.geoPoint) {
        locationDocRef.update({ geoPoint: inspection.recordedGeoPoint } as Location);
      }
    }
  }

  if (order) {
    await addOrUpdateInspectionForOrder({ order, inspection, profile, store });
  }
  if (oldLot && inspection.objectType === LotSchemaObjectTypeEnum.Lot) {
    const newLot: Lot = { ...oldLot, article };
    addOrUpdateInspectionForLot(newLot, inspection);

    // update properties (for now, this only happens at the lot level, but will change in the future)
    updateLotPropertiesFromInspection(newLot, inspection, propertiesToSet);

    await setLotFromUI(
      store,
      profile,
      newLot,
      supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined,
      organisation
    );
  }
}

export async function updateProductionSiteInspectionInRelatedEntities(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  oldInspection: ProductionSiteInspection,
  organisation: Organisation
) {
  let inspection: ProductionSiteInspection = cloneDeep(oldInspection);

  inspection.reference = removeEmptyInspectionReferenceFields(inspection.reference);
  inspection.id = buildInspectionId(profile.organisationId, inspection);

  updateAggregateModificationData(profile.id, inspection);

  // 1. Save inspection in its dedicated collection and in the inspection history collection
  await offlineSet(
    inspectionDocRef(store, profile.organisationId, inspection.id),
    inspection
  );

  inspectionHistoryColRef(store, profile.organisationId)
    .add(inspection)
    .catch((err) => {
      console.error('error on adding to inspectionHistory', err);
      throw err;
    });

  // 2. Update inspection preview in location document
  const { locationId } = inspection.reference;
  await offlineUpdate<ProductionSiteLocation>(
    locationColRef(store, organisation.id).doc(locationId),
    {
      latestInspectionPreview: compileProductionSiteInspectionPreview(inspection),
      lastQCDate: new Date(
        isNaN(+inspection.reference.date)
          ? inspection.reference.date
          : +inspection.reference.date
      ),
    }
  );
}

//---------------------------------------------

export async function addOrUpdateInspectionForOrder({
  order,
  store,
  inspection,
  profile,
}: {
  order: Order;
  inspection: LegacyInspection;
  profile: UserProfile;
  store: firebase.firestore.Firestore;
}) {
  const { transportId, lotId } = inspection.reference;

  let updateObj: Order = {} as Order;

  // if the assessment is completed, we add a flag indicating that a report draft is in progress
  if (new InspectionClass(inspection).isCompleted()) {
    updateObj.hasReportDraft = true;
  }

  for (const param of [TRANSPORT_INSPECTION_MAP, LOT_INSPECTION_MAP]) {
    if (!order[param]) {
      order[param] = {};
    }
  }

  if (
    transportId?.length > 0 &&
    inspection.objectType === TransitSchemaObjectTypeEnum.Transit
  ) {
    // Update transport only if newer than last one and if we are not overriding internal assessments with external ones
    if (isNewerInspection(inspection, order.transportInspectionMap?.[transportId])) {
      updateObj[`${TRANSPORT_INSPECTION_MAP}.${transportId}`] = inspection;
      order.transportInspectionMap[transportId] = inspection;
    }
  }
  if (lotId?.length > 0 && inspection.objectType === LotSchemaObjectTypeEnum.Lot) {
    // Update lot assessment only if newer than last one and if we are not overriding internal assessments with external ones
    if (isNewerInspection(inspection, order.lotInspectionMap?.[lotId])) {
      updateObj[`${LOT_INSPECTION_MAP}.${lotId}`] = inspection;
      order.lotInspectionMap[lotId] = inspection;
    }
  }

  if (
    Object.values(order.lotInspectionMap ?? {}).filter((a) =>
      new InspectionClass(a).isCompleted()
    ).length === order.positions?.length
  ) {
    updateObj.qcStatus = 'DONE';
    updateObj.lastQCStatusDate = new Date();
    updateObj.lastQCStatusUserId = profile.id;
  } else {
    updateObj.qcStatus = 'OPEN';
  }

  const org: Organisation = (
    await store.collection('organisation').doc(profile.organisationId).get()
  ).data() as Organisation;

  updateObj.search = generateOrderSearch(order, org?.settings);

  // add date of first and last inspection
  if (inspection.source === 'INTERNAL') {
    if (!order.firstQCDate) {
      updateObj.firstQCDate = new Date();
    }
    updateObj.lastQCDate = new Date();
  }

  updateAggregateModificationData(profile.id, updateObj);

  const orderRef = orderColRef(store, order.orgId).doc(order.id);

  try {
    orderRef.update(updateObj);
    return;
  } catch (err) {
    console.error('error on addOrUpdateInspectionForOrder', err);
    throw err;
  }
}

export function addOrUpdateInspectionForLot(lot: Lot, inspection: LotInspection) {
  if (!lot.transient) {
    lot.transient = {} as LotTransientProps;
  }

  // add date of first and last inspection, and update relevancy date
  if (inspection.source === 'INTERNAL') {
    const newDate = new Date();
    if (!lot.firstQCDate) {
      lot.firstQCDate = newDate;
    }
    lot.lastQCDate = newDate;
    lot.transient.freshnessDate = newDate;
  }

  // update assessments according to the latest model.
  lot.latestInspection = inspection;

  // update lot location if present on assessment
  lot.transient.locationId = inspection.locationId;
  lot.search.locationId = inspection.locationId;

  const lotInspections: LotInspection[] = lot.inspections ?? [];
  const assessmentIndex = lotInspections.findIndex((a) =>
    hasInspectionReference(a, inspection.reference)
  );

  if (assessmentIndex < 0) {
    lotInspections.push(inspection);
  } else {
    lotInspections[assessmentIndex] = inspection;
  }

  lot.inspections = lotInspections.sort(sortByLastModifiedDateAsc);
}

export function findInspectionInEntity(
  reference: LegacyInspectionReference,
  order?: Order,
  lot?: Lot
): LegacyInspection | undefined {
  if (reference.lotId?.length > 0 && !!lot) {
    const inspections: LotInspection[] = lot?.inspections ?? [];
    const inspection = inspections.find((i) => hasInspectionReference(i, reference));

    // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
    if (!!inspection) inspection.objectType = LotSchemaObjectTypeEnum.Lot;
    return inspection;
  }
  if (reference.transportId?.length && !!order) {
    const transportInspectionMap = order?.transportInspectionMap ?? {};
    const inspection =
      transportInspectionMap?.[reference.transportId] ??
      (newInspectionForOrder(
        { ...reference, type: 'transport' },
        order
      ) as TransitInspection);

    // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
    if (!!inspection) inspection.objectType = TransitSchemaObjectTypeEnum.Transit;
    return inspection;
  }
  return undefined;
}

export function getReportInspectionSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  reportId: string,
  reference: LegacyInspectionReference,
  onResult: (inspection: LegacyInspection) => any
): () => void {
  // Optimization: fetch from the order object as it is already in mem
  return getReportSnapshot(firestoreRef, organisationId, reportId, async (report) => {
    if (!report) {
      onResult(null);
    } else {
      report.transportInspectionMap = report.transportInspectionMap || {};
      report.lotInspectionMap = report.lotInspectionMap || {};
      if (reference.transportId?.length > 0 && report.transportInspectionMap) {
        const inspection =
          report.transportInspectionMap[reference.transportId] ||
          (newInspectionForOrder(reference, report) as TransitInspection);

        // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
        inspection.objectType = TransitSchemaObjectTypeEnum.Transit;
        onResult(inspection);
      } else if (reference.lotId?.length > 0 && report.lotInspectionMap) {
        const inspection =
          report.lotInspectionMap[reference.lotId] ||
          (newInspectionForOrder(reference, report) as LotInspection);

        // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
        inspection.objectType = LotSchemaObjectTypeEnum.Lot;
        onResult(inspection);
      }
    }
  });
}

export function getLegacyInspectionSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  reference: LegacyInspectionReference,
  date: string | undefined,
  onResult: (assessment: LegacyInspection) => any,
  objectOrganisationId: string = organisationId,
  supplyChainLot: boolean = false
): () => void {
  if (reference.orderId) {
    // Optimization: fetch from the order object as it is already in mem
    return getOrderSnapshot(
      firestoreRef,
      organisationId,
      reference.orderId,
      async (order) => {
        if (!order) {
          onResult(null);
        } else {
          order.transportInspectionMap = order.transportInspectionMap || {};
          order.lotInspectionMap = order.lotInspectionMap || {};
          if (reference.transportId?.length > 0 && order.transportInspectionMap) {
            const inspection =
              order.transportInspectionMap[reference.transportId] ||
              (newInspectionForOrder(reference, order) as TransitInspection);

            // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
            inspection.objectType = TransitSchemaObjectTypeEnum.Transit;
            onResult(inspection);
          } else if (reference.lotId?.length > 0 && order.lotInspectionMap) {
            const inspection =
              order.lotInspectionMap[reference.lotId] ||
              (newInspectionForOrder(reference, order) as LotInspection);

            // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
            inspection.objectType = LotSchemaObjectTypeEnum.Lot;
            onResult(inspection);
          }
        }
      },
      objectOrganisationId
    );
  }

  if (reference.lotId) {
    return getLotSnapshot(
      firestoreRef,
      organisationId,
      reference.lotId,
      async (lot) => {
        if (!lot) {
          return;
        }
        const inspections: LotInspection[] = lot.inspections ?? [];
        const inspection = inspections?.find((i) =>
          hasInspectionReference(i, reference)
        );
        // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
        if (!!inspection) inspection.objectType = LotSchemaObjectTypeEnum.Lot;
        onResult(inspection || newInspectionForLot(reference, lot));
      },
      objectOrganisationId,
      supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined
    );
  }
}

export function getProductionSiteInspectionSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  reference: ProductionSiteInspectionReference,
  onResult: (inspection: ProductionSiteInspection | undefined) => any,
  onError?: (err: firebase.firestore.FirestoreError) => any
): () => void {
  let query:
    | firebase.firestore.Query<firebase.firestore.DocumentData>
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
    inspectionColRef(firestoreRef, organisationId);

  Object.entries(reference).forEach(([key, value]) => {
    if ((value ?? '').length) {
      query = query.where(`reference.${key}`, '==', value);
    }
  });

  // Only return the first one (which should be the only one)
  return query.onSnapshot(
    (docs) => {
      const inspection = docs.empty
        ? undefined
        : (docs.docs[0].data() as ProductionSiteInspection);
      onResult(inspection);
    },
    (error) => {
      if (!!onError) {
        onError(error);
      } else {
        throw error;
      }
    }
  );
}

export function getAllProductionSiteInspectionsSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  locationId: string,
  onResult: (inspection: ProductionSiteInspection[]) => any,
  onError?: (err: firebase.firestore.FirestoreError) => any
): () => void {
  return inspectionColRef(firestoreRef, organisationId)
    .where('reference.locationId', '==', locationId)
    .onSnapshot(
      (docs) => {
        const inspections = docs.empty
          ? []
          : docs.docs.map((d) => d.data() as ProductionSiteInspection);
        onResult(inspections);
      },
      (error) => {
        if (!!onError) {
          onError(error);
        } else {
          throw error;
        }
      }
    );
}

export function useGetAllProductionSiteInspections(
  firestore: firebase.firestore.Firestore,
  orgId: string,
  locationId: string
) {
  const [inspections, setInspections] = useState<ProductionSiteInspection[]>([]);
  const [error, setError] = useState<firebase.firestore.FirestoreError>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = getAllProductionSiteInspectionsSnapshot(
      firestore,
      orgId,
      locationId,
      (res) => {
        setInspections(res);
        setLoading(false);
      },
      setError
    );
    return () => unsubscribe();
  }, []);

  return { isLoading, inspections, error };
}

export async function copyLotInspectionsWithinOrder(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  inspection: LotInspection,
  order: Order,
  openInspections: LotInspection[],
  organisation: Organisation
) {
  let copy: LotInspection = cloneDeep(inspection);
  // we need to copy back all dates objects because they are lost in copyObject
  copy.lastModifiedDate = inspection.lastModifiedDate;
  copy.lastSystemDate = inspection.lastSystemDate;
  // keep status as OPEN
  copy.status = 'IN PROGRESS';

  // remove user inputs related to the num of boxes, as it has to be filled by the user
  // TODO: check if this still holds for the new model
  delete copy.userInputs[AG_BOXES_AT_INSPECTION_QUESTION_ID];
  delete copy.userInputs[AG_BOXES_SHIPPED_QUESTION_ID];

  console.log(order, copy);
  return await Promise.all(
    openInspections.map(async (insp) => {
      const pos: LotPosition = (order?.positions ?? []).find(
        (p) => p.lotId === insp.reference.lotId
      );
      const lotProperties: LotProperties = {
        article: insp.lotProperties.article,
        palletIds: pos.palletIds,
        isSplitLot: isSplitLotPosition(pos),
      };

      let inspection: LotInspection = {
        ...copy,
        reference: insp.reference,
        isCopy: true,
        pictures: [],
        lotProperties,
      };

      console.log('-->', inspection);
      return await updateLotOrTransitInspectionInRelatedEntities(
        store,
        profile,
        inspection,
        [],
        organisation
      );
    })
  );

  // // Doing this sequentially instead of paralelly so the report is also properly updated
  // for (const ass of openAssessments) {
  //     let assessment = {
  //       ...copy,
  //       reference: ass.reference,
  //       article: ass.article,
  //       isCopy: true,
  //       pictures: [],
  //       boxesExpected: ass.boxesExpected,
  //     }
  //     console.log('-->', assessment)
  //     await updateAssessmentCache(store, profile, assessment)
  // }
}

export async function uploadInspectionImages(
  storage: firebase.storage.Storage,
  files: FileList,
  profile: UserProfile,
  reference: InspectionReference
) {
  const maxAttachmentSize = 1024 * 1024 * 5; // 5 MB
  let errors: { filename: string; error: string }[] = [];
  const newAttachments: string[] = [];
  const inspectionReferenceId = stringifyInspectionReference(reference);

  function readFileAsync(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  // Uploading files
  for (const file of files) {
    let filename = uuid4();
    const storagePath = `${INSPECTION_IMAGES_STORAGE_BASE_PATH}/${profile.organisationId}/${inspectionReferenceId}/${filename}`;

    try {
      if (file.size > maxAttachmentSize) {
        throw new Error(
          `File too big, must be smaller than ${
            maxAttachmentSize / (1024 * 1024)
          } megabytes`
        );
      }
      const content = await readFileAsync(file);
      await storage.ref().child(storagePath).put(content, {
        // set the content type to ensure the extension triggers the image resize(s)
        contentType: 'image/jpeg',
      });
      console.log('Uploaded:', storagePath);
      newAttachments.push(storagePath);
    } catch (error) {
      console.log(`Error uploading ${storagePath}`, error);
      errors.push({ filename, error });
    }
  }

  return { errors, newAttachments };
}

export async function addInspectionMessage(
  firestoreRef: firebase.firestore.Firestore,
  inspection: Inspection,
  userId: string,
  conversation: Conversation
): Promise<any> {
  return addMessage(firestoreRef, conversation, {
    userId: userId,
    inspection: inspection,
    creationDate: Date.now(),
    type: 'INSPECTION',
  });
}
