import { ApplicationState, ImagesState } from '../Model';
import { isInstalledPwaSession } from '../helpers/HelperPwa';
import { SimpleObservable } from './SimpleObservable';

export const applicationStateObservable = new SimpleObservable<ApplicationState>({
  online: navigator.onLine,
  syncing: false,
  dirty: false,
  newVersionAvailable: false,
  networkEnabled: true,
  updateAvailable: false,
  isInstalledPwaSession: isInstalledPwaSession(),
  isInvitationLink: false,
});

export const imagesStateObservable = new SimpleObservable<ImagesState>({
  syncing: false,
  images: [],
});
