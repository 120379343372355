import firebase from 'firebase/compat/app';
import _ from 'lodash';
import { conditionalPagingCriteria, PagedResult } from './DataStorage';
import { Lot, OrganisationSettings, UserProfile, Todo, Task } from './Model';
import { AGFilters, generateLotSearch } from './SearchService';

type FS = firebase.firestore.Firestore;

export const TodoReorderOptions = [
  { label: 'CUSTOM', value: 'CUSTOM' },
  { label: 'Last Inspection', value: 'dateSinceLastInspection' },
  { label: 'Quality Code', value: 'inputGroupMap.group_condition.score' },
  { label: 'Location', value: 'location' },
];

export const createTodos = async (
  firestore: FS,
  lots: Lot[],
  profile: UserProfile,
  orgSettings: OrganisationSettings
) => {
  return new Promise(async (resolve, reject) => {
    let batch = firestore.batch();

    lots.map((lot, index) => {
      // const id = uuid()
      const action = 'STOCK_INSPECTION';
      const uid = profile.id;
      const id = `${lot.id};${action};${uid}`;
      const docRef = firestore
        .collection('organisation')
        .doc(profile.organisationId)
        .collection('todo')
        .doc(id);

      // const locationReference1 = "20L0" + index
      const locationReference1 = lot.transient.locationReference1;
      const locRef1SearchArray = locationReference1
        ?.split('')
        .map((c, i) => locationReference1.substr(0, i + 1));

      return batch.set(docRef, {
        uid: uid,
        date: new Date().getTime() + index * 100,
        // date: new Date().getTime(),
        // listPosition: new Date().getTime() + (index*100),
        id: id,
        isCompleted: false,
        search: {
          ...generateLotSearch(lot, orgSettings),
          agProductId: lot.article.agProductId,
          locRef1SearchArray: locRef1SearchArray,
        },
        tasks: [
          // prettier-ignore
          {
            lotId: lot.id,
            isCompleted: false,
            dateSinceLastInspection: (
              // @ts-ignore
              lot.transient.freshnessDate.toDate?.() ??
              new Date(lot.transient.freshnessDate)
            ).getTime(),
            action: action,
            // action: !lot.latestAssessment ? "INCOMING_INSPECTION" : 'STOCK_INSPECTION',
            article: lot.article,
            transfers: lot.transfers,
            scores: lot.latestInspection?.scores,
            location: lot.transient.locationId,
            locationReference1: lot.transient.locationReference1 ?? locationReference1,
            locationReference2: lot.transient.locationReference2,
            palletIds: lot.transient.palletIds,
          },
        ] as Task[],
      } as Todo);
    });

    batch
      .commit()
      .then(() => resolve(true))
      .catch(async (err) => reject(err));
  });
};

export const reorderTodos = async (
  firestore: FS,
  reorderBy: string,
  profile: UserProfile
) => {
  return new Promise(async (resolve, reject) => {
    let batch = firestore.batch();
    let todos = (
      await firestore
        .collection('organisation')
        .doc(profile.organisationId)
        .collection('todo')
        .where('uid', '==', profile.id)
        .where('isCompleted', '==', false)
        .get()
    ).docs.map((d) => d.data() as Todo);

    if (reorderBy === 'location') {
      todos = todos.sort((a, b) =>
        (a.tasks[0].location ?? '') + (a.tasks[0].locationReference1 ?? '') <
        (b.tasks[0].location ?? '') + (b.tasks[0].locationReference1 ?? '')
          ? 1
          : -1
      );
    } else {
      todos = todos.sort((a, b) =>
        parseInt(_.get(a.tasks[0], reorderBy) ?? 100) <
        parseInt(_.get(b.tasks[0], reorderBy) ?? 100)
          ? 1
          : -1
      );
    }

    todos.map((todo, index) => {
      const docRef = firestore
        .collection('organisation')
        .doc(profile.organisationId)
        .collection('todo')
        .doc(todo.id);

      return batch.update(docRef, {
        date: new Date().getTime() + index * 100,
      } as Todo);
    });

    batch
      .commit()
      .then(() => console.log('ok'))
      .catch((e) => {
        console.log(e);
        reject(e);
      })
      .finally(() => resolve(true));
  });
};

export function getTodos(
  firestore: firebase.firestore.Firestore,
  organisationId: string,
  pagedResult: PagedResult<Todo> | undefined,
  profile: UserProfile,
  max: number = 20,
  filters: AGFilters = {},
  search: string = '',
  onResult?: Function
): Function {
  let collectionRef = firestore
    .collection('organisation')
    .doc(organisationId)
    .collection('todo')
    .where('uid', '==', profile.id);

  if (search.length > 0) {
    collectionRef = collectionRef.where('search.searchbox', 'array-contains', search);
  } else {
    if (filters.locationReference1) {
      collectionRef = collectionRef.where(
        'search.locRef1SearchArray',
        'array-contains',
        filters.locationReference1
      );
    }

    if (!filters.showCompleted) {
      collectionRef = collectionRef.where('isCompleted', '==', false);
    }

    if (!!filters.locationId) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        filters.locationId
      );
    }

    if (filters.article?.productId?.length > 0) {
      // agProductId
      collectionRef = collectionRef.where(
        'search.agProductId',
        '==',
        filters.article.productId[0].label
      );
    }

    if (!!filters?.qcScore?.value) {
      collectionRef = collectionRef.where(
        'search.scores.' + filters.qcScore.value,
        '==',
        true
      );
    }
  }

  collectionRef = collectionRef.orderBy('date', filters.sort).limit(max);

  collectionRef = conditionalPagingCriteria(pagedResult?.cursor, collectionRef);

  return collectionRef.onSnapshot(
    { includeMetadataChanges: true },
    async (snapshot) => {
      // if (snapshot.metadata.fromCache === false || (snapshot.metadata.fromCache && !isOnline())) {
      const todos: Todo[] = snapshot.docs.map((doc) => doc.data() as Todo);

      if (todos.length > 0) {
        const data: Todo[] = (pagedResult?.data || []).concat(todos);
        // const lots = await Promise.all(data.map(async todo => await Promise.all(todo.tasks.map(async task => {
        //   return await getLot(firestore, organisationId, task.lotId, organisationId)
        // }))))
        const lots = [];

        // console.log('lots', lots.flat())
        return onResult({
          cursor: snapshot.docs[snapshot.docs.length - 1],
          data: data,
          lots: lots.flat(),
        } as PagedResult<Todo>);
      } else {
        return onResult(pagedResult || { cursor: undefined, data: [] });
      }
      // }
    }
  );
}
