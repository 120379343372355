import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';

export const CInspectionToolbar = ({
  collapse = undefined,
  children = null,
  size = undefined,
}) => {
  return (
    <IonHeader collapse={collapse}>
      <IonToolbar>
        {children}
        <IonTitle size={size}>Inspection</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
