import {
  IonButton,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './PageLogin.scss';
import './PageResetPassword.scss';
import logo from './assets/logo.png';
import { auth } from './ConfigFirebase';
import { useHistory } from 'react-router-dom';
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';

interface Props {
  closeModal?: Function;
}

const PageResetPassword = (props: Props) => {
  const history = useHistory();
  const [resetCode, setResetCode] = useState('');
  const [isResetCodeValid, setIsResetCodeValid] = useState(true);
  const [resetCodeError, setResetCodeError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [email, setEmail] = useState('');
  const [mode, setMode] = useState('');
  const [displayForm, setDisplayForm] = useState(false);
  const parameters = new URLSearchParams(window.location.search);

  // setting the mode and oobCode from params
  useEffect(() => {
    getMode();
    getOobCode();
  });

  useEffect(() => {
    if (mode === 'resetPassword') {
      checkIfOobCodeIsValid(resetCode, mode);
      getEmailFromOobCode(resetCode);
    }
  }, [resetCode, mode]);

  useEffect(() => {
    shouldFormBeDisplayed(mode, isResetCodeValid);
  }, [mode, isResetCodeValid]);

  const handleSubmit = async () => {
    const passwordsMatch = checkPasswordsMatch();

    if (!passwordsMatch) return;

    if (mode === 'resetPassword') {
      try {
        if (passwordsMatch) {
          try {
            await confirmPasswordReset(auth, resetCode, password);
            await signInWithEmailAndPassword(auth, email, password);
            history.push('/tabs/quality-control');
          } catch (e) {
            console.log(e);
            window.alert('There was an error resetting your password');
            history.push('/');
          }
        }
      } catch (e) {
        console.log('ERROR: ', e);
      }
    } else {
      try {
        if (passwordsMatch) {
          await updatePassword(auth?.currentUser, password);
          props.closeModal();
        }
      } catch (e) {
        window.alert(e.message);
      }
    }
  };
  const checkPasswordsMatch = (): boolean => {
    let passwordsMatch = false;
    if (password === passwordConfirm) {
      passwordsMatch = true;
    } else {
      window.alert('Passwords do not match');
    }
    return passwordsMatch;
  };

  const handleGoToLogin = async () => {
    await auth.signOut();

    if (history) {
      history.push('/');
    } else {
      window.location.href = '/';
    }
  };

  const getMode = () => {
    const modeParam = parameters.get('mode');
    setMode(modeParam);
    return modeParam;
  };

  const getOobCode = () => {
    const oobCodeParam = parameters.get('oobCode');
    setResetCode(oobCodeParam);
    return oobCodeParam;
  };

  const getEmailFromOobCode = async (oobCode) => {
    try {
      const email = await verifyPasswordResetCode(auth, oobCode);
      setEmail(email);
    } catch (e) {
      console.log(e.message);
    }
  };

  const checkIfOobCodeIsValid = async (oobCode, mode) => {
    if (mode === 'resetPassword') {
      try {
        const code = await verifyPasswordResetCode(auth, oobCode);
        if (code) setIsResetCodeValid(true);
        setResetCodeError('');
        return { isValid: true, errorMessage: '' };
      } catch (e) {
        setIsResetCodeValid(false);
        setResetCodeError(e.message);
        return { isValid: false, errorMessage: e.message };
      }
    }
  };

  const shouldFormBeDisplayed = (mode, isResetCodeValid) => {
    if (mode === 'resetPassword') {
      if (isResetCodeValid) {
        setDisplayForm(true);
      } else {
        setDisplayForm(false);
      }
    } else {
      setDisplayForm(true);
    }
  };

  return (
    <IonPage className="page-reset">
      <IonContent>
        <div className="cover-area-wrapper">
          <div className="cover-area"></div>
        </div>
        <div className="logo-wrapper">
          <img src={logo} alt={'logo'} className="brand-logo" />
        </div>
        <div className="main-area">
          {mode === 'resetPassword' ? (
            <h1 className="ion-text-center">Password Reset</h1>
          ) : (
            <h1 className="ion-text-center">Set Password</h1>
          )}

          <IonGrid className="password-reset-form">
            {displayForm && (
              <>
                <input
                  autoComplete="false"
                  name="hidden"
                  type="text"
                  className="hidden"
                />
                <IonItem className="password-reset-input-group">
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    value={password}
                    type="password"
                    autocomplete="new-password"
                    className="password-reset-input"
                    onIonChange={(e) => setPassword(e.detail.value)}
                  />
                </IonItem>
                <IonItem className="password-reset-input-group">
                  <IonLabel position="floating">Confirm Password</IonLabel>
                  <IonInput
                    value={passwordConfirm}
                    type="password"
                    autocomplete="new-password"
                    className="password-reset-input"
                    onIonChange={(e) => setPasswordConfirm(e.detail.value)}
                  />
                </IonItem>
                <IonButton
                  color={'primary'}
                  className="password-reset-btn"
                  onClick={handleSubmit}
                >
                  Submit
                </IonButton>
                <IonButton
                  color={'default'}
                  fill="clear"
                  className="back-to-login-btn"
                  onClick={handleGoToLogin}
                >
                  Go To Login
                </IonButton>
              </>
            )}
            {mode === 'resetPassword' && !isResetCodeValid && (
              <>
                <IonItem color="danger" style={{ borderRadius: '10px' }}>
                  <div
                    style={{ width: '100%', textAlign: 'center', borderRadius: '10px' }}
                  >
                    {resetCodeError}
                  </div>
                </IonItem>
                <IonButton color={'primary'} onClick={handleGoToLogin}>
                  Go To Login
                </IonButton>
              </>
            )}
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default PageResetPassword;
