import * as Sentry from '@sentry/react';
import { History } from 'history';

const SENTRY_FILTERS: Array<string | RegExp> = [
  'Could not initialize camera correctly',
  'No camera available',
  'IndexedDbTransactionError',
  'Failed to update a ServiceWorker',
  'INTERNAL ASSERTION FAILED',
  'Network Error',
  'Document already exists',
];

export function initSentry(_history: History<unknown>) {
  Sentry.init({
    dsn: 'https://4e7f11ce5e2f4748a5da3d4b43dec870@o4505347056599040.ingest.sentry.io/4505368356847621',
    // TODO: commented out for now, reimplement according to DEV-1724
    // beforeSend(event, _) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     const dialogOptions: Sentry.ReportDialogOptions = {
    //       eventId: event.event_id,
    //       labelName: 'User id',
    //     };
    //     if (auth.currentUser) {
    //       dialogOptions.user = {
    //         email: auth.currentUser.email,
    //         name: auth.currentUser.uid,
    //       };
    //     }
    //     Sentry.showReportDialog(dialogOptions);
    //   }
    //   return event;
    // },
    ignoreErrors: SENTRY_FILTERS,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(_history),
      }),
    ],

    // Capture Replay only on session with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,

    tracesSampleRate: 1.0,
  });
}
