import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { alertCircle, chevronBack, chevronForward } from 'ionicons/icons';
import React from 'react';
import { capitalizeFirstLetter, isMobile } from '../HelperUtils';
import './CWizardNavigation.scss';

export interface NavigationProps<T> {
  sections: T[];
  section: T;
  setSection: (s: T) => void;
  dataTipPrefix: string;

  sectionsWithIssues?: T[];
  disabledSections?: T[];
}

class CWizardNavigation<T> extends React.Component<NavigationProps<T>> {
  renderSectionButtonContent = (section: T) => {
    return isMobile()
      ? this.props.sections.findIndex((s) => s === section) + 1
      : capitalizeFirstLetter(section as unknown as string);
  };

  navigateSection = (increment: number) => {
    const { sections, disabledSections } = this.props;
    const currIndex = sections.findIndex((s) => s === this.props.section);

    const maxIndex = sections.length - 1;
    let newIndex = currIndex + increment;
    while (
      (disabledSections ?? []).includes(sections[newIndex]) &&
      newIndex <= maxIndex &&
      newIndex >= 0
    ) {
      newIndex += increment;
    }

    if (newIndex > maxIndex || newIndex < 0) {
      return;
    }
    this.props.setSection(sections[newIndex]);
  };

  render() {
    return (
      <div className="wizard-navigation-buttons">
        <IonButtons>
          <IonButton onClick={() => this.navigateSection(-1)} color="dark">
            <IonIcon icon={chevronBack} slot="icon-only" />
          </IonButton>
        </IonButtons>

        {this.props.sections.map((s) => {
          const hasIssue = (this.props.sectionsWithIssues ?? []).includes(s);
          const isDisabled = (this.props.disabledSections ?? []).includes(s);
          const sectionSelected = this.props.section === s;

          let color = sectionSelected ? 'tertiary' : 'dark';
          let fill: any = sectionSelected ? 'outline' : 'clear';

          if (hasIssue) {
            color = 'danger';
            // fill = 'outline';
          }

          return (
            <div className="navigation-button" key={s as any}>
              <IonButton
                disabled={isDisabled}
                data-tip={`${this.props.dataTipPrefix}-section-${s}`}
                key={s as unknown as string}
                color={color}
                fill={fill}
                onClick={() => this.props.setSection(s)}
              >
                {this.renderSectionButtonContent(s)}
                {hasIssue && (
                  <IonIcon
                    color="danger"
                    className="issue-icon"
                    icon={alertCircle}
                    slot="start"
                  />
                )}
              </IonButton>
            </div>
          );
        })}

        <IonButtons>
          <IonButton onClick={() => this.navigateSection(+1)} color="dark">
            <IonIcon icon={chevronForward} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </div>
    );
  }
}

export default CWizardNavigation;
