import React from 'react';
import { Report } from './Model';

import { IonBadge } from '@ionic/react';
import { getReportTypeBadgeColor, reportTypeToStringMap } from './DataReport';
import { LegacyInspection, LotInspection, Inspection } from './InspectionModel';

interface Props {
  report?: Report;
  inspection?: Inspection;
}

class ViewReportTypeBadge extends React.Component<Props> {
  render() {
    const { report, inspection } = this.props;

    let color: string;
    let text: string;

    if (!!report) {
      color = getReportTypeBadgeColor(report);
      text = reportTypeToStringMap[report?.reportType];
    } else if (!!inspection) {
      color = getReportTypeBadgeColor({
        reportType: inspection.reference?.type.toUpperCase(),
      } as Report);
      text =
        reportTypeToStringMap[
          (inspection.reference?.type
            ? inspection.reference?.type.toLocaleUpperCase()
            : undefined) ?? 'INCOMING'
        ];
    } else {
      return '';
    }

    return (
      <IonBadge color={color} className="report-type-badge">
        {text}
      </IonBadge>
    );
  }
}

export default ViewReportTypeBadge;
