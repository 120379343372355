/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { DuplicateSectionRequest } from '../model';
// @ts-ignore
import { HTTPValidationError } from '../model';
// @ts-ignore
import { InspectableObjectType } from '../model';
// @ts-ignore
import { ModifiedSection } from '../model';
// @ts-ignore
import { NewSection } from '../model';
// @ts-ignore
import { ResponseGetCreateSectionRequestSectionGetCreateSectionRequestPost } from '../model';
// @ts-ignore
import { SectionList } from '../model';
// @ts-ignore
import { SectionOverviewList } from '../model';
// @ts-ignore
import { SectionResponse } from '../model';
// @ts-ignore
import { SectionToClone } from '../model';
/**
 * SectionApi - axios parameter creator
 * @export
 */
export const SectionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Section
     * @param {NewSection} newSection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSection: async (
      newSection: NewSection,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newSection' is not null or undefined
      assertParamExists('createSection', 'newSection', newSection);
      const localVarPath = `/section/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newSection,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Section
     * @param {string} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSection: async (
      sectionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteSection', 'sectionId', sectionId);
      const localVarPath = `/section/{section_id}`.replace(
        `{${'section_id'}}`,
        encodeURIComponent(String(sectionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Duplicate Section
     * @param {string} sectionId
     * @param {DuplicateSectionRequest} duplicateSectionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateSection: async (
      sectionId: string,
      duplicateSectionRequest: DuplicateSectionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('duplicateSection', 'sectionId', sectionId);
      // verify required parameter 'duplicateSectionRequest' is not null or undefined
      assertParamExists(
        'duplicateSection',
        'duplicateSectionRequest',
        duplicateSectionRequest
      );
      const localVarPath = `/section/{section_id}/duplicate`.replace(
        `{${'section_id'}}`,
        encodeURIComponent(String(sectionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        duplicateSectionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit Section
     * @param {string} sectionId
     * @param {ModifiedSection} modifiedSection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSection: async (
      sectionId: string,
      modifiedSection: ModifiedSection,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('editSection', 'sectionId', sectionId);
      // verify required parameter 'modifiedSection' is not null or undefined
      assertParamExists('editSection', 'modifiedSection', modifiedSection);
      const localVarPath = `/section/{section_id}`.replace(
        `{${'section_id'}}`,
        encodeURIComponent(String(sectionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        modifiedSection,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Create Section Request
     * @param {SectionToClone} sectionToClone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCreateSectionRequest: async (
      sectionToClone: SectionToClone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sectionToClone' is not null or undefined
      assertParamExists('getCreateSectionRequest', 'sectionToClone', sectionToClone);
      const localVarPath = `/section/get_create_section_request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sectionToClone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Section
     * @param {string} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSection: async (
      sectionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getSection', 'sectionId', sectionId);
      const localVarPath = `/section/{section_id}`.replace(
        `{${'section_id'}}`,
        encodeURIComponent(String(sectionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Sections
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSections: async (
      objectType?: InspectableObjectType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/section/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (objectType !== undefined) {
        localVarQueryParameter['object_type'] = objectType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Sections Overview
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSectionsOverview: async (
      objectType?: InspectableObjectType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/section/overview/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (objectType !== undefined) {
        localVarQueryParameter['object_type'] = objectType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Template Overviews
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateOverviews: async (
      objectType?: InspectableObjectType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/sectionTemplate/overview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (objectType !== undefined) {
        localVarQueryParameter['object_type'] = objectType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Section
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importSection: async (
      templateId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists('importSection', 'templateId', templateId);
      const localVarPath = `/sectionTemplate/{template_id}/import`.replace(
        `{${'template_id'}}`,
        encodeURIComponent(String(templateId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SectionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Section
     * @param {NewSection} newSection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSection(
      newSection: NewSection,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSection(
        newSection,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Delete Section
     * @param {string} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSection(
      sectionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSection(
        sectionId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Duplicate Section
     * @param {string} sectionId
     * @param {DuplicateSectionRequest} duplicateSectionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateSection(
      sectionId: string,
      duplicateSectionRequest: DuplicateSectionRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateSection(
        sectionId,
        duplicateSectionRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Edit Section
     * @param {string} sectionId
     * @param {ModifiedSection} modifiedSection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editSection(
      sectionId: string,
      modifiedSection: ModifiedSection,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editSection(
        sectionId,
        modifiedSection,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Create Section Request
     * @param {SectionToClone} sectionToClone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCreateSectionRequest(
      sectionToClone: SectionToClone,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ResponseGetCreateSectionRequestSectionGetCreateSectionRequestPost>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCreateSectionRequest(
        sectionToClone,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Section
     * @param {string} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSection(
      sectionId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSection(
        sectionId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Sections
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSections(
      objectType?: InspectableObjectType,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSections(
        objectType,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Sections Overview
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSectionsOverview(
      objectType?: InspectableObjectType,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionOverviewList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSectionsOverview(
        objectType,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Template Overviews
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTemplateOverviews(
      objectType?: InspectableObjectType,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionOverviewList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateOverviews(
        objectType,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Import Section
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importSection(
      templateId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importSection(
        templateId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SectionApiFp(configuration);
  return {
    /**
     *
     * @summary Create Section
     * @param {NewSection} newSection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSection(
      newSection: NewSection,
      options?: any
    ): AxiosPromise<SectionResponse> {
      return localVarFp
        .createSection(newSection, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Section
     * @param {string} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSection(sectionId: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .deleteSection(sectionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Duplicate Section
     * @param {string} sectionId
     * @param {DuplicateSectionRequest} duplicateSectionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateSection(
      sectionId: string,
      duplicateSectionRequest: DuplicateSectionRequest,
      options?: any
    ): AxiosPromise<SectionResponse> {
      return localVarFp
        .duplicateSection(sectionId, duplicateSectionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Edit Section
     * @param {string} sectionId
     * @param {ModifiedSection} modifiedSection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSection(
      sectionId: string,
      modifiedSection: ModifiedSection,
      options?: any
    ): AxiosPromise<SectionResponse> {
      return localVarFp
        .editSection(sectionId, modifiedSection, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Create Section Request
     * @param {SectionToClone} sectionToClone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCreateSectionRequest(
      sectionToClone: SectionToClone,
      options?: any
    ): AxiosPromise<ResponseGetCreateSectionRequestSectionGetCreateSectionRequestPost> {
      return localVarFp
        .getCreateSectionRequest(sectionToClone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Section
     * @param {string} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSection(sectionId: string, options?: any): AxiosPromise<SectionResponse> {
      return localVarFp
        .getSection(sectionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Sections
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSections(
      objectType?: InspectableObjectType,
      options?: any
    ): AxiosPromise<SectionList> {
      return localVarFp
        .getSections(objectType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Sections Overview
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSectionsOverview(
      objectType?: InspectableObjectType,
      options?: any
    ): AxiosPromise<SectionOverviewList> {
      return localVarFp
        .getSectionsOverview(objectType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Template Overviews
     * @param {InspectableObjectType} [objectType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateOverviews(
      objectType?: InspectableObjectType,
      options?: any
    ): AxiosPromise<SectionOverviewList> {
      return localVarFp
        .getTemplateOverviews(objectType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import Section
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importSection(templateId: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .importSection(templateId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
  /**
   *
   * @summary Create Section
   * @param {NewSection} newSection
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public createSection(newSection: NewSection, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .createSection(newSection, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Section
   * @param {string} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public deleteSection(sectionId: string, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .deleteSection(sectionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Duplicate Section
   * @param {string} sectionId
   * @param {DuplicateSectionRequest} duplicateSectionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public duplicateSection(
    sectionId: string,
    duplicateSectionRequest: DuplicateSectionRequest,
    options?: AxiosRequestConfig
  ) {
    return SectionApiFp(this.configuration)
      .duplicateSection(sectionId, duplicateSectionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit Section
   * @param {string} sectionId
   * @param {ModifiedSection} modifiedSection
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public editSection(
    sectionId: string,
    modifiedSection: ModifiedSection,
    options?: AxiosRequestConfig
  ) {
    return SectionApiFp(this.configuration)
      .editSection(sectionId, modifiedSection, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Create Section Request
   * @param {SectionToClone} sectionToClone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public getCreateSectionRequest(
    sectionToClone: SectionToClone,
    options?: AxiosRequestConfig
  ) {
    return SectionApiFp(this.configuration)
      .getCreateSectionRequest(sectionToClone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Section
   * @param {string} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public getSection(sectionId: string, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .getSection(sectionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Sections
   * @param {InspectableObjectType} [objectType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public getSections(objectType?: InspectableObjectType, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .getSections(objectType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Sections Overview
   * @param {InspectableObjectType} [objectType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public getSectionsOverview(
    objectType?: InspectableObjectType,
    options?: AxiosRequestConfig
  ) {
    return SectionApiFp(this.configuration)
      .getSectionsOverview(objectType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Template Overviews
   * @param {InspectableObjectType} [objectType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public getTemplateOverviews(
    objectType?: InspectableObjectType,
    options?: AxiosRequestConfig
  ) {
    return SectionApiFp(this.configuration)
      .getTemplateOverviews(objectType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Section
   * @param {string} templateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public importSection(templateId: string, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .importSection(templateId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
