const ViewProductName = ({ article = undefined }) => {
  // TODO: return either agProductId or productId depending on Organisation Settings
  return article?.agProductId ?? article?.productId ?? '';

  // // Old code for reference
  // const productList = useContext(ctxProducts)
  // const pId = productId ?? article.productId
  // if (!pId) {
  //   return ''
  // }
  // let p = productList.find(p => p.id === pId)
  // if (p) {
  //   return p.name
  // } else {
  //   return pId
  // }

  // if (article.productName) {
  //   return article.productName
  // }
  // const {productId} = article
  // if (!productId) {
  //   return null
  // }
  // let product: Product | undefined = productList?.find(prod => prod.id === productId);
  // // console.log(product, productList, ctxProducts)
  // return product ? product.name : productId;
};

export default ViewProductName;
