/********** */
// FIREBASE
/********** */
export const FIREBASE_FUNCTIONS_REGION = 'europe-west1';
export const PRODUCTION_ENV_ID = 'agrinorm-connect';
export const INSPECTION_IMAGES_STORAGE_BASE_PATH = 'assessment-images';
export const MAX_FIRESTORE_DOC_SIZE_BYTES: number = 1048576;

/********** */
// FIRESTORE COLLECTIONS
/********** */
export const SUPPLY_CHAIN_COL_NAME = 'supplyChain';
export const ARCHIVED_COL_NAME = 'archived';
export const COMMON_COL_NAME = 'common';

/********** */
// FIRESTORE FIELD PREFIXES
/********** */
export const LOT_INSPECTION_MAP = 'lotInspectionMap';
export const TRANSPORT_INSPECTION_MAP = 'transportInspectionMap';

/********** */
// LOCALSTORAGE KEYS
/********** */
export const CLEAR_CACHE_STORAGE_KEY = 'clear-cache-firestore';

export const DARK_MODE_STORAGE_KEY = 'ag-dark-mode';
export const INLINE_EDITING_STORAGE_KEY = 'ag-inlineEditing';

export const LAST_FILTER_STORAGE_KEY = `lastUsedFilter`;
export const SAVED_FILTERS_STORAGE_KEY = 'savedFilters';

export const DASHBOARD_SORTING_DATE_STORAGE_KEY = 'dashboard-dateProperty';

export const INSPECTION_TIMER_INDEXEDDB_PREFIX = 'inspection_time_';
export const INSPECTION_BACKUP_INDEXEDDB_PREFIX = 'inspection_backup_';

export const APP_CONTEXT_STORAGE_KEY = 'agrinorm_applicationContext';

export const APP_UPDATED_RECENTLY = 'agrinorm_updated_recently';
export const APP_UPDATE_COOLDOWN_MS = 15000;

/********** */
// FILTERS
/********** */
export const LOT_DATE_SORTING_FIELD = 'transient.freshnessDate';
export const ORDER_DATE_SORTING_FIELD = 'fulfilmentDate';
export const REPORT_DATE_SORTING_FIELD = 'lastModifiedDate';
export const FIELD_DATE_SORTING_FIELD = 'latestInspectionPreview.lastModifiedDate';

/********** */
// POSTGRES
/********** */
export const MAX_RETRIES_BEFORE_REPORTING_QUEUE_ISSUE = 20;

/********** */
// APP URLs and PARAM QUERIES
/********** */
export const SUPPLY_CHAIN_URL_SUFFIX = 'supply-chain';
export const SCHEMA_PREFIX_URL_SUFFIX = 'schema-preview';

export const ORDER_BOXES_EXPECTED = 'boxes_expected';
export const ORDER_QC_STATUS = 'qc_order_status';

export const SHARED_FIELD_REPORT_BASE_PATH = 'shared-field-report';

/********** */
// ACCOUNTS
/********** */
export const TEST_ACCOUNT_LIMIT_TIME_IN_DAYS = 14;

/********** */
// OTHER
/********** */
export const NOT_AVAILABLE_STRING = 'N/A';

/********** */
// INSPECTION
/********** */
export const MAX_BADGE_TEXT_SIZE: number = 20;
