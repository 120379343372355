export interface OrderStatusSubscription {
  value: StatusSubscription;
  label: string;
  checkBoxLabel: string;
  emailLabel: string;
}

export type StatusSubscription = 'DONE' | 'CHECKED';

// OderStatusSubscriptionOptions controls the statuses that the user can subscribe to in
// the ViewSubscriptionSelector.tsx

export const OderStatusSubscriptionOptions: OrderStatusSubscription[] = [
  {
    value: 'DONE',
    label: 'Done',
    checkBoxLabel: 'Inspections are DONE',
    emailLabel: 'Completed',
  },
  {
    value: 'CHECKED',
    label: 'Checked',
    checkBoxLabel: 'Report can be SHARED',
    emailLabel: 'Checked',
  },
];

export const userRolesAllowedToSubscribe = 'COMMERCIAL';
