import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  //IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonSpinner, IonTitle, IonToolbar,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
// import {closeOutline} from "ionicons/icons";
import React, { RefObject } from 'react';
// import Select from "react-select";
// import {ApplicationContext, UserProfile} from "./Model";
import './ComponentPartnersAdmin.scss';
// import i18n from "./ServiceI18n";
import { firestore } from './ConfigFirebase';
import { Contact, Invite, User, UserProfile } from './Model';

import { alertController } from '@ionic/core';
import { toastController } from '@ionic/core';
import {
  contactColRef,
  issueInviteForOrganisation,
  // saveContact
} from './DataStorage';
import {
  deleteOrganizationContact,
  deleteOrganizationInvite,
  updateOrganizationContact,
} from './DataAdmin';
import { findIndex } from 'lodash';
import { closeOutline, trashOutline } from 'ionicons/icons';
import Select from 'react-select';
import { ISO3166 } from './HelperInsight';
interface Props {
  // organisationId: string;
  partners: Contact[];
  profile: UserProfile;
}

interface State {
  inited: true;
  search: string;
  modal: boolean;
  page: number;
}

class ComponentPartnersAdmin extends React.Component<Props, State> {
  private nameInput: RefObject<HTMLIonInputElement> = React.createRef();
  private idInput: RefObject<HTMLIonInputElement> = React.createRef();
  private select: RefObject<any> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      inited: true,
      search: '',
      modal: false,
      page: 0,
    };
  }

  componentDidMount() {}
  componentWillUnmount() {
    console.log('ComponentPartnersAdmin will unmount');
  }

  async addPartner() {
    const name = this.nameInput.current.value as string;
    const id = this.idInput.current.value as string;
    const select = this.select.current;
    const type = select.state.value?.map((v) => v.value) ?? [];
    // let contactPayload: ContactUpdatePayload
    // saveContact(firestore, this.props.profile, )

    if (!name) return alert('name cannot be empty');
    if (!(type.length > 0)) return alert('select at least one type');

    const partnerRef = contactColRef(firestore, this.props.profile.organisationId).doc(
      id
    );

    const partnerSnapshot = await partnerRef.get();

    if (partnerSnapshot.exists) {
      return alert(`Partner with ID: ${id} already exists!`);
    } else {
      await partnerRef.set({
        name,
        id,
        type,
        users: [],
      });
    }
    this.setState({ modal: false });

    const toast = await toastController.create({
      message: 'Partner Created',
      position: 'top',
      color: 'dark',
      duration: 3000,
      buttons: [
        {
          text: 'Got it',
          role: 'cancel',
        },
      ],
    });

    toast.present().then();
  }

  async inviteUser(contact) {
    const alert = await alertController.create({
      cssClass: 'alert-invite-partner',
      header: 'Invite partner to Agrinorm',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email from partner',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Ok',
          handler: async (data) => {
            const toast = await toastController.create({
              message: data.email + ' has been invited to collaborate',
              position: 'top',
              color: 'dark',
              duration: 3000,
              buttons: [
                {
                  text: 'Ok',
                  role: 'cancel',
                },
              ],
            });

            toast.present().then();
            await issueInviteForOrganisation(
              firestore,
              this.props.profile,
              data.email,
              contact,
              window.location.hostname
            );
          },
        },
      ],
    });

    await alert.present();
  }

  handleSelectOnChange = async (contactId, attribute, selectData) => {
    if (!selectData) selectData = [];
    let data = selectData.map((data) => data.value);
    console.log(this.props.profile.organisationId, contactId, attribute, data);

    if (attribute === 'countryCode') data = data[0];

    const res = await updateOrganizationContact(
      firestore,
      this.props.profile.organisationId,
      contactId,
      attribute,
      data
    );

    // Remove GGN if it's not a GROWER anymore
    if (attribute === 'type' && !data.includes('GROWER')) {
      await updateOrganizationContact(
        firestore,
        this.props.profile.organisationId,
        contactId,
        'ggn',
        null
      );
    }
  };

  handleDeleteContact = async (contactId) => {
    const text = `Are you sure you want to delete contact ${
      this.props.partners.find((c) => c.id === contactId)?.name ?? contactId
    } (id ${contactId})}? This action cannot be undone`;
    if (window.confirm(text)) {
      await deleteOrganizationContact(
        firestore,
        this.props.profile.organisationId,
        contactId
      );
    }
  };

  handleInputOnChange = async (contactId, attribute, data) => {
    if (attribute === 'name') {
      const partnerNameExists =
        findIndex(
          this.props.partners,
          (partner) => partner.name === data.detail.value
        ) !== -1;
      if (partnerNameExists) {
        return alert(`Partner with name ${data.detail.value} already exists.`);
      } else {
        await updateOrganizationContact(
          firestore,
          this.props.profile.organisationId,
          contactId,
          attribute,
          data.detail.value
        );
      }
    } else {
      await updateOrganizationContact(
        firestore,
        this.props.profile.organisationId,
        contactId,
        attribute,
        data.detail.value
      );
    }
  };

  handleRemoveInvite = async (inviteId, acceptingEmail) => {
    const confirmation = window.confirm(
      `Are you sure you would like to delete the invitation for ${acceptingEmail}`
    );
    if (!confirmation) return;
    await deleteOrganizationInvite(firestore, inviteId);
  };

  debounce(func, timeout = 200) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  renderPaging(list) {
    if (!list || list.length <= 60) {
      return;
    }
    return (
      <div className="paging">
        {[...Array(Math.ceil(list.length / 60))].map((v, i) => (
          <div
            key={i}
            onClick={(_) => this.setState({ page: i })}
            className={i === this.state.page ? 'current' : ''}
          >
            {i + 1}
          </div>
        ))}
      </div>
    );
  }

  render() {
    // TODO: uncomment when ready to release
    //return <IonItem>Partners - coming soon</IonItem>

    console.log('partners', this.props.partners);
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        color: '#1c1c1c',
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
    const opts = ['GROWER', 'BUYER', 'SELLER', 'LOGISTICS'].map((o) => {
      return { value: o, label: o };
    });
    const countryOpts = Object.entries(ISO3166).map((country) => {
      return { value: country[0], label: `${country[1]} - ${country[0]}` };
    });
    const countryOptsSorted = countryOpts.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    const filteredPartners = this.props.partners
      ?.sort((a, b) =>
        a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : 0
      )
      ?.filter((c) =>
        this.state.search.length > 0
          ? c.name.toLocaleLowerCase().includes(this.state.search)
          : true
      );

    return (
      <div className="component-partners-admin">
        <div className="top-toolbar">
          <div>
            <IonSearchbar
              mode="ios"
              onIonChange={(ev) =>
                this.setState({ page: 0, search: ev.detail.value?.toLocaleLowerCase() })
              }
            />
          </div>
          <strong>Total Partners ({this.props.partners?.length ?? 0})</strong>
        </div>

        {this.renderPaging(filteredPartners)}

        <div className="table-list align-top double-row">
          <div className="list-header">
            <div>
              <div className="name">Name</div>
              <div>Type</div>
              <div className="users">Users</div>
              <div className="actions">&nbsp;</div>
            </div>
          </div>
          {!this.props.partners && <IonSpinner name="dots" />}
          {filteredPartners
            ?.slice(this.state.page * 60, this.state.page * 60 + 60)
            .map((contact: Contact) => {
              const type =
                contact.type?.map((o) => {
                  return { value: o, label: o };
                }) ?? [];
              const country = countryOpts.find((c) => c.value === contact.countryCode);

              return (
                <div key={contact.id} className="">
                  <div>
                    <div className="name">
                      <IonInput
                        className="partners-input"
                        value={contact.name}
                        onIonChange={(e) =>
                          this.debounce(this.handleInputOnChange(contact.id, 'name', e))
                        }
                      />
                      {!!contact.organisation && (
                        <div className="ag-id">AG ID: {contact.organisation.id}</div>
                      )}
                    </div>

                    <div className="item-selectable">
                      <Select
                        options={opts}
                        classNamePrefix={'react-select'}
                        defaultValue={type}
                        onChange={(e) =>
                          this.handleSelectOnChange(contact.id, 'type', e)
                        }
                        isMulti
                        isClearable={false}
                        menuPortalTarget={window.document.body}
                        styles={customStyles}
                      />
                    </div>
                    <div className="users">
                      {contact.users?.map((user: User) => (
                        <div key={user.id}>{user.email}</div>
                      ))}
                      {(!contact.users || contact.users.length === 0) && 'no users'}
                    </div>

                    <div className="actions">
                      {/*Actions are disabled for now*/}
                      <span
                        title="remove partner"
                        className="action-button delete"
                        onClick={(e) => this.handleDeleteContact(contact.id)}
                      >
                        <IonIcon icon={trashOutline} />
                      </span>
                      {/* <span title="invite partner" className="action-button" onClick={() => this.inviteUser(contact) }>
                     <IonIcon icon={personAddOutline} />
                    </span> */}
                    </div>
                  </div>

                  <div
                    className="view-more"
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('less');
                      // @ts-ignore
                      e.currentTarget.nextSibling.classList?.toggle('visible');
                    }}
                  >
                    view more
                  </div>

                  <div className="more">
                    <div className="name">
                      <IonLabel>ID</IonLabel>
                      <div className={'id-value'}>{contact.id}</div>
                    </div>
                    <div className="name">
                      <IonLabel>Country</IonLabel>
                      <Select
                        options={countryOptsSorted}
                        classNamePrefix={'react-select'}
                        defaultValue={country}
                        onChange={(e) =>
                          this.handleSelectOnChange(contact.id, 'countryCode', [e])
                        }
                        isClearable={false}
                        menuPortalTarget={window.document.body}
                        styles={customStyles}
                      />
                    </div>
                    <div className="name">
                      <IonLabel>Address</IonLabel>
                      <IonInput
                        className="partners-input"
                        value={contact.address}
                        debounce={500}
                        onIonChange={(e) =>
                          this.handleInputOnChange(contact.id, 'address', e)
                        }
                      />
                    </div>
                    {(contact.type ?? []).includes('GROWER') && (
                      <div className="name">
                        <IonLabel>GGN</IonLabel>
                        <IonInput
                          className="partners-input"
                          value={contact.ggn}
                          debounce={500}
                          onIonChange={(e) =>
                            this.handleInputOnChange(contact.id, 'ggn', e)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <IonButton
          slot="fixed"
          color="tertiary"
          className="main-button"
          onClick={(_) => this.setState({ modal: true })}
        >
          Add Partner
        </IonButton>

        <IonModal isOpen={this.state.modal || false}>
          <IonHeader className={''}>
            <IonToolbar>
              <IonTitle>Add Partner</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={(_) => this.setState({ modal: false })}>
                  <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="add-modal">
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
              }}
            >
              <IonList>
                <IonItem>
                  <IonLabel>Name</IonLabel>
                  <IonInput ref={this.nameInput} />
                </IonItem>
                <IonItem>
                  <IonLabel>ID</IonLabel>
                  <IonInput ref={this.idInput} />
                </IonItem>
                <IonItem className="select-input">
                  <IonLabel>Type</IonLabel>
                  <Select
                    options={opts}
                    isMulti
                    ref={this.select}
                    classNamePrefix={'react-select'}
                  />
                </IonItem>
                {false && (
                  <IonItem lines="none">
                    <IonLabel>Remember me</IonLabel>
                    <IonCheckbox defaultChecked={true} slot="start" />
                  </IonItem>
                )}
              </IonList>
              <div className="main-btn">
                <IonButton onClick={(_) => this.addPartner()}>Add</IonButton>
              </div>
            </form>
          </IonContent>
        </IonModal>
      </div>
    );
  }
}

export default ComponentPartnersAdmin;
