import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import {
  arrowBackOutline,
  arrowForwardOutline,
  attachOutline,
  closeCircleOutline,
  closeOutline,
  documentAttachOutline,
  linkOutline,
  trashOutline,
} from 'ionicons/icons';
import React, { RefObject } from 'react';
import Select from 'react-select';
import { firestore, storage } from './ConfigFirebase';
import { PagedResult, uploadOrderOrReportAttachments } from './DataStorage';
import {
  Lot,
  Order,
  OrderType,
  Picture,
  Report,
  ReportReference,
  ReportType,
  UserProfile,
} from './Model';
import './PageNewLot.scss';
import './PageNewReport.scss';

import _, { cloneDeep, isEqual } from 'lodash';
import { ctxContacts } from './App';
import CardLot from './CardLot';
import { getOrders } from './DataApp';
import {
  buildOrderlessReportId,
  buildReportReference,
  buildReportSubject,
  deleteAttachmentFromStorage,
  supportedReportTypeMap,
} from './DataReport';
import withContext, { ContextProps } from './HOC/withContext';
import {
  formatDate,
  lotsToPositions,
  removeSpecialChars,
  standardizeDate,
} from './HelperUtils';
import { LotInspection, LegacyInspectionReference } from './InspectionModel';
import { QCFilterType } from './ModelAGTabs';
import { generateReportSearch } from './SearchService';
import { MAX_FIRESTORE_DOC_SIZE_BYTES } from './GlobalConstants';
import { presentStandardToast } from './HelperIonic';
import { toastController } from '@ionic/core';
interface Props {
  onCancel: () => any;
  onSave: (report: Report) => any;
  type: OrderType;
  multipleSelectionArray: Array<Lot>;
  section: QCFilterType;
}

interface State {
  // order: OrderCreatePayload;
  report: Report;
  search: string;
  selectedOrderId?: string;
  selectedOrder?: Order;
  selectedLots?: Array<Lot>;
  lots?: PagedResult<Lot>;
  orders?: PagedResult<Order>;
  loading?: boolean;
  ionloading?: boolean;
  attachmentUploading?: boolean;
  attachmentDeleting?: boolean;
  step: number;
}

class PageNewReport extends React.Component<Props & ContextProps, State> {
  private unsubscribe;
  private nameInput: RefObject<HTMLIonInputElement> = React.createRef();
  private supplierSelect: RefObject<HTMLIonSelectElement> = React.createRef();
  private orderIdInput: RefObject<HTMLIonInputElement> = React.createRef();
  private fileInput: RefObject<HTMLInputElement> = React.createRef();
  private commentsInput: RefObject<HTMLTextAreaElement> = React.createRef();

  constructor(props: Props & ContextProps) {
    super(props);

    const { multipleSelectionArray, profile, section } = props;

    const reportReference = {} as ReportReference;

    const lotMap = _.cloneDeep(multipleSelectionArray);
    // const lotMap = multipleSelectionArray

    // take the contactId of the first lot as the suggested report contactId
    const contactId: string = multipleSelectionArray[0]?.suppliedByContactId;
    const contactName: string = this.props.contacts.find(
      (c) => c.id === contactId
    )?.name;

    // when coming from the context of BATCHES, we skip the first step
    // const step: number = (section === 'BATCHES') ? 2 : 1;
    const reportType: ReportType = section === 'BATCHES' ? 'INCOMING' : 'UPCOMING';

    this.state = {
      step: 1,
      report: {
        id: buildOrderlessReportId(profile.organisationId),
        orgId: profile.organisationId,
        positions: lotsToPositions(lotMap),
        lotInspectionMap: this.lotsToInspectionMap(lotMap),
        reportType,
        reportReference,
        contactId,
        contactName,
        lotMap,
      },
      selectedLots: lotMap,
      search: '',
      attachmentUploading: false,
      attachmentDeleting: false,
    };
  }

  async onSave() {
    const { report: r, selectedOrder, selectedOrderId } = this.state;
    const { profile } = this.props;

    const report: Report = cloneDeep(r);

    const date = new Date();
    report.lastModifiedDate = date;
    report.lastSystemDate = date;

    report.createdByUserId = profile.id;
    report.lastModifiedUserId = profile.id;
    report.lastWriteSource = 'UI';
    report.createdByOrgId = profile.organisationId;
    report.orgId = profile.organisationId;
    report.reportStatus = 'CREATED';
    report.autogenFromOrder = false;

    if (report.inspectionAggregation === 'batch_summaries') {
      report.lotInspectionMap = undefined;
    } else {
      report.lotMap = undefined;
    }

    report.comments = this.commentsInput.current.value.trim();

    report.reportReference = buildReportReference(
      report,
      profile,
      selectedOrder ?? ({ id: selectedOrderId } as Order),
      undefined,
      undefined,
      undefined,
      !!selectedOrder
    );
    report.search = generateReportSearch(
      report,
      selectedOrder,
      this.props.organisation?.settings
    );

    if (!!selectedOrder) {
      report.transport = selectedOrder.transport;
      report.transportInspectionMap = selectedOrder.transportInspectionMap;
      report.externalReference = selectedOrder.externalReference;
      report.estimatedFulfilmentDate = standardizeDate(selectedOrder.fulfilmentDate);
    }

    const reportSize: number = JSON.stringify(report).length;
    if (reportSize > MAX_FIRESTORE_DOC_SIZE_BYTES) {
      return await presentStandardToast(
        toastController,
        `Report size is too big. Please remove some inspections and try again`
      );
    }

    this.setState({ ionloading: true });
    console.log('saving report', report);
    await this.props.onSave(report);
    this.setState({ ionloading: false });
  }

  componentDidMount() {
    setTimeout((_) => this.nameInput.current.setFocus(), 100);
  }

  ionViewDidEnter() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { selectedLots, report } = this.state;

    if (!isEqual(prevState.report, report)) {
      console.log('Preliminary report:', report);
    }

    if (selectedLots.length !== prevState.selectedLots.length) {
      const report: Report = {
        ...this.state.report,
        positions: lotsToPositions(selectedLots),
        lotInspectionMap: this.lotsToInspectionMap(selectedLots),
      };
      this.setState({ report });
    }
  }

  lotsToInspectionMap(lots: Lot[]) {
    return lots.reduce(
      (acc: { [lotId: string]: LotInspection }, lot: Lot) => ({
        ...acc,
        [lot.id]: lot.latestInspection,
      }),
      {}
    );
  }

  async searchOrders(search: string) {
    if (this.unsubscribe) {
      // unsubscribe
      this.unsubscribe();
    }
    if (!search) {
      this.setState({ loading: false, orders: undefined, search: '' });
      return;
    }
    this.setState({ loading: true, orders: undefined, search: search });
    this.unsubscribe = getOrders(
      firestore,
      this.props.profile.organisationId,
      search,
      undefined,
      undefined,
      20,
      {},
      this.props.profile.organisationId,
      (result) => {
        console.log('result', result);
        this.setState({
          orders: result,
          loading: false,
        });
      }
    );
  }

  removeLot(lot: Lot) {
    const selectedLots = this.state.selectedLots.filter((l) => lot.id !== l.id);
    this.setState({
      selectedLots,
      report: {
        ...this.state.report,
        lotMap: selectedLots,
      },
    });
  }

  getSelectedOrder(orders: PagedResult<Order>) {
    return orders?.data.find((order) => order.id === this.state.selectedOrderId);
  }

  async step2() {
    let { report, selectedOrderId, selectedOrder } = cloneDeep(this.state);

    report.autogenFromOrder = false;

    if (!!selectedOrder) {
      report = {
        ...report,
        estimatedFulfilmentDate: standardizeDate(selectedOrder.fulfilmentDate),
      };

      if (selectedOrder.type === 'BUY') {
        report.contactId = selectedOrder.contactId;
      }
    }

    if (!report.reportSubject && !!selectedOrderId) {
      report.reportSubject = buildReportSubject(report, selectedOrderId);
    }

    this.setState(
      {
        step: 2,
        report: report,
      },
      () => console.log('STEP 2', report)
    );
  }

  renderAttachmentSection(report: Report) {
    return (
      <>
        <IonItem>
          File Attachments
          <IonButton
            onClick={() => this.fileInput?.current?.click()}
            slot="end"
            color="tertiary"
            size="default"
          >
            <IonIcon
              icon={attachOutline}
              style={{ marginRight: '10px' }}
              slot="start"
            />
            Upload
          </IonButton>
        </IonItem>
        {(report?.attachments ?? []).length > 0 &&
          report.attachments.map((attachment) => {
            const [filename] = attachment.split('/').slice(-1);
            return (
              <IonItem
                key={attachment}
                button
                onClick={async () => await this.onDownloadAttachment(attachment)}
              >
                <IonIcon icon={documentAttachOutline} style={{ marginRight: '10px' }} />
                {filename}
                <IonButton
                  disabled={!navigator.onLine}
                  fill="clear"
                  color="danger"
                  slot="end"
                  onClick={(e) => this.onDeleteAttachment(e, attachment)}
                >
                  <IonIcon icon={trashOutline} />
                </IonButton>
              </IonItem>
            );
          })}
      </>
    );
  }

  async onDownloadAttachment(path: string) {
    try {
      const downloadURL = await storage.ref().child(path).getDownloadURL();
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('type', 'hidden');
      downloadLink.href = downloadURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      alert('Document could not be downloaded');
      console.log(e);
    }
  }

  async onDeleteAttachment(
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    path: string
  ) {
    e.stopPropagation();

    const { report } = this.state;

    this.setState({ attachmentDeleting: true });

    return deleteAttachmentFromStorage(storage, path)
      .then((_) =>
        this.setState({
          attachmentDeleting: false,
          report: {
            ...report,
            attachments: report.attachments.filter((a) => a !== path),
          },
        })
      )
      .catch((error) => {
        alert(`Something went wrong when deleting the attachment`);
        console.log(`Error updating report`, error);
        this.setState({ attachmentDeleting: false });
      });
  }

  async uploadAttachments(
    event: React.ChangeEvent<HTMLInputElement>,
    profile: UserProfile,
    report: Report
  ) {
    const files = event.target.files;
    const currentAttachments: string[] = report.attachments ?? [];

    this.setState({ attachmentUploading: true });

    // upload files
    let { errors, newAttachments } = await uploadOrderOrReportAttachments(
      storage,
      files,
      profile,
      undefined,
      report
    );

    // Update report
    const mergedAttachments: string[] = [
      ...new Set([...currentAttachments, ...newAttachments]),
    ];

    if (errors.length > 0) {
      alert(
        `Following files could not be uploaded:\r\n${errors
          .map((e) => `File: ${e.filename}, ${e.error}`)
          .join('\r\n')}`
      );
    }

    this.setState({
      attachmentUploading: false,
      report: { ...report, attachments: mergedAttachments },
    });
  }

  disablecreateReport(): boolean {
    const { selectedLots, report } = this.state;
    // const requiredFields = ['contactId'];
    const requiredFields = [];
    return (
      (selectedLots ?? []).length === 0 ||
      requiredFields.reduce(
        (acc: boolean, field: string) => acc || report[field] == null,
        false
      )
    );
  }

  onPicturesUpdated(
    allPictures: Picture[],
    affectedPictures: Picture[],
    assessmentReference: LegacyInspectionReference
  ) {
    console.log(allPictures, affectedPictures, assessmentReference);
    const report = this.state.report;
    report.lotInspectionMap[assessmentReference.lotId].pictures = allPictures;
    this.setState({ report });
  }

  onPicturesUpdatedSummary(allPictures: Picture[], affectedPictures: Picture[], lotId) {
    console.log(allPictures, affectedPictures);
    const report = this.state.report;
    const index = report.lotMap.findIndex((lot) => lot.id === lotId);
    const lot = report.lotMap[index];

    lot.inspections.map((inspection) => {
      return (inspection.pictures = allPictures.filter((pic) =>
        _.isEqual(pic.assessmentReference, inspection.reference)
      ));
    });
    lot.latestInspection.pictures = allPictures.filter((pic) =>
      _.isEqual(pic.assessmentReference, lot.latestInspection.reference)
    );

    this.setState({ report });
  }

  render() {
    let {
      orders,
      loading,
      ionloading,
      attachmentUploading,
      attachmentDeleting,
      selectedLots,
      search,
      selectedOrder,
      selectedOrderId,
      step,
      report,
    } = this.state;
    const { profile, section } = this.props;

    console.log('selectedOrderId', selectedOrderId, selectedOrder);

    // For now, we only allow for UPCOMING or INCOMING, depending on the section
    const reportTypeOptions = Object.entries(supportedReportTypeMap)
      .map(([value, label]) => ({ value, label }))
      .filter((o) => {
        switch (section) {
          case 'SUPPLY-CHAIN':
            return o.value === 'UPCOMING';
          default:
            return o.value !== 'UPCOMING';
        }
      });

    const inspectionAggregationOptions = [
      { value: 'latest_inspection', label: 'Latest Inspection' },
      { value: 'first_inspection', label: 'First Inspection' },
      { value: 'batch_summaries', label: 'All inspections' },
    ];

    let classNameLotWrapper = `selected-lots ${
      !selectedLots?.length ? 'empty-lots' : ''
    } `;
    if (report.hideDefects) {
      classNameLotWrapper += ' hide-defects';
    }
    if (report.hideScores) {
      classNameLotWrapper += ' hide-scores';
    }
    if (report.hideInspector) {
      classNameLotWrapper += ' hide-inspector';
    }
    if (report.hideQuantity) {
      classNameLotWrapper += ' hide-quantity';
    }

    return (
      <div className="ion-page page-new-report">
        <IonLoading
          isOpen={attachmentUploading || attachmentDeleting}
          message={attachmentDeleting ? `Deleting attachment...` : `Uploading files...`}
        />
        <IonLoading isOpen={ionloading} />
        <input
          ref={this.fileInput}
          hidden
          multiple
          type="file"
          accept=".pdf, .doc*, .xls*, .ppt*"
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) =>
            await this.uploadAttachments(e, profile, report)
          }
        />
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              New {supportedReportTypeMap[report.reportType] ?? ''} Report:{' '}
              {report.reportName}
            </IonTitle>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={() => this.props.onCancel()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="wrapper">
            <div className={classNameLotWrapper}>
              {report.reportSubject && (
                <IonListHeader>
                  <h1>{report.reportSubject}</h1>
                </IonListHeader>
              )}
              {!!selectedLots?.length && (
                <div className="pictures-hint">
                  <b>HINT:</b> you can click on the gallery pictures to remove specific
                  pictures from the report
                </div>
              )}
              <div className="lots-list">
                {(selectedLots ?? []).map((lot: Lot, key) => {
                  const props = {
                    lot,
                    showSummary:
                      this.state.report.inspectionAggregation === 'batch_summaries',
                    showGalleryModal: true,
                    allowGalleryEdit: true,
                    onPicturesUpdated: (
                      allPictures,
                      affectedPictures,
                      assessmentReference
                    ) => {
                      if (report.inspectionAggregation === 'batch_summaries') {
                        this.onPicturesUpdatedSummary(
                          allPictures,
                          affectedPictures,
                          lot.id
                        );
                      } else {
                        this.onPicturesUpdated(
                          allPictures,
                          affectedPictures,
                          assessmentReference
                        );
                      }
                    },
                  };

                  return (
                    <div
                      className="card-wrapper"
                      key={lot.id + this.state.report.inspectionAggregation}
                    >
                      <IonIcon
                        icon={closeCircleOutline}
                        className="close-icon"
                        onClick={() => this.removeLot(lot)}
                      />
                      <CardLot {...props} />
                    </div>
                  );
                })}
                {!selectedLots?.length && (
                  <div className="no-lots-message">
                    <div>No batches selected</div>
                    <IonButton
                      color="medium"
                      fill="outline"
                      onClick={() => this.props.onCancel()}
                    >
                      <IonIcon icon={closeOutline} /> Close
                    </IonButton>
                  </div>
                )}
              </div>

              {step === 1 && (
                <div className="buttons fixed">
                  <IonButton
                    color="primary"
                    data-tip={'pnr-next-step'}
                    onClick={(_) => this.step2()}
                  >
                    <IonIcon icon={arrowForwardOutline} slot="start" /> next step
                  </IonButton>
                </div>
              )}

              {step === 2 && (
                <div className="buttons fixed">
                  <IonButton color="white" onClick={(_) => this.setState({ step: 1 })}>
                    <IonIcon icon={arrowBackOutline} slot="start" /> previous step
                  </IonButton>
                  <IonButton
                    data-tip={'pnr-create-report'}
                    color="primary"
                    onClick={() => this.onSave()}
                    disabled={this.disablecreateReport()}
                  >
                    Create Report
                  </IonButton>
                </div>
              )}
            </div>

            <IonList className={`form-list step1 ${step === 1 ? 'visible' : 'hidden'}`}>
              <IonItem className="select-item z3">
                <IonLabel>Report Type</IonLabel>
                <Select
                  classNamePrefix={'react-select'}
                  options={reportTypeOptions}
                  defaultValue={reportTypeOptions[0]}
                  onChange={(evt) =>
                    this.setState({
                      report: { ...report, reportType: evt.value as ReportType },
                    })
                  }
                  isClearable={false}
                />
              </IonItem>

              <IonItem>
                <IonLabel>Report Name</IonLabel>
                <IonInput
                  ref={this.nameInput}
                  debounce={100}
                  data-tip={'pnr-report-name'}
                  placeholder="name your report (optional)"
                  onIonChange={(evt) =>
                    this.setState({
                      report: {
                        ...report,
                        reportName:
                          evt.detail.value.length > 0 ? evt.detail.value : undefined,
                      },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Report Subject</IonLabel>
                <IonInput
                  debounce={100}
                  data-tip={'pnr-report-subject'}
                  placeholder="subject of your report (optional)"
                  onIonChange={(evt) =>
                    this.setState({
                      report: {
                        ...report,
                        reportSubject:
                          evt.detail.value.length > 0 ? evt.detail.value : undefined,
                      },
                    })
                  }
                />
              </IonItem>

              {selectedOrderId !== undefined &&
                ['INCOMING', 'QUALITY_EVOLUTION', 'UPCOMING'].includes(
                  report.reportType
                ) && (
                  <IonItem className="input-selected-order-id">
                    <IonLabel>Order ID</IonLabel>
                    <IonText className="label-order-id">
                      {!!selectedOrder && <IonIcon icon={linkOutline} />}
                      <b>{selectedOrderId ? selectedOrderId : 'not defined'}</b>
                    </IonText>
                    <IonButton
                      color="tertiary"
                      fill="clear"
                      onClick={(_) =>
                        this.setState({
                          selectedOrderId: undefined,
                          selectedOrder: undefined,
                        })
                      }
                    >
                      change
                    </IonButton>
                  </IonItem>
                )}

              {selectedOrderId === undefined &&
                ['INCOMING', 'QUALITY_EVOLUTION', 'UPCOMING'].includes(
                  report.reportType
                ) && (
                  <>
                    <div className="form-title">Is it associated with an order?</div>
                    <IonItem className="input-order-id">
                      <IonInput
                        placeholder="please type the order ID here"
                        debounce={300}
                        ref={this.orderIdInput}
                        onIonChange={(ev: any) => {
                          const formattedOrderId = removeSpecialChars(
                            ev.target.value
                          ).toUpperCase();
                          this.orderIdInput.current.value = formattedOrderId;
                          this.setState({
                            search: formattedOrderId,
                          });
                          this.searchOrders(formattedOrderId);
                        }}
                      />
                    </IonItem>

                    <div className="buttons">
                      {!!search && (
                        <IonButton
                          color="primary"
                          onClick={(_) =>
                            this.setState({
                              selectedOrderId: search,
                              selectedOrder: undefined,
                              search: '',
                              loading: false,
                            })
                          }
                        >
                          Use {search}
                        </IonButton>
                      )}
                      <IonButton
                        color="medium"
                        fill="outline"
                        data-tip={'pnr-no-order-button'}
                        onClick={(_) =>
                          this.setState({
                            selectedOrderId: null,
                            search: '',
                            loading: false,
                          })
                        }
                      >
                        No, it isn't
                      </IonButton>
                    </div>

                    <div className="orders">
                      <IonRadioGroup
                        onIonChange={(ev) => {
                          console.log(ev.detail.value);
                          this.setState({
                            selectedOrderId: ev.detail.value.id,
                            selectedOrder: ev.detail.value,
                          });
                        }}
                      >
                        {!!loading && <IonSpinner name="dots" />}
                        {orders?.data
                          .filter((order) => order.id.indexOf(search) > -1)
                          .map((order, key) => (
                            <IonItem key={key}>
                              <IonRadio slot="start" value={order} />
                              <IonLabel>
                                <div>
                                  <b>{order.id}</b>
                                </div>
                                <div>
                                  <IonBadge color="medium">
                                    {formatDate(order.fulfilmentDate, {
                                      dateStyle: 'short',
                                    })}
                                  </IonBadge>
                                  <span> ({order.positions.length} batches)</span>
                                </div>
                              </IonLabel>
                            </IonItem>
                          ))}
                      </IonRadioGroup>
                      {!loading && orders?.data.length === 0 && (
                        <div style={{ textAlign: 'center' }}>
                          <span>
                            <i>No existing orders found</i>
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}
            </IonList>

            <IonList className={`form-list step2 ${step === 2 ? 'visible' : 'hidden'}`}>
              {!!selectedOrderId && (
                <IonItem>
                  <IonLabel>Order ID</IonLabel>
                  <IonLabel slot="end" className="label-order-id">
                    <b>{selectedOrderId}</b>
                  </IonLabel>
                </IonItem>
              )}

              <IonItem className="select-item z2">
                <IonLabel>Which inspections?</IonLabel>
                <Select
                  classNamePrefix={'react-select'}
                  options={inspectionAggregationOptions}
                  defaultValue={inspectionAggregationOptions[0]}
                  onChange={(evt) =>
                    this.setState({
                      //@ts-ignore
                      report: { ...report, inspectionAggregation: evt.value },
                    })
                  }
                  isClearable={false}
                />
              </IonItem>

              <IonItem className="select-item z1" data-tip={'pnr-supplier-select'}>
                <IonLabel>Supplier</IonLabel>
                <ctxContacts.Consumer>
                  {(contacts) => {
                    const supplierOptions = (contacts ?? [])
                      //.filter(c => c.type?.includes('SELLER'))
                      .sort((a, b) => a?.name?.localeCompare(b.name))
                      .map((contact) => ({
                        value: contact.id,
                        label: contact.name ?? contact.id,
                      }));

                    const value = (supplierOptions ?? []).find(
                      (o) => o.value === report?.contactId
                    );

                    return (
                      <Select
                        classNamePrefix={'react-select'}
                        options={supplierOptions}
                        isClearable={true}
                        placeholder={'(optional)'}
                        value={value}
                        onChange={(evt) => {
                          if (evt == null) {
                            return this.setState({
                              report: {
                                ...report,
                                contactId: undefined,
                                contactName: undefined,
                              },
                            });
                          }
                          this.setState({
                            report: {
                              ...report,
                              contactId: evt.value,
                              contactName: evt.label,
                            },
                          });
                        }}
                        noOptionsMessage={() => 'No options'}
                      />
                    );
                  }}
                </ctxContacts.Consumer>
              </IonItem>

              <IonItem>
                <IonLabel>Hide Defects</IonLabel>
                <IonToggle
                  color="tertiary"
                  onIonChange={(_) =>
                    this.setState({
                      report: { ...report, hideDefects: !report.hideDefects },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Hide Scores</IonLabel>
                <IonToggle
                  color="tertiary"
                  onIonChange={(_) =>
                    this.setState({
                      report: { ...report, hideScores: !report.hideScores },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Hide Inspector Name</IonLabel>
                <IonToggle
                  color="tertiary"
                  onIonChange={(_) =>
                    this.setState({
                      report: { ...report, hideInspector: !report.hideInspector },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Hide Quantities</IonLabel>
                <IonToggle
                  color="tertiary"
                  onIonChange={(_) =>
                    this.setState({
                      report: { ...report, hideQuantity: !report.hideQuantity },
                    })
                  }
                />
              </IonItem>

              {/* <IonItem className='select-item z0'>
              <IonLabel>
                Grower
              </IonLabel>
              <ctxContacts.Consumer> 
                {contacts => {
                  const growerOptions = (contacts ?? [])
                    .filter(c => c.type?.includes('GROWER'))
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .map(grower => ({ value: grower.id, label: grower.name ?? grower.id }));

                  const value = (growerOptions ?? []).find(o => o.value === report?.growerContactId);
                  const defaultValue = (growerOptions ?? []).find(o => o.value === selectedOrder?.growerContactId);

                  return <Select
                    classNamePrefix={'react-select'}
                    options={growerOptions}
                    isClearable={true}
                    placeholder={'(optional)'}
                    value={defaultValue ?? value}
                    onChange={(evt) => {
                      if (evt == null) {
                        return this.setState({
                          report: { ...report, growerContactId: undefined }
                        });  
                      }
                      this.setState({
                        report: { ...report, growerContactId: evt.value }
                      });
                    }}
                    noOptionsMessage={() => "No options"}
                    isDisabled={!!defaultValue}
                  />
                }}
              </ctxContacts.Consumer>
            </IonItem> */}

              {/* TODO: momentary hack, do this properly later */}
              {section === 'SUPPLY-CHAIN' && (
                <>
                  <IonItem>
                    <IonLabel>Estimated arrival date</IonLabel>
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      placeholder="(optional)"
                      value={
                        !!report.estimatedFulfilmentDate
                          ? new Date(report.estimatedFulfilmentDate).toDateString()
                          : undefined
                      }
                      onIonChange={(evt) =>
                        this.setState({
                          report: {
                            ...report,
                            estimatedFulfilmentDate: new Date(evt.detail.value),
                          },
                        })
                      }
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Estimated dispatch date</IonLabel>
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      placeholder="(optional)"
                      value={
                        !!report.estimatedDepartureDate
                          ? new Date(report.estimatedDepartureDate).toDateString()
                          : undefined
                      }
                      onIonChange={(evt) =>
                        this.setState({
                          report: {
                            ...report,
                            estimatedDepartureDate: new Date(evt.detail.value),
                          },
                        })
                      }
                    />
                  </IonItem>
                </>
              )}

              {this.renderAttachmentSection(report)}

              <IonItem>
                <textarea
                  ref={this.commentsInput}
                  name="comments"
                  placeholder="Comments (optional)"
                ></textarea>
              </IonItem>

              <div className="buttons"></div>
            </IonList>
          </div>
        </IonContent>
      </div>
    );
  }
}

export default withContext(withIonLifeCycle(PageNewReport), [
  'profile',
  'contacts',
  'organisation',
]);
