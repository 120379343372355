import { DateRange, EntityType, InspectionTime, TimeLabel } from './ModelInsight';
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  getUnixTime,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

export const msInYear = 31449600000;

export const INSIGHTS_DECIMALS = 2;
export const STD_STRING = '±';
export const AGREGATION_INTERVAL_STORAGE_KEY = 'aggregation_interval';
export const MEASURED_BY_STORAGE_KEY = 'measured_by';
export const INSIGHTS_GRID_STORAGE_KEY = 'insights-grid';
export const DATE_RAGE_STORAGE_KEY = 'dateRange';
export const GENERIC_SCORE_STRING = 'Generic';
export const RANKING_WEIGHT_FILTER_STORAGE_KEY = 'insights_weight_filters';

export const TIME_RESOLUTION_TITLE_PARAM = '{timeResolution}';
export const TIME_AGGREGATION_TITLE_PARAM = '{timeAggregation}';
export const MEASURE_BY_TITLE_PARAM = '{measuredBy}';
export const SCORE_NAME_TITLE_PARAM = '{scoreName}';

export const insightsTimeOffset = 6 * 24 * 60 * 60 * 1000; // +6 days, because Ben wants to see the last day of the range, not the first one

// todo: hardcoded for now, this should come from somewhere when the final version of forecasts is ready
export const weeks = ['Week1', 'Week2', 'Week3', 'Week4', 'Week5'];

export const monthStrings: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const inspectionTimeMap: { [key in InspectionTime]?: number } = {
  day: 3600000 * 24,
  week: 3600000 * 24 * 7,
  month: 3600000 * 24 * 30,
  year: 3600000 * 24 * 365,
};

export const entityTypeMap: { [key in EntityType]?: string } = {
  product: 'Product',
  buyer: 'Customer',
  customer: 'Customer',
  product_group: 'Product group',
  supplier: 'Supplier',
  origin: 'Origin',
  user_id: 'QC Hero',
  packaging: 'Packaging',
  variety: 'Variety',
};

export const weight = {
  scale: 0.001,
  units: '1k kg',
};

export const definedDates: { [key: string]: Date } = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),

  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),

  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),

  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),

  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),

  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  startOfLast3Months: startOfMonth(addMonths(new Date(), -3)),
  startOfLast6Months: startOfMonth(addMonths(new Date(), -6)),

  endOfYear: endOfYear(new Date()),
  startOfYear: startOfYear(new Date()),

  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),

  oneYearAgo: addYears(new Date(), -1),
  tenYearsAgo: addYears(new Date(), -10),
};

export class CDateRange {
  timeLabel: TimeLabel;

  constructor(timeLabel: TimeLabel) {
    this.timeLabel = timeLabel;
  }

  mappings: { [key in TimeLabel]?: { start: Date; end: Date } } = {
    'Week to date': {
      start: addDays(new Date(), -7),
      end: new Date(),
    },
    'This Week': {
      start: definedDates.startOfWeek,
      end: new Date(),
    },
    'Last Week': {
      start: definedDates.startOfLastWeek,
      end: definedDates.endOfLastWeek,
    },
    'Month to date': {
      start: addMonths(new Date(), -1),
      end: new Date(),
    },
    'This Month': {
      start: definedDates.startOfMonth,
      end: new Date(),
    },
    'Last Month': {
      start: definedDates.startOfLastMonth,
      end: definedDates.endOfLastMonth,
    },
    'Year to date': {
      start: addYears(new Date(), -1),
      end: new Date(),
    },
    'This Year': {
      start: definedDates.startOfYear,
      end: new Date(),
    },
    'Last Year': {
      start: definedDates.startOfLastYear,
      end: definedDates.endOfLastYear,
    },
    'Last 3 Months': {
      start: addMonths(new Date(), -3),
      end: new Date(),
    },
    'Last 6 Months': {
      start: addMonths(new Date(), -6),
      end: new Date(),
    },
    'Today': {
      start: definedDates.startOfToday,
      end: new Date(),
    },
    'All': {
      start: new Date(0),
      end: new Date(),
    },
  };

  getStart(convertToUnixTS: boolean = false): Date | number {
    let date: Date | number;
    if (this.timeLabel.includes('days up to today')) {
      // extract time from string
      const days = +this.timeLabel.split(' days up to today')[0];
      date = addDays(new Date(), -days);
    } else {
      // assumes default starting date as ONE YEAR AGO
      date = this.mappings[this.timeLabel]?.start ?? definedDates.oneYearAgo;
    }
    return convertToUnixTS ? getUnixTime(date) : date;
  }

  getEnd(convertToUnixTS: boolean = false): Date | number {
    let date: Date | number;
    if (this.timeLabel.includes('days up to today')) {
      date = definedDates.endOfYesterday;
    } else {
      // assumes default ending date as TODAY (not included)
      date = this.mappings[this.timeLabel]?.end ?? definedDates.endOfYesterday;
    }
    return convertToUnixTS ? getUnixTime(date) : date;
  }

  getDateRange(): DateRange {
    return {
      range: this.timeLabel,
      startDate: (this.mappings[this.timeLabel]?.start ?? definedDates.oneYearAgo) + '',
      endDate: (this.mappings[this.timeLabel]?.end ?? definedDates.endOfYesterday) + '',
    };
  }
}

export function getUnixStartAndEndTime(
  dateRange: DateRange,
  JSformat: boolean = false
) {
  const res = {
    start_time: getUnixTime(definedDates.oneYearAgo) as number | Date,
    end_time: getUnixTime(definedDates.endOfYesterday) as number | Date,
  };

  if (!dateRange) {
    console.warn('getStartAndEndTime: no dateRange found, returning default range');
    return res;
  }

  const { startDate, endDate, range } = dateRange;

  if (!range || range === 'All') {
    res.start_time = startDate
      ? getUnixTime(new Date(dateRange?.startDate))
      : getUnixTime(definedDates.oneYearAgo);
    res.end_time = endDate
      ? getUnixTime(new Date(dateRange?.endDate))
      : getUnixTime(definedDates.endOfYesterday);
  } else {
    const DR = new CDateRange(range);
    res.start_time = DR.getStart(true);
    res.end_time = DR.getEnd(true);
  }

  if (JSformat) {
    (res.start_time as number) *= 1000;
    (res.end_time as number) *= 1000;
  }

  return res;
}

type RGBColor = [number, number, number];
export const getRGBColorCustom = (
  value?: number,
  thresholds: number[] = [0, 49.9, 49.99, 60, 60.1, 100],
  colors: RGBColor[] = [
    [51, 0, 0],
    [255, 0, 0],
    [255, 128, 0],
    [200, 180, 0],
    [204, 255, 153],
    [52, 168, 85],
  ]
): string => {
  // RGB values for "#ea0206"
  // RGB values for "#ffcc68"
  // RGB values for "#34a854"
  const grayRGB = `rgb(220,220,210)`;
  if (value == null) {
    return grayRGB;
  }

  if (thresholds.length !== colors.length) {
    console.error(
      `Color thresholds (${thresholds}) should have the same length as the colors array (${colors})`
    );
    return grayRGB;
  }

  for (const [idx, t] of thresholds.entries()) {
    if (idx === 0) {
      continue;
    }
    if (t <= thresholds[idx - 1]) {
      console.error(`Color thresholds (${thresholds}) should be sorted ascending`);
      return grayRGB;
    }
  }

  let r: number, g: number, b: number;

  function getValue(
    color1: RGBColor,
    color2: RGBColor,
    index: number,
    lowerLimit: number,
    upperLimit: number
  ): number {
    return (
      color1[index] +
      ((color2[index] - color1[index]) * (value - lowerLimit)) /
        (upperLimit - lowerLimit)
    );
  }

  const zippedThesholds: [number, RGBColor][] = thresholds.map((v, i) => [
    v,
    colors[i],
  ]);

  for (const [idx, [limit, color]] of zippedThesholds.entries()) {
    if (idx === thresholds.length - 1) {
      break;
    }
    const [nextLimit, nextColor] = zippedThesholds[idx + 1];

    if ((idx === 0 ? limit <= value : limit < value) && value <= nextLimit) {
      r = Math.round(getValue(color, nextColor, 0, limit, nextLimit));
      g = Math.round(getValue(color, nextColor, 1, limit, nextLimit));
      b = Math.round(getValue(color, nextColor, 2, limit, nextLimit));
      break;
    }
  }

  // Convert the RGB values to a string representation
  const rgbColor = `rgb(${r},${g},${b})`;

  return rgbColor;
};

export const ISO3166 = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua & Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia',
  BR: 'Brazil',
  BS: 'Bahama',
  BT: 'Bhutan',
  BU: 'Burma (no longer exists)',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: "Côte D'ivoire (Ivory Coast)",
  CK: 'Cook Iislands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CS: 'Czechoslovakia (no longer exists)',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DD: 'German Democratic Republic (no longer exists)',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands (Malvinas)',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  FX: 'France, Metropolitan',
  GA: 'Gabon',
  GB: 'United Kingdom (Great Britain)',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard & McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Islamic Republic of Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'St. Kitts and Nevis',
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libyan Arab Jamahiriya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova, Republic of',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  ML: 'Mali',
  MN: 'Mongolia',
  MM: 'Myanmar',
  MO: 'Macau',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Monserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NT: 'Neutral Zone (no longer exists)',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'St. Pierre & Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'St. Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard & Jan Mayen Islands',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome & Principe',
  SU: 'Union of Soviet Socialist Republics (no longer exists)',
  SV: 'El Salvador',
  SY: 'Syrian Arab Republic',
  SZ: 'Swaziland',
  TC: 'Turks & Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TP: 'East Timor',
  TR: 'Turkey',
  TT: 'Trinidad & Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan, Province of China',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City State (Holy See)',
  VC: 'St. Vincent & the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'United States Virgin Islands',
  VN: 'Viet Nam',
  VU: 'Vanuatu',
  WF: 'Wallis & Futuna Islands',
  WS: 'Samoa',
  YD: 'Democratic Yemen (no longer exists)',
  YE: 'Yemen',
  YT: 'Mayotte',
  YU: 'Yugoslavia',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZR: 'Zaire',
  ZW: 'Zimbabwe',
  ZZ: 'Unknown or unspecified country',
};
