import React from 'react';
import withContext, { ContextProps } from './HOC/withContext';
import { User } from './Model';
import './ViewUser.scss';

interface Props {
  user?: User;
  userId?: string;
  username?: string;
}

class ViewUser extends React.Component<Props & ContextProps> {
  getUserInitials(user: User) {
    let userName = this.props.username || user?.name;
    if (!userName) {
      return '';
    }

    if (userName.indexOf(' ') >= 0) {
      let acronym = userName
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      return acronym.toUpperCase();
    } else {
      let max = userName.length > 3 ? 3 : userName.length;
      return userName.substr(0, max).toUpperCase();
    }
  }

  render() {
    let user =
      this.props.user ||
      this.props.users?.find((user) => user.id === this.props.userId);

    return (
      <div className="render-user">
        <span className="initials">{this.getUserInitials(user)}</span>
      </div>
    );
  }
}

export default withContext(ViewUser, ['users']);
