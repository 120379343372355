import {
  IonBadge,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonModal,
} from '@ionic/react';
import {
  airplaneOutline,
  boatOutline,
  carOutline,
  documentAttachOutline,
  linkOutline,
  warningOutline,
} from 'ionicons/icons';
import { isEqual } from 'lodash';
import React from 'react';
import { ctxUsers } from './App';
import { CardImages } from './CardImages';
import './CardInspection.scss';
import { supportedInspectionTypeMap } from './DataReport';
// import { shallowEqual } from "./HelperUtils";
import withContext, { ContextProps } from './HOC/withContext';
import { formatDate, getLotOrPosQuantity, resolveLocationName } from './HelperUtils';
import { LotInspection } from './InspectionModel';
import {
  AG_BOXES_AT_INSPECTION_QUESTION_ID,
  AG_BOXES_SHIPPED_QUESTION_ID,
  IQuestionSpec,
  Lot,
  Order,
  OrganisationSettings,
  Report,
  SharedReport,
  UserProfile,
} from './Model';
import PageGallery from './PageGallery';
import { Article } from './ServiceArticle';
import {
  getInspectionBoxes,
  getUserInputValuesToRender,
  stringifyInspectionReference,
} from './ServiceInspection';
import ViewArticleDescription from './ViewArticleDescription';
import ViewContactName, { ViewContactNameById } from './ViewContactName';
import { ViewInspectionScore } from './ViewInspectionScore';

interface Props {
  inspection?: LotInspection;
  showLink?: boolean;
  showGalleryModal?: boolean;
  routerLink?: string;
  loading: boolean;
  showButtons?: boolean;
  allowStatusEdit?: boolean;
  lot?: Lot;
  // lotIdOverride?: string;
  report?: Report;
  maxImgs?: number;
  shouldUpdate?: Function;
  organisationId: string;
  noIcon?: boolean;
  reload?: boolean;
  imageMode?: string;
  searchString?: string;
  doubleCard?: Order;
  orgSettings?: OrganisationSettings;
  history?: any;
  testingSuffix?: string;
  allowGalleryEdit?: boolean;
  onPicturesUpdated?: Function;

  onLotUnlinkFromPageOrder?: (lotId: string, order: Order) => void;

  onMismatchShow?: () => void;

  splitLotTransferedQuantity?: number;
  motherLotIds?: string[];
  sharedReport?: boolean;
}

interface State {
  showPageGallery: boolean;
  culo?: boolean;
  showDefectId: string;
}

class CardInspectionSummary extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showPageGallery: false,
      showDefectId: undefined,
      culo: false,
    };
  }

  // TODO: implement properly
  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    console.log('shouldComponentUpdate');
    // always update on state change
    if (this.state !== nextState) {
      return true;
    }

    if (!isEqual(this.props.locations, nextProps?.locations)) {
      return true;
    }

    if (this.props.reload === true) {
      return true;
    }

    const { showDefectId, showPageGallery } = this.state;

    if (
      showPageGallery !== nextState.showPageGallery ||
      showDefectId !== nextState.showDefectId
    ) {
      return true;
    }

    if (nextProps.organisationId !== this.props.organisationId) {
      // console.error('nextProps.organisationId !== this.props.organisationId')
      return true;
    }

    return true;
  }

  showGallery(e, defectId?) {
    if (this.props.showGalleryModal === false) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if (defectId) {
      if (
        this.props.inspection?.pictures.filter((p) => p.inputIds.includes(defectId))
          .length <= 0
      ) {
        return;
      }
      this.setState({
        showPageGallery: true,
        showDefectId: defectId,
      });
    } else {
      this.setState({ showPageGallery: true, showDefectId: undefined });
    }
  }

  hideGallery() {
    this.setState({ showPageGallery: false });
  }

  boxesCount() {
    const { inspection: assessment } = this.props;
    const { boxesExpected, boxesShipped, boxesAtInspection } =
      getInspectionBoxes(assessment);

    if (boxesShipped == null && boxesAtInspection == null) {
      return '';
    }

    if (boxesExpected == null) {
      // We don't show the boxes at inspection (for now)
      // return <IonBadge className="score-4">Boxes {boxesAtInspection}</IonBadge>
      return null;
    } else {
      let agScore: any = assessment?.scores?.[AG_BOXES_SHIPPED_QUESTION_ID]?.agScore;
      agScore = agScore ?? assessment?.scores?.[AG_BOXES_SHIPPED_QUESTION_ID]?.score;
      // read score and show as super red
      if (parseInt(agScore) === 2) {
        agScore = 1;
      }
      return (
        <IonBadge className={`score-${agScore} boxes-count`}>
          Shipped {boxesShipped}/{boxesExpected}
        </IonBadge>
      );
    }
  }

  renderDefects(inspection: LotInspection) {
    const questionSpecs: { [key in string]: IQuestionSpec } =
      inspection?.renderingInfo?.questionSpecs;

    // these questions are treated separatedly in boxesCount()
    const ommitedDefects: string[] = [
      AG_BOXES_AT_INSPECTION_QUESTION_ID,
      AG_BOXES_SHIPPED_QUESTION_ID,
    ];

    if (!questionSpecs) return;

    let defects = Object.entries(inspection?.userInputs).filter(
      ([id, o]) => o.agScore < 4 && !ommitedDefects.includes(id)
    );
    let rest = Object.entries(inspection?.userInputs).filter(
      ([id, o]) =>
        (o.agScore === 4 || o.agScore === undefined) && !ommitedDefects.includes(id)
    );
    let boxesCount = this.boxesCount();

    if (!!defects.length || !!rest.length || !!boxesCount) {
      return (
        <div className="all-defects">
          {boxesCount}
          {defects
            .filter(([id]) => questionSpecs[id]?.showInSummary !== false)
            .map(
              ([id, o], i) =>
                !!questionSpecs[id]?.displayedName && (
                  <IonBadge
                    className={`score-${o.agScore} ${o.groupId}`}
                    key={'d-' + i} /*onClick={e=>this.showGallery(e, o.)}*/
                  >
                    <span>
                      {!!questionSpecs[id].reportName
                        ? questionSpecs[id].reportName
                        : questionSpecs[id].displayedName}
                    </span>
                    <span>{getUserInputValuesToRender(o)}</span>
                  </IonBadge>
                )
            )}
          {rest
            .filter(([id]) => questionSpecs[id]?.showInSummary !== false)
            .map(
              ([id, o], i) =>
                !!questionSpecs[id]?.displayedName && (
                  <IonBadge
                    className={`score-${o.agScore} ${o.groupId} print-only`}
                    key={'dr-' + i} /*onClick={e=>this.showGallery(e, o.)}*/
                  >
                    <span>
                      {!!questionSpecs[id].reportName
                        ? questionSpecs[id].reportName
                        : questionSpecs[id].displayedName}
                    </span>
                    <span>{getUserInputValuesToRender(o)}</span>
                  </IonBadge>
                )
            )}
        </div>
      );
    }
  }

  getLink(doubleCard = false) {
    let { showLink, inspection: assessment, profile, routerLink } = this.props;

    // TODO: reimplement double card logic

    // if (doubleCard) {
    //   assessment = this.props.doubleCard.lotAssessmentMap[linkedLotId]
    //   if (!assessment)
    //     assessment = this.props.doubleCard.transportAssessmentMap[linkedLotId]

    //   return { routerLink: '/secure/' + this.props.doubleCard.orgId + '/report/' + assessmentId(assessment?.reference) }
    // } else {
    if (showLink === false) {
      return { routerLink: undefined };
    } else if (routerLink !== undefined) {
      return { routerLink };
    }
    // }

    // TODO: fix this later and also add support for upcoming lots
    // if(assessment?.reference.type === "STOCK"){
    //   return { routerLink: '/secure/' + profile.organisationId + '/report/' + assessmentId(assessment?.reference) + "/" + dateToString(assessment?.lastModifiedDate) }
    // } else {
    //   return { routerLink: '/secure/' + profile.organisationId + '/report/' + assessmentId(assessment?.reference) }
    // }
    return {
      routerLink:
        '/secure/' +
        profile.organisationId +
        '/report/' +
        stringifyInspectionReference(assessment?.reference),
    };
  }

  renderStock() {
    const { lot, splitLotTransferedQuantity } = this.props;
    if (!lot || !lot.transfers) return null;

    let quantity: any = getLotOrPosQuantity(lot);
    if (isNaN(quantity)) quantity = lot.transfers[0]?.numBoxes;
    quantity = Math.abs(quantity);
    quantity = isNaN(quantity) ? '??' : parseInt(quantity);

    return (
      <div className="quantity">
        <div>
          {quantity}
          <small>
            {splitLotTransferedQuantity && splitLotTransferedQuantity > 0
              ? ` (-${splitLotTransferedQuantity})`
              : ''}
          </small>
        </div>
        <div>{new Article(lot.article).getUnit(quantity)}</div>
      </div>
    );
  }

  culo(e) {
    console.log(this.state.culo);

    e.stopPropagation();
    this.setState({ culo: !this.state.culo });
  }

  getIcon() {
    const icon = () => {
      if (this.props.inspection?.reference.transportId) {
        switch (
          this.props.inspection?.userInputs.transport_type?.measurableInputs?.[0]
            ?.measurementInput?.value
        ) {
          case 1:
            return <IonIcon icon={airplaneOutline} />;
          case 2:
            return <IonIcon icon={boatOutline} />;
          case 3:
            return <IonIcon icon={carOutline} />;
        }
        return <IonIcon icon={documentAttachOutline} />;
      }
      return <IonIcon icon={documentAttachOutline} />;
    };

    return <div className="report-icon">{icon()}</div>;
  }

  getTitle() {
    let { searchString, lot } = this.props;
    const inspection = lot.latestInspection;
    const { routerLink } = this.getLink();
    const title =
      inspection?.reference.lotId ?? inspection?.reference.transportId ?? lot.id;
    // const printLink = <a href={'https://app.agrinorm.ai' + routerLink} target="_blank" title={title} className="print-only inspection-print-link">open <IonIcon icon={openOutline}/></a>
    const printLink = (
      <a
        href={routerLink}
        target="_blank"
        rel="noreferrer"
        title={title}
        onClick={(e) => e.preventDefault()}
      >
        {title}
      </a>
    );

    if (searchString && title.endsWith(searchString)) {
      return (
        <>
          {title.slice(0, title.length - searchString.length)}
          <span className="highlight">{searchString}</span>
        </>
      );
    }

    return <>{printLink}</>;
  }

  getSubTitle() {
    const { suppliedByContactId } = this.props.lot ?? {};

    if (suppliedByContactId == null) {
      return null;
    }

    if (
      suppliedByContactId === this.props.profile?.organisationId &&
      !!this.props.organisation?.name
    ) {
      return <div className="supplier">{this.props.organisation.name}</div>;
    }

    return (
      <>
        {suppliedByContactId != null && (
          <div className="supplier">
            <ViewContactName lot={this.props.lot} />
          </div>
        )}
      </>
    );
  }

  render() {
    if (this.props.loading) {
      return '';
    }

    let { maxImgs, lot, report, onMismatchShow } = this.props;

    // add all the pictures together with a reference to their assessment
    let pictures =
      lot.inspections
        ?.map((inspection) =>
          inspection?.pictures?.map((picture) => {
            return { ...picture, inspectionReference: inspection?.reference };
          })
        )
        .sort()
        .flat() ?? [];

    // take the last ones aka the most recent ones
    if (maxImgs) {
      pictures = pictures.slice(-maxImgs);
    }

    const inspection: LotInspection = lot.latestInspection;
    // let configuration = getConfiguration(assessment, applicationContext.schemas, this.props.organisationId);

    // TODO: reimplement this logic
    const displayLotLink = false;

    let className = 'card-inspection ';

    const growerId = lot?.origin?.growerContactId;

    return (
      <>
        <div
          className={`ion-card ${className}`}
          data-tip={`card-report-${this.props.testingSuffix}`}
          onClick={(e) => {
            e.stopPropagation();
            const { routerLink } = this.getLink();
            if (!routerLink) return;
            this.props.history?.push(routerLink);
          }}
        >
          <div
            className="cover"
            onClick={(e) => (pictures.length ? this.showGallery(e) : null)}
          >
            <CardImages
              pictures={pictures}
              max={maxImgs || pictures.length}
              imageMode={this.props.imageMode}
            />
          </div>
          <div>
            <IonCardHeader>
              <div>
                <IonCardTitle className="card-title">
                  {this.props.noIcon ? null : this.getIcon()}
                  {this.getTitle()}
                  {lot?.transient?.hasLink ? <IonIcon icon={linkOutline} /> : null}
                </IonCardTitle>

                {this.getSubTitle()}

                {displayLotLink && (
                  <div className="linked-lot">
                    <IonIcon icon={linkOutline} />
                    {!!onMismatchShow && (
                      <IonButton
                        title="Display allocation mismatches"
                        fill="clear"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onMismatchShow();
                        }}
                      >
                        <IonIcon icon={warningOutline} />
                      </IonButton>
                    )}
                  </div>
                )}

                {!!growerId && lot?.suppliedByContactId !== growerId && (
                  <div className="grower-name">
                    <ViewContactNameById contactId={growerId} />
                  </div>
                )}

                <ctxUsers.Consumer>
                  {(users) => {
                    if (
                      this.props.sharedReport &&
                      this.props.orgSettings?.sharedReportDisplaySettings
                        ?.inspectedBy === false
                    ) {
                      return null;
                    }
                    let inspectionUser: UserProfile = users.find(
                      (o) => o.id === inspection?.lastModifiedUserId
                    );
                    return (
                      inspectionUser && (
                        <div className="user">
                          {inspectionUser.name ?? inspectionUser.email}
                        </div>
                      )
                    );
                  }}
                </ctxUsers.Consumer>

                {/* {
              (!!assessment?.lastModifiedDate)
                ? <div className="assessment-date">{formatDateTime(assessment?.lastModifiedDate)}</div>
                : null
            } */}

                {inspection?.lotProperties.article && (
                  <ViewArticleDescription
                    // searchString={this.props.searchString}
                    article={inspection?.lotProperties.article}
                    pills={true}
                  />
                )}
              </div>
              <div>{this.renderStock()}</div>
            </IonCardHeader>

            <IonCardContent>
              {/* Only display defects if inspection is finished */}
              {lot.inspections
                .sort((a, b) => (a.lastModifiedDate < b.lastModifiedDate ? 1 : -1))
                .map((inspection, i) => {
                  return (
                    <div
                      className="batch-summary"
                      key={'bs-' + inspection?.reference.lotId + i}
                    >
                      <div className="batch-summary-title">
                        <div>
                          <IonBadge color="dark" className="assessment-date">
                            {formatDate(inspection?.lastModifiedDate)}
                          </IonBadge>
                          &nbsp;
                          <IonBadge color="medium" className="assessment-date">
                            {supportedInspectionTypeMap[inspection?.reference.type]}
                          </IonBadge>
                        </div>
                        <div className="batch-summary-score">
                          {
                            <ViewInspectionScore
                              inspection={inspection}
                              order={report}
                              // @ts-ignore
                              organisationSettings={
                                (report as SharedReport)?.orgSettings ??
                                this.props.organisation?.settings
                              }
                              profile={this.props.profile}
                              displayType="INLINE"
                            />
                          }
                        </div>
                      </div>
                      <div className="defects">{this.renderDefects(inspection)}</div>
                    </div>
                  );
                })}
            </IonCardContent>

            {lot.transient?.locationId && !this.props.sharedReport && (
              <div className="location">
                Location:{' '}
                {resolveLocationName(lot.transient.locationId, this.props.locations)}
              </div>
            )}
          </div>
        </div>

        <IonModal
          isOpen={this.state.showPageGallery}
          onDidDismiss={(_) => this.hideGallery()}
        >
          <PageGallery
            editable={this.props.allowGalleryEdit}
            inspection={inspection}
            editMode={'report'}
            pictures={pictures}
            showDefectId={this.state.showDefectId}
            onDismiss={(_) => this.hideGallery()}
            onPicturesUpdated={this.props.onPicturesUpdated}
          />
        </IonModal>
      </>
    );
  }
}

export default withContext(CardInspectionSummary, [
  'locations',
  'profile',
  'organisation',
]);
