import firebase from 'firebase/compat/app';
import { cloneDeep, pick } from 'lodash';
import {
  contactColRef,
  getOrder,
  handleOnCollectionSnapshot,
  handleOnDocSnapshot,
  lotColRef,
  orderColRef,
  orgDocRef,
  reportColRef,
  sharedReportColRef,
} from './DataStorage';
import {
  COMMON_COL_NAME,
  LOT_INSPECTION_MAP,
  SUPPLY_CHAIN_COL_NAME,
} from './GlobalConstants';
import {
  dateToYMD,
  replaceAll,
  sortByLastModifiedDateAsc,
  standardizeDate,
} from './HelperUtils';
import { LotInspection } from './InspectionModel';
import {
  Contact,
  Lot,
  Order,
  OrderAGStatus,
  OrderType,
  Organisation,
  OrganisationSettings,
  Report,
  ReportReference,
  ReportStatus,
  ReportType,
  SharedTo,
  User,
  UserProfile,
  SharedReport,
  Location,
  ProductView,
} from './Model';
import {
  buildReportArraySearch,
  generateOrderSearch,
  generateReportSearch,
} from './SearchService';
import {
  hasInspectionReference,
  removeInspectionlessPositions,
} from './ServiceInspection';
import { InspectionType } from './generated/openapi/core';
import { userProfileToUser } from './utils/Mappers';

type FS = firebase.firestore.Firestore;

/* ***************************** */
/* Database related
/* ***************************** */

export function getReportSnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  reportId: string,
  onResult: (report: Report) => any
): () => void {
  return reportColRef(firestoreRef, organisationId)
    .doc(reportId)
    .onSnapshot(...handleOnDocSnapshot(onResult));
}

export async function getReport(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  reportId: string
): Promise<Report> {
  const reportDoc = await reportColRef(firestoreRef, organisationId)
    .doc(reportId)
    .get();

  return reportDoc.data() as Report;
}

export function getReportHistorySnapshot(
  firestoreRef: firebase.firestore.Firestore,
  organisationId: string,
  reportId: string,
  onResult: (reports: Report[]) => any
): () => void {
  return reportColRef(firestoreRef, organisationId)
    .doc(reportId)
    .collection('history')
    .onSnapshot(...handleOnCollectionSnapshot(onResult));
}

export async function createInternalReportFromOrder(
  store: FS,
  profile: UserProfile,
  order: Order,
  orgSettings?: OrganisationSettings
): Promise<Report> {
  if (orgSettings == null) {
    orgSettings = (
      (await orgDocRef(store, profile.organisationId).get()).data() as Organisation
    ).settings;
  }

  const reportType = orderToInternalReportTypeMap[order.type];
  const report: Report = orderToInternalReport(
    order,
    reportType,
    profile,
    orgSettings,
    undefined,
    undefined,
    'CREATED'
  );

  return setReport(store, profile, report)
    .then((_) => {
      const reportReferences: ReportReference[] = !!order.reportReferences
        ? [...order.reportReferences, report.reportReference]
        : [report.reportReference];

      const orderUpdateObj = {
        latestReportReference: report.reportReference,
        reportReferences,
        hasReportDraft: false,
        qcStatus: 'CHECKED',
        lastQCStatusDate: new Date(),
        lastQCStatusUserId: profile.id,
      } as Order;

      orderUpdateObj.search = generateOrderSearch(
        { ...order, ...orderUpdateObj },
        orgSettings
      );

      // update order with the report references
      return orderColRef(store, profile.organisationId)
        .doc(order.id)
        .update(orderUpdateObj);
    })
    .then((_) => report)
    .catch((e) => {
      throw e;
    });
}

export async function setReport(store: FS, profile: UserProfile, report: Report) {
  return reportColRef(store, profile.organisationId).doc(report.id).set(report);
}

export async function deleteReport(store: FS, profile: UserProfile, reportId: string) {
  const reportDocRef = reportColRef(store, profile.organisationId).doc(reportId);
  const reportDoc = await reportDocRef.get();

  if (!reportDoc.exists) {
    return;
  }

  const batch = store.batch();
  const report: Report = reportDoc.data() as Report;

  // 1. Delete references in associated order, if exists
  if (report.reportReference?.orderId != null) {
    const orderDocRef = orderColRef(store, profile.organisationId).doc(
      report.reportReference.orderId
    );
    const orderDoc = await orderDocRef.get();

    if (orderDoc.exists) {
      const order: Order = orderDoc.data() as Order;
      const orderUpdateObj = {} as Order;

      if ((order.reportReferences ?? []).length > 0) {
        const newReportReferences: ReportReference[] = order.reportReferences
          .filter((r) => r.reportId !== report.id)
          .sort(sortByLastModifiedDateAsc);

        if (newReportReferences.length !== order.reportReferences.length) {
          orderUpdateObj.reportReferences = newReportReferences;

          if (order.latestReportReference?.reportId === report.id) {
            const newLatestRefence =
              newReportReferences[newReportReferences.length - 1];
            // @ts-ignore
            orderUpdateObj.latestReportReference = !!newLatestRefence
              ? newLatestRefence
              : firebase.firestore.FieldValue.delete();
          }
        }
      }

      if (Object.keys(orderUpdateObj).length > 0) {
        batch.update(orderDocRef, orderUpdateObj);
      }
    }
  }

  // 2. Delete the report
  batch.delete(reportDocRef);

  return batch.commit();
}

// This function replaces skipOrder, redoOrder and checkOrder
export async function updateOrderStatus(
  store: FS,
  profile: UserProfile,
  order: Order,
  qcStatus: OrderAGStatus,
  orgSettings: OrganisationSettings
) {
  const updateObj: Order = {
    lastModifiedDate: new Date(),
    lastQCStatusDate: new Date(),
    lastQCStatusUserId: profile.id,
    lastModifiedUserId: profile.id,
    qcStatus,
  } as Order;

  updateObj.search = generateOrderSearch({ ...order, ...updateObj }, orgSettings);

  return orderColRef(store, profile.organisationId).doc(order.id).update(updateObj);
}

export async function setReportAsSharedAndUpdateOrderReportRef(
  store: FS,
  profile: UserProfile,
  report: Report,
  message: string = 'Shared via Agrinorm platform',
  orgSettings: OrganisationSettings,
  sharedTo?: SharedTo
) {
  const reportUpdateObj: Report = {
    reportStatus: 'SHARED',
    lastModifiedUserId: profile.id,
    lastWriteSource: 'UI',
  } as Report;

  const order: Order = report.reportReference.orderId
    ? await getOrder(store, profile.organisationId, report.reportReference.orderId)
    : undefined;

  // also update the sharing history
  if (!sharedTo) {
    sharedTo = {
      sharedBy: userProfileToUser(profile),
      message,
      date: new Date() as any,
    };

    // if sharedTo is present in the parameters, that means we are calling this from the sharing via email, in that case we don't want to have the feedback message here, but only in the report that's being added to the shared report collection
    reportUpdateObj.feedbackMessage = message;
    reportUpdateObj.sharedBy = userProfileToUser(profile);
  }

  reportUpdateObj.reportReference = buildReportReference(
    report,
    profile,
    order,
    undefined,
    new Date(),
    'SHARED',
    report.reportReference?.orderIdLinked
  );
  reportUpdateObj.search = generateReportSearch(
    { ...report, ...reportUpdateObj },
    order,
    orgSettings
  );

  reportColRef(store, profile.organisationId)
    .doc(report.id)
    .update({
      ...reportUpdateObj,
      sharedTo: firebase.firestore.FieldValue.arrayUnion(sharedTo),
    });

  // Now update order
  if (order == null) {
    return;
  }

  const orderUpdateObj: Order = {} as Order;

  // update report references in order
  if (
    !!order.latestReportReference &&
    order?.latestReportReference?.reportId === report.id
  ) {
    orderUpdateObj.latestReportReference = reportUpdateObj.reportReference;
  }

  if (!!order.reportReferences) {
    orderUpdateObj.reportReferences = order.reportReferences.map((ref) =>
      ref.reportId === report.id ? reportUpdateObj.reportReference : ref
    );
  }

  if (report.autogenFromOrder) {
    orderUpdateObj.qcStatus = 'SHARED';
  }
  orderUpdateObj.lastQCStatusDate = new Date();
  orderUpdateObj.lastQCStatusUserId = profile.id;
  orderUpdateObj.lastModifiedDate = new Date();

  orderUpdateObj.search = generateOrderSearch(
    { ...order, ...orderUpdateObj },
    orgSettings
  );

  orderColRef(store, profile.organisationId).doc(order.id).update(orderUpdateObj);
}

export async function updateReportReferenceOrderId(
  store: FS,
  profile: UserProfile,
  newOrderId: string,
  oldReport: Report
) {
  let batch = store.batch();

  const report = cloneDeep(oldReport);

  const oldOrderId: string = report.reportReference.orderId;

  let { reportSubject } = report;

  if (
    !reportSubject ||
    (reportSubject === buildReportSubject(report, undefined) && !!newOrderId)
  ) {
    reportSubject = buildReportSubject(report, newOrderId);
  } else if (reportSubject.includes(oldOrderId)) {
    reportSubject = !!newOrderId
      ? replaceAll(reportSubject, oldOrderId, newOrderId)
      : buildReportSubject(report, newOrderId);
  }

  // 1. Update the report
  batch.update(reportColRef(store, profile.organisationId).doc(report.id), {
    'reportReference.orderId': newOrderId ?? firebase.firestore.FieldValue.delete(),
    'reportReference.orderIdLinked': !!newOrderId,
    'search': buildReportArraySearch({
      ...report,
      reportReference: { ...report.reportReference, orderId: newOrderId },
    }),
    reportSubject,
  });

  // 2. Remove references from old order
  const oldOrderDocRef = orderColRef(store, profile.organisationId).doc(oldOrderId);
  const oldOrderDoc = await oldOrderDocRef.get();

  if (oldOrderDoc.exists) {
    const oldOrder: Order = oldOrderDoc.data() as Order;
    const oldOrderUpdateObj = {} as Order;

    if ((oldOrder.reportReferences ?? []).length > 0) {
      const newReportReferences: ReportReference[] = oldOrder.reportReferences
        .filter((r) => r.reportId !== report.id)
        .sort(sortByLastModifiedDateAsc);

      if (newReportReferences.length !== oldOrder.reportReferences.length) {
        oldOrderUpdateObj.reportReferences = newReportReferences;

        if (oldOrder.latestReportReference?.reportId === report.id) {
          const newLatestRefence = newReportReferences[newReportReferences.length - 1];
          // @ts-ignore
          oldOrderUpdateObj.latestReportReference = !!newLatestRefence
            ? newLatestRefence
            : firebase.firestore.FieldValue.delete();
        }
      }
    }

    if (Object.keys(oldOrderUpdateObj).length > 0) {
      batch.update(oldOrderDocRef, oldOrderUpdateObj);
    }
  }

  // 3. Add references to new order
  batch = (await addReferencesToOrder(
    store,
    profile,
    report,
    newOrderId,
    batch
  )) as firebase.firestore.WriteBatch;

  await batch.commit();
}

export async function addReferencesToOrder(
  store: FS,
  profile: UserProfile,
  report: Report,
  newOrderId: string,
  batch?: firebase.firestore.WriteBatch
) {
  if (!newOrderId) {
    return batch;
  }

  const newOrderDocRef = orderColRef(store, profile.organisationId).doc(newOrderId);
  const newOrderDoc = await newOrderDocRef.get();
  const newOrderUpdateObj = {} as Order;

  if (newOrderDoc.exists) {
    const newOrder: Order = newOrderDoc.data() as Order;
    const newReportReference = { ...report.reportReference, orderIdLinked: true };

    const newReportReferences: ReportReference[] = [
      ...(newOrder.reportReferences ?? []),
      newReportReference,
    ].sort(sortByLastModifiedDateAsc);

    // update the report as well to indicate that it has a link to an existing order
    if (batch != null) {
      batch.update(reportColRef(store, profile.organisationId).doc(report.id), {
        'reportReference.orderIdLinked': true,
      });
    }

    const latestReportReference: ReportReference =
      newReportReferences[newReportReferences.length - 1];

    newOrderUpdateObj.latestReportReference = latestReportReference;
    newOrderUpdateObj.reportReferences = newReportReferences;
  } else {
    if (batch != null) {
      // if no order found, make sure to indicate the link does not exist
      batch.update(reportColRef(store, profile.organisationId).doc(report.id), {
        'reportReference.orderIdLinked': false,
      });
    }
    return batch;
  }

  if (batch != null) {
    batch.update(newOrderDocRef, newOrderUpdateObj);
    return batch;
  } else {
    return newOrderDocRef.update(newOrderUpdateObj);
  }
}

export async function updateOrderReportReferences(
  store: firebase.firestore.Firestore,
  order: Order,
  profile: UserProfile
): Promise<Order> {
  // look for all reports with this orderId in the reference, compare them with the existing report references in the order and update them if necessary
  const reportDocs = await reportColRef(store, profile.organisationId)
    .where('reportReference.orderId', '==', order.id)
    .get();

  if (reportDocs.empty) {
    return order;
  }

  const reports: Report[] = reportDocs.docs.map((d) => d.data() as Report);

  // If report references are not there, add them
  if (!order.reportReferences || order.reportReferences?.length === 0) {
    order.reportReferences = reports.map((r) => ({
      ...r.reportReference,
      orderIdLinked: true,
    }));
  } else {
    for (const report of reports) {
      const currentReference: ReportReference = (order.reportReferences ?? []).find(
        (r) => r.reportId === report.id
      );
      if (!currentReference) {
        order.reportReferences.push({ ...report.reportReference, orderIdLinked: true });
      }
    }
  }

  order.reportReferences = (order.reportReferences ?? []).sort(
    sortByLastModifiedDateAsc
  );

  return order;
}

export async function reopenOrder(
  store: FS,
  profile: UserProfile,
  order: Order,
  orgSettings: OrganisationSettings,
  qcStatus: OrderAGStatus = 'REDO'
) {
  const updateObj: Order = {} as Order;

  updateObj.qcStatus = qcStatus;
  updateObj.lastModifiedDate = new Date();
  updateObj.lastModifiedUserId = profile.id;
  updateObj.lastQCStatusUserId = profile.id;
  updateObj.lastWriteSource = 'UI';
  updateObj.lastQCStatusDate = new Date();

  updateObj.search = generateOrderSearch({ ...order, ...updateObj }, orgSettings);

  return orderColRef(store, profile.organisationId).doc(order.id).update(updateObj);
}

/* ***************************** */
/* Helpers
/* ***************************** */

export function orderToInternalReport(
  order: Order,
  reportType: ReportType,
  profile: UserProfile,
  orgSettings: OrganisationSettings,
  reportName?: string,
  sharingDate?: Date,
  reportStatus?: ReportStatus,
  lastModifiedDate?: Date
): Report {
  let orderCopy = cloneDeep(order);

  // Remove positions with no assessments
  orderCopy = removeInspectionlessPositions(orderCopy as Order);

  // TODO: how report id is built should depend on the stage?
  const id = buildReportIdFromOrder(orderCopy);

  reportName = orderCopy.id;

  const report: Report = {
    ...orderCopy,
    id,
    reportType,
    reportName,
    lastModifiedDate: lastModifiedDate ?? orderCopy.lastModifiedDate,
    estimatedFulfilmentDate: standardizeDate(order.fulfilmentDate),
    createdByUserId: profile.id,
    autogenFromOrder: true,
  };

  report.reportReference = buildReportReference(
    report,
    profile,
    orderCopy,
    report.lastModifiedDate,
    sharingDate,
    reportStatus
  );
  report.reportStatus = reportStatus ?? 'CREATED';
  report.search = generateReportSearch(report, order, orgSettings);
  report.createdByOrgId = order.orgId;

  return report;
}

export const buildReportSubject = (report: Report, orderId: string) => {
  return `${supportedReportTypeMap[report.reportType]} report${
    !!orderId ? ` for order ${orderId}` : ''
  }`;
};

export function internalToExternalReport(report: Report, sameOrgContact: Contact) {
  // TODO: reimplement this logic once onReportWrite trigger is reimplemented

  // Old code for reference:
  // report.search.contactId = report.contactOrder?.order?.contactId;
  // report.search.locationId = report.contactOrder?.order?.locationId;
  // report.reportType = orderToExternalReportTypeMap[report.type];
  // report.contactId = sameOrgContact.id;
  // report.contactName = sameOrgContact.name;

  // const { orderId, externalOrderId } = report.reportReference;

  // // swap order references
  // report.reportReference = {
  //   ...report.reportReference,
  //   orderId: externalOrderId,
  //   externalOrderId: orderId
  // };

  return report;
}

export function buildReportIdFromOrder(order: Order) {
  return [order.orgId, order.id, Date.now()].join(';');
}

export function buildOrderlessReportId(orgId: string) {
  return [orgId, '', Date.now()].join(';');
}

export function buildReportReference(
  report: Report,
  profile?: UserProfile,
  order?: Order,
  lastModifiedDate?: Date,
  sharingDate?: Date,
  reportStatus?: ReportStatus,
  orderIdLinked: boolean = true
): ReportReference {
  return {
    sharingDate,
    reportId: report.id,
    type: report.reportType,
    orgId: report.orgId,
    orderId: order?.id,
    orderIdLinked,
    reportStatus: reportStatus ?? report.reportStatus,
    reportName: report.reportName,
    externalOrderId: report.reportReference?.externalOrderId,
    lastModifiedDate: lastModifiedDate ?? report.lastModifiedDate,
    lastModifiedUserId: profile?.id,
    lastModifiedUserEmail: profile?.email,
    autogenFromOrder: report.autogenFromOrder,
    createdByUserId: report.createdByUserId,
  };
}

export function getReportTitle(entity: Order | Report, longTitle: boolean = false) {
  // TODO ILDE: test the order case of this function
  if (entity == null) {
    return null;
  }

  let order: Order = entity as Order;
  let report: Report = entity as Report;

  let id: string, typeTitle: string, typeString: string;

  if (!!report.reportReference) {
    id = report.reportName ?? report.reportReference.orderId;
    typeString = reportTypeToStringMap[report.reportType];
  } else {
    id = order.id;
    typeString = orderToInternalReportTypeMap[order.type];
  }

  typeTitle = !!typeString ? `${typeString} report` : 'Report';

  if (!longTitle) {
    return !!id ? id : typeTitle;
  }

  return !!id ? `${typeTitle}: ${id}` : typeTitle;
}

export function generateReportName({
  reportName,
  order,
}: {
  reportName?: string;
  order?: Order;
}) {
  if (reportName != null) {
    return reportName;
  }

  if (order != null) {
    return `${order.id}`;
  }

  // TODO: define a better default value
  return `Agrinorm report ${dateToYMD(new Date())}`;
}

// colors are arbitrary
const reportTypeBadgeColorMap: { [key in ReportType]: string } = {
  UPCOMING: 'dark',
  CUSTOM: 'dark',
  FEEDBACK: 'dark',
  INCOMING: 'dark',
  OUTGOING: 'dark',
  STOCK: 'dark',
  OTHER: 'dark',
  POINT_OF_SALE: 'dark',
  POST_PRODUCTION: 'dark',
  POST_HARVEST: 'success',
  PRE_HARVEST: 'success',
  PROMO: 'dark',
  QUALITY_EVOLUTION: 'dark',
};

export function getReportTypeBadgeColor(report: Report) {
  if (!report?.reportType) {
    return 'primary';
  }
  return reportTypeBadgeColorMap[report.reportType] ?? 'primary';
}

export const orderToExternalReportTypeMap: { [key in OrderType]?: ReportType } = {
  BUY: 'FEEDBACK',
  SELL: 'UPCOMING',
};

export const internalToExternalReportTypeMap: { [key in ReportType]?: ReportType } = {
  INCOMING: 'FEEDBACK',
  OUTGOING: 'UPCOMING',
};

export const orderToInternalReportTypeMap: { [key in OrderType]?: ReportType } = {
  BUY: 'INCOMING',
  INTERNAL_TRANSFER: 'INCOMING',
  SELL_RETURN: 'INCOMING',
  SELL: 'OUTGOING',
};

export const supportedInspectionTypeMap: { [key in InspectionType]?: string } = {
  stock: 'Stock',
  point_of_sale: 'Point of sale',
  upcoming: 'Upcoming',
  pre_harvest: 'Pre-harvest',
  outgoing: 'Dispatch',
  incoming: 'Incoming',
  post_production: 'Post-production',
  post_harvest: 'Post-harvest',
  transport: 'Transport',
};

export const supportedReportTypeMap: { [key in ReportType]?: string } = {
  INCOMING: 'Incoming',
  UPCOMING: 'Upcoming',
  STOCK: 'Stock',
  QUALITY_EVOLUTION: 'Quality Evolution',
  OUTGOING: 'Dispatch',
  PROMO: 'Promo',
  // POINT_OF_SALE: 'Point of sale',
  // PRE_HARVEST: 'Pre-harvest',
  // POST_PRODUCTION: 'Post-production',
  // POST_HARVEST: 'Post-harvest',
  // TRANSPORT: 'Transport'
};

export const reportTypeToStringMap: { [key in ReportType]: string } = {
  STOCK: 'Stock',
  UPCOMING: 'Upcoming',
  OUTGOING: 'Dispatch',
  INCOMING: 'Incoming',
  FEEDBACK: 'Feedback',
  CUSTOM: 'Custom',
  OTHER: 'Other',
  PROMO: 'Promo',
  POST_HARVEST: 'Post-harvest',
  PRE_HARVEST: 'Pre-harvest',
  POST_PRODUCTION: 'Post-production',
  POINT_OF_SALE: 'Point of sale',
  QUALITY_EVOLUTION: 'Quality Evolution',
};

export async function addSharedReport(
  store: firebase.firestore.Firestore,
  report: Report,
  users: User[],
  emails: string[],
  profile: UserProfile,
  message: string,
  contact: Contact,
  organisation: Organisation,
  locations: Location[],
  organisationUsers: User[],
  products: ProductView[],
  contacts: Contact[]
) {
  const reportCopy = cloneDeep(report);
  const orgSettings = organisation?.settings;
  let organisationId = profile.organisationId;
  let userSlim = userProfileToUser(profile);
  let usersSlim = users.map((user) => userProfileToUser(user));
  let orgSettingsSlim = pick(orgSettings, [
    'orderSummary',
    'scoreSpace',
    'sharedReportDisplaySettings',
    'qualityScores',
    'inspectionScoresOrder',
    'scoreMappings',
  ]);

  const mapInspectionIds = (inspection) => {
    inspection.locationId = locations.find(
      (l) => l.locationId === inspection.locationId
    )?.name;
    inspection.lastModifiedUserId = organisationUsers.find(
      (u) => u.id === inspection.lastModifiedUserId
    )?.name;
    inspection.lotProperties.article.agProductId = products.find(
      (u) => u.agProductId === inspection.lotProperties.article.agProductId
    )?.agProductId;
  };

  if (reportCopy.hideDefects) {
    Object.entries(reportCopy.lotInspectionMap ?? []).map(([key, inspection]) => {
      inspection.userInputs = {};
    });
    reportCopy.lotMap?.forEach((lot) => {
      lot.inspections.forEach((i) => {
        i.userInputs = {};
      });
    });
  }

  if (reportCopy.hideScores) {
    Object.entries(reportCopy.lotInspectionMap ?? []).map(([key, inspection]) => {
      inspection.userInputs = {};
    });
    reportCopy.lotMap?.forEach((lot) => {
      lot.inspections.forEach((i) => {
        i.scores = {};
      });
    });
  }

  Object.values(reportCopy.lotInspectionMap ?? []).map(mapInspectionIds);

  Object.values(reportCopy.lotMap ?? []).map((lot) => {
    lot.lastModifiedUserId = users.find((u) => u.id === lot.lastModifiedUserId)?.name;
    lot.suppliedByContactId = contacts.find(
      (u) => u.id === lot.suppliedByContactId
    )?.name;
    Object.values(lot.inspections).map(mapInspectionIds);
  });

  console.log('report being shared', reportCopy);

  sharedReportColRef(store)
    .add({
      ...reportCopy,
      contactId: reportCopy.contactName, // replace contact id by name in the shared report
      emails: emails,
      users: usersSlim,
      sharedBy: userSlim,
      feedbackMessage: message,
      orgSettings: orgSettingsSlim,
    } as SharedReport)
    .then((docRef) => {
      const sharedTo: SharedTo = {
        sharedReportId: docRef.id,
        emails: emails,
        users: usersSlim,
        sharedBy: userSlim as User,
        message: message,
        date: new Date() as any,
      };

      setReportAsSharedAndUpdateOrderReportRef(
        store,
        profile,
        report,
        message,
        orgSettings,
        sharedTo
      );

      // Update contacts's emails
      if (!!contact?.id) {
        const contactRef = contactColRef(store, organisationId).doc(contact.id);

        return contactRef.get().then((ref) => {
          if (ref.exists) {
            return contactRef.update({
              contactEmails: [...new Set((contact.contactEmails ?? []).concat(emails))],
              lastContactsUsed: emails,
            });
          }
        });
      }
    });
}

export async function updateLotStatus(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  order: Order,
  inspection: LotInspection,
  status: any,
  report?: Report,
  supplyChainLot: boolean = false
) {
  // TODO ILDE: check that this function updates the lot as intended
  const updateObj = {
    [`${LOT_INSPECTION_MAP}.${inspection.reference.lotId}.status`]: status,
    [`${LOT_INSPECTION_MAP}.${inspection.reference.lotId}.reviewedBy`]: profile.id,
  } as Order;

  let orderId: string, reportId: string;
  // if report != null, we are coming from PageReport, so we  update this particular report
  // if report == null, we are coming from the dashboard and we update the latest report
  if (report != null) {
    orderId = report.reportReference.orderId;
    reportId = report.id;
  } else {
    orderId = order.id;
    reportId = order.latestReportReference?.reportId;
  }

  orderColRef(store, profile.organisationId)
    .doc(orderId)
    .update(updateObj)
    .then(() => {
      console.log(
        'order status updated ' +
          orderId +
          ' lotInspectionMap status updated ' +
          inspection.reference.lotId
      );
      console.log('lot status updated ' + inspection.reference.lotId);
    })
    .catch((error) => {
      console.error('error on updateOrder status from Dashboard', error);
    });

  if (reportId != null) {
    reportColRef(store, profile.organisationId)
      .doc(reportId)
      .update(updateObj)
      .then(() => {
        console.log(
          'report status updated ' +
            reportId +
            ' lotAssessmentMap status updated ' +
            inspection.reference.lotId
        );
        console.log('lot status updated ' + inspection.reference.lotId);
      })
      .catch((error) => {
        console.error('error on update report status from Dashboard', error);
      });
  }

  // update assessment in lot
  const lotRef = lotColRef(
    store,
    profile.organisationId,
    supplyChainLot ? SUPPLY_CHAIN_COL_NAME : COMMON_COL_NAME
  ).doc(inspection.reference.lotId);

  const lot: Lot = (await lotRef.get()).data() as Lot;

  if (!!lot) {
    // find the corresponding assessment in the lot assessments and latestAssesment and update status and reviewedBy
    let inspections: LotInspection[] = lot.inspections;
    let latestInspection: LotInspection = lot.latestInspection;

    const targetInspectionIdx: number = inspections.findIndex((i) =>
      hasInspectionReference(i, inspection.reference)
    );

    inspections = inspections.map((a, idx) =>
      idx === targetInspectionIdx ? { ...a, status, reviewedBy: profile.id } : a
    );

    if (
      !!latestInspection &&
      hasInspectionReference(latestInspection, inspection.reference)
    ) {
      latestInspection = { ...latestInspection, status, reviewedBy: profile.id };
    }

    lotRef
      .update({ inspections, latestInspection })
      .then(() => {
        console.log('lot status updated ' + inspection.reference.lotId);
      })
      .catch((error) => {
        console.error('error on updateLot status from Dashboard', error);
      });
  }
}

export async function addReportAttachments(
  firestore: firebase.firestore.Firestore,
  profile: UserProfile,
  report: Report,
  attachments: string[]
) {
  reportColRef(firestore, profile.organisationId)
    .doc(report.id)
    .update({ attachments });
}

export async function saveContactGroup(
  store: firebase.firestore.Firestore,
  contact: Contact,
  contactGroups: any,
  organisationId: string
) {
  ///organisation/specialfruit.be/meta/meta/contact/L02988

  // Update contacts's emails
  const contactRef = contactColRef(store, organisationId).doc(contact.id);

  return contactRef.update({
    contactGroups: contactGroups,
  });
}

export async function deleteReportAttachment(
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  profile: UserProfile,
  report: Report,
  path: string
) {
  await reportColRef(firestore, profile.organisationId)
    .doc(report.id)
    .update({ attachments: report.attachments.filter((a) => a !== path) });

  // delete actual file from storage
  await deleteAttachmentFromStorage(storage, path);
}

export async function deleteAttachmentFromStorage(
  storage: firebase.storage.Storage,
  path: string
) {
  await storage.ref().child(path).delete();
}
