import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  // IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  arrowForward,
  closeOutline,
  readerOutline,
  warningOutline,
} from 'ionicons/icons';
import React from 'react';
import { firestore } from './ConfigFirebase';
import { getLot } from './DataStorage';
import { getSplitLotTransferedQuantity } from './HelperUtils';
import {
  articleParamMap,
  createSublotOrderId,
  Lot,
  LotPosition,
  Order,
  UserProfile,
} from './Model';
import './PageSplitLot.scss';
import { Article } from './ServiceArticle';

interface Props {
  profile: UserProfile;
  order: Order;
  motherPosition: LotPosition;
  onCancel: Function;
  onSave: (splitLotOrder: Order) => any;
}

interface State {
  childPosition: LotPosition;
  loading: boolean;
  validId: boolean;

  motherPalletIds: string[];
}

class PageSplitLot extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // TODO DEV-818: get rid of quantity and keep only numBoxes
    this.state = {
      childPosition: {
        lotId: '',
        numBoxes: 0,
        volumeInKg: 0,
        palletIds: this.props.motherPosition?.palletIds ?? [],
      },
      validId: true,
      loading: true,
      motherPalletIds: this.props.motherPosition?.palletIds ?? [],
    };
  }

  async componentDidMount(): Promise<void> {
    // check if the id is not used
    let lotId: string;
    let idx: number = 2;
    while (!lotId) {
      const provLotIt = `${this.props.motherPosition.lotId}-${idx}`;
      const lot: Lot = await getLot(
        firestore,
        this.props.profile.organisationId,
        provLotIt
      );
      if (!lot) {
        lotId = provLotIt;
      }
      idx++;
    }
    this.setState({
      loading: false,
      childPosition: { ...this.state.childPosition, lotId },
    });
  }

  articleParamOrder = [
    'agProductId',
    'auxProductId',
    'agVariety',
    'extPackagingRepr',
    'boxNetWeightInKg',
    'boxGrossWeightInKg',
    'numConsumerUnitsInBox',
    'consumerUnitNumberOfPieces',
    'consumerUnitWeightInGrams',
    'avgPieceWeightInGrams',
    'size',
    'origin',
    'brand',
    'isBio',
    'isOrganic',
    'description',
  ];

  positionParamOrder = ['growerId', 'ggn'];
  positionParamMap = {
    growerId: 'Grower id',
    ggn: 'GGN',
  };

  async onSave() {
    const { order, onCancel, onSave, motherPosition, profile } = this.props;
    const { childPosition, motherPalletIds } = this.state;

    const { lotId, growerId, ggn, ggns, article } = motherPosition;

    const splitLotPayload: Order = {
      id: createSublotOrderId(motherPosition.lotId, childPosition.lotId),
      fulfilmentDate: new Date(),
      locationId: order.locationId,
      splitLotOrderIdLink: order.id,
      positions: [
        {
          lotId,
          article,
          growerId: growerId,
          ggn,
          ggns,
          numBoxes: -childPosition.numBoxes,
          volumeInKg: -childPosition.volumeInKg,
          palletIds: motherPalletIds,
        },
        {
          article,
          growerId: growerId,
          ggn,
          ggns,
          lotId: childPosition.lotId,
          numBoxes: childPosition.numBoxes,
          volumeInKg: childPosition.volumeInKg,
          palletIds: childPosition.palletIds,
        },
      ],
      type: 'SPLIT_LOT',
      qcRelevant: false,
      orgId: profile.organisationId,
    };

    this.setState({ loading: true });
    await onSave(splitLotPayload);
    this.setState({ loading: false }, () => onCancel());
  }

  async setLotId(lotId: string) {
    this.setState(
      { childPosition: { ...this.state.childPosition, lotId } },
      async () => {
        if (!lotId || lotId?.length === 0) {
          return this.setState({ validId: false });
        }
        try {
          const lot: Lot = await getLot(
            firestore,
            this.props.profile.organisationId,
            lotId
          );
          this.setState({ validId: !lot });
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  setNumBoxes(numBoxes: number, splitLotTransferedQuantity: number) {
    // check that the quantity is within margin
    if (
      numBoxes >
      this.props.motherPosition.numBoxes - splitLotTransferedQuantity - 1
    ) {
      numBoxes = this.props.motherPosition.numBoxes - splitLotTransferedQuantity - 1;
    }
    if (numBoxes < 0) {
      numBoxes = 0;
    }
    const volumeInKg = new Article(this.props.motherPosition.article).computeVolumeInKg(
      numBoxes
    );
    this.setState({
      childPosition: { ...this.state.childPosition, volumeInKg, numBoxes },
    });
  }

  setVolume(volumeInKg: number, splitLotTransferedVolume: number) {
    // check that the vol is within margin
    if (
      volumeInKg >
      this.props.motherPosition.volumeInKg - splitLotTransferedVolume - 1
    ) {
      volumeInKg = this.props.motherPosition.volumeInKg - splitLotTransferedVolume - 1;
    }
    if (volumeInKg < 0) {
      volumeInKg = 0;
    }
    this.setState({ childPosition: { ...this.state.childPosition, volumeInKg } });
  }

  isRaw(): boolean {
    return new Article(this.props.motherPosition?.article).isRaw();
  }

  checkPallets(motherLotBoxes: number, motherLotVolume: number) {
    const { childPosition } = this.state;
    const originalPallets = this.props.motherPosition?.palletIds;

    if (originalPallets == null || originalPallets?.length === 0) {
      // if mother position has no pallets, just go ahead and don't check anything
      return undefined;
    }

    const quantity = this.isRaw() ? childPosition.volumeInKg : childPosition.numBoxes;
    const motherLotQuantity = this.isRaw() ? motherLotVolume : motherLotBoxes;

    const sublotPallets = childPosition.palletIds;
    const motherPallets = this.state.motherPalletIds;

    const missingPallets = originalPallets.filter(
      (pId) => !sublotPallets.includes(pId) && !motherPallets.includes(pId)
    );

    const unit = new Article(this.props.motherPosition?.article).getUnit(quantity);

    if (originalPallets.length === 0) {
      return `Mother batch has no pallets left. Please select a different batch`;
    }
    if (missingPallets.length > 0) {
      return `Following pallets are missing: ${missingPallets.join(', ')}`;
    }
    if (sublotPallets?.length === 0 && originalPallets?.length > 0) {
      return `Child batch must have at least 1 pallet`;
    }
    if ((motherLotQuantity - quantity ?? 0) <= 0 && motherPallets.length > 0) {
      return `Mother batch has no ${unit} left, but it still has ${motherPallets.length} pallets`;
    }
    if ((motherLotQuantity - quantity ?? 0) > 0 && motherPallets.length === 0) {
      return `Mother batch has no pallets left, but it still has ${
        isNaN(quantity) ? motherLotQuantity : motherLotQuantity - quantity
      } ${unit}`;
    }
    return undefined;
  }

  warningWrapper(content: any) {
    return (
      <IonItem color="danger" className="warning-wrapper">
        <IonIcon icon={warningOutline} />
        <div>{content}</div>
      </IonItem>
    );
  }

  isQuantityOk(): boolean {
    const { childPosition } = this.state;
    if (this.isRaw()) {
      return !isNaN(childPosition.volumeInKg) && childPosition.volumeInKg > 0;
    } else {
      return !isNaN(childPosition.numBoxes) && childPosition.numBoxes > 0;
    }
  }

  render() {
    const { motherPosition, order } = this.props;
    const { childPosition, loading, validId, motherPalletIds } = this.state;
    const { lotId } = childPosition;
    const { article } = motherPosition;

    const articleClass = new Article(article);

    // calculate quantity that has already been transfered to other sublots
    let splitLotTransferedBoxes: number = getSplitLotTransferedQuantity(
      order,
      motherPosition,
      'numBoxes'
    );
    const actualMotherBoxes: number = motherPosition.numBoxes - splitLotTransferedBoxes;

    let splitLotTransferedVolume: number = getSplitLotTransferedQuantity(
      order,
      motherPosition,
      'volumeInKg'
    );
    const actualMotherVolume: number =
      motherPosition.volumeInKg - splitLotTransferedVolume;

    const actualMotherQuantity = this.isRaw() ? actualMotherVolume : actualMotherBoxes;

    let palletProblem = this.checkPallets(actualMotherBoxes, actualMotherVolume);
    console.log('RENDER PALLET', palletProblem);

    const saveDisabled =
      !validId || !this.isQuantityOk() || !!palletProblem || actualMotherQuantity <= 0;

    const displayedMotherQuantity = this.isRaw()
      ? isNaN(childPosition.volumeInKg)
        ? actualMotherVolume
        : actualMotherVolume - childPosition.volumeInKg
      : isNaN(childPosition.numBoxes)
      ? actualMotherBoxes
      : actualMotherBoxes - childPosition.numBoxes;

    const displayedChildQuantity = this.isRaw()
      ? isNaN(childPosition.volumeInKg)
        ? 0
        : childPosition.volumeInKg
      : isNaN(childPosition.numBoxes)
      ? 0
      : childPosition.numBoxes;

    const unit = articleClass.getUnit();

    return (
      <div className="ion-page page-split-lot">
        <IonLoading isOpen={loading} />
        <IonHeader>
          <IonToolbar>
            <IonTitle>Split batch</IonTitle>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={() => this.props.onCancel()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                fill="solid"
                data-tip={`split-lot-save`}
                onClick={(_) => this.onSave()}
                disabled={saveDisabled}
              >
                <IonIcon icon={readerOutline} slot="start" />
                Save Changes
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {!!palletProblem && this.warningWrapper(palletProblem)}
          <IonList>
            <IonItem>
              <IonLabel>Child batch id</IonLabel>
              <IonLabel style={{ textAlign: 'right' }}>{lotId}</IonLabel>
              {/* <IonInput inputmode="text" type="text" placeholder="(enter batch id)" debounce={500} value={lotId} disabled
              onFocus={(e: any) => e?.target?.select()}
              onIonChange={(evt) => this.setLotId(evt.detail.value)} /> */}
            </IonItem>

            <IonItemDivider color="medium">Transfer</IonItemDivider>

            {!articleClass.isRaw() ? (
              <>
                <IonItem className="item-has-focus">
                  <IonLabel>
                    Boxes*{' '}
                    <i>
                      <b>required</b>
                    </i>{' '}
                  </IonLabel>
                  <IonInput
                    inputmode="numeric"
                    data-tip={`split-lot-amount`}
                    autofocus={true}
                    type="number"
                    placeholder="(enter amount)"
                    value={
                      childPosition.numBoxes > 0 ? childPosition.numBoxes : undefined
                    }
                    onFocus={(e: any) => e?.target?.select()}
                    className="amount-input"
                    onIonChange={(evt) =>
                      this.setNumBoxes(
                        parseInt(evt.detail.value),
                        splitLotTransferedBoxes
                      )
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>Volume (Kg)</IonLabel>
                  <IonInput
                    inputmode="numeric"
                    autofocus={true}
                    type="number"
                    value={childPosition.volumeInKg}
                    disabled
                    className="amount-input"
                  />
                </IonItem>
              </>
            ) : (
              <IonItem className="item-has-focus">
                <IonLabel>
                  Volume (Kg) *
                  <i>
                    <b>required</b>
                  </i>{' '}
                </IonLabel>
                <IonInput
                  inputmode="numeric"
                  data-tip={`split-lot-volume`}
                  autofocus={true}
                  type="number"
                  placeholder="(enter volume)"
                  value={
                    childPosition.volumeInKg > 0 ? childPosition.volumeInKg : undefined
                  }
                  onFocus={(e: any) => e?.target?.select()}
                  className="amount-input"
                  onIonChange={(evt) =>
                    this.setVolume(parseInt(evt.detail.value), splitLotTransferedVolume)
                  }
                />
              </IonItem>
            )}

            <IonItem>
              <IonGrid className="transfer-grid ion-text-center ion-align-item-center">
                <IonRow>
                  <IonCol size="5">
                    <b>{motherPosition.lotId}</b>
                  </IonCol>
                  <IonCol size="2" className="ion-align-item-center">
                    <IonIcon icon={arrowForward} />
                  </IonCol>
                  <IonCol size="5">
                    <b>{childPosition.lotId}</b>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5">
                    {unit}: <b>{displayedMotherQuantity}</b>
                  </IonCol>
                  <IonCol size="2">{/* <IonIcon icon={arrowForward} /> */}</IonCol>
                  <IonCol size="5">
                    {unit}: <b>{displayedChildQuantity}</b>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>

            {actualMotherBoxes <= 0 &&
              this.warningWrapper(
                `Mother lot has no boxes left, please select a different mother lot`
              )}

            {motherPosition?.palletIds?.length > 0 && (
              <>
                <IonItemDivider color="medium">Pallets</IonItemDivider>
                <IonItem>
                  <IonGrid className="ion-text-center ion-align-item-center">
                    <IonRow>
                      <IonCol size="6" className="ion-text-left">
                        <b>Nr.</b>
                      </IonCol>
                      <IonCol size="3">
                        <b>{motherPosition.lotId}</b>
                      </IonCol>
                      <IonCol size="3">
                        <b>{childPosition.lotId}</b>
                      </IonCol>
                    </IonRow>
                    {motherPosition.palletIds.map((pId) => {
                      return (
                        <IonRow key={pId} className="ion-align-items-left">
                          <IonCol size="6" className="ion-text-left">
                            {pId}
                          </IonCol>
                          <IonCol size="3">
                            <IonCheckbox
                              style={{ margin: 'auto' }}
                              checked={motherPalletIds.includes(pId)}
                              onIonChange={(e) =>
                                e.detail.checked
                                  ? this.setState({
                                      motherPalletIds: [...motherPalletIds, pId],
                                    })
                                  : this.setState({
                                      motherPalletIds: motherPalletIds.filter(
                                        (id) => id !== pId
                                      ),
                                    })
                              }
                            />
                          </IonCol>
                          <IonCol size="3">
                            <IonCheckbox
                              style={{ margin: 'auto' }}
                              checked={childPosition.palletIds.includes(pId)}
                              onIonChange={(e) =>
                                e.detail.checked
                                  ? this.setState({
                                      childPosition: {
                                        ...childPosition,
                                        palletIds: [...childPosition.palletIds, pId],
                                      },
                                    })
                                  : this.setState({
                                      childPosition: {
                                        ...childPosition,
                                        palletIds: childPosition.palletIds.filter(
                                          (id) => id !== pId
                                        ),
                                      },
                                    })
                              }
                            />
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonGrid>
                </IonItem>
              </>
            )}

            {!validId &&
              this.warningWrapper(
                lotId == null || lotId.length === 0
                  ? `Batch id cannot be empty`
                  : `Batch id ${lotId} is already in use. Please pick a different one`
              )}

            <IonItemDivider color="medium">Article properties</IonItemDivider>
            {this.articleParamOrder
              .filter((param) => !!article[param])
              .map((param) => {
                const label = articleParamMap[param];
                let value = article[param];
                if (param === 'extPackagingRepr' && value == null) {
                  value = articleClass.getPackagingRepr();
                }

                return (
                  <IonItem key={param}>
                    <IonLabel>{label}</IonLabel>
                    <IonInput inputmode="text" type="text" disabled value={value} />
                  </IonItem>
                );
              })}

            {this.positionParamOrder.filter((param) => !!motherPosition[param]).length >
              0 && (
              <>
                <IonItemDivider color="medium">Position properties</IonItemDivider>
                {this.positionParamOrder
                  .filter((param) => !!motherPosition[param])
                  .filter((param) => !!motherPosition[param])
                  .map((param) => {
                    return (
                      <IonItem key={param}>
                        <IonLabel>{this.positionParamMap[param]}</IonLabel>
                        <IonInput
                          inputmode="text"
                          type="text"
                          disabled
                          value={motherPosition[param]}
                        />
                      </IonItem>
                    );
                  })}
              </>
            )}
          </IonList>
          {/* <div className="bottom-buttons">

          <IonButton color="primary" onClick={_ => this.onSave()} disabled={saveDisabled}>
            <IonIcon icon={readerOutline} slot="start" />
            Save child batch
          </IonButton>
        </div> */}
        </IonContent>
      </div>
    );
  }
}

export default PageSplitLot;
