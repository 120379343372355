/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTaskRequest } from '../../../platform/tasks'
import type { TaskListResponse } from '../../../platform/tasks'
import type { TaskResponse } from '../../../platform/tasks'
import type { TaskSearch } from '../../../platform/tasks'
import type { UpdateTaskRequest } from '../../../platform/tasks'
import type { CancelablePromise } from '../../@api_core/CancelablePromise'
import type { BaseHttpRequest } from '../../@api_core/BaseHttpRequest'
export class TaskService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get All Tasks
     * @returns TaskListResponse Successful Response
     * @throws ApiError
     */
    public getAllTasks(): CancelablePromise<TaskListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/task',
            errors: {
                401: `Not authenticated`,
            },
        })
    }
    /**
     * Add Task
     * @returns TaskResponse Successful Response
     * @throws ApiError
     */
    public addTask({ requestBody }: { requestBody: CreateTaskRequest }): CancelablePromise<TaskResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/task',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Tasks Search
     * @returns TaskListResponse Successful Response
     * @throws ApiError
     */
    public getTasksSearch({ requestBody }: { requestBody: TaskSearch }): CancelablePromise<TaskListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/task/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Delete Task
     * Deletes a task
     * @returns string Successful Response
     * @throws ApiError
     */
    public deleteTask({ id }: { id: string }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/task/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Not authenticated`,
                404: `Task not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Task
     * @returns TaskResponse Successful Response
     * @throws ApiError
     */
    public getTask({ taskId }: { taskId: string }): CancelablePromise<TaskResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/task/{task_id}',
            path: {
                task_id: taskId,
            },
            errors: {
                401: `Not authenticated`,
                404: `Task not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Update Task
     * @returns TaskResponse Successful Response
     * @throws ApiError
     */
    public updateTask({
        taskId,
        requestBody,
    }: {
        taskId: string
        requestBody: UpdateTaskRequest
    }): CancelablePromise<TaskResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/task/{task_id}',
            path: {
                task_id: taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                404: `Task not found`,
                422: `Validation Error`,
            },
        })
    }
}
