import { toastController } from '@ionic/core';
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonDatetime,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import {
  add,
  arrowDownOutline,
  arrowUpOutline,
  checkbox,
  checkboxOutline,
  checkmarkDoneCircleOutline,
  closeCircle,
  colorWandOutline,
  cubeOutline,
  documentAttachOutline,
  documentTextOutline,
  filterOutline,
  receiptOutline,
  syncOutline,
} from 'ionicons/icons';
import React, { RefObject, useContext, useState } from 'react';
import {
  ctxContacts,
  ctxLocations,
  ctxOrg,
  ctxProducts,
  ctxProfile,
  delay,
} from './App';
import CardLot from './CardLot';
import CardOrder from './CardOrder';
import { firestore } from './ConfigFirebase';
import {
  PagedResult,
  addOrRemoveOrderReferenceInLotInspections,
  createLotFromContactPosition,
  createNewLot,
  createNewOrder,
  createSupplyChainLot,
  getLot,
  saveOrderWrapper,
} from './DataStorage';
import { isMobile, lotHasQuantity } from './HelperUtils';
import { cloneDeep, find, get, isEqual } from 'lodash';
import Select from 'react-select';
import { getLots, getOrders, isOnline } from './DataApp';
import {
  FABButtonModel,
  Lot,
  LotCreatePayload,
  LotPosition,
  Order,
  OrderAGStatuses,
  OrderCreatePayload,
  OrderType,
  ProductionPayload,
  ProductionSiteLocation,
  Report,
  Todo,
  UserProfile,
  dateToString,
} from './Model';
import {
  AGTab,
  AGTabs,
  QCFilterType,
  defaultAgTabs,
  defaultAgTabs as defaultAgTabsState,
  qcTabsOrderMap,
} from './ModelAGTabs';
import PageFilters from './PageFilters';
import PageNewLot from './PageNewLot';
import './PageQualityControl.scss';
import { hasLocationRestriction, userHasPermission } from './PermissionsService';
import {
  AGFilters,
  SearchField,
  adaptSectionFilters,
  applyFieldFilters,
  computeNewFilters,
  searchFields,
} from './SearchService';

import firebase from 'firebase/compat/app';
import _ from 'lodash';
import { TodoList } from './CTodoList';
import { addReferencesToOrder, setReport } from './DataReport';
import { TodoReorderOptions, createTodos, getTodos, reorderTodos } from './DataTodo';
import { SUPPLY_CHAIN_URL_SUFFIX } from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import { newInspectionForLot } from './InspectionLogic';
import { LotInspection, LegacyInspectionReference } from './InspectionModel';
import PageLotTransformation from './PageLotTransformation';
import PageNewOrder from './PageNewOrder';
import PageNewReport from './PageNewReport';
import { InspectionClass, stringifyInspectionReference } from './ServiceInspection';
import { CDatePicker } from './components/CDatePicker';
import { CardField } from './CardField';
import { useLocationUtils } from './hooks/useLocationUtils';
import { TimeLabel } from './ModelInsight';

interface Props {
  history: any;
  onUserPropertiesChanged: Function;
}

interface Filter {
  search?: string;
  type: QCFilterType;
}

interface ScrollTop {
  stock: number;
  upcoming: number;
  batches: number;
  orders: number;
}

interface State {
  orders?: PagedResult<Order>;
  lots?: PagedResult<Lot>;
  stock?: PagedResult<Todo>;
  externalLots?: PagedResult<Lot>;

  orderData?: any;

  filter: Filter;
  batchesNoMore?: boolean;

  showNewLotModal?: boolean;
  showNewOrderModal?: boolean;
  showNewReportModal?: boolean;
  showBatchOperationModal?: boolean;
  showCalendar?: boolean;

  showNewDispatchOrderModal?: boolean;

  showLinkLotModal?: boolean;
  filters?: AGFilters;
  loadingMore: boolean;
  showListMode?: boolean;
  changingFilters?: boolean;

  loadingOfflineCache: boolean;
  loadingSelectAll?: boolean;
  multipleSelection: boolean;
  multipleSelectionArray: Array<Lot>;
  scrollTop: ScrollTop;

  // set from CardOrder
  currContactPosition: LotPosition;
  currOrder: Order;

  alert: { message: string; header: string; show: boolean; onAccept: any };
  processing: boolean;

  agTabs: AGTabs<QCFilterType>;

  closeLotOptionsFromCardOrder?: () => void;
}

class PageQualityControl extends React.Component<Props & ContextProps, State> {
  private pageRef: RefObject<HTMLElement> = React.createRef();
  private contentRef: RefObject<HTMLIonContentElement> = React.createRef();
  private searchBarRef: RefObject<HTMLIonSearchbarElement> = React.createRef();

  private defaultAlert = { message: '', header: '', show: false, onAccept: (_) => {} };

  private stopInfiniteScroll;
  private lotSubscriptions = [];
  private stockSubscriptions = [];
  private incomingSubscriptions = [];
  private outgoingSubscriptions = [];

  private upcomingLotSubscriptions = [];

  private throttledScroll;

  savedFiltersKey = 'qc-filters';

  constructor(props: Props & ContextProps) {
    super(props);

    const parameters = new URLSearchParams(window.location.search);
    const profileAGTabs: QCFilterType = get(this.props.profile, 'displayAGTabs[0]');

    const startingTab: QCFilterType = profileAGTabs ?? 'ORDERS';

    // console.log('starting tab BLYN: ', startingTab, profileAGTabs)
    const startType: Filter = {
      type: (startingTab ?? parameters.get('tab')?.toLocaleUpperCase()) as QCFilterType,
    };

    this.throttledScroll = _.throttle((e) => {
      document.getElementById('searchbar-container')?.classList?.add('animated');
      const el = document.getElementById('searchbar-container');
      if (e.detail.deltaY === 0) return;
      if (e.detail.deltaY > 0) {
        if (!el.classList.contains('out')) el.classList.add('out');
      } else {
        if (!el.classList.contains('in')) el.classList.add('in');
        if (el.classList.contains('out')) el.classList.remove('out');
      }
      if (e.detail.scrollTop < 112) {
        el.classList.remove('out');
      }
      if (e.detail.scrollTop < 10) {
        el.classList.remove('shadow');
      } else {
        el.classList.add('shadow');
      }
    }, 100);

    const defaultFilterState: Filter = startType;

    this.state = {
      filter: defaultFilterState,
      loadingMore: false,
      loadingOfflineCache: false,
      multipleSelection: false,
      scrollTop: {
        stock: 0,
        upcoming: 0,
        orders: 0,
        batches: 0,
      },
      multipleSelectionArray: [],
      filters: {
        ...this.props.filters,
        arrivalDate: defaultAgTabsState[defaultFilterState.type]?.minDate,
        arrivalDateMax: defaultAgTabsState[defaultFilterState.type]?.maxDate,
        dateProperty: defaultAgTabsState[defaultFilterState.type]?.dateProperty,
        sort: defaultAgTabsState[defaultFilterState.type]?.sorting,
      },
      currContactPosition: undefined,
      currOrder: undefined,
      alert: this.defaultAlert,
      processing: false,
      agTabs: defaultAgTabsState,
    };
  }

  componentDidMount() {
    // set stage as the user default
    const profileAGTabs = get(this.props.profile, 'displayAGTabs[0]');
    const parameters = new URLSearchParams(window.location.search);

    const filterType: Filter = {
      type: (profileAGTabs ??
        parameters.get('tab')?.toLocaleUpperCase()) as QCFilterType,
    };
    if (!!filterType.type) {
      this.setState({ filter: filterType });
    }
    this.fetchData();
    this.getSummary();
  }

  async getSelectAll(filters?: AGFilters, toastBefore?) {
    const getSelectAll = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('getSelectAll');
    const tab = this.state.agTabs[this.state.filter.type];
    try {
      await this.setState({ loadingSelectAll: true });

      let minTime = new Date(tab.minDate.getTime());
      minTime.setDate(minTime.getDate());
      minTime.setHours(0, 0, 0, 0);

      let maxTime = new Date(tab.maxDate.getTime());
      maxTime.setDate(maxTime.getDate());
      maxTime.setHours(23, 59, 59, 0);

      const params = {
        minDate: minTime.getTime(),
        maxDate: maxTime.getTime(),
        filters: filters ?? this.state.filters,
        tab: this.state.filter.type,
      };

      console.log('call getSelectAll', params);
      const { data } = await getSelectAll(params);
      console.log('response getSelectAll', data);

      this.setState({
        multipleSelectionArray: data.data,
        loadingSelectAll: false,
      });

      if (toastBefore) await toastBefore?.dismiss();

      const toast = await toastController.create({
        message: `<b>${data.data.length} BATCHES HAVE BEEN SELECTED.</b>${
          data.data.length === 100
            ? '<br/>There might be more lots that have not been selected. Please narrow your search'
            : ''
        }`,
        position: 'bottom',
        color: 'dark',
        duration: 5000,
        cssClass: 'toast-fade-in-up',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          },
        ],
      });
      toast.present().then();
    } catch (e) {
      this.setState({ loadingSelectAll: false });
      console.log('UPS');
      console.error(e);
      if (toastBefore) await toastBefore?.dismiss();
      const toast = await toastController.create({
        message: !navigator.onLine
          ? `<b>ERROR: You are offline</b>`
          : '<b>ERROR: There was an error</b> ',
        position: 'bottom',
        color: 'danger',
        duration: 5000,
        cssClass: 'toast-fade-in-up',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          },
        ],
      });
      toast.present().then();
    }
  }

  async getSummary(filters?: AGFilters) {
    // disable for now
    if (this.state) return;

    console.log('==>', this.state.filters);
    const getSummary = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('getSummary');
    try {
      this.setState({ orderData: undefined });

      let minTime = new Date(this.state.agTabs.ORDERS.minDate.getTime());
      minTime.setDate(minTime.getDate());
      minTime.setHours(0, 0, 0, 0);

      let maxTime = new Date(this.state.agTabs.ORDERS.maxDate.getTime());
      maxTime.setDate(maxTime.getDate());
      maxTime.setHours(23, 59, 59, 0);

      const { data } = await getSummary({
        minDate: minTime.getTime(),
        maxDate: maxTime.getTime(),
        filters: filters ?? this.state.filters,
      });
      console.log('getSummaryOrders', data);
      this.setState({ orderData: data });
    } catch (e) {
      console.log('UPS');
      this.setState({ orderData: { total: '-' } });
      console.error(e);
    }
  }

  async componentDidUpdate(prevProps: Props & ContextProps, prevState: State) {
    // update content scrollTop to previous recorded one
    // only when the filter.type has changed
    // to keep scroll in position when clicking the segment bar
    if (prevState.filter.type !== this.state.filter.type)
      this.contentRef.current.scrollToPoint(
        0,
        this.state.scrollTop[this.state.filter?.type?.toLocaleLowerCase()]
      );

    // check for changes in the permissions
    if (
      !isEqual(prevProps.profile.userPermissions, this.props.profile.userPermissions)
    ) {
      this.fetchData();
    }

    // Check for changes in the profile tab selection
    if (!isEqual(prevProps.profile.displayAGTabs, this.props.profile.displayAGTabs)) {
      const userHasTab = find(
        this.props.profile.displayAGTabs,
        (tab) => this.state.filter.type === tab
      );
      if (!userHasTab) {
        this.setState({
          filter: {
            ...this.state.filter,
            type: this.props.profile?.displayAGTabs[0],
          },
        });
      }
    }

    // check for changes in date filters
    if (!isEqual(prevState.agTabs, this.state.agTabs)) {
      this.fetchData();

      if (
        prevState.agTabs.ORDERS.maxDate !== this.state.agTabs.ORDERS.maxDate ||
        prevState.agTabs.ORDERS.minDate !== this.state.agTabs.ORDERS.minDate
      ) {
        this.getSummary();
      }
    }

    // check for changes in filters.article
    if (!isEqual(prevState.filters?.article, this.state.filters?.article)) {
      this.fetchData();
    }

    // remove empty/undefined
    const prevFilters = _.pickBy(prevProps.filters, _.identity);
    const actualFilters = _.pickBy(this.props.filters, _.identity);

    if (
      (!isEqual(prevFilters, actualFilters) ||
        this.props.filters?.hasAssessment !== this.state.filters?.hasAssessment) &&
      this.props.filters != null
    ) {
      // recalculate summary when lot or order-type have changed only
      if (
        prevFilters.orderType?.value !== actualFilters.orderType?.value ||
        !_.isEqual(prevFilters.article, actualFilters.article) ||
        !_.isEqual(prevFilters.locationId, actualFilters.locationId) ||
        !_.isEqual(prevFilters.qcScore, actualFilters.qcScore) ||
        !_.isEqual(prevFilters.userId, actualFilters.userId)
      ) {
        this.getSummary(actualFilters);
      }

      this.setState(
        {
          loadingMore: true,
          filters: {
            ...computeNewFilters(this.state.filters, this.props.filters),
            search: '',
          },
          // TODO: fine tune to reload only the ones affected by the changed filters
          orders: undefined,
          lots: undefined,
          externalLots: undefined,
          stock: undefined,
        },
        () => {
          try {
            this.fetchData();
          } catch (err) {
            console.error('error while loading fetchDataFiltered:', err);
          }
        }
      );
    }
  }

  componentWillUnmount(): void {
    this.lotSubscriptions.forEach((unsubscribe) => unsubscribe?.());
    this.outgoingSubscriptions.forEach((unsubscribe) => unsubscribe?.());
    this.incomingSubscriptions.forEach((unsubscribe) => unsubscribe?.());
    this.stockSubscriptions.forEach((unsubscribe) => unsubscribe?.());
    this.upcomingLotSubscriptions.forEach((unsubscribe) => unsubscribe?.());
  }

  ionViewWillLeave() {
    document.getElementById('searchbar-container')?.classList?.remove('animated');
  }

  openNewLotModal(
    currentPosition: LotPosition,
    order: Order,
    closeLotOptionsFromCardOrder?: () => void
  ) {
    this.setState({
      showNewLotModal: true,
      currContactPosition: currentPosition,
      currOrder: order,
      closeLotOptionsFromCardOrder,
    });
  }

  openLinkLotModal(
    currentPosition: LotPosition,
    order: Order,
    closeLotOptionsFromCardOrder?: () => void
  ) {
    this.setState({
      showLinkLotModal: true,
      currContactPosition: currentPosition,
      currOrder: order,
      closeLotOptionsFromCardOrder,
    });
  }

  async fetchData(more?: boolean) {
    if (!userHasPermission(this.props.profile, 'VIEW', 'ORDER')) {
      this.setState({
        // incomingOrders: { data: [], cursor: undefined },
        stock: { data: [], cursor: undefined },
        orders: { data: [], cursor: undefined },
        externalLots: { data: [], cursor: undefined },
        lots: { data: [], cursor: undefined },
        loadingMore: false,
      });
      return;
    }
    const max: number = more ? 12 : 12;
    const { stock, lots, filter, orders, externalLots: upcomingLots } = this.state;
    const { profile } = this.props;
    const { organisationId } = profile;

    let { filters } = this.state;
    let filterType: QCFilterType = this.state.filter.type;
    filters.dateProperty = this.state?.agTabs[this.state.filter.type]?.dateProperty;
    filters.sort = this.state?.agTabs[this.state.filter.type]?.sorting;
    filters.arrivalDate = this.state.agTabs[this.state.filter.type]?.minDate;
    filters.arrivalDateMax = this.state.agTabs[this.state.filter.type]?.maxDate;

    const isSurveyor = userHasPermission(profile, 'DO', 'SURVEYING');

    let onlyNoTransfers = isSurveyor;
    let seeAllLots = userHasPermission(profile, 'LINK', 'LOT') && !isSurveyor;
    let search = filter.search; // what is inside the search bar input
    let page, orderType: OrderType;

    filters = adaptSectionFilters(
      filters,
      searchFields.PageQualityControl[filter.type]
    );

    switch (filterType) {
      case 'STOCK':
        page = more ? stock : undefined;
        if (!page) {
          this.setState({ stock: undefined });
          this.stockSubscriptions.forEach((unsubscribe) => unsubscribe?.());
          this.stockSubscriptions = [];
        }
        this.stockSubscriptions.push(
          getTodos(
            firestore,
            organisationId,
            page,
            profile,
            20,
            filters,
            search,
            (result) => {
              result.data = _.uniqBy(result.data, 'id');
              this.setState({ stock: result, loadingMore: false });
            }
          )
        );
        return;

      case 'BATCHES':
        page = more ? lots : undefined;
        if (!page) {
          this.setState({ lots: undefined });
          this.lotSubscriptions.forEach((unsubscribe) => unsubscribe?.());
          this.lotSubscriptions = [];
        }
        this.lotSubscriptions.push(
          getLots(
            firestore,
            organisationId,
            search,
            page,
            max,
            filters,
            onlyNoTransfers,
            seeAllLots,
            (result) => {
              this.setState({
                lots: result,
                loadingMore: false,
              });
              this.stopInfiniteScroll = false;
            },
            hasLocationRestriction(this.props.profile),
            undefined,
            this.props.profile
          )
        );
        return;

      case 'ORDERS':
        page = more ? orders : undefined;
        if (!page) {
          this.setState({ orders: undefined });
          this.incomingSubscriptions.forEach((unsubscribe) => unsubscribe?.());
          this.incomingSubscriptions = [];
        }
        // TODO: orderType should not be a parameter, it should come inside of "filters"
        orderType = filters?.orderType?.value;
        this.incomingSubscriptions.push(
          getOrders(
            firestore,
            organisationId,
            search,
            orderType,
            page,
            max,
            filters,
            organisationId,
            async (result) => {
              if (this.state.orders?.data.length > result.data.length) {
                return;
              }
              this.setState({ orders: result, loadingMore: false });
              this.stopInfiniteScroll = false;
            },
            hasLocationRestriction(this.props.profile),
            undefined,
            this.props.profile
          )
        );
        return;

      case 'SUPPLY-CHAIN':
        page = more ? upcomingLots : undefined;
        if (!page) {
          this.setState({ externalLots: undefined });
          this.upcomingLotSubscriptions.forEach((unsubscribe) => unsubscribe?.());
          this.upcomingLotSubscriptions = [];
        }
        getLots(
          firestore,
          organisationId,
          search,
          page,
          max,
          filters,
          onlyNoTransfers,
          seeAllLots,
          (result) => {
            if (this.state.externalLots?.data.length > result.data.length) {
              return;
            }
            this.setState({ externalLots: result, loadingMore: false });
            this.stopInfiniteScroll = false;
          },
          hasLocationRestriction(this.props.profile),
          true,
          this.props.profile
        );
        return;
    }
  }

  private async fetchDataFiltered(filters: AGFilters) {
    this.props.setFilters(filters);
  }

  async searchByText(value: string) {
    const isField = this.state.filter.type === 'FIELD_INSPECTIONS';

    if (value.length > 0 && value.length < 4 && !isField) {
      return;
    }
    this.contentRef.current.scrollToTop(500);
    value = value.replace(/\./gi, '/');
    const locationRestriction = hasLocationRestriction(this.props.profile);
    let state = {
      loadingMore: true,
      batchesNoMore: false,
      filter: {
        ...this.state.filter,
        search: value.toLocaleUpperCase().replace(/\//g, '-'),
      },
      filters: {
        ...this.state.filters,
        search: value.toLocaleUpperCase().replace(/\//g, '-'),
      },
      orders: undefined,
      lots: undefined,
      externalLots: undefined,
      stock: undefined,
    } as any;

    if (locationRestriction) {
      state.filter.locationId = locationRestriction as any;
    }

    await this.setState(state);
    if (!isField) await this.fetchData();
  }

  async changeFilterType(value: QCFilterType) {
    // get the scrollTop and save it
    let domScroll = await this.contentRef.current.getScrollElement();

    this.setState({
      multipleSelection: false,
      multipleSelectionArray: [],
      changingFilters: true,
      scrollTop: {
        upcoming:
          this.state.filter.type === 'SUPPLY-CHAIN'
            ? domScroll.scrollTop
            : this.state.scrollTop.upcoming,
        batches:
          this.state.filter.type === 'BATCHES'
            ? domScroll.scrollTop
            : this.state.scrollTop.batches,
        stock:
          this.state.filter.type === 'STOCK'
            ? domScroll.scrollTop
            : this.state.scrollTop.stock,
        orders:
          this.state.filter.type === 'ORDERS'
            ? domScroll.scrollTop
            : this.state.scrollTop.orders,
      },
      filter: {
        search: this.state.filter.search,
        type: value,
      },
      filters: {
        ...this.state.filters,
        arrivalDate: this.state.agTabs[value]?.minDate,
        arrivalDateMax: this.state.agTabs[value]?.maxDate,
        dateProperty: this.state.agTabs[value]?.dateProperty,
        sort: this.state.agTabs[value]?.sorting,
        search: this.state.filter.search,
      },
    });

    // fetch only when there is no data in state
    if (
      (value === 'BATCHES' && !this.state.lots) ||
      (value === 'ORDERS' && !this.state.orders) ||
      (value === 'STOCK' && !this.state.stock) ||
      (value === 'SUPPLY-CHAIN' && !this.state.externalLots)
    ) {
      this.contentRef.current.scrollToTop(500);
      return this.fetchData();
    }

    document.getElementById('searchbar-container').classList.remove('out');
  }

  addMultipleSelection(lot: Lot) {
    if (!this.state.multipleSelection) {
      return;
    }

    let { multipleSelectionArray } = Object.assign({}, this.state);
    let index = multipleSelectionArray.findIndex((p) => p.id === lot.id);

    if (index >= 0) {
      multipleSelectionArray.splice(index, 1);
    } else {
      multipleSelectionArray.push(lot);
    }
    this.setState({ multipleSelectionArray });
  }

  toggleMultipleSelection() {
    const { multipleSelection } = this.state;
    if (!multipleSelection) {
      this.setState({ multipleSelection: !multipleSelection });
    } else {
      this.setState({
        multipleSelection: !multipleSelection,
        multipleSelectionArray: [],
      });
    }
  }

  async selectAll() {
    const toast = await toastController.create({
      message:
        'Wait until all batches in your search have been selected.<br/><b>MAXIMUM AMMOUNT: 100</b>',
      position: 'bottom',
      color: 'dark',
      duration: 3000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    toast.present().then();
    this.getSelectAll(this.state.filters, toast);
  }

  async saveOrder(order: OrderCreatePayload) {
    order.qcStatus = 'DONE';
    await createNewOrder(firestore, this.props.profile, order);

    this.setState(
      {
        showNewOrderModal: false,
        multipleSelection: false,
        multipleSelectionArray: [],
        filter: {
          type: 'ORDERS',
        },
      },
      () => {
        this.props.history.push(
          '/secure/' + this.props.profile.organisationId + '/order/' + order.id
        );
      }
    );
  }

  async saveLot(lot: LotCreatePayload, inspect: boolean = false) {
    const { currOrder, currContactPosition, filter } = this.state;
    const { profile, history, products, organisation } = this.props;

    // Check if lot with current id exists
    try {
      const oldLot = await getLot(firestore, profile.organisationId, lot.id);
      if (!!oldLot) {
        alert(`Error: lot ${lot.id} already exists, please enter a different id`);
        return;
      }
    } catch (e) {
      // be careful if the client is offline will throw a client is offline exception
      // we catch the error and continue
      console.log('Could not fetch lot to check its existence', e);
    }

    // TODO DEV-818: save article

    // Flow for adding lot with the plus button
    if (!currOrder || !currContactPosition) {
      // TODO: fix this once we implement changing the assessment type
      // from stock
      if (filter.type === 'BATCHES') {
        await createNewLot(firestore, profile, lot, organisation);

        this.setState(
          {
            showNewLotModal: false,
            filter: {
              type: 'BATCHES',
            },
          },
          () => {
            if (inspect) {
              // Default assessment type set to INCOMING, since agland is the only org (for now) that's using this functionality
              const inspectionReference: LegacyInspectionReference = {
                type: 'incoming',
                lotId: lot.id,
                date: dateToString(new Date()),
              };
              history.push(
                '/secure/' +
                  profile.organisationId +
                  '/inspection/' +
                  stringifyInspectionReference(inspectionReference)
              );
            }
          }
        );
      }
      // from upcoming (new surveyor model)
      else if (filter.type === 'SUPPLY-CHAIN') {
        await createSupplyChainLot(firestore, profile, lot, organisation);

        this.setState(
          {
            showNewLotModal: false,
            filter: {
              type: 'SUPPLY-CHAIN',
            },
          },
          () => {
            if (inspect) {
              const inspectionReference: LegacyInspectionReference = {
                type: 'upcoming',
                date: dateToString(new Date()),
                lotId: lot.id,
              };
              history.push(
                '/secure/' +
                  profile.organisationId +
                  '/inspection/' +
                  stringifyInspectionReference(inspectionReference) +
                  `/${SUPPLY_CHAIN_URL_SUFFIX}`
              );
            }
          }
        );
      }
    }
    // Flow for adding lot from contact positions in outgoing orders (UNUSED NOW)
    else {
      await createLotFromContactPosition(
        firestore,
        lot,
        currOrder,
        currContactPosition,
        profile,
        products,
        organisation?.settings
      );

      this.setState(
        {
          showNewLotModal: false,
          filter: {
            type: 'ORDERS',
          },
          currOrder: undefined,
          currContactPosition: undefined,
        },
        () => {
          if (inspect) {
            const inspectionReference: LegacyInspectionReference = {
              type: 'outgoing',
              lotId: lot.id,
              orderId: currOrder.id,
              date: dateToString(new Date()),
            };
            history.push(
              '/secure/' +
                profile.organisationId +
                '/inspection/' +
                stringifyInspectionReference(inspectionReference)
            );
          }
        }
      );
    }
  }

  async saveDispatchOrder(orderPayload: OrderCreatePayload) {
    await saveOrderWrapper(
      firestore,
      undefined,
      this.props.profile,
      orderPayload,
      true,
      undefined,
      undefined,
      orderPayload.type === 'BUY'
    );
    if (Object.keys(orderPayload.lotInspectionMap ?? {}).length > 0) {
      await addOrRemoveOrderReferenceInLotInspections(
        firestore,
        this.props.profile,
        orderPayload,
        'add'
      );
    }
    this.setState(
      {
        showNewDispatchOrderModal: false,
        multipleSelection: false,
        multipleSelectionArray: [],
      },
      () =>
        this.props.history.push(
          `/secure/${this.props.profile.organisationId}/order/${orderPayload.id}`
        )
    );
  }

  async saveLotTransformation(payload: ProductionPayload) {}

  saveSupplyChainReport = async (report: Report) => {
    try {
      await setReport(firestore, this.props.profile, report);
      await addReferencesToOrder(
        firestore,
        this.props.profile,
        report,
        report.reportReference?.orderId
      );
      await this.setState({
        showNewReportModal: false,
        multipleSelection: false,
        multipleSelectionArray: [],
      });
      setTimeout(() => this.props.history.push(`/secure/report/${report.id}`), 0);
    } catch (e) {
      alert('error saving report\nError: ' + e.message);
      throw e;
    }
    return;
  };

  async removeFilter(key, attr?: SearchField) {
    let { filters } = this.state;
    if (attr) {
      if (attr === 'producedVarieties') {
        filters[attr] = [];
      } else if (attr === 'producedProducts') {
        filters[attr] = [];
        filters.producedVarieties = [];
      } else {
        delete filters[attr];
      }
    } else {
      delete filters.article?.[key];
    }
    this.props.setFilters(filters);
  }

  async infiniteScroll(ev) {
    ev.persist?.();
    this.setState({ loadingMore: true });
    try {
      await this.fetchData(true);
    } catch (err) {
      console.error('error while loading more:', err);
    } finally {
      ev.target?.complete?.();
    }
  }

  renderList() {
    // let lotsDOM, ordersIncomingDOM, ordersOutgoingDOM, upcomingLotsDOM;
    let lotsDOM, ordersDOM, externalLotsDOM;

    // let lots: Lot[] = this.state.lots?.data;
    // let ordersIncoming = this.state.incomingOrders?.data;
    // let ordersOutgoing = this.state.outgoingOrders?.data;
    // let upcomingLots = this.state.upcomingLots?.data;

    let lots: Lot[] = this.state.lots?.data;

    let todos: Todo[] = this.state.stock?.data;
    //@ts-ignore
    let todoLots: Lot[] = this.state.stock?.lots;

    let orders: Order[] = this.state.orders?.data;
    let externalLots: Lot[] = this.state.externalLots?.data;

    const { orderData, agTabs } = this.state;
    const { minDate, maxDate } = agTabs.ORDERS;

    // Build lots list
    if (!lots) {
      lotsDOM = [...Array(4).keys()].map((item) => (
        <CardLot key={item} loading={true} />
      ));
    } else {
      let lastIdx = 0;
      lotsDOM = lots //.slice(-48)
        .map((lot, i) => {
          lastIdx = 1;
          return (
            <div onClick={() => this.addMultipleSelection(lot)} key={'lot-' + lot.id}>
              <CardLot
                index={i}
                multipleSelection={this.state.multipleSelection}
                selected={
                  this.state.multipleSelectionArray.findIndex(
                    (p) => p.id === lot.id
                  ) !== -1
                }
                searchString={this.state.filter.search}
                lot={lot}
                history={this.props.history}
                eligibleForMultiSelection={true}
              />
            </div>
          );
        });
      lotsDOM = lotsDOM.concat(
        [...Array(3).keys()].map((i) => (
          <CardLot
            key={'skl-' + lastIdx + i}
            loading={true}
            className={
              this.state.loadingMore && !this.state.batchesNoMore ? 'visible' : 'hidden'
            }
          />
        ))
      );

      if (this.state.batchesNoMore) {
        lotsDOM = lotsDOM.concat([
          <h1 key="end" className="h1-not-found">
            no more results
          </h1>,
        ]);
      }
      if (lots.length === 0) {
        lotsDOM = <NoResults />;
      }
    }

    // Build upcoming lots list
    if (!externalLots) {
      externalLotsDOM = [...Array(4).keys()].map((item) => (
        <CardLot key={item} loading={true} />
      ));
    } else {
      let lastIdx = 0;
      externalLotsDOM = externalLots //.slice(-48)
        .map((lot, i) => {
          lastIdx = 1;
          const inspection: LotInspection =
            lot.latestInspection ||
            newInspectionForLot(
              { lotId: lot.id, type: 'stock', date: dateToString(new Date()) },
              lot
            );

          return (
            <div onClick={() => this.addMultipleSelection(lot)} key={lot.id}>
              <CardLot
                index={`supply-chain-${i}`}
                multipleSelection={this.state.multipleSelection}
                selected={
                  this.state.multipleSelectionArray.findIndex(
                    (p) => p.id === lot.id
                  ) !== -1
                }
                searchString={this.state.filter.search}
                lot={lot}
                context={this.state.filter.type}
                history={this.props.history}
                eligibleForMultiSelection={new InspectionClass(
                  inspection
                ).isCompleted()}
              />
            </div>
          );
        });

      externalLotsDOM = externalLotsDOM.concat(
        [...Array(3).keys()].map((i) => (
          <CardLot
            key={'sklext-' + lastIdx + i}
            loading={true}
            style={{
              visibility:
                this.state.loadingMore && !this.state.batchesNoMore
                  ? 'visible'
                  : 'hidden',
            }}
          />
        ))
      );

      if (externalLots.length === 0) {
        externalLotsDOM = <NoResults />;
      }
    }

    // Incoming list
    if (!orders) {
      ordersDOM = [...Array(4).keys()].map((i) => <CardOrder key={i} loading={true} />);
    } else {
      let lastIdx = 0;
      ordersDOM = orders //.slice(-48)
        .map((order, idx) => {
          lastIdx = idx;
          return (
            <CardOrder
              key={idx}
              index={idx}
              expandLots={this.state.showListMode}
              searchString={this.state.filter.search}
              highlightLotId={this.state.filter.search}
              // hideDone={this.state.filters?.todo}
              order={order}
            />
          );
        });

      ordersDOM = (ordersDOM ?? []).concat(
        [...Array(3).keys()].map((i) => (
          <CardOrder
            key={'sko-' + lastIdx + i++}
            loading={true}
            style={{
              visibility:
                this.state.loadingMore && !this.state.batchesNoMore
                  ? 'visible'
                  : 'hidden',
            }}
          />
        ))
      );
      if (orders.length === 0) {
        ordersDOM = <NoResults />;
      }
    }

    let fields = this.props.locations?.filter(
      (l) => l.locationType === 'PRODUCTION_SITE'
    ) as ProductionSiteLocation[];

    fields = applyFieldFilters(
      fields,
      this.state.filters,
      this.state.filter.search,
      this.state.agTabs.FIELD_INSPECTIONS
    );

    // we render the three lists and toggle the view depending the state.filter.type
    // this way we prevent rerendering the list when the filter type changes
    // and it's only a display:yes/no for the DOM.
    return (
      <>
        <div
          style={{ display: this.state.filter.type === 'BATCHES' ? 'grid' : 'none' }}
        >
          {lotsDOM}
        </div>
        <div
          style={{
            display: this.state.filter.type === 'FIELD_INSPECTIONS' ? 'grid' : 'none',
          }}
        >
          {fields.length === 0 ? (
            <NoResults />
          ) : (
            fields.map((field) => <CardField key={field.locationId} field={field} />)
          )}
        </div>
        <div
          style={{ display: this.state.filter.type === 'STOCK' ? 'block' : 'none' }}
          className={'todo-list'}
        >
          <TodoList
            todos={this.state.stock?.data}
            loadingMore={this.state.loadingMore}
            batchesNoMore={this.state.batchesNoMore}
          />
        </div>
        <div style={{ display: this.state.filter.type === 'ORDERS' ? 'grid' : 'none' }}>
          <div className="top-order-summary">
            {/* <div className="hide-only-mobile" onClick={()=>this.setState({showCalendar:true})}>FROM {formatDate(minDate).replace(`/${(new Date()).getFullYear()}`, '')} - {formatDate(maxDate).replace(`/${(new Date()).getFullYear()}`, '')}</div> */}
            {/* <div>LAST {formatDistance(minDate, maxDate)}</div> */}
            <div
              onClick={async () => {
                this.contentRef.current.scrollToTop(500);
                await this.setState({
                  filters: {
                    ...this.state.filters,
                    search: '',
                    qcStatus: { value: undefined, label: 'Any' },
                  },
                });
                this.fetchDataFiltered(this.state.filters);
              }}
              className={!this.state.filters.qcStatus?.value ? 'selected' : ''}
            >
              {orderData?.total ?? <IonSpinner />}
              {orderData?.total === 500 && '+'} TOTAL
            </div>

            {Object.entries(OrderAGStatuses).map(([value, label]) => (
              <div
                key={value}
                onClick={async (_) => {
                  this.contentRef.current.scrollToTop(500);
                  const { qcStatus } = this.state.filters;
                  if (value === qcStatus?.value) {
                    await this.setState({
                      filters: {
                        ...this.state.filters,
                        search: '',
                        qcStatus: undefined,
                      },
                    });
                  } else {
                    await this.setState({
                      filters: {
                        ...this.state.filters,
                        search: '',
                        //@ts-ignore
                        qcStatus: { value, label },
                      },
                    });
                  }
                  this.fetchDataFiltered(this.state.filters);
                }}
                className={
                  value === this.state.filters.qcStatus?.value ? 'selected' : ''
                }
              >
                {orderData?.total === 500 && '~'}
                {orderData?.[value] ?? <IonSpinner />} {label}
              </div>
            ))}
          </div>
          {ordersDOM}
        </div>
        <div
          style={{
            display: this.state.filter.type === 'SUPPLY-CHAIN' ? 'grid' : 'none',
          }}
        >
          {externalLotsDOM}
        </div>
      </>
    );
  }

  onListScroll(e) {
    // console.log('nana', e)
    this.throttledScroll(e);
  }

  getDateForFilter = (date) => {
    let dateForFilter = undefined;
    if (date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      // These transformations insure the format for min and max attributes of IonDateTime Component
      // YYYY-MM-DD or undefined
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      dateForFilter = `${year}-${month}-${day}`;
    }

    return dateForFilter;
  };

  changeDate(type: 'max' | 'min', date: Date) {
    this.setState({
      filters: { ...this.state.filters, arrivalDateMax: date },
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          [type + 'Date']: date,
        },
      },
    });
  }

  changeDates(minDate: Date, maxDate: Date, range: TimeLabel) {
    this.setState({
      filters: { ...this.state.filters, arrivalDateMax: maxDate },
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          minDate: minDate,
          maxDate: maxDate,
          range,
        },
      },
    });
  }

  changeSortingBy(sortBy) {
    this.setState({
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          dateProperty: sortBy,
        },
      },
    });
  }

  getFilterDateValue(type: 'max' | 'min', asDate = false) {
    const agTabs = this.state.agTabs;
    const date = new Date(agTabs[this.state.filter.type]?.[type + 'Date']);
    if (asDate) return date;
    return date.toDateString();
  }

  handleSortingOnClick = () => {
    const filterType = this.state.filter.type;
    let sorting = undefined;
    if (this.state?.agTabs[filterType]?.sorting === 'desc') {
      sorting = 'asc';
    }
    if (this.state?.agTabs[filterType]?.sorting === 'asc') {
      sorting = 'desc';
    }
    this.setState({
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          sorting: sorting,
        },
      },
      filters: {
        ...this.state.filters,
        sort: sorting,
      },
    });
  };

  render() {
    const {
      showNewReportModal,
      showBatchOperationModal,
      showNewLotModal,
      showNewDispatchOrderModal,
      filter,
      filters,
      alert,
      processing,
      agTabs,
    } = this.state;
    const { profile } = this.props;

    const inboxUnreadCount = profile?.unreadConversationsCount ?? 0;

    //Check if user has not selected any tabs on profile settings and display a message to select them
    if (profile.displayAGTabs?.length === 0)
      return (
        <IonPage className="page-quality-control ion-page">
          <IonContent>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>No Tabs Selected</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                Plase go to your settings page, and select the tabs you would like to
                see!
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonPage>
      );

    return (
      <IonPage
        className={
          'page-quality-control ion-page ' +
          (!this.state.loadingMore && 'loading-finished')
        }
        ref={this.pageRef}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton color="dark" />
              {inboxUnreadCount > 0 && (
                <IonBadge mode="ios" className="notification-number" color="danger">
                  {inboxUnreadCount}
                </IonBadge>
              )}
            </IonButtons>
            <SegmentNavigation
              value={this.state.filter.type}
              profile={this.props.profile}
              onChange={this.changeFilterType.bind(this)}
            />
            <IonButtons slot="end" className="top-filter-button">
              <MainToolbar
                filterType={filter.type}
                changeSortingBy={this.changeSortingBy.bind(this)}
                searchByText={this.searchByText.bind(this)}
                profile={this.props.profile}
                onFilter={this.fetchDataFiltered.bind(this)}
                filters={filters}
                agTabs={agTabs}
                setAgTabs={(agTabs: AGTabs<QCFilterType>) => this.setState({ agTabs })}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          ref={this.contentRef}
          scrollEvents={true}
          onIonScroll={(e) => this.onListScroll(e)}
        >
          {processing && <IonLoading isOpen={processing} />}

          {alert.show && (
            <IonAlert
              isOpen={alert.show}
              onDidDismiss={() =>
                this.setState({
                  alert: this.defaultAlert,
                  closeLotOptionsFromCardOrder: undefined,
                })
              }
              header={alert.header}
              cssClass="alert-qc"
              message={alert.message}
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () =>
                    this.setState({
                      alert: this.defaultAlert,
                      closeLotOptionsFromCardOrder: undefined,
                    }),
                },
                {
                  text: 'Proceed',
                  cssClass: 'accept-button-alert',
                  handler: alert.onAccept,
                },
              ]}
            />
          )}

          {this.state.showCalendar && (
            <div
              id="pqc-cal"
              className="cdate-picker-wrapper"
              onClick={async (e) => {
                if (e.target === e.currentTarget) {
                  document.getElementById('pqc-cal').classList.add('out');
                  await delay(550);
                  this.setState({ showCalendar: false });
                }
              }}
            >
              <CDatePicker
                startDate={this.getFilterDateValue('min', true) as Date}
                endDate={this.getFilterDateValue('max', true) as Date}
                onConfirm={async (dates) => {
                  if (!!dates) {
                    const { startDate, endDate, range } = dates[0];
                    this.changeDates(startDate, endDate, range);
                  }
                  document.getElementById('pqc-cal').classList.add('out');
                  await delay(550);
                  this.setState({ showCalendar: false });
                }}
              />
            </div>
          )}

          <div
            className="searchbar-container"
            id="searchbar-container"
            data-tip={'pqc-search'}
          >
            <div>
              <div className="wrapper">
                <IonSearchbar
                  placeholder="SEARCH BY ID"
                  mode="ios"
                  inputmode="text"
                  value={this.state.filter.search}
                  onKeyUp={async (evt) => {
                    evt.persist();
                    const val = evt.currentTarget.value;
                    if (val !== this.state.filter.search) {
                      await this.setState({
                        filter: { ...this.state.filter, search: val },
                      });
                      this.searchByText(val);
                    }
                  }}
                  onIonClear={async (evt) => {
                    await this.setState({
                      filter: { ...this.state.filter, search: '' },
                    });
                    this.searchByText('');
                  }}
                />
                <ctxProducts.Consumer>
                  {(productList) => {
                    if (this.props.profile.products) {
                      // TODO: options should be either agProductId or productId depending on Organisation Settings. For now only displaying agProductId
                      productList = (productList ?? []).filter(
                        (p) => this.props.profile.products.indexOf(p.productId) >= 0
                      );
                    }
                    let options = (productList ?? [])
                      .sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
                      .map((b) => {
                        return {
                          value: b.productId,
                          label: b.agProductId ?? b.productId,
                        };
                      });

                    const isTodo = this.state.filter.type === 'STOCK';

                    return this.state.filter?.search?.length > 0 ? (
                      <Select
                        isDisabled
                        placeholder={'Disabled while filtering by id'}
                        value={[]}
                        className={'products-select desktop'}
                        classNamePrefix={'react-select'}
                      />
                    ) : (
                      <Select
                        isClearable={true}
                        isDisabled={this.state.filter?.search?.length > 0}
                        isMulti={!isTodo}
                        placeholder={'Select product' + (isTodo ? '' : '(s)')}
                        value={this.state.filters.article?.productId ?? []}
                        options={options}
                        isLoading={!productList}
                        className={'products-select desktop'}
                        classNamePrefix={'react-select'}
                        onChange={(val) => {
                          if (!!val && isTodo) {
                            //@ts-ignore
                            val = [val];
                          }
                          this.props.setFilters({
                            ...this.state.filters,
                            article: {
                              ...this.state.filters.article,
                              productId: val as any,
                            },
                          });
                        }}
                      />
                    );
                  }}
                </ctxProducts.Consumer>
                {this.state.filter.type === 'STOCK' ? (
                  <div className="reorderSelect">
                    <Select
                      classNamePrefix={'react-select'}
                      menuPortalTarget={document.getElementById('root')}
                      menuShouldScrollIntoView={false}
                      isSearchable={false}
                      blurInputOnSelect={true}
                      options={TodoReorderOptions}
                      placeholder={'Sort by...'}
                      onChange={async (evt) => {
                        if (!evt || evt.value === 'CUSTOM') return;
                        console.log(evt.value);
                        await this.setState({ processing: true });
                        await reorderTodos(firestore, evt.value, profile);
                        await this.setState({ processing: false });
                      }}
                    />
                  </div>
                ) : (
                  <div className={'datePicker'}>
                    <div className={'datePicker-label'}>
                      {' '}
                      {agTabs[this.state.filter.type]?.dateLabel}
                    </div>
                    <div
                      className={'datePicker-selects'}
                      onClick={(e) => {
                        this.setState({ showCalendar: true });
                      }}
                    >
                      <div>
                        {new Date(this.getFilterDateValue('min')).toLocaleDateString(
                          'en-GB'
                        )}
                      </div>
                      <div>&nbsp;-&nbsp;</div>
                      <div>
                        {new Date(this.getFilterDateValue('max')).toLocaleDateString(
                          'en-GB'
                        )}
                      </div>
                      {/* <IonDatetime
                        className="datePicker-start"
                        value={this.getFilterDateValue('min') as string}
                        max={this.getDateForFilter(agTabs[filter.type]?.maxDate)}
                        onIonChange={(o) => {
                          this.changeDate('min', new Date(o.detail.value));
                        }}
                      />
                      <IonDatetime
                        value={this.getFilterDateValue('max') as string}
                        min={this.getDateForFilter(
                          agTabs[this.state.filter.type]?.minDate
                        )}
                        onIonChange={(o) => {
                          this.changeDate('max', new Date(o.detail.value));
                        }}
                      /> */}
                    </div>
                  </div>
                )}
                {this.state?.agTabs[this.state.filter.type]?.sorting === 'desc' ? (
                  <IonIcon
                    icon={arrowDownOutline}
                    onClick={() => this.handleSortingOnClick()}
                    className="sorting-icon"
                  />
                ) : (
                  <IonIcon
                    icon={arrowUpOutline}
                    onClick={() => this.handleSortingOnClick()}
                    className="sorting-icon"
                  />
                )}
              </div>
            </div>
            {filter.type !== 'FIELD_INSPECTIONS' && (
              <div className="wrapper">
                <ctxProducts.Consumer>
                  {(productList) => {
                    if (this.props.profile.products) {
                      // TODO: options should be either agProductId or productId depending on Organisation Settings. For now only displaying agProductId
                      productList = (productList ?? []).filter(
                        (p) => this.props.profile.products.indexOf(p.productId) >= 0
                      );
                    }
                    let options = (productList ?? [])
                      .sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
                      .map((b) => {
                        return {
                          value: b.productId,
                          label: b.agProductId ?? b.productId,
                        };
                      });

                    const isTodo = this.state.filter.type === 'STOCK';

                    return this.state.filter?.search?.length > 0 ? null : (
                      <Select
                        isClearable={true}
                        isMulti={!isTodo}
                        placeholder={'Select product' + (isTodo ? '' : '(s)')}
                        data-tip={'pqc-product-filter'}
                        value={this.state.filters.article?.productId ?? []}
                        options={options}
                        isLoading={!productList}
                        className={'products-select mobile'}
                        classNamePrefix={'react-select'}
                        onChange={(val) => {
                          if (!!val && isTodo) {
                            //@ts-ignore
                            val = [val];
                          }
                          this.props.setFilters({
                            ...this.state.filters,
                            article: {
                              ...this.state.filters.article,
                              productId: val as any,
                            },
                          });
                        }}
                      />
                    );
                  }}
                </ctxProducts.Consumer>
              </div>
            )}
            <div className="wrapper" style={{ justifyContent: 'flex-start' }}>
              <FilterToolbar
                filters={filters}
                onRemoveFilter={this.removeFilter.bind(this)}
                searchText={this.state.filter.search}
                searchFields={searchFields.PageQualityControl[filter.type]}
              />
            </div>
          </div>
          <div className="cards-wrapper">
            <IonList
              className={
                'masonry-with-columns ' +
                (this.state.showListMode === true ? 'list-view' : '')
              }
            >
              {this.renderList()}
            </IonList>
            <PageFilters
              organisation={this.props.organisation}
              searchFields={searchFields.PageQualityControl[filter.type]}
              searchByText={this.searchByText.bind(this)}
              changeSortingBy={this.changeSortingBy.bind(this)}
              profile={profile}
              filterType={filter.type}
              filters={filters}
              onFilter={(filters: AGFilters) => {
                this.fetchDataFiltered(filters);
              }}
              isComponent={true}
              searchOnChange={true}
              handleSortingOnClick={this.handleSortingOnClick.bind(this)}
              onDateClick={() => this.setState({ showCalendar: true })}
              dateFilter={{
                dateLabel: agTabs[this.state.filter.type]?.dateLabel ?? '',
                sorting: agTabs[this.state.filter.type]?.sorting ?? 'desc',
                start: {
                  value: this.getFilterDateValue('min'),
                  max: this.getDateForFilter(agTabs[filter.type]?.maxDate),
                  onChange: (o) => {
                    this.changeDate('min', new Date(o.detail.value));
                  },
                },
                end: {
                  value: this.getFilterDateValue('max'),
                  min: this.getDateForFilter(agTabs[this.state.filter.type]?.minDate),
                  onChange: (o) => {
                    this.changeDate('max', new Date(o.detail.value));
                  },
                },
              }}
              agTabs={agTabs}
              setAgTabs={(agTabs: AGTabs<any>) => this.setState({ agTabs })}
            />
          </div>
          {!isMobile() && (
            <div className="loading-more-button">
              <IonButton color="dark" onClick={(e) => this.infiniteScroll(e)}>
                Load more
              </IonButton>
            </div>
          )}
          <IonInfiniteScroll
            threshold="100px"
            onIonInfinite={(ev) => this.infiniteScroll(ev)}
          >
            &nbsp; &nbsp;
          </IonInfiniteScroll>

          {/* ---------------------------------------------------------------- */}
          {/* Fab buttons and modals */}
          {/* ---------------------------------------------------------------- */}

          {this.renderFabButtons()}

          {showNewLotModal && (
            <IonModal
              isOpen={true}
              cssClass="modal-page-new-lot"
              onDidDismiss={() => this.closeLotModal()}
            >
              <PageNewLot
                onCancel={() => this.closeLotModal()}
                onSave={(lot: LotCreatePayload, inspect: boolean) =>
                  this.saveLot(lot, inspect)
                }
                // position={this.state.currContactPosition}
                // order={this.state.currOrder}
                context={filter.type}
                supplyChainLot={filter.type === 'SUPPLY-CHAIN'}
              ></PageNewLot>
            </IonModal>
          )}

          {showNewReportModal && (
            <IonModal
              isOpen={true}
              cssClass="new-report-modal"
              onDidDismiss={() => this.setState({ showNewReportModal: false })}
            >
              <PageNewReport
                onCancel={() => this.setState({ showNewReportModal: false })}
                onSave={this.saveSupplyChainReport.bind(this)}
                type={'SELL'}
                multipleSelectionArray={this.state.multipleSelectionArray.filter((l) =>
                  new InspectionClass(l.latestInspection).isCompleted()
                )} // Note: this a is temporary solution, we should handle the eligibility of lots differently
                section={filter.type}
              />
            </IonModal>
          )}

          {showBatchOperationModal && (
            <IonModal
              isOpen={showBatchOperationModal}
              cssClass="lot-transformation-modal"
              onDidDismiss={() => this.setState({ showBatchOperationModal: false })}
            >
              <PageLotTransformation
                onCancel={() => this.setState({ showBatchOperationModal: false })}
                onSave={this.saveLotTransformation.bind(this)}
                motherLots={this.state.multipleSelectionArray.filter(lotHasQuantity)}
                onSuccess={() =>
                  this.setState({
                    showBatchOperationModal: false,
                    multipleSelectionArray: [],
                    multipleSelection: false,
                  })
                }
              />
            </IonModal>
          )}

          {showNewDispatchOrderModal && (
            <IonModal
              isOpen={showNewDispatchOrderModal}
              cssClass="new-order-modal"
              onDidDismiss={() => this.setState({ showNewDispatchOrderModal: false })}
            >
              <PageNewOrder
                onCancel={() => this.setState({ showNewDispatchOrderModal: false })}
                onSave={this.saveDispatchOrder.bind(this)}
                lots={this.state.multipleSelectionArray.filter(lotHasQuantity)} // Note: this a is temporary solution, we should handle the eligibility of lots differently
              />
            </IonModal>
          )}
        </IonContent>
      </IonPage>
    );
  }

  renderMultiSelectionToggle(visibilityMap: { [key in QCFilterType]?: boolean }) {
    const { filter } = this.state;

    const display = visibilityMap[filter.type] ? 'flex' : 'none';

    return (
      <IonButtons className={'mulitple-select-button'} style={{ display: display }}>
        <IonButton
          mode="md"
          data-tip={'pqc-multiple-select-button'}
          disabled={!isOnline()}
          onClick={() => this.toggleMultipleSelection()}
        >
          {this.state.multipleSelection ? (
            <IonIcon slot="icon-only" icon={checkbox} color="tertiary"></IonIcon>
          ) : (
            <IonIcon slot="icon-only" icon={checkboxOutline} color="medium"></IonIcon>
          )}
        </IonButton>
      </IonButtons>
    );
  }

  renderSelectAllButton(visibilityMap: { [key in QCFilterType]?: boolean }) {
    const { filter } = this.state;

    const display = visibilityMap[filter.type] ? 'block' : 'none';

    return this.state.multipleSelection ? (
      //@ts-ignore
      <IonButtons className={'selectall-button'} mode="md">
        <IonButton
          color="dark"
          fill="solid"
          disabled={this.state.loadingSelectAll}
          data-tip={'pqc-selectall-button'}
          onClick={() => this.selectAll()}
          style={{ display }}
        >
          Select All
          {this.state.multipleSelectionArray?.length > 0 &&
            !this.state.loadingSelectAll && (
              <span style={{ animation: 'fadeInRight .3s both' }}>
                &nbsp;({this.state.multipleSelectionArray.length})
              </span>
            )}
        </IonButton>
      </IonButtons>
    ) : null;
  }

  setShowNewReportModal() {
    const ommitedLotIds = this.state.multipleSelectionArray
      // .filter(l => !new Assessment(l.latestAssessment).isCompleted())
      .filter((l) => !new InspectionClass(l.latestInspection).isCompleted())
      .map((l) => l.id);

    if (
      ommitedLotIds.length > 0 &&
      ommitedLotIds.length < this.state.multipleSelectionArray.length
    ) {
      let text = 'Batch(es):';
      ommitedLotIds.forEach((id) => (text += `\n${id}`));
      text += '\n\nAre omitted due to missing/incomplete/open inspections';
      alert(text);
    } else if (ommitedLotIds.length === this.state.multipleSelectionArray.length) {
      let text =
        'None of the selected batches is eligible for creating a report, as they are missing/incomplete/open inspections. Please select different batches';
      alert(text);
      return;
    }

    this.setState({ showNewReportModal: true });
  }

  setShowBatchOperation() {
    const ommitedLotIds = this.state.multipleSelectionArray
      .filter((l) => !lotHasQuantity(l))
      .map((l) => l.id);

    const productIds = Array.from(
      new Set(this.state.multipleSelectionArray.map((l) => l.article.agProductId))
    );

    if (
      ommitedLotIds.length > 0 &&
      ommitedLotIds.length < this.state.multipleSelectionArray.length
    ) {
      let text = 'Batch(es):';
      ommitedLotIds.forEach((id) => (text += `\n${id}`));
      text += '\n\nAre omitted due to zero quantities';
      alert(text);
    } else if (ommitedLotIds.length === this.state.multipleSelectionArray.length) {
      let text =
        'None of the selected batches is eligible for lot transformations, as all of them have zero quantity. Please select different batches';
      alert(text);
      return;
    } else if (productIds.length > 1) {
      let text = `Only batches of the same product are eligible for transformations (products in current selection: ${productIds})`;
      alert(text);
      return;
    }
    this.setState({ showBatchOperationModal: true });
  }

  setShowDispatchOrderModal() {
    const ommitedLotIds = this.state.multipleSelectionArray
      .filter((l) => !lotHasQuantity(l))
      .map((l) => l.id);

    if (
      ommitedLotIds.length > 0 &&
      ommitedLotIds.length < this.state.multipleSelectionArray.length
    ) {
      let text = 'Batch(es):';
      ommitedLotIds.forEach((id) => (text += `\n${id}`));
      text += '\n\nAre omitted due to zero quantities';
      alert(text);
    } else if (ommitedLotIds.length === this.state.multipleSelectionArray.length) {
      let text =
        'None of the selected batches is eligible for creating an order, as all of them have zero quantity. Please select different batches';
      alert(text);
      return;
    }

    this.setState({ showNewDispatchOrderModal: true });
  }

  async createTodos() {
    await this.setState({ loadingSelectAll: true });
    const lots = this.state.multipleSelectionArray;
    const orgSettings = this.props.organisation?.settings;
    const profile = this.props.profile;

    console.log('lots', lots);

    createTodos(firestore, lots, profile, orgSettings)
      .then(async () => {
        const toast = await toastController.create({
          message: lots.length + ' TODOS have been added to your list',
          position: 'bottom',
          cssClass: 'toast-fade-in-up',
          color: 'dark',
          duration: 3000,
          buttons: [
            {
              text: 'Got it',
              role: 'cancel',
            },
          ],
        });
        toast.present().then();
      })
      .catch(async (err) => {
        console.error(err);
        const toast = await toastController.create({
          message: 'There was an error',
          position: 'bottom',
          color: 'danger2',
          duration: 3000,
          buttons: [
            {
              text: 'Got it',
              role: 'cancel',
            },
          ],
        });
        toast.present().then();
      })
      .finally(() =>
        this.setState({
          loadingSelectAll: false,
          multipleSelection: false,
          multipleSelectionArray: [],
        })
      );
  }

  renderFabButtons() {
    const { allowManagingLots, allowSplitLots } =
      this.props.organisation?.settings ?? {};
    const { profile } = this.props;
    const { multipleSelectionArray, filter } = this.state;

    const displayCreateSCLotReport =
      userHasPermission(this.props.profile, 'DO', 'SURVEYING') &&
      filter.type === 'SUPPLY-CHAIN';
    const displayCreateSCLot =
      userHasPermission(profile, 'DO', 'SURVEYING') && filter.type === 'SUPPLY-CHAIN';

    // TODO DEV-807: different permissions for this?
    const displayCreateOrder =
      allowManagingLots &&
      userHasPermission(profile, 'CREATE', 'LOT') &&
      filter.type === 'BATCHES';
    const displayCreateLotReport =
      userHasPermission(profile, 'WRITE', 'REPORT') && filter.type === 'BATCHES';
    const displayCreateBatch =
      allowManagingLots &&
      userHasPermission(profile, 'CREATE', 'LOT') &&
      filter.type === 'BATCHES';
    const displayBatchOperation =
      allowSplitLots &&
      allowManagingLots &&
      userHasPermission(profile, 'SPLIT', 'LOT') &&
      filter.type === 'BATCHES'; // TODO DEV-817: check out if permissions are right

    const fabMap: { [key in QCFilterType]?: FABButtonModel[] } = {
      'BATCHES': [
        {
          display: displayCreateBatch,
          text: `New Batch`,
          onClick: (_) => this.setState({ showNewLotModal: true }),
          icon: cubeOutline,
        },
        {
          display: displayCreateOrder,
          disabled: multipleSelectionArray?.length === 0,
          text: (
            <>
              New Order
              {this.state.multipleSelectionArray?.length > 0 ? (
                <b>{this.state.multipleSelectionArray.length}</b>
              ) : null}
            </>
          ),
          onClick: (_) => this.setShowDispatchOrderModal(),
          icon: documentTextOutline,
        },
        {
          display: displayCreateLotReport,
          disabled: multipleSelectionArray?.length === 0,
          text: (
            <>
              New Report
              {this.state.multipleSelectionArray?.length > 0 ? (
                <b>{this.state.multipleSelectionArray.length}</b>
              ) : null}
            </>
          ),
          onClick: (_) => this.setShowNewReportModal(),
          icon: documentAttachOutline,
        },
        {
          display: displayBatchOperation,
          disabled: multipleSelectionArray?.length === 0,
          text: (
            <>
              Transformations
              {this.state.multipleSelectionArray?.length > 0 ? (
                <b>{this.state.multipleSelectionArray.length}</b>
              ) : null}
            </>
          ),
          onClick: (_) => this.setShowBatchOperation(),
          icon: colorWandOutline,
        },

        // {
        //   display: true,
        //   text: `New Report ${this.state.multipleSelectionArray?.length > 0 ? this.state.multipleSelectionArray.length : ''}`,
        //   onClick: _ => this.setState({ showNewReportModal: true }),
        //   icon: receiptOutline
        // },
        {
          display: this.props.organisation?.settings?.showTodos,
          disabled: multipleSelectionArray?.length === 0,
          text: (
            <>
              Create todo
              {this.state.multipleSelectionArray?.length > 0 ? (
                <b>{this.state.multipleSelectionArray.length}</b>
              ) : null}
            </>
          ),
          onClick: (_) => this.createTodos(),
          icon: checkmarkDoneCircleOutline,
        },
        // {
        //   display: true,
        //   text: `Assign TODO ${this.state.multipleSelectionArray?.length > 0 ? this.state.multipleSelectionArray.length : ''}`,
        //   onClick: _ => this.setState({ showNewReportModal: true }),
        //   icon: documentAttachOutline
        // }
      ],
      'SUPPLY-CHAIN': [
        {
          display: displayCreateSCLot,
          text: `New Batch`,
          onClick: (_) => this.setState({ showNewLotModal: true }),
          icon: receiptOutline,
        },
        {
          display: displayCreateSCLotReport,
          disabled: multipleSelectionArray?.length === 0,
          text: `New Report ${
            multipleSelectionArray?.length > 0
              ? `(${multipleSelectionArray.length})`
              : ''
          }`,
          onClick: (_) => this.setState({ showNewReportModal: true }),
          icon: documentAttachOutline,
        },
      ],
    };

    // Visibility settings for multi selection toggle button
    const multiselectVisMap: { [key in QCFilterType]?: boolean } = {
      'SUPPLY-CHAIN': displayCreateSCLotReport || displayCreateSCLot,
      'BATCHES': displayCreateLotReport || displayCreateOrder || displayCreateLotReport,
      'ORDERS': false,
    };

    // Only display if there is at least one visible button
    const shouldDisplayFabButtons =
      (fabMap[filter.type] ?? []).filter((fab) => fab.display).length > 0;

    return (
      <>
        {shouldDisplayFabButtons && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              color={
                !this.state.loadingOfflineCache && !this.state.loadingSelectAll
                  ? 'primary'
                  : 'dark'
              }
              data-tip={'pqc-add-button'}
            >
              {!this.state.loadingOfflineCache && !this.state.loadingSelectAll ? (
                <IonIcon icon={add} className="action-button-fab" />
              ) : (
                <IonIcon
                  icon={syncOutline}
                  className="rotate"
                  style={{ fontSize: '24px' }}
                />
              )}
            </IonFabButton>

            <IonFabList side="top" color="primary">
              {fabMap[filter.type]
                .filter((b) => !!b.display)
                .map((b, i) => {
                  return (
                    b.display && (
                      <div
                        key={i + 1}
                        className={`culolo c${i + 1} ${!!b.disabled ? 'disabled' : ''}`}
                        onClick={b.onClick}
                      >
                        <span>{b.text}</span>
                      </div>
                    )
                  );
                })}

              {fabMap[filter.type]
                .filter((b) => !!b.display)
                .map((b, i) => {
                  return (
                    b.display && (
                      <IonFabButton
                        data-tip={`pqc-fab-${b.text}`}
                        key={i}
                        color={b.color ?? 'dark'}
                        disabled={!!b.disabled}
                        onClick={b.onClick}
                        className={`fab-button ${!!b.disabled ? 'disabled' : ''}`}
                      >
                        <IonIcon icon={b.icon} />
                      </IonFabButton>
                    )
                  );
                })}
            </IonFabList>
          </IonFab>
        )}
        {this.renderMultiSelectionToggle(multiselectVisMap)}
        {this.renderSelectAllButton(multiselectVisMap)}
      </>
    );
  }

  closeLotModal() {
    !!this.state.closeLotOptionsFromCardOrder &&
      this.state.closeLotOptionsFromCardOrder();
    this.setState({
      showNewLotModal: false,
      showLinkLotModal: false,
      currContactPosition: undefined,
      currOrder: undefined,
      closeLotOptionsFromCardOrder: undefined,
    });
  }
}

export default React.memo(withContext(withIonLifeCycle(PageQualityControl)));

// ----------------------------------------------------------------
// FilterToolbar inner component
// ----------------------------------------------------------------
export const FilterToolbar = ({
  filters,
  onRemoveFilter,
  searchFields,
  searchText,
}: {
  filters: AGFilters;
  onRemoveFilter;
  searchFields: SearchField[];
  searchText?: string;
}) => {
  const locations = useContext(ctxLocations);
  const profile = useContext(ctxProfile);

  const { getProductionTypeLabel } = useLocationUtils();

  if (
    (false ||
      filters?.contactId ||
      !!filters?.qcStatus?.value ||
      !!filters?.userId?.value ||
      !!filters?.onlyLinked ||
      !!filters?.qcScore?.value ||
      !!filters?.reportStatus?.value ||
      !!filters?.locationId ||
      !!filters?.orderType ||
      !!filters?.inspectionType ||
      !!filters?.reportType ||
      !!filters?.isInStock ||
      !!filters?.productionType != null ||
      (filters?.producedVarieties ?? []).length > 0 ||
      !!filters?.hasAssessment != null) &&
    (searchText == null || searchText?.length === 0)
  ) {
    if (!searchFields) return null;

    return (
      <div className="filter-toolbar">
        {!!filters.hasAssessment && searchFields.includes('hasAssessment') && (
          <div onClick={() => onRemoveFilter(filters.hasAssessment, 'hasAssessment')}>
            <span data-tip={'remove-filter-has-inspections'}>Has inspections</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.skipZeroVolume && searchFields.includes('skipZeroVolume') && (
          <div onClick={() => onRemoveFilter(filters.skipZeroVolume, 'skipZeroVolume')}>
            <span>Omit processed</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.isInStock && searchFields.includes('isInStock') && (
          <div onClick={() => onRemoveFilter(filters.isInStock, 'isInStock')}>
            <span data-tip={'remove-filter-isInStock'}>In Stock</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.orderType?.value && searchFields.includes('orderType') && (
          <div onClick={() => onRemoveFilter(filters.orderType.value, 'orderType')}>
            <span>TYPE: {filters.orderType.label.substring(0, 10)}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.inspectionType?.value && searchFields.includes('inspectionType') && (
          <div
            onClick={() =>
              onRemoveFilter(filters.inspectionType.value, 'inspectionType')
            }
          >
            <span>
              LATEST INSPECTION: {filters.inspectionType.label.substring(0, 10)}
            </span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.reportType?.value && searchFields.includes('reportType') && (
          <div onClick={() => onRemoveFilter(filters.reportType.value, 'reportType')}>
            <span>REPORT TYPE: {filters.reportType.label.substring(0, 10)}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.qcStatus?.value && searchFields.includes('qcStatus') && (
          <div onClick={() => onRemoveFilter(filters.qcStatus.value, 'qcStatus')}>
            <span>QC STATUS: {filters.qcStatus.value}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.reportStatus?.value && searchFields.includes('reportStatus') && (
          <div
            onClick={() => onRemoveFilter(filters.reportStatus.value, 'reportStatus')}
          >
            <span>STATUS: {filters.reportStatus.value}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.qcScore?.value && searchFields.includes('score') && (
          <div onClick={() => onRemoveFilter(filters.qcScore.value, 'qcScore')}>
            <span>QC SCORE: {filters.qcScore.label}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.userId?.value && searchFields.includes('user') && (
          <div onClick={() => onRemoveFilter(filters.userId.value, 'userId')}>
            <span>USER: {filters.userId.name.substring(0, 10)}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {!!filters.locationId && searchFields.includes('location') && (
          <div
            onClick={() => {
              if (!hasLocationRestriction(profile)) {
                onRemoveFilter(filters.locationId, 'locationId');
              }
            }}
          >
            <span>
              LOCATION:{' '}
              {locations
                ?.find((l) => l.locationId === filters.locationId)
                ?.name.substring(0, 10)}
            </span>
            {!hasLocationRestriction(profile) && <IonIcon icon={closeCircle} />}
          </div>
        )}

        {!!filters.productionType && searchFields.includes('productionType') && (
          <div onClick={() => onRemoveFilter(filters.productionType, 'productionType')}>
            <span>PROD TYPE: {getProductionTypeLabel(filters.productionType)}</span>
            <IonIcon icon={closeCircle} />
          </div>
        )}

        {(filters.producedProducts ?? []).length > 0 &&
          searchFields.includes('producedProducts') && (
            <div
              onClick={() =>
                onRemoveFilter(filters.producedProducts, 'producedProducts')
              }
            >
              <span>PRODUCTS: {filters.producedProducts.join(', ')}</span>
              <IonIcon icon={closeCircle} />
            </div>
          )}

        {(filters.producedVarieties ?? []).length > 0 &&
          searchFields.includes('producedVarieties') && (
            <div
              onClick={() =>
                onRemoveFilter(filters.producedVarieties, 'producedVarieties')
              }
            >
              <span>
                VARIETIES:{' '}
                {filters.producedVarieties
                  .map(({ agProductId, variety }) => `${agProductId}|${variety}`)
                  .join(', ')}
              </span>
              <IonIcon icon={closeCircle} />
            </div>
          )}

        {searchFields.includes('supplier') && (
          <ctxContacts.Consumer>
            {(contactList) => {
              const contact = contactList?.find((c) => filters.contactId === c.id);
              if (!contact) return null;
              return (
                <div onClick={() => onRemoveFilter(filters.contactId, 'contactId')}>
                  <span>{contact.name.substring(0, 18)}</span>
                  <IonIcon icon={closeCircle} />
                </div>
              );
            }}
          </ctxContacts.Consumer>
        )}

        {searchFields.includes('partner') && (
          <ctxContacts.Consumer>
            {(contactList) => {
              const contact = contactList?.find((c) => filters.partnerId === c.id);
              if (!contact) return null;
              return (
                <div onClick={() => onRemoveFilter(filters.partnerId, 'partnerId')}>
                  <span>{contact.name.substring(0, 18)}</span>
                  <IonIcon icon={closeCircle} />
                </div>
              );
            }}
          </ctxContacts.Consumer>
        )}
      </div>
    );
  } else return null;
};

// ----------------------------------------------------------------
// SegmentNavigation inner component
// ----------------------------------------------------------------
const SN = ({
  value,
  profile,
  onChange,
}: {
  value: any;
  profile: UserProfile;
  onChange: any;
}) => {
  const agTabsToRender = cloneDeep(defaultAgTabs);
  const tabs = Object.entries(agTabsToRender).sort(
    (a, b) => qcTabsOrderMap[a[0]] - qcTabsOrderMap[b[0]]
  );
  const userHasDisplayAGTabsProperty = get(profile, 'displayAGTabs');
  const userHasTab = (tab: AGTab<QCFilterType>, stage: string) =>
    find(profile.displayAGTabs, (userTab) => tab.id === userTab) ||
    stage === 'SUPPLY-CHAIN';

  const renderSegmentBar = () => {
    return (
      <>
        {tabs.map(([stage, tab]) => {
          if (!userHasDisplayAGTabsProperty || userHasTab(tab, stage)) {
            return (
              <IonSegmentButton
                data-tip={`tabs-${tab.id.toLowerCase()}`}
                key={tab.id}
                value={tab.id}
              >
                <span>{tab.label}</span>
                <span>{tab.labelMobile}</span>
              </IonSegmentButton>
            );
          } else {
            return '';
          }
        })}
      </>
    );
  };

  if (isMobile()) {
    return (
      <div style={{ margin: 5 }}>
        <Select
          options={tabs
            .map(([stage, tab]) => {
              if (!userHasDisplayAGTabsProperty || userHasTab(tab, stage))
                return {
                  label: tab.label,
                  value: tab.id,
                };
              else return null;
            })
            .filter((o) => !!o)}
          isSearchable={false}
          value={{
            value,
            label: tabs.find(([_, tab]) => tab.id === value)?.[1]?.label,
          }}
          menuPortalTarget={document.body}
          classNamePrefix={'react-select'}
          onChange={(e) => {
            onChange(e.value);
          }}
        />
      </div>
    );
  }

  return (
    <IonSegment
      value={value}
      scrollable={isMobile()}
      onIonChange={(evt) => {
        onChange(evt.detail.value);
      }}
    >
      {renderSegmentBar()}
    </IonSegment>
  );
};

const SegmentNavigation = React.memo(SN, (prev, next) => {
  return isEqual(prev.profile, next.profile) && prev.value === next.value;
});

// ----------------------------------------------------------------
// MainToolbar inner component
// ----------------------------------------------------------------
const MTB = ({
  profile,
  onFilter,
  filters,
  searchByText,
  filterType,
  changeSortingBy,
  agTabs,
  setAgTabs,
}) => {
  const [showPageFilters, setShowPageFilters] = useState(false);
  const organisation = useContext(ctxOrg);

  const renderToolbar = () => (
    <IonButton
      data-tip={'pqc-filter-button'}
      onClick={() => setShowPageFilters(true)}
      color="dark"
    >
      <IonIcon icon={filterOutline} slot="start" />
      <span className="hide-only-mobile">&nbsp;Filter</span>
      {/* Filter */}
    </IonButton>
  );

  return (
    <>
      {renderToolbar()}

      <IonModal
        isOpen={showPageFilters || false}
        cssClass={'modal-filter'}
        onDidDismiss={(_) => setShowPageFilters(false)}
      >
        <PageFilters
          organisation={organisation}
          searchFields={searchFields.PageQualityControl[filterType]}
          searchByText={searchByText}
          changeSortingBy={changeSortingBy}
          filterType={filterType}
          profile={profile}
          filters={filters}
          onCancel={() => setShowPageFilters(false)}
          onFilter={onFilter}
          agTabs={agTabs}
          setAgTabs={setAgTabs}
        />
      </IonModal>
    </>
  );
};

const MainToolbar = MTB;

const NoResults = () => {
  return <h1 className="h1-not-found">no results</h1>;
};
