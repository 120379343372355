import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { IonButton, isPlatform } from '@ionic/react';
import {
  addDays,
  isSameDay,
  differenceInDays,
  differenceInCalendarDays,
  addHours,
  addMinutes,
  addSeconds,
} from 'date-fns';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { CDateRange, definedDates } from '../HelperInsight';
import { DateRange, TimeLabel } from '../ModelInsight';
import './CDatePicker.scss';

// ----------------------------------------------------------------
// Date Picker
// ----------------------------------------------------------------
interface Props {
  startDate: Date;
  endDate: Date;
  maxDate?: Date;
  onConfirm: Function;
}

export const CDatePicker = ({ onConfirm, startDate, endDate, maxDate }: Props) => {
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    },
  ]);

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }

  const staticRangesArray: { label: TimeLabel; range: () => DateRange }[] = (
    [
      'Year to date',
      'This Year',
      'Last Year',
      'Month to date',
      'This Month',
      'Last Month',
      'Last 3 Months',
      'Last 6 Months',
      'Week to date',
      'This Week',
      'Last Week',
      'Today',
      'All',
    ] as TimeLabel[]
  ).map((label) => ({
    label,
    range: () => ({
      startDate: new CDateRange(label).getStart() as Date,
      endDate: new CDateRange(label).getEnd() as Date,
      range: label,
    }),
  }));

  const defaultStaticRanges = createStaticRanges(staticRangesArray);

  const defaultInputRanges = [
    {
      label: 'days up to today',
      range(value) {
        const startDate = addDays(
          definedDates.startOfToday,
          (Math.max(Number(value), 1) - 1) * -1
        );
        return {
          startDate: startDate,
          endDate: definedDates.endOfToday,
          range:
            differenceInDays(definedDates.endOfToday, startDate) +
            1 +
            ' days up to today',
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, definedDates.endOfToday)) return '-';
        if (!range.startDate) return '∞';
        return differenceInCalendarDays(definedDates.endOfToday, range.startDate) + 1;
      },
    },
  ];

  const showCondensed = () => {
    if (
      navigator?.platform === 'Win32' ||
      // @ts-ignore
      navigator?.userAgentData?.platform === 'Windows'
    )
      return false;
    return isPlatform('mobile') && !isPlatform('tablet');
  };

  const onChange = (oldItem: {
    selection: { endDate: Date; key: string; startDate: Date; range?: string };
  }) => {
    const item = cloneDeep(oldItem);
    if (!item?.selection?.range) {
      // include the whole day in the endDate if it's a custom (non labeled) date range
      item.selection.endDate = addHours(item.selection.endDate, 23);
      item.selection.endDate = addMinutes(item.selection.endDate, 59);
      item.selection.endDate = addSeconds(item.selection.endDate, 59);
    }
    console.log('setting dates from date picker', item.selection);
    setState([item.selection]);
  };

  return (
    <div className="cdate-picker">
      <DateRangePicker
        staticRanges={defaultStaticRanges}
        inputRanges={showCondensed() ? [] : defaultInputRanges}
        onChange={onChange}
        editableDateInputs={true}
        // showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={showCondensed() ? 1 : 2}
        maxDate={maxDate}
        ranges={state}
        direction="horizontal"
        rangeColors={['var(--ion-color-tertiary)']}
      />
      <div className="date-picker-ok-button">
        <IonButton
          color="medium"
          size="small"
          fill="clear"
          onClick={(_) => onConfirm(undefined)}
        >
          Close
        </IonButton>
        <IonButton color="tertiary" size="small" onClick={(_) => onConfirm(state)}>
          Confirm Dates
        </IonButton>
      </div>
    </div>
  );
};
