import {
  IonBackButton,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useContext } from 'react';
import { ctxOrg } from './App';
import './PageLot.scss';
import './PageOrder.scss';
import i18n from './ServiceI18n';
import { logout } from './DataApp';
import { auth } from './ConfigFirebase';

interface Props {
  noHeader?: boolean;
}

const PageDocsRedirect = (props: Props) => {
  const org = useContext(ctxOrg);

  const renderBackButton = () => (
    <IonBackButton
      text={i18n.t('General.back')}
      defaultHref="/tabs/quality-control"
      color="dark"
    >
      <IonIcon icon={chevronBackOutline} slot="icon-only" />
    </IonBackButton>
  );

  return (
    <IonPage>
      {!props.noHeader && (
        <IonHeader>
          <IonToolbar>
            <IonTitle>Login Successful</IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent id="page-order-content">
        {!org && <IonSpinner name="dots" className="dots-spinner" />}
        {!!org && (
          <div style={{ textAlign: 'center', margin: 50 }}>
            <a href="https://docs.agrinorm.support/en" target="_blank">
              click here to go back to the Agrinorm Help Center
            </a>
            <br />
            <br />
            <IonButton color="dark" onClick={() => logout(auth)}>
              logout
            </IonButton>
          </div>
        )}
        {props.noHeader && <IonItem>{renderBackButton()}</IonItem>}
      </IonContent>
    </IonPage>
  );
};

export default PageDocsRedirect;
