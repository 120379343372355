import {
  IonBadge,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { homeOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ctxOrg, ctxProfile } from '../../../App';
import { CardImages } from '../../../CardImages';
import {
  ProductionSiteInspection,
  ProductionSiteInspectionReference,
} from '../../../InspectionModel';
import { ProductionSiteLocation } from '../../../Model';
import PageGallery from '../../../PageGallery';
import {
  buildProdSiteInspectionViewPath,
  formatFieldInspectionDate,
  getUserInputValuesToRender,
} from '../../../ServiceInspection';
import { ViewInspectionScore } from '../../../ViewInspectionScore';
import ViewReportTypeBadge from '../../../ViewReportTypeBadge';
import { useLocationUtils } from '../../../hooks/useLocationUtils';

interface CardFieldInspectionProps {
  field: ProductionSiteLocation;
  inspection: ProductionSiteInspection;
  sharedReportId: string;
}

export const CardFieldInspection = ({
  field,
  inspection,
  sharedReportId,
}: CardFieldInspectionProps) => {
  const org = useContext(ctxOrg);
  const profile = useContext(ctxProfile);
  const history = useHistory();
  const { buildProdSiteInspectionViewPath, buildSharedFieldInspectionViewPath } =
    useLocationUtils();
  const [showPageGallery, setShowPageGallery] = useState(false);
  const questionSpecs = inspection?.renderingInfo?.questionSpecs;

  const getLink = (e) => {
    e.stopPropagation();
    const link = !!sharedReportId
      ? buildSharedFieldInspectionViewPath(sharedReportId, inspection.id)
      : buildProdSiteInspectionViewPath(org?.id, inspection.reference);
    history.push(link);
  };

  let defects = Object.entries(inspection?.userInputs ?? {}).filter(
    ([_, o]) => o.agScore < 4 // && !ommitedDefects.includes(id)
  );

  return (
    <>
      <div className="ion-card card-inspection" onClick={getLink}>
        <div
          className="cover"
          onClick={(e) => {
            e.stopPropagation();
            setShowPageGallery(true);
          }}
        >
          <CardImages pictures={inspection?.pictures} max={5} />
        </div>
        <div className="card-inspection-wrapper">
          <IonCardHeader>
            <div>
              <IonCardTitle className="card-title">
                <IonIcon
                  style={{ pointerEvents: 'none', marginRight: 5 }}
                  icon={homeOutline}
                />
                {field.name}
              </IonCardTitle>

              <div className="date-report-type">
                <ViewReportTypeBadge inspection={inspection} />
                <IonBadge color="medium">
                  {formatFieldInspectionDate(inspection.reference)}
                </IonBadge>
                <IonBadge color="dark">{inspection?.status ?? 'NONE'}</IonBadge>
              </div>
            </div>
            <div>
              <div className="score-type-section">
                <ViewInspectionScore
                  inspection={inspection}
                  organisationSettings={org?.settings}
                  profile={profile}
                  displayType="INLINE"
                />
              </div>
            </div>
          </IonCardHeader>
          {!!inspection && (
            <IonCardContent className="defects">
              <div className="all-defects">
                {defects
                  .filter(
                    ([id, o], i) =>
                      questionSpecs[id]?.cardProperties?.hideInCard !== true
                  )
                  .map(
                    ([id, o], i) =>
                      !!questionSpecs[id]?.displayedName && (
                        <IonBadge
                          className={`score-${o.agScore} ${o.groupId}`}
                          key={'d-' + i} /*onClick={e=>this.showGallery(e, o.)}*/
                        >
                          <span>
                            {!!questionSpecs[id].cardProperties?.cardName
                              ? questionSpecs[id].cardProperties?.cardName
                              : questionSpecs[id].displayedName}
                          </span>
                          <span>{getUserInputValuesToRender(o)}</span>
                        </IonBadge>
                      )
                  )}
              </div>
            </IonCardContent>
          )}
        </div>
      </div>
      <IonModal
        isOpen={showPageGallery}
        onDidDismiss={(_) => setShowPageGallery(false)}
      >
        <PageGallery
          editable={false}
          pictures={inspection.pictures}
          inspection={inspection}
          profile={profile}
          onDismiss={() => setShowPageGallery(false)}
          inspectionReference={inspection.reference}
          orgSettings={org?.settings}
        />
      </IonModal>
    </>
  );
};
