import { IonButton } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';

// Styling
import '../PageInspection.scss';

interface WarningProps {
  warningMessage: string;
  setWarningMessage: (s: string | undefined) => void;
  onDiscardChangesAccept: () => Promise<void>;
}

export const CInspectionWarning = ({
  warningMessage,
  setWarningMessage,
  onDiscardChangesAccept,
}: WarningProps) => {
  const history = useHistory();

  if (!warningMessage) {
    return null;
  }

  return (
    <div className="lock-message race-condition">
      <div>{warningMessage}</div>
      <div className="bottom-buttons">
        <IonButton
          mode="md"
          color="white"
          fill="clear"
          onClick={() => {
            if (
              window.confirm('Are you sure?\nYour changes will override their changes')
            ) {
              setWarningMessage(undefined);
            }
          }}
        >
          Continue editing
        </IonButton>

        <IonButton
          mode="md"
          color="white"
          className="exit-button"
          fill="outline"
          onClick={async () => {
            if (window.confirm('Are you sure?\nYour changes will be discarded')) {
              await onDiscardChangesAccept();
              history.go(-1);
            }
          }}
        >
          Exit
        </IonButton>
      </div>
    </div>
  );
};
