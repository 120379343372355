import { IonBadge } from '@ionic/react';
import React from 'react';
import { standardizeDate } from '../../../HelperUtils';
import { SharedFieldReport } from '../../../InspectionModel';
import { ProductionSiteLocation } from '../../../Model';
import { useLocationUtils } from '../../../hooks/useLocationUtils';
import './LocationProperties.scss';
import { LocationSharedInfo } from './LocationSharedInfo/LocationSharedInfo';

interface Props {
  field: ProductionSiteLocation | SharedFieldReport;
  associatedPartnerName?: string;
}

export const LocationProperties = ({ field, associatedPartnerName }: Props) => {
  const {
    productionType,
    name,
    hasCoolingFacilities,
    latestInspectionPreview,
    producedVarieties = [],
    totalAreaInHa,
    growingAreaInHa,
    irrigationSystems = [],
    growingType,
    substrateType,
    growingMethodType,
    maxMinutesOnFieldBeforeTransportation,
    maxPrecoolingTime,
    refrigeratedTransporter,
    datePlanted,
    productionWeekStart,
    productionWeekEnd,
    plantsPerHa,
    kgProducedPerPlant,
    fieldStorageConditions,
    geoPoint,
    locationId,
  } = field;

  const { message, sharedTo } = field as SharedFieldReport;

  const {
    getProductionTypeLabel,
    getIrrigationSystemLabel,
    getGrowingMethodLabel,
    getGrowingTypeLabel,
    getSubstrateTypeLabel,
    getFieldStorageConditionLabel,
    getTimeLabel,
  } = useLocationUtils();

  return (
    <div className="location-properties-wrapper">
      <div className="location-properties-secondary-wrapper">
        <div className="location-properties">
          <div className="property-name">Production Site</div>
          <div className="properties-badges">
            {!!associatedPartnerName && (
              <IonBadge color="success">{associatedPartnerName}</IonBadge>
            )}
            <IonBadge color="dark">{getProductionTypeLabel(productionType)}</IonBadge>
            {!!datePlanted && (
              <IonBadge color="primary">
                Planted: {standardizeDate(datePlanted).toLocaleDateString()}
              </IonBadge>
            )}
            {productionWeekStart != null && productionWeekEnd != null && (
              <IonBadge color="primary">
                Prod weeks: {productionWeekStart}-{productionWeekEnd}
              </IonBadge>
            )}
            {hasCoolingFacilities && (
              <IonBadge color="medium">Cooling Chamber</IonBadge>
            )}
            {refrigeratedTransporter && (
              <IonBadge color="medium">Refrigerated Transport</IonBadge>
            )}
            {fieldStorageConditions != null && (
              <IonBadge color="medium">
                {getFieldStorageConditionLabel(fieldStorageConditions)}
              </IonBadge>
            )}
            {growingType != null && (
              <IonBadge color="medium">{getGrowingTypeLabel(growingType)}</IonBadge>
            )}
            {growingMethodType != null && (
              <IonBadge color="success">
                {getGrowingMethodLabel(growingMethodType)}
              </IonBadge>
            )}
            {substrateType != null && (
              <IonBadge color="success">
                {getSubstrateTypeLabel(substrateType)}
              </IonBadge>
            )}
            {irrigationSystems.map((v, i) => (
              <IonBadge key={i} color="blue">
                {getIrrigationSystemLabel(v)}
              </IonBadge>
            ))}
            {totalAreaInHa != null && (
              <IonBadge color="light-medium">Total area: {totalAreaInHa.toFixed(2)} ha</IonBadge>
            )}
            {growingAreaInHa != null && (
              <IonBadge color="light-medium">
                Growing area: {growingAreaInHa.toFixed(2)} ha
              </IonBadge>
            )}
            {plantsPerHa != null && (
              <IonBadge color="light-medium">Plants/ha: {plantsPerHa}</IonBadge>
            )}
            {maxPrecoolingTime != null && (
              <IonBadge color="light-medium">
                Precooling: {getTimeLabel(maxPrecoolingTime)}
              </IonBadge>
            )}
          </div>
          {producedVarieties.length > 0 && (
            <>
              <br />
              <div className="property-name">Varieties produced</div>
              <div className="properties-badges">
                {producedVarieties.map((v, i) => {
                  const content = !!v.variety
                    ? `${v.agProductId} | ${v.variety}`
                    : v.agProductId;
                  return (
                    <IonBadge key={i} color="tertiary">
                      {content}
                    </IonBadge>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {!!sharedTo && <LocationSharedInfo sharedTo={sharedTo} message={message} />}
      </div>
    </div>
  );
};
