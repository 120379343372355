import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  addCircleOutline,
  chevronDown,
  chevronForward,
  closeCircleOutline,
  closeOutline,
  peopleOutline,
  personAddOutline,
  sendOutline,
  trashBin,
} from 'ionicons/icons';
import React, { RefObject } from 'react';
import './ModalShare.scss';
import { Order, Report, User, UserProfile } from './Model';
// import ViewContactName from "./ViewContactName";
// import i18n from "./ServiceI18n";
import { toastController } from '@ionic/core';
import * as EmailValidator from 'email-validator';
import {
  cloneDeep,
  compact,
  countBy,
  find,
  findIndex,
  forEach,
  has,
  map,
  pullAt,
  split,
} from 'lodash';
import { firestore } from './ConfigFirebase';
import { addSharedReport, saveContactGroup } from './DataReport';
import { contactColRef, reportColRef } from './DataStorage';
import withContext, { ContextProps } from './HOC/withContext';

interface Props {
  profile: UserProfile;

  report?: Report;
  order?: Order;

  onCancel: Function;
  onSuccess?: (res?: { emails: string[]; message: string }) => void;
  title?: string;
  contactId?: string;
  shareLink?: string;
  password?: string;
}

interface State {
  inited: true;
  users: User[];
  report: Report;
  search: string;
  bulkEmailList: string[];
  showCompanyContacts: boolean;
  showContacts: boolean;
  showGroups: boolean;
  showGroupModal: boolean;
  emailToGroup: string;
  showNewGroupInput: boolean;
  groupsSelected: {};
}

function isContactChecked(contactEmail: string, contacts: any) {
  if (contacts) {
    return !!find(contacts, (contact) => contact.email === contactEmail);
  } else {
    return false;
  }
}

class ModalShare extends React.Component<Props & ContextProps, State> {
  private messageInput: RefObject<HTMLIonTextareaElement> = React.createRef();
  private listOfEmailsInput: RefObject<HTMLIonTextareaElement> = React.createRef();
  private newGroupInputRef: RefObject<any> = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      inited: true,
      users: [],
      report: undefined,
      search: '',
      bulkEmailList: [],
      showCompanyContacts: false,
      showContacts: true,
      showGroups: true,
      showGroupModal: false,
      emailToGroup: undefined,
      showNewGroupInput: false,
      groupsSelected: {},
    };
  }

  appText_emailSearchPlaceholder = 'Search for emails';

  async componentDidMount(): Promise<void> {
    // this is necessary since when we are calling this from PageDashboard, we have no report, only an order
    let report: Report;
    if (this.props.report) {
      report = this.props.report;
    } else {
      report = (
        await reportColRef(firestore, this.props.profile.organisationId)
          .doc(this.props.order?.latestReportReference.reportId)
          .get()
      ).data() as Report;
    }

    let contactId = report?.contactId ?? this.props.contactId;

    console.log('contactId', contactId);

    const contact = this.props.contacts?.find((contact) => contact.id === contactId);
    let users = [];
    if (contact?.lastContactsUsed) {
      users = contact.lastContactsUsed?.map((lastContactUsed) => ({
        id: lastContactUsed,
        email: lastContactUsed,
      }));
    }
    const lastContactsUsed = contact?.lastContactsUsed ?? [];
    const contactGroups = { ...contact?.contactGroups } ?? {};
    let groupsSelected = {};
    forEach(contactGroups, (emailList, groupName) => {
      groupsSelected[groupName] = this.isGroupPreviouslySelected(
        groupName,
        lastContactsUsed,
        emailList
      );
    });
    this.setState({
      report,
      users,
      groupsSelected,
    });
  }

  async onSend() {
    const groupsSelected = this.state.groupsSelected;
    let groupsSelectedCount = countBy(groupsSelected);

    const message = this.messageInput.current.value;
    const listOfEmails = this.listOfEmailsInput.current.value;
    const {
      // profile,
      onSuccess,
      onCancel,
    } = this.props;
    const { users, report } = this.state;

    if (!users.length && !listOfEmails.length && !groupsSelectedCount['true']) {
      return;
    }

    let allUsers = this.props.users;
    let usersCopy = [...users];
    let contact = this.props.contacts.find(
      (c) => c.id === (report?.contactId ?? this.props.contactId)
    );

    // if contact not found use organization as contact
    if (!contact) {
      contact = this.props.organisation;
    }

    // const contactId = order.contactId

    Object.values(report?.lotInspectionMap ?? {}).map(
      (i) =>
        (i.lotProperties.article.productName = this.props.products.find(
          (p) =>
            p.productId === i.lotProperties.article.productId ||
            p.agProductId === i.lotProperties.article?.agProductId
        )?.agProductId)
    );

    const reportCopy = cloneDeep({
      ...report,
      contactId: contact.id,
      contactName: contact.name,
      id: report?.reportReference?.reportId,
    });

    let listOfEmailsArray = listOfEmails
      .replace(/ /g, '')
      .replace(/\n/g, '')
      .replace(/\t/g, '')
      .split(',')
      .filter((e) => !!e);

    const invalidEmails = listOfEmailsArray.filter((email) => {
      if (!EmailValidator.validate(email)) {
        return email;
      }
      return false;
    });
    listOfEmailsArray = listOfEmailsArray.filter((email) => {
      if (EmailValidator.validate(email)) {
        return email;
      }
      return false;
    });
    if (invalidEmails.length > 0) {
      const shouldContinue = window.confirm(
        'Some emails are invalid, do you want to continue?'
      );
      if (!shouldContinue) return;
    }
    listOfEmailsArray = listOfEmailsArray.filter((email) => {
      let user = allUsers.find((u) => u.email === email);
      if (user) {
        usersCopy.push(user);
        return false;
      } else {
        return true;
      }
    });

    // add agrinorm users from the list of emails to be sent
    listOfEmailsArray.push(...usersCopy.map((o) => o.email));

    // add email from groups
    const unsortedGroups = contact?.contactGroups ?? {};
    forEach(groupsSelected, (isSelected: boolean, groupName) => {
      if (isSelected) {
        listOfEmailsArray.push(...unsortedGroups[groupName]);
      }
    });

    const listOfUniqueEmails = [...new Set(listOfEmailsArray)];
    // return
    if (this.props.report) {
      addSharedReport(
        firestore,
        reportCopy,
        usersCopy,
        listOfUniqueEmails,
        this.props.profile,
        message,
        contact,
        this.props.organisation,
        this.props.locations,
        this.props.users,
        this.props.products,
        this.props.contacts
      )
        .then(async () => {
          const toast = await toastController.create({
            message: `Report ${
              report?.reportName ?? report?.reportReference?.orderId ?? ''
            } has been shared with ${listOfEmailsArray.length} people.`,
            position: 'top',
            color: 'dark',
            duration: 5000,
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
              },
            ],
          });

          toast.present().then();
          // history.push('/secure/inbox/' + conversationId)
          onCancel();
          onSuccess?.();
        })
        .catch((err) =>
          window.alert(`Report could not bet shared: ${JSON.stringify(err)}`)
        );
    }

    if (onSuccess != null) {
      onSuccess({
        emails: listOfUniqueEmails,
        message: message,
      });
    }

    // share order on chat
    // shareOrder(firestore, orderCopy, usersCopy, profile, message, listOfEmailsArray, contact).then(conversationId => {
    //   console.log('share order conversation id:', conversationId)
    // })
  }

  removeContact(contactEmail) {
    const contactId = this.state.report?.contactId ?? this.props.contactId;
    let contact = this.props.contacts.find((c) => c.id === contactId);
    let res = contactColRef(firestore, this.props.profile.organisationId).doc(
      contactId
    );

    res.update({
      contactEmails: [
        ...new Set((contact.contactEmails ?? []).filter((e) => e !== contactEmail)),
      ],
      lastContactsUsed: [
        ...new Set((contact.lastContactsUsed ?? []).filter((e) => e !== contactEmail)),
      ],
    });
  }

  saveNewGroup(groupName?: string) {
    const { report } = this.state;

    if (groupName === undefined) {
      groupName = (window.prompt('Group Name') ?? '').trim();

      if (!(groupName && groupName !== '' && groupName !== undefined)) {
        return;
      }
    }

    let contact = this.props.contacts.find((c) => c.id === report?.contactId);
    let contactGroups = contact.contactGroups ?? {};
    if (has(contactGroups, `${groupName}`)) {
      contactGroups[groupName] = [
        ...new Set([...contactGroups[groupName], this.state.emailToGroup]),
      ];
    } else {
      contactGroups[groupName] = [this.state.emailToGroup];
    }
    saveContactGroup(
      firestore,
      contact,
      contactGroups,
      this.props.profile.organisationId
    );
    this.setState({ showNewGroupInput: false });
  }

  deleteContactGroup(groupName: string) {
    if (!window.confirm(`Are you sure you want to delete this group: ${groupName}?`)) {
      return;
    }
    const { report } = this.state;
    let contact = this.props.contacts.find((c) => c.id === report?.contactId);
    let contactGroups = { ...contact?.contactGroups } ?? {};
    delete contactGroups[groupName];
    saveContactGroup(
      firestore,
      contact,
      contactGroups,
      this.props.profile.organisationId
    );
  }

  deleteContactFromGroup(groupName: string, email: string) {
    const { report } = this.state;
    let contact = this.props.contacts.find((c) => c.id === report?.contactId);
    let contactGroups = { ...contact?.contactGroups } ?? {};
    let emailList = contactGroups[groupName].filter(
      (groupEmail) => groupEmail !== email
    );
    contactGroups[groupName] = emailList;
    saveContactGroup(
      firestore,
      contact,
      contactGroups,
      this.props.profile.organisationId
    );
  }

  updateContactInGroup(e, groupName: string, email: string) {
    const isChecked = e.detail.checked;
    // const {report} = this.state;
    if (isChecked) {
      this.saveNewGroup(groupName);
    } else {
      this.deleteContactFromGroup(groupName, email);
    }
  }

  isEmailInGroup(groupName, email): boolean {
    let isEmailInGroup = false;
    const { report } = this.state;
    let contact = this.props.contacts.find((c) => c.id === report?.contactId);
    let contactGroups = { ...contact?.contactGroups } ?? {};
    forEach(contactGroups[groupName], (groupEmail) => {
      if (groupEmail === email) {
        isEmailInGroup = true;
      }
    });
    return isEmailInGroup;
  }

  isGroupPreviouslySelected(
    groupName,
    lastContactsUsed = [],
    emailList: string[] = undefined
  ) {
    const emailsFound: boolean[] = [];
    // const {report} = this.state;
    // let contact = this.props?.applicationContext?.contacts?.find((c) => c?.id === report?.contactId)

    if (emailList && emailList.length === 0) return false;
    forEach(emailList, (groupMail) => {
      const wasEmailLastUsed = !!find(
        lastContactsUsed,
        (contact) => contact === groupMail
      );
      wasEmailLastUsed ? emailsFound.push(true) : emailsFound.push(false);
    });
    const emailsFoundCompacted = compact(emailsFound);

    return emailsFoundCompacted.length === emailsFound.length;
  }

  addContact() {
    let email = window.prompt('Add new contact. Please type a valid email address');
    if (!email) {
      return;
    }
    const contactId = this.state.report?.contactId ?? this.props.contactId;
    let contact = this.props.contacts.find((c) => c.id === contactId);
    if (contactId == null || !contact) {
      this.setState({ bulkEmailList: [...this.state.bulkEmailList, email] });
      return;
    }
    let res = contactColRef(firestore, this.props.profile.organisationId).doc(
      contactId
    );

    res.update({
      contactEmails: [...new Set((contact?.contactEmails ?? []).concat(email))],
    });
  }

  contactToggle(contact) {
    let users = [...this.state.users];
    if (this.state.users.find((c) => c.id === contact.id)) {
      this.setState({ users: users.filter((c) => c.id !== contact.id) });
    } else {
      users.push(contact);
      this.setState({ users });
    }
  }

  filterContacts(contacts: string[]) {
    const { search } = this.state;
    if (!search || (contacts ?? []).length === 0) {
      return contacts;
    }
    return contacts.filter(
      (c) => c.includes(search) || isContactChecked(c, this.state.users)
    );
  }

  filterCompanyContacts(users: User[]) {
    const { search } = this.state;
    if (!search || (users ?? []).length === 0) {
      return users;
    }
    return users.filter(
      (c) => c.email.includes(search) || isContactChecked(c.email, this.state.users)
    );
  }

  getEmailsFromTextArea(emailListString) {
    const separatorRegex = /[\n ,]+/;
    const emailList = split(emailListString, separatorRegex);
    this.setState({ bulkEmailList: emailList });
  }

  isEmailValid(email) {
    const emailNoNewLine = email.replace(/\n/g, '');
    return String(emailNoNewLine)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  selectedEmails() {
    let listOfEmailsArray = [];
    const { users, bulkEmailList, groupsSelected, report } = this.state;
    const contact = this.props.contacts?.find(
      (contact) => contact.id === report?.contactId
    );
    const unsortedGroups = contact?.contactGroups ?? {};
    const a = (users ?? []).map((user) => user.email);
    const b = bulkEmailList.filter((e) => this.isEmailValid(e)) || [];
    forEach(groupsSelected, (isSelected: boolean, groupName) => {
      if (isSelected) {
        listOfEmailsArray.push(...(unsortedGroups[groupName] ?? []));
      }
    });
    return [...new Set([...a, ...b, ...listOfEmailsArray])].length;
  }

  render() {
    let {
      profile,
      onCancel,
      title,
      contactId,
      contacts: propContacts,
      users,
    } = this.props;
    title = title ?? 'Share Report';
    const { report } = this.state;
    const dividerWrapper = (
      icon: string,
      text: string,
      color: string = 'light',
      slot: string = 'start',
      showMore: boolean = null,
      setShowMore: React.Dispatch<React.SetStateAction<boolean>> = (_: boolean) => null
    ) => (
      <IonItemDivider color={color} onClick={() => setShowMore(!showMore)}>
        <IonListHeader style={{ padding: '0' }}>{text}</IonListHeader>
        {showMore != null && (
          <>
            &nbsp;
            <IonIcon icon={showMore ? chevronDown : chevronForward} />
          </>
        )}
      </IonItemDivider>
    );

    const contact = propContacts?.find(
      (contact) => contact.id === (report?.contactId ?? this.props.contactId)
    );

    // console.log(applicationContext)

    let contacts = contact?.contactEmails?.sort((a, b) => a.localeCompare(b));
    let companyContacts = users
      .filter((c) => c.organisationId === profile.organisationId)
      .filter(
        (c) =>
          c.isAgrinormTestUser !== true && !c.email?.toLowerCase().includes('agrinorm')
      )
      .sort((a, b) => a.email.localeCompare(b.email));

    contacts = this.filterContacts(contacts);
    companyContacts = this.filterCompanyContacts(companyContacts);
    const unsortedGroups = contact?.contactGroups ?? {};
    const unsortedGroupList = Object.keys(unsortedGroups);
    // let contactGroups = unsortedGroupList.sort((a:any, b:any) =>  (a - b))
    let contactGroups = unsortedGroupList.sort();
    return (
      <div className="modal-share-report ion-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={(_) => onCancel()} color="dark">
                <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
            <b className="b-title">{title}</b>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                onClick={(_) => this.addContact()}
                fill="solid"
              >
                add contact
                <IonIcon icon={personAddOutline} slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              showClearButton="always"
              mode="ios"
              placeholder={this.appText_emailSearchPlaceholder}
              onIonChange={(e) => this.setState({ search: e.detail.value })}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader> */}
          {!!contact && (
            <div className="contact">
              {/* <ViewContactName contactId={order.contactId} /> */}
              {contact?.name.toLocaleLowerCase()}
              {this.selectedEmails() > 0 && (
                <IonBadge color="tertiary">{this.selectedEmails()} selected</IonBadge>
              )}
            </div>
          )}
          {!!this.props.shareLink && (
            <div className="share-link">
              <div>
                <b>Share Link:</b>
                <br />
                <a href={this.props.shareLink} target="_blank">
                  {this.props.shareLink}
                </a>
                <div style={{ marginTop: 10 }}>
                  <b>Password:</b> {this.props.password}
                </div>
              </div>
              {/* <IonButton
                onClick={() => {
                  navigator.clipboard.writeText(this.props.shareLink);
                  toastController.create({
                    message: `Copied to clipboard`,
                    position: 'top',
                    color: 'dark',
                    duration: 5000,
                  });
                }}
                color="dark"
              >
                Copy
              </IonButton> */}
            </div>
          )}
          <div className="grid">
            <div className="first-col">
              <IonList>
                {dividerWrapper(
                  null,
                  'Groups',
                  undefined,
                  undefined,
                  this.state.showGroups,
                  (value: any) => this.setState({ showGroups: value })
                )}
                {this.state.showGroups && (
                  <>
                    {/*{map(contactGroups, (emailList, groupName) => {*/}
                    {map(contactGroups, (groupName) => {
                      return (
                        <div
                          key={groupName}
                          style={{ borderBottom: '2px solid #e6e6e6' }}
                        >
                          <IonItemSliding>
                            <IonItem className="group-item">
                              <IonCheckbox
                                slot="start"
                                checked={this.state.groupsSelected[groupName]}
                                onClick={() => {
                                  const groupsSelected = this.state.groupsSelected;
                                  this.setState({
                                    groupsSelected: {
                                      ...groupsSelected,
                                      [groupName]: !groupsSelected[groupName],
                                    },
                                  });
                                }}
                              />
                              <IonLabel className="group-label">
                                <IonIcon icon={peopleOutline} />
                                {groupName}
                              </IonLabel>
                            </IonItem>
                            <IonItemOptions side="end">
                              <IonItemOption
                                color="danger"
                                onClick={() => this.deleteContactGroup(groupName)}
                              >
                                <IonIcon slot="icon-only" icon={trashBin} />
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>

                          {map(unsortedGroups[groupName], (email: string) => {
                            return (
                              <IonItemSliding key={email} style={{ cursor: 'pointer' }}>
                                <IonItem>
                                  <IonLabel style={{ paddingLeft: '45px' }}>
                                    {email}
                                  </IonLabel>
                                </IonItem>
                                <IonItemOptions side="end">
                                  <IonItemOption
                                    color="danger"
                                    onClick={() =>
                                      this.deleteContactFromGroup(groupName, email)
                                    }
                                  >
                                    <IonIcon slot="icon-only" icon={trashBin} />
                                  </IonItemOption>
                                </IonItemOptions>
                              </IonItemSliding>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                )}

                {dividerWrapper(
                  null,
                  'Contacts from my company',
                  undefined,
                  undefined,
                  this.state.showCompanyContacts,
                  (value: any) => this.setState({ showCompanyContacts: value })
                )}
                {this.state.showCompanyContacts && (
                  <>
                    {(companyContacts ?? []).length > 0 ? (
                      (companyContacts ?? []).map((contact) => {
                        return (
                          <IonItemSliding key={contact.id}>
                            <IonItem key={contact.id}>
                              <IonCheckbox
                                slot="start"
                                onIonChange={(_) => this.contactToggle(contact)}
                              />
                              <IonLabel>{contact.email}</IonLabel>
                            </IonItem>
                            <IonItemOptions>
                              <IonItemOption
                                color="primary"
                                onClick={() =>
                                  this.setState({
                                    showGroupModal: true,
                                    emailToGroup: contact.email,
                                  })
                                }
                              >
                                <IonIcon slot="icon-only" icon={peopleOutline} />
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        );
                      })
                    ) : (
                      <IonItem>
                        <i>No matches found</i>
                      </IonItem>
                    )}
                  </>
                )}
                {!!contact &&
                  dividerWrapper(
                    null,
                    'Contacts',
                    undefined,
                    undefined,
                    this.state.showContacts,
                    (value: any) => this.setState({ showContacts: value })
                  )}
                {this.state.showContacts && !!contact && (
                  <>
                    {contact?.contactEmails?.length > 0 && (
                      <>
                        {(contacts ?? []).length > 0 ? (
                          (contacts ?? []).map((contact) => {
                            const isChecked = isContactChecked(
                              contact,
                              this.state.users
                            );

                            return (
                              <IonItemSliding key={contact}>
                                <IonItem key={contact}>
                                  <IonCheckbox
                                    slot="start"
                                    onClick={(_) =>
                                      this.contactToggle({
                                        id: contact,
                                        email: contact,
                                      })
                                    }
                                    checked={isChecked}
                                  />
                                  <IonLabel>{contact}</IonLabel>
                                </IonItem>
                                <IonItemOptions side="end">
                                  <IonItemOption
                                    color="primary"
                                    onClick={() =>
                                      this.setState({
                                        showGroupModal: true,
                                        emailToGroup: contact,
                                      })
                                    }
                                  >
                                    <IonIcon slot="icon-only" icon={peopleOutline} />
                                  </IonItemOption>
                                  <IonItemOption
                                    color="danger"
                                    onClick={() => this.removeContact(contact)}
                                  >
                                    <IonIcon slot="icon-only" icon={trashBin} />
                                    {/* Archive */}
                                  </IonItemOption>
                                </IonItemOptions>
                              </IonItemSliding>
                            );
                          })
                        ) : (
                          <IonItem>
                            <i>No matches found</i>
                          </IonItem>
                        )}
                      </>
                    )}
                  </>
                )}
              </IonList>
            </div>
            <div className="second-col">
              <IonList>
                <IonListHeader>Message</IonListHeader>
                <IonItem>
                  <IonTextarea
                    rows={7}
                    placeholder="type your message..."
                    ref={this.messageInput}
                  />
                </IonItem>
              </IonList>
              <IonList>
                <IonListHeader>Import e-mails</IonListHeader>
                <IonItem className="bulk-list">
                  {/*{this.state.invalidEmailList.reduce((emailListHTML, email, i ) => {*/}
                  {/*  return ( emailListHTML + <div key={i}>{email}</div>)*/}
                  {/*}, <div /> )}*/}

                  <div className="bulkEmailValidator">
                    {this.state.bulkEmailList.map((email, i) => {
                      if (email.length === 0) return null;
                      return (
                        <div key={i} className="bulkEmailValidatorItem">
                          <div
                            key={i}
                            className={
                              EmailValidator.validate(email)
                                ? 'bulkEmailValidatorContainer'
                                : 'bulkEmailValidatorContainer invalidEmailContainer'
                            }
                            onClick={() => {
                              let emailList = [...this.state.bulkEmailList];
                              pullAt(emailList, [
                                findIndex(emailList, (e) => e === email),
                              ]);
                              this.setState({ bulkEmailList: emailList });
                            }}
                          >
                            <span className="bulkEmailLabel">{email} </span>
                            <IonIcon
                              size="small"
                              slot="icon-only"
                              icon={closeCircleOutline}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </IonItem>
                <IonItem>
                  <IonTextarea
                    data-tip={'report-share-import-emails'}
                    value={`${this.state.bulkEmailList}`}
                    onIonChange={(e) => this.getEmailsFromTextArea(e.detail.value)}
                    rows={6}
                    disabled={false}
                    placeholder='copy & paste a list of emails separated by comma "," and they will be added to your contacts'
                    ref={this.listOfEmailsInput}
                  />
                </IonItem>
                <IonItem
                  style={{ borderTop: '1px solid var(--ion-color-light-medium)' }}
                >
                  <b style={{ padding: '10px 0' }}>
                    * Hint: slide left any contact/email to delete or add to a group.
                  </b>
                </IonItem>
              </IonList>
            </div>
          </div>
          <div className="bottom-button">
            {this.selectedEmails() > 0 && (
              <IonButton
                data-tip={'share-report-send-button'}
                color="tertiary"
                onClick={(_) => this.onSend()}
              >
                <IonIcon icon={sendOutline} slot="start" /> Send
              </IonButton>
            )}
          </div>
          <div>
            <IonModal
              onDidDismiss={() =>
                this.setState({
                  showGroupModal: false,
                  emailToGroup: undefined,
                  showNewGroupInput: false,
                })
              }
              isOpen={this.state.showGroupModal}
              cssClass="contact-group-modal"
            >
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton
                      onClick={() =>
                        this.setState({
                          showGroupModal: false,
                          emailToGroup: undefined,
                        })
                      }
                    >
                      <IonIcon icon={closeOutline} color="dark"></IonIcon>
                    </IonButton>
                  </IonButtons>
                  <IonButtons slot="end">
                    <IonButton
                      color="tertiary"
                      fill="solid"
                      onClick={() => this.saveNewGroup()}
                    >
                      {/* <IonIcon icon={addCircleOutline}/> */}
                      Create Group
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Groups</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonItem>
                  Add&nbsp;<b>{this.state.emailToGroup}</b>&nbsp;to:
                </IonItem>
                <IonList>
                  {/*{map(unsortedGroups, (emailList, groupName) => {*/}
                  {map(contactGroups, (groupName) => {
                    const isEmailInGroup = this.isEmailInGroup(
                      groupName,
                      this.state.emailToGroup
                    );
                    return (
                      <IonItem key={groupName}>
                        <IonCheckbox
                          slot="start"
                          checked={isEmailInGroup}
                          onIonChange={(e) =>
                            this.updateContactInGroup(
                              e,
                              groupName,
                              this.state.emailToGroup
                            )
                          }
                        />
                        <IonLabel>{groupName}</IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonContent>
              <div
                className={
                  this.state.showNewGroupInput ? 'new-group-input-group' : 'hidden'
                }
              >
                <IonInput
                  ref={this.newGroupInputRef}
                  placeholder="Enter group name"
                  clearInput
                ></IonInput>
                <IonButton
                  color="primary"
                  expand="full"
                  onClick={() => this.saveNewGroup(this.newGroupInputRef.current.value)}
                >
                  <IonIcon icon={addCircleOutline} />
                  Save Group
                </IonButton>
              </div>
            </IonModal>
          </div>
        </IonContent>
      </div>
    );
  }
}

export default withContext<Props>(ModalShare);
