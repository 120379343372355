import { replaceAll } from './HelperUtils';
import { IArticle } from './Model';
import { PackagingType } from './generated/openapi/core';

export const NUM_REGEX = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
export const LETTER_REGEX = /^[A-Za-z]+$/;

export interface ArticleClass {
  getArticle: () => IArticle;
  // getNewArticle: (d?: boolean) => IArticle;
  getPackagingRepr: () => string;
  computeBoxNetWeight: (r?: boolean) => void | number;
  computeAvgPieceWeightInGrams: (r?: boolean) => void | number;
  getUnit: (n?: number) => string;
  getArticleInfoArray: (s?: boolean) => string[];
  validatePackaging: () => boolean;
  computePackagingRelatedFieldsFromPackagingString: (override?: boolean) => void;
  getArticleSummary: () => { [key: string]: Partial<IArticle> };
  isRaw: () => boolean;
  computeVolumeInKg: (n: number) => number;
  computeNumberOfBoxes: (n: number) => number;
}

export class Article implements ArticleClass {
  private article: IArticle;
  private logErrors: boolean = false;

  constructor(article?: IArticle, skipComputeBoxNetWeight: boolean = false) {
    this.article = article ?? ({} as IArticle);
    if (!skipComputeBoxNetWeight) {
      this.computeBoxNetWeight();
    }
  }

  public getArticle(): IArticle {
    return this.article;
  }

  public isRaw(): boolean {
    return this.article.packagingType === PackagingType.Raw;
  }

  public isBulk(): boolean {
    return this.article.packagingType === PackagingType.Bulk;
  }

  public isPacked(): boolean {
    return (
      [
        PackagingType.ConsumerUnitsWeight,
        PackagingType.ConsumerUnitsPieces,
      ] as PackagingType[]
    ).includes(this.article.packagingType);
  }

  public computeBoxNetWeight(ret: boolean = false) {
    if (
      this.article.packagingType === PackagingType.ConsumerUnitsWeight &&
      !isNaN(this.article.consumerUnitWeightInGrams) &&
      !isNaN(this.article.numConsumerUnitsInBox)
    ) {
      // rounding to 3 decimals
      this.article.boxNetWeightInKg = +(
        (this.article.consumerUnitWeightInGrams / 1000) *
        this.article.numConsumerUnitsInBox
      ).toFixed(3);
    }
    if (
      this.article.packagingType === PackagingType.ConsumerUnitsPieces &&
      !isNaN(this.article.numConsumerUnitsInBox) &&
      !isNaN(this.article.avgPieceWeightInGrams) &&
      !isNaN(this.article.consumerUnitNumberOfPieces)
    ) {
      // rounding to 3 decimals
      this.article.boxNetWeightInKg = +(
        (this.article.consumerUnitNumberOfPieces *
          this.article.numConsumerUnitsInBox *
          this.article.avgPieceWeightInGrams) /
        1000
      ).toFixed(3);
    }
    if (ret) {
      return this.getArticle().boxNetWeightInKg;
    }
  }

  public computeAvgPieceWeightInGrams(ret: boolean = false) {
    if (
      this.article.packagingType === PackagingType.ConsumerUnitsPieces &&
      !isNaN(this.article.numConsumerUnitsInBox) &&
      !isNaN(this.article.boxNetWeightInKg) &&
      !isNaN(this.article.consumerUnitNumberOfPieces)
    ) {
      // rounding to 3 decimals
      this.article.avgPieceWeightInGrams = +(
        (this.article.boxNetWeightInKg /
          this.article.numConsumerUnitsInBox /
          this.article.consumerUnitNumberOfPieces) *
        1000
      ).toFixed(3);
    }
    if (ret) {
      return this.getArticle().avgPieceWeightInGrams;
    }
  }

  // returns the packaging representation string
  public getPackagingRepr() {
    switch (this.article.packagingType) {
      case PackagingType.Bulk:
        if (this.article.boxNetWeightInKg == null) {
          return '';
        }
        return `${parseFloat(this.article.boxNetWeightInKg.toFixed(2))}K`;
      case PackagingType.ConsumerUnitsWeight:
        if (
          this.article.numConsumerUnitsInBox == null ||
          this.article.consumerUnitWeightInGrams == null
        ) {
          return '';
        }
        return `${this.article.numConsumerUnitsInBox}x${this.article.consumerUnitWeightInGrams}g`;
      case PackagingType.ConsumerUnitsPieces:
        if (
          this.article.numConsumerUnitsInBox == null ||
          this.article.consumerUnitNumberOfPieces == null
        ) {
          return '';
        }
        return `${this.article.numConsumerUnitsInBox}x${this.article.consumerUnitNumberOfPieces}pcs`;
      case PackagingType.Raw:
        return PackagingType.Raw;
      default:
        return undefined;
    }
  }

  // TODO DEV-818: name better
  public getArticleSummary(): { [key: string]: Partial<IArticle> } {
    const {
      avgPieceWeightInGrams,
      numConsumerUnitsInBox,
      consumerUnitNumberOfPieces,
      consumerUnitWeightInGrams,
      boxGrossWeightInKg,
      boxNetWeightInKg,
    } = this.article;
    const articleSummary: { [key: string]: Partial<IArticle> } = {
      [this.getArticleSummaryKey()]: {
        avgPieceWeightInGrams,
        consumerUnitNumberOfPieces,
        numConsumerUnitsInBox,
        boxNetWeightInKg,
        consumerUnitWeightInGrams,
        boxGrossWeightInKg,
      },
    };
    return articleSummary;
  }

  private getArticleSummaryKey(): string {
    const articleFieldsMapping = [
      ['avgPieceWeightInGrams', 'APW'],
      ['numConsumerUnitsInBox', 'NCUB'],
      ['consumerUnitNumberOfPieces', 'CUNP'],
      ['consumerUnitWeightInGrams', 'CUWG'],
      ['boxGrossWeightInKg', 'BGW'],
      ['boxNetWeightInKg', 'BNW'],
    ].sort((a, b) => (a[0] > b[0] ? 1 : -1));

    return articleFieldsMapping
      .map(([key, shortenedKey]) => `${shortenedKey}:${this.article[key] ?? ''}`)
      .join(';');
  }

  // returns BOXES or KG depending on the packaging type
  public getUnit(quantity: number = 0) {
    if (isNaN(quantity)) {
      return 'BOXES';
    }
    const isPlural = quantity <= 0 || quantity > 1;
    switch (this.article.packagingType) {
      case PackagingType.Raw:
        return `KG${isPlural ? '' : ''}`;
      case PackagingType.Bulk:
      case PackagingType.ConsumerUnitsWeight:
      case PackagingType.ConsumerUnitsPieces:
      default:
        return `BOX${isPlural ? 'ES' : ''}`;
    }
  }

  public computeVolumeInKg(numBoxes: number): number {
    if (this.isRaw()) {
      return undefined;
    }
    if (isNaN(+numBoxes)) {
      this.logErrors &&
        console.error('computeVolumeInKg: number of boxes is not valid');
      return undefined;
    }
    if (this.article.boxNetWeightInKg == null) {
      this.logErrors && console.error('computeVolumeInKg: boxNetWeightInKg is not set');
      return undefined;
    }
    return parseFloat((+this.article.boxNetWeightInKg * +numBoxes).toFixed(2));
  }

  public computeNumberOfBoxes(volumeInKg: number): number {
    if (this.isRaw()) {
      return undefined;
    }
    if (isNaN(+volumeInKg)) {
      this.logErrors && console.error('computeNumberOfBoxes: volume is not valid');
      return undefined;
    }
    if (this.article.boxNetWeightInKg == null) {
      this.logErrors &&
        console.error('computeNumberOfBoxes: boxNetWeightInKg is not set');
      return undefined;
    }
    return Math.floor(volumeInKg / this.article.boxNetWeightInKg);
  }

  // migrates an old article to the new model
  // public getNewArticle(deleteOldFields: boolean = false): IArticle {
  //   let article = cloneDeep(this.article);

  //   // if packagingType is present, it means it's already in the new format
  //   if (!article.packagingType) {
  //   const packagingType: PackagingType = article.packed
  //       ? ((article.packaging ?? '').toLowerCase().includes('pcs') || (article.packaging ?? '').toLowerCase().includes('s'))
  //         ? PackagingType.ConsumerUnitsPieces
  //         : PackagingType.ConsumerUnitsWeight
  //       : PackagingType.Bulk;

  //     const isBio: boolean = (article.certificates ?? []).includes('BIO');
  //     const boxGrossWeightInKg = article.grossWeight;
  //     const avgPieceWeightInGrams = article.avgPieceWeight;
  //     const extPackagingRepr = article.packaging;

  //     const newArticle = new Article({ ...article, packagingType, boxGrossWeightInKg, isBio, avgPieceWeightInGrams, extPackagingRepr });
  //     newArticle.computePackagingRelatedFieldsFromPackagingString(true);
  //     article = newArticle.getArticle();
  //   }

  //   if (deleteOldFields) {
  //     const oldFields = ['packaging', 'grossWeight', 'netWeight', 'packed', 'certificates', 'description', 'auxProductId', 'numConsumerUnits', 'pickingDate', 'supplier', 'avgPieceWeight'];
  //     oldFields.forEach(field => delete article[field]);
  //   }
  //   delete article.lastModifiedDate;
  //   return article;
  // }

  public getArticleInfoArray() {
    if (!this.article) {
      return [];
    }

    const packaging = this.article.extPackagingRepr ?? this.getPackagingRepr();

    const isBio = this.article?.isBio ? 'BIO' : undefined;

    return [
      this.article.agVariety ?? this.article.variety,
      // packagingType,
      packaging,
      this.article.origin,
      this.article.brand,
      this.article.size,
      this.article.category,
      this.article.isOrganic ? 'ORGANIC' : undefined,
      isBio,
    ].filter((o) => !!o);
  }

  public validatePackaging(fromAddPackagingModal: boolean = false) {
    return (
      !!this.article.packagingType &&
      ((this.article.packagingType === PackagingType.ConsumerUnitsWeight &&
        this.article.numConsumerUnitsInBox != null &&
        this.article.consumerUnitWeightInGrams != null) ||
        (this.article.packagingType === PackagingType.ConsumerUnitsPieces &&
          this.article.numConsumerUnitsInBox != null &&
          this.article.consumerUnitNumberOfPieces != null &&
          (fromAddPackagingModal || this.article.avgPieceWeightInGrams != null)) ||
        (this.article.packagingType === PackagingType.Bulk &&
          this.article.boxNetWeightInKg != null) ||
        this.isRaw())
    );
  }

  private inferPackagingTypeFromPackRepr(packagingReprParam?: string) {
    let packRepr = packagingReprParam ?? this.article.extPackagingRepr;
    if (!packRepr) {
      this.logErrors &&
        console.error(
          'inferPackagingTypeFromPackRepr: Could not determine packaging representation'
        );
      return;
    }
    packRepr = packRepr.toLowerCase();

    if (
      !packRepr.includes('x') &&
      (packRepr.includes('k') || packRepr.includes('g') || packRepr.includes('co'))
    ) {
      this.article.packagingType = PackagingType.Bulk;
    } else if (packRepr.includes('x')) {
      if (packRepr.includes('s') || packRepr.includes('pcs')) {
        this.article.packagingType = PackagingType.ConsumerUnitsPieces;
      } else {
        this.article.packagingType = PackagingType.ConsumerUnitsWeight;
      }
    }
  }

  // used to migrate from old packaging string to the new model
  public computePackagingRelatedFieldsFromPackagingString(
    overrideFields: boolean = false,
    packagingReprParam?: string
  ) {
    let { packagingType, extPackagingRepr } = this.article;

    let packRepr = packagingReprParam ?? extPackagingRepr;

    if (!packagingType) {
      this.inferPackagingTypeFromPackRepr(packagingReprParam);
      packagingType = this.article.packagingType;
    }

    if (!packagingType || !packRepr) {
      this.logErrors &&
        console.error(
          'computePackagingRelatedFieldsFromPackagingString: Could not determine packaging type or packaging representation'
        );
      return;
    }

    packRepr = replaceAll(packRepr, ',', '.');

    const shouldSet = (field: string) => overrideFields || this.article[field] == null;

    if (packagingType === PackagingType.Bulk) {
      try {
        if (shouldSet('boxNetWeightInKg')) {
          const match = packRepr.match(NUM_REGEX);
          const numbers = match != null ? match.join('') : null;

          if (numbers != null) {
            const unitLetter = packRepr.replace(/[0-9]/g, '').toLowerCase();
            let divisor: number = 1;
            switch (unitLetter) {
              case 'g':
                divisor = 1000;
                break;
              case 'k':
              default:
                break;
            }
            this.article.boxNetWeightInKg = parseFloat(numbers) / divisor;
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let numbersFromPackedType = packRepr.match(NUM_REGEX);
      const isFruitsPerPunnet = packagingType === PackagingType.ConsumerUnitsPieces;
      const isInKg = packRepr.match(/k/) || packRepr.match(/K/);

      if (numbersFromPackedType?.length >= 2) {
        // for special case of SF in the format 'N1xN2xN3"
        if (((packRepr ?? '').toLowerCase().match(/x/g) ?? []).length > 1) {
          numbersFromPackedType = [
            (+numbersFromPackedType[0] * +numbersFromPackedType[1]).toFixed(),
            numbersFromPackedType?.[2] ?? '1',
          ];
        }
        if (shouldSet('numConsumerUnitsInBox')) {
          this.article.numConsumerUnitsInBox = +numbersFromPackedType[0];
        }
        if (isFruitsPerPunnet) {
          if (shouldSet('consumerUnitNumberOfPieces')) {
            this.article.consumerUnitNumberOfPieces = +numbersFromPackedType[1];
          }
          if (
            shouldSet('avgPieceWeightInGrams') &&
            this.article.boxNetWeightInKg != null &&
            this.article.numConsumerUnitsInBox != null &&
            this.article.consumerUnitNumberOfPieces != null
          ) {
            this.article.avgPieceWeightInGrams = +(
              (+this.article.boxNetWeightInKg /
                +this.article.numConsumerUnitsInBox /
                +this.article.consumerUnitNumberOfPieces) *
              1000
            );
          }
        } else {
          if (shouldSet('consumerUnitWeightInGrams')) {
            this.article.consumerUnitWeightInGrams =
              +numbersFromPackedType[1] * (isInKg ? 1000 : 1);
          }
        }
      } else if (numbersFromPackedType?.length === 1) {
        if (shouldSet('numConsumerUnitsInBox')) {
          this.article.numConsumerUnitsInBox = +numbersFromPackedType[0];
        }
        if (isFruitsPerPunnet) {
          if (shouldSet('consumerUnitNumberOfPieces')) {
            this.article.consumerUnitNumberOfPieces = 1;
          }
          if (
            shouldSet('avgPieceWeightInGrams') &&
            this.article.boxNetWeightInKg != null &&
            this.article.numConsumerUnitsInBox != null &&
            this.article.consumerUnitNumberOfPieces != null
          ) {
            this.article.avgPieceWeightInGrams = +(
              (this.article.boxNetWeightInKg /
                +this.article.numConsumerUnitsInBox /
                +this.article.consumerUnitNumberOfPieces) *
              1000
            );
          }
        } else {
          if (shouldSet('consumerUnitWeightInGrams')) {
            this.article.consumerUnitWeightInGrams = +numbersFromPackedType[0];
          }
        }
      } else {
        if (shouldSet('numConsumerUnitsInBox')) {
          this.article.numConsumerUnitsInBox = 1;
        }
        if (isFruitsPerPunnet) {
          if (shouldSet('consumerUnitNumberOfPieces')) {
            this.article.consumerUnitNumberOfPieces = +packRepr;
          }
        } else {
          if (shouldSet('consumerUnitWeightInGrams')) {
            this.article.consumerUnitWeightInGrams = +packRepr;
          }
        }
      }
    }
  }
}
