import {
  IonButton,
  IonButtons,
  IonContent,
  // IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { toastController } from '@ionic/core';
import { closeOutline, readerOutline, warningOutline } from 'ionicons/icons';
import React, { Fragment } from 'react';
import { firestore } from './ConfigFirebase';
import { getLot, importProductionOrder } from './DataStorage';
import {
  IArticle,
  Lot,
  LotPosition,
  ProductionPayload,
  TransformationReason,
  articlePackagingFields,
  transformationTypesMap,
} from './Model';
import './PageLotTransformation.scss';

import { isEqual } from 'lodash';
import withContext, { ContextProps } from './HOC/withContext';
import {
  articlePropToTitle,
  lotId as getLotId,
  productionOrderId,
  removeSpecialChars,
} from './HelperUtils';
import { Article } from './ServiceArticle';
import ViewAddProductPackagingModal from './ViewAddProductPackagingModal';
import CWizardNavigation, { NavigationProps } from './components/CWizardNavigation';
import { PackagingType } from './generated/openapi/core';

interface Props {
  motherLots: Lot[];
  onCancel: Function;
  onSave: (splitLotOrder: ProductionPayload) => any;
  onSuccess: Function;
}

interface State {
  outgoingPosition: LotPosition;
  incomingPositions: LotPosition[];
  reason: TransformationReason;

  locationId: string;

  section: LotTransformationSection;

  loading: boolean;
  validId: boolean;

  addedPalletIds: string[];
  palletIdToAdd: string;

  modalPackaging: boolean;

  checkingLotId: boolean;
  connectionIssue: boolean;
}

type LotTransformationSection = 'reason' | 'from' | 'to' | 'warehouse';
const sectionOrder: LotTransformationSection[] = ['reason', 'from', 'to'];
class PageLotTransformation extends React.Component<Props & ContextProps, State> {
  nonEditableArticleParams = [
    'agProductId',
    'variety',
    'numConsumerUnitsInBox',
    'consumerUnitNumberOfPieces',
    'consumerUnitWeightInGrams',
    'boxGrossWeightInKg',
    'size',
    'origin',
  ];

  constructor(props: Props) {
    super(props);

    const { motherLots } = this.props;

    const incomingPositions: LotPosition[] = motherLots.map((l) => {
      const numBoxes = l.transient?.numBoxes ?? 0;
      const volumeInKg = l.transient?.volumeInKg ?? 0;
      return {
        lotId: l.id,
        numBoxes,
        volumeInKg,
        palletIds: l.transient?.palletIds,
        article: l.article,
      };
    });

    const varieties = [...new Set(motherLots.map((lot) => lot.article?.variety))];

    const article: IArticle =
      varieties.length > 1
        ? { ...motherLots[0].article, variety: 'MIX', agVariety: 'MIX' }
        : { ...motherLots[0].article };

    const outgoingPosition: LotPosition = {
      lotId: '',
      numBoxes: 0,
      volumeInKg: 0,
      // palletIds: motherLots.flatMap(l => l.transient?.palletIds ?? []),
      article,
    };
    delete outgoingPosition.article.id;

    this.state = {
      incomingPositions,
      outgoingPosition,
      reason: 'QUALITY_SORT',

      section: 'reason',
      locationId: undefined,

      validId: true,
      loading: true,

      addedPalletIds: [],
      palletIdToAdd: '',
      modalPackaging: false,
      checkingLotId: false,
      connectionIssue: false,
    };
  }

  async componentDidMount(): Promise<void> {
    // check if the id is not used
    let lotId: string;
    while (!lotId) {
      const provLotIt = getLotId();
      const lot: Lot = await getLot(
        firestore,
        this.props.profile.organisationId,
        provLotIt
      );
      if (!lot) {
        lotId = provLotIt;
      }
    }
    this.setState({
      loading: false,
      outgoingPosition: { ...this.state.outgoingPosition, lotId },
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!isEqual(this.state, prevState)) {
      console.log('New state:', this.state);
    }
    const newArticle = this.state.outgoingPosition.article;
    if (!isEqual(newArticle, prevState.outgoingPosition.article)) {
      this.setState({
        outgoingPosition: {
          ...this.state.outgoingPosition,
          volumeInKg: new Article(newArticle).computeVolumeInKg(
            this.state.outgoingPosition.numBoxes
          ),
        },
      });
    }
  }

  async onSave() {
    const { incomingPositions, outgoingPosition, locationId, reason } = this.state;
    const { motherLots } = this.props;

    // if there is a single supplier among the source lots, we add it to the payload such that the target lot also has it
    let suppliedByContactId: string;
    if (
      Array.from(new Set(motherLots.map((l) => l.suppliedByContactId))).length === 1
    ) {
      suppliedByContactId = motherLots[0].suppliedByContactId;
    }

    const payload: ProductionPayload = {
      id: productionOrderId(),
      incomingPositions,
      outgoingPositions: [outgoingPosition],
      locationId,
      fulfilmentDate: Date.now(),
      wasteVolumeInKg: this.getUnusedVolume().unusedVolume,
      isAGMaster: true,
      reason,
      suppliedByContactId,
    };
    this.setState({ loading: true });
    await importProductionOrder(firestore, this.props.profile, payload);
    this.setState({ loading: false }, () => this.props.onSuccess());
  }

  async setLotId(lotId: string) {
    lotId = removeSpecialChars(lotId).toUpperCase();
    this.setState({ outgoingPosition: { ...this.state.outgoingPosition, lotId } });
  }

  checkLotId = async () => {
    this.setState({ checkingLotId: true, connectionIssue: false });
    const { lotId } = this.state.outgoingPosition;
    if (!lotId || lotId?.length === 0) {
      return this.setState({ validId: false });
    }
    try {
      const lot: Lot = await getLot(
        firestore,
        this.props.profile.organisationId,
        lotId
      );
      this.setState({ validId: !lot });
    } catch (error) {
      this.setState({ connectionIssue: true });
    } finally {
      this.setState({ checkingLotId: false });
    }
  };

  warningWrapper(content: any, color: string = 'danger') {
    return (
      <IonItem color={color} className="warning-wrapper">
        <IonIcon icon={warningOutline} />
        <div>{content}</div>
      </IonItem>
    );
  }

  setIncomingBoxes(value: number, lotId: string, totalBoxes: number) {
    const { incomingPositions } = this.state;
    let numBoxes = value ?? 1;
    if (numBoxes <= 0) {
      numBoxes = 1;
    }
    if (numBoxes > totalBoxes) {
      numBoxes = totalBoxes;
    }
    const volumeInKg = new Article(
      incomingPositions.find((p) => p.lotId === lotId)?.article
    ).computeVolumeInKg(numBoxes);
    const newPositions: LotPosition[] = incomingPositions.map((p) => {
      return lotId === p.lotId ? { ...p, numBoxes, volumeInKg } : p;
    });
    this.setState({ incomingPositions: newPositions });
  }

  setIncomingVolume(value: number, lotId: string, totalVolume: number) {
    const { incomingPositions } = this.state;
    let volumeInKg = value ?? 1;
    if (volumeInKg <= 0) {
      volumeInKg = 1;
    }
    if (volumeInKg > totalVolume) {
      volumeInKg = totalVolume;
    }
    const newPositions: LotPosition[] = incomingPositions.map((p) => {
      return lotId === p.lotId ? { ...p, numBoxes: undefined, volumeInKg } : p;
    });
    this.setState({ incomingPositions: newPositions });
  }

  setOutgoingBoxes(value: number, incomingVolume: number) {
    let numBoxes = value ?? 1;
    if (numBoxes <= 0) {
      numBoxes = undefined;
    }
    const articleClass = new Article(this.state.outgoingPosition.article);
    let volumeInKg = articleClass.computeVolumeInKg(numBoxes);
    if (volumeInKg > incomingVolume) {
      volumeInKg = incomingVolume;
      numBoxes = articleClass.computeNumberOfBoxes(volumeInKg);
    }
    this.setState({
      outgoingPosition: { ...this.state.outgoingPosition, numBoxes, volumeInKg },
    });
  }

  setOutgoingVolume(value: number, incomingVolume: number) {
    let volumeInKg = value ?? 1;
    if (volumeInKg <= 0) {
      volumeInKg = undefined;
    }
    if (volumeInKg > incomingVolume) {
      volumeInKg = incomingVolume;
    }
    this.setState({ outgoingPosition: { ...this.state.outgoingPosition, volumeInKg } });
  }

  render() {
    const { motherLots } = this.props;
    const {
      outgoingPosition,
      incomingPositions,
      loading,
      validId,
      reason,
      section,
      checkingLotId,
      connectionIssue,
    } = this.state;
    const { lotId, article } = outgoingPosition;

    const articleClass = new Article(article);

    // let palletProblem = this.checkPallets();

    const packagingOk = articleClass.validatePackaging();
    const quantityOk = articleClass.isRaw()
      ? outgoingPosition.volumeInKg > 0
      : outgoingPosition.numBoxes > 0;

    const saveDisabled =
      !validId || !quantityOk || !packagingOk || checkingLotId || connectionIssue;
    // || !!palletProblem

    const incomingPalletIds = [];
    motherLots.forEach((l) =>
      incomingPalletIds.push(...(l.transient?.palletIds ?? []))
    );

    const sectionsWithIssues: LotTransformationSection[] = [];
    // if (!!palletProblem) {
    //   sectionsWithIssues.push('warehouse');
    // }
    if (!validId || !quantityOk || !packagingOk) {
      sectionsWithIssues.push('to');
    }

    const navigationProps: NavigationProps<LotTransformationSection> = {
      sections: sectionOrder,
      setSection: (section: LotTransformationSection) => this.setState({ section }),
      section,
      dataTipPrefix: 'plt',
      sectionsWithIssues,
    };

    const addNewPackagingOptionText = 'Add New Packaging';
    const rawOptionText = 'Raw';

    const emptyArticlePackagingFields: IArticle = articlePackagingFields.reduce(
      (acc: IArticle, key: string) => ({ ...acc, [key]: undefined }),
      {} as IArticle
    );

    const { unusedVolume, incomingVolume } = this.getUnusedVolume();
    const volumeMatches = unusedVolume === 0;
    const unusedPercentage = parseFloat(
      ((unusedVolume / incomingVolume) * 100).toFixed(1)
    );

    return (
      <div className="ion-page lot-transformation-modal">
        <IonLoading isOpen={loading} />
        <IonHeader>
          <IonToolbar>
            <IonTitle>Batch transformation</IonTitle>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={() => this.props.onCancel()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                fill="solid"
                data-tip={`split-lot-save`}
                onClick={(_) => this.onSave()}
                disabled={saveDisabled}
              >
                <IonIcon icon={readerOutline} slot="start" />
                Save Changes
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="form-container">
            {/* STEP 1 - TRANSFORM*/}
            <IonList className="form-list transform" hidden={section !== 'reason'}>
              <IonItemDivider>Reason</IonItemDivider>
              <IonItem>
                <IonLabel>What is the reason?</IonLabel>
                <IonSelect
                  interface="action-sheet"
                  value={reason}
                  onIonChange={(e) => this.setState({ reason: e.detail.value })}
                >
                  {Object.entries(transformationTypesMap).map(([name, value]) => (
                    <IonSelectOption key={value} value={value}>
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonList>

            {/* STEP 2 - from*/}
            <IonList className="form-list from" hidden={section !== 'from'}>
              <IonItemDivider>From batches</IonItemDivider>

              {incomingPositions.map((p) => {
                const { unit, totalQuantity, quantity, totalBoxes, totalVolume } =
                  this.extractQuantities(p);

                const remaining = !isNaN(totalQuantity - quantity)
                  ? totalQuantity - quantity
                  : totalQuantity;

                const header = `${p.lotId} (total ${unit}: ${totalQuantity} / remaining: ${remaining})`;

                return (
                  <Fragment key={p.lotId}>
                    <IonListHeader color="light-medium">{header}</IonListHeader>
                    {this.renderQuantityFields(
                      p.article,
                      p,
                      (evt) =>
                        this.setIncomingBoxes(+evt.detail.value, p.lotId, totalBoxes),
                      (evt) =>
                        this.setIncomingVolume(+evt.detail.value, p.lotId, totalVolume)
                    )}
                  </Fragment>
                );
              })}
              <IonListHeader color="light-medium">
                <b>{`Total volume of new batch: ${incomingVolume} Kg`}</b>
              </IonListHeader>
            </IonList>

            {/* STEP 3 - to*/}
            <IonList className="form-list to" hidden={section !== 'to'}>
              <IonItemDivider>To batch</IonItemDivider>

              <IonItem>
                <IonLabel>New batch id</IonLabel>
                {checkingLotId && <IonSpinner />}
                <IonInput
                  inputmode="text"
                  disabled={checkingLotId}
                  type="text"
                  placeholder="(enter batch id)"
                  value={lotId}
                  onFocus={(e: any) => {
                    e?.target?.select();
                    this.setState({ validId: true, connectionIssue: false });
                  }}
                  onIonChange={(evt) => this.setLotId(evt.detail.value)}
                  onIonBlur={() => this.checkLotId()}
                />
              </IonItem>

              {!validId &&
                !connectionIssue &&
                this.warningWrapper(
                  lotId == null || lotId.length === 0
                    ? `Batch id cannot be empty`
                    : `Batch id ${lotId} is already in use. Please pick a different one`
                )}
              {connectionIssue &&
                this.warningWrapper(
                  `Could not check if batch id ${lotId} already exists. Please check your internet connection and try again`
                )}

              <IonItemDivider>Quantity</IonItemDivider>

              {this.renderQuantityFields(
                article,
                outgoingPosition,
                (evt) => this.setOutgoingBoxes(+evt.detail.value, incomingVolume),
                (evt) => this.setOutgoingVolume(+evt.detail.value, incomingVolume)
              )}

              {!articleClass.isRaw() &&
                outgoingPosition.numBoxes <= 0 &&
                this.warningWrapper(`New batch cannot have 0 boxes`)}
              {articleClass.isRaw() &&
                outgoingPosition.volumeInKg <= 0 &&
                this.warningWrapper(`New batch must have volume`)}
              {!volumeMatches &&
                !isNaN(unusedVolume) &&
                this.warningWrapper(`Unused volume: ${unusedVolume} Kg`, 'warning')}

              <IonItemDivider>Batch properties</IonItemDivider>

              {!this.state.modalPackaging && (
                <IonItem>
                  <IonLabel>{'Packaging'}</IonLabel>
                  <IonSelect
                    interface="action-sheet"
                    placeholder="select packaging"
                    data-tip={'plt-packaging-selector'}
                    value={
                      articleClass.isRaw()
                        ? rawOptionText
                        : articleClass.getPackagingRepr()
                    }
                    onIonChange={(evt) => {
                      // prevent updates while adding new packaging
                      if (this.state.modalPackaging) {
                        return;
                      }
                      if (evt.detail.value === addNewPackagingOptionText) {
                        this.setState({
                          modalPackaging: true,
                          outgoingPosition: {
                            ...outgoingPosition,
                            article: {
                              ...outgoingPosition.article,
                              ...emptyArticlePackagingFields,
                            },
                          },
                        });
                      } else if (evt.detail.value === rawOptionText) {
                        this.setArticle(
                          {
                            ...article,
                            packagingType: PackagingType.Raw,
                            ...emptyArticlePackagingFields,
                          },
                          'select packaging PLT'
                        );
                      } else {
                        const newArticleClass = new Article({
                          ...article,
                          ...emptyArticlePackagingFields,
                          packagingType: undefined,
                        });
                        newArticleClass.computePackagingRelatedFieldsFromPackagingString(
                          true,
                          evt.detail.value
                        );
                        this.setArticle(
                          newArticleClass.getArticle(),
                          'select packaging PLT'
                        );

                        if (!newArticleClass.isRaw()) {
                          this.setOutgoingBoxes(
                            outgoingPosition.numBoxes,
                            incomingVolume
                          );
                        }
                      }
                    }}
                  >
                    {[
                      addNewPackagingOptionText,
                      rawOptionText,
                      ...(this.findProductInAppContext()?.packaging ?? []).sort(),
                    ].map((packaging) => (
                      <IonSelectOption key={packaging} value={packaging}>
                        {packaging.replace('X', 'x')}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              )}

              {([PackagingType.ConsumerUnitsPieces] as PackagingType[]).includes(
                article?.packagingType
              ) && (
                <IonItem>
                  <IonLabel>Box net weight (Kg)</IonLabel>
                  <IonInput
                    inputmode="decimal"
                    type="number"
                    placeholder="enter box net weight"
                    data-tip={'plt-consumer-unit-pieces-net-weight'}
                    onFocus={(e: any) => e?.target?.select()}
                    value={article?.boxNetWeightInKg}
                    onIonChange={(evt) => {
                      const boxNetWeightInKg = isNaN(parseFloat(evt.detail.value))
                        ? undefined
                        : parseFloat(evt.detail.value);
                      const newArticle = { ...article, boxNetWeightInKg };
                      this.setArticle(
                        {
                          ...newArticle,
                          avgPieceWeightInGrams: new Article(
                            newArticle,
                            true
                          ).computeAvgPieceWeightInGrams(true),
                        },
                        'box net PNL'
                      );
                      this.setOutgoingBoxes(outgoingPosition.numBoxes, incomingVolume);
                    }}
                  />
                </IonItem>
              )}

              {[
                ...this.nonEditableArticleParams,
                ...(article.packagingType !== PackagingType.ConsumerUnitsPieces
                  ? ['boxNetWeightInKg']
                  : []),
              ].map((param) => {
                return (
                  outgoingPosition.article[param] != null && (
                    <IonItem key={param}>
                      <IonLabel color="dark">{articlePropToTitle(param)}</IonLabel>
                      <IonInput value={outgoingPosition.article[param]} disabled />
                    </IonItem>
                  )
                );
              })}
            </IonList>

            {/* STEP 4 - pallets*/}
            {/* COMMENTED OUT FOR NOW */}
            {/* <IonList className="form-list from" hidden={section !== 'warehouse'}>
            <IonItemDivider>Warehouse</IonItemDivider>

            <ctxLocations.Consumer>
              {locations => {
                return <IonItem>
                  <IonLabel color='dark'>Warehouse</IonLabel>
                  <IonSelect
                    interface="action-sheet" value={locationId}
                    onIonChange={e => this.setState({ locationId: e.detail.value })}>
                    {locations.map(l => <IonSelectOption key={l.locationId} value={l.locationId}>{l.name}</IonSelectOption>)}
                  </IonSelect>
                </IonItem>
              }}
            </ctxLocations.Consumer>

            <IonItemDivider>Pallets</IonItemDivider>

            <IonItem>
              <IonGrid className="ion-text-center ion-align-item-center">
                {this.palletRow(['Pallet nr.', 'From', 'To'])}
                {motherLots.map((l, idx) => {
                  const totalBoxes = l.transient?.numBoxes;
                  const pos = incomingPositions.find(p => p.lotId === l.id);
                  const boxesCount = `(${totalBoxes}/${totalBoxes - pos.numBoxes})`;
                  return <Fragment key={l.id}>
                    {this.palletRow(['', l.id, (idx === 0 ? outgoingPosition.lotId : '')])}
                    {l.transient?.palletIds?.length && l.transient.palletIds.map(pId => {
                      return <IonRow key={pId} className="ion-align-items-left">
                        <IonCol size="6" className="ion-text-left">{pId}</IonCol>
                        <IonCol size="3"><IonCheckbox style={{ margin: 'auto' }}
                          checked={pos.palletIds.includes(pId)}
                          onIonChange={e => this.onPalletChange(e, pId, l.id)} />
                        </IonCol>
                        <IonCol size="3"><IonCheckbox style={{ margin: 'auto' }}
                          checked={outgoingPosition.palletIds?.includes(pId)}
                          onIonChange={e => this.onPalletChange(e, pId)} />
                        </IonCol>
                      </IonRow>
                    })}
                  </Fragment>
                })}
              </IonGrid>
            </IonItem>

            <IonItemDivider>Additional pallets</IonItemDivider>
            {addedPalletIds.length > 0 && <IonItem>
              <IonGrid className="ion-text-center ion-align-item-center">
                {addedPalletIds.map(pId => {
                  return <IonRow key={pId} className="ion-align-items-left">
                    <IonCol size="6" className="ion-text-left">
                      <IonLabel className="pallet-label">{pId}
                        <IonButton color="danger" size="small" fill='clear' onClick={e => this.onPalletDelete(e, pId)}>
                          <IonIcon icon={closeCircleOutline} />
                        </IonButton>
                      </IonLabel>
                    </IonCol>
                    <IonCol size="3">{''}</IonCol>
                    <IonCol size="3"><IonCheckbox style={{ margin: 'auto' }} checked disabled /></IonCol>
                  </IonRow>
                })}
              </IonGrid>
            </IonItem>}

            <IonItem>
              <IonLabel>Add pallet</IonLabel>
              <IonInput
                onFocus={(e: any) => e?.target?.select()}
                value={palletIdToAdd} className="add-pallet"
                debounce={300}
                onIonChange={e => this.setState({ palletIdToAdd: e.detail.value })}
              />
              <IonButton slot="end" color="success" onClick={() => this.onPalletAdd()}><IonIcon icon={addOutline} /></IonButton>
            </IonItem>

            {!!palletProblem && this.warningWrapper(palletProblem)}

          </IonList> */}
          </div>

          <CWizardNavigation {...navigationProps} />

          {this.state.modalPackaging && (
            <ViewAddProductPackagingModal
              article={outgoingPosition.article}
              setArticle={this.setArticle.bind(this)}
              agProductId={outgoingPosition.article.agProductId}
              orgId={this.props.organisation?.id}
              packagingArray={
                this.props.products?.find(
                  (p) => p.agProductId === outgoingPosition.article.agProductId
                )?.packaging ?? []
              }
              openModal={this.state.modalPackaging}
              setOpenModal={(value: boolean) => {
                // if (!value && this.state.modalPackaging) this.packagingSelectRef.current.open();
                this.setState({ modalPackaging: value });
              }}
            />
          )}
        </IonContent>
      </div>
    );
  }

  private getUnusedVolume() {
    const { incomingPositions, outgoingPosition } = this.state;
    const incomingVolume = incomingPositions.reduce(
      (acc: number, p: LotPosition) => acc + p.volumeInKg,
      0
    );
    const outgoingVolume = outgoingPosition.volumeInKg;
    const unusedVolume = parseFloat((incomingVolume - outgoingVolume).toFixed(2));
    return { unusedVolume, incomingVolume };
  }

  extractQuantities(p: LotPosition) {
    const lot = this.props.motherLots.find((l) => l.id === p.lotId);
    const totalBoxes = lot.transient?.numBoxes;
    const totalVolume = lot.transient?.volumeInKg;

    const articleClass = new Article(lot.article);

    const totalQuantity = articleClass.isRaw() ? totalVolume : totalBoxes;
    const quantity = articleClass.isRaw() ? p.volumeInKg : p.numBoxes;
    const unit = articleClass.getUnit().toLocaleLowerCase();
    return { unit, totalQuantity, quantity, totalBoxes, totalVolume };
  }

  renderQuantityFields(
    article: IArticle,
    pos: LotPosition,
    setBoxes: (evt: CustomEvent<any>) => void,
    setVolume: (evt: CustomEvent<any>) => void
  ) {
    const articleClass = new Article(article);
    const quantityUnit = articleClass.getUnit().toLocaleLowerCase();
    return !articleClass.isRaw() ? (
      <>
        <IonItem>
          <IonLabel>{`How many ${quantityUnit}?`}</IonLabel>
          <IonInput
            inputmode="numeric"
            type="number"
            value={pos.numBoxes}
            onFocus={(e: any) => e?.target?.select()}
            className="amount-input"
            debounce={100}
            onIonChange={setBoxes}
          />
        </IonItem>
        <IonItem>
          <IonLabel>{`Volume (Kg)`}</IonLabel>
          <IonInput
            inputmode="numeric"
            type="number"
            value={pos.volumeInKg}
            disabled
            className="amount-input"
          />
        </IonItem>
      </>
    ) : (
      <IonItem>
        <IonLabel>{`How many ${quantityUnit}?`}</IonLabel>
        <IonInput
          inputmode="numeric"
          type="number"
          value={pos.volumeInKg}
          onFocus={(e: any) => e?.target?.select()}
          className="amount-input"
          debounce={100}
          onIonChange={setVolume}
        />
      </IonItem>
    );
  }

  setArticle = (article: IArticle, calledFrom: string) => {
    this.setState((prevState) => {
      const newArticle = { ...prevState.outgoingPosition.article, ...article };
      // console.log("SET ARTICLE CALLED FROM", calledFrom, newArticle)
      return {
        outgoingPosition: { ...prevState.outgoingPosition, article: newArticle },
      };
    });
  };

  findProductInAppContext() {
    return this.props.products?.find(
      (p) => this.state.outgoingPosition.article?.agProductId === p.agProductId
    );
  }

  // UNUSED FOR NOW:
  // palletRow([a, b, c]: [string, string, string]) {
  //   return <IonRow>
  //     <IonCol size="6" className="ion-text-left"><b>{a}</b></IonCol>
  //     <IonCol size="3"><b>{b}</b></IonCol>
  //     <IonCol size="3"><b>{c}</b></IonCol>
  //   </IonRow>
  // }

  // onPalletChange(e: CustomEvent<CheckboxChangeEventDetail>, palletId: string, incomingPosId?: string) {
  //   // TODO: optimize this
  //   if (!!incomingPosId) {
  //     const incomingPositions = this.state.incomingPositions.map(p => {
  //       if (incomingPosId === p.lotId) {
  //         return e.detail.checked
  //           ? { ...p, palletIds: [...p.palletIds, palletId] } as LotPosition
  //           : { ...p, palletIds: p.palletIds.filter(id => id !== palletId) } as LotPosition
  //       } else {
  //         return p;
  //       }
  //     });
  //     this.setState({ incomingPositions });
  //   } else {
  //     e.detail.checked
  //       ? this.setState({ outgoingPosition: { ...this.state.outgoingPosition, palletIds: [...this.state.outgoingPosition.palletIds, palletId] } })
  //       : this.setState({ outgoingPosition: { ...this.state.outgoingPosition, palletIds: this.state.outgoingPosition.palletIds.filter(id => id !== palletId) } })
  //   }
  // }

  // getAllPalletIds() {
  //   return [...(this.props.motherLots.flatMap(l => l.transient?.palletIds ?? [])), ...this.state.addedPalletIds];
  // }

  // onPalletAdd() {
  //   const { palletIdToAdd: pId } = this.state;
  //   if (!pId || pId?.length === 0) {
  //     return;
  //   }
  //   if (this.getAllPalletIds().includes(pId)) {
  //     // TODO: display message?
  //     return;
  //   }
  //   this.setState({
  //     addedPalletIds: [...this.state.addedPalletIds, pId],
  //     outgoingPosition: { ...this.state.outgoingPosition, palletIds: [...this.state.outgoingPosition.palletIds, pId] },
  //     palletIdToAdd: ''
  //   })
  // }

  // onPalletDelete(e: React.MouseEvent<any, MouseEvent>, palletId: string) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const filterPallet = (pId: string) => pId !== palletId;
  //   this.setState({
  //     addedPalletIds: this.state.addedPalletIds.filter(filterPallet),
  //     outgoingPosition: { ...this.state.outgoingPosition, palletIds: this.state.outgoingPosition.palletIds.filter(filterPallet) },
  //   })
  // }

  // checkPallets() {
  //   const { outgoingPosition, incomingPositions, locationId } = this.state;
  //   const allPalletIds = this.getAllPalletIds();

  //   if (allPalletIds == null || allPalletIds?.length === 0) {
  //     // if mother positions have no pallets, don't check anything
  //     return undefined;
  //   }

  //   const outgoingPallets = outgoingPosition.palletIds;
  //   const missingPallets = allPalletIds.filter(pId => !outgoingPallets.includes(pId) && !incomingPositions.flatMap(p => p.palletIds).includes(pId));

  //   if (missingPallets.length > 0) {
  //     return `Following pallets are missing: ${missingPallets.join(', ')}`;
  //   }
  //   if ((outgoingPallets?.length === 0 && allPalletIds?.length > 0)) {
  //     return `Child batch must have at least 1 pallet`;
  //   }

  //   for (const pos of incomingPositions) {
  //     const { palletIds } = pos;
  //     const { unit, totalQuantity, quantity } = this.extractQuantities(pos);

  //     const currQuantity = totalQuantity - quantity;

  //     if (currQuantity <= 0 && palletIds?.length > 0) {
  //       return `Batch ${pos.lotId} has no ${unit} left, but it still has ${palletIds.length} pallets`;
  //     }
  //     if (currQuantity > 0 && palletIds?.length === 0) {
  //       return `Batch ${pos.lotId} has no pallets left, but it still has ${currQuantity} ${unit}`;
  //     }
  //     if (!(this.props.motherLots.find(l => pos.lotId === l.id)?.transient?.palletIds ?? [])?.some(p => outgoingPallets.includes(p))) {
  //       return `The new batch does not include any pallet from batch ${pos.lotId}`;
  //     }
  //   }

  //   return undefined;
  // }
}

export default withContext(PageLotTransformation, [
  'profile',
  'products',
  'organisation',
]);
