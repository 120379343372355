import { IonItem, IonLabel, IonList, IonSearchbar } from '@ionic/react';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import './CMapSearch.scss';

interface Props {
  onSelect: (place: google.maps.places.PlaceResult) => void;
}

const MAX_DESCRIPTION_LENGTH: number = 70;

const CMapSearch = ({ onSelect }: Props) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<google.maps.places.PlaceResult[]>([]);

  const handleSearch = useCallback((searchQuery: string) => {
    if (window.google) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      if (searchQuery) {
        autocompleteService.getPlacePredictions(
          { input: searchQuery },
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              setResults(predictions);
            } else {
              setResults([]);
            }
          }
        );
      } else {
        setResults([]);
      }
    }
  }, []);

  const handleSelect = (placeId: string) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    placesService.getDetails(
      { placeId, fields: ['geometry', 'name'] },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          setResults([]);
          setQuery('');
          onSelect(place);
        }
      }
    );
  };

  const renderResult = (r: google.maps.places.PlaceResult): string => {
    const { description } = r as any; // description is not part of the model, weird...
    if (typeof description !== 'string') return '';
    return description.length > MAX_DESCRIPTION_LENGTH
      ? `${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`
      : description;
  };

  return (
    <IonList className={`map-search ${results.length > 0 ? 'translucent' : ''}`}>
      <IonSearchbar
        value={query}
        debounce={300}
        onIonChange={(e) => {
          setQuery(e.detail.value);
          handleSearch(e.detail.value);
        }}
        placeholder="Search location..."
      />
      {results.map((result) => (
        <IonItem
          button
          key={result.place_id}
          onClick={() => handleSelect(result.place_id)}
        >
          <IonLabel>{renderResult(result)}</IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
};

export default CMapSearch;
