import { LotScoring } from './InspectionModel';
import { IQuestionSpec } from './Model';
import {
  CreateFieldSectionRequest,
  CreateLotSectionRequest,
  CreateTransitSectionRequest,
  FieldInspectionSpec as PartialFieldInspectionSpec,
  FieldSchema,
  FieldSchemaObjectTypeEnum,
  FieldSchemaOverview,
  FieldSectionIn,
  FieldSectionOverview,
  InspectableObjectType,
  InspectionType,
  LotInspectionSpec as PartialLotInspectionSpec,
  LotSchema,
  LotSchemaObjectTypeEnum,
  LotSchemaOverview,
  LotSectionIn,
  LotSectionOverview,
  MeasurementUnit,
  SectionType as OAPISectionType,
  PackagingType,
  PictureSectionType,
  TransitInspectionSpec as PartialTransitInspectionSpec,
  TransitSchema,
  TransitSchemaObjectTypeEnum,
  TransitSchemaOverview,
  TransitSectionIn,
  TransitSectionOverview,
  Criteria as LotCriteria,
  FieldCriteria,
} from './generated/openapi/core';

export interface OldCriteria {
  productIds?: string[]; // Future TODO show in UI if company uses product mappings
  agProductIds?: string[];
  packaging?: string[]; // Not actively using this
  packagingTypes?: PackagingType[];
  assessmentTypes?: InspectionType[];
}

// When adding a new value, make sure to extend the SectionNames mapping as well
export enum SectionType {
  ACTION = 'ACTION',
  CONTROLLED_ATMOSPHERE = 'CONTROLLED_ATMOSPHERE',
  GENERAL = 'GENERAL',
  HEADER = 'HEADER',
  ORGANOLEPTICS = 'ORGANOLEPTICS',
  PACKAGING_AND_LABELING = 'PACKAGING_AND_LABELING',
  PICTURES = 'PICTURES',
  QUALITY = 'QUALITY',
  SAMPLING = 'SAMPLING',
  SCORING = 'SCORING',
  SUMMARY = 'SUMMARY',
}

export const PackagingNames: { [key in PackagingType]?: string } = {
  [PackagingType.Bulk]: 'Bulk',
  [PackagingType.ConsumerUnitsWeight]: 'Consumer units (weight)',
  [PackagingType.ConsumerUnitsPieces]: 'Consumer units (pieces)',
  [PackagingType.Raw]: 'Raw',
};

export const SectionTypes: OAPISectionType[] = Object.values(OAPISectionType);
export const InspectionTypes: InspectionType[] = Object.values(InspectionType).filter(
  (t) => t !== InspectionType.Transport
);
export const ConfigurableSectionTypes: OAPISectionType[] = SectionTypes.filter(
  (type) => type !== OAPISectionType.Scoring && type !== OAPISectionType.Pictures
);
export const LotSectionTypes: OAPISectionType[] = ConfigurableSectionTypes.filter(
  (s) => s !== OAPISectionType.ControlledAtmosphere
);
export const TransitSectionTypes: OAPISectionType[] = [
  OAPISectionType.ControlledAtmosphere,
  OAPISectionType.General,
];
export const FieldSectionTypes: OAPISectionType[] = [
  OAPISectionType.Quality,
  OAPISectionType.General,
];
export const InspectableObjectNames: { [objectType in InspectableObjectType]: string } =
  {
    lot: 'Batch',
    transit: 'Transit',
    field: 'Field',
  };
export const objectSpecificSectionTypes = (objectType: InspectableObjectType) => {
  switch (objectType) {
    case InspectableObjectType.Lot:
      return LotSectionTypes;
    case InspectableObjectType.Transit:
      return TransitSectionTypes;
    case InspectableObjectType.Field:
      return FieldSectionTypes;
  }
};

export const SectionNames: { [key in OAPISectionType | PictureSectionType]: string } = {
  header: 'Overview',
  general: 'General',
  sampling: 'Sampling',
  packaging_and_labeling: 'Packaging & labeling',
  quality: 'Quality',
  organoleptics: 'Taste & Smell',
  scoring: 'Scoring',
  summary: 'Summary',
  action: 'Action',
  pictures: 'Pictures',
  inner_pictures: 'Cut fruit pictures',
  controlled_atmosphere: 'Controlled atmosphere',
  packaging_pictures: 'Packaging pictures',
  overview_pictures: 'Overview pictures',
};

const sectionTypesPerAssessmentType: {
  [sectionType in InspectionType]: OAPISectionType[];
} = {
  incoming: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.PackagingAndLabeling,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  stock: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  outgoing: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.PackagingAndLabeling,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  upcoming: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.PackagingAndLabeling,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  shelf_life: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  post_production: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.PackagingAndLabeling,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  post_harvest: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.PackagingAndLabeling,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  pre_harvest: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.Sampling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Action,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
  transport: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.Summary,
    OAPISectionType.ControlledAtmosphere,
    OAPISectionType.Scoring,
  ],
  point_of_sale: [
    OAPISectionType.Header,
    OAPISectionType.General,
    OAPISectionType.PackagingAndLabeling,
    OAPISectionType.Quality,
    OAPISectionType.Organoleptics,
    OAPISectionType.Summary,
    OAPISectionType.Scoring,
  ],
};

const assessmentTypesPerSectionType: {
  [assessmentType in OAPISectionType]: InspectionType[];
} = {
  action: [
    'incoming',
    'stock',
    'upcoming',
    'shelf_life',
    'post_production',
    'post_harvest',
    'pre_harvest',
  ],
  controlled_atmosphere: ['transport'],
  general: InspectionTypes,
  header: InspectionTypes,
  organoleptics: [
    'incoming',
    'stock',
    'outgoing',
    'upcoming',
    'shelf_life',
    'post_production',
    'post_harvest',
    'pre_harvest',
    'point_of_sale',
  ],
  packaging_and_labeling: [
    'incoming',
    'outgoing',
    'upcoming',
    'post_production',
    'post_harvest',
    'point_of_sale',
  ],
  pictures: [],
  quality: [
    'incoming',
    'stock',
    'outgoing',
    'upcoming',
    'shelf_life',
    'post_production',
    'post_harvest',
    'pre_harvest',
    'point_of_sale',
  ],
  sampling: [
    'incoming',
    'stock',
    'outgoing',
    'upcoming',
    'shelf_life',
    'post_production',
    'post_harvest',
    'pre_harvest',
  ],
  scoring: InspectionTypes,
  summary: InspectionTypes,
};

export const getValidSectionTypes = (
  ...inspectionTypes: InspectionType[]
): Set<OAPISectionType> => {
  if (inspectionTypes.length === 0) {
    // return new Set<NewSectionType>(NewSectionTypes);
    return new Set<OAPISectionType>(sectionTypesPerAssessmentType['incoming']);
  }

  if (inspectionTypes.length === 1) {
    return new Set<OAPISectionType>(sectionTypesPerAssessmentType[inspectionTypes[0]]);
  }

  const validSectionTypes = new Set<OAPISectionType>();
  inspectionTypes.forEach((assessmentType) =>
    sectionTypesPerAssessmentType[assessmentType].forEach((t) =>
      validSectionTypes.add(t)
    )
  );
  return validSectionTypes;
};

export const getValidAssessmentTypes = (
  sectionType: OAPISectionType
): InspectionType[] => {
  return assessmentTypesPerSectionType[sectionType];
};

export const InspectionTypeName: { [key in InspectionType]?: string } = {
  incoming: 'Incoming',
  shelf_life: 'Shelf-life',
  stock: 'Stock',
  outgoing: 'Outgoing',
  upcoming: 'Upcoming',
  pre_harvest: 'Pre-harvest',
  point_of_sale: 'Point of Sale',
  post_production: 'Post-production',
  post_harvest: 'Post-harvest',
  transport: 'Transport',
};

export const MeasurementUnitName: { [key in keyof typeof MeasurementUnit]: string } = {
  Boxes: 'Boxes',
  Celsius: 'Celsius',
  DegreeBrix: 'Degree Brix',
  Fahrenheit: 'Fahrenheit',
  Grams: 'Grams',
  Kilos: 'Kilos',
  Pieces: 'Pieces',
  Pounds: 'Pounds',
  Punnets: 'Punnets',
  ProductPercentage: '% Pieces',
  PunnetsPercentage: '% Punnets',
  BoxesPercentage: '% Boxes',
  WeightPercentage: '% Weight',
  DurofelUnit: 'Durofel unit',
  ConcentrationPercentage: '% of Concentration',
  Shore: 'Shore',
  Millimeter: 'Millimeter',
  KilosPerBox: 'Kilos per box',
  Color: 'Color',
  Question: 'Question',
  Score: 'Question Score',
  Days: 'Days',
  Liters: 'Liters',
  PlantsPercentage: '% Plants'
};

export interface Subsection {
  displayedName: string;
  questionIds: string[];
  expandByDefault: boolean;
}

export interface Section {
  id: string;
  name: string;

  // Capping at one subsection for now; using a list to preserve order
  layout: (string | Subsection)[]; // List of questionIds and subsections
  questions: { [questionId in string]: IQuestionSpec };

  creationDate?: number;
  lastModifiedDate?: number;
  lastModifiedUserId?: string;
  derivedFrom?: string;

  type: SectionType;
  criteria: OldCriteria;

  expandByDefault: boolean;
}

export interface LotScoringSection {
  id: string;
  name: string;
  organisationId: string;

  creationDate?: number;
  lastModifiedDate?: number;
  lastModifiedUserId?: string;

  criteria: OldCriteria;

  lotScoring: { [scoreId in string]: LotScoring }; // Old data model
}

export interface ScoringToSection {
  sectionId: string;
  scoringId: string;
}

export type Schema =
  | ({ objectType: LotSchemaObjectTypeEnum } & LotSchema)
  | ({ objectType: TransitSchemaObjectTypeEnum } & TransitSchema)
  | ({ objectType: FieldSchemaObjectTypeEnum } & FieldSchema);
export type SchemaOverview =
  | ({ objectType: LotSchemaObjectTypeEnum } & LotSchemaOverview)
  | ({ objectType: TransitSchemaObjectTypeEnum } & TransitSchemaOverview)
  | ({ objectType: FieldSchemaObjectTypeEnum } & FieldSchemaOverview);
export type SectionOverview =
  | ({ objectType: LotSchemaObjectTypeEnum } & LotSectionOverview)
  | ({ objectType: TransitSchemaObjectTypeEnum } & TransitSectionOverview)
  | ({ objectType: FieldSchemaObjectTypeEnum } & FieldSectionOverview);
export type CreateSectionRequest =
  | ({ objectType: LotSchemaObjectTypeEnum } & CreateLotSectionRequest)
  | ({ objectType: TransitSchemaObjectTypeEnum } & CreateTransitSectionRequest)
  | ({ objectType: FieldSchemaObjectTypeEnum } & CreateFieldSectionRequest);
export type EditSectionRequest =
  | ({ objectType: LotSchemaObjectTypeEnum } & LotSectionIn)
  | ({ objectType: TransitSchemaObjectTypeEnum } & TransitSectionIn)
  | ({ objectType: FieldSchemaObjectTypeEnum } & FieldSectionIn);

export type LotInspectionSpec = { objectType: 'lot' } & PartialLotInspectionSpec;
export type TransitInspectionSpec = {
  objectType: 'transit';
} & PartialTransitInspectionSpec;
export type FieldInspectionSpec = { objectType: 'field' } & PartialFieldInspectionSpec;

export type InspectionSpec =
  | LotInspectionSpec
  | TransitInspectionSpec
  | FieldInspectionSpec;

export type Criteria = LotCriteria | FieldCriteria;

export const inspectionTypesPerObjectType: {
  [key in InspectableObjectType]: InspectionType[];
} = {
  [LotSchemaObjectTypeEnum.Lot]: [
    'incoming',
    'shelf_life',
    'stock',
    'outgoing',
    'upcoming',
    'point_of_sale',
    'post_production',
    'post_harvest',
  ],
  [TransitSchemaObjectTypeEnum.Transit]: ['transport'],
  [FieldSchemaObjectTypeEnum.Field]: ['pre_harvest', 'post_harvest'],
};
