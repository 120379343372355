import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import ComponentLocationsAdmin from './ComponentLocationsAdmin';
import ComponentPartnersAdmin from './ComponentPartnersAdmin';
import ComponentProductsAdmin from './ComponentProductsAdmin';
import ComponentUsersAdmin from './ComponentUsersAdmin';
import withContext, { ContextProps } from './HOC/withContext';
import './PageManagement.scss';
import PageMissingPermissions from './PageMissingPermissions';
import { isAGAdmin, isFreeQCTier, userHasPermission } from './PermissionsService';
import { exitOutline } from 'ionicons/icons';

interface Props {
  onCancel?: Function;
}

interface State {
  inited: true;
  page?: string;
}

class PageManagement extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    const page = this.getInitialPage();

    this.state = {
      inited: true,
      page,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props & ContextProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!_.isEqual(this.props.profile, prevProps.profile)) {
      this.setState({ page: this.getInitialPage() });
    }
  }

  getInitialPage() {
    let page: string;

    if (userHasPermission(this.props.profile, 'WRITE', 'ADMIN')) {
      page = 'users';
    }
    // Commented out for now, as the partners section is not available yet
    // else if (hasPermission(this.props.profile, 'WRITE', 'ADMIN_PARTNERS')) {
    //   page = 'partners';
    // }
    else {
      page = '';
    }

    return page;
  }

  isSelected(page) {
    return this.state.page === page ? 'selected' : '';
  }

  renderPage(isFreeTier: boolean) {
    const { profile } = this.props;
    const organisationId = profile.organisationId;

    switch (this.state.page) {
      case 'users':
        return userHasPermission(this.props.profile, 'WRITE', 'ADMIN') ? (
          <ComponentUsersAdmin
            organisationId={organisationId}
            organisationSettings={this.props.organisation?.settings}
            profile={profile}
          />
        ) : (
          <PageMissingPermissions noHeader />
        );
      case 'partners':
        return !isFreeTier &&
          userHasPermission(this.props.profile, 'WRITE', 'ADMIN_PARTNERS') ? (
          <ComponentPartnersAdmin partners={this.props.contacts} profile={profile} />
        ) : (
          <PageMissingPermissions noHeader />
        );
      case 'products':
        return userHasPermission(this.props.profile, 'WRITE', 'ADMIN') ? (
          <ComponentProductsAdmin
            products={this.props.products ?? []}
            organisationId={organisationId}
          />
        ) : (
          <PageMissingPermissions noHeader />
        );
      case 'locations':
        return !isFreeTier &&
          userHasPermission(this.props.profile, 'WRITE', 'ADMIN') ? (
          <ComponentLocationsAdmin />
        ) : (
          <PageMissingPermissions noHeader />
        );
      default:
        return <></>;
    }
  }

  render() {
    const isFreeTier = isFreeQCTier(this.props.organisation?.settings);
    const title = 'Admin';

    const showSpecBuilder = !this.props.organisation?.settings?.hideSpecBuilder;

    const showScoring =
      !isFreeTier &&
      userHasPermission(this.props.profile, 'WRITE', 'SPECIFICATIONS') &&
      this.props.organisation?.settings?.lotScoringType === 'ADLS';

    // console.log("PAGEEEE: ", this.state)
    return (
      <IonPage className="page-management ion-page">
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonMenuButton slot="start" color="dark" />
            {/* <IonButtons slot="start">
            <IonButton onClick={_ => this.props.onCancel()}>
              <IonIcon icon={closeOutline} color="dark"></IonIcon>
            </IonButton>
          </IonButtons> */}
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="main-container">
            <IonList>
              {/* <IonItem routerLink="/tabs/admin" detail={false} className={this.isSelected('org')} onClick={() => this.setState({page: 'org'})}>
              {/* <IonIcon icon={briefcaseOutline} slot="start" />
              <IonLabel>Organization Settings</IonLabel>
            </IonItem> */}

              {userHasPermission(this.props.profile, 'WRITE', 'ADMIN') && (
                <IonItem
                  routerLink="/tabs/admin"
                  detail={false}
                  className={this.isSelected('users')}
                  onClick={() => this.setState({ page: 'users' })}
                >
                  {/* <IonIcon icon={peopleOutline} slot="start" /> */}
                  <IonLabel>Users</IonLabel>
                </IonItem>
              )}

              {!isFreeTier &&
                userHasPermission(this.props.profile, 'WRITE', 'ADMIN_PARTNERS') && (
                  <IonItem
                    routerLink="/tabs/admin"
                    detail={false}
                    className={this.isSelected('partners')}
                    onClick={() => this.setState({ page: 'partners' })}
                  >
                    {/* <IonIcon icon={peopleCircleOutline} slot="start" /> */}
                    <IonLabel>Partners</IonLabel>
                  </IonItem>
                )}

              {userHasPermission(this.props.profile, 'WRITE', 'ADMIN') && (
                <IonItem
                  routerLink="/tabs/admin"
                  detail={false}
                  className={this.isSelected('products')}
                  onClick={() => this.setState({ page: 'products' })}
                >
                  {/* <IonIcon icon={storefrontOutline} slot="start" /> */}
                  <IonLabel>Products</IonLabel>
                </IonItem>
              )}

              {!isFreeTier &&
                userHasPermission(this.props.profile, 'WRITE', 'ADMIN') && (
                  <IonItem
                    routerLink="/tabs/admin"
                    detail={false}
                    className={this.isSelected('locations')}
                    onClick={() => this.setState({ page: 'locations' })}
                  >
                    {/* <IonIcon icon={storefrontOutline} slot="start" /> */}
                    <IonLabel>Locations</IonLabel>
                  </IonItem>
                )}
              {((!isFreeTier &&
                userHasPermission(this.props.profile, 'WRITE', 'SPECIFICATIONS') &&
                showSpecBuilder) ||
                this.props.profile?.email?.includes('agrinorm')) && (
                <IonRouterLink
                  href={`${process.env.REACT_APP_NEW_PLATFORM_HOST}/specifications`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IonItem
                    button
                    detail={false}
                    className={this.isSelected('spec-builder')}
                    onClick={() => {
                      console.log('clicked');
                    }}
                  >
                    <IonLabel>Specifications</IonLabel>
                    <IonIcon icon={exitOutline} slot="end" />
                  </IonItem>
                </IonRouterLink>
              )}

              {showScoring && (
                <IonItem
                  routerLink="/secure/scoring"
                  detail={false}
                  className={this.isSelected('scoring')}
                  onClick={() => this.setState({ page: 'scoring' })}
                >
                  {/* <IonIcon icon={storefrontOutline} slot="start" /> */}
                  <IonLabel>Scoring</IonLabel>
                </IonItem>
              )}

              {this.props.profile?.email?.includes('agrinorm') && (
                <IonItem
                  routerLink="/secure/question-editor"
                  detail={false}
                  className={this.isSelected('question-editor')}
                  onClick={() => this.setState({ page: 'question-editor' })}
                >
                  <IonLabel>Questions</IonLabel>
                </IonItem>
              )}

              {/* <IonItem routerLink="/tabs/admin" detail className={this.isSelected('advanced')} onClick={() => this.setState({page: 'advanced'})}>
              {/* <IonIcon icon={beerOutline} slot="start" /> 
              <IonLabel>Advanced Settings</IonLabel>
            </IonItem> */}
            </IonList>
            <div>{this.renderPage(isFreeTier)}</div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default React.memo(
  withContext(PageManagement, [
    'profile',
    'organisation',
    'contacts',
    'products',
    'locations',
  ])
);
