import firebase from 'firebase/compat/app';
import { Computable } from './LotScoringLogic';
import {
  AGScore,
  AG_COC_INVALID,
  AG_COC_MISSING,
  AG_GGN_INVALID,
  AG_GGN_MISSING,
  AG_GLN_INVALID,
  AG_GLN_MISSING,
  IArticle,
  PartialEntity,
  Picture,
  ProductionSiteLocation,
  ProductionType,
  SharedTo,
} from './Model';
import { SectionNames } from './ModelSpecification';
import {
  BooleanMeasurementValueTypeEnum,
  CategoricalMeasurementValueTypeEnum,
  FieldSchemaObjectTypeEnum,
  FloatListMeasurementValueTypeEnum,
  FloatMeasurementValueTypeEnum,
  InspectionRelevantProperties,
  InspectionSpecSection,
  InspectionType,
  IntListMeasurementValueTypeEnum,
  IntMeasurementValueTypeEnum,
  LotInspectionSpec,
  LotPropertiesFromInspection,
  LotSchemaObjectTypeEnum,
  MeasurementUnit,
  MeasurementValueTypeEnum,
  QuestionCardProperties,
  QuestionReportProperties,
  QuestionSpec,
  SectionType,
  TransitSchemaObjectTypeEnum,
} from './generated/openapi/core';
import { TransitType } from './generated/openapi/external';

/********* */
// Inspection
/********* */

export type InspectionSource = 'EXTERNAL' | 'INTERNAL';

export type InspectionStatus =
  | 'OPEN'
  | 'COMPLETED'
  | 'APPROVED'
  | 'REJECTED'
  | 'ACCEPTED NOT FULL PRICE'
  | 'ACCEPTED WITH SORTING'
  | 'ACCEPTED UPON RESERVATION'
  | 'SETTLED'
  | 'IN PROGRESS';

export interface BaseInspection extends PartialEntity {
  // We only add the id before writing to the db
  id?: string;

  status: InspectionStatus;

  locationId?: string;

  recordedGeoPoint?: firebase.firestore.GeoPoint;

  source: InspectionSource;

  schemaId?: string;
  schemaVersion?: string;

  pictures?: Picture[];

  userInputs: { [key in string]: QuestionInput };
  scores?: { [key in string]: InspectionScore };

  // Flag to indicate that the inspection was copied from another one
  isCopy?: boolean;

  // this is optional because it doesn't have to be there from the beginning, it's compiled once it's saved in the db
  renderingInfo?: InspectionRenderingInfo;

  // TODO: save this properly in a collection outside of the inspection itself
  inspectionTime?: number;
  reviewedBy?: string;
}

// We are calling "Legacy inspection" to the union between LotInspection and TransitInspection. Replaces the old IInspection
export type LegacyInspection =
  | ({ objectType: LotSchemaObjectTypeEnum } & LotInspection)
  | ({ objectType: TransitSchemaObjectTypeEnum } & TransitInspection);
export type Inspection =
  | LegacyInspection
  | ({ objectType: FieldSchemaObjectTypeEnum } & ProductionSiteInspection);

//-----------------------------------------------
// LOT
export interface LotProperties {
  palletIds?: string[];
  isSplitLot?: boolean;
  ggnList?: string[];
  glnList?: string[];
  cocList?: string[];
  article: IArticle;
}

export type InspectionReference =
  | LegacyInspectionReference
  | ProductionSiteInspectionReference;

// For now shared between Lot and Transit inspections
export interface LegacyInspectionReference {
  type: InspectionType;
  orderId?: string;
  lotId?: string;
  transportId?: string;
  date: string;
  growerId?: string;
}

export interface LotInspection extends BaseInspection {
  barcodes?: any[];
  lotProperties?: LotProperties;
  reference: LegacyInspectionReference;
  // Flag to indicate it's an inspection for a supply chain entity (not owned by the org)
  isSupplyChain?: boolean;
  objectType: LotSchemaObjectTypeEnum;
}

//-----------------------------------------------
// TRANSIT
export interface TransitProperties {
  transportType?: TransitType;
  isRefrigerated?: boolean;
  isCA?: boolean;
  // TODO: complete the list
}

export interface TransitInspection extends BaseInspection {
  transitProperties: TransitProperties;
  objectType: TransitSchemaObjectTypeEnum;
  reference: LegacyInspectionReference;
}

//-----------------------------------------------
// PRODUCTION SITE
export interface ProductionSiteInspectionReference {
  productionType: ProductionType;
  type: InspectionType;
  locationId: string;
  date: string;
}

export interface ProductionSiteInspection extends BaseInspection {
  objectType: FieldSchemaObjectTypeEnum;
  reference: ProductionSiteInspectionReference;
  // A snapshot of the location at the moment of the inspection
  productionSiteSnapshot?: ProductionSiteLocation;
}

export interface PreviewQuestionSpec {
  displayedName: string;
  value: string;
  agScore: AGScore;
}
export interface ProdSiteInspectionPreview {
  pictures: Picture[];
  scores: { [key in string]: InspectionScore };
  renderedQuestions: PreviewQuestionSpec[];
  lastModifiedDate: Date;
  status: InspectionStatus;
}

export interface SharedFieldReport extends ProductionSiteLocation {
  id: string;  
  orgId: string;
  inspections: ProductionSiteInspection[];
  sharedTo: SharedTo;
  message: string;
  associatedPartnerName?: string;
  emailSent?: boolean;
}

//--------------------------------------
// InspectionView
export interface QuestionInput {
  agScore?: AGScore;
  groupId?: string;
  measurableInputs: MeasurableInput[];
}

export interface InspectionRenderingInfo {
  questionSpecs: { [questionId: string]: QuestionViewSpec };
  layout: InspectionSpecSection[];
  externalProperties: { [propId in InspectionRelevantProperties]?: any };
}

// Subset of the QuestionSpec that's relevant for the rendering of the question within an inspection view
export interface QuestionViewSpec {
  reportProperties: QuestionReportProperties;
  cardProperties: QuestionCardProperties;
  displayedName: string;
}

export interface InspectionScore {
  id: string;
  name: string;

  score: string;
  agScore?: AGScore;

  manuallyOverriden?: boolean;
}

export interface InspectionError {
  type: 'out_of_range' | 'missing_mandatory' | 'non_present_tagged_defects';
  questionId: string;
}

export type MeasurableId = string;
// The Typescript types for a measurement value or AGScore.
export type MeasurementValueType = boolean | number | string;

// This is a dict of dicts. The first key is the measurableId, the second key is the tagId.
export type PartialSummaryResolutionCanditates = {
  [partialSummaryMeasurableId: string]: { [tagId: string]: MeasurableId[] };
};
export type PartialSummaryResolutions = {
  [partialSummaryMeasurableId: string]: { [tagId: string]: MeasurementValueType[] };
};

export interface InspectionContext extends Record<string, Record<string, any>> {
  // measureables: { [measureableId: string]: number | number[] };
  // auxMeasurements: { [auxMeasId: string]: number | number[] };
  // scores: { [scoreId: string]: any };
  inputs: {
    [contextRefId: string]: MeasurementValueType | MeasurementValueType[] | undefined;
  }; // TODO: find a better name than "inputs"? Should be a generic term for measurables, auxMeas and scores
  properties: { [propId in InspectionRelevantProperties]?: any }; // TODO: the key should be an enum defined in core
  formulas: { [measurebleId: MeasurableId]: string };
  resolvedFormulas: { [measurebleId: MeasurableId]: string };
  // List of primary measurables for the questions with each tag.
  partialSummaryResolutionCandidates: PartialSummaryResolutionCanditates;
  resolvedTags?: PartialSummaryResolutions;
}

export type InspectionPropertiesMap = {
  [propId in InspectionRelevantProperties]?: any;
};

export interface UserInputLocator {
  questionId: string;
  // measurableId could be undefined for question level scores.
  measurableId?: string;
  auxMeasurementIndex?: number;
  // For measurables with a scoring policy defined, we also create an input with
  // 'qscore' prefix.
  isQuestionScore?: boolean;
  // isScore refers to whether the input is in InspectionLevelScoring.
  isScore?: boolean;
}

/********* */
// Measurement
/********* */

export type ValueType =
  | FloatMeasurementValueTypeEnum
  | FloatListMeasurementValueTypeEnum
  | IntMeasurementValueTypeEnum
  | IntListMeasurementValueTypeEnum
  | CategoricalMeasurementValueTypeEnum
  | MeasurementValueTypeEnum
  | BooleanMeasurementValueTypeEnum;
// export type MeasurementUnion = BooleanMeasurement | FloatMeasurement | IntMeasurement | CategoricalMeasurement | Measurement | IntListMeasurement | FloatListMeasurement;

export interface MeasurementInput {
  valueType: ValueType;
  value: any;
  unit?: MeasurementUnit;
  displayedName?: string;
}

/********* */
// Measureable
/********* */

export interface MeasurableInput {
  measurableId: string;
  isPrimary: boolean;
  measurementInput?: MeasurementInput;
  auxMeasurementsInput?: MeasurementInput[];
  verifiableSource?: number;
}

export interface PropertyToBeSet {
  setProperty: LotPropertiesFromInspection | 'barcodes';
  value?: any;
}

export type ContextReferencePrefix = 'meas' | 'aux' | 'extern' | 'score' | 'qscore';

/********* 
// Scoring
**********/
export type QuestionSeverity = 'critical' | 'major' | 'minor';

export type LotScoringType = 'MANUAL' | 'ADLS' | 'ADP';

export interface LotScoring {
  type: LotScoringType;
  params: ADLSScoringParams | ADPScoringParams;
  name: string;
}

export interface ADLSScoringParams {
  rankedScoreSpace: any[];
  groupScoreMap?: { [defectsScore: string]: any };

  // The interface must specify a default score
  defaultScore: any;
}

export interface ADPScoringParams {
  // ranked from worst to best
  rankedCategoriesSpace: string[];

  allowManualOverride?: boolean;

  // e.g. "critical" and "major"
  questionGroups: string[];

  categoriesToScores?: { [category: string]: Computable };

  // The interface must specify a default score
  defaultScore: any;
}

// TEMPLATES

export const getOCRIssueInput = (questionId: string) => {
  let measurableId = questionId;
  // switch (questionId) {
  //   default:
  //     measurableId = 'caca';
  //     break;
  // }

  const input: QuestionInput = {
    measurableInputs: [
      {
        isPrimary: true,
        measurableId,
        measurementInput: {
          valueType: 'boolean',
          value: true,
        },
      },
    ],
    agScore: 2,
  };

  return input;
};

export const emptyPackagingSection: InspectionSpecSection = {
  name: SectionNames[SectionType.PackagingAndLabeling],
  type: 'default',
  imageTag: null,
  layout: [],
  sectionType: SectionType.PackagingAndLabeling,
  expandByDefault: true,
  taggable: true,
};

// TODO: do this better
export const ocrQuestionIds: string[] = [
  AG_GGN_INVALID,
  AG_GGN_MISSING,
  AG_GLN_INVALID,
  AG_GLN_MISSING,
  AG_COC_INVALID,
  AG_COC_MISSING,
];
export const ocrQuestionNames: string[] = [
  'GGN Invalid',
  'GGN Missing',
  'GLN Invalid',
  'GLN Missing',
  'CoC Invalid',
  'CoC missing',
];

const getOCRDisplayedName = (qId: string) =>
  ocrQuestionNames[ocrQuestionIds.findIndex((i) => i === qId)];

export const getOCRQuestionSpec = (questionId: string): QuestionSpec => {
  const measurableId: string = questionId;
  const displayedName: string = getOCRDisplayedName(questionId);

  return {
    questionId,
    isScore: false,
    displayedName,
    inspectionProperties: {
      inclusionCriteria: null,
      isMandatory: false,
      taggable: true,
      hint: null,
    },
    reportProperties: null,
    cardProperties: { hideInCard: false, cardName: null },
    id: undefined,
    scoringPolicy: {
      measurableIds: [measurableId],
      rules: [
        {
          slice: [
            {
              measurableId,
              type: 'boolean',
              booleanVal: false,
            },
          ],
          score: 4,
        },
        {
          slice: [
            {
              measurableId,
              type: 'boolean',
              booleanVal: true,
            },
          ],
          score: 2,
        },
      ],
    },
    measurables: [
      {
        isPrimary: true,
        id: undefined,
        measurableId,
        setProperty: null,
        inclusionCriteria: null,
        type: 'default',
        measurement: {
          type: 'boolean',
          displayedName: null,
          valueType: 'boolean',
          defaultValue: null,
        },
      },
    ],
  };
};

const sp = {
  measurableIds: ['bleeding:product_percentage'],
  rules: [
    {
      slice: [
        {
          measurableId: 'bleeding:product_percentage',
          type: 'float',
          min: 0,
          max: 5,
        },
      ],
      score: 4,
    },
    {
      slice: [
        {
          measurableId: 'bleeding:product_percentage',
          type: 'float',
          min: 5,
          max: 10,
        },
      ],
      score: 3,
    },
    {
      slice: [
        {
          measurableId: 'bleeding:product_percentage',
          type: 'float',
          min: 10,
          max: 15,
        },
      ],
      score: 2,
    },
    {
      slice: [
        {
          measurableId: 'bleeding:product_percentage',
          type: 'float',
          min: 15,
          max: 100,
        },
      ],
      score: 1,
    },
  ],
};

export const bwSampleSchema: LotInspectionSpec = {
  creationDate: '2023-02-20T12:11:56+00:00',
  lastModifiedDate: '2023-02-20T12:11:56+00:00',
  lastModifiedUserId: 'yo',
  id: '1234',
  name: 'Incoming Blueberries',
  orgId: 'berryworld.com',
  version: 7,
  criteria: {
    articleProperties: {
      agProductIds: ['Blueberries'],
      packaging: [],
      packagingTypes: [],
      isBio: false,
      origins: null,
      inspectionTypes: ['incoming'],
    },
    mutuallyExclusiveQuestionIds: [],
  },
  layout: [
    {
      name: 'Overview',
      type: 'default',
      imageTag: null,
      layout: [
        'ag_boxes_shipped',
        'ag_boxes_shipped_mismatch',
        'ag_boxes_at_inspection',
        'comment',
      ],
      sectionType: 'header',
      expandByDefault: true,
      taggable: false,
    },
    {
      name: 'Packaging pictures',
      type: 'picture',
      imageTag: 'ag_pictures:packaging',
      layout: [],
      sectionType: 'packaging_pictures',
      expandByDefault: false,
      taggable: false,
    },
    {
      name: 'Labels & packaging',
      type: 'default',
      imageTag: null,
      layout: [
        {
          name: 'Packaging',
          expandByDefault: true,
          questionIds: [
            'packaging_damaged_lids',
            'packaging_damaged_punnets',
            'packaging_broken_boxes',
            'packaging_non_compliant_boxes',
            'packaging_non_compliant_punnets',
            'label_box_wrong',
            'label_punnet_wrong',
            'pallets_bad_condition',
          ],
        },
        {
          name: 'Admin',
          expandByDefault: true,
          questionIds: [
            'info_missing_documents',
            'wrong_pallets',
            'wrong_grower',
            'wrong_variety',
            'weight',
            'packaging_wrong_box',
            'packaging_wrong_punnet',
            'packaging_wrong_lid',
            'packaging_non_compliant',
            'packaging_mixed_punnets',
            'info_incorrect_arrival_date',
            'info_incorrect_delivery_address',
          ],
        },
      ],
      sectionType: 'packaging_and_labeling',
      expandByDefault: true,
      taggable: true,
    },
    {
      name: 'Sampling',
      type: 'default',
      imageTag: null,
      layout: [
        'number_boxes_checked',
        'box_weight',
        'number_punnets_checked',
        'average_piece_weight',
        'num_pieces_checked',
      ],
      sectionType: 'sampling',
      expandByDefault: true,
      taggable: false,
    },
    {
      name: 'Overview pictures',
      type: 'picture',
      imageTag: 'ag_pictures:overview',
      layout: [],
      sectionType: 'overview_pictures',
      expandByDefault: false,
      taggable: true,
    },
    {
      name: 'Cut product pictures',
      type: 'picture',
      imageTag: 'ag_pictures:inner',
      layout: [],
      sectionType: 'inner_pictures',
      expandByDefault: false,
      taggable: true,
    },
    {
      name: 'Quality',
      type: 'default',
      imageTag: null,
      layout: [
        {
          name: 'Critical',
          expandByDefault: true,
          questionIds: [
            'anthracnose',
            'bleeding',
            'collapsed',
            'contamination',
            'quality_debris',
            'quality_residue',
            'quality_foreign_body',
            'decay_mould',
            'storage_mould',
            'wet_bruising',
          ],
        },
        {
          name: 'Major',
          expandByDefault: true,
          questionIds: [
            'birds_damage',
            'bladdery',
            'chill_damage',
            'flower_sepal_remains-major',
            'hail_damage',
            'immature-major',
            'picking_damage-major',
            'red_berry-major',
            'dehydration-major',
            'soft',
            'wind_damage',
            'stem-major',
          ],
        },
        {
          name: 'Minor',
          expandByDefault: true,
          questionIds: [
            'flower_sepal_remains-minor',
            'immature-minor',
            'insects_damage',
            'picking_damage-minor',
            'red_berry-minor',
            'dehydration-minor',
            'stem-minor',
          ],
        },
      ],
      sectionType: 'quality',
      expandByDefault: true,
      taggable: true,
    },
    {
      name: 'Organoleptics',
      type: 'default',
      imageTag: null,
      layout: ['brix'],
      sectionType: 'organoleptics',
      expandByDefault: true,
      taggable: false,
    },
    {
      name: 'Summary',
      type: 'default',
      imageTag: null,
      layout: ['waste_score', 'sorting_score'],
      sectionType: 'summary',
      expandByDefault: true,
      taggable: false,
    },
  ],
  questionSpecs: {
    'number_boxes_checked': {
      questionId: 'number_boxes_checked',
      isScore: false,
      displayedName: 'Boxes checked',
      inspectionProperties: {
        inclusionCriteria: {
          articleProperties: {
            agProductIds: [],
            packaging: [],
            packagingTypes: [],
            isBio: false,
            origins: null,
            inspectionTypes: [],
          },
          mutuallyExclusiveQuestionIds: [],
        },
        isMandatory: true,
        taggable: false,
        hint: null,
      },
      reportProperties: {
        hideInReport: false,
        hideInExternalReport: false,
      },
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'number_boxes_checked:boxes',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'int',
            displayedName: null,
            valueType: 'int',
            unit: 'boxes',
            minValue: 0,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
        },
      ],
    },
    'box_weight': {
      questionId: 'box_weight',
      isScore: false,
      displayedName: 'Box (net) weight (kg)',
      inspectionProperties: {
        inclusionCriteria: {
          articleProperties: {
            agProductIds: [],
            packaging: [],
            packagingTypes: [],
            isBio: false,
            origins: null,
            inspectionTypes: [],
          },
          mutuallyExclusiveQuestionIds: [],
        },
        isMandatory: true,
        taggable: false,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'box_weight:kilos',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'float',
            displayedName: null,
            valueType: 'float',
            unit: 'kilos',
            minValue: 0.0,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
        },
      ],
    },
    'number_punnets_checked': {
      questionId: 'number_punnets_checked',
      isScore: false,
      displayedName: 'Punnets checked',
      inspectionProperties: {
        inclusionCriteria: {
          articleProperties: {
            agProductIds: [],
            packaging: [],
            packagingTypes: [],
            isBio: false,
            origins: null,
            inspectionTypes: [],
          },
          mutuallyExclusiveQuestionIds: [],
        },
        isMandatory: true,
        taggable: false,
        hint: null,
      },
      reportProperties: {
        hideInReport: false,
        hideInExternalReport: false,
      },
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'number_punnets_checked:punnets',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'int',
            displayedName: null,
            valueType: 'int',
            unit: 'punnets',
            minValue: 0,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
        },
      ],
    },
    'average_piece_weight': {
      questionId: 'average_piece_weight',
      isScore: false,
      displayedName: 'Avg. pc. weight',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: false,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'average_piece_weight:grams',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: null,
            valueType: 'float',
            unit: 'grams',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|average_piece_weight:grams;1}/{aux|average_piece_weight:grams;0}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: 'Pieces in the sample',
              valueType: 'int',
              unit: 'pieces',
              minValue: 10,
              maxValue: 10,
              granularity: 0,
              defaultValue: 10,
            },
            {
              type: 'float',
              displayedName: 'Total weight',
              valueType: 'float',
              unit: 'grams',
              minValue: 0.0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'num_pieces_checked': {
      questionId: 'num_pieces_checked',
      isScore: false,
      displayedName: 'Number of pcs in sample:',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: false,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'num_pieces_checked:pieces',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: null,
            valueType: 'float',
            unit: 'pieces',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '({meas|number_boxes_checked:boxes} * {meas|box_weight:kilos}*1000) / {meas|average_piece_weight:grams}',
          auxMeasurements: null,
        },
      ],
    },
    'brix': {
      questionId: 'brix',
      isScore: false,
      displayedName: 'Brix readings',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: false,
        hint: 'Enter values separated by a comma (example: 2.2,5,6.7,3.5)',
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'brix:degree_brix:avg',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Avg',
            valueType: 'float',
            unit: 'degree_brix',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula: 'mean({aux|brix:degree_brix:avg;0})',
          auxMeasurements: [
            {
              type: 'float_list',
              displayedName: null,
              valueType: 'float_list',
              unit: 'degree_brix',
              minValue: null,
              maxValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'brix:degree_brix:std',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'STD',
            valueType: 'float',
            unit: 'degree_brix',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula: 'std({aux|brix:degree_brix:avg;0})',
          auxMeasurements: null,
        },
      ],
    },
    'waste_score': {
      questionId: 'waste_score',
      isScore: true,
      displayedName: 'Waste code',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: false,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'waste_score',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'category',
            displayedName: null,
            valueType: 'category',
            unit: null,
            choices: ['BLUE', 'GREEN', 'AMBER', 'RED', 'GREY', 'REJECT'],
            defaultValue: null,
          },
        },
      ],
    },
    'sorting_score': {
      questionId: 'sorting_score',
      isScore: true,
      displayedName: 'Sort code',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: false,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'sorting_score',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'category',
            displayedName: null,
            valueType: 'category',
            unit: null,
            choices: ['0', '1', '2', '3', '4', '5', 'REJECT'],
            defaultValue: null,
          },
        },
      ],
    },
    'ag_boxes_shipped': {
      questionId: 'ag_boxes_shipped',
      isScore: false,
      displayedName: 'Boxes shipped',
      inspectionProperties: {
        inclusionCriteria: {
          articleProperties: {
            agProductIds: [],
            packaging: [],
            packagingTypes: [],
            isBio: false,
            origins: null,
            inspectionTypes: [],
          },
          mutuallyExclusiveQuestionIds: [],
        },
        isMandatory: true,
        taggable: false,
        hint: 'Indicate the number of boxes shipped from your supplier',
      },
      reportProperties: null,
      cardProperties: { hideInCard: true, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'ag_boxes_shipped:boxes',
          setProperty: null,
          inclusionCriteria: null,
          type: 'verified',
          measurement: {
            type: 'int',
            displayedName: null,
            valueType: 'int',
            unit: 'boxes',
            minValue: 0,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          source: '{extern|boxes_expected}',
        },
      ],
    },
    'ag_boxes_shipped_mismatch': {
      questionId: 'ag_boxes_shipped_mismatch',
      isScore: false,
      displayedName: 'Shipped boxes mismatch',
      inspectionProperties: {
        inclusionCriteria: {
          articleProperties: {
            agProductIds: [],
            packaging: [],
            packagingTypes: [],
            isBio: false,
            origins: null,
            inspectionTypes: [],
          },
          mutuallyExclusiveQuestionIds: [],
        },
        isMandatory: false,
        taggable: false,
        hint: 'The question will be automatically highlighted as an issue in case the number of boxes shipped is different than the expected number of boxes, which is pre-filled based on the order.',
      },
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['ag_boxes_shipped_mismatch'],
        rules: [
          {
            slice: [
              {
                measurableId: 'ag_boxes_shipped_mismatch',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'ag_boxes_shipped_mismatch',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'ag_boxes_shipped_mismatch',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
          formula: '{meas|ag_boxes_shipped:boxes} != {extern|boxes_expected}',
          auxMeasurements: null,
        },
      ],
    },
    'ag_boxes_at_inspection': {
      questionId: 'ag_boxes_at_inspection',
      isScore: false,
      displayedName: 'Boxes at inspection',
      inspectionProperties: {
        inclusionCriteria: {
          articleProperties: {
            agProductIds: [],
            packaging: [],
            packagingTypes: [],
            isBio: false,
            origins: null,
            inspectionTypes: [],
          },
          mutuallyExclusiveQuestionIds: ['ag_boxes_shipped'],
        },
        isMandatory: true,
        taggable: false,
        hint: 'Indicate the current number of boxes in this batch. For your convenience, the last known number of boxes is pre-filled.',
      },
      reportProperties: {
        hideInReport: false,
        hideInExternalReport: true,
      },
      cardProperties: { hideInCard: true, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'ag_boxes_at_inspection:boxes',
          setProperty: null,
          inclusionCriteria: null,
          type: 'verified',
          measurement: {
            type: 'int',
            displayedName: null,
            valueType: 'int',
            unit: 'boxes',
            minValue: 0,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          source: '{extern|current_num_boxes}',
        },
      ],
    },
    'comment': {
      questionId: 'comment',
      isScore: false,
      displayedName: 'Comments',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: { hideInCard: true, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'comment',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'text',
            displayedName: null,
            valueType: 'text',
            defaultValue: null,
          },
        },
      ],
    },
    'anthracnose': {
      questionId: 'anthracnose',
      isScore: false,
      displayedName: 'Anthracnose',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'anthracnose:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'anthracnose:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|anthracnose:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'anthracnose:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|anthracnose:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'anthracnose:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|anthracnose:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'bleeding': {
      questionId: 'bleeding',
      isScore: false,
      displayedName: 'Bleeding',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'bleeding:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'bleeding:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|bleeding:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'bleeding:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|bleeding:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'bleeding:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|bleeding:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'collapsed': {
      questionId: 'collapsed',
      isScore: false,
      displayedName: 'Collapsing',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'collapsed:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'collapsed:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|collapsed:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'collapsed:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|collapsed:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'collapsed:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|collapsed:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'contamination': {
      questionId: 'contamination',
      isScore: false,
      displayedName: 'Contamination',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'contamination:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'contamination:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|contamination:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'contamination:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|contamination:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'contamination:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|contamination:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'quality_debris': {
      questionId: 'quality_debris',
      isScore: false,
      displayedName: 'Debris',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'quality_debris:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'quality_debris:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_debris:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'quality_debris:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_debris:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'quality_debris:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_debris:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'quality_residue': {
      questionId: 'quality_residue',
      isScore: false,
      displayedName: 'Residue Exceedance',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'quality_residue:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'quality_residue:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_residue:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'quality_residue:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_residue:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'quality_residue:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_residue:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'quality_foreign_body': {
      questionId: 'quality_foreign_body',
      isScore: false,
      displayedName: 'Foreign Body',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'quality_foreign_body:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'quality_foreign_body:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_foreign_body:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'quality_foreign_body:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_foreign_body:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'quality_foreign_body:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|quality_foreign_body:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'decay_mould': {
      questionId: 'decay_mould',
      isScore: false,
      displayedName: 'Mould',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'decay_mould:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'decay_mould:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|decay_mould:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'decay_mould:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|decay_mould:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'decay_mould:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|decay_mould:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'storage_mould': {
      questionId: 'storage_mould',
      isScore: false,
      displayedName: 'Storage mould',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'storage_mould:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'storage_mould:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|storage_mould:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'storage_mould:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|storage_mould:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'storage_mould:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|storage_mould:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'wet_bruising': {
      questionId: 'wet_bruising',
      isScore: false,
      displayedName: 'Wet splits',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'critical',
          measurableId: 'wet_bruising:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'wet_bruising:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|wet_bruising:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'wet_bruising:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|wet_bruising:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'wet_bruising:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|wet_bruising:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'birds_damage': {
      questionId: 'birds_damage',
      isScore: false,
      displayedName: 'Birds damage',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'birds_damage:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'birds_damage:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|birds_damage:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'birds_damage:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|birds_damage:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'birds_damage:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|birds_damage:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'bladdery': {
      questionId: 'bladdery',
      isScore: false,
      displayedName: 'Bladdery',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'bladdery:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'bladdery:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|bladdery:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'bladdery:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|bladdery:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'bladdery:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|bladdery:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'chill_damage': {
      questionId: 'chill_damage',
      isScore: false,
      displayedName: 'Chill damage',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'chill_damage:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'chill_damage:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|chill_damage:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'chill_damage:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|chill_damage:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'chill_damage:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|chill_damage:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'flower_sepal_remains-major': {
      questionId: 'flower_sepal_remains-major',
      isScore: false,
      displayedName: 'Flowers attached',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'flower_sepal_remains-major:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'flower_sepal_remains-major:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|flower_sepal_remains-major:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'flower_sepal_remains-major:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|flower_sepal_remains-major:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'flower_sepal_remains-major:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|flower_sepal_remains-major:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'hail_damage': {
      questionId: 'hail_damage',
      isScore: false,
      displayedName: 'Hail damage',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'hail_damage:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'hail_damage:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|hail_damage:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'hail_damage:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|hail_damage:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'hail_damage:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|hail_damage:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'immature-major': {
      questionId: 'immature-major',
      isScore: false,
      displayedName: 'Immature',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'immature-major:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'immature-major:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|immature-major:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'immature-major:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|immature-major:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'immature-major:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|immature-major:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'picking_damage-major': {
      questionId: 'picking_damage-major',
      isScore: false,
      displayedName: 'Picking scars',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'picking_damage-major:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'picking_damage-major:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|picking_damage-major:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'picking_damage-major:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|picking_damage-major:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'picking_damage-major:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|picking_damage-major:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'red_berry-major': {
      questionId: 'red_berry-major',
      isScore: false,
      displayedName: 'Red berry',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'red_berry-major:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'red_berry-major:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|red_berry-major:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'red_berry-major:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|red_berry-major:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'red_berry-major:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|red_berry-major:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'dehydration-major': {
      questionId: 'dehydration-major',
      isScore: false,
      displayedName: 'Shrivel',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'dehydration-major:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'dehydration-major:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|dehydration-major:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'dehydration-major:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|dehydration-major:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'dehydration-major:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|dehydration-major:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'soft': {
      questionId: 'soft',
      isScore: false,
      displayedName: 'Soft',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'soft:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'soft:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|soft:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'soft:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|soft:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'soft:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|soft:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'wind_damage': {
      questionId: 'wind_damage',
      isScore: false,
      displayedName: 'Windrub',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'wind_damage:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'wind_damage:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|wind_damage:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'wind_damage:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|wind_damage:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'wind_damage:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|wind_damage:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'stem-major': {
      questionId: 'stem-major',
      isScore: false,
      displayedName: 'Stems',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: {
        waste_score: {
          scoreGroupId: 'major',
          measurableId: 'stem-major:product_percentage',
        },
      },
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'stem-major:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|stem-major:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'stem-major:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|stem-major:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'stem-major:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|stem-major:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'flower_sepal_remains-minor': {
      questionId: 'flower_sepal_remains-minor',
      isScore: false,
      displayedName: 'Flowers attached',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'flower_sepal_remains-minor:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|flower_sepal_remains-minor:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'flower_sepal_remains-minor:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|flower_sepal_remains-minor:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'flower_sepal_remains-minor:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|flower_sepal_remains-minor:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'immature-minor': {
      questionId: 'immature-minor',
      isScore: false,
      displayedName: 'Immature',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'immature-minor:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|immature-minor:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'immature-minor:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|immature-minor:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'immature-minor:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|immature-minor:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'insects_damage': {
      questionId: 'insects_damage',
      isScore: false,
      displayedName: 'Pest damage',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'insects_damage:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|insects_damage:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'insects_damage:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|insects_damage:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'insects_damage:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|insects_damage:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'picking_damage-minor': {
      questionId: 'picking_damage-minor',
      isScore: false,
      displayedName: 'Picking scars',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'picking_damage-minor:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|picking_damage-minor:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'picking_damage-minor:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|picking_damage-minor:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'picking_damage-minor:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|picking_damage-minor:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'red_berry-minor': {
      questionId: 'red_berry-minor',
      isScore: false,
      displayedName: 'Red berry',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'red_berry-minor:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|red_berry-minor:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'red_berry-minor:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|red_berry-minor:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'red_berry-minor:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|red_berry-minor:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'dehydration-minor': {
      questionId: 'dehydration-minor',
      isScore: false,
      displayedName: 'Shrivel',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'dehydration-minor:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|dehydration-minor:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'dehydration-minor:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|dehydration-minor:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'dehydration-minor:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|dehydration-minor:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'stem-minor': {
      questionId: 'stem-minor',
      isScore: false,
      displayedName: 'Stems',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'stem-minor:product_percentage',
          setProperty: null,
          inclusionCriteria: null,
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Berries %',
            valueType: 'float',
            unit: 'product_percentage',
            minValue: null,
            maxValue: null,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|stem-minor:product_percentage;0}/{meas|num_pieces_checked:pieces}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'pieces',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'stem-minor:punnets_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['consumer_units_pieces', 'consumer_units_weight'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Punnets %',
            valueType: 'float',
            unit: 'punnets_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|stem-minor:punnets_percentage;0}/{meas|number_punnets_checked:punnets}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'punnets',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
        {
          id: '123',
          measurableId: 'stem-minor:boxes_percentage',
          setProperty: null,
          inclusionCriteria: {
            articleProperties: {
              agProductIds: [],
              packaging: [],
              packagingTypes: ['bulk'],
              isBio: false,
              origins: null,
              inspectionTypes: [],
            },
            mutuallyExclusiveQuestionIds: [],
          },
          type: 'computed',
          measurement: {
            type: 'float',
            displayedName: 'Boxes %',
            valueType: 'float',
            unit: 'boxes_percentage',
            minValue: 0.0,
            maxValue: 100.0,
            granularity: null,
            defaultValue: null,
          },
          formula:
            '100*{aux|stem-minor:boxes_percentage;0}/{meas|number_boxes_checked:boxes}',
          auxMeasurements: [
            {
              type: 'int',
              displayedName: null,
              valueType: 'int',
              unit: 'boxes',
              minValue: 0,
              maxValue: null,
              granularity: null,
              defaultValue: null,
            },
          ],
        },
      ],
    },
    'packaging_damaged_lids': {
      questionId: 'packaging_damaged_lids',
      isScore: false,
      displayedName: 'Damaged lids',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_damaged_lids',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_damaged_punnets': {
      questionId: 'packaging_damaged_punnets',
      isScore: false,
      displayedName: ' Damaged punnets',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_damaged_punnets',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_broken_boxes': {
      questionId: 'packaging_broken_boxes',
      isScore: false,
      displayedName: ' Broken boxes',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_broken_boxes',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_non_compliant_boxes': {
      questionId: 'packaging_non_compliant_boxes',
      isScore: false,
      displayedName: ' Unapproved box',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_non_compliant_boxes',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_non_compliant_punnets': {
      questionId: 'packaging_non_compliant_punnets',
      isScore: false,
      displayedName: ' Unapproved punnet',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_non_compliant_punnets',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'label_box_wrong': {
      questionId: 'label_box_wrong',
      isScore: false,
      displayedName: ' Wrong box labelling',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'label_box_wrong',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'label_punnet_wrong': {
      questionId: 'label_punnet_wrong',
      isScore: false,
      displayedName: ' Wrong punnet labelling',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'label_punnet_wrong',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'pallets_bad_condition': {
      questionId: 'pallets_bad_condition',
      isScore: false,
      displayedName: 'Broken pallets',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'pallets_bad_condition',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'info_missing_documents': {
      questionId: 'info_missing_documents',
      isScore: false,
      displayedName: 'No AOA',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'info_missing_documents',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'wrong_pallets': {
      questionId: 'wrong_pallets',
      isScore: false,
      displayedName: 'Incorrect pallet number',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'wrong_pallets',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'wrong_grower': {
      questionId: 'wrong_grower',
      isScore: false,
      displayedName: 'Incorrect grower',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'wrong_grower',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'wrong_variety': {
      questionId: 'wrong_variety',
      isScore: false,
      displayedName: 'Incorrect variety',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'wrong_variety',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'weight': {
      questionId: 'weight',
      isScore: false,
      displayedName: 'Incorrect package weight',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'weight',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_wrong_box': {
      questionId: 'packaging_wrong_box',
      isScore: false,
      displayedName: 'Incorrect box/tray type',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_wrong_box',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_wrong_punnet': {
      questionId: 'packaging_wrong_punnet',
      isScore: false,
      displayedName: 'Incorrect punnet type',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_wrong_punnet',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_wrong_lid': {
      questionId: 'packaging_wrong_lid',
      isScore: false,
      displayedName: 'Incorrect lid type',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_wrong_lid',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_non_compliant': {
      questionId: 'packaging_non_compliant',
      isScore: false,
      displayedName: 'Unapproved packaging',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_non_compliant',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'packaging_mixed_punnets': {
      questionId: 'packaging_mixed_punnets',
      isScore: false,
      displayedName: 'Mixed punnets',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'packaging_mixed_punnets',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'info_incorrect_arrival_date': {
      questionId: 'info_incorrect_arrival_date',
      isScore: false,
      displayedName: 'Incorrect date of arrival',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'info_incorrect_arrival_date',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'info_incorrect_delivery_address': {
      questionId: 'info_incorrect_delivery_address',
      isScore: false,
      displayedName: 'Incorrect delivery address',
      inspectionProperties: {
        inclusionCriteria: null,
        isMandatory: false,
        taggable: true,
        hint: null,
      },
      reportProperties: null,
      cardProperties: { hideInCard: false, cardName: null },
      scoringPolicy: null,
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'info_incorrect_delivery_address',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'ggn_missing': {
      questionId: 'ggn_missing',
      isScore: false,
      displayedName: 'GGN missing',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['ggn_missing'],
        rules: [
          {
            slice: [
              {
                measurableId: 'ggn_missing',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'ggn_missing',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'ggn_missing',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'ggn_invalid': {
      questionId: 'ggn_invalid',
      isScore: false,
      displayedName: 'GGN invalid',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['ggn_invalid'],
        rules: [
          {
            slice: [
              {
                measurableId: 'ggn_invalid',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'ggn_invalid',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'ggn_invalid',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'gln_missing': {
      questionId: 'gln_missing',
      isScore: false,
      displayedName: 'GLN missing',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['gln_missing'],
        rules: [
          {
            slice: [
              {
                measurableId: 'gln_missing',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'gln_missing',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'gln_missing',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'gln_invalid': {
      questionId: 'gln_invalid',
      isScore: false,
      displayedName: 'GLN invalid',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['gln_invalid'],
        rules: [
          {
            slice: [
              {
                measurableId: 'gln_invalid',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'gln_invalid',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'gln_invalid',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'coc_missing': {
      questionId: 'coc_missing',
      isScore: false,
      displayedName: 'CoC missing',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['coc_missing'],
        rules: [
          {
            slice: [
              {
                measurableId: 'coc_missing',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'coc_missing',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'coc_missing',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
    'coc_invalid': {
      questionId: 'coc_invalid',
      isScore: false,
      displayedName: 'CoC invalid',
      inspectionProperties: null,
      reportProperties: null,
      cardProperties: null,
      scoringPolicy: {
        measurableIds: ['coc_invalid'],
        rules: [
          {
            slice: [
              {
                measurableId: 'coc_invalid',
                type: 'boolean',
                booleanVal: true,
              },
            ],
            score: 2,
          },
          {
            slice: [
              {
                measurableId: 'coc_invalid',
                type: 'boolean',
                booleanVal: false,
              },
            ],
            score: 4,
          },
        ],
      },
      inspectionScoring: null,
      id: '123',
      measurables: [
        {
          id: '123',
          measurableId: 'coc_invalid',
          setProperty: null,
          inclusionCriteria: null,
          type: 'default',
          measurement: {
            type: 'boolean',
            displayedName: null,
            valueType: 'boolean',
            defaultValue: null,
          },
        },
      ],
    },
  },
  lotScoring: 'TODO',
};
