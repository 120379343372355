import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
  IonSkeletonText,
  IonText,
} from '@ionic/react';
import {
  addOutline,
  caretDownCircleOutline,
  caretUpCircleOutline,
  documentAttachOutline,
  linkOutline,
  readerOutline,
  trailSignOutline,
  unlinkOutline,
} from 'ionicons/icons';
import _ from 'lodash';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import './CardOrder.scss';
import withContext, { ContextProps } from './HOC/withContext';
import {
  formatDate,
  getLotOrPosQuantity,
  getQcStatusColor,
  getReportTypeTitle,
  getSplitLotTransferedQuantity,
  getSupplyChainReportReferences,
  isSplitLotPosition,
  qcStatusToUIName,
  resolveLocationName,
  shouldShowPositionQuantity,
  standardizeDate,
} from './HelperUtils';
import { LegacyInspection, LotInspection } from './InspectionModel';
import {
  LotPopoverModel,
  LotPosition,
  Order,
  Report,
  ReportReference,
  ReportType,
} from './Model';
import { userHasPermission } from './PermissionsService';
import { Article } from './ServiceArticle';
import { InspectionClass, orderInspectionsList } from './ServiceInspection';
import ViewArticleDescription from './ViewArticleDescription';
import ViewContactName from './ViewContactName';
import { ViewInspectionScore } from './ViewInspectionScore';
import { CardOrderMenu } from './components/CardOrderMenu';

interface Props {
  order?: Order;
  loading?: boolean;
  expandLots?: boolean;
  showLink?: boolean;
  highlightLotId?: string;
  searchString?: string;
  routerLink?: string;
  onClick?: any;
  hideDone?: boolean;
  displayButtons?: boolean;

  // position index of the card in the UI
  index?: number;
  style?: any;

  showContactPositions?: boolean;

  openNewLotModal?: (p: LotPosition, o: Order, f: () => void) => void;
  openLinkLotModal?: (p: LotPosition, o: Order, f: () => void) => void;
  onLotUnlink?: (p: LotPosition, o: Order | Report, f: () => void) => void;
}

interface State {
  expandLots: boolean;
  currPosition: LotPosition;
  lotOptionsPopover?: LotPopoverModel;
}

class CardOrder extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      expandLots: this.props.expandLots,
      currPosition: undefined,
      lotOptionsPopover: { visible: false },
    };
  }

  private pageRef: RefObject<HTMLElement> = React.createRef();

  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    const { expandLots, lotOptionsPopover } = this.state;

    if (this.props.expandLots !== nextProps.expandLots) {
      return true;
    }

    if (
      expandLots !== nextState.expandLots ||
      !_.isEqual(lotOptionsPopover, nextState.lotOptionsPopover)
    ) {
      return true;
    }

    if (!_.isEqual(this.props.locations, nextProps?.locations)) {
      return true;
    }

    if (!this.props.order) {
      return true;
    }

    if (this.props.order?.lastModifiedDate !== nextProps.order?.lastModifiedDate) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.expandLots !== nextProps.expandLots) {
      this.setState({ expandLots: nextProps.expandLots });
    }
  }

  getLink() {
    let { showLink, routerLink, order } = this.props;
    if (showLink === false) {
      return null;
    } else if (routerLink !== undefined) {
      return { to: routerLink };
    }
    return { to: '/secure/' + order.orgId + '/order/' + order.id };
  }

  toggleOrders(e) {
    const { expandLots } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expandLots: !expandLots });
  }

  orderIcon() {
    let icon = trailSignOutline;
    switch (this.props.order.type) {
      case 'INTERNAL_TRANSFER':
      case 'SELL_RETURN':
        icon = trailSignOutline;
        break;
      default:
        icon = readerOutline;
    }
    return (
      <>
        <IonIcon style={{ pointerEvents: 'none', marginLeft: '-4px' }} icon={icon} />
        {(this.props.order.reportReferences ?? []).length > 0 && (
          <IonIcon
            style={{ pointerEvents: 'none', marginLeft: '-4px' }}
            icon={documentAttachOutline}
          />
        )}
      </>
    );
  }

  getTitle(title) {
    let { searchString } = this.props;

    if (searchString && title.endsWith(searchString)) {
      return (
        <>
          {title.slice(0, title.length - searchString.length)}
          <span className="highlight">{searchString}</span>
        </>
      );
    }

    return title;
  }

  getOrderId(entity: Order | Report) {
    let order: Order = entity as Order;
    let report: Report = entity as Report;

    // TODO ILDE: test this
    if (!!report.reportReference) {
      return report.reportReference.orderId ?? report.reportReference.externalOrderId;
    }
    return order.id;
  }

  getClassName() {
    let c = '';
    const { order } = this.props;
    // if (order.list === "OUT")
    //   return

    if (
      order.qcStatus === 'OPEN' ||
      order.qcStatus === 'REDO' ||
      order.qcStatus === undefined
    ) {
      c = '';
    } else {
      c = 'completed ';
    }

    c += ' qc-status-' + order.qcStatus;

    if (this.state.expandLots === true) {
      c = c + ' open ';
    }

    if (order.search?.scores?.has1orLess) {
      c += ' score-1';
    } else if (order.search?.scores?.has2orLess) {
      c += ' score-2';
    } else if (order.search?.scores?.has3orLess) {
      c += ' score-3';
    }
    return c;
  }

  onLotOptionsDismiss() {
    this.setState({ lotOptionsPopover: { visible: false }, currPosition: undefined });
  }

  displaySupplyChainReportBadge() {
    const { order } = this.props;
    const SCReportReferences: ReportReference[] = getSupplyChainReportReferences(order);

    if (SCReportReferences.length === 0) {
      return <></>;
    }

    let title: string;
    (['UPCOMING', 'FEEDBACK'] as ReportType[]).forEach((t) => {
      if (SCReportReferences.find((r) => r.type === t)) {
        title = getReportTypeTitle(t);
      }
    });

    return (
      <div className="card-order-report-type">
        <IonBadge color="light-medium">
          {/* <IonIcon icon={attachOutline} title=""/> */}
          {title.toUpperCase()}
        </IonBadge>
      </div>
    );
  }

  render() {
    const { order, highlightLotId, index } = this.props;

    if (this.props.loading) {
      return (
        <IonCard className="card-order skeleton" style={this.props.style}>
          <IonCardHeader>
            {!!order?.id && (
              <IonCardTitle>
                <div className="order-id">
                  {this.orderIcon()}
                  <div>{this.getTitle(this.getOrderId(order))}</div>
                </div>
              </IonCardTitle>
            )}
            <IonCardTitle>
              <IonSkeletonText animated />
              {[0].map((idx) => (
                <div key={idx} style={{ padding: '5px 90px 20px 0' }}>
                  <IonLabel>
                    <IonSkeletonText animated />
                  </IonLabel>
                </div>
              ))}
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
      );
    }

    let { expandLots, lotOptionsPopover } = this.state;

    let inspections: LegacyInspection[] = orderInspectionsList(order);
    let toggleIcon = expandLots ? caretUpCircleOutline : caretDownCircleOutline;

    const normalPositions = order.positions?.filter((p) => !isSplitLotPosition(p));
    const splitLotPositions = order.positions?.filter(isSplitLotPosition);

    let sum = inspections?.reduce((accumulator, element) => {
      return accumulator + (new InspectionClass(element).isCompleted() ? 1 : 0);
    }, 0);

    let orderDone = sum === inspections.length - 1 && order.positions.length > 0;

    if (orderDone && this.props.hideDone === true) {
      return null;
    }

    let onClick = this.props.onClick
      ? { onClick: () => this.props.onClick(order) }
      : null;

    const displayOrderPositions = expandLots;
    const displaySubLots = expandLots && order.hasSplitLot;

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const fulfilmentDateTimestamp = standardizeDate(
      order.fulfilmentDate ?? 0
    ).getTime();

    return (
      <IonCard
        mode="md"
        key={order.id}
        className={'card-order ' + this.getClassName()}
        {...onClick}
        data-tip={`card-order-index-${this.props.index}`}
      >
        <Link
          className="card-order-link"
          {...this.getLink()}
          style={{ textDecoration: 'none' }}
        >
          <IonCardHeader>
            <IonCardTitle>
              <div className="order-id">
                {this.orderIcon()}
                <div>{this.getTitle(this.getOrderId(order))}</div>
                {/* <div title="report available">{orderDone && <IonIcon style={{"pointerEvents": "none"}} icon={documentAttachOutline} className="report-icon"/>}</div> */}
              </div>

              <div className="order-info">
                <IonBadge color="medium">{formatDate(order.fulfilmentDate)}</IonBadge>

                {order.qcRelevant ? (
                  fulfilmentDateTimestamp < new Date().getTime() ? (
                    fulfilmentDateTimestamp < today.getTime() ? (
                      <IonBadge color="dark">ARRIVED</IonBadge>
                    ) : (
                      <IonBadge color="primary">DUE TODAY</IonBadge>
                    )
                  ) : (
                    <IonBadge color="warning">EXPECTED</IonBadge>
                  )
                ) : null}

                {!!order.qcStatus && (
                  <IonBadge color={getQcStatusColor(order)}>
                    {qcStatusToUIName(order.qcStatus)}
                  </IonBadge>
                )}
              </div>

              {this.displaySupplyChainReportBadge()}
            </IonCardTitle>
            <IonCardTitle className="contact capitalize">
              <ViewContactName report={order} />
              {order.supplierId ? (
                <span>{order.supplierName.toLocaleLowerCase()}</span>
              ) : order.type === 'INTERNAL_TRANSFER' ? (
                'INTERNAL TRANSFER'
              ) : null}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardHeader>
            <IonButtons>
              <IonButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className="redo-button"
              >
                REDO
              </IonButton>
              <IonButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className="share-button"
                fill="solid"
                color="tertiary"
              >
                SHARE
              </IonButton>
              {/* <IonButton 
              onClick={(e) => {e.stopPropagation(); e.preventDefault();}} 
              className="create-report-button" 
              fill="solid" 
              color="primary"
            >
              CREATE REPORT
            </IonButton> */}
              <CardOrderMenu order={order} />
              <IonButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.toggleOrders(e);
                }}
              >
                <IonIcon
                  icon={toggleIcon}
                  data-tip={`unfold-order-card-${index}`}
                  color="medium"
                  slot="icon-only"
                />
              </IonButton>
            </IonButtons>
          </IonCardHeader>
        </Link>
        <IonCardContent style={{ display: this.state.expandLots ? 'block' : 'none' }}>
          {/* Lot options popover */}
          {lotOptionsPopover.visible && (
            <IonPopover
              isOpen={lotOptionsPopover.visible}
              event={lotOptionsPopover.event}
              onDidDismiss={() => this.onLotOptionsDismiss()}
            >
              {this.renderLotOptions()}
            </IonPopover>
          )}
          {/* DISPLAY order positions */}
          {normalPositions?.map((position: LotPosition, index) => {
            const inspection = inspections.find(
              (i) => i.reference?.lotId === position.lotId
            ) as LotInspection;
            // console.log('ass', assessment);
            if (!inspection) return null;

            const splitLotTransferedQuantity = getSplitLotTransferedQuantity(
              order,
              position,
              new Article(position?.article).isRaw() ? 'volumeInKg' : 'numBoxes'
            );

            return this.renderPosition(
              index,
              highlightLotId,
              position,
              inspection,
              order,
              splitLotTransferedQuantity,
              undefined,
              false
            );
          })}

          {/* DISPLAY SUBLOTS */}
          {
            <>
              {splitLotPositions.length > 0 && (
                <>
                  {/* <IonItemDivider/> */}
                  <div className="sublots-divider">Child batches</div>

                  {splitLotPositions.map((position: LotPosition, index) => {
                    const inspection = inspections.find(
                      (i) => i.reference?.lotId === position.lotId
                    ) as LotInspection;
                    // console.log('ass', assessment);
                    if (!inspection) return null;

                    return this.renderPosition(
                      index,
                      highlightLotId,
                      position,
                      inspection,
                      order
                    );
                  })}
                </>
              )}
            </>
          }
        </IonCardContent>
        {order.locationId && (
          <div className="location">
            Location: {resolveLocationName(order.locationId, this.props.locations)}
          </div>
        )}
      </IonCard>
    );
  }

  renderPosition(
    index: number,
    highlightLotId: string,
    position: LotPosition,
    inspection: LotInspection,
    order: Order,
    splitLotTransferedQuantity: number = 0,
    origOrder?: Order,
    isReport?: boolean
  ): JSX.Element {
    const showLink: boolean = !position.lotId.endsWith(highlightLotId);

    const { profile } = this.props;

    // TODO: reimplement this logic
    const displayLinkedLotId = false;
    let linkedLotId: string = undefined;

    let ionItemProps: any = {
      key: index,
      className:
        highlightLotId && position.lotId.endsWith(highlightLotId) && 'highlight',
    };

    const articleClass = new Article(position?.article);

    if (showLink)
      ionItemProps.routerLink = `/secure/${order.orgId}/lot-from-order/${position.lotId}/${order.id}`;

    const showQuantity = shouldShowPositionQuantity(position);
    const quantity = getLotOrPosQuantity(position);

    return (
      <IonItem {...ionItemProps} detail={false}>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-padding-end col-name">
              <div className="lot-info">
                {isReport && !!linkedLotId ? linkedLotId : position.lotId}
              </div>

              {displayLinkedLotId && (
                <div className="contact-lot-info">
                  <IonIcon icon={linkOutline} />
                  {isReport ? position.lotId : linkedLotId}
                </div>
              )}

              <div>
                <ViewArticleDescription
                  growerId={position.growerId}
                  pills={true}
                  // quantity={position.quantity}
                  article={position.article}
                />
                <IonBadge
                  mode="md"
                  className={
                    'status ' + inspection.status.replace(/ /g, '-').toLowerCase()
                  }
                >
                  {!['OPEN', 'COMPLETED'].includes(inspection.status) &&
                    inspection.status}
                </IonBadge>
              </div>
            </IonCol>
            <IonCol size="auto" className="col-scoring">
              {new InspectionClass(
                order.lotInspectionMap?.[position.lotId]
              ).isCompleted() && (
                <ViewInspectionScore
                  organisationSettings={this.props.organisation?.settings}
                  profile={profile}
                  inspection={inspection}
                  order={order}
                  displayType={'CARD-ORDER'}
                />
              )}

              {showQuantity && (
                <IonBadge color="light-medium" className="quantity">
                  <b>{quantity}</b>
                  <div>{articleClass.getUnit(position.numBoxes)}</div>
                </IonBadge>
              )}
              {splitLotTransferedQuantity > 0 ? (
                <IonBadge color="danger" className="split-negative">{`-${Math.round(
                  splitLotTransferedQuantity
                )}`}</IonBadge>
              ) : (
                ''
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    );
  }

  renderLotOptions() {
    const wrapper = (
      icon: string,
      func: (e: any) => any,
      text: string,
      disabled: boolean,
      title: string,
      color: string = 'primary'
    ) => (
      <IonItem title={title} button disabled={disabled} onClick={func}>
        <IonIcon color={color} icon={icon} />
        &nbsp;
        <IonLabel>{text}</IonLabel>
      </IonItem>
    );

    const { openNewLotModal, openLinkLotModal, onLotUnlink } = this.props;
    const { order, origOrder } = this.state.lotOptionsPopover;
    const { currPosition } = this.state;

    // TODO: reimplement this logic
    let linkedLotId: string = undefined;

    const displayUnlink =
      userHasPermission(this.props.profile, 'LINK', 'LOT') &&
      !!linkedLotId &&
      this.props.displayButtons &&
      ['OPEN', 'REDO'].includes(order?.qcStatus);

    const displayLink =
      userHasPermission(this.props.profile, 'LINK', 'LOT') &&
      this.props.displayButtons &&
      ['OPEN', 'REDO'].includes(order?.qcStatus);

    const displayCreate =
      userHasPermission(this.props.profile, 'CREATE', 'LOT') &&
      this.props.displayButtons &&
      ['OPEN', 'REDO'].includes(order?.qcStatus);

    if (!displayLink && !displayUnlink && !displayCreate) {
      return (
        <IonItem>
          <IonText color="medium">No actions available</IonText>
        </IonItem>
      );
    }

    return (
      <IonList>
        {displayLink &&
          wrapper(
            addOutline,
            (e) => {
              e.preventDefault();
              e.stopPropagation();
              openNewLotModal(
                currPosition,
                origOrder,
                this.onLotOptionsDismiss.bind(this)
              );
            },
            'Create batch and link',
            false,
            'Create position',
            'primary'
          )}

        {displayCreate &&
          wrapper(
            linkOutline,
            (e) => {
              e.preventDefault();
              e.stopPropagation();
              openLinkLotModal(
                currPosition,
                origOrder,
                this.onLotOptionsDismiss.bind(this)
              );
            },
            'Link to existing batch',
            false,
            'Link with existing lot',
            'secondary'
          )}

        {displayUnlink &&
          wrapper(
            unlinkOutline,
            (e) => {
              e.preventDefault();
              e.stopPropagation();
              onLotUnlink(currPosition, order, this.onLotOptionsDismiss.bind(this));
            },
            'Unlink batches',
            false,
            'Unlink lot',
            'danger'
          )}
      </IonList>
    );
  }
}

export default withContext(CardOrder, ['locations', 'organisation', 'profile']);
