function isInStandaloneMode(): boolean {
  return !!(
    (
      (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
      (window.navigator as any).standalone
    ) // Fallback for iOS
  );
}

let hasBeenInStandaloneMode: boolean;

if (isInStandaloneMode()) {
  hasBeenInStandaloneMode = true;
  sessionStorage.setItem('is_standalone_mode', 'yes');
} else {
  hasBeenInStandaloneMode = sessionStorage.getItem('is_standalone_mode') === 'yes';
}

export function isInstalledPwaSession(): boolean {
  return hasBeenInStandaloneMode;
}
