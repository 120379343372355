import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItemDivider,
  IonList,
  IonSpinner,
  IonItem,
} from '@ionic/react';
import React, { Fragment } from 'react';
import {
  Claim,
  Lot,
  LotTransfer,
  Order,
  OrderType,
  ProductionSiteLocation,
  Report,
  ReportReference,
} from './Model';
import './PageLot.scss';
import { createOutline, link, nutritionOutline, receiptOutline } from 'ionicons/icons';
import CardClaim from './CardClaim';
import CardInspection from './CardInspection';
import CardOrder from './CardOrder';
import CardProduction from './CardProduction';
import CardReport from './CardReport';
import CardTransfer from './CardTransfer';
import { firestore } from './ConfigFirebase';
import { getReportSnapshot, supportedInspectionTypeMap } from './DataReport';
import {
  getClaimsByLotIdSnapshot,
  getLotSnapshot,
  getOrder,
  getOrderSnapshot,
  orderColRef,
} from './DataStorage';
import { SUPPLY_CHAIN_COL_NAME, SUPPLY_CHAIN_URL_SUFFIX } from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import {
  LOT_ACTION_ID_PREFIX,
  c,
  formatDate,
  getLotOrPosQuantity,
  getSupplyChainReportReferences,
  standardizeDate,
} from './HelperUtils';
import { LotInspection } from './InspectionModel';
import { Article } from './ServiceArticle';
import {
  hasInspectionReference,
  stringifyInspectionReference,
} from './ServiceInspection';
import ViewContactName from './ViewContactName';
import { cloneDeep, isEqual } from 'lodash';
import { CardField } from './CardField';

interface Props {
  history: any;
  organisationId: string;
  lot: Lot;
  supplyChainLot?: boolean;
}

interface State {
  lotHistory: LotHistory[];
  lot: Lot;
  supplyChainReports: { [reportId: string]: Report };
  inited: boolean;
}

// each one of these will be visually represented in a different way
// TODO: do we want a special type of visualization for retours?
type LotActionType =
  | 'ASSESSMENT'
  | 'ORDER'
  | 'PRODUCTION'
  | 'SPLIT'
  | 'TRANSFER'
  | 'CREATE'
  | 'CLAIM'
  | 'FIELD';

interface LotHistory {
  type: LotActionType;
  date: Date;
  order?: Order;
  inspection?: LotInspection;
  claim?: Claim;
  transfer?: LotTransfer;
  field?: ProductionSiteLocation;
  isLoading?: boolean;
}

const lotHistoryOrderTypeMap: { [key in OrderType]?: LotActionType } = {
  BUY: 'ORDER',
  SELL: 'ORDER',
  CREATE_LOT: 'CREATE',
  INTERNAL_TRANSFER: 'TRANSFER',
  PRODUCTION: 'PRODUCTION',
  SPLIT_LOT: 'SPLIT',
  BUY_RETURN: 'ORDER',
  SELL_RETURN: 'ORDER',
};

class PageLotHistory extends React.Component<Props & ContextProps, State> {
  private mounted: boolean = false;
  private orderSubscriptions: { [orderId: string]: any } = {};
  private reportSubscriptions: { [reportId: string]: any } = {};
  private lotSubscription;
  private claimsSubscription;

  constructor(props) {
    super(props);

    this.state = {
      inited: false,
      lot: undefined,
      supplyChainReports: {},
      lotHistory: [],
    };
  }

  //----------------------------------------------------------
  // Life cycle
  async componentDidMount() {
    this.mounted = true;
    const { lot: propsLot, organisationId, supplyChainLot } = this.props;

    const collection = supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined;

    // set up subscriptions
    if (propsLot && this.mounted) {
      this.lotSubscription = getLotSnapshot(
        firestore,
        organisationId,
        propsLot.id,
        this.handleLotSnapshot,
        undefined,
        collection
      );
    }
  }

  componentWillUnmount() {
    !!this.lotSubscription && this.lotSubscription();
    !!this.claimsSubscription && this.claimsSubscription();
    Object.values(this.orderSubscriptions).forEach((s) => s());
    Object.values(this.reportSubscriptions).forEach((s) => s());
    this.mounted = false;
  }

  //----------------------------------------------------------
  // State management and helpers
  updateLotInLotHistory(lot: Lot | undefined) {
    if (!lot) {
      return this.setState({ lotHistory: [] });
    }

    let lotHistory = cloneDeep(this.state.lotHistory);

    // Populate lot creation events
    for (const transfer of lot.transfers ?? []) {
      if (
        ((['BUY', 'HARVESTED', 'CREATE_LOT'] as OrderType[]).includes(
          transfer.transferType
        ) ||
          this.isLotAction(transfer)) &&
        lot.isAGMaster
      ) {
        const event: LotHistory = {
          type: 'CREATE',
          date: standardizeDate(transfer.transferDate),
          transfer,
        };
        const currIndex = lotHistory.findIndex(
          (lh) => lh.transfer?.transferId === transfer.transferId
        );

        if (currIndex >= 0) {
          lotHistory[currIndex] = event;
        } else {
          lotHistory.push(event);
        }
      }
    }

    // Populate inspection events
    for (const inspection of lot.inspections ?? []) {
      const event: LotHistory = {
        type: 'ASSESSMENT',
        date: standardizeDate(inspection.lastQCDate ?? inspection.lastModifiedDate),
        inspection,
      };
      const currIndex = lotHistory.findIndex((lh) =>
        hasInspectionReference(lh.inspection, inspection.reference)
      );

      if (currIndex >= 0) {
        lotHistory[currIndex] = event;
      } else {
        lotHistory.push(event);
      }
    }

    // Populate fields, if available
    if (!!lot.origin?.locationId) {
      const field = this.props.locations.find(
        (l) => l.locationId === lot.origin?.locationId
      ) as ProductionSiteLocation;
      if (!!field?.latestInspectionPreview) {
        const event: LotHistory = {
          type: 'FIELD',
          field,
          date: field.latestInspectionPreview.lastModifiedDate,
        };
        lotHistory.push(event);
      }
    }

    this.setLotHistory(lotHistory);
  }

  // Since location sites
  componentDidUpdate(prevProps: Readonly<Props & ContextProps>): void {
    const associatedFieldId: string = this.state.lot?.origin?.locationId;

    if (associatedFieldId == null) {
      return;
    }

    const latestField = this.props.locations.find(
      (l) => associatedFieldId === l.locationId
    ) as ProductionSiteLocation;

    // if the field info changed, update it in the state
    if (
      isEqual(
        latestField,
        prevProps.locations.find((l) => associatedFieldId === l.locationId)
      )
    ) {
      return;
    }

    const fieldIndexInHistory = this.state.lotHistory.findIndex(
      (lh) => lh.field?.locationId === associatedFieldId
    );

    const newHistory = cloneDeep(this.state.lotHistory);

    if (!!latestField?.latestInspectionPreview) {
      const event: LotHistory = {
        field: latestField,
        type: 'FIELD',
        date: latestField.latestInspectionPreview.lastModifiedDate,
      };

      if (fieldIndexInHistory >= 0) {
        newHistory[fieldIndexInHistory] = event;
      } else {
        newHistory.push(event);
      }
    } else {
      newHistory.splice(fieldIndexInHistory, 1);
    }

    this.setLotHistory(newHistory);
  }

  updateOrdersInLotHistory(orders: Order[], isLoading: boolean = false) {
    if (!this.state.lot) {
      return;
    }
    let lotHistory = cloneDeep(this.state.lotHistory);

    orders.forEach((order) => {
      // Skip if snapshot is the same as the current order in the lot history event
      const currEventIndex: number = lotHistory.findIndex(
        (lh) => lh.order?.id === order.id
      );

      if (currEventIndex >= 0 && isEqual(lotHistory[currEventIndex].order, order))
        return;

      const event: LotHistory = {
        type: lotHistoryOrderTypeMap[order.type],
        date: standardizeDate(order.fulfilmentDate),
        order,
        transfer: this.state.lot.transfers.find(
          (t) => (t.orderId ?? t.transferId) === order.id
        ),
        isLoading,
      };

      if (currEventIndex >= 0) {
        lotHistory[currEventIndex] = event;
      } else {
        lotHistory.push(event);
      }
    });

    this.setLotHistory(lotHistory);
  }

  updateClaimsInLotHistory(claims: Claim[] | null) {
    if (!this.state.lot) {
      return;
    }
    let lotHistory = cloneDeep(this.state.lotHistory);

    if ((claims ?? []).length === 0) {
      lotHistory = lotHistory.filter((lh) => lh.type !== 'CLAIM');
    } else {
      for (const claim of claims) {
        const event: LotHistory = {
          type: 'CLAIM',
          date: standardizeDate(claim.receivedDate),
          claim,
        };
        const currIndex = lotHistory.findIndex(
          (lh) => lh.claim?.claimId === claim.claimId
        );
        if (currIndex >= 0) {
          lotHistory[currIndex] = event;
        } else {
          lotHistory.push(event);
        }
      }
    }

    this.setLotHistory(lotHistory);
  }

  isLotAction = (transfer: LotTransfer): boolean => {
    return (transfer.transferId ?? transfer.orderId).startsWith(LOT_ACTION_ID_PREFIX);
  };

  setLotHistory(lotHistory: LotHistory[], callback?: () => void) {
    lotHistory.sort((a, b) => (a.date < b.date ? 1 : -1));

    // skip if no changes detected
    if (isEqual(this.state.lotHistory, lotHistory)) {
      return callback;
    }

    this.setState({ lotHistory }, callback);
  }

  mapOrderIdToPlaceholderOrder = (orderId: string, lot: Lot): Order => {
    const { transferDate, transferType, numBoxes, volumeInKg } =
      lot.transfers.find((t) => (t.orderId ?? t.transferId) === orderId) ?? {};

    return {
      id: orderId,
      positions: [{ lotId: lot?.id, numBoxes, volumeInKg, article: lot?.article }],
      type: transferType,
      fulfilmentDate: standardizeDate(transferDate),
    } as Order;
  };

  handleLotSnapshot = async (lot: Lot | undefined) => {
    if (!lot) {
      return this.setState({ lot: undefined, inited: false, lotHistory: [] });
    }

    const { organisationId, supplyChainLot } = this.props;

    console.log('Fetched lot', lot);
    this.setState({ lot, inited: true, lotHistory: [] }, () =>
      this.updateLotInLotHistory(lot)
    );

    // We skip the rest if it's a supply chain lot
    if (supplyChainLot) {
      return;
    }

    // Gather order ids to fetch
    const orderIds: string[] = (lot?.transfers ?? [])
      .filter(
        (transfer) =>
          (transfer?.orderId ?? transfer?.transferId) != null &&
          transfer?.transferType !== 'CREATE_LOT' &&
          !this.isLotAction(transfer)
      )
      .sort(
        (a, b) =>
          standardizeDate(b.transferDate).getTime() -
          standardizeDate(a.transferDate).getTime()
      )
      .map((t) => t.orderId ?? t.transferId);

    // Set "mock orders" in state to display something while we fetch the actual ones
    const placeholderOrders: Order[] = orderIds.map((orderId) =>
      this.mapOrderIdToPlaceholderOrder(orderId, lot)
    );

    this.updateOrdersInLotHistory(placeholderOrders, true);

    // Clean subscriptions before fetching
    Object.values(this.orderSubscriptions).forEach((s) => s());
    Object.values(this.reportSubscriptions).forEach((s) => s());

    // Set up the snapshot listeners for the orders
    let orderSubIdx = 0;
    while (orderIds.length > 0) {
      this.orderSubscriptions[orderSubIdx++] = orderColRef(firestore, organisationId)
        .where('id', 'in', orderIds.splice(0, 10))
        .onSnapshot((orderDocs) => {
          const orders: Order[] = orderDocs.docs.map((d) => d.data() as Order);

          // Update state with the actual orders fetched from the db and set up report listeners, when available
          this.updateOrdersInLotHistory(orders);
          orders.forEach(this.setupReportSnapshotListener);
        });
    }

    // Set up claim subscriptions
    this.claimsSubscription = getClaimsByLotIdSnapshot(
      firestore,
      organisationId,
      lot.id,
      (claims: Claim[] | null) => {
        this.updateClaimsInLotHistory(claims);
      }
    );
  };

  setupReportSnapshotListener = (order: Order) => {
    // fetch supply chain reports, if available
    const scReportReferences: ReportReference[] = getSupplyChainReportReferences(order);

    if (scReportReferences.length > 0) {
      // display only the first one
      const { reportId } = scReportReferences[0];
      this.reportSubscriptions[reportId] = getReportSnapshot(
        firestore,
        this.props.organisationId,
        reportId,
        (report) => {
          if (!!report && !isEqual(this.state.supplyChainReports[report.id], report)) {
            this.setState({
              supplyChainReports: {
                ...this.state.supplyChainReports,
                [report.id]: report,
              },
            });
          } else {
            this.setState({
              supplyChainReports: {
                ...this.state.supplyChainReports,
                [reportId]: undefined,
              },
            });
          }
        }
      );
    }
  };

  //----------------------------------------------------------
  // Render
  renderLotHistory() {
    const { lotHistory } = this.state;

    if (!lotHistory || lotHistory.length === 0) {
      return <></>;
    }

    return lotHistory.map((lh, idx) => {
      switch (lh.type) {
        case 'ASSESSMENT':
          return this.renderInspection(lh, idx);
        case 'ORDER':
          return this.renderOrder(lh, idx);
        case 'CREATE':
          return this.renderCreation(lh);
        case 'PRODUCTION':
          return this.renderProduction(lh, idx);
        case 'SPLIT':
          return this.renderSplit(lh, idx);
        case 'TRANSFER':
          return this.renderTransfer(lh, idx);
        case 'CLAIM':
          return this.renderClaim(lh, idx);
        case 'FIELD':
          return this.renderField(lh, idx);
        default:
          return null;
      }
    });
  }

  loading() {
    return (
      <div className="loading">
        <IonSpinner name="dots" />
      </div>
    );
  }

  renderOrder(lh: LotHistory, idx: number) {
    const { order, date, isLoading } = lh;
    const { lot } = this.props;

    if (isLoading) {
      return <CardOrderLoading key={order.id} order={order} date={date} />;
    }

    const { lotHistory } = this.state;

    switch (order.type) {
      // do we want these 2 to be rendered differently?
      case 'BUY':
      case 'SELL':
      case 'SELL_RETURN':
      case 'BUY_RETURN':
        const position = order.positions.find((o) => o.lotId === lot.id);
        const quantity = getLotOrPosQuantity(position);

        const supplyChainReportId =
          getSupplyChainReportReferences(order)?.[0]?.reportId;
        const supplyChainReport =
          supplyChainReportId != null
            ? this.state.supplyChainReports[supplyChainReportId]
            : undefined;
        const claim = lotHistory.find(
          (o) => o.type === 'CLAIM' && o.claim?.orderId === order.id
        )?.claim;

        return (
          <Fragment key={order.id}>
            <IonItemDivider>
              <span>{formatDate(date)}</span>
              {order.type.replace('_', ' ')} {quantity}&nbsp;
              {new Article(this.props.lot.article).getUnit(quantity)}
            </IonItemDivider>
            <div className="order-container" data-tip={`lot-history-index-${idx}`}>
              <CardOrder order={order} highlightLotId={lot.id} loading={false} />
              {supplyChainReport != null && (
                <>
                  <div className="icon-link">
                    <IonIcon icon={link} />
                  </div>
                  <CardReport
                    report={supplyChainReport}
                    highlightLotId={lot.id}
                    loading={false}
                  />
                </>
              )}
              {!!claim && (
                <>
                  <div className="icon-link">
                    <IonIcon icon={link} />
                  </div>
                  <CardClaim
                    order={order}
                    highlightLotId={lot.id}
                    loading={false}
                    claim={claim}
                    showDate={true}
                    showLotId={false}
                  />
                </>
              )}
            </div>
          </Fragment>
        );
    }
  }

  renderInspection(lotHistory: LotHistory, idx: number) {
    const { inspection, date } = lotHistory;

    const lotLink =
      '/secure/' +
      this.props.profile.organisationId +
      '/inspection-view/' +
      stringifyInspectionReference(inspection.reference) +
      (this.props.supplyChainLot ? `/${SUPPLY_CHAIN_URL_SUFFIX}` : '');

    let title =
      supportedInspectionTypeMap[inspection?.reference?.type.toLowerCase()] + ' ' ?? '';

    return (
      <div
        data-tip={`lot-history-index-${idx}`}
        key={JSON.stringify(inspection.reference)}
      >
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          {`${title}Inspection`}
        </IonItemDivider>
        <CardInspection
          inspection={inspection}
          loading={false}
          organisationId={this.props.organisationId}
          routerLink={lotLink}
          history={this.props.history}
        />
      </div>
    );
  }

  getCreationIcon(t: LotTransfer) {
    switch (t.transferType) {
      case 'BUY':
        return receiptOutline;
      case 'HARVESTED':
        return nutritionOutline;
      default:
        return createOutline;
    }
  }

  renderCreationTitle(t: LotTransfer) {
    if (t.transferType === 'CREATE_LOT') {
      return 'CREATION';
    }
    return t.transferType;
  }

  renderCreation(lotHistory: LotHistory) {
    const { transfer, date } = lotHistory;
    const { numBoxes, volumeInKg } = transfer;
    const articleClass = new Article(this.props.lot.article);
    const quantity = articleClass.isRaw() ? volumeInKg : numBoxes;

    return (
      <Fragment key={transfer.transferId + (date ?? new Date()).toLocaleDateString()}>
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          {this.renderCreationTitle(transfer)}{' '}
          {!!quantity &&
            `${quantity} ${new Article(this.props.lot.article).getUnit(quantity)}`}
        </IonItemDivider>
        <div className="order-container">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <div className="order-id">
                  <IonIcon icon={this.getCreationIcon(transfer)} /> {this.props.lot.id}
                </div>
                {transfer.transferType === 'BUY' && (
                  <ViewContactName lot={this.props.lot} />
                )}
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </div>
      </Fragment>
    );
  }

  renderProduction(lotHistory: LotHistory, idx: number) {
    const { order, date, isLoading } = lotHistory;
    const { lot } = this.props;

    if (isLoading) {
      return <CardOrderLoading key={order.id} order={order} date={date} />;
    }

    const position = order.positions.find((o) => o.lotId === lot.id);
    const quantity = getLotOrPosQuantity(position);

    return (
      <Fragment key={order.id}>
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          {order.type.replace('_', ' ')} {quantity}&nbsp;
          {new Article(this.props.lot.article).getUnit(quantity)}
        </IonItemDivider>
        <div className="order-container" data-tip={`lot-history-index-${idx}`}>
          <CardProduction order={order} highlightLotId={lot.id} loading={false} />
        </div>
      </Fragment>
    );
  }

  renderSplit(lotHistory: LotHistory, idx: number) {
    const { order, date } = lotHistory;
    const { lot } = this.props;

    let position = order.positions.find((o) => o.lotId === lot.id);
    const quantity = getLotOrPosQuantity(position);

    return (
      <Fragment key={order.id}>
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          SPLIT BATCH {quantity}&nbsp;
          {new Article(this.props.lot.article).getUnit(quantity)}
        </IonItemDivider>
        <div className="order-container" data-tip={`lot-history-index-${idx}`}>
          <CardProduction order={order} highlightLotId={lot.id} loading={false} />
        </div>
      </Fragment>
    );
  }

  renderTransfer(lotHistory: LotHistory, idx: number) {
    const { order, date, transfer, isLoading } = lotHistory;
    const { lot } = this.props;

    if (isLoading) {
      return <CardOrderLoading key={order.id} order={order} date={date} />;
    }

    let position = order.positions.find((o) => o.lotId === lot.id);
    const quantity = getLotOrPosQuantity(position);

    return (
      <Fragment key={order.id}>
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          Transfer {quantity}&nbsp;
          {new Article(this.props.lot.article).getUnit(quantity)}
        </IonItemDivider>
        <div className="order-container" data-tip={`lot-history-index-${idx}`}>
          <CardTransfer
            order={order}
            highlightLotId={lot.id}
            loading={false}
            transfer={transfer}
          />
        </div>
      </Fragment>
    );
  }

  renderClaim(lotHistory: LotHistory, idx: number) {
    const { order, date } = lotHistory;
    const { lot } = this.props;

    const { claim } = lotHistory;

    return (
      <Fragment key={claim.claimId}>
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          CLAIM
        </IonItemDivider>
        <div className="order-container" data-tip={`lot-history-index-${idx}`}>
          <CardClaim
            order={order}
            highlightLotId={lot.id}
            loading={false}
            claim={claim}
            showLotId={false}
          />
        </div>
      </Fragment>
    );
  }

  renderField(lotHistory: LotHistory, idx: number) {
    const { field, date } = lotHistory;

    return (
      <Fragment key={field.locationId}>
        <IonItemDivider>
          <span>{formatDate(date)}</span>
          PRODUCTION SITE
        </IonItemDivider>
        <div className="order-container" data-tip={`lot-history-index-${idx}`}>
          <CardField field={field} />
        </div>
      </Fragment>
    );
  }

  render() {
    const { lot } = this.props;

    if (!lot) {
      return this.loading();
    }

    return (
      <div className="page-lot-history">
        {!this.state.inited ? (
          this.loading()
        ) : (
          <IonList className="page-lot-history-list">{this.renderLotHistory()}</IonList>
        )}
      </div>
    );
  }
}

export default React.memo(withContext(PageLotHistory, ['profile', 'locations']));

interface COLProps {
  order: Order;
  date: Date;
}
function CardOrderLoading({ order, date }: COLProps) {
  const position = order.positions[0];

  let quantity = !!position ? getLotOrPosQuantity(position) : '';

  if (isNaN(+quantity)) {
    quantity = '??';
  }

  const unit =
    quantity !== '??' && !!position
      ? new Article(position.article).getUnit(+quantity)
      : '';

  return (
    <Fragment>
      <IonItemDivider>
        <span>{date ? formatDate(date) : ''}</span>
        {(order?.type ?? '').replace('_', ' ')} {quantity}&nbsp;
        {unit}
      </IonItemDivider>
      <div className="order-container">
        <CardOrder order={order} loading={true} />
      </div>
    </Fragment>
  );
}
