import firebase from 'firebase/compat/app';
import { Location } from './Model';

/********************
 *  CONSTANTS
 * *******************/

export const GOOGLE_MAPS_API_KEY = 'AIzaSyA-_Vq0CNaO7Mjo4zYGMfr92qljkcTsVes';

/********************
 *  MODEL
 * *******************/

export type GPSCoordinates = google.maps.LatLngLiteral;

/********************
 *  FUNCTIONS
 * *******************/

// 0. Main
export function getGpsLocation(callback: PositionCallback) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(callback);
  } else {
    console.warn('Geo Location not supported by browser');
    callback(undefined);
  }
}

// 1. Algorithms
export function getClosestLocation(
  coords: GPSCoordinates,
  locations: Location[],
  thresholdInMeters: number = 1000
): Location {
  if (!coords || !locations) {
    return undefined;
  }

  const locationsWithGeoPoint: Location[] = locations.filter((l) => !!l.geoPoint);

  if (locationsWithGeoPoint.length === 0) {
    return undefined;
  }

  const locationDistanceMap: { [locationId: string]: number } =
    locationsWithGeoPoint.reduce(
      (acc, l: Location) => ({
        ...acc,
        [l.locationId]: calculateDistance(coords, geoPointToGPS(l.geoPoint)),
      }),
      {}
    );

  const [closestLocationId, closestDistance] = Object.entries(locationDistanceMap).sort(
    (a, b) => a[1] - b[1]
  )[0];
  const closestLocation = locations.find((l) => l.locationId === closestLocationId);

  // console.log('EXECUTE getClosestLocation')
  // console.log('Current coordinates:', coords);
  // console.log(`Closest location id:`, closestLocationId);
  // console.log('Closest location coords:', geoPointToGPS(closestLocation.geoPoint));
  // console.log(`Distance to closest location (meters):`, closestDistance);
  // console.log(`threshold (meters):`, thresholdInMeters);

  return closestDistance <= thresholdInMeters ? closestLocation : undefined;
}

function calculateDistance(coords1: GPSCoordinates, coords2: GPSCoordinates): number {
  // generally used geo measurement function
  if (!coords1 || !coords2) {
    return Infinity;
  }

  const { lat: lat1, lng: lon1 } = coords1;
  const { lat: lat2, lng: lon2 } = coords2;

  var R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters
}

// 2. Type convertion
export function gpsToGeoPoint(coords: GPSCoordinates): firebase.firestore.GeoPoint {
  if (!coords) {
    return undefined;
  }
  return new firebase.firestore.GeoPoint(coords.lat, coords.lng);
}

export function geoPointToGPS(geoPoint: firebase.firestore.GeoPoint): GPSCoordinates {
  if (!geoPoint) {
    return undefined;
  }
  const coords: GPSCoordinates = {
    lat: geoPoint.latitude,
    lng: geoPoint.longitude,
  };
  return coords;
}

export function geolocationPositionToGPS(
  position: GeolocationPosition
): GPSCoordinates {
  if (!position) {
    return undefined;
  }
  const coords: GPSCoordinates = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  };
  return coords;
}

// "DDM" stands for "Degrees and Decimal Minutes", a better, human-readable way of expressing GPS coordinates
export function geoPointToDDM(geoPoint: firebase.firestore.GeoPoint): string {
  let { latitude, longitude } = geoPoint;

  const convertToDDM = (decimalDegree: number): string => {
    const degree = Math.floor(decimalDegree);
    const decimalMinute = (decimalDegree - degree) * 60;
    return `${degree}° ${decimalMinute.toFixed(4)}'`;
  };

  const latSuffix = latitude >= 0 ? 'N' : 'S';
  const lonSuffix = longitude >= 0 ? 'E' : 'W';

  return `${convertToDDM(Math.abs(latitude))} ${latSuffix}, ${convertToDDM(
    Math.abs(longitude)
  )} ${lonSuffix}`;
}
