import {
  IonContent,
  IonHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { firestore } from '../../ConfigFirebase';
import { ProductionSiteInspection } from '../../InspectionModel';
import ModalShare from '../../ModalShare';
import { ProductionSiteLocation } from '../../Model';
import { LocationDetailsHeader } from '../../components/location/LocationDetailsHeader/LocationDetailsHeader';
import { LocationInspections } from '../../components/location/LocationInspections/LocationInspections';
import { LocationProperties } from '../../components/location/LocationProperties/LocationProperties';
import { LocationActions } from './LocationActions/LocationActions';
import { useLocationDetailsContainer } from './LocationDetailsContainer.hooks';

interface LDProps {
  field: ProductionSiteLocation;
  associatedPartnerName?: string;
  inspections: ProductionSiteInspection[];
  isLoadingInspections: boolean;
}
export const LocationDetailsContainer = ({
  field,
  inspections,
  associatedPartnerName,
  isLoadingInspections,
}: LDProps) => {
  const { name, geoPoint } = field;

  const {
    isInSharingFlow,
    selectedInspections,
    toggleInspection,
    setIsInSharingFlow,
    isInDeletionFlow,
    onShareCancel,
    onShareAccept,
    showShareModal,
    setShowShareModal,
    shareFieldReport,
    setIsInDeletionFlow,
    onDeletionCancel,
    onDeletionAccept,
    isDeleting,
  } = useLocationDetailsContainer(firestore, field, inspections, associatedPartnerName);

  return (
    <IonPage className="page-lot">
      {isDeleting && <IonLoading isOpen={isDeleting} />}
      <LocationDetailsHeader name={name} geopoint={geoPoint} />
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <div>{name}</div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <LocationProperties
          field={field}
          associatedPartnerName={associatedPartnerName}
        />

        <LocationInspections
          field={field}
          inspections={inspections}
          isLoadingInspections={isLoadingInspections}
          isSelecting={isInSharingFlow || isInDeletionFlow}
          selectedInspections={selectedInspections}
          toggleInspection={toggleInspection}
        />

        <LocationActions
          field={field}
          isInSharingFlow={isInSharingFlow}
          isInDeletionFlow={isInDeletionFlow}
          onShareStart={() => setIsInSharingFlow(true)}
          onShareCancel={onShareCancel}
          onShareAccept={onShareAccept}
          onDeletionStart={() => setIsInDeletionFlow(true)}
          onDeletionCancel={onDeletionCancel}
          onDeletionAccept={onDeletionAccept}
          selectedInspections={selectedInspections}
          hasInspections={inspections.length > 0}
        />

        {showShareModal && (
          <IonModal
            cssClass={'modal-share'}
            isOpen={showShareModal}
            onDidDismiss={() => setShowShareModal(false)}
          >
            <ModalShare
              onCancel={() => setShowShareModal(false)}
              title={'Share field report'}
              onSuccess={shareFieldReport}
              contactId={field.associatedPartnerId}
            />
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};
