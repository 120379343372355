import { IonButton } from '@ionic/react';
import React, { useState, KeyboardEventHandler } from 'react';
import { getValueAndUnitFromLocator, getVerifiedSourceValue } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import {
  AuxmeasurementsInner,
  FloatMeasurement,
  IntMeasurement,
  MeasurablesInner1,
  VerifiedMeasurable,
} from '../generated/openapi/core';
import './CInput.scss';
import { renderUnit } from '../ServiceInspection';
import { useInspectionContext } from '../context/InspectionContextProvider';

const regExpNumber = /^[+-]?((\d*(\.\d*)?)|(\.\d+))$/;

// --------------------------------------------------------------------------------------------------------
// CInput
// --------------------------------------------------------------------------------------------------------
interface ICInputVerification {
  id: string;
  locator: UserInputLocator;
  index: number;
  measurement: AuxmeasurementsInner;
  measurable: MeasurablesInner1;
}
export const CInputVerification = (props: ICInputVerification) => {
  const { id, index, measurement, locator, measurable } = props;
  // console.log('measurement', measurement)
  const { inspection, updateInspection, context } = useInspectionContext();
  const [lastValue, setLastValue] = useState(null);
  const [hasFocus, setHasFocus] = useState(false);

  const { displayedName, valueType, unit } = measurement as
    | FloatMeasurement
    | IntMeasurement;

  const { source } = measurable as VerifiedMeasurable;

  const onBlur = (e) => {
    let value = e.target.value?.length ? e.target.value : null;
    if (valueType === 'float' || valueType === 'int') {
      value = +value;
    }
    // console.log(value, lastValue)
    setHasFocus(false);
    if (value === lastValue) {
      return;
    }
    updateInspection(value, locator);
    // console.log('updatedInspection', updatedInspection)
  };

  const onClick = (e) => {
    const value = valueToVerify;
    if (value === lastValue) {
      return;
    }
    //@ts-ignore
    document.getElementById(id + index).value = value;
    updateInspection(value, locator);
    // console.log('updatedInspection', updatedInspection)
  };

  const onFocus = (e) => {
    const value = e.target.value?.length ? e.target.value : null;
    // console.log(value)
    setHasFocus(true);
    setLastValue(value);
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    // console.log((e.key))

    if (
      [
        'Tab',
        'Backspace',
        'ArrowRight',
        'ArrowDown',
        'ArrowUp',
        'ArrowLeft',
        'Enter',
        '-',
        '.',
        ',',
      ].includes(e.key)
    ) {
      return;
    }

    if (!regExpNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  const defaultValue = getValueAndUnitFromLocator(locator, inspection).value;
  const valueToVerify = getVerifiedSourceValue(context, source);
  // console.log(defaultValue)

  return (
    <div className={`c-input c-input-verification ${displayedName ? 'has-label' : ''}`}>
      {displayedName && <div className="label">{displayedName.trim()}</div>}
      <div className="actual-input">
        <IonButton mode="ios" color="primary" size="default" onClick={onClick}>
          OK
        </IonButton>
        <input
          id={id + index}
          onFocus={onFocus}
          onBlur={onBlur}
          inputMode="numeric"
          type="number"
          // disabled={minValue === maxValue}
          onKeyDown={handleKeyDown}
          className={hasFocus ? 'focus' : ''}
          defaultValue={defaultValue}
        />
        <label htmlFor={id + index} className="unit long">
          of {valueToVerify} {renderUnit(unit)}
        </label>
      </div>
    </div>
  );
};
