import { IonBadge, IonCardHeader, IonCardTitle, IonModal } from '@ionic/react';
import React, { useState, useContext } from 'react';
import { formatDate } from '../HelperUtils';
import {
  InspectionReference,
  LegacyInspectionReference,
  ProductionSiteInspectionReference,
} from '../InspectionModel';
import {
  FieldSchemaObjectTypeEnum,
  InspectableObjectType,
  LotSchemaObjectTypeEnum,
  TransitSchemaObjectTypeEnum,
} from '../generated/openapi/core';
import { Lot, Picture } from '../Model';

// Styling
import '../PageInspection.scss';
import { CardImages } from '../CardImages';
import PageGallery from '../PageGallery';
import { useInspectionContext } from '../context/InspectionContextProvider';
import { ctxLocations, ctxOrg, ctxProfile } from '../App';
import ViewArticleDescription from '../ViewArticleDescription';
import { formatFieldInspectionDate } from '../ServiceInspection';

interface Props {
  objectType: InspectableObjectType;
  reference: InspectionReference;
  loading?: boolean;

  picturesUpdated?: (p: Picture[]) => void;
}

export const CInspectionTitle = ({
  reference,
  objectType,
  picturesUpdated,
  loading = false,
}: Props) => {
  const prodSiteInspectionRef = reference as ProductionSiteInspectionReference;
  const legacyInspectionRef = reference as LegacyInspectionReference;

  const { isEditable, spec: schema, inspection } = useInspectionContext();
  const profile = useContext(ctxProfile);
  const org = useContext(ctxOrg);
  const locations = useContext(ctxLocations);

  const pictures: Picture[] = inspection?.pictures ?? [];

  // State
  const [showPageGallery, setShowPageGallery] = useState<boolean>(false);
  const [showDefectId, setShowDefectId] = useState<string | undefined>();

  // Functionality
  const showGallery = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    defectId?: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (defectId) {
      if (pictures.filter((p) => p.inputIds.includes(defectId)).length <= 0) {
        return;
      }
      setShowDefectId(defectId);
    }
    setShowPageGallery(true);
  };

  const hideGallery = () => {
    setShowPageGallery(false);
    setShowDefectId(undefined);
  };

  const getTitle = (): string => {
    switch (objectType) {
      case LotSchemaObjectTypeEnum.Lot:
        return legacyInspectionRef.lotId;
      case TransitSchemaObjectTypeEnum.Transit:
        return legacyInspectionRef.transportId;
      case FieldSchemaObjectTypeEnum.Field:
        const location = locations.find(
          (l) => l.locationId === prodSiteInspectionRef.locationId
        );
        return location?.name ?? prodSiteInspectionRef.locationId;
      default:
        return '';
    }
  };

  const getDate = () => {
    return objectType === 'field'
      ? formatFieldInspectionDate(reference as ProductionSiteInspectionReference)
      : formatDate(reference.date);
  };

  // Render
  return (
    <div className="card-inspection">
      <div className="card-inspection-wrapper">
        {pictures.length > 0 && (
          <div className="cover" onClick={(e) => showGallery(e)}>
            <CardImages pictures={pictures} max={pictures.length} />
          </div>
        )}
        <IonCardHeader>
          <div>
            <IonCardTitle>{getTitle()}</IonCardTitle>
            <div className="date-report-type">
              <IonBadge color="medium" className="arrival-date">
                {getDate()}
              </IonBadge>
            </div>
            {!!(inspection?.objectType === LotSchemaObjectTypeEnum.Lot) && (
              <div className="lot-article-description">
                {loading || !inspection?.lotProperties?.article ? (
                  <div className="view-article-description">
                    <div className="article-name">Product</div>
                    <div className="article-badges">
                      <IonBadge color="tertiary">YY-A</IonBadge>
                      <IonBadge color="tertiary">XX-C</IonBadge>
                      <IonBadge color="tertiary">ZZ-B</IonBadge>
                    </div>
                  </div>
                ) : (
                  <ViewArticleDescription
                    article={inspection.lotProperties.article}
                    pills={true}
                  />
                )}
              </div>
            )}
          </div>
        </IonCardHeader>
      </div>
      {showPageGallery && (
        <IonModal isOpen={showPageGallery} onDidDismiss={hideGallery}>
          <PageGallery
            editable={isEditable}
            editMode={'assessment'}
            pictures={pictures}
            showDefectId={showDefectId}
            inspection={inspection}
            schema={schema}
            profile={profile}
            onPicturesUpdated={picturesUpdated}
            onDismiss={() => hideGallery()}
            inspectionReference={inspection.reference}
            orgSettings={org?.settings}
          />
        </IonModal>
      )}
    </div>
  );
};
