import { toastController } from '@ionic/core';
import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonSpinner,
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import { trashOutline } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import Select from 'react-select';
import { ctxContacts, ctxLocations, ctxOrg, ctxProducts, ctxProfile } from './App';
import { firestore } from './ConfigFirebase';
import {
  deleteOrganizationLocation,
  setOrganizationLocation,
  updateOrganizationLocation,
} from './DataAdmin';
import { geoPointToDDM } from './DataLocation';
import { presentStandardToast } from './HelperIonic';
import { defaultSelectProps } from './HelperReactSelect';
import {
  ProductionSiteLocation,
  Location,
  LocationType,
  LocationTypes,
  WarehouseLocation,
  productionSiteExclusiveKeys,
  warehouseExclusiveKeys,
  FieldStorageConditions,
} from './Model';
import CMapPicker from './components/CMapPicker';
import { useLocationAdminOptions } from './hooks/useLocationAdminOptionsOld';
import ModalAddLocation from './modals/ModalAddLocation';
import { ClearableDateTime } from './components/ClearableDateTime';
import { clamp } from 'lodash';

export default function ComponentLocationsAdmin() {
  //-------------------------------------------
  // Refs
  const nameInputRefs = useRef<{
    [key: string]: React.RefObject<HTMLIonInputElement>;
  }>({});

  //-------------------------------------------
  // Context
  const locations = useContext(ctxLocations);
  const profile = useContext(ctxProfile);
  const orgProducts = useContext(ctxProducts);
  const orgContacts = useContext(ctxContacts);
  const org = useContext(ctxOrg);

  //-------------------------------------------
  // State
  const [creationModal, setCreationModal] = useState<boolean>(false);
  const [focusedLocation, setFocusedLocation] = useState<Location | undefined>();
  const [geoPointFieldKey, setGeoPointFieldKey] = useState<
    keyof ProductionSiteLocation | undefined
  >();

  //-------------------------------------------
  // Options
  const {
    countryOptions,
    varietyOptions,
    servicesOptions,
    locationTypeOptions,
    productionTypeOptions,
    fieldManagementOptions,
    booleanOptions,
    irrigationSystemOptions,
    associatedPartnerOptions,
    selectedLocationType,
    selectedCountry,
    selectedServices,
    selectedVarieties,
    selectedProductionType,
    selectedFieldManagementOption,
    selectedBoolean,
    selectedIrrigationSystems,
    selectedAssociatedPartner,
    getLabel,
  } = useLocationAdminOptions(orgProducts, orgContacts, org);

  //-------------------------------------------
  // Handlers
  async function handleInputOnChange<L extends Location>(
    locationId: string,
    attribute: keyof L,
    data: L[keyof L]
  ) {
    if (attribute === 'name') {
      // we make sure the the name is unique
      const locationWithSameName: Location | undefined = locations.find(
        (l) => l.name === data && l.locationId !== locationId
      );

      if (!!locationWithSameName) {
        // reset the ion-input to the name saved in the db
        if (nameInputRefs.current[locationId]?.current) {
          nameInputRefs.current[locationId].current.value = locations.find(
            (l) => l.locationId === locationId
          ).name as string;
        }
        // display message
        return presentStandardToast(
          toastController,
          `Location with name '${locationWithSameName.name}' already exists.`
        );
      }
    }
    await updateOrganizationLocation(
      firestore,
      profile.organisationId,
      locationId,
      attribute,
      data
    );
  }

  async function handleSelectOnChange<L extends Location>(
    locationId: string,
    attribute: keyof L,
    selectData: L[keyof L]
  ) {
    await updateOrganizationLocation(
      firestore,
      profile.organisationId,
      locationId,
      attribute,
      selectData
    );
  }

  async function handleLocationTypeChange(id: string, newType: LocationType) {
    const location = locations.find((l) => l.locationId === id);

    // TODO DEV-1830: list the fields to be deleted in a human readable format
    if (
      location.locationType != null &&
      location.locationType !== newType &&
      window.confirm(
        `Changing the location type will trigger the deletion of some ${
          selectedLocationType(location.locationType)?.label ?? location.locationType
        } specific data. Are you sure you want to continue?`
      )
    ) {
      location.locationType = newType;
      // Remove fields that don't belong to the current type
      if (location.locationType !== LocationTypes.ProductionSite) {
        productionSiteExclusiveKeys.forEach((k) => delete location[k]);
      }
      if (location.locationType !== LocationTypes.Warehouse) {
        warehouseExclusiveKeys.forEach((k) => delete location[k]);
      }

      await setOrganizationLocation(firestore, profile, location);
    }
  }

  async function handleDeleteLocation(id: string) {
    const text = `Are you sure you want to delete contact ${
      locations.find((l) => l.locationId === id)?.name ?? id
    } (id ${id})}? This action cannot be undone`;
    if (window.confirm(text))
      await deleteOrganizationLocation(firestore, profile.organisationId, id);
  }

  const closeGpsModal = () => {
    setFocusedLocation(undefined);
    setGeoPointFieldKey(undefined);
  };

  //-------------------------------------------
  // Render

  interface BLProps {
    label: keyof WarehouseLocation | keyof ProductionSiteLocation;
  }
  const BoldLabel = ({ label }: BLProps) => (
    <IonLabel>
      <b>{getLabel(label)}</b>
    </IonLabel>
  );

  return (
    <div className="component-locations-admin">
      <div className="table-list align-top double-row">
        {!locations && <IonSpinner name="dots" />}

        {locations
          .sort((a, b) => (a.locationId > b.locationId ? 1 : -1))
          .map((location) => {
            const { locationType, countryCode, locationId, geoPoint, name, address } =
              location;

            if (!nameInputRefs.current[locationId]) {
              nameInputRefs.current[locationId] =
                React.createRef<HTMLIonInputElement>();
            }

            const { services } = location as WarehouseLocation;
            const {
              producedVarieties,
              packhouseGeoPoint,
              productionType,
              fieldManagementQuality,
              hasCoolingFacilities,
              irrigationSystems,
              growingAreaInHa,
              totalAreaInHa,
              associatedPartnerId,
            } = location as ProductionSiteLocation;

            return (
              <div key={locationId}>
                <div>
                  <div className="name">
                    <BoldLabel label="locationId" />
                    <div className="id-value">{locationId}</div>
                  </div>
                  <div className="name">
                    <BoldLabel label="name" />
                    <IonInput
                      ref={nameInputRefs.current[locationId]}
                      className="partners-input"
                      value={name}
                      debounce={500}
                      onIonChange={(e) => {
                        handleInputOnChange(locationId, 'name', e.detail.value);
                      }}
                    />
                  </div>
                  <div className="item-selectable">
                    <BoldLabel label="locationType" />
                    <Select
                      {...defaultSelectProps}
                      options={locationTypeOptions}
                      value={selectedLocationType(locationType)}
                      onChange={(e) => handleLocationTypeChange(locationId, e.value)}
                    />
                  </div>

                  <div className="item-selectable">
                    <BoldLabel label="associatedPartnerId" />
                    <Select
                      {...defaultSelectProps}
                      options={associatedPartnerOptions}
                      defaultValue={associatedPartnerOptions[0]}
                      value={selectedAssociatedPartner(associatedPartnerId)}
                      isClearable={false}
                      onChange={(e) =>
                        handleSelectOnChange(locationId, 'associatedPartnerId', e.value)
                      }
                    />
                  </div>

                  <div className="actions">
                    <span
                      title="remove location"
                      className="action-button delete"
                      onClick={(_) => handleDeleteLocation(locationId)}
                    >
                      <IonIcon icon={trashOutline} />
                    </span>
                  </div>
                </div>

                <div
                  className="view-more"
                  onClick={(e) => {
                    e.currentTarget.classList.toggle('less');
                    // @ts-ignore
                    e.currentTarget.nextSibling.classList?.toggle('visible');
                  }}
                >
                  view more{' '}
                </div>

                <div className="more grid-list">
                  <div className="item-selectable">
                    <BoldLabel label="countryCode" />
                    <Select
                      {...defaultSelectProps}
                      options={countryOptions}
                      defaultValue={selectedCountry(countryCode)}
                      onChange={(e) =>
                        handleSelectOnChange(locationId, 'countryCode', e.value)
                      }
                    />
                  </div>
                  <div className="name">
                    <BoldLabel label="address" />
                    <IonInput
                      className="partners-input"
                      value={address}
                      debounce={500}
                      onIonChange={(e) =>
                        handleInputOnChange(locationId, 'address', e.detail.value)
                      }
                    />
                  </div>
                  <div
                    className="name"
                    onClick={() => {
                      setFocusedLocation(location);
                      setGeoPointFieldKey('geoPoint');
                    }}
                  >
                    <BoldLabel label="geoPoint" />

                    <div className="gps-coords">
                      {geoPoint != null ? geoPointToDDM(geoPoint) : 'Not set'}
                    </div>
                  </div>

                  {/* Type specific fields */}
                  {locationType === LocationTypes.Warehouse && (
                    <>
                      <div className="item-selectable">
                        <BoldLabel label="services" />
                        <Select
                          {...defaultSelectProps}
                          options={servicesOptions}
                          isMulti
                          defaultValue={selectedServices(services)}
                          isClearable={true}
                          onChange={(e) =>
                            handleSelectOnChange<WarehouseLocation>(
                              locationId,
                              'services',
                              e?.map((v) => v.value) ?? []
                            )
                          }
                        />
                      </div>
                    </>
                  )}

                  {locationType === LocationTypes.ProductionSite && (
                    <>
                      <div className="item-selectable">
                        <BoldLabel label="productionType" />
                        <Select
                          {...defaultSelectProps}
                          options={productionTypeOptions}
                          defaultValue={selectedProductionType(productionType)}
                          isClearable={false}
                          onChange={(e) =>
                            handleSelectOnChange<ProductionSiteLocation>(
                              locationId,
                              'productionType',
                              e?.value
                            )
                          }
                        />
                      </div>

                      <div className="item-selectable">
                        <BoldLabel label="fieldManagementQuality" />
                        <Select
                          {...defaultSelectProps}
                          options={fieldManagementOptions}
                          defaultValue={selectedFieldManagementOption(
                            fieldManagementQuality
                          )}
                          isClearable={false}
                          onChange={(e) =>
                            handleSelectOnChange<ProductionSiteLocation>(
                              locationId,
                              'fieldManagementQuality',
                              e?.value
                            )
                          }
                        />
                      </div>

                      <div className="item-selectable">
                        <BoldLabel label="producedVarieties" />
                        <Select
                          {...defaultSelectProps}
                          options={varietyOptions}
                          isMulti
                          defaultValue={selectedVarieties(producedVarieties)}
                          isClearable={true}
                          onChange={(e) =>
                            handleSelectOnChange<ProductionSiteLocation>(
                              locationId,
                              'producedVarieties',
                              e?.map((v) => v.value) ?? []
                            )
                          }
                        />
                      </div>

                      {/* Rendered as select to not break the layout prattern */}
                      <div className="item-selectable">
                        <BoldLabel label="hasCoolingFacilities" />
                        <Select
                          {...defaultSelectProps}
                          options={booleanOptions}
                          defaultValue={selectedBoolean(!!hasCoolingFacilities)}
                          onChange={(e) =>
                            handleSelectOnChange<ProductionSiteLocation>(
                              locationId,
                              'hasCoolingFacilities',
                              e.value
                            )
                          }
                        />
                      </div>

                      <div className="item-selectable">
                        <BoldLabel label="irrigationSystems" />
                        <Select
                          {...defaultSelectProps}
                          options={irrigationSystemOptions}
                          isMulti
                          defaultValue={selectedIrrigationSystems(irrigationSystems)}
                          isClearable={true}
                          onChange={(e) =>
                            handleSelectOnChange<ProductionSiteLocation>(
                              locationId,
                              'irrigationSystems',
                              e?.map((v) => v.value) ?? []
                            )
                          }
                        />
                      </div>

                      <div className="name">
                        <BoldLabel label="totalAreaInHa" />
                        <IonInput
                          className="partners-input"
                          value={totalAreaInHa}
                          debounce={500}
                          type="number"
                          inputMode="decimal"
                          onIonChange={(e) =>
                            handleInputOnChange<ProductionSiteLocation>(
                              locationId,
                              'totalAreaInHa',
                              (e.detail.value ?? '').length
                                ? +e.detail.value
                                : undefined
                            )
                          }
                        />
                      </div>

                      <div className="name">
                        <BoldLabel label="growingAreaInHa" />
                        <IonInput
                          className="partners-input"
                          value={growingAreaInHa}
                          debounce={500}
                          type="number"
                          inputMode="decimal"
                          onIonChange={(e) =>
                            handleInputOnChange<ProductionSiteLocation>(
                              locationId,
                              'growingAreaInHa',
                              (e.detail.value ?? '').length
                                ? +e.detail.value
                                : undefined
                            )
                          }
                        />
                      </div>

                      <div
                        className="name"
                        onClick={() => {
                          setFocusedLocation(location);
                          setGeoPointFieldKey('packhouseGeoPoint');
                        }}
                      >
                        <BoldLabel label="packhouseGeoPoint" />
                        <div className="gps-coords">
                          {packhouseGeoPoint != null
                            ? geoPointToDDM(packhouseGeoPoint)
                            : 'Not set'}
                        </div>
                      </div>

                      {/* We define it as a separate component to make the merge with feature/admin easier */}
                      <ExtraFieldComponents
                        location={location}
                        handleInputOnChange={handleInputOnChange}
                        handleSelectOnChange={handleSelectOnChange}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {!!focusedLocation && !!geoPointFieldKey && (
        <IonModal
          isOpen={!!focusedLocation}
          onDidDismiss={() => closeGpsModal()}
          cssClass={'map-picker-modal'}
        >
          <CMapPicker
            onAccept={(g: firebase.firestore.GeoPoint) => {
              handleInputOnChange(focusedLocation.locationId, geoPointFieldKey, g);
              closeGpsModal();
            }}
            initialGeoPoint={focusedLocation[geoPointFieldKey]}
            onCancel={() => closeGpsModal()}
          />
        </IonModal>
      )}

      <IonButton
        slot="fixed"
        color="tertiary"
        className="main-button"
        onClick={(_) => {
          setCreationModal(true);
        }}
      >
        Add Location
      </IonButton>

      <IonModal
        isOpen={creationModal || false}
        onDidDismiss={(_) => setCreationModal(false)}
      >
        <ModalAddLocation onClose={() => setCreationModal(false)} />
      </IonModal>
    </div>
  );
}

// We define it as a separate component to make the merge with feature/admin easier
interface EFCProps {
  location: ProductionSiteLocation;
  handleInputOnChange<L extends Location>(
    locationId: string,
    attribute: keyof L,
    data: L[keyof L]
  ): Promise<void>;
  handleSelectOnChange<L extends Location>(
    locationId: string,
    attribute: keyof L,
    selectData: L[keyof L]
  ): Promise<void>;
}
const ExtraFieldComponents = ({
  handleInputOnChange,
  handleSelectOnChange,
  location,
}: EFCProps) => {
  //-------------------------------------------
  // Context & Ref
  const orgProducts = useContext(ctxProducts);
  const orgContacts = useContext(ctxContacts);
  const org = useContext(ctxOrg);

  //-------------------------------------------
  // Options
  const {
    getLabel,
    growingTypeOptions,
    selectedGrowingType,
    substrateTypeOptions,
    selectedSubstrateType,
    growingMethodOptions,
    selectedGrowingMethod,
    timeOptions,
    selectedTime,
    fieldStorageConditionOptions,
    selectedFieldStorageConditions,
    booleanOptions,
    selectedBoolean,
  } = useLocationAdminOptions(orgProducts, orgContacts, org);

  const {
    growingType,
    locationId,
    substrateType,
    growingMethodType,
    maxMinutesOnFieldBeforeTransportation,
    maxPrecoolingTime,
    refrigeratedTransporter,
    datePlanted,
    productionWeekEnd,
    productionWeekStart,
    plantsPerHa,
    kgProducedPerPlant,
    fieldStorageConditions,
    refrigerationTemperature,
  } = location;

  interface BLProps {
    label: keyof WarehouseLocation | keyof ProductionSiteLocation;
  }
  const BoldLabel = ({ label }: BLProps) => (
    <IonLabel>
      <b>{getLabel(label)}</b>
    </IonLabel>
  );

  return (
    <>
      <div className="item-selectable">
        <BoldLabel label="growingType" />
        <Select
          {...defaultSelectProps}
          options={growingTypeOptions}
          defaultValue={selectedGrowingType(growingType)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'growingType',
              e?.value
            )
          }
        />
      </div>
      <div className="item-selectable">
        <BoldLabel label="substrateType" />
        <Select
          {...defaultSelectProps}
          options={substrateTypeOptions}
          defaultValue={selectedSubstrateType(substrateType)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'substrateType',
              e?.value
            )
          }
        />
      </div>
      <div className="item-selectable">
        <BoldLabel label="growingMethodType" />
        <Select
          {...defaultSelectProps}
          options={growingMethodOptions}
          defaultValue={selectedGrowingMethod(growingMethodType)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'growingMethodType',
              e?.value
            )
          }
        />
      </div>
      <div className="item-selectable">
        <BoldLabel label="maxMinutesOnFieldBeforeTransportation" />
        <Select
          {...defaultSelectProps}
          options={timeOptions}
          defaultValue={selectedTime(maxMinutesOnFieldBeforeTransportation)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'maxMinutesOnFieldBeforeTransportation',
              e?.value
            )
          }
        />
      </div>
      <div className="item-selectable">
        <BoldLabel label="maxPrecoolingTime" />
        <Select
          {...defaultSelectProps}
          options={timeOptions}
          defaultValue={selectedTime(maxPrecoolingTime)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'maxPrecoolingTime',
              e?.value
            )
          }
        />
      </div>
      <div className="item-selectable">
        <BoldLabel label="refrigeratedTransporter" />
        <Select
          {...defaultSelectProps}
          options={booleanOptions}
          defaultValue={selectedBoolean(refrigeratedTransporter)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'refrigeratedTransporter',
              e?.value
            )
          }
        />
      </div>
      <div className="name">
        <BoldLabel label="datePlanted" />
        <ClearableDateTime
          dateValue={datePlanted}
          onChange={(d: Date | undefined) =>
            handleInputOnChange<ProductionSiteLocation>(locationId, 'datePlanted', d)
          }
        />
      </div>
      <div className="name">
        <BoldLabel label="productionWeekStart" />
        <IonInput
          className="partners-input"
          value={productionWeekStart}
          debounce={500}
          type="number"
          inputMode="numeric"
          placeholder='(optional)'
          onIonChange={(e) => {
            let value = (e.detail.value ?? '').length
              ? clamp(+e.detail.value, 1, 52)
              : undefined;

            handleInputOnChange<ProductionSiteLocation>(
              locationId,
              'productionWeekStart',
              value
            );
          }}
        />
      </div>
      <div className="name">
        <BoldLabel label="productionWeekEnd" />
        <IonInput
          className="partners-input"
          value={productionWeekEnd}
          debounce={500}
          type="number"
          inputMode="numeric"
          placeholder='(optional)'
          onIonChange={(e) => {
            let value = (e.detail.value ?? '').length
              ? clamp(+e.detail.value, 1, 52)
              : undefined;

            handleInputOnChange<ProductionSiteLocation>(
              locationId,
              'productionWeekEnd',
              value
            );
          }}
        />
      </div>
      <div className="name">
        <BoldLabel label="plantsPerHa" />
        <IonInput
          className="partners-input"
          value={plantsPerHa}
          debounce={500}
          type="number"
          inputMode="numeric"
          placeholder='(optional)'
          onIonChange={(e) => {
            let value = (e.detail.value ?? '').length
              ? clamp(+e.detail.value, 0, Infinity)
              : undefined;

            handleInputOnChange<ProductionSiteLocation>(
              locationId,
              'plantsPerHa',
              value
            );
          }}
        />
      </div>
      <div className="name">
        <BoldLabel label="kgProducedPerPlant" />
        <IonInput
          className="partners-input"
          value={kgProducedPerPlant}
          debounce={500}
          type="number"
          inputMode="decimal"
          placeholder='(optional)'
          onIonChange={(e) => {
            let value = (e.detail.value ?? '').length
              ? clamp(+e.detail.value, 0, Infinity)
              : undefined;

            handleInputOnChange<ProductionSiteLocation>(
              locationId,
              'kgProducedPerPlant',
              value
            );
          }}
        />
      </div>
      <div className="item-selectable">
        <BoldLabel label="fieldStorageConditions" />
        <Select
          {...defaultSelectProps}
          options={fieldStorageConditionOptions}
          defaultValue={selectedFieldStorageConditions(fieldStorageConditions)}
          isClearable={true}
          onChange={(e) =>
            handleSelectOnChange<ProductionSiteLocation>(
              locationId,
              'fieldStorageConditions',
              e?.value
            )
          }
        />
      </div>
      {fieldStorageConditions === FieldStorageConditions.Refrigerator && (
        <div className="name">
          <BoldLabel label="refrigerationTemperature" />
          <IonInput
            className="partners-input"
            value={refrigerationTemperature}
            debounce={500}
            type="number"
            inputMode="decimal"
            placeholder="(optional)"
            onIonChange={(e) => {
              let value = (e.detail.value ?? '').length ? +e.detail.value : undefined;

              handleInputOnChange<ProductionSiteLocation>(
                locationId,
                'refrigerationTemperature',
                value
              );
            }}
          />
        </div>
      )}
    </>
  );
};
