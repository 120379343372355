import React, { useContext, createContext, PropsWithChildren } from 'react';
import {
  Inspection,
  InspectionContext as IInspectionContext,
  InspectionError,
  InspectionReference,
  InspectionStatus,
  UserInputLocator,
} from '../InspectionModel';
import { GPSCoordinates } from '../DataLocation';
import { InspectionSpec, LotScoringSection } from '../ModelSpecification';

export interface InspectionCtxInterface {
  // State
  inspection: Inspection | undefined;
  inspectionReference?: InspectionReference;
  spec: InspectionSpec | undefined;
  context?: IInspectionContext;
  currGPSCoordinates?: GPSCoordinates;
  errors?: InspectionError[];
  applicableSpecs?: InspectionSpec[];

  // Setters
  setContext?: (value: React.SetStateAction<IInspectionContext>) => void;
  setInspection?: (value: React.SetStateAction<Inspection>) => void;
  setScoring?: (value: React.SetStateAction<LotScoringSection>) => void;
  setSpec?: (value: React.SetStateAction<InspectionSpec>) => void;
  setErrors?: (value: React.SetStateAction<InspectionError[]>) => void;
  setApplicableSpecs?: (value: React.SetStateAction<InspectionSpec[]>) => void;

  // Business logic
  updateInspection?: (
    value: any,
    inputLocator: UserInputLocator
  ) => { updatedContext: IInspectionContext; updatedInspection: Inspection };
  retrieveBackupInspection?: (
    ref: InspectionReference
  ) => Promise<Inspection | undefined>;
  saveInspectionInDB?: (inspectionStatus: InspectionStatus) => Promise<void>;

  // Other relevant variables/flags
  supplyChainLot?: boolean;
  isLocked?: boolean;
  isEditable?: boolean;
  inited?: boolean;
  renderedQuestionIds?: React.MutableRefObject<string[]>;
  isPreview?: boolean;
  isPreviewEditable?: boolean;
}

export const defaultInspectionSetters = {
  setContext: () => {},
  setInspection: () => {},
  setScoring: () => {},
  setSpec: () => {},
  setErrors: () => {},
  setApplicableSpecs: () => {},
  updateInspection: () => ({} as any),
  retrieveBackupInspection: async () => ({} as Inspection),
  saveInspectionInDB: async () => {},
};

export const InspectionContext = createContext<InspectionCtxInterface>({
  spec: undefined,
  inspection: undefined,
  ...defaultInspectionSetters,
});

export function useInspectionContext(): InspectionCtxInterface {
  return useContext(InspectionContext);
}

export function InspectionContextProvider({
  children,
  ...rest
}: PropsWithChildren<InspectionCtxInterface>) {
  return (
    <InspectionContext.Provider value={{ ...defaultInspectionSetters, ...rest }}>
      {children}
    </InspectionContext.Provider>
  );
}
