import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToolbar,
} from '@ionic/react';
import {
  checkmarkCircle,
  chevronDown,
  chevronForward,
  closeOutline,
  trashBin,
} from 'ionicons/icons';
import React, { createRef, Fragment } from 'react';
import { Inspection, LegacyInspection } from './InspectionModel';
import { Picture } from './Model';
import { InspectionSpec, SectionNames } from './ModelSpecification';
import { InspectionSpecSection, InspectionSpecSubsection } from './generated/openapi/core';
import './PagePicture.scss';
import i18n from './ServiceI18n';
import { getLayoutFilteredByTaggable } from './ServiceInspection';
import ViewPicture from './ViewPicture';

export interface Props {
  editable: boolean;
  picture?: Picture;
  pictures?: Picture[];

  schema?: InspectionSpec;
  inspection?: Inspection;

  multi?: string[];
  editMode?: 'report' | 'assessment';

  onDismiss: Function;
  onPictureSelected?: Function;
  onPictureUpdated?: Function;
  onPictureRemoved?: Function;
}

interface State {
  showRemovePictureAlert: boolean;
  toggleMap: any;
  groupId: any;
  inputIds: string[];
}

class PagePictureMulti extends React.Component<Props, State> {
  private contentRef = createRef<HTMLIonContentElement>();
  private popstate: any;
  private goBack = true;
  private mounted: boolean;

  constructor(props) {
    super(props);

    // const href = window.location.href
    // window.history.pushState({cameraBack: true}, '', href)

    // this.popstate = (event) => {
    //   this.goBack = false
    //   this.props.onDismiss()
    // }
    // window.addEventListener('popstate', this.popstate, false);

    this.state = {
      showRemovePictureAlert: false,
      groupId: undefined,
      inputIds: [],
      toggleMap: {},
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    // window.removeEventListener('popstate', this.popstate, false);
    // if (this.goBack) window.history.back()
  }

  updateGroupId(section: InspectionSpecSection) {
    const data = {
      sectionId: section.sectionType,
      sectionName: SectionNames[section.sectionType] ?? section.sectionType,
      imageTag: section.imageTag,
      inputIds: [],
    };

    if (this.mounted) this.setState({ groupId: data.sectionId, inputIds: [] });
    this.props.onPictureUpdated({ ...data, pics: this.props.multi });
  }

  removePicture() {
    this.props.onPictureRemoved(this.props.multi);
  }

  private showRemovePictureAlert() {
    if (this.mounted) this.setState({ ...this.state, showRemovePictureAlert: true });
  }

  private hideRemovePictureAlert() {
    if (this.mounted) this.setState({ ...this.state, showRemovePictureAlert: false });
  }

  updateToggle(id: string) {
    const toggleMap = { ...this.state.toggleMap };
    toggleMap[id] = toggleMap[id] === undefined ? false : !toggleMap[id];
    if (this.mounted) this.setState({ toggleMap: toggleMap });
    console.log(toggleMap);
  }

  onDefectChange(defectId: string, selected: boolean) {
    if (!this.mounted) {
      return;
    }
    const { inputIds } = this.state;

    let res = inputIds;

    if (selected) {
      if (inputIds.indexOf(defectId) < 0) {
        res.push(defectId);
      }
    } else {
      res = inputIds.filter((def) => def !== defectId);
    }
    this.setState({ inputIds: res });
    this.props.onPictureUpdated({
      pics: this.props.multi,
      sectionId: this.state.groupId,
      inputIds: res,
    });
  }

  renderToggle(id: string, toggle: boolean) {
    if (toggle) {
      return <IonIcon icon={chevronDown} onClick={(_) => this.updateToggle(id)} />;
    }
    return <IonIcon icon={chevronForward} onClick={(_) => this.updateToggle(id)} />;
  }

  renderDefectNode(defectId: string, level: number) {
    const node = this.props.schema.questionSpecs[defectId];
    if (!node || !node.inspectionProperties?.taggable) {
      return;
    }

    const { inputIds } = this.state;

    return (
      <IonItem
        key={defectId}
        className={'level-' + level}
        data-tip={`multitag-question-${defectId}`}
      >
        <IonLabel className="ion-text-wrap">{node.displayedName}</IonLabel>
        <IonCheckbox
          disabled={!this.props.editable}
          color="danger"
          slot="end"
          checked={inputIds.indexOf(defectId) >= 0}
          onIonChange={(evt) => this.onDefectChange(defectId, evt.detail.checked)}
        />
      </IonItem>
    );
  }

  renderNode(node: InspectionSpecSection, level: number) {
    let open = this.state.toggleMap[node.sectionType] ?? true;

    const questionIds = (node?.layout ?? []).filter(
      (n) => typeof n === 'string'
    ) as string[];
    const subsections = (node?.layout ?? []).filter(
      (n) => typeof n !== 'string'
    ) as InspectionSpecSubsection[];

    return (
      <Fragment key={node.name}>
        {level === 0 && (
          <IonListHeader
            onClick={(_) => this.updateToggle(node.sectionType)}
            data-tip={`multitag-group-${node.sectionType}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h1>{node.name}</h1>
            </IonLabel>
          </IonListHeader>
        )}
        {level === 1 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.sectionType)}
            data-tip={`multitag-group-${node.sectionType}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h2>{node.name}</h2>
            </IonLabel>
          </IonItem>
        )}
        {level === 2 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.sectionType)}
            data-tip={`multitag-group-${node.sectionType}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h4>{node.name}</h4>
            </IonLabel>
          </IonItem>
        )}

        {open &&
          subsections.length > 0 &&
          subsections.map((child) => this.renderSecondaryNode(child, level + 1))}
        {open &&
          questionIds?.length > 0 &&
          questionIds.map((defectId) => this.renderDefectNode(defectId, level))}
      </Fragment>
    );
  }

  renderSecondaryNode(node: InspectionSpecSubsection, level: number) {
    let open = this.state.toggleMap[node.name] ?? true;

    return (
      <Fragment key={node.name}>
        {level === 0 && (
          <IonListHeader
            onClick={(_) => this.updateToggle(node.name)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h1>{node.name}</h1>
            </IonLabel>
          </IonListHeader>
        )}
        {level === 1 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.name)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h2>{node.name}</h2>
            </IonLabel>
          </IonItem>
        )}
        {level === 2 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.name)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h4>{node.name}</h4>
            </IonLabel>
          </IonItem>
        )}

        {open &&
          node?.questionIds?.length > 0 &&
          node.questionIds.map((defectId) => this.renderDefectNode(defectId, level))}
      </Fragment>
    );
  }

  render() {
    const groupNode = this.props?.schema?.layout?.find(
      (node) => node.sectionType === this.state.groupId
    );
    // console.log(this.state.groupId, groupNode, this.props.schema.layout)
    return (
      <div className="page-picture ion-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                data-tip={'multitag-close'}
                onClick={(_) => this.props.onDismiss()}
                color="white"
              >
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          ref={this.contentRef}
          className={'multitag ' + (this.props.editable ? 'editable' : 'no-editable')}
        >
          <IonAlert
            isOpen={this.state.showRemovePictureAlert}
            onDidDismiss={() => this.hideRemovePictureAlert()}
            header={i18n.t('PagePicture.confirm')}
            message="Do you really want to remove the selected pictures?"
            buttons={[
              {
                text: i18n.t('General.no'),
                role: 'cancel',
                cssClass: 'medium',
                handler: () => {},
              },
              {
                text: i18n.t('General.yes'),
                cssClass: 'danger-button primary',
                handler: () => {
                  this.removePicture();
                },
              },
            ]}
          />
          <div className={'image-area multi p' + this.props.multi?.length}>
            {this.props.multi?.map((picture) => (
              <ViewPicture id={picture} key={picture}></ViewPicture>
            ))}
          </div>
          {this.props.editable ? (
            <IonCard className="image-meta">
              <IonList className={'image-tags edit-mode-' + this.props.editMode}>
                {groupNode && (
                  <div className="view-defect-node">
                    {this.renderNode(groupNode, -1)}
                  </div>
                )}

                {this.props.schema?.layout && (
                  <div className="chip-list">
                    {getLayoutFilteredByTaggable(this.props.schema).map(
                      (node, index) => (
                        <div
                          key={index}
                          data-tip={`multitag-group-${node.sectionType}`}
                          onClick={(_) => this.updateGroupId(node)}
                          className={
                            'ion-chip ' +
                            (this.state.groupId === node.sectionType && ' selected')
                          }
                        >
                          <IonLabel>
                            {SectionNames[node.sectionType] ?? node.sectionType}
                          </IonLabel>
                        </div>
                      )
                    )}
                  </div>
                )}
              </IonList>
              <div className="bottom-buttons">
                <IonButton
                  color="primary"
                  fill="solid"
                  data-tip={`multitag-accept`}
                  onClick={(_) => this.props.onDismiss()}
                >
                  <IonIcon icon={checkmarkCircle} />
                </IonButton>
                <IonButton
                  color="medium"
                  fill="clear"
                  data-tip={`multitag-delete`}
                  onClick={(_) => this.showRemovePictureAlert()}
                >
                  <IonIcon icon={trashBin} />
                </IonButton>
              </div>
            </IonCard>
          ) : (
            ''
          )}
        </IonContent>
      </div>
    );
  }
}

export default PagePictureMulti;
