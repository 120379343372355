import { toastController, alertController } from '@ionic/core';
import firebase from 'firebase/compat/app';
import { isEqual } from 'lodash';
import { useContext, useState } from 'react';
import {
  ProductionSiteInspection,
  ProductionSiteInspectionReference,
} from '../../InspectionModel';
import { ProductionSiteLocation } from '../../Model';
import { addSharedFieldReport } from '../../data/sharedFieldReport/sharedFieldReport.service';

import { ctxOrg, ctxProfile } from '../../App';
import { presentStandardToast } from '../../HelperIonic';
import { deleteFieldInspectionsById } from '../../data/fieldInspection/fieldInspection.service';
import { applicationStateObservable } from '../../simpleObservable/observables';

interface UseLocationDetailsContainer {
  selectedInspections: ProductionSiteInspection[];
  toggleInspection: (r: ProductionSiteInspectionReference) => void;
  isInSharingFlow: boolean;
  setIsInSharingFlow: (v: boolean) => void;
  isInDeletionFlow: boolean;
  setIsInDeletionFlow: (v: boolean) => void;
  onShareCancel: () => void;
  onShareAccept: () => void;
  onDeletionCancel: () => void;
  onDeletionAccept: () => Promise<void>;
  showShareModal: boolean;
  isDeleting: boolean;
  setShowShareModal: (v: boolean) => void;
  shareFieldReport: (res: { emails: string[]; message: string }) => Promise<void>;
}

export const useLocationDetailsContainer = (
  firestore: firebase.firestore.Firestore,
  field: ProductionSiteLocation,
  inspections: ProductionSiteInspection[],
  associatedPartnerName?: string
): UseLocationDetailsContainer => {
  // Context
  const org = useContext(ctxOrg);
  const profile = useContext(ctxProfile);
  const { networkEnabled } = applicationStateObservable.getValue();

  // State
  const [isInSharingFlow, setIsInSharingFlow] = useState<boolean>(false);
  const [isInDeletionFlow, setIsInDeletionFlow] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [selectedInspections, setSelectedInspections] = useState<
    ProductionSiteInspection[]
  >([]);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  // Handlers
  const toggleInspection = (reference: ProductionSiteInspectionReference) => {
    const hasSameRef = (i: ProductionSiteInspection) => isEqual(i.reference, reference);
    if (selectedInspections.some(hasSameRef)) {
      setSelectedInspections((curr) => curr.filter((i) => !hasSameRef(i)));
    } else {
      setSelectedInspections((curr) => [...curr, inspections.find(hasSameRef)]);
    }
  };

  const onShareCancel = () => {
    setIsInSharingFlow(false);
    setSelectedInspections([]);
  };

  const onShareAccept = () => {
    setShowShareModal(true);
  };

  const shareFieldReport = async (res: { emails: string[]; message: string }) => {
    try {
      if (!networkEnabled) {
        await presentStandardToast(
          toastController,
          `No internet connection. Please wait until you are back online`
        );
      } else {
        await addSharedFieldReport(
          firestore,
          field,
          selectedInspections,
          org.id,
          profile,
          res.emails,
          res.message,
          associatedPartnerName
        );
        await presentStandardToast(
          toastController,
          `Report shared successfully`,
          3000,
          'success'
        );
      }
    } catch (error) {
      await presentStandardToast(
        toastController,
        `Something went wrong sharing the report:\n${error}`
      );
    } finally {
      onShareCancel();
      setShowShareModal(false);
    }
  };

  const onDeletionCancel = () => {
    setIsInDeletionFlow(false);
    setSelectedInspections([]);
  };

  const onDeletionAccept = async () => {
    const alert = await alertController.create({
      header: 'Warning',
      message: `You are about to delete ${selectedInspections.length} inspection${
        selectedInspections.length === 1 ? '' : 's'
      }. This action cannot be undone. Are you sure you want to continue?`,
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            if (!networkEnabled) {
              return await presentStandardToast(
                toastController,
                `No internet connection. Please wait until you are back online`
              );
            } else {
              setIsDeleting(true);
              try {
                await deleteFieldInspectionsById(
                  firestore,
                  org.id,
                  profile,
                  selectedInspections.map((i) => i.id)
                );
              } catch (error) {
                await presentStandardToast(
                  toastController,
                  `Something went wrong while deleting the inspections:\n${error}`
                );
              } finally {
                setIsDeleting(false);
                onDeletionCancel();
              }
            }
          },
          cssClass: 'alert-button danger-button',
        },
        {
          text: 'No',
          cssClass: 'alert-button success-button',
          role: 'cancel',
        },
      ],
    });

    alert.present();
  };

  return {
    isInSharingFlow,
    selectedInspections,
    toggleInspection,
    setIsInSharingFlow,
    isInDeletionFlow,
    onShareCancel,
    onShareAccept,
    showShareModal,
    setShowShareModal,
    shareFieldReport,
    setIsInDeletionFlow,
    onDeletionCancel,
    onDeletionAccept,
    isDeleting,
  };
};
