import { IonFab, IonFabButton, IonIcon, IonModal } from '@ionic/react';
import { imageOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { ctxOrg } from '../App';
import { Inspection, InspectionError, InspectionReference, LotProperties } from '../InspectionModel';
import { Picture, UserProfile } from '../Model';
import PageGallery from '../PageGallery';
import ViewPicture from '../ViewPicture';
import { ImageSync } from '../components/CSideMenu';
import { InspectionSpec } from '../ModelSpecification';

// Styling
import '../PageInspection.scss';

interface PropsGallery {
  onPicturesUpdated: Function;
  isEditable: boolean;
  pictures: Picture[];
  schema: InspectionSpec;
  assessmentStatus: boolean;
  inspectionReference: InspectionReference;
  inspection: Inspection;
  profile: UserProfile;
  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;
  errors: InspectionError[]
}

export const CInspectionGallery: React.FC<PropsGallery> = ({
  isEditable,
  pictures,
  schema,
  onPicturesUpdated,
  profile,
  inspectionReference,
  inspection,
  updateOCRData,
  errors
}) => {
  const [isOpen, setOpen] = useState(false);

  const org = useContext(ctxOrg);

  return (
    <>
      <IonFab
        vertical="bottom"
        horizontal="start"
        slot="fixed"
        className={'align-to-big'}
      >
        <IonFabButton
          onClick={() => setOpen(true)}
          color="medium"
          className="gallery-fab"
          data-tip={'gallery-button'}
        >
          <ImageSync renderAs="icon" />
          {pictures?.length > 0 ? (
            <ViewPicture id={pictures[pictures.length - 1].id} />
          ) : (
            <IonIcon icon={imageOutline} />
          )}
        </IonFabButton>
      </IonFab>

      <IonModal isOpen={isOpen} onDidDismiss={(_) => setOpen(false)} animated={true}>
        <PageGallery
          errors={errors}
          editable={isEditable}
          pictures={pictures}
          schema={schema}
          inspection={inspection}
          onDismiss={(_) => setOpen(false)}
          onPicturesUpdated={onPicturesUpdated}
          updateOCRData={updateOCRData}
          profile={profile}
          inspectionReference={inspectionReference}
          orgSettings={org?.settings}
        />
      </IonModal>
    </>
  );
};
