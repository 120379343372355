import { toastController } from '@ionic/core';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { updatePassword as updatePasswordFB } from 'firebase/auth';
import {
  briefcaseOutline,
  checkmarkDoneCircleOutline,
  chevronDown,
  chevronForward,
  closeCircleOutline,
  closeOutline,
  flaskOutline,
  folderOutline,
  keyOutline,
  languageOutline,
  logOutOutline,
  mailOutline,
  peopleOutline,
  personCircleOutline,
} from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { ctxOrg, ctxProfile } from './App';
import { auth, firestore } from './ConfigFirebase';
import { logout } from './DataApp';
import { CLEAR_CACHE_STORAGE_KEY } from './GlobalConstants';
import { agTabsReports, defaultAgTabs } from './ModelAGTabs';
import './PageSettings.scss';
import {
  AppProduct,
  QCTierAction,
  isFreeQCTier,
  isTierHigher,
  orgHasPermission,
  orgPermissionMap,
  userHasPermission,
  userHasPermissionSet,
  userPermissionsMapping,
} from './PermissionsService';
import i18n, { supportedLanguages } from './ServiceI18n';
import ViewSubscriptionSelector from './ViewSubscriptionSelector';
import { ViewTabSelector } from './ViewTabSelector';

export interface Props {
  onUserPropertiesChanged?: Function;
}

const PageSettings = ({ onUserPropertiesChanged }: Props) => {
  const organisation = useContext(ctxOrg);
  const profile = useContext(ctxProfile);

  const isFreeTier = isFreeQCTier(organisation?.settings);
  const profileName = useRef(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const changeLanguageHandler = async (lang) => {
    await i18n.changeLanguage(lang.code);
    // TODO throw new Error("Not Implemented");
    //this.props.onUserPropertiesChanged({...this.props.userContext.properties, language: code});
  };

  const clearFirestoreCache = () => {
    localStorage.setItem(CLEAR_CACHE_STORAGE_KEY, 'CLEAR');
    window.location.reload();
  };

  const [showPermissions, setShowPermissions] = useState(true);
  const [showAGTabs, setShowAGTabs] = useState(true);
  const [showReportTabs, setShowReportTabs] = useState(true);
  // const [quota, setQuota] = useState({percentageUsed:0, remaining:0});

  const logoutHandler = async () => {
    await logout(auth);
  };

  const userPermissions = Object.values(userPermissionsMapping).filter((permission) => {
    const {
      section: s,
      displayRules: { appProduct, minTier: tier },
    } = permission;
    const orgTier = organisation?.settings?.productTiers?.[appProduct];
    const isRightTier = !!orgTier
      ? isTierHigher(orgTier, tier, orgPermissionMap[appProduct].hierarchy)
      : false;

    return userHasPermissionSet(permission, profile) && isRightTier;
  });
  const permissionSections = [...new Set(userPermissions.map((p) => p.section))];

  const dividerWrapper = (
    icon: string,
    text: string,
    color: string = 'light',
    slot: string = 'start',
    showMore: boolean = null,
    setShowMore: React.Dispatch<React.SetStateAction<boolean>> = (_: boolean) => null,
    buttons = <></>
  ) => (
    <IonItemDivider color={color} onClick={() => setShowMore(!showMore)}>
      <IonIcon icon={icon} slot={slot} />
      {text}
      {showMore != null && (
        <IonIcon icon={showMore ? chevronDown : chevronForward} className="show-more" />
      )}
      <div className="divide-wrapper-buttons">{buttons}</div>
    </IonItemDivider>
  );

  // useEffect( () => {
  //   const quota = async () => {
  //     if (navigator.storage && navigator.storage.estimate) {
  //       const quota = await navigator.storage.estimate();
  //       // quota.usage -> Number of bytes used.
  //       // quota.quota -> Maximum number of bytes available.
  //       const percentageUsed = (quota.usage / quota.quota) * 100;
  //       console.log(`You've used ${percentageUsed}% of the available storage.`);
  //       const remaining = quota.quota - quota.usage;
  //       console.log(`You can write up to ${remaining/1024} more Mbytes.`);
  //       setQuota({percentageUsed, remaining})
  //     }
  //   }
  //   quota()

  // }, [])

  const updatePassword = async () => {
    const newPassword = password.trim();
    if (newPassword.length < 6) {
      window.alert('Password should be at least 6 characters');
    } else if (newPassword !== confirmPassword.trim()) {
      window.alert('Passwords do not match');
    } else {
      await updatePasswordFB(auth?.currentUser, newPassword)
        .then(async () => {
          const toast = await toastController.create({
            message: 'Password successfully changed',
            position: 'top',
            color: 'dark',
            duration: 3000,
            buttons: [
              {
                text: 'Got it',
                role: 'cancel',
              },
            ],
          });
          toast.present().then(() => setShowPasswordModal(false));
        })
        .catch(async (err) => {
          console.log(err);
          const toast = await toastController.create({
            message:
              'Unable to change the password. If this problem persists re-login and try again',
            position: 'top',
            color: 'dark',
            duration: 3000,
            buttons: [
              {
                text: 'Got it',
                role: 'cancel',
              },
            ],
          });
          toast.present().then();
        });
    }
  };
  const updateName = async () => {
    const newName = profileName.current.value.trim();
    if (!newName) {
      return;
    }

    firestore.collection('user').doc(profile.id).update({ name: newName });

    firestore
      .collection('user')
      .doc(profile.id)
      .collection('profile')
      .doc(profile.id)
      .update({ name: newName });

    const toast = await toastController.create({
      message: 'Profile name changed to: ' + newName,
      position: 'top',
      color: 'dark',
      duration: 3000,
      buttons: [
        {
          text: 'Got it',
          role: 'cancel',
        },
      ],
    });

    toast.present().then();
  };

  const renderNonFreeSettings = () => {
    return (
      <>
        {dividerWrapper(peopleOutline, 'User Role')}
        <IonItem>
          <IonLabel>{profile.userRole}</IonLabel>
        </IonItem>

        {dividerWrapper(
          keyOutline,
          'User Permissions',
          undefined,
          undefined,
          showPermissions,
          setShowPermissions
        )}
        <div className="user-permissions">
          {showPermissions &&
            permissionSections.map((section) => (
              <div key={section}>
                <IonListHeader color="light">
                  <b>{section}</b>
                </IonListHeader>
                {userPermissions
                  .filter((p) => p.section === section)
                  .map((permission, idx) => {
                    const { uiTitle, uiDescription } = permission;
                    return (
                      <IonItem
                        key={idx}
                        title={uiDescription}
                        className="user-permissions-item"
                      >
                        <IonIcon icon={checkmarkDoneCircleOutline} color="primary" />{' '}
                        <span>{uiTitle}</span>
                      </IonItem>
                    );
                  })}
              </div>
            ))}
        </div>

        {dividerWrapper(
          folderOutline,
          'Quality Control Tabs',
          undefined,
          undefined,
          showAGTabs,
          setShowAGTabs
        )}
        {showAGTabs && (
          <IonItem>
            <ViewTabSelector
              profile={profile}
              profileTabProperty="displayAGTabs"
              defaultTabs={defaultAgTabs}
              organisationSettings={organisation?.settings}
            />
          </IonItem>
        )}

        {dividerWrapper(
          folderOutline,
          'Report Tabs',
          undefined,
          undefined,
          showReportTabs,
          setShowReportTabs
        )}
        {showReportTabs && (
          <IonItem>
            <ViewTabSelector
              profile={profile}
              profileTabProperty="displayReportTabs"
              defaultTabs={agTabsReports}
              organisationSettings={organisation?.settings}
            />
          </IonItem>
        )}

        {userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') &&
          orgHasPermission(
            organisation?.settings,
            AppProduct.QualityControl,
            QCTierAction.EmailNotification
          ) && (
            <>
              {dividerWrapper(mailOutline, 'Subscriptions')}
              <IonItem className="subscription-selector">
                <ViewSubscriptionSelector profile={profile} />
              </IonItem>
            </>
          )}

        <div className="experimental-clear-cache">
          {/* {dividerWrapper(flaskOutline, 'Experimental (only visible for admins)', 'danger2')} */}
          <IonItemDivider color={'danger2'}>
            <IonIcon icon={flaskOutline} slot={'start'} />
            Experimental
            <IonButton onClick={() => clearFirestoreCache()} color="dark" slot="end">
              <IonIcon slot="start" icon={closeCircleOutline} />
              &nbsp; CLEAR CACHE
            </IonButton>
          </IonItemDivider>
        </div>
      </>
    );
  };

  console.log('render settings');

  return (
    <IonPage className="page-profile">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="medium" />
          </IonButtons>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={logoutHandler}>
              <IonIcon icon={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonModal
        isOpen={showPasswordModal}
        onDidDismiss={() => setShowPasswordModal(false)}
        cssClass="modal-reset-password"
      >
        <IonHeader className={''}>
          <IonToolbar>
            <IonTitle>Change Password</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowPasswordModal(false)}>
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form
            autoComplete="off"
            onSubmit={(evt) => {
              evt.preventDefault();
            }}
          >
            <IonList>
              <IonItem>
                <IonLabel>Password&nbsp;</IonLabel>
                <IonInput
                  autocomplete={'new-password'}
                  type="password"
                  onIonInput={(e: any) => setPassword(e.target.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel>Confirm Password&nbsp;</IonLabel>
                <IonInput
                  autocomplete={'new-password'}
                  type="password"
                  onIonInput={(e: any) => setConfirmPassword(e.target.value)}
                />
              </IonItem>
            </IonList>
            <div className="bottom-buttons">
              <IonButton onClick={updatePassword}>Confirm</IonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>

      <IonContent>
        <IonList className="page-profile-wrapper">
          {dividerWrapper(briefcaseOutline, 'Organization')}
          <IonItem>{organisation?.name ?? profile.organisationId}</IonItem>

          {dividerWrapper(
            personCircleOutline,
            'Profile',
            undefined,
            undefined,
            undefined,
            undefined,
            <IonButton fill="outline" onClick={() => setShowPasswordModal(true)}>
              Change Password
            </IonButton>
          )}
          <IonItem>
            <IonLabel>My name:</IonLabel>
            <IonInput
              ref={profileName}
              value={profile?.name}
              style={{ backgroundColor: 'transparent' }}
            />
            <IonButton onClick={updateName}>change</IonButton>
          </IonItem>

          {/* {dividerWrapper(keyOutline, 'Password')}
          <IonItem>
            <IonButton onClick={() => setShowPasswordModal(true)}>Reset Password</IonButton>
          </IonItem> */}

          {/* { !!quota.remaining &&
            <>
              {dividerWrapper(briefcaseOutline, 'Disk info')}
              <IonItem>
                Available space: {(quota.remaining / (1024*1024*1024)).toFixed(2)} GB
              </IonItem>
            </>
          } */}

          {dividerWrapper(languageOutline, 'Languages')}
          {supportedLanguages.map((lang) => (
            <IonItem key={lang.code} onClick={() => changeLanguageHandler(lang)}>
              <img alt={lang.name} src={lang.flags[1] ?? lang.flags[0]} slot="start" />
              <IonLabel>{lang.name}</IonLabel>
              <IonCheckbox
                slot="end"
                checked={profile.properties?.language === lang.code}
                disabled={true}
              />
            </IonItem>
          ))}

          {!isFreeTier && renderNonFreeSettings()}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(PageSettings);
