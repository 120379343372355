import { IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import { getValueAndUnitFromLocator } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import { CategoricalMeasurement } from '../generated/openapi/core';
import { useInspectionContext } from '../context/InspectionContextProvider';

// --------------------------------------------------------------------------------------------------------
// CCategorical
// --------------------------------------------------------------------------------------------------------
interface ICCategorical {
  measurement: CategoricalMeasurement;
  inputLocator: UserInputLocator;
}

const selectNoneString: string = '— None —';

export const CCategorical = (props: ICCategorical) => {
  const choices: string[] = [selectNoneString, ...props.measurement.choices];
  const { inspection, updateInspection } = useInspectionContext();

  const value = getValueAndUnitFromLocator(props.inputLocator, inspection).value;

  return (
    <IonSelect
      interface="action-sheet"
      placeholder="Select an option"
      className={props.inputLocator.isScore ? `score-${value}` : ''}
      value={value}
      onIonChange={(e) => {
        // @ts-ignore
        if (!e.currentTarget?.classList.contains('select-expanded')) {
          // we only trigger the onChange when the select is expanded
          return;
        }
        const value =
          e.detail?.value === selectNoneString ? undefined : e.detail?.value;
        updateInspection(value, props.inputLocator);
      }}
    >
      {choices.map((option: string, idx) => {
        return (
          <IonSelectOption key={option} value={option}>
            {option}
          </IonSelectOption>
        );
      })}
    </IonSelect>
  );
};
