import { toastController } from '@ionic/core';
import {
  IonButton,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonModal,
  isPlatform,
} from '@ionic/react';
import {
  barcode,
  closeCircle,
  closeOutline,
  listCircle,
  listCircleOutline,
  videocamOffOutline,
} from 'ionicons/icons';
import React, { RefObject } from 'react';
import { Camera } from './camera/Camera';
import useSound from 'use-sound';
import { saveRawPictureToIndexedDB } from './DataImage';
import SCANDIT_KEY from './DataScandit';
import {
  LotProperties,
  OrganisationSettings,
  Picture,
  UserProfile,
  IBarcode,
} from './Model';
import './PageCamera.scss';
import PagePicture from './PagePicture';
import eventLogger from './events/common';

import { drop } from 'lodash';
import { Barcode, ScanSettings } from 'scandit-sdk';
import ScanditBarcodeScanner from 'scandit-sdk-react';
import { INLINE_EDITING_STORAGE_KEY } from './GlobalConstants';
import { Inspection } from './InspectionModel';
import { AppProduct, QCTierAction, orgHasPermission } from './PermissionsService';
import { stringifyInspectionReference } from './ServiceInspection';
import { createCapturePhotoEvent, createPhotoSavedLocallyEvent } from './events/photo';
import { InspectionSpecSection, LotSchemaObjectTypeEnum } from './generated/openapi/core';
import { InspectionSpec } from './ModelSpecification';
import { presentStandardToast } from './HelperIonic';
import * as Sentry from '@sentry/react';

export interface PageCameraProps {
  pictures: Picture[];
  editable: boolean;
  onDismiss: Function;
  lastPictureTaken: Picture;
  picturesUpdated: (pictures: Picture[]) => void;
  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;
  profile: UserProfile;
  onUserPropertiesChanged?: Function;
  onScan?: Function;
  cameraTag?: InspectionSpecSection;
  playCameraSound?: Function;
  playBeepSound?: Function;
  playStarwarsSound?: Function;

  expectedBarcodes?: string[];

  schema: InspectionSpec;
  orgSettings: OrganisationSettings;

  organisationId: string;
  inspection: Inspection;
}

export interface State {
  photo: any;
  photoSrc: any;
  showBarcode: boolean;
  showShutterOverlay: boolean;
  flashIndex: number;
  hasCamera: boolean | null;
  rotation: number;
  deviceError: any;
  pictureSelectedIndex: number;
  savingPictureLocally: boolean;
  cameraTag: InspectionSpecSection;
  barcode?: IBarcode;
  imgBarcode?: string;
  inlineEditing: boolean;
  cameraInitialized: boolean;
  cameraNotFound: boolean;
  aspectRatio: number;

  // OCR
  // uploadingOCRPicture: boolean;
  // ocrApiResponse: VisionAPIResponse;
  // showOCRModal: boolean;
  // ocrPicture: Picture;
}

class PageCamera extends React.Component<PageCameraProps, State> {
  private webcamRef: RefObject<any>;
  private ignoreNextClick: boolean = false;
  private videoConstraints = {
    facingMode: 'environment',
  };
  private popstate: any;
  private goBack = true;
  private mounted: boolean;
  private timeoutRef;

  constructor(props) {
    super(props);

    // const href = window.location.href
    // window.history.pushState({cameraBack: true}, '', href)

    // this.popstate = (event) => {
    //   if (event?.state?.pictureBack) {
    //     return
    //   }
    //   this.goBack = false
    //   this.props.onDismiss()
    // }
    // window.addEventListener('popstate', this.popstate, false);

    this.state = {
      photo: null,
      photoSrc: null,
      showBarcode: false,
      flashIndex: 0,
      hasCamera: null,
      rotation: 0,
      deviceError: null,
      pictureSelectedIndex: -1,
      savingPictureLocally: false,
      showShutterOverlay: false,
      cameraTag: this.props.cameraTag,
      inlineEditing: !!localStorage.getItem(INLINE_EDITING_STORAGE_KEY),
      cameraInitialized: true,
      cameraNotFound: false,
      aspectRatio: 9 / 16,
      // uploadingOCRPicture: false,
      // ocrApiResponse: undefined,
      // showOCRModal: false,
      // ocrPicture: undefined
    };
    console.log(this.props.cameraTag);
    this.webcamRef = React.createRef();
  }

  strikethroughStyle = {
    background:
      'linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 2px),rgba(255,255,255,1) 50%,rgba(0,0,0,0) calc(50% + 2px),rgba(0,0,0,0) 100%)',
    borderRadius: '50%',
    overflow: 'hidden',
  };

  componentDidMount() {
    this.mounted = true;
    // this.checkCameraInitialized();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (!!this.timeoutRef) {
      console.log('clearInterval', this.timeoutRef);
      clearInterval(this.timeoutRef);
    }
    // window.removeEventListener('popstate', this.popstate, false);
    // if (this.goBack) {
    //   window.history.back()
    // }
  }

  dismiss() {
    this.props.onDismiss();
  }

  async checkCameraInitialized(attempt: number = 0) {
    // return
    const maxAttempts = 15;
    const checkFrequencyInMs = 200;

    let cameraAvailable = this.webcamRef.current?.getNumberOfCameras() > 0;
    if (attempt < maxAttempts) {
      this.timeoutRef = setTimeout(async () => {
        if (!cameraAvailable) {
          this.checkCameraInitialized(++attempt);
        } else {
          let stream = await navigator.mediaDevices.getUserMedia();

          let stream_settings = stream.getVideoTracks()[0].getSettings();

          console.log(stream_settings);

          // // actual width & height of the camera video
          // let stream_width = stream_settings.width;
          // let stream_height = stream_settings.height;

          this.setState({
            // aspectRatio: stream_height/stream_width,
            cameraInitialized: true,
          });
        }
      }, checkFrequencyInMs);
    } else {
      this.setState({ cameraNotFound: true });
      const that = this;
      const toast = await toastController.create({
        message:
          'No camera found! Please check if the device has a camera and that the app has permissions to use it.',
        position: 'top',
        color: 'dark',
        // duration: 5000,
        buttons: [
          {
            text: 'close',
            role: 'cancel',
          },
          {
            text: 'check again',
            role: 'button',
            handler() {
              that.setState({ cameraNotFound: false });
              that.checkCameraInitialized();
            },
          },
        ],
      });
      toast.present().then();
    }
  }

  async capture() {
    const { inspection, profile } = this.props;
    const { inlineEditing, savingPictureLocally, cameraTag } = this.state;
    if (savingPictureLocally || this.ignoreNextClick) {
      return;
    }

    if (!this.mounted) {
      return;
    }

    this.ignoreNextClick = true;
    this.setState({ savingPictureLocally: true });
    this.props.playCameraSound();

    let base64Img: string;
    const newState = { savingPictureLocally: false } as State;

    try {
      base64Img = this.webcamRef.current.takePhoto();

      eventLogger.log(createCapturePhotoEvent(inspection), profile);

      if (!inlineEditing) {
        this.flashScreen();
      }

      const picture = await saveRawPictureToIndexedDB(
        base64Img,
        this.props.organisationId,
        stringifyInspectionReference(this.props.inspection.reference)
      );

      // if the camera page has a tag on the state, add it to the picture
      if (cameraTag) {
        picture.sectionId = cameraTag.name;
        picture.sectionName = cameraTag.name;
        picture.imageTag = cameraTag.imageTag;
      }

      eventLogger.log(createPhotoSavedLocallyEvent(inspection, picture.id), profile);

      let pictures = [...this.props.pictures, picture];

      if (!!inlineEditing && !cameraTag) {
        newState.pictureSelectedIndex = pictures.length - 1;
      }
      this.props.picturesUpdated(pictures);
    } catch (e) {
      await presentStandardToast(toastController, e.message, 5000, 'danger2');
      Sentry.captureException(e, (scope) => {
        // we send the first 100 characters of the base64 string to have an idea of the metadata and the shape of the data
        scope.setExtra('base64ImgExcerpt', `${(base64Img ?? '').slice(0, 100)}[...]`);
        return scope;
      });
    } finally {
      this.ignoreNextClick = false;
      if (this.mounted) {
        this.setState(newState);
      }
    }
  }

  private hidePicture() {
    if (this.mounted) this.setState({ pictureSelectedIndex: -1 });
  }

  onPictureUpdated(picture: Picture) {
    let pictures = this.props.pictures.map((pic) =>
      pic.id === picture.id ? picture : pic
    );
    this.props.picturesUpdated(pictures);
  }

  removePicture(picture: Picture) {
    let pictures = this.props.pictures.filter((pic) => pic.id !== picture.id);
    this.props.picturesUpdated(pictures);
    this.hidePicture();
  }

  inlineEditingToggle() {
    if (this.state.inlineEditing) {
      localStorage.removeItem(INLINE_EDITING_STORAGE_KEY);
    } else {
      localStorage.setItem(INLINE_EDITING_STORAGE_KEY, 'true');
    }

    this.setState({ inlineEditing: !this.state.inlineEditing });
    // if (this.props.onUserPropertiesChanged) {
    //   this.props.onUserPropertiesChanged({
    //     ...this.props.profile.properties,
    //     flagAutoEditImage: !this.props.profile.properties.flagAutoEditImage
    //   });
    // }
  }

  setBarcodeModeON() {
    let { showBarcode } = this.state;
    if (this.mounted) this.setState({ showBarcode: !showBarcode, barcode: undefined });
    // if (!this.state.showBarcode) this.props.playStarwarsSound();
  }

  getScanSettings() {
    return new ScanSettings({
      codeDuplicateFilter: 1500,
      enabledSymbologies: [Barcode.Symbology.EAN8, Barcode.Symbology.EAN13],
    });
  }

  async flashScreen() {
    return new Promise<void>((resolve, _reject) => {
      if (this.mounted) this.setState({ showShutterOverlay: true });
      setTimeout(() => {
        if (this.mounted) this.setState({ showShutterOverlay: false });
        resolve();
      }, 60);
    });
  }

  showModal() {
    if (
      this.state.pictureSelectedIndex >= 0 &&
      this.props.pictures.length > this.state.pictureSelectedIndex
    ) {
      window.removeEventListener('popstate', this.popstate, false);
      return true;
    } else {
      return false;
    }
  }

  async onScan(b) {
    if (!this.mounted) {
      return;
    }
    const isUPCABarcode =
      b.barcodes[0].data.length === 13 && b.barcodes[0].data[0] === '0';

    if (isUPCABarcode) {
      b.barcodes[0].data = drop(b.barcodes[0].data, 1);
      b.barcodes[0].symbology = 'UPC-A';
    }

    await this.setState({ barcode: undefined });
    if (!this.mounted) {
      return;
    }
    this.setState({ barcode: b.barcodes[0] });

    if (!isUPCABarcode) {
      this.props.onScan({
        code: b.barcodes[0].data,
        type: b.barcodes[0].symbology,
      });
    }
  }

  render() {
    let {
      showBarcode,
      inlineEditing,
      savingPictureLocally,
      cameraInitialized,
      cameraNotFound,
    } = this.state;
    const cameraUnavailable =
      (savingPictureLocally || !cameraInitialized) && !cameraNotFound;

    const errorMessages = {
      noCameraAccessible:
        'No camera device accessible. Please connect your camera or try a different browser.',
      permissionDenied: 'Permission denied. Please refresh and give camera permission.',
      switchCamera:
        'It is not possible to switch camera to different one because there is only one video device accessible.',
      canvas: 'Canvas is not supported.',
    };

    return (
      <div className="page-camera ion-page">
        <IonContent scrollY={false}>
          <div className="camera-dark-background" />

          {this.state.cameraTag && (
            <IonChip
              color="white"
              className="auto-tag-chip"
              onClick={(_) =>
                this.mounted ? this.setState({ cameraTag: undefined }) : null
              }
            >
              <IonLabel>{this.state.cameraTag.name}</IonLabel>
              <IonIcon icon={closeCircle} />
            </IonChip>
          )}

          <IonButton
            onClick={(_) => this.dismiss()}
            color="white"
            disabled={this.state.savingPictureLocally}
            className="close-button-top"
            data-tip={'camera-close-button'}
          >
            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
          </IonButton>
          {this.showModal() && (
            <IonModal
              isOpen={true}
              cssClass="modal-camera-gallery page-picture"
              onDidDismiss={() =>
                window.addEventListener('popstate', this.popstate, false)
              }
              animated={false}
            >
              <PagePicture
                profile={this.props.profile}
                editable={this.props.editable}
                picture={this.props.pictures[this.state.pictureSelectedIndex]}
                inspection={this.props.inspection}
                schema={this.props.schema}
                onDismiss={this.hidePicture.bind(this)}
                onPictureUpdated={this.onPictureUpdated.bind(this)}
                onPictureRemoved={this.removePicture.bind(this)}
                updateOCRData={this.props.updateOCRData}
                orgSettings={this.props.orgSettings}
              />
            </IonModal>
          )}

          {!showBarcode && (
            <IonFab
              vertical="bottom"
              horizontal="center"
              slot="fixed"
              className={'big camera-assessment ' + (isPlatform('tablet') && 'tablet')}
            >
              <IonFabButton
                disabled={cameraUnavailable || cameraNotFound}
                data-tip={`camera-shutter-button`}
                onClick={(_) => this.capture()}
                color="white"
                className="recor2d-button"
              >
                {/* {cameraUnavailable && <IonIcon icon={hourglassOutline} className={'rotate'}/>} */}
                {cameraNotFound && <IonIcon icon={videocamOffOutline} />}
              </IonFabButton>
            </IonFab>
          )}

          {/* OCR Photo button disabled (for now at least) */}
          {/* <IonFab vertical='top' horizontal='center' slot='fixed' className='ocr-toggle'>
          <IonBadge color="dark">OCR OFF</IonBadge>
        </IonFab>

        <IonFab vertical='bottom' horizontal='end' slot='fixed' className='vision align-to-big' >
          {!showBarcode && <IonFabButton disabled={this.state.uploadingOCRPicture}
            onClick={(_) => this.callVisionAPI()} color="dark">
            <IonIcon icon={uploadingOCRPicture ? hourglassOutline : eyeOutline} className={uploadingOCRPicture ? 'rotate' : ''} />
          </IonFabButton>}
        </IonFab> */}

          {orgHasPermission(
            this.props.orgSettings,
            AppProduct.QualityControl,
            QCTierAction.BarcodeScan
          ) &&
            this.props.inspection?.objectType === LotSchemaObjectTypeEnum.Lot &&
            !!this.props.onScan && (
              <IonFab
                vertical="bottom"
                horizontal="end"
                slot="fixed"
                className="align-to-big"
              >
                <IonFabButton
                  disabled={this.state.savingPictureLocally}
                  onClick={(_) => this.setBarcodeModeON()}
                  className="barcode"
                  color="camera"
                >
                  <IonIcon icon={barcode} color="white" />
                </IonFabButton>
              </IonFab>
            )}

          <IonFab
            vertical="bottom"
            horizontal="start"
            slot="fixed"
            className="align-to-big"
          >
            <div style={!inlineEditing ? this.strikethroughStyle : {}}>
              <IonFabButton
                className={'close-button'}
                data-tip={`camera-inline-editing`}
                disabled={this.state.savingPictureLocally}
                onClick={() => this.inlineEditingToggle()}
              >
                <IonIcon
                  color="white"
                  icon={inlineEditing ? listCircle : listCircleOutline}
                />
              </IonFabButton>
            </div>
          </IonFab>

          <div className="camera-wrapper">
            {!!this.state.barcode && (
              <div className="barcode-container">
                <div className={'message code-' + this.state.barcode.data.length}>
                  <span>
                    {this.state.barcode.symbology} | {this.state.barcode.data}
                  </span>
                </div>
                {(this.props.expectedBarcodes ?? []).length > 0 &&
                  (() => {
                    const barcodePresent: boolean =
                      this.props.expectedBarcodes.includes(this.state.barcode.data);

                    return (
                      <div className={`message code-${barcodePresent ? '13' : '12'}`}>
                        <span>
                          {`BARCODE ${barcodePresent ? 'PRESENT' : 'MISSING'}`}
                        </span>
                      </div>
                    );
                  })()}
              </div>
            )}

            <div
              className={
                'camera-video ' + (showBarcode ? 'mode-barcode' : 'mode-camera')
              }
              id="scandit-barcode-picker"
              // key={1}
            >
              {this.state.showShutterOverlay && <div className="shutter-overlay"></div>}

              {!showBarcode ? (
                <Camera
                  facingMode="environment"
                  errorMessages={errorMessages}
                  ref={this.webcamRef}
                  // aspectRatio={this.state.aspectRatio}
                />
              ) : (
                <ScanditBarcodeScanner
                  licenseKey={SCANDIT_KEY}
                  engineLocation="/assets/js/scandit-sdk/" // could also be a local folder, e.g. "build"
                  // engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/" // could also be a local folder, e.g. "build"
                  // Picker events
                  // onReady={() => this.setState({ scannerReady: true })}
                  onScan={(barcode) => this.onScan(barcode)}
                  onScanError={console.log}
                  // Picker options
                  scanSettings={this.getScanSettings()}
                  playSoundOnScan={true}
                  // videoFit={BarcodePicker.ObjectFit.CONTAIN}
                />
              )}
            </div>
          </div>
        </IonContent>
      </div>
    );
  }
}

// export default PageCamera;

const withHooksHOC = (Component: any) => {
  return (props: any) => {
    const [playCameraSound] = useSound('/assets/mp3/camera.mp3', { volume: 0.1 });
    const [playBeepSound] = useSound('/assets/mp3/beep.mp3', { volume: 0.5 });
    const [playStarwarsSound] = useSound('/assets/mp3/fx4.mp3', { volume: 0.9 });

    return (
      <PageCamera
        playBeepSound={playBeepSound}
        playStarwarsSound={playStarwarsSound}
        playCameraSound={playCameraSound}
        {...props}
      />
    );
  };
};

export default withHooksHOC(PageCamera);
