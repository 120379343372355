import React from 'react';
import ViewPicture from './ViewPicture';
import './CardImages.scss';
import { cloneDeep } from 'lodash';

export const CardImages = ({
  pictures,
  max = 6,
  hideEmpty = true,
  onClick = undefined,
  className = '',
  selection = [],
  imageMode = 'ion-img',
}) => {
  if (!pictures || pictures.length === 0) {
    if (hideEmpty) {
      return null;
    } else {
      return <div className="card-images emptyPicture"></div>;
    }
  }

  const pics = cloneDeep(pictures)
    .reverse()
    .slice(0, max > pictures.length ? pictures.length : max);

  return (
    <div
      className={`card-images ${className} ${
        pictures.length > 12 ? 'two-rows' : 'one-row'
      }`}
    >
      {pics.map((picture, index) => (
        <ViewPicture
          id={picture.id}
          index={index}
          key={picture.id + index}
          onClick={onClick}
          picture={picture}
          className={selection.includes(picture.id) ? 'selected' : 'culo'}
          mode={imageMode}
        />
      ))}
    </div>
  );
};
