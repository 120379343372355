import { getQuestionsApiClient } from '../ConfigApi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CreateQuestionTagPayload,
  QuestionIn,
  QuestionList,
  QuestionTag,
  SectionType,
} from '../generated/openapi/core';

export interface QuestionsState {
  isLoading: boolean;
  isError: boolean;
  data: QuestionList;
  error: Error;
}

// Inspiration: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
const questionKeys = {
  all: ['questions'] as const,
  bySectionType: (sectionType: SectionType) => ['questions', { sectionType }] as const,
};

export async function fetchQuestions(sectionType?: SectionType) {
  const questionsApi = getQuestionsApiClient();
  const res = await questionsApi.getQuestions(sectionType);
  return res.data;
}

export function useQuestions(
  successCallback?: (questions: QuestionList) => any
): QuestionsState {
  return useQuery(questionKeys.all, () => fetchQuestions(), {
    onSuccess: successCallback,
  });
}

// --------------------------------
// question tags
// --------------------------------
async function fetchQuestionTags() {
  const questionsApi = getQuestionsApiClient();
  const res = await questionsApi.getQuestionTags();
  return res.data;
}

export function useQuestionTags() {
  return useQuery('question-tags', () => fetchQuestionTags());
}

export function useCreateQuestionTags() {
  const queryClient = useQueryClient();
  const questionsApi = getQuestionsApiClient();
  return useMutation({
    mutationFn: (newQuestionTag: CreateQuestionTagPayload) =>
      questionsApi.createQuestionTag(newQuestionTag),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: 'question-tags' });
    },
  });
}
// --------------------------------

export function useQuestionsBySectionType(sectionType: SectionType) {
  return useQuery(questionKeys.bySectionType(sectionType), () =>
    fetchQuestions(sectionType)
  );
}

export function useCreateQuestion() {
  const queryClient = useQueryClient();
  const questionsApi = getQuestionsApiClient();
  return useMutation({
    mutationFn: (newQuestion: QuestionIn) => questionsApi.createQuestion(newQuestion),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: questionKeys.all });
    },
  });
}

export function useEditQuestion() {
  const queryClient = useQueryClient();
  const questionsApi = getQuestionsApiClient();
  interface EditQuestionArgs {
    questionId: string;
    modifiedQuestion: QuestionIn;
  }
  return useMutation({
    mutationFn: ({ questionId, modifiedQuestion }: EditQuestionArgs) =>
      questionsApi.editQuestion(questionId, modifiedQuestion),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: questionKeys.all });
    },
  });
}

export function useDeleteQuestion() {
  const queryClient = useQueryClient();
  const questionsApi = getQuestionsApiClient();
  return useMutation({
    mutationFn: (questionId: string) => questionsApi.deleteQuestion(questionId),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: questionKeys.all });
    },
  });
}
