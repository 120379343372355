import {
  IonItemSliding,
  IonItem,
  IonBadge,
  IonButton,
  IonIcon,
  IonButtons,
  IonCheckbox,
  IonReorder,
  IonItemOptions,
  IonItemOption,
  IonList,
  IonReorderGroup,
  IonSkeletonText,
} from '@ionic/react';
import { caretDownCircleOutline, caretUpCircleOutline, trashBin } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ctxOrg, ctxProfile } from './App';
import CardLot from './CardLot';
import './CTodoList.scss';
import { firestore } from './ConfigFirebase';
import { dateToString, Todo, Task } from './Model';
import ViewArticleDescription from './ViewArticleDescription';
import { ViewInspectionScore } from './ViewInspectionScore';
import { ItemReorderEventDetail } from '@ionic/core';
import { formatDate } from './HelperUtils';
import { Link } from 'react-router-dom';
import { stringifyInspectionReference } from './ServiceInspection';
import { LegacyInspectionReference, LotInspection } from './InspectionModel';
import { getLotSnapshot } from './DataStorage';

// ===========================================================================================
// TODO ITEM
// ===========================================================================================

interface PropsTodoItem {
  i: number;
  todo: Todo;
  task: Task;
  organisationId: string;
  setSelection: Function;
  isSelected: boolean;
}

export const TodoItem = ({
  i,
  todo,
  task,
  organisationId,
  setSelection,
  isSelected,
}: PropsTodoItem) => {
  // --------------------------------------------------------------
  // hooks declaration
  // --------------------------------------------------------------
  const profile = useContext(ctxProfile);
  const organisation = useContext(ctxOrg);
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const [inspection, setInspection] = useState<LotInspection>(undefined);

  useEffect(() => {
    const unsubscribe = getLotSnapshot(
      firestore,
      organisation.id,
      task.lotId,
      (lot) => {
        setInspection(lot.latestInspection);
      }
    );
    return () => unsubscribe();
  }, []);

  // --------------------------------------------------------------
  // methods
  // --------------------------------------------------------------
  const deleteTask = async () => {
    // console.log(todo, task);
    firestore
      .collection('organisation')
      .doc(organisationId)
      .collection('todo')
      .doc(todo.id)
      .delete()
      .then((o) => console.log(o, 'deleted successfully'))
      .catch((e) => console.log('error deleting todo', e));
  };

  const inspectionReference: LegacyInspectionReference = {
    lotId: task.lotId,
    type: task.action === 'STOCK_INSPECTION' ? 'stock' : 'incoming',
    orderId: undefined,
    date: dateToString(new Date()),
  };

  const inspId = stringifyInspectionReference(inspectionReference);

  // const lot = todoLots.find(lot => lot.id === task.lotId)

  // let dateBanner
  // if (!isSameDay(lastTodoDate, todo.date)) {
  //   lastTodoDate = todo.date
  //   dateBanner = <div className="date-banner">{formatDate(todo.date)}</div>
  // }

  const showMoreButton = (
    <IonButtons>
      <IonButton
        className="btn-start-task"
        // routerLink={'/secure/' + organisationId + '/assessment/' + assessmentId(assessmentReference)}
        color="medium"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowMore(!showMore);
        }}
      >
        {showMore ? (
          <IonIcon icon={caretUpCircleOutline} slot="icon-only" />
        ) : (
          <IonIcon icon={caretDownCircleOutline} slot="icon-only" />
        )}
      </IonButton>
    </IonButtons>
  );

  return (
    <IonItemSliding>
      <IonItem
        mode="md"
        id={todo.id + task.lotId}
        className={todo.isCompleted ? 'crossed' : ''}
      >
        <div className="top-wrapper">
          <div className="item-wrapper">
            <Link
              className="link"
              // onClick={(e) => setShowMore(!showMore)}
              to={'/secure/' + organisationId + '/inspection/' + inspId}
            >
              {/* {j} -  */}
              {/* <i>{todo.date}</i> */}
              <div className="pepe">
                <b>
                  <span>{task.lotId}</span>
                  <IonBadge color="light-medium">
                    {task.action.replace('_', ' ')}
                  </IonBadge>
                  {task.locationReference1 && (
                    <IonBadge color="light-medium">
                      {/* {task.action.replace('_', ' ')} */}
                      {task.locationReference1}
                    </IonBadge>
                  )}
                </b>
                <div className="show-only-mobile" style={{ marginLeft: 'auto' }}>
                  {showMoreButton}
                  <ViewInspectionScore
                    organisationSettings={organisation?.settings}
                    profile={profile}
                    displayType="INLINE"
                    scores={inspection?.scores}
                  />
                </div>
              </div>
              <div className="description">
                <ViewArticleDescription article={task.article} pills={true} />
                <i>
                  Last inspected: {formatDate(new Date(task.dateSinceLastInspection))}
                  {/* {formatRelative(new Date(task.dateSinceLastInspection), new Date()} */}
                </i>
                {task.palletIds && (
                  <div className="pallets" style={{ whiteSpace: 'break-spaces' }}>
                    PALLETS: {task.palletIds.join(', ')}
                  </div>
                )}
              </div>
            </Link>
            <div className="buttons-wrapper hide-only-mobile">
              {showMoreButton}
              <div className="hide-only-mobile">
                <ViewInspectionScore
                  organisationSettings={organisation?.settings}
                  profile={profile}
                  displayType="INLINE"
                  scores={inspection?.scores}
                />
              </div>
            </div>
          </div>
          {showMore ? (
            <div>
              <CardLot
                // lotId={!lot ? task.lotId : undefined}
                lotId={task.lotId}
                // lot={lot}
                history={history}
                showSummary={true}
                showLink={true}
                routerLink={
                  '/secure/' +
                  organisationId +
                  '/inspection/' +
                  stringifyInspectionReference(inspectionReference)
                }
              />
            </div>
          ) : null}
        </div>
        <IonCheckbox
          slot="start"
          checked={isSelected}
          onClick={(e) => {
            setSelection((prev) => {
              const newSelection = [...prev];
              if (newSelection.find((id) => id === todo.id) === undefined) {
                newSelection.push(todo.id);
              } else {
                newSelection.splice(newSelection.indexOf(todo.id), 1);
              }
              console.log('newSelection', newSelection);
              return newSelection;
            });
          }}
        />
        {<IonReorder slot="end" />}
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={deleteTask}>
          <IonIcon slot="icon-only" icon={trashBin} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );

  // return <React.Fragment key={todo.id+task.lotId}>
  //   {/* {dateBanner} */}
  //   {item}
  // </React.Fragment>
};

// const TodoItem = React.memo(TI, (prev, next) => {
//   // return prev.todo.id === next.todo.id
//   return false
//   // return true
// })

// ===========================================================================================
// TODO LIST
// ===========================================================================================
interface PropsTodoList {
  todos: Todo[];
  loadingMore: boolean;
  batchesNoMore: boolean;
}

export const TodoList = (props: PropsTodoList) => {
  const { todos, loadingMore, batchesNoMore } = props;
  const profile = useContext(ctxProfile);
  const [selection, setSelection] = useState([]);

  const markTasksAsCompleted = async () => {
    // console.log(todo, task);
    let batch = firestore.batch();
    const organisationId = profile.organisationId;

    selection.map((todoId) => {
      const colRef = firestore
        .collection('organisation')
        .doc(organisationId)
        .collection('todo');

      const updatedTodo = {
        isCompleted: true,
        dateCompleted: new Date().getTime(),
      } as Todo;

      batch.update(colRef.doc(todoId), updatedTodo);
    });

    setSelection([]);

    batch
      .commit()
      .then(() => console.log(selection, 'markTaskAsCompleted successfully'))
      .catch((e) => console.log('error markTaskAsCompleted', e));
  };

  // --------------------------------------------------------------
  const deleteSelectedTasks = async () => {
    // console.log(todo, task);
    let batch = firestore.batch();
    const organisationId = profile.organisationId;

    selection.map((todoId) => {
      const colRef = firestore
        .collection('organisation')
        .doc(organisationId)
        .collection('todo');

      batch.delete(colRef.doc(todoId));
    });

    setSelection([]);

    batch
      .commit()
      .then(() => console.log(selection, 'deleteSelectedTasks successfully'))
      .catch((e) => console.log('error deleteSelectedTasks', e));
  };

  // --------------------------------------------------------------
  const clear = () => {
    setSelection([]);
  };

  // --------------------------------------------------------------
  const selectAll = () => {
    setSelection(todos.map((todo) => todo.id));
  };

  const doReorder = async (event: CustomEvent<ItemReorderEventDetail>) => {
    const { from, to } = event.detail;
    const direction = from < to ? 1 : -1;
    console.log('Dragged from index', from, 'to', to, 'dir', direction);
    console.log(todos[from].tasks[0].lotId, todos[to]?.tasks[0].lotId);
    // console.log('todos', todos)
    event.detail.complete();

    if (to === todos.length) {
      return;
    }

    const newDate =
      ((todos[to]?.date ?? 0) +
        (todos[to + direction]?.date ?? todos[to].date - direction * 200)) /
      2;

    firestore
      .collection('organisation')
      .doc(profile.organisationId)
      .collection('todo')
      .doc(todos[from].id)
      .set({ ...todos[from], date: newDate })
      .then(() => {
        console.log(todos[from].id, 'updated');
      })
      .catch((e) => console.error(e));
  };

  return (
    <>
      {selection.length > 0 && (
        <div className="bottom-buttons">
          <div className="left">
            <IonButton color="white" onClick={clear}>
              Clear
            </IonButton>
            <IonButton color="dark" onClick={selectAll}>
              Select All
            </IonButton>
          </div>
          <div className="right">
            <IonButton color="danger" onClick={deleteSelectedTasks}>
              Delete {!!selection.length && `(${selection.length})`}
            </IonButton>
            <IonButton color="white" onClick={markTasksAsCompleted}>
              Mark as complete {!!selection.length && `(${selection.length})`}
            </IonButton>
          </div>
        </div>
      )}
      <IonList>
        <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
          {todos?.length === 0 && <h1 className="h1-not-found">no results</h1>}
          {!todos &&
            [1, 2, 3].map((i) => (
              <TodoSkeleton visibility="visible" key={'kkk-' + i} />
            ))}
          {todos?.map((todo, j) => {
            return todo.tasks.map((task, i) => (
              <TodoItem
                i={j + 1}
                key={todo.id}
                todo={todo}
                task={task}
                organisationId={profile.organisationId}
                setSelection={setSelection}
                isSelected={!!selection.find((id) => id === todo.id)}
              />
            ));
          })}
        </IonReorderGroup>

        {[1, 2, 3].map((i) => (
          <TodoSkeleton
            key={'kkk-' + i}
            visibility={
              !(todos?.length === 0) && loadingMore && !batchesNoMore
                ? 'visible'
                : 'hidden'
            }
          />
        ))}
      </IonList>
    </>
  );
};

const TodoSkeleton = ({ visibility }) => (
  <IonItem style={{ pointerEvents: 'none', visibility: visibility }}>
    <IonSkeletonText animated></IonSkeletonText>
    <IonCheckbox slot="start" />
  </IonItem>
);
