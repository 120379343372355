import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  // IonFab,
  // IonFabButton,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import i18n from 'i18next';
import {
  checkmarkCircle,
  closeCircleOutline,
  closeOutline,
  pricetags,
} from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import React, { Fragment, createRef } from 'react';
import { saveRawPictureToIndexedDB } from './DataImage';
import withContext, { ContextProps } from './HOC/withContext';
import { BlobToBase64 } from './HelperUtils';
import {
  LotInspection,
  LegacyInspectionReference,
  InspectionReference,
  Inspection,
  InspectionError,
} from './InspectionModel';
import {
  LotProperties,
  MultiPictureSelection,
  Picture,
  getSectionNameFromPicture,
} from './Model';
import { InspectionSpec, SectionNames } from './ModelSpecification';
import './PageGallery.scss';
import PagePicture from './PagePicture';
import PagePictureMulti from './PagePictureMulti';
import { stringifyInspectionReference } from './ServiceInspection';
import ViewPicture from './ViewPicture';
import { ImageSync } from './components/CSideMenu';

export interface Props {
  editable: boolean;
  pictures: Picture[];
  picture?: Picture; // Autoselect a picture

  inspection: Inspection;

  schema?: InspectionSpec;

  editMode?: 'report' | 'assessment';

  showCategoryId?: string;
  showDefectId?: string;

  onDismiss: Function;
  onPicturesUpdated?: Function;
  inspectionReference?: InspectionReference;

  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;

  errors?: InspectionError[];
}

export interface State {
  picture?: Picture;
  filter?: string;
  showSelect?: boolean;
  multiPicture?: boolean;
  multiPictureSelection?: MultiPictureSelection;
}

class PageGallery extends React.Component<Props & ContextProps, State> {
  private contentRef = createRef<HTMLIonContentElement>();
  private popstate: any;
  private goBack = true;
  private mounted: boolean;

  constructor(props) {
    super(props);

    // const href = window.location.href
    // window.history.pushState({cameraBack: true}, '', href)

    // this.popstate = (event) => {
    //   console.log(event)
    //   if (event?.state?.pictureBack) {
    //     return
    //   }
    //   this.goBack = false
    //   this.props.onDismiss()
    // }
    // window.addEventListener('popstate', this.popstate, false);

    this.state = {
      multiPicture: false,
    };

    if (props.picture) {
      setTimeout(
        () => (this.mounted ? this.setState({ picture: props.picture }) : null),
        100
      );
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    // window.removeEventListener('popstate', this.popstate, false);
    // if (this.goBack) window.history.back()
  }

  hidePicture() {
    if (!this.mounted) {
      return;
    }
    this.setState({ picture: undefined });
  }

  hidePictureMulti() {
    if (!this.mounted) {
      return;
    }
    this.setState({
      multiPicture: false,
      multiPictureSelection: {
        ...this.state.multiPictureSelection,
        pictures: [],
      },
    });
  }

  onPictureUpdated(picture: Picture) {
    if (!this.mounted) {
      return;
    }
    this.setState({ picture: picture });
    let pictures = this.props.pictures.map((pic) =>
      pic.id === picture.id ? { ...picture } : pic
    );
    this.props.onPicturesUpdated(pictures, [picture], this.props.inspectionReference);
  }

  onMultiPictureUpdated(obj: {
    pics: string[];
    sectionId: string;
    inputIds: string[];
  }) {
    let { pictures: originalPictures, schema } = this.props;
    const pictures = cloneDeep(originalPictures);

    obj.pics.forEach((pic, i) => {
      let p = pictures?.find((o) => o.id === pic);
      if (!p) {
        return;
      }
      p.sectionId = obj.sectionId;
      p.sectionName = obj.sectionId;
      p.inputIds = obj.inputIds || [];
    });

    this.props.onPicturesUpdated(pictures, obj.pics, this.props.inspectionReference);
  }

  removePicture(picture: Picture) {
    let pictures = this.props.pictures.filter((pic) => pic.id !== picture.id);
    this.props.onPicturesUpdated(pictures, [picture], this.props.inspectionReference);
    this.hidePicture();
  }

  // for multi tag
  removePictures(picsArray) {
    let pictures = this.props.pictures.filter((pic) => !picsArray.includes(pic.id));
    this.props.onPicturesUpdated(pictures, picsArray, this.props.inspectionReference);
    this.cancelMultipleSelection();
    this.hidePictureMulti();
  }

  selectPicture(picture: Picture) {
    if (!this.mounted) {
      return;
    }
    // window.removeEventListener('popstate', this.popstate, false);
    if (this.state.multiPictureSelection) {
      let selection = [...this.state.multiPictureSelection.pictures];
      if (selection.indexOf(picture.id) < 0) {
        selection.push(picture.id);
      } else {
        selection.splice(selection.indexOf(picture.id), 1);
      }
      this.setState({
        multiPictureSelection: {
          ...this.state.multiPictureSelection,
          pictures: selection,
        },
      });
      // console.log('pictures selected', selection)
    } else {
      this.setState((state) => {
        return { ...state, picture: picture };
      });
    }
  }

  multiTag() {
    if (this.mounted)
      this.setState((state) => {
        return { ...state, multiPicture: true };
      });
  }

  hideAlert(): void {
    if (this.mounted) this.setState({ ...this.state, showSelect: false });
  }

  showAlert(): void {
    if (this.mounted) this.setState({ ...this.state, showSelect: true });
  }

  cancelMultipleSelection() {
    if (this.mounted) this.setState({ multiPictureSelection: undefined });
  }

  startMultipleSelection() {
    if (this.mounted)
      this.setState({
        multiPictureSelection: {
          pictures: [],
          showRemovePictureAlert: false,
          chooseDefects: false,
          conditions: [],
        },
      });
  }

  categorySorting(a: string, b: string) {
    // console.log(a,b)
    if (a === 'Other') return -1;
    else if (b === 'Other') return 1;
    else return a < b ? -1 : 1;
  }

  render() {
    let {
      inspection,
      editMode,
      pictures,
      editable,
      onPicturesUpdated,
      profile,
      inspectionReference,
      errors,
    } = this.props;
    let { picture, multiPicture } = this.state;

    let picturesMap = {};

    // Emi: is this unused?
    if (this.props.showCategoryId) {
      // pictures = pictures.filter((o) => o.groupId === this.props.showCategoryId)
    }

    if (this.props.showDefectId) {
      pictures = pictures.filter((p) => p.inputIds.includes(this.props.showDefectId));
    }

    pictures.forEach((x) => {
      const groupName = getSectionNameFromPicture(x);
      if (picturesMap[groupName] === undefined) picturesMap[groupName] = [];
      picturesMap[groupName].push(x);
    });
    // pictures.forEach((x) => {
    //   const groupName = getConfigurationGroupName(key, this.props.schema)
    //   if (picturesMap[x.groupId] === undefined)
    //     picturesMap[x.groupId] = [];
    //   picturesMap[x.groupId].push(x)
    // });

    // console.log("picture map", pictures, picturesMap);

    return (
      <Fragment>
        <IonHeader
          className={
            'page-gallery ' + (this.props.editable ? 'editable' : 'non-editable')
          }
        >
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                data-tip={'gallery-close'}
                onClick={(_) => this.props.onDismiss()}
              >
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="start">
              <ImageSync renderAs="button" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          className={
            'page-gallery ' + (this.props.editable ? 'editable' : 'non-editable')
          }
          ref={this.contentRef}
        >
          {/* <IonFab vertical='bottom' horizontal='center' slot='fixed' className='big'>
          <IonFabButton color='camera' onClick={_=>console.log('camera')} className='big'>
            <IonIcon icon={cameraOutline} style={{fontSize: '36px'}}/>
          </IonFabButton>
        </IonFab> */}
          <IonAlert
            isOpen={this.state.showSelect}
            onDidDismiss={() => this.hideAlert()}
            header="Select Pictures"
            message="Not yet implemented"
            buttons={[
              {
                text: 'ok',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {},
              },
            ]}
          />

          <input
            type="file"
            multiple
            id="image-upload-gallery"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              // await this.uploadAttachments(e)
              //@ts-ignore
              // return document.getElementById('culo').src = await convertBase64(e.target.files[0])
              let picturesArray = [...pictures];
              for (const file of e.target.files) {
                const base64Img = await BlobToBase64(file);
                let picture = await saveRawPictureToIndexedDB(
                  base64Img,
                  profile.organisationId,
                  stringifyInspectionReference(inspectionReference)
                );
                picturesArray.push(picture);
              }
              onPicturesUpdated(picturesArray);
            }}
          />

          <IonModal isOpen={!!picture} onDidDismiss={() => this.hidePicture()}>
            <PagePicture
              editable={editable}
              picture={picture}
              pictures={pictures}
              inspection={inspection}
              editMode={this.props.editMode}
              schema={this.props.schema}
              onDismiss={this.hidePicture.bind(this)}
              onPictureUpdated={this.onPictureUpdated.bind(this)}
              onPictureSelected={this.selectPicture.bind(this)}
              onPictureRemoved={this.removePicture.bind(this)}
              updateOCRData={this.props.updateOCRData}
            />
          </IonModal>
          {
            <IonModal
              isOpen={!!multiPicture}
              onDidDismiss={() => this.hidePictureMulti()}
            >
              <PagePictureMulti
                editable={editable}
                pictures={pictures}
                inspection={inspection}
                editMode={this.props.editMode}
                schema={this.props.schema}
                multi={this.state.multiPictureSelection?.pictures}
                onDismiss={this.hidePictureMulti.bind(this)}
                onPictureUpdated={this.onMultiPictureUpdated.bind(this)}
                onPictureSelected={this.selectPicture.bind(this)}
                onPictureRemoved={this.removePictures.bind(this)}
              />
            </IonModal>
          }

          {!pictures.length && (
            <div className="no-pictures">{i18n.t('PageGallery.noPictures')}</div>
          )}

          <div className="gallery-wrapper">
            {pictures.length > 0 ? (
              <>
                {Object.keys(picturesMap)
                  .sort(this.categorySorting)
                  .map((key, i) => {
                    return (
                      <div key={key}>
                        <div
                          className="group-title"
                          data-tip={`gallery-group-title-${key}`}
                        >
                          {SectionNames[key] ?? key}
                        </div>
                        <div className="gallery-container">
                          {picturesMap[key].reverse().map((picture: Picture, index) => {
                            const hasError: boolean = (errors ?? []).length > 0 && picture?.inputIds.some(
                              (id) => !(id in inspection?.userInputs)
                            );
                            return (
                              <div
                                key={index}
                                className={
                                  'gallery-item ' +
                                  (this.state.multiPictureSelection?.pictures?.indexOf(
                                    picture.id
                                  ) >= 0
                                    ? 'selected'
                                    : 'no-selected') +
                                  (hasError ? ' has-error' : '')
                                }
                                data-tip={`gallery-group-${key}-item-${index}`}
                              >
                                <div
                                  onClick={(_) => {
                                    this.selectPicture(picture);
                                  }}
                                >
                                  <ViewPicture id={picture.id} mode={'img'} />
                                </div>
                                <div className="image-meta">
                                  {picture?.inputIds
                                    ?.map((questionId) => {
                                      const questionSpecs = {
                                        ...(
                                          this.props.schema ??
                                          this.props.inspection?.renderingInfo
                                        )?.questionSpecs,
                                      }[questionId];
                                      const name =
                                        questionSpecs?.displayedName ?? questionId;
                                      return { id: questionId, name };
                                    })
                                    ?.map((q) => (
                                      <IonBadge key={q.id} color="dark">
                                        {q.name}
                                      </IonBadge>
                                    ))}
                                </div>
                                <IonIcon
                                  icon={checkmarkCircle}
                                  className="selected-icon"
                                />
                                {editable && (
                                  <IonIcon
                                    icon={closeCircleOutline}
                                    className="remove-icon"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Are you sure you want to delete this picture?'
                                        )
                                      ) {
                                        this.removePicture(picture);
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : null}

            <div className="bottom-shadow"></div>

            {this.props.editable && editMode !== 'report' && (
              <div className="fixed-button">
                <IonButton
                  data-tip={`gallery-multitag-button`}
                  color={this.state.multiPictureSelection ? 'white' : 'black'}
                  className="gallery-multitag-button"
                  onClick={(_) => {
                    this.state.multiPictureSelection
                      ? this.cancelMultipleSelection()
                      : this.startMultipleSelection();
                  }}
                >
                  Multi Edit {this.state.multiPictureSelection ? <b>ON</b> : <b>OFF</b>}
                </IonButton>
              </div>
            )}

            {editable && editMode !== 'report' && (
              <div className="fixed-button upload-image-button">
                <IonButton
                  onClick={(_) =>
                    document.getElementById('image-upload-gallery').click()
                  }
                  color="tertiary"
                  fill="solid"
                >
                  Upload images
                </IonButton>
              </div>
            )}

            {this.state.multiPictureSelection?.pictures.length > 0 && (
              <IonFab
                vertical="bottom"
                horizontal="end"
                slot="fixed"
                className="multi-tag-fab-button"
                data-tip={`gallery-multitag-tag`}
              >
                <IonFabButton color="white" onClick={(_) => this.multiTag()}>
                  <IonIcon icon={pricetags} style={{ fontSize: '24px' }} />
                </IonFabButton>
              </IonFab>
            )}
          </div>
        </IonContent>
      </Fragment>
    );
  }
}

export default withContext(PageGallery, ['profile', 'organisation']);
