import { flatten, isEqual, get } from 'lodash';
import {
  PermissionAction,
  PermissionContext,
  PermissionResource,
  UserProfile,
  UserRoleType,
  UserPermission,
  OrganisationSettings,
} from './Model';
import { formatDate } from './HelperUtils';

/***************** */
// User permissions
/***************** */

/**
 * @param {UserProfile} profile - The user profile
 * @param {PermissionAction} action - the action to be performed
 * @param {PermissionResource} action - the resource to which the action will be applied
 * @param {PermissionContext} [context={}] - context of the action (optional)
 * @param {boolean} [ignoreAdminFlag=false] - if set to true, it will ignore the admin flag and will only check whether the user has the permission
 */

export function userHasPermission(
  profile: UserProfile,
  action?: PermissionAction,
  resource?: PermissionResource,
  context: PermissionContext = {},
  ignoreAdminFlag: boolean = false
) {

  // if user has not been loaded yet, deny all access
  if (!profile) {
    return false;
  }

  // admins have full access
  if (profile.isAdmin && !ignoreAdminFlag) {
    return true;
  }

  const permissions = profile.userPermissions ?? [];

  // no action and no resource? no permission!
  if (!action || !resource) {
    return false;
  }

  const matchingRules = permissions.find(
    (r) => r.action === action && r.resources.includes(resource)
  );

  if (!!matchingRules) {
    return true;
  }

  return false;
}

export function hasRole(
  profile: UserProfile,
  userRole: UserRoleType,
  context: PermissionContext = {}
) {
  // if user has not been loaded yet, deny all access
  if (!profile) {
    return false;
  }

  return profile.userRole === userRole;
}

// returns true if user is from agrinorm and is admin
export const isAGAdmin = (profile: UserProfile) =>
  !!profile &&
  profile.isAdmin &&
  (profile?.email ?? '').toLowerCase().includes('agrinorm');
// export const isAGAdmin = (profile: UserProfile) => !!profile && profile.isAdmin && ((profile?.email ?? '').toLowerCase().includes('agrinorm') || (profile?.email ?? '').toLowerCase().includes('daria') );

export const isAdmin = (profile: UserProfile, orgId?: string) =>
  !!profile && profile.isAdmin && (!!orgId ? profile.organisationId === orgId : true);

export const hasLocationRestriction = (userProfile: UserProfile): boolean | string => {
  let locationRestriction: boolean | string = false;

  const allowedLocation = get(userProfile, 'allowedLocation');
  const isAllowedLocationUndefined = allowedLocation === undefined;
  const isAllowedLocationEmptyString = allowedLocation === '';

  if (isAllowedLocationUndefined || isAllowedLocationEmptyString) {
    locationRestriction = false;
  } else {
    locationRestriction = allowedLocation;
  }

  return locationRestriction;
};

// Used to check whether the user has the permission assigned in ComponentUsersAdmin and PageSettings
export function userHasPermissionSet(
  currPerm: IPermission,
  user: UserProfile
): boolean {
  return currPerm.permissions.reduce(
    (prev, curr) => !!user?.userPermissions?.find((p) => isEqual(curr, p)) && prev,
    true
  );
}

/***************** */
// Org permissions
/***************** */

export const isFreeQCTier = (orgSettings: OrganisationSettings) =>
  orgSettings?.productTiers?.qc === QCTier.Free;

export const isTierHigher = (
  tierToCheck: Tier,
  referenceTier: Tier,
  hierarchy: Tier[]
) => {
  // console.log("TIER CHECKFILTERRRR TOOLBAR:", tierToCheck, hierarchy.findIndex(t => t === tierToCheck), '>=', referenceTier, hierarchy.findIndex(t => t === referenceTier), '=', hierarchy.findIndex(t => t === tierToCheck) >= hierarchy.findIndex(t => t === referenceTier))
  return (
    hierarchy.findIndex((t) => t === tierToCheck) >=
    hierarchy.findIndex((t) => t === referenceTier)
  );
};

export function orgHasPermission(
  orgSettings: OrganisationSettings,
  appProduct: AppProduct,
  action: TierAction
): boolean {
  const orgTier = orgSettings?.productTiers?.[appProduct];

  if (orgTier == null) {
    return false;
  }

  const { permissions, hierarchy } = orgPermissionMap[appProduct];

  const actionTier = permissions[action];

  // user has permission to perform the action if the org's product tier is equal or greater than the action tier
  return isTierHigher(orgTier, actionTier, hierarchy);
}

export function isTestAccount(orgSettings: OrganisationSettings): boolean {
  return !!orgSettings?.testAccountSettings?.isTestAccount;
}

export function testExpirationDate(orgSettings: OrganisationSettings): string {
  const date = formatDate(
    orgSettings?.testAccountSettings?.testStartDate,
    {},
    true
  ) as Date;
  return formatDate(date.setDate(date.getDate() + 14)) as string;
}

/***************** */
// Model and data
/***************** */

// PRODUCTS
export const AppProduct = {
  QualityControl: 'qc',
  Insights: 'insights',
} as const;
export type AppProduct = (typeof AppProduct)[keyof typeof AppProduct];

// TIERS
export const QCTier = {
  Free: 'free',
  Tier1: 't1',
  Tier2: 't2',
  Tier3: 't3',
} as const;
export type QCTier = (typeof QCTier)[keyof typeof QCTier];

// hierarchy from lowest to highest
export const qcTierHierarchyOrder: QCTier[] = [
  QCTier.Free,
  QCTier.Tier1,
  QCTier.Tier2,
  QCTier.Tier3,
];

export const InsightsTier = {
  Tier1: 't1',
} as const;
export type InsightsTier = (typeof InsightsTier)[keyof typeof InsightsTier];

// hierarchy from lowest to highest
export const insightsTierHierarchyOrder: InsightsTier[] = [InsightsTier.Tier1];

// ACTIONS
export const QCTierAction = {
  Dashboard: 'dashboard',
  EmailNotification: 'email_notification',
  BarcodeScan: 'barcode_scan',
  LabelReader: 'label_reader',
  Chat: 'chat',
} as const;
export type QCTierAction = (typeof QCTierAction)[keyof typeof QCTierAction];

export const InsightsTierAction = {
  Partners: 'partners',
  Insights: 'insights',
} as const;
export type InsightsTierAction =
  (typeof InsightsTierAction)[keyof typeof InsightsTierAction];

type InsightsPermissions = { [key in InsightsTierAction]: InsightsTier };
const insightsPermissionMap: InsightsPermissions = {
  [InsightsTierAction.Insights]: InsightsTier.Tier1,
  [InsightsTierAction.Partners]: InsightsTier.Tier1,
};

type QCPermissions = { [key in QCTierAction]: QCTier };
const qcPermissionMap: QCPermissions = {
  [QCTierAction.BarcodeScan]: QCTier.Tier2,
  [QCTierAction.Dashboard]: QCTier.Tier2,
  [QCTierAction.EmailNotification]: QCTier.Tier2,
  [QCTierAction.LabelReader]: QCTier.Tier2,
  [QCTierAction.Chat]: QCTier.Tier3,
};

// Union types
export type Tier = QCTier | InsightsTier;
export type TierAction = QCTierAction | InsightsTierAction;
export type OrgPermissions = QCPermissions | InsightsPermissions;

export interface OrgPermission {
  permissions: OrgPermissions;
  hierarchy: Tier[];
}

export interface ProductTiers {
  [AppProduct.QualityControl]: QCTier | null; // null means no access
  [AppProduct.Insights]: InsightsTier | null;
}

export const orgPermissionMap: { [key in AppProduct]: OrgPermission } = {
  [AppProduct.QualityControl]: {
    permissions: qcPermissionMap,
    hierarchy: qcTierHierarchyOrder,
  },
  [AppProduct.Insights]: {
    permissions: insightsPermissionMap,
    hierarchy: insightsTierHierarchyOrder,
  },
};

// Mapping from UI options to actual permissions
export interface UserPermissionMapping {
  [permissionString: string]: IPermission;
}

export interface IPermission {
  permissions: UserPermission[];
  uiTitle: string;
  uiDescription: string;
  index: number; // defines the orden in which they'll be displayed
  section: PermissionSection;
  displayRules: { appProduct: AppProduct; minTier: Tier }; // used to decide whether to show or not the user permissions in the admin section depending on the org product tiers
}

export const roleOptions = [
  {
    value: 'QC_HERO',
    label: 'Quality Control',
  },
  {
    value: 'COMMERCIAL',
    label: 'Commercial',
  },
  {
    value: 'MEMBER',
    label: 'Member',
  },
  // {
  //   value: 'ORGANISATION_ADMIN',
  //   label: 'Admin'
  // }
];

type PermissionSection =
  | 'General'
  | 'Inspections'
  | 'Reports'
  | 'Commercial'
  | 'Insights'
  | 'Surveying';

export const userPermissionsMapping: UserPermissionMapping = {
  // View data: orders, lots  (filtered by products, locations, stage etc)
  perm1: {
    index: 0,
    permissions: [{ action: 'VIEW', resources: ['LOT', 'ORDER'] }],
    uiTitle: 'View batches / orders',
    uiDescription:
      'View batches and orders cards in the different stage tabs and in the commercial dashboard. For example, view orders and their associated batches in the incoming tab',
    section: 'General',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // View reports: orders, lots (filtered by products, locations, stage etc)
  perm2: {
    index: 1,
    permissions: [{ action: 'VIEW', resources: ['REPORT'] }],
    uiTitle: 'View reports',
    uiDescription:
      'View quality reports associated with batches and/or orders in the different stages. For example view an incoming quality report associated with an incoming order',
    section: 'Reports',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Free,
    },
  },

  // Write quality data: create inspection, QC lot DONE/OPEN/RE-OPEN status, and order QC status: DONE/OPEN, copy inspections  (filtered by products, locations, stage etc)
  perm3: {
    index: 2,
    permissions: [
      {
        action: 'WRITE',
        resources: ['ASSESSMENT', 'QC_LOT_STATUS', 'QC_ORDER_STATUS'],
      },
    ],
    uiTitle: 'Create/edit/copy inspections',
    uiDescription:
      'Create inspections for batches based on pre-defined schemas in the different stages. Set the batch level QC statuses:  DONE / OPEN / RE-OPEN and re-inspect batches. Set the order level QC statuses: DONE / OPEN. Copy inspections from other batches. For example, create incoming inspection for a batch, set the order status to DONE',
    section: 'Inspections',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Revert to a version in the history
  perm4: {
    index: 3,
    permissions: [{ action: 'REVERT', resources: ['ASSESSMENT'] }],
    uiTitle: 'Revert an inspection version',
    uiDescription:
      'Open an inspection, browse the version history and set the inspection to a previous version',
    section: 'Inspections',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Upload pictures and documents
  perm5: {
    index: 4,
    permissions: [{ action: 'UPLOAD', resources: ['PICTURE', 'DOCUMENT'] }],
    uiTitle: 'Upload pictures / documents',
    uiDescription: 'Upload pictures and documents to an inspection or report',
    section: 'Reports',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Specifcation builder (& scoring) read, write and share with other organization **** only for org admin
  perm6: {
    index: 5,
    permissions: [{ action: 'WRITE', resources: ['SPECIFICATIONS'] }],
    uiTitle: 'Create/Edit/Share specifications',
    uiDescription:
      'Create and edit quality specifications, including automatically assigned question level and inspection level scores (when applicable). Share your organization schemas with seller organizations (when applicable)',
    section: 'Inspections',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Split batch due to mixed quality
  perm7: {
    index: 6,
    permissions: [{ action: 'SPLIT', resources: ['LOT'] }],
    uiTitle: 'Split batch and other batch operations',
    uiDescription:
      'Split a batch due to mixed quality. Determine the quantities and pallets (when applicable) of the two child batches. Note that this feature requires enabling the splitting of batches at the organization settings',
    section: 'Inspections',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Setting commercial order statuses (CHECKED, SHARED, REDO) and lot level commercial REDO and custom (user defined) lot level statuses
  perm8: {
    index: 7,
    permissions: [
      {
        action: 'WRITE',
        resources: ['COMMERCIAL_LOT_STATUS', 'COMMERCIAL_ORDER_STATUS', 'REPORT'],
      },
    ],
    uiTitle: 'Create reports / set commercial statuses',
    uiDescription:
      'Create reports from inspections and set and subscribe to commercial batch and order level statuses, including CHECKED / SHARED / REDO',
    section: 'Commercial',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Comminicate regarding orders/lots/reports internally via chat, or otherwise
  perm9: {
    index: 8,
    permissions: [{ action: 'SHARE_INT', resources: ['ORDER', 'REPORT', 'LOT'] }],
    uiTitle: 'Share report/order/batch internally',
    uiDescription:
      'Exchange information about reports / orders / batches within your organizations, via chat or email',
    section: 'General',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier3,
    },
  },

  // Share reports externally via email and in the system (later in the chat)
  perm10: {
    index: 9,
    permissions: [{ action: 'SHARE_EXT', resources: ['REPORT'] }],
    uiTitle: 'Share reports externally',
    uiDescription:
      'Share reports with your organizations buyers and sellers. Share reports via email or directly in the system (when applicable)',
    section: 'Commercial',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Access partners profiles (insights)
  perm11: {
    index: 10,
    permissions: [{ action: 'VIEW', resources: ['INSIGHT_PROFILES'] }],
    uiTitle: 'View partners insights',
    uiDescription: 'Access profiles of suppliers or buyers',
    section: 'Insights',
    displayRules: {
      appProduct: AppProduct.Insights,
      minTier: InsightsTier.Tier1,
    },
  },

  // Write/edit partners contact details, including inviting  (access to the admin module)
  perm12: {
    index: 11,
    permissions: [{ action: 'WRITE', resources: ['ADMIN_PARTNERS'] }],
    uiTitle: 'Create/Edit/Invite partner organizations',
    uiDescription:
      'Create and edit the details of partner organizations. Invite users from the partner organizations to collaborate with your organization',
    section: 'Commercial',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier2,
    },
  },

  // Creation of lots and orders*** (should be dependant on the org type)
  perm13: {
    index: 12,
    permissions: [{ action: 'CREATE', resources: ['LOT', 'ORDER'] }],
    uiTitle: 'Create order/batch',
    uiDescription:
      'Create batches and orders from Agrinorm application. Note that this feature requires enabling the creation of batches and orders at the organization settings',
    section: 'General',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Edit lots and orders*** (should be dependant on the org type)
  perm14: {
    index: 13,
    permissions: [{ action: 'WRITE', resources: ['LOT', 'ORDER'] }],
    uiTitle: 'Edit order/batch',
    uiDescription:
      'Edit batches and orders details in the Agrinorm application. Note that this feature requires enabling the creation of batches and orders at the organization settings',
    section: 'General',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  perm14b: {
    index: 13.5,
    permissions: [{ action: 'DELETE', resources: ['LOT', 'ORDER'] }],
    uiTitle: 'Delete order/batch',
    uiDescription:
      'Delete batches and orders in the Agrinorm application. Note that this feature requires enabling the creation of batches and orders at the organization settings',
    section: 'General',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Linking and un-linking of lots and orders to a customer/supplier lots
  perm15: {
    index: 14,
    permissions: [{ action: 'LINK', resources: ['LOT', 'ORDER'] }],
    uiTitle: 'Link order/batch',
    uiDescription:
      "Link and unlink orders and batches of your organization to your buyer's orders/batches identifiers. For example, in the outgoing orders you can view and link to your buyer's order and batch identifiers. Note that this requires your buyer's organization to be a partner of your organization",
    section: 'General',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },

  // Access to QC inspection related insights
  perm16: {
    index: 15,
    permissions: [{ action: 'VIEW', resources: ['INSIGHT_QC'] }],
    uiTitle: 'View QC insights',
    uiDescription: 'Access to quality control related insights',
    section: 'Insights',
    displayRules: {
      appProduct: AppProduct.Insights,
      minTier: InsightsTier.Tier1,
    },
  },

  // Access to insights (explore + profiles)
  perm17: {
    index: 16,
    permissions: [
      { action: 'VIEW', resources: ['INSIGHT_EXPLORE', 'INSIGHT_PROFILES'] },
    ],
    uiTitle: 'View insights',
    uiDescription:
      'Access to the all of the insights sections except for quality control related insights',
    section: 'Insights',
    displayRules: {
      appProduct: AppProduct.Insights,
      minTier: InsightsTier.Tier1,
    },
  },

  // Access to dashboard
  perm18: {
    index: 17,
    permissions: [{ action: 'VIEW', resources: ['DASHBOARD'] }],
    uiTitle: 'View commercial dashboard',
    uiDescription: 'View the commercial dashboard with order/batches statuses',
    section: 'Commercial',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier2,
    },
  },

  perm19: {
    index: 18,
    permissions: [{ action: 'DO', resources: ['SURVEYING'] }],
    uiTitle: 'Surveying permissions',
    uiDescription: 'Create supply chain batches and reports',
    section: 'Surveying',
    displayRules: {
      appProduct: AppProduct.QualityControl,
      minTier: QCTier.Tier1,
    },
  },
};

export function computePermissions(permNameArray: string[]) {
  return flatten(
    permNameArray.map((pId) => userPermissionsMapping[pId]?.permissions ?? [])
  );
}

// DEFAULT PERMISSIONS PER ROLE

const defaultMemberPermissions: UserPermission[] = computePermissions(['perm1']);

const defaultQCHeroPermissions: UserPermission[] = computePermissions([
  'perm1',
  'perm2',
  'perm3',
  'perm4',
  'perm5',
  'perm9',
  'perm13',
  'perm14',
  'perm15',
]);

const defaultCommercialPermissions: UserPermission[] = computePermissions([
  'perm1',
  'perm2',
  'perm5',
  'perm8',
  'perm9',
  'perm10',
  'perm11',
  'perm12',
  'perm13',
  'perm14',
  'perm15',
  'perm17',
  'perm18',
]);

export const defaultPermissions: { [key in UserRoleType]?: UserPermission[] } = {
  COMMERCIAL: defaultCommercialPermissions,
  QC_HERO: defaultQCHeroPermissions,
  MEMBER: defaultMemberPermissions,
};
