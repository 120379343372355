import { Capacitor } from '@capacitor/core';
import { IonFab, IonFabButton, IonIcon, IonModal, isPlatform } from '@ionic/react';
import { cameraOutline, cloudUploadOutline } from 'ionicons/icons';
import React, { useContext, useImperativeHandle, useState } from 'react';
import { ctxOrg, ctxProfile } from '../App';
import { saveRawPictureToIndexedDB } from '../DataImage';
import { BlobToBase64 } from '../HelperUtils';
import { Inspection, LotProperties } from '../InspectionModel';
import { Picture } from '../Model';
import PageCamera from '../PageCamera';
import { stringifyInspectionReference } from '../ServiceInspection';
import { InspectionSpecSection } from '../generated/openapi/core';

// Styling
import '../PageInspection.scss';
import { InspectionSpec } from '../ModelSpecification';

interface PropsCameraButtons {
  onPicturesUpdated: (pic: Picture[]) => void;
  onScan: Function;
  isEditable: boolean;
  schema: InspectionSpec;
  ref: any;
  cameraMode: boolean;
  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;
  inspection: Inspection;
  onNativePicture: (section?: InspectionSpecSection) => void;
  expectedBarcodes?: string[];
}

export const CInspectionCameraButtons: React.FC<PropsCameraButtons> = React.forwardRef(
  (
    {
      isEditable,
      schema,
      onPicturesUpdated,
      onScan,
      cameraMode,
      updateOCRData,
      inspection,
      onNativePicture,
      expectedBarcodes,
    },
    ref
  ) => {
    const [isOpen, setOpen] = useState(false);
    const [pictureSection, setPictureSection] =
      useState<InspectionSpecSection>(undefined);
    const profile = useContext(ctxProfile);

    const pictures = inspection?.pictures ?? [];

    // this handle defines the interface to call this component by ref
    useImperativeHandle(ref, () => ({
      openCamera(section?: InspectionSpecSection) {
        if (section) setPictureSection(section);
        setOpen(true);
      },

      closeCamera() {
        closeCamera();
      },
    }));

    const closeCamera = () => {
      setOpen(false);
      setPictureSection(undefined);
    };

    const org = useContext(ctxOrg);

    if (!isEditable) return null;

    return (
      <>
        <input
          type="file"
          multiple
          id="imageFile"
          capture="environment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            let picturesArray = [...pictures];
            for (const file of e.target.files) {
              const base64Img = await BlobToBase64(file);
              let picture = await saveRawPictureToIndexedDB(
                base64Img,
                profile.organisationId,
                stringifyInspectionReference(inspection.reference)
              );
              picturesArray.push(picture);
            }
            onPicturesUpdated(picturesArray);
          }}
        />
        <IonModal
          isOpen={isOpen}
          onDidDismiss={closeCamera}
          cssClass={'modal-camera'}
          animated={false}
        >
          <PageCamera
            profile={profile}
            organisationId={profile.organisationId}
            editable={isEditable}
            cameraTag={pictureSection}
            pictures={pictures}
            schema={schema}
            onDismiss={closeCamera}
            lastPictureTaken={pictures[pictures.length - 1]}
            picturesUpdated={onPicturesUpdated}
            expectedBarcodes={expectedBarcodes}
            onScan={onScan}
            updateOCRData={updateOCRData}
            inspection={inspection}
            orgSettings={org?.settings}
          />
        </IonModal>

        <IonFab
          vertical="bottom"
          horizontal="center"
          slot="fixed"
          className={'big camera-assessment ' + (isPlatform('tablet') && 'tablet')}
        >
          <IonFabButton
            color="camera"
            className="big"
            data-tip={'inspection-camera-button'}
            onClick={async () => {
              if (Capacitor.isNativePlatform()) {
                onNativePicture();
              } else {
                if (!cameraMode) document.getElementById('imageFile').click();
                else setOpen(true);
              }
            }}
          >
            {cameraMode ? (
              <IonIcon icon={cameraOutline} style={{ fontSize: '36px' }} />
            ) : (
              <IonIcon
                icon={isPlatform('desktop') ? cloudUploadOutline : cameraOutline}
                style={{ fontSize: '36px' }}
              />
            )}
          </IonFabButton>
        </IonFab>
      </>
    );
  }
);
