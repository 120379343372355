/**
 * Various utility functions used for creating and evaluting partial summaries.
 */

import { OptionsType, OptionTypeBase } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { isQuestionSpec, scoreLabels } from '../Model';
import { MeasurementUnitName } from '../ModelSpecification';
import {
  LayoutInner,
  MeasurablesInner1,
  MeasurementUnit,
  QuestionSpecIn,
  QuestionTagList,
  SectionLayoutSubsectionItem,
} from '../generated/openapi/core';

export function getMeasurementUnitName(unit: string): string {
  if (unit === 'bool') {
    return 'Yes/No';
  }
  const unitType = Object.keys(MeasurementUnit).find(
    (key) => MeasurementUnit[key] === unit
  );
  if (unitType === undefined) {
    return '';
  }
  return MeasurementUnitName[unitType];
}

const aggregationOptions = [
  { label: 'Sum', value: 'sum' },
  { label: 'Average', value: 'mean' },
  { label: 'Minimum', value: 'min' },
  { label: 'Maximum', value: 'max' },
  { label: 'Count questions w/ score <=', value: 'count_le' },
  { label: 'Count questions w/ score >=', value: 'count_ge' },
];

type AggregationOption = 'sum' | 'mean' | 'min' | 'max' | 'count_le' | 'count_ge';

// The id looks like "partial_summary~<tagId>~<aggregation>~<unit>[=scores]?~<unique_id>"
const aggregationValues = aggregationOptions.map((option) => option.value);
const questionIdFormat = new RegExp(
  `^partial_summary~(.+)~(${aggregationValues.join('|')})~([^=]+)(=\\d)?~(.+)$`
);

export interface QuestionTagQuestionSpecInfo {
  tag: string;
  questionSpecDisplayName: string;
  questionSpecUnits: string[];
}
/**
 * Returns the list of question tags used in the given layout.
 */
export const questionTagsFromLayout = (
  layout: (LayoutInner | QuestionSpecIn)[]
): QuestionTagQuestionSpecInfo[] => {
  const flatList: QuestionTagQuestionSpecInfo[] = layout?.flatMap((item) => {
    if (isQuestionSpec(item)) {
      const questionSpec = item as QuestionSpecIn;
      let measurable: MeasurablesInner1 | undefined = questionSpec.measurables?.find(
        (m) => m.isPrimary
      );
      if (!measurable && questionSpec.measurables?.length > 0) {
        measurable = questionSpec.measurables[0];
      }

      // We can have two units for a question: either the unit for the primary
      // measurable, or 'score' if the question has a scoring policy.
      const units: MeasurementUnit[] = [];
      const primaryMeasurableUnit =
        measurable && 'unit' in measurable.measurement
          ? measurable.measurement.unit
          : // : measurable.measurement.type === BooleanMeasurementValueTypeEnum.Boolean
            // ? 'bool'
            undefined;
      if (primaryMeasurableUnit !== undefined) {
        units.push(primaryMeasurableUnit);
      }
      if (questionSpec.scoringPolicy != null) {
        units.push(MeasurementUnit.Score);
      }
      // Questions without units cannot participate in summaries.
      if (units.length === 0) {
        return [];
      }
      return (questionSpec.questionTagIds ?? []).map((tag) => {
        return {
          tag: tag,
          questionSpecDisplayName: questionSpec.displayedName,
          questionSpecUnits: units,
        };
      });
    } else {
      return questionTagsFromLayout(
        (item as SectionLayoutSubsectionItem).questionSpecs
      );
    }
  });
  return flatList;
};

export function getPossibleUnits(
  questionTags: QuestionTagQuestionSpecInfo[],
  tag: string | undefined
): OptionsType<OptionTypeBase> {
  let units = new Set<string>();
  questionTags.forEach((qt: QuestionTagQuestionSpecInfo) => {
    if (qt.tag === tag) {
      qt.questionSpecUnits.forEach((u) => units.add(u));
    }
  });
  const returnUnits = Array.from(units.values()).map((u) => ({
    value: u,
    label: getMeasurementUnitName(u),
  }));
  return returnUnits;
}

export function getPossibleAggregationOptions(
  unit: string
): OptionsType<OptionTypeBase> {
  if (unit === 'score') {
    return aggregationOptions.filter((option) =>
      isScoreBasedAggregation(option.value as AggregationOption)
    );
  } else {
    return aggregationOptions.filter(
      (option) => !isScoreBasedAggregation(option.value as AggregationOption)
    );
  }
}

export function isScoreBasedAggregation(aggregation: AggregationOption): boolean {
  return ['count_le', 'count_ge'].includes(aggregation);
}

export class PartialSummarySpec {
  tag: string | undefined;
  aggregation: AggregationOption | undefined;
  unit: string | undefined;
  // When the aggregation is 'count', we also need the question level score range that
  // contribute to the count.
  scoreThreshold: number | undefined;
  uniqueId: string;

  public constructor(other?: PartialSummarySpec) {
    if (other) {
      this.tag = other.tag;
      this.aggregation = other.aggregation;
      this.unit = other.unit;
      this.uniqueId = other.uniqueId;
      this.scoreThreshold = other.scoreThreshold;
    } else {
      this.uniqueId = uuidv4();
      this.tag = undefined;
      this.aggregation = undefined;
      this.unit = undefined;
      this.scoreThreshold = undefined;
    }
  }

  public getIdString(): string {
    if (!this.isFullySpecified()) {
      return '';
    }
    if (isScoreBasedAggregation(this.aggregation)) {
      return [
        'partial_summary',
        this.tag,
        this.aggregation,
        this.unit + (this.scoreThreshold ? `=${this.scoreThreshold}` : ''),
        this.uniqueId,
      ].join('~');
    }

    return [
      'partial_summary',
      this.tag,
      this.aggregation,
      this.unit,
      this.uniqueId,
    ].join('~');
  }

  public parseFromIdString(idString: string) {
    const match = idString.match(questionIdFormat);
    if (match) {
      this.tag = match[1];
      this.aggregation = match[2] as AggregationOption;
      this.unit = match[3];
      if (match[4] !== undefined && match[4].startsWith('=')) {
        this.scoreThreshold = parseInt(match[4].substring(1));
      }
      this.uniqueId = match[5];
    }
    return this;
  }

  public isFullySpecified(): boolean {
    return (
      this.tag !== undefined &&
      this.aggregation !== undefined &&
      this.unit !== undefined &&
      // When the aggregation is count, the score range must be specified.
      (!isScoreBasedAggregation(this.aggregation) || this.scoreThreshold !== undefined)
    );
  }

  public getAggregationOption(): OptionTypeBase | undefined {
    return aggregationOptions.find((option) => option.value === this.aggregation);
  }
}

export class PartialSummaryGenerator {
  private questionTagList: QuestionTagList | undefined;
  private originalSpec: PartialSummarySpec = new PartialSummarySpec();

  public constructor(
    questionTagList: QuestionTagList | undefined,
    questionSpec: QuestionSpecIn
  ) {
    this.questionTagList = questionTagList;
    if (questionSpec.measurables?.length > 0) {
      this.originalSpec.parseFromIdString(questionSpec.measurables[0].measurableId);
    }
  }

  public getOriginalSpec(): PartialSummarySpec {
    return this.originalSpec;
  }

  public onUpdateQuestionSpec(input: {
    questionSpec: QuestionSpecIn;
    partialSummarySpec: PartialSummarySpec;
  }): QuestionSpecIn {
    const measurables: MeasurablesInner1[] = this.generateMeasurable(
      input.partialSummarySpec
    );
    const newQuestionSpec = {
      ...input.questionSpec,
      questionId: this.getQuestionId(input.partialSummarySpec),
      isPartialSummary: true,
      displayedName: this.getDisplayName(input.partialSummarySpec),
      measurables: measurables,
    } as QuestionSpecIn;
    return newQuestionSpec;
  }

  private getDisplayName(input: PartialSummarySpec): string | undefined {
    let tagName: string | undefined = undefined;
    if (input.tag !== undefined && this.questionTagList !== undefined) {
      tagName = this.getTagName(input.tag);
    } else {
      return undefined;
    }
    if (input.aggregation === undefined) {
      return tagName;
    }
    const aggregationLabel = aggregationOptions.find(
      (o) => o.value === input.aggregation
    )?.label;

    switch (input.aggregation) {
      case 'count_le':
        return `Count [${tagName}] questions w/ score <= ${
          scoreLabels[input.scoreThreshold]
        }`;

      case 'count_ge':
        return `Count [${tagName}] questions w/ score >= ${
          scoreLabels[input.scoreThreshold]
        }`;
      default:
        return `${aggregationLabel} [${tagName}]`;
    }
  }

  private getTagName(tagId: string): string | undefined {
    return this.questionTagList.questionTags.find((t) => t.id === tagId)?.name;
  }

  private getQuestionId(input: PartialSummarySpec): string {
    return input.getIdString();
  }

  private generateMeasurable(input: PartialSummarySpec): MeasurablesInner1[] {
    if (!input.isFullySpecified()) {
      return [];
    }
    const newMeasurable: MeasurablesInner1 = {
      type: 'computed',
      measurableId: this.getQuestionId(input),
      isPrimary: true,
      formula: this.generateFormula(input),
      measurement: {
        type: 'float',
        unit: isScoreBasedAggregation(input.aggregation)
          ? MeasurementUnit.Question
          : (input.unit as MeasurementUnit),
        valueType: 'float',
      },
    };
    return [newMeasurable];
  }

  private generateFormula(spec: PartialSummarySpec): string {
    // The generic formula without score categories.
    if (!isScoreBasedAggregation(spec.aggregation)) {
      const { tag, aggregation, unit } = spec;
      return `${aggregation}({tag|${tag}:${unit}})`;
    }
    // Count formula with score categories. We use range_map to map the interesting
    // categories to 1 and the rest to 0, and we can aggregate it with sum.
    const ranges: string = Object.keys(scoreLabels)
      .map((s) => {
        const val = parseInt(s) || 0;
        const shouldInclude: boolean =
          (spec.aggregation === 'count_ge' && val >= spec.scoreThreshold) ||
          (spec.aggregation === 'count_le' && val <= spec.scoreThreshold);
        return `[${val - 1},${val},${shouldInclude ? 1 : 0}]`;
      })
      .join(',');
    return `sum(<AG>range_map({tag|${spec.tag}:score},[${ranges}])</AG>)`;
  }
}
