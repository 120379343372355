import {
  IonBadge,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonRouterLink
} from '@ionic/react';
import { earthOutline, homeOutline } from 'ionicons/icons';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { ctxContacts } from './App';
import { CardImages } from './CardImages';
import { ProductionSiteLocation } from './Model';
import { useLocationUtils } from './hooks/useLocationUtils';

interface Props {
  field: ProductionSiteLocation;
}

export const CardField = ({ field }: Props) => {
  const contacts = useContext(ctxContacts);
  const history = useHistory();

  const { buildLocationDetailsUrl, getProductionTypeLabel, buildGoogleMapsUrl } =
    useLocationUtils();

  const { pictures = [] } = field.latestInspectionPreview ?? {};

  const getLink = (e) => {
    e.stopPropagation();
    history.push(buildLocationDetailsUrl(field.locationId));
  };

  const associatedPartnerId: string | undefined = contacts.find(
    (c) => c.id === field.associatedPartnerId
  )?.name;

  return (
    <div className="ion-card card-inspection" onClick={getLink}>
      <div className="cover">
        <CardImages pictures={pictures} max={5} />
      </div>
      <div className="card-inspection-wrapper">
        <IonCardHeader>
          <div>
            {field.geoPoint && (
              <IonRouterLink
                href={buildGoogleMapsUrl(field.geoPoint)}
                target="_blank"
                rel="noreferrer"
              >
                <IonButton
                  size="small"
                  fill="clear"
                  color="blue"
                  style={{ position: 'absolute', right: '1px', top: '12px' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <IonIcon icon={earthOutline} />
                </IonButton>
              </IonRouterLink>
            )}
            <IonCardTitle className="card-title" style={{ alignItems: 'center' }}>
              <IonIcon
                style={{ pointerEvents: 'none', marginRight: 5 }}
                icon={homeOutline}
              />
              {field.name}
            </IonCardTitle>

            <div className="date-report-type">
              {!!associatedPartnerId && (
                <IonBadge color="success">{associatedPartnerId}</IonBadge>
              )}
              <IonBadge color="dark">
                {getProductionTypeLabel(field.productionType)}
              </IonBadge>
            </div>
            <div className="date-report-type">
              {(field.producedVarieties ?? []).map((v, i) => (
                <IonBadge key={i} color="tertiary">
                  {v.agProductId} | {v.variety}
                </IonBadge>
              ))}
            </div>
          </div>
        </IonCardHeader>
      </div>
    </div>
  );
};