import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useContext } from 'react';
import { ctxOrg } from './App';
import './PageLot.scss';
import './PageOrder.scss';
import i18n from './ServiceI18n';

interface Props {
  noHeader?: boolean;
}

const PageMissingPermissions = (props: Props) => {
  const org = useContext(ctxOrg);

  const renderBackButton = () => (
    <IonBackButton
      text={i18n.t('General.back')}
      defaultHref="/tabs/quality-control"
      color="dark"
    >
      <IonIcon icon={chevronBackOutline} slot="icon-only" />
    </IonBackButton>
  );

  return (
    <IonPage>
      {!props.noHeader && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" className="no-print">
              {renderBackButton()}
            </IonButtons>
            <IonTitle>
              {!org ? 'Checking permissions...' : 'Missing permissions!'}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent id="page-order-content">
        {!org && <IonSpinner name="dots" className="dots-spinner" />}
        {!!org && (
          <IonItem>
            You don't have the necessary permissions to access the requested page.
            Please contact your administrator
          </IonItem>
        )}
        {props.noHeader && <IonItem>{renderBackButton()}</IonItem>}
      </IonContent>
    </IonPage>
  );
};

export default PageMissingPermissions;
