import React from 'react';

interface Props {
  percentage: number;
  width?: number | 'max';
  height?: number;
  showNumber?: boolean;
  roundDecimals?: number;
}

const CProgressBar = ({
  percentage,
  width = 'max',
  height = 16,
  showNumber = true,
  roundDecimals = 0,
}: Props) => {
  const containerStyles: React.CSSProperties = {
    width: typeof width === 'number' ? `${width}px` : '100%',
    height: `${height}px`,
    borderRadius: '3px',
    border: '1px solid var(--ion-color-dark)',
    position: 'relative',
    overflow: 'hidden',
    margin: '5px 0px',
  };

  const fillStyles: React.CSSProperties = {
    width: `${percentage}%`,
    height: '100%',
    backgroundColor: 'var(--ion-color-primary)',
    transition: 'width 0.3s ease',
  };

  const labelStyles: React.CSSProperties = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    lineHeight: `${height}px`,
    color: '#000',
    fontSize: `${Math.abs(height * 0.7)}px`,
  };

  return (
    <div style={containerStyles}>
      <div style={fillStyles}></div>
      {showNumber && (
        <div style={labelStyles}>{percentage.toFixed(roundDecimals)}%</div>
      )}
    </div>
  );
};

export default CProgressBar;
