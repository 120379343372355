import { IonSelect, IonSelectOption } from '@ionic/react';
import React, { useContext } from 'react';
import { ctxSpecs } from '../App';
import { InspectionReference } from '../InspectionModel';
import {
  InspectionTypeName,
  inspectionTypesPerObjectType,
} from '../ModelSpecification';
import { getObjectTypeFromReference } from '../ServiceInspection';
import { useInspectionContext } from '../context/InspectionContextProvider';
import { FieldSchemaObjectTypeEnum, InspectionType } from '../generated/openapi/core';
import './CLotInspectionHeader.scss';

interface InspectionTypeProps {
  inspectionReference: InspectionReference;
  updateInspectionType: (evt: CustomEvent) => Promise<void>;
  disableAssessmentTypeSelect?: boolean;
}

export function CInspectionTypeSelector({
  inspectionReference,
  updateInspectionType,
  disableAssessmentTypeSelect,
}: InspectionTypeProps) {
  const { inited } = useInspectionContext();
  const { inspectionSpecs } = useContext(ctxSpecs);

  const inspectionTypeOptions = inspectionTypesPerObjectType[
    getObjectTypeFromReference(inspectionReference)
  ]
    .filter((type) => {
      return (
        getObjectTypeFromReference(inspectionReference) ===
          FieldSchemaObjectTypeEnum.Field ||
        inspectionSpecs
          ?.flatMap((s) => s.criteria?.articleProperties?.inspectionTypes)
          .includes(type as InspectionType)
      );
    })
    .map((type) => ({
      type,
      name: InspectionTypeName[type],
    }));

  const disableSelect =
    (disableAssessmentTypeSelect != null && disableAssessmentTypeSelect) ||
    inspectionTypeOptions.length <= 1;

  return (
    <div className="ion-item">
      <div className="ion-label" color="dark">
        Inspection type
      </div>
      <IonSelect
        interfaceOptions={{ cssClass: 'schema-selector' }}
        data-tip={'assessment-type-selector'}
        className="inspection-header-selector"
        interface="action-sheet"
        value={inspectionReference.type}
        placeholder={!inited ? 'Loading...' : 'Select'}
        onIonChange={updateInspectionType}
        disabled={disableSelect}
      >
        {inspectionTypeOptions.map(({ name, type }) => (
          <IonSelectOption key={type} value={type}>
            {name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </div>
  );
}
