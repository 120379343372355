import { IonDatetime } from '@ionic/react';
import React, { useRef } from 'react';
import { standardizeDate } from '../HelperUtils';

interface DateTimeProps {
  dateValue: Date | undefined;
  onChange: (d: Date | undefined) => void;
  placeholder?: string;
}
export const ClearableDateTime = ({
  dateValue,
  onChange,
  placeholder = '(optional)',
}: DateTimeProps) => {
  const dateRef = useRef<HTMLIonDatetimeElement>(null);

  return (
    <IonDatetime
      pickerOptions={{
        buttons: [
          {
            text: 'Clear',
            cssClass: 'alert-button danger-button',
            handler: (e) => {
              dateRef.current.value = '';
              onChange(undefined);
            },
          },
          {
            text: 'Done',
            handler: (evt) => {
              const date = `${evt.year.text}/${evt.month.text}/${evt.day.text}`;
              onChange(new Date(date));
            },
          },
        ],
      }}
      ref={dateRef}
      displayFormat="DD/MM/YYYY"
      placeholder={placeholder}
      value={!!dateValue ? standardizeDate(dateValue).toDateString() : ''}
    />
  );
};
