import React, { useState, KeyboardEventHandler, useRef, useEffect } from 'react';
import { getValueAndUnitFromLocator } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import { FloatMeasurement, IntMeasurement } from '../generated/openapi/core';
import { renderUnit } from '../ServiceInspection';
import './CInput.scss';
import { useInspectionContext } from '../context/InspectionContextProvider';
import { IonButton } from '@ionic/react';
import { isMobile } from '../HelperUtils';

const regExpNumber = /^[+-]?((\d*(\.\d*)?)|(\.\d+))$/;

// --------------------------------------------------------------------------------------------------------
// CInput
// --------------------------------------------------------------------------------------------------------
interface ICInput {
  id: string;
  locator: UserInputLocator;
  index: number;
  measurement: FloatMeasurement | IntMeasurement;
  showDisplayName?: boolean;
  toggleInput?: () => void;
}
export const CInput = (props: ICInput) => {
  const { id, index, measurement, locator, showDisplayName, toggleInput } = props;
  // console.log('measurement', measurement)
  const { inspection, updateInspection } = useInspectionContext();
  const [lastValue, setLastValue] = useState(null);
  const [hasFocus, setHasFocus] = useState(false);

  const inputRef = useRef(null);

  // this prevents the input value to change when the mousewheel is used
  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const input = inputRef.current;
    input.addEventListener('wheel', handleWheel);

    if (!!toggleInput) {
      input.focus();
    }

    return () => {
      input.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const { displayedName, unit, valueType, minValue, granularity } = measurement as
    | FloatMeasurement
    | IntMeasurement;

  const showNegateButton =
    (minValue == null || (!isNaN(minValue) && minValue < 0)) && isMobile();

  const onBlur = (e) => {
    let value = e.target.value?.length ? e.target.value : null;
    if ((valueType === 'float' || valueType === 'int') && value != null) {
      value = +value;
    }
    // console.log(value, lastValue)
    setHasFocus(false);

    // if measurement has granularity, we make sure the value entered is a multiple of it
    if (granularity && value != null) {
      value = Math.ceil(value / granularity) * granularity;
    }

    if (value === lastValue) {
      return;
    }
    updateInspection(value, locator);
    toggleInput?.();
    // console.log('updatedInspection', updatedInspection)
  };

  const onFocus = (e) => {
    e?.target?.select();
    const value = e.target.value?.length ? e.target.value : null;
    // console.log(value)
    setHasFocus(true);
    setLastValue(value);
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    // console.log((e.key))

    if (
      [
        'Tab',
        'Backspace',
        'ArrowRight',
        'ArrowDown',
        'ArrowUp',
        'ArrowLeft',
        'Enter',
        '-',
        '.',
        ',',
      ].includes(e.key)
    ) {
      return;
    }

    if (!regExpNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  const negateValue = () => {
    const currentValue = inputRef.current.value;
    if (!!currentValue) {
      inputRef.current.value *= -1;
      updateInspection(inputRef.current.value, locator);
    }
  };

  const value = getValueAndUnitFromLocator(locator, inspection).value;
  if (
    inputRef?.current?.value != null &&
    value != null &&
    value != inputRef.current.value
  ) {
    inputRef.current.value = value;
  }

  return (
    <div className={`c-input ${displayedName ? 'has-label' : ''}`}>
      {showDisplayName && displayedName && (
        <div className="label">{displayedName.trim()}</div>
      )}

      <div className="negate-button">
        {showNegateButton && (
          <IonButton mode="ios" color="primary" size="default" onClick={negateValue}>
            -
          </IonButton>
        )}
      </div>
      <div className="actual-input">
        <input
          id={id + index}
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          inputMode="decimal"
          type="number"
          // disabled={minValue === maxValue}
          onKeyDown={handleKeyDown}
          className={hasFocus ? 'focus' : ''}
          defaultValue={value}
        />
        <label
          htmlFor={id + index}
          className="unit"
          onClick={() => toggleInput?.()}
          style={!!toggleInput ? { cursor: 'pointer' } : undefined}
        >
          {renderUnit(unit)}
        </label>
      </div>
    </div>
  );
};
