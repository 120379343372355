/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationRequest } from '../../../platform/organization'
import type { OrganizationListResponse } from '../../../platform/organization'
import type { OrganizationResponse } from '../../../platform/organization'
import type { OrganizationType } from '../../../platform/organization'
import type { UpdateOrganizationRequest } from '../../../platform/organization'
import type { CancelablePromise } from '../../@api_core/CancelablePromise'
import type { BaseHttpRequest } from '../../@api_core/BaseHttpRequest'
export class OrganizationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Delete Org
     * Deletes an organization
     * @returns string Successful Response
     * @throws ApiError
     */
    public deleteOrg(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organization',
        })
    }
    /**
     * Get Org
     * @returns OrganizationResponse Successful Response
     * @throws ApiError
     */
    public getOrg(): CancelablePromise<OrganizationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organization',
            errors: {
                401: `Not authenticated`,
            },
        })
    }
    /**
     * Add Org
     * Adds a new organization
     * @returns OrganizationResponse Successful Response
     * @throws ApiError
     */
    public addOrg({
        requestBody,
    }: {
        requestBody: CreateOrganizationRequest
    }): CancelablePromise<OrganizationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        })
    }
    /**
     * Update Org
     * Updates an existing organization
     * @returns OrganizationResponse Successful Response
     * @throws ApiError
     */
    public updateOrg({
        requestBody,
    }: {
        requestBody: UpdateOrganizationRequest
    }): CancelablePromise<OrganizationResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Orgs
     * Fetches a list of organizations. It accepts `organization_type`      as optional query parameter
     * @returns OrganizationListResponse Successful Response
     * @throws ApiError
     */
    public getOrgs({
        organizationType,
    }: {
        organizationType?: OrganizationType | null
    }): CancelablePromise<OrganizationListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations',
            query: {
                organization_type: organizationType,
            },
            errors: {
                401: `Not authorized`,
                403: `Not authenticated`,
                422: `Validation Error`,
            },
        })
    }
}
