import { toastController } from '@ionic/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonLoading,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ctxProfile } from '../App';
import { firestore } from '../ConfigFirebase';
import { lotColRef, orderColRef } from '../DataStorage';
import { presentStandardToast } from '../HelperIonic';
import { arrayToReactSelectOptions } from '../HelperReactSelect';
import { Order, PreloadDataEntities } from '../Model';
import '../components/CSideMenu.scss';
interface OfflineModalProps {
  onCancel: () => void;
  onAccept: (data: PreloadDataEntities) => void;
}

export const ModalOfflineEntitySelection = ({
  onCancel,
  onAccept,
}: OfflineModalProps) => {
  const profile = useContext(ctxProfile);

  const orderSelectRef = useRef(null);
  const lotSelectRef = useRef(null);

  const [lotIds, setLotIds] = useState<string[]>([]);
  const [orderIds, setOrderIds] = useState<string[]>([]);
  const [checking, setChecking] = useState<boolean>(false);

  const [orderMap, setOrderMap] = useState<{ [id: string]: Order }>({});

  const saveDisabled: boolean = [...lotIds, ...orderIds].length === 0;

  const go = () => {
    return onAccept({
      lotIds,
      orderIds,
    });
  };

  const handleOrderIdAdd = async (
    evt: {
      label: string;
      value: string;
    }[]
  ) => {
    setChecking(true);
    try {
      // make uppercase (TODO: this could become an issue if companies don't use capitalized ids)
      const newOrderIds: string[] = (evt ?? []).map((e) => e.value.toUpperCase());
      const newOrderId: string = newOrderIds.find((id) => !orderIds.includes(id));

      // when deleting
      if (!newOrderId) {
        const deletedOrderId: string = orderIds.find((id) => !newOrderIds.includes(id));
        setOrderMap((prev) => {
          const newMap = cloneDeep(prev);
          delete newMap[deletedOrderId];
          return newMap;
        });
        return setOrderIds(newOrderIds);
      }

      const order: Order = (
        await orderColRef(firestore, profile.organisationId).doc(newOrderId).get()
      ).data() as Order;

      if (!order) {
        setOrderIds(orderIds);
        return await presentStandardToast(
          toastController,
          `Order id ${newOrderId} does not exist`
        );
      }

      setOrderMap((prev) => ({ ...prev, [order.id]: order }));
      setOrderIds(newOrderIds);
    } catch (error) {
      await presentStandardToast(
        toastController,
        'Something went wrong, please check your internet connection'
      );
    } finally {
      setChecking(false);
      // TODO: find out why doesn't it work without the timeout
      setTimeout(() => {
        orderSelectRef.current.focus();
      }, 100);
    }
  };

  const handleLotIdAdd = async (
    evt: {
      label: string;
      value: string;
    }[]
  ) => {
    setChecking(true);
    try {
      // make uppercase (TODO: this could become an issue if companies don't use capitalized ids)
      const newLotIds: string[] = (evt ?? []).map((e) => e.value.toUpperCase());
      const newLotId: string = newLotIds.find((id) => !lotIds.includes(id));

      // when deleting
      if (!newLotId) {
        return setLotIds(newLotIds);
      }

      const exists: boolean = (
        await lotColRef(firestore, profile.organisationId).doc(newLotId).get()
      ).exists;

      if (!exists) {
        setLotIds(lotIds);
        return await presentStandardToast(
          toastController,
          `Lot id ${newLotId} does not exist`
        );
      }

      setLotIds(newLotIds);
    } catch (error) {
      await presentStandardToast(
        toastController,
        'Something went wrong, please check your internet connection'
      );
    } finally {
      setChecking(false);
      // TODO: find out why doesn't it work without the timeout
      setTimeout(() => {
        lotSelectRef.current.focus();
      }, 100);
    }
  };

  return (
    <div className="ion-page page-new-lot">
      {checking && <IonLoading isOpen={checking} />}
      <IonHeader>
        <IonToolbar>
          <IonTitle>Offline data</IonTitle>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={onCancel}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="tertiary"
              disabled={saveDisabled}
              fill="solid"
              onClick={go}
            >
              {'Accept'}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="form-container">
          <IonList className="form-list">
            <IonItemDivider>Orders</IonItemDivider>

            <IonItem>
              If the batches you need to inspect are associated to orders, please
              indicate the IDs of the orders here
            </IonItem>
            <IonItem>
              <CreatableSelect
                ref={orderSelectRef}
                isClearable={false}
                formatCreateLabel={(value: string) => (
                  <>
                    Add "<b>{value}</b>"
                  </>
                )}
                noOptionsMessage={() => `Please enter an order id`}
                value={arrayToReactSelectOptions(orderIds)}
                placeholder={'Enter order IDs'}
                menuPortalTarget={document.body}
                classNamePrefix={'react-select'}
                onChange={handleOrderIdAdd}
                isMulti
              />
            </IonItem>
            {orderIds.length > 0 && (
              <>
                <IonItem>
                  The following associated batches will also be available offline
                </IonItem>
                <IonItem>
                  <Select
                    isDisabled
                    isMulti
                    isClearable={false}
                    value={arrayToReactSelectOptions(
                      Object.values(orderMap).flatMap((o) =>
                        o?.positions.map((p) => p.lotId)
                      )
                    )}
                  />
                </IonItem>
              </>
            )}
            <IonItemDivider>Batches</IonItemDivider>
            <IonItem>
              If you need to inspect batches that are not associated to orders, please
              indicate the IDs of the batches here
            </IonItem>
            <IonItem>
              <CreatableSelect
                ref={lotSelectRef}
                isClearable={false}
                noOptionsMessage={() => `Please enter a batch id`}
                value={arrayToReactSelectOptions(lotIds)}
                formatCreateLabel={(value: string) => (
                  <>
                    Add "<b>{value}</b>"
                  </>
                )}
                placeholder={'Enter batch IDs'}
                menuPortalTarget={document.body}
                classNamePrefix={'react-select'}
                onChange={handleLotIdAdd}
                isMulti
              />
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </div>
  );
};
