import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { addDays } from 'date-fns';
import { arrowDownOutline, arrowUpOutline, filterCircle } from 'ionicons/icons';
import React, { RefObject, useState, useContext } from 'react';
import Select from 'react-select';
import { ctxOrg, ctxProducts } from './App';
import { firestore } from './ConfigFirebase';
import { getOrders } from './DataApp';
import {
  createInternalReportFromOrder,
  reopenOrder,
  updateOrderStatus,
} from './DataReport';
import { PagedResult } from './DataStorage';
import { formatDate, qcStatusToUIName } from './HelperUtils';
import ModalShare from './ModalShare';
import { LotPosition, Order, OrderAGStatus, OrderType } from './Model';
import './PageDashboard.scss';
import PageFilters from './PageFilters';
// import i18n from "./ServiceI18n";
import { isEqual } from 'lodash';
import { DASHBOARD_SORTING_DATE_STORAGE_KEY } from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import { LegacyInspection, LotInspection, QuestionViewSpec } from './InspectionModel';
import { FilterToolbar } from './PageQualityControl';
import { hasLocationRestriction } from './PermissionsService';
import {
  adaptSectionFilters,
  AGFilters,
  computeNewFilters,
  searchFields,
} from './SearchService';
import { Article } from './ServiceArticle';
import {
  getUserInputValuesToRender,
  InspectionClass,
  orderInspectionsList,
  stringifyInspectionReference,
} from './ServiceInspection';
import ViewContactName from './ViewContactName';
import { ViewInspectionScore } from './ViewInspectionScore';
import { Status } from './ViewInspectionStatus';
import ViewProductName from './ViewProductName';

interface Props {}

interface State {
  inited: true;
  incomingOrders?: PagedResult<Order>;
  filters: AGFilters;
  page: number;
  show: string[];
}

class PageDashboard extends React.Component<Props & ContextProps, State> {
  private incomingSubscriptions = [];
  private contentRef: RefObject<HTMLIonContentElement> = React.createRef();
  private searchBarRef: RefObject<HTMLIonSearchbarElement> = React.createRef();

  // App text for translations
  appText_orderReopenAlert =
    'Are you sure you want to modify the inspections? Note that a report has already been created';

  constructor(props) {
    super(props);

    const savedDateProperty = localStorage.getItem('dashboard-dateProperty');

    let filters: AGFilters = {
      arrivalDateMax: new Date(),
      arrivalDate: addDays(new Date(), -3),
      maxDays: 2,
      dateProperty: !!savedDateProperty ? savedDateProperty : 'lastQCStatusDate',
      sort: 'desc',
      // qcStatus: {value:'DONE'},
      article: {
        // productId: [{value:"AB-BLA"}]
        productId: undefined,
      },
      ...this.props.filters,
    };

    this.state = {
      inited: true,
      show: [],
      page: 0,
      filters,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(
    prevProps: Readonly<Props & ContextProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!isEqual(prevProps.filters, this.props.filters) && this.props.filters != null) {
      this.setState(
        {
          filters: {
            ...computeNewFilters(this.state.filters, this.props.filters),
            search: '',
          },
        },
        () => {
          try {
            this.fetchData();
          } catch (err) {
            console.error('error while loading fetchDataFiltered:', err);
          } finally {
            // console.log('fetcheDataFiltered done')
          }
        }
      );
    }
  }

  fetchData() {
    const { organisationId } = this.props.profile;

    this.setState({ incomingOrders: undefined });
    this.incomingSubscriptions.forEach((unsubscribe) => unsubscribe());
    this.incomingSubscriptions = [];

    let filters = adaptSectionFilters(
      this.state.filters,
      searchFields.PageDashboard.ORDERS
    );

    console.log('DASHBOARD FETCH WITH NEW FILTERS', filters);

    // TODO: this should come from the state, a filter or something
    const orderType: OrderType = 'BUY';

    this.incomingSubscriptions.push(
      getOrders(
        firestore,
        organisationId,
        this.state.filters.search,
        orderType,
        undefined,
        150,
        filters,
        organisationId,
        (result) => {
          this.setState({ incomingOrders: result });
          console.log('dashboard orders', result.data?.length);
        },
        hasLocationRestriction(this.props.profile)
      )
    );
    return;
  }

  fetchDataFiltered(filters) {
    this.searchBarRef.current.value = '';
    this.props.setFilters({ ...filters });
  }

  async toggleDateProperty() {
    if (this.state.filters.dateProperty === 'lastQCStatusDate') {
      await this.setState({
        filters: { ...this.state.filters, dateProperty: 'fulfilmentDate' },
      });
      localStorage.setItem(DASHBOARD_SORTING_DATE_STORAGE_KEY, 'fulfilmentDate');
    } else {
      await this.setState({
        filters: { ...this.state.filters, dateProperty: 'lastQCStatusDate' },
      });
      localStorage.setItem(DASHBOARD_SORTING_DATE_STORAGE_KEY, 'lastQCStatusDate');
    }
    this.fetchData();
  }

  searchByText(search: string) {
    search = search.replace(/\./gi, '/');
    let filters = { ...this.state.filters, search };
    const lr = hasLocationRestriction(this.props.profile);
    if (!!lr) {
      // @ts-ignore
      filters.locationId = lr;
    }
    this.setState({ filters }, () => this.fetchData());
  }

  async removeFilter(key, attr?) {
    // TODO: remove unnecessary stuff from here
    let { filters } = this.state;
    if (attr) {
      if (attr === 'onlyInStock') {
        filters[attr] = false;
      } else {
        delete filters[attr];
      }
    } else {
      delete filters.article?.[key];
    }
    console.log('filters after removed', filters);
    this.props.setFilters(filters);
  }

  renderLotOverviewList(order: Order, inspections: LegacyInspection[]) {
    const productPositionMap: { [key in string]: LotPosition[] } = {};

    order.positions.forEach((pos) => {
      if (!productPositionMap[pos.article?.productId]) {
        productPositionMap[pos.article?.productId] = [];
      }
      productPositionMap[pos.article?.productId].push(pos);
    });

    return (
      <div className="lot-list">
        {Object.keys(productPositionMap).map((productId: string) => (
          <div key={productId}>
            <IonListHeader>
              <h4>
                {/* <ViewProductName {...this.props} productId={productId}/> */}
                <ViewProductName
                  article={
                    order.positions.find(
                      (p) =>
                        p.article?.productId === productId ||
                        (p.article?.agProductId != null &&
                          p.article?.agProductId === productId)
                    )?.article
                  }
                />
              </h4>
            </IonListHeader>
            {productPositionMap[productId].map((position: LotPosition, index) => {
              const inspection = inspections.find(
                (ass) => ass.reference.lotId === position.lotId
              ) as LotInspection;
              const isCompleted = new InspectionClass(inspection).isCompleted();
              const articleClass = new Article(position.article);

              return (
                <IonItem
                  detail={false}
                  className={isCompleted ? 'go-to-top ' : ''}
                  key={position.lotId}
                  routerLink={
                    '/secure/' +
                    order.orgId +
                    '/inspection-view/' +
                    stringifyInspectionReference(inspection.reference)
                  }
                >
                  <IonGrid className={'ion-no-padding ' + (!isCompleted && 'open')}>
                    <IonRow className="ion-align-items-center item">
                      <IonCol className="ion-padding-end">
                        <div className="assesment-info">
                          <div className="lot-info">{position.lotId}</div>
                          {isCompleted && (
                            <div className="show-only-mobile">
                              <Status
                                order={order}
                                title={inspection.status}
                                profile={this.props.profile}
                                inspection={inspection}
                                allowStatusEdit={true}
                              />
                            </div>
                          )}
                          {/*
                        assessment.status === "OPEN" ? 
                        <IonBadge color="light-medium">OPEN</IonBadge>
                        :
                        <IonBadge color="light-medium">COMPLETED</IonBadge>
                      */}
                        </div>
                        <div className="assesment-badges">
                          <IonBadge
                            className="quantity"
                            color="dark"
                            style={{ display: isCompleted ? 'none' : 'inline' }}
                          >
                            {Math.round(position.numBoxes)}&nbsp;
                            {articleClass.getUnit(position.numBoxes)}
                          </IonBadge>
                          {articleClass
                            .getArticleInfoArray()
                            .filter((a) => !!a)
                            .filter((a) => a !== '-')
                            .map((o, i) => (
                              <IonBadge color="medium" key={i}>
                                {o}
                              </IonBadge>
                            ))}
                        </div>
                      </IonCol>
                      <IonCol className="col-scoring">
                        {isCompleted && (
                          <div className="hide-only-mobile">
                            <Status
                              order={order}
                              title={inspection.status}
                              profile={this.props.profile}
                              inspection={inspection}
                              allowStatusEdit={true}
                            />
                          </div>
                        )}
                        {isCompleted && (
                          <ViewInspectionScore
                            inspection={inspection}
                            order={order}
                            displayType={'INLINE'}
                            profile={this.props.profile}
                            organisationSettings={this.props.organisation?.settings}
                          ></ViewInspectionScore>
                        )}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <div className="assesment-defects">
                          {isCompleted && this.renderDefects(inspection)}
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  boxesCount(assessment) {
    const { boxesExpected, boxesReceived } = assessment;

    if (!boxesExpected || !boxesReceived) {
      return;
    }

    if (boxesExpected !== boxesReceived) {
      return (
        <IonBadge className="score-1">
          Boxes {boxesReceived}/{boxesExpected}
        </IonBadge>
      );
    } else {
      return (
        <IonBadge className="score-4">
          Boxes {boxesReceived}/{boxesExpected}
        </IonBadge>
      );
    }
  }

  renderDefects(inspection: LotInspection) {
    const questionSpecs: { [key in string]: QuestionViewSpec } =
      inspection?.renderingInfo?.questionSpecs;
    // these questions are treated separatedly in boxesCount()
    const ommitedDefects = ['quantity_correct', 'quantity'];

    if (!questionSpecs) return;

    let defects = Object.entries(inspection.userInputs).filter(
      ([id, o]) => o.agScore < 4 && !ommitedDefects.includes(id)
    );
    let boxesCount = this.boxesCount(inspection);

    if (!!defects.length || !!boxesCount) {
      return (
        <div className="all-defects">
          {boxesCount}
          {defects.map(
            ([id, o], i) =>
              !!questionSpecs[id]?.displayedName && (
                <IonBadge
                  className={`score-${o.agScore} ${o.groupId}`}
                  key={id} /*onClick={e=>this.showGallery(e, o.)}*/
                >
                  {questionSpecs[id].cardProperties?.cardName ??
                    questionSpecs[id].displayedName}
                  <span>{getUserInputValuesToRender(o)}</span>
                </IonBadge>
              )
          )}
        </div>
      );
    }
  }

  redoOrder(order: Order, e) {
    e.preventDefault?.();
    e.stopPropagation?.();

    if (['CHECKED', 'SHARED'].includes(order.qcStatus)) {
      if (window.confirm(this.appText_orderReopenAlert))
        reopenOrder(
          firestore,
          this.props.profile,
          order,
          this.props.organisation?.settings,
          'REDO'
        );
    } else {
      updateOrderStatus(
        firestore,
        this.props.profile,
        order,
        'REDO',
        this.props.organisation?.settings
      );
    }
  }

  getColor(order: Order) {
    switch (order.qcStatus) {
      case 'REDO':
        return 'danger';
      case 'APPROVED':
      case 'CHECKED':
        return 'primary';
      case 'OPEN':
        return 'medium';
      case 'SHARED':
        return 'tertiary';
      default:
        return 'dark';
    }
  }

  show(order: Order) {
    if (this.state.show.length === 0) {
      return true;
    }

    switch (order.qcStatus) {
      case 'OPEN':
      case 'DONE':
      case 'REDO':
      case 'SHARED':
      case 'CHECKED':
        return this.state.show.includes(order.qcStatus);
    }
  }

  updateShow(qcStatus: OrderAGStatus) {
    let { show } = this.state;
    if (show.includes(qcStatus)) {
      show = show.filter((o) => o !== qcStatus);
    } else {
      show.push(qcStatus);
    }
    this.setState({ show: show, page: 0 });
  }

  renderPaging(incomingOrdersFiltered) {
    if (Math.ceil(incomingOrdersFiltered.length / 20) <= 1) {
      return;
    }
    return (
      <div className="paging">
        {[...Array(Math.ceil(incomingOrdersFiltered.length / 20))].map((v, i) => (
          <div
            key={i}
            onClick={(_) => this.setState({ page: i })}
            className={i === this.state.page ? 'current' : ''}
          >
            {i + 1}
          </div>
        ))}
      </div>
    );
  }

  async createReport(order: Order, e) {
    e.preventDefault?.();
    e.stopPropagation?.();

    const { profile, organisation } = this.props;
    if (window.confirm(`Are you sure you want to create the report?`))
      createInternalReportFromOrder(
        firestore,
        profile,
        order,
        organisation?.settings
      ).catch((err) => {
        window.alert('Error creating report' + JSON.stringify(err));
      });
  }

  renderReportInfoBadge(order: Order) {
    const reportNumber = order.reportReferences?.length;
    if (!reportNumber) {
      return '';
    }
    const sharedNumber = order.reportReferences?.filter(
      (ref) => ref.reportStatus === 'SHARED'
    ).length;

    const reportText = `${reportNumber} REPORT${reportNumber !== 1 ? 'S' : ''}`;
    const sharedText = `${sharedNumber} SHARE${sharedNumber !== 1 ? 'S' : ''}`;
    return (
      <>
        {reportNumber > 0 && <IonBadge color="medium">{reportText}</IonBadge>}
        {sharedNumber > 0 && <IonBadge color="tertiary">{sharedText}</IonBadge>}
      </>
    );
  }

  getClassName(order: Order) {
    let c = '';
    // if (order.list === "OUT")
    //   return

    try {
      if (
        order.qcStatus === 'OPEN' ||
        order.qcStatus === 'REDO' ||
        order.qcStatus === undefined
      ) {
        c = '';
      } else {
        c = 'completed ';
      }
      if (order.search?.scores?.has1orLess) {
        c += 'score-1';
      } else if (order.search?.scores?.has2orLess) {
        c += 'score-2';
      } else if (order.search?.scores?.has3orLess) {
        c += 'score-3';
      }
      return c;
    } catch (error) {
      console.log('ASDLKJADSLJKDAS', order);
    }
  }

  getRouterLink(order: Order) {
    const { organisationId } = this.props.profile;

    switch (order.qcStatus) {
      case 'OPEN':
      case 'REDO':
        return `/secure/${organisationId}/order/${order.id}`;
      case 'DONE':
        if (order.hasReportDraft) {
          return `/secure/report/draft/${order.id}`;
        } else {
          return `/secure/${organisationId}/order/${order.id}`;
        }
      case 'SHARED':
      case 'CHECKED':
        return `/secure/report/${order.latestReportReference?.reportId}`;
      default:
        return `/secure/${organisationId}/order/${order.id}`;
    }
  }

  render() {
    const title = 'Commercial Dashboard';

    const incomingOrders = this.state.incomingOrders?.data;
    let incomingOrdersFiltered =
      incomingOrders?.filter((order) => this.show(order)) ?? [];

    const loadingMore = incomingOrders === undefined;

    const { filters } = this.state;

    const open = incomingOrders?.filter((o) => o.qcStatus === 'OPEN').length ?? 0;
    const done = incomingOrders?.filter((o) => o.qcStatus === 'DONE').length ?? 0;
    const redo = incomingOrders?.filter((o) => o.qcStatus === 'REDO').length ?? 0;
    const approved =
      incomingOrders?.filter((o) => o.qcStatus === 'CHECKED').length ?? 0;
    const shared = incomingOrders?.filter((o) => o.qcStatus === 'SHARED').length ?? 0;

    console.log('render commercial dashboard');

    return (
      <IonPage className="commercial-dashboard ion-page">
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton color="dark" />
            </IonButtons>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              {!this.searchBarRef?.current?.value?.length && (
                <MainToolbar
                  contentRef={this.contentRef}
                  profile={this.props.profile}
                  onFilter={this.fetchDataFiltered.bind(this)}
                  filters={filters}
                />
              )}
            </IonButtons>
          </IonToolbar>
          {!this.searchBarRef?.current?.value?.length && (
            <FilterToolbar
              filters={filters}
              onRemoveFilter={this.removeFilter.bind(this)}
              searchFields={searchFields.PageDashboard.ORDERS}
            />
          )}
        </IonHeader>
        <IonContent ref={this.contentRef}>
          <div className="top-filter">
            <IonItem className="search-bar">
              <IonSearchbar
                debounce={500}
                onIonChange={(evt) => this.searchByText(evt.detail.value)}
                placeholder="SEARCH BY ID"
                mode="ios"
                showClearButton="always"
                // showCancelButton="focus"
                inputmode="text"
                ref={this.searchBarRef}
              />
            </IonItem>
            <IonItem>
              <IonButton
                disabled={this.searchBarRef?.current?.value?.length > 0}
                onClick={async (o) => {
                  this.setState(
                    {
                      page: 0,
                      filters: { ...this.state.filters, arrivalDateMax: new Date() },
                    },
                    () => this.fetchData()
                  );
                }}
                color="primary"
                size="default"
              >
                TODAY
              </IonButton>
            </IonItem>
            <div className="ion-item">
              <div className="filter-date">
                <div className="label-date" onClick={(_) => this.toggleDateProperty()}>
                  {this.state.filters.dateProperty === 'lastQCStatusDate'
                    ? 'last modified date:'
                    : 'arrival date:'}
                </div>
                <div className="inputs">
                  <div>
                    {formatDate(this.state.filters.arrivalDate, {
                      dateStyle: 'medium',
                    })}{' '}
                    to&nbsp;
                  </div>
                  <IonDatetime
                    disabled={this.searchBarRef?.current?.value?.length > 0}
                    style={{ fontWeight: 'bold' }}
                    displayFormat={'D MMM YYYY'}
                    value={
                      this.state.filters.arrivalDateMax
                        ? new Date(this.state.filters.arrivalDateMax).toDateString()
                        : ''
                    }
                    onIonChange={async (o) => {
                      console.log(new Date(o.detail.value).toLocaleDateString());
                      this.setState(
                        {
                          page: 0,
                          filters: {
                            ...this.state.filters,
                            arrivalDateMax: new Date(o.detail.value),
                            arrivalDate: addDays(new Date(o.detail.value), -3),
                          },
                        },
                        () => this.fetchData()
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <IonButtons>
              <IonButton
                disabled={this.searchBarRef?.current?.value?.length > 0}
                fill="clear"
                color="medium"
                onClick={async (o) => {
                  this.setState(
                    {
                      page: 0,
                      filters: {
                        ...this.state.filters,
                        sort: this.state.filters.sort === 'asc' ? 'desc' : 'asc',
                      },
                    },
                    () => this.fetchData()
                  );
                }}
              >
                <IonIcon
                  slot="icon-only"
                  icon={
                    this.state.filters.sort === 'asc'
                      ? arrowUpOutline
                      : arrowDownOutline
                  }
                />
              </IonButton>
            </IonButtons>

            {/* <div className="to-date">
            to {formatDate(this.state.filters.arrivalDate, {dateStyle: 'medium'})}
          </div> */}

            {/* <IonItem className="status-select item-selectable z-13">
            <Select 
              isClearable={true}
              placeholder={"Status"}
              // value={{value:"COMPLETED", label:"COMPLETED"}}
              onChange={ async (evt) => {
                await this.setState({filters: { ...this.state.filters, qcStatus: evt } })
                this.fetchData()
                }}
              options={statusOptions} />
          </IonItem> */}

            <IonItem className="date-select item-selectable z-13">
              {/* <IonLabel>Product</IonLabel> */}
              <ctxProducts.Consumer>
                {(productList) => {
                  if (this.props.profile.products) {
                    // TODO: options should be either agProductId or productId depending on Organisation Settings. For now only showing agProductIds
                    productList = productList.filter(
                      (p) => this.props.profile.products.indexOf(p.productId) >= 0
                    );
                  }
                  let options = productList
                    ?.sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
                    .map((b) => {
                      return {
                        value: b.productId,
                        label: b.agProductId ?? b.productId,
                      };
                    });

                  return (
                    <Select
                      isClearable={true}
                      isDisabled={this.searchBarRef?.current?.value?.length > 0}
                      value={this.state.filters.article?.productId ?? []}
                      placeholder={'Filter product(s)'}
                      classNamePrefix={'react-select'}
                      isOptionDisabled={() =>
                        this.state.filters.article?.productId?.length >= 10
                      }
                      isMulti
                      options={options}
                      onChange={(evt) =>
                        this.props.setFilters({
                          ...this.state.filters,
                          article: {
                            ...this.state.filters.article,
                            productId: evt as any,
                          },
                        })
                      }
                    />
                  );
                }}
              </ctxProducts.Consumer>
            </IonItem>
          </div>

          <div
            className="loading-more-spinner"
            style={{ display: loadingMore ? 'block' : 'none' }}
          >
            <IonSpinner name="dots" />
          </div>

          <IonList>
            {!!incomingOrders && (filters?.search ?? '').length === 0 && (
              <div className="top-resume">
                <div
                  className={this.state.show.includes('OPEN') ? 'selected' : ''}
                  onClick={(_) => this.updateShow('OPEN')}
                >
                  {open} OPEN
                </div>
                <div
                  className={this.state.show.includes('REDO') ? 'selected' : ''}
                  onClick={(_) => this.updateShow('REDO')}
                >
                  {redo} REDO
                </div>
                <div
                  className={this.state.show.includes('DONE') ? 'selected' : ''}
                  onClick={(_) => this.updateShow('DONE')}
                >
                  {done} DONE
                </div>
                <div
                  className={this.state.show.includes('CHECKED') ? 'selected' : ''}
                  onClick={(_) => this.updateShow('CHECKED')}
                >
                  {approved} REPORTS CREATED
                </div>
                <div
                  className={this.state.show.includes('SHARED') ? 'selected' : ''}
                  onClick={(_) => this.updateShow('SHARED')}
                >
                  {shared} REPORTS SHARED
                </div>
              </div>
            )}

            {this.renderPaging(incomingOrdersFiltered)}

            {incomingOrders?.length === 0 && (
              <h1 className="h1-not-found">no results</h1>
            )}
            {incomingOrdersFiltered
              .slice(this.state.page * 20, this.state.page * 20 + 20)
              .map((order) => {
                let inspections: LegacyInspection[] = orderInspectionsList(order);

                const routerLink = this.getRouterLink(order);

                const createReportDisabled = order.qcStatus !== 'DONE';
                const hideCreateReport = order.qcStatus === 'CHECKED';
                const redoDisabled =
                  order.qcStatus === 'OPEN' || order.qcStatus === 'REDO';

                return (
                  <div
                    key={order.id}
                    className={
                      this.getClassName(order) + ' order-div ' + this.show(order)
                    }
                  >
                    <IonItem
                      className="order-item"
                      button
                      detail={false}
                      routerLink={routerLink}
                    >
                      <div className="order-header">
                        <div>
                          <b>{order.id}</b>
                        </div>
                        <div>
                          {/* <IonBadge className="date-badge" color={this.getColor(order)} mode="md">
                      <span className="status">{qcStatusToUIName(order.qcStatus)}</span>
                      <div className="date-div">
                        <span className="date">
                          { this.state.filters.dateProperty === 'lastQCStatusDate'
                            ? formatDate(order.lastQCStatusDate, order.qcStatus === 'OPEN' ? {} : {dateStyle: 'short', timeStyle: 'short'})
                            : formatDate(order.fulfilmentDate, order.qcStatus === 'OPEN' ? {} : {dateStyle: 'short'})
                          }
                        </span>
                      </div>
                    </IonBadge> */}
                          <IonBadge color="light-medium" mode="md">
                            {this.state.filters.dateProperty === 'lastQCStatusDate'
                              ? formatDate(
                                  order.lastQCStatusDate,
                                  order.qcStatus === 'OPEN'
                                    ? {}
                                    : { dateStyle: 'short', timeStyle: 'short' }
                                )
                              : formatDate(
                                  order.fulfilmentDate,
                                  order.qcStatus === 'OPEN'
                                    ? {}
                                    : { dateStyle: 'short' }
                                )}
                          </IonBadge>

                          {order.qcStatus !== 'SHARED' && (
                            <IonBadge color={this.getColor(order)} mode="md">
                              {qcStatusToUIName(order.qcStatus)}
                            </IonBadge>
                          )}

                          {/* <IonBadge color="light-medium">
                       {formatDate(order.fulfilmentDate)} 
                    </IonBadge> */}

                          {/* Sharing history can't be shown here anymore, since the sharing history is part of the report, not the order */}
                          {/* {order.sharedTo?.length > 0 && 
                      <ShareHistoryButton  profile={this.props.profile} order={order} applicationContext={this.props.applicationContext}/>
                    } */}
                          {this.renderReportInfoBadge(order)}
                          {order.attachments?.length > 0 && (
                            <IonBadge color="medium">
                              {order.attachments.length} ATTACHMENTS
                            </IonBadge>
                          )}
                        </div>
                        <div className="capitalize contact-name">
                          <ViewContactName report={order} />
                        </div>
                      </div>
                      <div
                        className={
                          'actions ' + (order.latestReportReference?.reportId ? '' : '')
                        }
                      >
                        {!redoDisabled && (
                          <IonButton
                            size="default"
                            disabled={redoDisabled}
                            color={'medium'}
                            fill="clear"
                            onClick={(e) => this.redoOrder(order, e)}
                          >
                            REDO
                          </IonButton>
                        )}
                        {!createReportDisabled && (
                          <IonButton
                            size="default"
                            disabled={createReportDisabled}
                            hidden={hideCreateReport}
                            color={createReportDisabled ? 'medium' : 'primary'}
                            onClick={(e) => {
                              this.createReport(order, e);
                            }}
                          >
                            CREATE REPORT
                          </IonButton>
                        )}
                        <ShareButton
                          order={order}
                          // updateOrder={this.updateOrder.bind(this)}
                        />
                      </div>
                    </IonItem>
                    {this.renderLotOverviewList(order, inspections)}
                  </div>
                );
              })}

            {this.renderPaging(incomingOrdersFiltered)}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default React.memo(
  withContext(PageDashboard, ['profile', 'filters', 'setFilters', 'organisation'])
);

// ----------------------------------------------------------------
const ShareButton = ({ order }: { order: Order }) => {
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const hidden = !['CHECKED', 'SHARED'].includes(order.qcStatus);
  const color = hidden ? 'medium' : 'tertiary';

  return (
    <>
      {showShareModal && (
        <IonModal
          isOpen={showShareModal}
          onDidDismiss={() => setShowShareModal(false)}
          cssClass={'modal-share'}
          showBackdrop
        >
          <ModalShare order={order} onCancel={() => setShowShareModal(false)} />
        </IonModal>
      )}
      <IonButton
        hidden={hidden}
        size="default"
        color={color}
        onClick={(e) => {
          e.preventDefault?.();
          e.stopPropagation?.();
          setShowShareModal(true);
        }}
      >
        SHARE
      </IonButton>
    </>
  );
};

const MainToolbar = ({ contentRef, profile, onFilter, filters }) => {
  const [showPageFilters, setShowPageFilters] = useState(false);
  const organisation = useContext(ctxOrg);

  const renderToolbar = () => (
    <IonButton onClick={() => setShowPageFilters(true)} color="medium">
      <IonIcon icon={filterCircle} />
      <span className="hide-only-mobile">&nbsp;Filter</span>
      {/* Filter */}
    </IonButton>
  );

  return (
    <>
      {renderToolbar()}

      <IonModal
        isOpen={showPageFilters || false}
        cssClass={'modal-filter'}
        onDidDismiss={(_) => setShowPageFilters(false)}
      >
        <PageFilters
          organisation={organisation}
          searchFields={searchFields.PageDashboard.ORDERS}
          profile={profile}
          filters={filters}
          onCancel={() => setShowPageFilters(false)}
          onFilter={(filters) => {
            setShowPageFilters(false);
            onFilter(filters);
          }}
        />
      </IonModal>
    </>
  );
};

// ----------------------------------------------------------------

// const ShareHistoryButton = ({profile, applicationContext, order}) => {
//   const [presentShareHistory, dismissIt] = useIonModal(PageSharedHistoryOrder, {
//     profile:profile,
//     applicationContext: applicationContext,
//     order: order,
//     onCancel: _ => dismissIt(),
//   });

//   return <IonBadge color="light-medium" onClick={_=>presentShareHistory()} className="shareCountBadge">
//       {order.sharedTo.length} SHARES
//     </IonBadge>
// }
