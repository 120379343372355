import { toastController } from '@ionic/core';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import React from 'react';
import i18n from './ServiceI18n';
import { Contact, Order } from './Model';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline } from 'ionicons/icons';

import './PageContactProfiling.scss';

import Select from 'react-select';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CustomerData, ProfilesFilter } from './ModelInsight';
import {
  fetchEntities,
  profilesDateRange,
  insightsProfilesApi,
  shareCustomerProfile,
  sharedCustomerProfile,
  verifyCustomerProfilePassword,
} from './DataInsight';
import { cloneDeep } from 'lodash';
import { fetchQuestions } from './data/Questions';
import { Question } from './generated/openapi/core';
import { getRGBColorCustom } from './HelperInsight';
import { Link } from 'react-router-dom';
import ModalShare from './ModalShare';
import { functions } from './ConfigFirebase';
import withContext, { ContextProps } from './HOC/withContext';

interface Props extends RouteComponentProps {
  contactId: string;
  modal?: boolean;
  onClick?: Function;
  onCancel?: any;
  isShared?: boolean;
  sharedId?: string;
  contactName?: string;
}

interface State {
  recentOrders?: Order[];
  recentOrdersShared?: Order[];
  data: any[];
  inited: boolean;
  contactId: string;
  errorMessage?: string;
  products: string[];
  selectedProduct: { value: string; label: string };

  inputList: Question[];
  pgData: CustomerData;
  customerIds: string[];

  isShareModalOpen?: boolean;
  isLoadingPassword?: boolean;
  shareLink?: string;
  showPassword?: boolean;
  password?: string;
}

class PageCustomerProfiling extends React.Component<Props & ContextProps, State> {
  private mounted;

  constructor(props) {
    super(props);

    this.state = {
      recentOrders: undefined,
      data: [],
      inited: false,
      contactId: this.props.contactId,
      products: [],
      selectedProduct: undefined,
      inputList: [],
      pgData: {},
      customerIds: [],
      showPassword: this.props.isShared,
      password: '',
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.selectedProduct?.value !== this.state.selectedProduct?.value &&
      !!this.state.selectedProduct?.value
    ) {
      this.fetchPGData();
    } else if (prevState.contactId !== this.state.contactId) {
      this.fetchData().then((_) => this.fetchPGData());
    }
  }

  async fetchData() {
    let data = [];
    this.setState({
      data: [],
      selectedProduct: undefined,
    });

    // let a = await firestore
    //   .collection('organisation')
    //   .doc(this.props.profile.organisationId)
    //   .collection('insight')
    //   .doc('profiles')
    //   .collection('profiles')
    //   .where('entities.customer', '==', this.state.contactId)
    //   .get();

    // a.forEach((doc) => {
    //   data.push(doc.data());
    // });

    const filter: ProfilesFilter = {
      buyer: this.state.contactId,
    } as ProfilesFilter;

    try {
      const res = await fetchEntities(
        filter,
        this.props.profile?.organisationId,
        'outgoing',
        profilesDateRange,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined,
        true
      );

      // console.log("AAA", res)

      const products = [...new Set(res.product)];

      this.setState({
        data: data,
        products: products,
        selectedProduct:
          products.length > 0
            ? {
                value: products[0],
                label: this.renderProductLabel(products[0]),
              }
            : { value: '', label: '' },
      });
    } catch (e) {
      this.setState({
        inited: true,
        errorMessage:
          e.message === 'The function must be called with a valid shared id.'
            ? 'Invalid share-code'
            : e.message,
      });
    }
  }

  private renderProductLabel(productId: string): string {
    const agProductId = this.props.products?.find(
      (p) => p.productId === productId || p.agProductId === productId
    )?.agProductId;

    if (agProductId == null || agProductId === productId) {
      return productId;
    }

    return `[${productId}] ` + agProductId;
  }

  async fetchPGData() {
    if (!this.state.selectedProduct) return;
    this.setState({
      pgData: {},
      inited: false,
    });
    const filter: ProfilesFilter = {
      product: this.state.selectedProduct.value,
      buyer: this.state.contactId,
    };

    try {
      const fetchedDefectSensitivity = await insightsProfilesApi(
        filter,
        'defect_sensitivity',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      const fetchedRecentDefectIncidents = await insightsProfilesApi(
        filter,
        'recent_defect_incidents',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      const fetchedClaimsAmount = await insightsProfilesApi(
        filter,
        'claims_amount',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      this.setState({
        inited: true,
        pgData: {
          defectSensitivity: fetchedDefectSensitivity,
          recentDefectIncidents: fetchedRecentDefectIncidents,
          claimsAmount: fetchedClaimsAmount,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  ionViewWillEnter() {
    fetchEntities(
      {},
      this.props.profile?.organisationId,
      'outgoing',
      profilesDateRange,
      this.props.isShared
        ? {
            isShared: true,
            sharedId: this.props.sharedId,
          }
        : undefined,
      true
    ).then((res) => {
      const customerIds: string[] = res.buyer;
      this.setState({ customerIds });
      this.fetchData();
      fetchQuestions()
        .then((ql) => {
          // console.log("questions fetched:", ql.questions);
          this.setState({ inputList: ql.questions });
        })
        .catch((e) => console.error(e));
    });
  }

  renderRecentDefectIncidents() {
    const { inputList } = this.state;
    const series = this.state.pgData.recentDefectIncidents?.series;

    if (!series) {
      return null;
    } else if (series.length === 0) {
      return (
        <div>
          <div className="title">defects incident breakdown</div>
          <p className="no-enough-data">Not enough data available</p>
        </div>
      );
    }

    const sigTypes = ['significant', 'insignificant'];

    const separatedData = {};
    const defects = {};

    for (const t of sigTypes) {
      separatedData[t] = series.filter((o) => o.attributability_category === t);
      defects[t] = [...new Set(separatedData[t].map((o) => o.question_id))];
    }

    let titleWithout = 'Claims/Sales Without Defect';
    let titleWith = 'Claims/Sales With Defect';

    return (
      <>
        <div className="title">Defects incident breakdown</div>
        <IonGrid className="defects-incident-breakdown-grid">
          <IonRow>
            {sigTypes.map((t) => (
              <IonCol key={t} size="6">
                <IonRow className="top-row">
                  <IonCol size="12">
                    <h3>{t === 'insignificant' ? titleWithout : titleWith}</h3>
                  </IonCol>
                </IonRow>
                <IonRow className="top-row-2">
                  <IonCol size="4">
                    <strong>Defect</strong>
                  </IonCol>
                  <IonCol size="4">
                    <strong>Last 3 months</strong>
                  </IonCol>
                  <IonCol size="4">
                    <strong>Last year</strong>
                  </IonCol>
                </IonRow>
                {defects[t].map((defect, idx) => {
                  const threeMonths = separatedData[t].find(
                    (o) => o.question_id === defect && o.period === 'three_months'
                  );
                  const oneYear = separatedData[t].find(
                    (o) => o.question_id === defect && o.period === 'one_year'
                  );

                  return (
                    <IonRow key={idx} className="data-row">
                      <IonCol size="4">
                        {inputList.find((i) => i.id === defect)?.name ?? defect}
                      </IonCol>
                      <IonCol size="4">
                        {threeMonths
                          ? `${threeMonths.claimed}/${threeMonths.count.toFixed(0)}`
                          : 'N/A'}
                      </IonCol>
                      <IonCol size="4">
                        {oneYear
                          ? `${oneYear.claimed}/${oneYear.count.toFixed(0)}`
                          : 'N/A'}
                      </IonCol>
                    </IonRow>
                  );
                })}
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </>
    );
  }

  renderDefects() {
    const { inputList } = this.state;
    const series = this.state.pgData.defectSensitivity?.series;

    if (!series) {
      return null;
    } else if (series.length === 0) {
      return (
        <div>
          <div className="title">sensitivity to defects</div>
          <p className="no-enough-data">Not enough data available</p>
        </div>
      );
    }

    const sortSeriesBySensitivity = (a, b) => {
      const order = ['whitelist', 'blacklist', 'unknown'];
      return order.indexOf(a.sensitivity) - order.indexOf(b.sensitivity);
    };

    const data = series
      ?.sort((a, b) => {
        return b.present - b.non_existent - (a.present - a.non_existent);
      })
      .sort(sortSeriesBySensitivity);
    // console.log('data', data);

    let defects = [...new Set(data.map((o) => o.condition))];

    let colSeries = [
      {
        name: 'present',
        color: '#FA937A',
        data: data.map((o) => parseFloat((o.present * 100).toFixed(2))),
      },
      {
        name: 'missing',
        color: '#97D176',
        data: data.map((o) => parseFloat((o.non_existent * 100).toFixed(2))),
      },
    ];

    const seriesMax: number =
      Math.max(...(colSeries ?? []).flatMap((s) => s.data)) ?? 0;

    // console.log("colSeries", colSeries, seriesMax)

    let optionsStacked = {
      chart: {
        type: 'column',
        height: '360px',
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      title: {
        text: 'Sensitivity to Defects',
        style: {
          fontWeight: 'bold',
          fontFamily: 'HK Grotesk',
          fontSize: '20px',
        },
      },
      xAxis: {
        categories: defects,
        labels: {
          formatter: function () {
            const v = inputList.find((o) => o.id === this.value);
            const sensitivity = data
              .find((o) => o.condition === this.value)
              ?.sensitivity.toUpperCase();
            if (!!v) return v.name + '<br/><b>' + sensitivity + '</b>';
            else return this.value + '<br/><b>' + sensitivity + '</b>';
          },
        },
      },
      yAxis: [
        {
          min: 0,
          max: seriesMax >= 80 ? 100 : undefined,
          title: {
            text: 'Claim % (percentage)',
          },
          minorTickInterval: 'auto',
        },
        {
          visible: false,
        },
      ],
      series: colSeries,
    };
    return (
      <HighchartsReact
        // key={p as string}
        highcharts={Highcharts}
        options={optionsStacked}
        allowChartUpdate={true}
      />
    );
  }

  renderSummary() {
    const series = this.state.pgData.claimsAmount?.series.sort(
      (a, b) => a.quality_score - b.quality_score
    );

    if (!series) {
      return null;
    } else if (series.length === 0) {
      return (
        <div>
          <div className="title">sales/claims breakdown into quality categories</div>
          <p className="no-enough-data">Not enough data available</p>
        </div>
      );
    }

    let optionsHC: Highcharts.Options = {
      title: {
        text: 'Sales',
        style: {
          fontFamily: 'HK Grotesk',
          fontSize: '20px',
        },
      },
      chart: {
        height: '400px',
        // styledMode: true
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: 'QC: {point.name}<br/>{point.percentage:.2f} %',
            distance: 20,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 1,
            },
          },
        },
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>',
      },
      series: [
        {
          type: 'pie',
          showInLegend: true,
          data: series
            .filter((s) => s?.num_sales > 0)
            .map((s) => {
              return {
                sliced: false,
                y: s.num_sales,
                name: s.quality_score ? s.quality_score : 'n/a',
                color: getRGBColorCustom(s.quality_score),
              };
            }),
        },
      ],
    };

    let optionsHCClaims: Highcharts.Options = cloneDeep({ ...optionsHC });
    optionsHCClaims.title.text = 'Claims';
    //@ts-ignore
    optionsHCClaims.series[0].data = series
      .filter((s) => s?.num_claims > 0)
      .map((s) => {
        return {
          sliced: false,
          y: s.num_claims,
          name: s.quality_score ? s.quality_score : 'n/a',
          color: getRGBColorCustom(s.quality_score),
        };
      });

    let highchartsStackedColumn = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Claims vs Sales',
      },
      xAxis: {
        categories: series.map((s) => (s.quality_score ? s.quality_score : 'n/a')),
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Sales (%)',
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            // color:
            //   // theme
            //   (Highcharts.defaultOptions.title.style &&
            //     Highcharts.defaultOptions.title.style.color) ||
            //   "gray",
          },
        },
      },
      legend: {
        enabled: false,
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        // headerFormat: "<b>{point.x}</b><br/>",
        // pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Not Claimed',
          data: series.map((s) => s.num_sales - s.num_claims),
          color: '#97D176',
        },
        {
          name: 'Claimed',
          data: series.map((s) => s.num_claims),
          color: '#FA937A',
        },
      ],
    };

    return (
      <div className="summary-claims-sales-wrapper">
        <div className="title">sales/claims breakdown into quality categories</div>
        <div className="summary-claims-sales">
          <div>
            <h2>Sales</h2>
            <div style={{ width: 400, textAlign: 'center' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsHC}
                allowChartUpdate={true}
              />
              <div>Total: {series.reduce((acc, o) => acc + o.num_sales, 0)}</div>
            </div>
          </div>
          <div>
            <h2>Claims</h2>
            <div style={{ width: 400, textAlign: 'center' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsHCClaims}
                allowChartUpdate={true}
              />
              <div>Total: {series.reduce((acc, o) => acc + o.num_claims, 0)}</div>
            </div>
          </div>
          <div>
            <h2>Sales vs Claims</h2>
            <div style={{ width: 400, textAlign: 'center' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={highchartsStackedColumn}
                allowChartUpdate={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  async share() {
    this.setState({ isShareModalOpen: true });
    const contact: Contact = this.props.contacts?.find(
      (c) => c.id === this.state.contactId
    );
    const password = Math.random().toString(36).slice(-8);
    const shareObj: sharedCustomerProfile = {
      orgId: this.props.profile.organisationId,
      customerType: 'buyer',
      customerId: this.state.contactId,
      customerName: contact.name,
      password,
    };
    const res = await shareCustomerProfile(shareObj);
    this.setState({
      shareLink: `${window.location.origin}/shared-customer-profile/buyer/${
        this.state.contactId
      }/${encodeURIComponent(contact.name)}/${res.id}`,
      password: password,
    });
  }

  async modalShareSuccess(res) {
    this.setState({ isShareModalOpen: false, shareLink: undefined });
    const { customerIds } = this.state;
    const contacts: Contact[] = this.props.contacts?.filter((c) =>
      (customerIds ?? []).includes(c.id)
    );
    const contact: Contact = contacts?.find((c) => c.id === this.state.contactId);
    try {
      await functions.httpsCallable('sendShareCustomerProfileEmail')({
        email: res.emails,
        message: res.message,
        link: this.state.shareLink,
        password: this.state.password,
        orgName: this.props.profile.organisationId,
        contactName: contact.name,
      });
      const toast = await toastController.create({
        message: 'Email Sent',
        position: 'top',
        color: 'primary',
        duration: 3000,
        buttons: [
          {
            text: 'Got it',
            role: 'cancel',
          },
        ],
      });

      toast.present().then();
    } catch (e) {
      console.error(e);
      const toast = await toastController.create({
        message: 'Error: ' + e?.message,
        position: 'top',
        color: 'danger2',
        duration: 3000,
        buttons: [
          {
            text: 'Got it',
            role: 'cancel',
          },
        ],
      });

      toast.present().then();
    }
  }

  render() {
    const { customerIds } = this.state;

    const contacts: Contact[] = this.props.contacts?.filter((c) =>
      (customerIds ?? []).includes(c.id)
    );

    const contact: Contact = contacts?.find((c) => c.id === this.state.contactId);

    let contactOptions =
      (contacts ?? [])
        .filter((c) => c.type?.includes('BUYER'))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((b) => {
          return {
            value: b.id,
            label: b.id !== b.name ? `[${b.id}] ${b.name}` : b.name,
          };
        }) ?? [];

    const agProductOptions = (this.state.products ?? [])
      .map((o) => {
        return { value: o, label: this.renderProductLabel(o) };
      })
      .filter((o) => o.label != null && o.value != null)
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    // let optionsProducts = this.props.profile.organisationId === 'berryworld.com' ? agProductOptions : orgProductOptions;
    let optionsProducts = agProductOptions;

    const { showPassword } = this.state;

    return (
      // TODO: options should be either agProductId or productId depending on Organisation Settings. For now hardcoded
      // const orgProductOptions = (productList ?? []).filter(o => this.state.products.includes(o.productId)).map(o => {return {value:o.productId, label: `[${o.productId}] ` + o.agProductId}}).sort((a,b) => {return a.label.localeCompare(b.label)})

      // Note: option 'All' is removed for now as it's broken
      // optionsProducts.unshift({value:undefined, label: 'All'});

      <IonPage className={'page-contact-profiling ion-page'}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" color="light">
              {!this.props.profile ? (
                <Link to={'/quality-control'} className="logo">
                  <img src="/assets/icon/android-icon-192x192.png" alt="agrinorm" />
                  <IonTitle className="agrinorm">Agrinorm</IonTitle>
                </Link>
              ) : (
                <IonBackButton
                  text={i18n.t('General.back')}
                  defaultHref={
                    !!this.props.profile
                      ? '/secure/contact/' + this.props.contactId
                      : '/quality-control'
                  }
                  color="dark"
                >
                  <IonIcon icon={chevronBackOutline} slot="icon-only" />
                </IonBackButton>
              )}
            </IonButtons>

            <IonTitle>{contact?.name ?? contact?.id}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="customer-title">{this.props.contactName}</div>
          {showPassword ? (
            <div
              style={{
                display: 'flex',
                width: 400,
                gap: 10,
                alignItems: 'center',
                margin: '30px auto',
              }}
            >
              <IonInput
                style={{ borderRadius: 4 }}
                placeholder="Password"
                value={this.state.password}
                onIonChange={(e) => this.setState({ password: `${e.detail.value}` })}
              ></IonInput>{' '}
              <IonButton
                onClick={async () => {
                  this.setState({ isLoadingPassword: true });
                  const response = await verifyCustomerProfilePassword(
                    this.props.sharedId,
                    this.state.password
                  );
                  this.setState({ isLoadingPassword: false });
                  if (!!response.data.isPasswordCorrect) {
                    this.setState({ showPassword: false });
                  } else {
                    alert('Wrong password');
                  }
                }}
              >
                {this.state.isLoadingPassword ? <IonSpinner /> : 'OK'}
              </IonButton>
            </div>
          ) : (
            <>
              <IonModal
                cssClass={'modal-share'}
                isOpen={this.state.isShareModalOpen}
                onDidDismiss={() => {
                  this.setState({ isShareModalOpen: false, shareLink: undefined });
                }}
              >
                <ModalShare
                  password={this.state.password}
                  contactId={this.state.contactId}
                  shareLink={this.state.shareLink}
                  title={'Share Customer Profile'}
                  onCancel={() => {
                    this.setState({ isShareModalOpen: false, shareLink: undefined });
                  }}
                  onSuccess={async (res) => {
                    this.modalShareSuccess(res);
                  }}
                />
              </IonModal>
              <div className="wrapper">
                <div className="left">
                  {contactOptions.length > 0 ? (
                    <IonItem className="item-selectable z-13">
                      <IonLabel>Customer</IonLabel>
                      <Select
                        classNamePrefix={'react-select'}
                        options={contactOptions}
                        defaultValue={contactOptions.find(
                          (o) => o.value === this.props.contactId
                        )}
                        name="supplier"
                        onChange={(e) => {
                          // console.log(e);
                          this.setState({
                            contactId: e.value,
                            selectedProduct: { value: undefined, label: undefined },
                          });
                          // window.history.replaceState(null, '', `/secure/contact/${e.value}/insight`);
                          // this.props.history.replace(`/secure/contact/${e.value}/insight`, null);
                        }}
                      />
                    </IonItem>
                  ) : null}
                  <IonItem className="item-selectable z-12">
                    <IonLabel>Product</IonLabel>
                    <Select
                      options={optionsProducts}
                      classNamePrefix={'react-select'}
                      name="product"
                      value={this.state.selectedProduct}
                      onChange={(e) => {
                        this.setState({ selectedProduct: e });
                      }}
                    />
                  </IonItem>
                  {!this.props.isShared && (
                    <IonButton color="tertiary" onClick={() => this.share()}>
                      Share
                    </IonButton>
                  )}
                </div>
                <div className="right">
                  {this.state.inited ? (
                    <>
                      <div className="claims-sales">{this.renderSummary()}</div>
                    </>
                  ) : (
                    <IonSpinner name="dots" color="dark" className="loading-spinner" />
                  )}
                </div>
              </div>
              {!!this.state.errorMessage ? (
                <div className="error-message">{this.state.errorMessage}</div>
              ) : null}
              {this.state.inited && !this.state.errorMessage ? (
                <>
                  <div className="propertiesOther">{this.renderDefects()}</div>
                  <div className="propertiesOther">
                    {this.renderRecentDefectIncidents()}
                  </div>
                </>
              ) : null}
            </>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default withContext(withIonLifeCycle(PageCustomerProfiling), [
  'contacts',
  'products',
  'profile',
]);
