import { IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import { getValueAndUnitFromLocator } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import './CInput.scss';
import { useInspectionContext } from '../context/InspectionContextProvider';

// const regExpNumber = /^[+-]?((\d*(\.\d*)?)|(\.\d+))$/;

// --------------------------------------------------------------------------------------------------------
// CInputText
// --------------------------------------------------------------------------------------------------------
interface ICInputText {
  locator: UserInputLocator;
}
export const CInputText = (props: ICInputText) => {
  const { locator } = props;
  // console.log('measurement', measurement)
  const { inspection, updateInspection } = useInspectionContext();
  const [lastValue, setLastValue] = useState(null);
  const [hasFocus, setHasFocus] = useState(false);

  const onBlur = (e) => {
    const value = e.target.value?.length ? e.target.value : null;
    // console.log(value, lastValue)
    setHasFocus(false);
    if (value === lastValue) {
      return;
    }
    updateInspection(value, locator);
    // console.log('updatedInspection', updatedInspection)
  };

  const onFocus = (e) => {
    const value = e.target.value?.length ? e.target.value : null;
    // console.log(value)
    setHasFocus(true);
    setLastValue(value);
  };

  const defaultValue = getValueAndUnitFromLocator(locator, inspection).value;

  function getRowNumber(str: string): number {
    if (!str) return 1;
    const lineBreaks = str.match(/(\r\n|\r|\n)/g);
    return (lineBreaks ? lineBreaks.length : 0) + 1;
  }

  return (
    <IonTextarea
      onFocus={onFocus}
      onBlur={onBlur}
      rows={getRowNumber(defaultValue)}
      className={`c-input-text ${hasFocus ? 'focus' : ''}`}
      value={defaultValue}
    />
  );
};
