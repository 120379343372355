import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import Select from 'react-select';
import { Question, RiskCategory, SectionType } from './generated/openapi/core';

interface Props {
  question: Question;
  onSaveQuestion: (question: Question) => void;
  isNewQuestion: boolean;
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    color: '#1c1c1c',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export default function ViewQuestionEditor(props: Props) {
  const { question, onSaveQuestion, isNewQuestion } = props;
  const title = isNewQuestion ? 'New Question' : 'Edit Question';
  const [modifiedQuestion, setModifiedQuestion] = useState<Question>(question);

  const sectionTypeOpts: { label: string; value: string }[] = Object.keys(
    SectionType
  ).map((secType) => {
    return { label: secType, value: SectionType[secType] };
  });

  const originalSecTypes: { label: string; value: string }[] = question?.section_types
    ? sectionTypeOpts.filter((st) =>
        modifiedQuestion.section_types.includes(st.value as SectionType)
      )
    : [];

  const riskCategoryOpts: { label: string; value: string }[] = Object.keys(
    RiskCategory
  ).map((riskCategory) => {
    return { label: riskCategory, value: RiskCategory[riskCategory] };
  });

  const originalRiskCategory: { label: string; value: string } = question?.risk_category
    ? riskCategoryOpts.find((rc) => modifiedQuestion.risk_category === rc.value)
    : undefined;

  return (
    <>
      <div className="ion-page">
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                fill="solid"
                data-tip="vqe-save-button"
                onClick={(_) => onSaveQuestion(modifiedQuestion)}
                disabled={
                  !modifiedQuestion || !modifiedQuestion.id || !modifiedQuestion.name
                }
              >
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Id</IonLabel>
              <IonInput
                value={modifiedQuestion.id}
                placeholder="Enter id"
                onIonChange={(e) => {
                  setModifiedQuestion((prevState) => {
                    return { ...prevState, id: e.detail.value };
                  });
                }}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Name</IonLabel>
              <IonInput
                value={modifiedQuestion.name}
                placeholder="Enter name"
                onIonChange={(e) => {
                  setModifiedQuestion((prevState) => {
                    return { ...prevState, name: e.detail.value };
                  });
                }}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Description</IonLabel>
              <IonInput
                value={modifiedQuestion.description}
                placeholder="Enter description"
                onIonChange={(e) => {
                  setModifiedQuestion((prevState) => {
                    return { ...prevState, description: e.detail.value };
                  });
                }}
              />
            </IonItem>
            <IonItem style={{ overflow: 'visible' }}>
              <IonLabel style={{ marginBottom: '12px' }} position="stacked">
                Applicable section types
              </IonLabel>
              <Select
                classNamePrefix="react-select"
                className="react-select"
                isMulti
                menuPortalTarget={window.document.body}
                options={sectionTypeOpts}
                styles={customStyles}
                placeholder="Select the section type(s)"
                defaultValue={originalSecTypes}
                onChange={(selectedSecTypes) => {
                  setModifiedQuestion((prevState) => {
                    return {
                      ...prevState,
                      section_types: selectedSecTypes.map(
                        (stOpt) => stOpt.value as SectionType
                      ),
                    };
                  });
                }}
              />
            </IonItem>
            <IonItem style={{ overflow: 'visible' }}>
              <IonLabel style={{ marginBottom: '12px' }} position="stacked">
                To which type of risk it contributes
              </IonLabel>
              <Select
                classNamePrefix="react-select"
                className="react-select"
                menuPortalTarget={window.document.body}
                options={riskCategoryOpts}
                styles={customStyles}
                placeholder="Select the section type(s)"
                defaultValue={originalRiskCategory}
                onChange={(riskCategory) => {
                  setModifiedQuestion((prevState) => {
                    return {
                      ...prevState,
                      risk_category: riskCategory.value as RiskCategory,
                    };
                  });
                }}
              />
            </IonItem>
          </IonList>
        </IonContent>
      </div>
    </>
  );
}
