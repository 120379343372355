import React, { Fragment, useContext } from 'react';
import { IArticle } from '../Model';
import { ctxProducts } from '../App';
import { Option } from '../HelperReactSelect';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Select from 'react-select';
import { closeOutline } from 'ionicons/icons';

interface EVMProps {
  onClose: () => void;
  article: IArticle;
  onVarietyChange: (v: string) => void;
}
export const ModalEditVariety = ({ onClose, article, onVarietyChange }: EVMProps) => {
  const products = useContext(ctxProducts);
  const currVariety = article.agVariety ?? article.variety;
  const varietyOptions: Option[] = (
    products.find((p) => p.agProductId === article.agProductId)?.agVarieties ?? []
  ).map((value) => ({ label: value, value }));

  return (
    <Fragment>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Select new variety</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: '4px' }}>
          <Select
            autoFocus
            menuIsOpen
            isClearable
            options={varietyOptions}
            defaultValue={varietyOptions.find((o) => o.value === currVariety)}
            onChange={(e) => {
              if (e?.value !== currVariety) {
                onVarietyChange(e?.value ?? null);
              }
              onClose();
            }}
          />
        </div>
      </IonContent>
    </Fragment>
  );
};
