import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';

import PageConnect from '../PageConnect';
import PageContact from '../PageContact';
import PageConversation from '../PageConversation';
import PageCustomerProfiling from '../PageCustomerProfiling';
import PageDashboard from '../PageDashboard';
import PageInbox from '../PageInbox';
import PageLot from '../PageLot';
import PageManagement from '../PageManagement';
import PageMissingPermissions from '../PageMissingPermissions';
import PageOrder from '../PageOrder';
import PageQualityControl from '../PageQualityControl';
import PageReport from '../PageReport';
import PageReportDashboard from '../PageReportDashboard';
import PageScoring from '../PageScoring';
import PageSettings from '../PageSettings';
import PageSupplierProfiling from '../PageSupplierProfiling';

import {
  AppProduct,
  InsightsTierAction,
  QCTierAction,
  isFreeQCTier,
  orgHasPermission,
  userHasPermission,
} from '../PermissionsService';

import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useLocation } from 'react-router-dom';
import { SentryRoute, ctxOrg, ctxProfile, ctxSyncState, history } from '../App';
import { SCHEMA_PREFIX_URL_SUFFIX, SHARED_FIELD_REPORT_BASE_PATH, SUPPLY_CHAIN_URL_SUFFIX } from '../GlobalConstants';
import PageDocsRedirect from '../PageDocsRedirect';
import PageLegacyInspection from '../PageLegacyInspection';
import PageInspectionView from '../PageInspectionView';
import PageTimeline from '../PageTimeline';
import { parseInspectionReference } from '../ServiceInspection';
import { AgrinormFrontendSchemaPreview } from '../agrinormFrontedViews/AgrinormFrontendSchemaPreview';
import { OnboardingPage } from '../pages/OnboardingPage/OnboardingPage';
import PageProductionSiteInspection from '../PageProductionSiteInspection';
import { PageLocationDetails } from '../pages/PageLocationDetails/PageLocationDetails';
import { isLocalServer, isMobile } from '../HelperUtils';
import { PagePwaEnforcing } from '../PagePwaEnforcing';
import ViewQuestionTab from '../ViewQuestionTab';
import { PageChangeLog } from '../PageChangeLog';
import { PageSharedFieldReport } from '../pages/PageSharedFieldReport/PageSharedFieldReport';

export const CMainRoutes = () => {
  const profile = useContext(ctxProfile);
  const org = useContext(ctxOrg);
  const { isInstalledPwaSession, isInvitationLink } = useContext(ctxSyncState);

  const isFreeTier = () => {
    return isFreeQCTier(org?.settings);
  };

  const insightsAccess =
    orgHasPermission(org?.settings, AppProduct.Insights, InsightsTierAction.Insights) &&
    userHasPermission(profile, 'VIEW', 'INSIGHT_EXPLORE');

  const partnerInsightsAccess =
    orgHasPermission(org?.settings, AppProduct.Insights, InsightsTierAction.Partners) &&
    userHasPermission(profile, 'VIEW', 'INSIGHT_PROFILES');

  const hasChatAccess = orgHasPermission(
    org?.settings,
    AppProduct.QualityControl,
    QCTierAction.Chat
  );

  const hasDashboardAccess = () => {
    return orgHasPermission(
      org?.settings,
      AppProduct.QualityControl,
      QCTierAction.Dashboard
    );
  };

  const location = useLocation();

  const updateTitle = (location) => {
    const breadcrumbs = location.pathname.split('/');
    let title = breadcrumbs[breadcrumbs.length - 1];
    if (title === 'external') {
      title = breadcrumbs[breadcrumbs.length - 2];
    }
    document.title = 'AG - ' + title;
  };

  useEffect(() => {
    // Track route changes
    updateTitle(location);
  }, [location]);

  const isSupportUrl = document.location.href.includes('login.agrinorm.support');
  const isInvitationFlow =
    document.location.href.includes('onboarding') && isInvitationLink;

  if (isSupportUrl) {
    return (
      <IonRouterOutlet id="mainRouter">
        <SentryRoute
          path="*"
          render={() => {
            return <PageDocsRedirect />;
          }}
        />
      </IonRouterOutlet>
    );
  }

  if (
    isMobile() &&
    !isInstalledPwaSession &&
    !isInvitationFlow &&
    !isLocalServer() &&
    // Hack DEV-2222 (2024-02-05):
    // We are momentarily allowing Berryworld users to access the app from a browser on mobile devices
    !(profile.email.includes('agrinorm') || profile.organisationId === 'berryworld.com')
  ) {
    return (
      <IonRouterOutlet id="mainRouter">
        <SentryRoute path="*" render={() => <PagePwaEnforcing />} />
      </IonRouterOutlet>
    );
  }

  return (
    <>
      <IonReactRouter history={history}>
        <IonRouterOutlet id="mainRouter">
          <SentryRoute
            exact
            path="/culo"
            render={(props) => {
              return <PageMissingPermissions />;
            }}
          />
          <SentryRoute
            exact
            path="/shared-customer-profile/buyer/:contactId/:name/:id"
            render={(props) => (
              <PageCustomerProfiling
                {...props}
                profile={profile}
                isShared={true}
                sharedId={decodeURIComponent(props.match.params['id'])}
                contactName={decodeURIComponent(props.match.params['name'])}
                contactId={decodeURIComponent(props.match.params['contactId'])}
              />
            )}
          />
          <SentryRoute
            exact
            path="/shared-customer-profile/supplier/:contactId/:name/:id"
            render={(props) => (
              <PageSupplierProfiling
                {...props}
                profile={profile}
                isShared={true}
                sharedId={decodeURIComponent(props.match.params['id'])}
                contactName={decodeURIComponent(props.match.params['name'])}
                contactId={decodeURIComponent(props.match.params['contactId'])}
              />
            )}
          />
          {/* {!!isThatUrl && <Redirect to="/culo" from="*" />} */}
          <SentryRoute
            exact
            path="/secure/:organisationId/timeline"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'ORDER') && !isFreeTier() ? (
                <PageTimeline
                // {...props}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />
          <SentryRoute
            exact
            path="/secure/:organisationId/order/:orderId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'ORDER') && !isFreeTier() ? (
                <PageOrder
                  // {...props}
                  history={props.history}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  orderId={decodeURIComponent(props.match.params['orderId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/report/:reportId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'ORDER') ? (
                <PageReport
                  history={props.history}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  reportId={decodeURIComponent(props.match.params['reportId'])}
                  reportType={props.match.params['reportType']}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/report/draft/:reportId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'ORDER') && !isFreeTier() ? (
                <PageReport
                  history={props.history}
                  draft={true}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  reportId={decodeURIComponent(props.match.params['reportId'])}
                  reportType={props.match.params['reportType']}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          {/* TODO ILDE: get rid of /external URL */}
          <SentryRoute
            exact
            path="/secure/report/:reportId/external"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'ORDER') ? (
                <PageReport
                  history={props.history}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  reportId={decodeURIComponent(props.match.params['reportId'])}
                  reportType={props.match.params['reportType']}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/order/:orderId/upfront"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'ORDER') && !isFreeTier() ? (
                <PageOrder
                  // {...props}
                  hideGeneral={true}
                  upfront={true}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  orderId={decodeURIComponent(props.match.params['orderId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          {/* <SentryRoute exact
        path="/secure/:organisationId/report/:assessmentReference"
        render={(props) =>
          userHasPermission(profile, 'VIEW', 'REPORT') ? (
            <PageInspectionResult
              history={props.history}
              profile={profile}
              applicationContext={applicationContext}
              organisationId={decodeURIComponent(
                props.match.params["organisationId"]
              )}
              assessmentReference={parseAssessmentId(
                decodeURIComponent(props.match.params["assessmentReference"])
              )}
            />)
            :
            <PageMissingPermissions />
        }
      /> */}

          <SentryRoute
            exact
            path="/secure/:organisationId/inspection-view/:inspectionReference"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'REPORT') ? (
                <PageInspectionView
                  history={props.history}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  inspectionReference={parseInspectionReference(
                    decodeURIComponent(props.match.params['inspectionReference'])
                  )}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path={`/secure/:organisationId/inspection-view/:inspectionReference/${SUPPLY_CHAIN_URL_SUFFIX}`}
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'REPORT') ? (
                <PageInspectionView
                  history={props.history}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  inspectionReference={parseInspectionReference(
                    decodeURIComponent(props.match.params['inspectionReference'])
                  )}
                  supplyChainLot={true}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/inspection-view/:inspectionReference/reportId/:reportId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'REPORT') ? (
                <PageInspectionView
                  history={props.history}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  inspectionReference={parseInspectionReference(
                    decodeURIComponent(props.match.params['inspectionReference'])
                  )}
                  reportId={decodeURIComponent(props.match.params['reportId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            path={`/secure/${SCHEMA_PREFIX_URL_SUFFIX}/:sectionIds`}
            render={(props) => {
              return (userHasPermission(profile, 'WRITE', 'SPECIFICATIONS') &&
                !isFreeQCTier(org?.settings)) ||
                profile?.email?.includes('agrinorm') ? (
                <AgrinormFrontendSchemaPreview
                  sectionIds={
                    JSON.parse(
                      decodeURIComponent(
                        props.match.params['sectionIds'] ?? encodeURIComponent('[]')
                      )
                    ) as string[]
                  }
                />
              ) : (
                <PageMissingPermissions />
              );
            }}
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/field-inspection/:stringifiedReference"
            render={(props) =>
              userHasPermission(profile, 'WRITE', 'ASSESSMENT') && !isFreeTier() ? (
                <PageProductionSiteInspection
                  {...props}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  stringifiedReference={decodeURIComponent(
                    props.match.params['stringifiedReference']
                  )}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/inspection/:inspectionReference"
            render={(props) =>
              userHasPermission(profile, 'WRITE', 'ASSESSMENT') && !isFreeTier() ? (
                <PageLegacyInspection
                  {...props}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  reference={decodeURIComponent(
                    props.match.params['inspectionReference']
                  )}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path={`/secure/:organisationId/inspection/:inspectionReference/${SUPPLY_CHAIN_URL_SUFFIX}`}
            render={(props) =>
              userHasPermission(profile, 'WRITE', 'ASSESSMENT') && !isFreeTier() ? (
                <PageLegacyInspection
                  {...props}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  reference={decodeURIComponent(
                    props.match.params['inspectionReference']
                  )}
                  supplyChainLot={true}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/location/:locationId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'LOT') && !isFreeTier() ? (
                <PageLocationDetails
                  {...props}
                  locationId={decodeURIComponent(props.match.params['locationId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/lot/:lotId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'LOT') && !isFreeTier() ? (
                <PageLot
                  {...props}
                  profile={profile}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  lotId={decodeURIComponent(props.match.params['lotId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/:organisationId/lot-from-order/:lotId/:orderId"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'LOT') && !isFreeTier() ? (
                <PageLot
                  {...props}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  lotId={decodeURIComponent(props.match.params['lotId'])}
                  orderId={decodeURIComponent(props.match.params['orderId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path={`/secure/:organisationId/lot/:lotId/${SUPPLY_CHAIN_URL_SUFFIX}`}
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'LOT') && !isFreeTier() ? (
                <PageLot
                  {...props}
                  organisationId={decodeURIComponent(
                    props.match.params['organisationId']
                  )}
                  supplyChainLot={true}
                  lotId={decodeURIComponent(props.match.params['lotId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/inbox/:conversationId"
            render={(props) =>
              hasChatAccess ? (
                <PageConversation
                  conversationId={decodeURIComponent(
                    props.match.params['conversationId']
                  )}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/inbox/:conversationId/new-message"
            render={(props) =>
              hasChatAccess ? (
                <PageConversation
                  {...props}
                  conversationId={decodeURIComponent(
                    props.match.params['conversationId']
                  )}
                  newMessage={true}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          {/* Since this shows orders, we don't allow uesrs to go in without view order permissions */}
          <SentryRoute
            exact
            path="/secure/contact/:contactId"
            render={(props) =>
              (userHasPermission(profile, 'VIEW', 'ORDER') || partnerInsightsAccess) &&
              !isFreeTier() ? (
                <PageContact
                  contactId={decodeURIComponent(props.match.params['contactId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/supplier/:contactId/insight"
            // sensitive={false}
            render={(props) =>
              partnerInsightsAccess ? (
                <PageSupplierProfiling
                  {...props}
                  contactId={decodeURIComponent(props.match.params['contactId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/customer/:contactId/insight"
            // sensitive={false}
            render={(props) =>
              partnerInsightsAccess ? (
                <PageCustomerProfiling
                  {...props}
                  contactId={decodeURIComponent(props.match.params['contactId'])}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/insight/:sharedInsight?"
            render={(props) =>
              insightsAccess ? (
                (() => {
                  // Redirect old shared insight links to the new insights page
                  const sharedId = props.match.params['sharedInsight'];
                  window.location.href = `${process.env.REACT_APP_NEW_PLATFORM_HOST}/insights/shared/${sharedId}`;
                  return <></>;
                })()
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/secure/scoring"
            render={(props) =>
              userHasPermission(profile, 'WRITE', 'SPECIFICATIONS') &&
              !isFreeTier() &&
              org?.settings?.lotScoringType === 'ADLS' ? (
                <PageScoring {...props} />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact={true}
            path="/tabs/inbox"
            render={(props) =>
              hasChatAccess ? <PageInbox /> : <PageMissingPermissions />
            }
          />

          <SentryRoute
            exact={true}
            path="/tabs/quality-control"
            render={(props) =>
              !isFreeTier() ? (
                <PageQualityControl
                  history={props.history}
                  // onUserPropertiesChanged={onUserPropertiesChanged}
                />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact={true}
            path="/tabs/reports"
            render={(props) => <PageReportDashboard />}
          />

          <SentryRoute
            path="/tabs/dashboard"
            render={(props) =>
              userHasPermission(profile, 'VIEW', 'DASHBOARD') &&
              hasDashboardAccess() ? (
                <PageDashboard />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/tabs/connect"
            render={(props) =>
              (userHasPermission(profile, 'VIEW', 'ORDER') || partnerInsightsAccess) &&
              !isFreeTier() ? (
                <PageConnect />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/tabs/settings"
            render={(props) => <PageSettings />}
          />

          <SentryRoute
            exact
            path="/tabs/admin"
            render={(props) =>
              userHasPermission(profile, 'WRITE', 'ADMIN_PARTNERS') ||
              userHasPermission(profile, 'WRITE', 'ADMIN') ||
              userHasPermission(profile, 'WRITE', 'SPECIFICATIONS') ? (
                <PageManagement />
              ) : (
                <PageMissingPermissions />
              )
            }
          />

          <SentryRoute
            exact
            path="/shared-report/:id/:inspectionReference"
            render={(props) => (
              <PageInspectionView
                history={props.history}
                sharedReport={true}
                sharedReportId={decodeURIComponent(props.match.params['id'])}
                inspectionReference={decodeURIComponent(
                  props.match.params['inspectionReference']
                )}
              />
            )}
          />

          <SentryRoute
            exact
            path="/shared-report/:id/:inspectionReference"
            render={(props) => (
              <PageInspectionView
                history={props.history}
                sharedReport={true}
                sharedReportId={decodeURIComponent(props.match.params['id'])}
                inspectionReference={decodeURIComponent(
                  props.match.params['inspectionReference']
                )}
              />
            )}
          />

          <SentryRoute
            exact
            path="/shared-report/:id"
            render={(props) => (
              <PageReport
                history={props.history}
                sharedReport={true}
                id={decodeURIComponent(props.match.params['id'])}
              />
            )}
          />

          <SentryRoute
            exact
            path={`/${SHARED_FIELD_REPORT_BASE_PATH}/:sharedReportId/:fieldInspectionId`}
            render={(props) => (
              <PageInspectionView
                {...props}
                sharedFieldReport={true}
                sharedReportId={decodeURIComponent(
                  props.match.params['sharedReportId']
                )}
                fieldInspectionId={decodeURIComponent(
                  props.match.params['fieldInspectionId']
                )}
              />
            )}
          />
          <SentryRoute
            exact
            path={`/${SHARED_FIELD_REPORT_BASE_PATH}/:sharedReportId`}
            render={(props) => (
              <PageSharedFieldReport
                {...props}
                sharedReportId={decodeURIComponent(
                  props.match.params['sharedReportId']
                )}
              />
            )}
          />

          <SentryRoute
            exact
            path="/secure/changelog"
            render={(props) => <PageChangeLog />}
          />

          <SentryRoute
            exact
            path="/secure/question-editor"
            render={() =>
              profile.email.includes('agrinorm') ? (
                <ViewQuestionTab />
              ) : (
                <Redirect to="/" exact />
              )
            }
          />

          {/* PERM_TODO: is this the right permission? */}
          {/* <SentryRoute exact
          path="/tabs/invite-email"
          render={(props) => userHasPermission(profile, 'WRITE', 'ADMIN_PARTNERS') ? (
            <PageInviteLink
              {...props}
              profile={profile}
              applicationContext={applicationContext}
            />)
            :
            <PageMissingPermissions />
          }
        /> */}

          {isInvitationFlow ? (
            <SentryRoute exact path="/onboarding" render={() => <OnboardingPage />} />
          ) : (
            <Redirect to="/" from="/onboarding" exact />
          )}

          <Redirect
            to={isFreeTier() ? '/tabs/reports' : '/tabs/quality-control'}
            from="/"
            exact
          />
          <Redirect
            to="/tabs/quality-control?tab=outgoing"
            from="/tutorial-finished"
            exact
          />

          {/* <Redirect to="/tabs/quality-control" /> */}
        </IonRouterOutlet>
      </IonReactRouter>
    </>
  );
};
