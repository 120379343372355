import { cloneDeep } from 'lodash';
import * as math from 'mathjs';
import { MutableRefObject } from 'react';
import {
  companyToAGScore,
  isSplitLot,
  isSplitLotPosition,
  replaceAll,
} from './HelperUtils';
import {
  ADLSScoringParams,
  ADPScoringParams,
  ContextReferencePrefix,
  LotInspection,
  InspectionContext,
  InspectionError,
  InspectionPropertiesMap,
  LegacyInspectionReference,
  InspectionRenderingInfo,
  InspectionScore,
  LotProperties,
  MeasurableId,
  MeasurableInput,
  MeasurementInput,
  MeasurementValueType,
  PartialSummaryResolutionCanditates,
  QuestionInput,
  QuestionViewSpec,
  UserInputLocator,
  ocrQuestionIds,
  LegacyInspection,
  BaseInspection,
  TransitInspection,
  Inspection,
  ProductionSiteInspectionReference,
  ProductionSiteInspection,
} from './InspectionModel';
import { ADLSBatchLevelScorer, ADPBatchLevelScorer } from './LotScoringLogic';
import {
  AGScore,
  AG_BOXES_AT_INSPECTION_QUESTION_ID,
  AG_BOXES_RECEIVED,
  AG_BOXES_SHIPPED_MISMATCH,
  AG_BOXES_SHIPPED_QUESTION_ID,
  AG_VOLUME_AT_INSPECTION,
  IArticle,
  Lot,
  Order,
  OrganisationSettings,
  ProductionSiteLocation,
  Report,
} from './Model';
import {
  Criteria,
  FieldInspectionSpec,
  InspectionSpec,
  LotInspectionSpec,
  LotScoringSection,
  SectionNames,
} from './ModelSpecification';
import { injectOCRQuestionsToSpec } from './ServiceInspection';
import { PartialSummarySpec } from './libs/partial-summary';
import {
  AuxmeasurementsInner,
  ComputedMeasurable,
  Criteria as LotCriteria,
  FieldSchemaObjectTypeEnum,
  InspectionRelevantProperties,
  InspectionSpecQuestionSpec,
  InspectionSpecSection,
  InspectionSpecSubsection,
  IntMeasurement,
  LayoutInner1,
  LotSchemaObjectTypeEnum,
  MeasurablesInner,
  Measurement1,
  MeasurementUnit,
  PackagingType,
  QuestionSpec,
  SectionType,
  TransitSchemaObjectTypeEnum,
  VerifiedMeasurable,
  FieldCriteria,
} from './generated/openapi/core';

import { groupBy } from 'lodash';

interface InspectionDelta {
  inputLocator: UserInputLocator;
  value: any;
}

// Separators.
const REF_ID_PREFIX_SEPARATOR = '|';
const MEASURABLE_ID_SEPARATOR = ':';
const AUX_MEASUREMENT_SEPARATOR = ';';
const OR_FORMULA_SEPARATOR = '$';

// Prefixes.
const TAG_PREFIX = 'tag';
const MEASUREMENT_PREFIX = 'meas';
const SCORE_PREFIX = 'score';
// qscore stands for Question Score (as opposed to Inspection Level Score for the whole
// lot).
const QSCORE_PREFIX = 'qscore';
const AUX_PREFIX = 'aux';
const EXTERNAL_PREFIX = `extern${REF_ID_PREFIX_SEPARATOR}`;
export const PARTIAL_SUMMARY_PREFIX = 'partial_summary~';

// Agrinorm controlled custom formulas for measurables
const AG_FORMULA_WRAPPER_START = '<AG>';
const AG_FORMULA_WRAPPER_END = '</AG>';

enum AgFormulas {
  range_map = 'range_map',
}

function extendPartialSummaryResolutionCandidates(
  inspectionSpec: InspectionSpec,
  sectionLevelQuestionIds: string[],
  output: PartialSummaryResolutionCanditates
) {
  // This is a map that is keyed by the tag and contains all the partial summaries
  // relying on that tag in the formula.
  const tagsToPartialSummaries = groupBy(
    sectionLevelQuestionIds
      .filter((id: string) => id.startsWith(PARTIAL_SUMMARY_PREFIX))
      .map((id: string) => {
        const spec = new PartialSummarySpec().parseFromIdString(id);
        return spec;
      }),
    (spec: PartialSummarySpec) => spec.tag
  );
  // Now go through all normal questions and check if they reference any of the tags
  // that we collected in tagsToPartialSummaries above. If so, take all the primary
  // measurables and add them to the output map of map.
  for (const questionId of sectionLevelQuestionIds) {
    const spec: InspectionSpecQuestionSpec = inspectionSpec.questionSpecs[questionId];
    // Skip partial summaries as they cannot be used as inputs to other partial
    // summaries. (They should not have a tag anyway, but better safe than sorry.)
    if (!!spec.isPartialSummary) continue;

    // Collect the possible refIds for this question: either the primary measurable or a
    // question level score.
    const refIdsWithUnits: { refId: string; unit: MeasurementUnit | undefined }[] = [];

    // Add the primary measurable.
    const primaryMeasurable: MeasurablesInner | undefined = spec.measurables.find(
      (m: MeasurablesInner) => m.isPrimary || spec.measurables.length === 1
    );
    if (primaryMeasurable !== undefined) {
      refIdsWithUnits.push({
        refId: buildContextRefIdFromInputLocator({
          questionId,
          measurableId: primaryMeasurable.measurableId,
        } as UserInputLocator),
        unit:
          'unit' in primaryMeasurable.measurement
            ? primaryMeasurable.measurement.unit
            : undefined,
      });
    }

    // Add the question level score.
    if (spec.scoringPolicy != null) {
      refIdsWithUnits.push({
        refId: buildContextRefIdFromInputLocator({
          questionId,
          isQuestionScore: true,
        } as UserInputLocator),
        unit: MeasurementUnit.Score,
      });
    }

    // Loop through the tags for this question that are inputs to partial summaries.
    spec.questionTagIds
      ?.filter((tagId: string) => tagId in tagsToPartialSummaries)
      .forEach((tagId: string) => {
        refIdsWithUnits.forEach(
          (refIdWithUnit: { refId: string; unit: MeasurementUnit | undefined }) => {
            // Add this mesaurable as a dependency for all partial summaries that reference
            // the current tagId.
            const { refId, unit } = refIdWithUnit;
            tagsToPartialSummaries[tagId].forEach(
              (partialSummarySpec: PartialSummarySpec) => {
                // Skip non-matching units.
                if (unit !== partialSummarySpec.unit) return;
                const partialSummaryId = [
                  MEASUREMENT_PREFIX,
                  REF_ID_PREFIX_SEPARATOR,
                  partialSummarySpec.getIdString(),
                ].join('');
                const formulaInput = [
                  TAG_PREFIX,
                  REF_ID_PREFIX_SEPARATOR,
                  partialSummarySpec.tag,
                  MEASURABLE_ID_SEPARATOR,
                  partialSummarySpec.unit,
                ].join('');
                // We make sure that the map of map is initialized at both level.
                if (!output[partialSummaryId]) output[partialSummaryId] = {};
                if (!output[partialSummaryId][formulaInput])
                  output[partialSummaryId][formulaInput] = [];
                output[partialSummaryId][formulaInput].push(refId);
              }
            );
          }
        );
      });
  }
}

// TODO(dienes) Move this to partial-summary.ts and update the tests.
function getPartialSummaryResolutionCandidates(
  spec: InspectionSpec
): PartialSummaryResolutionCanditates {
  const output: PartialSummaryResolutionCanditates = {};
  for (const section of spec.layout) {
    const sectionLevelQuestions: string[] = [];
    for (const layoutItem of section.layout) {
      if (typeof layoutItem === 'string') {
        sectionLevelQuestions.push(layoutItem);
      } else {
        sectionLevelQuestions.push(
          ...layoutItem.questionIds.filter(
            (id: string) => !id.startsWith(PARTIAL_SUMMARY_PREFIX)
          )
        );
        extendPartialSummaryResolutionCandidates(spec, layoutItem.questionIds, output);
      }
    }
    extendPartialSummaryResolutionCandidates(spec, sectionLevelQuestions, output);
  }
  return output;
}

export function prepareInspectionContext<T extends InspectionSpec>(
  spec: T,
  inspection?: Inspection,
  properties: InspectionPropertiesMap = {}
): InspectionContext {
  // Iterate over the question specs
  // extract all the inspection relevant properties and go fetch them from the corresponding entities
  // make an inventory of all needed measurables and aux measurements
  // stuff it into the context and return it

  const inputs: { [contextRefId: string]: number | number[] } = {};
  const formulas: { [measurebleId: string]: string } = {};
  Object.entries(spec.questionSpecs).forEach(
    ([questionId, spec]: [string, InspectionSpecQuestionSpec]) => {
      // If this question spec has a scoring policy, add the question scores to the
      //context as a potential input.
      if ('scoringPolicy' in spec) {
        const refId = buildContextRefIdFromInputLocator({
          questionId,
          isQuestionScore: true,
        } as UserInputLocator);
        inputs[refId] = null;
      }
      spec.measurables.forEach((m: MeasurablesInner) => {
        const refId = buildContextRefIdFromInputLocator({
          questionId,
          measurableId: m.measurableId,
        } as UserInputLocator);

        if (m.type === 'computed') {
          // add formula to context
          formulas[refId] = m.formula;
          // extract the references from the formulas and stuff them into the context
          const refIds = extractRefIdsFromFormulas(m.formula);
          refIds.forEach((refId) => (inputs[refId] = null));
        }
      });
    }
  );

  const context: InspectionContext = {
    inputs,
    properties,
    formulas,
    resolvedFormulas: {},
    partialSummaryResolutionCandidates: getPartialSummaryResolutionCandidates(spec),
  };

  // If an inspection is passed, populate the context with the current user inputs
  if (!!inspection) {
    Object.entries(inspection.userInputs ?? {}).forEach(([questionId, input]) => {
      // Add the question score if it exists for this measurable.
      if (input?.agScore != null) {
        const refIdForQuestionScore = buildContextRefIdFromInputLocator({
          questionId,
          isQuestionScore: true,
        } as UserInputLocator);
        if (refIdForQuestionScore in context.inputs) {
          context.inputs[refIdForQuestionScore] = input.agScore;
        }
      }

      (input.measurableInputs ?? []).forEach((mInput) => {
        const { measurableId } = mInput;
        const inputLocator: UserInputLocator = { questionId, measurableId };
        const refId = buildContextRefIdFromInputLocator(inputLocator);
        // Add the primary measurement.
        context.inputs[refId] = mInput.measurementInput?.value ?? null;
        // Add the aux measurements.
        (mInput.auxMeasurementsInput ?? []).forEach(
          (auxMInput, auxMeasurementIndex) => {
            const refId = buildContextRefIdFromInputLocator({
              ...inputLocator,
              auxMeasurementIndex,
            });
            context.inputs[refId] = auxMInput.value ?? null;
          }
        );
      });
    });
  }

  updateFormulasInContext(context);
  return context;
}

export function initDefaultValues<I extends Inspection, S extends InspectionSpec>(
  spec: S,
  inspection: I,
  context: InspectionContext,
  scoring: LotScoringSection,
  orgSettings: OrganisationSettings
): { inspection: I; context: InspectionContext } {
  let inittedContext = cloneDeep(context);
  let inittedInspection = cloneDeep(inspection);

  const inputs: { value: any; inputLocator: UserInputLocator }[] = [];

  // go over the specs, look for default values and store the inputs
  Object.entries(spec.questionSpecs).forEach(([questionId, spec]) => {
    // omit setting the default value if user input for the current question already exists in the original inspection
    if (!!inspection.userInputs?.[questionId]) {
      return;
    }
    spec.measurables.forEach((m) => {
      const { measurableId, measurement } = m;
      const source = (measurement as IntMeasurement).source;

      // If there's a 'source' in the measurement, it takes precedence over the 'defaultValue'
      const defaultValue = getDefaultValue(measurement, context);

      if (defaultValue != null) {
        const inputLocator: UserInputLocator = { questionId, measurableId };
        inputs.push({ value: defaultValue, inputLocator });
      }
      ((m as ComputedMeasurable).auxMeasurements ?? []).forEach(
        (am, auxMeasurementIndex) => {
          const auxDefaultValue = getDefaultValue(am, context);

          if (auxDefaultValue != null) {
            const inputLocator: UserInputLocator = {
              questionId,
              measurableId,
              auxMeasurementIndex,
            };
            inputs.push({ value: auxDefaultValue, inputLocator });
          }
        }
      );
    });
  });

  // initialize the inspection and context with the default values
  inputs.forEach((i) => {
    const { value, inputLocator } = i;
    const { updatedContext, updatedInspection } = handleUserInput(
      value,
      inputLocator,
      spec,
      inittedInspection,
      inittedContext,
      scoring,
      orgSettings
    );
    inittedContext = updatedContext;
    inittedInspection = updatedInspection;
  });

  // init inspection level scores if present
  computeInspectionLevelScores(
    [],
    inittedInspection,
    spec,
    orgSettings,
    scoring,
    false
  );

  return { inspection: inittedInspection, context: inittedContext };
}

function getDefaultValue(
  measurement: AuxmeasurementsInner | Measurement1,
  context: InspectionContext
) {
  const source = (measurement as IntMeasurement).source;
  const defaultValue = !!source
    ? getVerifiedSourceValue(context, source)
    : (measurement as IntMeasurement).defaultValue;
  return defaultValue;
}

export function handleUserInput<T extends Inspection>(
  value: any,
  inputLocator: UserInputLocator,
  spec: InspectionSpec,
  inspection: T,
  context: InspectionContext,
  scoring: LotScoringSection,
  orgSettings: OrganisationSettings
): { updatedInspection: T; updatedContext: InspectionContext } {
  const updatedContext = cloneDeep(context);
  const updatedInspection = cloneDeep(inspection);

  if (value === '' || (Array.isArray(value) && value?.length === 0)) {
    value = undefined;
  }

  // If input is being removed, default it when it corresponds
  const defaultValue = getDefaultValue(
    getMeasurement(spec, undefined, inputLocator),
    context
  );
  if (defaultValue != null && value == null) {
    value = defaultValue;
  }

  // Check that the value is within range
  // We ignore measurables of type computed here as in their case we display a warning if the value is outside of the range in the component itself (CInspectionItem)
  if (!isMeasurableOfType('computed', inputLocator, spec) && value != null) {
    let { maxValue, minValue } = extractMeasurementFields(inputLocator, spec);
    if (maxValue != null && value > maxValue) {
      value = maxValue;
    }
    if (minValue != null && value < minValue) {
      value = minValue;
    }
  }

  let changes: InspectionDelta[] = [
    {
      inputLocator,
      value,
    },
  ];

  const isPotentialQScore: boolean =
    'scoringPolicy' in spec.questionSpecs[inputLocator.questionId];
  // check if the context has to be updated and update it
  if (
    isInputContextRelevant(inputLocator, updatedContext) ||
    isInputContextRelevant(
      { questionId: inputLocator.questionId, isQuestionScore: isPotentialQScore },
      updatedContext
    )
  ) {
    // we also compute the whole chain of additional changes generated by the change in the context
    let additionalChanges: InspectionDelta[] = [];
    updateInspectionContext(
      value,
      inputLocator,
      updatedContext,
      updatedInspection,
      spec,
      additionalChanges,
      scoring,
      orgSettings
    );
    changes = [...changes, ...additionalChanges];
  }
  // update the user inputs/scores in the inspection
  handleInspectionChanges(
    changes,
    updatedInspection,
    spec,
    orgSettings,
    context,
    scoring
  );

  // compute and update question level scores
  computeQuestionLevelScores(updatedInspection, spec);

  // update inspection level scores if there is a lot scoring in place
  if (isAutoScoresAThing(spec)) {
    computeInspectionLevelScores(
      changes,
      updatedInspection,
      spec,
      orgSettings,
      scoring
    );
    // TODO: update auto calculated scores in the context, if it corresponds. This could trigger even more changes, leading to potential infinite loops if the dependences between questions are not well designed, how to go about it?
  }

  return { updatedInspection, updatedContext };
}

export function compileInspectionRenderingInfo(
  spec: InspectionSpec,
  inspection: Inspection,
  context: InspectionContext,
  orgSettings: OrganisationSettings
): InspectionRenderingInfo {
  const layout: InspectionSpecSection[] = cloneDeep(spec.layout);
  const questionSpecs: { [questionId: string]: QuestionViewSpec } = {};

  Object.entries(spec.questionSpecs).forEach(([questionId, spec]) => {
    const { reportProperties, displayedName, cardProperties } = spec;

    const viewSpec: QuestionViewSpec = {
      reportProperties: reportProperties ?? {},
      displayedName,
      cardProperties: cardProperties ?? {},
    };

    questionSpecs[questionId] = viewSpec;
  });

  const info: InspectionRenderingInfo = {
    layout,
    questionSpecs,
    externalProperties: context.properties,
  };

  // Add inspection level scores to the summary, if they don't exist as a question already
  if (Object.keys(inspection.scores ?? {}).length > 0) {
    // if summary section doesn't exist, add it
    let summaryIndex = info.layout.findIndex(
      (l) => l.sectionType === SectionType.Summary
    );
    if (summaryIndex < 0) {
      summaryIndex = info.layout.length;
      info.layout.push({
        name: SectionNames[SectionType.Summary],
        type: 'default',
        sectionType: SectionType.Summary,
        layout: [],
        expandByDefault: true,
        reportProperties: { hideEmptyQuestions: true, hideInExternalReport: false },
      });
    }

    // populate the question view specs for scores
    Object.entries(inspection.scores)
      .sort((a, b) => sortScoresEntries(a, b, orgSettings))
      .forEach(([scoreId, score]) => {
        if (info.layout[summaryIndex].layout.includes(scoreId)) {
          return;
        }
        const scoreSpec: QuestionViewSpec = {
          cardProperties: {
            hideInCard: false,
            cardName: null,
          },
          reportProperties: {
            hideInExternalReport: false,
            hideInReport: false,
          },
          displayedName: score.name,
        };
        info.layout[summaryIndex].layout.push(scoreId);
        info.questionSpecs[scoreId] = scoreSpec;
      });
  }

  return info;
}

export function newInspectionForOrder(
  reference: LegacyInspectionReference,
  order: Order | Report
): LegacyInspection {
  const baseInspection: BaseInspection = {
    status: 'OPEN',
    source: 'INTERNAL',
    pictures: [],
    userInputs: {},
    scores: {},
    lastModifiedDate: new Date(),
  };

  if (reference.transportId) {
    const transitInspection: TransitInspection = {
      ...baseInspection,
      objectType: TransitSchemaObjectTypeEnum.Transit,
      transitProperties: {},
      reference,
    };
    if (!!order) {
      const { isCA, isRefrigerated, transportType } = order.transport ?? {};
      transitInspection.transitProperties = {
        isCA,
        isRefrigerated,
        transportType,
      };
    }
    return transitInspection;
  }

  if (reference.lotId) {
    const lotInspection: LotInspection = {
      ...baseInspection,
      objectType: LotSchemaObjectTypeEnum.Lot,
      reference,
      barcodes: [],
      lotProperties: {
        article: undefined,
      },
    };

    const pos = order?.positions?.find((pos) => pos.lotId === reference.lotId);
    if (pos) {
      const lotProperties: LotProperties = {
        article: pos.article,
        palletIds: pos.palletIds,
        ggnList: !!pos.ggns ? pos.ggns : !!pos.ggn ? [pos.ggn] : [],
        isSplitLot: isSplitLotPosition(pos),
      };
      lotInspection.lotProperties = lotProperties;
    }
    return lotInspection;
  }
}

export function newInspectionForLot(
  reference: LegacyInspectionReference,
  lot?: Lot,
  spec?: InspectionSpec,
  scoring?: LotScoringSection
): LotInspection {
  const lotProperties: LotProperties = getInspectionLotProperties(lot);
  const inspection: LotInspection = {
    reference,
    status: 'OPEN',
    source: 'INTERNAL',
    pictures: [],
    barcodes: [],
    userInputs: {},
    scores: {},
    lastModifiedDate: reference.date ? new Date(reference.date) : new Date(),
    lotProperties,
    objectType: LotSchemaObjectTypeEnum.Lot,
  };

  // if a schema is passed, we try to populate the inspection with the input of the latest inspection for all those questions that are also present in the current schema
  if (!!spec && !!lot?.latestInspection) {
    removeIncompatibleInputs(inspection, spec, scoring, lot.latestInspection);
  }

  return inspection;
}

export function newProductionSiteInspection(
  reference: ProductionSiteInspectionReference,
  location?: ProductionSiteLocation
): ProductionSiteInspection {
  const inspection: ProductionSiteInspection = {
    reference,
    status: 'OPEN',
    source: 'INTERNAL',
    pictures: [],
    userInputs: {},
    scores: {},
    lastModifiedDate: reference.date ? new Date(reference.date) : new Date(),
    objectType: FieldSchemaObjectTypeEnum.Field,
    productionSiteSnapshot: location,
  };

  return inspection;
}

export function getInspectionLotProperties(lot: Lot): LotProperties {
  const lotProps: LotProperties = cloneDeep({
    article: lot?.article,
    palletIds: lot?.transient?.palletIds,
    ggnList: !!lot?.origin?.growerGGNs
      ? lot!.origin!.growerGGNs!.filter((v) => v != null)
      : !!lot?.origin?.growerGGN
      ? [lot?.origin?.growerGGN].filter((v) => v != null)
      : [],
    isSplitLot: isSplitLot(lot),
  });

  Object.entries(lotProps).forEach(([key, val]) => {
    if (val == null) delete lotProps[key];
  });

  return lotProps;
}

/*
this function removes user inputs that are not compatible with the current schema for cases like:
- inspection is reopened and one of the questions changed in the schema (e.g. now it has a different amount of measurables or they are of different type)
- conducting a stock inspection for a lot that already has an incoming inspection, and the stock schema has a question with the same id but different structure
*/
export function removeIncompatibleInputs(
  newInspection: Inspection,
  spec: InspectionSpec,
  scoring?: LotScoringSection,
  oldInspection: Inspection = cloneDeep(newInspection)
) {
  if (!!newInspection && !!spec) {
    Object.entries(oldInspection.userInputs ?? {})
      .map(([questionId, input]) => {
        const res = (v: boolean): [boolean, string, QuestionInput] => [
          v,
          questionId,
          input,
        ];

        // Do not copy "boxes shipped" questions' inputs if it's not an incoming inspection
        if (
          [AG_BOXES_SHIPPED_QUESTION_ID, AG_BOXES_SHIPPED_MISMATCH].includes(
            questionId
          ) &&
          newInspection.reference.type !== 'incoming'
        ) {
          return res(false);
        }

        // if (
        //   newInspection.objectType === LotSchemaObjectTypeEnum.Lot &&
        //   !fulfillsCriteria(spec.criteria, newInspection, questionId)
        // ) {
        //   return res(false);
        // }

        const specs = spec.questionSpecs[questionId];
        if (!specs) {
          return res(false);
        }

        // check that the input is compatible with the specs (i.e: have same number and type of measurables)
        for (const oldInput of input.measurableInputs ?? []) {
          const measurableInNewSpec = specs.measurables?.find(
            (m) => m.measurableId === oldInput.measurableId
          );

          if (
            !measurableInNewSpec ||
            (!!oldInput.measurementInput?.valueType &&
              measurableInNewSpec?.measurement.type !==
                oldInput.measurementInput.valueType)
          ) {
            return res(false);
          }

          if (measurableInNewSpec.type === 'computed') {
            for (const [idx, auxmi] of (
              oldInput.auxMeasurementsInput ?? []
            ).entries()) {
              if (
                !!measurableInNewSpec.auxMeasurements?.[idx] &&
                measurableInNewSpec.auxMeasurements?.[idx]?.type !== auxmi.valueType
              ) {
                return res(false);
              }
            }
          }
        }
        return res(true);
      })
      .forEach(([isValid, questionId, input]) => {
        if (isValid) {
          newInspection.userInputs[questionId] = input;
        } else {
          delete newInspection.userInputs[questionId];
        }
      });

    if (!!scoring) {
      Object.entries(oldInspection.scores ?? {})
        .filter(([scoreId]) => !!scoring?.lotScoring?.[scoreId])
        .forEach(([scoreId, score]) => (newInspection.scores[scoreId] = score));
    }
  }
}

export function getErrorsOnInspectionClose({
  inspection,
  spec,
  renderedQuestionIds,
}: {
  inspection: Inspection;
  spec: InspectionSpec;
  renderedQuestionIds: MutableRefObject<string[]>;
}): { errors: InspectionError[]; mandatoryQuestionIds: string[] } {
  let errors: InspectionError[] = [];

  let questionSpecs = cloneDeep({ ...(spec?.questionSpecs ?? {}) });

  // Compute mandatory field errors
  let mandatoryQuestionIds = Object.keys(questionSpecs)
    .filter((qId) => renderedQuestionIds.current.includes(qId))
    .filter((qId) => isMandatoryQuestion(spec, qId));

  mandatoryQuestionIds.forEach((questionId) => {
    if (
      inspection.userInputs?.[questionId] == null &&
      inspection.scores?.[questionId] == null
    ) {
      const error: InspectionError = {
        type: 'missing_mandatory',
        questionId,
      };
      errors.push(error);
    }
  });

  // Compute out of range errors
  Object.entries(inspection.userInputs ?? {}).forEach(([questionId, input]) => {
    for (const mInput of input.measurableInputs ?? []) {
      const { measurableId } = mInput;
      const inputLocator: UserInputLocator = { questionId, measurableId };
      const { value } = getValueAndUnitFromLocator(inputLocator, inspection);
      let { minValue, maxValue, unit } = extractMeasurementFields(inputLocator, spec);
      const isComputed = isMeasurableOfType('computed', inputLocator, spec);
      const valueIsNumeric = !isNaN(+value);

      // if it's a computed value with a unit based on percentage, and the maxValue is missing, we set it to 100%
      if (
        maxValue == null &&
        (unit ?? '').includes('percentage') &&
        !questionSpecs[questionId].isPartialSummary
      ) {
        maxValue = 100;
      }

      if (
        isComputed &&
        valueIsNumeric &&
        ((minValue != null && +value < minValue) ||
          (maxValue != null && +value > maxValue))
      ) {
        const error: InspectionError = {
          type: 'out_of_range',
          questionId,
        };
        errors.push(error);
        break;
      }
    }
  });

  // NOTE: this was requested by Frutania, but we disabled it because it was causing problems to our existing customers
  // // Compute non-present tagged defects
  // inspection.pictures.forEach(({ inputIds }) => {
  //   (inputIds ?? []).forEach((questionId) => {
  //     const omittedQuestionIds: string[] = ['label']
  //     if (!omittedQuestionIds.includes(questionId) && !(questionId in inspection.userInputs)) {
  //       errors.push({
  //         type: 'non_present_tagged_defects',
  //         questionId,
  //       });
  //     }
  //   });
  // });

  console.log('errors', errors);

  return { errors, mandatoryQuestionIds };
}

//----------------------------------------------------------------------------
// Schemas

export function getApplicableLotOrTransitSpecs(
  reference: LegacyInspectionReference,
  specs: InspectionSpec[],
  article?: IArticle,
  inspection?: LegacyInspection
): { applicableSpecs: InspectionSpec[]; spec: InspectionSpec } {
  let applicableSpecs: InspectionSpec[] = cloneDeep(specs).filter((v) =>
    ['lot', 'transit'].includes(v.objectType)
  );

  if (reference?.transportId) {
    applicableSpecs = applicableSpecs.filter((s) =>
      (s.criteria?.articleProperties?.inspectionTypes ?? []).includes('transport')
    );
  } else {
    applicableSpecs = getNonTransportApplicableSpecs(
      applicableSpecs,
      criteriaFromArticleAndReference(article, reference)
    );
  }

  let defaultSpec = applicableSpecs[0];

  // try to find the schema with the same id as the one in the inspection if we are reopening an inspection
  let spec =
    inspection?.schemaId != null
      ? specs.find((s) => s.id === inspection?.schemaId)
      : undefined;

  // if no schema was found and there's only one applicable schema, use it by default
  if (!spec && applicableSpecs.length === 1) {
    spec = defaultSpec;
  }

  // if an inspection is passed, inject the OCR ag question ids to the layout when related user inputs are present
  if (!!spec && !!inspection) {
    const presentOcrQuestionIds = ocrQuestionIds.filter((q) =>
      Object.keys(inspection.userInputs ?? {}).includes(q)
    );
    spec = injectOCRQuestionsToSpec(spec, presentOcrQuestionIds);
  }

  if (reference.type == null || !reference.type?.length) {
    spec = undefined;
  }

  return { applicableSpecs, spec };
}

//----------------------------------------------------------------------------
export function getApplicableProductionSiteSpecs(
  specs: InspectionSpec[],
  location: ProductionSiteLocation,
  inspection?: ProductionSiteInspection
): {
  applicableSpecs: FieldInspectionSpec[];
  spec: FieldInspectionSpec | undefined;
} {
  let applicableSpecs: FieldInspectionSpec[] = filterProductionSiteInspectionSpecs(
    specs,
    location,
    inspection?.schemaId
  );

  if (applicableSpecs.length === 0) {
    return { applicableSpecs: applicableSpecs, spec: undefined };
  }

  let defaultSpec = applicableSpecs[0];

  // try to find the schema with the same id as the one in the inspection if we are reopening an inspection
  let spec =
    inspection?.schemaId != null
      ? applicableSpecs.find((s) => s.id === inspection?.schemaId)
      : undefined;

  // if no schema was found and there's only one applicable schema, use it by default
  if (!spec && applicableSpecs.length === 1) {
    spec = defaultSpec;
  }

  return { applicableSpecs: applicableSpecs, spec: spec };
}

export const filterProductionSiteInspectionSpecs = (
  specs: InspectionSpec[],
  location?: ProductionSiteLocation,
  schemaId?: string
): FieldInspectionSpec[] => {
  return specs.filter((s) => {
    if (s.objectType !== FieldSchemaObjectTypeEnum.Field) {
      return false;
    }

    return (
      (schemaId && s.id === schemaId) ||
      (s.criteria?.agProductIds ?? []).length === 0 ||
      !location ||
      !!location.producedVarieties
        ?.map((pv) => pv.agProductId)
        .some((agProdId) => s.criteria?.agProductIds.includes(agProdId))
    );
  }) as FieldInspectionSpec[];
};

export function getNonTransportApplicableSpecs(
  applicableSpecs: InspectionSpec[],
  criteria: LotCriteria
) {
  applicableSpecs = applicableSpecs.filter(
    (s) => !(s.criteria?.articleProperties?.inspectionTypes ?? []).includes('transport')
  );
  const criteriaFieldTypePairs = [
    ['agProductIds', 'array'],
    ['packaging', 'array'],
    ['inspectionTypes', 'array'],
    ['packagingTypes', 'array'],
    ['isBio', 'value'],
    ['origins', 'array'],
    ['brands', 'array'],
  ];

  for (const [field, type] of criteriaFieldTypePairs) {
    const articleProp = criteria.articleProperties[field];

    const specsFilteredByField = applicableSpecs.filter((s) => {
      const specProp = (s.criteria.articleProperties ?? {})[field];

      if (
        (type === 'array' ? (specProp ?? []).length === 0 : specProp == null) ||
        (type === 'array' ? (articleProp ?? []).length === 0 : articleProp == null)
      ) {
        return true;
      }

      if (type === 'array') {
        let massagedArticleProp = articleProp?.map((p) =>
          field === 'packagingTypes' ? p.toLowerCase() : p
        );
        return !!specProp?.some((c: any[]) => c.includes(massagedArticleProp));
      } else {
        return specProp === articleProp;
      }
    });

    if (specsFilteredByField.length > 0) {
      applicableSpecs = specsFilteredByField;
    }
  }
  return applicableSpecs;
}

export function filterSectionOrSubsectionLayout(
  questionSpecs: { [key: string]: QuestionSpec },
  context: InspectionContext,
  inspection: Inspection,
  section?: InspectionSpecSection,
  subsection?: InspectionSpecSubsection
) {
  // do a first filtering by criteria and presence of context dependencies and extract all question ids that have a
  // 'mutuallyExclusiveQuestionIds' field in the criteria (while not including them yet in the filteredLayout)
  let excludedQIds: string[] = [];
  let filteredLayout = cloneDeep(
    !!section ? section.layout : subsection?.questionIds ?? []
  ).filter((l) =>
    questionInclusionFilter(l, excludedQIds, questionSpecs, context, inspection)
  );

  // now go over the excluded questions and check if at least one of their 'mutuallyExclusiveQuestionIds' is present after the first filtering.
  // If not, then stuff the previously excluded question into the layout (only if it fulfills the requirements)
  excludedQIds.forEach((qId) => {
    const criteria = questionSpecs[qId]?.inspectionProperties?.inclusionCriteria;
    const { mutuallyExclusiveQuestionIds } = criteria ?? {};
    if (
      !(filteredLayout.filter((l) => typeof l === 'string') as string[]).some((l) =>
        (mutuallyExclusiveQuestionIds ?? []).includes(l)
      ) &&
      questionFulfillsRequirements(criteria, inspection, context, questionSpecs[qId])
    ) {
      filteredLayout.unshift(qId);
    }
  });

  // HACK: don't display boxes questions if it's a transport inspection
  filteredLayout = filteredLayout.filter((l) => {
    if (typeof l === 'string') {
      return (
        ![
          AG_BOXES_SHIPPED_MISMATCH,
          AG_BOXES_SHIPPED_QUESTION_ID,
          AG_VOLUME_AT_INSPECTION,
          AG_BOXES_AT_INSPECTION_QUESTION_ID,
          AG_BOXES_RECEIVED,
        ].includes(l) || inspection?.reference?.type !== 'transport'
      );
    }
    return true;
  });

  return filteredLayout;
}

const questionInclusionFilter = (
  question: LayoutInner1,
  excludedQuestionIds: string[],
  questionSpecs: { [key: string]: QuestionSpec },
  context: InspectionContext,
  inspection: Inspection
) => {
  if (typeof question === 'string') {
    const criteria = questionSpecs[question]?.inspectionProperties?.inclusionCriteria;
    if ((criteria?.mutuallyExclusiveQuestionIds ?? []).length > 0) {
      excludedQuestionIds.push(question);
      return false;
    }
    return questionFulfillsRequirements(
      criteria,
      inspection,
      context,
      questionSpecs[question]
    );
  }
  return true;
};

function questionFulfillsRequirements(
  criteria: Criteria,
  inspection: Inspection,
  context: InspectionContext,
  questionSpecs: QuestionSpec
) {
  return (
    (inspection.objectType !== LotSchemaObjectTypeEnum.Lot ||
      fulfillsCriteria(criteria, inspection, questionSpecs?.questionId)) &&
    areExternalDependenciesPresent(context, questionSpecs)
  );
}

export function getValueAndUnitFromLocator<T extends Inspection>(
  inputLocator: UserInputLocator,
  inspection: T
): { value?: any; unit?: MeasurementUnit } {
  if (!inputLocator || !inspection) {
    return {};
  }

  const { questionId, measurableId, auxMeasurementIndex, isScore } = inputLocator;

  if (isScore) {
    return { value: inspection.scores?.[questionId]?.score };
  } else {
    const measurable = inspection.userInputs?.[questionId]?.measurableInputs?.find(
      (m) => m.measurableId === measurableId
    );
    if (!measurable) {
      return {};
    }

    if (auxMeasurementIndex != null) {
      const { value, unit } =
        measurable.auxMeasurementsInput?.[auxMeasurementIndex] ?? {};
      return { value, unit };
    } else {
      const { value, unit } = measurable.measurementInput ?? {};
      return { value, unit };
    }
  }
}

export function compileExternalLotInspectionProperties(
  boxes_expected?: number,
  lot?: Lot
) {
  const externalProperties: InspectionPropertiesMap = {
    boxes_expected,
    current_num_boxes: lot?.transient?.numBoxes,
    batch_transient_volume: lot?.transient?.volumeInKg,
    consumer_unit_num_pieces: lot?.article?.consumerUnitNumberOfPieces,
    consumer_unit_weight: lot?.article?.consumerUnitWeightInGrams,
    box_net_weight: lot?.article?.boxNetWeightInKg,
    punnets_per_box: lot?.article?.numConsumerUnitsInBox,
  };
  return externalProperties;
}

const isVerifiedSourceInTheOldFormat = (source: string) => (source ?? '').includes('{');

export function getVerifiedSourceValue(context: InspectionContext, source: string) {
  if (!source || !context) {
    return undefined;
  }

  let prefix: ContextReferencePrefix;
  let property: InspectionRelevantProperties;

  // HACK: previously, the 'source' field had a formula like syntax (e.g. {extern|boxes_expected}), and now it's of type InspectionRelevantProperties.
  // We implemented this check so we can handle it both in the old and new format.
  if (isVerifiedSourceInTheOldFormat(source)) {
    source = source.replace('{', '').replace('}', '');
    [prefix, property] = source.split(REF_ID_PREFIX_SEPARATOR) as [
      ContextReferencePrefix,
      InspectionRelevantProperties
    ];
  } else {
    property = source as InspectionRelevantProperties;
  }

  return context.properties[property];
}

export function areExternalDependenciesPresent(
  context: InspectionContext,
  specs: QuestionSpec,
  checkDefaultToManualFlag: boolean = true
): boolean {
  if (!context) {
    return false;
  }
  const getExternalRefIds = (str: string) =>
    extractRefIdsFromFormulas(str).filter((f) => f.includes(EXTERNAL_PREFIX));
  const areAllExternalDependenciesPresent = (ids: string[]) =>
    ids.reduce((areAllPropsSetSoFar: boolean, refId: string) => {
      const contextPropKey = refId.replace(EXTERNAL_PREFIX, '');
      const currExtPropIsSetInContext = context.properties[contextPropKey] != null;
      return areAllPropsSetSoFar && currExtPropIsSetInContext;
    }, true);

  const presentMeasurables = (specs?.measurables ?? []).filter((m) => {
    let externalRefIds: string[];
    if (m.type === 'computed') {
      externalRefIds = getExternalRefIds(m.formula);
    } else if (m.type === 'verified') {
      externalRefIds =
        !!m.defaultToManual && checkDefaultToManualFlag
          ? []
          : isVerifiedSourceInTheOldFormat(m.source) // HACK: same as in getVerifiedSourceValue
          ? getExternalRefIds(m.source)
          : [m.source];
    }
    return !!externalRefIds ? areAllExternalDependenciesPresent(externalRefIds) : true;
  });

  return presentMeasurables.length > 0;
}

//****** */
// PRIVATE HELPERS
//****** */

/********* */
// Context
/********* */
// exported for testing
export function buildContextRefIdFromInputLocator(
  inputLocator: UserInputLocator
): string {
  const { auxMeasurementIndex, questionId, measurableId, isQuestionScore } =
    inputLocator;

  const prefix: ContextReferencePrefix = !!isQuestionScore
    ? QSCORE_PREFIX
    : auxMeasurementIndex != null
    ? AUX_PREFIX
    : !!inputLocator.isScore
    ? SCORE_PREFIX
    : MEASUREMENT_PREFIX;

  return `${prefix}${REF_ID_PREFIX_SEPARATOR}${measurableId ?? questionId}${
    auxMeasurementIndex != null
      ? `${AUX_MEASUREMENT_SEPARATOR}${auxMeasurementIndex}`
      : ''
  }`;
}

function extractRefIdsFromFormulas(formula: string): string[] {
  if (!formula) return [];
  const regex = /{([^}]+)}/g;
  const matches = formula.match(regex).map((match) => match.slice(1, -1));
  return matches;
}

function updateInspectionContext(
  value: number,
  inputLocator: UserInputLocator,
  context: InspectionContext,
  inspection: Inspection,
  spec: InspectionSpec,
  additionalChanges: InspectionDelta[],
  scoring: LotScoringSection,
  orgSettings: OrganisationSettings,
  depth: number = 0
) {
  // keep a copy of the old context
  const oldContext = cloneDeep(context);
  // update value in the context
  updateValueInContext(value, inputLocator, context);

  // update all formulas by resolving the variables with their actual values
  updateFormulasInContext(context);

  // update the user inputs/scores in the inspection. We only need to do this for proper
  // measurables, but not for qscores.
  if (!!!inputLocator.isQuestionScore && !!!inputLocator.isScore) {
    handleInspectionChanges(
      [
        {
          inputLocator,
          value,
        },
      ],
      inspection,
      spec,
      orgSettings,
      context,
      scoring
    );
    const updatedQuestions: InspectionDelta[] = computeQuestionLevelScores(
      inspection,
      spec
    );

    // Call updates for the qscores.
    for (const updatedQuestion of updatedQuestions) {
      updateInspectionContext(
        updatedQuestion.value,
        updatedQuestion.inputLocator,
        context,
        inspection,
        spec,
        updatedQuestions,
        scoring,
        orgSettings,
        depth + 1
      );
    }
  }
  // Check for the following:
  // - Formulas with all variables present. If so, proceed with the computation
  // - Formulas that were resolved but cannot be resolved anymore (i.e: the user deleted an input that was necessary to calculate said formula)
  const refsToBeUpdated: { action: 'compute' | 'remove'; refId: string }[] =
    getFormulaRefIdsToBeUpdated(additionalChanges, context, oldContext);

  refsToBeUpdated.forEach(({ refId, action }) => {
    // compute formula or delete value
    let currValue =
      action === 'compute' && isFormulaResolvable(context.resolvedFormulas[refId])
        ? computeFormula(refId, context)
        : undefined;

    const currInputLocator: UserInputLocator = getInputLocatorFromRefId(refId);

    // Proceed only if the value is different from the one currently in the inspection
    const currInspectionValue = getValueAndUnitFromLocator(
      currInputLocator,
      inspection
    ).value;
    if (currInspectionValue === currValue) {
      return;
    }

    // Prepare current change
    const currChange: InspectionDelta = {
      inputLocator: currInputLocator,
      value: currValue,
    };

    // Accumulate changes
    additionalChanges.push(currChange);

    // recursively update the context
    if (isInputContextRelevant(currInputLocator, context)) {
      updateInspectionContext(
        currValue,
        currInputLocator,
        context,
        inspection,
        spec,
        additionalChanges,
        scoring,
        orgSettings,
        depth + 1
      );
    }
  });
}

// Exported for testing.
export function computeFormula(
  refId: string,
  context: InspectionContext
): number | undefined {
  let res: number;
  try {
    res = math.evaluate(context.resolvedFormulas[refId]);
  } catch (error) {
    console.error('Could not evaluate:', refId, context.resolvedFormulas[refId]);
  }
  const isValid = res !== Infinity && !isNaN(res);
  return isValid ? res : undefined;
}

const AG_FORMULA_MATCHER_REGEXP = new RegExp(
  `${AG_FORMULA_WRAPPER_START}(.*?)${AG_FORMULA_WRAPPER_END}`,
  'g'
);

function extractAgFormulas(formula: string): string[] {
  const matches = [...formula.matchAll(AG_FORMULA_MATCHER_REGEXP)];
  return matches.map((match) => match[1]);
}

function resolveAgFormulas(formula: string): string {
  const agFormulas: string[] = extractAgFormulas(formula);

  for (const agFormula of agFormulas) {
    // skip if measurement values are not resolved
    if (agFormula.includes('{')) {
      continue;
    }
    const result: number[] | undefined = computeAgFormula(agFormula);
    if (result != null) {
      formula = replaceAll(
        formula,
        `${AG_FORMULA_WRAPPER_START}${agFormula}${AG_FORMULA_WRAPPER_END}`,
        `${result}`
      );
    }
  }
  return formula;
}

function computeAgFormula(formula: string): (number | undefined)[] {
  // Expected syntax: range_map({meas|blah},[[0,50,2],[50,100,3],[100,null,4]])
  const rangeMapRegexp = new RegExp(/range_map\(([^[]+),\[(.*)\]\)/);
  const match = formula.match(rangeMapRegexp);

  if (match) {
    const measurementValues = match[1].split(',');
    // Rules can be read as [min value of slice (or null for -Infinity), max value of slice (or null for +Infinity), result if value is in the range]
    let rules: [number | null, number | null, number][];

    try {
      rules = JSON.parse(`[${match[2]}]`);
      // make sure rules have the right signature (praised be god for chatgpt)
      if (
        !Array.isArray(rules) ||
        !rules.every(
          (subArr) =>
            Array.isArray(subArr) &&
            subArr.length === 3 &&
            subArr.every((element) => typeof element === 'number' || element === null)
        )
      ) {
        throw new Error(
          `Rules do not have the expected signature [number | null, number | null, number][]`
        );
      }
    } catch (error) {
      console.error(`Could not parse rules from formula: ${formula}`, error);
      return undefined;
    }

    return measurementValues.flatMap((measValueString: string) => {
      const measValue = parseFloat(measValueString);
      try {
        if (isNaN(measValue)) {
          throw new Error(`Value parsed is not numeric`);
        }
      } catch (error) {
        console.error(`Could not parse value from formula: ${formula}`, error);
        return [];
      }
      for (let [min, max, result] of rules) {
        if (min === null) {
          min = -Infinity;
        }
        if (max === null) {
          max = Infinity;
        }
        if (min < measValue && measValue <= max) {
          return [result];
        }
      }
      return [];
    });
  }

  return undefined;
}

// Exported for testing.
export const isFormulaResolvable = (formula: string): boolean => {
  formula = (formula ?? '').length === 0 ? '{' : formula;
  return !formula.includes('{') && !formula.includes(AG_FORMULA_WRAPPER_START);
};

function getFormulaRefIdsToBeUpdated(
  additionalChanges: InspectionDelta[],
  context: InspectionContext,
  oldContext: InspectionContext
): { action: 'compute' | 'remove'; refId: string }[] {
  const refsToBeUpdated: { action: 'compute' | 'remove'; refId: string }[] = [];

  Object.entries(context.resolvedFormulas).forEach(([refId, formula]) => {
    if (isChangeInQueue(refId, additionalChanges)) {
      return;
    }

    const formulaIsResolvable: boolean = isFormulaResolvable(formula);
    const formulaIsNotResolvableAnymore: boolean =
      isFormulaResolvable(oldContext.resolvedFormulas[refId]) && !formulaIsResolvable;

    if (formulaIsResolvable) {
      refsToBeUpdated.push({ refId, action: 'compute' });
    }
    if (formulaIsNotResolvableAnymore) {
      refsToBeUpdated.push({ refId, action: 'remove' });
    }
  });

  return refsToBeUpdated;
}

function updateValueInContext(
  value: number,
  inputLocator: UserInputLocator,
  context: InspectionContext
) {
  const refId = buildContextRefIdFromInputLocator(inputLocator);
  context.inputs[refId] = value;
}

function isInputContextRelevant(
  inputLocator: UserInputLocator,
  context: InspectionContext
): boolean {
  /*
  An input is context relevant if:
    - its ref id is a key in the context inputs, or
    - it's a variable in a formula, or
    - it's a variable for a question tag formula
  */
  const refId = buildContextRefIdFromInputLocator(inputLocator);

  if (!!context.inputs[refId]) return true;
  for (const formula of Object.values(context.formulas)) {
    if (formula.includes(refId)) return true;
  }

  // Check that the input is a variable for a question tag formula:
  for (const candidates of Object.values(context.partialSummaryResolutionCandidates)) {
    for (const measurement of Object.values(candidates)) {
      if (measurement.includes(refId)) return true;
    }
  }

  return false;
}

function resolveTagsInContext(context: InspectionContext) {
  context.resolvedTags = {};
  Object.entries(context.partialSummaryResolutionCandidates).forEach(
    ([partialSummaryId, tagMap]: [string, { [tagId: string]: MeasurableId[] }]) => {
      context.resolvedTags[partialSummaryId] = {};
      Object.entries(tagMap).forEach(
        ([tagId, measurableIds]: [string, MeasurableId[]]) => {
          context.resolvedTags[partialSummaryId][tagId] = [];
          const measurements = [];
          measurableIds.forEach((measurableId: MeasurableId) => {
            if (measurableId in context.inputs) {
              const value: MeasurementValueType | MeasurementValueType[] | undefined =
                context.inputs[measurableId];
              if (value !== null && value !== undefined && Array.isArray(value)) {
                measurements.push(...value);
              } else if (value !== null && value !== undefined) {
                measurements.push(value);
              } else {
                // TODO(dienes): Should we push 0 here for to make sure that average
                // makes sense?
                measurements.push(0);
              }
            }
          });
          if (measurements.length > 0) {
            context.resolvedTags[partialSummaryId][tagId] = measurements;
          } else {
            // This ensures partial summary aggregations are calculated even if
            // there are no user inputs for a particular tag
            context.resolvedTags[partialSummaryId][tagId] = [0];
          }
        }
      );
    }
  );
}

function updateFormulasInContext(context: InspectionContext) {
  // Loop over the formulas and replace the references with the actual values, if
  // present.
  const resolvedFormulas: { [measurebleId: string]: string } = cloneDeep(
    context.formulas
  );
  resolveTagsInContext(context);

  Object.keys(resolvedFormulas).forEach((formulaRefId) => {
    const possibleValues = {
      inputs: context.inputs,
      properties: context.properties,
      resolvedTags: context.resolvedTags[formulaRefId] ?? {},
    };
    for (const contextField of ['inputs', 'properties', 'resolvedTags']) {
      Object.entries(possibleValues[contextField]).forEach(([refId, value]) => {
        if (value != null) {
          let f: string = resolvedFormulas[formulaRefId];

          f = replaceAll(
            f,
            `{${contextField === 'properties' ? `${EXTERNAL_PREFIX}${refId}` : refId}}`,
            `${value}`
          );

          // Resolve Ag Formulas
          if (extractAgFormulas(f).length > 0) {
            f = resolveAgFormulas(f);
          }

          // If there's an "or" operation present in the formula, check if any of the
          // sub-formulas in the expression can be resolved. If so, use it
          if (f.includes(OR_FORMULA_SEPARATOR)) {
            const candidates = f.split(OR_FORMULA_SEPARATOR);
            for (const candidate of candidates) {
              if (isFormulaResolvable(candidate)) {
                f = candidate;
                break;
              }
            }
          }

          resolvedFormulas[formulaRefId] = f;
        }
      });
    }
  });

  context.resolvedFormulas = resolvedFormulas;
}

function getInputLocatorFromRefId(refId: string): UserInputLocator {
  const [prefix, rest] = refId.split(REF_ID_PREFIX_SEPARATOR);
  const [measurableId, auxMeasurementIndex] = rest.split(AUX_MEASUREMENT_SEPARATOR);
  const [questionId] = measurableId.split(MEASURABLE_ID_SEPARATOR);
  return {
    questionId,
    measurableId,
    auxMeasurementIndex: auxMeasurementIndex != null ? +auxMeasurementIndex : undefined,
    isScore: prefix === SCORE_PREFIX ? true : undefined,
  } as UserInputLocator;
}

function getMeasurement(
  spec: InspectionSpec,
  refId?: string,
  inputLocator?: UserInputLocator
) {
  // this functions get fed with either a refId or an inputLocator and returns the corresponding measurement (whether "normal" or auxiliary) from the specs
  if (!refId && !inputLocator) {
    return undefined;
  }
  if (!!refId) {
    inputLocator = getInputLocatorFromRefId(refId);
  }
  const { questionId, measurableId, auxMeasurementIndex } = inputLocator;
  const measurable = getMeasurable(spec, questionId, measurableId);
  try {
    const measurement =
      auxMeasurementIndex != null
        ? (measurable as ComputedMeasurable).auxMeasurements[auxMeasurementIndex]
        : measurable.measurement;
    return measurement;
  } catch (error) {
    console.error(error, inputLocator, measurable);
    return {} as any;
  }
}

function getMeasurable(spec: InspectionSpec, questionId: string, measurableId: string) {
  return spec?.questionSpecs[questionId]?.measurables?.find(
    (m) => m.measurableId === measurableId
  );
}

function isMandatoryQuestion(spec: InspectionSpec, questionId: string) {
  return !!spec?.questionSpecs[questionId]?.inspectionProperties?.isMandatory;
}

export function fulfillsCriteria(
  inclusionCriteria: Criteria,
  inspection: Inspection,
  questionId?: string
) {
  if (!inclusionCriteria) {
    return true;
  }

  if (!inspection?.reference) {
    return false;
  }

  switch (inspection.objectType) {
    case FieldSchemaObjectTypeEnum.Field:
      return fulfillsFieldCriteria(inspection, inclusionCriteria as FieldCriteria);
    case LotSchemaObjectTypeEnum.Lot:
      return fulfillsLotCriteria(inspection, inclusionCriteria as LotCriteria);
    case TransitSchemaObjectTypeEnum.Transit:
      return true;
  }
}

function fulfillsFieldCriteria(
  inspection: ProductionSiteInspection,
  criteria: FieldCriteria
) {
  if ((criteria?.agProductIds ?? []).length === 0) {
    return true;
  }

  return criteria.agProductIds.reduce(
    (acc, curr) =>
      acc ||
      inspection.productionSiteSnapshot?.producedVarieties
        ?.map((pv) => pv.agProductId)
        .includes(curr),
    false
  );
}

function fulfillsLotCriteria(inspection: LotInspection, criteria: LotCriteria) {
  if (!criteria?.articleProperties) {
    return true;
  }

  const { packagingTypes, inspectionTypes, isBio, agProductIds, origins, brands } =
    criteria?.articleProperties;

  const article = inspection.lotProperties?.article;
  // if (!article) {
  //   console.log('no article')
  //   return true
  // }
  const inspectionType = inspection.reference.type;

  // TODO: implement:
  // - fulfillsAgProductIds <-- can we somehow unify productIds and agProductIds?
  // - fulfillsPackaging
  let fulfillsPacked: boolean = true;
  let fulfillsBio: boolean = true;
  let fulfillsProductIds: boolean = true;
  let fulfillsOrigins: boolean = true;
  let fulfillsBrands: boolean = true;
  let fulfillsInspectionTypes: boolean = true;

  // check packaging conditions
  if ((packagingTypes ?? []).length > 0 && !!article?.packagingType) {
    fulfillsPacked = packagingTypes.includes(
      (article.packagingType ?? '').toLowerCase() as PackagingType
    );
  }

  // check brands conditions
  if ((brands ?? []).length > 0 && !!article?.brand) {
    fulfillsBrands = brands.includes(article.brand);
  }

  // check inspection types
  if ((inspectionTypes ?? []).length > 0 && !!inspectionType) {
    fulfillsInspectionTypes = inspectionTypes.includes(inspectionType);
  }

  // check BIO conditions
  if (isBio !== undefined) {
    if (
      (article?.isBio === undefined && isBio === true) ||
      (article?.isBio === true && isBio === false) ||
      (article?.isBio === false && isBio === true)
    ) {
      fulfillsBio = false;
    }
  }

  // check product conditions
  const requiredProductIds = agProductIds;
  if (requiredProductIds && requiredProductIds.length > 0) {
    fulfillsProductIds = requiredProductIds.reduce(
      (a, b) => a || article?.productId === b || article?.agProductId === b,
      false
    );
  }

  // check origin conditions
  const requiredOrigins = origins;
  if (requiredOrigins && requiredOrigins.length > 0) {
    fulfillsOrigins = requiredOrigins.reduce(
      (a, b) => a || article?.origin === b,
      false
    );
  }

  return (
    fulfillsPacked &&
    fulfillsProductIds &&
    fulfillsBio &&
    fulfillsOrigins &&
    fulfillsInspectionTypes &&
    fulfillsBrands
  );
}

function criteriaFromArticleAndReference(
  article: IArticle,
  reference: LegacyInspectionReference
): LotCriteria {
  const criteria: LotCriteria = { articleProperties: {} };
  if (article.agProductId !== undefined) {
    criteria.articleProperties.agProductIds = [article.agProductId];
  }
  // TODO
  // if (article.productId !== undefined) { criteria.articleProperties.productIds = [article.productId] }
  //TODO: implement after model is updated
  if (article.packagingType !== undefined) {
    criteria.articleProperties.packagingTypes = [article.packagingType];
  }
  if (reference?.type !== undefined) {
    criteria.articleProperties.inspectionTypes = [reference.type];
  }
  if (article.brand !== undefined) {
    criteria.articleProperties.brands = [article.brand];
  }
  if (article.origin !== undefined) {
    criteria.articleProperties.origins = [article.origin];
  }
  return criteria;
}

/********* */
// User Inputs
/********* */

export function extractMeasurementFields(
  inputLocator: UserInputLocator,
  spec?: InspectionSpec,
  questionSpec?: QuestionSpec
) {
  if (!spec && !questionSpec) {
    return {};
  }
  if (!spec) {
    spec = {
      questionSpecs: { [inputLocator.questionId]: questionSpec },
    } as InspectionSpec;
  }

  const measurement = getMeasurement(spec, undefined, inputLocator);
  let { valueType, type, displayedName } = measurement;
  let unit: MeasurementUnit, maxValue: number, minValue: number;

  if (
    measurement.type === 'int' ||
    measurement.type === 'float' ||
    measurement.type === 'float_list' ||
    measurement.type === 'int_list'
  ) {
    ({ maxValue, minValue } = measurement);
  }

  if (measurement.type !== 'boolean' && measurement.type !== 'text') {
    ({ unit } = measurement);
  }

  return { unit, maxValue, minValue, valueType, type, displayedName };
}

export function isMeasurableOfType(
  type: 'computed' | 'default' | 'verified',
  inputLocator: UserInputLocator,
  spec: InspectionSpec
): boolean {
  if (!inputLocator || !spec) {
    return false;
  }
  const { questionId, measurableId } = inputLocator;
  const measurable = spec.questionSpecs[questionId]?.measurables?.find(
    (m) => m.measurableId === measurableId
  );
  return !!(measurable?.type === type);
}

function handleInspectionChanges(
  changes: InspectionDelta[],
  inspection: Inspection,
  spec: InspectionSpec,
  orgSettings: OrganisationSettings,
  context: InspectionContext,
  scoring: LotScoringSection
) {
  changes.forEach((change) => {
    let { value, inputLocator } = change;
    const { questionId, auxMeasurementIndex, isScore, measurableId } = inputLocator;

    let { unit, valueType, displayedName } = extractMeasurementFields(
      inputLocator,
      spec
    );

    // scores go directly to the scores field in the inspection
    if (isScore) {
      const scoringType = scoring?.lotScoring?.[questionId]?.type; // TODO: implement lotScoring model in python
      const manuallyOverriden = scoringType !== 'MANUAL';
      const name = spec.questionSpecs[questionId].displayedName;
      const score: InspectionScore = {
        id: questionId,
        name,
        score: value,
        agScore: companyToAGScore(value, questionId, orgSettings),
        manuallyOverriden,
      };
      inspection.scores[questionId] = score;
    }
    // other types of changes go to the inputs dictionary
    else {
      let measurementInput: MeasurementInput = {
        valueType,
        value,
        unit,
        displayedName,
      };

      // what kind of measurement is it?
      const isAuxMeasurement = auxMeasurementIndex != null;

      // Check if question input already exists in the inspection and proceed accordingly
      let qInput: QuestionInput = inspection.userInputs[questionId];
      if (!!qInput) {
        // find the index of the measurable in the question input
        let measurableIdx = qInput.measurableInputs.findIndex(
          (i) => i.measurableId === measurableId
        );

        // create the measurable input if it doesn't exist already in the array
        if (measurableIdx < 0) {
          measurableIdx = qInput.measurableInputs.length;
          const measurableInput: MeasurableInput = { measurableId, isPrimary: false };
          if (isMeasurableOfType('verified', inputLocator, spec)) {
            measurableInput.verifiableSource = getVerifiedSourceValue(
              context,
              (getMeasurable(spec, questionId, measurableId) as VerifiedMeasurable)
                ?.source
            );
          }
          qInput.measurableInputs.push(measurableInput);
        }

        // set the value accordingly, depending on whether it's a "normal" or auxiliar measurement
        if (isAuxMeasurement) {
          if (!qInput.measurableInputs[measurableIdx].auxMeasurementsInput) {
            qInput.measurableInputs[measurableIdx].auxMeasurementsInput = Array(
              auxMeasurementIndex + 1
            ).fill(undefined);
          }
          qInput.measurableInputs[measurableIdx].auxMeasurementsInput[
            auxMeasurementIndex
          ] = measurementInput;
        } else {
          qInput.measurableInputs[measurableIdx].measurementInput = measurementInput;
        }
      }
      // if question input doesn't exist yet, create it
      else {
        const measurableInput: MeasurableInput = { measurableId, isPrimary: false };

        if (isMeasurableOfType('verified', inputLocator, spec)) {
          measurableInput.verifiableSource = getVerifiedSourceValue(
            context,
            (getMeasurable(spec, questionId, measurableId) as VerifiedMeasurable)
              ?.source
          );
        }

        if (isAuxMeasurement) {
          measurableInput.auxMeasurementsInput = Array(auxMeasurementIndex + 1).fill(
            undefined
          );
          measurableInput.auxMeasurementsInput[auxMeasurementIndex] = measurementInput;
        } else {
          measurableInput.measurementInput = measurementInput;
        }
        qInput = {
          measurableInputs: [measurableInput],
        };
      }

      // Reorder measurableInputs according to the order of the measurables in the questionSpecs
      // to ensure the are displayed in the right order in the reports (to avoid issues like e.g. DEV-1570)

      // Also define which input corresponds to the primary measurable, in order to be able to identify the value that has to be
      // sent to the insights db. We do it at this point just to make sure we always capture the latest state of the specs
      // (for cases like: the user is reopening an inspection and the primary measurable has been updated in the schema).
      const specMeasurables: MeasurablesInner[] =
        spec.questionSpecs[questionId].measurables;
      const sortedMeasIds: string[] = specMeasurables.map((m) => m.measurableId);
      const primaryMeasurableId: string = specMeasurables.find(
        (m) => m.isPrimary
      )?.measurableId;
      qInput.measurableInputs = qInput.measurableInputs
        .sort(
          (a, b) =>
            sortedMeasIds.indexOf(a.measurableId) -
            sortedMeasIds.indexOf(b.measurableId)
        )
        .map((m: MeasurableInput) => ({
          ...m,
          isPrimary: m.measurableId === primaryMeasurableId,
        }));

      inspection.userInputs[questionId] = qInput;
    }
  });

  // check for every questionId in the userInputs that there are actual user inputs present, and remove it from the dictionary if it's not the case
  Object.entries(inspection.userInputs).forEach(([questionId, input]) => {
    const measurablesWithUserInputs = (input.measurableInputs ?? []).filter((m) => {
      const measurementHasInput = (m.measurementInput ?? {}).value != null;
      const auxMeasurementsWithInputs = (m.auxMeasurementsInput ?? []).filter(
        (a) => a?.value != null
      );
      return measurementHasInput || auxMeasurementsWithInputs.length > 0;
    });
    if (measurablesWithUserInputs.length === 0) {
      delete inspection.userInputs[questionId];
    }
  });

  // Do the same for the scores
  Object.entries(inspection.scores ?? {}).forEach(([scoreId, score]) => {
    if (score.agScore == null && score.score == null) {
      delete inspection.scores[scoreId];
    }
  });
}

function isChangeInQueue(refId: string, changes: InspectionDelta[]): boolean {
  // Partial summaries, unlike other formulas, might have multiple dependencies
  // (mulitple inputs, or depending on a measurement that depends on an aux
  // measurement), so we pretend the they are not in the queue to force recalculation of
  // them.
  if (refId.includes('partial_summary')) return false;
  return changes
    .map((c) => buildContextRefIdFromInputLocator(c.inputLocator))
    .includes(refId);
}

/********* */
// Question level scores
/********* */
// Recomputes the question level scores and returns the update scrores in a list.
function computeQuestionLevelScores(
  inspection: Inspection,
  inspectionSpec: InspectionSpec
): InspectionDelta[] {
  const updatedScores: InspectionDelta[] = [];
  // TODO: add explanatory comments
  Object.entries(inspection.userInputs).forEach(([questionId, input]) => {
    const spec = inspectionSpec.questionSpecs[questionId];
    // if (!spec) console.log("EXPLODES", questionId, spec)
    const scoringPolicy = spec?.scoringPolicy;

    if (!scoringPolicy) {
      return;
    }

    const { measurableInputs } = input;
    const { measurableIds, rules } = scoringPolicy;

    let scoreFound: boolean = false;

    for (const [ruleIndex, rule] of rules.entries()) {
      const { score, slice } = rule;

      let applies: boolean = true;

      for (const s of slice) {
        const { type, measurableId } = s;

        if (!measurableIds.includes(measurableId)) {
          continue;
        }

        const measurementValue = measurableInputs.find(
          (i) => i.measurableId === measurableId
        )?.measurementInput?.value;
        if (measurementValue == null) {
          applies = false;
          break;
        }

        switch (type) {
          case 'boolean':
            const { booleanVal } = s;
            applies = applies && measurementValue === booleanVal;
            break;
          case 'categorical':
            const { category } = s;
            applies = applies && measurementValue === category;
            break;
          case 'float':
          case 'int':
            let { min, max } = s;

            // if min/max are undefined, they should be interpreted as -/+ infinity, respectively
            if (min == null) min = -Infinity;
            if (max == null) max = +Infinity;

            if (ruleIndex === 0) {
              // for the first interval, we are also including the min value in the check
              applies = applies && measurementValue >= min && measurementValue <= max;
            } else {
              applies = applies && measurementValue > min && measurementValue <= max;
            }
            break;
        }
      }

      if (applies) {
        if (inspection.userInputs[questionId].agScore !== score) {
          updatedScores.push({
            inputLocator: {
              questionId,
              isQuestionScore: true,
            },
            value: score,
          });
        }
        inspection.userInputs[questionId].agScore = score as AGScore;
        scoreFound = true;
        break;
      }
    }

    if (!scoreFound) {
      inspection.userInputs[questionId].agScore = undefined;
    }
  });
  return updatedScores;
}

/********* */
// Inspection level Scores
/********* */
export const getScoreIndex = (
  scoreId: string,
  orgSettings: OrganisationSettings
): number => {
  if (!orgSettings?.inspectionScoresOrder) {
    return -1;
  }
  return (
    +Object.entries(orgSettings.inspectionScoresOrder).find(
      ([_, val]) => val.id === scoreId
    )?.[0] ?? -1
  );
};

export const sortScoresEntries = (
  a: [string, InspectionScore],
  b: [string, InspectionScore],
  orgSettings: OrganisationSettings
) => {
  if (!!orgSettings?.inspectionScoresOrder) {
    return getScoreIndex(a?.[0], orgSettings) > getScoreIndex(b?.[0], orgSettings)
      ? 1
      : -1;
  }
  return a?.[1].name > b?.[1].name ? 1 : -1;
};

export function isAutoScoresAThing(spec: InspectionSpec): boolean {
  return !!(spec as LotInspectionSpec).lotScoring;
}

export function findScoringForSpec(
  spec: InspectionSpec,
  scorings: LotScoringSection[] = []
): LotScoringSection | undefined {
  return scorings.find((s) => s.id === (spec as LotInspectionSpec)?.lotScoring);
}

function doChangesContributeToScore(
  changes: InspectionDelta[],
  spec: InspectionSpec,
  scoringGroupId: string
): boolean {
  const changeQuestionIds = changes.map((c) => c.inputLocator.questionId);

  for (const qId of changeQuestionIds) {
    if (!!spec.questionSpecs[qId].inspectionScoring?.[scoringGroupId]) {
      return true;
    }
  }
  return false;
}

export function computeInspectionLevelScores(
  changes: InspectionDelta[],
  inspection: Inspection,
  spec: InspectionSpec,
  organisationSettings: OrganisationSettings,
  scoring: LotScoringSection,
  checkChanges: boolean = true
) {
  /**
   * Compute the group level scores. The logic depends on the type of scorer defined in the schema
   */

  // Loop over the scores within the lotScoring
  // For each score,
  // 1. extract the parameters
  // 2. Construct a scorer according to the type of score
  // 3. Pass the entire inspection to the scorer and call get score which computes
  // the score according to the internal logic which is score dependant

  for (const [scoringGroupId, lotScoring] of Object.entries(
    scoring?.lotScoring ?? {}
  )) {
    // continue with the automatic computation only if current changes contribute to the auto calculated score
    if (!doChangesContributeToScore(changes, spec, scoringGroupId) && checkChanges) {
      continue;
    }

    // if the score group id is present in the changes array, it means it's being manually overriden (we skip it, as it was already set via handleInspectionChanges)
    if (changes.map((c) => c.inputLocator.questionId).includes(scoringGroupId)) {
      console.log(
        `Score '${scoringGroupId}' manually overriden, skipping auto calculation`
      );
      continue;
    }

    let score: string;
    const { type, params, name } = lotScoring;

    if (type === 'ADLS') {
      const scorer = new ADLSBatchLevelScorer(
        spec,
        params as ADLSScoringParams,
        scoringGroupId
      );
      score = scorer.getGroupScore(inspection);
    } else if (type === 'ADP') {
      const scorer = new ADPBatchLevelScorer(
        spec,
        params as ADPScoringParams,
        scoringGroupId
      );
      score = scorer.getGroupScore(inspection);
    }

    if (score != null) {
      const inspectionScore: InspectionScore = {
        score,
        id: scoringGroupId,
        name,
        manuallyOverriden: false,
        agScore: companyToAGScore(score, scoringGroupId, organisationSettings),
      };
      inspection.scores[scoringGroupId] = inspectionScore;
    }
  }
}
