import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  closeOutline,
  mailOutline,
  openOutline,
  peopleCircleOutline,
  personCircleOutline,
  timeOutline,
} from 'ionicons/icons';
import React, { Fragment } from 'react';
import { SharedTo } from '../Model';
import '../PageSharedHistory.scss';
import { formatDateTime } from '../HelperUtils';

interface SHProps {
  onCancel: () => void;
  name: string;
  sharedTo: SharedTo[];
  buildLink: (sharedReportId: string) => string;
}
export const CSharedHistory = ({ onCancel, name, sharedTo, buildLink }: SHProps) => {
  const title = 'Shared History';

  function getTime(date) {
    return date?.seconds * 1000 ?? 0;
  }

  return (
    <div className="shared-history ion-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <IonIcon icon={closeOutline} color="dark"></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <b>{name}</b> &middot; {title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {sharedTo
            ?.sort((a, b) => getTime(b.date) - getTime(a.date))
            ?.map((s: SharedTo, idx: number) => {
              return (
                <React.Fragment key={s.sharedReportId ?? idx}>
                  <div className="shared-item">
                    <div className="date">
                      <IonIcon icon={timeOutline}></IonIcon>
                      <b>{(formatDateTime(s.date) as string).replace(',', '')}</b>
                      {!!s.sharedReportId && (
                        <a
                          rel="noopener noreferrer"
                          href={buildLink(s.sharedReportId)}
                          target="_blank"
                          className="link-to-report"
                        >
                          <IonIcon icon={openOutline} />
                          OPEN
                        </a>
                      )}
                    </div>
                    <div>
                      <IonIcon icon={personCircleOutline}></IonIcon>
                      <b>Shared by:</b> {s.sharedBy.name}
                    </div>
                    {s.message?.length > 0 && (
                      <>
                        <div className="align-top">
                          <IonIcon icon={mailOutline}></IonIcon>
                          <div>
                            <b>Message:</b>
                          </div>
                        </div>
                        <div className="message">
                          {s.message.split('\n').map((m, i) => (
                            <Fragment key={i}>
                              {m}
                              <br />
                            </Fragment>
                          ))}
                        </div>
                      </>
                    )}
                    {!!s.emails && (
                      <>
                        <div>
                          <IonIcon icon={peopleCircleOutline}></IonIcon>
                          <b>Shared to:</b>
                        </div>
                        {s.emails.map((e) => (
                          <IonItem key={e} className="shared-to">
                            &bull; {e.toLowerCase()}
                          </IonItem>
                        ))}
                      </>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
        </IonList>
      </IonContent>
    </div>
  );
};
