import { IonPage } from '@ionic/react';
import React, { useContext } from 'react';
import { ctxContacts, ctxLocations, ctxOrg } from '../../App';
import { firestore } from '../../ConfigFirebase';
import { useGetAllProductionSiteInspections } from '../../DataInspection';
import { ProductionSiteLocation } from '../../Model';
import { LocationDetailsHeader } from '../../components/location/LocationDetailsHeader/LocationDetailsHeader';
import { LocationDetailsContainer } from '../../containers/LocationDetailsContainer/LocationDetailsContainer';

interface Props {
  locationId: string;
}

export const PageLocationDetails = ({ locationId }: Props) => {
  const org = useContext(ctxOrg);  
  const locations = useContext(ctxLocations);
  const contacts = useContext(ctxContacts);

  const { inspections = [], isLoading } = useGetAllProductionSiteInspections(
    firestore,
    org.id,
    locationId
  );

  const field = locations.find(
    (l) => l.locationId === locationId
  ) as ProductionSiteLocation;

  if (!field) {
    return (
      <IonPage className="page-lot">
        <LocationDetailsHeader name={`Field id ${locationId} not found`} />
      </IonPage>
    );
  }

  const associatedPartnerName: string | undefined = contacts.find(
    (c) => c.id === field.associatedPartnerId
  )?.name;

  return (
    <LocationDetailsContainer
      inspections={inspections}
      field={field}
      isLoadingInspections={isLoading}
      associatedPartnerName={associatedPartnerName}
    />
  );
};
