import firebase from 'firebase/compat/app';
import { decodePermDictFromFirestore } from './DataScoring';
import { orgDocRef } from './DataStorage';
import { capitalizeFirstLetter } from './HelperUtils';
import { ADLSScoringParams, LotScoring, ScoringContext } from './Model';
import {
  InspectionSpec,
  LotScoringSection,
  ScoringToSection,
  Section,
} from './ModelSpecification';

type FS = firebase.firestore.Firestore;
type DocData = firebase.firestore.DocumentData;
type ColRef = firebase.firestore.CollectionReference<DocData>;

export function scoringContextToScoring(permDict: ScoringContext) {
  const scoringGroup = {};
  for (const combination of Object.keys(permDict)) {
    scoringGroup[combination] = permDict[combination].prediction;
  }
  return scoringGroup;
}

export const specColRef = (fs: FS, orgId: string) =>
  orgDocRef(fs, orgId).collection('specs');

export const inspectionSpecColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('postgres').collection('inspectionSpecs');

export const scoringColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('schemas').collection('scorings');

export const scoringContextColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('schemas').collection('scoringContexts');

// Note: delete after reworking the scoring
export const scoringToSectionColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('schemas').collection('scoring-section');

export const sectionColRef = (fs: FS, orgId: string) =>
  specColRef(fs, orgId).doc('schemas').collection('sections');
//////////////////////

export interface SpecificationRepository {
  // Merged Schemas
  getInspectionSpecs: () => Promise<InspectionSpec[]>;

  // Scoring
  getScorings: () => Promise<LotScoringSection[]>;
  getScoring: (scoringId: string) => Promise<LotScoringSection>;
  fetchScoringInterfaceData: () => Promise<
    [ScoringToSection[], LotScoringSection[], Section[]]
  >;

  getScoringContext: (
    scoringId: string
  ) => Promise<{ [groupId: string]: ScoringContext }>;
  updateADLSScoring: (scoringId: string) => Promise<void>;
}

export class FirebaseSpecificationRepository implements SpecificationRepository {
  firestore: FS;
  organisationId: string;

  constructor(firestore: FS, organisationId: string) {
    this.firestore = firestore;
    this.organisationId = organisationId;
  }

  private async getAllFirestoreDocs<T>(
    orgId: string,
    fsColRef: (fs: FS, orgId: string) => ColRef
  ): Promise<T[]> {
    const fsDocs = await fsColRef(this.firestore, orgId).get();
    return fsDocs.docs.map((doc) => doc.data() as T);
  }

  private async getFirestoreDoc<T>(
    docId: string,
    orgId: string,
    fsColRef: (fs: FS, orgId: string) => ColRef
  ): Promise<T> {
    const fsDoc = await fsColRef(this.firestore, orgId).doc(docId).get();
    return fsDoc.data() as T;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // NOTE: This legacy code is only being kept to make PageScoring.tsx work.
  public async getSections(sectionIds: string[] = undefined): Promise<Section[]> {
    return sectionIds === undefined
      ? await this.getAllFirestoreDocs<Section>(this.organisationId, sectionColRef)
      : await this.getFirestoreDocs<Section>(
          this.organisationId,
          sectionIds,
          sectionColRef
        );
  }

  private async getFirestoreDocs<T>(
    orgId: string,
    docIds: string[],
    fsColRef: (fs: FS, orgId: string) => ColRef
  ): Promise<T[]> {
    const docs: T[] = [];
    const doGetDocs = async (ids: string[]) => {
      const fsDocs = await fsColRef(this.firestore, orgId).where('id', 'in', ids).get();
      return fsDocs.docs.forEach((doc) => docs.push(doc.data() as T));
    };
    const queries: Promise<void>[] = [];
    for (let i = 0; i < docIds.length; i += 10) {
      queries.push(doGetDocs(docIds.slice(i, i + 10)));
    }
    await Promise.all(queries);
    return docs;
  }

  public getScoringToSections(): Promise<ScoringToSection[]> {
    return this.getAllFirestoreDocs(this.organisationId, scoringToSectionColRef);
  }
  // END LEGACY CODE
  ////////////////////////////////////////////////////////////////////////////////////

  /********************
   * INSPECTION SPECS *
   ********************/
  public async getInspectionSpecs(): Promise<InspectionSpec[]> {
    return await this.getAllFirestoreDocs(this.organisationId, inspectionSpecColRef);
  }

  /***********
   * SCORING *
   ***********/

  public getScorings(): Promise<LotScoringSection[]> {
    return this.getAllFirestoreDocs(this.organisationId, scoringColRef);
  }

  public getScoring(scoringId: string): Promise<LotScoringSection> {
    return this.getFirestoreDoc(scoringId, this.organisationId, scoringColRef);
  }

  public async fetchScoringInterfaceData(): Promise<
    [ScoringToSection[], LotScoringSection[], Section[]]
  > {
    const scoringToSections = await this.getScoringToSections();
    const scorings = await this.getScorings();
    const sections = await this.getSections(scoringToSections.map((s) => s.sectionId));
    return [scoringToSections, scorings, sections];
  }

  public async getScoringContext(
    scoringId: string
  ): Promise<{ [groupId: string]: ScoringContext }> {
    // Scoring contexts are saved stringified in the db, we have to parse them
    const stringifiedScoringContext = await this.getFirestoreDoc(
      scoringId,
      this.organisationId,
      scoringContextColRef
    );
    return Object.entries(stringifiedScoringContext).reduce(
      (parsedSC: { [groupId: string]: ScoringContext }, [groupId, strSC]) => ({
        ...parsedSC,
        [groupId]: decodePermDictFromFirestore(strSC),
      }),
      {}
    );
  }

  public async updateADLSScoring(scoringId: string) {
    const scoringContext = await this.getScoringContext(scoringId);
    const scoring = await this.getScoring(scoringId);
    Object.entries(scoringContext).forEach(([groupId, sc]) => {
      if (!scoring.lotScoring?.[groupId]) {
        scoring.lotScoring[groupId] = this.initADLSLotScoring(groupId);
      }
      (scoring.lotScoring[groupId].params as ADLSScoringParams).groupScoreMap =
        scoringContextToScoring(sc);
    });
    await scoringColRef(this.firestore, this.organisationId)
      .doc(scoringId)
      .set(scoring);
  }

  private initADLSLotScoring(groupId: string) {
    let name = capitalizeFirstLetter(groupId.replace('group_', ''));
    const lotScoring: LotScoring = {
      type: 'ADLS',
      name,
      params: {
        defaultScore: 4,
        groupScoreMap: {},
        rankedScoreSpace: [1, 2, 3, 4],
      } as ADLSScoringParams,
    };
    return lotScoring;
  }
}
