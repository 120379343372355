import { toastController } from '@ionic/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
  IonItemDivider,
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import {
  closeOutline,
  warningOutline,
  checkboxOutline,
  squareOutline,
} from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import Select from 'react-select';
import { ctxContacts, ctxLocations, ctxOrg, ctxProducts, ctxProfile } from '../App';
import { firestore } from '../ConfigFirebase';

import { setOrganizationLocation } from '../DataAdmin';
import { geoPointToDDM } from '../DataLocation';
import { presentStandardToast } from '../HelperIonic';
import {
  ProductVariety,
  Location,
  LocationType,
  LocationTypes,
  WarehouseService,
  ProductionType,
  ProductionTypes,
  IrrigationType,
  FieldManagementQuality,
  ProductionSiteLocation,
  GrowingType,
  SubstrateType,
  GrowingMethodType,
  FieldStorageCondition,
  FieldStorageConditions,
} from '../Model';
import CMapPicker from '../components/CMapPicker';
import { useLocationAdminOptions } from '../hooks/useLocationAdminOptionsOld';
import { defaultSelectProps } from '../HelperReactSelect';
import { ClearableDateTime } from '../components/ClearableDateTime';
import { clamp } from 'lodash';

interface AddLocationProps {
  onClose: () => void;
}

interface GPSModalData {
  geoPoint: firebase.firestore.GeoPoint;
  setter: (g: firebase.firestore.GeoPoint) => void;
}

const ModalAddLocation = ({ onClose }: AddLocationProps) => {
  // Context
  const profile = useContext(ctxProfile);
  const orgProducts = useContext(ctxProducts);
  const locations = useContext(ctxLocations);
  const orgContacts = useContext(ctxContacts);
  const org = useContext(ctxOrg);

  // State
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [gpsModalData, setGpsModalData] = useState<GPSModalData | undefined>();

  const [locationId, setLocationId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [locationType, setLocationType] = useState<LocationType | undefined>();
  const [address, setAddress] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>();
  const [geoPoint, setGeoPoint] = useState<firebase.firestore.GeoPoint | undefined>();
  const [associatedPartnerId, setAssociatedPartnerId] = useState<string | null>(null);

  // Warehouse specific
  const [services, setServices] = useState<WarehouseService[]>([]);

  // Production site specific
  const [productionType, setProductionType] = useState<ProductionType | undefined>();
  const [producedVarieties, setProducedVarieties] = useState<ProductVariety[]>([]);
  const [hasCoolingFacilities, setHasCoolingFacilities] = useState<boolean>(false);
  const [irrigationSystems, setIrrigationSystems] = useState<IrrigationType[]>([]);
  const [fieldManagementQuality, setFieldManagementQuality] = useState<
    FieldManagementQuality | undefined
  >();
  const [totalAreaInHa, setTotalAreaInHa] = useState<number | undefined>();
  const [growingAreaInHa, setGrowingAreaInHa] = useState<number | undefined>();
  const [growerId, setGrowerId] = useState<string | undefined>();
  const [packhouseGeoPoint, setPackhouseGeoPoint] = useState<
    firebase.firestore.GeoPoint | undefined
  >();

  // Extra properties requested by SF
  const [growingType, setGrowingType] = useState<GrowingType | undefined>();
  const [substrateType, setSubstrateType] = useState<SubstrateType | undefined>();
  const [growingMethodType, setGrowingMethodType] = useState<
    GrowingMethodType | undefined
  >();
  const [
    maxMinutesOnFieldBeforeTransportation,
    setMaxMinutesOnFieldBeforeTransportation,
  ] = useState<number | string | undefined>();
  const [maxPrecoolingTime, setMaxPrecoolingTime] = useState<
    number | string | undefined
  >();
  const [refrigeratedTransporter, setRefrigeratedTransporter] =
    useState<boolean>(false);
  const [datePlanted, setDatePlanted] = useState<Date | undefined>();
  const [productionWeekEnd, setProductionWeekEnd] = useState<number | undefined>();
  const [productionWeekStart, setProductionWeekStart] = useState<number | undefined>();
  const [plantsPerHa, setPlantsPerHa] = useState<number | undefined>();
  const [kgProducedPerPlant, setKgProducedPerPlant] = useState<number | undefined>();
  const [fieldStorageConditions, setFieldStorageConditions] = useState<
    FieldStorageCondition | undefined
  >();
  const [refrigerationTemperature, setRefrigerationTemperature] = useState<
    number | undefined
  >();

  //-------------------------------------------
  // Options
  const {
    countryOptions,
    varietyOptions,
    servicesOptions,
    locationTypeOptions,
    productionTypeOptions,
    fieldManagementOptions,
    irrigationSystemOptions,
    associatedPartnerOptions,
    selectedLocationType,
    selectedCountry,
    selectedServices,
    selectedVarieties,
    selectedProductionType,
    selectedFieldManagementOption,
    selectedIrrigationSystems,
    selectedAssociatedPartner,
    getLabel,
    // SF properties
    growingTypeOptions,
    selectedGrowingType,
    substrateTypeOptions,
    selectedSubstrateType,
    growingMethodOptions,
    selectedGrowingMethod,
    timeOptions,
    selectedTime,
    fieldStorageConditionOptions,
    selectedFieldStorageConditions,
    booleanOptions,
    selectedBoolean,
  } = useLocationAdminOptions(orgProducts, orgContacts, org);

  //----------------------------------------------
  // Handlers
  async function onAddLocation() {
    setIsSaving(true);

    let location: Location = {
      locationId,
      name,
      locationType,
      address,
      countryCode,
      geoPoint,
      associatedPartnerId,
    };

    if (location.locationType === LocationTypes.ProductionSite) {
      location = {
        ...location,
        producedVarieties,
        packhouseGeoPoint,
        productionType,
        hasCoolingFacilities,
        irrigationSystems,
        totalAreaInHa,
        growingAreaInHa,
        growingType,
        locationId,
        substrateType,
        growingMethodType,
        maxMinutesOnFieldBeforeTransportation,
        maxPrecoolingTime,
        refrigeratedTransporter,
        datePlanted,
        productionWeekEnd,
        productionWeekStart,
        plantsPerHa,
        fieldManagementQuality,
        kgProducedPerPlant,
        fieldStorageConditions,
        refrigerationTemperature,
      };
    } else if (location.locationType === LocationTypes.Warehouse) {
      location = {
        ...location,
        services,
      };
    }

    try {
      await setOrganizationLocation(firestore, profile, location);
      await presentStandardToast(
        toastController,
        `Location '${location.name}' created`,
        3000,
        'dark'
      );
      onClose();
    } catch (error) {
      await presentStandardToast(
        toastController,
        `Error creating '${location.name}': ${error}`
      );
    }

    setIsSaving(false);
  }

  // Render
  const isNameNotUnique = !!locations.find((l) => l.name === name);
  const isIdNotUnique = !!locations.find((l) => l.locationId === locationId);

  const addButtonDisabled = (): boolean => {
    return (
      name.length < 1 ||
      locationId.length < 1 ||
      isNameNotUnique ||
      isIdNotUnique ||
      isSaving ||
      !geoPoint ||
      !locationType ||
      (locationType === LocationTypes.ProductionSite && !productionType)
    );
  };

  return (
    <>
      <IonLoading isOpen={isSaving} />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Location</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="add-modal">
        <form onSubmit={(e) => e.preventDefault()}>
          <IonList>
            <IonItem className="select-input">
              <IonLabel>{getLabel('associatedPartnerId')}</IonLabel>
              <div>
                <Select
                  {...defaultSelectProps}
                  options={associatedPartnerOptions}
                  defaultValue={associatedPartnerOptions[0]}
                  value={selectedAssociatedPartner(countryCode)}
                  isClearable={false}
                  onChange={(e) => {
                    const newAssociatedPartnerId = e.value;
                    setAssociatedPartnerId(newAssociatedPartnerId);

                    // also update the locationId and name to reflect the partner, if there's one selected
                    if (newAssociatedPartnerId != null) {
                      const buildString = (
                        prefix: string,
                        suffix: number,
                        separator: string = '-'
                      ): string => `${prefix}${separator}${suffix}`;

                      let suffix: number = 1;
                      let newLocationId: string = buildString(
                        newAssociatedPartnerId,
                        suffix
                      );
                      while (!!locations.find((l) => l.locationId === newLocationId)) {
                        newLocationId = buildString(newAssociatedPartnerId, ++suffix);
                      }
                      setLocationId(newLocationId);

                      const partnerName = associatedPartnerOptions.find(
                        (o) => o.value === newAssociatedPartnerId
                      ).label;
                      suffix = 1;
                      let namePrefix = `${partnerName} - Location `;
                      let newName: string = buildString(namePrefix, suffix, '');
                      while (!!locations.find((l) => l.name === newName)) {
                        newName = buildString(namePrefix, ++suffix, '');
                      }
                      setName(newName);
                    } else {
                      setLocationId('');
                      setName('');
                    }
                  }}
                />
              </div>
            </IonItem>
            <IonItem color={!locationId ? 'warning' : undefined}>
              <IonLabel>{getLabel('locationId')}</IonLabel>
              <IonInput
                placeholder="required"
                onIonChange={(e) => setLocationId(e.detail.value ?? '')}
                value={locationId}
              />
            </IonItem>
            {isIdNotUnique && !isSaving && (
              <IonItem color="danger">
                <IonIcon icon={warningOutline} />
                {`Warning: id '${locationId}' is already in use`}
              </IonItem>
            )}

            <IonItem color={!name ? 'warning' : undefined}>
              <IonLabel>{getLabel('name')}</IonLabel>
              <IonInput
                onIonChange={(e) => setName(e.detail.value ?? '')}
                placeholder="required"
                value={name}
              />
            </IonItem>
            {isNameNotUnique && !isSaving && (
              <IonItem color="danger">
                <IonIcon icon={warningOutline} />
                {`Warning: location with name '${name}' already exists`}
              </IonItem>
            )}

            <IonItem>
              <IonLabel>{getLabel('address')}</IonLabel>
              <IonInput
                onIonChange={(e) => setAddress(e.detail.value ?? '')}
                value={address}
                placeholder="optional"
              />
            </IonItem>

            <IonItem className="select-input">
              <IonLabel>{getLabel('countryCode')}</IonLabel>
              <div>
                <Select
                  {...defaultSelectProps}
                  options={countryOptions}
                  value={selectedCountry(countryCode)}
                  isClearable={false}
                  onChange={(e) => setCountryCode(e?.value)}
                  placeholder="optional"
                />
              </div>
            </IonItem>

            <IonItem
              className="name"
              color={!geoPoint ? 'warning' : undefined}
              onClick={() => setGpsModalData({ setter: setGeoPoint, geoPoint })}
            >
              <IonLabel>{getLabel('geoPoint')}</IonLabel>
              <div className="gps-coords">
                {geoPoint != null
                  ? geoPointToDDM(geoPoint)
                  : 'Please indicate GPS coordinates'}
              </div>
            </IonItem>

            <IonItem
              className="select-input"
              color={!locationType ? 'warning' : undefined}
            >
              <IonLabel>{getLabel('locationType')}</IonLabel>
              <div>
                <Select
                  {...defaultSelectProps}
                  value={selectedLocationType(locationType)}
                  options={locationTypeOptions}
                  isClearable={false}
                  onChange={(e) => {
                    const newLocationType = e.value;
                    setLocationType(newLocationType);
                  }}
                  placeholder="required"
                />
              </div>
            </IonItem>

            {!!locationType && (
              <>
                {/* Warehouse specific */}
                {locationType === LocationTypes.Warehouse && (
                  <>
                    <IonItemDivider>Warehouse specific</IonItemDivider>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('services')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          isMulti
                          options={servicesOptions}
                          value={selectedServices(services)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) => setServices(e?.map((v) => v.value) ?? [])}
                        />
                      </div>
                    </IonItem>
                  </>
                )}

                {/* Production site specific */}
                {locationType === LocationTypes.ProductionSite && (
                  <>
                    <IonItemDivider>Production site specific</IonItemDivider>
                    <IonItem
                      className="select-input"
                      color={!productionType ? 'warning' : undefined}
                    >
                      <IonLabel>{getLabel('productionType')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={productionTypeOptions}
                          value={selectedProductionType(productionType)}
                          isClearable={false}
                          placeholder="Please enter production type"
                          onChange={(e) => setProductionType(e?.value ?? undefined)}
                        />
                      </div>
                    </IonItem>

                    <IonItem className="select-input">
                      <IonLabel>{getLabel('fieldManagementQuality')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={fieldManagementOptions}
                          value={selectedFieldManagementOption(fieldManagementQuality)}
                          isClearable={false}
                          placeholder="optional"
                          onChange={(e) =>
                            setFieldManagementQuality(e?.value ?? undefined)
                          }
                        />
                      </div>
                    </IonItem>

                    <IonItem className="select-input">
                      <IonLabel>{getLabel('producedVarieties')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          isMulti
                          options={varietyOptions}
                          value={selectedVarieties(producedVarieties)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) =>
                            setProducedVarieties(e?.map((v) => v.value) ?? [])
                          }
                        />
                      </div>
                    </IonItem>

                    <IonItem className="select-input">
                      <IonLabel>{getLabel('hasCoolingFacilities')}</IonLabel>
                      <IonButton
                        fill="clear"
                        slot="end"
                        onClick={() => setHasCoolingFacilities(!hasCoolingFacilities)}
                      >
                        <IonIcon
                          icon={hasCoolingFacilities ? checkboxOutline : squareOutline}
                        />
                      </IonButton>
                    </IonItem>

                    <IonItem className="select-input">
                      <IonLabel>{getLabel('irrigationSystems')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          isMulti
                          options={irrigationSystemOptions}
                          value={selectedIrrigationSystems(irrigationSystems)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) =>
                            setIrrigationSystems(e?.map((v) => v.value) ?? [])
                          }
                        />
                      </div>
                    </IonItem>

                    <IonItem className="select-input">
                      <IonLabel>{getLabel('totalAreaInHa')}</IonLabel>
                      <IonInput
                        placeholder="optional"
                        inputMode="decimal"
                        type="number"
                        onIonChange={(e) =>
                          setTotalAreaInHa(
                            (e.detail.value ?? '').length ? +e.detail.value : undefined
                          )
                        }
                        value={totalAreaInHa}
                      />
                    </IonItem>

                    <IonItem className="select-input">
                      <IonLabel>{getLabel('growingAreaInHa')}</IonLabel>
                      <IonInput
                        placeholder="optional"
                        inputMode="decimal"
                        type="number"
                        onIonChange={(e) =>
                          setGrowingAreaInHa(
                            (e.detail.value ?? '').length ? +e.detail.value : undefined
                          )
                        }
                        value={growingAreaInHa}
                      />
                    </IonItem>

                    <IonItem
                      className="name"
                      onClick={() =>
                        setGpsModalData({
                          setter: setPackhouseGeoPoint,
                          geoPoint: packhouseGeoPoint,
                        })
                      }
                    >
                      <IonLabel>{getLabel('packhouseGeoPoint')}</IonLabel>
                      <div className="gps-coords">
                        {packhouseGeoPoint != null
                          ? geoPointToDDM(packhouseGeoPoint)
                          : 'Please indicate GPS coordinates'}
                      </div>
                    </IonItem>

                    {/* SF stuff */}
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('growingType')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={growingTypeOptions}
                          value={selectedGrowingType(growingType)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) => setGrowingType(e?.value ?? undefined)}
                        />
                      </div>
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('substrateType')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={substrateTypeOptions}
                          value={selectedSubstrateType(substrateType)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) => setSubstrateType(e?.value ?? undefined)}
                        />
                      </div>
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('growingMethodType')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={growingMethodOptions}
                          value={selectedGrowingMethod(growingMethodType)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) => setGrowingMethodType(e?.value ?? undefined)}
                        />
                      </div>
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>
                        {getLabel('maxMinutesOnFieldBeforeTransportation')}
                      </IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={timeOptions}
                          value={selectedTime(maxMinutesOnFieldBeforeTransportation)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) =>
                            setMaxMinutesOnFieldBeforeTransportation(
                              e?.value ?? undefined
                            )
                          }
                        />
                      </div>
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('maxPrecoolingTime')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          options={timeOptions}
                          value={selectedTime(maxPrecoolingTime)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) => setMaxPrecoolingTime(e?.value ?? undefined)}
                        />
                      </div>
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('refrigeratedTransporter')}</IonLabel>
                      <IonButton
                        fill="clear"
                        slot="end"
                        onClick={() =>
                          setRefrigeratedTransporter(!refrigeratedTransporter)
                        }
                      >
                        <IonIcon
                          icon={
                            refrigeratedTransporter ? checkboxOutline : squareOutline
                          }
                        />
                      </IonButton>
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('datePlanted')}</IonLabel>
                      <ClearableDateTime
                        dateValue={datePlanted}
                        onChange={(d) => setDatePlanted(d)}
                      />
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('productionWeekStart')}</IonLabel>
                      <IonInput
                        placeholder="optional"
                        inputMode="numeric"
                        type="number"
                        onIonChange={(e) =>
                          setProductionWeekStart(
                            (e.detail.value ?? '').length
                              ? clamp(+e.detail.value, 1, 52)
                              : undefined
                          )
                        }
                        value={productionWeekStart}
                      />
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('productionWeekEnd')}</IonLabel>
                      <IonInput
                        placeholder="optional"
                        inputMode="numeric"
                        type="number"
                        onIonChange={(e) =>
                          setProductionWeekEnd(
                            (e.detail.value ?? '').length
                              ? clamp(+e.detail.value, 1, 52)
                              : undefined
                          )
                        }
                        value={productionWeekEnd}
                      />
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('plantsPerHa')}</IonLabel>
                      <IonInput
                        placeholder="optional"
                        inputMode="numeric"
                        type="number"
                        onIonChange={(e) =>
                          setPlantsPerHa(
                            (e.detail.value ?? '').length
                              ? clamp(+e.detail.value, 0, Infinity)
                              : undefined
                          )
                        }
                        value={plantsPerHa}
                      />
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('kgProducedPerPlant')}</IonLabel>
                      <IonInput
                        placeholder="optional"
                        inputMode="decimal"
                        type="number"
                        onIonChange={(e) =>
                          setKgProducedPerPlant(
                            (e.detail.value ?? '').length
                              ? clamp(+e.detail.value, 0, Infinity)
                              : undefined
                          )
                        }
                        value={kgProducedPerPlant}
                      />
                    </IonItem>
                    <IonItem className="select-input">
                      <IonLabel>{getLabel('fieldStorageConditions')}</IonLabel>
                      <div>
                        <Select
                          {...defaultSelectProps}
                          menuPlacement='auto'
                          options={fieldStorageConditionOptions}
                          value={selectedFieldStorageConditions(fieldStorageConditions)}
                          isClearable={true}
                          placeholder="optional"
                          onChange={(e) => {
                            setFieldStorageConditions(e?.value ?? undefined);
                            if (e?.value !== FieldStorageConditions.Refrigerator) {
                              setRefrigerationTemperature(undefined);
                            }
                          }}
                        />
                      </div>
                    </IonItem>
                    {fieldStorageConditions === FieldStorageConditions.Refrigerator && (
                      <IonItem className="select-input">
                        <IonLabel>{getLabel('refrigerationTemperature')}</IonLabel>
                        <IonInput
                          placeholder="optional"
                          inputMode="decimal"
                          type="number"
                          onIonChange={(e) =>
                            setRefrigerationTemperature(
                              (e.detail.value ?? '').length
                                ? +e.detail.value
                                : undefined
                            )
                          }
                          value={refrigerationTemperature}
                        />
                      </IonItem>
                    )}
                    {/* END SF stuff */}
                  </>
                )}
              </>
            )}
          </IonList>
          <div className="main-btn">
            <IonButton onClick={onAddLocation} disabled={addButtonDisabled()}>
              Add
            </IonButton>
          </div>
        </form>
        {!!gpsModalData && (
          <IonModal
            isOpen={!!gpsModalData}
            onDidDismiss={() => setGpsModalData(undefined)}
            cssClass={'map-picker-modal'}
          >
            <CMapPicker
              onAccept={(g: firebase.firestore.GeoPoint) => {
                gpsModalData.setter(g);
                setGpsModalData(undefined);
              }}
              initialGeoPoint={gpsModalData.geoPoint}
              onCancel={() => setGpsModalData(undefined)}
            />
          </IonModal>
        )}
      </IonContent>
    </>
  );
};

export default ModalAddLocation;
