import {
  IonPopover,
  IonList,
  IonListHeader,
  IonItem,
  IonIcon,
  IonButton,
  IonItemDivider,
} from '@ionic/react';
import { ellipsisHorizontalSharp } from 'ionicons/icons';
import React, { useState } from 'react';
import { Order } from '../Model';

/// ----------------------------------------------------------------

interface CardOrderMenuProps {
  order: Order;
}

/// ----------------------------------------------------------------

export const CardOrderMenu = ({ order }: CardOrderMenuProps) => {
  // const profile = useContext(ctxProfile)
  // const customNameRef = useRef<HTMLIonInputElement>(null);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  return (
    <>
      <IonPopover
        // cssClass="help-popover"
        mode="md"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonList>
          <IonListHeader>Actions for {order.id}</IonListHeader>
          <IonItem
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Share by email
          </IonItem>
          <IonItem
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Start conversation
          </IonItem>
          <IonItem
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Create a report
          </IonItem>
          <IonItem
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Mark as re-do
          </IonItem>
          <IonItemDivider />
          <IonItem
            lines="none"
            detail={false}
            button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowPopover({ showPopover: false, event: undefined });
            }}
          >
            Close Menu
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
        className="button-menu"
      >
        <IonIcon icon={ellipsisHorizontalSharp} slot="icon-only" color="medium" />
      </IonButton>
    </>
  );
};
