/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { CreateQuestionTagPayload } from '../model';
// @ts-ignore
import { EditQuestionTagPayload } from '../model';
// @ts-ignore
import { HTTPValidationError } from '../model';
// @ts-ignore
import { Question } from '../model';
// @ts-ignore
import { QuestionIn } from '../model';
// @ts-ignore
import { QuestionList } from '../model';
// @ts-ignore
import { QuestionTag } from '../model';
// @ts-ignore
import { QuestionTagList } from '../model';
// @ts-ignore
import { SectionType } from '../model';
/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Question
     * @param {QuestionIn} questionIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createQuestion: async (
      questionIn: QuestionIn,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionIn' is not null or undefined
      assertParamExists('createQuestion', 'questionIn', questionIn);
      const localVarPath = `/question/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionIn,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a new question tag and returns all existing question tags, including the     newly created one.
     * @summary Create Question Tag
     * @param {CreateQuestionTagPayload} createQuestionTagPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createQuestionTag: async (
      createQuestionTagPayload: CreateQuestionTagPayload,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createQuestionTagPayload' is not null or undefined
      assertParamExists(
        'createQuestionTag',
        'createQuestionTagPayload',
        createQuestionTagPayload
      );
      const localVarPath = `/questionTag/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createQuestionTagPayload,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestion: async (
      questionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('deleteQuestion', 'questionId', questionId);
      const localVarPath = `/question/{question_id}`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the question tag with the given ID.
     * @summary Delete Question Tag
     * @param {string} questionTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionTag: async (
      questionTagId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionTagId' is not null or undefined
      assertParamExists('deleteQuestionTag', 'questionTagId', questionTagId);
      const localVarPath = `/questionTag/{question_tag_id}`.replace(
        `{${'question_tag_id'}}`,
        encodeURIComponent(String(questionTagId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit Question
     * @param {string} questionId
     * @param {QuestionIn} questionIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editQuestion: async (
      questionId: string,
      questionIn: QuestionIn,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('editQuestion', 'questionId', questionId);
      // verify required parameter 'questionIn' is not null or undefined
      assertParamExists('editQuestion', 'questionIn', questionIn);
      const localVarPath = `/question/{question_id}`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionIn,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the question tag with the given ID.
     * @summary Edit Question Tag
     * @param {string} questionTagId
     * @param {EditQuestionTagPayload} editQuestionTagPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editQuestionTag: async (
      questionTagId: string,
      editQuestionTagPayload: EditQuestionTagPayload,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionTagId' is not null or undefined
      assertParamExists('editQuestionTag', 'questionTagId', questionTagId);
      // verify required parameter 'editQuestionTagPayload' is not null or undefined
      assertParamExists(
        'editQuestionTag',
        'editQuestionTagPayload',
        editQuestionTagPayload
      );
      const localVarPath = `/questionTag/{question_tag_id}`.replace(
        `{${'question_tag_id'}}`,
        encodeURIComponent(String(questionTagId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editQuestionTagPayload,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestion: async (
      questionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('getQuestion', 'questionId', questionId);
      const localVarPath = `/question/{question_id}`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the question tag with the given ID.
     * @summary Get Question Tag
     * @param {string} questionTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionTag: async (
      questionTagId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionTagId' is not null or undefined
      assertParamExists('getQuestionTag', 'questionTagId', questionTagId);
      const localVarPath = `/questionTag/{question_tag_id}`.replace(
        `{${'question_tag_id'}}`,
        encodeURIComponent(String(questionTagId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all existing question tags for the organisation.
     * @summary Get Question Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/questionTag/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Questions
     * @param {SectionType} [sectionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestions: async (
      sectionType?: SectionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, 'x-api-key', configuration);

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (sectionType !== undefined) {
        localVarQueryParameter['section_type'] = sectionType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Question
     * @param {QuestionIn} questionIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createQuestion(
      questionIn: QuestionIn,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestion(
        questionIn,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Creates a new question tag and returns all existing question tags, including the     newly created one.
     * @summary Create Question Tag
     * @param {CreateQuestionTagPayload} createQuestionTagPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createQuestionTag(
      createQuestionTagPayload: CreateQuestionTagPayload,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTagList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionTag(
        createQuestionTagPayload,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Delete Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestion(
      questionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestion(
        questionId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Deletes the question tag with the given ID.
     * @summary Delete Question Tag
     * @param {string} questionTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestionTag(
      questionTagId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionTag(
        questionTagId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Edit Question
     * @param {string} questionId
     * @param {QuestionIn} questionIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editQuestion(
      questionId: string,
      questionIn: QuestionIn,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editQuestion(
        questionId,
        questionIn,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Updates the question tag with the given ID.
     * @summary Edit Question Tag
     * @param {string} questionTagId
     * @param {EditQuestionTagPayload} editQuestionTagPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editQuestionTag(
      questionTagId: string,
      editQuestionTagPayload: EditQuestionTagPayload,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTag>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editQuestionTag(
        questionTagId,
        editQuestionTagPayload,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestion(
      questionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestion(
        questionId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns the question tag with the given ID.
     * @summary Get Question Tag
     * @param {string} questionTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionTag(
      questionTagId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTag>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionTag(
        questionTagId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Returns all existing question tags for the organisation.
     * @summary Get Question Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionTags(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTagList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionTags(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get Questions
     * @param {SectionType} [sectionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestions(
      sectionType?: SectionType,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestions(
        sectionType,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = QuestionApiFp(configuration);
  return {
    /**
     *
     * @summary Create Question
     * @param {QuestionIn} questionIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createQuestion(questionIn: QuestionIn, options?: any): AxiosPromise<Question> {
      return localVarFp
        .createQuestion(questionIn, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new question tag and returns all existing question tags, including the     newly created one.
     * @summary Create Question Tag
     * @param {CreateQuestionTagPayload} createQuestionTagPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createQuestionTag(
      createQuestionTagPayload: CreateQuestionTagPayload,
      options?: any
    ): AxiosPromise<QuestionTagList> {
      return localVarFp
        .createQuestionTag(createQuestionTagPayload, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestion(questionId: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .deleteQuestion(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes the question tag with the given ID.
     * @summary Delete Question Tag
     * @param {string} questionTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionTag(questionTagId: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .deleteQuestionTag(questionTagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Edit Question
     * @param {string} questionId
     * @param {QuestionIn} questionIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editQuestion(
      questionId: string,
      questionIn: QuestionIn,
      options?: any
    ): AxiosPromise<Question> {
      return localVarFp
        .editQuestion(questionId, questionIn, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the question tag with the given ID.
     * @summary Edit Question Tag
     * @param {string} questionTagId
     * @param {EditQuestionTagPayload} editQuestionTagPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editQuestionTag(
      questionTagId: string,
      editQuestionTagPayload: EditQuestionTagPayload,
      options?: any
    ): AxiosPromise<QuestionTag> {
      return localVarFp
        .editQuestionTag(questionTagId, editQuestionTagPayload, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestion(questionId: string, options?: any): AxiosPromise<Question> {
      return localVarFp
        .getQuestion(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the question tag with the given ID.
     * @summary Get Question Tag
     * @param {string} questionTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionTag(questionTagId: string, options?: any): AxiosPromise<QuestionTag> {
      return localVarFp
        .getQuestionTag(questionTagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all existing question tags for the organisation.
     * @summary Get Question Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionTags(options?: any): AxiosPromise<QuestionTagList> {
      return localVarFp
        .getQuestionTags(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Questions
     * @param {SectionType} [sectionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestions(sectionType?: SectionType, options?: any): AxiosPromise<QuestionList> {
      return localVarFp
        .getQuestions(sectionType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
  /**
   *
   * @summary Create Question
   * @param {QuestionIn} questionIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public createQuestion(questionIn: QuestionIn, options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .createQuestion(questionIn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new question tag and returns all existing question tags, including the     newly created one.
   * @summary Create Question Tag
   * @param {CreateQuestionTagPayload} createQuestionTagPayload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public createQuestionTag(
    createQuestionTagPayload: CreateQuestionTagPayload,
    options?: AxiosRequestConfig
  ) {
    return QuestionApiFp(this.configuration)
      .createQuestionTag(createQuestionTagPayload, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Question
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public deleteQuestion(questionId: string, options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .deleteQuestion(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes the question tag with the given ID.
   * @summary Delete Question Tag
   * @param {string} questionTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public deleteQuestionTag(questionTagId: string, options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .deleteQuestionTag(questionTagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit Question
   * @param {string} questionId
   * @param {QuestionIn} questionIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public editQuestion(
    questionId: string,
    questionIn: QuestionIn,
    options?: AxiosRequestConfig
  ) {
    return QuestionApiFp(this.configuration)
      .editQuestion(questionId, questionIn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the question tag with the given ID.
   * @summary Edit Question Tag
   * @param {string} questionTagId
   * @param {EditQuestionTagPayload} editQuestionTagPayload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public editQuestionTag(
    questionTagId: string,
    editQuestionTagPayload: EditQuestionTagPayload,
    options?: AxiosRequestConfig
  ) {
    return QuestionApiFp(this.configuration)
      .editQuestionTag(questionTagId, editQuestionTagPayload, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Question
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public getQuestion(questionId: string, options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .getQuestion(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the question tag with the given ID.
   * @summary Get Question Tag
   * @param {string} questionTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public getQuestionTag(questionTagId: string, options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .getQuestionTag(questionTagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all existing question tags for the organisation.
   * @summary Get Question Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public getQuestionTags(options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .getQuestionTags(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Questions
   * @param {SectionType} [sectionType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApi
   */
  public getQuestions(sectionType?: SectionType, options?: AxiosRequestConfig) {
    return QuestionApiFp(this.configuration)
      .getQuestions(sectionType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
