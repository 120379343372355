import {
  cloneDeep,
  findIndex,
  findLastIndex,
  head,
  isArray,
  isEqual,
  isObject,
  isUndefined,
  mapValues,
  omitBy,
  union,
} from 'lodash';
import { InspectionScore, Inspection } from './InspectionModel';
import {
  AllocationMismatch,
  Contact,
  Location,
  Lot,
  LotCreatePayload,
  LotPosition,
  LotTransfer,
  MergedSchema,
  Order,
  OrderAGStatus,
  OrderCreatePayload,
  OrderType,
  OrganisationSettings,
  ProductView,
  Report,
  ReportReference,
  ReportType,
  ScoreColor,
  TransferLotSummary,
} from './Model';
import { generateLotSearch } from './SearchService';
import { Article } from './ServiceArticle';

export const BlobToBase64 = (blob: any): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

// This function takes either a Date object, a unix timestamp (number) or a { _seconds: number... } object (firestore dates read from a node script or the FE) and converts it into a Date object
// We are using it in all places where we have dates that were previously modelled as unix timestamps,
// such that the app doesn't break between the deployment of the refactored code and the data migration (DEV-1359)
type FSDate = { _seconds?: number; seconds?: number };
export function standardizeDate(date?: number | Date | FSDate): Date | undefined {
  if (date == null) {
    return undefined;
  }
  if (!!(date as FSDate)._seconds || !!(date as FSDate).seconds) {
    return new Date(((date as FSDate)._seconds ?? (date as FSDate).seconds) * 1000);
  } else {
    return new Date(date as number | Date);
  }
}

// TODO: make this prettier
// AGREE
export function formatDate(
  date: any,
  formatOptions: Intl.DateTimeFormatOptions = {},
  returnDateObj: boolean = false
) {
  if (!date) {
    console.log('Invalid date. undefined or null');
    return '';
  }
  if (!!date.seconds) {
    return returnDateObj
      ? new Date(date.seconds * 1000)
      : new Intl.DateTimeFormat('en-GB', formatOptions).format(date.seconds * 1000);
  }
  if (!!date._seconds) {
    return returnDateObj
      ? new Date(date._seconds * 1000)
      : new Intl.DateTimeFormat('en-GB', formatOptions).format(date._seconds * 1000);
  }
  if (isNaN(new Date(date).getTime())) {
    console.log('Invalid date. not a date string', date);
    if (returnDateObj) return new Date();
    if (typeof date === 'string') {
      return date;
    } else {
      return new Intl.DateTimeFormat('en-GB', formatOptions).format(new Date());
    }
  } else {
    return returnDateObj
      ? new Date(date)
      : new Intl.DateTimeFormat('en-GB', formatOptions).format(new Date(date));
  }
}

export const getQcStatusColor = (order: Order) => {
  switch (order.qcStatus) {
    case 'REDO':
      return 'danger';
    case 'APPROVED':
    case 'CHECKED':
      return 'primary';
    case 'OPEN':
      return 'medium';
    case 'DONE':
      return 'primary';
    case 'SHARED':
      return 'tertiary';
    default:
      return 'dark';
  }
};

export function qcStatusToUIName(qcStatus: OrderAGStatus) {
  const statusMap: { [key in OrderAGStatus]?: string } = {
    CHECKED: 'REPORT CREATED',
  };

  return statusMap[qcStatus] ?? qcStatus;
}

export function qcStatusToColor(qcStatus: OrderAGStatus) {
  switch (qcStatus) {
    case 'DONE':
    case 'APPROVED':
    case 'CHECKED':
      return 'primary';
    case 'OPEN':
      return 'medium';
    case 'REDO':
      return 'danger';
    case 'SHARED':
      return 'tertiary';
    default:
      return 'dark';
  }
}

export function dateToYMD(date: Date) {
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export function formatDateTime(date: any) {
  return formatDate(date, { dateStyle: 'short', timeStyle: 'medium' });
}

export function round(number, numberOfCommas): number {
  return parseFloat(number.toFixed(numberOfCommas));
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function getSelectionFromId(id) {
  return JSON.parse(decodeURIComponent(id));
}

export function capitalizeFirstLetter(string: string | number) {
  if (string) {
    return (
      string.toString().toLowerCase().charAt(0).toUpperCase() +
      string.toString().split('_').join(' ').toLowerCase().slice(1)
    );
  } else {
    return '';
  }
}

export var uuid4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function resolveLocationName(locationId: string, locations: Location[] = []) {
  const location: Location = locations.find((l) => l.locationId === locationId);
  return !!location ? location.name : locationId;
}
const colorMap = {
  blue: '4',
  red: '1',
  green: '2',
  brown: '3',
  cyan: '6',
  magenta: '5',
  grey: '7',
  black: '0',
};

type LogColor =
  | 'blue'
  | 'red'
  | 'green'
  | 'yellow'
  | 'cyan'
  | 'magenta'
  | 'grey'
  | 'black';
export function colorLog(color: LogColor = 'green', ...args: any) {
  console.log(`\x1b[3${colorMap[color]}m%s\x1b[0m`, ...args);
}

// Color strings for console.log, for cooler logging 😎
// Example of use:
// console.log(`Picture ${c(p.id, 'green')} copied to ${c(newId, 'brown')}`);
// console.log(`Comencing import of inspection ${c(JSON.stringify(inspection.reference), 'blue')}`)
export const c = (text: string | number, color: LogColor = 'green') =>
  `\x1b[3${colorMap[color]}m${text}\x1b[0m`;

// compares two sets of elements to determine equality
// ----------------------------------------------------------------
export const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export function lotsToPositions(lots: Lot[]): LotPosition[] {
  return lots.map((lot) => ({
    lotId: lot.id,
    numBoxes: lot.transient?.numBoxes,
    volumeInKg:
      lot.transient?.volumeInKg ??
      new Article(lot.article).computeVolumeInKg(lot.transient?.numBoxes),
    article: lot.article,
    growerId: lot.origin?.growerContactId,
  }));
}

// shallow comparison between objects
// ----------------------------------------------------------------
export const shallowEqual = (object1, object2) => {
  object1 = object1 ?? {};
  object2 = object2 ?? {};
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export function truncateDec(value, decimals) {
  const truncated = (+value).toFixed(decimals);
  if (truncated === 'NaN') return value;
  return truncated.includes('.00') ? truncated.slice(0, -3) : truncated;
}

export function commaSeparatedNumbersToArray(commaSeparatedNumbers: any) {
  if (!commaSeparatedNumbers) {
    return [];
  }
  // console.log("commaSeparatedNumbers",commaSeparatedNumbers)
  return commaSeparatedNumbers
    .split(',')
    .map((s) => +parseFloat(s.trim()).toFixed(2))
    .filter((s) => !isNaN(s));
}

export function companyToAGScore(
  companyScore: any,
  mappingId: string,
  organisationSettings: OrganisationSettings
) {
  const agScore = organisationSettings?.scoreMappings?.[mappingId]?.find(
    (m) => m.companyScore == companyScore
  )?.agScore;
  return agScore;
}

export function getCustomColorMapping(
  score: InspectionScore,
  orgSettings: OrganisationSettings
): ScoreColor | undefined {
  if (!orgSettings?.scoreMappings || !score) {
    return;
  }

  return (orgSettings.scoreMappings[score.id] ?? []).find(
    (s) => s.companyScore === score.score
  )?.customColor;
}

export function getTodayMinusXDays(days: number) {
  let d = new Date();
  d.setDate(d.getDate() - days);
  return d.getTime();
}

export function dateOffsetInDays(date1: string, date2: string) {
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000; // 1 day in milliseconds
  const startDate = new Date(date1);
  const endDate = new Date(date2);
  const timeOffsetInMs = endDate.getTime() - startDate.getTime();
  const offsetInDays = Math.round(timeOffsetInMs / ONE_DAY_IN_MS);
  return Math.abs(offsetInDays);
}

export const merger = <T>(objValue: T, srcValue: T) => {
  if (isEqual(objValue, srcValue)) {
    return objValue;
  } else {
    return srcValue;
  }
};

export function articlePropToTitle(param: string) {
  const articleToTitleMap = {
    agProductId: 'Product',
    productId: 'Product',
    agVariety: 'Variety',
    variety: 'Variety',
    productName: 'Product name',
    size: 'Size',
    extPackagingRepr: 'Packaging',
    origin: 'Origin',
    brand: 'Brand',
    description: 'Description',
    consumerUnitNumberOfPieces: 'Pcs. per consumer unit',
    consumerUnitWeightInGrams: 'Consumer unit weight (g)',
    numConsumerUnitsInBox: 'Nr. of consumer units in box',
    boxGrossWeightInKg: 'Box gross weight (Kg)',
    boxNetWeightInKg: 'Box net weight (Kg)',
    isOrganic: 'Is organic?',
    avgPieceWeightInGrams: 'Avg. piece weight (g)',
    isBio: 'Is bio?',
  };
  return articleToTitleMap[param] ?? param;
}

export function stringifyArticleValue(param: any) {
  const emptyString = 'Not set';
  if (param == null) {
    return emptyString;
  }
  if (Array.isArray(param)) {
    return param.length > 0 ? param.join(', ') : emptyString;
  }
  if (param === true || param === false) {
    return param.toString();
  }
  return param;
}

// *************** //
// INSPECTION UTILS
// *************** //
type BaseEnt = ReportReference | Order | Lot | Report | Inspection;

export const sortByLastModifiedDateAsc = (a: BaseEnt, b: BaseEnt): number =>
  a.lastModifiedDate > b.lastModifiedDate ? 1 : -1;
export const sortByLastModifiedDateDesc = (a: BaseEnt, b: BaseEnt): number =>
  a.lastModifiedDate > b.lastModifiedDate ? -1 : 1;

export function cleanUserCreatedDataFromEntity(
  entity: Order | Lot | Contact,
  bumpDate: boolean = true
): Order | Lot | Contact {
  if (!entity) {
    return entity;
  }

  let lot = entity as Lot;
  let order = entity as Order;
  let contact = entity as Contact;

  if (!!lot.latestInspection) {
    delete lot.latestInspection;
    delete lot.firstQCDate;
    delete lot.lastQCDate;

    lot.inspections = [];
    lot.lastModifiedUserId = 'system';
    lot.search.inspectionTypes = [];
    lot.search.hasAssessment = false;
  }

  // 2. If it's an order:
  if (
    Object.keys(order.lotInspectionMap ?? order.transportInspectionMap ?? {}).length >
      0 ||
    (!!order.qcStatus && order.qcStatus !== 'OPEN')
  ) {
    delete order.firstQCDate;
    delete order.lastQCDate;
    delete order.lastQCStatusUserId;
    delete order.lastQCStatusDate;
    delete order.latestReportReference;
    delete order.reportReferences;

    order.lotInspectionMap = {};
    order.transportInspectionMap = {};
    order.lastModifiedUserId = 'system';
    order.hasReportDraft = false;
    order.qcStatus = 'OPEN';
    order.search.inspectionTypes = [];
    order.search.hasAssessment = false;
  }

  if (!!order.fulfilmentDate && bumpDate) {
    order.fulfilmentDate = new Date();
  }

  // 3. If it's a contact
  if (!!contact.contactEmails || !!contact.lastContactsUsed) {
    contact.contactEmails = [];
    contact.lastContactsUsed = [];
  }

  return entity;
}

/* ************** */

// *************** //
// ORDER UTILS
// *************** //
export function getSupplyChainReportReferences(order: Order): ReportReference[] {
  return (order?.reportReferences ?? []).filter(
    (r) =>
      r.autogenFromOrder === false &&
      (['UPCOMING', 'FEEDBACK'] as ReportType[]).includes(r.type)
  );
}

export function dispatchOrderId() {
  return 'SO' + getDateSuffix();
}

// TODO: refactor rest of order types
export function createOrderId(type: OrderType) {
  const suffixMap: { [key in OrderType]?: string } = {
    BUY: 'PO',
    SELL: 'SO',
  };

  return `${suffixMap[type] ?? 'PO'}${getDateSuffix()}`;
}

export function productionOrderId() {
  return 'P' + getDateSuffix();
}

/* ************** */
// *************** //
// LOT UTILS
// *************** //
export const sortLotTransfers = (a: LotTransfer, b: LotTransfer) => {
  return (
    standardizeDate(a.transferDate ?? 0).getTime() -
    standardizeDate(b.transferDate ?? 0).getTime()
  );
};

export function computeTransientQuantities(lot: Lot): {
  numBoxes: number;
  volumeInKg: number;
} {
  const sortedTransfers: LotTransfer[] = (lot?.transfers ?? []).sort(sortLotTransfers);
  const lastTransferIndex = findLastIndex(
    sortedTransfers,
    (t) => t.transferType === 'INTERNAL_TRANSFER'
  );

  // we compute it starting from the last known INTERNAL TRANSFER, assuming its quantity is the last known num of boxes at that point
  const numBoxes = sortedTransfers
    .slice(lastTransferIndex < 0 ? 0 : lastTransferIndex)
    .filter((t) => typeof t.numBoxes === 'number')
    .reduce((current: number, transfer: LotTransfer) => current + transfer.numBoxes, 0);

  const volumeInKg = sortedTransfers
    .slice(lastTransferIndex < 0 ? 0 : lastTransferIndex)
    .filter((t) => typeof t.volumeInKg === 'number')
    .reduce(
      (current: number, transfer: LotTransfer) => current + transfer.volumeInKg,
      0
    );

  return { numBoxes, volumeInKg };
}

export function isSplitLot(lot: Lot): boolean {
  const splitLotTransfers = lot?.transfers?.filter(
    (t) => t.transferType === 'SPLIT_LOT'
  );

  if (!splitLotTransfers?.length) {
    return false;
  }

  return !!splitLotTransfers.some((t) => t.toLots?.find((l) => l.lotId === lot.id));
}

export const replaceAll = (str: string, search: string | RegExp, replacement: string) => {
  return str.split(search).join(replacement);
};

export function lotIsUserCreated(lot: Lot) {
  // Note: all lots created via UI after 2022/11/28 should have isAGMaster flag; the second check is to support older supply chain lots
  return !!lot?.isAGMaster || lot?.transfers?.[0]?.transferType === 'CREATE_LOT';
}

export function getProductionTransferOfLotCreatedFromBatchTransformation(lot: Lot) {
  return lot.transfers.find(
    (t) =>
      t.transferType === 'PRODUCTION' &&
      (t.toLots ?? []).map((l) => l.lotId).includes(lot.id)
  );
}

export function lotId(): string {
  return 'L' + getDateSuffix();
}

export function mapLotToLotAction(lot: Lot, isSupplyChain: boolean = false): Order {
  const position: LotPosition = {
    lotId: lot.id,
    numBoxes: lot.transient.numBoxes,
    volumeInKg: lot.transient.volumeInKg,
    article: lot.article,
  };

  let { transferType: type, transferDate, transferId: id } = lot.transfers[0];

  if (isSupplyChain) {
    type = 'SURVEYED';
  }

  const lotAction: Order = {
    id,
    orgId: lot.orgId,
    type,
    fulfilmentDate: standardizeDate(transferDate),
    locationId: lot.transient?.locationId ?? null,
    positions: [position],
    contactId: lot.suppliedByContactId ?? lot.origin?.growerContactId ?? null,
  };
  return lotAction;
}

export function getSplitLotTransferedQuantity(
  order: Order | Report,
  motherPosition: LotPosition,
  quantityField: 'numBoxes' | 'volumeInKg'
) {
  let transferedQuantity: number = 0;
  order.positions
    .filter((p) => (p.motherLotIds ?? []).includes(motherPosition.lotId))
    .forEach((p) => {
      transferedQuantity += p[quantityField];
    });
  return transferedQuantity;
}

export function getLotOrPosQuantity(entity: LotPosition | Lot): number {
  if (!entity) {
    return undefined;
  }
  const lot: Lot = entity as Lot;
  const pos: LotPosition = entity as LotPosition;

  const isRaw = new Article(entity?.article).isRaw();

  if (!!pos.lotId) {
    return Math.round(isRaw ? pos.volumeInKg : pos.numBoxes);
  } else {
    return Math.round(isRaw ? lot.transient?.volumeInKg : lot.transient?.numBoxes);
  }
}

// returns true if given position is a sublot and the mother lot exists in the order
export const isValidSubLot = (
  position: LotPosition,
  order: Order | OrderCreatePayload
) =>
  !!(position.motherLotIds ?? []).some((mLotId: string) =>
    order.positions.map((p) => p.lotId).includes(mLotId)
  );

export const isSplitLotPosition = (p: LotPosition) => !!(p.motherLotIds ?? [])?.length;

export const shouldShowPositionQuantity = (position: LotPosition) => {
  if (!position) {
    return false;
  }
  const articleClass = new Article(position?.article);
  return (
    (articleClass.isRaw() && position.volumeInKg !== undefined) ||
    (!articleClass.isRaw() && position.numBoxes !== undefined)
  );
};

export function getDateSuffix(): string {
  const date = new Date();
  return (
    date.getFullYear().toString().slice(2) +
    '-' +
    (date.getMonth() + 1) +
    date.getDate() +
    date.getTime().toString().slice(-5)
  );
}

export const LOT_ACTION_ID_PREFIX = 'LA-';
export const createLotActionId = () => `${LOT_ACTION_ID_PREFIX}${getDateSuffix()}`;

export function changeLotId(
  oldLot: Lot,
  newLotId: string,
  orgSettings: OrganisationSettings
): Lot {
  const lot: Lot = cloneDeep(oldLot);

  lot.id = newLotId;

  if (!!lot.inspections) {
    lot.inspections = lot.inspections.map((a) => ({
      ...a,
      reference: { ...a.reference, lotId: newLotId },
    }));
  }

  if (!!lot.latestInspection) {
    lot.latestInspection.reference.lotId = newLotId;
  }

  lot.search = generateLotSearch(lot, orgSettings);

  return lot;
}

export const mapLotTransferSummary = (p: LotPosition): TransferLotSummary => ({
  lotId: p.lotId,
  numBoxes: Math.abs(p.numBoxes),
  volumeInKg: Math.abs(
    p.volumeInKg ?? new Article(p.article).computeVolumeInKg(p.numBoxes)
  ),
  article: p.article,
});

export const mapProductionOrSplitLotPayloadPositions = (
  p: LotPosition,
  multi: number = 1
) => ({
  ...p,
  numBoxes: !isNaN(p.numBoxes) ? multi * Math.abs(p.numBoxes) : undefined,
  volumeInKg:
    multi *
    Math.abs(p.volumeInKg ?? new Article(p.article).computeVolumeInKg(p.numBoxes)),
});

export const filterFromPositions = (p: LotPosition) =>
  new Article(p.article).isRaw() ? p.volumeInKg < 0 : p.numBoxes < 0;
export const filterToPositions = (p: LotPosition) =>
  new Article(p.article).isRaw() ? p.volumeInKg >= 0 : p.numBoxes >= 0;

export const lotHasQuantity = (l: Lot) =>
  (!new Article(l?.article).isRaw() && l?.transient?.numBoxes > 0) ||
  (new Article(l?.article).isRaw() && l?.transient?.volumeInKg > 0);

/**************** */

export const getWasteId = (lotActionId: string, lotId: string) =>
  `${lotActionId}-${lotId}`;

export function readFileAsync(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function computeAllocationMismatches(
  expectedLot: Lot | LotPosition | LotCreatePayload,
  foundLot: Lot | LotPosition | LotCreatePayload
) {
  if (!expectedLot || !foundLot) {
    return [];
  }

  const mismatches: AllocationMismatch[] = [];
  const foundArticle = foundLot.article;
  const expectedArticle = expectedLot.article;

  const allParams = [
    ...new Set([...Object.keys(foundArticle), ...Object.keys(expectedArticle)]),
  ];

  // console.log("computeAllocationMismatches", allParams, foundArticle, expectedArticle)

  // article properties mismatches
  for (const param of allParams) {
    // we omit the following article properties in the comparison
    if (
      ['agProductId', 'productId', 'id', 'certificates', 'lastModifiedDate'].includes(
        param
      )
    ) {
      continue;
    }
    if (
      expectedArticle[param] !== foundArticle[param] &&
      expectedArticle[param] != null &&
      foundArticle[param] != null
    ) {
      mismatches.push({
        title: articlePropToTitle(param),
        expected: stringifyArticleValue(expectedArticle[param]),
        found: stringifyArticleValue(foundArticle[param]),
      });
    }
  }

  const foundQuantity =
    (foundLot as Lot).transient?.numBoxes ??
    (foundLot as LotPosition | LotCreatePayload).numBoxes;

  const expectedQuantity =
    (expectedLot as Lot).transient?.numBoxes ??
    (expectedLot as LotPosition | LotCreatePayload).numBoxes;

  // lot properties mismatches (for now, only quantity)
  if (foundQuantity !== expectedQuantity) {
    mismatches.push({
      title: 'Nr. of boxes',
      expected: expectedQuantity,
      found: foundQuantity,
    });
  }
  return mismatches;
}

export function copyArray<T>(arr: T[]): T[] {
  // TODO: deprecate this and always use lodash's deepClone
  return JSON.parse(JSON.stringify([...arr]));
}

export function removeSpecialChars(str: string) {
  if (!str) {
    return str;
  }
  //eslint-disable-next-line
  return replaceAll(
    str.replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
    ' ',
    ''
  );
}

// TODO ILDE: decommission this once we get rid of contactOrder
export function getOrderReportTypeTitle(
  order: Order,
  bypassStatusCheck: boolean = false
) {
  if (
    !order ||
    (order?.latestReportReference?.reportStatus !== 'SHARED' && !bypassStatusCheck)
  )
    return 'Order';

  switch (order.type) {
    case 'BUY':
      return 'Feedback Report';
    case 'SELL':
      return 'Upcoming Report';
    default:
      return 'Contact order';
  }
}

export function getReportTypeTitle(type: ReportType) {
  switch (type) {
    case 'UPCOMING':
      return 'Upcoming report';
    case 'FEEDBACK':
      return 'Feedback';
    default:
      return 'Report';
  }
}

export function getOrderReportTypeString(contactOrder: Order) {
  if (!contactOrder) return '';
  switch (contactOrder.type) {
    case 'BUY':
      return 'feedback';
    case 'SELL':
      return 'upcoming';
    default:
      return '';
  }
}

export function removeUndefinedFields(obj) {
  if (isArray(obj)) {
    return obj.map(removeUndefinedFields);
  } else if (isObject(obj)) {
    return omitBy(mapValues(obj, removeUndefinedFields), isUndefined);
  } else {
    return obj;
  }
}

export function trimDecimals(n: number, decimals: number = 2): string {
  if (isNaN(+n)) {
    return `${n}`;
  }
  return (+n).toFixed(decimals).replace(/\.?0+$/, '');
}

export function compareObjects(obj1, obj2) {
  const keys = union(Object.keys(obj1 ?? {}), Object.keys(obj2 ?? {}));
  const diffKeys = [];

  keys.forEach((key) => {
    if (!isEqual(obj1[key], obj2[key])) {
      diffKeys.push(key);
    }
  });

  return diffKeys;
}

export function convertCamelToSentenceCase(input: string): string {
  if (!input) return '';

  let result = input[0].toUpperCase();

  for (let i = 1; i < input.length; i++) {
    if (input[i].toUpperCase() === input[i]) {
      result += ' ' + input[i].toLowerCase();
    } else {
      result += input[i];
    }
  }

  return result;
}

interface Difference {
  key: string;
  val1: any;
  val2: any;
}

export function deepDiff(obj1: any, obj2: any, path: string = ''): Difference[] {
  let diff: Difference[] = [];

  for (let key in obj1) {
    const newPath = path ? `${path}.${key}` : key;
    if (typeof obj1[key] === 'object' && obj1[key] !== null && obj2[key]) {
      diff = diff.concat(deepDiff(obj1[key], obj2[key], newPath));
    } else if (!isEqual(obj1[key], obj2[key])) {
      diff.push({ key: newPath, val1: obj1[key], val2: obj2[key] });
    }
  }

  return diff;
}

export const isLocalServer = () => window.location.href.indexOf('localhost:3000') >= 0;

export const isMobile = () =>
  /Android|webOS|Tablet|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) ||
  (navigator.maxTouchPoints > 0 && navigator.maxTouchPoints < 10);

export const agProductEmojiMapping = {
  'Aloe': '🌿',
  'Apples': '🍎',
  'Apricots': '🍑',
  'Avocado': '🥑',
  'Baby Corn': '🌽',
  'Bananas': '🍌',
  'Bananas Red': '🍌',
  'Bananas Small': '🍌',
  'Basil': '🌿',
  'Bay Leaf': '🍃',
  'Beans': '',
  'Berries Mixed': '',
  'Black Currants': '',
  'Black Salsify': '',
  'Blackberries': '🍇',
  'Blueberries': '🫐',
  'Carambola': '',
  'Cherimoya': '',
  'Cherries': '🍒',
  'Chervil': '🌿',
  'Chestnuts': '🌰',
  'Chives': '🌿',
  'Clementines': '🍊',
  'Coconut': '🥥',
  'Coriander': '🌿',
  'Cranberries': '',
  'Curcuma': '🍠',
  'Currants': '',
  'Dates': '',
  'Durians': '',
  'Exotic Mixed': '',
  'Fig': '',
  'Fruit Mixed': '',
  'Ginger': '',
  'Gooseberries': '',
  'Granadilla': '',
  'Grapefruits': '🍊',
  'Grapefruits Red': '🍊',
  'Grapefruits White': '🍊',
  'Grapes': '🍇',
  'Grapes Black': '🍇',
  'Grapes Green': '🍇',
  'Grapes Mixed': '🍇',
  'Grapes Red': '🍇',
  'Green Asparagus': '',
  'Green Asparagus Tips': '',
  'Guava': '',
  'Haskap Berries': '',
  'Herbs Mixed': '🌿',
  'Horseradish': '',
  'Hot Peppers': '🌶️',
  'Jackfruits': '',
  'Kaki-Apple': '',
  'Kiwano': '',
  'Kiwi Berries': '',
  'Kiwifruits': '🥝',
  'Kumquat': '🍊',
  'Lemongrass': '',
  'Lemons': '🍋',
  'Lime': '🍋',
  'Lovage': '🌿',
  'Lychee': '',
  'Mandarines': '🍊',
  'Mango': '🥭',
  'Mangosteen': '',
  'Melon pear': '',
  'Melons': '🍈',
  'Mini Pineapple': '🍈',
  'Minneola': '',
  'Mixed Cintrus': '',
  'Nashi Pear': '🍐',
  'Nectarines': '🍊',
  'Oranges': '🍊',
  'Oranges Blond': '🍊',
  'Oranges Blood (Red)': '🍊',
  'Oranges Half-Blood (Half-Red)': '🍊',
  'Papaya': '',
  'Parsley': '🌿',
  'Passionfruit': '',
  'Peaches': '🍑',
  'Peas': '',
  'Persimmons': '',
  'Physalis': '',
  'Pineapples': '🍍',
  'Pineberries': '',
  'Pitahaya': '',
  'Plantains': '🍌',
  'Plums': '',
  'Pomegranate': '',
  'Pomelo': '🍊',
  'Pomelo Red': '🍊',
  'Pomelo White': '🍊',
  'Rambutan': '',
  'Raspberries': '🍇',
  'Red Currants': '',
  'Rhubarb': '',
  'Rosemary': '🌿',
  'Sage': '🌿',
  'Savory': '🌿',
  'Seedless Grapes': '🍇',
  'Seedless Grapes Black': '🍇',
  'Seedless Grapes Green': '🍇',
  'Seedless Grapes Red': '🍇',
  'Sharonfruit': '',
  'Snowpeas': '',
  'Sorrel': '🌿',
  'Spearmint': '🌿',
  'Stone Fruit Mixed': '',
  'Strawberries': '🍓',
  'Sugar Snap Peas': '',
  'Sweet Corn': '🌽',
  'Sweet Potatoes': '🍠',
  'Sweetie': '🍋',
  'Sweetie Red': '',
  'Sweetie White': '',
  'Tamarillo': '🍅',
  'Tarragon': '🌿',
  'Thyme': '🌿',
  'Tropical Avocado': '🥑',
  'Watermelons': '🍉',
  'White Asparagus': '',
  'White Asparagus Tips': '',
  'White Currants': '',
};

export function getFruitEmojiFromCriteria(
  schema: MergedSchema,
  products: ProductView[]
) {
  if (!schema.criteria) {
    return '';
  }
  let key: string;
  // TODO Update this w/ the revised Criteria interface
  // if (!!schema.criteria?.agProductId) {
  //   key = schema.criteria?.agProductId;
  // }
  // else if (!!schema.criteria?.productId && !!products) {
  //   key = products.find(p => p.productId === schema.criteria.productId)?.agProductId;
  // }

  if (key && !!agProductEmojiMapping[key]) {
    return agProductEmojiMapping[key];
  }
  return '';
}

export function containsObject<T>(obj: T, list: T[]) {
  let i;
  for (i = 0; i < list.length; i++) {
    if (isEqual(list[i], obj)) {
      return true;
    }
  }

  return false;
}

// utility to get a date + or - some days
export const getOffsetDate = (daysToOffsetDate: number) => {
  const today = new Date();
  let offsetDate: Date = new Date(today);
  offsetDate.setDate(offsetDate.getDate() + daysToOffsetDate);
  return offsetDate;
};

export const swapHeadWithValueAtIndex = (arr: any[], value: string) => {
  console.log('SWAP START');
  console.log('arr', arr);
  console.log('value', value);
  if (arr.length >= 2) {
    const tabIndex = findIndex(arr, (tab) => tab === value);
    console.log('tabIndex', tabIndex);
    const headTabs = head(arr);
    console.log('headTabs', headTabs);
    arr[0] = arr[tabIndex];
    arr[tabIndex] = headTabs;
  }
  console.log('SWAPT END');
  return arr;
};

export const CurrencyCodeNames: { [key: string]: string } = {
  AED: 'United Arab Emirates Dirham',
  AFN: 'Afghan Afghani',
  ALL: 'Albanian Lek',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Angolan Kwanza',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AWG: 'Aruban Florin',
  AZN: 'Azerbaijani Manat',
  BAM: 'Bosnia-Herzegovina Convertible Mark',
  BBD: 'Barbadian Dollar',
  BDT: 'Bangladeshi Taka',
  BGN: 'Bulgarian Lev',
  BHD: 'Bahraini Dinar',
  BIF: 'Burundian Franc',
  BMD: 'Bermudian Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Bolivian Boliviano',
  BRL: 'Brazilian Real',
  BSD: 'Bahamian Dollar',
  BTN: 'Bhutanese Ngultrum',
  BWP: 'Botswanan Pula',
  BYN: 'Belarusian Ruble',
  BZD: 'Belize Dollar',
  CAD: 'Canadian Dollar',
  CDF: 'Congolese Franc',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CNY: 'Chinese Yuan',
  COP: 'Colombian Peso',
  CRC: 'Costa Rican Colón',
  CUC: 'Cuban Convertible Peso',
  CUP: 'Cuban Peso',
  CVE: 'Cape Verdean Escudo',
  CZK: 'Czech Republic Koruna',
  DJF: 'Djiboutian Franc',
  DKK: 'Danish Krone',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  ERN: 'Eritrean Nakfa',
  ETB: 'Ethiopian Birr',
  EUR: 'Euro',
  FJD: 'Fijian Dollar',
  FKP: 'Falkland Islands Pound',
  GBP: 'British Pound Sterling',
  GEL: 'Georgian Lari',
  GGP: 'Guernsey Pound',
  GHS: 'Ghanaian Cedi',
  GIP: 'Gibraltar Pound',
  GMD: 'Gambian Dalasi',
  GNF: 'Guinean Franc',
  GTQ: 'Guatemalan Quetzal',
  GYD: 'Guyanaese Dollar',
  HKD: 'Hong Kong Dollar',
  HNL: 'Honduran Lempira',
  HRK: 'Croatian Kuna',
  HTG: 'Haitian Gourde',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Sheqel',
  IMP: 'Isle of Man Pound',
  INR: 'Indian Rupee',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  ISK: 'Icelandic Króna',
  JEP: 'Jersey Pound',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KGS: 'Kyrgystani Som',
  KHR: 'Cambodian Riel',
  KMF: 'Comorian Franc',
  KPW: 'North Korean Won',
  KRW: 'South Korean Won',
  KWD: 'Kuwaiti Dinar',
  KYD: 'Cayman Islands Dollar',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Laotian Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lankan Rupee',
  LRD: 'Liberian Dollar',
  LSL: 'Lesotho Loti',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariary',
  MKD: 'Macedonian Denar',
  MMK: 'Myanma Kyat',
  MNT: 'Mongolian Tugrik',
  MOP: 'Macanese Pataca',
  MRU: 'Mauritanian Ouguiya',
  MUR: 'Mauritian Rupee',
  MVR: 'Maldivian Rufiyaa',
  MWK: 'Malawian Kwacha',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  MZN: 'Mozambican Metical',
  NAD: 'Namibian Dollar',
  NGN: 'Nigerian Naira',
  NIO: 'Nicaraguan Córdoba',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese Rupee',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PAB: 'Panamanian Balboa',
  PEN: 'Peruvian Nuevo Sol',
  PGK: 'Papua New Guinean Kina',
  PHP: 'Philippine Peso',
  PKR: 'Pakistani Rupee',
  PLN: 'Polish Zloty',
  PYG: 'Paraguayan Guarani',
  QAR: 'Qatari Rial',
  RON: 'Romanian Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Russian Ruble',
  RWF: 'Rwandan Franc',
  SAR: 'Saudi Riyal',
  SBD: 'Solomon Islands Dollar',
  SCR: 'Seychellois Rupee',
  SDG: 'Sudanese Pound',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SHP: 'Saint Helena Pound',
  SLL: 'Sierra Leonean Leone',
  SOS: 'Somali Shilling',
  SPL: 'Seborga Luigino',
  SRD: 'Surinamese Dollar',
  STN: 'São Tomé and Príncipe Dobra',
  SVC: 'Salvadoran Colón',
  SYP: 'Syrian Pound',
  SZL: 'Swazi Lilangeni',
  THB: 'Thai Baht',
  TJS: 'Tajikistani Somoni',
  TMT: 'Turkmenistani Manat',
  TND: 'Tunisian Dinar',
  TOP: "Tongan Pa'anga",
  TRY: 'Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TVD: 'Tuvaluan Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Ukrainian Hryvnia',
  UGX: 'Ugandan Shilling',
  USD: 'United States Dollar',
  UYU: 'Uruguayan Peso',
  UZS: 'Uzbekistan Som',
  VEF: 'Venezuelan Bolívar',
  VND: 'Vietnamese Dong',
  VUV: 'Vanuatu Vatu',
  WST: 'Samoan Tala',
  XAF: 'CFA Franc BEAC',
  XCD: 'East Caribbean Dollar',
  XDR: 'Special Drawing Rights',
  XOF: 'CFA Franc BCEAO',
  XPF: 'CFP Franc',
  YER: 'Yemeni Rial',
  ZAR: 'South African Rand',
  ZMW: 'Zambian Kwacha',
  ZWD: 'Zimbabwean Dollar',
};
