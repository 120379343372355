/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SectionType = {
  Action: 'action',
  ControlledAtmosphere: 'controlled_atmosphere',
  General: 'general',
  Header: 'header',
  Organoleptics: 'organoleptics',
  PackagingAndLabeling: 'packaging_and_labeling',
  Pictures: 'pictures',
  Quality: 'quality',
  Sampling: 'sampling',
  Scoring: 'scoring',
  Summary: 'summary',
} as const;

export type SectionType = (typeof SectionType)[keyof typeof SectionType];
