import './App.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.scss';

import {
  // getConfig,
  IonApp,
  IonContent,
  IonFab,
  IonIcon,
  IonLoading,
  IonRouterOutlet,
  IonSlide,
  IonSlides,
  IonSplitPane,
  isPlatform,
} from '@ionic/react';
import { toastController } from '@ionic/core';
import { isEqual } from 'lodash';
import { IonReactRouter } from '@ionic/react-router';
import i18n from 'i18next';
import { cloudOffline, cloudUploadOutline, reloadCircle } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { Route } from 'react-router-dom';
import firebase, { auth, firestore } from './ConfigFirebase';
import { logout, onAuthenticationChanged } from './DataApp';
import { doImageSync, resetIsUploadingIndexedDB } from './DataImage';
import {
  getContactsSnapshot,
  getLocationsSnapshot,
  getOrganisationSnapshot,
  getOrganisationSpecs,
  getProductViewSnapshot,
  getUserProfileSnapshot,
  getUsersSnapshot,
} from './DataStorage';
import {
  ApplicationState,
  Contact,
  Location,
  Organisation,
  ProductView,
  User,
  UserProfile,
} from './Model';
import { initalizeServiceWorker } from './Worker';

import { usePrevious } from './HelperHooks';
import PageLogin from './PageLogin';

import { configure as configureScandit } from 'scandit-sdk';

import SCANDIT_KEY from './DataScandit';
import ErrorBoundaryFallback from './ErrorBoundary';

import { CMainRoutes } from './components/CMainRoutes';
import { CSideMenu } from './components/CSideMenu';
import PageReport from './PageReport';
import PageResetPassword from './PageResetPassword';
import ViewResetPasswordModal from './ViewResetPasswordModal';
import {
  AGFilters,
  getLocationFilter,
  removeSectionSpecificFilterFields,
} from './SearchService';

import { QueryClient, QueryClientProvider } from 'react-query';
import {
  APP_CONTEXT_STORAGE_KEY,
  SHARED_FIELD_REPORT_BASE_PATH,
} from './GlobalConstants';
import eventLogger from './events/common';
import _ from 'lodash';
import PageInspectionView from './PageInspectionView';

import { set, get } from 'idb-keyval';
import PageCustomerProfiling from './PageCustomerProfiling';
import PageSupplierProfiling from './PageSupplierProfiling';

import * as Sentry from '@sentry/react';
import { fetchEntityErrorMessage, presentStandardToast } from './HelperIonic';
import { createBrowserHistory } from 'history';
import { isSignInWithEmailLink } from 'firebase/auth';
import { InspectionSpec, LotScoringSection } from './ModelSpecification';
import { PagePrivacyPolicy } from './PagePrivacyPolicy';
import { c } from './HelperUtils';
import { initSentry } from './ConfigSentry';
import { applicationStateObservable } from './simpleObservable/observables';
import { PageSharedFieldReport } from './pages/PageSharedFieldReport/PageSharedFieldReport';

// ----------------------------------------------------------------
// queryClient react-query
// ----------------------------------------------------------------

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       retry: false
//     }
//   }
// });

export const history = createBrowserHistory();
export const SentryRoute = Sentry.withSentryRouting(Route);

// Sentry
if (process.env.REACT_APP_ENV === 'production') {
  initSentry(history);
}

// React Query
const queryClient = new QueryClient();

export const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

// ----------------------------------------------------------------
// State
// ----------------------------------------------------------------

interface AppState {
  syncState: ApplicationState;

  profile: UserProfile;
  inited: boolean;
  signInMethods: string[];

  fetchDataSuccess: boolean;
  authSuccess: boolean;

  // ex-ApplicationContext
  organisation: Organisation;
  filters: AGFilters;

  users: User[];
  contacts: Contact[];
  locations: Location[];
  products: ProductView[];

  inspectionSpecs: InspectionSpec[];
  scorings: LotScoringSection[];
}

const appContextKeys: (keyof AppState)[] = [
  'organisation',
  'filters',
  'contacts',
  'users',
  'locations',
  'products',
  'inspectionSpecs',
  'scorings',
];

interface Props {}

// ----------------------------------------------------------------
// Context
// ----------------------------------------------------------------
interface FiltersContextInterface {
  filters: AGFilters;
  setFilters: (f: AGFilters) => void;
}

interface SpecsContextInterface {
  inspectionSpecs: InspectionSpec[];
  setInspectionSpecs: (s: InspectionSpec[]) => void;
}

interface ScoringContextInterface {
  scorings: LotScoringSection[];
  setScorings: (s: LotScoringSection[]) => void;
}

export const ctxProducts = React.createContext<ProductView[]>([]);
export const ctxUsers = React.createContext<User[]>([]);
export const ctxProfile = React.createContext<UserProfile | undefined>(undefined);
export const ctxContacts = React.createContext<Contact[]>([]);
export const ctxSyncState = React.createContext<ApplicationState>({
  ...applicationStateObservable.getValue(),
});
export const ctxLocations = React.createContext<Location[]>([]);
export const ctxOrg = React.createContext<Organisation | undefined>(undefined);
export const ctxFilters = React.createContext<FiltersContextInterface>(undefined);

export const ctxSpecs = React.createContext<SpecsContextInterface>({
  inspectionSpecs: [],
  setInspectionSpecs: () => {},
});
export const ctxScorings = React.createContext<ScoringContextInterface>({
  scorings: [],
  setScorings: () => {},
});

// ----------------------------------------------------------------
// class App
// ----------------------------------------------------------------
class App extends React.Component<Props, AppState> {
  private onSyncStateChangedSubscription: () => void;
  private onUserAuthStateChangeSubscription: any;
  private timeBeforeLogin: number = Date.now();
  private loggingIn: boolean;

  private userProfileSubscription: any;
  private appStateSubscriptions: { [key in keyof AppState]?: Function } = {};

  constructor(props: Props) {
    super(props);

    // scandit requires an early call to configure
    // for authorizing the device
    // https://docs.scandit.com/stable/web/
    configureScandit(SCANDIT_KEY, {
      engineLocation: '/assets/js/scandit-sdk/',
    }).then((_) => console.log('Scandit SDK successfully loaded.'));

    // prevent the back button from getting out of the app
    // for android devices
    // --------------------------------------------------------------
    // window.addEventListener('load', function() {
    //   window.history.pushState({ noBackExitsApp: true }, '')
    // })
    // window.addEventListener('popstate', function(event) {
    //   if (event.state && event.state.noBackExitsApp) {
    //     window.history.pushState({ noBackExitsApp: true }, '')
    //   }
    // })

    this.state = {
      authSuccess: false,
      fetchDataSuccess: false,
      syncState: { ...applicationStateObservable.getValue() },
      organisation: undefined,
      profile: undefined,
      filters: {},
      users: [],
      contacts: [],
      products: [],
      locations: [],
      inspectionSpecs: [],
      scorings: [],
      inited: false,
      signInMethods: ['password'],
    };
  }

  // ----------------------------------------------------------------
  // Life cycle

  async componentDidMount() {
    // This needs to be set before initializing the service worker, in order to avoid updates
    // when a new user is going through the invite flow
    const isInvitationLink = isSignInWithEmailLink(auth, window.location.href);
    applicationStateObservable.next({
      ...applicationStateObservable.getValue(),
      isInvitationLink,
    });

    initalizeServiceWorker();

    // Watch for Sync State changes
    this.onSyncStateChangedSubscription = applicationStateObservable.subscribe(
      (syncState) => {
        this.setState({ syncState });
      }
    );

    // Set up authentication listener
    this.onUserAuthStateChangeSubscription = onAuthenticationChanged(
      auth,
      async (firebaseUser: firebase.User | null) => {
        if (!firebaseUser || !auth.currentUser) {
          this.onUserNotAuthenticated();
        } else {
          this.timeBeforeLogin = Date.now();
          this.loggingIn = true;

          this.userProfileSubscription = getUserProfileSnapshot(
            firestore,
            firebaseUser.uid,
            (profile) => this.onProfileSnapshot(profile, firebaseUser)
          );
        }
      }
    );

    // if the user is coming with a sign-in link from an email or with a reset password link,
    // we log the current user out in order to prompt the user to the login page
    if (isInvitationLink) {
      auth.signOut();
    }

    const parameters = new URLSearchParams(window.location.search);
    const mode = parameters.get('mode');
    if (mode === 'resetPassword') {
      auth.signOut();
    }
  }

  componentWillUnmount() {
    this.cancelDataSubscriptions();
    this.onSyncStateChangedSubscription();
    this.onUserAuthStateChangeSubscription();
  }

  cancelDataSubscriptions() {
    if (this.appStateSubscriptions) {
      Object.values(this.appStateSubscriptions).forEach((v) => v!! && v());
    }
    if (this.userProfileSubscription) this.userProfileSubscription();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<AppState>,
    snapshot?: any
  ): void {
    if (
      this.state.profile?.allowedLocation !== prevState.profile?.allowedLocation &&
      prevState.profile != null
    ) {
      console.log('Updating location restrictions');
      this.setState({
        filters: getLocationFilter(this.state.profile, this.state.filters),
      });
    }

    for (const key of appContextKeys) {
      if (!isEqual(prevState[key], this.state[key]) && this.state.fetchDataSuccess) {
        console.log(`${key} updated, caching...`);
        this.saveAppStateInCache(key);
      }
    }
  }

  //----------------------------------------------------------
  onProfileSnapshot = async (
    profile: UserProfile | null,
    firebaseUser: firebase.User
  ) => {
    // If we are coming from an invite link, we set a preliminary profile while the full one is being created in the db by the triggers
    if (this.state.syncState.isInvitationLink && firebaseUser != null) {
      profile = {
        id: firebaseUser.uid,
        email: firebaseUser.email,
        organisationId: undefined,
      };
    }

    if (!profile) {
      // It seems like, at times, there's some kind of race condition (probably during logout?) that causes onProfileSnapshot
      // to get called with a stale firebaseUser.uid that's actually not authenticated anymore, which makes fetching the profile fail (example: https://agrinorm.sentry.io/issues/4477983597)
      // Because of that, we'll only display an error message in the presence of a null/undefined profile if the user is authenticated but their profile couldn't be found in the db
      if (!!auth.currentUser) {
        Sentry.captureException(new Error('User profile not found'), (scope) => {
          scope.setExtra('authenticatedFirebaseUser', auth.currentUser);
          scope.setExtra('firebaseUserFromListener', {
            uid: firebaseUser?.uid,
            displayName: firebaseUser?.displayName,
            email: firebaseUser?.email,
          } as firebase.User);
          return scope;
        });
        await presentStandardToast(
          toastController,
          `A problem occurred while trying to log in. Please try again or contact support at support@agrinorm.ai`,
          10000
        );
      }

      this.setState(
        { fetchDataSuccess: false, profile: null, authSuccess: false },
        () => logout(auth)
      );
      return;
    }

    console.log('Authenticated user:', profile.id);

    // If already logged in, on snapshot changes only update the profile in the state and skip the rest
    // We also skip setting up the data subscriptions if we are in the onboarding flow
    if (
      !this.loggingIn ||
      (!profile.organisationId && this.state.syncState.isInvitationLink)
    ) {
      return this.setState({ profile });
    }

    this.loggingIn = false;

    // Miscellaneous app configurations at start-up
    this.preDataFetchSetup(profile);

    // Restore cached entities and stuff them in the state so the user can already start using the app
    await this.restoreCachedAppState(profile);

    // Set up snapshot listeners and populate the state with fresh data from the db in the background
    this.fetchAppDataAndSetupSubscriptions(profile);

    // Asynchronously sync local pictures to the server
    this.syncPictures();

    console.log(
      'Authentication flow took',
      (Date.now() - this.timeBeforeLogin) / 1000,
      'seconds'
    );
  };

  preDataFetchSetup(profile: UserProfile) {
    // Emit login event
    eventLogger.log({ eventType: 'LoginEvent' }, profile);

    // Set user info for Sentry
    Sentry.setUser({
      email: profile.email,
      id: profile.id,
      username: profile.name,
    } as Sentry.User);

    // Change the language
    i18n.changeLanguage(profile.properties?.language ?? 'en');
  }

  async restoreCachedAppState(profile: UserProfile) {
    let newState: AppState = {
      profile,
      inited: true,
    } as AppState;

    for (const key of appContextKeys) {
      try {
        let cache = await get(this.buildAppContextStorageKey(key, profile));

        // If no filters for the user were found, try to fetch the one saved in the
        // indexedDB using the old, non-user specific key format
        // We do this such that the first time the user logs in after this change was deployed,
        // they don't lose their current filter
        // This logic below can be removed after a while (implemented on 2024/03/14).
        if (key === 'filters' && !cache) {
          cache = await get(`${APP_CONTEXT_STORAGE_KEY}_${key}`);
        }

        if (!!cache) {
          newState[key as string] = cache;
        }
      } catch (error) {
        console.log(`Could not retrieve cached ${key}`);
      }
    }

    console.log(
      'Retrieving cached entities took',
      (Date.now() - this.timeBeforeLogin) / 1000,
      'seconds'
    );

    // Set profile + data restored from the cache in the state
    this.setState({ ...newState });
  }

  buildAppContextStorageKey(key: keyof AppState, profile: UserProfile): string {
    // Filters are user specific
    // The rest of the entities are org specific
    if (key === 'filters' && !!profile) {
      key += `_${profile.id}`;
    } else if (!!profile) {
      key += `_${profile.organisationId}`;
    }
    return `${APP_CONTEXT_STORAGE_KEY}_${key}`;
  }

  fetchAppDataAndSetupSubscriptions(profile: UserProfile) {
    if (this.appStateSubscriptions) {
      Object.values(this.appStateSubscriptions).forEach((v) => v!! && v());
    }

    this.appStateSubscriptions.organisation = getOrganisationSnapshot(
      firestore,
      profile.organisationId,
      async (organisation) => {
        if (!organisation) return;
        this.setState({ organisation });
      }
    );

    this.appStateSubscriptions.locations = getLocationsSnapshot(
      firestore,
      profile.organisationId,
      async (locations) => {
        if (!locations) return;
        this.setState({ locations });
      }
    );

    this.appStateSubscriptions.products = getProductViewSnapshot(
      firestore,
      profile.organisationId,
      async (products) => {
        if (!products) return;
        this.setState({ products });
      }
    );

    this.appStateSubscriptions.contacts = getContactsSnapshot(
      firestore,
      profile.organisationId,
      async (contacts) => {
        if (!contacts) return;
        this.setState({ contacts });
      }
    );

    this.appStateSubscriptions.users = getUsersSnapshot(
      firestore,
      profile.organisationId,
      async (users) => {
        if (!users) return;
        this.setState({ users });
      }
    );

    // Load organisation specific schemas and scorings
    getOrganisationSpecs(firestore, profile.organisationId)
      .then(({ inspectionSpecs, scorings }) => {
        console.log(
          `Fetching ${c(inspectionSpecs?.length)} inspections specs and ${c(
            scorings?.length
          )} scorings took`,
          (Date.now() - this.timeBeforeLogin) / 1000,
          'seconds ',
          inspectionSpecs
        );

        this.setState({
          inspectionSpecs,
          scorings,
        });
      })
      .catch((err) => {
        if (this.state.profile) {
          presentStandardToast(
            toastController,
            `${fetchEntityErrorMessage('schemas')}: ${err}`
          );
        }
      });

    this.setState({ fetchDataSuccess: true });
  }

  syncPictures() {
    resetIsUploadingIndexedDB();
    doImageSync(true);
  }

  onUserNotAuthenticated() {
    console.log('Auth changed: no user authenticated');

    // Remove user based subscriptions
    this.cancelDataSubscriptions();

    this.setState({
      profile: null,
      inited: true,
      authSuccess: false,
      fetchDataSuccess: false,
    });

    Sentry.setUser(null);
  }
  //----------------------------------------------------------
  saveAppStateInCache(key: keyof AppState) {
    const entities = _.cloneDeep(this.state[key]);

    try {
      set(this.buildAppContextStorageKey(key, this.state.profile), entities);
    } catch (error) {
      console.log(`Could not cache ${key}`, error);
    }
  }

  setAppFilters(filters: AGFilters) {
    const newFilters = removeSectionSpecificFilterFields(filters);
    this.setState({ filters: newFilters });
  }

  renderApp() {
    return (
      <IonSplitPane contentId="mainRouter" /*when={false}*/>
        <CSideMenu />
        <CMainRoutes />
      </IonSplitPane>
    );
  }

  reload() {
    logout(auth).then((_) => (window.location.href = '/'));
  }

  render() {
    const {
      syncState,
      profile,
      inited,
      organisation,
      products,
      contacts,
      users,
      locations,
      filters,
      inspectionSpecs,
      scorings,
    } = this.state;

    const specsContext: SpecsContextInterface = {
      inspectionSpecs,
      setInspectionSpecs: (inspectionSpecs: InspectionSpec[]) =>
        this.setState({ inspectionSpecs }),
    };
    const scoringContext: ScoringContextInterface = {
      scorings,
      setScorings: (scorings: LotScoringSection[]) => this.setState({ scorings }),
    };
    const filtersContext: FiltersContextInterface = {
      filters,
      setFilters: this.setAppFilters.bind(this),
    };

    let appClassName =
      syncState.online && syncState.networkEnabled ? 'online' : 'offline';
    // let areSignInMethodsLoaded = (profile) ? this.state.signInMethods.length > 0 : true;
    // areSignInMethodsLoaded = true

    return (
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
        <QueryClientProvider client={queryClient}>
          <ctxOrg.Provider value={organisation}>
            <ctxProducts.Provider value={products}>
              <ctxUsers.Provider value={users}>
                <ctxProfile.Provider value={profile}>
                  <ctxContacts.Provider value={contacts}>
                    <ctxSpecs.Provider value={specsContext}>
                      <ctxScorings.Provider value={scoringContext}>
                        <ctxLocations.Provider value={locations}>
                          <ctxFilters.Provider value={filtersContext}>
                            <ctxSyncState.Provider value={syncState}>
                              <IonApp className={appClassName}>
                                <AppStateUI
                                  inited={inited}
                                  profile={this.state.profile}
                                  reload={this.reload.bind(this)}
                                />
                                {inited && (
                                  <ViewResetPasswordModal
                                    signInMethods={this.state.signInMethods}
                                    profile={this.state.profile}
                                  />
                                )}
                                {/* {inited && <Tutorial />} */}
                                {
                                  <IonReactRouter history={history}>
                                    {profile ? (
                                      this.renderApp()
                                    ) : (
                                      <IonRouterOutlet>
                                        <SentryRoute
                                          exact
                                          path="/shared-report/:sharedReportId/:inspectionReference"
                                          render={(props) => (
                                            <PageInspectionView
                                              {...props}
                                              sharedReport={true}
                                              sharedReportId={decodeURIComponent(
                                                props.match.params['sharedReportId']
                                              )}
                                              inspectionReference={decodeURIComponent(
                                                props.match.params[
                                                  'inspectionReference'
                                                ]
                                              )}
                                            />
                                          )}
                                        />
                                        <SentryRoute
                                          exact
                                          path="/shared-report/:sharedReportId"
                                          render={(props) => (
                                            <PageReport
                                              {...props}
                                              sharedReport={true}
                                              id={decodeURIComponent(
                                                props.match.params['sharedReportId']
                                              )}
                                            />
                                          )}
                                        />
                                        <SentryRoute
                                          exact
                                          path="/shared-customer-profile/buyer/:contactId/:name/:id"
                                          render={(props) => (
                                            <PageCustomerProfiling
                                              {...props}
                                              isShared={true}
                                              sharedId={decodeURIComponent(
                                                props.match.params['id']
                                              )}
                                              contactName={decodeURIComponent(
                                                props.match.params['name']
                                              )}
                                              contactId={decodeURIComponent(
                                                props.match.params['contactId']
                                              )}
                                            />
                                          )}
                                        />
                                        <SentryRoute
                                          exact
                                          path="/shared-customer-profile/supplier/:contactId/:name/:id"
                                          render={(props) => (
                                            <PageSupplierProfiling
                                              {...props}
                                              isShared={true}
                                              sharedId={decodeURIComponent(
                                                props.match.params['id']
                                              )}
                                              contactName={decodeURIComponent(
                                                props.match.params['name']
                                              )}
                                              contactId={decodeURIComponent(
                                                props.match.params['contactId']
                                              )}
                                            />
                                          )}
                                        />
                                        <SentryRoute
                                          exact
                                          path="/reset-password"
                                          render={(props) => <PageResetPassword />}
                                        />
                                        <SentryRoute
                                          exact
                                          path="/privacy-policy"
                                          render={() => <PagePrivacyPolicy />}
                                        />
                                        <SentryRoute
                                          render={(props) => (
                                            <PageLogin
                                              profileIsSet={this.state.profile !== null}
                                              authSuccess={this.state.authSuccess}
                                              setAuthSuccess={(authSuccess: boolean) =>
                                                this.setState({ authSuccess })
                                              }
                                            />
                                          )}
                                        />
                                        <SentryRoute
                                          exact
                                          path={`/${SHARED_FIELD_REPORT_BASE_PATH}/:sharedReportId/:fieldInspectionId`}
                                          render={(props) => (
                                            <PageInspectionView
                                              {...props}
                                              sharedFieldReport={true}
                                              sharedReportId={decodeURIComponent(
                                                props.match.params['sharedReportId']
                                              )}
                                              fieldInspectionId={decodeURIComponent(
                                                props.match.params['fieldInspectionId']
                                              )}
                                            />
                                          )}
                                        />
                                        <SentryRoute
                                          exact
                                          path={`/${SHARED_FIELD_REPORT_BASE_PATH}/:sharedReportId`}
                                          render={(props) => (
                                            <PageSharedFieldReport
                                              {...props}
                                              sharedReportId={decodeURIComponent(
                                                props.match.params['sharedReportId']
                                              )}
                                            />
                                          )}
                                        />
                                      </IonRouterOutlet>
                                    )}
                                  </IonReactRouter>
                                }
                              </IonApp>
                            </ctxSyncState.Provider>
                          </ctxFilters.Provider>
                        </ctxLocations.Provider>
                      </ctxScorings.Provider>
                    </ctxSpecs.Provider>
                  </ctxContacts.Provider>
                </ctxProfile.Provider>
              </ctxUsers.Provider>
            </ctxProducts.Provider>
          </ctxOrg.Provider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;

// ----------------------------------------------------------------
// Tutorial
// ----------------------------------------------------------------

const TutorialShell = () => {
  const parameters = new URLSearchParams(window.location.search);

  const [show, setShow] = useState(!!parameters.get('tutorial'));

  // console.log('text', text)

  return (
    !!show && (
      <IonContent className="tutorial-slides">
        <IonSlides pager={true}>
          <IonSlide>
            <h1 onClick={(_) => setShow(false)}>Slide 1</h1>
          </IonSlide>
          <IonSlide>
            {/* <h1 onClick={_ => setText([0, 1, 2, 3, 4, 5])}>Slide 2 <br /> {text}</h1> */}
          </IonSlide>
          <IonSlide>
            <h1>Slide 3</h1>
          </IonSlide>
        </IonSlides>
      </IonContent>
    )
  );
};
const Tutorial = React.memo(TutorialShell);

// ----------------------------------------------------------------
// AppStateUI
// ----------------------------------------------------------------

const AppStateUI = ({ profile, inited, reload }) => {
  // const prev = usePrevious(syncState)
  const prevInited = usePrevious(inited);
  const syncState = useContext(ctxSyncState);

  // if the app was previously inited and now it is not,
  // that means we are logging out
  if (!inited && prevInited) {
    return null;
  }

  // this.onSyncStateChangedSubscription = applicationStateBehaviour.subscribe(syncState => {
  //   this.setState({ syncState: syncState });
  // });

  // const showUploadingMessage = async () => {
  //   if (!prev?.syncing && syncState.syncing) {
  //     const toast = await toastController.create({
  //       message: "Data is being uploaded to the cloud. Don't close the app",
  //       duration: 3000,
  //       position: 'top',
  //       color: 'dark',
  //       buttons: [{
  //         text: 'Got it',
  //         role: 'cancel'
  //       }]
  //     });
  //     toast.present().then();
  //   }
  // }
  // showUploadingMessage()

  // const showFinishUploadingMessage = async () => {
  //   if (prev?.syncing && !syncState.syncing) {
  //     const toast = await toastController.create({
  //       message: 'Data has finished uploading successfuly',
  //       position: 'top',
  //       color: 'dark',
  //       duration: 3000,
  //       buttons: [{
  //         text: 'Got it',
  //         role: 'cancel'
  //       }]
  //     });
  //     toast.present().then();
  //   }
  // }
  // showFinishUploadingMessage()

  return (
    <>
      {(!syncState.online || !syncState.networkEnabled) && (
        <div id="app-offline">
          {syncState.dirty && !syncState.syncing ? (
            <>
              <IonIcon icon={cloudUploadOutline}></IonIcon>
              <span>go online to upload data</span>
            </>
          ) : (
            <>
              <IonIcon icon={cloudOffline}></IonIcon>
              <span>offline mode {profile?.email}</span>
            </>
          )}
        </div>
      )}

      <IonFab
        vertical="bottom"
        horizontal="start"
        slot="fixed"
        className="sync-fab loading-wrapper animated fadeIn"
        style={{ display: syncState.syncing ? 'block' : 'none' }}
      >
        <IonIcon icon={reloadCircle} className="rotate" color="dark" />
      </IonFab>

      {
        syncState.newVersionAvailable && (
          <IonLoading
            isOpen={true}
            message={'New Version Detected. Installing'}
            cssClass="main-loading-indicator-version"
          />
        )
        // : !inited && <>
        //   <IonLoading
        //     isOpen={true}
        //     mode="md"
        //     cssClass="main-loading-indicator"
        //   />
        //   <div className="main-reload-button">
        //     <IonButton onClick={reload} color="dark">
        //       reload
        //     </IonButton>
        //   </div>
        // </>
      }
    </>
  );
};
