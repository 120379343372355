import {
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonRouterLink,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { chevronDown, chevronForwardOutline, exitOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { ctxLocations, ctxOrg, ctxProfile, ctxScorings, ctxSpecs } from '../App';
import { findInspectionInEntity } from '../DataInspection';
import {
  compileExternalLotInspectionProperties,
  findScoringForSpec,
  getApplicableLotOrTransitSpecs,
  initDefaultValues,
  newInspectionForLot,
  newInspectionForOrder,
  prepareInspectionContext,
  removeIncompatibleInputs,
} from '../InspectionLogic';
import {
  InspectionContext,
  InspectionPropertiesMap,
  LegacyInspectionReference,
  LegacyInspection,
  Inspection,
} from '../InspectionModel';
import { Lot, Order, OrderAGStatus } from '../Model';
import { InspectionSpec, LotScoringSection } from '../ModelSpecification';
import { ViewLotProperties } from '../PageInspectionView';
import { userHasPermission } from '../PermissionsService';
import { InspectionClass } from '../ServiceInspection';
import eventLogger from '../events/common';
import { createModifyLocalInspectionEvent } from '../events/inspection';
import {
  InspectionType,
  LotInspectionSpec,
  LotSchemaObjectTypeEnum,
} from '../generated/openapi/core';
import './CLotInspectionHeader.scss';
import { useInspectionContext } from '../context/InspectionContextProvider';
import { CSchemaSelector } from './CSchemaSelector';
import { CInspectionTypeSelector } from './CInspectionTypeSelector';

interface InspectionHeaderProps {
  inspectionReference: LegacyInspectionReference;
  history: any;
  orgId: string;
  boxesExpected?: number;
  qcStatus?: OrderAGStatus;
  setInspectionReference: (ref: LegacyInspectionReference) => any;
  lot: Lot;
  order: Order;
}

const CLotInspectionHeader = (props: InspectionHeaderProps) => {
  const { inspectionReference, boxesExpected, setInspectionReference, lot, order } =
    props;
  const externalProperties: InspectionPropertiesMap =
    compileExternalLotInspectionProperties(boxesExpected, lot);

  const {
    inspection,
    spec: schema,
    setInspection,
    inited,
    setContext,
    setScoring,
    retrieveBackupInspection,
    setSpec: setSchema,
    applicableSpecs: applicableSchemas,
    setApplicableSpecs: setApplicableSchemas,
    isEditable,
  } = useInspectionContext();

  const profile = useContext(ctxProfile);
  const locations = useContext(ctxLocations);
  const myOrg = useContext(ctxOrg);
  const { inspectionSpecs } = useContext(ctxSpecs);
  const { scorings } = useContext(ctxScorings);
  const isCompleted = new InspectionClass(inspection).isCompleted();

  // State
  const [showAllSchemas, setShowAllSchemas] = useState<boolean>(false);
  const [showBatchInfo, setShowBatchInfo] = useState<boolean>(true);

  // Schemas
  let selectableSchemas =
    !!schema && !(applicableSchemas ?? []).find((s) => s.id === schema.id)
      ? [...applicableSchemas, schema]
      : applicableSchemas;
  if (showAllSchemas) {
    selectableSchemas = inspectionSpecs;
  }

  //-------------------------------------------------------------------------------------------
  // Functions
  const updateInspectionType = async (evt: CustomEvent) => {
    // @ts-ignore
    if (!evt.currentTarget?.classList.contains('select-expanded')) {
      // we only trigger the onChange when the select is expanded
      return;
    }

    const locationId = inspection?.locationId;

    let { value } = evt.detail;

    // Inform the user if they are trying to conduct an incoming inspection but the batch already has one
    if (
      (value as InspectionType) === InspectionType.Incoming &&
      !!lot?.inspections?.find((i) => i.reference.type === InspectionType.Incoming) &&
      !window.confirm(
        `An incoming inspection has already been conducted for batch ${lot?.id}. Are you sure you want to continue?`
      )
    ) {
      value = inspectionReference.type;
    }

    const newReference: LegacyInspectionReference = {
      ...inspectionReference,
      type: value,
    };

    const backupInspection = (await retrieveBackupInspection(
      newReference
    )) as LegacyInspection;

    let schema: InspectionSpec;
    let applicableSchemas: InspectionSpec[];
    let newInspection: LegacyInspection;
    let context: InspectionContext;
    let scoring: LotScoringSection;

    if (!!newReference.lotId?.length) {
      newInspection =
        backupInspection ?? findInspectionInEntity(newReference, undefined, lot);
      ({ spec: schema, applicableSpecs: applicableSchemas } =
        getApplicableLotOrTransitSpecs(
          newReference,
          inspectionSpecs,
          lot.article,
          newInspection
        ));
      scoring =
        schema?.objectType === LotSchemaObjectTypeEnum.Lot
          ? findScoringForSpec(schema, scorings)
          : undefined;
      if (!!schema) {
        if (!newInspection) {
          const emptyInspection = newInspectionForLot(
            newReference,
            lot,
            schema,
            scoring
          );
          const emptyContext = prepareInspectionContext(
            schema,
            emptyInspection,
            externalProperties
          );
          ({ inspection: newInspection, context } = initDefaultValues(
            schema,
            emptyInspection,
            emptyContext,
            scoring,
            myOrg?.settings
          ));
        } else {
          removeIncompatibleInputs(newInspection, schema, scoring);
          context = prepareInspectionContext(schema, newInspection, externalProperties);
        }
      }
    } else if (!!newReference.transportId?.length && !!newReference.orderId?.length) {
      newInspection = findInspectionInEntity(newReference, order);
      ({ spec: schema, applicableSpecs: applicableSchemas } =
        getApplicableLotOrTransitSpecs(
          newReference,
          inspectionSpecs,
          undefined,
          newInspection
        ));
      scoring =
        schema?.objectType === LotSchemaObjectTypeEnum.Lot
          ? findScoringForSpec(schema, scorings)
          : undefined;
      if (!!schema) {
        if (!newInspection) {
          ({ spec: schema, applicableSpecs: applicableSchemas } =
            getApplicableLotOrTransitSpecs(newReference, inspectionSpecs, lot.article));
          const emptyInspection = newInspectionForOrder(newReference, order);
          const emptyContext = prepareInspectionContext(
            schema,
            emptyInspection,
            externalProperties
          );
          ({ inspection: newInspection, context } = initDefaultValues(
            schema,
            emptyInspection,
            emptyContext,
            scoring,
            myOrg?.settings
          ));
        } else {
          context = prepareInspectionContext(schema, newInspection, externalProperties);
        }
      }
    }

    console.log('SCHEMA SET', schema);

    if (locationId !== undefined) {
      newInspection = newInspection != null ? newInspection : ({} as LegacyInspection);
      newInspection.locationId = locationId;
    }

    setInspectionReference(newReference);
    setInspection(newInspection);
    setContext(context);
    setScoring(scoring);
    setApplicableSchemas(applicableSchemas);
    setSchema(schema);
  };

  //-------------------------------------------------------------------------------------------
  const updateInspectionLocation = (evt: CustomEvent) => {
    // @ts-ignore
    if (!evt.currentTarget?.classList.contains('select-expanded')) {
      // we only trigger the onChange when the select is expanded
      return;
    }

    const { value: locationId } = evt.detail;

    setInspection((prevInspection) => {
      const newInspection = { ...prevInspection, locationId };
      eventLogger.log(
        createModifyLocalInspectionEvent(newInspection, { locationId }),
        profile
      );
      return newInspection;
    });
  };

  //-------------------------------------------------------------------------------------------
  const onSchemaChange = (schemaId: string, oldInspection: Inspection) => {
    const schema = inspectionSpecs.find((s) => s.id === schemaId);
    const scoring = scorings.find(
      (s) => s.id === (schema as LotInspectionSpec)?.lotScoring
    );

    const pictures = oldInspection?.pictures ?? [];
    const status = oldInspection?.status;
    const locationId = oldInspection?.locationId;

    // If the schema selected is different than the schema in the inspection, we wipe out the user inputs (the pictures we keep though!)
    if (schema?.id !== oldInspection?.schemaId && !!schema?.id) {
      let inspection = findInspectionInEntity(inspectionReference, order, lot);
      if (!inspection) {
        if (!!inspectionReference.lotId?.length) {
          inspection = newInspectionForLot(inspectionReference, lot, schema, scoring);
        } else if (
          !!inspectionReference.transportId?.length &&
          !!inspectionReference.orderId?.length
        ) {
          inspection = newInspectionForOrder(inspectionReference, order);
        }
        // console.log("SCHEMA CHNGE", inspection, inspectionReference)
      } else {
        removeIncompatibleInputs(inspection, schema, scoring);
      }
      let context = prepareInspectionContext(schema, inspection, externalProperties);
      ({ context, inspection } = initDefaultValues(
        schema,
        inspection,
        context,
        scoring,
        myOrg?.settings
      ));

      setContext(context);
      setInspection({
        ...inspection,
        pictures,
        status,
        locationId,
        schemaId: schema.id,
        schemaVersion: `${schema.version}`,
      });
      setSchema(schema);
      setScoring(scoring);

      console.log('SCHEMA SET', schema);
    }
  };

  // ==================================================================================================
  // render
  // ==================================================================================================

  // Permissions and "show" flags
  const disableAssessmentTypeSelect =
    isCompleted ||
    !userHasPermission(profile, 'WRITE', 'ASSESSMENT') ||
    !!inspectionReference.orderId;

  const disableLocationSelect =
    isCompleted || !userHasPermission(profile, 'WRITE', 'ASSESSMENT') || !inspection;

  const disableSchemaSelect =
    isCompleted ||
    !userHasPermission(profile, 'WRITE', 'ASSESSMENT') ||
    !inspectionReference.type;

  const { externalInstructionsUrl } = lot?.transient ?? {};

  const showLotPropertiesSection =
    inspection?.objectType === LotSchemaObjectTypeEnum.Lot &&
    !!(
      inspection?.lotProperties?.ggnList?.length ||
      inspection?.lotProperties?.glnList?.length ||
      inspection?.lotProperties?.palletIds?.length
    );

  return (
    <div className="c-inspection-header">
      <CInspectionTypeSelector
        inspectionReference={inspectionReference}
        disableAssessmentTypeSelect={disableAssessmentTypeSelect}
        updateInspectionType={updateInspectionType}
      />

      {/* LOCATION SELECTION */}
      <div className="ion-item">
        <div className="ion-label" color="dark">
          Location
        </div>
        <IonSelect
          interfaceOptions={{ cssClass: 'schema-selector' }}
          data-tip={'location-selector'}
          placeholder={!inited ? 'Loading...' : 'Select'}
          className="inspection-header-selector"
          interface="action-sheet"
          value={inspection?.locationId}
          onIonChange={updateInspectionLocation}
          disabled={disableLocationSelect}
        >
          {(locations ?? []).map((l, i) => (
            <IonSelectOption key={l.locationId + i} value={l.locationId}>
              {l.name ?? l.locationId}
            </IonSelectOption>
          ))}

          {inspection?.locationId != null &&
            (locations ?? []).find((l) => l.locationId === inspection?.locationId) ==
              null && (
              <IonSelectOption value={inspection?.locationId}>
                {inspection?.locationId}
              </IonSelectOption>
            )}

          <IonSelectOption
            key={'use-gps'}
            className="show-all-schemas"
            value={null}
            onSelect={() => {}}
          >
            - Use my GPS location -
          </IonSelectOption>
        </IonSelect>
      </div>

      {/* SCHEMA SELECTION */}
      <CSchemaSelector
        selectableSchemas={selectableSchemas}
        setShowAllSchemas={setShowAllSchemas}
        onSchemaChange={onSchemaChange}
        disableSchemaSelect={disableSchemaSelect}
        showAllSchemas={showAllSchemas}
      />

      {(showLotPropertiesSection || !!externalInstructionsUrl) && (
        <div className="view-assessment">
          <div>
            <IonListHeader
              className="toggle-section"
              onClick={(_) => setShowBatchInfo(!showBatchInfo)}
            >
              <IonLabel>
                <IonIcon icon={showBatchInfo ? chevronDown : chevronForwardOutline} />
                <h1>Batch info</h1>
              </IonLabel>
            </IonListHeader>
            {showBatchInfo && (
              <>
                {showLotPropertiesSection && (
                  <div className="top-info" style={{ padding: '10px' }}>
                    <ViewLotProperties
                      lotProperties={inspection?.lotProperties}
                      setInspection={setInspection}
                      isEditable={isEditable}
                    />
                  </div>
                )}
                {!!externalInstructionsUrl && (
                  <IonRouterLink
                    href={externalInstructionsUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IonItem button color="light-medium">
                      <b>External instructions</b>&nbsp;
                      <IonIcon icon={exitOutline} />
                    </IonItem>
                  </IonRouterLink>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* {!applicableSchemas.length && <div className="loading">
    {applicationContext?.schemas?.length > 0
      ? `No applicable schemas found for ${assessment?.article?.agProductId}`
      : <IonSpinner name="dots" />}</div>} */}

      {inited && !!inspectionReference?.type && !schema && (
        <h1 className="h1-not-found">⬆️ select a Schema</h1>
      )}
      {inited && !inspectionReference?.type && (
        <h1 className="h1-not-found">⬆️ select an inspection type</h1>
      )}
    </div>
  );
};

export default CLotInspectionHeader;
