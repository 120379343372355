import React, { useContext, useEffect, useState } from 'react';
import { UserProfile } from './Model';
import Select from 'react-select';
import { ctxProducts as productsFromContext } from './App';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import { saveUserSubscriptions } from './DataStorage';
import { firestore } from './ConfigFirebase';
import './ViewSubscriptionSelector.scss';
import { findIndex } from 'lodash';
import {
  OderStatusSubscriptionOptions,
  OrderStatusSubscription,
} from './ModelSubscriptions';

interface Props {
  profile: UserProfile;
}

// ToDo: Check why so many re-renders in first load of component. Prop might be the issue.
const ViewSubscriptionSelector = ({ profile }: Props) => {
  let availableProducts = useContext(productsFromContext);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [options, setOptions] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const mapOderStatusSubscriptionOptionsToState = (
    OderStatusSubscriptionOptions: OrderStatusSubscription[]
  ) => {
    let checkboxState = [];
    OderStatusSubscriptionOptions.forEach((option) => {
      checkboxState.push({
        checked: false,
        label: option.checkBoxLabel,
        value: option.value,
      });
    });
    return checkboxState;
  };

  const [checkBoxes, setCheckBoxes] = useState(
    mapOderStatusSubscriptionOptionsToState(OderStatusSubscriptionOptions)
  );

  if (profile.products) {
    // TODO: We are assuming here that profile.products is always an array of productIds (not agProductIds)
    availableProducts = availableProducts.filter(
      (p) => profile.products.indexOf(p.productId) >= 0
    );
  }

  useEffect(() => {
    setOptions(
      availableProducts
        ?.sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
        .map((b) => {
          return {
            value: b.productId,
            label: b.agProductId ?? b.productId,
          };
        })
    );
  }, [availableProducts]);

  useEffect(() => {
    if (profile.productSubscriptions) {
      let subscriptions = profile.productSubscriptions.map((productId) => {
        let productLabel = availableProducts?.find((product) => {
          return product.productId === productId;
        });
        return {
          value: productId,
          label: productLabel?.agProductId,
        };
      });
      setSelectedProducts(subscriptions);
    }
    if (profile?.statusSubscriptions) {
      const statusArray = profile?.statusSubscriptions || [];

      const checkboxState = [...checkBoxes];
      checkboxState.forEach((cb) => {
        if (findIndex(statusArray, (status) => status === cb.value) !== -1) {
          cb.checked = true;
        }
      });
      setCheckBoxes(checkboxState);
    }
    // eslint-disable-next-line
  }, [profile, availableProducts]);

  const selectChangeHandler = (event) => {
    setSelectedProducts(() => event);
    setIsSaveDisabled(false);
  };

  const checkBoxHandler = (event, status) => {
    const checkboxState = [...checkBoxes];
    checkboxState.forEach((cb) => {
      if (cb.value === status) {
        cb.checked = event.target.checked;
      }
    });
    setCheckBoxes(checkboxState);

    setIsSaveDisabled(false);
  };

  const saveSubscriptionsHandler = () => {
    let subscriptions = selectedProducts?.map((product) => product.value) || [];
    let subscriptionsStatus = createSubscriptionStatusArray();
    saveUserSubscriptions(firestore, profile, subscriptions, subscriptionsStatus)
      .then(() => {
        setIsSaveDisabled(true);
      })
      .catch((error) => console.log(error));
  };

  const createSubscriptionStatusArray = (): string[] => {
    let statusArray = [];
    checkBoxes.forEach((cb) => {
      if (cb.checked) statusArray.push(cb.value);
    });

    return statusArray;
  };

  return (
    <IonGrid className="view-subscription-selector">
      <IonRow>
        <IonCol>
          <div>
            <Select
              isMulti
              classNamePrefix={'react-select'}
              value={selectedProducts}
              options={options}
              isClearable={true}
              onChange={selectChangeHandler}
            />
          </div>
          <div style={{ textAlign: 'right', marginTop: 5 }}>
            <IonButton
              color="primary"
              onClick={saveSubscriptionsHandler}
              disabled={isSaveDisabled}
              size="default"
            >
              Save Subscriptions
            </IonButton>
          </div>
          <div>
            <IonLabel>
              <b>Notify me when:</b>
            </IonLabel>
            <IonList className="subscription-status">
              {checkBoxes.map((cb) => {
                return (
                  <IonItem key={cb.value}>
                    <IonCheckbox
                      checked={cb.checked}
                      className="subscription-status-checkbox"
                      slot="start"
                      color="primary"
                      onIonChange={(e) => checkBoxHandler(e, cb.value)}
                    />
                    <IonLabel className="subscription-status-label" slot="start">
                      {cb.label}
                    </IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ViewSubscriptionSelector;
