import React from 'react';
import { Report } from './Model';
import { CSharedHistory } from './components/CSharedHistory';

interface Props {
  report: Report;
  onCancel: () => void;
}

interface State {
  inited: true;
}

class PageSharedHistoryOrder extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      inited: true,
    };
  }

  render() {
    // The report name is optional (user set)
    // The report id should always have the format orgId;orderId;timestamp, so if 
    // the reportName is missing, we use the orderId instead.
    const name =
      this.props.report.reportName ??
      (this.props.report.id.indexOf(';') > 0
        ? this.props.report.id.split(';')[1]
        : this.props.report.id);

    return (
      <CSharedHistory
        onCancel={this.props.onCancel}
        name={name}
        sharedTo={this.props.report.sharedTo}
        buildLink={(id) => `${window.location.origin}/shared-report/${id}`}
      />
    );
  }
}

export default PageSharedHistoryOrder;
