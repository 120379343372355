import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { ProductionSiteInspection, SharedFieldReport } from '../../InspectionModel';
import { ProductionSiteLocation } from '../../Model';
import { LocationDetailsHeader } from '../../components/location/LocationDetailsHeader/LocationDetailsHeader';
import { LocationInspections } from '../../components/location/LocationInspections/LocationInspections';
import { LocationProperties } from '../../components/location/LocationProperties/LocationProperties';

interface LDProps {
  sharedFieldReport: SharedFieldReport;
  associatedPartnerName?: string;
  inspections: ProductionSiteInspection[];
  isLoadingInspections: boolean;
}
export const SharedFieldReportContainer = ({
  sharedFieldReport,
  inspections,
  associatedPartnerName,
  isLoadingInspections,
}: LDProps) => {
  const { name, geoPoint } = sharedFieldReport;

  return (
    <IonPage className="page-lot">
      <LocationDetailsHeader name={name} geopoint={geoPoint} />
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <div>{name}</div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <LocationProperties
          field={sharedFieldReport}
          associatedPartnerName={associatedPartnerName}
        />

        <LocationInspections
          field={sharedFieldReport}
          inspections={inspections}
          isLoadingInspections={isLoadingInspections}
        />
      </IonContent>
    </IonPage>
  );
};
