import React from 'react';
import { IonLabel } from '@ionic/react';
import './ViewAGTable.scss';
import { articleParamMap } from './Model';

interface Props {
  tableName: string;
  headers: any[];
  data: any[];
}

const ViewAGTable = (props: Props) => {
  return (
    <div className={'agTable'}>
      <IonLabel>{props.tableName}</IonLabel>

      <table>
        <thead>{renderHeaders(props.headers)}</thead>
        <tbody>{renderRows(props.headers, props.data)}</tbody>
      </table>
    </div>
  );
};

const renderHeaders = (headers) => {
  let renderArray = [];

  for (let i = 0; i < headers.length; i++) {
    const mapArticleParam = (param: string) => {
      param = param === 'packaging' ? 'extPackagingRepr' : param;
      return articleParamMap[param] ?? param;
    };

    headers.forEach((element) => {
      if (element.index === i) {
        renderArray.push(
          <th className={'cell'} key={element.index}>
            {/* {element.name[0].toUpperCase() + element.name.substring(1)} */}
            {mapArticleParam(element.name)}
          </th>
        );
      }
    });
  }

  return <tr id="AGTableHeader">{renderArray}</tr>;
};

const renderRows = (headers, rowData) => {
  let renderArray2 = [];
  rowData.forEach((row, index) => {
    if (!!row) {
      let renderArray = [];
      for (let i = 0; i < headers.length; i++) {
        headers.forEach((element) => {
          if (element.index === i) {
            renderArray.push(
              <td className={'cell'} key={`${element.name}${element.index}`}>
                {row[element.name]}
              </td>
            );
          }
        });
      }
      renderArray2.push(
        <tr key={`${row.packaging}${row.agVariety}${index}`}>{renderArray}</tr>
      );
    }
  });

  return <>{renderArray2}</>;
};

export default ViewAGTable;
