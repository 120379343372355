import {
  ADLSScoringParams,
  QuestionSeverity,
  ADPScoringParams,
  Inspection,
} from './InspectionModel';
import { cloneDeep } from 'lodash';
import { InspectionSpec } from './ModelSpecification';

export const severitySpace: QuestionSeverity[] = ['critical', 'major', 'minor'];

/*******************
 * ADLS (e.g: specialfruit)
 ********************/

export class ADLSBatchLevelScorer {
  /**
   * In an ADLS scoring, the groupScoring stores for every scorable group, a mapping between all possible
   * combinations of group questions scores and the respective group score.
   */

  schema: InspectionSpec;
  scoringParams: ADLSScoringParams;
  scoringGroupId: string;

  private showLogs: boolean = false;

  constructor(
    schema: InspectionSpec,
    scoringParams: ADLSScoringParams,
    scoringGroupId: string
  ) {
    this.schema = schema;
    this.scoringParams = scoringParams;
    this.scoringGroupId = scoringGroupId;
  }

  getGroupScore(i: Inspection) {
    const inspection = cloneDeep(i);
    const specs = this.schema.questionSpecs;
    const { rankedScoreSpace, defaultScore, groupScoreMap } = this.scoringParams;

    const scoreSeverityList = Object.entries(specs)
      .filter(([qId, _]) => specs[qId]?.inspectionScoring?.[this.scoringGroupId])
      .map(([qId, _]) => ({
        score: inspection.userInputs?.[qId]?.agScore ?? defaultScore,
        severity: specs[qId].inspectionScoring[this.scoringGroupId].scoreGroupId,
      }));
    const vectorArray = [];

    severitySpace.forEach((severity) => {
      rankedScoreSpace.forEach((score) => {
        const countArray = scoreSeverityList.filter(
          (row) => row.score === score && row.severity === severity
        );
        vectorArray.push(countArray.length);
      });
    });

    // console.log("SCORING", this.scoringGroupId, "severitySpace", severitySpace, "scoreSeverityList", scoreSeverityList)

    const scoreVector = vectorArray.join('');

    const groupScore = groupScoreMap[scoreVector] ?? defaultScore;

    this.showLogs &&
      console.log(
        'groupId',
        this.scoringGroupId,
        'scoreVector',
        scoreVector,
        'score',
        groupScore
      );

    return groupScore.toString();
  }
}

/*******************
 * ADP (e.g: berryworld)
 ********************/

export class ADPBatchLevelScorer {
  /**
   * In an ADP scoring [insert description here]
   */
  schema: InspectionSpec;
  scoringParams: ADPScoringParams;
  scoringGroupId: string;

  private showLogs: boolean = false;

  constructor(
    schema: InspectionSpec,
    scoringParams: ADPScoringParams,
    scoringGroupId: string
  ) {
    this.schema = schema;
    this.scoringParams = scoringParams;
    this.scoringGroupId = scoringGroupId;
  }

  getGroupScore(i: Inspection) {
    const inspection = cloneDeep(i);
    this.showLogs && console.log('Begin execution of ADPLotLevelScorer.getGroupScore');

    // prepare an object to store the sums of percentages based on the scoreable groups (e.g. "critical" and "major")
    const groupSum: any = {};
    this.scoringParams.questionGroups.forEach((g) => (groupSum[g] = 0));

    // loop over the scored assessmentVM and compute the sums
    for (const questionId in inspection.userInputs) {
      const currScoredQuestion = inspection.userInputs[questionId];

      // skip if no values found for the current question
      if (!currScoredQuestion.measurableInputs) {
        continue;
      }

      const specs = this.schema.questionSpecs?.[questionId];
      const groupScoringParams = specs?.inspectionScoring?.[this.scoringGroupId];

      // skip if no batch scoring params found for the current group in the current question specs
      if (!groupScoringParams) {
        continue;
      }

      // extract scoring params
      const { scoreGroupId, measurableId } = groupScoringParams;

      // skip if value is not numeric or if the question group is not part of the scoring params
      const value = currScoredQuestion.measurableInputs?.find(
        (m) => m.measurableId === measurableId
      )?.measurementInput?.value;

      const isNaN = value == null || typeof value !== 'number';
      if (isNaN || !this.scoringParams.questionGroups.includes(scoreGroupId)) {
        this.showLogs &&
          isNaN &&
          console.warn(`ADPLotLevelScorer: ${value} is not numeric!`);
        continue;
      }

      // we add it to the sum
      groupSum[scoreGroupId] += value;
    }

    this.showLogs && console.log('ADPLotLevelScorer Group Sum', groupSum);

    // After the sums are computed, calculate the actual score according to the rules/thresholds set in the categoriesToScores field
    // For that we rely on the ranked categories space, which should be already sorted from worst to best
    for (const score of this.scoringParams.rankedCategoriesSpace) {
      const computable = this.scoringParams.categoriesToScores?.[score];

      if (computable == null) {
        this.showLogs && console.log(`No computable found for score ${score}`);
        continue;
      }

      this.showLogs && console.log(`Evaluating for score ${score}`, computable);
      const applies = new Evaluator(computable, groupSum, this.showLogs).evaluate();
      if (applies) {
        this.showLogs && console.log(`Score ${score} fulfills the rules`);
        return score;
      }
    }

    this.showLogs &&
      console.warn(
        `ADPLotLevelScorer: No matching rule found, returning default score "${this.scoringParams.defaultScore}"`
      );
    return this.scoringParams.defaultScore;
  }
}

interface MinMax {
  min: number;
  max: number;
}

class Evaluator {
  computable: Computable;
  variables: { [key: string]: number };

  private showLogs: boolean;

  constructor(
    evalExpression: Computable,
    variables: { [key: string]: number },
    showLogs: boolean = false
  ) {
    this.computable = evalExpression;
    this.variables = variables;
    this.showLogs = showLogs;
  }

  evaluate(): number | boolean {
    const { term1Type, term2Type, term1, term2 } = this.computable;
    const evalTerms: { [key: string]: boolean | number | MinMax } = {
      term1: undefined,
      term2: undefined,
      range: undefined,
    };

    const zippedTerms: [string, any, ComputableType][] = [
      ['term1', term1, term1Type],
      ['term2', term2, term2Type],
    ];

    for (const [key, term, type] of zippedTerms) {
      try {
        switch (type) {
          case 'EVAL':
            evalTerms[key] = new Evaluator(
              term,
              this.variables,
              this.showLogs
            ).evaluate();
            break;
          case 'SCALAR':
            const num = parseFloat(term);
            if (isNaN(num)) {
              throw new Error();
            }
            evalTerms[key] = num;
            break;
          case 'RANGE':
            if (term?.min == null) {
              throw new Error();
            }
            // when the term is a range, we store it here so we know how to look for it in this.compute
            evalTerms.range = term;
            break;
          case 'VAR':
            this.showLogs && console.log(`VAR: ${term}`);
            const variable = this.variables[term];
            if (variable == null) {
              throw new Error();
            }
            evalTerms[key] = variable;
            break;
          default:
            this.throwError(`Type ${type} not recognized`);
        }
      } catch (e) {
        this.throwError(`Term "${term}" is not of type ${type}`);
      }
    }

    return this.compute(
      evalTerms.term1 as number | boolean,
      evalTerms.term2 as number | boolean,
      evalTerms.range as MinMax
    );
  }

  private compute(a: number | boolean, b: number | boolean, range?: MinMax) {
    let result: any;
    switch (this.computable.op) {
      case 'AND':
        this.checkTypes([a, b], 'boolean');
        result = a && b;
        break;
      case 'OR':
        this.checkTypes([a, b], 'boolean');
        result = a || b;
        break;
      case 'PLUS':
        this.checkTypes([a, b], 'number');
        result = (a as number) + (b as number);
        break;
      case 'MULT':
        this.checkTypes([a, b], 'number');
        result = (a as number) * (b as number);
        break;
      case 'IN-BETWEEN':
        this.checkTypes([a, b, range], 'range');
        const term = (a ?? b) as number; // only one of them was populated (see case 'RANGE' in this.evaluate)
        // TODO: how to proceed with the <= / <??
        result = range.min <= term && term < range.max;
        break;
    }
    this.showLogs &&
      console.log(
        `OPERATION: ${a ?? b} / ${this.computable.op} / ${
          !!range ? JSON.stringify(range) : b ?? a
        } -> ${result}`
      );
    return result;
  }

  private checkTypes(
    values: (number | boolean | MinMax)[],
    checkType: 'boolean' | 'number' | 'range'
  ) {
    if (checkType === 'range') {
      const num = values[0] ?? values[1]; // one of both must be a number, the other should be null
      if (typeof num !== 'number' || (values[2] as MinMax)?.min == null) {
        // the 3rd value is always the range
        this.throwError(
          `operation of type "${this.computable.op}" requires a number and a range`
        );
      }
    } else if (values.reduce((a, b) => a || typeof b !== checkType, false)) {
      this.throwError(
        `both values must be of type "${checkType}" to perform a "${this.computable.op}" operation`
      );
    }
  }

  private throwError(error: string) {
    console.error('Error in Evaluator:', error);
    return null;
  }
}

export interface Computable {
  term1Type: ComputableType;
  term1: any;
  op: ComputableOp;
  term2Type: ComputableType;
  term2: any;
}

type ComputableType = 'EVAL' | 'SCALAR' | 'VAR' | 'RANGE';
type ComputableOp = 'PLUS' | 'MULT' | 'OR' | 'AND' | 'IN-BETWEEN';
