// localhost and agrinorm-connect-emiliano
let SCANDIT_KEY =
  'ASHgqJiBDGz5BbkR5RSxe94Ezm55CNkNLHhtFao2LYVvcds/7UASs1Vf1f3iA+0g5WObgx1Oo5sUb/JfXW6liqZgzqhqIQUYAEBmS1UwawnEYU9BckAIMgdrHiefYu5//HWcC9BhCc5Mdup8rB4ydJNtKND0W75qWlNj4H94vpylOAVd7zF/oYwZP0tbLBsX/KfHeq8U65jq00F/WcZPItX/JCrQ8puZjesQb/dQwvF365EqICKVZ2Z3ZI7MQQAZvxWHFzENKheVqH2arDrN3jJ8Hfi6+2+WZlEl8EOxs5Q6MdtXL3a9OxEwkFuIeolxjIBs3mDIuDamBouO6zr0O8weAplEnPag1m4gO3UumKK4IReNFOGABkvzdpfG0G7YeV8hbKdZ8Sj+JBrHVLcXJOUxV7gXhqc/HlMlUydiMra0uz614UcjOxGyyYWtrEuZ9HK2ujiLWpwR0mePbYCabU3bPPDdOEYXHYQypFKs23+cy6vNMkazQATAlYvuWvocKCbQEavYfp2+edEGGTVmplisyvVEmKNSUZ/qnYt6xk9DlSn66UqLr/qSER0qCi81NJG3VpMRSunACaxSR/PKNEX7Bgvxmc5WvJYNwQmxvUiLD1R/C8t/6xXf9shLgh/xiHkHX9BE0Sqsg4XPJPbCZ0i/uhkD69SNYAN7aYnkajA1lNf/Ds5kqS0/mX30rMksK5T6f2sxLnjq2bC2OXY2reKmwv4Ai+vPvvvfwvj1c715WrzbmfSa0+/czKqZ5BUPbp1qsSXy20SFYsbsIzHCVuXzQd8d/XWSDmQohz57+7Zm9HnaPs9FTWVKNYdUz/atvKeDCye1gLStJar9wT3vNN2euOHjX5k4l1qfIBn61nY=';

switch (window.location.hostname) {
  case 'agrinorm-connect.web.app':
  case 'app.agrinorm.ai':
    SCANDIT_KEY =
      'ARjRRp2BCYsaK+oT/C4YK3gFmGl+Hf6lQ3syTZUYosdJaS3F716w8fto20vvY+K5lTPfZ34S4CS7bt3clXU5HgFh9R1ydz0PTmYoogAP/s1aVyGb3XDHLlUmXD3TaMJ0WXWoNE1jhKeictR30mYBRvp9NG20Vp3wUXVP72taicobTNac1VxENYQ1zyO4MmVueW4+/ElUIOQxVv2g6kOp1UJI7mCDHf51lANtb9wH7KeaHlojHy4/5uKZtdzcgW6NIOZfaWo67XKP2v5Ityqc3fE/LCLqE42VtTH+H1VPIRCAeGU6UwLF/EbcUschOX8C8Qjf91nGtIbiYlo9QuMHmsFc/FviAyT+G92IIogbGxoUWBBXyTE8VNDMB3V5oxYoamxm1Ku8QgTRTHn5ydCXiRzGdRmb7X9MPznlUXwfWN9ZeN3bLY49DmQ+EhSLdCAo8lDWawA4+Cw+9gtpu8OH+dUI3G8r8o6rxXxkosvGB5E59bfQKdxQUSCppZkFyPzRrN6kN3Ciix5gbNMMY9rTQCgvMRDSIqJz7UuQghrkgf74jytNNdIAqYyIrKcbvo1DMcsHD+FCq20O7hTaB7k38h4oITmxNbLDtybEZFVNiFS774X2fVIRzrfzg4mbiNacJixadqTVRUmL09AiJARhXTY+yQsT1yobDrVw9i39dX8pUVMxYb+XIw3MHxegnnwbJU2eCBpI2hPDTt61BhGjpJW5ypT3MZZShWPtyOnWo1PBFa05Nb//3Roceq34mh8P8uognleWq7IXCTNBNC9bTGgjPDQt6Lt5U0wHC6SnL6L1WyJWYUVJjrwF+bc3c4Awm+s4X3+OXlDcakwjec15wMMUG9JEFnAGTLqEZEuz2rtu72TWzlsh5eeqftQ/57I67oZXn/R8TtsJoo09TK7bGcrNlJR1McdG5g==';
    break;

  case 'agrinorm-connect-prerelease.web.app':
    SCANDIT_KEY =
      'ASIyHSlEDAVJGQn1Kgr+XU1DDRo3A0e4ZGEF+jQE0wwIW1xi/WtyEElMqRyFVjIKG3NdsERC323AC1d542vdgDZ7PbjCJU3TwFymeSkBd75SbRmyGBRc6uxLLKOuSOiDshYpzjRjmOFhX41cLVT26nBrQ5RtWSHWpyYV7x1W8HG9U/J2+mTkfDp2vnMtcBa/eCN1w6IVEqsvMc+S/C6sqVEoEjaaLjNzGhTixjsuXx5kLmKl+PklqOiEIbPJw6/SCDc8tVHoO56x/9aKi4i9nSzx4K77FGrDsWy/mGKluXsEWSdpNSFN88AoO57/dCGF3F5YRpnIEZpjlAKW+jqsGnvXuuJIqfXzV6vF4mvVk5b4M8o3/rQ6QlX2SH4yo2I7QxcYUmn0vMgxybhQLN9FX4TCsxUL8UkVXTCHE8XL+e0EOFCmDNU9vdjD6D672UGkZbNuH1BOeVOe54s03cE2BmJcVxId1hB4qdqy0nmN/AlHCgonSOv4ckLMxUaWozK/UUkp1UHPFCXwjox6uQbkaM9fBY21dIWpoo2TCkg80vrkyh9hn3X2NkiKfNdk2ZHNA9VKKJOgPCxvAu15xO4AA/L/mqkqTiA8DTErF5xpJ/ntVAp+XhRu/a4dN/Uo20kxLC9BrMlp2AF1SUrndk/Y0NGqzIbyEiSuWa01HwSwOeidE9DH4Ld9mHhFQyqpPAul5kLzZtJBAAzi2AU2lkoD8E2ZnlyCugfFAo9HfUcbeJtIdCb/gd8jUw7kZvqshrmTwJX+AZg0i/ZGW7Fjzw0nR+/oq8NFQNDT922xUS3ZxoYdevl1snR8HGwjrjte8wNqN5OpyAp4gr/3u232UnjFEiO+Q+vti3WZig4bG19fsJ0F95sKGyKLkLUce7bfKI98znA/7pYg';
    break;

  case 'integration.agrinorm.ai':
  case 'agrinorm-connect-integration.web.app':
    SCANDIT_KEY =
      'ATwQETPREw81IyULqA1T6Q8Db366LWoKyXWMGYk2z/EnU2KoF2UI6VB6/oUjfjXynlbbydoHxEJ0PnIBDX3RmJ1zj0p0UBtD5nogAfogJN/Fcb78Hh2zaJxOb56AdQibvRwNUnohU15SepMBxx639zoLan4JRCjBuCKItQD1S5Mgug3GrFkKR2LZU8UwplSerZVa6LvrnBXkMJeyJUXJddlu/vWISBXVvBA5fpKeM2FXpACeOdLl9Km4cjq139DOdK7o0fSEcRimTw2Vrtr517NUDP6V8+26+w7Wj5FJXkny0PrJjkVl5aLmy5AxmVImPxNzFV0SkiWrjB55j8YUsQlMwgTy7eJJ4VvmKGBQ1JUQuVW5lPOHcA1Hf2fR3xOgfyd1LKpDO32NPDiLnCWfcVVaqVCDD8ca6Yb2L1xBkNV926mNvkrAZeBIBeqPl6cAM9XV6d51FoFyP3E525p18H4PbAMW2sAxVQC1iNTUELxYECZ8wftQk9RxrJcY0PMf11ic9q7z43PLrFJfoJ0eu/YPVu6U6DXaunWfWUvzHnRXGh0bAxPODBUC6C1KPGV6HUl3l51Or0OQPD82yJ8RIHP9thLCHMBNCnPiswKMSb47+gOl4tqwDJPSYObzA7rtoig6/hJbw4NvzHSphtHUQ/oD4QdLyyGV/b2M8VAmIGtW70MbIbizFTrZVtcbVwGx1KqFzBul1zqbWeVxvkybWBIqZFtUBXusE5V/RdtGJBv3UbHMa0uXLgfKlYXwQ8UF78I1ilL9SvUGGZXPXT4CW+1rjRd4AX5/TmfjjfJoTH5FjxTMLewIwDDnrD5ZL+m8O9+9s4x9TaAmMirk';
    break;

  case 'agrinorm-connect-stg.web.app':
    SCANDIT_KEY =
      'ATNB3TXdEa4GAlYNAyfnmqkDOXm/PI+9CEepSmkHLhpia8ZIOW7WNiZGxkkuWAIgDmwtOnB098z0cRikHnRWfHlo2b9bZhQ29FrieKA/voOLbJAWemztbwF86ILwZSnX4TNgSvMz89X3b35SrEv6cbwlAoqVbi5WdBIDHMRSjSbKfsMfDWZsO6ENYV75BEkJ7Bm/9owCTIWp4QIgQ3XBOWEJCNMdJv9y8ruvz/iwCUrty4gk+xKH2XqxV2JiipGQahd89qS0r8cnWMZT+ZmJWRV/lfeIXFqQfofoKaS/nXVJg6fnYgTKUyN7/WDwRdYrE+vfoVjO5SuNMK2gdDZK7TddfwtJCjnFmKGRwWiUsc8573cxBMQqO/M8X7f9X5nXmDj1EOwxSAeiXIf0Gh7OpmXJxRcJDRpGaqDhAHL+5SES3p/oh58ZspdTZibD6MVqtdKw4ry21lyNNGII+a38Xe62f1Ugz8c+rT6Y3judeI3R1uy/fGaKhJmE2lSX3/JNNtfwGJjxEi3V7bwsOmHV7srsMSto/Wo8vPs1upwC2vS4uGfVSCuhwQY8gMLBva7S8PMjx0fiDaXwAQwFXs70rW7pIeLiSzFFe5RZAWJumMGM3sndxU+2PXNp90kFjFkRrBZJ6zeEEYA9Flp9/8RCX+FctQmwODEc4Vu6rOjjd4pG2NpF8S1kR45OtkhJjiP/RiGlXzCbezXDbXX5cNoImeKrK/mbOX2er7H8QFHLIflWpcCTFm1uS9NVEY8sHIaAOHix2CzvHoE8z+4LOA2fxFs35/n5VYdF2JfisJYvC26Y33iyiKXvqacc7BCkCU7eKCR35+TRr+c9LqbsIm27eAeJVSwxmMd8SwoZzihLklrIjGy3/Y3QeA==';
    break;
}

export default SCANDIT_KEY;
