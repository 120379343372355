import React from 'react';
import { UserProfile } from './Model';
import { IonButton } from '@ionic/react';

class ErrorBoundaryFallback extends React.Component {
  render() {
    return (
      <h1 className="error-boundary-message">
        <div>Something went wrong</div>
        <div className="buttons">
          <IonButton
            color="primary"
            mode="ios"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Go home
          </IonButton>
          <IonButton
            color="dark"
            mode="ios"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </IonButton>
        </div>
      </h1>
    );
  }
}

export default ErrorBoundaryFallback;
