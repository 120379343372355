import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { replaceAll } from './HelperUtils';
const showdown = require('showdown');

export const PageChangeLog = () => {
  const [changeLog, setChangeLog] = useState<string | undefined>();
  const [initted, setInitted] = useState<boolean>(false);

  useEffect(() => {
    fetch('CHANGELOG.md')
      .then((res) => res.text())
      .then((changelog) => {
        changelog = sanitizeMarkdown(changelog);
        const converter = new showdown.Converter();
        const htmlText: string = converter.makeHtml(changelog);

        setChangeLog(htmlText);
        setInitted(true);
      })
      .catch((err) => console.error('Failed to load changelog file', err));
  }, []);

  const sanitizeMarkdown = (text: string) => {
    // Remove title
    text = text.split('# Changelog')[1];
    // Remove version links
    const versionLinkRegex = /\[(\d+\.\d+\.\d+)\]\(.*?\)/;
    text = replaceAll(text, versionLinkRegex, '');
    // Remove commit links
    const markdownLinkRegex = /\(\[.*?\]\(.*?\)\)/g;
    text = replaceAll(text, markdownLinkRegex, '');
    return text;
  };

  return (
    <IonPage className="ion-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text={'Back'}
              defaultHref="/tabs/quality-control"
              color="dark"
            />
          </IonButtons>
          <IonTitle>Change Log</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {initted ? (
          !!changeLog ? (
            <div
              style={{ padding: '0 16px 0 16px' }}
              dangerouslySetInnerHTML={{ __html: changeLog }}
            />
          ) : (
            <>Changelog could not be loaded. Please contact support</>
          )
        ) : (
          <IonLoading isOpen={!initted} />
        )}
      </IonContent>
    </IonPage>
  );
};
