import {
  FirebaseSpecificationRepository,
  scoringContextColRef,
} from './DataSpecification';
import { ISO3166 } from './HelperInsight';
import { shuffleArray } from './HelperUtils';
import {
  AGScore,
  CombinationSample,
  DefectInfo,
  Example,
  IQuestionSpec,
  LotScoringType,
  MergedSchema,
  OrganisationSettings,
  QuestionSeverity,
  ScoringContext,
  ScoringContextSlim,
  ScoringContextValue,
  ScoringContextValueSlim,
  SeverityDict,
} from './Model';
import { LotScoringSection, Section } from './ModelSpecification';
import {
  Configuration,
  InitializeRankingtreeApi,
  LabelCorrectionApi,
  QueryGetApi,
  SamplePostApi,
} from './generated/openapi/scoring';
import firebase from 'firebase/compat/app';
import { Auth } from 'firebase/auth';
// import { auth } from './ConfigFirebase'

/********************/
// Constants
/********************/

// if set to true, displays logs in api functions
const debug = true;

// export const scoreSpace: Score[] = [1, 2, 3, 4];
export const scoreMaps = {
  numbers: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
  letters: {
    1: 'D',
    2: 'C',
    3: 'B',
    4: 'A',
    5: 'A+',
  },
};

/********************/
// API
/********************/

export const getScoringApiUrl = () => {
  const defaultUrl = 'https://scoring-api-integration-cua7thhd7a-ew.a.run.app';
  const urlMap = {
    'agrinorm-connect': 'https://scoring-api-cua7thhd7a-ew.a.run.app',
    'agrinorm-connect-integration': defaultUrl,
    'agrinorm-fernando': defaultUrl,
  };
  return urlMap[process.env.REACT_APP_PROJECT_ID] ?? defaultUrl;
};

const apiConfig: Configuration = new Configuration({ basePath: getScoringApiUrl() });

async function initApi<T>(auth: Auth, api: any) {
  const token = await auth.currentUser.getIdToken();
  const axiosConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("AXIOS CONFIG", axiosConfig)

  const initApi = new api(apiConfig);
  return { api: initApi as T, axiosConfig };
}

export async function fetchNewSample(
  auth: Auth,
  context_permdict: ScoringContext,
  sample: CombinationSample
) {
  const start = Date.now();
  const data = { context_permdict: slimmifyScoringContext(context_permdict), sample };

  const { api, axiosConfig } = await initApi<SamplePostApi>(auth, SamplePostApi);

  debug && console.log('new sample payload', data, 'size', JSON.stringify(data).length);
  const newSample = (await api.newSampleEndpoint(data, axiosConfig)).data;
  newSample.context_permdict = fattenScoringContext(
    newSample.context_permdict as ScoringContextSlim
  );
  debug && console.log('new sample response', newSample);
  debug && console.log(`fetch new sample took `, Date.now() - start, ' milliseconds');

  return newSample;
}

export async function fetchNewQuery(auth: Auth, context_permdict: ScoringContext) {
  const start = Date.now();

  const data = { context_permdict: slimmifyScoringContext(context_permdict) };

  const { api, axiosConfig } = await initApi<QueryGetApi>(auth, QueryGetApi);

  debug &&
    console.log('fetchNewQuery payload', data, 'size', JSON.stringify(data).length);
  const nextQuery = (await api.nextQueryEndpoint(data, axiosConfig)).data;
  nextQuery.context_permdict = fattenScoringContext(
    nextQuery.context_permdict as ScoringContextSlim
  );
  debug && console.log('fetchNewQuery response', nextQuery);
  debug && console.log(`fetch next query took `, Date.now() - start, ' milliseconds');

  return nextQuery;
}

export async function correctLabel(
  auth: Auth,
  context_permdict: ScoringContext,
  corrected_label: CombinationSample
) {
  const start = Date.now();

  const data = {
    context_permdict: slimmifyScoringContext(context_permdict),
    corrected_label,
  };

  const { api, axiosConfig } = await initApi<LabelCorrectionApi>(
    auth,
    LabelCorrectionApi
  );

  debug &&
    console.log('correctLabel payload', data, 'size', JSON.stringify(data).length);
  const correctLabel = (await api.labelCorrectionEndpoint(data, axiosConfig)).data;
  correctLabel.context_permdict = fattenScoringContext(
    correctLabel.context_permdict as ScoringContextSlim
  );
  debug && console.log('correctLabel response', correctLabel);
  debug && console.log(`fetch correctLabel took `, Date.now() - start, ' milliseconds');

  return correctLabel;
}

export async function initializeRankingTree(auth: Auth, combination_class: string) {
  const start = Date.now();
  const data = { combination_class };

  const { api, axiosConfig } = await initApi<InitializeRankingtreeApi>(
    auth,
    InitializeRankingtreeApi
  );

  debug && console.log('initializeRankingTree payload', data, axiosConfig);
  const tree = (await api.initializeContextEndpoint(data, axiosConfig)).data;
  debug && console.log('initializeRankingTree response', tree);
  debug &&
    console.log(
      `fetch initializeRankingTree took `,
      Date.now() - start,
      ' milliseconds'
    );

  return tree;
}

/********************/
// DB functions
/********************/

export async function fetchContextPermDict(
  firestore,
  auth: Auth,
  section: Section,
  groupId: string,
  orgId: string,
  organisationSettings: OrganisationSettings,
  scoring: LotScoringSection
): Promise<ScoringContext> {
  const specRepo = new FirebaseSpecificationRepository(firestore, orgId);
  let permDict = await specRepo.getScoringContext(scoring.id);

  // if no perm dict found, create it
  if (!permDict) {
    console.log('No perm dict found. Creating them...');
    for (const groupId of getGroupsFromSection(section)) {
      await checkInitContextPermDict(
        firestore,
        auth,
        section,
        groupId,
        scoring,
        orgId,
        organisationSettings,
        true
      );
      console.log(
        `Creating perm dict for scoring ${scoring.id} (section ${section.id}, group ${groupId})`
      );
    }
    permDict = await specRepo.getScoringContext(scoring.id);
  }

  // permDict comes stringified, must be parsed
  return permDict[groupId];
}

export async function setContextPermDict(
  firestore,
  permDict: ScoringContext,
  scoring: LotScoringSection,
  groupId: string,
  orgId: string
) {
  const permDicts = {};
  permDicts[groupId] = encodePermDictForFirestore(permDict);
  await scoringContextColRef(firestore, orgId)
    .doc(scoring.id)
    .set(permDicts, { merge: true });
}

export async function checkInitContextPermDict(
  firestore,
  auth,
  section: Section,
  groupId: string,
  scoring: LotScoringSection,
  organisationId: string,
  organisationSettings: OrganisationSettings,
  reset: boolean = false,
  userId?: string
) {
  // First check if the perm dict exists
  const scoringContextRef = await scoringContextColRef(firestore, organisationId)
    .doc(scoring.id)
    .get();

  // if it's a reset, save the old scoring context a.k.a. perm dict in the history
  if (reset && scoringContextRef.exists) {
    const oldPermDict = scoringContextRef.data();
    oldPermDict.resetDate = Date.now();
    oldPermDict.resetUserId = userId;
    await scoringContextColRef(firestore, organisationId)
      .doc(scoring.id)
      .collection('history')
      .doc(composeSchemaVersion())
      .set(oldPermDict);
  }

  // if context perm dict not found or if reset == true, create an empty one and store it in the db
  if (!scoringContextRef.exists || reset) {
    const possibleCombinations = await getPossibleCombinations(auth, section, groupId);
    const contextPermDict = buildContextPermDict(
      possibleCombinations,
      organisationSettings,
      groupId
    );
    await setContextPermDict(
      firestore,
      contextPermDict,
      scoring,
      groupId,
      organisationId
    );
    console.log('context permutation dictionary set');
  }
}

export function composeSchemaVersion(timestamp?: number) {
  let date = timestamp ? new Date(timestamp) : new Date();
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  month = month.toString().length === 1 ? `0${month}` : month;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timeInSeconds = hour * 3600 + minutes * 60 + seconds;
  const version = `${year}-${month}-${day}-${timeInSeconds}`;
  return version;
}

export async function getOrigins(orgId: string): Promise<string[]> {
  return Object.keys(ISO3166);
}

/********************/
// Helpers
/********************/

export function slimmifyScoringContext(sc: ScoringContext) {
  const slimSC: ScoringContextSlim = {};

  Object.entries(sc).forEach(([key, value]) => {
    const valueSlim: ScoringContextValueSlim = {
      l: value.label ?? null,
      d: value.deduction ?? null,
      p: value.prediction ?? null,
      o: value.label_order ?? null,
      e: value.example ?? null,
    };
    slimSC[key] = valueSlim;
  });

  return slimSC;
}

export function fattenScoringContext(scSlim: ScoringContextSlim) {
  const scoringContext: ScoringContext = {};

  Object.entries(scSlim).forEach(([key, valueSlim]) => {
    const value: ScoringContextValue = {
      label: valueSlim.l,
      deduction: valueSlim.d,
      prediction: valueSlim.p,
      label_order: valueSlim.o,
      example: valueSlim.e ?? null,
    };
    scoringContext[key] = value;
  });

  return scoringContext;
}

export function encodePermDictForFirestore(sc: ScoringContext): string {
  const slimSC: ScoringContextSlim = slimmifyScoringContext(sc);
  return JSON.stringify(slimSC);
}

export function decodePermDictFromFirestore(encodedSC: string): ScoringContext {
  const scSlim: ScoringContextSlim = JSON.parse(encodedSC);
  return fattenScoringContext(scSlim);
}

export function scoringContextToScoring(permDict: ScoringContext) {
  const scoringGroup = {};
  for (const combination of Object.keys(permDict)) {
    scoringGroup[combination] = permDict[combination].prediction;
  }
  return scoringGroup;
}

export function renderGroupName(groupId: string) {
  // TODO: do this right when we have a source for the names of the groups
  const groupNameMap = {
    group_appearance: 'Appearance',
    group_condition: 'Condition',
  };
  if (!groupNameMap[groupId]) {
    return groupId;
  }
  return groupNameMap[groupId];
}

export function scoringToScoringContext(
  scoring: { [key: string]: AGScore },
  section: Section,
  groupId: string,
  organisationSettings: OrganisationSettings
) {
  // TODO: for now all of them are considered user labels. Improve this once we implement saving if it's a label or not in the scoring
  const permDict: ScoringContext = {};

  let labelOrder = 0;
  for (const combination of Object.keys(scoring)) {
    permDict[combination] = {
      label: scoring[combination],
      deduction: scoring[combination],
      prediction: scoring[combination],
      label_order: labelOrder,
      example: exampleFromCombination(
        combination,
        getSeverityDict(section, groupId),
        section,
        organisationSettings,
        groupId
      ).example,
    };
    delete permDict[combination].example.isValidExample;
    labelOrder++;
  }

  return permDict;
}

export async function getPossibleCombinations(
  auth: Auth,
  section: Section,
  groupId: string
) {
  const combinationClass = getCombinationClass(section, groupId);
  const { keys } = await initializeRankingTree(auth, combinationClass);
  return keys;
}

export function getClosestExample(
  example: Example,
  section: Section,
  groupId: string,
  organisationSettings: OrganisationSettings
) {
  const defectInfo: DefectInfo[] = getDefectsInfo(
    section,
    groupId,
    organisationSettings
  );
  const defects: string[] = Object.keys(example);

  const closestExample = {};

  defects.forEach((defect) => {
    const info = defectInfo.find((d) => d.id === defect);
    const inputSpace = info.inputSpace;
    const score = example[defect];
    if (!inputSpace.includes(score)) {
      // get the closest score
      closestExample[defect] = inputSpace.reduce((prev, curr) =>
        Math.abs(curr - score) < Math.abs(prev - score) ? curr : prev
      );
    } else {
      closestExample[defect] = example[defect];
    }
  });
  return closestExample;
}

export function getInitialExample(section: Section, groupId: string) {
  const initialAssignment: Example = {};
  const specs = section.questions;
  Object.keys(specs).forEach((key) => {
    const spec = specs[key];
    if (spec.groupId === groupId) {
      initialAssignment[key] = 4;
    }
  });
  return initialAssignment;
}

export function getDefectsInfo(
  section: Section,
  groupId: string,
  organisationSettings: OrganisationSettings
): DefectInfo[] {
  const defectsInfo: DefectInfo[] = [];
  Object.keys(section.questions).forEach((key) => {
    const spec = section.questions[key];
    if (spec.groupId === groupId) {
      const dInfo: DefectInfo = {} as DefectInfo;

      dInfo.severity = spec.batchScoring[groupId].groupId as QuestionSeverity;
      dInfo.id = key;

      // resolve name
      dInfo.name = spec.displayedName;

      // define thresholds and input space conditionally
      let { inputSpace, thresholds } = getInputSpaceAndThresholds(
        spec,
        organisationSettings,
        groupId
      );

      dInfo.inputSpace = inputSpace;
      dInfo.thresholds = thresholds;
      defectsInfo.push(dInfo);
    }
  });

  // sort by severity and alphabetically
  const sortedDefectsInfo = defectsInfo
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .sort((a, b) => {
      const severityMap: { [key in QuestionSeverity]: number } = {
        CRITICAL: 1,
        MAJOR: 2,
        MINOR: 3,
      };
      return severityMap[a.severity] - severityMap[b.severity];
    });
  return sortedDefectsInfo;
}

function getInputSpaceAndThresholds(
  spec: IQuestionSpec,
  organisationSettings: OrganisationSettings,
  groupId: string
) {
  // const scoreSpace = (schema.lotScoring.params as ADLSScoringParams).scoreSpace;
  const scoreSpace = organisationSettings.scoreSpace[groupId]?.sort((a, b) =>
    a < b ? -1 : 1
  );
  if (!scoreSpace) {
    console.log('ERROR: No score space found in organisation settings');
    return { inputSpace: undefined, thresholds: undefined };
  }
  let inputSpace: any[] = [];
  let thresholds: { [key in AGScore]?: string[] } = {};

  const rules = spec.scoringPolicy;
  // Always take the first input, as in the case of specialfruit, only the first input is ever scored
  const input = spec.inputs[0];

  inputSpace = [...new Set([...rules.map((r) => r.score)])].sort((a, b) => a - b);

  switch (input.answerType) {
    case 'CATEGORY':
      rules.forEach((r) => {
        if (thresholds[r.score]) {
          thresholds[r.score].push(r.values[0].category);
        } else {
          thresholds[r.score] = [r.values[0].category];
        }
      });
      break;
    case 'FLOAT':
    case 'INT':
      const unitMap: { [key in string]: string } = {
        'BOXES': 'B.',
        'CELSIUS': 'ºC',
        'FAHRENHEIT': 'ºF',
        'GRAMS': 'g',
        'KILOS': 'Kg',
        'OUNCES': 'oz',
        'PERCENTAGE': '%',
        'PIECES': 'pcs',
        'POUNDS': 'Lb',
        'PUNNETS': 'p',
        'KG_PER_CM^2': 'kg/cm²',
      };
      const unit = unitMap[input.answerUnit ?? ''] ?? '';
      rules.forEach(
        (r) =>
          (thresholds[r.score] = [
            `${r.values[0].min}${unit} - ${r.values[0].max}${unit}`,
          ])
      );
      break;
    default:
      inputSpace = scoreSpace;
      thresholds = { 1: ['n/a'], 2: ['n/a'], 3: ['n/a'], 4: ['n/a'] };
      break;
  }

  return { inputSpace, thresholds };
}

export function exampleFromCombination(
  combination: string,
  severityDict: SeverityDict,
  section: Section,
  organisationSettings: OrganisationSettings,
  groupId: string,
  recursionCount = 0
) {
  const maxRecursionCount = 10;

  let combinationArray: (string | number)[] = combination.split('');
  combinationArray = combinationArray.map((c) => +c);
  const criticals = combinationArray.slice(0, 4);
  const majors = combinationArray.slice(4, 8);
  const minors = combinationArray.slice(8);

  function generateList(subset: (string | number)[]) {
    const subExample = [];
    subset.forEach((categoryAmount, idx) => {
      if (categoryAmount !== 0) {
        subExample.push(...Array(categoryAmount).fill(idx + 1));
      }
    });
    shuffleArray(subExample);
    return subExample;
  }

  const fullExamples = [
    ...generateList(criticals),
    ...generateList(majors),
    ...generateList(minors),
  ];

  const defectsDict = { CRITICAL: [], MAJOR: [], MINOR: [] };

  for (const defectId in severityDict) {
    defectsDict[severityDict[defectId]].push(defectId);
  }

  const fullDefects = [
    ...defectsDict['CRITICAL'],
    ...defectsDict['MAJOR'],
    ...defectsDict['MINOR'],
  ];

  const exampleDict: Example = {};
  fullDefects.forEach((d, idx) => (exampleDict[d] = fullExamples[idx]));

  // check if is a valid example (i.e: values within inputSpace)
  for (const defectId in exampleDict) {
    const spec = section.questions[defectId];
    const { inputSpace } = getInputSpaceAndThresholds(
      spec,
      organisationSettings,
      groupId
    );
    if (
      !inputSpace.includes(exampleDict[defectId]) &&
      recursionCount < maxRecursionCount
    ) {
      return exampleFromCombination(
        combination,
        severityDict,
        section,
        organisationSettings,
        groupId,
        recursionCount + 1
      );
    }
  }

  return { example: exampleDict, isValidExample: recursionCount < maxRecursionCount };
}

export function filterSchemasByScoringType(
  schemas: MergedSchema[],
  type: LotScoringType
) {
  return schemas.filter(
    (s) =>
      s.lotScoring &&
      Object.values(s.lotScoring).reduce((a, b) => a || b.type === type, false)
  );
}

export function combinationFromExample(
  exampleDict: Example,
  SeverityDict: SeverityDict,
  organisationSettings: OrganisationSettings,
  groupId: string
) {
  // const scoreSpace = (schema.lotScoring.params as ADLSScoringParams).scoreSpace;
  const scoreSpace = organisationSettings.scoreSpace[groupId]?.sort((a, b) =>
    a < b ? -1 : 1
  );
  if (!scoreSpace) {
    console.log('ERROR: No score space found in organisation settings');
    return '';
  }
  try {
    const severityDist = { CRITICAL: [], MAJOR: [], MINOR: [] };

    for (const defectId of Object.keys(exampleDict)) {
      const severity = SeverityDict[defectId];
      severityDist[severity].push(exampleDict[defectId]);
    }

    const dictCount = { CRITICAL: [], MAJOR: [], MINOR: [] };

    for (const severity in severityDist) {
      for (const x of scoreSpace) {
        dictCount[severity].push(severityDist[severity].filter((y) => y === x).length);
      }
    }

    const combinationArray = [
      ...dictCount['CRITICAL'],
      ...dictCount['MAJOR'],
      ...dictCount['MINOR'],
    ];
    return combinationArray.join('');
  } catch {
    return '';
  }
}

export function getCombinationClass(section: Section, groupId: string) {
  const specs = section.questions;
  const inputKeys = Object.keys(specs);

  const combinationClass = { CRITICAL: 0, MAJOR: 0, MINOR: 0 };
  const comboMap = { CRITICAL: 'C', MAJOR: 'M', MINOR: 'm' };

  inputKeys.forEach((key) => {
    if (specs[key].groupId === groupId) {
      combinationClass[specs[key].batchScoring[groupId].groupId]++;
    }
  });

  // stringify
  let comboClassString = '';
  Object.keys(combinationClass).forEach(
    (key) =>
      (comboClassString = `${comboClassString}${comboMap[key]}${combinationClass[key]}`)
  );

  debug && console.log('comboClass', comboClassString);
  return comboClassString;
}

export function getCategorySliderRange(
  permDict: ScoringContext,
  combination: string
): { min: AGScore; max: AGScore } {
  if (combination === '' || !permDict[combination]) {
    return { min: 1, max: 1 };
  }
  let deduction = permDict[combination].deduction;
  if (typeof deduction === 'number') {
    deduction = [deduction];
  }
  const catSlider = {
    min: Math.min(...deduction) as AGScore,
    max: Math.max(...deduction) as AGScore,
  };
  return catSlider;
}

export function getSeverityDict(section: Section, groupId: string): SeverityDict {
  const specs = section.questions;
  const inputKeys = Object.keys(specs);

  const severityDict: SeverityDict = {};

  inputKeys.forEach((key) => {
    if (specs[key].groupId === groupId) {
      severityDict[key] = specs[key].batchScoring[groupId].groupId as QuestionSeverity;
    }
  });
  return severityDict;
}

export function permDictId(schemaId: string, groupId: string, organisationId: string) {
  return [schemaId, groupId, organisationId].join(';');
}

export function getGroupsFromSection(section: Section): string[] {
  const groups = new Set<string>();
  Object.entries(section?.questions ?? {}).forEach(([qId, specs]) => {
    if (!!specs.batchScoring) {
      Object.keys(specs.batchScoring).forEach((groupId) => groups.add(groupId));
    }
  });
  return Array.from(groups);
}

export function buildContextPermDict(
  permutations: string[],
  organisationSettings: OrganisationSettings,
  groupId: string
) {
  // const scoreSpace = (schema.lotScoring.params as ADLSScoringParams).scoreSpace;
  const scoreSpace = organisationSettings.scoreSpace[groupId]?.sort((a, b) =>
    a < b ? -1 : 1
  );
  if (!scoreSpace) {
    console.log('ERROR: No score space found in organisation settings');
    return undefined;
  }
  const contextPermDict = {};
  permutations.forEach((perm) => {
    contextPermDict[perm] = {
      label: null,
      deduction: scoreSpace,
      prediction: null,
      label_order: 0,
      example: null,
    };
  });
  return contextPermDict;
}
