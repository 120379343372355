import React, { useContext } from 'react';
import {
  Contact,
  Organisation,
  ProductView,
  User,
  Location,
  UserProfile,
} from '../Model';
import {
  ctxContacts,
  ctxFilters,
  ctxLocations,
  ctxOrg,
  ctxProducts,
  ctxProfile,
  ctxScorings,
  ctxSpecs,
  ctxUsers,
} from '../App';
import { AGFilters } from '../SearchService';
import { InspectionSpec, LotScoringSection } from '../ModelSpecification';

export interface ContextProps {
  contacts?: Contact[];
  users?: User[];
  products?: ProductView[];
  organisation?: Organisation;
  locations?: Location[];
  profile?: UserProfile;
  filters?: AGFilters;
  setFilters?: (f: AGFilters) => void;
  inspectionSpecs?: InspectionSpec[];
  scorings?: LotScoringSection[];
  setInspectionSpecs?: (s: InspectionSpec[]) => void;
  setScorings?: (s: LotScoringSection[]) => void;
}

function withContext<Props>(
  WrappedComponent: React.ComponentType<Props>,
  onlyInclude?: Array<keyof ContextProps>
): React.FC<Omit<Props, keyof ContextProps>> {
  return function ContextComponent(props) {
    const contacts = useContext(ctxContacts);
    const users = useContext(ctxUsers);
    const organisation = useContext(ctxOrg);
    const products = useContext(ctxProducts);
    const locations = useContext(ctxLocations);
    const profile = useContext(ctxProfile);
    const { filters, setFilters } = useContext(ctxFilters);
    const { inspectionSpecs, setInspectionSpecs } = useContext(ctxSpecs);
    const { scorings, setScorings } = useContext(ctxScorings);

    const contextProps: ContextProps = {
      contacts,
      users,
      organisation,
      products,
      locations,
      profile,
      filters,
      setFilters,
      inspectionSpecs,
      scorings,
      setInspectionSpecs,
      setScorings,
    };

    if (onlyInclude && onlyInclude.length > 0) {
      Object.keys(contextProps).forEach((key: keyof ContextProps) => {
        if (!onlyInclude.includes(key)) {
          delete contextProps[key];
        }
      });
    }

    return <WrappedComponent {...(props as Props)} {...contextProps} />;
  };
}

export default withContext;
