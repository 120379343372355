import React from 'react';
import { Picture, RawPicture } from './Model';
import './ViewPicture.scss';
import {
  getPictureRaw,
  // getPictureURL
} from './DataImage';
import { IonIcon } from '@ionic/react';
import { cloudUploadOutline } from 'ionicons/icons';

export interface Props {
  id: string;
  alt?: string;
  className?: string;
  picture?: Picture;
  onClick?: Function;
  mode?: string;
  location?: string;
  index?: number;
  resolution?: 'thumbnail' | 'full';
}

export interface State {
  rawPicture?: RawPicture;
  inited: boolean;
}

const RETRY_TIME = 20000;

class ViewPicture extends React.Component<Props, State> {
  private rawPicture;
  private mounted;
  private retryTimes = 0;

  constructor(props) {
    super(props);

    this.state = {
      inited: false,
    };
  }

  async componentDidMount() {
    this.mounted = true;
    this.updatePicture();
  }

  async updatePicture() {
    this.rawPicture = await getPictureRaw(this.props.id, this.props.location);
    // console.log(this.rawPicture, this.props.id)
    if (this.mounted) {
      await this.setState({ rawPicture: this.rawPicture, inited: true });
    }
    // update for full resolution
    if (this.props.location && this.props.resolution !== 'thumbnail') {
      const id = this.props.id;
      const rawPic2 = await getPictureRaw(this.props.id, this.props.location);
      if (this.mounted && this.rawPicture !== rawPic2 && this.props.id === id) {
        this.setState({ rawPicture: rawPic2, inited: true });
      }
    }
  }

  async componentDidUpdate(prev: Props) {
    if (this.props.id !== prev.id) {
      this.updatePicture();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { rawPicture, inited } = this.state;
    const { alt, className } = this.props;
    if (!inited) {
      return <div />;
    }

    let dirty = rawPicture?.__dirty ? 'dirty' : '';
    let error = rawPicture?.__error ? 'error' : '';
    let isUploading = rawPicture?.isUploading ? 'uploading' : '';
    let loaded = inited ? 'loaded' : '';

    let params = {
      src: rawPicture?.content,
      onClick: (_) => this.props.onClick && this.props.onClick(this.props.picture),
      alt: alt,
      style: {
        visibility: 'hidden',
      },
      className: `render-picture ${className} ${error} ${loaded} ${dirty} ${isUploading}`,
    };

    if (error === 'error') {
      if (this.retryTimes < 5) {
        this.retryTimes++;
        setTimeout(this.updatePicture.bind(this), RETRY_TIME);
      }
      return (
        <div data-tip={`picture-${this.props.index}`} className="img-being-uploaded">
          <IonIcon icon={cloudUploadOutline} />
          <div>Uploading</div>
        </div>
      );
    }

    if (this.props.mode === 'img' || true) {
      return (
        // @ts-ignore
        <img
          {...params}
          data-tip={`picture-${this.props.index}`}
          alt={'report'}
          key={this.props.id}
          onLoad={(e) => (e.currentTarget.style.visibility = 'visible')}
        />
      );
    }

    // return <IonImg data-tip={`picture-${this.props.index}`} {...params} key={this.props.id} onIonImgDidLoad={(e)=>e.currentTarget.style.visibility = 'visible'}/>
  }
}

export default ViewPicture;
