import {
  IonAlert,
  IonBadge,
  IonButton,
  IonCard,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from '@ionic/react';
import {
  arrowBackCircle,
  arrowForwardCircle,
  checkmarkCircle,
  chevronDown,
  chevronDownOutline,
  chevronForward,
  chevronUpOutline,
  closeOutline,
  cloudOfflineOutline,
  syncOutline,
  trashBin,
} from 'ionicons/icons';
import _, { cloneDeep } from 'lodash';
import React, { Fragment, createRef } from 'react';
import { auth } from './ConfigFirebase';
import { fetchOCRData } from './DataImage';
import { INSPECTION_IMAGES_STORAGE_BASE_PATH } from './GlobalConstants';
import { Inspection, LegacyInspection, LotInspection } from './InspectionModel';
import {
  AG_COC_INVALID,
  AG_COC_MISSING,
  AG_GGN_INVALID,
  AG_GGN_MISSING,
  AG_GLN_INVALID,
  AG_GLN_MISSING,
  AgrinormQuestionId,
  LotProperties,
  Picture,
} from './Model';
import { InspectionSpec, SectionNames } from './ModelSpecification';

import withContext, { ContextProps } from './HOC/withContext';
import { OcrData, VisionAPIResponse } from './ModelVision';
import './PagePicture.scss';
import { AppProduct, QCTierAction, orgHasPermission } from './PermissionsService';
import i18n from './ServiceI18n';
import { getLayoutFilteredByTaggable } from './ServiceInspection';
import ViewOCRExtraction from './ViewOCRExtraction';
import ViewPicture from './ViewPicture';
import {
  InspectionSpecSection,
  InspectionSpecSubsection,
  LotSchemaObjectTypeEnum,
  SectionType,
} from './generated/openapi/core';
import { LabelResponse } from './generated/openapi/vision';

export interface Props {
  editable: boolean;
  picture: Picture;
  pictures?: Picture[];
  editMode?: 'report' | 'assessment';

  location?: string;

  schema?: InspectionSpec;
  inspection?: Inspection;

  onDismiss: Function;
  onPictureSelected?: Function;
  onPictureUpdated?: Function;
  onPictureRemoved?: Function;

  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;
}

interface State {
  showRemovePictureAlert: boolean;
  toggleMap: any;
  picture: Picture;
  ocrResponse: VisionAPIResponse;
  requestingOCR: boolean;
  hideMenu?: boolean;
}

class PagePicture extends React.Component<Props & ContextProps, State> {
  private popstate: any;
  private goBack = true;
  private mounted: boolean;

  constructor(props) {
    super(props);

    // const href = window.location.href
    // window.history.pushState({pictureBack: true}, '', href)

    // this.popstate = (event) => {
    //   this.goBack = false
    //   this.props.onDismiss()
    // }
    // window.addEventListener('popstate', this.popstate, false);

    this.state = {
      showRemovePictureAlert: false,
      toggleMap: {},
      picture: this.props.picture,
      ocrResponse: undefined,
      requestingOCR: false,
    };
  }

  private contentRef = createRef<HTMLIonContentElement>();

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    // window.removeEventListener('popstate', this.popstate, false);
    // if (this.goBack) window.history.back()
  }

  prevPicture() {
    let { pictures } = this.props;
    let { picture, ocrResponse } = this.state;

    // disable actions when ocr modal is visible
    if (!!ocrResponse) {
      return;
    }

    if (!pictures || pictures.length === 0 || !picture) {
      return;
    }

    let nextPic;

    if (pictures.indexOf(picture) < pictures.length - 1) {
      nextPic = pictures[pictures.indexOf(picture) + 1];
    } else {
      nextPic = pictures[0];
    }

    if (this.mounted) this.setState({ picture: nextPic });

    if (!this.props.onPictureSelected) {
      return;
    }

    this.props.onPictureSelected(nextPic);

    // this.contentRef.current && this.contentRef.current.scrollToTop(100);
  }

  nextPicture() {
    if (!this.mounted) {
      return;
    }
    let { pictures } = this.props;
    let { picture, ocrResponse } = this.state;

    // disable actions when ocr modal is visible
    if (!!ocrResponse) {
      return;
    }

    if (!pictures || pictures.length === 0 || !picture) {
      return;
    }

    let nextPic;

    if (pictures.indexOf(picture) > 0) {
      nextPic = pictures[pictures.indexOf(picture) - 1];
    } else {
      nextPic = pictures[pictures.length - 1];
    }

    this.setState({ picture: nextPic });

    if (!this.props.onPictureSelected) {
      return;
    }

    this.props.onPictureSelected(nextPic);
    // this.contentRef.current && this.contentRef.current.scrollToTop(100);
  }

  updateGroupId(section: InspectionSpecSection) {
    const picture: Picture = {
      ...this.state.picture,
      sectionId: section.sectionType,
      sectionName: SectionNames[section.sectionType] ?? section.sectionType,
      imageTag: section.imageTag,
      inputIds: [],
    };

    if (this.mounted) this.setState({ picture });

    this.props.onPictureUpdated(picture);
  }

  removePicture() {
    this.props.onPictureRemoved(this.state.picture);
  }

  private showRemovePictureAlert() {
    if (this.mounted) this.setState({ ...this.state, showRemovePictureAlert: true });
  }

  private hideRemovePictureAlert() {
    if (this.mounted) this.setState({ ...this.state, showRemovePictureAlert: false });
  }

  updateToggle(id: string, val: boolean) {
    const toggleMap = { ...this.state.toggleMap };
    toggleMap[id] = toggleMap[id] === undefined ? false : !toggleMap[id];
    if (this.mounted) this.setState({ toggleMap: toggleMap });
    console.log(toggleMap);
  }

  onDefectChange(selected: boolean, defectId: string) {
    if (!this.mounted) {
      return;
    }

    const picture = _.cloneDeep(this.state.picture);
    if (!picture.inputIds) {
      picture.inputIds = [];
    }

    if (selected) {
      if (picture.inputIds.indexOf(defectId) < 0) {
        picture.inputIds.push(defectId);
      }
    } else {
      picture.inputIds = picture.inputIds.filter((def) => def !== defectId);
    }
    this.setState({
      picture: picture,
    });
    this.props.onPictureUpdated(picture);
  }

  renderToggle(id: string, toggle: boolean) {
    return <IonIcon className="toggle" icon={toggle ? chevronDown : chevronForward} />;
  }

  renderDefectNode(defectId: string, level: number) {
    const node = this.props.schema.questionSpecs[defectId];
    if (!node || !node.inspectionProperties?.taggable) {
      return;
    }

    return (
      <IonItem
        key={defectId}
        className={'level-' + level}
        data-tip={`tag-question-${defectId}`}
      >
        <IonLabel className="label-defect">{node.displayedName}</IonLabel>
        <IonCheckbox
          disabled={!this.props.editable}
          color="danger"
          slot="end"
          checked={this.state.picture.inputIds?.indexOf(defectId) >= 0}
          onIonChange={(evt) => this.onDefectChange(evt.detail.checked, defectId)}
        />
      </IonItem>
    );
  }

  renderNode(node: InspectionSpecSection, level: number) {
    let open = this.state.toggleMap[node.sectionType] ?? true;

    const questionIds = (node?.layout ?? []).filter(
      (n) => typeof n === 'string'
    ) as string[];
    const subsections = (node?.layout ?? []).filter(
      (n) => typeof n !== 'string'
    ) as InspectionSpecSubsection[];

    return (
      <Fragment key={node.name}>
        {level === 0 && (
          <IonListHeader
            onClick={(_) => this.updateToggle(node.sectionType, !open)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h1>{node.name}</h1>
            </IonLabel>
          </IonListHeader>
        )}
        {level === 1 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.sectionType, !open)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h2>{node.name}</h2>
            </IonLabel>
          </IonItem>
        )}
        {level === 2 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.sectionType, !open)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h4>{node.name}</h4>
            </IonLabel>
          </IonItem>
        )}

        {open &&
          subsections.length > 0 &&
          subsections.map((child) => this.renderSecondaryNode(child, level + 1))}
        {open &&
          questionIds?.length > 0 &&
          questionIds.map((defectId) => this.renderDefectNode(defectId, level))}
      </Fragment>
    );
  }

  renderSecondaryNode(node: InspectionSpecSubsection, level: number) {
    let open = this.state.toggleMap[node.name] ?? true;

    return (
      <Fragment key={node.name}>
        {level === 0 && (
          <IonListHeader
            onClick={(_) => this.updateToggle(node.name, !open)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h1>{node.name}</h1>
            </IonLabel>
          </IonListHeader>
        )}
        {level === 1 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.name, !open)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h2>{node.name}</h2>
            </IonLabel>
          </IonItem>
        )}
        {level === 2 && (
          <IonItem
            onClick={(_) => this.updateToggle(node.name, !open)}
            data-tip={`multitag-group-${node.name}`}
          >
            <IonLabel>
              {this.renderToggle(node.name, open)} <h4>{node.name}</h4>
            </IonLabel>
          </IonItem>
        )}

        {open &&
          node?.questionIds?.length > 0 &&
          node.questionIds.map((defectId) => this.renderDefectNode(defectId, level))}
      </Fragment>
    );
  }

  async onOCRRequest() {
    const { picture, profile } = this.props;

    this.setState({ requestingOCR: true });

    let ocrResponse: LabelResponse;
    try {
      ocrResponse = (await fetchOCRData(auth, profile, picture)).data;
      // // Mocked response for testing
      // ocrResponse = {
      //   targets: {
      //     GLN: {
      //       value: "1234123412341",
      //       extras: { unclear: true }
      //     },
      //     // GLN: {
      //     //   value: "9283475987324",
      //     //   extras: { valid: true }
      //     // }
      //   }
      // }

      console.log('OCR API RESPONSE', ocrResponse);
    } catch (error) {
      const message = `${error}`.includes('412')
        ? 'Low or no connectivity, please try again later'
        : error;
      alert(`Problem requesting OCR: ${message}`);
      this.setState({ requestingOCR: false });
    }

    this.setState({ ocrResponse });
  }

  addOCRDataToInspection({
    ggn,
    gln,
    ggn_missing,
    gln_missing,
    ggn_invalid,
    gln_invalid,
    coc,
    coc_invalid,
    coc_missing,
  }: OcrData) {
    const lotProperties: LotProperties =
      cloneDeep((this.props.inspection as LotInspection)?.lotProperties) ??
      ({} as LotProperties);

    if (!!ggn) {
      lotProperties.ggnList = Array.from(
        new Set([...(lotProperties.ggnList ?? []), ggn])
      );
    }

    if (!!gln) {
      lotProperties.glnList = Array.from(
        new Set([...(lotProperties.glnList ?? []), gln])
      );
    }

    if (!!coc) {
      lotProperties.cocList = Array.from(
        new Set([...(lotProperties.cocList ?? []), coc])
      );
    }

    const inputIds = ['label'];

    (
      [
        [ggn_invalid, AG_GGN_INVALID],
        [ggn_missing, AG_GGN_MISSING],
        [gln_invalid, AG_GLN_INVALID],
        [gln_missing, AG_GLN_MISSING],
        [coc_invalid, AG_COC_INVALID],
        [coc_missing, AG_COC_MISSING],
      ] as [boolean, AgrinormQuestionId][]
    ).forEach(([hasIssue, questionId]) => {
      if (hasIssue) {
        inputIds.push(questionId);
      }
    });

    const picture: Picture = {
      ...cloneDeep(this.state.picture),
      inputIds,
      sectionId: SectionType.PackagingAndLabeling,
      sectionName: SectionNames[SectionType.PackagingAndLabeling],
    };

    this.props.onPictureUpdated(picture);
    this.props.updateOCRData(
      lotProperties,
      ggn_missing,
      ggn_invalid,
      gln_missing,
      gln_invalid,
      coc_missing,
      coc_invalid
    );

    // update picture and close request OCR
    this.setState({ picture, ocrResponse: undefined, requestingOCR: false });
  }

  closeOCRModal() {
    this.setState({ ocrResponse: undefined, requestingOCR: false });
  }

  render() {
    const { organisation } = this.props;
    let { picture, ocrResponse, requestingOCR, hideMenu } = this.state;

    // console.log(this.props.pictures)

    // console.log('pictures', this.props.pictures);

    if (!picture) {
      return '';
    }

    const groupNode: any = this.props?.schema?.layout.find(
      (node) =>
        node.sectionType ===
        (this.state.picture.sectionId ?? this.state.picture.imageTag)
    );

    return (
      <div className="page-picture ion-page">
        <IonContent
          ref={this.contentRef}
          className={this.props.editable ? 'editable' : 'no-editable'}
        >
          <div className="wrapper">
            {!!ocrResponse && (
              <div style={{ zIndex: 9999, position: 'relative' }}>
                <ViewOCRExtraction
                  ocrResponse={this.state.ocrResponse}
                  addOCRDataToInspection={this.addOCRDataToInspection.bind(this)}
                  onCancel={() => this.closeOCRModal()}
                  profile={this.props.profile}
                />
              </div>
            )}

            <IonAlert
              isOpen={this.state.showRemovePictureAlert}
              onDidDismiss={() => this.hideRemovePictureAlert()}
              header={i18n.t('PagePicture.confirm')}
              message={i18n.t('PagePicture.doYouReally')}
              buttons={[
                {
                  text: i18n.t('General.no'),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {},
                },
                {
                  text: i18n.t('General.yes'),
                  cssClass: 'danger-button primary',
                  handler: () => {
                    this.removePicture();
                  },
                },
              ]}
            />
            {!ocrResponse && (
              <IonButton
                onClick={(_) => this.props.onDismiss()}
                data-tip={'tag-close'}
                color="dark"
                className="close-button"
              >
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            )}
            <div className="image-area">
              <div className="defect-list">
                {picture?.inputIds
                  ?.map((questionId) => {
                    const questionSpecs = {
                      ...(this.props.schema ?? this.props.inspection?.renderingInfo)
                        ?.questionSpecs,
                    }[questionId];
                    const name = questionSpecs?.displayedName ?? questionId;
                    return { id: questionId, name };
                  })
                  ?.map((q) => (
                    <IonBadge key={q.id} color="dark">
                      {q.name}
                    </IonBadge>
                  ))}
              </div>
              {/* <Swipeable onSwipedRight={() => this.lastPicture()} onSwipedLeft={() => this.nextPicture()}> */}
              <ViewPicture
                id={picture?.id}
                location={
                  this.props.location ?? `${INSPECTION_IMAGES_STORAGE_BASE_PATH}/`
                }
                mode="img"
              />
              {/* </Swipeable> */}

              {this.props.pictures?.length > 1 && (
                <div className="back-button" onClick={(_) => this.prevPicture()}>
                  <IonIcon icon={arrowBackCircle} />
                </div>
              )}

              {this.props.pictures?.length > 1 && (
                <div className="next-button" onClick={(_) => this.nextPicture()}>
                  <IonIcon icon={arrowForwardCircle} />
                </div>
              )}
            </div>
            {this.props.editable && (
              <IonCard
                className={'image-meta ' + (requestingOCR ? 'requestingOCR' : '')}
              >
                <IonList className={'image-tags edit-mode-' + this.props.editMode}>
                  {groupNode && !(requestingOCR || hideMenu) && (
                    <div className="view-defect-node">
                      {this.renderNode(groupNode, -1)}
                    </div>
                  )}

                  {this.props.schema?.layout && (
                    <div className="chip-list">
                      {requestingOCR || hideMenu ? (
                        <></>
                      ) : (
                        getLayoutFilteredByTaggable(this.props.schema).map(
                          (node, index) => (
                            <div
                              key={index}
                              data-tip={`tag-group-${node.name}`}
                              onClick={(_) => this.updateGroupId(node)}
                              className={
                                'ion-chip ' +
                                ((picture.sectionId ?? picture.imageTag) ===
                                node.sectionType
                                  ? ' selected'
                                  : '')
                              }
                            >
                              {SectionNames[node.sectionType] ?? node.sectionType}
                            </div>
                          )
                        )
                      )}

                      {this.props.updateOCRData !== undefined &&
                        !hideMenu &&
                        this.props.inspection.objectType ===
                          LotSchemaObjectTypeEnum.Lot &&
                        !!organisation?.settings &&
                        orgHasPermission(
                          organisation.settings,
                          AppProduct.QualityControl,
                          QCTierAction.LabelReader
                        ) && (
                          <div
                            data-tip={`tag-ocr`}
                            onClick={(_) => {
                              if (navigator.onLine) {
                                this.onOCRRequest();
                              } else {
                                alert(
                                  'Cannot connect to label reader. Please check your internet connection.'
                                );
                              }
                            }}
                            style={{ pointerEvents: requestingOCR ? 'none' : 'all' }}
                            className={'ion-chip ocr-button'}
                          >
                            {requestingOCR ? (
                              <IonIcon
                                style={{ width: '32px', margin: 0, color: 'white' }}
                                className="rotate"
                                icon={syncOutline}
                              />
                            ) : (
                              <IonImg
                                style={{
                                  width: '32px',
                                  margin: 0,
                                  animation: 'fadeInUp .3s both',
                                }}
                                src={'/assets/icon/favicon-32x32.png'}
                              />
                            )}

                            <b>Read labels</b>
                            {!navigator.onLine && (
                              <IonIcon
                                icon={cloudOfflineOutline}
                                color="light"
                              ></IonIcon>
                            )}

                            {/* {requestingOCR && <IonIcon className='rotate' icon={hourglass} />} */}
                          </div>
                        )}
                    </div>
                  )}
                </IonList>
                <div className="bottom-buttons">
                  <IonButton
                    disabled={requestingOCR}
                    color="dark"
                    data-tip={`tag-accept`}
                    onClick={(_) => this.props.onDismiss()}
                  >
                    <IonIcon icon={checkmarkCircle} />
                  </IonButton>
                  <IonButton
                    disabled={requestingOCR}
                    fill="clear"
                    color="dark"
                    className="minimize-button"
                    onClick={(_) => this.setState({ hideMenu: !this.state.hideMenu })}
                  >
                    <IonIcon icon={hideMenu ? chevronUpOutline : chevronDownOutline} />
                  </IonButton>
                  <IonButton
                    disabled={requestingOCR}
                    color="medium"
                    fill="clear"
                    data-tip={`tag-delete`}
                    onClick={(_) => this.showRemovePictureAlert()}
                  >
                    <IonIcon icon={trashBin} />
                  </IonButton>
                </div>
              </IonCard>
            )}
          </div>
        </IonContent>
      </div>
    );
  }
}

export default withContext(PagePicture, ['organisation', 'profile']);
