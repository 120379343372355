import React from 'react';
import { IonButton } from '@ionic/react';

import './CongratulationsPanel.scss';

export function CongratulationsPanel() {
  return (
    <div className="congratulations-panel--wrapper">
      <div className="congratulations-panel--header">&nbsp;</div>
      <div className="congratulations-panel--content">
        <h1>Setup Complete!</h1>
        <p>
          Congratulations on setting up your account! Welcome to our community, and
          we're thrilled to have you on board.
        </p>
      </div>
      <div className="congratulations-panel--footer">
        <a href="/tabs/quality-control">
          <IonButton color={'primary'} className="welcome-panel--button">
            FINISH
          </IonButton>
        </a>
      </div>
    </div>
  );
}
