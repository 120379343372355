import React from 'react';
import './CAgrinormLogo.scss';

interface Props {
  fadeIn?: boolean;
}

export const CAgrinormLogo = ({ fadeIn = false }: Props) => {
  let className = `brand-logo${fadeIn ? ' fade-in' : ''}`;
  return <div className={className}></div>;
};
