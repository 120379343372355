import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonLoading,
  IonBadge,
} from '@ionic/react';
import { toastController } from '@ionic/core';
import { closeOutline } from 'ionicons/icons';
import React from 'react';
import { Lot, Order, Organisation, UserProfile } from './Model';
import './ModalSelectPicturesImport.scss';
import { firestore, storage } from './ConfigFirebase';
import { getLotSnapshot, getOrderSnapshot } from './DataStorage';
import { CardImages } from './CardImages';
import {
  INSPECTION_IMAGES_STORAGE_BASE_PATH,
  SUPPLY_CHAIN_COL_NAME,
} from './GlobalConstants';
import {
  LegacyInspection,
  LotInspection,
  LegacyInspectionReference,
} from './InspectionModel';
import {
  updateLotOrTransitInspectionInRelatedEntities,
  uploadInspectionImages,
} from './DataInspection';
import { cloneDeep } from 'lodash';
import { stringifyInspectionReference } from './ServiceInspection';
import { CFileUpload } from './components/CFileUpload';
import { presentStandardToast } from './HelperIonic';

interface Props {
  profile: UserProfile;
  organisation: Organisation;
  onCancel: Function;

  inspectionReference: LegacyInspectionReference;
  inspection: LegacyInspection;

  supplyChainLot?: boolean;
}

interface State {
  inited: boolean;
  loading?: boolean;
  loadingMessage?: string;
  order?: Order;
  lot?: Lot;
  selection: Object;
  bucket: any[];
}

class ModalSelectPicturesImport extends React.Component<Props, State> {
  private unsubscribers: any[] = [];
  private mounted;
  private fileInput = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    this.state = {
      inited: true,
      selection: {},
      bucket: [],
    };

    this.fileInput = React.createRef();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribers.map((unsubscribe) => unsubscribe());
  }

  async componentDidMount() {
    this.mounted = true;

    const { profile } = this.props;

    // don't fetch order unless user is signed in
    if (!profile) return;

    let { orderId, lotId } = this.props.inspectionReference;

    if (orderId) {
      this.unsubscribers.push(
        getOrderSnapshot(
          firestore,
          profile?.organisationId,
          orderId,
          (order) => {
            if (this.mounted) {
              this.setState({
                order: order ? order : null,
              });
              this.loadFromStorage();
            }
          },
          profile.organisationId
        )
      );
    } else {
      this.unsubscribers.push(
        getLotSnapshot(
          firestore,
          profile?.organisationId,
          lotId,
          (lot: Lot) => {
            if (this.mounted) {
              this.setState({
                lot: lot ? lot : null,
              });
              this.loadFromStorage();
            }
          },
          profile.organisationId,
          this.props.supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined
        )
      );
    }
  }

  ionViewWillLeave() {
    this.unsubscribers.map((unsubscribe) => unsubscribe());
  }

  renderLoading() {
    return (
      <div className="loading">
        <IonSpinner name="dots" />
      </div>
    );
  }

  render() {
    let { orderId, lotId } = this.props.inspectionReference;
    const title = lotId + ' · ' + orderId;
    const amountSelected = Object.values(this.state.selection).reduce(
      (acc, v) => v.length + acc,
      0
    );

    return (
      <div className="modal-select-pictures-import ion-page">
        <IonLoading isOpen={this.state.loading} message={this.state.loadingMessage} />
        <CFileUpload
          fileInputRef={this.fileInput}
          onFilesSelect={this.uploadAttachments.bind(this)}
          onError={() => this.setState({ loading: false })}
        />
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={(_) => this.props.onCancel()}>
                <IonIcon icon={closeOutline} color="dark"></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={(_) => this.fileInput.current.click()}>
                Upload Images
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.state.order === undefined && this.state.lot === undefined ? (
            this.renderLoading()
          ) : this.state.order == null ? (
            this.state.lot == null ? (
              <div className="not-found">order not found</div>
            ) : (
              this.renderLotMessage()
            )
          ) : (
            <>{this.renderAssessments()}</>
          )}
          <IonButton
            onClick={(_) => this.copyImages()}
            slot="fixed"
            className="upload-button"
            disabled={amountSelected === 0}
          >
            Transfer Images
          </IonButton>
        </IonContent>
      </div>
    );
  }

  renderLotMessage() {
    return (
      <div className="not-found">
        Please upload the pictures at the top right corner
      </div>
    );
  }

  async loadFromStorage() {
    // Create a reference under which you want to list
    const orgId = this.props.profile.organisationId;
    const assessmentIdVal = stringifyInspectionReference(
      this.props.inspection.reference
    );
    var listRef = storage
      .ref()
      .child(`/${INSPECTION_IMAGES_STORAGE_BASE_PATH}/${orgId}/${assessmentIdVal}`);
    const pictures = this.props.inspection.pictures ?? [];

    var res = await listRef.listAll();

    const bucket = res.items
      .map((itemRef) => {
        const path = itemRef.fullPath.replace(
          INSPECTION_IMAGES_STORAGE_BASE_PATH + '/',
          ''
        );
        if (!pictures.find((p) => p.id === path)) return { id: path };
        return null;
      })
      .filter((p) => !!p);

    console.log(bucket, pictures);

    this.setState({ bucket });

    return <div>hola</div>;
  }

  renderFromStorage() {
    return (
      <div className="lot-wrapper">
        <div className="lot-id">
          FROM BACKUP STORAGE: {this.state.bucket.length} pics
          {this.state.bucket.length > 0 && (
            <>
              {this.state.selection['bucket']?.length === this.state.bucket.length ? (
                <span
                  onClick={(_) =>
                    this.setState({
                      selection: {
                        ...this.state.selection,
                        bucket: [],
                      },
                    })
                  }
                >
                  unselect all
                </span>
              ) : (
                <span
                  onClick={(_) =>
                    this.setState({
                      selection: {
                        ...this.state.selection,
                        bucket: this.state.bucket.map((pic) => {
                          return { id: pic.id, inputIds: [] };
                        }),
                      },
                    })
                  }
                >
                  select all
                </span>
              )}
            </>
          )}
        </div>
        {this.state.bucket.length > 0 && (
          <CardImages
            hideEmpty={false}
            pictures={this.state.bucket}
            max={this.state.bucket.length}
            selection={this.state.selection['bucket']?.map((pic) => pic.id)}
            onClick={(pic) => {
              let arr = this.state.selection['bucket'] ?? [];
              if (arr.some((picture) => picture.id === pic.id)) {
                var index = arr.findIndex((picture) => picture.id === pic.id);
                if (index > -1) {
                  arr.splice(index, 1);
                }
              } else {
                arr.push(pic);
              }

              this.setState({
                selection: {
                  ...this.state.selection,
                  bucket: arr,
                },
              });
            }}
          />
        )}
      </div>
    );
  }

  renderAssessments() {
    const inspections = Object.values(this.state.order.lotInspectionMap);
    const amountSelected = Object.values(this.state.selection).reduce(
      (acc, v) => v.length + acc,
      0
    );
    let { lotId } = this.props.inspectionReference;

    return (
      <div>
        <div className="message">
          {amountSelected > 0 ? (
            amountSelected + ` image${amountSelected === 1 ? '' : 's'} selected`
          ) : (
            <>
              Select pictures to transfer or press{' '}
              <IonBadge color="medium">UPLOAD IMAGES</IonBadge> to add from a file
              browser
            </>
          )}
        </div>
        {this.renderFromStorage()}
        {inspections
          .filter((a) => a.reference.lotId !== lotId)
          .map((inspection: LotInspection, k) => {
            if (inspection.pictures.length) {
              return (
                <div key={k} className="lot-wrapper">
                  <div className="lot-id">
                    {inspection.reference.lotId} &middot; ({inspection.pictures.length}{' '}
                    pics)
                    {this.state.selection[inspection.reference.lotId]?.length ===
                    inspection.pictures.length ? (
                      <span
                        onClick={(_) =>
                          this.setState({
                            selection: {
                              ...this.state.selection,
                              [inspection.reference.lotId]: [],
                            },
                          })
                        }
                      >
                        unselect all
                      </span>
                    ) : (
                      <span
                        onClick={(_) =>
                          this.setState({
                            selection: {
                              ...this.state.selection,
                              [inspection.reference.lotId]: inspection.pictures,
                            },
                          })
                        }
                      >
                        select all
                      </span>
                    )}
                  </div>
                  <CardImages
                    hideEmpty={false}
                    pictures={inspection.pictures}
                    max={inspection.pictures.length}
                    selection={this.state.selection[inspection.reference.lotId]?.map(
                      (pic) => pic.id
                    )}
                    onClick={(pic) => {
                      console.log('pic', pic);
                      let arr = this.state.selection[inspection.reference.lotId] ?? [];
                      if (arr.some((picture) => picture.id === pic.id)) {
                        var index = arr.findIndex((picture) => picture.id === pic.id);
                        if (index > -1) {
                          arr.splice(index, 1);
                        }
                      } else {
                        arr.push(pic);
                      }

                      this.setState({
                        selection: {
                          ...this.state.selection,
                          [inspection.reference.lotId]: arr,
                        },
                      });
                    }}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    );
  }

  async copyImages() {
    const amountSelected = Object.values(this.state.selection).reduce(
      (acc, v) => v.length + acc,
      0
    );
    if (window.confirm('Transfer ' + amountSelected + ' pictures?')) {
      await this.setState({ loading: true, loadingMessage: 'copying...' });
      const pictures = Object.values(this.state.selection).reduce(
        (acc, v) => v.concat(acc),
        []
      );
      this.props.inspection.pictures = (this.props.inspection.pictures ?? []).concat(
        pictures
      );
      await updateLotOrTransitInspectionInRelatedEntities(
        firestore,
        this.props.profile,
        this.props.inspection,
        [],
        this.props.organisation,
        false,
        this.props.supplyChainLot
      );
      await this.setState({ loading: false });
      this.props.onCancel();
    }
  }

  async uploadAttachments(files: FileList) {
    const { profile, inspection, organisation } = cloneDeep(this.props);

    await this.setState({ loading: true, loadingMessage: 'uploading...' });

    // // upload files
    let { errors, newAttachments } = await uploadInspectionImages(
      storage,
      files,
      profile,
      this.props.inspectionReference
    );
    console.log(errors, newAttachments);

    if (errors.length > 0) {
      return presentStandardToast(
        toastController,
        `Following files could not be uploaded:\r\n${errors
          .map((e) => `File: ${e.filename}, ${e.error}`)
          .join('\r\n')}`
      );
    }

    let pictures = newAttachments.map((a) => {
      return {
        id: a.replace(INSPECTION_IMAGES_STORAGE_BASE_PATH + '/', ''),
        inputIds: ['upload image'],
      };
    });

    inspection.pictures = (inspection.pictures ?? []).concat(pictures);

    await updateLotOrTransitInspectionInRelatedEntities(
      firestore,
      profile,
      inspection,
      [],
      organisation,
      undefined,
      this.props.supplyChainLot
    );

    await this.setState({ loading: false });
    this.props.onCancel();
  }
}

export default ModalSelectPicturesImport;
