// This class mimics the functionality of rxjs' BehaviorSubject
export class SimpleObservable<T> {
  private value: T;
  private listeners: ((value: T) => void)[] = [];

  constructor(initialValue: T) {
    this.value = initialValue;
  }

  public next(value: T): void {
    this.value = value;
    this.listeners.forEach((listener) => listener(value));
  }

  public getValue(): T {
    return this.value;
  }

  public subscribe(listener: (value: T) => void): () => void {
    this.listeners.push(listener);
    listener(this.value);

    return () => {
      this.unsubscribe(listener);
    };
  }

  private unsubscribe(listener: (value: T) => void): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }
}
