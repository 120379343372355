import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import {
  caretDownCircleOutline,
  caretUpCircleOutline,
  documentAttachOutline,
  ellipsisVertical,
  linkOutline,
} from 'ionicons/icons';
import _ from 'lodash';
import React, { RefObject } from 'react';
import './CardOrder.scss';
import { getReportTitle } from './DataReport';
import withContext, { ContextProps } from './HOC/withContext';
import {
  formatDate,
  getLotOrPosQuantity,
  getSplitLotTransferedQuantity,
  resolveLocationName,
  shouldShowPositionQuantity,
} from './HelperUtils';
import { LegacyInspection, LotInspection } from './InspectionModel';
import { LotPopoverModel, LotPosition, Order, Report } from './Model';
import { Article } from './ServiceArticle';
import { InspectionClass, orderInspectionsList } from './ServiceInspection';
import ViewArticleDescription from './ViewArticleDescription';
import ViewContactName from './ViewContactName';
import { ViewInspectionScore } from './ViewInspectionScore';
import ViewReportTypeBadge from './ViewReportTypeBadge';

interface Props {
  report?: Report;
  loading?: boolean;
  showLink?: boolean;
  highlightLotId?: string;
  searchString?: string;
  routerLink?: string;
  onClick?: any;
  hideDone?: boolean;
  displayButtons?: boolean;
  style?: any;

  index?: number;

  openNewLotModal?: (p: LotPosition, o: Order, f: () => void) => void;
  openLinkLotModal?: (p: LotPosition, o: Order, f: () => void) => void;
  onLotUnlink?: (p: LotPosition, o: Order, f: () => void) => void;
}

interface State {
  showBatches: boolean;
  currPosition: LotPosition;
  lotOptionsPopover?: LotPopoverModel;
}

class CardReport extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showBatches: false,
      // showOrders: this.props.profile?.userRoles.includes("COMMERCIAL")
      currPosition: undefined,
      lotOptionsPopover: { visible: false },
    };
  }

  private pageRef: RefObject<HTMLElement> = React.createRef();

  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    const { showBatches: showOrders, lotOptionsPopover } = this.state;
    const { locations } = this.props;

    if (!_.isEqual(locations, nextProps?.locations)) {
      return true;
    }

    if (
      showOrders !== nextState.showBatches ||
      !_.isEqual(lotOptionsPopover, nextState.lotOptionsPopover)
    ) {
      return true;
    }

    if (!this.props.report) {
      return true;
    }

    if (this.props.report?.lastModifiedDate !== nextProps.report?.lastModifiedDate) {
      return true;
    }

    return false;
  }

  getLink() {
    let { showLink, routerLink, report } = this.props;
    if (showLink === false) {
      return null;
    } else if (routerLink !== undefined) {
      return { routerLink };
    }
    // TODO: hacer bien
    // @ts-ignore
    let ref = report.reportReference?.reportId ?? report.latestReportReference.reportId;
    // @ts-ignore
    if (report.hasReportDraft) {
      ref = 'draft/' + report.reportReference.orderId;
    }
    return { routerLink: '/secure/report/' + ref };
  }

  toggleOrders(e) {
    const { showBatches: showOrders } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showBatches: !showOrders });
  }

  getTitle(title) {
    let { searchString } = this.props;

    if (searchString && title.endsWith(searchString)) {
      return (
        <>
          {title.slice(0, title.length - searchString.length)}
          <span className="highlight">{searchString}</span>
        </>
      );
    }

    return title;
  }

  getClassName() {
    let c = 'completed ';
    const { report } = this.props;

    // if (report.qcStatus === "OPEN" || report.qcStatus === "REDO" || report.qcStatus === undefined) {
    //   c = ""
    // } else {
    //   c = "completed "
    // }
    if (this.state.showBatches === true) {
      c = c + ' open ';
    }
    if (report.search?.scores?.has1orLess) {
      c += 'score-1';
    } else if (report.search?.scores?.has2orLess) {
      c += 'score-2';
    } else if (report.search?.scores?.has3orLess) {
      c += 'score-3';
    }
    return c;
  }

  onLotOptionsDismiss() {
    this.setState({ lotOptionsPopover: { visible: false }, currPosition: undefined });
  }

  // reportTypeBadge(report: Report) {
  //   return <IonBadge color={getReportTypeBadgeColor(report)}>{reportTypeToStringMap[report.reportType]}</IonBadge>
  // }

  render() {
    if (this.props.loading) {
      return (
        <IonCard className="card-order skeleton" style={this.props.style}>
          <IonCardHeader>
            <IonCardTitle>
              <IonSkeletonText animated />
              {[0].map((idx) => (
                <div key={idx} style={{ padding: '5px 90px 20px 0' }}>
                  <IonLabel>
                    <IonSkeletonText animated />
                  </IonLabel>
                </div>
              ))}
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
      );
    }

    let { report, highlightLotId } = this.props;
    let { showBatches: showOrders } = this.state;

    let inspections: LegacyInspection[] = orderInspectionsList(report as Order);
    let toggleIcon = showOrders ? caretUpCircleOutline : caretDownCircleOutline;

    const normalPositions = report.positions?.filter((p) => !p.motherLotIds);
    const splitLotPositions = report.positions?.filter((p) => p.motherLotIds);

    let sum = inspections?.reduce((accumulator, element) => {
      return accumulator + (new InspectionClass(element).isCompleted() ? 1 : 0);
    }, 0);

    let orderDone = sum === inspections.length - 1 && report.positions.length > 0;

    if (orderDone && this.props.hideDone === true) {
      return null;
    }

    let onClick = this.props.onClick
      ? { onClick: () => this.props.onClick(report) }
      : null;

    const displayOrderPositions = showOrders;
    const displaySubLots = showOrders && report.hasSplitLot;

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    // today.setDate(today.getDate()+1)

    return (
      <IonCard
        key={report.id}
        className={'card-order ' + this.getClassName()}
        {...this.getLink()}
        {...onClick}
        data-tip={`card-report-index-${this.props.index}`}
      >
        <IonCardHeader>
          <IonCardTitle>
            <IonIcon
              icon={toggleIcon}
              color="medium"
              className="caret"
              onClick={(e) => {
                this.toggleOrders(e);
              }}
            />

            <div className="order-id">
              <IonIcon
                style={{ pointerEvents: 'none', marginLeft: '-2px' }}
                icon={documentAttachOutline}
                className="report-icon"
              />
              <div>{this.getTitle(getReportTitle(report))}</div>
              {report.reportReference.orderIdLinked && (
                <IonIcon icon={linkOutline} className="report-icon" />
              )}
            </div>

            <div className="order-info">
              <IonBadge color="medium">
                {formatDate(report.lastModifiedDate, {
                  dateStyle: 'short',
                  timeStyle: 'short',
                })}
              </IonBadge>
              <ViewReportTypeBadge report={report} />
              {report.reportStatus === 'SHARED' && (
                <IonBadge color="tertiary">SHARED</IonBadge>
              )}
            </div>
          </IonCardTitle>
          <IonCardTitle className="contact capitalize">
            <ViewContactName report={report} profile={this.props.profile} />
            {report.supplierId ? (
              <span>{report.supplierName.toLocaleLowerCase()}</span>
            ) : null}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {/* DISPLAY order positions */}
          {displayOrderPositions &&
            normalPositions?.map((position: LotPosition, index) => {
              const inspection = inspections.find(
                (i) => i.reference?.lotId === position.lotId
              ) as LotInspection;
              // console.log('ass', assessment);
              if (!inspection) return null;

              let splitLotTransferedQuantity: number = getSplitLotTransferedQuantity(
                report,
                position,
                new Article(position?.article).isRaw() ? 'volumeInKg' : 'numBoxes'
              );

              return this.renderPosition(
                index,
                highlightLotId,
                position,
                inspection,
                report,
                splitLotTransferedQuantity,
                undefined,
                !!report.reportType
              );
            })}

          {/* DISPLAY SUBLOTS */}
          {displaySubLots && (
            <>
              {splitLotPositions.length > 0 && (
                <>
                  {/* <IonItemDivider/> */}
                  <div className="sublots-divider">Child batches</div>

                  {splitLotPositions.map((position: LotPosition, index) => {
                    const inspection = inspections.find(
                      (ass) => ass.reference?.lotId === position.lotId
                    ) as LotInspection;
                    // console.log('ass', assessment);
                    if (!inspection) return null;

                    return this.renderPosition(
                      index,
                      highlightLotId,
                      position,
                      inspection,
                      report
                    );
                  })}
                </>
              )}
            </>
          )}
          {report.insight ? (
            <div
              className={
                'insight ' + report.insight.title.replace(' ', '-').toLowerCase()
              }
            >
              {report.insight.title}
            </div>
          ) : (
            ''
          )}
        </IonCardContent>
        {report?.locationId && (
          <div className="location">
            Location: {resolveLocationName(report?.locationId, this.props.locations)}
          </div>
        )}
      </IonCard>
    );
  }

  renderPosition(
    index: number,
    highlightLotId: string,
    position: LotPosition,
    inspection: LotInspection,
    report: Report,
    splitLotTransferedQuantity: number = 0,
    origOrder?: Order,
    isReport?: boolean
  ): JSX.Element {
    let linkedLotId: string = undefined;

    const { profile, displayButtons, organisation } = this.props;

    const displayLotOptions = true;
    const displayLinkedLotId = false;

    const articleClass = new Article(inspection?.lotProperties.article);

    const showQuantity = shouldShowPositionQuantity(position);
    let quantity: string | number = getLotOrPosQuantity(position);
    if (isNaN(quantity)) {
      quantity = '??';
    }

    return (
      <IonItem
        key={index}
        className={
          highlightLotId && position.lotId.endsWith(highlightLotId) && 'highlight'
        }
      >
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-align-items-center">
            <IonCol size="auto" className="lot-actions">
              {/* LOT ACTION BUTTON */}
              {displayLotOptions && displayButtons && (
                <>
                  <IonButton
                    className="lot-options-button"
                    color="dark"
                    fill="clear"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.persist();
                      this.setState({
                        currPosition: position,
                        lotOptionsPopover: {
                          visible: true,
                          event: e,
                          order: report,
                          origOrder,
                        },
                      });
                    }}
                  >
                    <IonIcon icon={ellipsisVertical} />
                  </IonButton>
                </>
              )}
            </IonCol>

            <IonCol className="ion-padding-end col-name">
              <div className="lot-info">
                {isReport && !!linkedLotId ? linkedLotId : position.lotId}
              </div>

              {displayLinkedLotId && (
                <div className="contact-lot-info">
                  <IonIcon icon={linkOutline} />
                  {isReport ? position.lotId : linkedLotId}
                </div>
              )}

              <div>
                <ViewArticleDescription
                  growerId={position.growerId}
                  pills={true}
                  article={position.article}
                />
                <IonBadge
                  mode="md"
                  className={
                    'status ' + inspection.status.replace(/ /g, '-').toLowerCase()
                  }
                >
                  {!['OPEN', 'COMPLETED'].includes(inspection.status) &&
                    inspection.status}
                </IonBadge>
              </div>
            </IonCol>
            <IonCol size="auto" className="col-scoring">
              {new InspectionClass(
                report.lotInspectionMap?.[position.lotId]
              ).isCompleted() && (
                <ViewInspectionScore
                  organisationSettings={organisation?.settings}
                  profile={profile}
                  inspection={inspection}
                  order={report}
                  displayType={'CARD-ORDER'}
                />
              )}

              {showQuantity && (
                <IonBadge color="light-medium" className="quantity">
                  <b>{quantity}</b>
                  <div>{articleClass.getUnit(+quantity)}</div>
                </IonBadge>
              )}
              {splitLotTransferedQuantity > 0 ? (
                <IonBadge color="danger">{`(-${Math.round(
                  splitLotTransferedQuantity
                )})`}</IonBadge>
              ) : (
                ''
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    );
  }
}

export default withContext(CardReport, ['profile', 'locations', 'organisation']);
