import { ctxOrg } from '../App';
import { convertCamelToSentenceCase } from '../HelperUtils';
import {
  FieldStorageCondition,
  GrowingMethodType,
  GrowingMethodTypes,
  GrowingType,
  GrowingTypes,
  IrrigationType,
  IrrigationTypes,
  ProductionType,
  ProductionTypes,
  SubstrateType,
  SubstrateTypes,
} from '../Model';
import { useContext } from 'react';
import { useLocationAdminOptions } from './useLocationAdminOptionsOld';
import firebase from 'firebase/compat/app';
import {
  buildProdSiteInspectionViewPath,
  buildSharedFieldInspectionViewPath,
  buildSharedFieldReportUrl,
} from '../ServiceInspection';
import { ProductionSiteInspectionReference } from '../InspectionModel';

interface UseLocationInterface {
  getProductionTypeLabel: (t: ProductionType) => string;
  getIrrigationSystemLabel: (t: IrrigationType) => string;
  getGrowingMethodLabel: (t: GrowingMethodType) => string;
  getGrowingTypeLabel: (t: GrowingType) => string;
  buildLocationDetailsUrl: (id: string) => string;
  getSubstrateTypeLabel: (t: SubstrateType) => string;
  getFieldStorageConditionLabel: (t: FieldStorageCondition) => string;
  getTimeLabel: (t: number | string) => string;
  buildSharedFieldReportUrl: (id: string) => string;
  buildSharedFieldInspectionViewPath: (sharedReportId: string, id: string) => string;
  buildProdSiteInspectionViewPath: (
    orgId: string,
    reference: ProductionSiteInspectionReference
  ) => string;
  buildGoogleMapsUrl: (g: firebase.firestore.GeoPoint) => string;
}

export const useLocationUtils = (): UseLocationInterface => {
  const org = useContext(ctxOrg);

  const { fieldStorageConditionOptions, timeOptions } = useLocationAdminOptions(
    [],
    [],
    {} as any
  );

  const getFieldStorageConditionLabel = (t: FieldStorageCondition) => {
    return fieldStorageConditionOptions.find(({ value }) => value === t)?.label ?? '';
  };

  const getTimeLabel = (v: number | string) => {
    return timeOptions.find(({ value }) => value === v)?.label ?? '';
  };

  const getProductionTypeLabel = (t: ProductionType) => {
    return convertCamelToSentenceCase(
      Object.entries(ProductionTypes).find(([_, type]) => type === t)?.[0] ?? ''
    );
  };

  const getIrrigationSystemLabel = (t: IrrigationType) => {
    return convertCamelToSentenceCase(
      Object.entries(IrrigationTypes).find(([_, type]) => type === t)?.[0] ?? ''
    );
  };

  const getGrowingTypeLabel = (t: GrowingType) => {
    return convertCamelToSentenceCase(
      Object.entries(GrowingTypes).find(([_, type]) => type === t)?.[0] ?? ''
    );
  };

  const getGrowingMethodLabel = (t: GrowingMethodType) => {
    return convertCamelToSentenceCase(
      Object.entries(GrowingMethodTypes).find(([_, type]) => type === t)?.[0] ?? ''
    );
  };

  const getSubstrateTypeLabel = (t: SubstrateType) => {
    return convertCamelToSentenceCase(
      Object.entries(SubstrateTypes).find(([_, type]) => type === t)?.[0] ?? ''
    );
  };

  const buildLocationDetailsUrl = (fieldId: string) =>
    `/secure/${org.id}/location/${fieldId}`;

  const buildGoogleMapsUrl = (geopoint: firebase.firestore.GeoPoint) =>
    `https://www.google.com/maps?q=${geopoint.latitude},${geopoint.longitude}`;

  return {
    getProductionTypeLabel,
    buildLocationDetailsUrl,
    getIrrigationSystemLabel,
    getGrowingMethodLabel,
    getGrowingTypeLabel,
    getSubstrateTypeLabel,
    getFieldStorageConditionLabel,
    getTimeLabel,
    buildGoogleMapsUrl,
    buildSharedFieldReportUrl,
    buildSharedFieldInspectionViewPath,
    buildProdSiteInspectionViewPath,
  };
};
