import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  // IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import i18n from 'i18next';
import {
  closeCircleOutline,
  closeOutline,
  readerOutline,
  warningOutline,
} from 'ionicons/icons';
import _, { cloneDeep } from 'lodash';
import React, { RefObject, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ctxContacts, ctxLocations, ctxProducts } from './App';
// import AsparagusIcon from './assets/icons/fruits-semicolor/Asparagus.svg';
// import AvocadosIcon from './assets/icons/fruits-semicolor/Avocados.svg';
// import BananaIcon from './assets/icons/fruits-semicolor/Banana.svg';
// import BlackberriesIcon from './assets/icons/fruits-semicolor/Blackberries.svg';
// import BlueberriesIcon from './assets/icons/fruits-semicolor/Blueberries.svg';
// import CarambolaIcon from './assets/icons/fruits-semicolor/Carambola.svg';
// import CarrotIcon from './assets/icons/fruits-semicolor/Carrot.svg';
// import CherriesIcon from './assets/icons/fruits-semicolor/Cherries.svg';
// import DragonfruitIcon from './assets/icons/fruits-semicolor/Dragonfruit.svg';
// import MangoIcon from './assets/icons/fruits-semicolor/Mango.svg';
// import PapayaIcon from './assets/icons/fruits-semicolor/Papaya.svg';
// import PomegranateIcon from './assets/icons/fruits-semicolor/Pomegranate.svg';
// import RaspberriesIcon from './assets/icons/fruits-semicolor/Raspberries.svg';
// import StrawberriesIcon from './assets/icons/fruits-semicolor/Strawberries.svg';
import { firestore } from './ConfigFirebase';
import { lotColRef } from './DataStorage';
import { NOT_AVAILABLE_STRING, SUPPLY_CHAIN_COL_NAME } from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import { ISO3166 } from './HelperInsight';
import {
  formatDate,
  isLocalServer,
  lotId,
  lotIsUserCreated,
  removeSpecialChars,
} from './HelperUtils';
import {
  Contact,
  IArticle,
  Location,
  LocationType,
  LocationTypes,
  Lot,
  LotCreatePayload,
  OrderType,
  ProductView,
  ProductionSiteLocation,
  articlePackagingFields,
  articleToProductArrayZippedFields,
} from './Model';
import { QCFilterType } from './ModelAGTabs';
import './PageNewLot.scss';
import { userHasPermission } from './PermissionsService';
import { Article } from './ServiceArticle';
import ViewAddProductPackagingModal from './ViewAddProductPackagingModal';
import { ViewOrganisationName } from './ViewContactName';
import CWizardNavigation, { NavigationProps } from './components/CWizardNavigation';
import { PackagingType } from './generated/openapi/core';
import { applicationStateObservable } from './simpleObservable/observables';

// const fruits = {
//   'Bananas': BananaIcon,
//   'Banana': BananaIcon,
//   'Blueberries': BlueberriesIcon,
//   'Mangoes': MangoIcon,
//   'Mangos': MangoIcon,
//   'Mango': MangoIcon,
//   'Strawberries': StrawberriesIcon,
//   'Avocado': AvocadosIcon,
//   'Avocados': AvocadosIcon,
//   'Blackberries': BlackberriesIcon,
//   'Carambola': CarambolaIcon,
//   'Carrot': CarrotIcon,
//   'Cherries': CherriesIcon,
//   'Dragonfruit': DragonfruitIcon,
//   'Asparagus': AsparagusIcon,
//   'Pomegranate': PomegranateIcon,
//   'Raspberries': RaspberriesIcon,
//   'Papaya': PapayaIcon,
// }

interface Props {
  onCancel: Function;
  onSave?: (
    lot: LotCreatePayload,
    inspect?: boolean,
    newLotId?: string
  ) => Promise<any>;

  context?: QCFilterType;

  // when editing lot from PageLot
  lotToEdit?: Lot;

  supplyChainLot?: boolean;
}

interface State {
  lot: LotCreatePayload;
  product?: ProductView;
  loading?: boolean;
  modalPackaging: boolean;

  section: PageNewLotSection;

  isLotIdAvailable?: boolean;
  isCheckingLotId?: boolean;

  useSupplierAsGrower: boolean;
  useGrowerAsSupplier: boolean;
  // companyIsTheGrower: boolean;
}

type PageNewLotSection = 'id' | 'source' | 'product' | 'batch' | 'origin';
const sectionOrder: PageNewLotSection[] = [
  'id',
  'product',
  'source',
  'batch',
  'origin',
];

class PageNewLot extends React.Component<Props & ContextProps, State> {
  packagingSelectRef: RefObject<HTMLIonSelectElement> = React.createRef();
  boxNetWeightInput: RefObject<HTMLIonInputElement> = React.createRef();
  originHarvestDateRef: RefObject<HTMLIonDatetimeElement> =
    React.createRef<HTMLIonDatetimeElement>();

  constructor(props: Props) {
    super(props);

    const { lotToEdit } = this.props;
    let product: ProductView;
    let article: IArticle = {} as IArticle;

    const { products } = this.props;

    if (products?.length === 1 && !lotToEdit) {
      article = {
        ...article,
        agProductId: products[0].agProductId,
        productId: products[0].productId,
      };
      product = cloneDeep(products[0]);
      article = this.fillInPropertiesWithOneChoice(article, product);
    }

    let lot: LotCreatePayload = {
      id: lotId(),
      // numBoxes: position?.quantity,
      transfers: [],
      article,
    };

    // if we are coming from PageLot, we populate the state with the existing lot's data
    if (!!lotToEdit) {
      product = this.findProductInAppContext(lotToEdit);
      console.log('product', this.props.products, lotToEdit.article?.agProductId);
      lot = {
        id: lotToEdit.id,
        article: lotToEdit.article,
        transfers: lotToEdit.transfers,
        numBoxes: lotToEdit.transient?.numBoxes,
        growerContactId: lotToEdit.origin?.growerContactId,
        growerGGN: lotToEdit.origin?.growerGGN,
        palletIds: lotToEdit.transient?.palletIds,
        locationId: lotToEdit.transient?.locationId,
        suppliedByContactId: lotToEdit.suppliedByContactId,
        originLocationId: lotToEdit.origin?.locationId,
        originPlotId: lotToEdit.origin?.plotId,
        originHarvestNumber: lotToEdit.origin?.harvestNumber,
        originDaysFromPrevHarvest: lotToEdit.origin?.daysFromPrevHarvest,
        originHarvestDate: !!lotToEdit.origin?.harvestDate
          ? (formatDate(lotToEdit.origin.harvestDate, {}, true) as Date)
          : undefined,
        originPreviousHarvestDate: !!lotToEdit.origin?.previousHarvestDate
          ? (formatDate(lotToEdit.origin.previousHarvestDate, {}, true) as Date)
          : undefined,
        prevOrgLotReferenceId: lotToEdit.previousOrg?.lotReferenceId,
        firstTransferType: lotToEdit.transfers[0].transferType,
        volumeInKg: lotToEdit.transient?.volumeInKg,
      };
    }

    this.state = {
      lot,
      product,
      modalPackaging: false,
      section: !!lotToEdit ? 'product' : 'id',
      isLotIdAvailable: true,
      isCheckingLotId: false,
      useSupplierAsGrower: false,
      useGrowerAsSupplier: false,
    };
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    // console.log(this.props, nextProps)
    // console.log(this.state, nextState)
    if (!_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (!_.isEqual(prevState, this.state) && isLocalServer()) {
      console.log('Lot in State', this.state.lot);
      console.log('Article in State', this.state.lot?.article);
    }
    // Update volume
    const articleClass = new Article(this.state.lot.article);
    if (
      (!_.isEqual(this.state.lot.article, prevState.lot.article) ||
        this.state.lot.numBoxes !== prevState.lot.numBoxes) &&
      !articleClass.isRaw()
    ) {
      this.setState((prevState) => {
        const volumeInKg = articleClass.computeVolumeInKg(this.state.lot.numBoxes);
        return { lot: { ...prevState.lot, volumeInKg } };
      });
    }
  }

  async onSave(inspect: boolean) {
    // Mismatches not calculated for now
    // const mismatches = computeAllocationMismatches(this.props.position, this.state.lot);
    // if (mismatches.length > 0 && !window.confirm('Are you sure you want to save the batch with the current mismatches?')) {
    //   return;
    // }
    const { lot } = this.state;

    this.setState({ loading: true });

    let lotExists = applicationStateObservable.getValue().networkEnabled;

    try {
      lotExists = (
        await lotColRef(
          firestore,
          this.props.profile.organisationId,
          this.props.supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined
        )
          .doc(lot.id)
          .get()
      ).exists;
    } catch (error) {
      console.log('Could not check existence of lot', error);
      lotExists = false;
    }

    const isLotIdAvailable =
      !lotExists || (!!this.props.lotToEdit && lot.id === this.props.lotToEdit?.id);

    if (!isLotIdAvailable) {
      this.setState({ isLotIdAvailable, loading: false, section: 'id' });
    } else {
      console.log('LotCreatePayload', lot);

      // compute volume
      const artClass = new Article(lot.article);
      const volumeInKg = artClass.isRaw()
        ? lot.volumeInKg
        : artClass.computeVolumeInKg(lot.numBoxes ?? 0);

      const isLotIdDirty = !!this.props.lotToEdit && lot.id !== this.props.lotToEdit.id;
      await this.props.onSave(
        { ...lot, volumeInKg },
        inspect,
        isLotIdDirty ? lot.id : undefined
      );
      this.setState({ loading: false });
    }
  }

  findProductInAppContext(lot: LotCreatePayload) {
    return this.props.products.find((p) => lot.article?.agProductId === p.agProductId);
  }

  updateLotId(e: CustomEvent<any>) {
    this.setState({
      isLotIdAvailable: true,
      lot: {
        ...this.state.lot,
        id: removeSpecialChars(e.detail.value).toUpperCase(),
      },
    });
  }

  locationsToOptions(locations: Location[]) {
    return locations.map((l) => ({
      value: l.locationId,
      label: l.name,
    }));
  }

  getSelectLocations(locations: Location[], filterByType?: LocationType) {
    return locations
      .filter((l) => !filterByType || l.locationType === filterByType)
      .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
  }

  getSelectLocationOptions(locations: Location[], filterByType?: LocationType) {
    return this.locationsToOptions(this.getSelectLocations(locations, filterByType));
  }

  onUseSupplierAsGrower() {
    const useSupplierAsGrower = !this.state.useSupplierAsGrower;
    const growerContactId = useSupplierAsGrower
      ? this.state.lot.suppliedByContactId
      : null;
    const growerGGN = useSupplierAsGrower
      ? this.props.contacts.find((c) => c.id === this.state.lot.suppliedByContactId)
          ?.ggn
      : null;

    this.setState({
      useSupplierAsGrower,
      lot: { ...this.state.lot, growerContactId, growerGGN },
    });
  }

  onUseGrowerAsSupplier() {
    const useGrowerAsSupplier = !this.state.useGrowerAsSupplier;
    const supplierContactId = useGrowerAsSupplier
      ? this.state.lot.growerContactId
      : null;

    this.setState({
      useGrowerAsSupplier: useGrowerAsSupplier,
      lot: { ...this.state.lot, suppliedByContactId: supplierContactId },
    });
  }

  render() {
    let {
      lot,
      product,
      loading,
      section,
      isLotIdAvailable,
      isCheckingLotId,
      useSupplierAsGrower,
      useGrowerAsSupplier,
    } = this.state;
    const { profile, lotToEdit, supplyChainLot, locations } = this.props;
    let { article } = lot;

    const sourceOk =
      supplyChainLot ||
      lot.firstTransferType === 'HARVESTED' ||
      (lot.firstTransferType === 'BUY' && !!lot.suppliedByContactId);
    const articleClass = new Article(article);
    const packagingOk = articleClass.validatePackaging();
    const rawVolumeOk = !articleClass.isRaw() || lot.volumeInKg > 0;
    const varietiesOk = article.variety || !product?.varieties?.length;
    const productOk = !!article.productId || !!article.agProductId;
    const originLocationOk =
      !!lot.originLocationId || lot.firstTransferType !== 'HARVESTED';

    let saveDisabled = !(
      productOk &&
      varietiesOk &&
      sourceOk &&
      lot.id &&
      isLotIdAvailable &&
      !isCheckingLotId &&
      packagingOk &&
      rawVolumeOk &&
      originLocationOk
    );

    // console.log('(article.variety || !product?.varieties?.length)', (article.variety || !product?.varieties?.length))

    const canAddLot =
      userHasPermission(profile, 'CREATE', 'LOT') ||
      userHasPermission(profile, 'WRITE', 'LOT') ||
      userHasPermission(profile, 'LINK', 'LOT') ||
      (userHasPermission(profile, 'DO', 'SURVEYING') && supplyChainLot);

    // const mismatches = computeAllocationMismatches(position, lot);

    const addNewPackagingOptionText = 'Add New Packaging';
    const rawOptionText = 'Raw';

    const title = `${!!lotToEdit ? 'Edit' : 'New'} batch`;

    const sectionsWithIssues: PageNewLotSection[] = [];
    if (!isLotIdAvailable || !lot.id) {
      sectionsWithIssues.push('id');
    }
    if (!sourceOk) {
      sectionsWithIssues.push('source');
    }
    if (!varietiesOk || !productOk || !packagingOk) {
      console.log();
      sectionsWithIssues.push('product');
    }
    if (!rawVolumeOk) {
      sectionsWithIssues.push('batch');
    }
    if (!originLocationOk) {
      sectionsWithIssues.push('origin');
    }

    const navigationProps: NavigationProps<PageNewLotSection> = {
      sections: sectionOrder,
      section,
      setSection: (section: PageNewLotSection) => this.setState({ section }),
      dataTipPrefix: 'pnl',
      sectionsWithIssues,
    };

    const emptyArticlePackagingFields: IArticle = articlePackagingFields.reduce(
      (acc: IArticle, key: string) => ({ ...acc, [key]: undefined }),
      {} as IArticle
    );

    return (
      <ctxProducts.Consumer>
        {(productList) => {
          // console.log('productList', productList)
          if (this.props.profile.products) {
            // TODO: options should be either agProductId or productId depending on Organisation Settings. We are assuming here that profile.products is an array of productIds (not agProductIds)
            productList = (productList ?? []).filter(
              (p) => this.props.profile.products.indexOf(p.productId) >= 0
            );
          }

          let varietiesHashMap = [];

          if (!!product) {
            varietiesHashMap = [
              ...varietiesHashMap,
              ...(product.varieties ?? [])
                .map((v, idx) => [v, product.agVarieties?.[idx] ?? v])
                .sort((a, b) => (a[1] < b[1] ? -1 : 1)),
            ];
          }

          const disableEditLotId = !!lotToEdit ? !lotIsUserCreated(lotToEdit) : false;

          return (
            <div className="ion-page page-new-lot">
              <IonLoading isOpen={loading} />
              <IonHeader>
                <IonToolbar>
                  <div className="title">{title}</div>
                  <IonButtons slot="start">
                    <IonButton color="dark" onClick={() => this.props.onCancel()}>
                      <IonIcon icon={closeOutline} slot="icon-only" />
                    </IonButton>
                  </IonButtons>
                  <IonButtons slot="end">
                    <IonButton
                      color="tertiary"
                      disabled={saveDisabled}
                      fill="solid"
                      onClick={(_) => this.onSave(false)}
                      data-tip={'pnl-save-button'}
                    >
                      <IonIcon icon={readerOutline} slot="start" />
                      {'Save'}
                    </IonButton>
                    {!lotToEdit && (
                      <IonButton
                        color="tertiary"
                        disabled={saveDisabled}
                        fill="solid"
                        onClick={(_) => this.onSave(true)}
                        data-tip={'pnl-save-and-inspection-button'}
                      >
                        <IonIcon icon={readerOutline} slot="start" />
                        {'Save & inspect'}
                      </IonButton>
                    )}
                  </IonButtons>
                </IonToolbar>
              </IonHeader>

              <IonContent className="page-new-lot-content">
                <div className="form-container">
                  {/* STEP 0 - ORG*/}
                  <IonList className="form-list organisation" hidden={section !== 'id'}>
                    <IonItemDivider>Identifier</IonItemDivider>

                    <IonItem>
                      <IonLabel>Organisation</IonLabel>
                      <ViewOrganisationName orgId={profile.organisationId} />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Batch id</IonLabel>
                      <IonInput
                        inputmode="text"
                        type="text"
                        placeholder=""
                        disabled={disableEditLotId}
                        value={lot.id}
                        onKeyDown={(e) => {
                          if (removeSpecialChars(e.key) === '') e.preventDefault();
                        }}
                        onIonChange={(e) => this.updateLotId(e)}
                      />
                    </IonItem>

                    {isCheckingLotId ? (
                      <IonItem color="medium">
                        <IonSpinner /> &nbsp;Checking id...
                      </IonItem>
                    ) : !!lot.id && lot.id.length > 0 ? (
                      !isLotIdAvailable && (
                        <IonItem color="danger">
                          <IonIcon icon={closeCircleOutline} />
                          &nbsp;
                          {` Batch id ${lot.id} is unavailable, please try a different id`}
                        </IonItem>
                      )
                    ) : (
                      <IonItem color="danger">
                        <IonIcon icon={warningOutline} /> Batch id cannot be empty
                      </IonItem>
                    )}
                  </IonList>

                  {/* STEP 1 - ARTICLE*/}
                  <IonList className="form-list article" hidden={section !== 'product'}>
                    <IonItemDivider>Article properties</IonItemDivider>

                    {!lotToEdit ? (
                      <SingleSelect
                        color={!productOk ? 'warning' : ''}
                        label={'Product'}
                        placeholder={'Select a product'}
                        options={(productList ?? [])
                          .sort((a: ProductView, b: ProductView) =>
                            a.agProductId.localeCompare(b.agProductId)
                          )
                          .map((p) => ({ value: p.productId, label: p.agProductId }))}
                        selectedOptionFind={(o) => o.value === lot.article?.productId}
                        onChange={(evt) => {
                          return this.chooseProductId(
                            evt.value,
                            (productList ?? []).find((prod) =>
                              [prod.productId, prod.agProductId].includes(evt.value)
                            )
                          );
                        }}
                      />
                    ) : (
                      <IonItem>
                        <IonLabel>Product</IonLabel>
                        <IonInput
                          inputmode="text"
                          type="text"
                          value={lot.article?.agProductId}
                          disabled
                        />
                      </IonItem>
                    )}

                    {!!lot.article?.agProductId && (
                      <>
                        <SingleSelect
                          isClearable
                          label={'Origin'}
                          placeholder={'(optional)'}
                          options={(product?.origins ?? []).sort().map((origin) => ({
                            value: origin,
                            label: `${ISO3166[origin]} (${origin})`,
                          }))}
                          selectedOptionFind={(o) => o.value === lot.article?.origin}
                          onChange={(evt) => {
                            this.setState((prevState) => {
                              return {
                                lot: {
                                  ...prevState.lot,
                                  article: {
                                    ...prevState.lot.article,
                                    origin: evt?.value,
                                  },
                                },
                              };
                            });
                          }}
                        />
                      </>
                    )}

                    {!!lot.article?.agProductId && (
                      <>
                        <SingleSelect
                          color={!varietiesOk ? 'warning' : ''}
                          isDisabled={!product}
                          placeholder={'Select variety'}
                          label={i18n.t('PageNewLot.variety')}
                          options={varietiesHashMap.map(([variety, agVariety]) => ({
                            value: variety,
                            label: agVariety,
                          }))}
                          selectedOptionFind={(o) => o.value === lot.article?.variety}
                          onChange={(evt) => {
                            let agVariety: string = varietiesHashMap.find(
                              ([variety, _]) => {
                                return variety === evt?.value;
                              }
                            )?.[1];

                            this.setState((prevState) => {
                              return {
                                lot: {
                                  ...prevState.lot,
                                  article: {
                                    ...prevState.lot.article,
                                    variety: evt?.value,
                                    agVariety,
                                  },
                                },
                              };
                            });
                          }}
                        />

                        <IonItemDivider>Packaging and weight</IonItemDivider>

                        {!this.state.modalPackaging && (
                          <SingleSelect
                            label={i18n.t('PageNewLot.packaging')}
                            color={!packagingOk ? 'warning' : ''}
                            isDisabled={!product}
                            placeholder={'Select packaging'}
                            selectedOptionFind={(o) => {
                              const value = articleClass.isRaw()
                                ? rawOptionText
                                : articleClass.getPackagingRepr();
                              return o.value === value;
                            }}
                            options={[
                              addNewPackagingOptionText,
                              rawOptionText,
                              ...(
                                this.findProductInAppContext(lot)?.packaging ?? []
                              ).sort(),
                            ].map((packaging, i) => ({
                              value: packaging,
                              label: packaging.replace('X', 'x'),
                            }))}
                            onChange={(evt) => {
                              // prevent updates while adding new packaging
                              if (this.state.modalPackaging) {
                                return;
                              }
                              if (evt?.value === addNewPackagingOptionText) {
                                this.setState({
                                  modalPackaging: true,
                                  lot: {
                                    ...lot,
                                    article: {
                                      ...article,
                                      ...emptyArticlePackagingFields,
                                      packagingType: undefined,
                                    },
                                  },
                                });
                              } else if (evt?.value === rawOptionText) {
                                this.setArticle(
                                  {
                                    ...article,
                                    packagingType: PackagingType.Raw,
                                    ...emptyArticlePackagingFields,
                                  },
                                  'select packaging PNL'
                                );
                              } else {
                                const newArticleClass = new Article({
                                  ...article,
                                  ...emptyArticlePackagingFields,
                                  packagingType: undefined,
                                });
                                newArticleClass.computePackagingRelatedFieldsFromPackagingString(
                                  true,
                                  evt?.value
                                );
                                this.setState((prevState) => {
                                  return {
                                    lot: {
                                      ...prevState.lot,
                                      article: newArticleClass.getArticle(),
                                    },
                                  };
                                });
                              }
                            }}
                          />
                        )}

                        {(
                          [
                            PackagingType.ConsumerUnitsWeight,
                            PackagingType.Bulk,
                          ] as PackagingType[]
                        ).includes(article?.packagingType) && (
                          <IonItem>
                            <IonLabel>Box net weight (Kg)</IonLabel>
                            <IonInput
                              inputmode="decimal"
                              type="number"
                              disabled
                              data-tip={'pnl-consumer-unit-weight-net-weight'}
                              value={article?.boxNetWeightInKg}
                            />
                          </IonItem>
                        )}

                        {(
                          [PackagingType.ConsumerUnitsPieces] as PackagingType[]
                        ).includes(article?.packagingType) && (
                          <IonItem color={!packagingOk ? 'warning' : ''}>
                            <IonLabel>Box net weight (Kg)</IonLabel>
                            <IonInput
                              inputmode="decimal"
                              type="number"
                              placeholder="enter box net weight"
                              data-tip={'pnl-consumer-unit-pieces-net-weight'}
                              ref={this.boxNetWeightInput}
                              onFocus={(e: any) => e?.target?.select()}
                              value={article?.boxNetWeightInKg}
                              onIonChange={(evt) => {
                                const boxNetWeightInKg = isNaN(
                                  parseFloat(evt.detail.value)
                                )
                                  ? undefined
                                  : parseFloat(evt.detail.value);
                                this.setArticle(
                                  {
                                    ...article,
                                    boxNetWeightInKg,
                                    avgPieceWeightInGrams: new Article(
                                      { ...article, boxNetWeightInKg },
                                      true
                                    ).computeAvgPieceWeightInGrams(true),
                                  },
                                  'box net PNL'
                                );
                              }}
                            />
                          </IonItem>
                        )}

                        {(
                          [
                            PackagingType.Bulk,
                            PackagingType.ConsumerUnitsPieces,
                            PackagingType.ConsumerUnitsWeight,
                          ] as PackagingType[]
                        ).includes(article?.packagingType) && (
                          <IonItem>
                            <IonLabel>Box gross weight (Kg)</IonLabel>
                            <IonInput
                              inputmode="decimal"
                              type="number"
                              placeholder="(optional)"
                              data-tip={'pnl-gross-weight'}
                              value={article?.boxGrossWeightInKg}
                              onIonChange={(evt) =>
                                this.setArticle(
                                  {
                                    ...article,
                                    boxGrossWeightInKg: isNaN(
                                      parseFloat(evt.detail.value)
                                    )
                                      ? undefined
                                      : parseFloat(evt.detail.value),
                                  },
                                  'box gross PNL'
                                )
                              }
                            />
                          </IonItem>
                        )}

                        <IonItem>
                          <IonLabel>Avg piece weight (g)</IonLabel>
                          <IonInput
                            inputmode="decimal"
                            type="number"
                            placeholder={
                              article?.packagingType ===
                              PackagingType.ConsumerUnitsPieces
                                ? 'enter avg piece weight'
                                : '(optional)'
                            }
                            value={article?.avgPieceWeightInGrams}
                            onFocus={(e: any) => e?.target?.select()}
                            onIonChange={(evt) =>
                              this.setState((prevState) => {
                                return {
                                  lot: {
                                    ...prevState.lot,
                                    article: {
                                      ...prevState.lot.article,
                                      avgPieceWeightInGrams: isNaN(
                                        parseFloat(evt.detail.value)
                                      )
                                        ? undefined
                                        : parseFloat(evt.detail.value),
                                    },
                                  },
                                };
                              })
                            }
                          />
                        </IonItem>

                        <IonItemDivider>Other</IonItemDivider>

                        <SingleSelect
                          label={'Brand'}
                          isDisabled={!product}
                          placeholder={'(optional)'}
                          options={(product?.brands ?? [])
                            .sort()
                            .map((brand) => ({ label: brand, value: brand }))}
                          selectedOptionFind={(o) => o.value === lot.article?.brand}
                          onChange={(evt) =>
                            this.setState((prevState) => {
                              return {
                                lot: {
                                  ...prevState.lot,
                                  article: {
                                    ...prevState.lot.article,
                                    brand: evt?.value,
                                  },
                                },
                              };
                            })
                          }
                        />

                        <IonItem>
                          <IonLabel>Is Bio?</IonLabel>
                          <IonCheckbox
                            disabled={!product}
                            data-tip={'pnl-bio'}
                            checked={!!lot.article?.isBio}
                            onIonChange={(_) =>
                              this.setState({
                                lot: {
                                  ...lot,
                                  article: {
                                    ...lot.article,
                                    isBio: !lot.article?.isBio,
                                  },
                                },
                              })
                            }
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel>Is Organic?</IonLabel>
                          <IonCheckbox
                            disabled={!product}
                            data-tip={'pnl-organic'}
                            checked={!!lot.article?.isOrganic}
                            onIonChange={(_) =>
                              this.setState({
                                lot: {
                                  ...lot,
                                  article: {
                                    ...lot.article,
                                    isOrganic: !lot.article?.isOrganic,
                                  },
                                },
                              })
                            }
                          />
                        </IonItem>

                        <IonItem>
                          {/* TODO: change input for select */}
                          <IonLabel>Size</IonLabel>
                          <IonInput
                            inputmode="text"
                            type="text"
                            placeholder="(optional)"
                            disabled={!product}
                            value={lot.article?.size}
                            onIonChange={(evt) =>
                              this.setState({
                                lot: {
                                  ...lot,
                                  article: { ...lot.article, size: evt.detail.value },
                                },
                              })
                            }
                          />
                        </IonItem>
                      </>
                    )}
                  </IonList>

                  {/* STEP 2 - SOURCE*/}
                  <IonList
                    className="form-list organisation"
                    hidden={section !== 'source'}
                  >
                    <IonItemDivider>Source</IonItemDivider>

                    {!supplyChainLot && (
                      <SingleSelect
                        label={'Source'}
                        color={!lot.firstTransferType ? 'warning' : ''}
                        placeholder={'Select source'}
                        options={[
                          {
                            value: 'BUY',
                            label: `I'm buying it`,
                          },
                          {
                            value: 'HARVESTED',
                            label: `I'm the grower`,
                          },
                        ]}
                        selectedOptionFind={(o) => o.value === lot.firstTransferType}
                        onChange={(evt) => {
                          const contactId =
                            evt?.value === 'HARVESTED' ? profile.organisationId : null;
                          this.setState({
                            lot: {
                              ...lot,
                              firstTransferType: evt?.value as OrderType,
                              growerContactId: contactId,
                              suppliedByContactId: contactId,
                              growerGGN: null,
                              originLocationId: undefined,
                              originPlotId: undefined,
                            },
                            useSupplierAsGrower: false,
                          });
                        }}
                      />
                    )}

                    {(supplyChainLot || lot.firstTransferType === 'BUY') && (
                      <>
                        {!supplyChainLot && <IonItemDivider />}
                        <ctxContacts.Consumer>
                          {(contactList) => {
                            const suppliers: Contact[] = (contactList ?? []).filter(
                              (c) => c.type?.includes('SELLER')
                            );
                            const growers: Contact[] = (contactList ?? []).filter((c) =>
                              c.type?.includes('GROWER')
                            );
                            const supplierOptions = (
                              useGrowerAsSupplier ? growers : suppliers
                            ).map((g) => ({ value: g.id, label: g.name }));

                            const supplierPlaceholder = supplyChainLot
                              ? '(optional)'
                              : 'please select supplier';
                            const growerPlaceholder = supplyChainLot
                              ? '(optional)'
                              : 'please select grower';

                            const selectedSupplier = supplierOptions.find(
                              (g) =>
                                g.value === lot.suppliedByContactId &&
                                !!lot.suppliedByContactId
                            ) ?? { value: undefined, label: supplierPlaceholder };
                            const growerOptions = (
                              useSupplierAsGrower ? suppliers : growers
                            ).map((g) => ({ value: g.id, label: g.name }));
                            const selectedGrower = growerOptions.find(
                              (g) =>
                                g.value === lot.growerContactId && !!lot.growerContactId
                            ) ?? { value: undefined, label: growerPlaceholder };

                            const ggn = growers.find(
                              (g) =>
                                g.id === lot.growerContactId && !!lot.growerContactId
                            )?.ggn;
                            const ggnDisplay = ggn ?? NOT_AVAILABLE_STRING;
                            return (
                              <>
                                <IonItem
                                  className="select-item z4"
                                  color={!sourceOk ? 'warning' : ''}
                                >
                                  <IonLabel>Supplier</IonLabel>
                                  <Select
                                    isDisabled={useGrowerAsSupplier}
                                    options={supplierOptions}
                                    placeholder={supplierPlaceholder}
                                    value={selectedSupplier}
                                    isClearable
                                    classNamePrefix={'react-select'}
                                    menuPortalTarget={document.body}
                                    onChange={(evt) => {
                                      const suppliedByContactId = evt?.value ?? null;
                                      const newLot = { ...lot, suppliedByContactId };
                                      if (useSupplierAsGrower) {
                                        newLot.growerContactId = suppliedByContactId;
                                        newLot.growerGGN = growers.find(
                                          (g) => g.id === suppliedByContactId
                                        )?.ggn;
                                      }
                                      this.setState({
                                        lot: newLot,
                                        useSupplierAsGrower: !suppliedByContactId
                                          ? false
                                          : useSupplierAsGrower,
                                      });
                                    }}
                                  />
                                </IonItem>

                                <IonItem
                                  className="select-item z3"
                                  color={
                                    !sourceOk && !lot.growerContactId ? 'warning' : ''
                                  }
                                >
                                  <IonLabel>Grower</IonLabel>
                                  <Select
                                    isDisabled={useSupplierAsGrower}
                                    placeholder={growerPlaceholder}
                                    isClearable
                                    options={growerOptions}
                                    value={selectedGrower}
                                    classNamePrefix={'react-select'}
                                    menuPortalTarget={document.body}
                                    onChange={(evt) => {
                                      const growerContactId = evt?.value ?? null;
                                      const newLot = {
                                        ...lot,
                                        growerContactId,
                                        growerGGN:
                                          evt != null
                                            ? growers.find((g) => g.id === evt.value)
                                                ?.ggn
                                            : null,
                                      };
                                      if (useGrowerAsSupplier) {
                                        newLot.suppliedByContactId = growerContactId;
                                      }
                                      this.setState({
                                        useGrowerAsSupplier: !growerContactId
                                          ? false
                                          : useGrowerAsSupplier,
                                        lot: newLot,
                                      });
                                    }}
                                  />
                                </IonItem>

                                {(!lot.growerContactId || useSupplierAsGrower) &&
                                  !!lot.suppliedByContactId && (
                                    <IonItem>
                                      <IonLabel
                                        color={
                                          !!lot.suppliedByContactId ? 'dark' : 'medium'
                                        }
                                      >
                                        <i>Supplier is also the grower</i>
                                      </IonLabel>
                                      <IonCheckbox
                                        disabled={!lot.suppliedByContactId}
                                        checked={useSupplierAsGrower}
                                        onIonChange={() => this.onUseSupplierAsGrower()}
                                      />
                                    </IonItem>
                                  )}

                                {(!lot.suppliedByContactId || useGrowerAsSupplier) &&
                                  !!lot.growerContactId && (
                                    <IonItem>
                                      <IonLabel
                                        color={
                                          !!lot.growerContactId ? 'dark' : 'medium'
                                        }
                                      >
                                        <i>Grower is also the supplier</i>
                                      </IonLabel>
                                      <IonCheckbox
                                        disabled={!lot.growerContactId}
                                        checked={useGrowerAsSupplier}
                                        onIonChange={() => this.onUseGrowerAsSupplier()}
                                      />
                                    </IonItem>
                                  )}

                                {lot.growerContactId && (
                                  <IonItem>
                                    <IonLabel>GGN</IonLabel>
                                    <div>{ggnDisplay}</div>
                                  </IonItem>
                                )}
                              </>
                            );
                          }}
                        </ctxContacts.Consumer>

                        <IonItem>
                          <IonLabel>Supplier reference</IonLabel>
                          <IonInput
                            inputmode="text"
                            type="text"
                            placeholder={'(optional)'}
                            value={lot.prevOrgLotReferenceId}
                            onIonChange={(evt) =>
                              this.setState({
                                lot: {
                                  ...lot,
                                  prevOrgLotReferenceId: evt.detail.value,
                                },
                              })
                            }
                          />
                        </IonItem>

                        {isCheckingLotId ? (
                          <IonItem color="medium">
                            <IonSpinner /> &nbsp;Checking id...
                          </IonItem>
                        ) : !!lot.id && lot.id.length > 0 ? (
                          !isLotIdAvailable && (
                            <IonItem color="danger">
                              <IonIcon icon={closeCircleOutline} />
                              &nbsp;
                              {` Batch id ${lot.id} is unavailable`}
                            </IonItem>
                          )
                        ) : (
                          <IonItem color="danger">
                            <IonIcon icon={warningOutline} /> Batch id cannot be empty
                          </IonItem>
                        )}
                      </>
                    )}
                  </IonList>

                  {/* STEP 3 - TRANSIENT*/}
                  <IonList className="form-list transient" hidden={section !== 'batch'}>
                    <IonItemDivider>Batch properties</IonItemDivider>
                    {canAddLot && (
                      <>
                        {!articleClass.isRaw() && (
                          <IonItem color={!rawVolumeOk ? 'warning' : ''}>
                            <IonLabel>{i18n.t('PageNewLot.quantity')}</IonLabel>
                            <IonInput
                              inputmode="numeric"
                              type="number"
                              placeholder={'(optional)'}
                              value={lot.numBoxes}
                              onIonChange={(evt) => {
                                const numBoxes = isNaN(parseInt(evt.detail.value))
                                  ? undefined
                                  : parseInt(evt.detail.value);
                                this.setState({
                                  lot: {
                                    ...lot,
                                    numBoxes,
                                  },
                                });
                              }}
                            />
                          </IonItem>
                        )}

                        <IonItem color={!rawVolumeOk ? 'warning' : ''}>
                          <IonLabel>Volume in Kg</IonLabel>
                          <IonInput
                            inputmode="numeric"
                            type="number"
                            placeholder={articleClass.isRaw() ? 'enter volume' : 'N/A'}
                            value={lot.volumeInKg}
                            disabled={!articleClass.isRaw()}
                            onIonChange={(evt) => {
                              if (!articleClass.isRaw()) return;
                              this.setState({
                                lot: {
                                  ...lot,
                                  volumeInKg: isNaN(parseInt(evt.detail.value))
                                    ? undefined
                                    : parseInt(evt.detail.value),
                                },
                              });
                            }}
                          />
                        </IonItem>

                        <IonItem className="select-item z2">
                          <IonLabel>Pallet ids</IonLabel>
                          <CreatableSelect
                            isClearable={false}
                            defaultValue={
                              lot.palletIds?.length > 0
                                ? (lot.palletIds ?? []).map((pId) => ({
                                    value: pId,
                                    label: pId,
                                  }))
                                : undefined
                            }
                            options={(lot.palletIds ?? []).map((pId) => ({
                              value: pId,
                              label: pId,
                            }))}
                            values={(lot.palletIds ?? []).map((pid) => ({
                              value: pid,
                              label: pid,
                            }))}
                            placeholder={'Pallet id + Enter (optional)'}
                            menuPortalTarget={document.body}
                            classNamePrefix={'react-select'}
                            onChange={(evt) => {
                              console.log(evt);
                              this.setState({
                                lot: {
                                  ...lot,
                                  palletIds: (evt ?? []).map((e) => e.value),
                                },
                              });
                            }}
                            isMulti
                          />
                        </IonItem>

                        <IonItem className="select-item z1">
                          <IonLabel>Warehouse</IonLabel>
                          <ctxLocations.Consumer>
                            {(locations) => {
                              const options = this.getSelectLocationOptions(
                                locations || [],
                                LocationTypes.Warehouse
                              );
                              const selectedLocation =
                                options.find(
                                  (g) => g.value === lot.locationId && !!lot.locationId
                                ) ?? null;

                              const placeholder =
                                options.length > 0
                                  ? '(optional)'
                                  : 'No warehouses available';

                              return (
                                <Select
                                  options={options}
                                  classNamePrefix={'react-select'}
                                  value={selectedLocation}
                                  noOptionsMessage={() =>
                                    `You can add warehouses in the location admin page`
                                  }
                                  placeholder={placeholder}
                                  isClearable
                                  menuPortalTarget={document.body}
                                  onChange={(evt) => {
                                    this.setState({
                                      lot: {
                                        ...lot,
                                        locationId: evt?.value ?? null,
                                      },
                                    });
                                  }}
                                />
                              );
                            }}
                          </ctxLocations.Consumer>
                        </IonItem>
                      </>
                    )}
                  </IonList>

                  {/* STEP 4 - ORIGIN */}
                  <IonList className="form-list origin" hidden={section !== 'origin'}>
                    <IonItemDivider>Origin properties</IonItemDivider>

                    {/* Display production site selector if the org is the grower */}
                    {
                      <IonItem
                        className="select-item z2"
                        color={!originLocationOk ? 'warning' : ''}
                      >
                        <IonLabel>Production site</IonLabel>
                        {(() => {
                          const allLocations = this.getSelectLocations(
                            locations,
                            LocationTypes.ProductionSite
                          );
                          const isBuyer = lot.firstTransferType !== 'HARVESTED';
                          // If buying, and a grower is specified, filter the list of production sites to those that belong to the grower
                          const filteredLocations = !isBuyer
                            ? allLocations
                            : !!lot.growerContactId
                            ? allLocations
                                .filter((l) => l.locationType === 'PRODUCTION_SITE')
                                .filter(
                                  (l) =>
                                    (l as ProductionSiteLocation)
                                      .associatedPartnerId === lot.growerContactId
                                )
                            : [];
                          const options = this.locationsToOptions(filteredLocations);
                          const value =
                            options.find((o) => lot.originLocationId === o.value) ??
                            null;
                          const placeholder =
                            options.length > 0
                              ? 'Select a site'
                              : 'No production sites available';

                          return (
                            <Select
                              options={options}
                              classNamePrefix={'react-select'}
                              value={value}
                              placeholder={placeholder}
                              noOptionsMessage={() =>
                                `You can add production sites in the location admin page`
                              }
                              menuPortalTarget={document.body}
                              onChange={(evt) => {
                                this.setState({
                                  lot: {
                                    ...lot,
                                    originLocationId: evt?.value ?? undefined,
                                  },
                                });
                              }}
                            />
                          );
                        })()}
                      </IonItem>
                    }

                    <IonItem>
                      <IonLabel>Plot id</IonLabel>
                      <IonInput
                        inputmode="text"
                        type="text"
                        placeholder="(optional)"
                        debounce={300}
                        value={lot.originPlotId}
                        onIonChange={(evt) =>
                          this.setState({
                            lot: { ...lot, originPlotId: evt.detail.value },
                          })
                        }
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Harvest date</IonLabel>
                      <IonDatetime
                        pickerOptions={{
                          buttons: [
                            {
                              text: 'Clear',
                              cssClass: 'alert-button danger-button',
                              handler: (e) => {
                                this.originHarvestDateRef.current.value = '';
                                this.setState({
                                  lot: {
                                    ...lot,
                                    originHarvestDate: undefined,
                                  },
                                });
                              },
                            },
                            {
                              text: 'Done',
                              handler: (evt) => {
                                const date = `${evt.year.text}/${evt.month.text}/${evt.day.text}`;
                                this.setState({
                                  lot: {
                                    ...lot,
                                    originHarvestDate: new Date(date),
                                  },
                                });
                              },
                            },
                          ],
                        }}
                        ref={this.originHarvestDateRef}
                        displayFormat="DD/MM/YYYY"
                        placeholder="(optional)"
                        value={
                          !!lot?.originHarvestDate
                            ? new Date(lot?.originHarvestDate).toDateString()
                            : ''
                        }
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Previous harvest date</IonLabel>
                      <IonDatetime
                        pickerOptions={{
                          buttons: [
                            {
                              text: 'Clear',
                              cssClass: 'alert-button danger-button',
                              handler: (e) => {
                                this.setState({
                                  lot: {
                                    ...lot,
                                    originPreviousHarvestDate: undefined,
                                  },
                                });
                              },
                            },
                            {
                              text: 'Done',
                              handler: (evt) => {
                                const date = `${evt.year.text}/${evt.month.text}/${evt.day.text}`;
                                this.setState({
                                  lot: {
                                    ...lot,
                                    originPreviousHarvestDate: new Date(date),
                                  },
                                });
                              },
                            },
                          ],
                        }}
                        displayFormat="DD/MM/YYYY"
                        placeholder="(optional)"
                        value={
                          !!lot?.originPreviousHarvestDate
                            ? new Date(lot?.originPreviousHarvestDate).toDateString()
                            : ''
                        }
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Days since previous harvest</IonLabel>
                      <IonInput
                        inputmode="numeric"
                        type="number"
                        placeholder="(optional)"
                        debounce={300}
                        value={lot.originDaysFromPrevHarvest}
                        onIonChange={(evt) =>
                          this.setState({
                            lot: {
                              ...lot,
                              originDaysFromPrevHarvest: +evt.detail.value,
                            },
                          })
                        }
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Harvest number</IonLabel>
                      <IonInput
                        inputmode="numeric"
                        type="number"
                        placeholder="(optional)"
                        debounce={300}
                        value={lot.originHarvestNumber}
                        onIonChange={(evt) =>
                          this.setState({
                            lot: { ...lot, originHarvestNumber: +evt.detail.value },
                          })
                        }
                      />
                    </IonItem>
                  </IonList>

                  {/* STEP 5 - PREVIOUS ORG */}
                  {/* TODO */}
                </div>

                <CWizardNavigation {...navigationProps} />
              </IonContent>

              {this.state.modalPackaging && (
                <ViewAddProductPackagingModal
                  article={this.state.lot?.article}
                  setArticle={this.setArticle.bind(this)}
                  agProductId={this.state.product?.agProductId}
                  orgId={this.props.organisation?.id}
                  packagingArray={
                    this.props.products?.find(
                      (p) => p.agProductId === this.state.product?.agProductId
                    )?.packaging ?? []
                  }
                  openModal={this.state.modalPackaging}
                  setOpenModal={(value: boolean) => {
                    // if (!value && this.state.modalPackaging) this.packagingSelectRef.current.open();
                    this.setState({ modalPackaging: value });
                  }}
                />
              )}
            </div>
          );
        }}
      </ctxProducts.Consumer>
    );
  }

  setArticle = (article: IArticle, calledFrom: string) => {
    this.setState((prevState) => {
      const newArticle = { ...prevState.lot.article, ...article };
      // console.log("SET ARTICLE CALLED FROM", calledFrom, newArticle)
      return { lot: { ...prevState.lot, article: newArticle } };
    });
  };

  private fillInPropertiesWithOneChoice(article: IArticle, product: ProductView) {
    articleToProductArrayZippedFields.forEach(([list, param]) => {
      if (product[list]?.length === 1) {
        article = { ...article, [param]: product[list][0] };
      }
    });

    return article;
  }

  private chooseProductId(productId: string, product: ProductView) {
    let article: IArticle = {
      productId,
      agProductId: product?.agProductId,
    } as IArticle;

    article = this.fillInPropertiesWithOneChoice(article, product);

    this.setState((prevState) => {
      return {
        lot: {
          ...prevState.lot,
          article,
        },
        product,
      };
    });
  }
}

export default withContext(PageNewLot, [
  'profile',
  'products',
  'contacts',
  'organisation',
  'locations',
]);

interface Option {
  value: string;
  label: string;
}
interface SSProps {
  options: Option[];
  label: string;
  placeholder: string;
  isClearable?: boolean;
  color?: string;
  isDisabled?: boolean;
  selectedOptionFind: (o: Option) => boolean;
  onChange: (o: Option) => void;
}

const SingleSelect = ({
  options,
  label,
  selectedOptionFind,
  onChange,
  placeholder,
  isClearable = false,
  isDisabled = false,
  color,
}: SSProps) => {
  const selectedOption = options.find(selectedOptionFind) ?? null;
  const [zIndex, setZIndex] = useState<number>(1);

  return (
    <IonItem className={`select-item z${zIndex}`} color={color}>
      <IonLabel>{label}</IonLabel>
      <Select
        onMenuOpen={() => setZIndex(5)}
        onMenuClose={() => setZIndex(1)}
        menuPlacement="auto"
        minMenuHeight={250}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        options={options}
        placeholder={placeholder}
        value={selectedOption}
        isClearable={isClearable}
        classNamePrefix={'react-select'}
        onChange={onChange}
      />
    </IonItem>
  );
};
