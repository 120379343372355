/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddOrUpdateLocationRequest } from '../../../platform/location'
import type { GetLocationResponse } from '../../../platform/location'
import type { GetMultipleLocationsResponse } from '../../../platform/location'
import type { LocationType } from '../../../platform/location-base'
import type { CancelablePromise } from '../../@api_core/CancelablePromise'
import type { BaseHttpRequest } from '../../@api_core/BaseHttpRequest'
export class LocationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add Location
     * Adds a new location
     * @returns GetLocationResponse Successful Response
     * @throws ApiError
     */
    public addLocation({
        requestBody,
    }: {
        requestBody: AddOrUpdateLocationRequest
    }): CancelablePromise<GetLocationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/location',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        })
    }
    /**
     * Delete Location
     * Deletes a location
     * @returns string Successful Response
     * @throws ApiError
     */
    public deleteLocation({ id }: { id: string }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/location/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Not authenticated`,
                404: `Location not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Location
     * Fetches a single location by `id`
     * @returns GetLocationResponse Successful Response
     * @throws ApiError
     */
    public getLocation({ id }: { id: string }): CancelablePromise<GetLocationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/location/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Not authenticated`,
                404: `Location not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Update Location
     * Updates an existing location
     * @returns GetLocationResponse Successful Response
     * @throws ApiError
     */
    public updateLocation({
        id,
        requestBody,
    }: {
        id: string
        requestBody: AddOrUpdateLocationRequest
    }): CancelablePromise<GetLocationResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/location/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                404: `Location not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Locations
     * Fetches a list of locations. It accepts `location_type`     as optional query parameter
     * @returns GetMultipleLocationsResponse Successful Response
     * @throws ApiError
     */
    public getLocations({
        locationType,
    }: {
        locationType?: LocationType | null
    }): CancelablePromise<GetMultipleLocationsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/locations',
            query: {
                location_type: locationType,
            },
            errors: {
                401: `Not authenticated`,
                422: `Validation Error`,
            },
        })
    }
}
