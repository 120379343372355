import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import i18n from './ServiceI18n';
import { I18nextProvider } from 'react-i18next';
import { setupConfig } from '@ionic/react';
import { isPlatform } from '@ionic/react';
import { createAnimation } from '@ionic/react';

// ------------------------------------------------------------------------------------------------
// https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/persist
// ------------------------------------------------------------------------------------------------
if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then((persistent) => {
    if (persistent) {
      console.log('Storage will not be cleared except by explicit user action');
    } else {
      console.log('Storage may be cleared by the UA under storage pressure.');
    }
  });
}

// ------------------------------------------------------------------------------------------------
// https://github.com/facebook/react/pull/22114
// read more about the react warning, 99% of the times this warning does not mean mem leak.
// ------------------------------------------------------------------------------------------------
// const KNOWN_VIOLATIONS = [
//   "Warning: Can't perform a React state update on an unmounted"
// ]

// const configureConsoleError = (silenceKnownErrors = true): void => {
//   if (silenceKnownErrors) {
//     const oldError = console.error
//     console.error = (...args) => {
//       const firstArg = args[0]
//       if (typeof firstArg === 'string' && KNOWN_VIOLATIONS.some((v) => firstArg.includes(v))) {
//         console.log('prevented error showing')
//         return
//       }
//       oldError(...args)
//     }
//   }
// }
// configureConsoleError()

// ------------------------------------------------------------------------------------------------
// IONIC CUSTOM ANIMATIONS
// ------------------------------------------------------------------------------------------------
const enterAnimation = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'translateX(50px)' },
      { offset: 1, opacity: '1', transform: 'translateX(0px)' },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(100)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const mobileModalAnimation = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0', '0');

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'scale(1)' },
      { offset: 1, opacity: '1', transform: 'scale(1)' },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(100)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

const leaveAnimation = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', 'var(--backdrop-opacity)', '0');

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '1', transform: 'translateX(0)' },
      { offset: 1, opacity: '0', transform: 'translateX(50px)' },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(100)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const mobileModalAnimationLeave = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0', '0');

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '1', transform3d: 'scale(1)' },
      { offset: 1, opacity: '0', transform3d: 'scale(1)' },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(100)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

// const isMobile = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)

// ------------------------------------------------------------------------------------------------
// SETUP CONFIGURATION MOBILE/TABLET/DESKTOP
// ------------------------------------------------------------------------------------------------
if (isPlatform('mobile') && !isPlatform('tablet'))
  setupConfig({
    rippleEffect: true,
    swipeBackEnabled: false,
    // animated: false,
    // navAnimation: createAnimation,
    // modalEnter: createAnimation, // doesnt work
    modalEnter: mobileModalAnimation,
    modalLeave: mobileModalAnimationLeave,
    // modalLeave: createAnimation,
    // mode: 'md',
  });
else
  setupConfig({
    rippleEffect: true,
    swipeBackEnabled: false,
    // mode: 'md',
    // animated: false,
    // navAnimation: createAnimation,
    modalEnter: enterAnimation,
    modalLeave: leaveAnimation,
  });

// if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'production' && window.location.hostname !== 'localhost') {
//   console.log = () => {};
//   console.info = () => {};
//   console.warn = () => {};
// }

document.getElementById('root').addEventListener('touchstart', (e) => {});

document.addEventListener('scroll', (e) => {
  if (document.documentElement.scrollTop > 1) {
    document.documentElement.scrollTop = 100;
  }
});

// ------------------------------------------------------------------------------------------------
// REACTDOM RENDER
// ------------------------------------------------------------------------------------------------
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);

// ------------------------------------------------------------------------------------------------
// Call the element loader after the app has been rendered the first time
// ------------------------------------------------------------------------------------------------
defineCustomElements(window);
