/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserRequest } from '../../../platform/user'
import type { UpdateUserRequest } from '../../../platform/user'
import type { UserListResponse } from '../../../platform/user'
import type { UserResponse } from '../../../platform/user'
import type { CancelablePromise } from '../../@api_core/CancelablePromise'
import type { BaseHttpRequest } from '../../@api_core/BaseHttpRequest'
export class AdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add User
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public addUser({ requestBody }: { requestBody: CreateUserRequest }): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        })
    }
    /**
     * Delete User
     * Deletes a user
     * @returns string Successful Response
     * @throws ApiError
     */
    public deleteUser({ userId }: { userId: string }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/user/{user_id}',
            path: {
                user_id: userId,
            },
            errors: {
                401: `Not authenticated`,
                404: `User not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get User
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public getUser({ userId }: { userId: string }): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/user/{user_id}',
            path: {
                user_id: userId,
            },
            errors: {
                401: `Not authenticated`,
                404: `User not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Update User
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public updateUser({
        userId,
        requestBody,
    }: {
        userId: string
        requestBody: UpdateUserRequest
    }): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{user_id}',
            path: {
                user_id: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                404: `User not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Users
     * Fetches a list of users. It accepts `org_id` as optional query parameters
     * @returns UserListResponse Successful Response
     * @throws ApiError
     */
    public getUsers(): CancelablePromise<UserListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/users',
            errors: {
                401: `Not authenticated`,
            },
        })
    }
}
