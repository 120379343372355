import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import React from 'react';
import { useSection } from '../data/Section';
import './AgrinormFrontendSchemaPreview.scss';
import { getSectionPreviewEntities } from '../HelpersSpecBuilder';
import { CSection } from '../components-inspection/CSection';
import { InspectionContextProvider } from '../context/InspectionContextProvider';
import { InspectionSpec } from '../ModelSpecification';

interface Props {
  sectionIds: string[];
}

export const AgrinormFrontendSchemaPreview: React.FC<Props> = ({ sectionIds }) => {
  return (
    <IonPage>
      <IonContent>
        <div className="schema-preview-wrapper">
          <div className="schema-preview">
            <div className="phone-preview page-inspection">
              <div className="notch">
                <svg className="device__ios-notch" viewBox="0 0 219 31">
                  <path
                    d="M0 1V0h219v1a5 5 0 0 0-5 5v3c0 12.15-9.85 22-22 22H27C14.85 31 5 21.15 5 9V6a5 5 0 0 0-5-5z"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              {sectionIds.map((sectionId) => (
                <SectionPreview key={sectionId} sectionId={sectionId} />
              ))}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

//------------------------------------------------------------
interface SPProps {
  sectionId: string;
}

const SectionPreview: React.FC<SPProps> = ({ sectionId }) => {
  const section = useSection(sectionId);

  if (section.isLoading) {
    return (
      <div className="section-loading">
        <IonSpinner name="dots" />
      </div>
    );
  }

  const sectionPreviewEntities = getSectionPreviewEntities(section.data);

  return (
    <InspectionContextProvider
      spec={
        {
          questionSpecs: sectionPreviewEntities.questionSpecs,
        } as InspectionSpec
      }
      inspection={undefined}
      updateInspection={() => ({} as any)}
      context={undefined}
      errors={undefined}
      isPreview={true}
    >
      <CSection
        key={sectionId}
        section={sectionPreviewEntities.section}
        onNativePicture={() => {}}
      />
    </InspectionContextProvider>
  );
};
