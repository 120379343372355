import React, { useRef, useState } from 'react';
import './CInspectionItem.scss';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ocrQuestionIds, UserInputLocator } from './InspectionModel';
import _ from 'lodash';
import {
  AnnotatedImage,
  AuxmeasurementsInner,
  CategoricalMeasurement,
  FloatMeasurement,
  InspectionSpecSection,
  IntMeasurement,
  LotSchemaObjectTypeEnum,
  MeasurablesInner,
  MeasurablesInner1,
  Measurement1,
  QuestionSpec,
  QuestionTemplateType,
} from './generated/openapi/core';
import {
  areExternalDependenciesPresent,
  extractMeasurementFields,
  fulfillsCriteria,
  getValueAndUnitFromLocator,
} from './InspectionLogic';
import { renderUnit } from './ServiceInspection';
import { CGranularity } from './components-inspection/CGranularity';
import { CInput } from './components-inspection/CInput';
import { CInputVerification } from './components-inspection/CInputVerification';
import { CCategorical } from './components-inspection/CCategorical';
import { CFloatList } from './components-inspection/CFloatList';
import { CBoolean } from './components-inspection/CBoolean';
import { IonIcon, IonItem, IonLabel, IonModal } from '@ionic/react';
import {
  checkboxOutline,
  helpCircleOutline,
  imagesOutline,
  squareOutline,
} from 'ionicons/icons';
import { CInputText } from './components-inspection/CInputText';
import { cloneDeep } from 'lodash';

import { EventEmitter, Events } from './EventEmmiter';
import { trimDecimals } from './HelperUtils';
import PagePicture from './PagePicture';
import ViewPicture from './ViewPicture';
import { useInspectionContext } from './context/InspectionContextProvider';
import { scoreLabels } from './Model';

// ------------------------------------------------------------------------------------------------
// CInspectionItem
// ------------------------------------------------------------------------------------------------
interface PropsCInspectionItem {
  questionSpecs: QuestionSpec;
  question?: string;
  section?: InspectionSpecSection;
}

const CInspectionItem = (props: PropsCInspectionItem) => {
  const ref = useRef(undefined);
  const [showSectionHint, setShowSectionHint] = useState(false);
  const [picture, setPicture] = useState<string>(undefined);

  const switchComponent = (
    measurable: MeasurablesInner | MeasurablesInner1,
    id: string,
    index: number
  ) => {
    const measurement = measurable.measurement;
    const measurementType = measurement.type;
    const hasUnit = measurementType !== 'boolean' && measurementType !== 'text';

    const inputLocator: UserInputLocator = {
      measurableId: measurable.measurableId,
      questionId: questionSpecs.questionId,
      isScore: questionSpecs.isScore,
    };

    const generateControl = (
      type,
      aux?: AuxmeasurementsInner,
      auxMeasurementIndex?: number,
      showDisplayName?: boolean
    ) => {
      const locator: UserInputLocator = { ...inputLocator, auxMeasurementIndex };
      const hasGranularity = (m: AuxmeasurementsInner | Measurement1) =>
        (m?.type === 'int' || m?.type === 'float') && m.granularity > 0;
      // console.log('type', type)

      switch (type) {
        case 'categorical':
        case 'category':
          componentes.push(
            <CCategorical
              measurement={measurement as CategoricalMeasurement}
              inputLocator={locator}
              key={id + auxMeasurementIndex}
            />
          );
          break;

        case 'float_list':
        case 'int_list':
          componentes.push(
            <CFloatList
              inputLocator={locator}
              key={id + auxMeasurementIndex}
              isInt={type === 'int_list'}
            />
          );
          break;

        // case 'verified':
        //   componentes.push(<>verified</>)
        //   break

        case 'text':
          componentes.push(<CInputText key={id + index} locator={locator} />);
          break;

        case 'boolean':
          // console.log('booolean')
          componentes.push(
            <CBoolean
              ref={ref}
              key={id + index}
              locator={locator}
              measurement={measurement}
            />
          );
          break;

        default:
          // rest of components are either float or int
          // if they present granularity, then we render the [+][-] buttons
          // with the CGranularity component
          if (hasGranularity(aux)) {
            return (
              <CGranularity
                measurement={aux as IntMeasurement | FloatMeasurement}
                inputLocator={locator}
                id={id}
                index={auxMeasurementIndex}
                key={id + auxMeasurementIndex}
                showDisplayName={showDisplayName}
              />
            );
          }

          // if there is no granularity, we render a CInput
          if (!!aux) {
            componentes.push(
              <CInput
                id={id}
                key={id + auxMeasurementIndex}
                index={auxMeasurementIndex}
                locator={locator}
                measurement={aux as FloatMeasurement | IntMeasurement}
                showDisplayName={showDisplayName}
              />
            );
          } else if (hasGranularity(measurement)) {
            componentes.push(
              <CGranularity
                measurement={measurement as FloatMeasurement | IntMeasurement}
                inputLocator={locator}
                index={index}
                id={id}
                key={id + index}
                showDisplayName={showDisplayName}
              />
            );
          } else {
            componentes.push(
              <CInput
                id={id}
                key={id + index}
                index={index}
                locator={locator}
                measurement={measurement as FloatMeasurement | IntMeasurement}
              />
            );
          }
      }
    };

    let componentes = [];
    let auxControls, computed;
    let cssClass = '';

    if (measurable.type === 'computed') {
      const { value } = getValueAndUnitFromLocator(inputLocator, inspection);
      let { minValue, maxValue, type, unit } = extractMeasurementFields(
        inputLocator,
        undefined,
        questionSpecs
      );

      let val;

      if (type === 'int' || type === 'float') {
        // val = value ? parseInt(value) : '0'
        val = value ? trimDecimals(value) : '0';
      } else if (type === 'boolean') {
        val = <>{val}</>;
      }
      // else if (type === 'float') {
      //   val = value ? +(parseFloat(value).toFixed(2)) : '0'
      // }
      else {
        val = value ?? '0';
      }

      // in the case of computed measurables, we don't cap the value within the range of the min and max,
      // but rather show a warning to the user that the value is out of range.
      // We should also not allow to close an inspection if there are computed measurements out of range

      // if it's a computed value with a unit based on percentage, and the maxValue is missing, we set it to 100%
      if (
        maxValue == null &&
        (unit ?? '').includes('percentage') &&
        !questionSpecs.isPartialSummary
      ) {
        maxValue = 100;
      }

      const isOutOfRange = !isNaN(+val)
        ? (minValue != null && +val < minValue) ||
          (maxValue != null && +val > (maxValue ?? 100))
        : false;

      cssClass = `${isOutOfRange && agScore !== 1 ? 'out-of-range' : ''}`;

      // console.log(val, isOutOfRange, maxValue)

      computed = (
        <div className={`computed ${cssClass}`}>
          {val}
          {hasUnit ? renderUnit(measurement.unit) : ''}
          {/* {isOutOfRange && <IonIcon icon={warningOutline}/>} */}
        </div>
      );
      auxControls = measurable.auxMeasurements;
    } else if (measurable.type === 'verified') {
      const dependenciesPresent = areExternalDependenciesPresent(
        context,
        questionSpecs,
        false
      );

      if (dependenciesPresent) {
        componentes.push(
          <CInputVerification
            id={id}
            key={id + index}
            index={index}
            locator={inputLocator}
            measurement={measurement}
            // @ts-ignore
            measurable={measurable}
          />
        );
      }

      // if the external dependencies are not present, but the measurable has the flag defaultToManual,
      // render as a regular measurable instead
      if (!dependenciesPresent && !!measurable.defaultToManual) {
        componentes.push(generateControl(measurementType));
      }
    } else {
      componentes.push(generateControl(measurementType));
    }

    // for each aux-measurement we create add component to the components array
    auxControls?.map((auxControl, idx) => {
      componentes.push(
        generateControl(auxControl.type, auxControl, idx, auxControls.length > 1)
      );
    });

    // we return the list of componets and the computed components separately to later
    // place them where we want in the UI
    return { componentes, computed, cssClass };
  };

  const { questionSpecs } = props;

  const { measurables, inspectionProperties: ip, questionId } = questionSpecs ?? {};
  const { inspection, errors, isPreviewEditable, isPreview, context } =
    useInspectionContext();

  if (!questionSpecs) {
    return null;
  }

  // Remove this hack once inspectionProperties are added to the OCR questions specs
  let inspectionProperties = cloneDeep(ip);
  if (!inspectionProperties) {
    inspectionProperties = {
      inclusionCriteria: null,
      isMandatory: false,
      taggable: false,
      hint: null,
    };
    // return null
  }

  const { hint, hintImages, taggable, isMandatory } = inspectionProperties;

  let auxM: AuxmeasurementsInner[];

  // console.log('CINSP INTEM', questionId, inspection)

  // omit measurables that don't fulfill the criteria
  const measurablesFiltered = isPreview
    ? measurables
    : measurables.filter(
        (m) =>
          inspection.objectType !== LotSchemaObjectTypeEnum.Lot ||
          fulfillsCriteria(m.inclusionCriteria, inspection)
      );

  if (!measurablesFiltered.length) {
    console.log('no measurable meets the criteria', measurables);
    return null;
  }

  if (measurablesFiltered[0].type === 'computed') {
    auxM = measurablesFiltered[0].auxMeasurements;
  }

  // console.log(questionSpecs.displayedName, questionSpecs)

  const measurement = measurablesFiltered[0].measurement;

  const isSingleRow = measurablesFiltered.length === 1 && (!auxM || auxM?.length < 2);

  const agScore = inspection?.userInputs?.[questionId]?.agScore;
  const hasError = errors?.find((e) => e.questionId === questionId);
  const className = `inspection-item 
    ${measurement.type}
    ${isSingleRow ? 'single-row' : 'multi-row'} 
    ${questionSpecs.measurables[0].type}
    score-${agScore}
    ${hasError ? 'has-error error-' + hasError.type : ''}`;

  const c1 = switchComponent(measurablesFiltered[0], questionId, 0);

  const numPictures =
    (inspection?.pictures ?? []).filter((pic) => pic.inputIds?.indexOf(questionId) > -1)
      ?.length ?? 0;

  const displayPictureInfo =
    (taggable || ocrQuestionIds.includes(questionId)) && numPictures > 0;

  // if (questionId === 'ggn_missing') {
  //   console.log("PICTURES", numPictures, inspection?.pictures)
  // }

  const showPartialSummaryScore = questionSpecs.isPartialSummary && agScore != null;

  return (
    <>
      <div
        className={className}
        id={`question-${questionId}`}
        onClick={(e) => {
          if (isSingleRow && measurement.type === 'boolean') {
            e.stopPropagation();
            ref.current?.toggle?.();
          }
        }}
      >
        <div
          className={`first-row ${
            isPreviewEditable && isSingleRow ? 'measurable-edit' : ''
          }`}
        >
          <div
            className={`inspection-label ${
              c1.componentes.length === 0 ? 'c-0' : 'c-many'
            }`}
          >
            <div className={'label'}>
              {questionSpecs.displayedName}
              {showPartialSummaryScore && (
                <div className={`computed`}>{scoreLabels[agScore]}</div>
              )}
              {isMandatory && '*'}
              {!!hint && (
                <>
                  <IonIcon
                    className="hint"
                    icon={helpCircleOutline}
                    data-tooltip-id={questionId}
                    data-tooltip-content={hint}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!!hintImages) {
                        setShowSectionHint(!showSectionHint);
                      }
                    }}
                  />
                </>
              )}
              {displayPictureInfo ? (
                <div className={`pic-indicator`}>
                  <div className={`pic-count ${numPictures === 0 ? '' : 'many'}`}>
                    {numPictures}
                  </div>
                  <IonIcon icon={imagesOutline} />
                </div>
              ) : null}
            </div>
            {isSingleRow && c1.computed}
          </div>
          {isSingleRow && (
            <div className={`inspection-control ${measurement.type}`}>
              {c1.componentes}
            </div>
          )}
          {!isSingleRow && measurablesFiltered.length === 1 && (
            <div className={`inspection-control ${measurement.type}`}>
              {c1.computed}
            </div>
          )}
          <div className="break"></div>
          {!['text', 'float_list', 'int_list'].includes(measurement.type) && (
            <div className="measurable-edit-buttons">
              <IonItem
                button
                onClick={(e) => {
                  e.stopPropagation();
                  EventEmitter.dispatch(Events.measurableEdit, {
                    index: 0,
                    questionId: questionId,
                    type: 'scoring',
                  });
                }}
              >
                <IonLabel>
                  <b>Main evaluation</b>
                </IonLabel>
                <IonIcon
                  icon={
                    measurablesFiltered?.[0]?.isPrimary
                      ? checkboxOutline
                      : squareOutline
                  }
                />
              </IonItem>
              {/* <IonButton
                fill="outline"
                color="tertiary"
                onClick={(e) => {
                  e.stopPropagation();
                  EventEmitter.dispatch(Events.measurableEdit, {
                    index: 0,
                    questionId: questionId,
                    type: 'scoring',
                  });
                }}
              >
                set as primary
              </IonButton> */}
            </div>
          )}
          {/* <div className="measurable-edit-buttons">
          
            <IonButton
              fill="clear"
              color="dark"
              onClick={(e) => {
                e.stopPropagation();
                EventEmitter.dispatch(Events.measurableEdit, {
                  index: 0,
                  questionId: questionId,
                  type: 'edit',
                });
                EventEmitter.dispatch(Events.questionEdit, {
                  type: 'edit',
                  questionSpecs: questionSpecs,
                  section: props.section,
                });
              }}
            >
              edit
            </IonButton>
            <IonButton
              fill="clear"
              color="danger"
              onClick={(e) => {
                e.stopPropagation();
                EventEmitter.dispatch(Events.measurableEdit, {
                  index: 0,
                  questionId: questionId,
                  type: 'remove',
                });
              }}
            >
              remove
            </IonButton>
          </div> */}
        </div>

        {!isSingleRow &&
          measurablesFiltered.map((m, i) => {
            const c2 = switchComponent(m, questionId, i);
            const displayName =
              // @ts-ignore
              m.measurement.displayedName ?? m.auxMeasurements?.[0]?.displayedName;
            // @ts-ignore
            const showDisplayName = !m.auxMeasurements || m.auxMeasurements?.length < 2;

            return (
              <div
                key={m.measurableId + i}
                id={m.measurableId}
                className={
                  isPreviewEditable &&
                  !['text', 'float_list', 'int_list'].includes(m.measurement.type)
                    ? `measurable-edit${m.isPrimary ? ' is-primary' : ''}` // ' is-primary'
                    : ''
                }
                onClick={(e) => {
                  if (!isSingleRow && m.measurement.type === 'boolean') {
                    e.stopPropagation();
                    ref.current?.toggle?.();
                  }
                }}
              >
                <div className={`inspection-label ${c2.cssClass}`}>
                  <>
                    {displayName && showDisplayName && <span>{displayName}</span>}
                    {questionSpecs.templateType !==
                      QuestionTemplateType.NumericValuesDistribution && c2.computed}
                  </>
                </div>
                <div className="inspection-control">
                  {c2.componentes.length ? c2.componentes : c2.computed}
                </div>
                <div className="break"></div>
                {!['text', 'float_list', 'int_list'].includes(m.measurement.type) && (
                  <div className="measurable-edit-buttons">
                    <IonItem
                      button
                      onClick={(e) => {
                        e.stopPropagation();
                        EventEmitter.dispatch(Events.measurableEdit, {
                          index: i,
                          questionId: questionId,
                          type: 'scoring',
                        });
                      }}
                    >
                      <IonIcon
                        color={m.isPrimary ? 'primary' : undefined}
                        icon={m.isPrimary ? checkboxOutline : squareOutline}
                      />
                      &nbsp;
                      <IonLabel>
                        <b>Main evaluation</b>
                      </IonLabel>
                    </IonItem>
                    {/* <IonButton
                      fill="outline"
                      color="tertiary"
                      onClick={(e) => {
                        e.stopPropagation();
                        EventEmitter.dispatch(Events.measurableEdit, {
                          index: i,
                          questionId: questionId,
                          type: 'scoring',
                        });
                      }}
                    >
                      set as primary
                    </IonButton> */}
                  </div>
                )}
                {/* <IonButton
                    fill="clear"
                    color="dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      EventEmitter.dispatch(Events.measurableEdit, {
                        index: i,
                        questionId: questionId,
                        type: 'edit',
                      });
                    }}
                  >
                    edit
                  </IonButton>
                  <IonButton
                    fill="clear"
                    color="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      EventEmitter.dispatch(Events.measurableEdit, {
                        index: i,
                        questionId: questionId,
                        type: 'remove',
                      });
                    }}
                  >
                    remove
                  </IonButton>
                </div>*/}
              </div>
            );
          })}
        {hasError?.type === 'missing_mandatory' && (
          <div className="missing_mandatory">This field is mandatory</div>
        )}
        {!!hint && !hintImages && (
          <Tooltip id={questionId} place="bottom" clickable={true} key={hint} />
        )}
      </div>
      {showSectionHint ? (
        <div className="section-hint">
          <div className="section-hint-title">
            Instructions for {questionSpecs.displayedName}:
          </div>
          <div className="section-hint-description">{hint}</div>
          <div className="section-hint-gallery">
            {hintImages?.map((imageWithCaption: AnnotatedImage) => {
              const [image, caption] = [
                imageWithCaption.storageId,
                imageWithCaption.caption,
              ];
              return (
                <div key={image}>
                  <ViewPicture
                    id={image}
                    onClick={() => setPicture(image)}
                    location=""
                    resolution="thumbnail"
                  />
                  {caption ? <span>{caption}</span> : <span></span>}
                </div>
              );
            })}
          </div>
          <IonModal isOpen={!!picture} onDidDismiss={() => setPicture(undefined)}>
            <PagePicture
              editable={false}
              location=""
              picture={{ id: picture }}
              pictures={hintImages?.map((p: AnnotatedImage) => {
                return { id: p.storageId };
              })}
              onDismiss={() => setPicture(undefined)}
            />
          </IonModal>
        </div>
      ) : null}
    </>
  );
};

export default CInspectionItem;
