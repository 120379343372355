/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePartnerRequest } from '../../../platform/partner'
import type { PartnerListResponse } from '../../../platform/partner'
import type { PartnerWithId } from '../../../platform/partner'
import type { UpdatePartnerRequest } from '../../../platform/partner'
import type { CancelablePromise } from '../../@api_core/CancelablePromise'
import type { BaseHttpRequest } from '../../@api_core/BaseHttpRequest'
export class PartnerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Partners
     * @returns PartnerListResponse Successful Response
     * @throws ApiError
     */
    public getPartners(): CancelablePromise<PartnerListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner/',
            errors: {
                401: `Not authenticated`,
            },
        })
    }
    /**
     * Create Partner
     * @returns PartnerWithId Successful Response
     * @throws ApiError
     */
    public createPartner({ requestBody }: { requestBody: CreatePartnerRequest }): CancelablePromise<PartnerWithId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/partner/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Delete Partner
     * @returns any Successful Response
     * @throws ApiError
     */
    public deletePartner({ partnerId }: { partnerId: string }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/partner/{partner_id}',
            path: {
                partner_id: partnerId,
            },
            errors: {
                401: `Not authenticated`,
                404: `Partner not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Get Partner
     * @returns PartnerWithId Successful Response
     * @throws ApiError
     */
    public getPartner({ partnerId }: { partnerId: string }): CancelablePromise<PartnerWithId> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner/{partner_id}',
            path: {
                partner_id: partnerId,
            },
            errors: {
                401: `Not authenticated`,
                404: `Partner not found`,
                422: `Validation Error`,
            },
        })
    }
    /**
     * Update Partner
     * @returns any Successful Response
     * @throws ApiError
     */
    public updatePartner({
        partnerId,
        requestBody,
    }: {
        partnerId: string
        requestBody: UpdatePartnerRequest
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/partner/{partner_id}',
            path: {
                partner_id: partnerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                404: `Partner not found`,
                422: `Validation Error`,
            },
        })
    }
}
