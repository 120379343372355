/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Vision API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface LabelRequest
 */
export interface LabelRequest {
  /**
   *
   * @type {string}
   * @memberof LabelRequest
   */
  org_id: string;
  /**
   *
   * @type {string}
   * @memberof LabelRequest
   */
  picture_uri: string;
  /**
   *
   * @type {number}
   * @memberof LabelRequest
   */
  time_buffer?: number;
}
/**
 *
 * @export
 * @interface LabelResponse
 */
export interface LabelResponse {
  /**
   *
   * @type {{ [key: string]: Target; }}
   * @memberof LabelResponse
   */
  targets: { [key: string]: Target };
}
/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 *
 * @export
 * @interface Target
 */
export interface Target {
  /**
   *
   * @type {string}
   * @memberof Target
   */
  value: string;
  /**
   *
   * @type {object}
   * @memberof Target
   */
  extras?: object;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValidationRequest
 */
export interface ValidationRequest {
  /**
   *
   * @type {string}
   * @memberof ValidationRequest
   */
  org_id: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ValidationRequest
   */
  targets: { [key: string]: string };
}
/**
 *
 * @export
 * @interface ValidationResponse
 */
export interface ValidationResponse {
  /**
   *
   * @type {{ [key: string]: Target; }}
   * @memberof ValidationResponse
   */
  targets: { [key: string]: Target };
}

/**
 * AgrinormPlatformApi - axios parameter creator
 * @export
 */
export const AgrinormPlatformApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Label Endpoint
     * @param {LabelRequest} labelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    labelEndpoint: async (
      labelRequest: LabelRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelRequest' is not null or undefined
      assertParamExists('labelEndpoint', 'labelRequest', labelRequest);
      const localVarPath = `/vision/label`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        labelRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validation Endpoint
     * @param {ValidationRequest} validationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validationEndpoint: async (
      validationRequest: ValidationRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'validationRequest' is not null or undefined
      assertParamExists('validationEndpoint', 'validationRequest', validationRequest);
      const localVarPath = `/vision/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        validationRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AgrinormPlatformApi - functional programming interface
 * @export
 */
export const AgrinormPlatformApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AgrinormPlatformApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Label Endpoint
     * @param {LabelRequest} labelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async labelEndpoint(
      labelRequest: LabelRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.labelEndpoint(
        labelRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Validation Endpoint
     * @param {ValidationRequest} validationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validationEndpoint(
      validationRequest: ValidationRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validationEndpoint(
        validationRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AgrinormPlatformApi - factory interface
 * @export
 */
export const AgrinormPlatformApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AgrinormPlatformApiFp(configuration);
  return {
    /**
     *
     * @summary Label Endpoint
     * @param {LabelRequest} labelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    labelEndpoint(
      labelRequest: LabelRequest,
      options?: any
    ): AxiosPromise<LabelResponse> {
      return localVarFp
        .labelEndpoint(labelRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Validation Endpoint
     * @param {ValidationRequest} validationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validationEndpoint(
      validationRequest: ValidationRequest,
      options?: any
    ): AxiosPromise<ValidationResponse> {
      return localVarFp
        .validationEndpoint(validationRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AgrinormPlatformApi - object-oriented interface
 * @export
 * @class AgrinormPlatformApi
 * @extends {BaseAPI}
 */
export class AgrinormPlatformApi extends BaseAPI {
  /**
   *
   * @summary Label Endpoint
   * @param {LabelRequest} labelRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgrinormPlatformApi
   */
  public labelEndpoint(labelRequest: LabelRequest, options?: AxiosRequestConfig) {
    return AgrinormPlatformApiFp(this.configuration)
      .labelEndpoint(labelRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Validation Endpoint
   * @param {ValidationRequest} validationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgrinormPlatformApi
   */
  public validationEndpoint(
    validationRequest: ValidationRequest,
    options?: AxiosRequestConfig
  ) {
    return AgrinormPlatformApiFp(this.configuration)
      .validationEndpoint(validationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
