import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonPopover,
  withIonLifeCycle,
} from '@ionic/react';
import {
  arrowBackOutline,
  chatbubbleEllipsesOutline,
  checkmarkOutline,
  chevronBackOutline,
  closeOutline,
  createOutline,
  documentAttachOutline,
  ellipsisHorizontal,
  mailOutline,
  printOutline,
  trashOutline,
} from 'ionicons/icons';
import _, { cloneDeep } from 'lodash';
import React, { RefObject, useRef, useState } from 'react';
import CardInspection from './CardInspection';
import CardLot from './CardLot';
import { firestore, storage } from './ConfigFirebase';
import {
  createInternalReportFromOrder,
  deleteReport,
  deleteReportAttachment,
  getReportSnapshot,
  getReportTitle,
  orderToInternalReport,
  updateOrderStatus,
  updateReportReferenceOrderId,
} from './DataReport';
import {
  addReportMessage,
  getOrder,
  getOrderSnapshot,
  getSharedReport,
  reportColRef,
} from './DataStorage';
import {
  computeAllocationMismatches,
  containsObject,
  formatDate,
  getSplitLotTransferedQuantity,
  removeSpecialChars,
} from './HelperUtils';
import ModalShare from './ModalShare';
import {
  AllocationMismatch,
  Contact,
  Conversation,
  Lot,
  LotPopoverModel,
  LotPosition,
  LotTransfer,
  Order,
  Organisation,
  OrganisationSettings,
  Picture,
  Report,
  ReportType,
  UserProfile,
} from './Model';
// import './PageLot.scss';
import withContext, { ContextProps } from './HOC/withContext';
import {
  LegacyInspection,
  LotInspection,
  LegacyInspectionReference,
} from './InspectionModel';
import PageNewConversation from './PageNewConversation';
import './PageOrder.scss';
import PageSharedHistoryOrder from './PageSharedHistoryOrder';
import {
  AppProduct,
  QCTierAction,
  hasRole,
  isAdmin,
  isFreeQCTier,
  orgHasPermission,
  userHasPermission,
} from './PermissionsService';
import { Article } from './ServiceArticle';
import i18n from './ServiceI18n';
import {
  InspectionClass,
  orderInspectionsList,
  removeInspectionlessPositions,
  stringifyInspectionReference,
} from './ServiceInspection';
import ViewAllocationMismatchWarning from './ViewAllocationMismatchWarning';
import ViewContactName, { ViewOrganisationName, ViewUserName } from './ViewContactName';
import ViewOrderLevelAggregation from './ViewOrderLevelAggregation';

interface Props {
  history: any;
  organisationId: string;
  reportId: string;
  hideGeneral?: boolean;
  upfront?: boolean;
  shared?: boolean;
  sharedReport?: boolean;
  id?: string;
  draft?: boolean;
  reportType?: ReportType;
}

interface State {
  report?: Report;
  // for creating report from draft view
  order?: Order;

  // history?: Report[];
  linkedOrder?: Order;
  filters?: any;
  showNewMetadata?: boolean;
  showSaveChanges?: boolean;
  showLoadingSpinner?: boolean;
  showDeleteAlert?: string;

  currContactPosition?: LotPosition;

  alert: { message: string; header: string; show: boolean; onAccept: any };
  showSplitLotModal: boolean;
  currPosition?: LotPosition;

  lotOptionsPopover?: LotPopoverModel;

  editingOrderId?: boolean;
  orderIdInputValue?: string;

  showMismatches: {
    show: boolean;
    mismatches: AllocationMismatch[];
    expectedId: string;
    foundId: string;
  };
}

class PageReport extends React.Component<Props & ContextProps, State> {
  private pageRef: RefObject<HTMLElement> = React.createRef();
  orderIdInputRef: RefObject<HTMLIonInputElement> = React.createRef();

  private unsubscribers: any[] = [];
  private mounted;
  private defaultAlert = { message: '', header: '', show: false, onAccept: (_) => {} };
  private defaultMismatch = {
    show: false,
    mismatches: [],
    expectedId: undefined,
    foundId: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      report: undefined,
      alert: this.defaultAlert,
      showSplitLotModal: false,
      lotOptionsPopover: { visible: false },
      showMismatches: this.defaultMismatch,
    };
  }

  // shouldComponentUpdate(nextProps: Props, nextState: State) {
  //   // const { showOrders } = this.state
  //   if (this.state !== nextState) {
  //     return true;
  //   }

  //   if (this.props.orderId !== nextProps.orderId) {
  //     return true;
  //   }
  //   if (this.state.order?.orgId !== nextProps.organisationId) {
  //     return true;
  //   }

  //   return false;
  // }

  // App text for translations
  appText_confirmShareOrder = 'Are you sure you want to set the order as shared?';

  async componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribers.map((unsubscribe) => unsubscribe());
  }

  async ionViewWillEnter() {
    const { profile, draft, reportId, sharedReport, id } = this.props;

    // console.log('reportType', this.props.reportType)

    if (this.props.reportId !== this.state.report?.id && this.mounted) {
      this.setState({ report: undefined });
    }

    if (draft) {
      this.unsubscribers.push(
        getOrderSnapshot(firestore, profile.organisationId, reportId, (order) => {
          if (this.mounted) {
            // remove positions with no assessments
            let report = order ? removeInspectionlessPositions(order) : null;
            if (!!order) {
              // @ts-ignore
              report = orderToInternalReport(
                report,
                this.props.reportType,
                profile,
                this.props.organisation?.settings
              );
            }
            this.setState({ report, order });
          }
        })
      );
    } else if (sharedReport) {
      let report = await getSharedReport(firestore, id);
      if (this.mounted) {
        this.setState({ report: report ? report : null });
      }
    } else {
      this.unsubscribers.push(
        getReportSnapshot(firestore, profile.organisationId, reportId, (report) => {
          if (this.mounted) {
            this.setState({
              report: report ? report : null,
            });
          }
        })
      );
    }
  }

  ionViewWillLeave() {
    this.unsubscribers.map((unsubscribe) => unsubscribe());
  }

  renderLoading() {
    // TODO: render loading
    return (
      <div className="loading">
        <IonSpinner name="dots" />
      </div>
    );
  }

  async onDownloadAttachment(path: string) {
    try {
      const downloadURL = await storage.ref().child(path).getDownloadURL();
      // Emi: is this too hacky?
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('type', 'hidden');
      downloadLink.href = downloadURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      alert('Document could not be downloaded');
      console.log(e);
    }
  }

  onDeleteAttachment(
    path: string,
    event?: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ showDeleteAlert: path });
  }

  async onDeleteConfirm(path: string) {
    const { profile } = this.props;
    const { report } = this.state;

    console.log('report', report);

    this.setState({ showLoadingSpinner: true, showDeleteAlert: undefined });

    try {
      // delete attachment in storage and remove reference in re`prt
      await deleteReportAttachment(firestore, storage, profile, report, path);
    } catch (error) {
      alert(`Something went wrong when deleting the attachment`);
      console.log(`Error updating report`, error);
    }
    this.setState({ showLoadingSpinner: false });
  }

  renderAttachmentCards(report: Report) {
    if (!report.attachments || report.attachments.length === 0) {
      return '';
    }

    return (
      <>
        <IonListHeader>
          <h1>Attachments</h1>
        </IonListHeader>
        <div className="container-reports container-attachments">
          {report.attachments.map((a) => {
            const [filename] = a.split('/').slice(-1);
            return (
              <IonCard
                button
                disabled={!navigator.onLine}
                onClick={async () => await this.onDownloadAttachment(a)}
                key={a}
              >
                <IonItem>
                  <IonIcon icon={documentAttachOutline} slot="start" />
                  {filename}
                  {/* DELETION OF ATTACHMENTS DISABLED FROM REPORT FOR NOW */}
                  {/* {!this.props.sharedReport && 
                <IonButton className='no-print' fill='clear' disabled={!navigator.onLine} color='danger' slot='end' onClick={e => this.onDeleteAttachment(a, e)}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              } */}
                </IonItem>
              </IonCard>
            );
          })}
        </div>
      </>
    );
  }

  setShowAllocationMismatches(
    mismatches: AllocationMismatch[],
    expectedId: string,
    foundId: string,
    e?: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ showMismatches: { show: true, mismatches, expectedId, foundId } });
  }

  getReportLink(inspection: LotInspection) {
    const report = this.state.report;
    if (report.reportType === 'QUALITY_EVOLUTION' || report.reportType === 'PROMO') {
      return null;
    }
    if (inspection.reference.orderId === '') {
      inspection.reference.orderId = this.props.reportId;
    }
    if (this.props.sharedReport) {
      return `/shared-report/${this.props.id}/${stringifyInspectionReference(
        inspection.reference
      )}`;
    } else {
      return (
        '/secure/' +
        report?.orgId +
        '/inspection-view/' +
        stringifyInspectionReference(inspection.reference) +
        (this.props.draft ? '' : '/reportId/' + this.props.reportId)
      );
    }
  }

  onPicturesUpdated(
    allPictures: Picture[],
    affectedPictures: Picture[],
    inspectionReference: LegacyInspectionReference
  ) {
    console.log(allPictures, affectedPictures, inspectionReference);
    const report = this.state.report;
    report.lotInspectionMap[inspectionReference.lotId].pictures = allPictures;
    // this.setState({report})
    reportColRef(firestore, this.props.profile.organisationId)
      .doc(report.id)
      .update(report);
  }

  onPicturesUpdatedSummary(allPictures: Picture[], affectedPictures: Picture[], lotId) {
    console.log(allPictures, affectedPictures);
    const report = this.state.report;
    const index = report.lotMap.findIndex((lot) => lot.id === lotId);
    const lot = report.lotMap[index];

    lot.inspections.map((inspection) => {
      return (inspection.pictures = allPictures.filter((pic) =>
        _.isEqual(pic.assessmentReference, inspection.reference)
      ));
    });
    lot.latestInspection.pictures = allPictures.filter((pic) =>
      _.isEqual(pic.assessmentReference, lot.latestInspection.reference)
    );

    // this.setState({report})
    reportColRef(firestore, this.props.profile.organisationId)
      .doc(report.id)
      .update(report);
  }

  renderQualityReports(
    report: Report,
    inspections: LegacyInspection[],
    title: string,
    dataTip: string
  ) {
    const { filters } = this.state;
    let reports = [];

    const splitLotPositions = report.positions.filter((p) => p.motherLotIds);

    inspections?.forEach((insp: LotInspection, index) => {
      let lotId = insp.reference?.lotId;
      const position = report.positions.find((p) => p.lotId === lotId);
      let lot: Lot;
      if (position) {
        lot = {
          id: position.lotId,
          article: position.article,
          suppliedByContactId: report.contactId ?? report.supplierId,
          origin: { growerContactId: position.growerId },
          transient: {
            numBoxes: position.numBoxes,
            volumeInKg: position.volumeInKg,
          },
          transfers: [
            {
              numBoxes: position.numBoxes,
              volumeInKg: position.volumeInKg,
            } as LotTransfer,
          ],
        } as Lot;
      }

      // console.log("renderQualityReports", lot)

      let splitLotTransferedQuantity: number = 0;

      // if (splitLotPositions.length > 0 && !assessment.reference.transportId) {
      //   // Look for current lot in the transfers of all split lots and accumulate the quantity that's been transfered from it
      //   splitLotPositions
      //     .filter(p => p.motherLotIds.includes(position.lotId))
      //     .forEach(p => splitLotTransferedQuantity += p.numBoxes);
      // }

      if (splitLotPositions.length > 0 && !insp.reference.transportId) {
        splitLotTransferedQuantity = getSplitLotTransferedQuantity(
          report,
          position,
          new Article(position?.article).isRaw() ? 'volumeInKg' : 'numBoxes'
        );
      }

      // TODO: reimplement if needed
      const linkedLotId = undefined;
      const linkedLot = undefined;
      const mismatches = computeAllocationMismatches(lot, linkedLot);

      let onMismatchShow;
      if (mismatches.length > 0) {
        onMismatchShow = () =>
          this.setShowAllocationMismatches(mismatches, linkedLotId, lotId);
      }

      // @ts-ignore
      const orgSettings = (report?.orgSettings ??
        this.props.organisation?.settings) as OrganisationSettings;

      let card = (
        <CardInspection
          inspection={insp}
          testingSuffix={`${dataTip}-${index}`}
          loading={false}
          lot={lot}
          report={report}
          history={this.props.history}
          allowStatusEdit={!this.props.sharedReport && report.reportStatus !== 'SHARED'}
          allowGalleryEdit={
            !this.props.sharedReport && report.reportStatus !== 'SHARED'
          }
          orgSettings={orgSettings}
          // doubleCard={report.contactOrder?.order}
          organisationId={report.orgId}
          routerLink={this.getReportLink(insp)}
          onMismatchShow={onMismatchShow}
          splitLotTransferedQuantity={splitLotTransferedQuantity}
          motherLotIds={position?.motherLotIds}
          sharedReport={this.props.sharedReport}
          imageMode={'img'}
          onPicturesUpdated={this.onPicturesUpdated.bind(this)}
          showGalleryModal={true}
          // onLotUnlinkFromPageOrder={(lotId: string, order: Order) => this.onLotUnlinkFromCardReport(lotId, order)}
          key={index}
        />
      );
      if (filters === undefined) {
        reports.push(card);
      } else if (
        filters.lots.find(
          (id) => id === insp.reference.lotId || id === insp.reference.transportId
        )
      ) {
        reports.push(card);
      }
    });

    return (
      <>
        <IonListHeader>
          <h1>{report.reportSubject ?? title}</h1>
        </IonListHeader>
        <div className="container-reports">{reports.map((card) => card)}</div>
      </>
    );
  }

  renderBatchSummaries() {
    const { filters, report } = this.state;

    let reports = [];

    report.lotMap?.forEach((lot: Lot, index) => {
      let lotId = lot.id;

      // @ts-ignore
      // const orgSettings = (report?.orgSettings ?? this.props.applicationContext.organisationSettings) as OrganisationSettings

      let card = (
        <CardLot
          report={report}
          // testingSuffix={`${dataTip}-${index}`}
          loading={false}
          lot={lot}
          sharedReport={this.props.sharedReport}
          showSummary={true}
          history={this.props.history}
          key={lot.id} // + lot.assessments.reduce((a, b) => a + b.pictures.length, 0)}
          allowGalleryEdit={
            !this.props.sharedReport && report.reportStatus !== 'SHARED'
          }
          onPicturesUpdated={(allPictures, affectedPictures) =>
            this.onPicturesUpdatedSummary(allPictures, affectedPictures, lot.id)
          }
        />
      );
      if (filters === undefined) {
        reports.push(card);
      } else if (filters.lots.find((id) => id === lotId)) {
        reports.push(card);
      }
    });

    return (
      <>
        <IonListHeader>
          <h1>{report.reportSubject ?? 'Quality Inspections'}</h1>
        </IonListHeader>
        <div className="container-reports">{reports.map((card) => card)}</div>
      </>
    );
  }

  renderLoadingLink() {
    // if (this.state.order.contactOrder.validLink === false)
    return (
      <>
        {/* <IonSpinner name="dots" /> */}
        ok
      </>
    );
  }

  renderGeneral() {
    let { report, editingOrderId, orderIdInputValue } = this.state;
    const { draft, profile } = this.props;

    // console.log("ROPIEPOIEIPOEIPOEIPO",report)

    const allowEditOrderId =
      (report?.autogenFromOrder === false ||
        report?.reportReference?.autogenFromOrder === false) &&
      !draft &&
      ((!!profile && profile?.id === report.createdByUserId) ||
        userHasPermission(profile));

    const orderIdRouterLinkDisabled =
      this.props.sharedReport ||
      !report?.reportReference?.orderIdLinked ||
      editingOrderId;

    // @ts-ignore
    const orgSettings = (report?.orgSettings ??
      this.props.organisation?.settings) as OrganisationSettings;

    return (
      <div
        className={
          'general-info-wrapper ' + (this.props.sharedReport ? 'no-events' : '')
        }
      >
        <IonListHeader>
          <h1>Report Information</h1>
        </IonListHeader>

        {/* <IonItem routerLink={"/secure/" + order.orgId + "/order/" + order.reportReference.orderId}> */}
        {/* {!!report.reportReference?.orderId ??
            <IonItem>
              <IonLabel>Order ID</IonLabel>
              <IonLabel className="organization-name  text-wrap">
                {report.reportReference?.orderId}
              </IonLabel>
            </IonItem>} */}

        {this.myOrgReport() && (
          <IonItem>
            <IonLabel>Status</IonLabel>
            <IonLabel className="organization-name  text-wrap">
              <IonBadge
                style={{ fontSize: 14 }}
                color={
                  draft
                    ? 'secondary'
                    : report.reportStatus === 'SHARED'
                    ? 'tertiary'
                    : 'primary'
                }
              >
                {draft ? 'DRAFT' : report.reportStatus}
              </IonBadge>
            </IonLabel>
          </IonItem>
        )}

        {report.createdByOrgId && (
          <IonItem>
            <IonLabel>Created by org.</IonLabel>
            <IonLabel className=" text-wrap">
              <ViewOrganisationName orgId={report.createdByOrgId} />
            </IonLabel>
          </IonItem>
        )}

        {this.myOrgReport() && report.createdByUserId ? (
          <IonItem data-tip={'page-report-created-by'}>
            <IonLabel>Created by</IonLabel>
            <IonLabel className=" text-wrap">
              <ViewUserName userId={report.createdByUserId} />
            </IonLabel>
          </IonItem>
        ) : (
          ''
        )}

        {report.lastModifiedDate &&
        (this.myOrgReport() ||
          (!this.myOrgReport() &&
            orgSettings?.sharedReportDisplaySettings?.lastModifiedDate !== false)) ? (
          <IonItem>
            <IonLabel>Last modified</IonLabel>
            <IonLabel className=" text-wrap">
              {formatDate(report.lastModifiedDate, {
                dateStyle: 'short',
                timeStyle: 'short',
              })}
            </IonLabel>
          </IonItem>
        ) : (
          ''
        )}

        {/* TODO: reimplement if needed */}
        {/* {displayContactOrderLink &&
          <IonItem className="external-id">
            <IonLabel>{`${myOrgReport ? 'External' : 'Internal'} ${externalReportShared ? 'report' : 'order'}`}</IonLabel>
            <IonLabel className=" text-wrap">              
              <div onClick={_=> (myOrgReport) 
                ? externalReportShared
                  ? this.props.history.push("/secure/report/" + report.contactOrder.order.latestReportReference.reportId)
                  : this.props.history.push("/secure/" + this.props.profile.organisationId + "/order/" + report.contactOrder?.id)
                : externalReportShared
                  ? this.props.history.push("/secure/report/" + report.contactOrder.order.latestReportReference.reportId + '/external')
                  : this.props.history.push("/secure/" + this.props.profile.organisationId + "/order/" + report.contactOrder?.id)
              }
              >
                {!!report.contactOrder?.id && <><IonIcon icon={linkOutline} /> {report.contactOrder?.id}</>}
              </div>
            </IonLabel>
          </IonItem>
        } */}

        {/* TODO: reimplement if needed */}
        {/* {displayContactOrderId && <IonItem className="external-id">
          <IonLabel>{`${myOrgReport ? 'External' : 'Internal'} ID`}</IonLabel>
          {!!report.contactOrder?.id && <>{report.contactOrder?.id}</>}
        </IonItem>
        } */}

        {report.reportType === 'OUTGOING' ? (
          report.contactId ? (
            <IonItem routerLink={'/secure/contact/' + report.contactId}>
              <IonLabel>{i18n.t('PageOrder.buyer')}</IonLabel>
              <IonLabel className="organization-name  text-wrap">
                <ViewContactName report={report} />
              </IonLabel>
            </IonItem>
          ) : (
            <IonItem routerLink={'/secure/contact/' + report.orgId}>
              <IonLabel>{i18n.t('PageOrder.seller')}</IonLabel>
              <IonLabel className="organization-name  text-wrap">
                {report.orgId}
              </IonLabel>
            </IonItem>
          )
        ) : null}

        {report.reportType === 'OUTGOING' && report.supplierName ? (
          <IonItem routerLink={'/secure/contact/' + report.contactId}>
            <IonLabel>Supplier</IonLabel>
            <IonLabel className="organization-name  text-wrap capitalize">
              {report.supplierName.toLocaleLowerCase()}
            </IonLabel>
          </IonItem>
        ) : null}

        {report.reportType === 'INCOMING' ? (
          report.contactId ? (
            <IonItem routerLink={'/secure/contact/' + report.contactId}>
              <IonLabel>{i18n.t('PageOrder.seller')}</IonLabel>
              <IonLabel className="organization-name  text-wrap">
                <ViewContactName report={report} />
              </IonLabel>
            </IonItem>
          ) : (
            <IonItem routerLink={'/secure/contact/' + report.orgId}>
              <IonLabel>{i18n.t('PageOrder.seller')}</IonLabel>
              <IonLabel className="organization-name  text-wrap">
                {report.orgId}
              </IonLabel>
            </IonItem>
          )
        ) : null}

        {!!report.sharedTo?.length &&
          (this.myOrgReport() ||
            (orgSettings?.sharedReportDisplaySettings?.sharedBy !== false &&
              !this.myOrgReport())) && (
            <IonItem>
              <IonLabel>Shared by</IonLabel>
              <IonLabel className=" text-wrap">
                {_.uniq(report.sharedTo.map((o) => o.sharedBy?.email)).map((email) => (
                  <div key={email}>
                    {email.split('@')[0]}
                    <br />@{email.split('@')[1]}
                  </div>
                ))}
              </IonLabel>
            </IonItem>
          )}

        <br />
        <IonListHeader>
          <h1>Additional Information</h1>
        </IonListHeader>

        {(!!report?.reportReference?.orderId || !report.autogenFromOrder) && (
          <IonItem
            routerLink={
              orderIdRouterLinkDisabled
                ? undefined
                : '/secure/' +
                  this.props.profile.organisationId +
                  '/order/' +
                  report.reportReference?.orderId
            }
          >
            <IonLabel>Order ID</IonLabel>
            {editingOrderId ? (
              <>
                <IonButtons>
                  <IonButton
                    size="small"
                    color="danger2"
                    onClick={async (e) => await this.removeOrderId(e)}
                  >
                    <IonIcon icon={trashOutline} slot="icon-only" />
                  </IonButton>
                  <IonButton
                    size="small"
                    color="dark"
                    onClick={(e) => this.cancelEditOrderId(e)}
                  >
                    <IonIcon icon={closeOutline} slot="icon-only" />
                  </IonButton>
                  <IonButton
                    size="small"
                    color="dark"
                    onClick={async (e) => await this.editOrderId(e)}
                  >
                    <IonIcon icon={checkmarkOutline} slot="icon-only" />
                  </IonButton>
                </IonButtons>
                <IonInput
                  ref={this.orderIdInputRef}
                  className="order-id-div"
                  value={orderIdInputValue}
                  // onIonBlur={() => this.setState({ editingOrderId: false, orderIdInputValue: undefined })}
                  onIonChange={(e) =>
                    this.setState({
                      orderIdInputValue: removeSpecialChars(
                        e.detail.value
                      )?.toUpperCase(),
                    })
                  }
                  onKeyDown={async (v) => {
                    if (v.key === 'Enter') {
                      await this.editOrderId(v);
                    }
                  }}
                ></IonInput>
              </>
            ) : (
              <>
                {allowEditOrderId && (
                  <div className="order-id-div">
                    <IonButtons>
                      <IonButton
                        size="small"
                        color="dark"
                        onClick={(e) => this.onEditOrderIdClick(e)}
                      >
                        <IonIcon icon={createOutline} slot="icon-only" />
                      </IonButton>
                    </IonButtons>
                  </div>
                )}
                {draft ? (
                  <IonLabel className="align text-wrap">
                    {report.reportReference?.orderId}
                  </IonLabel> // this is the order id when it's in draft status
                ) : !!report.reportReference?.orderId ? (
                  <IonLabel className=" text-wrap">
                    {report.reportReference.orderId}
                  </IonLabel>
                ) : (
                  <b>Not defined</b>
                )}
              </>
            )}
          </IonItem>
        )}

        {!!report?.reportReference?.externalOrderId && (
          <IonItem>
            <IonLabel>Counterparty order ID</IonLabel>
            <IonLabel className=" text-wrap">
              {report.reportReference.externalOrderId}
            </IonLabel>
          </IonItem>
        )}

        {!!report?.transport?.vessel && (
          <IonItem>
            <IonLabel>Vessel Name</IonLabel>
            <IonLabel className=" text-wrap">{report.transport.vessel}</IonLabel>
          </IonItem>
        )}

        {!!report?.transport?.container && (
          <IonItem>
            <IonLabel>Container</IonLabel>
            <IonLabel className=" text-wrap">{report.transport.container}</IonLabel>
          </IonItem>
        )}

        {!!report?.externalReference && (
          <IonItem>
            <IonLabel>External Ref.</IonLabel>
            <IonLabel className=" text-wrap">{report.externalReference}</IonLabel>
          </IonItem>
        )}

        {report?.estimatedDepartureDate ? (
          <IonItem>
            <IonLabel>Estimated departure</IonLabel>
            <IonLabel className=" text-wrap">
              {formatDate(report?.estimatedDepartureDate, { dateStyle: 'short' })}
            </IonLabel>
          </IonItem>
        ) : (
          ''
        )}

        {report?.estimatedFulfilmentDate && (
          <IonItem>
            <IonLabel>Arrival Date</IonLabel>
            <IonLabel className=" text-wrap">
              {formatDate(report?.estimatedFulfilmentDate, { dateStyle: 'short' })}
            </IonLabel>
          </IonItem>
        )}

        {report?.positions?.length > 0 && (
          <IonItem>
            <IonLabel>Products</IonLabel>
            <IonLabel className=" text-wrap">
              {_.uniq(report.positions.map((pos) => pos.article.agProductId)).join(
                ', '
              )}
            </IonLabel>
          </IonItem>
        )}
      </div>
    );
  }

  cancelEditOrderId(v: any) {
    v?.stopPropagation();
    v?.preventDefault();
    this.setState({ editingOrderId: false, orderIdInputValue: undefined });
  }

  async editOrderId(v: any) {
    v?.stopPropagation();
    v?.preventDefault();

    const { orderIdInputValue, report } = this.state;

    // Only update if id changed
    if (
      !!orderIdInputValue &&
      (orderIdInputValue ?? '').length > 0 &&
      orderIdInputValue !== report.reportReference.orderId
    ) {
      this.setState({ showLoadingSpinner: true });

      try {
        await updateReportReferenceOrderId(
          firestore,
          this.props.profile,
          orderIdInputValue,
          report
        );
      } catch (error) {
        window.alert(`Error updating order id: ${error}`);
        console.error(error);
      }
    }

    this.setState({
      orderIdInputValue: undefined,
      editingOrderId: false,
      showLoadingSpinner: false,
    });
  }

  async removeOrderId(v: any) {
    v?.stopPropagation();
    v?.preventDefault();

    const { report } = this.state;

    this.setState({ showLoadingSpinner: true });

    try {
      await updateReportReferenceOrderId(
        firestore,
        this.props.profile,
        undefined,
        report
      );
    } catch (error) {
      window.alert(`Error updating order id: ${error}`);
      console.error(error);
    }

    this.setState({
      orderIdInputValue: undefined,
      editingOrderId: false,
      showLoadingSpinner: false,
    });
  }

  onEditOrderIdClick(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    this.setState(
      {
        editingOrderId: true,
        orderIdInputValue: this.state.report?.reportReference?.orderId,
      },
      async () => (await this.orderIdInputRef?.current?.getInputElement()).select()
    );
  }

  renderReportGeneral(inspections: LegacyInspection[]) {
    /*
      Pallets: all MAJOR
      Labels: all MAJOR
      Contamination:
      Insect - CRITICAL
      All other - MAJOR
      Temperature: CRITICAL
      GGN Missing: MINOR
      Bio Number: MAJOR
      Underweight: MAJOR
      Barcode: MAJOR
    */

    let accumulatedDefects: any = inspections.map((i) => {
      return {
        assessmentId: i.reference?.lotId || i.reference?.transportId,
        defects: Object.keys(i.userInputs)
          .filter((k) => i.userInputs[k]?.agScore < 4)
          .map((d) => ({
            id: d,
            name: i.renderingInfo?.questionSpecs?.[d]?.displayedName ?? d,
          })),
      };
    });

    let allDefects = [];
    accumulatedDefects.forEach((acc) =>
      acc.defects.forEach((d) => {
        return !containsObject(d, allDefects) && allDefects.push(d);
      })
    );

    let defectLotMap = {};

    allDefects.forEach((d: { id: string; name: string }) => {
      const key = d.name;
      defectLotMap[key] = accumulatedDefects
        .filter((acc) => containsObject(d, acc.defects))
        .map((acc) => acc.assessmentId);
    });

    const canViewFeedback = !this.myOrgReport();
    let canViewShares =
      this.myOrgReport() &&
      userHasPermission(this.props.profile, 'SHARE_EXT', 'REPORT');

    const orgSettings =
      // @ts-ignore
      this.state.report?.orgSettings ?? this.props.organisation?.settings;

    return (
      <>
        <div className="report-general-overview">
          <div className="general">{this.renderGeneral()}</div>
          {this.renderComments()}
          {this.renderReportColumn(defectLotMap, 'Issues Overview', 'defects')}
          {canViewFeedback && (
            <div className={'report-list'}>
              <IonListHeader>
                <h1>Message</h1>
              </IonListHeader>
              <div className={'report-feedback'}>
                {this.state.report.feedbackMessage ? (
                  this.state.report.feedbackMessage
                ) : (
                  <i>No feedback given</i>
                )}
                {!!this.state.report.sharedBy && (
                  <div>
                    shared by{' '}
                    {orgSettings?.sharedReportDisplaySettings?.sharedBy === false
                      ? this.state.report.orgId
                      : this.state.report.sharedBy?.name}
                  </div>
                )}
              </div>
            </div>
          )}
          {canViewShares && (
            <div className={'report-list no-print'}>
              <SharesColumn report={this.state.report} profile={this.props.profile} />
            </div>
          )}
        </div>
      </>
    );
  }

  renderReportColumn(accumulator, title, className) {
    return (
      Object.keys(accumulator).length > 0 && (
        <div className={className}>
          <IonListHeader>
            <h1>{title}</h1>
          </IonListHeader>
          <span className="defects-wrapper">
            {Object.keys(accumulator).map((key) => (
              <div
                key={key}
                className={this.state.filters?.key === key ? 'selected-filter' : ''}
                onClick={() => this.applyFilter(key, accumulator[key])}
              >
                {accumulator[key].length}x {key}
              </div>
            ))}
          </span>
        </div>
      )
    );
  }

  renderComments() {
    if (!this.state.report.comments) {
      return;
    }

    return (
      <div className={'report-comments'}>
        <IonListHeader>
          <h1>Comments</h1>
        </IonListHeader>
        <div className={'report-feedback'}>{this.state.report.comments}</div>
      </div>
    );
  }

  applyFilter(key, lotsArray) {
    if (this.state.filters?.key === key) {
      this.setState({ filters: undefined });
    } else {
      this.setState({
        filters: {
          lots: lotsArray,
          key: key,
        },
      });
    }
  }

  async onBlur(orderId) {
    console.log('-- blur -- ', orderId);

    // const order: Order = await getCrossOrder(firestore, orderId);
    // console.log('order', order);
  }

  renderTopInformation(orderDone, inspections: LegacyInspection[]) {
    return this.renderReportGeneral(inspections);
  }

  renderListInformation(
    orderDone,
    report: Report,
    inspections: LegacyInspection[],
    myOrgOrder: boolean
  ) {
    // separate normal lots from split lots
    const normalPositions = report.positions.filter((p) => !p.motherLotIds);
    const splitLotPositions = report.positions.filter((p) => p.motherLotIds);

    const normalInspections = inspections.filter(
      (i) =>
        normalPositions.map((p) => p.lotId).includes(i.reference.lotId) ||
        (!!i.reference.transportId && new InspectionClass(i).isCompleted())
    );
    const splitLotInspections = inspections.filter((i) =>
      splitLotPositions.map((p) => p.lotId).includes(i.reference.lotId)
    );

    // also display contact positions in case of suppliers
    // const contactOrder = order?.contactOrder?.order;

    // let unlinkedBuyerPositions = (contactOrder?.positions ?? [])
    //   .filter(() => contactOrder.type === 'BUY')
    //   .filter(p => !Object.values(order?.contactOrder?.positionsMap ?? {}).includes(p.lotId));

    // const displayContactOrder = !!contactOrder
    //   && contactOrder.type === 'BUY'
    //   && unlinkedBuyerPositions.length > 0
    //   && !hasRole(this.props.profile, 'QC_SURVEYER');

    const showOrderAggregationTable =
      !!this.props.organisation?.settings?.orderSummary ||
      // @ts-ignore
      !!report.orgSettings?.orderSummary;

    const orgSettings =
      // @ts-ignore
      report.orgSettings ?? this.props.organisation?.settings;

    return (
      <div className="quality-reports">
        {showOrderAggregationTable && (
          <ViewOrderLevelAggregation
            lotInspectionsMap={report.lotInspectionMap}
            searchVarieties={report?.search?.searchVarieties}
            orgSettings={orgSettings}
            positions={report.positions}
          />
        )}
        {report.inspectionAggregation !== 'batch_summaries' &&
          this.renderQualityReports(
            report,
            normalInspections,
            i18n.t('PageOrder.qualityReports'),
            'lot'
          )}
        {report.inspectionAggregation === 'batch_summaries' &&
          this.renderBatchSummaries()}
        {splitLotPositions.length > 0 &&
          this.renderQualityReports(
            report,
            splitLotInspections,
            'Child batches',
            'split-lot'
          )}
        {this.renderAttachmentCards(report)}
      </div>
    );
  }

  closeMismatchesModal() {
    this.setState({ showMismatches: this.defaultMismatch });
  }

  // TODO: reimplement these methods if needed
  // async linkLot(lot: Lot, isOwnPosition: boolean = false) {
  //   const { report: order, currContactPosition } = this.state;

  //   await linkLotToOrderPosition(firestore, this.props.profile.organisationId, lot, order, this.props.profile, currContactPosition, this.props.applicationContext.organisationSettings, isOwnPosition);

  //   this.setState({
  //     currContactPosition: undefined,
  //     lotOptionsPopover: {visible: false}
  //   });
  // }

  // async onLotUnlink(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, pos: LotPosition, order: Order) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const externalLotId = order.contactOrder.positionsMap[pos.lotId];
  //   const alert = {
  //     header: 'Unlink lot',
  //     show: true,
  //     onAccept: () => this.onLotUnlinkAccept(pos, order, externalLotId),
  //     message: `Are you sure you want to unlink external lot <b>${externalLotId}</b> from <b>${pos.lotId}</b> in order <b>${order.id}</b>?`
  //   }
  //   this.setState({ alert });
  // }

  // onLotUnlinkFromCardReport(lotId: string, order: Order) {
  //   const externalLotId = order.contactOrder.positionsMap[lotId];
  //   const pos = order.positions.find(p => p.lotId === lotId);

  //   const alert = {
  //     header: 'Unlink lot',
  //     show: true,
  //     onAccept: () => this.onLotUnlinkAccept(pos, order, externalLotId),
  //     message: `Are you sure you want to unlink external lot <b>${externalLotId}</b> from <b>${pos.lotId}</b> in order <b>${order.id}</b>?`
  //   }
  //   this.setState({ alert });
  // }

  // async onLotUnlinkAccept(pos: LotPosition, order: Order, externalLotId: string) {
  //   this.setState({ alert: this.defaultAlert });

  //   await unlinkContactPosition(firestore, pos, order, this.props.profile, this.props.applicationContext.organisationSettings);

  //   this.setState({
  //     currContactPosition: undefined, lotOptionsPopover: {visible: false}
  //   });
  // }

  myOrgReport() {
    let myOrgOrder = true;
    if (!!this.state.report) {
      myOrgOrder = this.state.report.orgId === this.props.profile?.organisationId;
    }
    return myOrgOrder;
  }

  async createReport() {
    const { profile, organisation } = this.props;
    if (window.confirm(`Are you sure you want to create the report?`))
      createInternalReportFromOrder(
        firestore,
        profile,
        this.state.order,
        organisation?.settings
      )
        .then((report) => {
          this.props.history.replace('/secure/report/' + report.id);
        })
        .catch((err) => {
          window.alert('Error creating report' + JSON.stringify(err));
        });
  }

  // TODO: reimplement if needed
  // async shareWithPartner(report: Report) {
  //   if (window.confirm(`${this.appText_confirmShareOrder}${!!report.contactOrder ? `\nThis action will automatically share the ${getOrderReportTypeTitle(report, true)} with ${report.contactOrder?.organisationId} in the system` : ''}`)) {
  //     let message: string;
  //     message = window.prompt('Please provide a message to be shared with the recipient', 'Shared via Agrinorm platform');
  //     return setReportAsSharedAndUpdateOrderReportRef(firestore, this.props.profile, report, message, this.props.applicationContext.organisationSettings)
  //       .then(async () => {
  //         const toast = await toastController.create({
  //           message: `${report.reportReference.orderId} has been shared`,
  //           position: 'top',
  //           color: 'dark',
  //           duration: 5000,
  //           buttons: [{
  //             text: 'Ok',
  //             role: 'cancel'
  //           }]
  //         });

  //         toast.present().then();
  //       }).catch(async e => {
  //         const toast = await toastController.create({
  //           message: e,
  //           position: 'top',
  //           color: 'danger',
  //           duration: 5000,
  //           buttons: [{
  //             text: 'Ok',
  //             role: 'cancel'
  //           }]
  //         });

  //         toast.present().then();
  //       });
  //   }
  // }

  render() {
    let { profile, draft } = this.props;
    let { report, showDeleteAlert, alert, showMismatches } = this.state;
    // const organisationSettings  = this.props.applicationContext.organisationSettings;

    console.log('render report', report);

    if (this.props.sharedReport) {
      //@ts-ignore
      profile = { userRoles: [] };
    }

    let inspections: LegacyInspection[] = orderInspectionsList(report as Order);

    let myOrgOrder = this.myOrgReport();
    const orgSettings =
      // @ts-ignore
      report?.orgSettings ?? this.props.organisation?.settings;

    let sum = inspections?.reduce((accumulator, element) => {
      if (element.reference?.transportId) return accumulator;
      else return accumulator + (new InspectionClass(element).isCompleted() ? 1 : 0);
    }, 0);

    // console.log("assessments ", assessments)

    // TODO: This assumes that there is always a transport inspection, revise this to: is there any
    // non transport inspection that is OPEN?
    let orderDone = sum === inspections.length - 1;

    let className = 'page-order page-report' + (!myOrgOrder ? ' upfront-page' : '');
    if (report?.hideDefects) {
      className += ' hide-defects';
    }
    if (report?.hideScores) {
      className += ' hide-scores';
    }
    if (report?.hideInspector) {
      className += ' hide-inspector';
    }
    if (report?.hideQuantity) {
      className += ' hide-quantity';
    }

    const displayShareButtons =
      this.myOrgReport() && !draft && userHasPermission(profile, 'SHARE_EXT', 'REPORT'); // && ['SHARED', 'CHECKED'].includes(report?.qcStatus);

    let enableCreateReport =
      draft &&
      userHasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') &&
      report?.qcStatus === 'DONE';

    return (
      <IonPage className={className} ref={this.pageRef}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" className="no-print">
              <IonBackButton
                text={i18n.t('General.back')}
                defaultHref="/tabs/reports"
                data-tip={'page-report-back-button'}
                color="dark"
              >
                <IonIcon icon={chevronBackOutline} slot="icon-only" />
              </IonBackButton>
            </IonButtons>
            {/* <div className="b-title" data-tip={'page-report-title'}>
            {getReportTitle(report, true)}
          </div> */}
            <IonTitle>{getReportTitle(report, true)}</IonTitle>
            {report && !draft && (
              <IonButtons slot="end" className="no-print">
                <SideMenu
                  profile={this.props.profile}
                  report={report}
                  history={this.props.history}
                  draft={draft}
                  contacts={this.props.contacts}
                  organisation={this.props.organisation}
                />
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent id={'page-order-content-' + (this.state.report?.id ?? '')}>
          {showMismatches.show && (
            <IonModal
              isOpen={showMismatches.show}
              onDidDismiss={() => this.closeMismatchesModal()}
            >
              <ViewAllocationMismatchWarning
                allocationMismatchList={showMismatches.mismatches}
                onClose={() => this.closeMismatchesModal()}
                expectedId={showMismatches.expectedId}
                foundId={showMismatches.foundId}
              />
            </IonModal>
          )}

          {/* TODO: refactor this with the new generic alert below */}
          <IonAlert
            isOpen={!!showDeleteAlert}
            cssClass={'alert-delete'}
            onDidDismiss={() => this.setState({ showDeleteAlert: undefined })}
            header={'Warning'}
            message={`Are you sure you want to delete the file <strong>${
              showDeleteAlert ? showDeleteAlert.split('/').slice(-1)[0] : ''
            }</strong>?`}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: (_) => this.setState({ showDeleteAlert: undefined }),
              },
              {
                text: 'Proceed',
                cssClass: 'accept-button-alert',
                handler: async (_) => await this.onDeleteConfirm(showDeleteAlert),
              },
            ]}
          />

          {alert.show && (
            <IonAlert
              isOpen={alert.show}
              onDidDismiss={() => this.setState({ alert: this.defaultAlert })}
              header={alert.header}
              cssClass="alert-qc"
              message={alert.message}
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () => this.setState({ alert: this.defaultAlert }),
                },
                {
                  text: 'Proceed',
                  cssClass: 'accept-button-alert',
                  handler: alert.onAccept,
                },
              ]}
            />
          )}

          <IonLoading isOpen={this.state.showLoadingSpinner} />

          {/* CREATE REPORT */}
          {draft && (
            <div slot="fixed" className="button-container">
              <IonButton
                disabled={!enableCreateReport}
                data-tip={'page-report-create-report'}
                className={enableCreateReport ? '' : 'translucent'}
                expand="block"
                color="tertiary"
                onClick={() => this.createReport()}
              >
                <IonIcon icon={documentAttachOutline} />
                &nbsp;Create report
              </IonButton>
            </div>
          )}

          {/* Share button shows when all reports have commercial decision */}
          {displayShareButtons && (
            <div slot="fixed" className="button-container">
              <ShareButton profile={this.props.profile} report={this.state.report} />
              {/* TODO: reimplement when needed */}
              {/* {displayShareWithAgrinormPartner && <IonButton expand="block" color="tertiary" onClick={() => this.shareWithPartner(report)}>
            <IonIcon icon={shareSocialOutline} />
            &nbsp;Share with partner
          </IonButton>} */}
            </div>
          )}

          {/* top banner showing this order is external */}
          {!myOrgOrder &&
            orgSettings?.sharedReportDisplaySettings?.sharedBy !== false && (
              <div className="shared-order-banner">
                {this.props.sharedReport ? 'Shared by ' : `External report from `}
                {
                  //@ts-ignore
                  !this.props.sharedReport
                    ? this.state.report?.orgId
                    : report?.sharedBy?.email
                }
              </div>
            )}

          {this.state.showNewMetadata && (
            <IonList className="metadata" slot="fixed">
              <IonItem>
                <IonLabel>IO Number</IonLabel>
                <IonInput
                  inputmode="numeric"
                  type="text"
                  value={''}
                  onBlur={() => this.onBlur('IO-20-07987')}
                />
              </IonItem>
              <IonItem>
                <IonLabel>Supplier</IonLabel>
                <IonSelect className="select-contact" interface="action-sheet">
                  {this.props.contacts
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .map((contact) => (
                      <IonSelectOption key={contact.id} value={contact.id}>
                        {contact.name}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Extra field 1</IonLabel>
                <IonInput type="text" value={''} />
              </IonItem>
              <IonItem>
                <IonLabel>Extra field 2</IonLabel>
                <IonInput inputmode="numeric" type="text" value={''} />
              </IonItem>
              <IonItem>
                <IonLabel>Some date</IonLabel>
                <IonDatetime />
              </IonItem>
            </IonList>
          )}

          {report === undefined ? (
            this.renderLoading()
          ) : report === null ? (
            <h1 className="h1-not-found">not found</h1>
          ) : (
            <IonList className="list-wrapper main-wrapper">
              {this.renderTopInformation(orderDone, inspections)}
              {this.renderListInformation(orderDone, report, inspections, myOrgOrder)}
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default React.memo(
  withContext(withIonLifeCycle(PageReport), ['organisation', 'profile', 'contacts'])
);

// ----------------------------------------------------------------
const SharesColumn = ({
  profile,
  report,
}: {
  profile: UserProfile;
  report: Report;
}) => {
  const [presentShareHistory, dismissIt] = useIonModal(PageSharedHistoryOrder, {
    report,
    onCancel: (_) => dismissIt(),
  });

  let reports = report.sharedTo;

  if (!profile || !(reports?.length > 0)) {
    return <></>;
  }

  return (
    <>
      <IonListHeader>
        <h1>Shares</h1>&nbsp;&nbsp;
        <IonBadge mode="ios" color="danger">
          {reports.length}
        </IonBadge>
      </IonListHeader>
      {reports
        .sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime())
        .map((v, i) => (
          <IonItem
            key={i}
            onClick={() => presentShareHistory()}
            button
            className="share-item"
            data-tip={'page-report-shares'}
          >
            {/* <IonIcon icon={mailOutline} slot="start"/>  */}
            <div>
              <div className="when">
                {formatDate(v.date, { dateStyle: 'short', timeStyle: 'short' })}
              </div>
              {v.emails?.length > 0 && (
                <div className="shared-to">shared to: {v.emails.length} contacts</div>
              )}
              <div className="by">by {v.sharedBy.name}</div>
              <div className="message">{v.message}</div>
            </div>
          </IonItem>
        ))}
    </>
  );
};
// ----------------------------------------------------------------

// ----------------------------------------------------------------

const ShareButton = ({ profile, report }) => {
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const [, dismissIt] = useIonModal(PageSharedHistoryOrder, {
    profile: profile,
    report,
    onCancel: (_) => dismissIt(),
  });

  return (
    <div>
      {/* { order?.sharedTo?.length > 0 &&
      <div className="shared-count" onClick={_=>presentShareHistory()}>
        <span>shared {order.sharedTo.length} time{order.sharedTo.length === 1 ? '' : 's'}</span>
      </div>
    } */}
      <IonButton
        expand="block"
        color="tertiary"
        data-tip={'page-report-share'}
        onClick={(_) => setShowShareModal(true)}
      >
        <IonIcon icon={mailOutline} />
        &nbsp;Share By Email
      </IonButton>

      {showShareModal && (
        <IonModal
          isOpen={showShareModal}
          onDidDismiss={() => setShowShareModal(false)}
          cssClass={'modal-share'}
          showBackdrop
        >
          <ModalShare report={report} onCancel={() => setShowShareModal(false)} />
        </IonModal>
      )}
    </div>
  );
};

// ----------------------------------------------------------------

const PopoverList = ({
  onHide,
  onClick,
  profile,
  report,
  draft,
  orgSettings,
}: {
  onHide: any;
  onClick: any;
  profile: UserProfile;
  report: Report;
  draft: boolean;
  orgSettings: OrganisationSettings;
}) => {
  // const allowLink = hasRole(profile, 'QC_SURVEYER'); // hasPermission(profile, 'LINK', 'ORDER') ||
  const allowShareInternal =
    userHasPermission(profile, 'SHARE_INT', 'ORDER') &&
    orgHasPermission(orgSettings, AppProduct.QualityControl, QCTierAction.Chat);
  // const allowCopyAssessments = hasPermission(profile, 'WRITE', 'ASSESSMENT');
  const isMyOrgReport = report.orgId === profile?.organisationId;
  // const allowMarkAsOpen = isMyOrgReport && (hasPermission(profile, 'WRITE', 'QC_ORDER_STATUS') && report.qcStatus !== 'OPEN');
  // const allowMarkAsDone = isMyOrgReport && hasPermission(profile, 'WRITE', 'QC_ORDER_STATUS');
  // const allowMarkAsChecked = isMyOrgReport && (hasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') || hasRole(profile, 'QC_SURVEYER'));
  // const allowMarkAsRedo = isMyOrgReport && hasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS');
  // const allowMarkAsShared = isMyOrgReport && (hasPermission(profile, 'WRITE', 'COMMERCIAL_ORDER_STATUS') || hasRole(profile, 'QC_SURVEYER')) && report.reportStatus === 'CREATED';
  // const allowUpload = report.qcStatus !== "SHARED" && isMyOrgReport && hasPermission(profile, 'UPLOAD', 'DOCUMENT');
  const allowPrint = true;

  // for now, only reports that are not created from an order can be deleted nad haven't been shared, and only by the creator (or an admin)
  const allowDelete =
    (report.sharedTo ?? []).length === 0 &&
    (profile?.id === report.createdByUserId || isAdmin(profile)) &&
    report.autogenFromOrder === false;

  if (!allowPrint) {
    return (
      <IonList>
        <IonItem>
          <IonText color="medium">No actions available</IonText>
        </IonItem>
      </IonList>
    );
  }

  return (
    <IonList>
      {!isFreeQCTier(orgSettings) &&
        ((isMyOrgReport && report?.reportReference?.orderIdLinked) || draft) && (
          <IonItem button onClick={() => onClick('view_order')}>
            <IonIcon icon={arrowBackOutline} className="popup-icon" slot="start" />
            View inspections
          </IonItem>
        )}

      {allowShareInternal && (
        <IonItem button onClick={(_) => onClick('chat')}>
          <IonIcon
            icon={chatbubbleEllipsesOutline}
            className="popup-icon"
            slot="start"
          />
          Start new conversation
        </IonItem>
      )}

      {allowDelete && (
        <IonItem button onClick={(_) => onClick('delete')}>
          <IonIcon icon={trashOutline} className="popup-icon" slot="start" />
          Delete report
        </IonItem>
      )}

      {isMyOrgReport && report.reportStatus !== 'SHARED' && (
        <IonItem onClick={(_) => onClick('hideScores', false)}>
          <IonCheckbox
            style={{ marginRight: '18px' }}
            slot="start"
            checked={report.hideScores}
          />
          Hide Scores
        </IonItem>
      )}

      {isMyOrgReport && report.reportStatus !== 'SHARED' && (
        <IonItem onClick={(_) => onClick('hideDefects', false)}>
          <IonCheckbox
            style={{ marginRight: '18px' }}
            slot="start"
            checked={report.hideDefects}
          />
          Hide Defects
        </IonItem>
      )}

      {isMyOrgReport && report.reportStatus !== 'SHARED' && (
        <IonItem onClick={(_) => onClick('hideInspector', false)}>
          <IonCheckbox
            style={{ marginRight: '18px' }}
            slot="start"
            checked={report.hideInspector}
          />
          Hide Inspector Names
        </IonItem>
      )}

      {/* {(allowMarkAsOpen || allowMarkAsDone || allowMarkAsChecked || allowMarkAsRedo || allowMarkAsShared) && isMyOrgReport && <IonItemDivider>Report status</IonItemDivider>} */}
      {/* {(allowMarkAsShared) && isMyOrgReport && <IonItemDivider>Report status</IonItemDivider>} */}

      {/* { allowMarkAsOpen && <IonItem button onClick={_ => onClick("open")}>
        <IonIcon icon={ellipseOutline} className="popup-icon" slot="start" />
        Mark as &nbsp; {<IonBadge color="medium">OPEN</IonBadge>}
      </IonItem>}

      { allowMarkAsDone && <IonItem button onClick={_ => onClick("skip")}>
        <IonIcon icon={checkmarkCircleOutline} className="popup-icon" slot="start" />
        Mark as &nbsp; {<IonBadge color="dark">DONE</IonBadge>}
      </IonItem>}

      { allowMarkAsChecked && <IonItem button onClick={_ => onClick("check")}>
          <IonIcon icon={checkmarkDoneCircleOutline} className="popup-icon" slot="start" />
          Mark as &nbsp; {<IonBadge color="primary">CHECKED</IonBadge>}
        </IonItem>}

      { allowMarkAsRedo && <IonItem button onClick={_ => onClick("redo")}>
        <IonIcon icon={arrowRedoCircleOutline} className="popup-icon" slot="start" />
        Mark as &nbsp; {<IonBadge color="danger">REDO</IonBadge>}
      </IonItem>} */}

      {/* { allowMarkAsShared && <IonItem button onClick={_ => onClick("share")}>
          <IonIcon icon={shareOutline} className="popup-icon" slot="start" />
          Mark as &nbsp; {<IonBadge color="tertiary">SHARED</IonBadge>}
        </IonItem>} */}

      <IonItemDivider>Other actions</IonItemDivider>

      {/* { allowUpload && <IonItem disabled={!navigator.onLine} button onClick={_=>onClick("upload")}>
        <IonIcon icon={documentAttachOutline} className="popup-icon" slot="start" />
        Upload attachment
      </IonItem>} */}

      {allowPrint && (
        <IonItem disabled={!navigator.onLine} button onClick={(_) => onClick('print')}>
          <IonIcon icon={printOutline} className="popup-icon" slot="start" />
          Print
        </IonItem>
      )}
    </IonList>
  );
};

// ----------------------------------------------------------------

const SideMenu = ({
  profile,
  report,
  history,
  draft,
  contacts,
  organisation,
}: {
  report: Report;
  profile: UserProfile;
  history: any;
  draft: boolean;
  contacts: Contact[];
  organisation: Organisation;
}) => {
  const [showChat, setShowChat] = useState<boolean>(false);

  const contact = hasRole(profile, 'COMMERCIAL')
    ? contacts?.find((c) => c.id === report?.contactId)
    : undefined;

  const fileInput = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [loadingMessage] = useState<string>(undefined);

  const clickItem = async (item, dismissIt = true) => {
    if (dismissIt) dismiss();
    switch (item) {
      case 'view_order':
        const reportId = draft ? report.id : report.reportReference.orderId;
        history.push(`/secure/${profile.organisationId}/order/${reportId}`);
        break;
      case 'delete':
        if (!window.confirm('Are you sure you want to delete this report?')) {
          return;
        }
        history.push(`/tabs/reports`);
        return deleteReport(firestore, profile, report.id);
      case 'open':
        const orderOpen: Order = await getOrder(
          firestore,
          profile.organisationId,
          report.reportReference.orderId
        );
        return updateOrderStatus(
          firestore,
          profile,
          orderOpen,
          'OPEN',
          organisation?.settings
        );
      case 'skip':
        // get order first:
        const orderSkip: Order = await getOrder(
          firestore,
          profile.organisationId,
          report.reportReference.orderId
        );
        return updateOrderStatus(
          firestore,
          profile,
          orderSkip,
          'DONE',
          organisation?.settings
        );
      case 'check':
        // get order first:
        const orderCheck: Order = await getOrder(
          firestore,
          profile.organisationId,
          report.reportReference.orderId
        );
        return updateOrderStatus(
          firestore,
          profile,
          orderCheck,
          'CHECKED',
          organisation?.settings
        );
      case 'redo':
        // get order first:
        const orderRedo: Order = await getOrder(
          firestore,
          profile.organisationId,
          report.reportReference.orderId
        );
        return updateOrderStatus(
          firestore,
          profile,
          orderRedo,
          'REDO',
          organisation?.settings
        );
      case 'upload':
        fileInput?.current?.click();
        return;
      case 'chat':
        return setShowChat(true);

      case 'hideDefects':
        return firestore
          .collection('organisation')
          .doc(profile.organisationId)
          .collection('report')
          .doc(report.id)
          .update({ hideDefects: !report.hideDefects });

      case 'hideScores':
        return firestore
          .collection('organisation')
          .doc(profile.organisationId)
          .collection('report')
          .doc(report.id)
          .update({ hideScores: !report.hideScores });

      case 'hideInspector':
        return firestore
          .collection('organisation')
          .doc(profile.organisationId)
          .collection('report')
          .doc(report.id)
          .update({ hideInspector: !report.hideInspector });

      case 'print':
        const node = document.getElementById(
          'page-order-content-' + report.id
        ).lastChild;
        // debugger
        const clone = node.cloneNode(true);
        //@ts-ignore
        clone.classList.add('page-order');
        //@ts-ignore
        clone.classList.add('print-mode');
        //@ts-ignore
        clone.id = 'clone';

        setLoading(true);
        document.body.appendChild(clone);

        setTimeout(() => {
          window.print();
          setLoading(false);
          document.getElementById('clone').remove();
        }, 500);
        return;
    }
  };

  // Disabled for now
  // async function uploadAttachments(event: React.ChangeEvent<HTMLInputElement>, profile: UserProfile, report: Report) {
  //   const files = event.target.files;
  //   const currentAttachments: string[] = report.attachments ?? [];

  //   setLoading(true);
  //   setLoadingMessage(`Uploading files...`);

  //   // upload files
  //   let { errors, newAttachments } = await uploadOrderOrReportAttachments(storage, files, profile, report);

  //   // Update order
  //   const mergedAttachments: string[] = [...new Set([...currentAttachments, ...newAttachments])];

  //   try {
  //     await addReportAttachments(firestore, profile, report, mergedAttachments);
  //   } catch (error) {
  //     console.log(`Error updating order`, error);
  //     errors = [];
  //     for (const file of files) {
  //       errors.push({ filename: file.name, error: 'Could not update order in database' });
  //     }
  //   }

  //   if (errors.length > 0) {
  //     alert(`Following files could not be uploaded:\r\n${errors.map(e => `File: ${e.filename}, ${e.error}`).join('\r\n')}`);
  //   }

  //   setLoadingMessage(undefined);
  //   setLoading(false);
  // }

  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    onClick: clickItem,
    profile: profile,
    report,
    draft,
    history,
    orgSettings: organisation?.settings,
  });

  const saveNewConversation = async (conversation: Conversation, report: Report) => {
    let reportCopy = cloneDeep(report);
    // @ts-ignore
    reportCopy.hasReportDraft = draft;
    // copy the name as a contactId
    reportCopy.contactId =
      contacts.find((c) => c.id === report.contactId)?.name ?? organisation.name;
    conversation.title =
      organisation.id === report.reportReference.orgId
        ? `Report: ${report.reportReference.orderId}`
        : `External report for: ${report.reportReference.externalOrderId}`;

    setShowChat(false);
    setLoading(true);
    await addReportMessage(firestore, reportCopy, profile.id, conversation);
    await setLoading(false);

    history.push('/secure/inbox/' + conversation.id);
  };

  return (
    <>
      <IonLoading isOpen={loading} message={loadingMessage} />
      {/* <input
        ref={fileInput}
        hidden
        multiple
        type="file"
        accept=".pdf, .doc*, .xls*, .ppt*"
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => await uploadAttachments(e, profile, report)}
      /> */}
      {
        <IonButton
          color="dark"
          onClick={(e) => {
            present({ event: e.nativeEvent, mode: 'md' });
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <IonIcon icon={ellipsisHorizontal}></IonIcon>
        </IonButton>
      }
      {showChat && (
        <IonModal isOpen={showChat} onDidDismiss={() => setShowChat(false)}>
          <PageNewConversation
            onSave={(conversation) => saveNewConversation(conversation, report)}
            onCancel={() => setShowChat(false)}
            contact={contact}
          />
        </IonModal>
      )}
    </>
  );
};
