/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const InspectionSpecSectionType = {
  Default: 'default',
  Picture: 'picture',
} as const;

export type InspectionSpecSectionType =
  (typeof InspectionSpecSectionType)[keyof typeof InspectionSpecSectionType];
