import { IonBadge, IonIcon } from '@ionic/react';
import React from 'react';
import { ctxContacts } from './App';
import { IArticle } from './Model';
import { Article } from './ServiceArticle';
import './ViewArticleDescription.scss';
// import {ctxProducts} from "./App"
import ViewProductName from './ViewProductName';
import { createOutline } from 'ionicons/icons';

interface Props {
  article: IArticle;
  pills?: boolean;
  showOrder?: boolean;
  lotId?: string;
  quantity?: number;
  searchString?: string;
  growerId?: string;
  onVarietyClick?: () => void;
}

const ADD_VARIETY_STRING = 'Add variety';

const ViewArticleDescription = ({
  article,
  pills = false,
  showOrder = false,
  lotId = undefined,
  quantity = undefined,
  searchString = undefined,
  growerId = undefined,
  onVarietyClick,
}: Props) => {
  if (!article) return null;
  const articleClass = new Article(article);
  let articleInfo = articleClass.getArticleInfoArray();

  const varietyExists: boolean = !!(article.agVariety || article.variety);

  if (!varietyExists && !!onVarietyClick) {
    articleInfo.unshift(ADD_VARIETY_STRING);
  }

  const searchItems = searchString?.replace(' ', '').split(',');

  if (pills) {
    return (
      <div className="view-article-description">
        <div className="article-name" data-tip={'asessment-product-name'}>
          {quantity !== undefined && <IonBadge color="primary">Q: {quantity}</IonBadge>}
          {lotId && `${lotId} · `}
          <ViewProductName article={article} />
        </div>
        <div className="article-badges">
          {articleInfo
            .filter((a) => !!a)
            .filter((a) => a !== '-')
            .map((o, index) => {
              let color =
                searchItems?.findIndex((obj) => obj === o) >= 0 ? 'dark' : 'tertiary';

              if (o === ADD_VARIETY_STRING) {
                color = 'light-medium';
              }

              return (
                <IonBadge
                  color={color}
                  key={index}
                  onClick={() => {
                    if (index !== 0) return;
                    onVarietyClick?.();
                  }}
                >
                  {o}
                  {/* Variety is the first element of the articleInfo array, hence the index check */}
                  {index === 0 && !!onVarietyClick && (
                    <>
                      &nbsp;
                      <IonIcon style={{ fontSize: '12px' }} icon={createOutline} />
                    </>
                  )}
                </IonBadge>
              );
            })}

          {/* Render grower name */}
          {!!growerId && (
            <ctxContacts.Consumer>
              {(contacts) => {
                const growerName =
                  (contacts ?? []).find((c) => c.id === growerId)?.name ?? growerId;
                return (
                  <IonBadge
                    color={
                      searchItems?.findIndex((obj) => obj === growerName) >= 0
                        ? 'dark'
                        : 'tertiary'
                    }
                  >
                    {growerName}
                  </IonBadge>
                );
              }}
            </ctxContacts.Consumer>
          )}
        </div>
      </div>
    );
  }

  return <>{articleInfo.filter((a) => !!a).join(', ')}</>;
};

export default ViewArticleDescription;
