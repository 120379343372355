/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { FieldCriteria } from './field-criteria';
// May contain unused imports in some cases
// @ts-ignore
import { InspectionSpecQuestionSpec } from './inspection-spec-question-spec';
// May contain unused imports in some cases
// @ts-ignore
import { InspectionSpecSection } from './inspection-spec-section';
// May contain unused imports in some cases
// @ts-ignore
import { InspectionSpecSectionNew } from './inspection-spec-section-new';
// May contain unused imports in some cases
// @ts-ignore
import { ScoreSpecs } from './score-specs';

/**
 *
 * @export
 * @interface FieldInspectionSpec
 */
export interface FieldInspectionSpec {
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  creationDate: string;
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  lastModifiedDate: string;
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  lastModifiedUserId?: string;
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  orgId: string;
  /**
   *
   * @type {number}
   * @memberof FieldInspectionSpec
   */
  version: number;
  /**
   * Legacy layout for sections
   * @type {Array<InspectionSpecSection>}
   * @memberof FieldInspectionSpec
   * @deprecated
   */
  layout: Array<InspectionSpecSection>;
  /**
   * New layout for sections
   * @type {Array<InspectionSpecSectionNew>}
   * @memberof FieldInspectionSpec
   */
  sections?: Array<InspectionSpecSectionNew>;
  /**
   * Mapping between a question ID and a question spec
   * @type {{ [key: string]: InspectionSpecQuestionSpec; }}
   * @memberof FieldInspectionSpec
   */
  questionSpecs: { [key: string]: InspectionSpecQuestionSpec };
  /**
   * Mapping between a score ID and a score spec
   * @type {{ [key: string]: ScoreSpecs; }}
   * @memberof FieldInspectionSpec
   */
  scoreSpecs?: { [key: string]: ScoreSpecs };
  /**
   *
   * @type {string}
   * @memberof FieldInspectionSpec
   */
  objectType?: FieldInspectionSpecObjectTypeEnum;
  /**
   *
   * @type {FieldCriteria}
   * @memberof FieldInspectionSpec
   */
  criteria: FieldCriteria;
}

export const FieldInspectionSpecObjectTypeEnum = {
  Field: 'field',
} as const;

export type FieldInspectionSpecObjectTypeEnum =
  (typeof FieldInspectionSpecObjectTypeEnum)[keyof typeof FieldInspectionSpecObjectTypeEnum];
