import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext } from 'react';
import './PagePwaEnforcing.scss';
import { CAgrinormLogo } from './components/CAgrinormLogo';
import { logout } from './DataApp';
import { auth } from './ConfigFirebase';
import { ctxProfile } from './App';

export const PagePwaEnforcing = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Welcome to Agrinorm</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-pwa-enforcing-content">
        <div className="main-wrapper">
          <div className="logo-wrapper">
            <CAgrinormLogo />
          </div>
          <p>
            Agrinorm is designed exclusively as a standalone app for mobile devices and
            cannot be accessed through a web browser. Please install it for the full
            experience.
          </p>
          <p>
            <strong>Need help installing?</strong> Please follow{' '}
            <a href="https://docs.agrinorm.support/en/articles/6053593" target="_blank">
              this link
            </a>{' '}
            to access our Help Center
          </p>
          <div style={{ textAlign: 'center' }}>
            <IonButton color="dark" onClick={() => logout(auth)}>
              Log out
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
