import { alertController, toastController } from '@ionic/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonList,
  IonPage,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import {
  ctxLocations,
  ctxOrg,
  ctxProfile,
  ctxScorings,
  ctxSpecs,
  ctxUsers,
} from './App';
import { firestore } from './ConfigFirebase';
import { takeNativePicture } from './DataImage';
import {
  findInspectionInEntity,
  updateLotOrTransitInspectionInRelatedEntities,
} from './DataInspection';
import {
  GPSCoordinates,
  geolocationPositionToGPS,
  getClosestLocation,
  getGpsLocation,
  gpsToGeoPoint,
} from './DataLocation';
import {
  editVarietyInLotAndOrder,
  getLot,
  getLotSnapshot,
  getOrder,
  getOrgVarietyFromAgVariety,
} from './DataStorage';
import {
  ORDER_BOXES_EXPECTED,
  ORDER_QC_STATUS,
  SUPPLY_CHAIN_COL_NAME,
} from './GlobalConstants';
import { presentStandardToast } from './HelperIonic';
import {
  compileExternalLotInspectionProperties,
  compileInspectionRenderingInfo,
  findScoringForSpec,
  getApplicableLotOrTransitSpecs,
  handleUserInput,
  initDefaultValues,
  newInspectionForLot,
  newInspectionForOrder,
  prepareInspectionContext,
  removeIncompatibleInputs,
} from './InspectionLogic';
import {
  InspectionContext,
  InspectionError,
  InspectionPropertiesMap,
  InspectionStatus,
  LegacyInspection,
  LegacyInspectionReference,
  LotInspection,
  LotProperties,
  UserInputLocator,
  getOCRIssueInput,
} from './InspectionModel';
import {
  AG_COC_INVALID,
  AG_COC_MISSING,
  AG_GGN_INVALID,
  AG_GGN_MISSING,
  AG_GLN_INVALID,
  AG_GLN_MISSING,
  Location,
  Lot,
  Order,
  OrderAGStatus,
  Organisation,
  Picture,
} from './Model';
import { InspectionSpec, LotScoringSection } from './ModelSpecification';
import i18n from './ServiceI18n';
import {
  InspectionClass,
  compileLotPropertiesToSet,
  detectInspectionUpdateConflicts,
  getInspectionBackup,
  getInspectionTimer,
  hasInspectionReference,
  injectOCRQuestionsToSpec,
  parseLegacyInspectionReference,
  removeInspectionAndTimerFromIndexedDB,
  saveInspectionAndTimerIntoIndexedDB,
  stringifyInspectionReference,
} from './ServiceInspection';
import eventLogger from './events/common';
import {
  createModifyCloudInspectionEvent,
  logLocalInspectionModificationEvent,
} from './events/inspection';
import {
  InspectionSpecSection,
  LotSchemaObjectTypeEnum,
  TransitSchemaObjectTypeEnum,
} from './generated/openapi/core';

// Components
import CardInspection from './CardInspection';
import { ViewInspectionScore } from './ViewInspectionScore';
import { CInspectionBottomActions } from './components-inspection/CInspectionBottomActions';
import { CInspectionLoader } from './components-inspection/CInspectionLoader';
import { CInspectionToolbar } from './components-inspection/CInspectionToolbar';
import CLotInspectionHeader from './components-inspection/CLotInspectionHeader';
import { CSection } from './components-inspection/CSection';
import {
  InspectionCtxInterface,
  InspectionContextProvider,
} from './context/InspectionContextProvider';

// Styling
import './PageInspection.scss';
import { CInspectionWarning } from './components-inspection/CInspectionWarning';
import { userHasPermission } from './PermissionsService';
import { isOnline } from './DataApp';

interface Props extends RouteComponentProps {
  organisationId: string;
  reference: string;
  supplyChainLot?: boolean;
}

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PageLegacyInspection = (props: Props) => {
  const { organisationId, reference, supplyChainLot, history } = props;
  // ------------------------------------------------------------------------------------------------
  // subscriptions
  // ------------------------------------------------------------------------------------------------
  const unsubscribers = useRef<any[]>([]);
  // here we store the question ids that are currently being rendered, this way it's easier to check for mandatory fields
  const renderedQuestionIds = useRef<string[]>([]);
  // flag to keep track of the status of the indexedDB in case an error occurs
  const indexedDBOutOfOrder = useRef<boolean>(false);
  // keep track of whether the inspection has been modified by the user
  const isModified = useRef<boolean>(false);
  // for lot inspections, keep track of whether the lot has been fetched from the db and set into the state
  const lotSetInState = useRef<boolean>(false);

  // ------------------------------------------------------------------------------------------------
  // context
  // ------------------------------------------------------------------------------------------------
  const profile = useContext(ctxProfile);
  const myOrg: Organisation = useContext(ctxOrg);
  const locations = useContext(ctxLocations);
  const users = useContext(ctxUsers);
  const { inspectionSpecs } = useContext(ctxSpecs);
  const { scorings } = useContext(ctxScorings);

  // ------------------------------------------------------------------------------------------------
  // Query Params
  // ------------------------------------------------------------------------------------------------
  let query = useQueryParams();
  const boxesExpected: number = !!query.get(ORDER_BOXES_EXPECTED)
    ? +query.get(ORDER_BOXES_EXPECTED)
    : undefined;
  const qcOrderStatus = (query.get(ORDER_QC_STATUS) as OrderAGStatus) ?? undefined;

  // ------------------------------------------------------------------------------------------------
  // state
  // ------------------------------------------------------------------------------------------------
  const [inited, setInited] = useState<boolean>(false);
  const [lot, setLot] = useState<Lot>();
  const [order, setOrder] = useState<Order>();
  const [errors, setErrors] = useState<InspectionError[]>([]);
  const [context, setContext] = useState<InspectionContext>(undefined);
  const [inspection, setInspection] = useState<LegacyInspection>(undefined);
  const [inspectionReference, setInspectionReference] =
    useState<LegacyInspectionReference>(parseLegacyInspectionReference(reference));
  const [spec, setSpec] = useState<InspectionSpec>();
  const [scoring, setScoring] = useState<LotScoringSection>();
  const [applicableSpecs, setApplicableSpecs] = useState<InspectionSpec[]>([]);
  const [warningMessage, setWarningMessage] = useState<string>(undefined);
  const [currGPSCoordinates, setCurrGPSCoordinates] =
    useState<GPSCoordinates>(undefined);

  // refs
  const inspectionRef = useRef<LegacyInspection>();
  const specRef = useRef<InspectionSpec>();
  const contextRef = useRef<InspectionContext>();
  const cameraRef = useRef(undefined);

  // ------------------------------------------------------------------------------------------------
  // life-cycles
  // ------------------------------------------------------------------------------------------------
  const start = async () => {
    setInited(false);

    const inspectionReference = parseLegacyInspectionReference(props.reference);

    let { inspection, context, spec, applicableSpecs, scoring } =
      await getInspectionEntities(inspectionReference, organisationId, inspectionSpecs);

    // console.log('RETRIEVING INSPECTION', inspection);
    // console.log('SCHEMA SET', spec);

    setInspection(inspection);
    setSpec(spec);

    setScoring(scoring);
    setApplicableSpecs(applicableSpecs);
    setContext(context);
    setInited(true);

    getGpsLocation((pos) => setLocationRelatedState(pos, inspection));

    if (!!inspectionReference?.lotId) {
      unsubscribers.current?.map((unsubscribe) => unsubscribe());

      unsubscribers.current.push(
        getLotSnapshot(
          firestore,
          profile.organisationId,
          inspectionReference.lotId,
          onLotSnapshot,
          profile.organisationId,
          supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined
        )
      );
    }
  };

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
    unsubscribers.current?.map((unsubscribe) => unsubscribe());
  });

  useIonViewWillEnter(() => {
    console.log('useIonViewWillEnter event fired');
    start();
  }, [props]);

  useIonViewWillLeave(async () => {
    if (!inspectionRef.current) {
      return;
    }

    if (isModified.current) {
      const alert = await alertController.create({
        header: 'Save Changes?',
        message: 'Do you want to save your changes?',
        buttons: [
          {
            text: 'Discard Changes',
            handler: async () => {
              await removeLocalInspectionBackup(inspectionRef.current);
            },
            cssClass: 'dark',
          },
          {
            text: 'Save Changes',
            cssClass: 'primary',
            handler: async () => {
              await saveInspectionInDB('IN PROGRESS');
            },
          },
        ],
      });

      alert.present();
    }
  });

  // ------------------------------------------------------------------------------------------------
  // Effects
  // ------------------------------------------------------------------------------------------------

  // https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/
  // we need inspection, schema and context at unmount so we need a ref in sync with state
  useEffect(() => {
    inspectionRef.current = inspection;
  }, [inspection]);
  useEffect(() => {
    specRef.current = spec;
  }, [spec]);
  useEffect(() => {
    contextRef.current = context;
  }, [context]);

  // ------------------------------------------------------------------------------------------------
  // methods
  // ------------------------------------------------------------------------------------------------

  const onLotSnapshot = async (latestLotSnapshot: Lot) => {
    console.log('Latest lot snapshot -->', latestLotSnapshot);

    if (!lotSetInState.current) {
      setLot(latestLotSnapshot);
    } else {
      const latestInspectionSnapshot = latestLotSnapshot?.inspections?.find((i) =>
        hasInspectionReference(i, inspectionReference)
      );

      if (!latestInspectionSnapshot || !inspectionRef.current) return;

      await detectInspectionUpdateConflicts(
        inspectionRef.current,
        latestInspectionSnapshot,
        profile,
        users,
        setWarningMessage
      );
    }
    lotSetInState.current = true;
  };

  // ----------------------------------------------------------------------------------------------

  const saveLocalInspectionBackup = async (inspection: LegacyInspection) => {
    // Indicate that the inspection has been modified
    isModified.current = true;
    saveInspectionAndTimerIntoIndexedDB(inspection, presentIndexedDBIssuesToast);
  };

  // ----------------------------------------------------------------------------------------------
  const removeLocalInspectionBackup = async (inspection: LegacyInspection) => {
    removeInspectionAndTimerFromIndexedDB(inspection, presentIndexedDBIssuesToast);
  };

  // ----------------------------------------------------------------------------------------------
  const presentIndexedDBIssuesToast = async (hasError: boolean) => {
    if (hasError && !indexedDBOutOfOrder.current) {
      // TODO: think of a better message
      await presentStandardToast(
        toastController,
        `Warning: A problem occurred while trying to access the device's storage. Inspection backup will not work correctly`
      );
      indexedDBOutOfOrder.current = true;
    } else if (!hasError && indexedDBOutOfOrder.current) {
      // TODO: think of a better message
      await presentStandardToast(
        toastController,
        `Device storage is back online`,
        undefined,
        'success'
      );
      indexedDBOutOfOrder.current = false;
    }
  };

  // ----------------------------------------------------------------------------------------------
  const takeAndSaveNativePicture = async (section?: InspectionSpecSection) => {
    const insp = inspectionRef.current;
    try {
      const picture = await takeNativePicture(
        profile.organisationId,
        stringifyInspectionReference(insp.reference),
        section
      );

      const newPictures = [...(insp.pictures ?? []), picture];
      picturesUpdated(newPictures);
    } catch (e) {
      Sentry.captureException(e);
      console.log('Error saving native picture', e);
    }
  };

  // ----------------------------------------------------------------------------------------------
  const updateInspection = (value: any, inputLocator: UserInputLocator) => {
    let { updatedContext, updatedInspection } = handleUserInput(
      value,
      inputLocator,
      spec,
      inspection,
      context,
      scoring,
      myOrg.settings
    );
    updatedInspection = {
      ...updatedInspection,
      status: 'IN PROGRESS',
      schemaId: spec.id,
      schemaVersion: (spec.version ?? 0).toFixed(),
    };

    setContext(updatedContext);
    setInspection(updatedInspection);

    logLocalInspectionModificationEvent(inspection, updatedInspection, profile);
    saveLocalInspectionBackup(updatedInspection);

    // remove errors when the questionId has been modified
    setErrors(errors.filter((e) => e.questionId !== inputLocator.questionId));

    // console.log(
    //   'updatedContext',
    //   updatedContext,
    //   'updatedInspection',
    //   updatedInspection,
    //   'schema',
    //   spec
    // );

    return { updatedContext, updatedInspection };
  };

  // ------------------------------------------------------------------------------------------------
  const saveInspectionInDB = async (inspectionStatus: InspectionStatus) => {
    const inspection = cloneDeep(inspectionRef.current);
    const spec = specRef.current;
    const context = contextRef.current;

    inspection.renderingInfo = compileInspectionRenderingInfo(
      spec,
      inspection,
      context,
      myOrg?.settings
    );
    inspection.status = inspectionStatus;

    if (inspection.schemaId == null) {
      inspection.schemaId = spec.id;
      inspection.schemaVersion = (spec.version ?? 0).toFixed();
    }

    // Indicate that the user has given the instruction to save the inspection in the db
    isModified.current = false;

    // add time to the assessment when the assessment is closed and changes have been made (the timer is created when user triggers updateInspection for the first time during an inspection)
    const timer = await getInspectionTimer(inspection);

    const currentInspectionTime =
      timer != null && !isNaN(+timer) ? (Date.now() - +timer) / 1000 : 0;

    if (typeof currentInspectionTime === 'number') {
      inspection.inspectionTime =
        inspection.inspectionTime != null
          ? inspection.inspectionTime + currentInspectionTime
          : currentInspectionTime;
    }

    // Add GPS location
    if (!!currGPSCoordinates) {
      inspection.recordedGeoPoint = gpsToGeoPoint(currGPSCoordinates);
    }

    // compile list of properties to be set via setProperty
    let propertiesToSet =
      inspection.objectType === LotSchemaObjectTypeEnum.Lot
        ? compileLotPropertiesToSet(spec, inspection)
        : [];

    // before saving, make sure that all user inputs correspond to questions present in the inspection
    Object.keys(inspection.userInputs ?? {}).forEach((qId) => {
      if (!renderedQuestionIds.current.includes(qId)) {
        delete inspection.userInputs[qId];
      }
    });

    setInspection(inspection);

    try {
      await updateLotOrTransitInspectionInRelatedEntities(
        firestore,
        profile,
        inspection,
        propertiesToSet,
        myOrg,
        undefined,
        supplyChainLot
      );
      await removeLocalInspectionBackup(inspection);
      eventLogger.log(createModifyCloudInspectionEvent(inspection), profile);
    } catch (e) {
      console.error('inspection failed to update', inspection.reference, e);
      presentStandardToast(
        toastController,
        'There was a problem updating the inspection \n\n' + e.message
      );
    }
  };

  // ------------------------------------------------------------------------------------------------
  const setLocationRelatedState = (
    pos: GeolocationPosition,
    initialInspection: LegacyInspection
  ) => {
    const currGPSCoordinates = geolocationPositionToGPS(pos);
    // console.log("Current GPS coordinates:", currGPSCoordinates);

    setCurrGPSCoordinates(currGPSCoordinates);

    setInspection((currInspection) => {
      // we only use the initialInspection if there isn't an inspection already set in the state (in order to avoid issues like DEV-1649, when the device takes too long to determine the location)
      currInspection = currInspection ?? initialInspection;

      if (!new InspectionClass(currInspection).isCompleted()) {
        // Compare actual gps location against locations in the meta location collection and set assessment location to the closest one (if it's within a certain threshold)
        const location: Location = getClosestLocation(currGPSCoordinates, locations);
        // console.log("Closest location within range:", location);

        // If actual location is not accessible or no location was found within the set range, use the location in the order/lot
        const locationId =
          location?.locationId ??
          order?.locationId ??
          lot?.transient?.locationId ??
          null;

        return { ...currInspection, locationId };
      }

      return currInspection;
    });
  };

  // ------------------------------------------------------------------------------------------------
  async function getInspectionEntities(
    reference: LegacyInspectionReference,
    objectOrgId: string,
    specs: InspectionSpec[]
  ): Promise<{
    inspection: LegacyInspection;
    context: InspectionContext;
    applicableSpecs: InspectionSpec[];
    spec: InspectionSpec;
    scoring: LotScoringSection;
  }> {
    const backupInspection: LegacyInspection | undefined =
      await retrieveBackupInspection(reference);

    const { organisationId } = profile;
    const { lotId, transportId, orderId } = reference;

    let externalProperties: InspectionPropertiesMap =
      compileExternalLotInspectionProperties(boxesExpected);

    let inspection: LegacyInspection;
    let context: InspectionContext;
    let applicableSpecs: InspectionSpec[];
    let spec: InspectionSpec;
    let scoring: LotScoringSection;

    if (lotId?.length) {
      const lot: Lot = await getLot(
        firestore,
        organisationId,
        lotId,
        objectOrgId,
        supplyChainLot ? SUPPLY_CHAIN_COL_NAME : undefined
      );

      if (!!lot) {
        setLot(lot);
        console.log('LOT SET', lot);
        inspection =
          backupInspection ?? findInspectionInEntity(reference, undefined, lot);

        externalProperties = compileExternalLotInspectionProperties(boxesExpected, lot);
        ({ spec: spec, applicableSpecs: applicableSpecs } =
          getApplicableLotOrTransitSpecs(reference, specs, lot.article, inspection));

        scoring = findScoringForSpec(spec, scorings);

        if (!!spec) {
          if (!inspection) {
            const emptyInspection = newInspectionForLot(reference, lot, spec, scoring);
            const emptyContext = prepareInspectionContext(
              spec,
              emptyInspection,
              externalProperties
            );
            ({ inspection, context } = initDefaultValues(
              spec,
              emptyInspection,
              emptyContext,
              scoring,
              myOrg?.settings
            ));
          } else {
            removeIncompatibleInputs(inspection, spec, scoring);
            context = prepareInspectionContext(spec, inspection, externalProperties);
          }
        }
      } else {
        console.log(`No lot ${lotId} found`);
      }
    } else if (transportId?.length && orderId?.length) {
      const order: Order = await getOrder(
        firestore,
        organisationId,
        orderId,
        objectOrgId
      );

      if (!!order) {
        setOrder(order);
        console.log('ORDER SET', order);
        inspection = backupInspection ?? findInspectionInEntity(reference, order);

        ({ spec: spec, applicableSpecs: applicableSpecs } =
          getApplicableLotOrTransitSpecs(reference, specs, undefined, inspection));

        scoring =
          spec?.objectType === LotSchemaObjectTypeEnum.Lot
            ? findScoringForSpec(spec, scorings)
            : undefined;

        if (!!spec) {
          if (!inspection) {
            ({ spec: spec, applicableSpecs: applicableSpecs } =
              getApplicableLotOrTransitSpecs(reference, specs));
            const emptyInspection = newInspectionForOrder(reference, order);
            const emptyContext = prepareInspectionContext(
              spec,
              emptyInspection,
              externalProperties
            );
            ({ inspection, context } = initDefaultValues(
              spec,
              emptyInspection,
              emptyContext,
              scoring,
              myOrg?.settings
            ));
          } else {
            context = prepareInspectionContext(spec, inspection, externalProperties);
          }
        }
      } else {
        console.log(`No order ${orderId} found`);
      }
    }

    return {
      inspection,
      context,
      applicableSpecs: applicableSpecs,
      spec: spec,
      scoring,
    };
  }

  // ------------------------------------------------------------------------------------------------
  const picturesUpdated = (picturesArray: Picture[]) => {
    console.log('pictures-updated', picturesArray);
    const updatedInspection = { ...inspection, pictures: picturesArray };
    setInspection((prevInspection) => {
      const newInspection = { ...prevInspection, ...updatedInspection };
      logLocalInspectionModificationEvent(prevInspection, newInspection, profile);
      // remove picture related errors
      setErrors(
        errors.filter((e) =>
          picturesArray.some((p) => p.inputIds.includes(e.questionId))
        )
      );
      return newInspection;
    });
    saveLocalInspectionBackup(updatedInspection);
  };

  //-------------------------------------------------------------------------------------------------
  const retrieveBackupInspection = async (
    reference: LegacyInspectionReference
  ): Promise<LegacyInspection | undefined> => {
    let backupInspection: LegacyInspection = await getInspectionBackup(reference);

    if (!backupInspection) {
      console.log(`No backup found for ${stringifyInspectionReference(reference)}`);
    } else {
      // if a backup is present, it means the user had previously exited the app while in the middle of the inspection, so we set this flag to true
      isModified.current = true;
      console.log('Local inspection backup found:', backupInspection);

      // TODO DEV-1853: for now we are adding the objectType here to account for inspections that haven't been migrated (remove after migration)
      if (!backupInspection.objectType) {
        backupInspection.objectType = backupInspection.reference.transportId
          ? TransitSchemaObjectTypeEnum.Transit
          : LotSchemaObjectTypeEnum.Lot;
      }
    }
    return backupInspection;
  };

  // ------------------------------------------------------------------------------------------------
  function updateOCRData(
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) {
    const newPartialInspection: Partial<LotInspection> = cloneDeep({
      lotProperties,
      userInputs: {},
    });
    let newSpec = cloneDeep(spec);

    let addedQIds: string[] = [];
    (
      [
        [ggn_invalid, AG_GGN_INVALID],
        [ggn_missing, AG_GGN_MISSING],
        [gln_invalid, AG_GLN_INVALID],
        [gln_missing, AG_GLN_MISSING],
        [coc_invalid, AG_COC_INVALID],
        [coc_missing, AG_COC_MISSING],
      ] as [boolean, string][]
    ).forEach(([hasIssue, questionId]) => {
      if (hasIssue) {
        newPartialInspection.userInputs[questionId] = getOCRIssueInput(questionId);
        addedQIds.push(questionId);
      }
    });

    newSpec = injectOCRQuestionsToSpec(newSpec, addedQIds);
    setSpec(newSpec);

    setInspection((prevInspection: LotInspection) => {
      const newInspection: LotInspection = {
        ...prevInspection,
        ...newPartialInspection,
        userInputs: {
          ...prevInspection.userInputs,
          ...newPartialInspection.userInputs,
        },
      };
      logLocalInspectionModificationEvent(prevInspection, newInspection, profile);
      saveLocalInspectionBackup(newInspection);
      return newInspection;
    });
  }

  const editVariety = async (agVariety: string) => {
    try {
      // Extract org specific variety id, if applies
      const variety: string = await getOrgVarietyFromAgVariety(
        firestore,
        myOrg.id,
        agVariety
      );

      // Edit variety in inspection
      setInspection((i) => {
        if (i.objectType !== 'lot') return i;
        return {
          ...i,
          lotProperties: {
            ...i.lotProperties,
            article: { ...i.lotProperties.article, variety, agVariety },
          },
        };
      });

      // Modify variety in lot and/or order
      if (inspectionReference.lotId) {
        await editVarietyInLotAndOrder(
          firestore,
          myOrg.id,
          variety,
          agVariety,
          inspectionReference.lotId,
          inspectionReference.orderId
        );
      }
    } catch (error) {
      presentStandardToast(
        toastController,
        `Something went wrong while editing the variety:\n${error}`
      );
    }
  };

  // ==================================================================================================
  // render
  // ==================================================================================================

  const emptyInspection = newInspectionForLot(inspectionReference);

  const isOpen = !new InspectionClass(inspection).isCompleted();
  const isLocked = (['DONE', 'SHARED', 'CHECKED'] as OrderAGStatus[]).includes(
    qcOrderStatus
  );
  const isEditable = isOpen && !isLocked;

  const isLoading = !inited || !myOrg;

  const inspectionContextProviderProps: InspectionCtxInterface = {
    inspection,
    spec: spec,
    context,
    setContext,
    setInspection,
    updateInspection,
    currGPSCoordinates,
    supplyChainLot,
    errors,
    saveInspectionInDB,
    inited,
    renderedQuestionIds,
    setScoring,
    setSpec: setSpec,
    retrieveBackupInspection,
    setErrors,
    isLocked,
    applicableSpecs: applicableSpecs,
    setApplicableSpecs: setApplicableSpecs,
    isEditable,
    inspectionReference,
  };

  return (
    <IonPage className="page-inspection">
      <CInspectionToolbar>
        <IonButtons slot="start">
          <IonBackButton
            text={i18n.t('General.back')}
            defaultHref={
              inspectionReference.orderId
                ? `/secure/${myOrg?.id}/order/${inspectionReference.orderId}`
                : '/tabs/quality-control'
            }
            color="dark"
          />
        </IonButtons>
        <IonButtons slot="end" style={{ marginRight: '10px' }}>
          <ViewInspectionScore
            profile={profile}
            organisationSettings={myOrg?.settings}
            inspection={inspection}
            displayType="CARD-ORDER"
          />
        </IonButtons>
      </CInspectionToolbar>
      <IonContent className={!isEditable ? 'is-locked' : ''}>
        <CInspectionToolbar collapse="condense" size="large" />

        <CInspectionWarning
          warningMessage={warningMessage}
          setWarningMessage={setWarningMessage}
          onDiscardChangesAccept={async () => {
            isModified.current = false;
            await removeLocalInspectionBackup(inspection);
          }}
        />

        {isLocked && (
          <div className="lock-message">
            <div className="p1">Inspection locked</div>
            <div className="p2">order has been finished</div>
          </div>
        )}

        {isLoading ? (
          <CInspectionLoader />
        ) : (
          <InspectionContextProvider {...inspectionContextProviderProps}>
            <CardInspection
              inspection={!!inspection?.reference ? inspection : emptyInspection}
              loading={false}
              organisationId={myOrg?.id}
              allowGalleryEdit={isEditable}
              onVarietyEdit={
                isOnline() &&
                isEditable &&
                myOrg?.settings.allowManagingLots &&
                userHasPermission(profile, 'WRITE', 'LOT')
                  ? (v: string) => editVariety(v)
                  : undefined
              }
              updateOCRData={updateOCRData}
              onPicturesUpdated={picturesUpdated}
              schema={spec}
            />

            <CInspectionBottomActions
              cameraRef={cameraRef}
              lot={lot}
              onNativePicture={takeAndSaveNativePicture}
              saveLocalInspectionBackup={saveLocalInspectionBackup}
              updateOCRData={updateOCRData}
            />

            <CLotInspectionHeader
              inspectionReference={inspectionReference}
              setInspectionReference={setInspectionReference}
              history={history}
              orgId={organisationId}
              boxesExpected={boxesExpected}
              qcStatus={qcOrderStatus}
              lot={lot}
              order={order}
            />

            <IonList
              className={`inspection-wrapper ${inspection?.status}`}
              id="page-inspection-content"
            >
              {!!inspection &&
                !!spec &&
                !!inspectionReference?.type &&
                spec.layout.map((section) => (
                  <CSection
                    key={section.sectionType}
                    onNativePicture={takeAndSaveNativePicture}
                    section={section}
                    cameraRef={cameraRef}
                  />
                ))}
            </IonList>
          </InspectionContextProvider>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PageLegacyInspection;
