import {
  flatten,
  uniq,
  head,
  last,
  countBy,
  forEach,
  forIn,
  orderBy,
  findIndex,
} from 'lodash';
import { IQuestionSpec, isQuestionSpec } from './Model';
import {
  InspectionSpecSection,
  QuestionSpec,
  QuestionSpecIn,
  SectionType,
  SubsectionItemIn,
} from './generated/openapi/core';
import { EditSectionRequest } from './ModelSpecification';

// TODO: rules for questionSpecs with more than one value
export function checkIfRangeIsValid(scoringPolicy, input) {
  const isARuleValueInvalid = isARuleValueArrayNull(scoringPolicy);
  if (isARuleValueInvalid.value)
    return {
      isValid: false,
      message: `Score at line ${isARuleValueInvalid.at} must have an input. Please add an input or remove the score.`,
    };

  const arrayOfMinMaxValuePairs = scoringPolicy.map((rule) => [
    rule.values[0].min,
    rule.values[0].max,
  ]);

  const arrayOfValues: number[] = flatten(arrayOfMinMaxValuePairs);

  // sorting sometimes requires a more explicit comparison callback
  const uniqueValuesSorted: number[] = uniq(arrayOfValues).sort((a, b) => a - b);

  const minValueInRange: number = input.minValue || head(uniqueValuesSorted);
  const maxValueInRange: number = input.maxValue || last(uniqueValuesSorted);

  const isMinValueInputsSameAsPolicy = minValueInRange === head(uniqueValuesSorted);
  const isMaxValueInputsSameAsPolicy = maxValueInRange === last(uniqueValuesSorted);

  if (!isMinValueInputsSameAsPolicy)
    return {
      isValid: false,
      message: `Min value of ${minValueInRange} in Input level fields do not match Lowest value of ${head(
        uniqueValuesSorted
      )} in Scoring policy`,
    };
  if (!isMaxValueInputsSameAsPolicy)
    return {
      isValid: false,
      message: `Max value of ${maxValueInRange} in Input level fields do not match Highest value of ${last(
        uniqueValuesSorted
      )} in Scoring policy`,
    };

  const range = maxValueInRange - minValueInRange;
  let rangeFromPairsOfMinMax = 0;
  let isMinMaxPairsValid = { isValid: true, message: '' };
  const arrayOfMinMaxValuePairsMapped = arrayOfMinMaxValuePairs.map(
    (minmaxpair, index) => {
      return { min: minmaxpair[0], max: minmaxpair[1], line: index + 1 };
    }
  );
  const arrayOfMinMaxValuePairsSorted = orderBy(arrayOfMinMaxValuePairsMapped, ['min']);
  arrayOfMinMaxValuePairsSorted.forEach((minmaxpair, iteration) => {
    const { min, max, line: index } = minmaxpair;
    if (min >= max) {
      isMinMaxPairsValid = {
        isValid: false,
        message: `Please swap the order of ${min} and ${max} in line ${index}! The lower value should go first.`,
      };
    } else {
      if (iteration >= 1 && isMinMaxPairsValid.isValid) {
        let previousMaxValue = arrayOfMinMaxValuePairsSorted[iteration - 1].max;
        let currentMinValue = min;
        if (previousMaxValue !== currentMinValue) {
          let previousMaxValLine =
            findIndex(
              arrayOfMinMaxValuePairsMapped,
              (o: any) => o.max === previousMaxValue
            ) + 1;
          isMinMaxPairsValid = {
            isValid: false,
            message: `The min value ${currentMinValue} in line ${index}, does not match the max value ${previousMaxValue} in line ${previousMaxValLine}. These values should match to avoid any gaps or overlaps in the range.`,
          };
        }
      }

      const pairRange: number = max - min;
      rangeFromPairsOfMinMax = rangeFromPairsOfMinMax + pairRange;
    }
  });

  if (!isMinMaxPairsValid.isValid) return isMinMaxPairsValid;

  const isRangeFullyCovered = rangeFromPairsOfMinMax === range;
  const isRangeOverlapping = rangeFromPairsOfMinMax > range;
  if (isRangeOverlapping)
    return { isValid: false, message: 'Ranges in Scoring policy Overlap' };

  const isRangeContainingGaps = rangeFromPairsOfMinMax < range;
  if (isRangeContainingGaps)
    return { isValid: false, message: 'Ranges in Scoring policy contain gaps' };

  const isRangeValid =
    isRangeFullyCovered && !isRangeOverlapping && !isRangeContainingGaps;
  if (isRangeValid) return { isValid: true, message: 'Valid Range' };
}

export function checkIfRulesAreValid(questionsSpecs: IQuestionSpec) {
  const hasScoringPolicy = questionsSpecs.scoringPolicy?.length > 0 ?? false;

  if (!hasScoringPolicy)
    return { isValid: true, message: 'Rules do not contain a scoring policy' };

  const isInputsOfLengthOne = questionsSpecs.inputs.length === 1;

  if (!isInputsOfLengthOne)
    return { isValid: true, message: 'Rules contain more than one value' };

  const input = questionsSpecs.inputs[0];
  const { scoringPolicy } = questionsSpecs;
  const inputType = input.answerType || input.inputType;

  switch (inputType) {
    case 'CATEGORY':
      const isARuleValueInvalid = isARuleValueArrayNull(scoringPolicy);
      if (isARuleValueInvalid.value)
        return {
          isValid: false,
          message: `Score at line ${isARuleValueInvalid.at} must have an input. Please add an input or remove the score.`,
        };
      const numberOfTimesCategoriesPresent = countBy(
        scoringPolicy,
        'values[0].category'
      );
      let isCategoryRepeated: boolean = false;
      forEach(numberOfTimesCategoriesPresent, (category) => {
        if (category > 1) isCategoryRepeated = true;
      });
      let errorCategory = '';
      forIn(numberOfTimesCategoriesPresent, (key, value) => {
        if (key > 1) errorCategory = value;
      });
      if (isCategoryRepeated)
        return { isValid: false, message: `Category '${errorCategory}' Repeated` };
      break;

    case 'BOOLEAN':
      const isARuleBoolValueInvalid = isARuleValueArrayNull(scoringPolicy);
      if (isARuleBoolValueInvalid.value)
        return {
          isValid: false,
          message: `Score at line ${isARuleBoolValueInvalid.at} must have an input. Please add an input or remove the score.`,
        };

      const numberOfTimesBooleanIsPresent = countBy(
        scoringPolicy,
        'values[0].boolValue'
      );
      const numberOfTimesScoreIsPresent = countBy(scoringPolicy, 'score');
      let isBooleanRepeated: boolean = false;
      let isScoreRepeated: boolean = false;
      forEach(numberOfTimesBooleanIsPresent, (category) => {
        if (category > 1) isBooleanRepeated = true;
      });
      forEach(numberOfTimesScoreIsPresent, (score) => {
        if (score > 1) isScoreRepeated = true;
      });
      if (isBooleanRepeated)
        return { isValid: false, message: 'Boolean Value Repeated' };
      if (isScoreRepeated) return { isValid: false, message: 'Score Value Repeated' };
      break;

    case 'FLOAT':
    case 'INT':
      const { isValid, message } = checkIfRangeIsValid(scoringPolicy, input);
      if (!isValid) {
        return { isValid, message };
      }
      break;

    default:
      return { isValid: true, message: 'Rules are valid' };
  }

  return { isValid: true, message: 'Rules are valid' };
}

const isARuleValueArrayNull = (scoringPolicy) => {
  let isARuleValueArrayNull = { value: false, at: 0 };
  scoringPolicy.forEach((rule, index) => {
    if (rule.values[0] === null) {
      isARuleValueArrayNull = { value: true, at: index + 1 };
    }
  });
  return isARuleValueArrayNull;
};

export function getSectionPreviewEntities(
  editSection: EditSectionRequest & { id: string; type: SectionType }
): {
  questionSpecs: { [qId: string]: QuestionSpec };
  section: InspectionSpecSection;
} {
  if (Object.keys(editSection ?? {}).length === 0) {
    return { questionSpecs: undefined, section: undefined };
  }
  const questionSpecs: { [qId: string]: QuestionSpec } = {};

  const { name, layout } = editSection;

  const section: InspectionSpecSection = {
    name,
    type: 'default',
    sectionType: editSection.type,
    hint: editSection.hint,
    hintImages: editSection.hintImages,
    layout: layout.map((l) => {
      if (isQuestionSpec(l)) {
        const { questionId } = l as QuestionSpecIn;
        questionSpecs[questionId] = l as QuestionSpec;
        return questionId;
      } else {
        const { name, expandByDefault, questionSpecs: qSpec } = l as SubsectionItemIn;
        return {
          name,
          expandByDefault,
          questionIds: qSpec.map((q) => {
            const { questionId } = q;
            questionSpecs[questionId] = q as QuestionSpec;
            return questionId;
          }),
        };
      }
    }),
  };
  // console.log("editSection", editSection, "questionSpecs", questionSpecs, "section", section)
  return { questionSpecs, section };
}
