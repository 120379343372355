import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import i18n from './ServiceI18n';
import { Contact } from './Model';
import './PageConnect.scss';
import { barChartOutline, person, search } from 'ionicons/icons';
import {
  AppProduct,
  InsightsTierAction,
  orgHasPermission,
  userHasPermission,
} from './PermissionsService';
import { getContactIdsWithInsights } from './DataInsight';
import withContext, { ContextProps } from './HOC/withContext';

interface Props {}

interface State {
  search: string;
  showSearchbar?: boolean;

  contactIdsWithInsights: string[];
}

class PageConnect extends React.Component<Props & ContextProps, State> {
  searchBarRef = React.createRef<HTMLIonSearchbarElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
      contactIdsWithInsights: [],
    };
  }

  async componentDidMount(): Promise<void> {
    if (this.insightsPermission()) {
      const { contactIds: contactIdsWithInsights } = await getContactIdsWithInsights(
        this.props.profile.organisationId
      );
      this.setState({ contactIdsWithInsights });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props & ContextProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      prevState.showSearchbar !== this.state.showSearchbar &&
      !!this.state.showSearchbar
    ) {
      this.searchBarRef?.current?.setFocus();
    }
  }

  private renderSearchView(search: string) {
    let elements: any[] = [];

    this.props.contacts.forEach((contact) => {
      if (
        contact.name.toLowerCase().indexOf(search) >= 0 ||
        !!contact.users?.find((u) => u.email.indexOf(search) >= 0)
      ) {
        elements.push({
          type: 'CONTACT',
          item: contact,
          id: 'contact-' + contact.id,
          users: search
            ? contact.users?.filter((u) => u.email.indexOf(search) >= 0) ?? []
            : [],
        });
      }
    });

    return (
      <IonList>
        {elements.map((element, idx) => (
          <IonItem
            key={element.id}
            detail
            routerLink={'/secure/contact/' + element.item.id}
          >
            <IonLabel>
              <div>
                <IonBadge color="light">
                  <IonIcon icon={person} />
                  {element.users?.length ?? 0}
                </IonBadge>
                {element.item.name.toLocaleLowerCase()}
              </div>
              {!!element.users.length &&
                element.users.map((u) => <div key={u.email}>{u.email}</div>)}
            </IonLabel>
            {this.insightsPermission() &&
              this.state.contactIdsWithInsights?.includes(element?.item?.id) && (
                <IonIcon slot="end" icon={barChartOutline} />
              )}
          </IonItem>
        ))}
      </IonList>
    );
  }

  insightsPermission = (): boolean => {
    return (
      userHasPermission(this.props.profile, 'VIEW', 'INSIGHT_PROFILES') &&
      orgHasPermission(
        this.props.organisation?.settings,
        AppProduct.Insights,
        InsightsTierAction.Partners
      )
    );
  };

  private renderDefaultView() {
    // console.log('pendingInvitations', pendingInvitations);

    const getLink = (contact: Contact) => {
      const qcPermission = userHasPermission(this.props.profile, 'VIEW', 'ORDER');
      const insightsLink = `/secure/${
        contact.type?.includes('BUYER') ? 'customer' : 'supplier'
      }/${contact.id}/insight`;
      const standardLink = '/secure/contact/' + contact.id;

      return this.insightsPermission() && !qcPermission ? insightsLink : standardLink;
    };

    return (
      <IonList>
        <IonItemDivider>Partners</IonItemDivider>
        {this.props.contacts
          ?.sort((a: Contact, b: Contact) => a.name.localeCompare(b.name))
          .slice(0, 50)
          .map(
            (contact) => (
              <IonItem
                detail
                routerLink={getLink(contact)}
                className="capitalize"
                key={contact.id}
              >
                <div>
                  <IonBadge color="light">
                    <IonIcon icon={person} />
                    {contact.users?.length}
                  </IonBadge>
                  {contact.name?.toLocaleLowerCase() || contact.id}
                  {contact.type?.map((t) => (
                    <IonBadge color="medium" key={t}>
                      {t}
                    </IonBadge>
                  ))}
                </div>

                {this.insightsPermission() &&
                  this.state.contactIdsWithInsights?.includes(contact.id) && (
                    <IonIcon slot="end" icon={barChartOutline} />
                  )}
              </IonItem>
            )
            // <IonCard key={contact.id} className="card-partner" routerLink={"/secure/contact/"+contact.id}>
            //   <IonCardContent>
            //     {contact.imageId ?
            //       <ViewPicture id={contact.imageId} alt={contact.name} className="partner-logo"></ViewPicture> :
            //       <h2>{contact.name}</h2>}
            //     <div className="partner-score">
            //       <img src={score0} alt="Background"/>
            //       <span className="number">{parseInt(contact.id) % 3 === 0 ? '7' : '9'}</span>
            //     </div>
            //   </IonCardContent>
            // </IonCard>
          )}
      </IonList>
    );
  }

  render() {
    console.log('render partners');
    // console.log('this.props.applicationContext?.contacts', this.props.applicationContext?.contacts);
    return (
      <IonPage className="page-connect ion-page">
        <IonHeader>
          {!this.state.showSearchbar ? (
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton color="dark" />
              </IonButtons>
              <IonTitle>{i18n.t('PageConnect.title')}</IonTitle>
              <IonButtons slot="end" color="light">
                <IonButton onClick={() => this.setState({ showSearchbar: true })}>
                  <IonIcon slot="icon-only" icon={search} color="dark"></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          ) : (
            <IonToolbar>
              <IonSearchbar
                ref={this.searchBarRef}
                placeholder={i18n.t('PageConnect.searchPlaceholder')}
                onIonChange={(evt) => this.setState({ search: evt.detail.value + '' })}
                showCancelButton="always"
                onIonCancel={(_) => this.setState({ showSearchbar: false })}
              />
            </IonToolbar>
          )}
        </IonHeader>
        <IonContent>
          {this.state.search?.length > 0
            ? this.renderSearchView(this.state.search.toLowerCase())
            : this.renderDefaultView()}
        </IonContent>
      </IonPage>
    );
  }
}

export default React.memo(
  withContext(PageConnect, ['profile', 'contacts', 'organisation'])
);
