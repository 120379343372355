import { functions } from './ConfigFirebase';
import { toastController } from '@ionic/core';
import React, { useContext } from 'react';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './PageFilters.scss';
import {
  arrowDownOutline,
  arrowUpOutline,
  closeOutline,
  syncOutline,
  helpCircleOutline,
} from 'ionicons/icons';
import {
  Lot,
  OrderAGStatus,
  OrderAGStatuses,
  OrderType,
  Organisation,
  ReportStatus,
  ReportType,
  UserProfile,
} from './Model';

import { ctxContacts, ctxProducts, ctxUsers, ctxLocations, delay, ctxOrg } from './App';
import Select from 'react-select';
import { hasLocationRestriction } from './PermissionsService';
import {
  AGFilters,
  AGSavedFilters,
  areFiltersEqual,
  compileDateTabMapping,
  qcRelevantOrderTypeMap,
  removeSectionSpecificFilterFields,
  SearchField,
} from './SearchService';
import { reportTypeToStringMap } from './DataReport';
import {
  AGTab,
  AGTabs,
  FilterType,
  QCFilterType,
  ReportFilterType,
} from './ModelAGTabs';
import { cloneDeep, debounce, pickBy, identity, omit } from 'lodash';
import { firestore } from './ConfigFirebase';
import { lotColRef, profileDocRef } from './DataStorage';
import { reorderTodos, TodoReorderOptions } from './DataTodo';
import { InspectionType, PackagingType } from './generated/openapi/core';
import { PackagingNames } from './ModelSpecification';
import { useLocationAdminOptions } from './hooks/useLocationAdminOptionsOld';
import { presentStandardToast } from './HelperIonic';
import { CDateRange } from './HelperInsight';
import { isMobile } from './HelperUtils';

export const SortByOptions = [
  { label: 'Arrival Date', value: 'fulfilmentDate' },
  { label: 'Last Inspection', value: 'lastQCStatusDate' },
];

interface Props {
  profile: UserProfile;
  organisation: Organisation;
  filters: AGFilters;
  onCancel?: Function;
  changeSortingBy?: Function;
  onFilter: Function;
  searchByText?: Function;
  isComponent?: boolean;
  searchOnChange?: boolean;
  handleSortingOnClick?: Function;
  dateFilter?: {
    dateLabel: string;
    sorting: 'desc' | 'asc';
    start: {
      value: any;
      max: any;
      onChange: Function;
    };
    end: {
      value: any;
      min: any;
      onChange: Function;
    };
  };

  filterType?: FilterType;
  searchFields: SearchField[];
  onDateClick?: Function;

  agTabs?: AGTabs<FilterType>;
  setAgTabs?: (agTabs: AGTabs<FilterType>) => void;
}

interface State extends AGFilters {
  refreshing?: boolean;
  processing?: boolean;
  hintField?: string;

  showSavedFilters: boolean;
}

class PageFilters extends React.Component<Props, State> {
  myRef = React.createRef<HTMLDivElement>();
  searchRef = React.createRef<HTMLIonSearchbarElement>();

  debouncedSearch: Function;

  emptyState: AGFilters = {
    article: undefined,
    contactId: undefined,
    growerId: undefined,
    userId: undefined,
    locationId: undefined,
    locationReference1: undefined,
    onlyLinked: undefined,
    qcStatus: undefined,
    reportStatus: undefined,
    reportType: undefined,
    qcScore: undefined,
    orderType: undefined,
    inspectionType: undefined,
    hasAssessment: undefined,
    tentativeOrderId: undefined,
    isInStock: undefined,
    showCompleted: undefined,
    search: '',
    producedVarieties: [],
    productionType: undefined,
    skipZeroVolume: false,
    producedProducts: [],
    partnerId: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.debouncedSearch = debounce((val) => this.props.searchByText(val), 300);

    this.state = {
      processing: false,
      showSavedFilters: false,
    };
  }

  syncStockData(filters: AGFilters) {
    const syncStockDataMessage = functions.httpsCallable('syncStockData');
    syncStockDataMessage({ orgId: this.props.profile.organisationId, filters });
  }

  onFilter(filter?: AGFilters, onlyThisFilter?: boolean) {
    if (onlyThisFilter) {
      this.syncStockData(filter);
      return this.props.onFilter(filter);
    }

    const filters = { ...this.props.filters, ...filter };
    this.syncStockData(filters);
    return this.props.onFilter(filters);
  }

  async onClear() {
    const isLocationRestricted = hasLocationRestriction(this.props.profile);
    let locationIdOnClear = undefined;
    if (isLocationRestricted) locationIdOnClear = isLocationRestricted;

    const clearFilter = {
      ...this.emptyState,
      arrivalDate: this.props.filters.arrivalDate,
      locationId: locationIdOnClear,
    };
    const thereWasSearchText = !!this.props.filters.search?.length;

    if (this.searchRef?.current != null) {
      this.searchRef.current.value = '';
    }

    if (thereWasSearchText) {
      this.props.searchByText('');
    } else {
      this.onFilter(clearFilter);
    }
  }

  async saveFilter() {
    const filterToSave = removeSectionSpecificFilterFields(this.props.filters);
    console.log(filterToSave);

    if (
      Object.entries(filterToSave).filter(([k, v]) =>
        Array.isArray(v)
          ? v.length > 0
          : k === 'article'
          ? (v?.productId ?? []).length > 0
          : !!v
      ).length === 0
    ) {
      return presentStandardToast(toastController, `Cannot save empty filter`);
    }

    let savedFilters = cloneDeep(this.props.profile.filters ?? {});

    const equivalentFilters = Object.entries(savedFilters).filter(([_, filter]) =>
      areFiltersEqual(filterToSave, filter)
    );
    if (equivalentFilters.length > 0) {
      return presentStandardToast(
        toastController,
        `Filter with same parameters already exists: "${equivalentFilters[0][0]}"`
      );
    }

    let filterName = prompt('Filter Name?');
    if (!filterName || filterName?.length === 0) {
      return;
    }

    if (!!savedFilters[filterName]) {
      return presentStandardToast(
        toastController,
        `Filter "${filterName}" already exists`
      );
    }

    // also store the current tab vs date range mapping
    savedFilters[filterName] = {
      ...filterToSave,
      dateTabMapping: compileDateTabMapping(this.props.agTabs),
    };

    return this.updateFiltersInProfile(savedFilters);
  }

  selectSavedFilter(filter: AGSavedFilters) {
    this.onClear();

    const newFilter: AGFilters = omit(filter, 'dateTabMapping');

    const { agTabs, setAgTabs, filterType: selectedTab } = this.props;

    // if the current saved filter has a tab vs date range mapping, we translate the ranges into actual dates
    // and update them in the agTabs/filters
    if (!!filter.dateTabMapping && !!agTabs && !!setAgTabs) {
      const newAgTabs: AGTabs<FilterType> = (
        Object.entries(agTabs) as [FilterType, AGTab<FilterType>][]
      ).reduce(
        (acc, [tab, value]) => {
          const { sorting, range } = filter.dateTabMapping[tab] ?? {};

          const newAgTab: AGTab<FilterType> = {
            ...value,
          };

          let minDate: Date | undefined;
          let maxDate: Date | undefined;
          if (!!range) {
            newAgTab.range = range;
            const DateRange = new CDateRange(range);
            minDate = DateRange.getStart() as Date;
            maxDate = DateRange.getEnd() as Date;
          }

          // Update the dates/sorting in the agTabs, and also in the filter according to the currently selected tab
          const isSelectedTab: boolean = !!selectedTab && selectedTab === tab;

          if (!!minDate) {
            newAgTab.minDate = minDate;
            if (isSelectedTab) newFilter.arrivalDate = minDate;
          }

          if (!!maxDate) {
            newAgTab.maxDate = maxDate;
            if (isSelectedTab) newFilter.arrivalDateMax = maxDate;
          }

          if (!!sorting) {
            newAgTab.sorting = sorting;
            if (isSelectedTab) newFilter.sort = sorting;
          }

          return { ...acc, [tab]: newAgTab };
        },
        { ...agTabs }
      );

      setAgTabs(newAgTabs);
    }

    this.onFilter(newFilter, true);

    // We don't close the saved filters view on filter select for mobile users,
    // since the filter needs to be focused for the "remove" option to appear
    if (!isMobile()) {
      this.setState({ showSavedFilters: false });
    }
  }

  deleteFilter(name: string) {
    if (window.confirm('Do you want to remove this filter?\n- ' + name)) {
      let savedFilters = cloneDeep(this.props.profile.filters);
      delete savedFilters[name];
      // this.setState({ savedFilters });
      return this.updateFiltersInProfile(savedFilters);
    }
  }

  async updateFiltersInProfile(filters: { [filterName: string]: AGFilters }) {
    return profileDocRef(firestore, this.props.profile.id).update({
      filters,
    } as UserProfile);
  }

  randomDate(start, end, startHour, endHour) {
    var date = new Date(+start + Math.random() * (end - start));
    var hour = (startHour + Math.random() * (endHour - startHour)) | 0;
    date.setHours(hour);
    return date;
  }

  async refreshStock() {
    await this.setState({ refreshing: true });
    const ref = await lotColRef(firestore, this.props.profile.organisationId)
      // .where('transient.isInStock', '!=', true)
      .where('search.hasAssessment', '==', true)
      .where('transient.freshnessDate', '<=', this.props.filters.arrivalDateMax)
      .where('transient.freshnessDate', '>=', this.props.filters.arrivalDate)
      .orderBy('transient.freshnessDate', 'asc')
      .limit(15)
      .get();

    ref.docs.map((d, index) => {
      const doc = d.data() as Lot;
      console.log(index, doc);
      // @ts-ignore
      doc.transient.isInStock = !doc.transient.isInStock;
      lotColRef(firestore, this.props.profile.organisationId).doc(d.id).set(doc);
    });

    await delay(2000);
    this.setState({ refreshing: false });
    this.onFilter();
    return;
  }

  setHint(field?: SearchField) {
    this.setState({ hintField: !!this.state.hintField ? undefined : field });
  }

  renderHint() {
    let hint: string;

    switch (this.state.hintField) {
      case 'skipZeroVolume':
        hint =
          'A fully-processed batch is one where all volume has been allocated to an order or split into child batches';
        break;
      default:
        break;
    }

    return (
      !!hint && (
        <IonItem color="dark" onClick={() => this.setHint()}>
          {hint}
        </IonItem>
      )
    );
  }

  wrapper(list) {
    return (
      <div
        className={`no-print ${
          this.props.isComponent ? 'page-filters' : 'ion-page page-filters'
        }`}
      >
        {this.props.isComponent ? null : (
          <IonHeader translucent={true}>
            <IonToolbar>
              <IonTitle>Search Filter</IonTitle>
              <IonButtons slot="start">
                <IonButton color="dark" onClick={() => this.props.onCancel()}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton color="dark" onClick={(_) => this.onClear()}>
                  Clear
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        {this.state.showSavedFilters ? (
          this.props.isComponent ? (
            this.savedFilters()
          ) : (
            <IonContent>{this.savedFilters()}</IonContent>
          )
        ) : this.props.isComponent ? (
          <>{list}</>
        ) : (
          <IonContent>{list}</IonContent>
        )}
      </div>
    );
  }

  render() {
    const filters = pickBy(this.props.filters, identity);
    const { profile, filterType } = this.props;

    let { searchFields, dateFilter } = this.props;

    searchFields = searchFields ?? [];

    const contactTitle = 'Partner';

    const isTodo = filterType === 'STOCK';
    const isField = filterType === 'FIELD_INSPECTIONS';

    const list = (
      <IonList>
        {this.props.isComponent && (
          <IonItem className="search-by-id" data-tip={'page-filters-search-by-id'}>
            <IonLabel>Search by ID</IonLabel>
            <IonSearchbar
              // debounce={500}
              ref={this.searchRef}
              mode="ios"
              inputmode="decimal"
              placeholder={isField ? 'enter the id/name' : 'type at least 4 digits'}
              onKeyUp={async (evt) => {
                evt.persist();
                const val = evt.currentTarget?.value;
                if (!val || val.length === 0) {
                  if (!!filters.search) {
                    this.props.searchByText('');
                  }
                  return;
                }
                if (val.length > 0 && val.length < 4 && !isField) {
                  return;
                }
                if (val === filters.search) {
                  return;
                }
                this.debouncedSearch(val);
              }}
              onIonClear={async (evt) => {
                this.props.searchByText('');
              }}
            />
          </IonItem>
        )}

        <div
          className={
            'gets-blurry ' + (!!filters.search ? 'search-by-text-enabled' : '')
          }
        >
          {!!dateFilter && (
            <div className={'datePicker'}>
              <div className="label">
                <div>
                  {isTodo ? <div>Sort by&nbsp;</div> : <div>Dates</div>}

                  {!isTodo && (
                    <div className="sorting-text">
                      {dateFilter.sorting === 'desc' ? 'new first' : 'old first'}
                    </div>
                  )}
                </div>
                <IonButtons style={{ marginLeft: '-4px' }}>
                  {this.state.processing ? (
                    <IonButton>
                      <IonIcon
                        slot="icon-only"
                        icon={syncOutline}
                        className="sorting-icon rotate"
                      />
                    </IonButton>
                  ) : dateFilter.sorting === 'desc' ? (
                    <IonButton onClick={() => this.props.handleSortingOnClick()}>
                      <IonIcon
                        slot="icon-only"
                        icon={arrowDownOutline}
                        className="sorting-icon"
                      />
                    </IonButton>
                  ) : (
                    <IonButton onClick={() => this.props.handleSortingOnClick()}>
                      <IonIcon
                        slot="icon-only"
                        icon={arrowUpOutline}
                        className="sorting-icon"
                      />
                    </IonButton>
                  )}
                </IonButtons>
              </div>
              {isTodo && (
                <div className="label-dates sort-control">
                  <Select
                    classNamePrefix={'react-select'}
                    blurInputOnSelect={true}
                    isSearchable={false}
                    menuPortalTarget={document.getElementById('root')}
                    menuShouldScrollIntoView={false}
                    options={TodoReorderOptions}
                    onChange={async (evt) => {
                      if (!evt || evt.value === 'CUSTOM') return;
                      console.log(evt.value);
                      await this.setState({ processing: true });
                      await reorderTodos(firestore, evt.value, profile);
                      await this.setState({ processing: false });
                      // this.onFilter({})
                    }}
                  />
                </div>
              )}
              {!isTodo && (
                <div className="label-dates">
                  <div>
                    {!searchFields.includes('sort_by') && (
                      <div className={'datePicker-label'}>
                        BY{' '}
                        {dateFilter.dateLabel
                          .replace('ARRIVED', 'ARRIVAL')
                          .replace('INSPECTED', 'INSPECTION')
                          .replace('CREATED', 'CREATION')}{' '}
                        DATE
                      </div>
                    )}
                    <div
                      className={'datePicker-selects'}
                      onClick={(e) => {
                        if (this.props.onDateClick) {
                          this.props.onDateClick();
                        }
                      }}
                    >
                      <div className="datePicker-start">
                        <IonDatetime
                          readonly={!!this.props.onDateClick}
                          className="datePicker-start"
                          value={dateFilter.start.value}
                          max={dateFilter.start.max}
                          onIonChange={(o) => dateFilter.start.onChange(o)}
                        />
                      </div>
                      <div>-</div>
                      <div className="datePicker-end">
                        <IonDatetime
                          readonly={!!this.props.onDateClick}
                          className="datePicker-end"
                          value={dateFilter.end.value}
                          min={dateFilter.end.min}
                          onIonChange={(o) => dateFilter.end.onChange(o)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {searchFields.includes('sort_by') && (
            <div
              className={'datePicker'}
              style={{
                borderBottom: '1px solid var(--ion-color-light-medium)',
                paddingBottom: '5px',
                marginBottom: '5px',
              }}
            >
              <div className="label">Sort by</div>
              <div className="label-dates sort-control">
                <Select
                  classNamePrefix={'react-select'}
                  blurInputOnSelect={true}
                  isSearchable={false}
                  menuPortalTarget={document.getElementById('root')}
                  menuShouldScrollIntoView={false}
                  options={SortByOptions}
                  value={SortByOptions.find((o) => o.value === filters.dateProperty)}
                  onChange={async (evt) => {
                    this.props.changeSortingBy(evt.value);
                    this.onFilter({ dateProperty: evt.value });
                  }}
                />
              </div>
            </div>
          )}

          {searchFields.includes('product') && (
            <IonItem>
              <IonLabel>Product</IonLabel>
              <ctxProducts.Consumer>
                {(productList) => {
                  if (this.props.profile.products) {
                    // TODO: options should be either agProductId or productId depending on Organisation Settings. For now only displaying agProductId
                    productList = (productList ?? []).filter(
                      (p) => this.props.profile.products.indexOf(p.productId) >= 0
                    );
                  }
                  let options = (productList ?? [])
                    .sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
                    .map((b) => {
                      return {
                        value: b.productId,
                        label: b.agProductId ?? b.productId,
                      };
                    });

                  return (
                    <Select
                      classNamePrefix={'react-select'}
                      blurInputOnSelect={true}
                      menuPortalTarget={document.getElementById('root')}
                      menuShouldScrollIntoView={false}
                      isClearable={true}
                      isMulti={!isTodo}
                      value={filters?.article?.productId ?? null}
                      options={options}
                      isLoading={!productList}
                      onChange={async (val) => {
                        if (!!val && isTodo) {
                          //@ts-ignore
                          val = [val];
                        }
                        this.onFilter({
                          article: { ...filters.article, productId: val as any },
                        });
                      }}
                    />
                  );
                }}
              </ctxProducts.Consumer>
            </IonItem>
          )}

          {searchFields.includes('packagingType') && (
            <IonItem>
              <IonLabel>Packaging type</IonLabel>
              {(() => {
                const options = (
                  Object.entries(PackagingNames) as [PackagingType, string][]
                ).map(([value, label]) => ({ value, label }));
                const value =
                  options.find((o) => o.value === filters?.article?.packagingType) ??
                  null;

                return (
                  <Select
                    classNamePrefix={'react-select'}
                    blurInputOnSelect={true}
                    menuPortalTarget={document.getElementById('root')}
                    menuShouldScrollIntoView={false}
                    isClearable={true}
                    value={value}
                    options={options}
                    onChange={async (val) => {
                      this.onFilter({
                        article: {
                          ...filters.article,
                          packagingType: val?.value ?? undefined,
                        },
                      });
                    }}
                  />
                );
              })()}
            </IonItem>
          )}

          {searchFields.includes('orderType') && (
            <IonItem data-tip={'page-filters-order-type'}>
              <IonLabel>Order type</IonLabel>
              <Select
                classNamePrefix={'react-select'}
                menuPortalTarget={document.getElementById('root')}
                isClearable={true}
                isSearchable={false}
                value={filters.orderType ?? null}
                options={
                  Object.entries(qcRelevantOrderTypeMap).map(([value, label]) => ({
                    value: value as OrderType,
                    label,
                  })) ?? null
                }
                onChange={async (evt) => {
                  this.onFilter({ orderType: evt });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('reportType') && (
            <IonItem>
              <IonLabel>Report type</IonLabel>
              <Select
                classNamePrefix={'react-select'}
                menuPortalTarget={document.getElementById('root')}
                isClearable={true}
                isSearchable={false}
                value={filters.reportType ?? null}
                options={
                  Object.entries(reportTypeToStringMap).map(([value, label]) => ({
                    value: value as ReportType,
                    label,
                  })) ?? null
                }
                onChange={async (evt) => {
                  this.onFilter({ reportType: evt });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('inspectionType') && (
            <IonItem>
              <IonLabel style={{ lineHeight: '18px', margin: 0 }}>
                Latest
                <br />
                Inspection
              </IonLabel>
              <Select
                classNamePrefix={'react-select'}
                menuPortalTarget={document.getElementById('root')}
                isClearable={true}
                isSearchable={false}
                value={filters.inspectionType ?? null}
                options={
                  Object.entries(InspectionType).map(([label, value]) => ({
                    value: value as InspectionType,
                    label,
                  })) ?? null
                }
                onChange={async (evt) => {
                  this.onFilter({ inspectionType: evt });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('location') && (
            <IonItem>
              <IonLabel>Location</IonLabel>
              <ctxLocations.Consumer>
                {(locations) => {
                  let options = (locations ?? [])
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .map((b) => {
                      return {
                        value: b.locationId,
                        label: b.name ?? b.locationId,
                      };
                    });
                  const isLocationRestricted = !!hasLocationRestriction(
                    this.props.profile
                  );
                  return (
                    <Select
                      classNamePrefix={'react-select'}
                      menuPortalTarget={document.getElementById('root')}
                      isClearable={true}
                      value={
                        options?.find((l) => l.value === filters.locationId) ?? null
                      }
                      isLoading={!locations}
                      options={options}
                      onChange={async (evt) => {
                        this.onFilter({ locationId: evt?.value });
                      }}
                      isDisabled={isLocationRestricted}
                    />
                  );
                }}
              </ctxLocations.Consumer>
            </IonItem>
          )}

          {searchFields.includes('locationReference1') && (
            <IonItem>
              <IonLabel>Sub Location</IonLabel>
              <IonSearchbar
                // debounce={500}
                mode="ios"
                inputmode="decimal"
                value={this.props.filters.locationReference1}
                placeholder="type at least 2 digits..."
                onKeyUp={async (evt) => {
                  evt.persist();
                  const val = evt.currentTarget.value;
                  console.log(val?.toUpperCase());
                  if (val.length > 0 && val.length < 2) {
                    return;
                  }
                  this.onFilter({ locationReference1: val });
                }}
                onIonClear={async (evt) => {
                  this.onFilter({ locationReference1: '' });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('supplier') && (
            <IonItem>
              <IonLabel>{contactTitle}</IonLabel>
              <ctxContacts.Consumer>
                {(contacts) => {
                  let optionsSupplier = (contacts ?? [])
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .filter(
                      (c) => c.type?.includes('SELLER') || c.type?.includes('BUYER')
                    )
                    .map((b) => {
                      let postfix =
                        b.id.substring(0, 1) === 'K' || b.id.substring(0, 1) === 'L'
                          ? ` (${b.id.substring(0, 1)})`
                          : '';
                      return {
                        value: b.id,
                        label: b.name + postfix,
                      };
                    });

                  let defaultValue = filters.contactId
                    ? optionsSupplier.find((o) => o.value === filters.contactId)
                    : null;

                  return (
                    <Select
                      isClearable={true}
                      isLoading={!contacts}
                      value={defaultValue ?? null}
                      options={optionsSupplier}
                      classNamePrefix={'react-select'}
                      menuPortalTarget={document.getElementById('root')}
                      onChange={async (evt) => {
                        this.onFilter({ contactId: (evt as any)?.value });
                      }}
                    />
                  );
                }}
              </ctxContacts.Consumer>
            </IonItem>
          )}

          {searchFields.includes('grower') && (
            <IonItem>
              <IonLabel>Grower</IonLabel>
              <ctxContacts.Consumer>
                {(contacts) => {
                  let optionsSupplier = (contacts ?? [])
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .filter((c) => c.type?.includes('GROWER'))
                    .map((b) => {
                      return {
                        value: b.id,
                        label: b.name,
                      };
                    });

                  let defaultValue = filters.growerId
                    ? optionsSupplier.find((o) => o.value === filters.growerId)
                    : null;

                  return (
                    <Select
                      isClearable={true}
                      isLoading={!contacts}
                      value={defaultValue ?? null}
                      options={optionsSupplier}
                      classNamePrefix={'react-select'}
                      menuPortalTarget={document.getElementById('root')}
                      onChange={async (evt) => {
                        this.onFilter({ growerId: (evt as any)?.value });
                      }}
                    />
                  );
                }}
              </ctxContacts.Consumer>
            </IonItem>
          )}

          {searchFields.includes('user') && (
            <IonItem>
              <IonLabel>User</IonLabel>
              <ctxUsers.Consumer>
                {(users) => {
                  let options: any[] = (users ?? [])
                    .filter(
                      (c) =>
                        c.organisationId === this.props.profile.organisationId &&
                        c.id !== this.props.profile.id
                    )
                    .sort((a, b) => a?.name?.localeCompare(b.name))
                    .map((b) => {
                      return {
                        value: b.id,
                        label: (
                          <div className="option">
                            <span>{b.name}</span>
                            <span>{b.email}</span>
                          </div>
                        ),
                        name: b.name,
                        email: b.email,
                      };
                    });
                  options.unshift({
                    value: this.props.profile.id,
                    label: 'Me',
                    name: 'Me',
                  });

                  const selectedOption = options.find(
                    (o) => o.value === filters?.userId?.value
                  );

                  const selectedValue = !!selectedOption
                    ? {
                        value: selectedOption.value,
                        label: `${selectedOption.name} ${
                          !!selectedOption.email ? `(${selectedOption.email})` : ''
                        }`,
                      }
                    : null;

                  return (
                    <Select
                      isClearable={true}
                      filterOption={(
                        candidate: { label: string; value: string; data: any },
                        input: string
                      ) => {
                        if (input) {
                          return (
                            candidate.data.name
                              ?.toLowerCase()
                              .includes(input?.toLowerCase()) ||
                            candidate.data.email
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          );
                        }
                        return true;
                      }}
                      isLoading={!users}
                      value={selectedValue}
                      options={options}
                      classNamePrefix={'react-select'}
                      menuPortalTarget={document.getElementById('root')}
                      onChange={async (evt) => {
                        this.onFilter({ userId: { ...evt, label: undefined } });
                      }}
                    />
                  );
                }}
              </ctxUsers.Consumer>
            </IonItem>
          )}

          {searchFields.includes('qcStatus') && (
            <IonItem>
              <IonLabel>QC Status</IonLabel>
              <Select
                isClearable={true}
                isSearchable={false}
                // placeholder="Quality Control Status"
                value={filters.qcStatus ?? null}
                classNamePrefix={'react-select'}
                menuPortalTarget={document.getElementById('root')}
                options={
                  [
                    { value: '', label: 'Any' },
                    ...Object.entries(OrderAGStatuses).map(([value, label]) => {
                      return { value, label };
                    }),
                  ] as { value: OrderAGStatus; label: string }[]
                }
                onChange={async (evt) => {
                  this.onFilter({ qcStatus: evt });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('reportStatus') && (
            <IonItem>
              <IonLabel>Status</IonLabel>
              <Select
                isClearable={true}
                // placeholder="Quality Control Status"
                value={filters.reportStatus ?? null}
                isSearchable={false}
                classNamePrefix={'react-select'}
                menuPortalTarget={document.getElementById('root')}
                options={
                  [
                    { value: '', label: 'Any' },
                    { value: 'CREATED', label: 'Created' },
                    { value: 'SHARED', label: 'Shared' },
                  ] as { value: ReportStatus; label: string }[]
                }
                onChange={async (evt) => {
                  this.onFilter({ reportStatus: evt });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('score') && (
            <IonItem>
              <IonLabel>QC Score</IonLabel>
              <Select
                isClearable={true}
                // placeholder="Quality Control Status"
                value={filters.qcScore ?? null}
                isSearchable={false}
                classNamePrefix={'react-select'}
                menuPortalTarget={document.getElementById('root')}
                options={[
                  { value: '', label: 'Any' },
                  { value: 'has3orLess', label: 'Average and below' },
                  { value: 'has2orLess', label: 'Bad and below' },
                  { value: 'has1orLess', label: 'Very bad' },
                ]}
                onChange={async (evt) => {
                  this.onFilter({ qcScore: evt });
                }}
              />
            </IonItem>
          )}

          {searchFields.includes('hasAssessment') && (
            <IonItem>
              <IonLabel>Inspections?</IonLabel>
              {(() => {
                const options: { value: boolean | null; label: string }[] = [
                  { value: null, label: 'All batches' },
                  { value: true, label: 'Only inspected' },
                  { value: false, label: 'Only not inspected' },
                ];

                const value = options.find((v) => {
                  const compareValue =
                    this.props.filters.hasAssessment === undefined
                      ? null
                      : this.props.filters.hasAssessment;
                  return v.value === compareValue;
                });

                return (
                  <Select
                    value={value}
                    // defaultValue={value}
                    classNamePrefix={'react-select'}
                    menuPortalTarget={document.getElementById('root')}
                    options={options}
                    onChange={async (evt) => {
                      const newValue = evt?.value !== null ? evt.value : undefined;
                      this.onFilter({
                        hasAssessment: newValue,
                      });
                    }}
                  />
                );
              })()}
            </IonItem>
          )}

          {searchFields.includes('skipZeroVolume') && (
            <>
              <IonItem>
                <IonLabel>Omit processed batches?</IonLabel>
                <IonCheckbox
                  color="tertiary"
                  checked={!!filters.skipZeroVolume}
                  onClick={async (evt) => {
                    this.onFilter({ skipZeroVolume: !filters.skipZeroVolume });
                  }}
                />
                <IonIcon
                  slot="start"
                  icon={helpCircleOutline}
                  onClick={() => this.setHint('skipZeroVolume')}
                />
              </IonItem>
              {!!this.state.hintField && this.renderHint()}
            </>
          )}

          {searchFields.includes('isInStock') &&
            !!this.props.organisation?.settings?.allowFilterByStock && (
              <IonItem>
                <IonLabel>Is in stock?</IonLabel>
                <div>
                  <IonCheckbox
                    color="tertiary"
                    checked={filters.isInStock}
                    onClick={async (evt) => {
                      this.onFilter({ isInStock: evt.currentTarget.checked });
                    }}
                  />
                </div>
              </IonItem>
            )}

          {searchFields.includes('showCompleted') && (
            <IonItem>
              <IonLabel>Show completed tasks?</IonLabel>
              <IonCheckbox
                color="tertiary"
                checked={filters.showCompleted}
                onClick={async (evt) => {
                  this.onFilter({ showCompleted: evt.currentTarget.checked });
                }}
              />
            </IonItem>
          )}
        </div>

        {/* Field specific */}
        <FieldSpecificFields
          searchFields={searchFields}
          onFilter={this.onFilter.bind(this)}
          filters={filters}
        />

        <div className="bottom-buttons">
          {!this.props.searchOnChange && (
            <IonButton
              data-tip={'page-filters-search-button'}
              color="primary"
              className={
                'filter-button ' + (!!filters.search ? 'search-by-text-enabled' : '')
              }
              onClick={(_) => this.props.onCancel()}
            >
              Search
            </IonButton>
          )}
          <IonButton
            color={this.props.searchOnChange ? 'success' : 'medium'}
            className={
              'clear-button gets-blurry ' +
              (!!filters.search ? 'search-by-text-enabled' : '')
            }
            onClick={(_) => this.saveFilter()}
            fill={this.props.searchOnChange ? 'solid' : 'clear'}
          >
            Save
          </IonButton>
          <IonButton
            color={this.props.searchOnChange ? 'white' : 'medium'}
            className={
              'clear-button gets-blurry ' +
              (!!filters.search ? 'search-by-text-enabled' : '')
            }
            onClick={(_) => this.setState({ showSavedFilters: true })}
            fill={this.props.searchOnChange ? 'solid' : 'clear'}
          >
            See saved
          </IonButton>
          {this.props.searchOnChange && (
            <IonButton
              fill={'clear'}
              color="medium"
              className="clear-button"
              onClick={(_) => this.onClear()}
            >
              Clear
            </IonButton>
          )}
        </div>
      </IonList>
    );

    return this.wrapper(list);
  }

  savedFilters = () => {
    const savedFilters = this.props.profile?.filters ?? {};
    return (
      <div className="saved-filters ion-item">
        <div className="ion-label">
          Saved Filters
          <IonButton
            onClick={() => this.setState({ showSavedFilters: false })}
            className="saved-filters-back-button"
            size="small"
          >
            Back
          </IonButton>
        </div>
        <div ref={this.myRef} className="saved-filter-list">
          {!Object.entries(savedFilters)?.length && (
            <div className="no-results saved-filter-item">
              no filters have been saved yet
            </div>
          )}
          {Object.entries(savedFilters).map(([name, filter]) => {
            const isSelected = areFiltersEqual(filter, this.props.filters);

            return (
              <div
                className={'saved-filter-item ' + (isSelected ? 'selected' : '')}
                key={name}
                onClick={async (evt) => this.selectSavedFilter(filter)}
              >
                <div>&#x2022; {name}</div>
                <div
                  className="delete-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.deleteFilter(name);
                  }}
                >
                  remove
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

export default PageFilters;

interface FSFProps {
  searchFields: SearchField[];
  onFilter: (filter: AGFilters) => void;
  filters: Partial<AGFilters>;
}
const FieldSpecificFields = ({ searchFields, onFilter, filters }: FSFProps) => {
  const products = useContext(ctxProducts);
  const contacts = useContext(ctxContacts);
  const org = useContext(ctxOrg);

  const {
    selectedProductionType,
    productionTypeOptions,
    selectedVarieties,
    varietyOptions,
    associatedPartnerOptions,
    selectedAssociatedPartner,
  } = useLocationAdminOptions(products, contacts, org);

  const productOptions = Array.from(
    new Set(varietyOptions.map((v) => v.value.agProductId))
  ).map((value) => ({ value, label: value }));
  const selectedProducts = productOptions.filter((v) =>
    (filters.producedProducts ?? []).includes(v.value)
  );

  const filteredVarietyOptions = varietyOptions.filter((v) =>
    selectedProducts.map((p) => p.value).includes(v.value.agProductId)
  );

  return (
    <>
      {searchFields.includes('partner') && (
        <IonItem>
          <IonLabel>Partner</IonLabel>
          <Select
            isClearable={true}
            value={selectedAssociatedPartner(filters.partnerId) ?? null}
            classNamePrefix={'react-select'}
            menuPortalTarget={document.getElementById('root')}
            options={associatedPartnerOptions.filter((v) => !!v.value)}
            onChange={async (evt) => {
              onFilter({ partnerId: evt?.value });
            }}
          />
        </IonItem>
      )}
      {searchFields.includes('productionType') && (
        <IonItem>
          <IonLabel>Production type</IonLabel>
          <Select
            isClearable={true}
            value={selectedProductionType(filters.productionType)}
            classNamePrefix={'react-select'}
            menuPortalTarget={document.getElementById('root')}
            options={productionTypeOptions}
            onChange={async (evt) => {
              onFilter({ productionType: evt?.value });
            }}
          />
        </IonItem>
      )}

      {searchFields.includes('producedProducts') && (
        <>
          <IonItem>
            <IonLabel>Products</IonLabel>
            <Select
              isClearable={true}
              value={selectedProducts}
              classNamePrefix={'react-select'}
              isMulti
              menuPortalTarget={document.getElementById('root')}
              options={productOptions}
              onChange={async (evt) => {
                const producedProducts = (evt ?? []).map((o) => o.value);
                const producedVarieties = (filters.producedVarieties ?? []).filter(
                  (pv) => producedProducts.includes(pv.agProductId)
                );
                onFilter({ producedProducts, producedVarieties });
              }}
            />
          </IonItem>
          {filteredVarietyOptions.length > 0 && (
            <IonItem>
              <IonLabel>Prod. varieties</IonLabel>
              <Select
                isClearable={true}
                value={selectedVarieties(filters.producedVarieties)}
                classNamePrefix={'react-select'}
                isMulti
                menuPortalTarget={document.getElementById('root')}
                options={filteredVarietyOptions}
                onChange={async (evt) => {
                  onFilter({ producedVarieties: (evt ?? []).map((o) => o.value) });
                }}
              />
            </IonItem>
          )}
        </>
      )}
    </>
  );
};
