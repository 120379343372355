/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const QuestionTemplateType = {
  SampleQuantification: 'sample_quantification',
  Number: 'number',
  ListStats: 'list_stats',
  OptionsList: 'options_list',
  YesNo: 'yes_no',
  Text: 'text',
  ComputedOnly: 'computed_only',
  PopulateWithExtProperty: 'populate_with_ext_property',
  NumericValuesDistribution: 'numeric_values_distribution',
  AverageViaSumOfN: 'average_via_sum_of_N',
} as const;

export type QuestionTemplateType =
  (typeof QuestionTemplateType)[keyof typeof QuestionTemplateType];
