import React, { useEffect, useState } from 'react';
import { IonButton, IonSpinner } from '@ionic/react';
import { profileDocRef } from '../../../DataStorage';
import { auth, firestore } from '../../../ConfigFirebase';

import './WelcomePanel.scss';

interface Props {
  action: () => void;
}

export function WelcomePanel({ action }: Props) {
  useEffect(() => {
    let profileSubscription: () => void;

    const authSubscription = auth.onAuthStateChanged((fbUser) => {
      if (fbUser?.uid) {
        profileSubscription = profileDocRef(firestore, fbUser.uid).onSnapshot(
          (snapshot) => {
            if (snapshot.exists) {
              setProfileCreated(true);
            }
          }
        );
      }
    });

    return () => {
      authSubscription();
      profileSubscription?.();
    };
  }, []);

  const [profileCreated, setProfileCreated] = useState<boolean>(false);

  return (
    <div className="welcome-panel--wrapper">
      <div className="welcome-panel--header">
        <img src="https://www.agrinorm.com/dist/img/logo.svg" alt="logo" />
        <h1>Welcome</h1>
      </div>
      <div className="welcome-panel--content">
        {profileCreated ? (
          <>
            <p>
              To get started, please set up your password by following the instructions
              on the next slide. Once you have set up your password, you will be ready
              to explore all the amazing features of our application.
            </p>
            <p>
              We're here to help, so if you have any questions or need assistance, feel
              free to contact us. Thank you for joining us!
            </p>
          </>
        ) : (
          <>
            <p>
              Your account is being set up. This might take a couple of minutes, please
              do not close this page...
            </p>
            <IonSpinner />
          </>
        )}
      </div>
      {profileCreated && (
        <div className="welcome-panel--footer">
          <IonButton
            color={'primary'}
            className="welcome-panel--button"
            onClick={action}
          >
            Continue
          </IonButton>
        </div>
      )}
    </div>
  );
}
