import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import { barChartOutline, chevronBackOutline, closeOutline } from 'ionicons/icons';
import React from 'react';
import { firestore } from './ConfigFirebase';
import { getOrdersForContactId } from './DataStorage';
import { Contact, Order, Report } from './Model';
import './PageContact.scss';
import i18n from './ServiceI18n';

import CardOrder from './CardOrder';
import ViewUser from './ViewUser';

import { getOrders } from './DataApp';
import { capitalizeFirstLetter, equalsIgnoreOrder } from './HelperUtils';
import {
  AppProduct,
  InsightsTierAction,
  hasLocationRestriction,
  orgHasPermission,
  userHasPermission,
} from './PermissionsService';

import { isEqual } from 'lodash';
import { getContactIdsWithInsights } from './DataInsight';
import withContext, { ContextProps } from './HOC/withContext';

interface Props {
  contactId: string;
  modal?: boolean;
  onClick?: Function;
  onCancel?: any;
  order?: Order | Report;
}

interface State {
  recentOrders?: Order[];
  recentOrdersShared?: Order[];
  supplier: string;
  search: string;
  hasInsights: boolean;
  contactTypes: ('customer' | 'supplier')[];
}

class PageContact extends React.Component<Props & ContextProps, State> {
  private mounted;
  private recentOrdersSharedSub = [];

  constructor(props) {
    super(props);

    console.log('PageContact order', this.props.order);

    this.state = {
      recentOrders: undefined,
      recentOrdersShared: undefined,
      supplier: this.props.contactId ?? this.props.order?.supplierId,
      search: '',
      hasInsights: false,
      contactTypes: [],
    };
  }

  componentWillUnmount() {
    this.mounted = false;
    this.recentOrdersSharedSub.forEach((unsubscribe) => unsubscribe());
  }

  ionViewWillEnter() {
    this.start();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props & ContextProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    // check for changes in the location restriction
    if (
      hasLocationRestriction(this.props.profile) !==
      hasLocationRestriction(prevProps.profile)
    ) {
      await this.getContactOrdersWithLocationRestriction();
    }

    if (
      this.state.search !== prevState.search ||
      this.props.contactId !== prevProps.contactId ||
      !isEqual(this.props.contacts, prevProps.contacts)
    ) {
      await this.start();
    }
  }

  async start() {
    this.mounted = true;
    const { contactId, profile } = this.props;
    const contact: Contact = this.props.contacts?.find((c) => c.id === contactId);

    if (contact?.organisation?.id) {
      this.recentOrdersSharedSub.push(
        getOrders(
          firestore,
          profile.organisationId,
          '',
          undefined,
          undefined,
          50,
          {
            contactId: this.state.supplier,
          },
          undefined,
          (result) => {
            if (this.mounted)
              this.setState({
                recentOrdersShared: result?.data || [],
              });
          }
        )
      );
    }

    // check if it has insights
    try {
      const ids = await getContactIdsWithInsights(profile.organisationId);
      const hasInsights: boolean = ids.contactIds.includes(contactId);
      const contactTypes: ('customer' | 'supplier')[] = (
        ['customer', 'supplier'] as const
      ).filter((v) => ids[`${v === 'customer' ? 'buyer' : v}Ids`].includes(contactId));

      this.setState({ hasInsights, contactTypes });
    } catch (error) {
      console.log('Could not fetch entities', error);
    }

    await this.getContactOrdersWithLocationRestriction();
  }

  async getContactOrdersWithLocationRestriction() {
    const locationRestricted = hasLocationRestriction(this.props.profile);
    const recentOrders: Order[] = await getOrdersForContactId(
      firestore,
      this.props.profile.organisationId,
      this.props.contactId,
      undefined,
      locationRestricted
    );
    if (this.mounted)
      this.setState({
        recentOrders: recentOrders,
      });
  }

  compareOrdersForLinking(selectedOrder, order) {
    if (selectedOrder.contactOrder?.validLink === true) {
      alert('Error: Order already has link');
      return false;
    }
    if (order.positions.length !== selectedOrder.positions.length) {
      return window.confirm('Orders have different amount of lots.\nAre you sure?');
    }
    let a = order.positions.map((p) => p.article.productId);
    let b = selectedOrder.positions.map((p) => p.article.productId);
    if (!equalsIgnoreOrder(a, b)) {
      return window.confirm('Orders have different Products.\nAre you sure?');
    }
    return window.confirm('Are you sure? ' + selectedOrder.id);
  }

  onOrderClicked(selectedOrder) {
    if (!this.props.modal) {
      return;
    }
    let { order } = this.props;
    console.log({ selectedOrder, order });

    if (this.compareOrdersForLinking(selectedOrder, order)) {
      this.props.onClick(selectedOrder);
    }
  }

  renderOrders() {
    const { recentOrders, recentOrdersShared, search, supplier } = this.state;
    const contact: Contact = this.props.contacts?.find(
      (c) => c.id === this.props.contactId
    );

    if (!contact) {
      return [...Array(3).keys()].map((item) => (
        <CardOrder key={item} loading={true} />
      ));
    }

    const filterByContactId = (o: Order) => {
      // console.log("filterById", search, supplier)
      return search.length > 0 && supplier != null
        ? o.contactId.includes(supplier)
        : true;
    };

    if (!contact.users || contact?.users?.length === 0) {
      if (recentOrders === undefined) {
        return [...Array(3).keys()].map((item) => (
          <CardOrder key={item} loading={true} />
        ));
      } else {
        return recentOrders?.filter(filterByContactId).map((order) => (
          <CardOrder
            key={order.id}
            {...this.props}
            showLink={!this.props.modal}
            order={order}
            loading={false}
            routerLink={'/secure/' + order.orgId + '/order/' + order.id}
            // onClick={this.props.onClick}
          />
        ));
      }
    } else {
      if (recentOrdersShared === undefined) {
        return [...Array(3).keys()].map((item) => (
          <CardOrder key={item} loading={true} />
        ));
      } else {
        console.log('recentOrdersShared', recentOrdersShared);
        return recentOrdersShared
          ?.filter(filterByContactId)
          .map((order) => (
            <CardOrder
              key={order.id}
              {...this.props}
              showLink={!this.props.modal}
              order={order}
              loading={false}
              routerLink={'/secure/' + order.orgId + '/order/' + order.id}
              onClick={(_) => this.onOrderClicked(order)}
            />
          ));
      }
    }
  }

  render() {
    if (this.props.modal) {
      return <div className="page-contact ion-page modal-view">{this.renderC()}</div>;
    } else {
      return <IonPage className={'page-contact ion-page'}>{this.renderC()}</IonPage>;
    }
  }

  renderC() {
    const contact: Contact = this.props.contacts?.find(
      (c) => c.id === this.props.contactId
    );
    // const contactType = this.props.contactId.startsWith('K') ? 'customer' : 'supplier'

    // console.log('contact', contact);

    let insights = contact?.insights;
    // const insights = [
    //   {
    //     title: 'title',
    //     description: 'description',
    //   },
    //   {
    //     title: 'title2',
    //     description: 'description2',
    //   },
    // ]

    let optionsSupplier =
      contact?.contacts
        ?.sort((a, b) => (a.name >= b.name ? 1 : -1))
        .map((c) => {
          return {
            value: c.id,
            label: c.name + ` (${c.id.substr(0, 1)})`,
          };
        }) ?? [];

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" color="light">
              <IonBackButton
                text={i18n.t('General.back')}
                defaultHref="/tabs/connect"
                color="dark"
              >
                <IonIcon icon={chevronBackOutline} slot="icon-only" />
              </IonBackButton>
            </IonButtons>
            {this.props.modal && (
              <IonButtons slot="start">
                <IonButton color="dark" onClick={this.props.onCancel}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            )}
            {!this.props.modal &&
              userHasPermission(this.props.profile, 'VIEW', 'INSIGHT_PROFILES') &&
              orgHasPermission(
                this.props.organisation?.settings,
                AppProduct.Insights,
                InsightsTierAction.Partners
              ) &&
              this.state.hasInsights &&
              this.state.contactTypes.length > 0 && (
                <IonButtons slot="end">
                  {this.state.contactTypes.map((contactType) => (
                    <IonButton
                      key={contactType}
                      color="dark"
                      routerLink={`/secure/${contactType}/${this.props.contactId}/insight`}
                    >
                      <IonIcon icon={barChartOutline} />
                      <IonLabel>{`${capitalizeFirstLetter(
                        contactType
                      )} Stats`}</IonLabel>
                    </IonButton>
                  ))}
                </IonButtons>
              )}
            <IonTitle>
              <div>{contact?.name || contact?.id}</div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className={contact?.users?.length === 0 ? 'no-contacts' : ''}>
          {!!contact && (
            <IonList>
              {insights && (
                <>
                  <IonListHeader>
                    <IonLabel>{i18n.t('PageContact.insights')}</IonLabel>
                  </IonListHeader>
                  <IonSlides options={{ slidesPerView: 1.5 }}>
                    {[0, 1, 2].map((i) => (
                      <IonSlide key={i}>
                        <IonCard className="card-insight insight">
                          <IonCardSubtitle>insight.title {i}</IonCardSubtitle>
                          <p>insight.description</p>
                        </IonCard>
                      </IonSlide>
                    ))}
                  </IonSlides>
                </>
              )}

              <IonItemDivider className="users-divider">
                {i18n.t('PageContact.users')}
              </IonItemDivider>
              {contact.users?.length > 0 ? (
                contact.users.map((user) => (
                  <IonItem key={user.id} className="users-item">
                    <IonAvatar slot="start" className="avatar">
                      <ViewUser userId={user.id} />
                    </IonAvatar>
                    <IonLabel>
                      <b>
                        {user.name} from {user.organisationId}
                      </b>
                      <br />
                      {user.email}
                    </IonLabel>
                  </IonItem>
                ))
              ) : (
                <IonItem>
                  <IonLabel className="ion-text-center">
                    {i18n.t('PageContact.noUsers')}
                  </IonLabel>
                </IonItem>
              )}

              {userHasPermission(this.props.profile, 'VIEW', 'ORDER') && (
                <>
                  <IonItemDivider>
                    {i18n.t('PageContact.recentOrders')}
                    {contact?.users?.length === 0
                      ? ' (my orders)'
                      : ' (shared with me)'}
                    {!!hasLocationRestriction(this.props.profile) ? (
                      <>{` - (only seeing orders from warehouse ${hasLocationRestriction(
                        this.props.profile
                      )})`}</>
                    ) : (
                      ''
                    )}
                  </IonItemDivider>
                  <div className="container-orders">{this.renderOrders()}</div>
                </>
              )}
            </IonList>
          )}
        </IonContent>
      </>
    );
  }
}

export default React.memo(
  withContext(withIonLifeCycle(PageContact), ['contacts', 'profile', 'organisation'])
);
