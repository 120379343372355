import React, { useState } from 'react';
import { IonPage, IonContent } from '@ionic/react';

// Onboarding Styling
import './OnboardingContainer.scss';

// Components
import { SwipeablePanels } from '../../shared/components/SwipeablePanels/SwipeablePanels';
import { WelcomePanel } from './WelcomePanel/WelcomePanel';
import { PasswordPanel } from './PasswordPanel/PasswordPanel';
import { CongratulationsPanel } from './CongratulationsPanel/CongratulationsPanel';

export function OnboardingContainer() {
  const [panelIndex, setPanelIndex] = useState<number>(0);

  return (
    <IonPage>
      <IonContent className="onboarding--wrapper">
        <div className="onboarding--content">
          <SwipeablePanels
            index={panelIndex}
            setPanelIndex={setPanelIndex}
            disabled={panelIndex === 1}
          >
            <WelcomePanel action={() => setPanelIndex(1)} />
            <PasswordPanel action={() => setPanelIndex(2)} />
            <CongratulationsPanel />
          </SwipeablePanels>
        </div>
      </IonContent>
    </IonPage>
  );
}
