/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PowerSyncTransaction } from '../../../platform/powersync'
import type { CancelablePromise } from '../../@api_core/CancelablePromise'
import type { BaseHttpRequest } from '../../@api_core/BaseHttpRequest'
export class PowerSyncService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Process Transaction
     * @returns any Successful Response
     * @throws ApiError
     */
    public processTransaction({ requestBody }: { requestBody: PowerSyncTransaction }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/powersync/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not authenticated`,
                422: `Validation Error`,
            },
        })
    }
}
