import { Capacitor } from '@capacitor/core';
import { toastController } from '@ionic/core';
import { IonFab, IonFabButton, IonIcon, IonToggle, isPlatform } from '@ionic/react';
import { lockClosed, lockOpen } from 'ionicons/icons';
import { isEqual } from 'lodash';
import React, { useContext, useState } from 'react';
import { ctxProfile } from '../App';
import {
  getErrorsOnInspectionClose,
  getInspectionLotProperties,
} from '../InspectionLogic';
import { Inspection, LotInspection, LotProperties } from '../InspectionModel';
import { Lot, Picture, IBarcode } from '../Model';
import { InspectionClass, getQuestionDisplayedName } from '../ServiceInspection';
import eventLogger from '../events/common';
import {
  createCloseInspectionEvent,
  createOpenInspectionEvent,
  logLocalInspectionModificationEvent,
} from '../events/inspection';
import { InspectionSpecSection, LotSchemaObjectTypeEnum } from '../generated/openapi/core';
import { CInspectionCameraButtons } from './CInspectionCameraButtons';
import { CInspectionGallery } from './CInspectionGallery';

// Styling
import '../PageInspection.scss';
import { useInspectionContext } from '../context/InspectionContextProvider';

interface BottomActionsProps {
  cameraRef: any;
  updateOCRData?: (
    lotProperties: LotProperties,
    ggn_missing?: boolean,
    ggn_invalid?: boolean,
    gln_missing?: boolean,
    gln_invalid?: boolean,
    coc_missing?: boolean,
    coc_invalid?: boolean
  ) => any;
  onNativePicture: (section?: InspectionSpecSection) => void;
  saveLocalInspectionBackup: (i: Inspection) => Promise<void>;

  // Entities
  lot?: Lot;
}

export const CInspectionBottomActions = (props: BottomActionsProps) => {
  const {
    inspection,
    spec: schema,
    setInspection,
    renderedQuestionIds,
    saveInspectionInDB,
    setErrors,
    isLocked,
    errors
  } = useInspectionContext();

  const toggleFinish = async () => {
    if (!new InspectionClass(inspection).isCompleted()) {
      let { errors } = getErrorsOnInspectionClose({
        inspection,
        spec: schema,
        renderedQuestionIds,
      });
      console.log('ERRORS', errors);
      setErrors(errors);

      if (errors.length) {
        document
          .getElementById('question-' + errors[0].questionId)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });

        let toast = await toastController.create({
          cssClass: 'toast-mandatory',
          message: `<b>${errors.length} ERROR${
            errors.length > 1 ? 'S' : ''
          } FOUND:</b><br/>${errors
            .map((e) => {
              const name = getQuestionDisplayedName(e.questionId, schema);
              const errorTypeMap: { [t in string]: string } = {
                out_of_range: 'Out of range',
                missing_mandatory: 'Missing',
                non_present_tagged_defects: 'Tagged in picture but no input present',
              };
              return `<b>&bull; ${name} (${errorTypeMap[e.type]})</b>`;
            })
            .join('<br/>')}`,
          duration: 10000,
          position: 'top',
          color: 'danger2',
          buttons: [
            {
              text: 'Got it',
              role: 'cancel',
            },
          ],
        });
        toast.present().then();
        return;
      }

      eventLogger.log(createCloseInspectionEvent(inspection), profile);
      return saveInspectionInDB('COMPLETED');
    } else {
      eventLogger.log(createOpenInspectionEvent(inspection), profile);

      // if we are reopening a lot inspection, we check the latest lot properties. If they have changed, we update the inspection
      if (!!props.lot && inspection.objectType === LotSchemaObjectTypeEnum.Lot) {
        const lotProperties = getInspectionLotProperties(props.lot);
        if (!isEqual(lotProperties, inspection.lotProperties)) {
          console.log(
            'Lot properties changed, updating...',
            lotProperties,
            inspection.lotProperties
          );
          setInspection((prev) => {
            props.saveLocalInspectionBackup({
              ...prev,
              lotProperties,
            } as LotInspection);
            return { ...prev, lotProperties };
          });
        }
      }

      return openInspection();
    }
  };

  const openInspection = () => {
    setInspection((prev) => ({ ...prev, status: 'IN PROGRESS' }));
  };

  const onScan = (barcode: IBarcode) => {
    const updatedInspection = {
      ...inspection,
      barcodes: (
        (inspection as LotInspection).barcodes?.filter(
          (o) => o.code !== barcode.data
        ) ?? []
      ).concat(barcode),
    };
    setInspection(updatedInspection);
    props.saveLocalInspectionBackup(updatedInspection);
  };

  const picturesUpdated = (picturesArray: Picture[]) => {
    console.log('pictures-updated', picturesArray);
    const updatedInspection = { ...inspection, pictures: picturesArray };
    setInspection((prevInspection) => {
      const newInspection = { ...prevInspection, ...updatedInspection };
      logLocalInspectionModificationEvent(prevInspection, newInspection, profile);
      // remove picture related errors
      setErrors(
        errors.filter((e) =>
          picturesArray.some((p) => p.inputIds.includes(e.questionId))
        )
      );
      return newInspection;
    });
    props.saveLocalInspectionBackup(updatedInspection);
  };

  const [cameraMode, setCameraMode] = useState(
    localStorage.getItem('ag-camera-mode') === 'true'
  );
  const profile = useContext(ctxProfile);

  const { onNativePicture, updateOCRData } = props;
  const isOpen = !new InspectionClass(inspection).isCompleted();
  const isEditable = isOpen && !isLocked;

  if (!inspection || !schema || !inspection?.reference?.type) return <></>;

  return (
    <>
      <div className="bottom-shadow"></div>

      {!isLocked && (
        <IonFab
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          className={'align-to-big'}
        >
          <IonFabButton
            data-tip={'lock-button'}
            color={isOpen ? 'medium' : 'dark'}
            onClick={(_) => toggleFinish()}
          >
            <IonIcon color="light" icon={isOpen ? lockOpen : lockClosed} />
          </IonFabButton>
        </IonFab>
      )}

      {isEditable && !Capacitor.isNativePlatform() && (
        <div className="camera-toggle-wrapper">
          <IonToggle
            checked={cameraMode}
            mode="md"
            color="dark"
            data-tip={'camera-mode-toggle'}
            onIonChange={(_) => {
              if (cameraMode) {
                localStorage.removeItem('ag-camera-mode');
              } else {
                localStorage.setItem('ag-camera-mode', 'true');
              }
              setCameraMode(!cameraMode);
            }}
          />
          <div>
            {!cameraMode
              ? isPlatform('desktop')
                ? 'UPLOAD'
                : 'HIGH RES'
              : isPlatform('desktop')
              ? 'CAMERA'
              : 'SPEED'}
          </div>
        </div>
      )}

      <CInspectionGallery
        isEditable={isEditable}
        pictures={inspection.pictures}
        schema={schema}
        inspection={inspection}
        assessmentStatus={isOpen}
        inspectionReference={inspection.reference}
        onPicturesUpdated={picturesUpdated}
        profile={profile}
        updateOCRData={updateOCRData}
        errors={errors}
      />

      <CInspectionCameraButtons
        ref={props.cameraRef}
        onPicturesUpdated={picturesUpdated}
        onScan={onScan}
        isEditable={isEditable}
        schema={schema}
        cameraMode={cameraMode}
        updateOCRData={updateOCRData}
        inspection={inspection}
        onNativePicture={onNativePicture}
        expectedBarcodes={props.lot?.transient?.barcodes}
      />
    </>
  );
};
