import firebase from 'firebase/compat/app';
import {
  contactColRef,
  inviteDocRef,
  locationColRef,
  offlineUpdate,
  orgDocRef,
  productColRef,
  productViewColRef,
  profileDocRef,
  userDocRef,
} from './DataStorage';
import { FIREBASE_FUNCTIONS_REGION } from './GlobalConstants';
import {
  ProductionSiteLocation,
  Location,
  LocationType,
  LocationTypes,
  ProductView,
  UserProfile,
  WarehouseLocation,
  productionSiteExclusiveKeys,
  updateAggregateModificationData,
  warehouseExclusiveKeys,
} from './Model';

////////////////////////////////////
//      ORGANISATION FUNCTIONS    //
////////////////////////////////////

export async function getOrganizationInformation(
  store: firebase.firestore.Firestore,
  organizationId: string
) {
  return await orgDocRef(store, organizationId).get();
}

export async function updateOrganizationInformation(
  store: firebase.firestore.Firestore,
  organizationId: string,
  attributeToUpdate: string,
  data: any
) {
  return await orgDocRef(store, organizationId).update({
    [attributeToUpdate]: data,
  });
}

////////////////////////////////////
//      PRODUCT FUNCTIONS         //
////////////////////////////////////

export async function getAGProducts(store: firebase.firestore.Firestore) {
  try {
    const agProductsQuery = await productColRef(store).get();

    const agProducts = agProductsQuery.docs.map((doc) => doc.data());

    return agProducts;
  } catch (e) {
    console.log(e);
  }
}

export function agProductsToSelectOptions(products: any[]) {
  return products.map((product: { id: string; name: string }) => {
    return { value: product.id, label: product.name };
  });
}

export async function updateProduct(
  store: firebase.firestore.Firestore,
  organizationId: string,
  agProductId: string,
  data: Partial<ProductView>
) {
  //TODO: check that document we want to update exists
  //TODO: check that the information is valid
  //TODO: create types for attribute to update and the types for data
  //TODO: check that the property im trying to change is not repeated
  console.log('SAVING PRODUCT:', agProductId, data);

  await offlineUpdate(productViewColRef(store, organizationId).doc(agProductId), data);
}

export async function saveNewProduct(
  // TODO: check that the new product already does not exist in database
  // TODO: import the correct types
  // TODO: check product Id is not repeated
  store: firebase.firestore.Firestore,
  organizationId: string,
  agProductId: string,
  productId: string
) {
  console.log(agProductId, productId);
  const data: ProductView = {
    agProductId,
    agVarieties: [],
    brands: [],
    customers: [],
    origins: [],
    packaging: [],
    productId,
    suppliers: [],
    varieties: [],
  };

  await productViewColRef(store, organizationId).doc(agProductId).set(data);

  // TODO: what to return
}

export async function deleteProduct(
  // TODO: check that the new product already does not exist in database
  // TODO: import the correct types
  // TODO: check product Id is not repeated
  // TODO: DISPLAY WARNING ON DELETE IMPORTANT
  // TODO:Check if product exist is being used in orders and display warning if true
  store: firebase.firestore.Firestore,
  organizationId: string,
  agProductId: string
) {
  await productViewColRef(store, organizationId).doc(agProductId).delete();

  //TODO: what to do with the delete are we sure we want to delete?
}

////////////////////////////////////
//         USER FUNCTIONS         //
////////////////////////////////////

// TODO:move this to cloud function
export async function updateUser(
  store: firebase.firestore.Firestore,
  uid: string,
  attributeToUpdate: string,
  data: any
) {
  await profileDocRef(store, uid).update({
    [attributeToUpdate]: data,
  });
}

export async function deleteUser(store: firebase.firestore.Firestore, uid: string) {
  await profileDocRef(store, uid).delete();

  await userDocRef(store, uid).delete();
}

////////////////////////////////////
//      CONTACT FUNCTIONS         //
////////////////////////////////////

export async function updateOrganizationContact(
  store: firebase.firestore.Firestore,
  organizationId: string,
  contactId: string,
  attributeToUpdate: string,
  data: any
) {
  //TODO: check that document we want to update exists
  //TODO: check that the information is valid
  //TODO: create types for attribute to update and the types for data
  // TODO: add the last modified date timestamp and
  await contactColRef(store, organizationId)
    .doc(contactId)
    .update({
      [attributeToUpdate]: data ?? firebase.firestore.FieldValue.delete(),
    });
}
export async function createOrganizationContact(
  store: firebase.firestore.Firestore,
  organizationId: string,
  contactId: string,
  type: string[]
) {
  //TODO: add timestamps "2022-03-12T23:17:11.072Z"

  const data = {
    address: '',
    countryCode: '',
    id: contactId,
    lastModifiedDate: {
      __datatype__: 'timestamp',
      value: '',
    },
    lastModifiedUserId: 'system',
    lastSystemDate: {
      __datatype__: 'timestamp',
      value: '',
    },
    name: contactId,
    type,
    users: [],
  };

  await contactColRef(store, organizationId).doc(contactId).set(data);
}

export async function deleteOrganizationContact(
  store: firebase.firestore.Firestore,
  organizationId: string,
  contactId: string
) {
  await contactColRef(store, organizationId).doc(contactId).delete();
}

////////////////////////////////////
//      LOCATION FUNCTIONS         //
////////////////////////////////////

export async function updateOrganizationLocation<L extends Location>(
  store: firebase.firestore.Firestore,
  organizationId: string,
  locationId: string,
  attributeToUpdate: keyof L,
  data: L[keyof L]
) {
  await locationColRef(store, organizationId)
    .doc(locationId)
    .update({
      [attributeToUpdate]: data ?? firebase.firestore.FieldValue.delete(),
    });
}

export async function setOrganizationLocation(
  store: firebase.firestore.Firestore,
  profile: UserProfile,
  location: Location
) {
  updateAggregateModificationData(profile.id, location);

  await locationColRef(store, profile.organisationId)
    .doc(location.locationId)
    .set(location);
}

export async function deleteOrganizationLocation(
  store: firebase.firestore.Firestore,
  organizationId: string,
  locationId: string
) {
  await locationColRef(store, organizationId).doc(locationId).delete();
}
export async function deleteOrganizationInvite(
  store: firebase.firestore.Firestore,
  inviteId: string
) {
  await inviteDocRef(store, inviteId).delete();
}

export async function checkIfOrganizationInviteIsValid(firebaseCode: string) {
  const checkIfInviteIsValid = firebase
    .app()
    .functions(FIREBASE_FUNCTIONS_REGION)
    .httpsCallable('checkOrganizationInviteIsValid');
  const isInvitationValid = await checkIfInviteIsValid({ code: firebaseCode });

  return isInvitationValid;
}

////////////////////////////////////
//       HELPER FUNCTIONS         //
////////////////////////////////////
// export const optionPermissions = [

//   {
//     value: 'READ_QC_DATA',
//     label: 'Read Quality Data',
//     description: 'User can read quality data: orders, lots, reports (filtered by products and locations)'
//   },
//   {
//     value: 'WRITE_QC_DATA',
//     label: 'Write Quality Data',
//     description: 'Write quality data: create inspection, QC lot DONE/OPEN/RE-OPEN status, and order QC status: DONE/OPEN, copy inspections  (filtered by products, locations, stage etc)'
//   },
//   {
//     value: 'UPLOAD',
//     label: 'Upload Pictures and Documents',
//     description: 'lorem ipsum dolor sit amet, consectetur adipiscing el'
//   },
//   {
//     value: 'REVERT_HISTORY',
//     label: 'Revert to a version in the history ',
//     description: 'lorem ipsum dolor sit amet, consectetur adipiscing el'
//   },
//   {
//     value: 'SPECIFICATION_BUILDER',
//     label: 'Specifcation builder (& scoring)',
//     description: 'Specifcation builder (& scoring) read, write and share with other organization **** only for org admin'
//   },
//   {
//     value: 'SPLIT_LOT',
//     label: 'Split lot due to QC in the UI',
//     description: 'lorem ipsum dolor sit amet, consectetur adipiscing el'
//   },
//   {
//     value: 'COMMERCIAL_STATUSES',
//     label: 'Setting commercial order statuts',
//     description: 'Setting commercial order statuts (CHECKED, SHARED, REDO) and lot level commercial REDO and custom (user defined) lot level statuses'
//   },
//   {
//     value: 'CHAT',
//     label: 'Chat',
//     description: 'Comminicate regarding orders/lots internally via chat '
//   },
//   {
//     value: 'SHARE_REPORTS',
//     label: 'Share Reports',
//     description: 'Share reports externally via email and in the system (later in the chat)  '
//   },
//   {
//     value: 'INSIGHTS',
//     label: 'Access partners profiles (insights)',
//     description: 'lorem ipsum dolor sit amet, consectetur adipiscing el'
//   },
//   {
//     value: 'PARTNERS',
//     label: 'Edit & invite partners',
//     description: 'Write/edit partners contact details, including inviting  (access to the admin module)'
//   },
//   {
//     value: 'LINKING',
//     label: 'Link orders',
//     description: 'Linking and un-linking of lots and orders to a customer/supplier lots'
//   }
// ]

// export const rolesToSelectValue = (roles: string[], options = optionRoles) => {
//   let selectValue = [];

//   roles?.forEach(role => {
//     selectValue.push(find(options, (option) => option.value === role));
//   });

//   return selectValue

// }
