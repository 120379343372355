/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const InspectionType = {
  Incoming: 'incoming',
  ShelfLife: 'shelf_life',
  Stock: 'stock',
  Outgoing: 'outgoing',
  Upcoming: 'upcoming',
  PreHarvest: 'pre_harvest',
  PointOfSale: 'point_of_sale',
  PostProduction: 'post_production',
  PostHarvest: 'post_harvest',
  Transport: 'transport',
} as const;

export type InspectionType = (typeof InspectionType)[keyof typeof InspectionType];
