/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { AutomaticScoringLogicType } from './automatic-scoring-logic-type';

/**
 *
 * @export
 * @interface AutomaticScoreSpec
 */
export interface AutomaticScoreSpec {
  /**
   * Identifier pointing to a `Score` object
   * @type {string}
   * @memberof AutomaticScoreSpec
   */
  scoreId: string;
  /**
   * This is a pointer to the actual automated scoring logic currently stored in Firestore
   * @type {string}
   * @memberof AutomaticScoreSpec
   */
  logicId: string;
  /**
   *
   * @type {AutomaticScoringLogicType}
   * @memberof AutomaticScoreSpec
   */
  logicType: AutomaticScoringLogicType;
  /**
   * Whether or not the automatically computed score can be manually overriden
   * @type {boolean}
   * @memberof AutomaticScoreSpec
   */
  isOverrideable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AutomaticScoreSpec
   */
  isManual?: AutomaticScoreSpecIsManualEnum;
  /**
   *
   * @type {string}
   * @memberof AutomaticScoreSpec
   */
  id: string;
}

export const AutomaticScoreSpecIsManualEnum = {
  False: false,
} as const;

export type AutomaticScoreSpecIsManualEnum =
  (typeof AutomaticScoreSpecIsManualEnum)[keyof typeof AutomaticScoreSpecIsManualEnum];
