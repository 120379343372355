import { alertController, toastController } from '@ionic/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonList,
  IonPage,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import * as Sentry from '@sentry/react';
import i18n from './ServiceI18n';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { firestore } from './ConfigFirebase';

// Data/logic
import { takeNativePicture } from './DataImage';
import {
  getProductionSiteInspectionSnapshot,
  updateProductionSiteInspectionInRelatedEntities,
} from './DataInspection';
import { presentStandardToast } from './HelperIonic';
import {
  compileInspectionRenderingInfo,
  getApplicableProductionSiteSpecs,
  handleUserInput,
  initDefaultValues,
  newProductionSiteInspection,
  prepareInspectionContext,
  removeIncompatibleInputs,
} from './InspectionLogic';
import {
  InspectionClass,
  detectInspectionUpdateConflicts,
  getInspectionBackup,
  getInspectionTimer,
  parseProdSiteInspectionReference,
  removeInspectionAndTimerFromIndexedDB,
  saveInspectionAndTimerIntoIndexedDB,
  stringifyInspectionReference,
} from './ServiceInspection';
import eventLogger from './events/common';
import {
  createModifyCloudInspectionEvent,
  logLocalInspectionModificationEvent,
} from './events/inspection';
import { useLocationUtils } from './hooks/useLocationUtils';

// Model
import { FieldSchemaObjectTypeEnum, InspectionSpecSection } from './generated/openapi/core';
import {
  InspectionContext,
  InspectionError,
  InspectionStatus,
  ProductionSiteInspection,
  ProductionSiteInspectionReference,
  UserInputLocator,
} from './InspectionModel';
import { Organisation, Picture, ProductionSiteLocation } from './Model';
import { FieldInspectionSpec, InspectionSpec } from './ModelSpecification';

// Context
import {
  InspectionCtxInterface,
  InspectionContextProvider,
} from './context/InspectionContextProvider';
import { ctxLocations, ctxOrg, ctxProfile, ctxSpecs, ctxUsers } from './App';

// Components
import { ViewInspectionScore } from './ViewInspectionScore';
import { CInspectionBottomActions } from './components-inspection/CInspectionBottomActions';
import { CInspectionLoader } from './components-inspection/CInspectionLoader';
import { CInspectionToolbar } from './components-inspection/CInspectionToolbar';
import { CSection } from './components-inspection/CSection';
import { CInspectionTitle } from './components-inspection/CInspectionTitle';
import { CInspectionWarning } from './components-inspection/CInspectionWarning';
import CProdSiteInspectionHeader from './components-inspection/CProdSiteInspectionHeader';

// Styling
import './PageInspection.scss';

interface Props extends RouteComponentProps {
  organisationId: string;
  stringifiedReference: string;
}

const PageProductionSiteInspection = (props: Props) => {
  const { organisationId, stringifiedReference, history } = props;
  // ------------------------------------------------------------------------------------------------
  // subscriptions
  // ------------------------------------------------------------------------------------------------
  const unsubscribers = useRef<(() => void)[]>([]);
  // here we store the question ids that are currently being rendered, this way it's easier to check for mandatory fields
  const renderedQuestionIds = useRef<string[]>([]);
  // flag to keep track of the status of the indexedDB in case an error occurs
  const indexedDBOutOfOrder = useRef<boolean>(false);
  // keep track of whether the inspection has been modified by the user
  const isModified = useRef<boolean>(false);
  // for lot inspections, keep track of whether the lot has been fetched from the db and set into the state
  const initialInspectionFetched = useRef<boolean>(false);

  // ------------------------------------------------------------------------------------------------
  // context
  // ------------------------------------------------------------------------------------------------
  const profile = useContext(ctxProfile);
  const myOrg: Organisation = useContext(ctxOrg);
  const locations = useContext(ctxLocations);
  const users = useContext(ctxUsers);
  const { inspectionSpecs } = useContext(ctxSpecs);

  // ------------------------------------------------------------------------------------------------
  // state
  // ------------------------------------------------------------------------------------------------
  const [inited, setInited] = useState<boolean>(false);
  const [errors, setErrors] = useState<InspectionError[]>([]);
  const [context, setContext] = useState<InspectionContext>();
  const [inspection, setInspection] = useState<ProductionSiteInspection>();
  const [inspectionReference, setInspectionReference] =
    useState<ProductionSiteInspectionReference>(
      parseProdSiteInspectionReference(stringifiedReference)
    );
  const [spec, setSpec] = useState<FieldInspectionSpec>();
  const [applicableSpecs, setApplicableSpecs] = useState<FieldInspectionSpec[]>([]);
  const [warningMessage, setWarningMessage] = useState<string>();

  // refs
  const inspectionRef = useRef<ProductionSiteInspection>();
  const specRef = useRef<FieldInspectionSpec>();
  const contextRef = useRef<InspectionContext>();
  const cameraRef = useRef(undefined);

  // Location
  const location: ProductionSiteLocation = locations.find(
    (l) => l.locationId === inspectionReference.locationId
  ) as ProductionSiteLocation;

  // ------------------------------------------------------------------------------------------------
  // life-cycles
  // ------------------------------------------------------------------------------------------------
  const setupInspectionListener = async (
    inspectionReference: ProductionSiteInspectionReference
  ) => {
    // Reset state
    setInited(false);
    setApplicableSpecs([]);
    setSpec(undefined);
    setContext(undefined);
    setInspection(undefined);
    setInspectionReference(inspectionReference);
    initialInspectionFetched.current = false;

    // Cancel current subscriptions
    unsubscribers.current?.map((unsubscribe) => unsubscribe());

    // Set new listener
    unsubscribers.current.push(
      getProductionSiteInspectionSnapshot(
        firestore,
        organisationId,
        inspectionReference,
        (i) => onInspectionSnapshot(i, inspectionReference),
        (err) =>
          presentStandardToast(
            toastController,
            `An error occurred trying to read the inspection from the database: ${err.message}`
          )
      )
    );
  };

  useIonViewDidLeave(() => {
    unsubscribers.current?.map((unsubscribe) => unsubscribe());
  });

  useIonViewWillEnter(() => {
    setupInspectionListener(parseProdSiteInspectionReference(stringifiedReference));
  }, [props]);

  useIonViewWillLeave(async () => {
    if (!inspectionRef.current) {
      return;
    }

    if (isModified.current) {
      const alert = await alertController.create({
        header: 'Save Changes?',
        message: 'Do you want to save your changes?',
        buttons: [
          {
            text: 'Discard Changes',
            handler: async () => {
              await removeLocalInspectionBackup(inspectionRef.current);
            },
            cssClass: 'dark',
          },
          {
            text: 'Save Changes',
            cssClass: 'primary',
            handler: async () => {
              await saveInspectionInDB('IN PROGRESS');
            },
          },
        ],
      });

      alert.present();
    }
  });

  // // ------------------------------------------------------------------------------------------------
  // // Effects
  // // ------------------------------------------------------------------------------------------------

  // https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/
  // we need inspection, schema and context at unmount so we need a ref in sync with state
  useEffect(() => {
    inspectionRef.current = inspection;
  }, [inspection]);
  useEffect(() => {
    specRef.current = spec;
  }, [spec]);
  useEffect(() => {
    contextRef.current = context;
  }, [context]);

  // // ------------------------------------------------------------------------------------------------
  // // methods
  // // ------------------------------------------------------------------------------------------------

  const onInspectionSnapshot = async (
    dbInspection: ProductionSiteInspection | undefined,
    inspectionReference: ProductionSiteInspectionReference
  ) => {
    if (!initialInspectionFetched.current) {
      let { context, spec, applicableSpecs, inspection } = await getInspectionEntities(
        inspectionReference,
        inspectionSpecs,
        dbInspection
      );

      // console.log('RETRIEVING INSPECTION', inspection);
      // console.log('SCHEMA SET', spec);

      setInspection(inspection);
      setSpec(spec);
      setApplicableSpecs(applicableSpecs);
      setContext(context);
      setInited(true);

      initialInspectionFetched.current = true;
    } else if (!!dbInspection && !!inspectionRef.current) {
      detectInspectionUpdateConflicts(
        inspectionRef.current,
        dbInspection,
        profile,
        users,
        setWarningMessage
      );
    }
  };

  // ----------------------------------------------------------------------------------------------

  const saveLocalInspectionBackup = async (inspection: ProductionSiteInspection) => {
    // Indicate that the inspection has been modified
    isModified.current = true;
    saveInspectionAndTimerIntoIndexedDB(inspection, presentIndexedDBIssuesToast);
  };

  // ----------------------------------------------------------------------------------------------
  const removeLocalInspectionBackup = async (inspection: ProductionSiteInspection) => {
    removeInspectionAndTimerFromIndexedDB(inspection, presentIndexedDBIssuesToast);
  };

  // ----------------------------------------------------------------------------------------------
  const presentIndexedDBIssuesToast = async (hasError: boolean) => {
    if (hasError && !indexedDBOutOfOrder.current) {
      await presentStandardToast(
        toastController,
        `Warning: A problem occurred while trying to access the device's storage. Inspection backup will not work correctly`
      );
      indexedDBOutOfOrder.current = true;
    } else if (!hasError && indexedDBOutOfOrder.current) {
      await presentStandardToast(
        toastController,
        `Device storage is back online`,
        undefined,
        'success'
      );
      indexedDBOutOfOrder.current = false;
    }
  };

  // ----------------------------------------------------------------------------------------------
  const takeAndSaveNativePicture = async (section?: InspectionSpecSection) => {
    const insp = inspectionRef.current;
    try {
      const picture = await takeNativePicture(
        profile.organisationId,
        stringifyInspectionReference(insp.reference),
        section
      );

      const newPictures = [...(insp.pictures ?? []), picture];
      picturesUpdated(newPictures);
    } catch (e) {
      Sentry.captureException(e);
      console.log('Error saving native picture', e);
    }
  };

  // ----------------------------------------------------------------------------------------------
  const updateInspection = (value: any, inputLocator: UserInputLocator) => {
    let { updatedContext, updatedInspection } = handleUserInput(
      value,
      inputLocator,
      spec,
      inspection,
      context,
      undefined,
      myOrg.settings
    );
    updatedInspection = {
      ...updatedInspection,
      status: 'IN PROGRESS',
      schemaId: spec.id,
      schemaVersion: (spec.version ?? 0).toFixed(),
    };

    setContext(updatedContext);
    setInspection(updatedInspection);

    logLocalInspectionModificationEvent(inspection, updatedInspection, profile);
    saveLocalInspectionBackup(updatedInspection);

    // remove errors when the questionId has been modified
    setErrors(errors.filter((e) => e.questionId !== inputLocator.questionId));

    // console.log(
    //   'updatedContext',
    //   updatedContext,
    //   'updatedInspection',
    //   updatedInspection,
    //   'schema',
    //   spec
    // );

    return { updatedContext, updatedInspection };
  };

  // ------------------------------------------------------------------------------------------------
  const saveInspectionInDB = async (inspectionStatus: InspectionStatus) => {
    const inspection = cloneDeep(inspectionRef.current);
    const spec = specRef.current;
    const context = contextRef.current;

    inspection.renderingInfo = compileInspectionRenderingInfo(
      spec,
      inspection,
      context,
      myOrg?.settings
    );
    inspection.status = inspectionStatus;

    // add time to the assessment when the assessment is closed and changes have been made (the timer is created when user triggers updateInspection for the first time during an inspection)
    let timer: number = await getInspectionTimer(inspection);

    const currentInspectionTime =
      timer != null && !isNaN(+timer) ? (Date.now() - +timer) / 1000 : 0;

    if (typeof currentInspectionTime === 'number') {
      inspection.inspectionTime =
        inspection.inspectionTime != null
          ? inspection.inspectionTime + currentInspectionTime
          : currentInspectionTime;
    }

    // before saving, make sure that all user inputs correspond to questions present in the inspection
    Object.keys(inspection.userInputs ?? {}).forEach((qId) => {
      if (!renderedQuestionIds.current.includes(qId)) {
        delete inspection.userInputs[qId];
      }
    });

    // Add location snapshot to the inspection
    inspection.productionSiteSnapshot = location;

    setInspection(inspection);

    // Indicate that the user has given the instruction to save the inspection in the db
    isModified.current = false;

    try {
      await updateProductionSiteInspectionInRelatedEntities(
        firestore,
        profile,
        inspection,
        myOrg
      );
      await removeLocalInspectionBackup(inspection);
      eventLogger.log(createModifyCloudInspectionEvent(inspection), profile);
    } catch (e) {
      isModified.current = true;
      console.error('inspection failed to update', inspection.reference, e);
      presentStandardToast(
        toastController,
        'There was a problem updating the inspection \n\n' + e.message
      );
    }
  };

  // ------------------------------------------------------------------------------------------------
  async function getInspectionEntities(
    reference: ProductionSiteInspectionReference,
    specs: InspectionSpec[],
    initialInspection: ProductionSiteInspection | undefined
  ): Promise<{
    inspection: ProductionSiteInspection;
    context: InspectionContext;
    applicableSpecs: FieldInspectionSpec[];
    spec: FieldInspectionSpec;
  }> {
    const backupInspection: ProductionSiteInspection | undefined =
      await retrieveBackupInspection(reference);

    let inspection: ProductionSiteInspection;
    let context: InspectionContext;
    let applicableSpecs: FieldInspectionSpec[];
    let spec: FieldInspectionSpec;

    if (!!location) {
      inspection = backupInspection ?? initialInspection;

      ({ spec, applicableSpecs } = getApplicableProductionSiteSpecs(
        specs,
        location,
        inspection
      ));

      if (!!spec) {
        if (!inspection) {
          const emptyInspection = newProductionSiteInspection(reference, location);
          const emptyContext = prepareInspectionContext<FieldInspectionSpec>(
            spec,
            emptyInspection
          );
          ({ inspection, context } = initDefaultValues(
            spec,
            emptyInspection,
            emptyContext,
            undefined,
            myOrg?.settings
          ));
        } else {
          removeIncompatibleInputs(inspection, spec);
          context = prepareInspectionContext(spec, inspection);
        }
      }
    } else {
      presentStandardToast(
        toastController,
        `No location ${reference.locationId} found`
      );
    }

    return { inspection, context, applicableSpecs: applicableSpecs, spec: spec };
  }

  // ------------------------------------------------------------------------------------------------
  const picturesUpdated = (picturesArray: Picture[]) => {
    console.log('pictures-updated', picturesArray);
    const updatedInspection = { ...inspection, pictures: picturesArray };
    setInspection((prevInspection) => {
      const newInspection = { ...prevInspection, ...updatedInspection };
      logLocalInspectionModificationEvent(prevInspection, newInspection, profile);
      // remove picture related errors
      setErrors(
        errors.filter((e) =>
          picturesArray.some((p) => p.inputIds.includes(e.questionId))
        )
      );
      return newInspection;
    });
    saveLocalInspectionBackup(updatedInspection);
  };

  //-------------------------------------------------------------------------------------------------
  const retrieveBackupInspection = async (
    reference: ProductionSiteInspectionReference
  ): Promise<ProductionSiteInspection | undefined> => {
    let backupInspection: ProductionSiteInspection = await getInspectionBackup(
      reference
    );

    if (!backupInspection) {
      console.log(`No backup found for ${stringifyInspectionReference(reference)}`);
    } else {
      // if a backup is present, it means the user had previously exited the app while in the middle of the inspection, so we set this flag to true
      isModified.current = true;
      console.log('Local inspection backup found:', backupInspection);
    }
    return backupInspection;
  };

  // // ==================================================================================================
  // // render
  // // ==================================================================================================

  // Useful flags/constants
  const isOpen: boolean = !new InspectionClass(inspection).isCompleted();
  const isLoading: boolean = !inited || !myOrg;
  const renderSections: boolean = !!inspection && !!spec && !!inspectionReference?.type;

  const inspectionContextProviderProps: InspectionCtxInterface = {
    inspection,
    spec: spec,
    context,
    setContext,
    setInspection,
    updateInspection,
    errors,
    saveInspectionInDB,
    inited,
    renderedQuestionIds,
    setSpec: setSpec,
    retrieveBackupInspection,
    setErrors,
    isLocked: false,
    applicableSpecs: applicableSpecs,
    setApplicableSpecs: setApplicableSpecs,
    isEditable: isOpen,
  };

  const { buildLocationDetailsUrl } = useLocationUtils();

  return (
    <IonPage className="page-inspection">
      <CInspectionToolbar>
        <IonButtons slot="start">
          <IonBackButton
            text={i18n.t('General.back')}
            defaultHref={buildLocationDetailsUrl(inspectionReference.locationId)}
            color="dark"
          />
        </IonButtons>
        <IonButtons slot="end" style={{ marginRight: '10px' }}>
          <ViewInspectionScore
            profile={profile}
            organisationSettings={myOrg?.settings}
            inspection={inspection}
            displayType="CARD-ORDER"
          />
        </IonButtons>
      </CInspectionToolbar>
      <IonContent className={!isOpen ? 'is-locked' : ''}>
        <CInspectionToolbar collapse="condense" size="large" />

        <CInspectionWarning
          warningMessage={warningMessage}
          setWarningMessage={setWarningMessage}
          onDiscardChangesAccept={async () => {
            isModified.current = false;
            await removeLocalInspectionBackup(inspection);
          }}
        />

        {isLoading ? (
          <CInspectionLoader />
        ) : (
          <InspectionContextProvider {...inspectionContextProviderProps}>
            <CInspectionTitle
              objectType={FieldSchemaObjectTypeEnum.Field}
              reference={inspectionReference}
              picturesUpdated={picturesUpdated}
            />

            <CInspectionBottomActions
              cameraRef={cameraRef}
              onNativePicture={takeAndSaveNativePicture}
              saveLocalInspectionBackup={saveLocalInspectionBackup}
            />

            <CProdSiteInspectionHeader
              inspectionReference={inspectionReference}
              history={history}
              orgId={organisationId}
              location={location}
              setupInspectionListener={setupInspectionListener}
            />

            <IonList
              className={`inspection-wrapper ${inspection?.status}`}
              id="page-inspection-content"
            >
              {renderSections &&
                spec.layout.map((section) => (
                  <CSection
                    key={section.sectionType}
                    onNativePicture={takeAndSaveNativePicture}
                    section={section}
                    cameraRef={cameraRef}
                  />
                ))}
            </IonList>
          </InspectionContextProvider>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PageProductionSiteInspection;
