/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { Criteria } from './criteria';
// May contain unused imports in some cases
// @ts-ignore
import { LotSection } from './lot-section';
// May contain unused imports in some cases
// @ts-ignore
import { QuestionSpec } from './question-spec';
// May contain unused imports in some cases
// @ts-ignore
import { ScorespecsInner } from './scorespecs-inner';

/**
 *
 * @export
 * @interface LotSchema
 */
export interface LotSchema {
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  creationDate: string;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  lastModifiedDate: string;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  lastModifiedUserId?: string;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof LotSchema
   */
  version: number;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  orgId: string;
  /**
   *
   * @type {Array<QuestionSpec>}
   * @memberof LotSchema
   */
  questionSpecs?: Array<QuestionSpec>;
  /**
   * Inspection-level score specification
   * @type {Array<ScorespecsInner>}
   * @memberof LotSchema
   */
  scoreSpecs?: Array<ScorespecsInner>;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  objectType?: LotSchemaObjectTypeEnum;
  /**
   *
   * @type {Array<LotSection>}
   * @memberof LotSchema
   */
  sections?: Array<LotSection>;
  /**
   *
   * @type {Criteria}
   * @memberof LotSchema
   */
  criteria: Criteria;
  /**
   *
   * @type {string}
   * @memberof LotSchema
   */
  lotScoringSectionId?: string;
}

export const LotSchemaObjectTypeEnum = {
  Lot: 'lot',
} as const;

export type LotSchemaObjectTypeEnum =
  (typeof LotSchemaObjectTypeEnum)[keyof typeof LotSchemaObjectTypeEnum];
