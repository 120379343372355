import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';
import { AllocationMismatch } from './Model';
import './ViewAllocationMismatchWarning.scss';

interface Props {
  allocationMismatchList: AllocationMismatch[];
  onClose?: Function;
  expectedId?: string;
  foundId?: string;
}

const ViewAllocationMismatchWarning = ({
  allocationMismatchList,
  onClose,
  expectedId,
  foundId,
}: Props) => {
  const allocationMismatchHTMLList = () => {
    return allocationMismatchList.map((allocationMismatch) => {
      return (
        <React.Fragment key={allocationMismatch.title}>
          <IonItem className="view-allocation-mismatch-warning_grid-item divider">
            <IonRow className="view-allocation-mismatch-warning_grid-item-row">
              <IonCol className="title">{allocationMismatch.title}</IonCol>
              <IonCol className="expected">{allocationMismatch.expected}</IonCol>
              <IonCol className="found">{allocationMismatch.found}</IonCol>
            </IonRow>
          </IonItem>
        </React.Fragment>
      );
    });
  };

  return (
    <IonCard className="view-allocation-mismatch-warning">
      {onClose && (
        <IonCardHeader>
          <IonToolbar>
            <IonTitle>Allocation Mismatches</IonTitle>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={() => onClose()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>
      )}
      <IonCardContent>
        <IonGrid className="view-allocation-mismatch-warning_grid">
          <IonItem className="view-allocation-mismatch-warning_grid-item">
            <IonRow className="view-allocation-mismatch-warning_grid-item-row">
              <IonCol className="header title">Parameter</IonCol>
              <IonCol className="header">{expectedId ? expectedId : 'Expected'}</IonCol>
              <IonCol className="header">{foundId ? foundId : 'Found'}</IonCol>
            </IonRow>
          </IonItem>

          {allocationMismatchHTMLList()}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default ViewAllocationMismatchWarning;
