import { IonPage, IonSpinner } from '@ionic/react';
import React from 'react';
import { firestore } from '../../ConfigFirebase';
import { LocationDetailsHeader } from '../../components/location/LocationDetailsHeader/LocationDetailsHeader';
import { useGetSharedFieldReport } from '../../data/sharedFieldReport/sharedFieldReport.hooks';
import { SharedFieldReportContainer } from '../../containers/SharedFieldReportContainer/SharedFieldReportContainer';

interface Props {
  sharedReportId: string;
}

export const PageSharedFieldReport = ({ sharedReportId }: Props) => {
  const { sharedFieldReport, isLoading } = useGetSharedFieldReport(
    firestore,
    sharedReportId
  );

  if (isLoading) {
    return (
      <IonPage className="page-lot">
        <LocationDetailsHeader name={'Loading report...'} />
        <IonSpinner />
      </IonPage>
    );
  }

  if (!sharedFieldReport) {
    return (
      <IonPage className="page-lot">
        <LocationDetailsHeader name={`Shared report not found`} />
      </IonPage>
    );
  }

  return (
    <SharedFieldReportContainer
      inspections={sharedFieldReport.inspections}
      sharedFieldReport={sharedFieldReport}
      isLoadingInspections={isLoading}
      associatedPartnerName={sharedFieldReport.associatedPartnerName}
    />
  );
};
