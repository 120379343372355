import React from 'react';
import * as Sentry from '@sentry/react';

// Firebase
import { auth } from '../../../ConfigFirebase';
import { acceptUserInvite } from '../../../DataStorage';

// Styling
import './PasswordPanel.scss';

// Components
import { SetPasswordForm } from '../../../shared/components/SetPasswordForm/SetPasswordForm';

interface Props {
  action: () => void;
}

export function PasswordPanel({ action }: Props) {
  const onSuccess = () => {
    acceptUserInvite(auth.currentUser.email)
      .then(action)
      .catch((e) => Sentry.captureException(e));
  };

  return (
    <div className="password-panel--wrapper">
      <div className="password-panel--header">
        <h1>Set Password</h1>
        <p>Please set your password to continue</p>
      </div>
      <div className="password-panel--content">
        <SetPasswordForm action={onSuccess} />
      </div>
      <div className="password-panel--footer">&nbsp;</div>
    </div>
  );
}
