import { cloneDeep } from 'lodash';
import {
  LotInspection,
  LegacyInspectionReference,
  Inspection,
} from '../InspectionModel';
import { BaseEvent } from './common';

interface CapturePhotoEvent extends BaseEvent {
  eventType: 'CapturePhotoEvent';
  reference: LegacyInspectionReference;
}

interface PhotoSavedLocallyEvent extends BaseEvent {
  eventType: 'PhotoSavedLocallyEvent';
  reference: LegacyInspectionReference;
  pictureId: string;
}

interface PhotoSavedToCloudEvent extends BaseEvent {
  eventType: 'PhotoSavedToCloudEvent';
  pictureId: string;
  pictureUrl: string;
}

interface PhotoDeletedLocallyEvent extends BaseEvent {
  eventType: 'PhotoDeletedLocallyEvent';
  pictureId: string;
}

/************************* */

export function createCapturePhotoEvent(inspection: Inspection): CapturePhotoEvent {
  return cloneDeep({
    eventType: 'CapturePhotoEvent',
    reference: inspection.reference,
  });
}

export function createPhotoSavedLocallyEvent(
  inspection: Inspection,
  pictureId: string
): PhotoSavedLocallyEvent {
  return cloneDeep({
    eventType: 'PhotoSavedLocallyEvent',
    reference: inspection.reference,
    pictureId,
  });
}

export function createPhotoSavedToCloudEvent(
  pictureUrl: string,
  pictureId: string
): PhotoSavedToCloudEvent {
  return cloneDeep({
    eventType: 'PhotoSavedToCloudEvent',
    pictureUrl,
    pictureId,
  });
}

export function createPhotoDeletedLocallyEvent(
  pictureId: string
): PhotoDeletedLocallyEvent {
  return cloneDeep({
    eventType: 'PhotoDeletedLocallyEvent',
    pictureId,
  });
}
