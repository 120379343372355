// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import { firestore } from './ConfigFirebase';
import { removeUpdatedRecentlyFlag, triggerAppUpdate } from './DataApp';
import { updateService } from './DataStorage';
import { APP_UPDATE_COOLDOWN_MS } from './GlobalConstants';
import { applicationStateObservable } from './simpleObservable/observables';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', async () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        await navigator.serviceWorker.ready;

        console.log(
          'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://bit.ly/CRA-PWA'
        );
      } else {
        // Is not localhost. Just register service worker
        await registerValidSW(swUrl, config);
      }
    });
  }
}

async function registerValidSW(swUrl: string, config?: Config) {
  try {
    let registration = await navigator.serviceWorker.register(swUrl);

    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed.
            console.log(
              'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
            );
            //alert('New version is available, please restart the app');

            // Execute callback
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            console.log('Content is cached for offline use.');
            //alert('App has been upgraded sucesfully');

            // Execute callback
            if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        }
      };
    };
  } catch (error) {
    console.error('Error during service worker registration:', error);
  }
}

async function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  let response: any;

  try {
    response = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    });
  } catch (e) {
    console.log('No internet connection found. App is running in offline mode.');

    return;
  }

  // Ensure service worker exists, and that we really are getting a JS file.
  const contentType = response.headers.get('content-type');

  if (
    response.status === 404 ||
    (contentType != null && contentType.indexOf('javascript') === -1)
  ) {
    // No service worker found. Probably a different app. Reload the page.
    await (await navigator.serviceWorker.ready).unregister();

    // not sure if this line is the one that was causing the app getting stuck on load after a deploy
    // but looks like a good candidate.
    // window.location.reload();
  } else {
    // Service worker found. Proceed as normal.
    await registerValidSW(swUrl, config);
  }
}

async function unregister() {
  if ('serviceWorker' in navigator) {
    let registration = await navigator.serviceWorker.ready;
    await registration.unregister();
  }
}

let onAppInit = true;

async function checkForSWUpdates() {
  setTimeout(async (_) => {
    checkForSWUpdates();
  }, 60000);
  // check right away first time
  console.log('checking for app updates');

  if (navigator.onLine && navigator?.serviceWorker) {
    let regs = await navigator.serviceWorker.getRegistrations();
    regs.forEach((reg) => reg.update());
  }
}

export async function forceUpdate() {
  try {
    let regs = await navigator.serviceWorker.getRegistrations();
    regs.forEach(async (reg) => {
      await reg.update();
      if (reg.waiting) {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
        console.log('updating....');
      } else {
        console.log('Nothing to update');
      }
    });
  } catch (e) {
    console.error('forceUpdate ERROR', e);
  }
}

// let refreshing = false

export function initalizeServiceWorker() {
  if (window.location.href.indexOf('localhost:3000') < 0) {
    setTimeout(() => (onAppInit = false), 5000);
    setTimeout(() => removeUpdatedRecentlyFlag(), APP_UPDATE_COOLDOWN_MS);

    console.log('Registering Service Worker');
    register({
      onUpdate: (registration) => {
        // setTimeout(() => {
        if (registration.waiting) {
          let waitingWorker = registration.waiting;
          waitingWorker.addEventListener('statechange', (e) => {
            console.log('worker statechange');
            // @ts-ignore
            if (e.target.state === 'activated') {
              reloadOrUpdateMessage();
            }
          });
          waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      },
    });

    // detect controller change and refresh the page
    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      console.log('worker controllerchange');
      reloadOrUpdateMessage();
    });
    checkForSWUpdates();
  } else {
    console.log('Localhost, deregistering service worker');
    unregister();
  }
}

const reloadOrUpdateMessage = () =>
  updateService(firestore)
    .get()
    .then((doc) => {
      if ((doc?.data()?.isConfirmationNeeded ?? true) && !onAppInit) {
        applicationStateObservable.next({
          ...applicationStateObservable.getValue(),
          updateAvailable: true,
        });
      } else {
        triggerAppUpdate();
      }
    });
