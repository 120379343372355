/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { QuestionSpec } from './question-spec';
// May contain unused imports in some cases
// @ts-ignore
import { ScorespecsInner } from './scorespecs-inner';
// May contain unused imports in some cases
// @ts-ignore
import { TransitSection } from './transit-section';

/**
 *
 * @export
 * @interface TransitSchema
 */
export interface TransitSchema {
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  creationDate: string;
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  lastModifiedDate: string;
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  lastModifiedUserId?: string;
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof TransitSchema
   */
  version: number;
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  orgId: string;
  /**
   *
   * @type {Array<QuestionSpec>}
   * @memberof TransitSchema
   */
  questionSpecs?: Array<QuestionSpec>;
  /**
   * Inspection-level score specification
   * @type {Array<ScorespecsInner>}
   * @memberof TransitSchema
   */
  scoreSpecs?: Array<ScorespecsInner>;
  /**
   *
   * @type {string}
   * @memberof TransitSchema
   */
  objectType?: TransitSchemaObjectTypeEnum;
  /**
   *
   * @type {Array<TransitSection>}
   * @memberof TransitSchema
   */
  sections?: Array<TransitSection>;
}

export const TransitSchemaObjectTypeEnum = {
  Transit: 'transit',
} as const;

export type TransitSchemaObjectTypeEnum =
  (typeof TransitSchemaObjectTypeEnum)[keyof typeof TransitSchemaObjectTypeEnum];
