import axios, { AxiosRequestConfig } from 'axios';
import { auth } from './ConfigFirebase';
import {
  QuestionApi,
  SchemaApi,
  UserApi,
  SectionApi,
  InspectionSpecApi,
} from './generated/openapi/core';
import * as platform from './generated/agrinormApis/clients/cadova';

const coreApiBasePath =
  process.env.REACT_APP_CORE_API_BASE_PATH ?? 'http://127.0.0.1:8000';

const platformBasePath =
  process.env.REACT_APP_PLATFORM_API_BASE_PATH ?? 'http://127.0.0.1:8000';

const injectAuthToken = process.env.REACT_APP_INJECT_AUTH_TOKEN ?? true;

const tokenResolver = () => {
  return !!auth.currentUser && injectAuthToken
    ? auth.currentUser.getIdToken()
    : Promise.resolve(undefined);
};

const platformClient = new platform.cadovaClient({
  BASE: platformBasePath,
  TOKEN: tokenResolver,
});

const axiosInstance = axios.create();

if (injectAuthToken) {
  console.log('Configuring auth token injection');
  const injectTokenInterceptor = async (config: AxiosRequestConfig) => {
    if (!!auth.currentUser) {
      const authToken = await auth.currentUser.getIdToken();
      const newHeaders = config.headers ?? {};
      newHeaders['Authorization'] = `Bearer ${authToken}`;
      return { ...config, headers: newHeaders };
    }

    throw new Error(
      'Unable to send API request. There is no currentUser to authenticate'
    );
  };
  const onReject = (error: any) => Promise.reject(error);
  axiosInstance.interceptors.request.use(injectTokenInterceptor, onReject);
} else {
  console.log('Bypassing auth token injection');
}

export const getSchemaApiClient = (): SchemaApi =>
  new SchemaApi(undefined, coreApiBasePath, axiosInstance);
export const getQuestionsApiClient = (): QuestionApi =>
  new QuestionApi(undefined, coreApiBasePath, axiosInstance);
export const getSectionApiClient = (): SectionApi =>
  new SectionApi(undefined, coreApiBasePath, axiosInstance);
export const getInspectionSpecClient = (): InspectionSpecApi =>
  new InspectionSpecApi(undefined, coreApiBasePath, axiosInstance);

// Platform APIs
export const getAdminApi = (): platform.AdminService => platformClient.admin;
export const getTaskApi = (): platform.TaskService => platformClient.task;
export const getOrgApi = (): platform.OrganizationService => platformClient.organization;
export const getPartnerApi = (): platform.PartnerService => platformClient.partner;
