import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import './SwipeablePanels.scss';

interface Props {
  children: React.ReactNode;
  index?: number;
  setPanelIndex?: (index: number) => void;
  disabled: boolean;
}

export function SwipeablePanels({
  children,
  index,
  setPanelIndex,
  disabled = false,
}: Props) {
  return (
    <SwipeableViews
      className="swipeable-panels--wrapper"
      index={index}
      onChangeIndex={setPanelIndex}
      disabled={disabled}
    >
      {children}
    </SwipeableViews>
  );
}
