import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import { closeOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import {
  GPSCoordinates,
  geoPointToGPS,
  getGpsLocation,
  gpsToGeoPoint,
} from '../DataLocation';
import CMap from './CMap';
import './CMapPicker.scss';

interface Props {
  initialGeoPoint?: firebase.firestore.GeoPoint;
  onAccept: (g: firebase.firestore.GeoPoint) => void;
  onCancel: () => void;
}

function CMapPicker({ initialGeoPoint, onAccept, onCancel }: Props) {
  const [coords, setCoords] = useState<GPSCoordinates | undefined>(
    initialGeoPoint ? geoPointToGPS(initialGeoPoint) : undefined
  );
  const [inited, setInited] = useState<boolean>(!!initialGeoPoint);

  // if no geoPoint given, use the user's current location
  if (!initialGeoPoint && !inited) {
    getGpsLocation((position: GeolocationPosition) => {
      if (!position) return;

      const coords: GPSCoordinates = {
        lng: position.coords.longitude,
        lat: position.coords.latitude,
      };
      setCoords(coords);
    });
    setInited(true);
  }

  return (
    <div className="ion-page map-picker">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Select location</IonTitle>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={onCancel}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="tertiary"
              disabled={false}
              fill="solid"
              onClick={() => onAccept(gpsToGeoPoint(coords))}
            >
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {inited && (
          <div className="map-container">
            <CMap coords={coords} setCoords={setCoords} />
          </div>
        )}
      </IonContent>
    </div>
  );
}

export default CMapPicker;
