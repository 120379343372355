/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const RiskCategory = {
  Volume: 'volume',
  QualityAppearance: 'quality_appearance',
  QualityCondition: 'quality_condition',
  QualityOther: 'quality_other',
  PackagingAndCompliance: 'packaging_and_compliance',
  Shipment: 'shipment',
  Contamination: 'contamination',
} as const;

export type RiskCategory = (typeof RiskCategory)[keyof typeof RiskCategory];
