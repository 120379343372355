import { alertController } from '@ionic/core';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { ctxOrg, ctxProfile, ctxSpecs } from '../App';
import {
  initDefaultValues,
  newProductionSiteInspection,
  prepareInspectionContext,
  removeIncompatibleInputs,
} from '../InspectionLogic';
import { Inspection, ProductionSiteInspectionReference } from '../InspectionModel';
import { ProductionSiteLocation } from '../Model';
import { userHasPermission } from '../PermissionsService';
import {
  InspectionClass,
  getInspectionBackup,
  removeInspectionAndTimerFromIndexedDB,
} from '../ServiceInspection';
import { useInspectionContext } from '../context/InspectionContextProvider';
import { CInspectionTypeSelector } from './CInspectionTypeSelector';
import './CLotInspectionHeader.scss';
import { CSchemaSelector } from './CSchemaSelector';

interface InspectionHeaderProps {
  inspectionReference: ProductionSiteInspectionReference;
  history: any;
  orgId: string;
  location: ProductionSiteLocation;
  setupInspectionListener: (
    inspectionReference: ProductionSiteInspectionReference
  ) => void;
}

const CProdSiteInspectionHeader = (props: InspectionHeaderProps) => {
  // Props
  const { inspectionReference, setupInspectionListener, location } = props;

  // Context
  const {
    inspection,
    spec: schema,
    setInspection,
    inited,
    setContext,
    setSpec: setSchema,
    applicableSpecs: applicableSchemas,
  } = useInspectionContext();

  const profile = useContext(ctxProfile);
  const myOrg = useContext(ctxOrg);
  const { inspectionSpecs } = useContext(ctxSpecs);

  // State
  const [showAllSchemas, setShowAllSchemas] = useState<boolean>(false);

  // Schemas
  let selectableSchemas =
    !!schema && !(applicableSchemas ?? []).find((s) => s.id === schema.id)
      ? [...applicableSchemas, schema]
      : applicableSchemas;
  if (showAllSchemas) {
    selectableSchemas = inspectionSpecs;
  }

  // Other flags
  const isCompleted = new InspectionClass(inspection).isCompleted();

  //------------------------------------------------------------------------------
  // State management
  const updateInspectionType = async (evt: CustomEvent) => {
    // @ts-ignore
    if (!evt.currentTarget?.classList.contains('select-expanded')) {
      // we only trigger the onChange when the select is expanded
      return;
    }

    const newReference: ProductionSiteInspectionReference = {
      ...inspectionReference,
      type: evt.detail.value,
    };

    if (!!(await getInspectionBackup(inspectionReference))) {
      const alert = await alertController.create({
        header: 'Warning',
        message: `You already started an inspection of type ${inspectionReference.type}. If you change the type, all your changes will be lost. Are you sure you want to continue?`,
        buttons: [
          {
            text: 'Yes',
            handler: async () => {
              await removeInspectionAndTimerFromIndexedDB(inspection);
              return setupInspectionListener(newReference);
            },
            cssClass: 'alert-button danger-button',
          },
          {
            text: 'No',
            cssClass: 'alert-button success-button',
            role: 'cancel',
          },
        ],
      });

      alert.present();
    } else if (newReference.type !== inspectionReference.type) {
      return setupInspectionListener(newReference);
    }
  };

  //------------------------------------------------------------------------------
  const onSchemaChange = (schemaId: string, oldInspection: Inspection) => {
    const schema = inspectionSpecs.find((s) => s.id === schemaId);

    const pictures = oldInspection?.pictures ?? [];
    const status = oldInspection?.status;
    const locationId = oldInspection?.locationId;

    // If the schema selected is different than the schema in the inspection, we wipe out the user inputs (the pictures we keep though!)
    if (schema?.id !== oldInspection?.schemaId && !!schema?.id) {
      let inspection =
        oldInspection != null
          ? cloneDeep(oldInspection)
          : newProductionSiteInspection(inspectionReference, location);

      removeIncompatibleInputs(inspection, schema);

      let context = prepareInspectionContext(schema, inspection);
      ({ context, inspection } = initDefaultValues(
        schema,
        inspection,
        context,
        undefined,
        myOrg?.settings
      ));

      setContext(context);
      setInspection({
        ...inspection,
        pictures,
        status,
        locationId,
        schemaId: schema.id,
        schemaVersion: `${schema.version}`,
      });
      setSchema(schema);
      console.log('SCHEMA SET', schema);
    }
  };

  // ==================================================================================================
  // render
  // ==================================================================================================

  // Permissions and "show" flags
  const disableSchemaSelect =
    isCompleted ||
    !userHasPermission(profile, 'WRITE', 'ASSESSMENT') ||
    !inspectionReference.type;

  return (
    <div className="c-inspection-header">
      <CInspectionTypeSelector
        inspectionReference={inspectionReference}
        updateInspectionType={updateInspectionType}
      />

      <CSchemaSelector
        setShowAllSchemas={setShowAllSchemas}
        onSchemaChange={onSchemaChange}
        disableSchemaSelect={disableSchemaSelect}
        selectableSchemas={selectableSchemas}
        showAllSchemas={showAllSchemas}
      />

      {inited && !!inspectionReference?.type && !schema && (
        <h1 className="h1-not-found">⬆️ select a Schema</h1>
      )}
      {inited && !inspectionReference?.type && (
        <h1 className="h1-not-found">⬆️ select an inspection type</h1>
      )}
    </div>
  );
};

export default CProdSiteInspectionHeader;
