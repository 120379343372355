import {
  IonButton,
  IonButtons,
  IonIcon,
  IonList,
  IonModal,
  IonPage,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import { toastController } from '@ionic/core';
import {
  QuestionsState,
  useCreateQuestion,
  useDeleteQuestion,
  useEditQuestion,
  useQuestions,
} from './data/Questions';
import ViewQuestionEditor from './ViewQuestionEditor';
import { Question, QuestionIn, RiskCategory } from './generated/openapi/core';
import './ViewQuestionTab.scss';
import { trashOutline } from 'ionicons/icons';

export default function ViewQuestionTab() {
  const questionsState: QuestionsState = useQuestions();
  const createQuestion = useCreateQuestion().mutate;
  const editQuestion = useEditQuestion().mutate;
  const deleteQuestion = useDeleteQuestion().mutate;
  const [showQuestionEditor, setShowQuestionEditor] = useState<boolean>(false);
  const [currQuestion, setCurrQuestion] = useState<Question>(null);
  const [isNewQuestion, setIsNewQuestion] = useState<boolean>(false);
  const initQuestion: Question = {
    id: '',
    name: '',
    risk_category: RiskCategory.QualityOther,
  };

  const pageRef = useRef<HTMLElement>();

  async function onEditQuestion(question: Question) {
    console.log(`Editing question ${JSON.stringify(question)}`);
    const questionId = currQuestion.id;
    const modifiedQuestion = question as QuestionIn;
    editQuestion(
      { questionId, modifiedQuestion },
      {
        onSuccess: async () => {
          const toast = await toastController.create({
            message: `Question ${question.name} edited`,
            position: 'top',
            color: 'dark',
            duration: 3000,
            buttons: [{ text: 'Got it', role: 'cancel' }],
          });

          toast.present().then();
        },
        onError: async (error) => {
          console.log(error);
          const toast = await toastController.create({
            message: `Error editing question ${question.name}`,
            position: 'top',
            color: 'dark',
            duration: 3000,
            buttons: [{ text: 'Got it', role: 'cancel' }],
          });

          toast.present().then();
        },
      }
    );
    setShowQuestionEditor(false);
    setCurrQuestion(null);
  }

  async function onCreateQuestion(question: Question) {
    console.log(`Creating question ${JSON.stringify(question)}`);
    const newQuestion = question as QuestionIn;
    createQuestion(newQuestion, {
      onSuccess: async () => {
        const toast = await toastController.create({
          message: `Question ${question.name} created`,
          position: 'top',
          color: 'dark',
          duration: 3000,
          buttons: [{ text: 'Got it', role: 'cancel' }],
        });

        toast.present().then();
      },
      onError: async (error) => {
        console.log(error);
        const toast = await toastController.create({
          message: `Error creating question ${question.name}`,
          position: 'top',
          color: 'dark',
          duration: 3000,
          buttons: [{ text: 'Got it', role: 'cancel' }],
        });

        toast.present().then();
      },
    });
    setShowQuestionEditor(false);
    setCurrQuestion(null);
  }

  async function onDeleteQuestion(question: Question) {
    if (window.confirm(`Are you sure you want to delete ${question.id}?`)) {
      deleteQuestion(question.id, {
        onSuccess: async () => {
          const toast = await toastController.create({
            message: `Question ${question.name} deleted`,
            position: 'top',
            color: 'dark',
            duration: 3000,
            buttons: [{ text: 'Got it', role: 'cancel' }],
          });

          toast.present().then();
        },
        onError: async (error) => {
          console.log(error);
          const toast = await toastController.create({
            message: `Error deleting question ${question.name}`,
            position: 'top',
            color: 'dark',
            duration: 3000,
            buttons: [{ text: 'Got it', role: 'cancel' }],
          });

          toast.present().then();
        },
      });
    }
  }

  if (questionsState.isError) {
    return <h1>Error loading questions: {questionsState.error.message}</h1>;
  }

  if (questionsState.isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <IonPage ref={pageRef} className="ion-page">
      <div className="view-question-tab" style={{ margin: '15px 0' }}>
        <IonModal
          cssClass={'desktop small-modal'}
          isOpen={showQuestionEditor}
          presentingElement={pageRef.current}
          onDidDismiss={(_) => {
            setShowQuestionEditor(false);
            setCurrQuestion(null);
          }}
        >
          <ViewQuestionEditor
            question={currQuestion}
            onSaveQuestion={isNewQuestion ? onCreateQuestion : onEditQuestion}
            isNewQuestion={isNewQuestion}
          />
        </IonModal>

        <div className="top-button">
          <IonButton
            onClick={(_) => {
              setIsNewQuestion(true);
              setCurrQuestion(initQuestion);
              setShowQuestionEditor(true);
            }}
          >
            New Question
          </IonButton>
        </div>

        {questionsState.data?.questions?.length === 0 && <h1>No questions found</h1>}
        {questionsState.data?.questions?.length > 0 && (
          <IonList
            style={{
              display: 'grid',
              padding: '15px',
              gridTemplateColumns: 'repeat(auto-fill, minmax(278px, 1fr))',
            }}
          >
            {questionsState.data.questions
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((question) => (
                <div key={question.id} className="question-item">
                  <div>
                    <b>{question.name}</b>
                    <br />
                    <span>{question.id}</span>
                  </div>
                  <div>
                    <IonButtons>
                      <IonButton
                        onClick={(_) => {
                          setIsNewQuestion(false);
                          setCurrQuestion(question);
                          setShowQuestionEditor(true);
                        }}
                        fill="clear"
                        color="dark"
                        className="test-button"
                      >
                        Edit
                      </IonButton>
                      <IonButton
                        onClick={() => onDeleteQuestion(question)}
                        fill="solid"
                        color="danger"
                      >
                        <IonIcon icon={trashOutline} />
                      </IonButton>
                    </IonButtons>
                  </div>
                </div>
              ))}
          </IonList>
        )}
      </div>
    </IonPage>
  );
}
