import React from 'react';
import { Contact, Lot, Order, Organisation, Report, UserProfile } from './Model';

import { ctxContacts, ctxUsers } from './App';
import { orgColRef } from './DataStorage';
import { firestore } from './ConfigFirebase';
// import { SupplierList } from "./DataSuppliers"

interface Props {
  report?: Order | Report;
  lot?: Lot;
  contactId?: string;

  profile?: UserProfile;
}

class ViewContactName extends React.Component<Props> {
  render() {
    const { report, lot, profile } = this.props;

    if (report?.contactName) {
      return report?.contactName.toLocaleLowerCase();
    }

    let contactId = report?.contactId;
    if (!!lot) {
      contactId = lot.suppliedByContactId;
    }

    // If report is external, display the org that created the report instead of the contact name
    const reportOrgId = (report as Report)?.createdByOrgId ?? report?.orgId;
    if (!!profile && !!report && profile.organisationId !== reportOrgId) {
      return reportOrgId;
    }

    return (
      <ctxContacts.Consumer>
        {(contactList) => {
          let contact: Contact = contactList?.find(
            (contact) => contact.id === contactId
          );
          if (contact) return contact.name;
          else {
            // if contact not found then search in the surveyerContacts
            // first find the contact from the org of the order
            contact = contactList?.find((c) => c.id === report?.orgId);
            if (contact) {
              let c1 = (contact.contacts ?? []).find((c) => c.id === contactId);
              if (c1) {
                return c1.name?.toLocaleLowerCase();
              } else {
                return null;
              }
            } else {
              return contactId || '';
            }
          }
        }}
      </ctxContacts.Consumer>
    );
  }
}

export default ViewContactName;

interface Props2 {
  contactId?: string;
}

export class ViewContactNameById extends React.Component<Props2> {
  render() {
    return (
      <ctxContacts.Consumer>
        {(contactList) => {
          let contact: Contact = contactList?.find(
            (contact) => contact.id === this.props.contactId
          );
          if (contact) return contact.name?.toLocaleLowerCase();
          else return this.props.contactId;
        }}
      </ctxContacts.Consumer>
    );
  }
}

interface Props3 {
  userId?: string;
}

export class ViewUserName extends React.Component<Props3> {
  render() {
    return (
      <ctxUsers.Consumer>
        {(users) => {
          let user: UserProfile = users.find((o) => o.id === this.props.userId);
          return user && <div className="user">{user.name ?? user.email}</div>;
        }}
      </ctxUsers.Consumer>
    );
  }
}

interface Props4 {
  orgId?: string;
}

interface State4 {
  organisations: Organisation[];
}

export class ViewOrganisationName extends React.Component<Props4, State4> {
  private mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      organisations: [],
    };
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  async componentDidMount(): Promise<void> {
    this.mounted = true;
    orgColRef(firestore)
      .get()
      .then((docs) => {
        if (docs.empty) {
          return;
        }
        const organisations: Organisation[] = docs.docs.map((doc) => {
          const org: Organisation = doc.data() as Organisation;
          return { id: org.id, name: org.name } as Organisation;
        });
        if (this.mounted) this.setState({ organisations });
      });
  }

  render() {
    let org: Organisation = (this.state.organisations ?? []).find(
      (o) => o.id === this.props.orgId
    );
    return <div>{org?.name ?? org?.id ?? this.props.orgId}</div>;
  }
}
