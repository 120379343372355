import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './PageNewLot.scss';
import { updateProduct } from './DataAdmin';
import { firestore } from './ConfigFirebase';
import { IArticle } from './Model';
import { Article } from './ServiceArticle';
import { PackagingType } from './generated/openapi/core';
import { NOT_AVAILABLE_STRING } from './GlobalConstants';

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setArticle?: (article: IArticle, calledFrom: string) => void;
  article?: IArticle;
  agProductId: string;
  orgId: string;
  packagingArray: string[];
  handleSavePackaging?: Function;
}

const ViewAddProductPackagingModal = ({
  article,
  openModal,
  setOpenModal,
  orgId,
  agProductId,
  setArticle,
  packagingArray,
}: Props) => {
  const [saving, setSaving] = useState(false);
  const [localArticle, setLocalArticle] = useState<IArticle>({} as IArticle);

  article = article ?? localArticle;
  setArticle = setArticle ?? setLocalArticle;

  const articleClass = new Article(article);
  const packagingRepr = articleClass.getPackagingRepr();

  const handleSave = async () => {
    const newPackagingArray = [...packagingArray, articleClass.getPackagingRepr()];
    setSaving(true);
    await updateProduct(firestore, orgId, agProductId, {
      packaging: newPackagingArray,
    });
    setSaving(false);
    setOpenModal(false);
  };

  return (
    openModal && (
      <IonModal
        isOpen={openModal}
        onDidDismiss={() => setOpenModal(false)}
        cssClass="modal-page-new-packaging"
      >
        <IonHeader className={''}>
          <IonToolbar>
            <IonTitle>Add Packaging</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={(_) => setOpenModal(false)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="page-new-lot">
          {saving && <IonLoading isOpen={saving} />}
          <IonList>
            {!articleClass.isRaw() && (
              <IonItem>
                <IonLabel>Packaging representation</IonLabel>
                {/* <IonInput value={(packagingRepr ?? '').length > 0 ? packagingRepr : 'N/A'} disabled></IonInput> */}
                <IonText>
                  {(packagingRepr ?? '').length > 0
                    ? packagingRepr
                    : NOT_AVAILABLE_STRING}
                </IonText>
              </IonItem>
            )}

            <IonItem>
              <IonLabel>{'Packaging type'}</IonLabel>
              <IonSelect
                interface="action-sheet"
                placeholder="select packaging type"
                data-tip={'vappm-packaging-type-selector'}
                value={article?.packagingType}
                onIonChange={(evt) => {
                  if (evt.detail.value == null) {
                    return;
                  }
                  setArticle(
                    {
                      ...article,
                      packagingType: evt.detail.value,
                    },
                    'packaging type modal'
                  );
                }}
              >
                {Object.entries(PackagingType)
                  .filter(([_, type]) => type !== PackagingType.Raw)
                  .map(([name, type]) => (
                    <IonSelectOption key={type} value={type}>
                      {name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>

            {(
              [
                PackagingType.ConsumerUnitsPieces,
                PackagingType.ConsumerUnitsWeight,
              ] as PackagingType[]
            ).includes(article?.packagingType) && (
              <IonItem>
                <IonLabel>Consumer units per box</IonLabel>
                <IonInput
                  inputmode="decimal"
                  type="number"
                  placeholder="enter consumer units"
                  data-tip={'vappm-consumer-unit-per-box'}
                  value={article?.numConsumerUnitsInBox}
                  onIonChange={(evt) => {
                    const numConsumerUnitsInBox = isNaN(parseFloat(evt.detail.value))
                      ? undefined
                      : parseFloat(evt.detail.value);
                    setArticle(
                      {
                        ...article,
                        numConsumerUnitsInBox,
                        boxNetWeightInKg: new Article({
                          ...article,
                          numConsumerUnitsInBox,
                        }).computeBoxNetWeight(true),
                      },
                      'consumer units x box modal'
                    );
                  }}
                />
              </IonItem>
            )}

            {article?.packagingType === PackagingType.ConsumerUnitsWeight && (
              <IonItem>
                <IonLabel>Consumer unit weight (g)</IonLabel>
                <IonInput
                  inputmode="decimal"
                  type="number"
                  placeholder="enter weight"
                  data-tip={'vappm-consumer-unit-weight'}
                  value={article?.consumerUnitWeightInGrams}
                  onIonChange={(evt) => {
                    const consumerUnitWeightInGrams = isNaN(
                      parseFloat(evt.detail.value)
                    )
                      ? undefined
                      : parseFloat(evt.detail.value);
                    setArticle(
                      {
                        ...article,
                        consumerUnitWeightInGrams,
                        boxNetWeightInKg: new Article({
                          ...article,
                          consumerUnitWeightInGrams,
                        }).computeBoxNetWeight(true),
                      },
                      'consumer weight untis modal'
                    );
                  }}
                />
              </IonItem>
            )}

            {article?.packagingType === PackagingType.ConsumerUnitsPieces && (
              <IonItem>
                <IonLabel>Pieces per consumer unit</IonLabel>
                <IonInput
                  inputmode="decimal"
                  type="number"
                  placeholder="enter weight"
                  data-tip={'vappm-consumer-unit-pieces'}
                  value={article?.consumerUnitNumberOfPieces}
                  onIonChange={(evt) => {
                    const consumerUnitNumberOfPieces = isNaN(
                      parseFloat(evt.detail.value)
                    )
                      ? undefined
                      : parseFloat(evt.detail.value);
                    setArticle(
                      {
                        ...article,
                        consumerUnitNumberOfPieces,
                        boxNetWeightInKg: new Article({
                          ...article,
                          consumerUnitNumberOfPieces,
                        }).computeBoxNetWeight(true),
                      },
                      'consume unit piueces type modal'
                    );
                  }}
                />
              </IonItem>
            )}

            {article?.packagingType === PackagingType.Bulk && (
              <IonItem>
                <IonLabel>Box net weight (Kg)</IonLabel>
                <IonInput
                  inputmode="decimal"
                  type="number"
                  placeholder="enter net weight"
                  data-tip={'vappm-bulk-net-weight'}
                  value={article?.boxNetWeightInKg}
                  onIonChange={(evt) =>
                    setArticle(
                      {
                        ...article,
                        boxNetWeightInKg: isNaN(parseFloat(evt.detail.value))
                          ? undefined
                          : parseFloat(evt.detail.value),
                      },
                      'box net weight modal'
                    )
                  }
                />
              </IonItem>
            )}
          </IonList>
          <IonItem>
            <IonButton
              expand="full"
              onClick={() => {
                handleSave();
              }}
              disabled={!articleClass.validatePackaging(true)}
            >
              Add
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal>
    )
  );
};

export default ViewAddProductPackagingModal;
