import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  // IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  closeCircleOutline,
  closeOutline,
  helpCircleOutline,
  readerOutline,
  warningOutline,
} from 'ionicons/icons';
import { isEqual } from 'lodash';
import React, { Fragment } from 'react';
import Select from 'react-select';
import { ctxContacts } from './App';
import { firestore } from './ConfigFirebase';
import { orderColRef } from './DataStorage';
import withContext, { ContextProps } from './HOC/withContext';
import {
  capitalizeFirstLetter,
  createOrderId,
  removeSpecialChars,
  sortByLastModifiedDateDesc,
  standardizeDate,
} from './HelperUtils';
import { LotInspection } from './InspectionModel';
import {
  Lot,
  LotPosition,
  LotToPosition,
  OrderCreatePayload,
  OrderType,
  TransitType,
} from './Model';
import './PageNewOrder.scss';
import { ViewOrganisationName } from './ViewContactName';
import CWizardNavigation, { NavigationProps } from './components/CWizardNavigation';
import { InspectionType } from './generated/openapi/core';
import { ContactType } from './generated/openapi/external';

interface Props {
  onCancel: Function;
  onSave: (orderPayload: OrderCreatePayload) => Promise<void>;
  lots: Lot[];
}

interface State {
  orderPayload: OrderCreatePayload;
  loading?: boolean;

  section: DispatchOrderSection;

  isOrderIdAvailable?: boolean;
}

type DispatchOrderSection = 'id' | 'order' | 'batches' | 'transport';

class PageNewOrder extends React.Component<Props & ContextProps, State> {
  hintRef = React.createRef<HTMLDivElement>();

  contactMap: {
    [key in OrderType]?: {
      displayedName: string;
      type: ContactType;
      inspectionType: InspectionType;
    };
  } = {
    SELL: {
      displayedName: 'buyer',
      type: 'BUYER',
      inspectionType: 'outgoing',
    },
    BUY: {
      displayedName: 'seller',
      type: 'SELLER',
      inspectionType: 'incoming',
    },
  };

  constructor(props: Props & ContextProps) {
    super(props);

    // we take the first found contact
    // const contactId = this.props.lots.find(l => !!l.suppliedByContactId)?.suppliedByContactId;
    // const contactName = this.props.applicationContext.contacts.find(c => c.id === contactId)?.name;

    const type = 'SELL';
    const orderId = createOrderId(type);

    const lotInspectionMap: { [key in string]: LotInspection } =
      this.addOrderIdToLotInspectionMap(orderId);

    const orderPayload: OrderCreatePayload = {
      type,
      positions: this.props.lots.map((l) => LotToPosition(l)),
      id: orderId,
      fulfilmentDate: new Date().setHours(0, 0, 0, 0),
      transport: {},
      lotInspectionMap,
      isAGMaster: true,
    };

    this.state = {
      isOrderIdAvailable: true,
      orderPayload,
      loading: false,
      section: 'id',
    };
  }

  private addOrderIdToLotInspectionMap(orderId: string) {
    const lotInspectionMap: { [key in string]: LotInspection } = {};

    this.props.lots.forEach((l) => {
      // TODO: make this generic once we allow for the creation of any kind of order
      const inspection = (l?.inspections ?? [])
        .sort(sortByLastModifiedDateDesc)
        .find(
          (a) =>
            a.reference?.type ===
            this.contactMap[this.state?.orderPayload?.type ?? 'SELL'].inspectionType
        );

      if (!!inspection) {
        // we add the order id to the reference
        lotInspectionMap[l.id] = {
          ...inspection,
          reference: { ...inspection.reference, orderId },
        };
      }
    });
    return lotInspectionMap;
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    return true;
  }

  async componentDidUpdate(
    prevProps: Readonly<Props & ContextProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (!isEqual(this.state.orderPayload, prevState.orderPayload)) {
      console.log('ORDER PAYLOAD', this.state.orderPayload);
    }
  }

  async onSave() {
    const { orderPayload } = this.state;

    this.setState({ loading: true });
    const isOrderIdAvailable = !(
      await orderColRef(firestore, this.props.profile.organisationId)
        .doc(orderPayload.id)
        .get()
    ).exists;

    if (!isOrderIdAvailable) {
      this.setState({ isOrderIdAvailable, loading: false, section: 'id' });
    } else {
      await this.props.onSave({
        ...orderPayload,
        lotInspectionMap: this.addOrderIdToLotInspectionMap(orderPayload.id),
      });
      this.setState({ loading: false });
    }
  }

  setPositionBoxes(e: CustomEvent<any>, lotId: string, totalBoxes: number) {
    const { orderPayload } = this.state;
    let val = +(e.detail.value ?? 1);
    if (val <= 0) {
      val = 1;
    }
    if (val > totalBoxes) {
      val = totalBoxes;
    }
    const positions: LotPosition[] = orderPayload.positions.map((p) => {
      return lotId === p.lotId ? { ...p, numBoxes: +val } : p;
    });
    this.setState({ orderPayload: { ...orderPayload, positions } });
  }

  updateOrderId(e: CustomEvent<any>) {
    this.setState({
      isOrderIdAvailable: true,
      orderPayload: {
        ...this.state.orderPayload,
        id: removeSpecialChars(e.detail.value).toUpperCase(),
      },
    });
  }

  render() {
    const { loading, section, orderPayload, isOrderIdAvailable } = this.state;
    const { profile, lots } = this.props;

    // TODO DEV-807: move somewhere else
    const orderTypeToString: { [key in OrderType]?: string } = {
      BUY: 'Incoming',
      SELL: 'Sale',
    };

    // const title = `New ${orderTypeToString[orderPayload.type]} Order`;
    const title = `Create New Order`;

    const dateCorrect = !!orderPayload.fulfilmentDate;

    const saveDisabled = !(
      !!orderPayload.id &&
      !!orderPayload.contactId &&
      dateCorrect
    );

    // for now, only allowing for SELL orders
    const orderOptions = Object.entries(orderTypeToString).map(([value, label]) => ({
      value,
      label,
    }));
    // .filter(o => o.value === 'SELL');

    const transportOptions = (['AIR', 'ROAD', 'SEA'] as TransitType[]).map((o) => ({
      value: o,
      label: capitalizeFirstLetter(o),
    }));

    const sectionsWithIssues: DispatchOrderSection[] = [];
    if (!orderPayload.id) {
      sectionsWithIssues.push('id');
    }
    if (!orderPayload.contactId || !dateCorrect) {
      sectionsWithIssues.push('order');
    }

    const sectionOrder: DispatchOrderSection[] = ['id', 'order', 'transport'];
    if (orderPayload.type === 'SELL') {
      sectionOrder.push('batches');
    }

    const navigationProps: NavigationProps<DispatchOrderSection> = {
      section,
      sections: sectionOrder,
      setSection: (section: DispatchOrderSection) => this.setState({ section }),
      dataTipPrefix: 'pndo',
      sectionsWithIssues,
    };

    const { displayedName, type } = this.contactMap[orderPayload.type];

    return (
      <div className="ion-page page-new-dispatch-order">
        <IonLoading isOpen={loading} />
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={() => this.props.onCancel()}>
                <IonIcon icon={closeOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="tertiary"
                disabled={saveDisabled}
                fill="solid"
                onClick={(_) => this.onSave()}
                data-tip={'pndo-save-button'}
              >
                <IonIcon icon={readerOutline} slot="start" />
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div className="form-container">
            {/* STEP 0 - ID*/}
            <IonList className="form-list id" hidden={section !== 'id'}>
              <IonItemDivider>Identifier</IonItemDivider>

              <IonItem
                className="select-item z5"
                color={orderPayload?.id == null ? 'warning' : ''}
              >
                <IonLabel>Order type</IonLabel>
                <Select
                  options={orderOptions}
                  value={orderOptions.find((o) => o.value === orderPayload.type)}
                  classNamePrefix={'react-select'}
                  onChange={(evt) => {
                    this.setState({
                      orderPayload: {
                        ...orderPayload,
                        type: evt.value as OrderType,
                        contactId: undefined,
                        contactName: undefined,
                        id: createOrderId(evt.value as OrderType),
                        bookingDate: undefined,
                        estimatedArrivalDate: undefined,
                      },
                    });
                  }}
                />
              </IonItem>

              <IonItem>
                <IonLabel>Organisation</IonLabel>
                <ViewOrganisationName orgId={profile.organisationId} />
              </IonItem>

              <IonItem>
                <IonLabel>Order id</IonLabel>
                <IonInput
                  inputmode="text"
                  type="text"
                  placeholder=""
                  debounce={500}
                  value={orderPayload.id}
                  onIonChange={(e) => this.updateOrderId(e)}
                />
              </IonItem>

              {!!orderPayload.id && orderPayload.id.length > 0 ? (
                !isOrderIdAvailable && (
                  <IonItem color="danger">
                    <IonIcon icon={closeCircleOutline} />
                    &nbsp;
                    {` Order id ${orderPayload.id} is unavailable, please try a different id`}
                  </IonItem>
                )
              ) : (
                <IonItem color="danger">
                  <IonIcon icon={warningOutline} /> Order id cannot be empty
                </IonItem>
              )}
            </IonList>

            {/* STEP 1 - ORDER*/}
            <IonList className="form-list order" hidden={section !== 'order'}>
              <IonItemDivider>Order info</IonItemDivider>

              <ctxContacts.Consumer>
                {(contacts) => {
                  const contactOptions = (contacts ?? [])
                    .filter((c) => (c.type ?? []).includes(type))
                    .map((g) => ({ value: g.id, label: g.name }));

                  const buyerPlaceholder = `please select ${displayedName}`;
                  const selectedSupplier = contactOptions.find(
                    (g) =>
                      g.value === orderPayload.contactId && !!orderPayload.contactId
                  ) ?? { value: undefined, label: buyerPlaceholder };

                  // const hintToggle = (e) => { e.currentTarget.parentElement.classList.toggle('hint-active') };
                  const hintToggle = (e) => {
                    e.preventDefault();
                    this.hintRef.current.classList.toggle('hint-active');
                  };

                  return (
                    <IonItem
                      className="select-item z5"
                      color={orderPayload?.contactId == null ? 'warning' : ''}
                    >
                      <IonLabel className="buyer-label">
                        {capitalizeFirstLetter(displayedName)}
                        <IonIcon
                          icon={helpCircleOutline}
                          className="hint-icon"
                          onClick={hintToggle}
                          title={'hint'}
                        />
                      </IonLabel>
                      <Select
                        options={contactOptions}
                        placeholder={buyerPlaceholder}
                        value={selectedSupplier}
                        isClearable
                        classNamePrefix={'react-select'}
                        onChange={(evt) => {
                          const contactId = evt?.value ?? null;
                          const contactName = contacts.find(
                            (c) => c.id === contactId
                          )?.name;
                          const newPayload = {
                            ...orderPayload,
                            contactId,
                            contactName,
                          };
                          this.setState({ orderPayload: newPayload });
                        }}
                      />
                    </IonItem>
                  );
                }}
              </ctxContacts.Consumer>

              <div
                className="hint"
                ref={this.hintRef}
              >{`In case the ${displayedName} is missing from this list, please ask your organization admin to add the ${displayedName} to the company settings`}</div>

              <IonItem>
                <IonLabel>{`${capitalizeFirstLetter(
                  displayedName
                )} reference`}</IonLabel>
                <IonInput
                  inputmode="text"
                  type="text"
                  placeholder="(optional)"
                  debounce={300}
                  value={orderPayload.externalReference}
                  onIonChange={(evt) =>
                    this.setState({
                      orderPayload: {
                        ...orderPayload,
                        externalReference: evt.detail.value,
                      },
                    })
                  }
                />
              </IonItem>

              {/* TODO: do this better */}
              {orderPayload?.type === 'SELL' && (
                <>
                  <IonItem color={!dateCorrect ? 'warning' : ''}>
                    <IonLabel>Shipping date</IonLabel>
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      placeholder="Please enter date"
                      value={new Date(orderPayload?.fulfilmentDate).toDateString()}
                      onIonChange={(evt) =>
                        this.setState({
                          orderPayload: {
                            ...orderPayload,
                            fulfilmentDate: new Date(evt.detail.value).getTime(),
                          },
                        })
                      }
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Arrival date</IonLabel>
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      placeholder="(optional)"
                      value={new Date(
                        orderPayload?.estimatedArrivalDate
                      ).toDateString()}
                      onIonChange={(evt) =>
                        this.setState({
                          orderPayload: {
                            ...orderPayload,
                            estimatedArrivalDate: new Date(evt.detail.value).getTime(),
                          },
                        })
                      }
                    />
                  </IonItem>
                </>
              )}

              {orderPayload?.type === 'BUY' && (
                <>
                  <IonItem>
                    <IonLabel>Order date</IonLabel>
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      value={
                        !!standardizeDate(orderPayload?.bookingDate)
                          ? standardizeDate(orderPayload?.bookingDate).toDateString()
                          : undefined
                      }
                      placeholder="(optional)"
                      onIonChange={(evt) =>
                        this.setState({
                          orderPayload: {
                            ...orderPayload,
                            bookingDate: new Date(evt.detail.value).getTime(),
                          },
                        })
                      }
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Arrival date</IonLabel>
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      color={!dateCorrect ? 'warning' : ''}
                      placeholder="Please enter date"
                      value={
                        !!standardizeDate(orderPayload?.fulfilmentDate)
                          ? standardizeDate(orderPayload?.fulfilmentDate).toDateString()
                          : undefined
                      }
                      onIonChange={(evt) =>
                        this.setState({
                          orderPayload: {
                            ...orderPayload,
                            fulfilmentDate: new Date(evt.detail.value).getTime(),
                          },
                        })
                      }
                    />
                  </IonItem>
                </>
              )}
            </IonList>

            {/* STEP 2 - Transport*/}
            <IonList className="form-list order" hidden={section !== 'transport'}>
              <IonItemDivider>Transport info</IonItemDivider>
              <IonItem className="select-item z4">
                <IonLabel>Transport type</IonLabel>
                <Select
                  options={transportOptions}
                  placeholder={'(optional)'}
                  value={
                    transportOptions.find(
                      (o) => o.value === orderPayload.transport?.transportType
                    ) ?? { value: undefined, label: '(optional)' }
                  }
                  isClearable
                  classNamePrefix={'react-select'}
                  onChange={(evt) =>
                    this.setState({
                      orderPayload: {
                        ...orderPayload,
                        transport: {
                          ...orderPayload.transport,
                          transportType: evt?.value ?? undefined,
                        },
                      },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Vessel name</IonLabel>
                <IonInput
                  inputmode="text"
                  type="text"
                  placeholder="(optional)"
                  debounce={300}
                  value={orderPayload.transport?.vessel}
                  onIonChange={(evt) =>
                    this.setState({
                      orderPayload: {
                        ...orderPayload,
                        transport: {
                          ...orderPayload.transport,
                          vessel: evt.detail.value,
                        },
                      },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Container</IonLabel>
                <IonInput
                  inputmode="text"
                  type="text"
                  placeholder="(optional)"
                  debounce={300}
                  value={orderPayload.transport?.container}
                  onIonChange={(evt) =>
                    this.setState({
                      orderPayload: {
                        ...orderPayload,
                        transport: {
                          ...orderPayload.transport,
                          container: evt.detail.value,
                        },
                      },
                    })
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel>Shipping reference</IonLabel>
                <IonInput
                  inputmode="text"
                  type="text"
                  placeholder="(optional)"
                  debounce={300}
                  value={orderPayload.transport?.transportReference}
                  onIonChange={(evt) =>
                    this.setState({
                      orderPayload: {
                        ...orderPayload,
                        transport: {
                          ...orderPayload.transport,
                          transportReference: evt.detail.value,
                        },
                      },
                    })
                  }
                />
              </IonItem>
            </IonList>

            {/* STEP 3 - LOTS*/}
            <IonList className="form-list lots" hidden={section !== 'batches'}>
              <IonItemDivider>Batches info</IonItemDivider>
              {orderPayload.positions.map((p) => {
                const totalBoxes = lots.find((l) => l.id === p.lotId).transient
                  ?.numBoxes;
                const header = `${p.lotId} (total boxes: ${totalBoxes} / remaining: ${
                  totalBoxes - p.numBoxes
                })`;
                return (
                  <Fragment key={p.lotId}>
                    <IonListHeader color="light-medium">{header}</IonListHeader>
                    <IonItem>
                      <IonLabel>Boxes sold</IonLabel>
                      <IonInput
                        inputmode="numeric"
                        type="number"
                        value={p.numBoxes}
                        onFocus={(e: any) => e?.target?.select()}
                        className="amount-input"
                        onIonChange={(evt) =>
                          this.setPositionBoxes(evt, p.lotId, totalBoxes)
                        }
                      />
                    </IonItem>
                  </Fragment>
                );
              })}
            </IonList>
          </div>

          <CWizardNavigation {...navigationProps} />
        </IonContent>
      </div>
    );
  }
}

export default withContext(PageNewOrder, ['profile']);
