import { toastController } from '@ionic/core';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';
import React from 'react';
import i18n from './ServiceI18n';
import { Contact, Order } from './Model';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline } from 'ionicons/icons';
import { functions } from './ConfigFirebase';

import './PageContactProfiling.scss';

import Select from 'react-select';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  fetchEntities,
  insightsProfilesApi,
  insightsRankApi,
  profilesDateRange,
  shareCustomerProfile,
  sharedCustomerProfile,
  verifyCustomerProfilePassword,
} from './DataInsight';
import { ProfilesFilter, SupplierData, SupplierRanking } from './ModelInsight';
import { omit } from 'lodash';
import { ISO3166 } from './HelperInsight';
import { fetchQuestions } from './data/Questions';
import { Question } from './generated/openapi/core';
import { Link } from 'react-router-dom';
import ModalShare from './ModalShare';
import withContext, { ContextProps } from './HOC/withContext';

interface Props extends RouteComponentProps {
  contactId: string;
  modal?: boolean;
  onClick?: Function;
  onCancel?: any;
  isShared?: boolean;
  sharedId?: string;
  contactName?: string;
}

interface State {
  recentOrders?: Order[];
  recentOrdersShared?: Order[];
  // data: any[];
  inited: boolean;
  contactId: string;
  origins: string[];
  products: string[];
  supplierIds: string[];
  selectedProduct: { value: string; label: string };
  selectedOrigin: { value: string; label: string };

  inputList: Question[];
  pgData: SupplierData;

  error: string | undefined;
  errorMessage?: string;
  isShareModalOpen?: boolean;
  isLoadingPassword?: boolean;
  showPassword?: boolean;
  password?: string;
  shareLink?: string;
}

class PageSupplierProfiling extends React.Component<Props & ContextProps, State> {
  private mounted;

  constructor(props) {
    super(props);

    this.state = {
      recentOrders: undefined,
      // data: [],
      inited: false,
      contactId: this.props.contactId,
      origins: [],
      products: [],
      supplierIds: [],
      selectedProduct: undefined,
      selectedOrigin: undefined,
      inputList: [],
      pgData: {},
      error: undefined,
      showPassword: this.props.isShared,
      password: '',
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  // shouldComponentUpdate(
  //   nextProps: Readonly<Props>,
  //   nextState: Readonly<State>,
  //   nextContext: any
  // ): boolean {
  //   // if (!isEqual(this.state, nextState)) return true;
  //   // return !isEqual(
  //   //   pick(this.props.applicationContext, ['contacts', 'products']),
  //   //   pick(nextProps.applicationContext, ['contacts', 'products'])
  //   // );
  // }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // console.log(
    //   'componentDidUpdate',
    //   this.state.products,
    //   this.state.origins,
    //   this.state.selectedProduct?.value,
    //   this.state.selectedOrigin?.value
    // );
    if (
      prevState.selectedProduct?.value !== this.state.selectedProduct?.value &&
      !!this.state.selectedProduct?.value &&
      !this.state.selectedOrigin?.value
    ) {
      const filter: ProfilesFilter = {
        supplier: this.state.contactId,
      } as ProfilesFilter;
      if (!!this.state.selectedProduct?.value) {
        filter.product = this.state.selectedProduct?.value;
      }

      fetchEntities(
        filter,
        this.props.profile?.organisationId,
        'incoming',
        profilesDateRange,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined,
        true
      ).then((res) => {
        const origins = [...new Set(res.origin)];
        this.setState({
          origins: origins,
          selectedOrigin: null,
          pgData: {},
          error: undefined,
        });
      });
    } else if (
      (prevState.selectedProduct?.value !== this.state.selectedProduct?.value ||
        prevState.selectedOrigin?.value !== this.state.selectedOrigin?.value) &&
      !!this.state.selectedOrigin?.value &&
      !!this.state.selectedProduct?.value
    ) {
      this.fetchPGData();
    } else if (prevState.contactId !== this.state.contactId) {
      this.getEntities();
    }
  }

  async getEntities() {
    const filter: ProfilesFilter = {
      supplier: this.state.contactId,
    } as ProfilesFilter;
    if (!!this.state.selectedProduct?.value) {
      filter.product = this.state.selectedProduct?.value;
    }
    try {
      const res = await fetchEntities(
        {},
        this.props.profile?.organisationId,
        'incoming',
        profilesDateRange,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined,
        true
      );
      const supplierIds: string[] = res.supplier;

      const res2 = await fetchEntities(
        filter,
        this.props.profile?.organisationId,
        'incoming',
        profilesDateRange,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined,
        true
      );

      // console.log('AAA', res);

      const products = [...new Set(res2.product)];
      const origins = [...new Set(res2.origin)];

      this.setState({
        // data: data,
        supplierIds,
        inited: true,
        origins: origins,
        products: products,
        error: undefined,
        selectedProduct: null,
        selectedOrigin: null,
      });
    } catch (e) {
      this.setState({
        inited: true,
        errorMessage:
          e.message === 'The function must be called with a valid shared id.'
            ? 'Invalid share-code'
            : e.message,
      });
    }
  }

  // async fetchData() {
  //   let data = [];
  //   this.setState({
  //     inited: false,
  //     // data: [],
  //     selectedProduct: null,
  //     selectedOrigin: null,
  //   });

  //   // fetch questions, since we are not loading them on startup anymore
  //   getAGQuestions(globalFirestore).then((inputList) => {
  //     this.setState({ inputList });
  //     // console.log('questions LOADED:', inputList);
  //   });

  //   const filter: ProfilesFilter = {
  //     supplier: this.state.contactId,
  //   } as ProfilesFilter;
  //   // if (!!this.state.selectedProduct?.value) {
  //   //   filter.product = this.state.selectedProduct?.value;
  //   // }

  //   const res = await fetchEntities(
  //     filter,
  //     this.props.profile.organisationId,
  //     'incoming',
  //     this.entitiesDateRange
  //   );

  //   // console.log('AAA', res);

  //   const products = [...new Set(res.product)];
  //   const origins = [...new Set(res.origin)];

  //   this.setState({
  //     // data: data,
  //     inited: true,
  //     origins: origins,
  //     products: products,
  //     selectedProduct: null,
  //     selectedOrigin: null,
  //   });
  // }

  private renderOriginLabel(origin: string) {
    return ISO3166[origin] ?? origin;
  }

  private renderProductLabel(productId: string): string {
    const agProductId = this.props.products?.find(
      (p) => p.productId === productId || p.agProductId === productId
    )?.agProductId;

    if (agProductId == null || agProductId === productId) {
      return productId;
    }

    return `[${productId}] ` + agProductId;
  }

  async fetchPGData() {
    if (!this.state.selectedOrigin || !this.state.selectedProduct) return;

    this.setState({
      inited: false,
      pgData: {},
      error: undefined,
    });

    const filter: ProfilesFilter = {
      product: this.state.selectedProduct.value,
      origin: this.state.selectedOrigin.value,
      supplier: this.state.contactId,
    };

    const properties = ['variety', 'packaging', 'label'];
    try {
      const propertiesBreakdown: Promise<any>[] = [];
      properties.forEach(async (property: string) => {
        const fetchedSeries = insightsProfilesApi(
          filter,
          'property_breakdown',
          this.props.profile?.organisationId,
          property,
          this.props.isShared
            ? {
                isShared: true,
                sharedId: this.props.sharedId,
              }
            : undefined
        );
        propertiesBreakdown.push(fetchedSeries);
      });
      const fetchedPropertiesBreakdown = await Promise.all(propertiesBreakdown);

      this.setState({
        inited: true,
        pgData: {
          ...this.state.pgData,
          propertiesBreakdown: fetchedPropertiesBreakdown,
        },
      });

      const fetchedConditionBreakdown = await insightsProfilesApi(
        filter,
        'condition_breakdown',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      this.setState({
        inited: true,
        pgData: {
          ...this.state.pgData,
          conditionBreakdown: fetchedConditionBreakdown,
        },
      });

      const fetchedConditionAverage = await insightsProfilesApi(
        omit(filter, ['supplier']),
        'condition_average',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      fetchedConditionAverage.series
        .filter(
          (obj) =>
            obj.season === 'latest season' &&
            fetchedConditionBreakdown.series.some((o) => o.condition === obj.condition)
        )
        .forEach((obj) => {
          const {
            condition,
            global_avg_perc_num_1kkg_with_condition,
            global_avg_perc_num_1kkg_with_severe_condition,
            global_avg_num_1kkg_in_season,
          } = obj;
          const matchingObject = fetchedConditionBreakdown.series.find(
            (o) => o.season === 'latest season' && o.condition === condition
          );

          if (matchingObject) {
            matchingObject.global_avg_perc_num_1kkg_with_condition =
              global_avg_perc_num_1kkg_with_condition;
            matchingObject.global_avg_perc_num_1kkg_with_severe_condition =
              global_avg_perc_num_1kkg_with_severe_condition;
            matchingObject.global_avg_num_1kkg_in_season =
              global_avg_num_1kkg_in_season;
          }
        });

      const fetchedShipmentQualityBreakdown = await insightsProfilesApi(
        filter,
        'shipment_quality_breakdown',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      this.setState({
        inited: true,
        pgData: {
          ...this.state.pgData,
          shipmentQualityBreakdown: fetchedShipmentQualityBreakdown,
        },
      });

      const fetchedSupplierSummaryRanking = await insightsProfilesApi(
        filter,
        'supplier_summary_ranking',
        this.props.profile?.organisationId,
        undefined,
        this.props.isShared
          ? {
              isShared: true,
              sharedId: this.props.sharedId,
            }
          : undefined
      );

      this.setState({
        inited: true,
        pgData: {
          ...this.state.pgData,
          supplierSummaryRanking: fetchedSupplierSummaryRanking,
        },
      });

      const seasons = ['two seasons ago', 'previous season', 'latest season'];
      const rankingsBySeason: SupplierRanking = {};

      for (const season of seasons) {
        const entities: any = {
          product: this.state.selectedProduct.value,
          season: season,
        };
        if (this.state.selectedOrigin?.value != null) {
          entities.origin = this.state.selectedOrigin?.value;
        }
        const fetchedSupplierRankingRaw = await insightsRankApi(
          entities,
          {
            dateRange: {
              startDate: new Date('2018-01-01'),
              endDate: new Date(Date.now()),
            },
            aggregation_interval: null,
            measured_by: 'volume',
            metric: 'incoming_avg_quality',
            entity: 'supplier',
            stage: 'incoming',
            name: 'rank_incoming_code_supplier',
            chartType: 'rank',
            view: 'incoming',
          },
          this.props.profile?.organisationId,
          this.props.isShared
            ? {
                isShared: true,
                sharedId: this.props.sharedId,
              }
            : undefined
        );

        const fetchedSupplierRankings = fetchedSupplierRankingRaw.data.rankings;

        const fetchedSupplierRankingQuality = fetchedSupplierRankings
          .sort((a, b) => (a.kpi_value < b.kpi_value ? 1 : -1))
          .map((o) => ({
            entity: o.entity,
          }));

        const fetchedSupplierRankingVolume = fetchedSupplierRankings
          .sort((a, b) => (a.sum_1kkg < b.sum_1kkg ? 1 : -1))
          .map((o) => ({
            entity: o.entity,
          }));

        rankingsBySeason[season] = {
          quality: fetchedSupplierRankingQuality,
          volume: fetchedSupplierRankingVolume,
        };
      }

      this.setState({
        inited: true,
        error: undefined,
        pgData: {
          ...this.state.pgData,
          supplierRanking: rankingsBySeason,
        },
      });
    } catch (error) {
      console.log(error);
      this.setState({
        error: 'An error occurred, please try again or contact support',
      });
    }
  }

  ionViewWillEnter() {
    this.getEntities();
    fetchQuestions()
      .then((ql) => {
        // console.log("questions fetched:", ql.questions);
        this.setState({ inputList: ql.questions });
      })
      .catch((e) => console.error(e));
  }

  renderPropertiesBreakdown() {
    let seriesGroups = this.state.pgData.propertiesBreakdown;

    console.log('PB new data', seriesGroups);

    if (!seriesGroups) {
      return;
    }

    // Make monochrome colors
    var pieColors = (function () {
      let colors = [];
      for (let j = 0; j < 5; j++) {
        let base = Highcharts.getOptions().colors[j * 2],
          i;

        for (i = 0; i < 4; i += 1) {
          // Start out with a darkened base color (negative brighten), and end
          // up with a much brighter color
          colors.push(
            Highcharts.color(base)
              .brighten((i - 1) / 7)
              .get()
          );
        }
      }
      return colors;
    })();

    return seriesGroups.map((seriesGroup) => {
      let seriesP = seriesGroup.series;
      let property = seriesP[0]?.property;

      let categories = [...new Set(seriesP.map((o) => o.season))].sort();
      let values = [...new Set(seriesP.map((o) => o.value))];

      let optionsStacked = {
        chart: {
          type: 'column',
          height: '450px',
        },
        colors: pieColors,
        title: {
          text: property,
        },
        xAxis: {
          categories: categories,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total kg',
          },
          labels: {
            formatter: function () {
              return this.value + 'k';
            },
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return (this.total?.toFixed(1) ?? 0) + 'kg';
            },
            style: {
              fontWeight: 'bold',
              color:
                // theme
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                'gray',
            },
          },
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          itemStyle: {
            fontSize: 10,
          },
          floating: false,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false,
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat:
            property +
            ' {series.name}: {point.y:.3f}kg<br/>Total: {point.stackTotal:.3f}kg',
        },
        plotOptions: {
          series: { animation: false },
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: values.map((v) => {
          return {
            name: v,
            data: categories.map((c) => {
              return (
                parseFloat(
                  seriesP
                    .find((a) => a.value === v && a.season === c)
                    ?.num_1kkg?.toFixed(3)
                ) ?? 0
              );
            }),
          };
        }),
      };

      if (categories.length > 0) {
        return (
          <HighchartsReact
            key={property as string}
            highcharts={Highcharts}
            options={optionsStacked}
            allowChartUpdate={true}
          />
        );
      } else {
        return null;
      }
    });
  }

  renderShipmentRag() {
    const series = this.state.pgData.shipmentQualityBreakdown?.series;
    console.log('SQB new data', series);

    if (!series) {
      return;
    }

    let dates = [...series.map((o) => Date.parse(o.timestamp))];

    let optionsStacked = {
      chart: {
        type: 'column',
        height: '360px',
        zoomType: 'x',
      },
      title: {
        text: 'Shipment quality breakdown',
      },
      xAxis: {
        categories: dates,
        margin: [0, 0, 0, 0],
        visible: true,
        type: 'datetime',
        tickInterval: 4,
        labels: {
          formatter: function () {
            return new Date(this.value).toLocaleDateString?.('en-GB');
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total kg',
        },
        labels: {
          formatter: function () {
            return this.value + 'k';
          },
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return '';
            // return (this.total?.toFixed(3) ?? 0) + 'kg'
          },
          style: {
            fontWeight: 'bold',
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        formatter: function () {
          // console.log(this)
          return `
                ${new Date(this.x).toLocaleDateString('en-GB')} <br/>
                <b>${series[this.point.index].order_id}</b> <br/>
                ${this.series.name}: ${this.point.y.toFixed(3)}kg <br/>
                Total: ${this.point.stackTotal.toFixed(3)}kg
              `;
        },
        // headerFormat: '<b>{point.x:}</b><br/>',
        // pointFormat: ' {series.name}: {point.y:.3f}kg<br/>Total: {point.stackTotal:.3f}kg'
      },
      plotOptions: {
        series: { animation: false },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        // TODO: Add support for 5th category
        {
          name: 'good',
          color: '#97D176',
          meta: { a: 5, b: 6 },
          data: series.map((o) => parseFloat(o.quality_4_num_1kkg?.toFixed(3)) ?? 0),
        },
        {
          name: 'average',
          color: '#FFCC68',
          meta: { a: 3, b: 4 },
          data: series.map((o) => parseFloat(o.quality_3_num_1kkg?.toFixed(3)) ?? 0),
        },
        {
          name: 'bad',
          color: '#FA937A',
          meta: { b: 2 },
          data: series.map((o) => parseFloat(o.quality_2_num_1kkg?.toFixed(3)) ?? 0),
        },
        {
          name: 'very bad',
          color: '#EA0206',
          meta: { a: 1 },
          data: series.map((o) => parseFloat(o.quality_1_num_1kkg?.toFixed(3)) ?? 0),
        },
      ],
    };

    return (
      <HighchartsReact
        // key={p as string}
        highcharts={Highcharts}
        options={optionsStacked}
        allowChartUpdate={true}
      />
    );
  }

  renderDefects() {
    const { inputList } = this.state;
    const series = this.state.pgData.conditionBreakdown?.series;
    console.log('CB new data', series);

    if (!series) {
      return null;
    }

    let defects = [
      ...new Set(
        series
          .filter((o) => o.num_1kkg_with_condition > 1)
          .sort((a, b) =>
            a.num_1kkg_with_condition >= b.num_1kkg_with_condition ? -1 : 1
          )
          .map((o) => o.condition)
      ),
    ].slice(0, 8);

    let seasons = [...new Set(series.map((o) => o.season))].sort();

    // TODO: optimize this, a lot of repeated code

    let colSeries = seasons
      .filter((season) => season !== 'two seasons ago')
      .map((season) => {
        if (season === 'latest season') {
          return [
            // {
            //   name: season + ' average',
            //   type: 'scatter',
            //   zIndex: 100,
            //   color: 'red',
            //   data: defects.map(d=> ({
            //     y: (series.find(s=>s.season===season&&s.condition===d)?.global_avg_perc_num_1kkg_with_condition) ?? 0,
            //     metadata: series.find(s=>s.season===season&&s.condition===d),
            //   }))
            // },
            {
              name: season + ' average',
              color: '#222288',
              data: defects.map((d) => ({
                name: 'average',
                y:
                  series.find((s) => s.season === season && s.condition === d)
                    ?.global_avg_perc_num_1kkg_with_condition * 100 ?? 0,
                metadata: series.find((s) => s.season === season && s.condition === d),
              })),
            },
            {
              name: season,
              data: defects.map((d) => ({
                y:
                  (series.find((s) => s.season === season && s.condition === d)
                    ?.num_1kkg_with_condition /
                    series.find((s) => s.season === season && s.condition === d)
                      ?.num_1kkg_in_season) *
                    100 ?? 0,
                metadata: series.find((s) => s.season === season && s.condition === d),
              })),
            },
          ];
        }

        return [
          {
            name: season,
            data: defects.map((d) => ({
              y:
                (series.find((s) => s.season === season && s.condition === d)
                  ?.num_1kkg_with_condition /
                  series.find((s) => s.season === season && s.condition === d)
                    ?.num_1kkg_in_season) *
                  100 ?? 0,
              metadata: series.find((s) => s.season === season && s.condition === d),
            })),
          },
        ];
      })
      .flat();

    let colSeriesSevere = seasons
      .filter((season) => season !== 'two seasons ago')
      .map((season) => {
        if (season === 'latest season') {
          return [
            // {
            //   name: season + ' average',
            //   type: 'scatter',
            //   zIndex: 100,
            //   color: 'red',
            //   data: defects.map(d=> ({
            //     y: (series.find(s=>s.season===season&&s.condition===d)?.global_avg_perc_num_1kkg_with_condition) ?? 0,
            //     metadata: series.find(s=>s.season===season&&s.condition===d),
            //   }))
            // },
            {
              name: season + ' average',
              color: '#222288',
              data: defects.map((d) => ({
                name: 'average',
                y:
                  series.find((s) => s.season === season && s.condition === d)
                    ?.global_avg_perc_num_1kkg_with_severe_condition * 100 ?? 0,
                metadata: series.find((s) => s.season === season && s.condition === d),
              })),
            },
            {
              name: season,
              data: defects.map((d) => ({
                y:
                  (series.find((s) => s.season === season && s.condition === d)
                    ?.num_1kkg_with_severe_condition /
                    series.find((s) => s.season === season && s.condition === d)
                      ?.num_1kkg_in_season) *
                    100 ?? 0,
                metadata: series.find((s) => s.season === season && s.condition === d),
              })),
            },
          ];
        }

        return [
          {
            name: season,
            data: defects.map((d) => ({
              y:
                (series.find((s) => s.season === season && s.condition === d)
                  ?.num_1kkg_with_severe_condition /
                  series.find((s) => s.season === season && s.condition === d)
                    ?.num_1kkg_in_season) *
                  100 ?? 0,
              metadata: series.find((s) => s.season === season && s.condition === d),
            })),
          },
        ];
      })
      .flat();

    // @Emi this was causing issues displaying the previous seasons information.
    //if (colSeries.length > 2) {
    //// swap this season for previous season
    //let temp = colSeries[0]
    //colSeries[0] = colSeries[2]
    //colSeries[2] = temp
    //}

    let conditionsChart = {
      chart: {
        type: 'column',
        height: '360px',
      },
      plotOptions: {
        series: { animation: false },
        //     column: {
        //         grouping: false,
        //         shadow: false,
        //         borderWidth: 0
        //     }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      title: {
        text: 'Breakdown of conditions',
      },
      xAxis: {
        categories: defects,
        labels: {
          formatter: function () {
            const v = inputList.find((o) => o.id === this.value);
            if (!!v) return v.name;
            else return this.value;
          },
        },
      },
      yAxis: [
        {
          min: 0,
          max: 100,
          title: {
            text: '% with defect',
          },
          minorTickInterval: 'auto',
        },
        {
          visible: false,
        },
      ],
      tooltip: {
        formatter: function () {
          console.log(this);
          const value = `${!!this.y ? this.y.toFixed(2) : 0}%`;
          const text = `${
            this.key === 'average'
              ? 'Latest season avg. with condition: '
              : '% of supplied volume with condition: '
          } <b>${value}</b>`;
          return text;
          // const {metadata} = this.point
          // if (this.key === 'average') {
          //   return `
          //     Latest Season Avg.<br/><b>${metadata?.global_avg_perc_num_1kkg_with_condition?.toFixed(2) ?? 0}%</b>`
          // }

          // if (metadata.global_avg_perc_num_1kkg_with_condition)
          //   return `
          //     Value: ${(metadata.num_1kkg_with_condition/metadata.num_1kkg_in_season*100).toFixed(2) ?? 0}%<br/>
          //   `
          //   // Severe: ${(metadata.num_1kkg_with_severe_condition/metadata.num_1kkg_in_season*100).toFixed(2) ?? 0}%
          // else
          //   return `
          //   Value: ${(metadata.num_1kkg_with_condition/metadata.num_1kkg_in_season*100).toFixed(2) ?? 0}%<br/>
          //     `
          // Severe: ${(metadata.num_1kkg_with_severe_condition/metadata.num_1kkg_in_season*100).toFixed(2) ?? 0}%
        },
      },
      series: colSeries,
    };

    let conditionsSevereChart = {
      chart: {
        type: 'column',
        height: '360px',
      },
      plotOptions: {
        series: { animation: false },
        //     column: {
        //         grouping: false,
        //         shadow: false,
        //         borderWidth: 0
        //     }
      },
      title: {
        text: 'Breakdown of <b>severe</b> conditions',
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      xAxis: {
        categories: defects,
        labels: {
          formatter: function () {
            const v = inputList.find((o) => o.id === this.value);
            // console.log("LOGG", inputList, this.value, v)
            if (!!v) return v.name;
            else return this.value;
          },
        },
      },
      yAxis: [
        {
          min: 0,
          max: 100,
          title: {
            text: '% with severe defect',
          },
          minorTickInterval: 'auto',
        },
        {
          visible: false,
        },
      ],
      tooltip: {
        formatter: function () {
          // console.log(this);
          const value = `${!!this.y ? this.y.toFixed(2) : 0}%`;
          const text = `${
            this.key === 'average'
              ? 'Latest season avg. with severe condition: '
              : '% of supplied volume with severe condition: '
          } <b>${value}</b>`;
          return text;
        },
      },
      series: colSeriesSevere,
    };

    return (
      <>
        <HighchartsReact
          // key={p as string}
          highcharts={Highcharts}
          options={conditionsChart}
          allowChartUpdate={true}
        />
        <HighchartsReact
          // key={p as string}
          highcharts={Highcharts}
          options={conditionsSevereChart}
          allowChartUpdate={true}
        />
      </>
    );
  }

  renderSummary() {
    const { supplierSummaryRanking, supplierRanking } = this.state.pgData;

    if (!supplierSummaryRanking || !supplierRanking) {
      return;
    }
    const data = supplierSummaryRanking.series;

    const volumeRank = {};
    const qualityRank = {};
    const numEntities = {};
    const seasons = ['two seasons ago', 'previous season', 'latest season'];

    for (const season of seasons) {
      if (supplierRanking && supplierRanking[season]) {
        qualityRank[season] = supplierRanking[season].quality.map((o, index) => {
          if (this.state.contactId === o.entity) {
            return index;
          }
        });
        volumeRank[season] = supplierRanking[season].volume.map((o, index) => {
          if (this.state.contactId === o.entity) {
            return index;
          }
        });
        numEntities[season] = supplierRanking[season].quality.length;
      }
    }

    return (
      <div className="summary">
        {data.map((series) => {
          const green = series.num_1kkg_4_generic_score;
          const amber = series.num_1kkg_3_generic_score;
          const red = series.num_1kkg_2_generic_score;
          const superRed = series.num_1kkg_1_generic_score;

          let optionsHC: Highcharts.Options = {
            title: {
              text: null,
            },
            chart: {
              height: '250px',
            },
            plotOptions: {
              pie: {
                animation: false,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '{point.percentage:.2f} %',
                  distance: -40,
                  filter: {
                    property: 'percentage',
                    operator: '>',
                    value: 10,
                  },
                },
              },
            },
            tooltip: {
              pointFormat: '<b>{point.y:.3f}k</b>',
            },
            series: [
              {
                type: 'pie',
                data: [
                  {
                    y: green,
                    name: 'good',
                    color: '#97D176',
                  },
                  {
                    y: amber,
                    name: 'average',
                    color: '#FFCC68',
                  },
                  {
                    y: red,
                    name: 'bad',
                    color: '#FA937A',
                  },
                  {
                    y: superRed,
                    name: 'very bad',
                    color: '#EA0206',
                  },
                ],
              },
            ],
          };

          const season = series.season;
          const seasonPeriod = series.season_period;
          const total = (series.total_num_kilos / 1000).toFixed(3);
          const orderAmount = series.num_order_ids;
          const lotAmount = series.num_lot_ids;

          return (
            <div key={season as string}>
              <div style={{ width: 250 }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsHC}
                  allowChartUpdate={true}
                />
              </div>
              <div>
                <b>{season?.toUpperCase()}</b>
                <br />
                <span>({seasonPeriod})</span>
                <br />
                total:
                <b>{parseFloat(total).toFixed(3)}k kg</b>
                {!this.props.isShared && (
                  <>
                    <br />
                    Volume rank: {volumeRank[season]?.find((a) => a != null) + 1} /{' '}
                    {numEntities[season]}
                    <br />
                    Quality rank: {qualityRank[season]?.find((a) => a != null) +
                      1} / {numEntities[season]}
                  </>
                )}
                <br /># orders: {orderAmount}
                <br /># lots: {lotAmount}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  async share() {
    this.setState({ isShareModalOpen: true });
    const contact: Contact = this.props.contacts?.find(
      (c) => c.id === this.state.contactId
    );
    const password = Math.random().toString(36).slice(-8);
    const shareObj: sharedCustomerProfile = {
      orgId: this.props.profile.organisationId,
      customerType: 'supplier',
      customerId: this.state.contactId,
      customerName: contact.name,
      password,
    };
    const res = await shareCustomerProfile(shareObj);
    this.setState({
      shareLink: `${window.location.origin}/shared-customer-profile/supplier/${
        this.state.contactId
      }/${encodeURIComponent(contact.name)}/${res.id}`,
      password: password,
    });
  }

  async modalShareSuccess(res) {
    console.log(res);
    const { supplierIds } = this.state;

    const contacts: Contact[] = this.props.contacts?.filter((c) =>
      (supplierIds ?? []).includes(c.id)
    );
    const contact: Contact = contacts?.find((c) => c.id === this.state.contactId);

    this.setState({ isShareModalOpen: false, shareLink: undefined });
    try {
      await functions.httpsCallable('sendShareCustomerProfileEmail')({
        email: res.emails,
        message: res.message,
        link: this.state.shareLink,
        password: this.state.password,
        orgName: this.props.profile.organisationId,
        contactName: contact.name,
      });
      const toast = await toastController.create({
        message: 'Email Sent',
        position: 'top',
        color: 'primary',
        duration: 3000,
        buttons: [
          {
            text: 'Got it',
            role: 'cancel',
          },
        ],
      });

      toast.present().then();
    } catch (e) {
      console.error(e);
      const toast = await toastController.create({
        message: 'Error: ' + e?.message,
        position: 'top',
        color: 'danger2',
        duration: 3000,
        buttons: [
          {
            text: 'Got it',
            role: 'cancel',
          },
        ],
      });

      toast.present().then();
    }
  }

  render() {
    const { supplierIds } = this.state;

    const contacts: Contact[] = this.props.contacts?.filter((c) =>
      (supplierIds ?? []).includes(c.id)
    );
    const contact: Contact = contacts?.find((c) => c.id === this.state.contactId);

    // let options = contacts.filter(c=>c.id.startsWith('L')).sort((a, b) => a.name.localeCompare(b.name)).map(b => {
    let options =
      (contacts ?? [])
        .filter((c) => c.type?.includes('SELLER'))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((b) => {
          return {
            value: b.id,
            label: b.id !== b.name ? `[${b.id}] ${b.name}` : b.name,
          };
        }) ?? [];

    const { showPassword } = this.state;

    return (
      <IonPage className={'page-contact-profiling ion-page'}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" color="light">
              {!this.props.profile ? (
                <Link to={'/quality-control'} className="logo">
                  <img src="/assets/icon/android-icon-192x192.png" alt="agrinorm" />
                  <IonTitle className="agrinorm">Agrinorm</IonTitle>
                </Link>
              ) : (
                <IonBackButton
                  text={i18n.t('General.back')}
                  defaultHref={'/secure/contact/' + this.props.contactId}
                  color="dark"
                >
                  <IonIcon icon={chevronBackOutline} slot="icon-only" />
                </IonBackButton>
              )}
            </IonButtons>

            <IonTitle>{contact?.name || contact?.id}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="customer-title">{this.props.contactName}</div>
          {showPassword ? (
            <div
              style={{
                display: 'flex',
                width: 400,
                gap: 10,
                alignItems: 'center',
                margin: '30px auto',
              }}
            >
              <IonInput
                style={{ borderRadius: 4 }}
                placeholder="Password"
                value={this.state.password}
                onIonChange={(e) => {
                  this.setState({ password: `${e.detail.value}` });
                }}
              ></IonInput>{' '}
              <IonButton
                onClick={async () => {
                  this.setState({ isLoadingPassword: true });
                  const response = await verifyCustomerProfilePassword(
                    this.props.sharedId,
                    this.state.password
                  );
                  this.setState({ isLoadingPassword: false });
                  if (!!response.data.isPasswordCorrect) {
                    this.setState({ showPassword: false });
                  } else {
                    alert('Wrong password');
                  }
                }}
              >
                {this.state.isLoadingPassword ? <IonSpinner /> : 'OK'}
              </IonButton>
            </div>
          ) : (
            <>
              <IonModal
                cssClass={'modal-share'}
                isOpen={this.state.isShareModalOpen}
                onDidDismiss={() => {
                  this.setState({ isShareModalOpen: false, shareLink: undefined });
                }}
              >
                <ModalShare
                  password={this.state.password}
                  contactId={this.state.contactId}
                  shareLink={this.state.shareLink}
                  title={'Share Supplier Profile'}
                  onCancel={() => {
                    this.setState({ isShareModalOpen: false, shareLink: undefined });
                  }}
                  onSuccess={async (res) => {
                    this.modalShareSuccess(res);
                  }}
                />
              </IonModal>
              <div className="wrapper">
                <div className="left">
                  {options.length > 0 ? (
                    <IonItem className="item-selectable z-13">
                      <IonLabel>Supplier</IonLabel>
                      <Select
                        classNamePrefix={'react-select'}
                        options={options}
                        defaultValue={options.find(
                          (o) => o.value === this.props.contactId
                        )}
                        name="supplier"
                        onChange={(e) => {
                          this.setState({
                            contactId: e.value,
                            selectedProduct: null,
                            selectedOrigin: null,
                            pgData: {},
                          });
                          // window.history.replaceState(null, '', `/secure/contact/${e.value}/insight`);
                          // this.props.history.replace(`/secure/contact/${e.value}/insight`, null);
                        }}
                      />
                    </IonItem>
                  ) : null}
                  <IonItem className="item-selectable z-12">
                    <IonLabel>Product</IonLabel>
                    <Select
                      classNamePrefix={'react-select'}
                      options={(this.state.products ?? [])
                        .map((o) => {
                          return { value: o, label: o };
                        })
                        .sort((a, b) => {
                          return a.label.localeCompare(b.label);
                        })}
                      name="product"
                      placeholder="Please select product"
                      value={this.state.selectedProduct}
                      onChange={(e) => {
                        // console.log(e);
                        this.setState({
                          selectedProduct: { value: e.label, label: e.label },
                          selectedOrigin: null,
                          pgData: {},
                        });
                      }}
                    />
                  </IonItem>
                  <IonItem className="item-selectable z-11">
                    <IonLabel>Origin</IonLabel>
                    <Select
                      isDisabled={!this.state.selectedProduct?.value}
                      placeholder="Please select origin"
                      classNamePrefix={'react-select'}
                      options={this.state.origins.sort().map((o) => {
                        return { value: o, label: this.renderOriginLabel(o) };
                      })}
                      name="origin"
                      value={this.state.selectedOrigin}
                      onChange={(e) => {
                        this.setState({ selectedOrigin: e, pgData: {} });
                      }}
                    />
                  </IonItem>
                  {!this.props.isShared && (
                    <IonButton color="tertiary" onClick={() => this.share()}>
                      Share
                    </IonButton>
                  )}
                </div>
                <div className="right">
                  {this.state.inited ? (
                    <>{this.renderSummary()}</>
                  ) : (
                    <IonSpinner name="dots" color="dark" className="loading-spinner" />
                  )}
                </div>
              </div>
              {!!this.state.errorMessage ? (
                <div className="error-message">{this.state.errorMessage}</div>
              ) : null}
              {this.state.inited &&
              !this.state.errorMessage &&
              Object.keys(this.state.pgData ?? {}).length > 0 ? (
                <>
                  <div className="propertiesBreakdown">
                    {this.renderPropertiesBreakdown()}
                  </div>
                  <div className="propertiesOther">{this.renderShipmentRag()}</div>
                  <div className="propertiesOther">{this.renderDefects()}</div>
                </>
              ) : null}
            </>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default withContext(withIonLifeCycle(PageSupplierProfiling), [
  'contacts',
  'products',
  'profile',
]);
