import { IonItemDivider, IonSelect, IonSpinner } from '@ionic/react';
import React from 'react';
import { InspectionReference } from '../InspectionModel';
import { getObjectTypeFromReference } from '../ServiceInspection';
import { FieldSchemaObjectTypeEnum, InspectableObjectType } from '../generated/openapi/core';
import { CInspectionTitle } from './CInspectionTitle';

// Styling
import '../PageInspection.scss';
import { useInspectionContext } from '../context/InspectionContextProvider';

export const CInspectionLoader = () => {
  const { inspectionReference } = useInspectionContext();

  if (!inspectionReference) {
    return null;
  }

  const objectType: InspectableObjectType | null =
    getObjectTypeFromReference(inspectionReference);

  return (
    <div className="content-wrapper">
      <CInspectionTitle
        objectType={objectType}
        reference={inspectionReference}
        loading={true}
      />
      <CInspectionHeaderLoader objectType={objectType} />
      <div className="loading">
        <IonSpinner name="dots" />
      </div>
    </div>
  );
};

interface LoaderProps {
  objectType: InspectableObjectType;
}
class CInspectionHeaderLoader extends React.Component<LoaderProps> {
  render() {
    const { objectType } = this.props;

    return (
      <div className="c-inspection-header">
        <div className="ion-item">
          <div className="ion-label" color="dark">
            Inspection type
          </div>
          <IonSelect placeholder={'Loading...'} disabled={true}></IonSelect>
        </div>

        {objectType !== FieldSchemaObjectTypeEnum.Field && (
          <div className="ion-item">
            <div className="ion-label" color="dark">
              Location
            </div>
            <IonSelect placeholder={'Loading...'} disabled={true}></IonSelect>
          </div>
        )}

        <div className="ion-item">
          <div className="ion-label" color="dark">
            Schema
          </div>
          <IonSelect placeholder={'Loading...'} disabled={true}></IonSelect>
        </div>

        <IonItemDivider />
      </div>
    );
  }
}
