import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { GOOGLE_MAPS_API_KEY, GPSCoordinates } from '../DataLocation';
import './CMap.scss';
import CMapSearch from './CMapSearch';

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

const mapOptions = { fullscreenControl: false };

const defaultCenter = {
  lat: 0,
  lng: 0,
};

const libraries: any = ['places'];

interface Props {
  coords?: GPSCoordinates;
  setCoords?: (c: GPSCoordinates) => void;
}

const CMap = ({ coords, setCoords }: Props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // State management
  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.geometry.location) {
      setCoords?.({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  // Render
  if (loadError) {
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div className="map-wrapper">
      <div className="floating-search">
        <CMapSearch onSelect={handlePlaceSelect} />
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coords ?? defaultCenter}
        zoom={10}
        options={mapOptions}
        onClick={(event) => {
          setCoords?.({ lat: event.latLng.lat(), lng: event.latLng.lng() });
        }}
      >
        {!!coords && <Marker position={coords} />}
      </GoogleMap>
    </div>
  );
};

export default CMap;
