import { ISO3166 } from '../HelperInsight';
import {
  Option,
  booleanOptions,
  dictToReactSelectOptions,
  selectedBooleanOption,
} from '../HelperReactSelect';
import {
  ProductVariety,
  LocationType,
  LocationTypes,
  ProductView,
  WarehouseService,
  WarehouseServices,
  ProductionTypes,
  ProductionType,
  WarehouseLocation,
  ProductionSiteLocation,
  IrrigationTypes,
  IrrigationType,
  Contact,
  FieldManagementQuality,
  FieldManagementQualities,
  Organisation,
  GrowingType,
  SubstrateType,
  GrowingMethodType,
  FieldStorageCondition,
  GrowingTypes,
  SubstrateTypes,
  GrowingMethodTypes  
} from '../Model';

interface UseLocationAdminOptions {
  locationTypeOptions: Option<LocationType>[];
  productionTypeOptions: Option<ProductionType>[];
  fieldManagementOptions: Option<FieldManagementQuality>[];
  servicesOptions: Option<WarehouseService>[];
  varietyOptions: Option<ProductVariety>[];
  countryOptions: Option[];
  booleanOptions: Option<null | boolean>[];
  irrigationSystemOptions: Option<IrrigationType>[];
  associatedPartnerOptions: Option<string | null>[];

  selectedLocationType: (t: LocationType) => Option<LocationType> | null;
  selectedProductionType: (t: ProductionType) => Option<ProductionType> | null;
  selectedFieldManagementOption: (
    t: FieldManagementQuality
  ) => Option<FieldManagementQuality> | null;
  selectedCountry: (c: string) => Option | null;
  selectedServices: (s: WarehouseService[]) => Option<WarehouseService>[];
  selectedVarieties: (v: ProductVariety[]) => Option<ProductVariety>[];
  selectedBoolean: (v: boolean) => Option<boolean | null>;
  selectedIrrigationSystems: (s: IrrigationType[]) => Option<IrrigationType>[];
  selectedAssociatedPartner: (id: string) => Option | null;

  // Requested by SF
  growingTypeOptions: Option<GrowingType>[];
  substrateTypeOptions: Option<SubstrateType>[];
  growingMethodOptions: Option<GrowingMethodType>[];
  fieldStorageConditionOptions: Option<FieldStorageCondition>[];
  timeOptions: Option<number | string>[];

  selectedGrowingType: (t: GrowingType) => Option<GrowingType> | null;
  selectedSubstrateType: (t: SubstrateType) => Option<SubstrateType> | null;
  selectedGrowingMethod: (t: GrowingMethodType) => Option<GrowingMethodType> | null;
  selectedFieldStorageConditions: (
    t: FieldStorageCondition
  ) => Option<FieldStorageCondition> | null;
  selectedTime: (t: string | number) => Option<string | number> | null;

  getLabel: (key: keyof WarehouseLocation | keyof ProductionSiteLocation) => string;
}

// We created this copy of the hook to avoid conflicts with the feature/admin branch
export const useLocationAdminOptions = (
  orgProducts: ProductView[],
  orgContacts: Contact[],
  org: Organisation
): UseLocationAdminOptions => {
  // Options
  const countryOptions = Object.entries(ISO3166)
    .map((country) => {
      return { value: country[0], label: `${country[1]} - ${country[0]}` };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const locationTypeOptions = dictToReactSelectOptions(LocationTypes);
  const productionTypeOptions = dictToReactSelectOptions(ProductionTypes);
  const fieldManagementOptions = dictToReactSelectOptions(FieldManagementQualities);
  const servicesOptions = dictToReactSelectOptions(WarehouseServices);
  const fieldVarieties: ProductVariety[] = orgProducts.flatMap((p: ProductView) =>
    ((p.agVarieties ?? []).length > 0 ? p.agVarieties : p.varieties ?? []).map(
      (v) =>
        ({
          agProductId: p.agProductId,
          variety: v,
        } as ProductVariety)
    )
  );

  const varietyOptions = fieldVarieties
    .map((v) => ({
      value: v,
      label: `${v.agProductId} - ${v.variety}`,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const irrigationSystemOptions = dictToReactSelectOptions(IrrigationTypes);

  let associatedPartnerOptions = orgContacts
    .map((c) => ({
      value: c.id,
      label: c.id.length < 8 ? `${c.name} (${c.id})` : c.name,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  associatedPartnerOptions.unshift({
    value: null,
    label: org?.name ?? 'My organization',
  });

  // Selected options
  const selectedLocationType = (locationType: LocationType) =>
    locationTypeOptions.find((o) => o.value === locationType) ?? null;
  const selectedProductionType = (productionType: ProductionType) =>
    productionTypeOptions.find((o) => o.value === productionType) ?? null;
  const selectedFieldManagementOption = (
    fieldManagementOption: FieldManagementQuality
  ) => fieldManagementOptions.find((o) => o.value === fieldManagementOption) ?? null;
  const selectedCountry = (countryCode: string) =>
    countryOptions.find((c) => c.value === countryCode) ?? null;
  const selectedServices = (services?: WarehouseService[]) =>
    servicesOptions.filter((o) => (services ?? []).includes(o.value));
  const selectedVarieties = (producedVarieties?: ProductVariety[]) =>
    varietyOptions.filter(
      (o) =>
        (producedVarieties ?? [])
          .map((v) => v.agProductId)
          .includes(o.value.agProductId) &&
        (producedVarieties ?? []).map((v) => v.variety).includes(o.value.variety)
    );
  const selectedIrrigationSystems = (is?: IrrigationType[]) =>
    irrigationSystemOptions.filter((o) => (is ?? []).includes(o.value));
  const selectedAssociatedPartner = (partnerId: string) =>
    associatedPartnerOptions.find((g) => g.value === partnerId);

  /**************** */
  // Requested by SF
  const growingTypeOptions = dictToReactSelectOptions(GrowingTypes);
  const substrateTypeOptions = dictToReactSelectOptions(SubstrateTypes);
  const growingMethodOptions = dictToReactSelectOptions(GrowingMethodTypes);
  const fieldStorageConditionOptions: Option<FieldStorageCondition>[] = [
    { value: 'ON_FIELD', label: 'On the field' },
    { value: 'IN_SHADE', label: 'In a shaded place' },
    { value: 'REFRIGERATOR', label: 'In a refrigerator' },
    
  ];
  const timeOptions: Option<number | string>[] = [
    { value: 30, label: '<30 min' },
    { value: 60, label: '<1 hr' },
    { value: 120, label: '<2 hr' },
    { value: 180, label: '<3 hr' },
    { value: 300, label: '<5 hr' },
    { value: 480, label: '<8 hr' },
    { value: 720, label: '8 hr +' },
  ];

  const selectedGrowingType = (t: GrowingType) =>
    growingTypeOptions.find((o) => o.value === t) ?? null;
  const selectedSubstrateType = (t: SubstrateType) =>
    substrateTypeOptions.find((o) => o.value === t) ?? null;
  const selectedGrowingMethod = (t: GrowingMethodType) =>
    growingMethodOptions.find((o) => o.value === t) ?? null;
  const selectedFieldStorageConditions = (t: FieldStorageCondition) =>
    fieldStorageConditionOptions.find((o) => o.value === t) ?? null;
  const selectedTime = (t: number | string) =>
    timeOptions.find((o) => o.value === t) ?? null;

  /**************** */

  // Field UI names
  const nameMap: {
    [key in keyof WarehouseLocation | keyof ProductionSiteLocation]?: string;
  } = {
    address: 'Address',
    locationType: 'Type',
    countryCode: 'Country',
    geoPoint: 'GPS coordinates',
    hasCoolingFacilities: 'Cooling facilities on site?',
    name: 'Name',
    locationId: 'Id',
    packhouseGeoPoint: 'Packhouse location',
    services: 'Services provided',
    producedVarieties: 'Varieties produced',
    productionType: 'Production type',
    fieldManagementQuality: 'Level of professionalism at the site',
    irrigationSystems: 'Irrigation systems',
    totalAreaInHa: 'Total area (ha)',
    growingAreaInHa: 'Growing area (ha)',
    associatedPartnerId: 'Location belongs to',
    growingType: 'Growing type',
    substrateType: 'Substrate type',
    growingMethodType: 'Growing method',
    maxMinutesOnFieldBeforeTransportation: 'Max. time on field before transport',
    maxPrecoolingTime: 'Max. precooling time',
    refrigeratedTransporter: 'Refrigerated transporter',
    datePlanted: 'Date planted',
    productionWeekStart: 'Start harvesting period (week)',
    productionWeekEnd: 'End harvesting period (week)',
    plantsPerHa: 'Density (plants per ha)',
    kgProducedPerPlant: 'Kg produced per plant',
    fieldStorageConditions: 'Storage conditions on field',
    refrigerationTemperature: 'Refrigeration temperature ºC'
  };

  const getLabel = (
    key: keyof WarehouseLocation | keyof ProductionSiteLocation
  ): string => {
    return nameMap[key] ?? key;
  };

  return {
    locationTypeOptions,
    productionTypeOptions,
    fieldManagementOptions,
    servicesOptions,
    varietyOptions,
    countryOptions,
    booleanOptions,
    irrigationSystemOptions,
    associatedPartnerOptions,
    selectedLocationType,
    selectedProductionType,
    selectedFieldManagementOption,
    selectedCountry,
    selectedServices,
    selectedVarieties,
    getLabel,
    selectedBoolean: selectedBooleanOption,
    selectedIrrigationSystems,
    selectedAssociatedPartner,
    // SF stuff
    growingTypeOptions,
    substrateTypeOptions,
    growingMethodOptions,
    fieldStorageConditionOptions,
    timeOptions,
    selectedGrowingType,
    selectedSubstrateType,
    selectedGrowingMethod,
    selectedFieldStorageConditions,
    selectedTime,
  };
};
