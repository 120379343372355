import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import {
  caretDownCircleOutline,
  caretUpCircleOutline,
  arrowForwardOutline,
  trailSignOutline,
} from 'ionicons/icons';
import _, { isEqual } from 'lodash';
import React from 'react';
import './CardOrder.scss';
import {
  getLotOrPosQuantity,
  resolveLocationName,
  shouldShowPositionQuantity,
} from './HelperUtils';
import { LegacyInspection, LotInspection } from './InspectionModel';
import { LotPopoverModel, LotPosition, LotTransfer, Order } from './Model';
import { Article } from './ServiceArticle';
import { InspectionClass, orderInspectionsList } from './ServiceInspection';
import ViewArticleDescription from './ViewArticleDescription';
import { ViewInspectionScore } from './ViewInspectionScore';
import ViewContactName from './ViewContactName';
import withContext, { ContextProps } from './HOC/withContext';

interface Props {
  order?: Order;
  loading?: boolean;
  showLink?: boolean;
  highlightLotId?: string;
  searchString?: string;
  routerLink?: string;
  onClick?: any;
  hideDone?: boolean;
  displayButtons?: boolean;
  transfer: LotTransfer;

  showContactPositions?: boolean;

  openNewLotModal?: (p: LotPosition, o: Order, f: () => void) => void;
  openLinkLotModal?: (p: LotPosition, o: Order, f: () => void) => void;
  onLotUnlink?: (p: LotPosition, o: Order, f: () => void) => void;
}

interface State {
  showOrders: boolean;
  currPosition: LotPosition;
  lotOptionsPopover?: LotPopoverModel;
}

class CardTransfer extends React.Component<Props & ContextProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showOrders: true,
      // showOrders: this.props.profile?.userRoles.includes("COMMERCIAL")
      currPosition: undefined,
      lotOptionsPopover: { visible: false },
    };
  }

  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    const { showOrders, lotOptionsPopover } = this.state;
    if (
      showOrders !== nextState.showOrders ||
      !_.isEqual(lotOptionsPopover, nextState.lotOptionsPopover)
    ) {
      return true;
    }

    if (!isEqual(this.props.locations, nextProps?.locations)) {
      return true;
    }

    if (!this.props.order) {
      return true;
    }

    if (this.props.order?.lastModifiedDate !== nextProps.order?.lastModifiedDate) {
      return true;
    }

    return false;
  }

  toggleOrders(e) {
    const { showOrders } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showOrders: !showOrders });
  }

  orderIcon() {
    return <IonIcon style={{ pointerEvents: 'none' }} icon={trailSignOutline} />;
  }

  getTitle(title) {
    let { searchString } = this.props;

    if (searchString && title.endsWith(searchString)) {
      return (
        <>
          {title.slice(0, title.length - searchString.length)}
          <span className="highlight">{searchString}</span>
        </>
      );
    }

    return title;
  }

  getOrderId(order: Order) {
    return order.id;
  }

  getClassName() {
    let c = '';
    const { order } = this.props;
    // if (order.list === "OUT")
    //   return

    if (
      order.qcStatus === 'OPEN' ||
      order.qcStatus === 'REDO' ||
      order.qcStatus === undefined
    ) {
      c = '';
    } else {
      c = 'completed ';
    }
    if (order.search?.scores?.has1orLess) {
      c += 'score-1';
    } else if (order.search?.scores?.has2orLess) {
      c += 'score-2';
    } else if (order.search?.scores?.has3orLess) {
      c += 'score-3';
    }
    return c;
  }

  onLotOptionsDismiss() {
    this.setState({ lotOptionsPopover: { visible: false }, currPosition: undefined });
  }

  render() {
    if (this.props.loading) {
      return (
        <IonCard className="card-order skeleton">
          <IonCardHeader>
            <IonCardTitle>
              <IonSkeletonText animated />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {[0].map((idx) => (
              <IonItem key={idx} style={{ border: 0 }}>
                <IonLabel>
                  <IonSkeletonText animated />
                </IonLabel>
              </IonItem>
            ))}
          </IonCardContent>
        </IonCard>
      );
    }

    let { order, highlightLotId, transfer } = this.props;
    let { showOrders } = this.state;

    let inspections: LegacyInspection[] = orderInspectionsList(order);
    let toggleIcon = showOrders ? caretUpCircleOutline : caretDownCircleOutline;

    const negativePositions = order.positions?.filter((p) => p.numBoxes < 0);
    const positivePositions = order.positions?.filter((p) => p.numBoxes >= 0);

    let sum = inspections?.reduce((accumulator, element) => {
      return accumulator + (new InspectionClass(element).isCompleted() ? 1 : 0);
    }, 0);

    let orderDone = sum === inspections.length - 1 && order.positions.length > 0;

    if (orderDone && this.props.hideDone === true) {
      return null;
    }

    let onClick = this.props.onClick
      ? { onClick: () => this.props.onClick(order) }
      : null;

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    // today.setDate(today.getDate()+1)

    return (
      <IonCard
        key={order.id}
        className={'card-order ' + this.getClassName()}
        // {...this.getLink()}
        {...onClick}
      >
        <IonCardHeader>
          <IonCardTitle>
            <IonIcon
              icon={toggleIcon}
              color="medium"
              className="caret"
              onClick={(e) => {
                this.toggleOrders(e);
              }}
            />

            <div className="order-id">
              {this.orderIcon()}
              <div>{this.getTitle(this.getOrderId(order))}</div>
              {/* <div title="report available">{orderDone && <IonIcon style={{"pointerEvents": "none"}} icon={documentAttachOutline} className="report-icon"/>}</div> */}
              {/* <div title="Has child batches">{order.hasSplitLot && <IonIcon style={{"pointerEvents": "none"}} icon={copyOutline} className="report-icon"/>}</div> */}
            </div>

            {/* <div className="order-info">
            <IonBadge color="medium">
              {formatDate(order.fulfilmentDate)}
            </IonBadge>
          </div> */}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonCardTitle className="contact capitalize">
            <ViewContactName report={order} />
            {order.supplierId ? (
              <span>{order.supplierName.toLocaleLowerCase()}</span>
            ) : null}
          </IonCardTitle>
          {/* DISPLAY order positions */}
          {showOrders &&
            negativePositions?.map((position: LotPosition, index) => {
              const inspection = inspections.find(
                (ass) => ass.reference?.lotId === position.lotId
              ) as LotInspection;
              return this.renderPosition(
                index,
                highlightLotId,
                position,
                inspection,
                order
              );
            })}
          {showOrders && (
            <div className="arrows">
              <div>
                {resolveLocationName(transfer.dispatchLocationId, this.props.locations)}
              </div>
              <IonIcon icon={arrowForwardOutline} />
              <div>
                {resolveLocationName(transfer.locationId, this.props.locations)}
              </div>
            </div>
          )}
          {showOrders &&
            positivePositions?.map((position: LotPosition, index) => {
              const inspection = inspections.find(
                (ass) => ass.reference?.lotId === position.lotId
              ) as LotInspection;
              return this.renderPosition(
                index,
                highlightLotId,
                position,
                inspection,
                order
              );
            })}
        </IonCardContent>
        {/* {order.locationId && <div className="location">Location: {resolveLocationName(order.locationId, this.props.applicationContext)}</div>} */}
      </IonCard>
    );
  }

  renderPosition(
    index: number,
    highlightLotId: string,
    position: LotPosition,
    inspection: LotInspection,
    order: Order
  ): JSX.Element {
    const isContactOrder: boolean = false;
    const showLink: boolean = !position.lotId.endsWith(highlightLotId);

    const { profile, organisation } = this.props;

    let ionItemProps: any = {
      key: index,
      className:
        highlightLotId && position.lotId.endsWith(highlightLotId) && 'highlight',
    };
    if (showLink)
      ionItemProps.routerLink = `/secure/${order.orgId}/lot/${position.lotId}`;

    const articleClass = new Article(position?.article);

    const showQuantity = shouldShowPositionQuantity(position);
    const quantity = getLotOrPosQuantity(position);

    return (
      <IonItem {...ionItemProps} detail={false}>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-padding-end col-name">
              <div className="lot-info">{position.lotId}</div>
              <div>
                <ViewArticleDescription
                  growerId={position.growerId}
                  pills={true}
                  // quantity={position.quantity}
                  article={position.article}
                />
                <IonBadge
                  mode="md"
                  className={
                    'status ' + inspection.status.replace(/ /g, '-').toLowerCase()
                  }
                >
                  {!['OPEN', 'COMPLETED'].includes(inspection.status) &&
                    inspection.status}
                </IonBadge>
              </div>
            </IonCol>
            <IonCol size="auto" className="col-scoring">
              {new InspectionClass(
                order.lotInspectionMap?.[position.lotId]
              ).isCompleted() &&
                !isContactOrder && (
                  <ViewInspectionScore
                    organisationSettings={organisation?.settings}
                    profile={profile}
                    inspection={inspection}
                    order={order}
                    displayType={'CARD-ORDER'}
                  />
                )}

              {showQuantity && (
                <IonBadge color="light-medium" className="quantity">
                  <b>{quantity}</b>
                  <div>{articleClass.getUnit(quantity)}</div>
                </IonBadge>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    );
  }
}

export default withContext(CardTransfer, ['locations', 'profile', 'organisation']);
