import { OrganisationSettings, UserProfile } from './Model';
import { compact, filter, find, findIndex, get, map } from 'lodash';
// import {AGTabs, agTabsReports, agTabsReportsSurveyer, FilterType} from "./ModelAGTabs";
import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './ViewTabSelector.scss';
import { saveUserTabs } from './DataStorage';
import { firestore } from './ConfigFirebase';
import Select from 'react-select';
import { swapHeadWithValueAtIndex } from './HelperUtils';
import { AGTabs } from './ModelAGTabs';

interface Props {
  profile: UserProfile;
  profileTabProperty: string;
  defaultTabs: AGTabs<any>;
  organisationSettings: OrganisationSettings;
}

export const ViewTabSelector = ({
  profile,
  profileTabProperty,
  defaultTabs,
  organisationSettings,
}: Props) => {
  const { showTodos } = organisationSettings ?? {};

  const displayReportTabs: any[] = compact(get(profile, `${profileTabProperty}`));

  const [agTabs, setAGTabs] = useState(
    map(defaultTabs, (tab) => {
      return {
        id: tab.id,
        label: tab.label,
        checked: false,
      };
    })
  );

  useEffect(() => {
    const profileHasDisplayAGTabs = get(profile, `${profileTabProperty}`);
    if (profileHasDisplayAGTabs) {
      const tabs = map(agTabs, (tab) => {
        let checked = !!find(displayReportTabs, (id) => tab.id === id);
        return {
          ...tab,
          checked,
        };
      });
      setAGTabs(tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const checkBoxHandler = async (e, id) => {
    const tabs = [...agTabs];
    const tabIndex = findIndex(agTabs, (tab) => tab.id === id);
    if (tabIndex !== -1) {
      tabs[tabIndex].checked = e.target.checked;
    }
    //setAGTabs(tabs);
    const tabsToSave = filter(tabs, (tab) => tab.checked).map((tab) => tab.id);
    console.log('TABS TO SAVE LENGTH ', tabsToSave.length);
    if (tabsToSave.length > 0) {
      const profileHasDisplayAGTabs = get(profile, `${profileTabProperty}`);
      if (profileHasDisplayAGTabs) {
        console.log(`id: ${id} profile.displayReportTabs[0]: ${displayReportTabs[0]}`);
        if (id !== displayReportTabs[0]) {
          swapHeadWithValueAtIndex(tabsToSave, displayReportTabs[0]);
        }
      }

      await saveUserTabs(firestore, profile, tabsToSave, profileTabProperty);
    } else {
      // window.alert('At least one tab must be selected');
      tabs[tabIndex].checked = !e.target.checked;
      setAGTabs([...tabs]);
    }
  };

  const defaultSelectOption = (): { label: string; value: any } => {
    const path = `${profileTabProperty}[0]`;
    const displayAGTab = get(profile, path);
    const agTab = find(agTabs, (agTab) => agTab?.id === displayAGTab);
    return { value: agTab?.id, label: agTab?.label };
  };

  const handleSelectOnChange = async (e) => {
    const tabs = [...displayReportTabs];
    console.log('handleSelectOnChange', tabs);
    if (tabs.length >= 2) {
      // const tabIndex = findIndex(tabs, tab => tab === e.value)
      // const headTabs = head(tabs)
      // tabs[0] = tabs[tabIndex];
      // tabs[tabIndex] = headTabs;
      swapHeadWithValueAtIndex(tabs, e.value);
      await saveUserTabs(firestore, profile, tabs, profileTabProperty);
    }
  };

  const selectOptions = displayReportTabs?.map((tab) => {
    const agTab = find(agTabs, (agTab) => agTab?.id === tab);
    return { value: agTab?.id, label: agTab?.label };
  });

  // const defaultSelectOption = get(profile, 'displayReportTabs[0]');
  return (
    <div className="view-qc-tabs-selector">
      <span className={'view-qc-tabs-selector_checkbox-group'}>
        <IonLabel className={'ion-text-wrap view-qc-tabs-selector_checkbox-label'}>
          <b>Enable/Disable tabs:</b>
        </IonLabel>

        {agTabs
          ?.filter((tab) => (showTodos ? true : tab.id !== 'STOCK'))
          .map((tab) => (
            <IonItem key={tab.id} className={'view-qc-tabs-selector_checkbox-item'}>
              <IonCheckbox
                checked={tab.checked}
                className={''}
                slot="start"
                color="primary"
                onClick={(e) => checkBoxHandler(e, tab.id)}
              />
              <IonLabel className="" slot="start">
                {tab.label}
              </IonLabel>
            </IonItem>
          ))}
      </span>
      <span className="view-qc-tabs-selector__default-tab">
        <IonLabel>
          <b>Select the default tab:</b>
        </IonLabel>
        <br />
        <Select
          classNamePrefix={'react-select'}
          value={defaultSelectOption()}
          options={selectOptions}
          onChange={(e) => handleSelectOnChange(e)}
        />
      </span>
    </div>
  );
};
