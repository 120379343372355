// https://stackoverflow.com/questions/71633646/how-do-i-fix-this-error-uncaught-rangeerror-maximum-call-stack-size-exceeded-a
// import {v4} from uuid instead of import {uuid} from "uuidv4"
import { v4 as uuid } from 'uuid';

import firebase from 'firebase/compat/app';
import { cloneDeep } from 'lodash';
import { standardizeDate } from './HelperUtils';
import { QCFilterType, ReportFilterType } from './ModelAGTabs';
import { InsightWidget, TimeResolution } from './ModelInsight';
import { OldCriteria, Subsection } from './ModelSpecification';
import { StatusSubscription } from './ModelSubscriptions';
import { AGSavedFilters, Search } from './SearchService';

import {
  LotInspection,
  LegacyInspectionReference,
  InspectionScore,
  TransitInspection,
  Inspection,
  ProdSiteInspectionPreview,
} from './InspectionModel';
import { ProductTiers } from './PermissionsService';
import {
  InspectableObjectType,
  InspectionType,
  PackagingType,
  QuestionSpec,
  QuestionSpecIn,
  SubsectionItemIn,
} from './generated/openapi/core';
import { Barcode } from 'scandit-sdk';

export const AG_QUESTION_GROUP_NAME = 'Overview';
export const AG_QUESTION_GROUP_ID = 'HEADER';

export interface MultiPictureSelection {
  pictures: string[];
  // pictureType?: PictureType;
  showRemovePictureAlert: boolean;
  chooseDefects: boolean;
  conditions: [];
}

export interface OfflineSyncingStatus {
  entity: string;
  progress: number;
}

export interface PreloadDataEntities {
  lotIds: string[];
  orderIds: string[];
}

export interface AlertModel {
  show: boolean;
  header?: string;
  subheader?: string;
  message?: string;
  okHandler: (value: any) =>
    | boolean
    | void
    | {
        [key: string]: any;
      };
  inputs?: any[];
  confirmText?: string;
  confirmCssClass?: string;
}

/** Domain Model **/
export const uuid4 = uuid;

export type Todo = {
  id: string;
  uid: string;
  date: number;
  dateCompleted?: number;
  isCompleted: boolean;
  tasks: Task[];
  search?: Search;
};

export type Task = {
  lotId: string;
  isCompleted: boolean;
  transfers: LotTransfer[];
  action: 'STOCK_INSPECTION' | 'INCOMING_INSPECTION';
  article: IArticle;
  dateSinceLastInspection: number;
  scores: { [key in string]: InspectionScore };
  location: string;
  locationReference1?: string;
  locationReference2?: string;
  palletIds: string[];
};

export interface User {
  id: string;
  name?: string;
  email: string;
  image?: string;
  phoneNumber?: string | null;
  organisationId: string;
  isAgrinormTestUser?: boolean;
}

export interface FABButtonModel {
  display: boolean;
  text: any;
  onClick: (...args) => any;
  icon?: string;
  color?: string;
  disabled?: boolean;
}

/***************/
/* PERMISSIONS */
/***************/
export type UserRoleType = 'MEMBER' | 'QC_HERO' | 'COMMERCIAL';

export type PermissionAction =
  | 'VIEW'
  | 'WRITE'
  | 'REVERT'
  | 'UPLOAD'
  | 'SPLIT'
  | 'SHARE_INT'
  | 'SHARE_EXT'
  | 'CREATE'
  | 'LINK'
  | 'DELETE'
  | 'DO';
export type PermissionResource =
  | 'LOT'
  | 'ORDER'
  | 'ASSESSMENT'
  | 'REPORT'
  | 'QC_LOT_STATUS'
  | 'QC_ORDER_STATUS'
  | 'PICTURE'
  | 'DOCUMENT'
  | 'SPECIFICATIONS'
  | 'COMMERCIAL_LOT_STATUS'
  | 'COMMERCIAL_ORDER_STATUS'
  | 'INSIGHT_PROFILES'
  | 'INSIGHT_QC'
  | 'INSIGHT_EXPLORE'
  | 'ADMIN'
  | 'ADMIN_PARTNERS'
  | 'DASHBOARD'
  | 'SURVEYING';
export interface UserPermission {
  action: PermissionAction;
  resources: PermissionResource[];
  context?: PermissionContext;
}
export interface PermissionContext {
  stage?: InspectionType;
  locationId?: string;
  agProductId?: string;
}

export interface UserProfile {
  products?: string[];
  productSubscriptions?: [];
  statusSubscriptions?: StatusSubscription[];
  id: string;
  name?: string;
  email: string;
  image?: string;
  phoneNumber?: string | null;
  allowedLocation?: string;
  //userRoles: UserRoleType[];

  unreadConversationsCount?: number;

  filters?: { [filterName: string]: AGSavedFilters };

  userRole?: UserRoleType;
  userPermissions?: UserPermission[];

  properties?: UserProperties;
  organisationId: string;
  isDoingTutorial?: boolean;
  isAdmin?: boolean;
  displayAGTabs?: QCFilterType[];
  displayReportTabs?: ReportFilterType[];

  userInsights?: { [key: string]: UserInsight[] };
  userInsightsDefaultList?: string;

  intercomUserHash?: string;
}

// export class UserProfile {
//   products?: string[];
//   productSubscriptions?: [];
//   statusSubscriptions?: StatusSubscription[];
//   id: string;
//   name?: string;
//   email: string;
//   image?: string;
//   phoneNumber?: string | null;
//   allowedLocation?: string;
//   //userRoles: UserRoleType[];

//   userRole?: UserRoleType;
//   userPermissions?: UserPermission[];

//   properties?: UserProperties;
//   organisationId: string;
//   isDoingTutorial?: boolean;
//   isAdmin?: boolean;
//   displayAGTabs?: QCFilterType[];
//   displayReportTabs?: ReportFilterType[];

//   userInsights?: { [key: string]: UserInsight[] };
//   userInsightsDefaultList?: string;

//   isAgrinorm() {
//     return this.email.includes('agrinorm')
//   }
// }

/*************/
/* HANDSHAKE */
/*************/
type CollaborationOption = 'EXCHANGE_REPORTS';

export interface HandShake {
  collaboration: CollaborationOption[];
  orgId: string;
}

/************************/

export interface UserInsight {
  report: InsightWidget;
  entities: { [key: string]: string };
  timeResolution: TimeResolution;
  comparisonEntities?: string[];
  comparisonField?: string;
  customName?: string;
}

export interface UserProperties {
  language: string;
  flagAutoEditImage: boolean;
  updatedDate: any;
}

export interface Country {
  name: string;
  code1: string;
  code2: string;
}

export interface PartialEntity {
  lastModifiedDate?: any;
  lastSystemDate?: any;
  lastModifiedUserId?: string;

  // for CSV export
  firstQCDate?: any;
  lastQCDate?: any;

  lastWriteSource?: WriteSource;
}

const writeSources = ['UI', 'IMPORT_API', 'MIGRATION'] as const;
type WriteSource = (typeof writeSources)[number];

export interface BaseEntity extends PartialEntity {
  id: string;
}

export type InviteStatus = 'PENDING' | 'ACCEPTED' | 'REJECTED';

export interface Invite {
  id: string;
  invitingOrganisationId: string;
  invitingContactOrganisationId: string;
  invitingContactOrganisationName: string;
  invitingContactId: string;
  invitingContactName: string;
  invitingUserId: string;
  invitingEmail: string;

  acceptingEmail: string;

  acceptingOrganisationId?: string;
  acceptingUserId?: string;
  acceptingContactId?: string;

  products?: string[];
  orgType?: string[];

  status: InviteStatus | undefined;
}

export interface UserInvite {
  email: string;
  name: string;
  orgId: string;
  role: UserRoleType;
  code: string;
  created: boolean;
  isAdmin: boolean;
  inviteSentDate: Date;
  inviteAcceptedDate?: Date;
}

// TODO: check if we need both
export interface Product extends BaseEntity {
  name?: string;

  description?: string;

  imageId?: string;

  varieties: string[];
  packaging: string[];
  brands: string[];
}

// ProductView is the interface used by the organisation specific product collection
export interface ProductView {
  productId: string;
  agProductId: string;

  varieties?: string[];
  agVarieties?: string[];

  packaging?: string[];

  brands?: string[];
  origins?: string[];
  suppliers?: string[];
  customers?: string[];
}

export interface ProductMapping extends BaseEntity {
  companyId: string;
  displayedName?: string;

  // rest of the fields come from BaseEntity + PartialEntity
}

export interface VarietyMapping extends BaseEntity {
  companyId: string;
  displayedName?: string;

  companyProductId: string;
  productId: string;
  // rest of the fields come from BaseEntity + PartialEntity
}

export interface IArticle {
  id?: string;
  description?: string; // Optional description. Used by ExternalAPI.
  productName?: string;

  productId: string;
  agProductId?: string;

  variety?: string;
  agVariety?: string;

  size?: string;
  category?: string;
  origin?: string;
  brand?: string;
  isOrganic?: boolean;

  avgPieceWeightInGrams?: number;

  // merge flag only used for the import API
  merge?: boolean;

  // NEW ARTICLE FIELDS (DEV-818)
  packagingType: PackagingType;
  isBio?: boolean;

  // packaging representation for special cases (e.g. SF's "CO")
  extPackagingRepr?: string;

  // Required CONSUMER_UNITS_X input parameter
  numConsumerUnitsInBox?: number;

  // Required CONSUMER_UNITS_WEIGHT input parameter
  consumerUnitWeightInGrams?: number;
  // Required CONSUMER_UNITS_PIECES input parameter
  consumerUnitNumberOfPieces?: number;

  // Required BULK input parameters and CONSUMER_UNITS_X computed parameter
  boxNetWeightInKg?: number;

  // Optional input parameter for both BULK and CONSUMER_UNITS
  boxGrossWeightInKg?: number;
}

// export const packagingTypesMap = {
//   'Bulk': 'BULK',
//   'Consumer units (weight)': 'CONSUMER_UNITS_WEIGHT',
//   'Consumer units (pieces)': 'CONSUMER_UNITS_PIECES',
//   'Raw': 'RAW'
// } as const;

// export type PackagingType = typeof packagingTypesMap[keyof typeof packagingTypesMap];

export const articleParamMap = {
  agProductId: 'Product',
  auxProductId: 'Auxiliar product',
  agVariety: 'Variety',
  size: 'Size',
  extPackagingRepr: 'Packaging',
  boxNetWeightInKg: 'Box net weight (Kg)',
  boxGrossWeightInKg: 'Box gross weight (Kg)',
  numConsumerUnitsInBox: 'Num. consumer units in box',
  consumerUnitNumberOfPieces: 'Pieces per consumer unit',
  consumerUnitWeightInGrams: 'Consumer unit weight (g)',
  avgPieceWeightInGrams: 'Avg. piece weight (g)',
  origin: 'Origin',
  brand: 'Brand',
  packed: 'Packed?',
  certificates: 'Certificates',
  description: 'Description',
  numConsumerUnits: 'Number of consumer units',
  isOrganic: 'Is organic?',
  isBio: 'Is bio?',
};

export const articlePackagingFields: string[] = [
  'avgPieceWeightInGrams',
  'numConsumerUnitsInBox',
  'consumerUnitNumberOfPieces',
  'consumerUnitWeightInGrams',
  'boxGrossWeightInKg',
  'boxNetWeightInKg',
];

// This is how the IArticle interface should look after cleaning old fields (DEV-818)
export interface NewIArticle {
  id?: string;
  productName?: string;

  productId: string;
  agProductId?: string;

  variety?: string;
  agVariety?: string;

  packagingType: PackagingType;

  // Required CONSUMER_UNITS_X input parameters
  numConsumerUnitsInBox?: number;
  // Required CONSUMER_UNITS_WEIGHT input parameter
  consumerUnitWeightInGrams?: number;
  // Required CONSUMER_UNITS_PIECES input parameter
  consumerUnitNumberOfPieces?: number;

  // Required BULK input parameters and CONSUMER_UNITS_:X computed parameter
  boxNetWeight?: number;

  // Optional input parameter for both BULK and CONSUMER_UNITS
  boxGrossWeight?: number;

  size?: string;
  category?: string;
  origin?: string;

  brand?: string;

  isOrganic?: boolean;
  isBio?: boolean;

  avgPieceWeightInGrams?: number;

  // merge flag only used for the import API
  merge?: boolean;
}

// Old article for reference (DEV-818)
export interface OldArticle {
  id?: string;
  productName?: string;

  productId: string;
  agProductId?: string;

  variety?: string;
  agVariety?: string;

  packaging?: string;
  size?: string;
  category?: string;
  origin?: string;
  grossWeight?: number;
  netWeight?: number;
  brand?: string;
  packed?: boolean;
  certificates?: string[];
  description?: string;
  auxProductId?: string;
  numConsumerUnits?: number;
  isOrganic?: boolean;
  avgPieceWeight?: number;

  // merge flag only used for the import API
  merge?: boolean;

  // TODO: Emi, should this go here?
  pickingDate?: any;
  supplier?: string;

  lastModifiedDate?: any;
}

export interface Organisation extends BaseEntity {
  id: string;
  name: string;
  orgType?: orgTypes[];
  address1?: string;
  address2?: string;
  postalCode?: string;
  countryCode?: string;
  createdById?: string;
  createdByEmail?: string;
  settings?: OrganisationSettings;
  creationDate?: Date;
}

export type orgTypes = 'SELLER' | 'BUYER' | 'GROWER' | 'LOGISTICS';

export type ContactType =
  | 'SELLER'
  | 'BUYER'
  | 'OTHER'
  | 'GROWER'
  | 'CARRIER'
  | 'SURVEYOR'
  | 'SURVEYED';

export interface Contact extends BaseEntity {
  name: string;
  address?: string;
  countryCode?: string;
  type?: ContactType[];

  imageId?: string;
  contactEmails?: string[];
  lastContactsUsed?: string[];
  contactGroups?: {};
  insights?: InsightText[];

  ggn?: string;

  /** Autocalculated values do not modify **/
  organisation?: Organisation;
  users?: User[];
  contacts?: any[];
}

export function dateToString(lastModified: any) {
  let date;
  if (lastModified === undefined || lastModified === null) {
    date = new Date();
  } else if (!!lastModified.toDate) {
    date = lastModified.toDate();
  } else {
    date = new Date(lastModified);
  }
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    date = new Date();
  }
  return date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
}

/*  SCORING */

export type Example = { [key: string]: AGScore };
export type AGScore = 1 | 2 | 3 | 4 | 5 | -1;
export type Convergence = { [key in AGScore | 0]?: number };
export type SeverityDict = { [key: string]: QuestionSeverity };

export const scoreLabels: { [score in AGScore]?: string } = {
  1: 'Very bad',
  2: 'Bad',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};

export interface ScoringContext {
  [key: string]: ScoringContextValue;
}

export interface ScoringContextSlim {
  [combination: string]: ScoringContextValueSlim;
}

export interface ScoringContextValue {
  label: AGScore | null;
  deduction: AGScore[] | AGScore;
  prediction: AGScore | null;
  label_order: number;
  example?: Example;
}

export interface ScoringContextValueSlim {
  l: AGScore | null;
  d: AGScore[] | AGScore;
  p: AGScore | null;
  o: number;
  e?: Example;
}

export interface NextQuery {
  combination: string;
  possibleScores: AGScore[];
}

export interface Sample {
  example: Example;
  score: AGScore;
}

export interface CombinationSample {
  combination: string;
  score: AGScore;
}

export interface DefectInfo {
  id: string;
  name: string;
  inputSpace: AGScore[];
  thresholds: { [key in AGScore]?: string[] };
  severity: QuestionSeverity;
}

/* */

export interface OrderLink extends PartialEntity {
  id?: string;
  organisationId?: string;
  validLink: boolean;
  order?: Order;
  // key the is company's lot id; value is the customer's lot id
  positionsMap?: { [key: string]: string };
}

export interface Order extends BaseEntity {
  orgId: string;

  shippingReference?: string;
  // this is a counterparty reference; in case of buy orders, this is the supplier's outgoing order id; if it's a sell
  // order, it's the customer's incoming order id
  externalReference?: string;

  type: OrderType;

  // TODO: this is set to optional for now because a lot of functions that get either a report or an order as argument
  // would otherwise explode. This must be properly refactored later
  qcRelevant?: boolean;

  contactId?: string;
  contactName?: string;

  growerContactId?: string;

  supplierId?: string;
  supplierName?: string;

  bookingDate?: Date;
  fulfilmentDate?: Date;
  estimatedArrivalDate?: Date;

  // TODO: migrate qcStatus and related to "orderHandleStatus" or similar
  qcStatus?: OrderAGStatus;
  lastQCStatusDate?: Date;
  lastQCStatusUserId?: string;

  // contactOrder?: OrderLink;

  origin?: string;
  // sharedTo?: SharedTo[];

  positions: LotPosition[];

  lotInspectionMap?: { [key in string]: LotInspection };
  transportInspectionMap?: { [key in string]: TransitInspection };

  insight?: InsightText;

  search?: Search;

  comments?: string;

  metadata?: any;

  // When the sublot is linked to an order, we specify the order's id in this field
  splitLotOrderIdLink?: string;
  hasSplitLot?: boolean;

  locationId?: string;
  dispatchLocationId?: string;

  transport?: TransportInformation;

  attachments?: string[];

  // These 2 fields can only be present in Production orders
  reason?: TransformationReason;
  wasteVolumeInKg?: number;

  // Reports directly generated from this order
  reportReferences?: ReportReference[];
  latestReportReference?: ReportReference;
  hasReportDraft?: boolean;

  // indicates that the order was created in our system (we are the "ERP")
  isAGMaster?: boolean;

  price?: Money;
}

// TODO: for now a 1:1 copy of the Order interface
export interface Report extends BaseEntity {
  inspectionAggregation?: string;
  orgId: string;
  createdByOrgId?: string; // TODO: in the next migration, orgId has to go ansd this will replace the orgId

  createdByUserId?: string;

  hideScores?: boolean;
  hideInspector?: boolean;
  hideQuantity?: boolean;
  hideDefects?: boolean;
  reportSubject?: string;

  // TODO: these we want to remove/put into transport
  internalReference?: string;
  shippingReference?: string;
  externalReference?: string;

  // TODO: separate order vs report statuses
  status?: OrderStatus;

  qcStatus?: OrderAGStatus;
  reportStatus?: ReportStatus;

  contactId?: string;
  contactName?: string;

  growerContactId?: string;

  supplierId?: string;
  supplierName?: string;

  bookingDate?: Date;
  fulfilmentDate?: Date;

  // contactOrder ?: OrderLink;

  origin?: string;
  sharedTo?: SharedTo[];

  positions: LotPosition[];

  lotMap?: Lot[];

  lotInspectionMap?: { [key in string]: LotInspection };
  transportInspectionMap?: { [key in string]: TransitInspection };

  insight?: InsightText;

  search?: Search;

  // searchbox ?: string[];
  // searchProducts ?: string[];
  // searchSuppliers ?: string[];
  // searchVarieties ?: string[];
  // searchBrands ?: string[];

  comments?: string;

  metadata?: any;

  // When the sublot is linked to an order, we specify the order's id in this field
  splitLotOrderIdLink?: string;
  hasSplitLot?: boolean;

  locationId?: string;
  locationGeoPoint?: firebase.firestore.GeoPoint;

  // this is order related info, should it be here?
  estimatedDepartureDate?: Date;
  estimatedFulfilmentDate?: Date;

  // dispatchLocationId?: string;

  transport?: TransportInformation;

  attachments?: string[];

  // feedback when sharing
  feedbackMessage?: string;
  // sharedByUser
  sharedBy?: UserProfile;
  emails?: string[];

  reportType?: ReportType;
  reportReference?: ReportReference;
  reportName?: string; // optional field to allow the user to override the report's name

  autogenFromOrder?: boolean;
}

export interface SharedReport extends Report {
  orgSettings: {
    scoreMappings?: { [key: string]: CompanyScoreMapping[] };
    orderSummary: any;
    scoreSpace: any;
    qualityScores: any;
    inspectionScoresOrder: {
      [index: string]: {
        id: string;
        name: string;
      };
    };

    sharedReportDisplaySettings: {
      lastModifiedDate: boolean;
      sharedBy: boolean;
      inspectedBy: boolean;
    };
  };
  emailSent?: boolean;
}

export type ReportStatus = 'CREATED' | 'SHARED';

export type ReportType =
  | 'UPCOMING'
  | 'INCOMING'
  | 'STOCK'
  | 'OUTGOING'
  | 'FEEDBACK'
  | 'CUSTOM'
  | 'PROMO'
  | 'OTHER'
  | 'POST_HARVEST'
  | 'POST_PRODUCTION'
  | 'POINT_OF_SALE'
  | 'PRE_HARVEST'
  | 'QUALITY_EVOLUTION';

export type ReportReference = {
  reportId: string;
  type: ReportType;
  orgId: string;
  sharingDate?: Date;
  reportStatus: ReportStatus;

  orderId?: string;
  orderIdLinked?: boolean;

  externalOrderId?: string;

  autogenFromOrder: boolean;

  reportName?: string;
  lastModifiedDate?: Date;
  lastModifiedUserId?: string;
  createdByUserId?: string;
  lastModifiedUserEmail?: string;
};

export type OldReportReference = {
  reportId: string;
  type: ReportType;
  orgId: string;
  sharingDate: Date;
  reportStatus: ReportStatus;
  orderId?: string;
  externalOrderId?: string; // TODO: remove
  reportName?: string;
  lastModifiedDate?: Date;
  lastModifiedUserId?: string;
  lastModifiedUserEmail?: string;
};

export interface TransportInformation {
  transportType?: TransitType;
  transportReference?: string;
  vessel?: string;
  container?: string;
  truckReference?: string;
  carrier?: string;
  isRefrigerated?: boolean;
  isCA?: boolean;
}

export function updateAggregateModificationData(
  userId: string,
  partialEntity: PartialEntity,
  modificationDate?: any
) {
  // when the update comes from an orderSave from a trigger
  // we dont update the aggregate
  // profile comes with only organisationId setted up
  if (!userId) {
    return;
  }
  if (modificationDate) {
    if (!isNaN(modificationDate)) {
      partialEntity.lastModifiedDate = new Date(modificationDate);
    } else if (modificationDate.seconds) {
      partialEntity.lastModifiedDate = new Date(modificationDate.seconds * 1000);
    } else if (modificationDate._seconds) {
      partialEntity.lastModifiedDate = new Date(modificationDate._seconds * 1000);
    } else {
      partialEntity.lastModifiedDate = modificationDate;
    }
  } else if (userId !== 'system' || !partialEntity.lastModifiedDate) {
    partialEntity.lastModifiedDate = new Date();
  }
  // partialEntity.lastSystemDate = serverTimeStampFunction();

  // TODO: save timezone
  // https://medium.com/@vivekmadurai/how-to-deal-with-date-and-time-across-time-zones-39b1bd747f35
  // --------------------------------
  // get timezone string data ex: Europe/Zurich
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // get offset in minutes from UTC ex: -60
  // const date = new Date();
  // const offset = date.getTimezoneOffset();

  // update the last write source
  partialEntity.lastWriteSource = userId === 'system' ? 'IMPORT_API' : 'UI';

  // console.log("UPDATING DATE", "entity:", JSON.stringify(partialEntity), "userId", userId)

  partialEntity.lastSystemDate = new Date();
  if (userId !== 'system' || !partialEntity.lastModifiedUserId) {
    partialEntity.lastModifiedUserId = userId;
  }
}

export type TransitType = 'ROAD' | 'AIR' | 'SEA';

export interface SharedTo {
  sharedReportId?: string;
  emails?: string[];
  users?: User[];
  sharedBy: User;
  message: string;
  date: firebase.firestore.Timestamp;
}

export function removeSublotsFromOrder(order: Order): Order {
  const copyOrder = cloneDeep(order);

  const positions = copyOrder.positions.filter(
    (p) => !(!!p.motherLotIds && p.motherLotIds?.length > 0)
  );
  const lotInspectionMap = {};
  Object.keys(copyOrder.lotInspectionMap ?? {}).forEach((lotId) => {
    if (positions.map((l) => l.lotId).includes(lotId)) {
      lotInspectionMap[lotId] = copyOrder.lotInspectionMap[lotId];
    }
  });
  return { ...copyOrder, positions, lotInspectionMap };
}

export function copyOrderForSharing(
  order: Order | Report,
  contact: Contact,
  removeSublots: boolean = false
): Order {
  let copyOrder: Order = cloneDeep(order as Order);

  if (removeSublots) {
    copyOrder = removeSublotsFromOrder(copyOrder);
  }

  // we only share the reports if order is shared or if it's surveyor/surveyed:
  if (
    ['SHARED'].includes(order.qcStatus) ||
    contact == null || // case when being called from addOrderMessage
    // || (contact.type.includes('SELLER') && order.type === 'BUY')
    contact?.type.includes('SURVEYOR') ||
    contact?.type.includes('SURVEYED')
  ) {
    // keep the assessments!
  } else {
    copyOrder.lotInspectionMap = {};
    copyOrder.transportInspectionMap = {};
  }

  copyOrder.contactName = contact?.name;

  return copyOrder;
}

export function orderId(type: OrderType) {
  const date = new Date();
  return (
    (type === 'BUY' ? 'IO-' : 'SO-') +
    date.getFullYear().toString().slice(2) +
    '-' +
    (date.getMonth() + 1) +
    date.getDate() +
    date.getTime().toString().slice(8)
  );
}

export function createNewEmptyOrder(
  payload: OrderCreatePayload,
  profile: UserProfile
): Order {
  const date = new Date();
  const order: Order = {
    id: payload.id ?? orderId(payload.type),
    type: payload.type,
    qcStatus: 'OPEN',
    positions: [],
    bookingDate: standardizeDate(payload.bookingDate),
    lastModifiedDate: date,
    transportInspectionMap: {},
    lotInspectionMap: payload.lotInspectionMap ?? {},
    qcRelevant: true,
    orgId: profile.organisationId,
    fulfilmentDate: standardizeDate(payload.fulfilmentDate),
    contactId: payload.contactId,
    contactName: payload.contactName,
    estimatedArrivalDate: standardizeDate(payload.estimatedArrivalDate),
    isAGMaster: payload.isAGMaster,
  };

  return order;
}

export interface Money {
  quantity: number;
  currency: string;
}

// ***************** //
//  LOT
// ***************** //
export interface Lot extends BaseEntity {
  orgId?: string;
  suppliedByContactId?: string;

  article?: IArticle;

  emergenceDate?: Date;

  inspections?: LotInspection[];
  latestInspection?: LotInspection;

  transfers: LotTransfer[];

  transient?: LotTransientProps;
  origin?: LotOriginProps;
  previousOrg?: LotPreviousOrgProps;

  search?: Search;

  // Only present in archived lots
  deletionInfo?: DeletionInfo;

  // indicates that the lot was created in our system (we are the "ERP")
  isAGMaster?: boolean;
}

export interface DeletionInfo {
  deletedByUserId: string;
  deletionDate: Date;
  deletedFrom: string;
  originalPath: string;
}

export function LotToPosition(lot: Lot): LotPosition {
  if (!lot) {
    return undefined;
  }
  const position: LotPosition = {
    lotId: lot.id,
    numBoxes: lot.transient?.numBoxes ?? 0,
    article: lot.article,
    growerId: lot.origin?.growerContactId,
    ggn: lot.origin?.growerGGN,
    ggns: !!lot.origin?.growerGGNs
      ? lot.origin?.growerGGNs
      : !!lot.origin?.growerGGN
      ? [lot.origin?.growerGGN]
      : undefined,
    palletIds: lot.transient?.palletIds,
  };

  const splitLotTransfer = lot.transfers.find((t) => t.fromLots?.length > 0);
  if (!!splitLotTransfer) {
    position.motherLotIds = splitLotTransfer.fromLots.map((l) => l.lotId);
  }

  return position;
}

export interface LotUpdateObject extends Lot {
  'transient.palletIds'?: string[];
  'transient.numBoxes'?: number;
  'transient.volumeInKg'?: number;
  'transient.locationId'?: string;

  // origin props
  'origin.growerContactId'?: string;
  'origin.growerGGN'?: string;
  'origin.plotId'?: string;
  'origin.locationId'?: string;
  'origin.harvestDate'?: Date;
  'origin.harvestNumber'?: number;
  'origin.daysFromPrevHarvest'?: number;

  // prev org props
  'previousOrg.lotReferenceId'?: string;
}

// export function convertOldTransferToNew(oldTransfer: OldLotTransfer): LotTransfer {
//   return ({ ...oldTransfer, isMirror: undefined } as LotTransfer);
// }

export function convertOldReferenceToNew(oldRef: OldReportReference): ReportReference {
  return oldRef as ReportReference;
}

// Old lot interface as reference
export interface OldLot extends BaseEntity {
  orgId?: string;
  article?: IArticle;
  scores?: any;

  growerId?: string;

  transfers: LotTransfer[];

  insight?: InsightText;

  quantity?: number;
  arrivalDate?: number;

  initialQuantity?: number;

  palletIds?: string[];

  search?: Search;

  metadata?: any;
  hasLink: boolean;
  qcStatus: OrderAGStatus;

  traceabilityCode?: string;

  inStock?: boolean;

  locationId?: string;

  splitLotOrderIdLink?: string;
}

export interface NewLot extends BaseEntity {
  orgId?: string; // REFACTOR DONE
  suppliedByContactId?: string; // REFACTOR DONE

  article?: IArticle; // REFACTOR DONE

  emergenceDate: Date; // REFACTOR DONE // ex arrivalDate;

  transfers: LotTransfer[]; // REFACTOR DONE

  transient?: LotTransientProps;
  origin?: LotOriginProps;
  previousOrgProperties?: LotPreviousOrgProps;

  search?: Search; // REFACTOR DONE
}

export interface IBarcode {
  data: string,
  symbology: Barcode.Symbology
}

export interface LotTransientProps {
  numBoxes?: number; // ex quantity
  volumeInKg: number;

  palletIds?: string[];
  locationId?: string;

  // locationReferences?: { [key: string]: any } // dictionary, map between user defined location type and the value e.g. {"aisle": 7, "floor": 19}
  locationReference1?: string;
  locationReference2?: string;

  barcodes?: string[];
  // A link to a batch-specific external URL with instructions.
  externalInstructionsUrl?: string;

  // freshnessDate?: firebase.firestore.Timestamp;
  freshnessDate?: Date; // ex relevancyDate: correponds to the latest inspection date if present; otherwise it's the arrival/creation date
  isInMyPossession?: boolean; // if false, it's a supply chain lot
  isInStock?: boolean;

  hasLink?: boolean; // TODO: there are some uses cases for this, so I decided to not remove it for now, revise later
}

export interface LotOriginProps {
  growerContactId?: string;
  locationId?: string;
  plotId?: string;
  previousHarvestDate?: Date;
  harvestDate?: Date;
  harvestNumber?: number;
  // TODO(dienes) Depercate single GGN.
  growerGGN?: string;
  growerGGNs?: string[];
  daysFromPrevHarvest?: number;
}

export interface LotPreviousOrgProps {
  lotReferenceId?: string;
  dispatchDate?: Date;
  dispatchLocationAddress?: string;
  dispatchLocationGeoPoint?: firebase.firestore.GeoPoint;
  previousInspectionReference?: string;
}

export interface LotPosition {
  lotId: string;

  // TODO DEV-818 remove quantity and only keep numBoxes
  numBoxes?: number;
  volumeInKg?: number;
  /***/

  unit?: string;

  price?: Money;

  article?: IArticle;
  articleId?: string;

  growerId?: string;
  fieldId?: string;

  palletIds?: string[];

  // this field indicates the mother lot when the position is a sublot
  motherLotIds?: string[];

  // For BW/Frutania, some article properties come in the lot position.
  variety?: string;
  size?: string;
  origin?: string;

  // TODO(dienes) Deprecate single ggn.
  ggn?: string;
  ggns?: string[];

  barcodes?: string[];
  // A link to a batch-specific external URL with instructions.
  externalInstructionsUrl?: string;
}

export interface LotCreatePayload {
  id: string;
  transfers: LotTransfer[];
  article?: IArticle;

  numBoxes?: number;
  volumeInKg?: number;

  growerContactId?: string;
  growerGGN?: string;

  palletIds?: string[];

  locationId?: string;

  suppliedByContactId?: string;
  originLocationId?: string;
  originPlotId?: string;
  originHarvestDate?: Date;
  originPreviousHarvestDate?: Date;
  originHarvestNumber?: number;
  originDaysFromPrevHarvest?: number;
  prevOrgLotReferenceId?: string;

  firstTransferType?: OrderType;
  isAGMaster?: boolean;
}

// export function calculateExpectedLotBoxesForAssessment(lot: Lot, assessment: Assessment) : number | undefined {
//   if (assessment.reference.type === "INCOMING") {
//     // Find transfer from incoming order
//     return lot.transfers
//       ?.filter(tr => tr.transferType === "BUY" && tr.transferId === assessment.reference.orderId)
//       .map(t => t.quantity)
//       .reduce((a: number, b: number) => a + b);
//   }
//   return undefined;
// }

export interface InsightText {
  title: string;
  description: string;
}

export interface TransferLotSummary {
  lotId: string;
  numBoxes?: number;
  volumeInKg: number;
  article?: IArticle;
}

export interface LotTransfer {
  transferId: string;

  orderId?: string;

  numBoxes: number;
  volumeInKg: number;
  // only present in production orders
  wasteVolumeInKg?: number;

  fromLots?: TransferLotSummary[];
  toLots?: TransferLotSummary[];

  locationId?: string;
  dispatchLocationId?: string;

  // TODO: revise, should disappear
  // fromArticle?: Article;

  reason?: TransformationReason;

  transferDate?: Date;
  transferType: OrderType;

  // this is a pointer to an order when the lot has been splitted
  splitLotOrderIdLink?: string;

  // for undoing creation of incoming orders
  prevTransferBackup?: LotTransfer;
}

export interface NewLotTransfer {
  transferId: string;

  orderId?: string;

  numBoxes: number;
  volumeInKg: number;

  fromLots?: TransferLotSummary[];
  toLots?: TransferLotSummary[];

  locationId?: string;
  dispatchLocationId?: string;

  // TODO: revise, should disappear
  // fromArticle?: Article;

  reason?: TransformationReason;

  transferDate?: number;
  transferType: OrderType;

  // this is a pointer to an order when the lot has been splitted
  splitLotOrderIdLink?: string;
}

export const transformationTypesMap = {
  'Quality sort': 'QUALITY_SORT',
  'Pack': 'PACK',
  'Re-pack': 'RE_PACK',
  'Split batch (quality)': 'SPLIT_BATCH_QUALITY',
  'Split batch (other)': 'SPLIT_BATCH_OTHER',
  'Ripening': 'RIPENING',
  'Shelf-life': 'SHELF_LIFE',
} as const;

export const articleToProductArrayZippedFields = [
  ['origins', 'origin'],
  ['packaging', 'packaging'],
  ['agVarieties', 'agVariety'],
  ['varieties', 'variety'],
  ['brands', 'brand'],
];

export type TransformationReason =
  (typeof transformationTypesMap)[keyof typeof transformationTypesMap];

// export const transformationTypes = ['QUALITY_SORT', 'PACK', 'RE_PACK', 'SPLIT_BATCH_QUALITY', 'SPLIT_BATCH_OTHER', 'RIPENING'] as const;
// export type TransformationType = typeof transformationTypes[number];

export interface OldLotTransfer {
  transferId: string;

  orderId?: string;

  // TODO: rename to numBoxes
  quantity: number;

  fromLots?: TransferLotSummary[];
  toLots?: TransferLotSummary[];

  isMirror?: boolean;

  locationId?: string;
  dispatchLocationId?: string;

  // TODO: revise, should disappear
  // fromArticle?: Article;

  reason?: string;
  transferDate?: number;
  transferType: OrderType;

  // TODO: Emiliano EXPLAIN THIS
  linkedOrder?: string;

  // this is a pointer to an order when the lot has been splitted
  splitLotOrderIdLink?: string;
}

// export function lotTransferId(a: LotTransfer) {
//   if (a.transferType === "BUY") {
//     return [a.transferType, a.orderId].join("-");
//   }
//   return [a.transferType, a.orderId, a.lotId, a.quantity, a.transferDate, a.transferId, a.quantity].join("-");
// }

// export function lotTransferEq(a: LotTransfer, b: LotTransfer) {
//   // TODO: what if transferId is set? Then only compare based on that?
//   return lotTransferId(a) === lotTransferId(b);
// }

// As of now, this applies to BUY, SELL, SELL_RETURN orders
export interface OrderCreatePayload {
  id: string;

  // shipping info
  internalReference?: string;
  shippingReference?: string;
  externalReference?: string;

  status?: OrderStatus;
  type: OrderType;
  qcStatus?: OrderAGStatus;
  contactOrder?: OrderLink;

  contactId?: string;
  contactName?: string;

  positions: LotPosition[]; // Snapshot of events
  lotInspectionMap?: { [key in string]: LotInspection };

  growerContactId?: string;

  bookingDate?: number;
  fulfilmentDate?: number;
  estimatedArrivalDate?: number;

  origin?: string;

  dispatchLocationId?: string;
  locationId?: string;

  transport?: TransportInformation;

  // indicates that the lot was created in our system (we are the "ERP")
  isAGMaster?: boolean;

  price?: Money;

  // TODO: Emi, revise
  supplierId?: string;
  supplierName?: string;
}

export interface ClaimCreatePayload {
  position: LotPosition;
  orderId: string;
  amount: Money;
  receivedDate?: number;
  handledDate?: number;
  reason?: string;
  description?: string;
}

export function createSublotOrderId(
  motherLotId: string,
  childLotId: string,
  date: Date = new Date()
) {
  // Python version:
  // def _create_sublot_order_id(date_str, mother_lot_id, lot_id):
  //   # Rely on the ability of pandas to parse correctly all sorts of date formats
  //   pd_time = pd.Timestamp(date_str)
  //   return f"SL-{mother_lot_id}-{lot_id}-{pd_time.year}{pd_time.month}{pd_time.day}"
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return `SL-${motherLotId}-${childLotId}-${y}${m}${d}`;
}

export interface SplitLotPayload {
  id: string;
  // When the sublot is linked to an order, we specify the order's id in this field
  splitLotOrderIdLink?: string;

  incomingPositions: LotPosition[];
  outgoingPositions: LotPosition[];

  fulfilmentDate: number;

  locationId: string;
}

export interface InternalTransferPayload {
  id: string;
  positions: LotPosition[];
  fulfilmentDate: number;

  dispatchLocationId?: string;
  locationId: string;
}

export interface ProductionPayload {
  id: string;

  incomingPositions: LotPosition[];
  outgoingPositions: LotPosition[];

  wasteVolumeInKg?: number;

  reason?: TransformationReason;

  locationId: string;
  fulfilmentDate: number;

  isAGMaster?: boolean;

  suppliedByContactId?: string;
}

export interface Claim extends PartialEntity {
  // stringified orderId + lotId
  claimId: string;
  position: LotPosition;
  // this refers to the order that changed the ownership
  orderId: string;
  // type of order associated with the claim
  orderType?: ClaimableOrderType;

  amount: Money;
  receivedDate?: Date;
  handledDate?: Date;
  reason?: string;
  description?: string;

  isAGMaster?: boolean;
}

export interface AllocationMismatch {
  title: string;
  expected: any;
  found: any;
}

//export type LotTransferEventType = "BUY" | "SELL" | "PRODUCTION_ALLOCATION" | "SPLIT_LOT" | "TRANSFER";

export interface ContactUpdatePayload {
  id: string;
  name: string;
  address?: string;
  countryCode?: string;
  type?: ContactType[];
  additionalContactEmails?: string[];

  imageId?: string;

  insights?: InsightText[];
}

//----------------------------------------------------
// LOCATION
export const LocationTypes = {
  Warehouse: 'WAREHOUSE',
  ProductionSite: 'PRODUCTION_SITE',
} as const;
export type LocationType = (typeof LocationTypes)[keyof typeof LocationTypes];

export type Location =
  | ({ locationType: 'WAREHOUSE' } & WarehouseLocation)
  | ({ locationType: 'PRODUCTION_SITE' } & ProductionSiteLocation);

export interface BaseLocation extends PartialEntity {
  locationId: string;
  name: string;
  address?: string;
  countryCode?: string;
  geoPoint?: firebase.firestore.GeoPoint;
  associatedPartnerId?: string;
}

// Warehouse
export const WarehouseServices = {
  Sorting: 'SORTING',
  Cooling: 'COOLING',
  Packing: 'PACKING',
} as const;
export type WarehouseService =
  (typeof WarehouseServices)[keyof typeof WarehouseServices];

export interface WarehouseLocation extends BaseLocation {
  locationType: 'WAREHOUSE';
  services?: WarehouseService[];
}

// Production site
export const ProductionTypes = {
  OpenField: 'OPEN_FIELD',
  OpenFieldWithCover: 'OPEN_FIELD_WITH_COVER',
  OpenTunnel: 'OPEN_TUNNEL',
  ClosedTunnel: 'CLOSED_TUNNEL',
  Greenhouse: 'GREENHOUSE',
  // TODO: extend db enum
  GreenhouseHeated: 'GREENHOUSE_HEATED',
} as const;
export type ProductionType = (typeof ProductionTypes)[keyof typeof ProductionTypes];

export const FieldManagementQualities = {
  VeryProfessional: 'VERY_PROFESSIONAL',
  Professional: 'PROFESSIONAL',
  ToBeImproved: 'TO_BE_IMPROVED',
} as const;
export type FieldManagementQuality =
  (typeof FieldManagementQualities)[keyof typeof FieldManagementQualities];

export const IrrigationTypes = {
  Sprinklers: 'SPRINKLERS',
  Drip: 'DRIP',
  OtherIrrigationSystems: 'OTHER',
} as const;
export type IrrigationType = (typeof IrrigationTypes)[keyof typeof IrrigationTypes];

export const GrowingTypes = {
  SummerBearer: 'SUMMER_BEARER',
  EverBearer: 'EVER_BEARER',
} as const;
export type GrowingType = (typeof GrowingTypes)[keyof typeof GrowingTypes];

export const GrowingMethodTypes = {
  Ground: 'GROUND',
  Ridged: 'RIDGED',
  Tabletop: 'TABLETOP',
  Hydroponics: 'HYDROPONICS',
  PotsOnGround: 'POTS_ON_GROUND',
  PotsInGround: 'POTS_IN_GROUND',
} as const;
export type GrowingMethodType =
  (typeof GrowingMethodTypes)[keyof typeof GrowingMethodTypes];

export const SubstrateTypes = {
  Soil: 'SOIL',
  Cocopeat: 'COCOPEAT',
  Rockwool: 'ROCKWOOL',
  // TODO: extend db enum
  Turf: 'TURF',
  Perlite: 'PERLITE',
  Hydro: 'HYDRO',
  Combination: 'COMBINATION',
  Other: 'OTHER',
} as const;
export type SubstrateType = (typeof SubstrateTypes)[keyof typeof SubstrateTypes];

export const HarvestingMethods = {
  Manual: 'MANUAL',
  Mechanical: 'MECHANICAL',
} as const;
export type HarvestingMethod =
  (typeof HarvestingMethods)[keyof typeof HarvestingMethods];

export const FieldStorageConditions = {
  OnField: 'ON_FIELD',
  InShade: 'IN_SHADE',
  Refrigerator: 'REFRIGERATOR',  
} as const;
export type FieldStorageCondition =
  (typeof FieldStorageConditions)[keyof typeof FieldStorageConditions];

export interface ProductVariety {
  agProductId: string;
  variety?: string;
}

export interface ProductionSiteLocation extends BaseLocation {
  locationType: 'PRODUCTION_SITE';
  productionType?: ProductionType;
  fieldManagementQuality?: FieldManagementQuality;
  producedVarieties?: ProductVariety[];
  packhouseGeoPoint?: firebase.firestore.GeoPoint;
  hasCoolingFacilities?: boolean;
  latestInspectionPreview?: ProdSiteInspectionPreview;

  irrigationSystems?: IrrigationType[];
  totalAreaInHa?: number;
  growingAreaInHa?: number;

  // Props added on SF's request (TODO: extend platform db model in feature/admin later)
  growingType?: GrowingType;
  substrateType?: SubstrateType; // this one already exists in the platform model, but we need to extend it
  growingMethodType?: GrowingMethodType; // this one already exists in the platform model, but we need to extend it
  maxMinutesOnFieldBeforeTransportation?: number | string;
  maxPrecoolingTime?: number | string;
  refrigeratedTransporter?: boolean;
  datePlanted?: Date;
  // as "productionWeeks" in the platform model
  productionWeekStart?: number;
  productionWeekEnd?: number;
  plantsPerHa?: number;
  kgProducedPerPlant?: number;
  fieldStorageConditions?: FieldStorageCondition;
  // this one should only be present if fieldStorageConditions == REFRIGERATOR
  refrigerationTemperature?: number;
}

export const productionSiteExclusiveKeys: (keyof ProductionSiteLocation)[] = [
  'producedVarieties',
  'packhouseGeoPoint',
  'productionType',
  'hasCoolingFacilities',
  'irrigationSystems',
  'totalAreaInHa',
  'growingAreaInHa',
  'growingType',
  'substrateType',
  'growingMethodType',
  'maxMinutesOnFieldBeforeTransportation',
  'maxPrecoolingTime',
  'refrigeratedTransporter',
  'datePlanted',
  'productionWeekStart',
  'productionWeekEnd',
  'plantsPerHa',
  'kgProducedPerPlant',
  'fieldStorageConditions',
];
export const warehouseExclusiveKeys: (keyof WarehouseLocation)[] = ['services'];

//----------------------------------------------------

export const ClaimableOrderTypes = ['BUY', 'SELL'] as const;
export type ClaimableOrderType = (typeof ClaimableOrderTypes)[number];

export type OrderType =
  | ClaimableOrderType
  | 'INTERNAL_TRANSFER'
  | 'SELL_RETURN'
  | 'BUY_RETURN'
  | 'PRODUCTION'
  | 'SPLIT_LOT'
  | 'CREATE_LOT'
  | 'HARVESTED'
  // these below only apply to LotActions
  | 'SURVEYED';

export type OrderStatus = 'OPEN' | 'CONFIRMED' | 'CANCELED';
export type OrderQCStatus = 'OPEN' | 'DONE';
export type OrderCommercialStatus = 'REDO' | 'APPROVED' | 'CHECKED' | 'SHARED';
export type OrderAGStatus = OrderQCStatus | OrderCommercialStatus;

export const OrderAGStatuses: { [key in OrderAGStatus]?: string } = {
  OPEN: 'Open',
  DONE: 'Done',
  REDO: 'Redo',
  // "APPROVED": 'Approved',
  CHECKED: 'Report Created',
  SHARED: 'Shared',
};
/** Schema Forms **/

export interface Picture {
  id: string;
  inputIds?: string[];

  sectionId?: string;
  sectionName?: string;
  imageTag?: string;

  // TODO: DEV-789 deprecate after migration
  // groupId?: string;
  assessmentReference?: LegacyInspectionReference;
}

export type AssessmentStatus =
  | 'OPEN'
  | 'COMPLETED'
  | 'APPROVED'
  | 'REJECTED'
  | 'ACCEPTED NOT FULL PRICE'
  | 'ACCEPTED WITH SORTING'
  | 'ACCEPTED UPON RESERVATION'
  | 'SETTLED'
  | 'IN PROGRESS';

/***
 * OPEN --> COMPLETED --> APPROVED | REJECTED | APPROVED ...with comments
 */

export type InputType =
  | 'INT'
  | 'FLOAT'
  | 'BOOLEAN'
  | 'CATEGORY'
  | 'TEXT'
  | 'NUMBER_LIST'
  | 'COMMENT'
  | 'AUTO_CALCULATED';
export type Unit =
  | 'PERCENTAGE'
  | 'PIECES'
  | 'GRAMS'
  | 'OUNCES'
  | 'KILOS'
  | 'POUNDS'
  | 'BOXES'
  | 'PUNNETS'
  | 'CELSIUS'
  | 'FAHRENHEIT'
  | 'KG_PER_CM^2';
export type RenderingType =
  | 'DEFAULT'
  | 'IMAGE_CONTAINER'
  | 'PROPERTY_VERIFICATION'
  | 'COMMENT'
  | 'SCORE'
  | 'MULTIPLE_OUTPUTS'; // | 'CALCULATED'
export type ScoringPolicyType = 'THRESHOLDS' | 'CAT' | 'BOOLEAN';

export type QuestionSeverity = 'CRITICAL' | 'MAJOR' | 'MINOR';
export const severitySpace: QuestionSeverity[] = ['CRITICAL', 'MAJOR', 'MINOR'];

export interface ArticleRequirements {
  packagingTypes?: PackagingType[];
  isBio?: boolean;

  productIds?: string[];
  origins?: string[];
}

export type ConversionType =
  | 'PUNNETS_TO_PERCENTAGE'
  | 'BOXES_TO_PERCENTAGE'
  | 'VALUES_TO_AVG'
  | 'VALUES_TO_SD'
  | 'VALUES_TO_SUM'
  | 'VALUES_TO_MAX'
  | 'VALUES_TO_MIN'
  | 'PIECES_TO_PERCENTAGE'
  | 'WEIGHT_TO_PERCENTAGE'
  | 'AUX_WEIGHT_TO_PERCENTAGE'
  | 'STRING_TO_LENGTH'
  | 'SAMPLE_WEIGHT_TO_AVG_WEIGHT'
  | 'AVG_WEIGHT_TO_NUM_SAMPLE_PIECES'
  | 'AVG_WEIGHT_TO_NUM_PIECES_PUNNET'
  | 'AVG_WEIGHT_TO_NUM_PIECES_BOX'
  | 'NUM_SAMPLE_PIECES_FROM_CONTEXT'
  | 'DEFECT_WEIGHT_TO_SAMPLE_PERCENTAGE';

export interface IAnswer {
  inputType: InputType; // UI language
  inputUnit?: Unit; // for understanding the semantics

  answerLabel?: string;

  conversionType?: ConversionType;

  answerUnit?: Unit; // exists if requires conversion == true
  answerType?: InputType;

  defaultValue?: any;

  // The existence/rendering of an input might depend on the article as well, similarly to an entire question
  articleRequirements?: ArticleRequirements;
}

export interface INumericAnswer extends IAnswer {
  minValue?: number;
  maxValue?: number;
  granularity?: number;
}

export interface ICategoricalAnswer extends IAnswer {
  options: string[];
}

export function safeInputToInt(value: any, defaultValue: number) {
  return !isNaN(parseInt(value)) ? parseInt(value) : defaultValue;
}

export function safeInputToFloat(value: any, defaultValue: number) {
  // first we try to replace possible commas for dots
  try {
    value = value.replace(',', '.');
  } catch (error) {
    // do nothings
  }

  return !isNaN(parseFloat(value)) ? parseFloat(value) : defaultValue;
}

export interface CompanyScoreMapping {
  rank: number;
  companyScore: any;
  // Custom coloring for email notifications. TODO: use this mapping in ViewInspectionScore.tsx as well instead of using a hardcoded mapping in the CSS
  customColor?: ScoreColor;
  agScore: AGScore;
}

export interface ScoreColor {
  background: string;
  text: string;
}

export interface OrderSummary {
  groupBy: string[];
  aggregate: string[];
}

export interface InspectionStatusList {
  label: string;
  color: string;
}

export interface OrganisationSettings {
  scoreSpace?: { [key: string]: string[] };
  scoreMappings?: { [key: string]: CompanyScoreMapping[] };
  orderSummary?: OrderSummary;
  lotScoringType?: LotScoringType;
  shareSpecsWithOrgs?: { [orgId: string]: string[] };
  import?: ImportSettings;
  logoUrl?: string;

  // default group scores to display when order is marked as done but inspection is not done
  defaultGroupScores?: { [key in string]: OutputGroupValue };
  sharedReportDisplaySettings?: {
    inspectedBy?: boolean;
    inspectionDate?: boolean;
    lastModifiedDate?: boolean;
    sharedBy?: boolean;
  };

  defaultPermissions?: OrgSettingsPermissions;

  allowSplitLots?: boolean;
  showTodos?: boolean;
  allowFilterByStock?: boolean;

  // Enables ERP-lite functionality
  allowManagingLots?: boolean;

  // Enables deletion of orders/lots imported via API (not created via ERP-lite functionality)
  allowDeletingApiImportedEntities?: boolean;

  hideSpecBuilder?: boolean;

  // insightsAccess?: boolean;

  inspectionScoresOrder: {
    [index: string]: {
      id: string;
      name: string;
    };
  };

  // group id of the score that can be search/filtered and gives color codes to cards.
  groupScoreSearch: string;

  inspectionStatusList: InspectionStatusList[];

  includeEmptyInspectionQuestionsInReport?: boolean;
  qualityScores?: { [scoreId: string]: OrgQualityScores };

  useCompanyProductIdsInUI?: boolean;

  testAccountSettings?: TestAccountSettings;
  productTiers?: ProductTiers;

  enabledObjectTypes?: InspectableObjectType[];

  syncAPI?: SyncApiSettings;
}

interface SyncApiSettings {
  allowFetchingStockData?: boolean;
  allowPushingInspectionData?: boolean;
  authType?: string;
  basicAuthPassword?: string;
  basicAuthUsername?: string;
  getStockListAPIEndpoint?: string;
  pushInspectionAPIEndpoint?: string;
}

export interface TestAccountSettings {
  isTestAccount: boolean;
  testStartDate: Date;
}

export interface OrgSettingsPermissions {
  defaultUserPermissions?: UserPermission[];
  defaultAGTabs?: QCFilterType[];
  defaultReportTabs?: ReportFilterType[];
}

export interface OrgQualityScores {
  displayedName?: string;
  type?: QualityScoreType;
  includeInUIInspectionSummary?: boolean;
  // Only if the type is categorical
  agScoresMapping?: { [catKey: string]: any };
}

export type QualityScoreType = 'CATEGORICAL' | 'DISCRETE' | 'CONTINUOUS';

export interface ImportSettings {
  enforceVarietyMapping?: boolean;
}
export interface IQuestionSpec {
  // agQuestionId is not part of the interface because it's the key in whatever object stores the questions
  agQuestionId?: string;
  displayedName: string;
  // name displayed in the report
  reportName?: string;

  // A question might not need inputs, e.g. questionType === 'IMAGE'
  inputs?: IAnswer[];

  renderingType?: RenderingType; // what type of question that is
  groupId?: string;

  // we expect scoringPolicy if hasScoring == true

  scoringPolicy?: IRule[];

  taggable?: boolean;
  setContext?: string[]; // if exists, the question should set the answer values in the context using the setContext names
  hint?: string;
  isMandatory?: boolean;
  articleRequirements?: ArticleRequirements;
  questionParams?: { [key in QuestionParam]?: string }; //  In case a specific question needs extra parameters it should be passed here

  specOverrideable?: boolean;

  // decide whether to display question in report
  hideInReport?: boolean;
  hideInExternalReport?: boolean;
  showInSummary?: boolean;

  batchScoring?: { [scoreGroup: string]: ScoringGroupMembership };
}

export interface ScoringGroupMembership {
  groupId: string;
  // index not relevant for scoring of type ADLS
  inputIdx?: number;
}

export type QuestionParam = 'verifyArticleProperty' | 'verifyProperty';

export interface UserInput {
  // rawValue is the raw input that is understandable by the UI
  // value is what the user sees
  // score is the question level score
  rawValues?: any[];
  values?: any[];

  score?: any;
  agScore?: any;

  groupId?: string;
}

// TODO: CHANGE THE NAME PLEASE
export interface Slice {
  // Threshold
  min?: number;
  max?: number;

  // Category
  category?: string;

  // Boolean
  boolValue?: boolean;
}

export interface IRule {
  score: AGScore;
  values: Slice[];
}

export interface AGQuestion {
  // This interface is used to store the Agrinorm questions
  id: string; // The id of the question
  name: string; // Default displayed name

  synonyms?: string[];

  // Fields for the specification builder
  tags: string[];

  updateTimestamp?: number;
}

export type AGQuestionTag =
  | 'QUALITY'
  | 'GENERAL'
  | 'TRANSPORT'
  | 'LOT'
  | 'ORDER'
  | 'IMAGE';

export interface GroupLayout {
  // Future TODO: Simplify this (e.g. get rid of infinite-recursion ability)
  groupId?: string;
  name: string;

  children?: GroupLayout[];
  questionIds?: string[];

  collapsed?: boolean;

  taggable?: boolean;
  hideInReport?: boolean;

  // abstract === true means it must be overriden
  abstract?: boolean;
  groupScoreable?: boolean;
}

export interface MergedSchema {
  // Previously named `Schema`
  // we use the same interface for schemas and templates
  id: string;
  name: string;
  organisationId: string;

  version: string;

  testFromSchemaId?: string;

  fromSchemaId?: string; // id of the template if in the case of a schema; otherwise undefined
  fromSchemaVersion?: string; // version of the template " " "

  creationDate?: number;
  lastModifiedDate?: number;
  lastModifiedUserId?: string;

  criteria: OldCriteria;
  active?: boolean;

  // hasScoring?: boolean; // TODO: handle this in pagescoring

  // this is part of the merged schema and templates
  layout?: GroupLayout;
  // this is only present in the schema
  schemaLayout?: { [key in string]: GroupLayout };

  // in case of a schema, the questionSpecs override the questionSpecs of the template
  questionSpecs: { [key in string]: IQuestionSpec };
  agQuestionSpecs: { [key in string]: IQuestionSpec };

  lotScoring?: { [groupId: string]: LotScoring };
}

export type AgrinormQuestionId =
  | 'ag_boxes_shipped'
  | 'ag_boxes_at_inspection'
  | 'ggn_missing'
  | 'ggn_invalid'
  | 'gln_invalid'
  | 'gln_missing'
  | 'coc_missing'
  | 'coc_invalid';

export const AG_BOXES_SHIPPED_QUESTION_ID: AgrinormQuestionId = 'ag_boxes_shipped';
export const AG_BOXES_AT_INSPECTION_QUESTION_ID: AgrinormQuestionId =
  'ag_boxes_at_inspection';
export const AG_GGN_MISSING: AgrinormQuestionId = 'ggn_missing';
export const AG_GGN_INVALID: AgrinormQuestionId = 'ggn_invalid';
export const AG_GLN_MISSING: AgrinormQuestionId = 'gln_missing';
export const AG_GLN_INVALID: AgrinormQuestionId = 'gln_invalid';
export const AG_COC_MISSING: AgrinormQuestionId = 'coc_missing';
export const AG_COC_INVALID: AgrinormQuestionId = 'coc_invalid';

// TODO: these should ideally come from core
export const AG_BOXES_RECEIVED = 'ag_boxes_received';
export const AG_BOXES_SHIPPED_MISMATCH = 'ag_boxes_shipped_mismatch';
export const AG_VOLUME_AT_INSPECTION = 'ag_volume_at_inspection';

// export const AG_QUESTION_GROUP_NAME = 'Overview';
// export const AG_QUESTION_GROUP_ID = 'group_overview';

export const isQuestionSpec = (
  layoutItem: QuestionSpec | QuestionSpecIn | Subsection | SubsectionItemIn
): boolean =>
  layoutItem.hasOwnProperty('questionId') ||
  (layoutItem.hasOwnProperty('isPartialSummary') && !!layoutItem['isPartialSummary']);

export function getQuestionsFromSchema(
  schema: MergedSchema,
  withNames: boolean = false
): any[] {
  //console.log("getQuestionsFromSchema!", schema);
  if (!schema) return [];

  // If called with withNames === true, returns an array of {id, name}; else, returns only an array of ids
  function getAGQuestionIdsFromGroup(group: GroupLayout) {
    let result: any[] =
      group.questionIds?.map((id) =>
        withNames ? { id, name: schema.questionSpecs[id]?.displayedName } : id
      ) || [];

    if (group?.children?.length > 0) {
      for (const node of group.children) {
        result = result.concat(getAGQuestionIdsFromGroup(node));
      }
    }
    return result;
  }

  return getAGQuestionIdsFromGroup(schema.layout);
}

export function getGroupIdsFromSchema(
  schema: MergedSchema,
  withNames: boolean = false
): any[] {
  //console.log("getQuestionsFromSchema!", schema);
  if (!schema) return [];

  // If called with withNames === true, returns an array of {id, name}; else, returns only an array of ids
  function getGroupIdsFromGroup(group: GroupLayout) {
    let result: any[] = [
      withNames ? { id: group.groupId, name: group.name } : group.groupId,
    ];

    if (group?.children?.length > 0) {
      for (const node of group.children) {
        result = result.concat(getGroupIdsFromGroup(node));
      }
    }
    return result;
  }

  // remove 'root' from the options
  return getGroupIdsFromGroup(schema.layout).filter((g) => g !== 'root');
}

export interface LotScoring {
  type: LotScoringType;
  params: any;
  name: string;
}

export interface ADPScoringParams {
  // ranked from worst to best
  rankedCategoriesSpace: string[];

  allowManualOverride?: boolean;

  // e.g. "critical" and "major"
  questionGroups: string[];

  categoriesToScores?: { [category: string]: Computable };

  // The interface must specify a default score
  defaultScore: any;
}

export interface ADLSScoringParams {
  rankedScoreSpace: any[];
  groupScoreMap?: { [defectsScore: string]: any };

  // The interface must specify a default score
  defaultScore: any;
}

export class ADPLotLevelScorer {
  /**
   * In an ADP scoring [insert description here]
   */
  schema: MergedSchema;
  scoringParams: ADPScoringParams;
  scoringGroupId: string;

  private showLogs: boolean = false;

  constructor(
    schema: MergedSchema,
    scoringParams: ADPScoringParams,
    scoringGroupId: string
  ) {
    this.schema = schema;
    this.scoringParams = scoringParams;
    this.scoringGroupId = scoringGroupId;
  }

  getGroupScore(scored: AssessmentVM) {
    this.showLogs && console.log('Begin execution of ADPLotLevelScorer.getGroupScore');

    // prepare an object to store the sums of percentages based on the scoreable groups (e.g. "critical" and "major")
    const groupSum: any = {};
    this.scoringParams.questionGroups.forEach((g) => (groupSum[g] = 0));

    // loop over the scored assessmentVM and compute the sums
    for (const questionId in scored.outputMap) {
      const currScoredQuestion = scored.outputMap[questionId];

      // skip if no values found for the current question
      if (!currScoredQuestion.values) {
        continue;
      }

      const specs = this.schema.questionSpecs?.[questionId];
      const groupScoringParams = specs?.batchScoring?.[this.scoringGroupId];

      // skip if no batch scoring params found for the current group in the current question specs
      if (!groupScoringParams) {
        continue;
      }

      // extract scoring params
      const { groupId, inputIdx } = groupScoringParams;

      // skip if value not found or is not numeric
      const value = currScoredQuestion.values?.[inputIdx];
      if (value == null || typeof value !== 'number') {
        this.showLogs && console.warn(`ADPLotLevelScorer: ${value} is not numeric!`);
        continue;
      }

      // we add it to the sum
      groupSum[groupId] += value;
    }

    this.showLogs && console.log('ADPLotLevelScorer Group Sum', groupSum);

    // After the sums are computed, calculate the actual score according to the rules/thresholds set in the categoriesToScores field
    // For that we rely on the ranked categories space, which should be already sorted from worst to best
    for (const score of this.scoringParams.rankedCategoriesSpace) {
      const computable = this.scoringParams.categoriesToScores?.[score];

      if (computable == null) {
        this.showLogs && console.log(`No computable found for score ${score}`);
        continue;
      }

      this.showLogs && console.log(`Evaluating for score ${score}`, computable);
      const applies = new Evaluator(computable, groupSum, this.showLogs).evaluate();
      if (applies) {
        this.showLogs && console.log(`Score ${score} fulfills the rules`);
        return score;
      }
    }

    this.showLogs &&
      console.warn(
        `ADPLotLevelScorer: No matching rule found, returning default score "${this.scoringParams.defaultScore}"`
      );
    return this.scoringParams.defaultScore;
  }
}

export interface MinMax {
  min: number;
  max: number;
}

export class Evaluator {
  computable: Computable;
  variables: { [key: string]: number };

  private showLogs: boolean;

  constructor(
    evalExpression: Computable,
    variables: { [key: string]: number },
    showLogs: boolean = false
  ) {
    this.computable = evalExpression;
    this.variables = variables;
    this.showLogs = showLogs;
  }

  evaluate(): number | boolean {
    const { term1Type, term2Type, term1, term2 } = this.computable;
    const evalTerms: { [key: string]: boolean | number | MinMax } = {
      term1: undefined,
      term2: undefined,
      range: undefined,
    };

    const zippedTerms: [string, any, ComputableType][] = [
      ['term1', term1, term1Type],
      ['term2', term2, term2Type],
    ];

    for (const [key, term, type] of zippedTerms) {
      try {
        switch (type) {
          case 'EVAL':
            evalTerms[key] = new Evaluator(
              term,
              this.variables,
              this.showLogs
            ).evaluate();
            break;
          case 'SCALAR':
            const num = parseFloat(term);
            if (isNaN(num)) {
              throw new Error();
            }
            evalTerms[key] = num;
            break;
          case 'RANGE':
            if (term?.min == null) {
              throw new Error();
            }
            // when the term is a range, we store it here so we know how to look for it in this.compute
            evalTerms.range = term;
            break;
          case 'VAR':
            this.showLogs && console.log(`VAR: ${term}`);
            const variable = this.variables[term];
            if (variable == null) {
              throw new Error();
            }
            evalTerms[key] = variable;
            break;
          default:
            this.throwError(`Type ${type} not recognized`);
        }
      } catch (e) {
        this.throwError(`Term "${term}" is not of type ${type}`);
      }
    }

    return this.compute(
      evalTerms.term1 as number | boolean,
      evalTerms.term2 as number | boolean,
      evalTerms.range as MinMax
    );
  }

  private compute(a: number | boolean, b: number | boolean, range?: MinMax) {
    let result: any;
    switch (this.computable.op) {
      case 'AND':
        this.checkTypes([a, b], 'boolean');
        result = a && b;
        break;
      case 'OR':
        this.checkTypes([a, b], 'boolean');
        result = a || b;
        break;
      case 'PLUS':
        this.checkTypes([a, b], 'number');
        result = (a as number) + (b as number);
        break;
      case 'MULT':
        this.checkTypes([a, b], 'number');
        result = (a as number) * (b as number);
        break;
      case 'IN-BETWEEN':
        this.checkTypes([a, b, range], 'range');
        const term = a ?? b; // only one of them was populated (see case 'RANGE' in this.evaluate)
        // TODO: how to proceed with the <= / <??
        // @ts-ignore
        result = range.min <= term && term < range.max;
        break;
    }
    this.showLogs &&
      console.log(
        `OPERATION: ${a ?? b} / ${this.computable.op} / ${
          !!range ? JSON.stringify(range) : b ?? a
        } -> ${result}`
      );
    return result;
  }

  private checkTypes(
    values: (number | boolean | MinMax)[],
    checkType: 'boolean' | 'number' | 'range'
  ) {
    if (checkType === 'range') {
      const num = values[0] ?? values[1]; // one of both must be a number, the other should be null
      if (typeof num !== 'number' || (values[2] as MinMax)?.min == null) {
        // the 3rd value is always the range
        this.throwError(
          `operation of type "${this.computable.op}" requires a number and a range`
        );
      }
    } else if (values.reduce((a, b) => a || typeof b !== checkType, false)) {
      this.throwError(
        `both values must be of type "${checkType}" to perform a "${this.computable.op}" operation`
      );
    }
  }

  private throwError(error: string) {
    console.error('Error in Evaluator:', error);
    return null;
  }
}

export interface Computable {
  term1Type: ComputableType;
  term1: any;
  op: ComputableOp;
  term2Type: ComputableType;
  term2: any;
}

export type ComputableType = 'EVAL' | 'SCALAR' | 'VAR' | 'RANGE';
export type ComputableOp = 'PLUS' | 'MULT' | 'OR' | 'AND' | 'IN-BETWEEN';

export interface ManualScoringParams {
  sortedScoreSpace: any[];
  // The interface must specify a default score
  defaultScore: any;
  scoredQuestionIds: string[];
}

export class ADLSLotLevelScorer {
  /**
   * In an ADLS scoring, the groupScoring stores for every scorable group, a mapping between all possible
   * combinations of group questions scores and the respective group score.
   */
  schema: MergedSchema;
  scoringParams: ADLSScoringParams;
  scoringGroupId: string;

  private showLogs: boolean = false;

  constructor(
    schema: MergedSchema,
    scoringParams: ADLSScoringParams,
    scoringGroupId: string
  ) {
    this.schema = schema;
    this.scoringParams = scoringParams;
    this.scoringGroupId = scoringGroupId;
  }

  getGroupScore(scored: AssessmentVM) {
    const specs = this.schema.questionSpecs;
    const { rankedScoreSpace, defaultScore, groupScoreMap } = this.scoringParams;

    const scoreSeverityList = Object.entries(scored.outputMap)
      .filter(([qId, _]) => specs[qId]?.batchScoring?.[this.scoringGroupId])
      .map(([qId, _]) => ({
        score: scored.outputMap[qId].score ?? defaultScore,
        severity: specs[qId].batchScoring[this.scoringGroupId].groupId,
      }));
    const vectorArray = [];

    severitySpace.forEach((severity) => {
      rankedScoreSpace.forEach((score) => {
        const countArray = scoreSeverityList.filter(
          (row) => row.score === score && row.severity === severity
        );
        vectorArray.push(countArray.length);
      });
    });

    const scoreVector = vectorArray.join('');

    const groupScore = groupScoreMap[scoreVector] ?? defaultScore;

    this.showLogs &&
      console.log(
        'groupId',
        this.scoringGroupId,
        'scoreVector',
        scoreVector,
        'score',
        groupScore
      );

    return groupScore.toString();
  }
}

// RF_TODO: Is this necessary anymore?
export class ManualLotLevelScorer {
  schema: MergedSchema;
  scoringParams: ManualScoringParams;

  constructor(schema: MergedSchema, scoringParams: ManualScoringParams) {
    this.schema = schema;
    this.scoringParams = scoringParams;
  }
}

// ADLS = aggregated defect level score
// ADP = aggregated defect percentages
export type LotScoringType = 'MANUAL' | 'ADLS' | 'ADP';

// export function getConfigurationGroupName(id: string, conf: MergedSchema) {
//   // depending on where PageGallery is being rendered from, we may or may not have the conf
//   // let name = conf?.layout?.children?.find((group) => group.groupId === id)?.name;
//   // if (name) return name;
//   // if (name === undefined) return "Other"

//   // const str
//   return id.replace('group_', '').replace('_', ' ');
//   // return "other";
// }

export function getSectionNameFromPicture(picture: Picture) {
  // console.log("GET SEC", picture.sectionName)
  if (picture.sectionName == null) return 'Other';
  if (!!picture?.sectionName) return picture.sectionName;
  else return picture?.sectionId;
}

export function getSectionNameFromLayout(sectionId: string, layout: GroupLayout) {
  let name = sectionId;

  function recursive(layout: GroupLayout) {
    if (name === sectionId) {
      if (layout.groupId === sectionId) {
        name = layout.name;
      }
      if (!!layout.children) {
        layout.children.forEach((c) => recursive(c));
      }
    }
  }

  recursive(layout);
  return name;
}

export interface OutputGroupValue {
  id: string;
  name: string;

  score: string;
  agScore?: AGScore;

  manuallyOverriden?: boolean;
}

// export interface IAssessment extends PartialEntity {
//   reference: AssessmentReference;

//   status: AssessmentStatus;

//   locationId?: string;
//   recordedGeoPoint?: firebase.firestore.GeoPoint;

//   source: InspectionSource;

//   schemaId?: string;
//   schemaVersion?: string;

//   // templateId?: string;
//   // templateVersion?: string;

//   tombDate?: number;

//   description?: string;

//   pictures?: Picture[];
//   barcodes?: any[];

//   lotProperties?: LotProperties;

//   userInputMap: { [key in string]: UserInput };
//   userInputGroupMap?: { [key in string]: OutputGroupValue }; // In case manual overriden

//   article?: IArticle;
//   articleId?: string;

//   // boxesExpected?: number;
//   // boxesReceived?: number;

//   inspectionTime?: number;
//   reviewedBy?: string;

//   // layout for report purposes
//   reportLayout?: ReportLayout;
// }

// TODO: delete after
export interface LotProperties {
  palletIds?: string[];
  isSplitLot?: boolean;
  ggnList?: string[];
  glnList?: string[];
  cocList?: string[];
}

export type ReportLayout = {
  questionSpecs: { [key in string]: IQuestionSpec };
  // agQuestionSpecs: {[key in string] : IQuestionSpec};
  layout: GroupLayout[];
};

// type ReportRenderingType = 'DEFAULT' | 'ACTIONS' | 'OVERVIEW' | 'SAMPLING';

export interface AssessmentVM {
  node?: AssessmentVMNode;

  outputMap: { [key in string]: UserInput };
  outputGroupMap: { [key in string]: OutputGroupValue };
}

export type AssessmentVMNodeType = 'GROUP' | 'QUESTION';

export interface AssessmentVMNode {
  level: number;
  id: string; // this can be either a groupId or an agQuestionId, depending on the AssessmentVMNodeType
  type: AssessmentVMNodeType;
  taggable: boolean;

  children: AssessmentVMNode[];

  // Exists only when AssessmentVMNodeType === 'GROUP'
  groupLayout?: GroupLayout;

  // Exists only when AssessmentVMNodeType === 'QUESTION'
  outputValue?: UserInput;
  questionSpecs?: IQuestionSpec;

  // RF_TODO: can we get rid of one of them?
  pictures: Picture[];
  groupPictures?: Picture[];
}

export interface RawPicture {
  id: string;
  content: any;
  __error?: boolean;
  __dirty?: boolean;
  isUploading: boolean;
}

export interface ApplicationState {
  online: boolean;
  syncing: boolean;
  dirty: boolean;
  newVersionAvailable: boolean;
  networkEnabled: boolean;
  updateAvailable: boolean;
  isInstalledPwaSession: boolean;
  isInvitationLink: boolean;
}

export interface ImagesState {
  syncing: boolean;
  images: string[];
}

/** Begin Conversation **/
export type ConversationMessageType =
  | 'TEXT'
  | 'ORDER'
  | 'INSPECTION'
  | 'LOT'
  | 'STATUS'
  | 'REPORT';

export interface ConversationMessage {
  userId: string;
  creationDate: any;

  message?: string;

  order?: Order;
  report?: Report;
  lot?: Lot;
  inspection?: Inspection;

  type: ConversationMessageType;
  className?: string;
}

export interface LastRead {
  creationDate: any;
  userId: string;
}

export interface Conversation {
  id?: string;
  creationDate: Date;
  author: string;
  title?: string;
  subtitle?: string;
  order?: Order;

  members: string[];
  pendingInvites?: string[];
  listOfEmails?: string[];
  archived?: string[];

  lastMessages: ConversationMessage[];
  lastRead: { [key in string]: number };
}

export interface Paging {
  size: number;
  offset: number;
}

/*** Other **/

export type WasteReason = TransformationReason; // can be extended in the future

export interface Waste {
  lotId: string;
  reason: WasteReason;
  type: OrderType;
  lotActionId: string;
  date: Date;
  wasteVolumeInKg: number;
}

/*** Application Model **/
export interface PopoverModel {
  visible: boolean;
  event?: any;
}

export interface LotPopoverModel extends PopoverModel {
  isBuyerPosition?: boolean;
  myOrgOrder?: boolean;
  order?: Order | Report;
  isContactOrder?: boolean;
  origOrder?: Order;
}
