/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './../@api_core/BaseHttpRequest'
import type { OpenAPIConfig } from './../@api_core/OpenAPI'
import { AxiosHttpRequest } from './../@api_core/AxiosHttpRequest'
import { AdminService } from './services/AdminService'
import { LocationService } from './services/LocationService'
import { OrganizationService } from './services/OrganizationService'
import { PartnerService } from './services/PartnerService'
import { PowerSyncService } from './services/PowerSyncService'
import { TaskService } from './services/TaskService'
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest
export class cadovaClient {
    public readonly admin: AdminService
    public readonly location: LocationService
    public readonly organization: OrganizationService
    public readonly partner: PartnerService
    public readonly powerSync: PowerSyncService
    public readonly task: TaskService
    public readonly request: BaseHttpRequest
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        })
        this.admin = new AdminService(this.request)
        this.location = new LocationService(this.request)
        this.organization = new OrganizationService(this.request)
        this.partner = new PartnerService(this.request)
        this.powerSync = new PowerSyncService(this.request)
        this.task = new TaskService(this.request)
    }
}
