import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  withIonLifeCycle,
} from '@ionic/react';

import { arrowDownOutline, arrowUpOutline, filterCircle } from 'ionicons/icons';
import { debounce, find, get, isEqual, map } from 'lodash';
import React, { RefObject, useState, useContext } from 'react';
import Select from 'react-select';
import { ctxOrg, ctxProducts, delay } from './App';
import CardReport from './CardReport';
import { firestore } from './ConfigFirebase';
import { getExternalReports, getInternalReports } from './DataApp';
import { PagedResult } from './DataStorage';
import withContext, { ContextProps } from './HOC/withContext';
import { isMobile, shallowEqual } from './HelperUtils';
import { Lot, LotPosition, Order, Organisation, UserProfile } from './Model';
import { AGTabs, ReportFilterType, agTabsReports } from './ModelAGTabs';
import PageFilters from './PageFilters';
import { FilterToolbar } from './PageQualityControl';
import './PageQualityControl.scss';
import {
  hasLocationRestriction,
  isFreeQCTier,
  userHasPermission,
} from './PermissionsService';
import { adaptSectionFilters, computeNewFilters, searchFields } from './SearchService';
import { CDatePicker } from './components/CDatePicker';
import { TimeLabel } from './ModelInsight';

interface Props {
  onUserPropertiesChanged: Function;
}

interface Filter {
  search?: string;
  type: ReportFilterType;
}
interface ScrollTop {
  upcoming?: number;
  feedback?: number;
  internalReports?: number;
}

interface State {
  internalReports?: PagedResult<Order>;
  // outgoingOrders?: PagedResult<Order>;
  // lots?: PagedResult<Lot>;
  upcomingReports?: PagedResult<Order>;
  feedbackReports?: PagedResult<Order>;

  filter: Filter;
  batchesNoMore?: boolean;
  showNewLotModal?: boolean;
  showNewOrderModal?: boolean;
  showLinkLotModal?: boolean;
  filters?: any;
  loadingMore: boolean;
  showListMode?: boolean;
  changingFilters?: boolean;

  loadingOfflineCache: boolean;
  multipleSelection: boolean;
  multipleSelectionArray: Array<LotPosition>;
  scrollTop: ScrollTop;

  // set from CardOrder
  currContactPosition: LotPosition;
  currOrder: Order;

  alert: { message: string; header: string; show: boolean; onAccept: any };
  processing: boolean;
  showCalendar?: boolean;

  agTabs: AGTabs<ReportFilterType>;

  closeLotOptionsFromCardOrder?: () => void;
}

class PageReportDashboard extends React.Component<Props & ContextProps, State> {
  private pageRef: RefObject<HTMLElement> = React.createRef();
  private contentRef: RefObject<HTMLIonContentElement> = React.createRef();
  private searchBarRef: RefObject<HTMLIonSearchbarElement> = React.createRef();

  private defaultAlert = { message: '', header: '', show: false, onAccept: (_) => {} };
  // private orderRef = (orderId: string) => firestore.collection('organisation').doc(this.props.profile.organisationId).collection('common').doc(this.props.profile.organisationId).collection('order').doc(orderId);

  private stopInfiniteScroll;
  private internalReportsSubscriptions = [];
  private feedbackSubscriptions = [];
  private upcomingSubscriptions = [];

  private debouncedScroll;

  savedFiltersKey = 'report-filters';

  constructor(props: Props) {
    super(props);

    const parameters = new URLSearchParams(window.location.search);
    // const profileAGTabs = get(this.props.profile, 'displayReportTabs[0]');
    const startingTab = get(this.props.profile, 'displayReportTabs[0]') ?? 'MY-REPORTS';
    // const startingTab = "MY-REPORTS";
    const startType: Filter = {
      type: (startingTab ??
        parameters.get('tab')?.toLocaleUpperCase()) as ReportFilterType,
    };

    this.debouncedScroll = debounce((e) => {
      // console.log('scroll', e)
      const el = document.getElementById('searchbar-container1');
      if (e.detail.deltaY > 0) {
        // console.log('DOWN')
        el.classList.add('out');
      } else {
        // console.log('UP')
        el.classList.remove('out');
      }
      if (e.detail.scrollTop < 112) {
        el.classList.remove('out');
      }
      if (e.detail.scrollTop < 10) {
        el.classList.remove('shadow');
      } else {
        el.classList.add('shadow');
      }
    }, 50);

    const defaultFilterState: Filter = startType;

    this.state = {
      filter: defaultFilterState,
      loadingMore: false,
      loadingOfflineCache: false,
      multipleSelection: false,
      scrollTop: {
        internalReports: 0,
        upcoming: 0,
        feedback: 0,
      },
      multipleSelectionArray: [],
      filters: {
        ...this.props.filters,
        arrivalDate: agTabsReports[defaultFilterState.type]?.minDate,
        arrivalDateMax: agTabsReports[defaultFilterState.type]?.maxDate,
        dateProperty: agTabsReports[defaultFilterState.type]?.dateProperty,
        sort: agTabsReports[defaultFilterState.type]?.sorting,
      },
      currContactPosition: undefined,
      currOrder: undefined,
      alert: this.defaultAlert,
      processing: false,
      agTabs: agTabsReports,
    };
  }

  shouldComponentUpdate(nextProps: Props & ContextProps, nextState: State) {
    // const { showOrders } = this.state
    if (!shallowEqual(this.props.profile, nextProps.profile)) {
      console.log('profiles not equal, re-rendering PageQC');
      return true;
    }
    if (!shallowEqual(this.props.organisation?.name, nextProps.organisation?.name)) {
      console.log('profiles not equal, re-rendering PageQC');
      return true;
    }
    if (
      !isEqual(this.props.profile.userPermissions, nextProps.profile.userPermissions)
    ) {
      return true;
    }
    if (!isEqual(nextProps.filters, this.props.filters)) {
      return true;
    }
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    // console.log('NOOOT re-rendering PageQC');

    return false;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props & ContextProps, prevState: State) {
    // update content scrollTop to previous recorded one
    // only when the filter.type has changed
    // to keep scroll in position when clicking the segment bar
    if (prevState.filter.type !== this.state.filter.type)
      this.contentRef.current.scrollToPoint(
        0,
        this.state.scrollTop[this.state.filter.type.toLocaleLowerCase()]
      );

    // check for changes in the permissions
    if (
      !isEqual(prevProps.profile.userPermissions, this.props.profile.userPermissions)
    ) {
      this.fetchData();
    }

    // Check for changes in the profile tab selection
    if (
      !isEqual(
        prevProps.profile.displayReportTabs,
        this.props.profile.displayReportTabs
      )
    ) {
      const userHasTab = find(
        this.props.profile.displayReportTabs,
        (tab) => this.state.filter.type === tab
      );
      if (!userHasTab) {
        this.setState({
          filter: {
            ...this.state.filter,
            type: this.props.profile.displayReportTabs[0],
          },
        });
      }
    }

    // check for changes in date filters
    if (!isEqual(prevState.agTabs, this.state.agTabs)) {
      this.fetchData();
    }

    // check for changes in filters.lot.article
    if (!isEqual(prevState.filters?.lot?.article, this.state.filters?.lot?.article)) {
      console.log('PREV', prevState.filters?.lot?.article);
      console.log('NEW', this.state.filters?.lot?.article);
      this.fetchData();
    }

    if (!isEqual(prevProps.filters, this.props.filters) && this.props.filters != null) {
      // console.log("FROM DID YPOUIDATE", prevProps.applicationContext?.filters, this.props.applicationContext?.filters)
      this.setState(
        {
          loadingMore: true,
          filters: {
            ...computeNewFilters(this.state.filters, this.props.filters),
            search: '',
          },
          upcomingReports: undefined,
          feedbackReports: undefined,
          internalReports: undefined,
        },
        () => {
          try {
            this.fetchData();
          } catch (err) {
            console.error('error while loading fetchDataFiltered:', err);
          } finally {
            // console.log('fetcheDataFiltered done')
          }
        }
      );
    }
  }

  ionViewWillEnter() {
    // await this.fetchData(false, false);
  }

  componentWillUnmount(): void {
    this.internalReportsSubscriptions.forEach((unsubscribe) => unsubscribe?.());
    this.feedbackSubscriptions.forEach((unsubscribe) => unsubscribe?.());
    this.upcomingSubscriptions.forEach((unsubscribe) => unsubscribe?.());
  }

  openNewLotModal(
    currentPosition: LotPosition,
    order: Order,
    closeLotOptionsFromCardOrder?: () => void
  ) {
    this.setState({
      showNewLotModal: true,
      currContactPosition: currentPosition,
      currOrder: order,
      closeLotOptionsFromCardOrder,
    });
  }

  openLinkLotModal(
    currentPosition: LotPosition,
    order: Order,
    closeLotOptionsFromCardOrder?: () => void
  ) {
    this.setState({
      showLinkLotModal: true,
      currContactPosition: currentPosition,
      currOrder: order,
      closeLotOptionsFromCardOrder,
    });
  }

  fetchData(more?: boolean) {
    try {
      // console.log('FETCH DATA: filters', this.state.filters);
      // console.log('AGTAB SORT: ', this.state.agTabs[this.state.filter?.type]?.sorting)
      if (!userHasPermission(this.props.profile, 'VIEW', 'ORDER')) {
        this.setState({
          internalReports: { data: [], cursor: undefined },
          feedbackReports: { data: [], cursor: undefined },
          upcomingReports: { data: [], cursor: undefined },
          loadingMore: false,
        });
        return;
      }
      const max: number = more ? 12 : 12;
      const { filter, internalReports, feedbackReports, upcomingReports } = this.state;
      const { profile } = this.props;
      const { organisationId } = profile;

      let { filters } = this.state;
      let filterType: ReportFilterType = this.state.filter.type;
      filters.dateProperty = agTabsReports[this.state.filter.type]?.dateProperty;
      filters.sort = this.state.agTabs[this.state.filter.type]?.sorting;
      filters.arrivalDate = this.state.agTabs[this.state.filter.type]?.minDate;
      filters.arrivalDateMax = this.state.agTabs[this.state.filter.type]?.maxDate;

      let search = filter.search; // what is inside the search bar input
      let page;

      filters = adaptSectionFilters(
        filters,
        searchFields.PageReportDashboard[filter.type]
      );

      switch (filterType) {
        case 'MY-REPORTS':
          page = more ? internalReports : undefined;
          if (!page) {
            this.setState({ internalReports: undefined });
            this.internalReportsSubscriptions.forEach((unsubscribe) => unsubscribe?.());
            this.internalReportsSubscriptions = [];
          }

          this.internalReportsSubscriptions.push(
            getInternalReports(
              firestore,
              organisationId,
              search,
              page,
              max,
              filters,
              (result) => {
                this.setState({ internalReports: result, loadingMore: false });
                this.stopInfiniteScroll = false;
              },
              hasLocationRestriction(this.props.profile)
            )
          );
          return;

        case 'FEEDBACK':
          page = more ? feedbackReports : undefined;
          if (!page) {
            this.setState({ feedbackReports: undefined });
            this.feedbackSubscriptions.forEach((unsubscribe) => unsubscribe?.());
            this.feedbackSubscriptions = [];
          }
          this.feedbackSubscriptions.push(
            getExternalReports(
              firestore,
              organisationId,
              'FEEDBACK',
              search,
              page,
              max,
              filters,
              (result) => {
                this.setState({ feedbackReports: result, loadingMore: false });
                this.stopInfiniteScroll = false;
              },
              hasLocationRestriction(this.props.profile)
            )
          );
          return;

        case 'UPCOMING':
          page = more ? upcomingReports : undefined;
          if (!page) {
            this.setState({ upcomingReports: undefined });
            this.upcomingSubscriptions.forEach((unsubscribe) => unsubscribe?.());
            this.upcomingSubscriptions = [];
          }
          this.upcomingSubscriptions.push(
            getExternalReports(
              firestore,
              organisationId,
              'UPCOMING',
              search,
              page,
              max,
              filters,
              (result) => {
                this.setState({ upcomingReports: result, loadingMore: false });
                this.stopInfiniteScroll = false;
              },
              hasLocationRestriction(this.props.profile)
            )
          );
          return;
      }
    } catch (error) {
      alert(error);
    }
  }

  private async fetchDataFiltered(filters: any) {
    //@ts-ignore
    this.searchBarRef.current.value = '';
    this.props.setFilters(filters);
  }

  async searchByText(value: string) {
    this.contentRef.current.scrollToTop(500);
    value = value.replace(/\./gi, '/');

    const locationRestriction = hasLocationRestriction(this.props.profile);

    if (locationRestriction) {
      await this.setState({
        filters: {
          ...this.state.filters,
          locationId: locationRestriction,
        },
      });
    }

    await this.setState({
      loadingMore: true,
      filters: {
        ...this.state.filters,
        search: value.toLocaleUpperCase().replace(/\//g, '-'),
      },
      filter: {
        ...this.state.filter,
        search: value.toLocaleUpperCase().replace(/\//g, '-'),
      },
      internalReports: undefined,
      upcomingReports: undefined,
      feedbackReports: undefined,
    });
    await this.fetchData();
  }

  async changeFilterType(value: ReportFilterType) {
    // get the scrollTop and save it
    let domScroll = await this.contentRef.current.getScrollElement();

    this.setState({
      changingFilters: true,
      scrollTop: {
        internalReports:
          this.state.filter.type === 'MY-REPORTS'
            ? domScroll.scrollTop
            : this.state.scrollTop.internalReports,
        upcoming:
          this.state.filter.type === 'UPCOMING'
            ? domScroll.scrollTop
            : this.state.scrollTop.upcoming,
        feedback:
          this.state.filter.type === 'FEEDBACK'
            ? domScroll.scrollTop
            : this.state.scrollTop.feedback,
      },
      filter: {
        search: this.state.filter.search,
        type: value,
      },
      filters: {
        ...this.state.filters,
        arrivalDate: this.state.agTabs[value]?.minDate,
        arrivalDateMax: this.state.agTabs[value]?.maxDate,
        dateProperty: this.state.agTabs[value]?.dateProperty,
        sort: this.state.agTabs[value]?.sorting,
      },
    });

    // fetch only when there is no data in state
    if (
      (value === 'MY-REPORTS' && !this.state.internalReports) ||
      (value === 'FEEDBACK' && !this.state.feedbackReports) ||
      (value === 'UPCOMING' && !this.state.upcomingReports)
    ) {
      this.contentRef.current.scrollToTop(500);
      return this.fetchData();
    }

    // stop infiniteScroll to trigger
    // this.stopInfiniteScroll = true;
    // setTimeout(() => this.stopInfiniteScroll = false, 500)
  }

  addMultipleSelection(lot: Lot) {
    if (!this.state.multipleSelection) {
      return;
    }

    let position: LotPosition = {
      article: lot.article,
      lotId: lot.id,
      numBoxes: lot.transient?.numBoxes,
    };

    let { multipleSelectionArray } = Object.assign({}, this.state);
    let index = multipleSelectionArray.findIndex((p) => p.lotId === lot.id);

    if (index >= 0) {
      multipleSelectionArray.splice(index, 1);
    } else {
      multipleSelectionArray.push(position);
    }
    this.setState({ multipleSelectionArray });
    console.log('multipleSelectionArray', multipleSelectionArray);
  }

  toggleMultipleSelection() {
    const { multipleSelection } = this.state;
    if (!multipleSelection) {
      this.setState({ multipleSelection: !multipleSelection });
    } else {
      this.setState({
        multipleSelection: !multipleSelection,
        multipleSelectionArray: [],
      });
    }
  }

  async removeFilter(key, attr?) {
    let { filters } = this.state;
    if (attr) {
      if (attr === 'onlyInStock') {
        filters[attr] = false;
      } else {
        delete filters[attr];
      }
    } else {
      delete filters.lot.article?.[key];
    }
    // if (Object.keys(filters.lot.article).length === 0) filters = undefined
    console.log('filters after removed', filters);

    this.props.setFilters(filters);
  }

  async infiniteScroll(ev) {
    if (this.stopInfiniteScroll === true) {
      this.stopInfiniteScroll = false;
      ev.target?.complete();
      return;
    }
    // console.log('infiniteeeeee');

    this.setState({ loadingMore: true });
    const more = true;
    try {
      await this.fetchData(more);
    } catch (err) {
      console.error('error while loading more:', err);
    } finally {
      ev.target?.complete();
    }
  }

  renderList() {
    let myReportsDOM, upcomingReportsDOM, feedbackReportsDOM;
    let internalReports: Order[] = this.state.internalReports?.data;
    let feedbackReports = this.state.feedbackReports?.data;
    let upcomingReports = this.state.upcomingReports?.data;

    // MyReports
    if (!internalReports) {
      myReportsDOM = [...Array(12).keys()].map((item) => (
        <CardReport key={item} loading={true} />
      ));
    } else {
      myReportsDOM = internalReports //.slice(-48)
        .map((order, idx) => (
          <CardReport
            key={order.id}
            searchString={this.state.filter.search}
            report={order}
            index={idx}
            routerLink={`/secure/report/${order.id}`}
          />
        ));

      myReportsDOM = myReportsDOM.concat(
        [...Array(3).keys()].map((i) => (
          <CardReport
            key={'skmy-' + i++}
            loading={true}
            style={{
              visibility:
                this.state.loadingMore && !this.state.batchesNoMore
                  ? 'visible'
                  : 'hidden',
            }}
          />
        ))
      );

      if (internalReports.length === 0) {
        myReportsDOM = <h1 className="h1-not-found">no results</h1>;
      }
    }

    // Feedback
    if (!feedbackReports) {
      feedbackReportsDOM = [...Array(12).keys()].map((item) => (
        <CardReport key={item} loading={true} />
      ));
    } else {
      feedbackReportsDOM = feedbackReports //.slice(-48)
        .map((order, idx) => (
          <CardReport
            key={order.id}
            searchString={this.state.filter.search}
            report={order}
            index={idx}
            routerLink={`/secure/report/${order.id}/external`}
          />
        ));

      feedbackReportsDOM = feedbackReportsDOM.concat(
        [...Array(3).keys()].map((i) => (
          <CardReport
            key={'skfee-' + i++}
            loading={true}
            style={{
              visibility:
                this.state.loadingMore && !this.state.batchesNoMore
                  ? 'visible'
                  : 'hidden',
            }}
          />
        ))
      );

      if (feedbackReports.length === 0) {
        feedbackReportsDOM = <h1 className="h1-not-found">no results</h1>;
      }
    }

    // Upcoming
    if (!upcomingReports) {
      upcomingReportsDOM = [...Array(12).keys()].map((item) => (
        <CardReport key={item} loading={true} />
      ));
    } else {
      upcomingReportsDOM = upcomingReports.map((order, idx) => {
        return (
          <CardReport
            key={order.id}
            searchString={this.state.filter.search}
            report={order}
            index={idx}
            routerLink={`/secure/report/${order.id}/external`}
          />
        );
      });

      upcomingReportsDOM = upcomingReportsDOM.concat(
        [...Array(3).keys()].map((i) => (
          <CardReport
            key={'skup-' + i++}
            loading={true}
            style={{
              visibility:
                this.state.loadingMore && !this.state.batchesNoMore
                  ? 'visible'
                  : 'hidden',
            }}
          />
        ))
      );

      if (upcomingReports.length === 0) {
        upcomingReportsDOM = <h1 className="h1-not-found">no results</h1>;
      }
    }

    // we render the three lists and toggle the view depending the state.filter.type
    // this way we prevent rerendering the list when the filter type changes
    // and it's only a display:yes/no for the DOM.
    return (
      <>
        <div
          style={{ display: this.state.filter.type === 'UPCOMING' ? 'grid' : 'none' }}
        >
          {upcomingReportsDOM}
        </div>
        <div
          style={{ display: this.state.filter.type === 'MY-REPORTS' ? 'grid' : 'none' }}
        >
          {myReportsDOM}
        </div>
        <div
          style={{ display: this.state.filter.type === 'FEEDBACK' ? 'grid' : 'none' }}
        >
          {feedbackReportsDOM}
        </div>
      </>
    );
  }

  onListScroll(e) {
    // console.log('nana', e)
    this.debouncedScroll(e);
  }

  getDateForFilter = (date) => {
    let dateForFilter = undefined;
    if (date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      // These transformations insure the format for min and max attributes of IonDateTime Component
      // YYYY-MM-DD or undefined
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      dateForFilter = `${year}-${month}-${day}`;
    }

    return dateForFilter;
  };

  handleSortingOnClick = () => {
    const filterType = this.state.filter.type;
    let sorting = undefined;
    if (this.state.agTabs[filterType].sorting === 'desc') {
      sorting = 'asc';
    }
    if (this.state.agTabs[filterType].sorting === 'asc') {
      sorting = 'desc';
    }
    this.setState({
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          sorting: sorting,
        },
      },
      filters: {
        ...this.state.filters,
        sort: sorting,
      },
    });
  };

  changeDate(type: 'max' | 'min', date: Date) {
    this.setState({
      filters: { ...this.state.filters, arrivalDateMax: date },
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          [type + 'Date']: date,
        },
      },
    });
  }

  getFilterDateValue(type: 'max' | 'min', asDate = false) {
    const agTabs = this.state.agTabs;
    const date = new Date(agTabs[this.state.filter.type]?.[type + 'Date']);
    if (asDate) return date;
    return date.toDateString();
  }

  changeDates(minDate: Date, maxDate: Date, range: TimeLabel) {
    this.setState({
      filters: { ...this.state.filters, arrivalDateMax: maxDate },
      agTabs: {
        ...this.state.agTabs,
        [this.state.filter.type]: {
          ...this.state.agTabs[this.state.filter.type],
          minDate: minDate,
          maxDate: maxDate,
          range,
        },
      },
    });
  }

  render() {
    const { filter, filters, loadingMore, alert, processing, agTabs } = this.state;
    const { profile, organisation } = this.props;

    console.log(
      'render report dashboard ---->',
      this.state.agTabs,
      this.state.agTabs[this.state.filter.type],
      this.state.filter.type
    );

    //Check if user has not selected any tabs on profile settings and display a message to select them
    if (profile.displayReportTabs?.length === 0)
      return (
        <IonPage className="page-quality-control ion-page">
          <IonContent>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>No Tabs Selected</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                Please go to your settings page, and select the tabs you would like to
                see!
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonPage>
      );

    const inboxUnreadCount = profile?.unreadConversationsCount ?? 0;

    return (
      <IonPage className="page-quality-control ion-page page-report-dashboard">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {/* <IonButton style={{visibility: (this.state.loadingMore) ? 'visible' : 'hidden'}}>
              <IonSpinner name="dots" color="dark" />
            </IonButton> */}
              <IonMenuButton color="dark" />
              {inboxUnreadCount > 0 && (
                <IonBadge slot="end" color="danger">
                  {inboxUnreadCount}
                </IonBadge>
              )}
            </IonButtons>
            <SegmentNavigation
              value={this.state.filter.type}
              profile={this.props.profile}
              onChange={this.changeFilterType.bind(this)}
              organisation={organisation}
            />
            <IonButtons slot="end" className="top-filter-button">
              <MainToolbar
                profile={this.props.profile}
                onFilter={this.fetchDataFiltered.bind(this)}
                filters={filters}
                filterType={filter.type}
                agTabs={agTabs}
                setAgTabs={(agTabs: AGTabs<ReportFilterType>) =>
                  this.setState({ agTabs })
                }
              />
            </IonButtons>
            {/* <IonButtons style={{gap: '2px', justifyContent: 'flex-end'}} slot="end" className="top-view-buttons">
            <IonButton className={this.state.showListMode ? "selected" : ''} color="medium" onClick={_=>this.setState({showListMode: true})}>
              <IonIcon icon={listOutline} />
            </IonButton>
            <IonButton className={this.state.showListMode ? "" : 'selected'} color="medium" onClick={_=>this.setState({showListMode: false})}>
              <IonIcon icon={gridOutline} />
            </IonButton>
          </IonButtons> */}
          </IonToolbar>
        </IonHeader>
        <IonContent
          ref={this.contentRef}
          scrollEvents={true}
          onIonScroll={(e) => this.onListScroll(e)}
        >
          {/* <IonRefresher slot="fixed"
          onIonRefresh={evt => { this.fetchData(); setTimeout(evt.detail.complete, 800) }}>
          <IonRefresherContent />
        </IonRefresher> */}

          {processing && <IonLoading isOpen={processing} />}

          {alert.show && (
            <IonAlert
              isOpen={alert.show}
              onDidDismiss={() =>
                this.setState({
                  alert: this.defaultAlert,
                  closeLotOptionsFromCardOrder: undefined,
                })
              }
              header={alert.header}
              cssClass="alert-qc"
              message={alert.message}
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () =>
                    this.setState({
                      alert: this.defaultAlert,
                      closeLotOptionsFromCardOrder: undefined,
                    }),
                },
                {
                  text: 'Proceed',
                  cssClass: 'accept-button-alert',
                  handler: alert.onAccept,
                },
              ]}
            />
          )}

          {this.state.showCalendar && (
            <div
              id="prd-cal"
              className="cdate-picker-wrapper"
              onClick={async (e) => {
                // console.log(e.target, e.currentTarget, e.detail)
                if (e.target === e.currentTarget) {
                  document.getElementById('prd-cal').classList.add('out');
                  await delay(550);
                  this.setState({ showCalendar: false });
                }
              }}
            >
              <CDatePicker
                startDate={this.getFilterDateValue('min', true) as Date}
                endDate={this.getFilterDateValue('max', true) as Date}
                onConfirm={async (dates) => {
                  if (!!dates) {
                    const { startDate, endDate, range } = dates[0];
                    this.changeDates(startDate, endDate, range);
                  }
                  document.getElementById('prd-cal').classList.add('out');
                  await delay(550);
                  this.setState({ showCalendar: false });
                }}
              />
            </div>
          )}

          {/* { // multiple selection banner
          this.state.multipleSelection && this.state.filter.type === "STOCK" &&
          <div className="multiple-selection-message">
            multiple selection ON
            {this.state.multipleSelectionArray?.length > 0 &&
              <>&nbsp;({this.state.multipleSelectionArray.length})</>
            }
          </div>
        } */}
          <div
            className="searchbar-container"
            id="searchbar-container1"
            data-tip={'prd-search'}
          >
            <div>
              <div className="wrapper">
                <IonSearchbar
                  debounce={500}
                  onIonChange={(evt) => this.searchByText(evt.detail.value)}
                  placeholder="SEARCH BY ID"
                  mode="ios"
                  inputmode="text"
                  ref={this.searchBarRef}
                />
                <ctxProducts.Consumer>
                  {(productList) => {
                    if (this.props.profile.products) {
                      // TODO: options should be either agProductId or productId depending on Organisation Settings. For now only displaying agProductId
                      productList = (productList ?? []).filter(
                        (p) => this.props.profile.products.indexOf(p.productId) >= 0
                      );
                    }
                    let options = (productList ?? [])
                      .sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
                      .map((b) => {
                        return {
                          value: b.productId,
                          label: b.agProductId ?? b.productId,
                        };
                      });

                    return this.state.filter?.search?.length > 0 ? (
                      <Select
                        isDisabled
                        placeholder={'Disabled while filtering by id'}
                        value={[]}
                        className={'products-select desktop'}
                        classNamePrefix={'react-select'}
                      />
                    ) : (
                      <Select
                        isClearable={true}
                        isDisabled={this.state.filter?.search?.length > 0}
                        isMulti
                        value={this.state.filters.lot?.article?.productId ?? []}
                        options={options}
                        isLoading={!productList}
                        placeholder={'Select product(s)'}
                        className={'products-select desktop'}
                        classNamePrefix={'react-select'}
                        onChange={(evt) =>
                          this.props.setFilters({
                            ...this.state.filters,
                            lot: {
                              ...this.state.filters.lot,
                              article: {
                                productId: evt as any,
                              },
                            },
                          })
                        }
                      />
                    );
                  }}
                </ctxProducts.Consumer>
                <div className={'datePicker'}>
                  <div className={'datePicker-label'}>
                    {' '}
                    {agTabs[this.state.filter.type]?.dateLabel}
                  </div>
                  <div className={'datePicker-selects'}>
                    <IonDatetime
                      className="datePicker-start"
                      value={this.getFilterDateValue('min') as string}
                      max={this.getDateForFilter(agTabs[filter.type]?.maxDate)}
                      onIonChange={(o) => {
                        this.changeDate('min', new Date(o.detail.value));
                      }}
                    />
                    <IonDatetime
                      value={this.getFilterDateValue('max') as string}
                      min={this.getDateForFilter(
                        agTabs[this.state.filter.type]?.minDate
                      )}
                      onIonChange={(o) => {
                        this.changeDate('max', new Date(o.detail.value));
                      }}
                    />
                  </div>
                </div>

                {this.state?.agTabs[this.state.filter.type]?.sorting === 'desc' ? (
                  <IonIcon
                    icon={arrowDownOutline}
                    onClick={() => this.handleSortingOnClick()}
                    className="sorting-icon"
                  />
                ) : (
                  <IonIcon
                    icon={arrowUpOutline}
                    onClick={() => this.handleSortingOnClick()}
                    className="sorting-icon"
                  />
                )}
              </div>
            </div>
            <div className="wrapper">
              <ctxProducts.Consumer>
                {(productList) => {
                  if (this.props.profile.products) {
                    // TODO: options should be either agProductId or productId depending on Organisation Settings. For now only displaying agProductId
                    productList = (productList ?? []).filter(
                      (p) => this.props.profile.products.indexOf(p.productId) >= 0
                    );
                  }
                  let options = (productList ?? [])
                    .sort((a, b) => a?.agProductId?.localeCompare(b.agProductId))
                    .map((b) => {
                      return {
                        value: b.productId,
                        label: b.agProductId ?? b.productId,
                      };
                    });

                  return this.state.filter?.search?.length > 0 ? (
                    <Select
                      isDisabled
                      placeholder={'Disabled while filtering by id'}
                      value={[]}
                      className={'products-select mobile'}
                      classNamePrefix={'react-select'}
                    />
                  ) : (
                    <Select
                      isClearable={true}
                      isMulti
                      data-tip={'pqc-product-filter'}
                      value={this.state.filters.lot?.article?.productId ?? []}
                      options={options}
                      isLoading={!productList}
                      placeholder={'Select product(s)'}
                      className={'products-select mobile'}
                      classNamePrefix={'react-select'}
                      onChange={(evt) => {
                        this.setState({
                          filters: {
                            ...this.state.filters,
                            lot: {
                              ...(this.state.filters.lot ?? {}),
                              article: {
                                productId: evt as any,
                              },
                            },
                          },
                        });
                      }}
                    />
                  );
                }}
              </ctxProducts.Consumer>
            </div>
            <div className="wrapper" style={{ justifyContent: 'flex-start' }}>
              <FilterToolbar
                filters={filters}
                onRemoveFilter={this.removeFilter.bind(this)}
                searchText={this.state.filter.search}
                searchFields={searchFields.PageReportDashboard[filter.type]}
              />
            </div>
          </div>
          <div className="cards-wrapper">
            <IonList
              className={
                'masonry-with-columns ' +
                (this.state.showListMode === true ? 'list-view' : '')
              }
            >
              {this.renderList()}
            </IonList>
            <PageFilters
              organisation={this.props.organisation}
              searchFields={searchFields.PageReportDashboard[filter.type]}
              searchByText={this.searchByText.bind(this)}
              filterType={filter.type}
              profile={profile}
              filters={filters}
              onFilter={(filters) => {
                this.fetchDataFiltered(filters);
              }}
              isComponent={true}
              searchOnChange={true}
              handleSortingOnClick={this.handleSortingOnClick.bind(this)}
              onDateClick={() => this.setState({ showCalendar: true })}
              dateFilter={{
                dateLabel: agTabs[this.state.filter.type]?.dateLabel ?? '',
                sorting: agTabs[this.state.filter.type]?.sorting ?? 'desc',
                start: {
                  value: this.getFilterDateValue('min'),
                  max: this.getDateForFilter(agTabs[filter.type]?.maxDate),
                  onChange: (o) => {
                    this.changeDate('min', new Date(o.detail.value));
                  },
                },
                end: {
                  value: this.getFilterDateValue('max'),
                  min: this.getDateForFilter(agTabs[this.state.filter.type]?.minDate),
                  onChange: (o) => {
                    this.changeDate('max', new Date(o.detail.value));
                  },
                },
              }}
              agTabs={agTabs}
              setAgTabs={(agTabs: AGTabs<any>) => this.setState({ agTabs })}
            />
          </div>
          {!isMobile() && <div className="loading-more-button">
            <IonButton color="dark" onClick={(e) => this.infiniteScroll(e)}>
              Load more
            </IonButton>
          </div>}
          <IonInfiniteScroll
            threshold="100px"
            onIonInfinite={(ev) => this.infiniteScroll(ev)}
          >
            &nbsp; &nbsp;
          </IonInfiniteScroll>

          {/* ---------------------------------------------------------------- */}
          {/* Fab buttons and modals */}
          {/* ++ none for now ++ */}
          {/* ---------------------------------------------------------------- */}
        </IonContent>
      </IonPage>
    );
  }

  closeLotModal() {
    !!this.state.closeLotOptionsFromCardOrder &&
      this.state.closeLotOptionsFromCardOrder();
    this.setState({
      showNewLotModal: false,
      showLinkLotModal: false,
      currContactPosition: undefined,
      currOrder: undefined,
      closeLotOptionsFromCardOrder: undefined,
    });
  }
}

export default React.memo(
  withContext(withIonLifeCycle(PageReportDashboard), [
    'profile',
    'organisation',
    'filters',
    'setFilters',
  ])
);

// ----------------------------------------------------------------
// SegmentNavigation inner component
// ----------------------------------------------------------------
const SN = ({
  value,
  profile,
  onChange,
  organisation,
}: {
  value;
  profile: UserProfile;
  organisation: Organisation;
  onChange;
}) => {
  const reportTabs = (): AGTabs<ReportFilterType> => {
    const filteredTabs = { ...agTabsReports };
    if (isFreeQCTier(organisation?.settings)) {
      delete filteredTabs['MY-REPORTS'];
    }
    return filteredTabs;
  };

  const renderSegmentBar = () => {
    return (
      <>
        {map(reportTabs(), (tab) => {
          const userHasDisplayAGTabsProperty = get(profile, 'displayReportTabs');
          // const userHasDisplayAGTabsProperty = false
          const userHasTab = find(
            profile.displayReportTabs,
            (userTab) => tab.id === userTab
          );

          const label =
            tab.id === 'MY-REPORTS'
              ? `${organisation?.name ?? 'Company'} Reports`
              : tab.label;

          if (!userHasDisplayAGTabsProperty) {
            return (
              <IonSegmentButton
                data-tip={`tabs-${tab.id.toLowerCase()}`}
                key={tab.id}
                value={tab.id}
              >
                <span>{label}</span>
                <span>{tab.labelMobile}</span>
              </IonSegmentButton>
            );
          } else {
            if (userHasTab) {
              return (
                <IonSegmentButton
                  data-tip={`tabs-${tab.id.toLowerCase()}`}
                  key={tab.id}
                  value={tab.id}
                >
                  <span>{label}</span>
                  <span>{tab.labelMobile}</span>
                </IonSegmentButton>
              );
            }
          }
        })}
      </>
    );
  };

  return (
    <IonSegment
      scrollable={false}
      value={value}
      onIonChange={(evt) => {
        onChange(evt.detail.value);
      }}
    >
      {renderSegmentBar()}
    </IonSegment>
  );
};

const SegmentNavigation = React.memo(SN, (prev, next) => {
  return (
    isEqual(prev.profile, next.profile) &&
    prev.value === next.value &&
    isEqual(prev.organisation, next.organisation)
  );
});

// ----------------------------------------------------------------
// MainToolbar inner component
// ----------------------------------------------------------------

const MTB = ({ profile, onFilter, filters, filterType, agTabs, setAgTabs }) => {
  const [showPageFilters, setShowPageFilters] = useState(false);
  const organisation = useContext(ctxOrg);

  const renderToolbar = () => (
    <IonButton onClick={() => setShowPageFilters(true)} color="medium">
      <IonIcon icon={filterCircle} />
      <span className="hide-only-mobile">&nbsp;Filter</span>
      {/* Filter */}
    </IonButton>
  );

  return (
    <>
      {renderToolbar()}

      <IonModal
        isOpen={showPageFilters || false}
        cssClass={'modal-filter'}
        onDidDismiss={(_) => setShowPageFilters(false)}
      >
        <PageFilters
          organisation={organisation}
          searchFields={searchFields.PageReportDashboard[filterType]}
          filterType={filterType}
          profile={profile}
          filters={filters}
          onCancel={() => setShowPageFilters(false)}
          onFilter={(filters) => {
            setShowPageFilters(false);
            onFilter(filters);
          }}
          agTabs={agTabs}
          setAgTabs={setAgTabs}
        />
      </IonModal>
    </>
  );
};

const MainToolbar = React.memo(MTB);
// React.memo(MTB, (prev, next) => {
//   return (
//     true
//   )
// })
