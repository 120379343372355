import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonTextarea,
  IonTitle,
  // IonTitle,
  IonToolbar,
  useIonAlert,
  withIonLifeCycle,
} from '@ionic/react';
import { chevronBackOutline, personCircleOutline, send } from 'ionicons/icons';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import useLongPress from 'react-use/lib/useLongPress';
import CardInspection from './CardInspection';
import CardLot from './CardLot';
import CardOrder from './CardOrder';
import CardReport from './CardReport';
import { firestore } from './ConfigFirebase';
import {
  addTextMessage,
  conversationTitle,
  getConversationMessagesSnapshot,
  getConversationSnapshot,
  messagesUnread,
  updateLastRead,
} from './DataStorage';
import withContext, { ContextProps } from './HOC/withContext';
import { formatDate } from './HelperUtils';
import { Conversation, ConversationMessage } from './Model';
import './PageConversation.scss';
// import i18n from "i18next";

// import { ctxContacts } from './App';
// import ViewUser from "./ViewUser";
// import ViewContactName from "./ViewContactName";
// ----------------------------------------------------------------

interface Props {
  conversationId: string;
  newMessage?: boolean;
}

interface State {
  conversation: Conversation;
  conversationMessages: ConversationMessage[];
  conversationId: string;
  showSidebar?: boolean;
}

class PageConversation extends React.Component<Props & ContextProps, State> {
  private conversationSubscription: any;
  private conversationMessagesSubscription: any;
  private newMessageLoading: boolean;
  private textareaRef: RefObject<HTMLIonTextareaElement> = React.createRef();
  private mounted;

  constructor(props) {
    super(props);

    this.state = {
      conversation: undefined,
      conversationMessages: [],
      conversationId: this.props.conversationId,
      showSidebar: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    setTimeout(() => this.textareaRef.current.setFocus(), 100);
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  ionViewWillLeave() {
    // rendering hack
    document.getElementById('page-conversation').className =
      document.getElementById('page-conversation').className + ' no-bg no-animation';
  }

  ionViewDidLeave() {
    if (this.conversationSubscription) this.conversationSubscription();
    if (this.conversationMessagesSubscription) this.conversationMessagesSubscription();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (prevState.conversationMessages.length === 0) {
      return;
    }
    if (
      prevState.conversationMessages.length !== this.state.conversationMessages?.length
    ) {
      // scroll down when we get new messages
      let lastMessageDOM = document.getElementById('lastMessage');
      setTimeout(() => lastMessageDOM?.scrollIntoView({ behavior: 'smooth' }), 50);
    }
  }

  async ionViewWillEnter() {
    this.newMessageLoading = this.props.newMessage;

    if (this.props.conversationId !== this.state.conversationId) {
      await this.setState({ conversation: undefined });
      document.getElementById('page-conversation').className = document
        .getElementById('page-conversation')
        .className.replace('no-animation', '');
    }

    this.conversationMessagesSubscription = getConversationMessagesSnapshot(
      firestore,
      this.props.conversationId,
      async (conversationMessages) => {
        if (!this.mounted || !conversationMessages) {
          return;
        }

        this.setState({
          conversationMessages: conversationMessages,
        });
      }
    );

    this.conversationSubscription = getConversationSnapshot(
      firestore,
      this.props.conversationId,
      async (conversation: Conversation) => {
        // if (conversation && conversation?.messages?.length < this.state?.conversation?.lastMessages?.length) {
        //   // this object is outdated
        //   return;
        // }

        if (!this.mounted || !conversation) {
          return;
        }

        this.setState({
          conversation: conversation,
          conversationId: this.props.conversationId,
        });

        // Now at this point we update the last read message
        if (messagesUnread(conversation, this.props.profile.id) > 0) {
          try {
            updateLastRead(firestore, conversation, this.props.profile.id);
          } catch (er) {
            console.error('err:', er);
          }
        }
      }
    );
  }

  async addMessage() {
    let msg = this.textareaRef.current.value;

    if (this.validText(msg)) {
      // reset text area
      this.textareaRef.current.value = '';
      addTextMessage(firestore, msg, this.props.profile.id, this.state.conversation);
    }
  }

  validText(msg) {
    return msg?.length > 0;
  }

  getUserName(userId: string): string {
    const userName = this.props.users.find((u) => u.id === userId)?.name ?? '';
    return userName;
  }

  getConversationTitle(): string {
    // if (this.state.conversation) {
    return conversationTitle(
      this.props.profile,
      this.state.conversation ? [this.state.conversation] : [],
      this.props.users,
      this.props.conversationId
    );
    // }
  }

  refresh(evt) {
    return;
    /*
    var c = this.state.conversation.lastMessages;
    for (let i=0; i<=4; i++) {
      c.unshift({
        type: 'TEXT',
        message: 'test ' + i,
        userId: this.props.profile.id,
        creationDate: new Date(),
        className: 'new'
      });
    }
    this.setState({conversation: this.state.conversation});
    evt.detail.complete();
    console.log(this.state.conversation)*/
  }

  render() {
    let { conversation, conversationMessages, showSidebar } = this.state;
    let { profile, users } = this.props;

    console.log('conversation', conversation);

    return (
      <IonPage
        className={
          'page-conversation ion-page ' +
          (conversation?.members.length > 2
            ? 'multi-conversation'
            : 'two-people-conversation')
        }
        id="page-conversation"
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text="Back"
                defaultHref="/tabs/inbox"
                color="dark"
                icon={chevronBackOutline}
              />
            </IonButtons>
            <IonTitle
              onClick={(_) => this.setState({ showSidebar: !this.state.showSidebar })}
            >
              {this.getConversationTitle()?.trim() ?? 'Chat'}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        {conversation && (
          <Messages
            conversation={conversation}
            conversationMessages={conversationMessages}
            profile={profile}
            refresh={this.refresh.bind(this)}
            showSidebar={showSidebar}
            users={users}
          />
        )}
        <IonFooter>
          <IonToolbar>
            <IonTextarea
              ref={this.textareaRef}
              placeholder="write your message here..."
              onKeyDown={(e) =>
                e.altKey && e.key === 'Enter' ? this.addMessage() : null
              }
              rows={1}
            ></IonTextarea>
            <IonButtons slot="end">
              <IonButton
                onClick={(_) => this.addMessage()}
                color="medium"
                className="send-button"
              >
                <IonIcon icon={send} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }
}

// ----------------------------------------------------------------

const Messages = ({
  conversation,
  conversationMessages,
  showSidebar,
  profile,
  refresh,
  users,
}) => {
  const messagesEndRef = useRef(null);
  const container = useRef(null);
  let className = 'messages '; // + ((conversation.order) ? " has-order" : "")

  const scrollToBottom = () => {
    container.current.scrollToBottom();
  };

  useEffect(scrollToBottom, []); // only runs once

  return (
    <IonContent
      id="scrollableContent"
      ref={container}
      className="scrollable-content-messages"
    >
      {/* {conversation.order &&
        <div className="top-banner">
          <CardOrder order={conversation.order} />
        </div>
      } */}
      {showSidebar && (
        <div className="sidebar">
          <IonList>
            <IonListHeader>Users</IonListHeader>
            {conversation?.members
              .filter((id) => id !== profile.id)
              .map((id) => (
                <IonItem key={id}>
                  {/* <ViewContactName contactId={id} /> */}
                  <IonIcon icon={personCircleOutline} slot="start" />
                  <div>
                    <b>{users.find((u) => u.id === id)?.name || 'User'}</b>
                    <span>{users.find((u) => u.id === id)?.email}</span>
                  </div>
                </IonItem>
              ))}
            {conversation?.listOfEmails?.length > 0 && (
              <IonListHeader>Pending Users</IonListHeader>
            )}
            {conversation?.listOfEmails?.map((email) => (
              <IonItem key={email}>
                {/* <ViewContactName contactId={id} /> */}
                <IonIcon icon={personCircleOutline} slot="start" />
                <div>{email}</div>
              </IonItem>
            ))}
          </IonList>
        </div>
      )}
      <IonList className="scrollable-content-messages">
        {
          // (messages.length >= 10) &&
          // <div className="scroll-down-msg">
          //   {i18n.t('PageConversation.scrollDown')}
          // </div>
        }
        <div className={className} ref={container}>
          {conversationMessages.map((message, idx) => {
            let date;
            if (
              message.creationDate !== null &&
              (idx === 0 ||
                formatDate(message.creationDate) !==
                  formatDate(conversationMessages[idx - 1].creationDate))
            ) {
              date = (
                <div className="date-separator">
                  <span>{formatDate(message.creationDate)}</span>
                </div>
              );
            }
            return (
              <React.Fragment key={idx}>
                {date}
                <Bubble message={message} idx={idx} profile={profile} users={users} />
              </React.Fragment>
            );
          })}
        </div>
        <div ref={messagesEndRef} className="last-message" id="lastMessage" />
      </IonList>
    </IonContent>
  );
};

// const Messages = React.forwardRef((props, ref) => <MessagesEgg {...props} forwardRef={ref} />)

// ----------------------------------------------------------------

const Bubble = ({ message, idx, profile, users }) => {
  const onLongPress = () => {
    if (true) return;
    console.log('calls callback after long pressing 300ms');
    setSelected(true);
    present({
      cssClass: 'my-css',
      header: 'Delete message?',
      translucent: true,
      // message: 'alert from hook',
      buttons: [
        'Cancel',
        { text: 'Delete', handler: (d) => console.log('delete pressed', message) },
      ],
      onDidDismiss: (e) => setSelected(false),
    });
  };

  const [present] = useIonAlert();
  const [selected, setSelected] = useState(false);

  const defaultOptions = { isPreventDefault: false, delay: 400 };
  const longPressEvent = useLongPress(onLongPress, defaultOptions);

  const safe_tags = (str) => {
    return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  };

  const wrapEmojis = (txt) => {
    var regex = /\p{Emoji}/gu;
    return safe_tags(txt).replace(
      regex,
      (emoji) => '<span class="emoji">' + emoji + '</span>'
    );
  };

  const renderMessage = () => {
    switch (message.type) {
      case 'TEXT':
        return (
          <>
            <div className="txt">
              <div
                className="message"
                dangerouslySetInnerHTML={{
                  __html: wrapEmojis(message.message),
                }}
              />
            </div>
          </>
        );

      case 'STATUS':
        return <div>{message.message}</div>;

      case 'ORDER':
        return <CardOrder loading={false} order={message.order}></CardOrder>;

      case 'REPORT':
        return <CardReport loading={false} report={message.report}></CardReport>;

      case 'LOT':
        return <CardLot loading={false} lot={message.lot} />;

      case 'ASSESSMENT':
        return (
          <CardInspection
            loading={false}
            showGalleryModal={true}
            organisationId={message.organisationId}
            inspection={message.assessment}
          />
        );
    }
  };

  let renderedMessage = renderMessage();
  let whoSentItCssClass = profile.id === message.userId ? 'i-did' : 'they-did';

  const creationDate = message.creationDate?.toDate() ?? new Date();
  const dateString =
    creationDate.getHours() +
    ':' +
    (parseInt(creationDate.getMinutes()) < 10 ? '0' : '') +
    creationDate.getMinutes();

  switch (message.type) {
    case 'ORDER':
    case 'REPORT':
    case 'LOT':
    case 'ASSESSMENT':
      return (
        <div className={'card-wrapper ' + whoSentItCssClass}>
          <span className="timestamp">{dateString}</span>
          {renderedMessage}
        </div>
      );
    case 'STATUS':
      return (
        <div className={'status-wrapper ' + whoSentItCssClass}>{renderedMessage}</div>
      );
  }

  let divClassName =
    'msg ' + message.type + (selected ? ' selected ' : ' ') + whoSentItCssClass;
  let bubbleClassName = 'bubble ' + whoSentItCssClass;

  return (
    <div className={divClassName} key={idx}>
      <span className="timestamp">{dateString}</span>
      <div className={whoSentItCssClass}>
        <div className={bubbleClassName} {...longPressEvent}>
          {renderedMessage}
          <div className={'bubble-arrow ' + whoSentItCssClass}></div>
        </div>
        <div className="user-name">
          @{users.find((u) => u.id === message.userId)?.name || 'User'}
        </div>
      </div>
    </div>
  );
};

export default React.memo(
  withContext(withIonLifeCycle(PageConversation), ['profile', 'users'])
);
