import React, { useState } from 'react';
import './ViewInspectionScore.scss';
import {
  Order,
  OrganisationSettings,
  OutputGroupValue,
  Report,
  UserProfile,
} from './Model';
import {
  LotInspection,
  InspectionScore,
  Inspection,
  LegacyInspection,
} from './InspectionModel';
import { cloneDeep } from 'lodash';
import { sortScoresEntries } from './InspectionLogic';
import { MAX_BADGE_TEXT_SIZE } from './GlobalConstants';

export type DisplayType = 'INLINE' | 'FULL' | 'ASSESSMENT' | 'CONDITION' | 'CARD-ORDER';

export interface VISProps {
  inspection?: Inspection;
  displayType?: DisplayType;
  scores?: { [key in string]: InspectionScore };

  organisationSettings: OrganisationSettings;
  profile: UserProfile;
  order?: Order | Report;
}

export class ViewInspectionScore extends React.Component<VISProps> {
  render() {
    // console.log("Calling viewAssessmentScore")
    const {
      inspection,
      // organisationSettings
    } = this.props;

    const inspectionCopy = cloneDeep(inspection);

    // Different displaying behaviours if the inspection is still open
    // if (assessmentCopy?.status === 'OPEN') {
    //   // If the order is closed and the assessment is still open, display the default group scores
    //   if (!!order && order.qcStatus !== 'OPEN' && !!organisationSettings?.defaultGroupScores) {
    //     assessmentCopy.userInputGroupMap = organisationSettings.defaultGroupScores;
    //   }
    //   // If it's a commercial user and both the assessment and the order qcStatus are still OPEN, don't display
    //   if (!!order && order.qcStatus === 'OPEN' && !!profile && hasRole(this.props.profile, 'COMMERCIAL ')) {
    //     assessmentCopy.userInputGroupMap = undefined;
    //   }
    // }

    const scores = inspectionCopy?.scores ?? this.props.scores;

    if (!scores) {
      return null;
    }

    if (!!(inspectionCopy as LegacyInspection)?.reference?.transportId) {
      return null;
    }

    const showBig =
      Object.values(scores).reduce((acc, v) => (v.score?.length ?? 0) + acc, 0) < 8
        ? ' big'
        : '';

    return (
      <div
        className={
          'view-assessment-score ' + (this.props.displayType || 'FULL') + showBig
        }
      >
        {Object.entries(scores)
          .sort((a, b) => sortScoresEntries(a, b, this.props.organisationSettings))
          .map(([groupId, score]) => (
            <RenderGroupValue key={groupId} groupId={groupId} outputValue={score} />
          ))}
      </div>
    );
  }
}

export const RenderGroupValue = ({
  groupId,
  outputValue,
  omitName,
}: {
  groupId?: string;
  outputValue: OutputGroupValue | InspectionScore;
  omitName?: boolean;
}) => {
  const [showFullScore, setShowFullName] = useState<boolean>(false);

  if (!outputValue) {
    return <></>;
  }

  let { agScore, score, name, manuallyOverriden } = outputValue;
  const originalGroupId = groupId;

  // console.log("RENDER GROUP VALUE", groupId, outputValue)
  // console.log("TODO: revise the condition here to something generic!!")
  // condition and appearance are the only groups
  if (groupId !== 'group_condition' && groupId !== 'group_appearance') {
    groupId += ' group_generic_long score-generic';
  }

  //@ts-ignore
  agScore = agScore ?? score;
  //@ts-ignore
  score = score ?? outputValue.calculatedScore;

  const shortScore = score.length > 20 ? `${score.slice(0, 20)}...` : score;

  const isExpandable = shortScore !== score;

  // return <div className={"score " + groupId + " score-" + agScore} key={groupId}></div>
  // TODO: Emi, custom colors should come from organisationSettings.scoreMappings, not be hard-coded in the CSS
  return (
    <div
      data-tip={`score-${originalGroupId}`}
      className={'score ' + groupId + ' score-' + agScore + ' score-custom-' + score}
      key={groupId}
      onClick={
        isExpandable
          ? (e) => {
              e.stopPropagation();
              setShowFullName(!showFullScore);
            }
          : undefined
      }
    >
      {!omitName && <span className="label">{name}</span>}
      <span className={`numerical${isExpandable ? ' expandable' : ''}`}>
        {showFullScore ? score : shortScore}
        {!!manuallyOverriden ? '*' : ''}
      </span>
    </div>
  );
};
