import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import React, { useContext } from 'react';
import './PageLogin.scss';
// import logo from './assets/logo-green.png'
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from './ConfigFirebase';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { CAgrinormLogo } from './components/CAgrinormLogo';
import { applicationStateObservable } from './simpleObservable/observables';
import { ctxSyncState } from './App';

interface Props extends RouteComponentProps {
  profileIsSet: boolean;
  isKeyboardOpen?: boolean;
  authSuccess: boolean;
  setAuthSuccess: (v: boolean) => void;
}

type ContainerProps = Props & { isInvitationLink: boolean };

class PageLoginContainer extends React.Component<ContainerProps> {
  constructor(props: ContainerProps) {
    super(props);

    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(getAuth(firebase.app()), window.location.href)) {
      console.log('window location: ', window.location);

      const parameters = new URLSearchParams(window.location.search);
      let email = parameters.get('email');

      if (!email) {
        let continueUrl = parameters.get('continueUrl');
        email = continueUrl.substring(continueUrl.indexOf('=') + 1);
      }

      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      if (!email) {
        this.props.setAuthSuccess(false);
        return;
      }

      // The client SDK will parse the code from the link for us
      signInWithEmailLink(getAuth(firebase.app()), email, window.location.href)
        .then()
        .catch((error) => {
          console.error('error on isSignInWithEmailLink', error);
          applicationStateObservable.next({
            ...applicationStateObservable.getValue(),
            isInvitationLink: false,
          });
          this.props.setAuthSuccess(false);
          alert(error.code + ': ' + error.message);
        });
    }
  }

  componentDidMount() {
    const parameters = new URLSearchParams(window.location.search);
    const mode = parameters.get('mode');
    const fireCode = parameters.get('oobCode');

    if (mode === 'resetPassword') {
      console.log(mode);
      this.props.history.push(
        `/reset-password/?mode=resetPassword&oobCode=${fireCode}`
      );
    }

    if (mode === 'signIn') {
      this.props.history.push(`/onboarding`);
    }
  }

  render() {
    const { profileIsSet, authSuccess, isInvitationLink } = this.props;

    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: '/tabs/quality-control',
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
          disableSignUp: {
            status: true,
            adminEmail: 'webmaster@agrinorm.ai',
          },
        },
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => {
          this.props.setAuthSuccess(true);
          return false;
        },
      },
    };

    return (
      <IonPage className={`page-login ${!!authSuccess ? 'zoom-out-logos' : ''}`}>
        <IonContent>
          <div className="cover-area-wrapper">
            <div
              className={`cover-area ${this.props.isKeyboardOpen && 'keyboard-open'}`}
            ></div>
          </div>
          <div className="logo-wrapper">
            <CAgrinormLogo fadeIn />
          </div>
          <div className="main-area">
            {authSuccess && <h1 className="ion-text-center">Welcome</h1>}
            {isInvitationLink && (
              <>
                <h3 className="ion-text-center">Your account is being set up</h3>
                <h5 className="ion-text-center">
                  This might take a couple of minutes, please do not close this page...
                </h5>
              </>
            )}
            {authSuccess || profileIsSet || isInvitationLink ? (
              <div className="loading-container">
                <IonSpinner name="dots" />
              </div>
            ) : (
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={getAuth(firebase.app())}
              />
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const PageLogin = (props: Props) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { isInvitationLink } = useContext(ctxSyncState);
  return (
    <PageLoginContainer
      {...props}
      isInvitationLink={isInvitationLink}
      isKeyboardOpen={isKeyboardOpen}
    />
  );
};

export default withRouter(PageLogin);
