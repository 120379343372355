import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import React, { useState } from 'react';
import { formatDate } from '../../../../HelperUtils';
import { chevronBack, chevronDown } from 'ionicons/icons';
import { SharedTo } from '../../../../Model';

interface Props {
  sharedTo: SharedTo;
  message?: string;
}
export const LocationSharedInfo = ({ message, sharedTo }: Props) => {
  const [showInfo, setShowInfo] = useState<boolean>(true);

  return (
    <div className="shared-info">
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Shared Field Report</IonCardTitle>
          <IonButton fill="clear" color="dark" onClick={() => setShowInfo(!showInfo)}>
            <IonIcon icon={showInfo ? chevronDown : chevronBack} />
          </IonButton>
        </IonCardHeader>
        {showInfo && (
          <IonCardContent>
            <p>
              Shared by: <b>{sharedTo.sharedBy.name}</b>
            </p>
            <p>
              Shared on: <b>{formatDate(sharedTo.date)}</b>
            </p>
            {!!message && (
              <>
                <p>Comments:</p>
                {message.split('\n').map((m, i) => (
                  <p key={i}>
                    <b>{m}</b>
                  </p>
                ))}
              </>
            )}
          </IonCardContent>
        )}
      </IonCard>
    </div>
  );
};
