/* tslint:disable */
/* eslint-disable */
/**
 * Agrinorm scoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface InitializeRankingtreeInput
 */
export interface InitializeRankingtreeInput {
  /**
   *
   * @type {string}
   * @memberof InitializeRankingtreeInput
   */
  combination_class: string;
}
/**
 *
 * @export
 * @interface InitializeRankingtreeOutput
 */
export interface InitializeRankingtreeOutput {
  /**
   *
   * @type {Array<any>}
   * @memberof InitializeRankingtreeOutput
   */
  keys: Array<any>;
}
/**
 *
 * @export
 * @interface LabelCorrectionInput
 */
export interface LabelCorrectionInput {
  /**
   *
   * @type {object}
   * @memberof LabelCorrectionInput
   */
  context_permdict: object;
  /**
   *
   * @type {object}
   * @memberof LabelCorrectionInput
   */
  corrected_label: object;
}
/**
 *
 * @export
 * @interface LabelCorrectionOutput
 */
export interface LabelCorrectionOutput {
  /**
   *
   * @type {object}
   * @memberof LabelCorrectionOutput
   */
  context_permdict: object;
  /**
   *
   * @type {Array<any>}
   * @memberof LabelCorrectionOutput
   */
  implied_deletion: Array<any>;
  /**
   *
   * @type {object}
   * @memberof LabelCorrectionOutput
   */
  convergence: object;
  /**
   *
   * @type {boolean}
   * @memberof LabelCorrectionOutput
   */
  show_prediction: boolean;
  /**
   *
   * @type {object}
   * @memberof LabelCorrectionOutput
   */
  group_distribution: object;
  /**
   *
   * @type {object}
   * @memberof LabelCorrectionOutput
   */
  convergence_ratios: object;
}
/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 *
 * @export
 * @interface NewQueryInput
 */
export interface NewQueryInput {
  /**
   *
   * @type {object}
   * @memberof NewQueryInput
   */
  context_permdict: object;
}
/**
 *
 * @export
 * @interface NewQueryOutput
 */
export interface NewQueryOutput {
  /**
   *
   * @type {object}
   * @memberof NewQueryOutput
   */
  convergence: object;
  /**
   *
   * @type {object}
   * @memberof NewQueryOutput
   */
  context_permdict: object;
  /**
   *
   * @type {object}
   * @memberof NewQueryOutput
   */
  next_query: object;
  /**
   *
   * @type {boolean}
   * @memberof NewQueryOutput
   */
  show_prediction: boolean;
  /**
   *
   * @type {object}
   * @memberof NewQueryOutput
   */
  group_distribution: object;
  /**
   *
   * @type {object}
   * @memberof NewQueryOutput
   */
  convergence_ratios: object;
}
/**
 *
 * @export
 * @interface NewSampleInput
 */
export interface NewSampleInput {
  /**
   *
   * @type {object}
   * @memberof NewSampleInput
   */
  sample: object;
  /**
   *
   * @type {object}
   * @memberof NewSampleInput
   */
  context_permdict: object;
}
/**
 *
 * @export
 * @interface NewSampleOutput
 */
export interface NewSampleOutput {
  /**
   *
   * @type {object}
   * @memberof NewSampleOutput
   */
  convergence: object;
  /**
   *
   * @type {object}
   * @memberof NewSampleOutput
   */
  context_permdict: object;
  /**
   *
   * @type {object}
   * @memberof NewSampleOutput
   */
  next_query: object;
  /**
   *
   * @type {boolean}
   * @memberof NewSampleOutput
   */
  show_prediction: boolean;
  /**
   *
   * @type {object}
   * @memberof NewSampleOutput
   */
  group_distribution: object;
  /**
   *
   * @type {object}
   * @memberof NewSampleOutput
   */
  convergence_ratios: object;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}

/**
 * InitializeRankingtreeApi - axios parameter creator
 * @export
 */
export const InitializeRankingtreeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * In case nothing available in firestore and S3 build from spec. Returns the permdict keys
     * @summary Initialize Context Endpoint
     * @param {InitializeRankingtreeInput} initializeRankingtreeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeContextEndpoint: async (
      initializeRankingtreeInput: InitializeRankingtreeInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'initializeRankingtreeInput' is not null or undefined
      assertParamExists(
        'initializeContextEndpoint',
        'initializeRankingtreeInput',
        initializeRankingtreeInput
      );
      const localVarPath = `/initialize_ranking_tree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        initializeRankingtreeInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InitializeRankingtreeApi - functional programming interface
 * @export
 */
export const InitializeRankingtreeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InitializeRankingtreeApiAxiosParamCreator(configuration);
  return {
    /**
     * In case nothing available in firestore and S3 build from spec. Returns the permdict keys
     * @summary Initialize Context Endpoint
     * @param {InitializeRankingtreeInput} initializeRankingtreeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initializeContextEndpoint(
      initializeRankingtreeInput: InitializeRankingtreeInput,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InitializeRankingtreeOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.initializeContextEndpoint(
          initializeRankingtreeInput,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InitializeRankingtreeApi - factory interface
 * @export
 */
export const InitializeRankingtreeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InitializeRankingtreeApiFp(configuration);
  return {
    /**
     * In case nothing available in firestore and S3 build from spec. Returns the permdict keys
     * @summary Initialize Context Endpoint
     * @param {InitializeRankingtreeInput} initializeRankingtreeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeContextEndpoint(
      initializeRankingtreeInput: InitializeRankingtreeInput,
      options?: any
    ): AxiosPromise<InitializeRankingtreeOutput> {
      return localVarFp
        .initializeContextEndpoint(initializeRankingtreeInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InitializeRankingtreeApi - object-oriented interface
 * @export
 * @class InitializeRankingtreeApi
 * @extends {BaseAPI}
 */
export class InitializeRankingtreeApi extends BaseAPI {
  /**
   * In case nothing available in firestore and S3 build from spec. Returns the permdict keys
   * @summary Initialize Context Endpoint
   * @param {InitializeRankingtreeInput} initializeRankingtreeInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InitializeRankingtreeApi
   */
  public initializeContextEndpoint(
    initializeRankingtreeInput: InitializeRankingtreeInput,
    options?: AxiosRequestConfig
  ) {
    return InitializeRankingtreeApiFp(this.configuration)
      .initializeContextEndpoint(initializeRankingtreeInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LabelCorrectionApi - axios parameter creator
 * @export
 */
export const LabelCorrectionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Label Correction Endpoint
     * @param {LabelCorrectionInput} labelCorrectionInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    labelCorrectionEndpoint: async (
      labelCorrectionInput: LabelCorrectionInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelCorrectionInput' is not null or undefined
      assertParamExists(
        'labelCorrectionEndpoint',
        'labelCorrectionInput',
        labelCorrectionInput
      );
      const localVarPath = `/label_correction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        labelCorrectionInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelCorrectionApi - functional programming interface
 * @export
 */
export const LabelCorrectionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LabelCorrectionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Label Correction Endpoint
     * @param {LabelCorrectionInput} labelCorrectionInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async labelCorrectionEndpoint(
      labelCorrectionInput: LabelCorrectionInput,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelCorrectionOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.labelCorrectionEndpoint(
        labelCorrectionInput,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * LabelCorrectionApi - factory interface
 * @export
 */
export const LabelCorrectionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LabelCorrectionApiFp(configuration);
  return {
    /**
     *
     * @summary Label Correction Endpoint
     * @param {LabelCorrectionInput} labelCorrectionInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    labelCorrectionEndpoint(
      labelCorrectionInput: LabelCorrectionInput,
      options?: any
    ): AxiosPromise<LabelCorrectionOutput> {
      return localVarFp
        .labelCorrectionEndpoint(labelCorrectionInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LabelCorrectionApi - object-oriented interface
 * @export
 * @class LabelCorrectionApi
 * @extends {BaseAPI}
 */
export class LabelCorrectionApi extends BaseAPI {
  /**
   *
   * @summary Label Correction Endpoint
   * @param {LabelCorrectionInput} labelCorrectionInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelCorrectionApi
   */
  public labelCorrectionEndpoint(
    labelCorrectionInput: LabelCorrectionInput,
    options?: AxiosRequestConfig
  ) {
    return LabelCorrectionApiFp(this.configuration)
      .labelCorrectionEndpoint(labelCorrectionInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QueryGetApi - axios parameter creator
 * @export
 */
export const QueryGetApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Next Query Endpoint
     * @param {NewQueryInput} newQueryInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextQueryEndpoint: async (
      newQueryInput: NewQueryInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newQueryInput' is not null or undefined
      assertParamExists('nextQueryEndpoint', 'newQueryInput', newQueryInput);
      const localVarPath = `/next_query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newQueryInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QueryGetApi - functional programming interface
 * @export
 */
export const QueryGetApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QueryGetApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Next Query Endpoint
     * @param {NewQueryInput} newQueryInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nextQueryEndpoint(
      newQueryInput: NewQueryInput,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewQueryOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nextQueryEndpoint(
        newQueryInput,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * QueryGetApi - factory interface
 * @export
 */
export const QueryGetApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = QueryGetApiFp(configuration);
  return {
    /**
     *
     * @summary Next Query Endpoint
     * @param {NewQueryInput} newQueryInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextQueryEndpoint(
      newQueryInput: NewQueryInput,
      options?: any
    ): AxiosPromise<NewQueryOutput> {
      return localVarFp
        .nextQueryEndpoint(newQueryInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * QueryGetApi - object-oriented interface
 * @export
 * @class QueryGetApi
 * @extends {BaseAPI}
 */
export class QueryGetApi extends BaseAPI {
  /**
   *
   * @summary Next Query Endpoint
   * @param {NewQueryInput} newQueryInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QueryGetApi
   */
  public nextQueryEndpoint(newQueryInput: NewQueryInput, options?: AxiosRequestConfig) {
    return QueryGetApiFp(this.configuration)
      .nextQueryEndpoint(newQueryInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SamplePostApi - axios parameter creator
 * @export
 */
export const SamplePostApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary New Sample Endpoint
     * @param {NewSampleInput} newSampleInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newSampleEndpoint: async (
      newSampleInput: NewSampleInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'newSampleInput' is not null or undefined
      assertParamExists('newSampleEndpoint', 'newSampleInput', newSampleInput);
      const localVarPath = `/new_sample`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newSampleInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SamplePostApi - functional programming interface
 * @export
 */
export const SamplePostApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SamplePostApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary New Sample Endpoint
     * @param {NewSampleInput} newSampleInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newSampleEndpoint(
      newSampleInput: NewSampleInput,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewSampleOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newSampleEndpoint(
        newSampleInput,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SamplePostApi - factory interface
 * @export
 */
export const SamplePostApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SamplePostApiFp(configuration);
  return {
    /**
     *
     * @summary New Sample Endpoint
     * @param {NewSampleInput} newSampleInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newSampleEndpoint(
      newSampleInput: NewSampleInput,
      options?: any
    ): AxiosPromise<NewSampleOutput> {
      return localVarFp
        .newSampleEndpoint(newSampleInput, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SamplePostApi - object-oriented interface
 * @export
 * @class SamplePostApi
 * @extends {BaseAPI}
 */
export class SamplePostApi extends BaseAPI {
  /**
   *
   * @summary New Sample Endpoint
   * @param {NewSampleInput} newSampleInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SamplePostApi
   */
  public newSampleEndpoint(
    newSampleInput: NewSampleInput,
    options?: AxiosRequestConfig
  ) {
    return SamplePostApiFp(this.configuration)
      .newSampleEndpoint(newSampleInput, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
