import React, { useEffect, useState } from 'react';

export const CAppVersion = () => {
  const [version, setVersion] = useState<string>('');

  useEffect(() => {
    fetch('version.txt')
      .then((res) => res.text())
      .then((version) => setVersion(`v${version}`))
      .catch((err) => console.error('Failed to load version file', err));
  }, []);

  return <>{version}</>;
};
