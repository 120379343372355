import { Lot, Order, OrderType, ReportType, UserProfile } from './Model';
import firebase from 'firebase/compat/app';
import { Auth } from 'firebase/auth';
import { doImageSync, resetIsUploadingIndexedDB } from './DataImage';
import {
  conditionalPagingCriteria,
  lotColRef,
  orderColRef,
  PagedResult,
  reportColRef,
} from './DataStorage';
import {
  APP_UPDATED_RECENTLY,
  COMMON_COL_NAME,
  SUPPLY_CHAIN_COL_NAME,
} from './GlobalConstants';
import { AGFilters } from './SearchService';
import { c, formatDate } from './HelperUtils';
import {
  applicationStateObservable,
  imagesStateObservable,
} from './simpleObservable/observables';

export const DARK_MODE_STORAGE_KEY = 'ag-dark-mode';
export const INLINE_EDITING_STORAGE_KEY = 'ag-inlineEditing';

const appText_filterError =
  'Something went wrong, please remove the last filter and try again';

window.addEventListener('online', async (event) => {
  applicationStateObservable.next({
    ...applicationStateObservable.getValue(),
    online: navigator.onLine,
  });
  doImageSync(true);
});

window.addEventListener('offline', (event) => {
  applicationStateObservable.next({
    ...applicationStateObservable.getValue(),
    online: navigator.onLine,
  });
  imagesStateObservable.next({ ...imagesStateObservable.getValue(), syncing: false });
  // reset uploadingMap
  resetIsUploadingIndexedDB();
});

export function markDirtyFlag() {
  if (!navigator.onLine) {
    applicationStateObservable.next({
      ...applicationStateObservable.getValue(),
      dirty: true,
    });
  }
}

export function unmarkDirty() {
  applicationStateObservable.next({
    ...applicationStateObservable.getValue(),
    dirty: false,
  });
}

export function onAuthenticationChanged(auth: Auth, onAuth: Function) {
  return auth.onAuthStateChanged((firebaseUser: firebase.User) => {
    onAuth(firebaseUser);
  });
}

export async function logout(auth: Auth): Promise<any> {
  await auth.signOut();
  // localStorage.clear();
}

export let isOnline = () => {
  // return navigator.onLine
  return (
    applicationStateObservable.getValue().networkEnabled &&
    applicationStateObservable.getValue().online
  );
};

export function getOrders(
  store: firebase.firestore.Firestore,
  organisationId: string,
  search: string,
  orderType: OrderType,
  pagedResult: PagedResult<Order> | undefined,
  max: number = 10,
  filters: AGFilters,
  orderOrganisationId: string,
  onResult: Function,
  isLocationRestricted: boolean | string = false,
  considerQcRelevancy: boolean = true,
  profile?: UserProfile
): Promise<PagedResult<Order>> {
  let collectionRef: any = orderColRef(store, organisationId, orderOrganisationId);

  let queryText = 'collectionRef';

  if (considerQcRelevancy) {
    collectionRef = collectionRef.where('qcRelevant', '==', true);
    queryText += `.where("qcRelevant", "==", true)\n`;
  }

  if (filters.sort) {
    collectionRef = collectionRef.orderBy(filters?.dateProperty, filters.sort);
    queryText += `.orderBy(${filters?.dateProperty}, ${filters.sort})\n`;
  } else {
    collectionRef = collectionRef.orderBy('fulfilmentDate', 'desc');
    queryText += `.orderBy("fulfilmentDate", "desc")\n`;
  }

  if (orderType) {
    collectionRef = collectionRef.where('type', '==', orderType);
    queryText += `.where("type", "==", ${orderType})\n`;
  }

  filters = filters ?? {};
  if (isLocationRestricted) filters.locationId = isLocationRestricted as string;
  // console.log('filters', filters)

  if (search) {
    if (isLocationRestricted) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        isLocationRestricted
      );
      queryText += `.where("search.locationId", "==", ${isLocationRestricted})\n`;
    }
    collectionRef = collectionRef.where('search.searchbox', 'array-contains', search);
    queryText += `.where("search.searchbox", "array-contains", ${JSON.stringify(
      search
    )})\n`;
  } else {
    if (filters.article?.variety) {
      collectionRef = collectionRef.where(
        'search.searchVarieties',
        'array-contains-any',
        [filters.article.variety]
      );
      queryText += `.where("search.searchVarieties", "array-contains-any", ${JSON.stringify(
        [filters.article.variety]
      )})\n`;
    } else if (filters.article?.productId?.length > 0) {
      // include also 'labels', which are equal to the agProductId
      const productSearch = [
        ...new Set([
          ...filters.article.productId.map((o) => o.label),
          ...filters.article.productId.map((o) => o.value),
        ]),
      ].slice(0, 10);
      collectionRef = collectionRef.where(
        'search.searchProducts',
        'array-contains-any',
        productSearch
      );
      queryText += `.where("search.searchProducts", "array-contains-any", ${JSON.stringify(
        productSearch
      )})\n`;
    }

    if (filters.contactId) {
      collectionRef = collectionRef.where('search.contactId', '==', filters.contactId);
      queryText += `.where("search.contactId", "==", ${filters.contactId})\n`;
    }

    if (filters.userId?.value) {
      collectionRef = collectionRef.where(
        'lastModifiedUserId',
        '==',
        filters.userId.value
      );
      queryText += `.where("lastModifiedUserId", "==", ${filters.userId.value})\n`;
    }

    if (filters.supplierId) {
      collectionRef = collectionRef.where(
        'search.supplierId',
        '==',
        filters.supplierId
      );
      queryText += `.where("search.supplierId", "==", ${filters.supplierId})\n`;
    }

    if (filters.locationId) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        filters.locationId
      );
      queryText += `.where("search.locationId", "==", ${filters.locationId})\n`;
    }

    if (filters?.arrivalDate && filters?.dateProperty) {
      let time = new Date(filters.arrivalDate);
      time.setDate(time.getDate());
      time.setHours(0, 0, 0, 0);
      // console.log("And", filters.dateProperty, ">=" + time);
      collectionRef = collectionRef.where(filters?.dateProperty, '>=', time);
      queryText += `.where(${
        filters?.dateProperty
      }, ">=", ${time.getTime()} (${formatDate(time.getTime(), {
        dateStyle: 'short',
        timeStyle: 'long',
      })}))\n`;
    } else {
      // collectionRef = collectionRef.where("fulfilmentDate","<",new Date().getTime() - 60*60*24*2*1000);
    }
    if (filters?.arrivalDateMax && filters?.dateProperty) {
      let time = new Date(filters.arrivalDateMax);
      time.setDate(time.getDate());
      // The users find it intuitive to have the end date included in the time range when indicated
      time.setHours(23, 59, 59, 0);
      // console.log("And", filters.dateProperty, "<." + time);
      collectionRef = collectionRef.where(filters.dateProperty, '<=', time);
      queryText += `.where(${
        filters.dateProperty
      }, "<=", ${time.getTime()} (${formatDate(time.getTime(), {
        dateStyle: 'short',
        timeStyle: 'long',
      })}))\n`;
    }

    // if (filters?.maxDays >= 0) {
    //   let time = new Date(filters.arrivalDate)
    //   time.setDate(time.getDate() - filters.maxDays)
    //   time.setHours(0, 0, 0, 0)
    //   console.log("And", "fulfilmentDate > ." + time);
    //   collectionRef = collectionRef.where("fulfilmentDate", ">", time.getTime());
    // }

    if (!!filters?.qcStatus?.value) {
      collectionRef = collectionRef.where('qcStatus', '==', filters.qcStatus.value);
      queryText += `.where("qcStatus", "==", ${filters.qcStatus.value})\n`;
    }

    if (!!filters?.qcScore?.value) {
      collectionRef = collectionRef.where(
        'search.scores.' + filters.qcScore.value,
        '==',
        true
      );
      queryText += `.where(${'search.scores.' + filters.qcScore.value}, "==", true)\n`;
    }
  }

  collectionRef = conditionalPagingCriteria(pagedResult?.cursor, collectionRef);

  if (profile?.email.includes('agrinorm')) {
    console.log(c('Order filters'), filters);
    console.log(c(queryText, 'cyan'));
  }

  try {
    return (
      collectionRef
        //.orderBy(filters.sort)
        .limit(max)
        .onSnapshot({ includeMetadataChanges: true }, async (snapshot) => {
          // if (snapshot.metadata.fromCache === false || (snapshot.metadata.fromCache && !isOnline())) {
          // console.log(snapshot.metadata)
          const orders: Order[] = snapshot.docs.map((doc) => doc.data() as Order);
          if (orders.length > 0) {
            const totalOrders: Order[] = (pagedResult?.data || []).concat(orders);
            return onResult({
              cursor: snapshot.docs[snapshot.docs.length - 1],
              data: totalOrders,
            } as PagedResult<Order>);
          }

          return onResult(pagedResult || { cursor: undefined, data: [] });
          // }
        })
    );
  } catch (error) {
    console.error(error);
    alert(appText_filterError);
  }
}

export function getLots(
  firestore: firebase.firestore.Firestore,
  organisationId: string,
  search = '',
  pagedResult: PagedResult<Lot> | undefined,
  max: number = 10,
  filters: AGFilters = {},
  onlyNoTransfers: boolean,
  seeAllLots: boolean = false,
  onResult?: Function,
  isLocationRestricted: boolean | string = false,
  supplyChain: boolean = false,
  profile?: UserProfile
): Promise<PagedResult<Lot>> {
  let collectionRef: any = lotColRef(
    firestore,
    organisationId,
    supplyChain ? SUPPLY_CHAIN_COL_NAME : COMMON_COL_NAME
  );

  let queryText = 'collectionRef\n';

  if (isLocationRestricted) filters.locationId = isLocationRestricted as string;
  if (search.length > 0) {
    if (isLocationRestricted) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        isLocationRestricted
      );
      queryText += `.where("search.locationId", "==", ${isLocationRestricted})\n`;
    }
    collectionRef = collectionRef
      .where('search.searchbox', 'array-contains', search)
      .orderBy(filters?.dateProperty, filters?.sort ?? 'desc');
    queryText += `.where("search.searchbox", "array-contains", ${search})\n.orderBy("${
      filters?.dateProperty
    }", ${filters?.sort ?? 'desc'})`;
  } else {
    if (filters.sort) {
      collectionRef = collectionRef.orderBy(filters?.dateProperty, filters.sort);
      queryText += `.orderBy("${filters?.dateProperty}", ${filters?.sort ?? 'desc'})\n`;
    } else {
      if (onlyNoTransfers || seeAllLots) {
        collectionRef = collectionRef.orderBy(
          'stockAssessment.lastModifiedDate',
          'desc'
        );
      } else {
        collectionRef = collectionRef.orderBy('arrivalDate', 'desc');
      }
    }

    if (supplyChain && filters.tentativeOrderId) {
      collectionRef = collectionRef.where(
        'tentativeOrderId',
        '==',
        filters.tentativeOrderId
      );
      queryText += `.where("tentativeOrderId", "==", ${filters.tentativeOrderId})\n`;
    }

    if (filters.hasAssessment === true) {
      collectionRef = collectionRef.where('search.hasAssessment', '==', true);
      queryText += `.where("search.hasAssessment", "==", true)\n`;
    } else if (filters.hasAssessment === false) {
      collectionRef = collectionRef.where('search.hasAssessment', '==', false);
      queryText += `.where("search.hasAssessment", "==", false)\n`;
    }

    if (filters.skipZeroVolume) {
      collectionRef = collectionRef.where('search.hasVolume', '==', true);
      queryText += `.where("search.hasVolume", "==", true)\n`;
    }

    if (filters.isInStock) {
      collectionRef = collectionRef.where('transient.isInStock', '==', true);
      queryText += `.where("transient.isInStock", "==", true)\n`;
    }

    if (filters.article?.productId?.length > 0) {
      const products = [
        ...new Set(
          filters.article.productId
            .map((p) => p.value)
            .concat(filters.article.productId.map((p) => p.label))
        ),
      ].slice(0, 10);
      collectionRef = collectionRef.where('article.productId', 'in', products);
      queryText += `.where("article.productId", "in", ${JSON.stringify(products)})\n`;
    }

    if (filters.article?.packagingType) {
      collectionRef = collectionRef.where(
        'article.packagingType',
        '==',
        filters.article.packagingType
      );
      queryText += `.where("search.packagingType", "==", ${filters.article.packagingType})\n`;
    }

    if (filters.inspectionType) {
      collectionRef = collectionRef.where(
        'latestInspection.reference.type',
        '==',
        filters.inspectionType.value
      );
      queryText += `.where("latestInspection.reference.type", "==", ${filters.inspectionType.value}\n`;
    }

    if (filters.contactId) {
      collectionRef = collectionRef.where('search.supplierId', '==', filters.contactId);
      queryText += `.where("search.supplierId", "==", ${filters.contactId})\n`;
    }

    if (filters.growerId) {
      collectionRef = collectionRef.where('search.growerId', '==', filters.growerId);
      queryText += `.where("search.growerId", "==", ${filters.growerId})\n`;
    }

    if (filters?.arrivalDate && filters?.dateProperty) {
      let time = new Date(filters.arrivalDate);

      time.setDate(time.getDate());
      time.setHours(0, 0, 0, 0);
      collectionRef = collectionRef.where(filters?.dateProperty, '>=', time);
      queryText += `.where("${filters?.dateProperty}", ">=", ${time.getTime()})\n`;
    }
    if (filters?.arrivalDateMax && filters?.dateProperty) {
      let time = new Date(filters.arrivalDateMax);

      time.setDate(time.getDate());
      time.setHours(23, 59, 59, 0);
      collectionRef = collectionRef.where(filters?.dateProperty, '<=', time);
      queryText += `.where("${filters?.dateProperty}", "<=", ${time.getTime()})\n`;
    }

    if (filters.userId?.value) {
      collectionRef = collectionRef.where(
        'lastModifiedUserId',
        '==',
        filters.userId.value
      );
      queryText += `.where("lastModifiedUserId", "==", ${filters.userId.value})\n`;
    }

    if (filters.locationId) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        filters.locationId
      );
      queryText += `.where("search.locationId", "==", ${filters.locationId})\n`;
    }
    if (!!filters?.qcScore?.value) {
      collectionRef = collectionRef.where(
        'search.scores.' + filters.qcScore.value,
        '==',
        true
      );
      queryText += `.where("search.scores.${filters.qcScore.value}", "==", true)\n`;
    }
  }

  collectionRef = conditionalPagingCriteria(pagedResult?.cursor, collectionRef);

  if (profile?.email.includes('agrinorm')) {
    console.log(c('Lot filters'), filters);
    console.log(c(queryText, 'cyan'));
  }

  try {
    return collectionRef
      .limit(max)
      .onSnapshot({ includeMetadataChanges: true }, async (snapshot) => {
        // if (snapshot.metadata.fromCache === false || (snapshot.metadata.fromCache && !isOnline())) {
        const nLots: Lot[] = snapshot.docs.map((doc) => doc.data() as Lot);
        if (nLots.length > 0) {
          const lots: Lot[] = (pagedResult?.data || []).concat(nLots);
          return onResult({
            cursor: snapshot.docs[snapshot.docs.length - 1],
            data: lots,
          } as PagedResult<Lot>);
        } else {
          return onResult(pagedResult || { cursor: undefined, data: [] });
        }
        // }
      });
  } catch (error) {
    console.error(error);
    alert(appText_filterError);
  }
}

export function getInternalReports(
  store: firebase.firestore.Firestore,
  organisationId: string,
  search: string,
  pagedResult: PagedResult<Order> | undefined,
  max: number = 10,
  filters: AGFilters,
  onResult: Function,
  isLocationRestricted: boolean | string = false
): () => void {
  let collectionRef = reportColRef(store, organisationId).where(
    'orgId',
    '==',
    organisationId
  );

  filters = filters ?? {};

  console.log('\x1b[35m%s\x1b[0m', 'GET INTERNAL REPORTS', filters);

  if (filters.sort) {
    collectionRef = collectionRef.orderBy(
      filters?.dateProperty ?? 'fulfilmentDate',
      filters?.sort ?? 'desc'
    );
  }

  // TODO: evaluate what kind of filters we want for reports

  if (search) {
    collectionRef = collectionRef.where('search.searchbox', 'array-contains', search);
  } else {
    if (filters.article?.productId?.length > 0) {
      // include also 'labels', which are equal to the agProductId
      collectionRef = collectionRef.where(
        'search.searchProducts',
        'array-contains-any',
        [
          ...new Set([
            ...filters.article.productId.map((o) => o.value),
            ...filters.article.productId.map((o) => o.label),
          ]),
        ].slice(0, 10)
      );
    }

    if (filters.contactId) {
      collectionRef = collectionRef.where('search.contactId', '==', filters.contactId);
    }

    if (filters.userId?.value) {
      collectionRef = collectionRef.where(
        'lastModifiedUserId',
        '==',
        filters.userId.value
      );
    }

    if (filters.locationId) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        filters.locationId
      );
    }

    if (!!filters?.arrivalDate && !!filters?.dateProperty) {
      let time = new Date(filters.arrivalDate);
      time.setDate(time.getDate());
      time.setHours(0, 0, 0, 0);
      // console.log("And", filters.dateProperty + ">= " + time);
      collectionRef = collectionRef.where(filters.dateProperty, '>=', time);
    }

    if (!!filters?.arrivalDateMax && !!filters?.dateProperty) {
      let time = new Date(filters.arrivalDateMax);
      time.setDate(time.getDate());
      time.setHours(23, 59, 59, 0);
      // console.log("And", filters.dateProperty + "<= " + time);
      collectionRef = collectionRef.where(filters.dateProperty, '<=', time);
    }

    if (!!filters?.reportStatus?.value) {
      collectionRef = collectionRef.where(
        'reportStatus',
        '==',
        filters.reportStatus.value
      );
    }

    if (!!filters?.reportType?.value) {
      collectionRef = collectionRef.where('reportType', '==', filters.reportType.value);
    }

    if (!!filters?.qcScore?.value) {
      collectionRef = collectionRef.where(
        'search.scores.' + filters.qcScore.value,
        '==',
        true
      );
    }
  }

  collectionRef = conditionalPagingCriteria(pagedResult?.cursor, collectionRef);

  try {
    return collectionRef
      .limit(max)
      .onSnapshot({ includeMetadataChanges: true }, async (snapshot) => {
        if (
          snapshot.metadata.fromCache === false ||
          (snapshot.metadata.fromCache && !isOnline())
        ) {
          const orders: Order[] = snapshot.docs.map((doc) => doc.data() as Order);
          if (orders.length > 0) {
            const totalOrders: Order[] = (pagedResult?.data || []).concat(orders);
            return onResult({
              cursor: snapshot.docs[snapshot.docs.length - 1],
              data: totalOrders,
            } as PagedResult<Order>);
          }

          return onResult(pagedResult || { cursor: undefined, data: [] });
        }
      });
  } catch (error) {
    console.error(error);
    alert(appText_filterError);
  }
}

export function getExternalReports(
  store: firebase.firestore.Firestore,
  organisationId: string,
  reportType: ReportType,
  search: string,
  pagedResult: PagedResult<Order> | undefined,
  max: number = 10,
  filters: AGFilters,
  onResult: Function,
  isLocationRestricted: boolean | string = false
): () => void {
  let collectionRef = reportColRef(store, organisationId).where(
    'reportType',
    '==',
    reportType
  );

  console.log('\x1b[35m%s\x1b[0m', 'GET EXTERNAL REPORTS', filters);

  filters = filters ?? {};

  if (filters.sort) {
    collectionRef = collectionRef.orderBy(
      filters?.dateProperty ?? 'fulfilmentDate',
      filters?.sort ?? 'desc'
    );
  }

  // TODO: evaluate what kind of filters we want for reports

  if (search) {
    collectionRef = collectionRef.where('search.searchbox', 'array-contains', search);
  } else {
    if (filters.article?.productId?.length > 0) {
      // include also 'labels', which are equal to the agProductId
      collectionRef = collectionRef.where(
        'search.searchProducts',
        'array-contains-any',
        [
          ...new Set([
            ...filters.article.productId.map((o) => o.value),
            ...filters.article.productId.map((o) => o.label),
          ]),
        ].slice(0, 10)
      );
    }

    if (filters.contactId) {
      collectionRef = collectionRef.where('search.contactId', '==', filters.contactId);
    }

    if (filters.userId?.value) {
      collectionRef = collectionRef.where(
        'lastModifiedUserId',
        '==',
        filters.userId.value
      );
    }

    //   if (filters.supplierId) {
    //     collectionRef = collectionRef.where("supplierId", "==", filters.supplierId);
    //   }

    if (filters.locationId) {
      collectionRef = collectionRef.where(
        'search.locationId',
        '==',
        filters.locationId
      );
    }

    //   if (filters.onlyLinked) {
    //     collectionRef = collectionRef.where("contactOrder.validLink", "==", true);
    //   }

    if (!!filters?.arrivalDate && !!filters?.dateProperty) {
      let time = new Date(filters.arrivalDate);
      time.setDate(time.getDate());
      time.setHours(0, 0, 0, 0);
      console.log('And', filters.dateProperty + '>= ' + time);
      collectionRef = collectionRef.where(filters.dateProperty, '>=', time);
    }

    if (!!filters?.arrivalDateMax && !!filters?.dateProperty) {
      let time = new Date(filters.arrivalDateMax);
      time.setDate(time.getDate());
      time.setHours(23, 59, 59, 0);
      console.log('And', filters.dateProperty + '<= ' + time);
      collectionRef = collectionRef.where(filters.dateProperty, '<=', time);
    }

    if (!!filters?.reportStatus?.value) {
      collectionRef = collectionRef.where(
        'reportStatus',
        '==',
        filters.reportStatus.value
      );
    }

    if (!!filters?.qcScore?.value) {
      collectionRef = collectionRef.where(
        'search.scores.' + filters.qcScore.value,
        '==',
        true
      );
    }
  }

  collectionRef = conditionalPagingCriteria(pagedResult?.cursor, collectionRef);

  try {
    return collectionRef
      .limit(max)
      .onSnapshot({ includeMetadataChanges: true }, async (snapshot) => {
        if (
          snapshot.metadata.fromCache === false ||
          (snapshot.metadata.fromCache && !isOnline())
        ) {
          const orders: Order[] = snapshot.docs.map((doc) => doc.data() as Order);
          if (orders.length > 0) {
            const totalOrders: Order[] = (pagedResult?.data || []).concat(orders);
            return onResult({
              cursor: snapshot.docs[snapshot.docs.length - 1],
              data: totalOrders,
            } as PagedResult<Order>);
          }

          return onResult(pagedResult || { cursor: undefined, data: [] });
        }
      });
  } catch (error) {
    console.error(error);
    alert(appText_filterError);
  }
}

// UPDATE STUFF
export const triggerAppUpdate = (checkForUpdatedRecentlyFlag: boolean = true) => {
  const { isInvitationLink } = applicationStateObservable.getValue();
  
  // Prevent update if it's the user invite flow, as that would make it fail 
  if (isInvitationLink) return;

  if (!checkForUpdatedRecentlyFlag || !hasAppBeenUpdatedRecently()) {
    applicationStateObservable.next({
      ...applicationStateObservable.getValue(),
      newVersionAvailable: true,
    });

    setUpdatedRecentlyFlag();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};

const setUpdatedRecentlyFlag = () => {
  try {
    localStorage.setItem(APP_UPDATED_RECENTLY, 'true');
  } catch (error) {
    console.error('Could not write to the local storage');
  }
};

export const removeUpdatedRecentlyFlag = () => {
  try {
    localStorage.removeItem(APP_UPDATED_RECENTLY);
  } catch (error) {
    console.error('Could not write to the local storage');
  }
};

const hasAppBeenUpdatedRecently = (): boolean => {
  try {
    return localStorage.getItem(APP_UPDATED_RECENTLY) != null;
  } catch (error) {
    console.error('Could not write to the local storage');
    return false;
  }
};
