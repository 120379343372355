import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSkeletonText,
} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import CardInspection from './CardInspection';
import CardInspectionSummary from './CardInspectionSummary';
import './CardLot.scss';
import { firestore } from './ConfigFirebase';
import { getLotSnapshot } from './DataStorage';
import { SUPPLY_CHAIN_URL_SUFFIX } from './GlobalConstants';
import withContext, { ContextProps } from './HOC/withContext';
import { newInspectionForLot } from './InspectionLogic';
import { LotInspection } from './InspectionModel';
import { dateToString, Lot, Report } from './Model';
import { QCFilterType } from './ModelAGTabs';

interface Props {
  lot?: Lot;
  lotId?: string;
  loading?: boolean;
  multipleSelection?: boolean;
  selected?: boolean;
  searchString?: string;
  nonClickable?: boolean;
  context?: QCFilterType;
  history?: any;
  eligibleForMultiSelection?: boolean;
  showSummary?: boolean;
  allowGalleryEdit?: boolean;
  showGalleryModal?: boolean;
  onPicturesUpdated?: Function;
  index?: any;
  style?: any;
  className?: any;
  showLink?: boolean;
  sharedReport?: boolean;
  maxImgs?: number;
  routerLink?: string;

  // present if coming from PageReport.renderBatchSummaries
  report?: Report;
}

interface State {
  lot: Lot;
}

class CardLot extends React.Component<Props & ContextProps, State> {
  private inited: boolean;
  private unsubscribe: Function;

  // shouldComponentUpdate(nextProps: Props) {
  //   if (this.props.showSummary) {
  //     return true
  //   }
  //   if (!isEqual(nextProps.lot?.assessments, this.props.lot?.assessments)) {
  //     return true;
  //   }
  //   if (!isEqual(nextProps.lot?.latestAssessment, this.props.lot?.latestAssessment)) {
  //     return true;
  //   }
  //   if (nextProps.multipleSelection !== this.props.multipleSelection) {
  //     return true;
  //   }
  //   if (nextProps.selected !== this.props.selected) {
  //     return true;
  //   }
  //   if (nextProps.lot?.lastModifiedDate !== this.props.lot?.lastModifiedDate) {
  //     return true;
  //   }
  //   if (!shallowEqual(this.props.lot?.article, nextProps.lot?.article)) {
  //     return true;
  //   }

  //   return false
  // }

  constructor(props) {
    super(props);

    this.state = {
      lot: props.lot,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    if (!_.isEqual(this.props, nextProps)) {
      this.setState({ lot: nextProps.lot });
    }
  }

  componentDidMount() {
    this.inited = true;
    if (!this.props.lot && this.props.lotId) {
      this.unsubscribe = getLotSnapshot(
        firestore,
        this.props.organisation.id,
        this.props.lotId,
        (lot) => {
          if (this.inited) this.setState({ lot });
        }
      );
    }
  }

  componentWillUnmount(): void {
    this.inited = false;
    this.unsubscribe?.();
  }

  render() {
    let {
      showSummary,
      profile,
      multipleSelection,
      selected,
      nonClickable,
      eligibleForMultiSelection,
      index,
    } = this.props;
    const { lot } = this.state;

    let cssClass = multipleSelection ? ' multiple-selection ' : ' ';
    cssClass += selected ? ' enabled ' : ' ';
    cssClass += !!eligibleForMultiSelection ? ' ' : ' disabled ';

    if (nonClickable) {
      cssClass += ' non-clickable';
    }

    cssClass += ' ' + this.props.className ?? '';
    if (this.props.loading || !this.state.lot) {
      return (
        <IonCard
          className={'card-inspection card-lot skeleton ' + cssClass}
          style={this.props.style}
        >
          <div className="cover">
            <IonSkeletonText animated className="no-radius" />
          </div>
          <IonCardHeader>
            <div style={{ flex: 1 }}>
              <IonCardTitle>
                <IonSkeletonText animated />
              </IonCardTitle>
              <IonCardSubtitle style={{ padding: '5px 60px 0px 0' }}>
                <IonSkeletonText animated />
              </IonCardSubtitle>
              {/* <IonCardSubtitle style={{ padding: "5px 160px 0px 0" }}>
              <IonSkeletonText animated/>
            </IonCardSubtitle> */}
            </div>
            <div>
              <div className="score-type-section">
                <div className="view-assessment-score INLINE">
                  <div
                    data-tip="score-group_appearance"
                    className="score group_appearance score-4 score-custom-4"
                  >
                    <span className="label">Appearance</span>
                    <span className="numerical">4</span>
                  </div>
                  <div
                    data-tip="score-group_condition"
                    className="score group_condition score-3 score-custom-3"
                  >
                    <span className="label">Condition</span>
                    <span className="numerical">3</span>
                  </div>
                </div>
              </div>
            </div>
          </IonCardHeader>
          <IonCardContent>{/* <IonSkeletonText animated/> */}</IonCardContent>
        </IonCard>
      );
    }

    // let assessment = lot.latestAssessment || newAssessmentForLot({lotId : lot.id, type : "STOCK", date: dateToString(new Date())}, lot.article, lot);
    let inspection: LotInspection =
      lot.latestInspection ||
      newInspectionForLot(
        { lotId: lot.id, type: 'stock', date: dateToString(new Date()) },
        lot
      );

    const link =
      this.props.sharedReport && this.props.profile?.organisationId !== lot.orgId
        ? null
        : '/secure/' +
          lot.orgId +
          '/lot/' +
          lot.id +
          (this.props.context === 'SUPPLY-CHAIN' ||
          lot.transient?.isInMyPossession === false
            ? `/${SUPPLY_CHAIN_URL_SUFFIX}`
            : '');

    if (showSummary && !!this.state.lot.latestInspection) {
      return (
        <div className={cssClass} data-tip={`card-summary-lot-index-${index}`}>
          <CardInspectionSummary
            loading={false}
            showLink={this.props.showLink}
            showGalleryModal={this.props.showGalleryModal}
            organisationId={profile?.organisationId}
            lot={lot}
            report={this.props.report}
            imageMode={'img'}
            sharedReport={this.props.sharedReport}
            noIcon={true}
            searchString={this.props.searchString}
            shouldUpdate={() => false}
            routerLink={this.props.routerLink ?? link}
            history={this.props.history}
            allowGalleryEdit={this.props.allowGalleryEdit}
            onPicturesUpdated={this.props.onPicturesUpdated}
          />
        </div>
      );
    }
    return (
      <div className={cssClass} data-tip={`card-lot-index-${index}`}>
        <CardInspection
          inspection={inspection}
          loading={false}
          showGalleryModal={this.props.showGalleryModal}
          organisationId={profile.organisationId}
          lot={lot}
          noIcon={true}
          maxImgs={this.props.maxImgs ?? 12}
          searchString={this.props.searchString}
          shouldUpdate={() => false}
          routerLink={this.props.routerLink ?? link}
          history={this.props.history}
          allowGalleryEdit={this.props.allowGalleryEdit}
          onPicturesUpdated={this.props.onPicturesUpdated}
        />
      </div>
    );
  }
}

export default withContext(CardLot, ['profile', 'organisation']);
