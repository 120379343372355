import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSectionApiClient } from '../ConfigApi';
import {
  DuplicateSectionRequest,
  InspectableObjectType,
  ModifiedSection,
  NewSection,
  Section,
  SectionOverviewList,
} from '../generated/openapi/core';

export const sectionKeys = {
  all: (orgId: string) => ['sections', { orgId }] as const,
  overview: (orgId: string) => ['sectionsOverview', { orgId }] as const,
  section: (sectionId: string) => ['sections', { sectionId }] as const,
};

async function fetchSectionsOverview(): Promise<SectionOverviewList> {
  const sectionApi = getSectionApiClient();
  const res = await sectionApi.getSectionsOverview();
  return res.data;
}

async function fetchSection(sectionId: string): Promise<Section> {
  const sectionApi = getSectionApiClient();
  const res = await sectionApi.getSection(sectionId);
  return res.data.section;
}

export function useSectionsOverview(orgId: string, objectType: InspectableObjectType) {
  return useQuery(sectionKeys.overview(orgId), fetchSectionsOverview, {
    refetchOnWindowFocus: false,
    select: (data) => {
      return { sections: data.sections.filter((s) => s.objectType === objectType) };
    },
  });
}

export function useSection(
  sectionId: string,
  successCallback?: (section: Section) => void
) {
  return useQuery(sectionKeys.section(sectionId), () => fetchSection(sectionId), {
    onSuccess: successCallback,
    enabled: !!sectionId,
  });
}

export function useCreateSection(orgId: string) {
  const queryClient = useQueryClient();
  const sectionApi = getSectionApiClient();
  return useMutation({
    mutationFn: (newSection: NewSection) => sectionApi.createSection(newSection),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: sectionKeys.all(orgId) }),
        queryClient.invalidateQueries({ queryKey: sectionKeys.overview(orgId) }),
      ]),
  });
}

export function useDuplicateSection(orgId: string) {
  const queryClient = useQueryClient();
  const sectionApi = getSectionApiClient();
  return useMutation({
    mutationFn: (key: {
      id: string;
      duplicateSectionRequest: DuplicateSectionRequest;
    }) => sectionApi.duplicateSection(key.id, key.duplicateSectionRequest),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: sectionKeys.all(orgId) }),
        queryClient.invalidateQueries({ queryKey: sectionKeys.overview(orgId) }),
      ]),
  });
}

export function useEditSection(sectionId: string, orgId: string) {
  const queryClient = useQueryClient();
  const sectionApi = getSectionApiClient();
  return useMutation({
    mutationFn: (section: ModifiedSection) =>
      sectionApi.editSection(sectionId, section),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: sectionKeys.all(orgId) }),
        queryClient.invalidateQueries({ queryKey: sectionKeys.section(sectionId) }),
        queryClient.invalidateQueries({ queryKey: sectionKeys.overview(orgId) }),
      ]),
  });
}

export function useDeleteSection(sectionId: string, orgId: string) {
  const queryClient = useQueryClient();
  const sectionApi = getSectionApiClient();
  return useMutation({
    mutationFn: () => sectionApi.deleteSection(sectionId),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: sectionKeys.all(orgId) }),
        queryClient.invalidateQueries({ queryKey: sectionKeys.overview(orgId) }),
      ]),
  });
}
